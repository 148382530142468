<template>
	<v-container fluid class="fs-organizer-container">
		<div class="page-head__common">
      <v-spacer></v-spacer>
      <v-btn
        flat
        @click="handleClickCreateCategory"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Добавить категорию</v-btn>
    </div>


  <v-layout class="fs-organizer-school-result" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="items"
          hide-actions
          no-data-text="Нет категорий у текущего ОП"
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.created_at | transformDate }}</td>
              <td>{{ props.item.title }}</td>
              <td>
                <v-flex row>
                   <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="handleClickEditCategory(props.item.id)"
                      >edit</v-icon>
                      <span>Редактирование</span>
                    </template>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="handleDeleteCategory(props.item.id)"
                        class="fs-active-icon"
                      >delete</v-icon>
                    </template>
                    <span>Удалить</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog width="30%" v-model="isShowModal">

      <v-form ref="categoryForm" v-model="valid">
        <v-card>
          <v-toolbar elevation="1" dense light>
            <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="closeCategoryModal">
              <v-icon class="fs-active-icon">close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-text-field
              v-model="category.title"
              :rules="[rules.required]"
              label="Наименование категории:"
              @keydown.enter="handleClickSaveOrUpdate"
            />
          </v-card-text>

          <v-card-actions>
            <v-btn @click="handleClickSaveOrUpdate">
              {{ isCreate ? `Создать` : `Обновить` }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <div class="text-xs-center" v-if="pagination_count > 1">
      <v-pagination
        @input="handlePaginationClick"
        :value="paginate.page"
        :length="pagination_count"
        :total-visible="7"
      ></v-pagination>
    </div>

    <ConfirmModal
      :dialog.sync="confirmDeleteModal"
      @confirm="handlerConfirmModal"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
    >
      <template v-slot:headline>Вы действительно хотите удалить категорию продукта?</template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import ConfirmModal from '@/components/common/Modals/ConfirmModal'

import { fetchCategoryOP, createCategoryOP, deleteCategoryOP, fetchOneCategoryOP, updateCategoryOP } from '@/router/routes/organizer.js'

export default {
  name: 'Categories',
  components: { ConfirmModal },
  data() {
    return {
      confirmDeleteModal: false,
      product_id: null,
      items: [],

      headers: [
        { text: 'Дата создания', value: 'created_at' },
        { text: 'Наименование', value: 'title' },
        { text: 'Операции', value: 'actions' }
      ],

      rules: {
        required: (v) => !!v || "Поле обязательное для заполнения"
      },

      valid: true,

      category: {
        title: ''
      },

      paginate: {
        page: 1,
        page_size: 10
      },


      isShowModal: false,
      isCreate: false
    }
  },
  computed: {
    pagination_count() {
      return Math.ceil(this.items.count / this.paginate.page_size) || 0
    },
    modalTitle() {
      return this.isCreate ? 'Создать категорию' : 'Редактировать категорию'
    }
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async handleClickEditCategory(id) {
      await fetchOneCategoryOP(id)
        .then(res => {
          this.category = res.data
          this.isCreate = false
          this.isShowModal = true
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    },
    handleClickSaveOrUpdate() {
      this.$refs.categoryForm.validate()
      if (this.valid) {
        if (this.isCreate) {
          createCategoryOP({
            ...this.category,
          })
            .then(() => {
              this.closeCategoryModal()
              this.fetchCategories()
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              this.setShowPreloader(false)
            })
        } else {
          updateCategoryOP(this.category)
            .then(() => {
              this.closeCategoryModal()
              this.fetchCategories()
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              this.setShowPreloader(false)
            })
        }
      }

    },
    handleClickCreateCategory() {
      this.isCreate = true
      this.category = {}
      this.isShowModal = true
    },
    handleDeleteCategory(id) {
      this.product_id = id
      this.confirmDeleteModal = true
    },
    handlerConfirmModal(value) {
      if (value) {
        deleteCategoryOP(this.product_id)
          .then(() => {
            if (this.items.results && this.items.results.length > 1) {
              this.paginate.page--
            }
            this.fetchCategories()
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.setShowPreloader(false)
          })
      } else {
        this.confirmDeleteModal = false
      }
      this.product_id = null
    },
    handlePaginationClick(page) {
      this.paginate.page = page
      this.fetchCategories()
    },
    closeCategoryModal() {
      this.category = {}
      this.isShowModal = false
    },
    fetchCategories() {
      fetchCategoryOP({ params: { ...this.paginate } })
        .then(res => {
          this.items = res.data
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    },
  },
  mounted() {
    this.fetchCategories()
  }
}
</script>
