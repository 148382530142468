<template>
  <v-container class="fs-organizer-container">
    <v-layout md8 offset-md2 mb-5>
      <v-flex>
        <div class="mb-5" style="display: inline-flex">
          <h1>{{ companyName }}</h1>
          <context-help-modal :context-key="contextKeys.supplier.card" />
        </div>
        <v-layout row>
          <v-flex md6>
            <template v-for="(item, index) in organizer">
              <p
                :key="item.key"
                v-if="index % 2 == 0"
                class="fs-organizer-info"
              >
                <span class="fs-organizer-info__key">{{ item.key }}:</span>
                <span class="fs-organizer-info__value">{{ item.value }}</span>
              </p>
            </template>
          </v-flex>
          <v-flex md6>
            <template v-for="(item, index) in organizer">
              <p
                :key="item.key"
                v-if="index % 2 != 0"
                class="fs-organizer-info"
              >
                <span class="fs-organizer-info__key">{{ item.key }}:</span>
                <span class="fs-organizer-info__value">{{ item.value }}</span>
              </p>
            </template>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout md8 offset-md2 class="fs-organizer-school" column>
      <v-flex class="fs-organizer-school__header">
        <h1 mx-auto>Образовательные организации на обслуживании</h1>
      </v-flex>
    </v-layout>

    <v-layout md8 offset-md2 class="fs-organizer-school-result" column>
      <v-flex v-show="false" class="fs-organizer-school-result__header">
        <h4 mx-auto>Результаты поиска</h4>
      </v-flex>
      <v-layout row>
        <v-flex>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
            class="elevation-1"
            hide-headers
          >
            <template v-slot:items="props">
              <td
                @click="
                  $router.push({
                    name: 'OrganizerSchoolIndex',
                    params: { id: props.item.id, name: props.item.name }
                  })
                "
                class="cursor-pointer"
              >
                {{ props.item.name }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      style="float: right"
                      v-on="on"
                      @click="
                        $router.push({
                          name: 'OrganizerSchoolIndex',
                          params: { id: props.item.id, name: props.item.name }
                        })
                      "
                      >settings</v-icon
                    >
                  </template>
                  <span>Настройки</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script>
import { getOrganizerInfo, getSchools } from '@/router/routes/organizer'
import { mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import _ from 'lodash'
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal.vue'

export default {
  name: 'OrganizerCard',
  components: { ContextHelpModal },
  created() {
    getOrganizerInfo()
      .then((response) => {
        const org = response.data.data
	      this.isBudget = _.get(org, 'is_budget')
	      this.companyName = _.get(org, 'short_name', '')
        this.organizer = [
          {
            key: 'Полное название',
            value: _.get(org, 'full_name', '')
          },
          {
            key: 'Краткое название',
            value: _.get(org, 'short_name', '')
          },
          {
            key: 'Руководитель',
            value: _.get(org, 'manager_name', '')
          },
          {
            key: 'Юридический адрес',
            value: _.get(org, 'postal_address', '')
          },
          {
            key: 'Фактический адрес',
            value: _.get(org, 'legal_address', '')
          },
          {
            key: 'Телефон',
            value: _.get(org, 'phone', '')
          },
          {
            key: 'Сайт',
            value: _.get(org, 'site', '')
          },
          {
            key: 'E-mail',
            value: _.get(org, 'mail', '')
          },
          {
            key: 'ИНН',
            value: _.get(org, 'inn', '')
          },
          {
            key: 'КПП',
            value: _.get(org, 'kpp', '')
          },
          {
            key: 'Расчетный счет',
            value: _.get(org, 'settlement_account', '')
          },
          {
            key: 'Банк',
            value: _.get(org, 'bank', '')
          },
          {
            key: 'БИК',
            value: _.get(org, 'bic', '')
          },
          {
            key: 'ОГРН',
            value: _.get(org, 'ogrn', '')
          },
          {
            key: 'Корр. счет',
            value: _.get(org, 'correspondent_account', '')
          }
        ]

        if (this.isBudget) {
	        this.organizer.splice(10, 0, {
            key: 'Лицевой счет',
            value: _.get(org, 'personal_account', '')
          })

          this.organizer.push(
            {
              key: 'КБК',
              value: _.get(org, 'cbc', '')
            },
            {
              key: 'ОКТМО',
              value: _.get(org, 'oktmo', '')
            }
          )
        }

        this.loadSchools()
      })
      .finally(store.commit('setShowPreloader', false))
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      companyName: '',
	    isBudget: false,
      organizer: [],
      headers: [
        { text: 'Образовательная организация', value: 'School' },
        { text: 'Операции', value: 'operations' }
      ],
      items: []
    }
  },
  methods: {
    ...mapMutations(['setShowPreloader']),
    loadSchools() {
      getSchools()
        .then((response) => {
          this.items = _.map(response.data.data, (item) => {
            return {
              id: item.id,
              name: item.name
            }
          })
        })
        .finally(() => store.commit('setShowPreloader', false))
    }
  }
}
</script>
