<template>
  <v-container fluid>
    <v-layout class="fs-dish" row>
      <div class="title-help">
        <div class="d-flex pt-2">
          <p class="text text-center">Статистика по заявкам и ученикам</p>
          <p>
            <context-help-modal :context-key="contextKeys.admin.dashboard.orders"/>
          </p>
        </div>
      </div>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 md4 class="px-2 pb-2 mt-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-card
                v-on="on"
                class="clickable"
                @click="showModal = true; modalNegativeBalance = true"
                :color="orders_negative_balance > 0 ? 'red' : ''"
                :class="orders_negative_balance > 0 ? 'text-white hover-red' : 'hover'"
            >
              <h3 class="pb-0 pt-3 px-3 custom-title">
                С отрицательным балансом
              </h3>
              <v-card-text>
                <div class="text-center">
                  <span class="custom-text">{{ orders_negative_balance }}</span>
                </div>
              </v-card-text>
            </v-card>
          </template>
          <span>Список заявок, исполнение которых приведет к отрицательному балансу</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs12 md4 class="px-2 pb-2 mt-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-card
                v-on="on"
                class="clickable hover"
                @click="showModal = true; modalWithoutFoodtypes = true"
            >
              <h3 class="pb-0 pt-3 px-3 custom-title">
                Дети без типа питания
              </h3>
              <v-card-text>
                <div class="text-center">
                  <span class="custom-text">{{ pupil_without_foodtypes }}</span>
                </div>
              </v-card-text>
            </v-card>
          </template>
          <span>Список детей без назначенного типа питания</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs12 md4 class="px-2 pb-2 mt-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-card
                v-on="on"
                class="clickable"
                @click="showModal = true; modalOverCredit = true"
                :color="orders_over_credit > 0 ? 'red darken-4' : ''"
                :class="orders_over_credit > 0 ? 'text-white hover-red' : 'hover'"
            >
              <h3 class="pb-0 pt-3 px-3 custom-title">
                Превышение кредита
              </h3>
              <v-card-text>
                <div class="text-center">
                  <span class="custom-text">{{ orders_over_credit }}</span>
                </div>
              </v-card-text>
            </v-card>
          </template>
          <span>Список заявок, которые могут привести к превышению или достижению лимита кредита</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <v-card v-if="showModal && modalNegativeBalance">
      <ModalPupilNegativeBalance @close="handleCloseModal"/>
    </v-card>
    <v-card v-if="showModal && modalExpiredPrivilege">
      <ModalPupilExpiredPrivilege @close="handleCloseModal"/>
    </v-card>
    <v-card v-if="showModal && modalWithoutFoodtypes">
      <ModalPupilWithoutFoodtypes @close="handleCloseModal"/>
    </v-card>
    <v-card v-if="showModal && modalYesterdayUncompleted">
      <ModalOrdersYesterdayUncompleted @close="handleCloseModal"/>
    </v-card>
    <v-card v-if="showModal && modalOverCredit">
      <ModalOrdersOverCredit @close="handleCloseModal"/>
    </v-card>
  </v-container>
</template>


<script>
import store from '@/store'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { TextEditorModal } from '@/components/common'
import { getAdminDashboardOrderHistogram } from '@/router/routes/admin'
import notify from '@/utils/notifications/toastNotification'
import { mapGetters } from "vuex"
import ModalPupilNegativeBalance from '@/components/admin/Dashboard/ModalPupilNegativeBalance'
import ModalPupilExpiredPrivilege from '@/components/admin/Dashboard/ModalPupilExpiredPrivilege'
import ModalPupilWithoutFoodtypes from '@/components/admin/Dashboard/ModalPupilWithoutFoodtypes'
import ModalOrdersYesterdayUncompleted from '@/components/admin/Dashboard/ModalOrdersYesterdayUncompleted'
import ModalOrdersOverCredit from '@/components/admin/Dashboard/ModalOrdersOverCredit'
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal'
import Vue from "vue"

const labels = [
  'С отрицательным балансом', 'Истек срок льготы', 'Без типа питания',
  'Невыполненные за вчера', 'Превышение кредита'
]

export default {
  name: 'OrderHistogram',
  components: {
    TextEditorModal,
    ContextHelpModal,
    ModalPupilNegativeBalance,
    ModalPupilExpiredPrivilege,
    ModalPupilWithoutFoodtypes,
    ModalOrdersYesterdayUncompleted,
    ModalOrdersOverCredit
  },
  data() {
    return {
      showModal: false,
      contextKeys: Vue.config.contextHelp,
      modalNegativeBalance: false,
      modalExpiredPrivilege: false,
      modalWithoutFoodtypes: false,
      modalYesterdayUncompleted: false,
      modalOverCredit: false,
      modalTitle: '',
      modalContent: '',
      pupil_order_today: 0,
      pupil_no_order: 0,
      orders_negative_balance: 0,
      orders_over_credit: 0,
      orders_yesterday_uncompleted: 0,
      pupil_expired_privilege: 0,
      pupil_without_foodtypes: 0,
      pupil_count: 0,
      options: {
        dataLabels: {
          enabled: false
        },
        sparkline: {
          enabled: true
        },
        indexAxis: 'y',
        scales: {
          x: {
            display: false,
          },
          y: {
            display: true,
          }
        },
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            align: function(context) {
              return context.dataset.data[context.dataIndex] > 100 ? 'center' : 'end'
            },
            anchor: function(context) {
              return context.dataset.data[context.dataIndex] > 100 ? 'center' : 'end'
            },
            listeners: {
              enter: (context) => {
                context.hovered = true
                context.chart.canvas.style.cursor = 'pointer'
                return true
              },
              leave: (context) => {
                context.hovered = false
                context.chart.canvas.style.cursor = 'default'
                return true
              },
            }
          }
        }
      }
    }
  },
  mounted() {
    this.loadStatistics()
      .then(() => {
        this.createChart()
      })
      .catch((error) => {
        console.error(error)
        notify.error('Ошибка загрузки статистики заявок')
      })
      .finally(() => {
        store.commit('setShowPreloader', false)
      })
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    async loadStatistics() {
      try {
        const response = await getAdminDashboardOrderHistogram({ school_id: this.currentUser.school_id })
        this.pupil_order_today = response.data.pupil_order_today
        this.pupil_no_order = response.data.pupil_no_order
        this.orders_negative_balance = response.data.orders_negative_balance
        this.orders_over_credit = response.data.orders_over_credit
        this.orders_yesterday_uncompleted = response.data.orders_yesterday_uncompleted
        this.pupil_expired_privilege = response.data.pupil_expired_privilege
        this.pupil_without_foodtypes = response.data.pupil_without_foodtypes
        this.pupil_count = response.data.pupil_count
        this.$emit('getData', [ this.pupil_order_today, this.pupil_count, this.pupil_expired_privilege, this.orders_yesterday_uncompleted ])
      } catch (error) {
        notify.error('Ошибка загрузки статистики заявок') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    onClick(event) {
      this.chart.render()
      const activePoints = this.chart.getElementsAtEventForMode(event, 'y', { intersect: false, })
      if (activePoints.length > 0) {
        const index = activePoints[0].index
        this.openModal(index)
      }
    },
    createChart() {
      // const data = {
      //   labels: labels,
      //   datasets: [ {
      //     axis: 'y',
      //     label: '',
      //     data: [ this.orders_negative_balance, this.pupil_expired_privilege, this.pupil_without_foodtypes,
      //       this.orders_yesterday_uncompleted, this.orders_over_credit
      //     ],
      //     fill: false,
      //     backgroundColor: [
      //       'rgb(163,227,100)',
      //       'rgb(169,37,66)',
      //       'rgba(255, 205, 86)',
      //       'rgba(75, 192, 192)',
      //       'rgb(138,4,150)',
      //       'rgba(54, 162, 235)',
      //       'rgba(153, 102, 255)',
      //       'rgb(148,25,99)'
      //     ],
      //     borderColor: [
      //       'rgb(124, 252, 0)',
      //       'rgb(255, 99, 132)',
      //       'rgb(255, 205, 86)',
      //       'rgb(75, 192, 192)',
      //       'rgb(232, 228, 9)',
      //       'rgb(54, 162, 235)',
      //       'rgb(153, 102, 255)',
      //       'rgb(145, 25, 148)'
      //     ],
      //     borderWidth: 1,
      //     barPercentage: 1,
      //   } ]
      // }
      // const ctx = this.$refs.myChart.getContext('2d')
      // this.chart = new Chart(ctx, {
      //   type: 'bar',
      //   data: data,
      //   options: this.options,
      //   plugins: [ ChartDataLabels ]
      // })
    },
    openModal(index) {
      // определяем, какую модальную окно нужно открыть, в зависимости от индекса выбранной шкалы
      if (index === 0 && this.orders_negative_balance > 0) {
        this.modalNegativeBalance = true
      } else if (index === 1 && this.pupil_expired_privilege > 0) {
        this.modalExpiredPrivilege = true
      } else if (index === 2 && this.pupil_without_foodtypes > 0) {
        this.modalWithoutFoodtypes = true
      } else if (index === 3 && this.orders_yesterday_uncompleted > 0) {
        this.modalYesterdayUncompleted = true
      } else if (index === 4 && this.orders_over_credit > 0) {
        this.modalOverCredit = true
      }
      this.showModal = true
    },
    async handleCloseModal() {
      this.showModal = false
      this.modalNegativeBalance = false
      this.modalExpiredPrivilege = false
      this.modalWithoutFoodtypes = false
      this.modalYesterdayUncompleted = false
      this.modalOverCredit = false
      await this.loadStatistics()
      this.chart.destroy()
      await this.createChart()
    },
  }
}
</script>

<style>
.custom-text {
  font-size: 26px;
  font-weight: 400;
}

.title-help {
  display: flex;
  width: 100%;
  justify-content: center;
}

.text-white {
  color: white !important
}

.custom-title {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}

.hover:hover {
  background-color: #eeeeee !important;
}

.hover-red:hover {
  background-color: #c70c0c !important;
}

.clickable {
  cursor: pointer;
}

canvas {
  width: available !important;
}

</style>
