<template>
  <v-container
    fluid
    class="fs-container"
  >
    <v-layout
      class="fs-order"
      row
      mb-3
    >
      <v-flex
        md4
        mb-3
      >
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout
      row
      mb-3
      justify-space-between
      text-md-center
      class="fs-header-filter"
    >
      <v-flex
        md12
        text-md-right
      >
        <v-btn
          flat
          @click="print_orders('newForm')"
          class="primary"
        >печать по классу новая форма</v-btn>
        <v-btn
          flat
          @click="print_orders('oldForm')"
          class="primary"
        >печать по классу старая форма</v-btn>
        <v-btn
          flat
          @click="print"
          class="primary"
        >печать меню на день</v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      class="fs-order-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1 fs-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.ageCategory }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.qty }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex
        text-md-right
        pt-3
      >
        <v-btn
          flat
          class="primary"
          @click="$router.push({name: 'OrganizerOrderSchoolView'})"
        >К списку заявок</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment'
import {
  printMenu,
  getCurrentOrder,
  getOrderReport,
  getOrderReportDownload,
  getOrderReportStatus
} from '@/router/routes/organizer'
import { mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import store from '@/store'
import _ from 'lodash'
import asyncReqestsForDownload from "@/helpers/asyncReqestsForDownloadWithProgressBar"
import notify from "@/utils/notifications/toastNotification"

export default {
  name: 'OrganizerOrderView',
  created() {
    this.loadOrder().finally(() => store.commit('setShowPreloader', false))
  },
  data() {
    return {
      title: 'Заявка на питание',
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10),
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Возрастная категория', value: 'ageCategory' },
        { text: 'Название блюда', value: 'name' },
        { text: 'Количество', value: 'qty' }
      ],
      items: []
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    loadOrder() {
      const payload = {
        schoolId: this.$route.params.schoolId,
        classId: this.$route.params.classId,
        date: moment(this.$route.params.date).format(
          Vue.config.format.pythonDate
        )
      }
      return getCurrentOrder(payload).then(response => {
        if (!response.data.data.length) {
          return
        }
        const middleOrders = _.map(response.data.data[0].weightMiddle, item => {
          return {
            id: item.id,
            ageCategory: '11-18 лет',
            name: item.name,
            qty: item.quantity
          }
        })
        const primaryOrders = _.map(
          response.data.data[0].weightPrimary,
          item => {
            return {
              id: item.id,
              ageCategory: '7-10 лет',
              name: item.name,
              qty: item.quantity
            }
          }
        )
        this.items = this.items.concat(middleOrders)
        this.items = this.items.concat(primaryOrders)
      })
    },
    navigateTo(where, params) {
      const date = {
        day: moment(params.date, Vue.config.format.date).format('DD'),
        month: moment(params.date, Vue.config.format.date).format('MM'),
        year: moment(params.date, Vue.config.format.date).format('YYYY'),
        id: params.class.id
      }

      this.$router.push({ name: where, params: date })
    },
    async print_orders(reportType) {
      const payload = {
        order_day: moment(this.$route.params.date).format(
          Vue.config.format.serverDate
        ),
        reportType: reportType,
        classId: this.$route.params.classId
      }
      const callback = () => false
      try {
        await asyncReqestsForDownload(
          getOrderReport,
          getOrderReportStatus,
          getOrderReportDownload,
          payload,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки документа')
      } finally {
        callback()
        store.commit('setShowPreloader', false)
      }
    },
    print() {
      const payload = {}
      printMenu(payload)
        .then(response => {
          const file = new Blob([ response.data ], { type: response.data.type })
          const url = window.URL.createObjectURL(file)
          const a = document.createElement('a')
          a.href = url
          a.download = 'Меню на' +
            moment(this.$route.params.date).format(Vue.config.format.date) +
            '.xlsx'
          a.click()
        })
        .finally(() => store.commit('setShowPreloader', false))
    }
  }
}
</script>
