<template>
  <v-container fluid class="fs-admin-container fs-complex-page">
    <v-flex mb-5>
      <h1>{{title}}: {{item.name}}</h1>
    </v-flex>
    <v-form>
      <v-layout row justify-space-around>
        <v-flex class="fs-create-card-form--grey" md10>
          <v-layout mb-2>
            <v-flex md12>
              <v-text-field
                v-model="item.supplier"
                label="ОП:"
                readonly
                class="fs-admin-complex-autocomplete"
              ></v-text-field>
              <v-text-field
                v-model="item.foodType"
                label="Тип питания:"
                readonly
                class="fs-admin-complex-autocomplete"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row justify-space-around>
            <v-flex md11>
              <v-flex md3 mb-2>
                <h3>Спецификация комплекса</h3>
              </v-flex>
              <v-flex mb-3>
                <v-divider></v-divider>
              </v-flex>
              <v-layout column mb-3>
                <v-tabs grow class="fs-tabs--grey">
                  <v-tabs-slider color="yellow" class="fs-toolbar--grey v-toolbar"></v-tabs-slider>

                  <v-tab v-if="Object.keys(item.breakfast).length > 0"
                  >Завтрак</v-tab>

                  <v-tab v-if="Object.keys(item.lunch).length > 0"
                  >Второй завтрак</v-tab>

                  <v-tab v-if="Object.keys(item.dinner).length > 0"
                  >Обед</v-tab>

                  <v-tab v-if="Object.keys(item.afternoonSnack).length > 0"
                  >Полдник</v-tab>

                  <v-tab v-if="Object.keys(item.eveningMeal).length > 0"
                  >Ужин</v-tab>

                  <v-tab v-if="Object.keys(item.lateDinner).length > 0"
                  >Поздний ужин</v-tab>

                  <v-tab-item v-if="Object.keys(item.breakfast).length > 0">
                    <v-layout row>
                      <v-flex>
                        <v-data-table
                          :headers="headers"
                          :items="getDishes(item.breakfast)"
                          hide-actions
                          class="elevation-1"
                          no-data-text="Отсутствует"
                        >
                          <template v-slot:items="props">
                            <tr>
                              <td>{{props.item.name}}</td>
                              <td>{{props.item.energy}}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                  <v-tab-item v-if="Object.keys(item.lunch).length > 0">
                    <v-layout row>
                      <v-flex>
                        <v-data-table
                          :headers="headers"
                          :items="getDishes(item.lunch)"
                          hide-actions
                          class="elevation-1"
                          no-data-text="Отсутствует"
                        >
                          <template v-slot:items="props">
                            <tr>
                              <td>{{props.item.name}}</td>
                              <td>{{props.item.energy}}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                  <v-tab-item v-if="Object.keys(item.dinner).length > 0">
                    <v-layout row>
                      <v-flex>
                        <v-data-table
                          :headers="headers"
                          :items="getDishes(item.dinner)"
                          hide-actions
                          class="elevation-1"
                          no-data-text="Отсутствует"
                        >
                          <template v-slot:items="props">
                            <tr>
                              <td>{{props.item.name}}</td>
                              <td>{{props.item.energy}}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                  <v-tab-item v-if="Object.keys(item.afternoonSnack).length > 0">
                    <v-layout row>
                      <v-flex>
                        <v-data-table
                          :headers="headers"
                          :items="getDishes(item.afternoonSnack)"
                          hide-actions
                          class="elevation-1"
                          no-data-text="Отсутствует"
                        >
                          <template v-slot:items="props">
                            <tr>
                              <td>{{props.item.name}}</td>
                              <td>{{props.item.energy}}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                  <v-tab-item v-if="Object.keys(item.eveningMeal).length > 0">
                    <v-layout row>
                      <v-flex>
                        <v-data-table
                          :headers="headers"
                          :items="getDishes(item.eveningMeal)"
                          hide-actions
                          class="elevation-1"
                          no-data-text="Отсутствует"
                        >
                          <template v-slot:items="props">
                            <tr>
                              <td>{{props.item.name}}</td>
                              <td>{{props.item.energy}}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                  <v-tab-item v-if="Object.keys(item.lateDinner).length > 0">
                    <v-layout row>
                      <v-flex>
                        <v-data-table
                          :headers="headers"
                          :items="getDishes(item.lateDinner)"
                          hide-actions
                          class="elevation-1"
                          no-data-text="Отсутствует"
                        >
                          <template v-slot:items="props">
                            <tr>
                              <td>{{props.item.name}}</td>
                              <td>{{props.item.energy}}</td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                </v-tabs>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-flex text-md-right>
            <v-btn
              flat
              class="fs-btn fs-btn--secondary fs-admin-btn__cancel v-btn--active"
              @click="$router.go(-1)"
            >Назад</v-btn>
            <!--v-btn flat class="fs-btn fs-btn--primary fs-teacher-btn__cancel v-btn--active">сохранить</v-btn-->
          </v-flex>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import {getDishes, getOneComplex} from "@/router/routes/admin"
import store from "@/store"
import {mapGetters} from "vuex"
import _ from "lodash"
import Vue from "vue"

export default {
  name: "AdminComplexView",

  created() {
    const payload = {
      schoolId: this.currentUser.school.id,
      id: this.$route.params.id
    }

    getDishes(payload)
      .then(response => {
        this.dishes = response.data.data
        return getOneComplex(payload)
      })
      .then(response => {
        const complex = response.data.data
        this.item = {
          name: complex.name,
          foodType: _.first(complex.mealType).name,
          supplier: this.getOrganizerName(complex.supplier),
          breakfast: _.get(
            _.find(
              complex.fp,
              item => item.mealPeriod == Vue.config.general.static.breakfast
            ),
            "foods",
            []
          ),
          lunch: _.get(
            _.find(
                complex.fp,
                item => item.mealPeriod == Vue.config.general.static.lunch
            ),
              "foods",
              []
          ),
          dinner: _.get(
            _.find(
              complex.fp,
              item => item.mealPeriod == Vue.config.general.static.dinner
            ),
            "foods",
            []
          ),
          afternoonSnack: _.get(
            _.find(
              complex.fp,
              item =>
                item.mealPeriod == Vue.config.general.static.afternoonSnack
            ),
            "foods",
            []
          ),
          eveningMeal: _.get(
            _.find(
                complex.fp,
                item => item.mealPeriod == Vue.config.general.static.eveningMeal
            ),
              "foods",
              []
          ),
          lateDinner: _.get(
            _.find(complex.fp,
            item => item.mealPeriod == Vue.config.general.static.lateDinner
            ),
            "foods",
            []
          ),
        }
      })

        .finally(() => store.commit("setShowPreloader", false))
    console.log(this.item.dinner.lenght)
  },
  data() {
    return {
      title: "Посмотреть комплекс",
      tab: null,
      item: {
        name: '',
        foodType: null,
        supplier: null,
        breakfast: {},
        lunch: {},
        dinner: {},
        afternoonSnack: {},
        eveningMeal: {},
        lateDinner: {},
      },
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10),
      headers: [
        { text: "Блюдо", value: "dish-name" },
        { text: "Калорийность", value: "energy" }
      ],
      dishes: []
    }
  },
  computed: {
    ...mapGetters([ "foodTypes", "currentUser" ])
  },
  methods: {
    getDishes(dishesIds) {
      // @TODO complexes must respond same things
      const dishes = _.filter(this.dishes, item =>
        _.includes(dishesIds, item.id)
      )
      const preparedDishes = _.map(dishes, item => {
        return {
          id: item.id,
          name: item.name,
          energy: item.energyValue,
          protein: item.protein,
          fats: item.fat,
          carbs: item.carbohydrates,
          price: item.price || 0,
          additionally: false
        }
      })
      return preparedDishes
    },
    getOrganizerName() {
      return _.get(this.currentUser, 'school.supplier.short_name', null)
    }
  }
}
</script>

<style>
.fs-complex-page .fs-create-card-form--grey .v-input .v-label,
.fs-complex-page .fs-create-card-form--grey .v-text-field__slot .v-label,
.fs-complex-page .fs-create-card-form--grey .fs-student-info__key .v-label {
  width: 100px;
  min-width: 100px;
}
</style>