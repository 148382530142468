import { Menu, Submenu } from '@/lib/menu'

export default class MainMenuFactory {
  constructor(subroles, settings, currentUser) {

    const userRole = currentUser ? currentUser.roles : []
    subroles = subroles === null ? [] : subroles

    const admin = new Submenu('Администрирование')
    const foodMenu = new Submenu('Меню')
    const finances = new Submenu('Финансы')
    const supplier = new Submenu('Оператор питания')

    let menu = [
      admin,
      foodMenu,
      finances,
      supplier
    ]

    if (settings.mod_quality_control?.value) {
      const menu = new Menu('Контроль качества', 'Контроль качества', 'OrganizerQualityControlWrap')
      if (!supplier.includes(menu)) supplier.add(menu)
    }

    // if (settings.esia_auth_supplier?.value) {
    //   supplier.add(
    //       new Menu('ГосУслуги', 'ГосУслуги', 'EsiaSupplierSettings')
    //   )
    // }

    if (settings.supplier_privacy_policy?.value) {
      menu = menu.concat([
        new Menu('Конфиденциальность', 'Конфиденциальность', 'Privacy')
      ])
    }

    if (settings.archive_report_service?.value) {
      menu = menu.concat([
        new Menu('Отчёты (архив)', 'Архивные отчёты', 'OrganizerReportsArchiveIndex')
      ])
    }

    if (settings.mod_buffet?.value) {
      menu = menu.concat([
        // new Menu('Буфет', 'Буфет', 'OrganizerBuffet'),
        new Menu('Результаты опросов', 'Результаты опросов', 'OrganizerSyrveyResult')
      ])
    }

    if (settings.user_help?.value) {
      menu = menu.concat([
        // new Menu('Инструкции', 'Инструкции', 'OrganizerUserHelp'),
        new Menu('Справка', 'Справка', 'HelpDeskSU')
      ])
    }

    const userMenu = [
      {
        code: 'NEWS', // Раздел Новости доступен всем ролям
        type: 'news',
        items: [
          new Menu('Новости', 'Новости обновлений', 'UpdatesNewsOrganizer')
        ]
      }, {
        code: 'ADMIN', // Раздел Администрирование доступен только ОП
        type: 'admin',
        items: [
          new Menu('Пользователи', 'Пользователи', 'OrganizerUsers'),
          new Menu('Действия пользователей', 'Действия пользователей', 'OrganizerUsersActions'),
          new Menu('Лог изменения заявок', 'Лог изменения заявок', 'OrganizerOrderLogs')
        ]
      }, {
        code: 'SUB_SUPPLIER_MEALS',
        type: 'foodmenu',
        items: [
          new Menu('Блюда', 'Создание и изменение информации о предоставляемых блюдах', 'Dishes'),
          new Menu('Архив блюд', 'Просмотр архива блюд', 'DishesArchive'),
        ]
      }, {
        code: 'SUB_SUPPLIER_COMPLECTS',
        type: 'foodmenu',
        items: [
          new Menu('Комплексы', 'Создание и изменение состава комплексов питания', 'Complexes'),
          new Menu('Архив комплексов', 'Просмотр архива комплексов питания', 'StashedComplexes')
        ]
      }, {
        code: 'SUB_SUPPLIER_MENU_TEMPLATES',
        type: 'foodmenu',
        items: [
          new Menu('Шаблоны меню', 'Формирование ежедневного меню для образовательных организаций', 'AllDaysMenu')
        ]
      }, {
        code: 'SUB_SUPPLIER_DAILY_MENU',
        type: 'foodmenu',
        items: [
          new Menu('Ежедневное меню', 'Формирование ежедневного меню для образовательных организаций', 'MenuTemplates'),
        ]

      }, {
        code: 'SUB_SUPPLIER_MEALS',
        type: 'foodmenu',
        items: [
          new Menu('Доп. блюда', 'Дополнительные блюда', 'OrganizerAdditionalDishes')
        ]
      }, {
        code: 'SUB_SUPPLIER_RECEIPTS',
        type: 'finances',
        items: [
          new Menu('Платежи', 'Информация о платежах', 'Bills')
        ]
      }, {
        code: 'SUB_SUPPLIER_BALANCE_VIEW',
        type: 'finances',
        items: [
          new Menu('Просмотр баланса', 'Просмотр баланса в разрезе образовательной организации и классов', 'Balance')
        ]
      }, {
        code: 'SUB_SUPPLIER_BALANCE_EDIT',
        type: 'finances',
        items: [
          new Menu('Изменение баланса', 'Изменение баланса учеников', 'BalanceList')
        ]
      }, {
        code: 'SUB_SUPPLIER_CASHBACK_INDEX',
        type: 'finances',
        items: [
          new Menu('Заявления на возврат', 'Заявления на возврат', 'OrganizerCashbackIndex')
        ]
      }, {
        code: 'SUB_SUPPLIER_EXPELLED_REGISTER',
        type: 'finances',
        items: settings.supplier_expelled_register?.value ?
          [ new Menu('Реестр отчисленных детей', 'Реестр отчисленных детей', 'ExpelledRegister') ]
          : []
      }, {
        code: 'SUB_SUPPLIER_INFO',
        type: 'supplier',
        items: [
          new Menu('Карточка ОП', 'Реквизиты и контактная информация', 'OrganizerCard')
        ]
      }, {
        code: 'SUB_SUPPLIER_OFERTA',
        type: 'supplier',
        items: [
          new Menu('Оферта', 'Оферта', 'Oferta'),
          new Menu('Статус оферты', 'Отслеживание статуса оферты', 'OfertaStudents')
        ]
      }, {
        code: 'SUB_SUPPLIER_ORDERS',
        type: 'orders',
        items: [
          new Menu('Заявки на питание', 'Просмотр заявок на питание', 'OrganizerOrderSchoolView'),
        ]
      }, {
        code: 'SUB_SUPPLIER_REPORTS',
        type: 'reports',
        items: [
          new Menu('Отчёты', 'Отчёты', 'OrganizerReportsIndex')
        ]
      }, {
        code: 'SUB_SUPPLIER_NOTIFICATIONS',
        type: 'notifications',
        items: [
          new Menu('Уведомления', 'Просмотр уведомлений', 'OrganizerNotifications')
        ]
      }
    ]

    /**
     * Убираем под настройку страницу
     */
    if (settings.former_schools_for_supplier?.value) {
      userMenu.push({
        code: 'SUB_SUPPLIER_FORMER_SCHOOLS',
        type: 'finances',
        items: [
          new Menu(
            'Платежи по бывшим школам',
            'История платежей по бывшим школам',
            'FormerSchoolsView'
          )
        ]
      })
    }

    for (const menuItems of userMenu) {
      const menuType = menuItems.type
      const codeRoles = menuItems.code
      for (const menuItem of menuItems.items) {
        if (menuType === 'news') {
          menu.unshift(menuItem)
        }
        if (menuType === 'admin' && (subroles.includes(codeRoles) || userRole.includes('SUPPLIER'))) {
          admin.add(menuItem)
        }
        if (menuType === 'foodmenu' && (subroles.includes(codeRoles) || userRole.includes('SUPPLIER'))) {
          foodMenu.add(menuItem)
        }
        if (menuType === 'finances' && (subroles.includes(codeRoles) || userRole.includes('SUPPLIER'))) {
          finances.add(menuItem)
        }
        if (menuType === 'supplier' && (subroles.includes(codeRoles) || userRole.includes('SUPPLIER'))) {
          supplier.add(menuItem)
        }
        if (menuType === 'orders' && (subroles.includes(codeRoles) || userRole.includes('SUPPLIER'))) {
          menu.push(menuItem)
        }
        if (menuType === 'reports' && (subroles.includes(codeRoles) || userRole.includes('SUPPLIER'))) {
          menu.push(menuItem)
        }
        if (menuType === 'notifications' && (subroles.includes(codeRoles) || userRole.includes('SUPPLIER'))) {
          menu.push(menuItem)
        }
      }
    }
    return menu.filter(item => (item.children && item.children.length !== 0) || !item.children)
  }
}
