<template>
  <v-layout row>
    <v-dialog v-model="cashbackModal" persistent>
      <v-card>
        <v-card-title>
          <h3>Добавить новое заявление</h3>
        </v-card-title>
        <v-card-text>
          <v-layout column>
            <v-flex>
              <v-text-field
                v-model="pupilId"
                label="Лицевой счет ученика"
                class="pr-2"
              ></v-text-field>
            </v-flex>
            <v-flex>
              Загрузка заявления:
              {{ fileName }}
              <UploadButton
                :uniqueId="true"
                title="Выберите файл"
                noTitleUpdate
                :fileChangedCallback="fileChanged"
                add-class="fs-btn fs-btn--primary"
              />
            </v-flex>
            <v-flex class="mt-2">
              <v-menu
                :close-on-content-click="true"
                lazy
                transition="scale-transition"
                offset-y
                style="display: none"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Дата подачи заявления"
                    persistent-hint
                    v-model="selectedDateComputed"
                    v-on="on"
                    readonly
                    class="mr-2"
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="ru-ru"
                  type="date"
                  v-model="selectedDate"
                  first-day-of-week="1"
                  width="290"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex class="mt-3">
              <v-menu
                :close-on-content-click="true"
                lazy
                transition="scale-transition"
                offset-y
                style="display: none"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Дата возврата средств"
                    persistent-hint
                    v-model="selectedDateRefundComputed"
                    v-on="on"
                    readonly
                    class="mr-2"
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="ru-ru"
                  type="date"
                  v-model="selectedDateRefund"
                  first-day-of-week="1"
                  no-title
                  width="290"
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex>
              <v-text-field
                v-model="amount"
                label="Сумма к возврату, руб"
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-btn
                @click="saveRequest()"
                color="success"
              >
                Сохранить
              </v-btn>
              <v-btn
                @click="close()"
              >
                Закрыть
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="successModal"
      width="400"
    >
      <v-card>
        <v-card-title primary-title>
          Заявление сохранено
        </v-card-title>
        <v-card-text>
          <p>Статус: <b>{{ createdInfo ? createdInfo.status_display : '' }}</b></p>
          <p>Получатель: <b>{{ createdInfo ? createdInfo.recipient : '' }}</b></p>
          <p>Баланс на момент подачи: <b>{{ createdInfo ? convert(createdInfo.balance_on_submit) : '' }}</b></p>
          <p>Сумма возврата: <b>{{ createdInfo ? convert(createdInfo.amount) : '' }}</b></p>
          <p>Баланс на момент подтверждения: <b>{{ createdInfo ? convert(createdInfo.balance_on_approve) : '' }}</b></p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="closeSubmodal()"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import moment from "moment";
import UploadButton from '@/components/common/UploadButton/UploadButton'
import Vue from "vue";
import {
  createCashbackRequest,
  getOneStudentBalance,
} from "@/router/routes/organizer";
import store from "@/store";
import converter from "@/helpers/converter";
import notify from '@/utils/notifications/toastNotification'

export default {
  name: "CreateCashbackModal",
  components: {
    UploadButton
  },
  props: {
    cashbackModal: Boolean,
    registerId: Number
  },
  data() {
    return {
      selectedDate: null,
      selectedDateRefund: null,
      fileName: '',
      amount: null,
      file: null,
      successModal: false,
      pupilId: null,
      createdInfo: null,
      registerId: null
    }
  },
  watch: {
    cashbackModal() {
      if (this.cashbackModal && this.$attrs) {
        this.amount = this.$attrs.amount.toString()
        this.pupilId = this.$attrs.pupilId.toString()
        this.registerId = this.$attrs.registerId.toString()
      }
    }
  },
  computed: {
    selectedDateComputed() {
      if (this.selectedDate) return moment(this.selectedDate).format('DD-MM-YYYY')
      return ''
    },
    selectedDateRefundComputed() {
      if (this.selectedDateRefund) return moment(this.selectedDateRefund).format('DD-MM-YYYY')
      return ''
    }
  },
  methods: {
    close() {
      this.$parent.toggleCashbackModal()
    },
    closeSubmodal() {
      this.successModal = false
    },
    fileChanged(event){
      this.file = event
      this.fileName = event.name
    },
    convert(amount) {
      return converter(amount, 'pennyToRub')
    },
    convertMoney(money) {
      money = money.replaceAll(',', '.').split('.')
      const ruble = money[0]
      let penny = money[1] ? money[1] : '00'
      if (penny.toString().length > 2) {
        notify.error('Ошибка. Стоимость должна содержать две цифры после запятой') //pass
        throw new Error('Ошибка. Стоимость должна содержать две цифры после запятой')
      }
      penny = penny && penny.toString().length === 1 ? penny = penny * 10 : penny
      return `${ruble}${penny}`
    },
    async saveRequest() {
      if (this.selectedDate === null || this.selectedDateRefund === null || this.pupilId === null || this.amount === null) {
        notify.error('Необходимо указать лицевой счет, сумму, дату подачи и дату возврата!') //pass
        return
      }
      if (this.amount <= 0) {
        notify.error('Сумма возврата должна быть больше 0') //pass
        return
      }
      const form = new FormData()
      if (this.file) form.append('file', this.file ? this.file : null)
      form.append('pupil_id', this.pupilId)
      form.append('selected_date', this.selectedDate)
      form.append('refund_date', this.selectedDateRefund)
      form.append('amount', this.convertMoney(this.amount))
      form.append('register_id', this.registerId)

      const response = await createCashbackRequest(form)
      if (!response.data.error) {
        notify.success('Заявление сохранено. Сумма возврата списана с баланса ученика')
        this.selectedDate = null
        this.selectedDateRefund = null
        this.fileName = ''
        this.amount = null
        this.file = null
        this.pupilId = null
        this.createdInfo = response.data
        this.successModal = true
        this.dialog = false
      } else {
        notify.error(response.data.error ? response.data.error : 'Неизвестная ошибка, обратитесь в поддержку.') //pass
      }
      store.commit('setShowPreloader', false)
    }
  }
}
</script>

<style scoped>

</style>