import { getFoodBlockPassports,
  getFoodBlockTypes, updateFoodBlockTypes,
  getFoodBlockEngineer, updateFoodBlockEngineer,
  getFoodBlockTransport, updateFoodBlockTransport,
  getFoodBlockRooms, updateFoodBlockRooms,
  getFoodblockWarehouses, updateFoodBlockWarehouses,
  getFoodblockHousehold, updateFoodBlockHousehold,
  getFoodblockStaff, updateFoodBlockStaff,
  getFoodblockSchedule, updateFoodBlockSchedule,
  getFoodblockDocuments, updateFoodBlockDocuments,
  getFoodblockOrganizations, updateFoodBlockOrganizations,
  getFoodblockGeneral, updateFoodBlockGeneral
} from '@/router/routes/admin.js'

export default {
  actions: {
    getFoodBlockPassports(ctx, school_pk) {
      return getFoodBlockPassports({ school_pk: school_pk })
        .then(res => {
          ctx.commit('setFoodBlockPassport', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },


    // General
    getFoodblockGeneral(ctx, id) {
      return getFoodblockGeneral({ id: id })
        .then(res => res.data)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    updateFoodBlockGeneral(ctx, updatedData) {
      return updateFoodBlockGeneral(updatedData)
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },

    // Organizations
    getFoodblockOrganizations(ctx, id) {
      return getFoodblockOrganizations({ id: id })
        .then(res => res.data)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    updateFoodBlockOrganizations(ctx, updatedData) {
      return updateFoodBlockOrganizations(updatedData)
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },

    // documents
    getFoodblockDocuments(ctx, id) {
      return getFoodblockDocuments({ id: id })
        .then(res => res.data)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    updateFoodBlockDocuments(ctx, updatedData) {
      return updateFoodBlockDocuments(updatedData)
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },


    // schedule
    getFoodblockSchedule(ctx, id) {
      return getFoodblockSchedule({ id: id })
        .then(res => res.data)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    updateFoodBlockSchedule(ctx, updatedData) {
      return updateFoodBlockSchedule(updatedData)
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },

    // staff
    getFoodblockStaff(ctx, id) {
      return getFoodblockStaff({ id: id })
        .then(res => res.data)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    updateFoodBlockStaff(ctx, updatedData) {
      return updateFoodBlockStaff(updatedData)
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },

    // household
    getFoodblockHousehold(ctx, id) {
      return getFoodblockHousehold({ id: id })
        .then(res => res.data)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    updateFoodBlockHousehold(ctx, updatedData) {
      return updateFoodBlockHousehold(updatedData)
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },

    // warehouses
    getFoodblockWarehouses(ctx, id) {
      return getFoodblockWarehouses({ id: id })
        .then(res => res.data)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    updateFoodBlockWarehouses(ctx, updatedData) {
      return updateFoodBlockWarehouses(updatedData)
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },

    // types
    getFoodBlockTypes(ctx, form_id) {
      return getFoodBlockTypes({ form_id: form_id })
        .then(res => res.data)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    updateFoodBlockTypes(ctx, updatedData) {
      return updateFoodBlockTypes(updatedData)
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },

    // engineering
    getFoodblockEngineer(ctx, form_id) {
      return getFoodBlockEngineer({ form_id: form_id })
        .then(res => res.data)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    updateFoodBlockEngineer(ctx, updatedData) {
      return updateFoodBlockEngineer(updatedData)
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },

    // transport
    getFoodblockTransport(ctx, form_id) {
      return getFoodBlockTransport({ form_id: form_id })
        .then(res => res.data)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    updateFoodBlockTransport(ctx, updatedData) {
      return updateFoodBlockTransport(updatedData)
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },

    // rooms
    getFoodblockRooms(ctx, id) {
      return getFoodBlockRooms({ id: id })
        .then(res => res.data)
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    updateFoodBlockRooms(ctx, updatedData) {
      return updateFoodBlockRooms(updatedData)
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
  },
  mutations: {
    setFoodBlockPassport(state, passport) {
      state.foodBlockPassport = passport
    }
  },
  getters: {
    foodBlockPassport(state) {
      return state.foodBlockPassport
    }
  },
  state: {
    foodBlockPassport: null
  },
}
