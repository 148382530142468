<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <v-layout
      class="fs-teacher-order"
      row
      mb-3
    >
      <v-flex
        md4
        mb-3
      >
        <h1>{{title}}</h1>
      </v-flex>
      <!--v-flex md9 text-md-right>
        <v-btn flat @click="$router.push({name: 'OrdersCreate'})" class="fs-btn fs-teacher-btn__add">Добавить</v-btn>
      </v-flex-->
    </v-layout>
    <v-layout
      row
      mb-3
      justify-space-between
      text-md-center
      class="fs-header-filter"
    >
      <v-flex md3>
        <DatePickersSmart
          :startDate.sync="dateStart"
          :endDate.sync="dateEnd"
          @changeDate="changeDate"
        />
      </v-flex>
      <v-flex md3>
        <fs-autocomplete
          outline
          height="30"
          label="Тип отчета:"
          class="fs-teacher-complex-autocomplete"
          :items="subreports"
          item-text="text"
          item-value="id"
        ></fs-autocomplete>
      </v-flex>
      <v-flex md3>
        <v-btn
          flat
          class="fs-btn fs-teacher-btn__show v-btn--active"
        >Сформировать</v-btn>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex
        text-md-right
        pt-3
      >
        <v-btn
          flat
          class="fs-btn fs-btn--secondary fs-admin-btn__cancel v-btn--active"
          @click="$router.go(-1)"
        >назад</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { DatePickersSmart } from '@/components/common'
import moment from 'moment'
export default {
  name: 'StudentCategoriesReport',
  components: {
    DatePickersSmart
  },
  data() {
    return {
      title: 'Отчет по категориям питающихся',
      subreports: [
        {
          id: '1',
          text:
            'Отчет по категориям питающихся (в разрезе школ), количество человек',
          link: '/'
        },
        {
          id: '2',
          text: 'Отчет по категориям питающихся (в разрезе муниципалитета)',
          link: '/files/menuprint.xlsx'
        }
      ],
      dateStart: moment()
        .subtract('days', 1)
        .format(Vue.config.format.serverDate),
      dateEnd: moment()
        .add('days', 1)
        .format(Vue.config.format.serverDate)
    }
  },
  methods: {
    changeDate(payload) {}
  }
}
</script>
