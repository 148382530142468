<script>
import Table from '@/ui-components/Table.vue'
import { ConfirmModal } from '@/components/common'
import balancable from '@/mixins/balancable'
import { mapGetters, mapMutations } from 'vuex'
import {
  appointMealOrganizationForSchools,
  fetchPupilBalances
} from '@/router/routes/department'
import store from '@/store'
import { getSchools, getOrganizers } from '@/router/routes/department'
import { cloneDeep } from 'lodash'
import notify from '@/utils/notifications/toastNotification'
import axios from 'axios'

export default {
  name: 'EditingOrganizations',

  components: { Table, ConfirmModal },
  mixins: [balancable],
  props: {
    value: {
      required: true,
      type: Boolean
    },
    width: {
      default: 900
    },
    className: {
      default: 'fs-container'
    }
  },

  data() {
    return {
      expand: false,
      onlyChangesSchools: [],
      changedSchools: [],
      hasChanges: false,
      mealsOrganizations: [],
      mealsOrganizationsLoading: false,
      schoolsMap: new Map(),
      balanceListMap: new Map(),
      stepper: 0,
      title: 'Назначение организатора питания',
      balanceIsLoading: false,
      schoolHeaders: [
        { text: '№п/п', value: 'id' },
        { text: 'Школа', value: 'short_name' },
        { text: 'Организатор питания', value: 'supplier.short_name' },
        { text: 'Действия', sortable: false }
      ],
      schoolChangedHeaders: [
        { text: 'ID', value: 'id' },
        { text: 'Школа', value: 'short_name' },
        { text: 'Текущий Организатор Питания', value: 'supplier.short_name' },
        { sortable: false },
        { text: 'Новый Организатор Питания', value: 'supplier.shortName' },
        { text: 'Сохранение балансов', value: 'saveBalance' },
        { text: 'Подробнее', value: 'expanded', sortable: false }
      ],
      balanceHeaders: [
        { text: 'ID', value: 'id' },
        { text: 'ФИО', value: 'full_name' },
        { text: 'Класс', value: 'school_class' },
        { text: 'Текущий баланс', value: 'balance' },
        { text: 'Итоговый баланс', sortable: false }
      ]
    }
  },
  computed: {
    props() {
      return props
    },
    ...mapGetters(['currentUser', 'setting']),
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    ...mapMutations(['setShowPreloader']),

    hasFirstAppoint(item) {
      return (
        !this.schoolsMap.get(item.id).supplier ||
        !this.schoolsMap.get(item.id).supplier.id
      )
    },

    paintGraph({ balance }, school) {
      const newBalance = school.savedBalance ? balance : 0
      if (newBalance < balance) {
        return 'red lighten-5'
      } else if (newBalance > balance) {
        return 'orange lighten-5'
      } else {
        return 'green lighten-5'
      }
    },

    close() {
      this.show = false
      this.stepper = 1
    },
    async save() {
      await this.sendChanges()

      if (this.setting('archive_report_service')) {
        // TODO После изменения на Backend добавить await
        this.archiveMealsOrganizerChangesForSchool()
      }

      this.close()
    },

    async sendChanges() {
      const payload = {
        data: this.onlyChangesSchools.reduce((acc, item, idx) => {
          acc.push({
            school_id: item.id,
            supplier_id: item.supplier.id,
            saved_balance: item.savedBalance
          })
          return acc
        }, [])
      }

      try {
        const {
          data: { messages }
        } = await appointMealOrganizationForSchools(payload)

        messages.forEach((item) => {
          const schoolName = this.schoolsMap.get(item.school_id).short_name
          if (item.success) {
            notify.success(
              `"${schoolName}" обрабатывается. Обработка может выполняться до 1 ч.`
            )
          } else {
            const errorMessage = Object.entries(item.message)
              .map(([k, v]) => `${k} - ${v}`)
              .join(', ')

            notify.error(`"${schoolName}". ${errorMessage}`)
          }
        })

        this.$emit('update')
      } catch (e) {
        if (Array.isArray(e.messages)) {
          e.messages.forEach((item) => {
            const schoolName = this.schoolsMap.get(item.school_id).short_name
            const errorMessage = Object.entries(item.message)
              .map(([k, v]) => `${k} - ${v}`)
              .join(', ')

            notify.error(`"${schoolName}". ${errorMessage}`)
          })
        } else {
          notify.error(e.message)
        }
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async getSchools(key, value) {
      const payload = {
        department_id: this.currentUser.department.id
      }

      try {
        const {
          data: { data }
        } = await getSchools(payload)
        this.changedSchools = cloneDeep(data)
        data.forEach((school) => this.schoolsMap.set(school.id, school))
      } catch (e) {
        notify.error(e.message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async getMealsOrganizations() {
      const payload = {
        department_id: this.currentUser.department.id
      }
      this.mealsOrganizationsLoading = true
      try {
        const {
          data: { data }
        } = await getOrganizers(payload)
        this.mealsOrganizations = [
          { shortName: 'Не назначен', id: null },
          ...data
        ]
      } catch (e) {
        notify.error(e.message)
      } finally {
        this.setShowPreloader(false)
        this.mealsOrganizationsLoading = false
      }
    },

    async getPupilBalanceBySchool() {
      this.balanceIsLoading = true
      const payload = {
        params: {
          school_ids: this.onlyChangesSchools.map((item) => item.id)
        }
      }

      try {
        const { data } = await fetchPupilBalances(payload)
        data.forEach((school) => this.balanceListMap.set(school.id, school))
      } catch (e) {
        notify.error(e.message)
      } finally {
        this.setShowPreloader(false)
        this.balanceIsLoading = false
      }
    },

    changeSchool(item, v) {
      const schoolMap = this.schoolsMap.get(item.id)
      if ((schoolMap.supplier ? schoolMap.supplier?.id : null) !== v.id) {
        item.changed = true
        if (this.hasFirstAppoint(item) || !item.supplier.id) {
          item.savedBalance = false
          item.balanceSavingSwitchDisabled = true
        } else {
          item.savedBalance = true
          'balanceSavingSwitchDisabled' in item &&
            delete item.balanceSavingSwitchDisabled
        }
      } else {
        delete item.changed
        delete item.savedBalance
      }
    },
    getOnlyChangedSchools() {
      this.onlyChangesSchools = this.changedSchools.filter(
        (item) => item.changed
      )
      this.hasChanges = this.onlyChangesSchools?.length > 0
    },

    // TODO Переписать после изменения Archive на Backend
    async archiveMealsOrganizerChangesForSchool() {
      const payload = this.onlyChangesSchools.reduce((acc, cur, idx) => {
        cur.id
        acc.push(
          {
            supplier_id: this.schoolsMap.get(cur.id).supplier?.id || null,
            school_id: cur.id,
            action: 'REFUSE'
          },
          {
            supplier_id: cur.supplier.id,
            school_id: cur.id,
            action: 'ASSIGN'
          }
        )
        return acc
      }, [])

      try {
        await axios.post(
          `${process.env.VUE_APP_ARCHIVE_URL}/school/supplier/`,
          payload
        )
      } catch (e) {
        console.error(e.message)
      } finally {
        this.setShowPreloader(false)
      }
    }
  },

  watch: {
    show: {
      deep: true,
      async handler(v) {
        switch (v) {
          case true: {
            await this.getSchools()
            await this.getMealsOrganizations()
            this.balanceListMap.clear()
            break
          }
          case false: {
            this.balanceListMap.clear()
            this.changedSchools = []
            break
          }
        }
      }
    },

    changedSchools: {
      deep: true,
      handler(v) {
        this.getOnlyChangedSchools()
      }
    },

    stepper: {
      deep: true,
      async handler(v) {
        switch (v) {
          case 1: {
            this.balanceListMap.clear()
            break
          }
          case 2: {
            this.getOnlyChangedSchools()
            await this.getPupilBalanceBySchool()
            break
          }
        }
      }
    }
  }
}
</script>

<template>
  <v-dialog v-model="show" :max-width="width" persistent>
    <v-card>
      <v-card-title>
        <div class="headline">{{ title }}</div>
        <v-spacer />
        <v-btn icon class="fs-btn fs-btn--secondary" @click="close"
          ><v-icon>close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text class="py-0">
        <v-stepper v-model="stepper">
          <v-stepper-header class="elevation-3 mb-1">
            <v-stepper-step :complete="stepper > 1" step="1">
              Изменение
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="stepper > 2" step="2">
              Просмотр
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3"> Сохранение </v-stepper-step>
          </v-stepper-header>

          <v-stepper-content step="1" class="pa-0 mt-1">
            <v-flex class="table-container pa-0">
              <v-data-table
                :headers="schoolHeaders"
                :items="changedSchools"
                item-key="id"
                hide-actions
                class="custom-data-table"
              >
                <template v-slot:items="props">
                  <tr>
                    <td class="px-2 body-2">{{ props.item.id }}</td>
                    <td class="px-2 body-2">{{ props.item.short_name }}</td>
                    <td class="px-2" style="min-width: 300px">
                      <v-autocomplete
                        v-model="props.item.supplier"
                        :items="mealsOrganizations"
                        item-text="shortName"
                        item-value="id"
                        label="Организатор питания"
                        box
                        solo
                        dense
                        style="width: 300px"
                        :class="{
                          'cyan lighten-5': !props.item.supplier?.id
                        }"
                        class="ma-1 pa-0 body-2"
                        hide-details
                        return-object
                        @change="($value) => changeSchool(props.item, $value)"
                        :disabled="props.item?.is_changes"
                        :loading="mealsOrganizationsLoading"
                      >
                      </v-autocomplete>
                    </td>
                    <td class="px-2" style="min-width: 150px">
                      <template v-if="props.item?.is_changes">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">help_outline</v-icon>
                          </template>
                          Находится в обработке. <br />
                          Не доступно для изменения
                        </v-tooltip>
                      </template>
                      <v-switch
                        v-if="
                          props.item?.changed && !hasFirstAppoint(props.item)
                        "
                        v-model="props.item.savedBalance"
                        :disabled="props.item?.balanceSavingSwitchDisabled"
                        hide-details
                        color="primary"
                        class="pa-0 ma-0 body-2"
                      >
                        <template v-slot:label>
                          <div class="body-2">
                            <strong
                              :class="
                                props.item.savedBalance
                                  ? 'success--text'
                                  : 'error--text'
                              "
                            >
                              {{
                                props.item.savedBalance
                                  ? 'Сохранить'
                                  : 'Обнулить'
                              }}
                              балансы
                            </strong>
                          </div>
                        </template>
                      </v-switch>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </v-stepper-content>

          <v-stepper-content step="2" class="pa-0">
            <v-flex class="table-container pa-0">
              <v-data-table
                :headers="schoolChangedHeaders"
                :items="onlyChangesSchools"
                hide-actions
                class="custom-data-table"
                :loading="balanceIsLoading"
                no-data-text="Нет данных изменения"
                :expand="expand"
                item-key="id"
              >
                <template v-slot:items="props">
                  <tr
                    @click="props.expanded = !props.expanded"
                    :class="{ 'th-active': props.expanded }"
                  >
                    <td class="text-center px-2">{{ props.item.id }}</td>
                    <td class="px-2">{{ props.item.short_name }}</td>

                    <td
                      class="px-2"
                      :class="{
                        'info--text font-weight-bold': !schoolsMap.get(
                          props.item.id
                        ).supplier?.id
                      }"
                    >
                      {{
                        schoolsMap.get(props.item.id).supplier?.short_name ||
                        schoolsMap.get(props.item.id).supplier?.shortName ||
                        'Не назначен'
                      }}
                    </td>
                    <td class="px-1">
                      <v-icon color="blue-grey"
                        >keyboard_double_arrow_right</v-icon
                      >
                    </td>
                    <td
                      class="px-2"
                      :class="{
                        'info--text font-weight-bold': !props.item.supplier?.id
                      }"
                    >
                      {{
                        props.item.supplier?.short_name ||
                        props.item.supplier?.shortName ||
                        'Не назначен'
                      }}
                    </td>
                    <td class="text-center px-2">
                      <strong
                        class="font-weight-bold"
                        :class="
                          props.item.savedBalance
                            ? 'success--text'
                            : 'error--text'
                        "
                      >
                        {{ props.item.savedBalance ? 'Да' : 'Нет' }}
                      </strong>
                    </td>
                    <td class="text-center px-2">
                      <v-icon>{{
                        props.expanded ? 'expand_less' : 'expand_more'
                      }}</v-icon>
                    </td>
                  </tr>
                </template>
                <template v-slot:expand="props">
                  <v-flex class="gray ma-1 pa-1 elevation-3">
                    <v-alert
                      :value="
                        hasFirstAppoint(props.item) &&
                        balanceListMap
                          .get(props.item.id)
                          ?.pupil_balance.filter((i) => i.balance !== 0)
                          ?.length > 0
                      "
                      type="error"
                    >
                      ИЗМЕНЕНИЕ ДАННОЙ ШКОЛЫ НЕВОЗМОЖНО, ПОКА НЕ ОБНУЛЕНЫ ВСЕ
                      БАЛАНСЫ УЧЕНИКОВ
                    </v-alert>
                    <v-divider></v-divider>
                    <v-data-table
                      :headers="balanceHeaders"
                      :items="
                        balanceListMap
                          .get(props.item.id)
                          ?.pupil_balance.filter((i) => i.balance !== 0)
                      "
                      hide-actions
                      class="custom-data-table"
                      :loading="balanceIsLoading"
                      no-data-text="Нет данных изменения балансов"
                    >
                      <template v-slot:items="propsExpand">
                        <tr>
                          <td class="px-2">{{ propsExpand.item.id }}</td>
                          <td class="px-2">
                            {{ propsExpand.item.full_name }}
                          </td>
                          <td class="px-2">
                            {{ propsExpand.item.school_class }}
                          </td>
                          <td class="px-2">
                            {{
                              convertToRubles(
                                propsExpand.item.balance
                              ).toLocaleString('ru-RU')
                            }}
                            руб.
                          </td>

                          <td
                            class="px-2"
                            :class="paintGraph(propsExpand.item, props.item)"
                          >
                            {{
                              convertToRubles(
                                props.item.savedBalance
                                  ? propsExpand.item.balance
                                  : 0
                              ).toLocaleString('ru-RU')
                            }}
                            руб.
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <v-divider></v-divider>
                  </v-flex>
                </template>
              </v-data-table>
            </v-flex>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-flex xs12 text-xs-center>
              <v-btn color="primary" large @click="save"
                >Сохранить</v-btn
              ></v-flex
            >
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn v-if="stepper > 1" @click="stepper--">Назад</v-btn>
        <v-spacer />
        <v-btn
          v-if="stepper < 3"
          color="primary"
          @click="stepper++"
          :disabled="balanceIsLoading || !hasChanges"
        >
          Далее
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.table-container {
  max-height: 450px;
  height: min-content;
  overflow-y: auto;
  background: transparent;
}

.tr-warning {
  background: #f44336;
  color: white;
}

.custom-chip {
  align-items: center;
  border-radius: 28px;
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  font-size: 13px;
  margin: 4px;
  outline: none;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  padding: 4px 12px;
}

.custom-data-table table.v-table thead th:first-child,
.custom-data-table table.v-table thead th:not(:nth-child(1)) {
  white-space: pre-wrap;
  padding: 2px 8px;
}

.th-active {
  background-color: #cfd8dc;
}
</style>
