<template>
  <v-container fluid class="fs-organizer-container">
    <v-card flat v-if="poll_detail.id">
      <v-card-title style="display: flex; justify-content: space-between;">
        <h1>{{syrvey_name}}</h1>
        <div style="display: flex; align-items: center;">
          <span style="color: gray; margin-right: 8px;">{{transformDate(poll_detail.created_at)}}</span>
          <a :href="linkPollResult"
            target="_blank"
            class="fs-btn fs-btn--primary fs-btn__add-dish"
            style="text-decoration: none; display: block; padding: 7px 13px !important;"
          >Скачать EXCEL</a>
        </div>
        
      </v-card-title>

      <v-list subheader two-line v-for="question in poll_detail.result.questions" :key="question.id" >

        <v-subheader v-if="!question.is_comment">{{question.name}}</v-subheader>

        <v-textarea
          v-if="question.is_comment"
          name="input-7-1"
          :label="question.name"
          class="px-3"
          v-model="question.answer"
          readonly
        />

        <div style="display: flex; flex-wrap: wrap;" v-else>
          <v-list-tile v-for="answer in question.variants" :key="answer.id">
            <v-checkbox
              v-if="question.is_several"
              :label="answer.name"
              :input-value="question.answer.includes(answer.id)"
              @change="(e) => handleChangeInput(question, answer.id, e)"
              readonly
            />

            <v-radio-group v-else v-model="question.answer">
              <v-radio :label="answer.name" :value="answer.id" readonly></v-radio>
            </v-radio-group>
          </v-list-tile>
        </div>
      </v-list>

    </v-card>
    <v-card v-else>
      Ошибка загрузки
    </v-card>

  </v-container>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'AdminSyrveyDetail',
  data: () => ({
    poll_id: null,
    server: Vue.config.server
  }),
  computed: {
    ...mapGetters([ 'poll_detail' ]),
    linkPollResult() {
      return `${Vue.config.server}/api/polls/report/?poll_pk=${this.poll_id}`
    },
    syrvey_name() {
      if (this.poll_detail && this.poll_detail.result) {
        return this.poll_detail.result.name
      }
      return ''
    }
  },
  methods: {
    ...mapActions([ 'getPollDetail' ]),
    ...mapMutations([ 'set_poll_detail' ]),
    transformDate(date) {
      return moment(date).format(Vue.config.format.date)
    },

    handleChangeInput(question, answerId, e) {
      const findIdx = question.answer.findIndex(val => val === answerId)
      findIdx >= 0 
        ? question.answer.splice(findIdx, 1)
        : question.answer.push(answerId)
    }
  },
  async mounted() {
    this.poll_id = this.$route.params.poll_id
    if (!this.poll_id) this.$router.push({ name: 'Syrvey' })
    else await this.getPollDetail(this.poll_id)
  },
  beforeDestroy() {
    this.set_poll_detail({})
  }
}
</script>