<template>
    <v-form ref="userDetailForm" v-model="valid">
      <v-card>
        <v-toolbar elevation="1" dense light>
          <v-toolbar-title>
            {{!this.is_create ? `Редактировать пользователя`: `Создать пользователя`}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.prevent="$emit('close')">
            <v-icon class="fs-active-icon">close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text style="padding: 0px 15px; margin-top: 15px;">
            <v-text-field label="Имя пользователя" :value="one_user.username" readonly />

            <div style="display: flex; align-items: center; justify-content: space-between;">
              <v-switch :value="one_user.is_active" label="Активен" readonly/>
              <v-switch :value="one_user.is_staff" label="Статус персонала" readonly/>
            </div>

            <v-combobox
              v-model="chips"
              :items="all_roles_to_array"
              label="Выберите роль"
              chips
              clearable
              prepend-icon="filter_list"
              solo
              multiple
              :menu-props="{ minWidth: '250', maxHeight: '600' }"
              :rules="[rules.required]"
              class="user-detail__select"
            >
              <template v-slot:selection="data">
                <v-chip
                  :selected="data.selected"
                  small
                  close
                  @input="remove(data.item)"
                >
                  <strong>{{ data.item }}</strong>&nbsp;
                  <span>(interest)</span>
                </v-chip>
              </template>
            </v-combobox>
            <v-alert
              :value="true"
              color="error"
              icon="warning"
              outline
            >
              Внимание удаление следующих ролей ['SUPPLIER', 'TEACHER', 'PARENT'] у пользователя приведёт к его деактивации
            </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            flat
            class="fs-btn v-btn--active"
            @click="updateRoles"
          >Cохранить</v-btn>

          <v-spacer></v-spacer>

          <v-btn
            flat
            class="fs-btn v-btn--active"
            @click="$emit('close')"
          >Отменить</v-btn>
        </v-card-actions>
        </v-card>
      </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: [ 'user_id', 'is_create' ],
  data: () => ({
    valid: true,
    chips: [],
    rules: {
      required: (v) => !!v || "Поле обязательное для заполнения"
    },
  }),
  async mounted() {
    if (this.user_id) {
      await this.fetchOneUser({ user_id: this.user_id })
      this.fillActiveRoles()
    }
  },
  destroyed() {
    this.clear_one_user()
  },
  methods: {
    ...mapActions( [ 'fetchOneUser', 'updateUserRoles' ] ),
    ...mapMutations( [ 'clear_one_user' ] ),
    remove (item) {
      this.chips.splice(this.chips.indexOf(item), 1)
      this.chips = [ ...this.chips ]
    },
    fillActiveRoles() {
      this.chips = this.one_user.roles.map(role => role.name)
    },
    prepareRolesForApi() {
      const result = []
      this.chips.forEach(rolename => {
        this.all_roles.forEach(role_common => {
          if (rolename === role_common.name) {
            result.push(role_common.id)
          }
        })
      })
      return result
    },
    updateRoles() {
      const prepareRolesForApi = this.prepareRolesForApi()
      this.updateUserRoles({ user_id: this.user_id, data: { user: { roles: prepareRolesForApi } } })
        .then(() => this.$emit('close'))
    }
  },
  computed: {
    ...mapGetters( [ 'one_user', 'all_roles' ] ),

    all_roles_to_array() {
      /*
        Если редактируемый user имеет подроли (даём возможность работать только с ролями SUB_ исключая другие), 
        Если у редактируемого user нет ролей SUB_, тогда ему можно присвоить любую роль кроме SUB_
      */
      const result = this.all_roles.filter(r => r.id !== null).map(r => r.name)
      const userExistSubrolesOP = this.chips.findIndex(r => r.includes('SUB_')) >= 0
      return userExistSubrolesOP 
        ? result.filter(r => r.indexOf('SUB_') >= 0)
        : result.filter(r => r.indexOf('SUB_') < 0)
    }
  }
}
</script>

<style>
.user-detail__select .v-select__selections {
  height: auto !important;
}
</style>
