<template>
  <v-container fluid grid-list-md>
    <v-layout row mb-3>
      <v-flex mb-3>
        <h1>Лог создания заявок</h1>
      </v-flex>
    </v-layout>
    <v-layout wrap mb-12 align-center>
      <v-flex xs12 sm6 md4 lg3>
        <v-text-field v-model="filters.pupilId" label="ID ученика" />
      </v-flex>
      <v-flex xs12 sm6 md4 lg3>
        <v-text-field v-model="filters.orderId" label="ID заказа" />
      </v-flex>
      <v-flex xs12 sm6 md4 lg3>
        <v-text-field v-model="filters.classId" label="ID класса" />
      </v-flex>
      <v-flex xs12 sm6 md4 lg3>
        <v-btn
          class="v-btn--active"
          flat
          @click="loadMore(true)"
        >Показать</v-btn>
      </v-flex>
    </v-layout>
    <v-layout mb-3 justify-space-around>
      <v-flex>
        <v-data-table
          :headers="headers"
          :pagination.sync="pagination"
          :items="items"
          hide-actions
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.createdAt }}</td>
              <td>{{ props.item.pupil.id}}: {{ props.item.pupil.fullName }}</td>
              <td>
                <span v-if="props.item.order">
                  id: {{ props.item.order.id }}, date: {{ props.item.order.orderDay }}
                </span>
              </td>
              <td>
                <span v-if="props.item.school_class">
                  {{ props.item.school_class.id }}: {{ props.item.school_class.name }}
                </span>
              </td>
              <td style="min-width: 300px; white-space: pre-line;">{{ props.item.message }}</td>
              <td>
                <span v-if="props.item.author">
                  {{ props.item.author.id }}: {{ props.item.author.roles }}
                </span>
              </td>
              <td>{{ props.item.context }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import { getOrderDefaultLog } from '@/router/routes/superadmin'
import { mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import moment from 'moment'
import scrollable from "@/mixins/scrollable"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'SuperadminOrderLogDefaultIndex',
  mixins: [ scrollable ],
  data() {
    return {
      pagination: {
        rowsPerPage: -1
      },
      headers: [
        { text: 'Дата', value: 'created_at', sortable: false },
        { text: 'Ученик', value: 'pupil', sortable: false },
        { text: 'Заказ', value: 'order', sortable: false },
        { text: 'Класс', value: 'class', sortable: false },
        { text: 'Тип', value: 'message', sortable: false },
        { text: 'Автор', value: 'author', sortable: false },
        { text: 'Контекст', value: 'context', sortable: false },
      ],
      items: [],
      filters: {
        schoolId: null,
        classId: null,
        orderId: null,
      },
      limit: Vue.config.general.limit,
    }
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler)
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async loadMore(reset) {
      if (reset) {
        this.items = []
        this.offset = 0
      }
      try {
        const payload = {
          params: {
            school_class_pk: this.filters.classId || null,
            pupil_pk: this.filters.pupilId || null,
            order_pk: this.filters.orderId || null,
            limit: this.limit,
            offset: this.offset
          }
        }
        const response = await getOrderDefaultLog(payload)
        const itemsArray = _.map(response.data.data, item => {
          return {
            ...item,
            createdAt: moment(item.created_at).format(Vue.config.format.serverDateFull)
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        // notify.error('Не удалось загрузить лог') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
