import { getAllUsers, getOneUser, updateUserRole, getRoles } from '@/router/routes/admin'
import {loginRoles} from "@/router/routes/common";

export default {
  actions: {
    async fetchAvailableRoles(ctx, payload) {
      await loginRoles( payload ).then(res => {
        ctx.commit('set_available_roles', res.data.roles)
      })
    },
    async fetchRoles(ctx, payload) {
      await getRoles( payload )
        .then(res => {
          ctx.commit('set_all_roles', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async fetchAllUsers(ctx, payload) {
      await getAllUsers( {
        ...payload,
        params: { ...ctx.getters.users_filters, ...ctx.getters.users_pagination }
      } )
        .then(res => {
          ctx.commit('set_all_users', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async fetchOneUser(ctx, payload) {
      await getOneUser(payload)
        .then(res => {
          ctx.commit('set_one_user', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async updateUserRoles(ctx, payload) {
      return await updateUserRole(payload)
        .then(res => {
          ctx.commit('set_one_user', res.data)
          return Promise.resolve()
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    }
  },
  mutations: {
    set_available_roles(state, data) {
      state.available_roles = data
    },
    set_active_role(state, data) {
      state.active_role = data
    },
    set_all_users(state, data) {
      state.users = data
    },
    set_one_user(state, data) {
      state.one_user = data
    },
    clear_one_user(state, data) {
      state.one_user = {
        id: null,
        username: '',
        roles: [],
        is_staff: false,
        is_active: false
      }
    },
    set_users_filters(state, data) {
      state.users_filters = {
        ...state.users_filters,
        ...data
      }
    },
    set_users_pagination(state, data) {
      state.pagination = {
        ...state.pagination,
        ...data
      }
    },
    set_all_roles(state, data) {
      state.all_roles = [
        { id: null, name: 'Нет' },
        ...data
      ]
    },
  },
  getters: {
    all_users(state) {
      return state.users
    },
    one_user(state) {
      return state.one_user
    },
    users_filters(state) {
      return state.users_filters
    },
    users_pagination(state) {
      return state.pagination
    },
    all_roles(state) {
      return state.all_roles
    },
    available_roles(state) {
      return state.available_roles
    },
    active_role(state) {
      return state.active_role
    }
  },
  state: {
    users: {
      count: 0,
      data: []
    },
    available_roles: [],  // список ролей, полученнных из ЭШ
    active_role: null,  // текущая роль, которую пользователь выбрал при авторизации (если у него несколько)
    all_roles: [
      { id: null, name: 'Нет' }
    ],
    one_user: {
      id: null,
      username: '',
      roles: [],
      is_staff: false,
      is_active: false
    },
    users_filters: {
      search: null,
      role: null
    },
    pagination: {
      page: 1,
      page_size: 10
    }
  },
}
