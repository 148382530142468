<template>
  <div class="py-2">
    <v-card>
      <v-card-text class="px-0 pb-0 pt-1">
        <div class="d-flex justify-space-between">
          <v-text-field
            solo
            v-model="search"
            label="Поиск по таблице"
            class="mb-1 ml-3"
            style="max-width: 400px"
            hide-details
          />
          <div class="d-flex justify-end">
            <v-btn
              :disabled="selectedMultiplePupils.length === 0"
              class="mb-2"
              color="primary"
              @click="showModalEditingMealType"
              style="min-width: 200px; max-width: fit-content"
            >
              Изменить тип питания
            </v-btn>
            <v-btn
              :disabled="selectedMultiplePupils.length === 0"
              class="mb-2"
              color="primary"
              @click="showDialogResetMealType"
              style="min-width: 150px; max-width: fit-content"
            >
              Восстановить тип питания
            </v-btn>
          </div>
        </div>

        <v-data-table
          :headers="headers"
          :items="pupils"
          v-model="selectedMultiplePupils"
          :rows-per-page-items="[10, 25, 50]"
          item-key="id"
          :search="search"
          select-all
          class="elevation-1"
        >
          <template v-slot:items="props">
            <tr @click.spop="selectedItem(props)">
              <td>
                <v-checkbox :value="props.selected" hide-details />
              </td>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.fullName }}</td>
              <td class="text-center">{{ props.item.schoolClass.number }}</td>
              <td class="text-center">
                {{ props.item.schoolClass.school.name }}
              </td>

              <td class="text-center">
                {{ props.item.foodType?.name || '&mdash;' }}
              </td>
              <td class="text-right">
                {{ formatMoney(props.item.balance) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <superadmin-modal-editing-meal-type
      v-if="isShowModalEditingMealType.length !== 0"
      :show-modal="isShowModalEditingMealType"
      :pupils="selectedMultiplePupils"
      v-on:closeModal="closeShowModalEditingMealType"
    />
    <superadmin-dialog-reset-meal-type
      v-if="isShowModalEditingMealType.length !== 0"
      :show-modal="isShowDialogResetMealType"
      :pupils="selectedMultiplePupils"
      v-on:closeModal="closeShowDialogResetMealType"
      v-on:updatePupil="(data) => $emit('updatePupil', data)"
    />
  </div>
</template>

<script>
import numable from '@/mixins/balancable'
import SuperadminModalEditingMealType from '@/components/moduleadmin/PupilList/Components/ModalEditingMealType.vue'
import SuperadminDialogResetMealType from '@/components/moduleadmin/PupilList/Components/DialogResetMealType.vue'
import balancable from '@/mixins/balancable'

export default {
  name: 'SuperadminPupilTable',

  props: {
    pupils: {
      type: Array
    }
  },

  mixins: [numable, balancable],

  components: {
    SuperadminModalEditingMealType,
    SuperadminDialogResetMealType
  },

  data() {
    return {
      isShowModalEditingMealType: false,
      isShowDialogResetMealType: false,
      selectedPupil: null,
      selectedMultiplePupils: [],
      search: ''
    }
  },

  mounted() {
    this.selectedMultiplePupils = []
  },

  methods: {
    showModalEditingMealType() {
      this.isShowModalEditingMealType = true
    },

    showDialogResetMealType() {
      this.isShowDialogResetMealType = true
    },

    closeShowModalEditingMealType() {
      this.isShowModalEditingMealType = false
    },

    closeShowDialogResetMealType() {
      this.isShowDialogResetMealType = false
    },

    selectedItem({ item }) {
      if (this.selectedMultiplePupils.some((pupil) => pupil.id === item.id)) {
        this.selectedMultiplePupils = this.selectedMultiplePupils.filter(
          (pupil) => pupil.id !== item.id
        )
      } else {
        this.selectedMultiplePupils.push(item)
      }
    },
    formatMoney(num) {
      return this.convertToRubles(num).toLocaleString('ru-RU')
    }
  },

  computed: {
    headers() {
      return [
        {
          text: 'ID',
          align: 'left',
          value: 'id'
        },
        {
          text: 'Ученик',
          align: 'left',
          value: 'full_name'
        },
        {
          text: 'Класс',
          align: 'center',
          value: 'school_class_name'
        },
        {
          text: 'Школа',
          align: 'center',
          value: 'school_name'
        },
        {
          text: 'Тип питания',
          align: 'center',
          value: 'food_type_name'
        },
        {
          text: 'Баланс, руб',
          align: 'right',
          value: 'food_type_price'
        }
      ]
    }
  }
}
</script>

<style>
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
</style>
