<template>
  <v-container fluid class="fs-teacher-container">
    <v-layout class="fs-table-card-header" row>
      <v-flex md6 mb-5>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout class="fs-teacher-order-result" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1 fs-admin-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.name }}</td>
              <!--<td class="fs-operations">
                <v-flex row>
                  <v-btn flat  class="fs-btn fs-teacher-btn__details">Смотреть</v-btn>
                  <v-btn flat  class="fs-btn fs-teacher-btn__edit">Изменить</v-btn>
                  <v-btn flat  class="fs-btn fs-teacher-btn__delete">Удалить</v-btn>
                </v-flex>
              </td>-->
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex text-md-right pt-3>
        <v-btn
          flat
          class="fs-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active"
          @click="$router.go(-1)"
        >Назад</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "AdminFoodTimes",
  created() {
    this.items = this.foodTimes
  },
  data() {
    return {
      title: "Справочник «Время кормления»",
      headers: [
        { text: "№ п/п", value: "id" },
        { text: "Наименование", value: "name" }
      ],
      items: [],
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10)
    }
  },
  computed: {
    ...mapGetters([ "foodTimes" ])
  }
}
</script>
