<template>
    <v-dialog
      v-model="dialog"
      :fullscreen="true"
      persistent
      no-click-animation
      >
      <v-card>
        <v-container fluid>
          <h2 class="mb-5">Оповещение о плановом обновлении</h2>
          <div v-html="message"></div>
          <div class="text-right">
            <v-btn
                @click="closeDialog"
                :disabled="block"
            > ОК
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </template>
<script>  
export default {
  name: 'AlertUpdateInformation',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: null
    },
  },
  data() {
    return {
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialogEvent')
    }
  }
}
</script>
  <style>
  .text-right {
    text-align: right;
  }
  </style>