<template>
  <v-container grid-md-list>
    <Title layout>Отчёты</Title>
    <v-layout row wrap>
      <v-flex class="sm12 xl12" v-for="(role, key) in roles" :key="key">
        <v-expansion-panel>
          <v-expansion-panel-content>
            <template v-slot:header>
              <h3>{{ role.name }}</h3>
            </template>
            <v-card>
              <v-card-text>
                <v-data-table
                  :items="role.items"
                  :headers="headers"
                  hide-actions
                >
                  <template v-slot:items="props">
                    <tr>
                      <td>{{ reportTitle(props.item) }}</td>
                    </tr>
                    <td>
                      <v-flex row>
                        <with-tooltip text="Скачать отчёт">
                          <v-icon
                            @click="openModalReports(props.item, role.id)"
                            class="fs-active-icon"
                            >download
                          </v-icon>
                        </with-tooltip>
                      </v-flex>
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
      <v-card v-if="showModal && modalReports">
        <ModalReports :item="modalItem" @close="handleCloseModal" />
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import store from '@/store'
import Title from '@/ui-components/Title.vue'
import { getAllReports } from '@/router/routes/superadmin'
import ModalReports from '@/components/moduleadmin/Reports/ModalReports.vue'
import WithTooltip from '@/components/common/Tooltip/Tooltip.vue'

export default {
  name: 'SuperadminReports',
  components: {
    WithTooltip,
    ModalReports,
    Title
  },
  data() {
    return {
      roles: {
        systemAdmin: {
          id: 5,
          name: 'Администратор системы',
          items: []
        },
        superDepartment: {
          id: 7,
          name: 'Супердепартамент',
          items: []
        },
        department: {
          id: 4,
          name: 'Департамент',
          items: []
        },
        supplier: {
          id: 6,
          name: 'Организатор питания',
          items: []
        },
        mealAdmin: {
          id: 1,
          name: 'Администратор питания',
          items: []
        },
        teacher: {
          id: 2,
          name: 'Учитель',
          items: []
        }
      },
      headers: [
        { text: 'Название отчёта', value: 'name' },
        { text: 'Операции', value: 'download' }
      ],
      showModal: false,
      modalReports: false,
      modalItem: []
    }
  },
  async mounted() {
    await this.collectAllReports()
  },
  methods: {
    // report1Upload() {
    //   axios.get('/api/admin/reports/school_status/', {
    //     responseType: 'arraybuffer'
    //   })
    //     .then(data => {
    //       if (data.status === 200) {
    //         const blob = new Blob([ data.data ], {
    //           type: data.headers['content-type'] || 'application/octet-stream'
    //         })
    //         const link = document.createElement('a')
    //         link.href = URL.createObjectURL(blob)
    //         link.click()
    //         URL.revokeObjectURL(link.href)
    //       } else {
    //         errorNotify(data.status)
    //       }
    //     })
    //     .catch(error => {
    //       errorNotify(error.response.status)
    //     })
    //     .then(() => {
    //       store.commit('setShowPreloader', false)
    //     })
    // },

    openModalReports(item, role) {
      this.modalItem = item
      this.modalItem.selected_role = role
      this.modalItem.role_id = role
      this.modalReports = true
      this.showModal = true
    },

    handleCloseModal() {
      this.modalReports = false
      this.showModal = false
    },

    reportTitle(item) {
      return item.name + (item.form_id ? ` (Форма ${item.form_id})` : '')
    },

    async collectAllReports() {
      try {
        const { data } = await getAllReports()
        this.items = data
        Object.entries(this.roles).forEach(([k, v]) => {
          this.roles[k].items = data.filter((item) => item.roles.includes(v.id))
        })
      } catch (error) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>
