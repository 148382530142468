<template>
  <v-container class="fs-teacher-container" fluid grid-list-md>
    <v-layout class="fs-teacher-order-result" wrap>
      <v-flex xs12 mb-5>
        <h1>{{ title }}</h1>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1 fs-admin-table"
          item-key="name"
          hide-actions
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.name }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { getFoodPeriods } from '@/router/routes/common'
import scrollable from '@/mixins/scrollable'
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'MunicipalityFoodPeriods',
  mixins: [ scrollable ],
  created (){
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },
  computed: {
    ...mapGetters([
      'currentUser',
    ]),
  },
  data() {
    return {
      limit: Vue.config.general.limit,
      title: 'Справочник "Периоды кормления"',
      headers: [
        { text: '№ п/п', value: 'id' },
        { text: 'Наименование', value: 'name' },
      ],
      items: [],
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10)
    }
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async loadMore() {
      const payload = {
        limit: this.limit,
        offset: this.offset
      }
      getFoodPeriods(payload)
        .then(response => {
          const items = response.data.data
          this.items = this.items.concat(items)
        })
        .finally(() => store.commit('setShowPreloader', false))
    }
  }
}
</script>
