<template>
  <v-container class="fs-teacher-container" fluid grid-list-md>
    <Title layout>Планировщик смены типов питания</Title>
    <v-layout row wrap>
      <v-flex>
        <v-btn
          class="fs-btn fs-admin-btn__add d-inline ma-0 mr-3"
          @click="$router.push({ name: 'MunicipalityFoodTypes' })"
        >
          Скрыть планировщик
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout class="fs-teacher-order-result mt-3">
      <v-flex>
        <v-data-table
          class="fs-admin-table"
          hide-actions
          disable-initial-sort
          :headers="headers"
          :items="scheduledTasks"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.index + 1 }}</td>
              <td>{{ props.item.date | transformDate }}</td>
              <td>{{ props.item.old_meal_type_name }}</td>
              <td>
                <v-icon :style="props.item.archiving ? 'color: green' : 'color: red' ">
                  {{ props.item.archiving ? "done" : "close" }}
                </v-icon>
              </td>
              <td>{{ props.item.old_meal_type_price !== undefined ? convertToRubles(props.item.old_meal_type_price) : null }}</td>
              <td>{{ props.item.new_meal_type_name }}</td>
              <td>{{ props.item.new_meal_type_price !== undefined ? convertToRubles(props.item.new_meal_type_price) : null }}</td>
              <td>
                <v-icon :style="props.item.new_meal_type !== null ? 'color: green' : 'color: red' ">
                  {{ props.item.new_meal_type !== null ? "done" : "close" }}
                </v-icon>
              </td>
              <td>{{ props.item.created_at | transformDate }}</td>
              <td style="text-align: center">
                  <div class="d-inline-block mx-2" v-if="props.item.new_meal_type === null">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click="removeMealTypeChangesTask(props.item)"
                        >delete_outline</v-icon>
                      </template>
                      <span>Удалить</span>
                    </v-tooltip>
                  </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import {getScheduledMealTypesChanges, removeScheduledMealTypesChange} from "@/router/routes/department"
import Title from "@/ui-components/Title"
import moment from "moment/moment"
import numable from '@/mixins/balancable'
import store from "@/store"
import notify from "@/utils/notifications/toastNotification.vue";

export default {
  name: "FoodTypesItemScheduler",
  mixins: [ numable ],
  components: {
    Title
  },
  data() {
    return {
      headers: [
        { text: '№', value: 'id' },
        { text: 'Запланировано на', value: 'date' },
        { text: 'Старый тип питания', value: 'old_meal_type_name' },
        { text: 'Старый тип питания перенес в архив', value: 'archiving' },
        { text: 'Старая цена', value: 'old_meal_type_price' },
        { text: 'Новая тип питания', value: 'new_meal_type_name' },
        { text: 'Новая цена', value: 'new_meal_type_price' },
        { text: 'Статус', value: 'new_meal_type' },
        { text: 'Создано', value: 'created_at' },
        { text: 'Операции', value: 'actions' }
      ],
      scheduledTasks: [],
      currentDate: moment().add(1, 'day').format('YYYY-MM-DD')
    }
  },
  mounted() {
    this.getScheduledTasks()
  },
  computed: {
    selectedDateComputed() {
      if (this.selectedDate) return moment(this.selectedDate).format('DD-MM-YYYY')
      return ''
    },
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    async getScheduledTasks() {
      const response = await getScheduledMealTypesChanges({
        department_id: this.currentUser.department.id
      })
      this.scheduledTasks = response.data
      store.commit('setShowPreloader', false)
    },
    async removeMealTypeChangesTask(item) {
      const payload = {
        department_id: this.currentUser.department.id,
        schedule_id: item.id
      }
      await removeScheduledMealTypesChange(payload)
      notify.success('Запланированная задача удалена')
      await this.getScheduledTasks()
    }
  }
}

</script>

<style scoped>

</style>

