export default class AdminClassFactory {
  constructor() {
    return {
      id: null,
      name: '',
      teacher: {
        id: null,
        firstName: '',
        middleName: '',
        lastName: '',
        fullName: ''
      }
    }
  }
}
