<template>
  <v-container fluid class="fs-teacher-container">
    <v-layout row wrap>
      <v-flex xs12 pa-4 class="page-head__common">
        <h1>{{ title }}</h1>
        <v-btn
          flat
          @click="() => $router.push({ name: 'CardDetail' })"
          class="fs-btn fs-btn--primary fs-btn__add-dish mr-2"
          >Создать карту для пользователя
        </v-btn>
      </v-flex>
      <v-layout>
        <CardsListTable />
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import CardsListTable from '@/components/admin/Cards/CardsListTable.vue'

export default {
  name: 'Cards',
  components: {
    CardsListTable
  },
  data: () => ({
    darkTheme: Vue.config.env.darkTheme,
    title: 'Карты пользователей'
  })
}
</script>

<style scoped>
.rounded {
  border-radius: 10px;
}
</style>
