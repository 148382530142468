<template>
  <v-dialog v-model="dialog" max-width="1500" persistent no-click-animation>
    <v-card>
      <v-card-title>
        <h3>Заявки на питание {{ studentName }}</h3>
      </v-card-title>

      <v-card-text>
        <div class="mb-2">Внимание! Данный список содержит заказы за 7 дней до и 6 дней после текущего дня.</div>
        <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
            class="elevation-1 fs-admin-table"
            item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.orderDay }}</td>
              <td>{{ props.item.foodType.name ? props.item.foodType.name : 'Резервный тип питания' }}</td>
              <td>{{ props.item.complex.name }}</td>
              <td>{{ props.item.student.isCredit ? (props.item.student.balance - props.item.student.creditAmount) : props.item.student.balance }}</td>
              <td>
                {{ props.item.allPrice }}
                <span v-if="props.item.additionalPrice">
                  (доп. блюда {{ props.item.additionalPrice }})
                </span>
              </td>
              <td>{{ props.item.parentPayment }}</td>
              <td>{{ props.item.privilege }}</td>
              <td>{{ getOrderStatus(props.item.status, props.item.is_auto_status) }}</td>
              <td>
                <v-checkbox
                    :disabled="true"
                    class="fs-checkbox colTableCheckbox"
                    v-model="props.item.visit"
                />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn @click="closeDialog">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getPupilCardOrders } from "@/router/routes/common"
import store from "@/store"
import Vue from "vue"
import numable from '@/mixins/balancable'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: "OrdersViewModal",
  mixins: [ numable ],
  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    studentID: {
      default: 0,
      required: true
    },
    studentName: {
      type: String,
      default: '',
      required: true
    },
    schoolID: {
      type: Number,
      default: 0,
      required: true
    },
  },
  data() {
    return {
      headers: [
        { text: 'Дата', value: 'student.id', sortable: false, width: '10%' },
        { text: 'Тип питания', value: 'food-type.name', sortable: false },
        { text: 'Выбранный комплекс', value: 'complex.name', sortable: false },
        { text: 'Баланс, руб.', value: 'student.balance', sortable: false },
        { text: 'Полная стоимость, руб.', value: 'price', sortable: false },
        { text: 'Доплата родителя, руб.', value: 'parentPayment', sortable: false },
        { text: 'Льгота, руб.', value: 'privilege', sortable: false },
        { text: 'Статус', value: 'status', sortable: false },
        { text: 'Явка', value: 'visit', sortable: false },
      ],
      items: [],
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:dialog', false)
    },
    async getOrdersList() {
      const payload = {
        id: this.studentID,
        school_id: this.schoolID
      }
      try {
        const response = await getPupilCardOrders(payload)
        this.setItems(response.data, true)
      } catch (e) {
        console.log(e)
        // notify.error('Ошибка загрузки заявок') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    setItems(orders, resetItems) {
      const itemsArray = _.map(orders, (item, idx) => {'newForm'
        let addPrice = 0
        if (item.additional_foods) {
          for (const food of item.additional_foods) {
            addPrice += food.price * food.amount
          }
        }
        return {
          idx: idx,
          id: item.id,
          student: {
            id: _.get(item, 'pupil.id', null),
            name: _.get(item, 'pupil.fullName', ''),
            balance: (this.convertToRubles(
              _.get(item, 'pupil.reserved', 0) +
                    _.get(item, 'pupil.available', 0)).toFixed(2)
            ),
            isCredit: item.pupil.isCredit,
            creditAmount: this.convertToRubles(_.get(item, 'pupil.creditAmount', 0))
          },
          orderDay: item.orderDay,
          foodType: {
            name: _.get(item, 'foodType.name', null)
          },
          canChangeFoodType: item.canChangeFoodType,
          trafficDiff: item.traffic && item.traffic.diff_text,
          complex: _.get(item, 'complect', null),
          price: this.convertToRubles(
            _.get(item, 'foodType.price', 0)
          ),
          parentPayment:
              this.roundRubles(this.convertToRubles(_.get(item, 'foodType.price', 0)) -
                  this.convertToRubles(
                    _.get(item, 'privilegePrice', 0)
                  )),
          privilege: this.convertToRubles(
            _.get(item, 'privilegePrice', 0)
          ),
          totalPrice: this.convertToRubles(item.totalPrice),
          allPrice: this.convertToRubles(_.get(item, 'privilegePrice', 0) + item.totalPrice),
          additionalPrice: this.convertToRubles(addPrice),
          visit: this.checkStudetVisit(item),
          status: item.status,
          is_auto_status: _.get(item, 'status_received_is_auto', null),
          pupilId: _.get(item, 'pupil.id', null),
          can_delete_additional_food: item.can_delete_additional_food,
          additional_foods: item.additional_foods ? item.additional_foods : []
        }
      })
      if (resetItems) {
        this.items = itemsArray
        return
      }
      this.items = [ ...this.items, ...itemsArray ]
    },
    checkStudetVisit(item) {
      const itemStatus = _.get(item, 'status', null)
      // const isPupilTransferred = _.get(item, 'isPupilTransferred', false)
      const configCancelStatus = Vue.config.general.orderStatuses.cancel
      const visit = itemStatus !== configCancelStatus

      // if (isPupilTransferred !== null) {
      //   return isPupilTransferred
      // }
      return visit
    },
    getOrderStatus(value, auto) {
      switch (value) {
      case 1: return 'Черновик'
      case 2: return 'Подтвержден учителем'
      case 3: return 'Подтвержден школой'
      case 4: return 'Выдан'
      case 5:
        if (auto)
          return 'Выполнен (без списания)'
        return 'Выполнен'
      case 6: return 'Отменен'
      case 7: return 'Сохранен учителем'
      case 8: return 'Сохранен школой'
      case 9: return 'Подтвержден автоматически'
      case 10: return 'Изменен'
      }
    },
  },
  watch: {
    dialog(value) {
      if (value) {
        this.getOrdersList()
      }
    }
  }
}
</script>

<style scoped>

</style>
