export default {
  /**
   * Проверка на обязательное поле
   * @param {Object} field - Поле формы, содержащее label
   * @returns {Function} Функция валидации, которая проверяет, заполнено ли поле
   */
  required: (field) => (value) => {
    const isValid =
      (typeof value === 'string' && value.trim().length > 0) ||
      (typeof value === 'number' && value > 0) ||
      (typeof value === 'boolean' && value === true) ||
      (Array.isArray(value) && value.length > 0) ||
      (typeof value === 'object' &&
        value !== null &&
        Object.keys(value).length > 0)

    return isValid || `"${field?.label}" обязательноe поле`
  },

  /**
   * Проверка на минимальную длину строки
   * @param {number} length - Минимальная длина
   * @param {Object} field - Поле формы, содержащее label
   * @returns {Function} Функция валидации, которая проверяет минимальную длину строки
   */
  minLength: (length) => (field) => (value) =>
    (value && value.length >= length) ||
    `Поле "${field.label}" должно содержать не менее ${length} символов`,

  /**
   * Проверка на максимальную длину строки
   * @param {number} length - Максимальная длина
   * @param {Object} field - Поле формы, содержащее label
   * @returns {Function} Функция валидации, которая проверяет максимальную длину строки
   */
  maxLength: (length) => (field) => (value) =>
    (value && value.length <= length) ||
    `Поле "${field.label}" должно содержать не более ${length} символов`,

  /**
   * Проверка на минимальную длину массива
   * @param {number} length - Минимальная длина массива
   * @param {Object} field - Поле формы, содержащее label
   * @returns {Function} Функция валидации, которая проверяет минимальную длину массива
   */
  minLengthArr: (length) => (field) => (value) =>
    (value && value.length >= length) ||
    `Поле "${field.label}" должно содержать не менее ${length} элемента`,

  /**
   * Проверка на максимальную длину массива
   * @param {number} length - Максимальная длина массива
   * @param {Object} field - Поле формы, содержащее label
   * @returns {Function} Функция валидации, которая проверяет максимальную длину массива
   */
  maxLengthArr: (length) => (field) => (value) =>
    (value && value.length <= length) ||
    `Поле "${field.label}" должно содержать не более ${length} элементов`,

  /**
   * Проверка на совпадение паролей
   * @param {Object} compareField - Поле формы для сравнения
   * @param {Object} field - Поле формы, содержащее label
   * @returns {Function} Функция валидации, которая проверяет совпадение значений полей
   */
  passwordsMatch: (compareField) => (field) => (value) =>
    !compareField.value ||
    compareField.value?.length === 0 ||
    value === compareField.value ||
    `"${field.label}" должен совпадать с "${compareField.label}"`,

  /**
   * Проверка на наличие только латинских букв и цифр
   * @param {Object} field - Поле формы, содержащее label
   * @returns {Function} Функция валидации, которая проверяет, содержит ли строка только латинские буквы и цифры
   */
  containsLatinAndDigit: (field) => (value) =>
    /^[a-zA-Z0-9]+$/.test(value) ||
    `"${field.label}" должен содержать только латинские буквы и цифры`,

  /**
   * Проверка сложности пароля
   * @param {Object} field - Поле формы, содержащее label
   * @returns {Function} Функция валидации, которая проверяет, содержит ли пароль заглавные и строчные буквы, цифры и специальные символы
   */
  passwordComplexity: (field) => (value) => {
    const hasUpperCase = /[A-Z]/.test(value)
    const hasLowerCase = /[a-z]/.test(value)
    const hasDigit = /\d/.test(value)
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(value)
    return (
      (hasUpperCase && hasLowerCase && hasDigit && hasSpecial) ||
      `"${field.label}" должен содержать заглавные и строчные буквы, цифры и специальные символы`
    )
  },

  /**
   * Проверка на валидность числа с плавающей точкой
   * @param {Object} field - Поле формы, содержащее label
   * @returns {Function} Функция валидации, которая проверяет, является ли значение числом с плавающей точкой
   */
  isFloat: (field) => (value) => {
    const isValid = !isNaN(value) && parseFloat(value) === +value
    return isValid || `"${field.label}" должно быть числом с плавающей точкой`
  },

  /**
   * Проверка на валидность положительного целого числа в десятичном формате
   * @param {Object} field - Поле формы, содержащее label
   * @returns {Function} Функция валидации, которая проверяет, является ли значение положительным целым числом
   */
  numberDec: (field) => (value) => {
    const hasDigit = /^\d+$/.test(value)
    return (
      hasDigit || `"${field.label}" должно быть числом в десятичном формате`
    )
  },
  /**
   * Проверка на валидность числа в шестнадцатеричном формате
   * @param {Object} field - Поле формы, содержащее label
   * @returns {Function} Функция валидации, которая проверяет, является ли значение числом в шестнадцатеричном формате
   */
  numberHex: (field) => (value) => {
    const isValidHex = /^[0-9a-fA-F]+$/.test(value)
    return (
      isValidHex ||
      `"${field.label}" должно быть числом в шестнадцатеричном формате`
    )
  },

  /**
   * Проверка на валидность положительного целого числа в десятичном формате,
   * и возможности его преобразования в BigInt с учетом пределов.
   * @param {Object} field - Поле формы, содержащее label
   * @returns {Function} Функция валидации, которая проверяет, является ли значение допустимым для BigInt
   */
  numberDecBig: (field) => (value) => {
    const hasDigit = /^\d+$/.test(value)
    if (!hasDigit) {
      return `"${field.label}" должно быть числом в десятичном формате`
    }
    try {
      const bigIntValue = BigInt(value)
      const maxBigIntValue = BigInt('9223372036854775807') // Максимальное значение для BigInt (примерно 2^63 - 1)

      if (bigIntValue >= maxBigIntValue) {
        return `"${
          field.label
        }" не должно превышать ${maxBigIntValue.toString()}`
      }

      return true
    } catch (e) {
      return `"${field.label}" слишком велико для обработки`
    }
  },

  /**
   * Проверка на валидность положительного числа в шестнадцатеричном формате
   * и возможности его преобразования в BigInt с учетом пределов.
   * @param {Object} field - Поле формы, содержащее label
   * @returns {Function} Функция валидации, которая проверяет, является ли значение допустимым для BigInt
   */
  numberHexBig: (field) => (value) => {
    const isValidHex = /^[0-9a-fA-F]+$/.test(value)
    if (!isValidHex) {
      return `"${field.label}" должно быть числом в шестнадцатеричном формате`
    }
    try {
      const bigIntValue = BigInt(`0x${value}`)
      const maxBigIntValue = BigInt('0x7FFFFFFFFFFFFFFF') // Максимальное значение для BigInt в шестнадцатеричном формате (примерно 2^63 - 1)

      if (bigIntValue >= maxBigIntValue) {
        return `"${field.label}" не должно превышать ${maxBigIntValue
          .toString(16)
          .toUpperCase()}`
      }

      return true
    } catch (e) {
      return `"${field.label}" слишком велико для обработки`
    }
  }
}
