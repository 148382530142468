import SuperMunicipalityStartPage from '@/components/supermunicipality/StartPage/Index'
import SuperMunicipalityReportsIndex from '@/components/supermunicipality/Reports/Index'
import SuperMunicipalityQualityControlWrap from '@/components/common/QualityControl/Index'
import SuperMunicipalityFoodMealPassports from '@/components/supermunicipality/FoodMealPassports/Index'
import SuperMunicipalitySyrveyResult from '@/components/admin/Syrvey/SyrveyResult'
import SuperDepartmentSyrveyDetail from '@/components/admin/Syrvey/AdminSyrveyDetail'
import UserHelp from "@/components/common/UserHelp/Index.vue"
import HelpDesk from "@/components/common/HelpDesk/HelpDesk.vue"

export default [
  {
    path: '/',
    name: 'Supermunicipality',
    component: SuperMunicipalityStartPage
  },
  {
    path: 'reports',
    name: 'SuperMunicipalityReportsIndex',
    component: SuperMunicipalityReportsIndex
  },
  {
    path: 'user-help',
    name: 'SuperMunicipalityUserHelp',
    component: UserHelp
  },
  {
    path: 'helpdesk',
    name: 'HelpDeskSMU',
    component: HelpDesk,
    meta: { userRole: 'department_admin' }
  },
  {
    path: 'quality-control',
    name: 'SuperMunicipalityQualityControlWrap',
    component: SuperMunicipalityQualityControlWrap
  },
  {
    path: 'foodmeal-passports',
    name: 'SuperMunicipalityFoodMealPassports',
    component: SuperMunicipalityFoodMealPassports
  },
  {
    path: 'syrvey-result',
    name: 'SuperMunicipalSyrveyResult',
    component: SuperMunicipalitySyrveyResult,
    props: { user_role: 'is_department_admin' }
  },
  {
    path: 'syrvey-detail',
    name: 'SuperDepartmentSyrveyDetail',
    component: SuperDepartmentSyrveyDetail,
    props: { user_role: 'is_department_admin' }
  },
]
