export default {
  red: {
    header: {
      bg: '#D32F2F',
      text: '#fff'
    },
    launcher: {
      bg: '#D32F2F'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      bg: '#F44336',
      text: '#fff'
    },
    receivedMessage: {
      bg: '#eaeaea',
      text: '#222222'
    },
    userInput: {
      bg: '#fff',
      text: '#212121'
    },
    userList: {
      bg: '#fff',
      text: '#212121'
    },
    chatCard: {
      bg: '#F44336',
      text: '#fff',
      basic: '#eaeaea'
    }
  },
  green: {
    header: {
      bg: '#388E3C',
      text: '#fff'
    },
    launcher: {
      bg: '#388E3C'
    },
    messageList: {
      bg: '#fff'
    },
    sentMessage: {
      bg: '#4CAF50',
      text: '#fff'
    },
    receivedMessage: {
      bg: '#eaeaea',
      text: '#222222'
    },
    userInput: {
      bg: '#fff',
      text: '#212121'
    },
    userList: {
      bg: '#fff',
      text: '#212121'
    },
    chatCard: {
      bg: '#4CAF50',
      text: '#fff',
      basic: '#eaeaea'
    }
  },
  blue: {
    header: {
      bg: '#4e8cff',
      text: '#ffffff'
    },
    launcher: {
      bg: '#4e8cff'
    },
    messageList: {
      bg: '#ffffff'
    },
    sentMessage: {
      bg: '#4e8cff',
      text: '#ffffff'
    },
    receivedMessage: {
      bg: '#eaeaea',
      text: '#222222'
    },
    userInput: {
      bg: '#f4f7f9',
      text: '#565867'
    },
    userList: {
      bg: '#fff',
      text: '#212121'
    },
    chatCard: {
      bg: '#4e8cff',
      text: '#ffffff',
      basic: '#eaeaea'
    }
  },
  dark: {
    header: {
      bg: '#34495e',
      text: '#ecf0f1'
    },
    launcher: {
      bg: '#34495e'
    },
    messageList: {
      bg: '#2c3e50'
    },
    sentMessage: {
      bg: '#7f8c8d',
      text: '#ecf0f1'
    },
    receivedMessage: {
      bg: '#95a5a6',
      text: '#ecf0f1'
    },
    userInput: {
      bg: '#34495e',
      text: '#ecf0f1'
    },
    userList: {
      bg: '#2c3e50',
      text: '#ecf0f1'
    },
    chatCard: {
      bg: '#7f8c8d',
      text: '#ecf0f1',
      basic: '#95a5a6'
    }
  },
  parents: {
    header: {
      bg: '#a1856b',
      text: '#ffffff'
    },
    launcher: {
      bg: '#cb6026'
    },
    messageList: {
      bg: '#fffbf1'
    },
    sentMessage: {
      bg: '#d5b08d',
      text: '#ffffff'
    },
    receivedMessage: {
      bg: '#eaeaea',
      text: '#222222'
    },
    userInput: {
      bg: '#ffffff',
      text: '#212121'
    },
    userList: {
      bg: '#ffffff',
      text: '#212121'
    },
    chatCard: {
      bg: '#fff8e3',
      text: '#212121',
      basic: '#ede7d0'
    }
  },
  teacher: {
    header: {
      bg: '#b0b0b0',
      text: '#ffffff'
    },
    launcher: {
      bg: '#a8a8a8'
    },
    messageList: {
      bg: '#f5f5f5'
    },
    sentMessage: {
      bg: '#d1d1d1',
      text: '#333333'
    },
    receivedMessage: {
      bg: '#eaeaea',
      text: '#222222'
    },
    userInput: {
      bg: '#ffffff',
      text: '#212121'
    },
    userList: {
      bg: '#ffffff',
      text: '#212121'
    },
    chatCard: {
      bg: '#d1d1d1',
      text: '#333333',
      basic: '#f5f5f5'
    }
  }
}
