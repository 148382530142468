<template>
  <v-card flat>
    <v-card-text v-for="(item, idx) in form.questions" :key="idx">

      <v-text-field
        v-if="item.type === 'text'"
        v-model="item.value"
        :label="!darkTheme ? item.title : ''"
        :placeholder="darkTheme ? item.title : ''"
        :outline="darkTheme"
        :hide-details="darkTheme"
      />
      <v-select
        v-else-if="item.type === 'select'"
        :items="selectItems"
        v-model="item.value"
        item-value="value"
        item-text="name"
        :outline="darkTheme"
        :hide-details="darkTheme"
      />

      <v-checkbox
        v-else
        v-model="item.value"
        :label="`${item.title}: ${item.value ? 'Есть': 'Нет'}`"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        flat
        @click="update"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from "@/store"
export default {
  name: 'Form8',
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      selectItems: [
        { name: 'с предварительным накрытием', value: 1 },
        { name: 'через раздачу', value: 2 },
      ],
      form: {
        id: null,
        questions: [
          { title: 'Проектная вместимость школы', name: 'proect_count_children', value: "", type: "text" },
          { title: 'Количество посадочных мест в обеденном зале', name: 'number_of_seats', value: "", type: "text" },
          { title: 'Питание детей в общеобразовательном учреждении организовано', name: 'food_for_children', value: 1, type: "select" },
          { title: 'Количество детей', name: 'food_for_children_count', value: "", type: "text" },

          { title: 'Рацион питания согласован с органами Роспотребнадзора', name: 'rospotrebnadzor', value: false },
          { title: 'В общеобразовательном учреждении «С»-витаминизация готовых блюд', name: 'c_vitaminization', value: false },

          { title: 'В общеобразовательном учреждении обогащенных хлеб, обогащенные хлебобулочные изделия', name: 'enriched_bread', value: false },
          { title: 'В общеобразовательном учреждении обогащенных хлеб, обогащенные хлебобулочные изделия(Описание)', name: 'enriched_bread_descriptions', value: "", type: "text" },

          { title: 'В общеобразовательном учреждении обогащенные йодом продукты', name: 'fortified_iodine', value: false },
          { title: 'В общеобразовательном учреждении обогащенные йодом продукты (Описание)', name: 'fortified_iodine_description', value: "", type: "text" },
          { title: 'В общеобразовательном учреждении иные обогащенные продукты', name: 'other_fortified', value: false },
          { title: 'В общеобразовательном учреждении иные обогащенные продукты (Описание)', name: 'other_fortified_description', value: "", type: "text" },


          { title: 'Договор на дератизацию', name: 'deratization', value: "", type: "text" },
          { title: 'Договор на дезинфекцию', name: 'disinfection', value: "", type: "text" },
          { title: 'Договор на дезинсекцию', name: 'pest_control', value: "", type: "text" },
          { title: 'Договор на вывоз мусора', name: 'garbage', value: "", type: "text" }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([ 'foodBlockPassport' ])
  },
  mounted() {
    this.getFoodblockGeneral(this.foodBlockPassport.general_sections.id)
      .then(result => this.setForm(result))
  },
  methods: {
    ...mapMutations([ 'setShowPreloader', 'setShowPreloader', 'updateFoodBlocknames' ]),
    ...mapActions([ 'getFoodblockGeneral', 'updateFoodBlockGeneral' ]),
    prepareObjectForApi() {
      const res = {}
      this.form.questions.forEach(item => {
        res[item.name] = item.value
      })
      res["passport"] = this.foodBlockPassport.id
      return res
    },
    setForm(data) {
      this.form.id = data.id
      this.form.passport = data.passport
      this.form.questions.forEach(item => {
        for (const key in data) {
          if (item.name === key) item.value = data[key]
        }
      })
    },
    update() {
      const requestBody = this.prepareObjectForApi()
      this.updateFoodBlockGeneral({ id: this.form.id, data: requestBody })
        .then(result => this.setForm(result))
    }
  },

}
</script>
