import Vue from "vue"
import axios from "axios"

export const teacherTransferTrafficClass = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.teacher.orders.transferTrafficClass.replace(
      '$schoolClassId',
      payload.classId
    ),
    payload.data
  )

export const getOrderFoodTypes = payload => {
  let url = Vue.config.server +
    '/' +
    Vue.config.api.teacher.students.order_meal_types
      .replace('$schoolId', payload.schoolId)
      .replace('$id', payload.id)
  if (payload.orderId) {
    url += '?order_id=' + payload.orderId
  }
  return axios.get(url)
}

export const getUpdatesNews = id => axios.get(Vue.config.server + '/' + Vue.config.api.teacher.updates)

export const getStudents = payload =>
  axios.get(Vue.config.server + "/" + Vue.config.api.teacher.students.all, {
    params: payload.params
  })

export const getOneStudent = (payload) => {
  let url = Vue.config.server +
    "/" +
    Vue.config.api.teacher.students.one
      .replace('$schoolId', payload.schoolId)
      .replace("$id", payload.id)
  if (payload.orderId) {
    url += '?order_id=' + payload.orderId
  }
  return axios.get(url)
}

export const getPayments = payload =>
  axios.get(
    Vue.config.server + "/" + Vue.config.api.teacher.balance.get,
    payload
  )

export const deleteMealTypeOneStudent = payload =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.teacher.students.one
        .replace('$schoolId', payload.schoolId)
        .replace('$id', payload.id),
    payload.data
  )

export const updateOneStudent = payload =>
  axios.put(
    Vue.config.server +
      "/" +
      Vue.config.api.teacher.students.one
        .replace("$schoolId", payload.schoolId)
        .replace("$id", payload.id),
    payload.data
  )

export const getOrders = payload =>
  axios.get(
    Vue.config.server +
      "/" +
      Vue.config.api.teacher.orders.all.replace("$classId", payload.id),
    { params: payload.params }
  )

export const getOneOrder = payload =>
  axios.get(
    Vue.config.server +
      "/" +
      Vue.config.api.teacher.orders.one
        .replace("$classId", payload.id)
        .replace("$day", payload.day)
        .replace("$month", payload.month)
        .replace("$year", payload.year)
  )

export const updateOneOrder = payload =>
  axios.put(
    Vue.config.server +
      "/" +
      Vue.config.api.teacher.orders.one
        .replace("$classId", payload.id)
        .replace("$day", payload.day)
        .replace("$month", payload.month)
        .replace("$year", payload.year),
    payload.data
  )

export const getPrivileges = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.teacher.students.privileges
        .replace('$schoolId', payload.schoolId),
    payload.data
  )

export const updateStudentPrivilege = payload =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.teacher.students.privilege
        .replace('$schoolId', payload.schoolId)
        .replace('$id', payload.id),
    payload.data
  )

export const confirmOneOrder = payload =>
  axios.put(
    Vue.config.server +
    "/" +
    Vue.config.api.teacher.orders.confirm
      .replace("$classId", payload.id)
      .replace("$day", payload.day)
      .replace("$month", payload.month)
      .replace("$year", payload.year),
    payload.data
  )

export const printOrder = payload =>
  axios.post(
    Vue.config.server + "/" + Vue.config.api.teacher.orders.download,
    payload,
    { responseType: "blob" }
  )

export const printInvoice = payload =>
  axios.get(Vue.config.server + "/" + Vue.config.api.teacher.invoice.create, {
    params: payload.params,
    responseType: "blob"
  })
// PRINT
export const getInvoicesTaskId = payload =>
  axios.post(
    Vue.config.server + "/" + Vue.config.api.teacher.invoices.all,
    payload,
    { preloaderState: false }
  )

export const getInvoicesFileStatus = payload =>
  axios.get(
    Vue.config.server +
      "/" +
      Vue.config.api.teacher.invoices.status.replace(
        "$task_id",
        payload.task_id
      ),
    { preloaderState: false }
  )

export const getInvoicesFile = payload =>
  axios.get(
    Vue.config.server +
      "/" +
      Vue.config.api.teacher.invoices.download.replace(
        "$task_id",
        payload.task_id
      ),
    { responseType: "blob", preloaderState: false }
  )
// agreement
export const studentOffer = payload =>
  axios.put(
    Vue.config.server +
      "/" +
      Vue.config.api.teacher.agreement.one
        .replace("$schoolId", payload.school_id)
        .replace("$id", payload.id)
  )
// REPORTS
export const getAllReports = () =>
  axios.get(Vue.config.server + "/" + Vue.config.api.teacher.reports.all)

export const reportFileTaskId = payload =>
  axios.post(
    Vue.config.server +
      "/" +
      Vue.config.api.teacher.reports.one.replace("$id", payload.id),
    payload.data,
    { preloaderState: false }
  )

export const reportFormFileTaskId = payload =>
  axios.post(
    Vue.config.server +
      "/" +
      Vue.config.api.teacher.reports.form
        .replace("$id", payload.id)
        .replace("$form_id", payload.form_id),
    payload.data,
    { preloaderState: false }
  )

export const reportFileStatus = payload =>
  axios.get(
    Vue.config.server +
      "/" +
      Vue.config.api.teacher.reports.status
        .replace("$id", payload.id)
        .replace("$task_id", payload.task_id),
    { preloaderState: false }
  )

export const reportPrintFile = payload =>
  axios.get(
    Vue.config.server +
      "/" +
      Vue.config.api.teacher.reports.download
        .replace("$id", payload.id)
        .replace("$task_id", payload.task_id),
    { responseType: "blob", preloaderState: false }
  )
// /
// Notifications
export const getNotifications = () =>
  axios.get(Vue.config.server + "/" + Vue.config.api.teacher.notifications.all)

export const setNotification = payload =>
  axios.post(
    Vue.config.server + "/" + Vue.config.api.teacher.notifications.all,
    payload.data
  )

export const setNotificationEveryone = payload =>
  axios.post(
    Vue.config.server + "/" + Vue.config.api.teacher.notifications.everyone,
    payload.data
  )

export const updateNotification = payload =>
  axios.put(
    Vue.config.server +
      "/" +
      Vue.config.api.teacher.notifications.update.replace("$id", payload.id),
    payload.data
  )
//

// PRINT ADDITIONAL REPORT file
export const reportAdditional = payload =>
  axios.post(
    Vue.config.server + "/" + Vue.config.api.teacher.additional.one,
    payload.data,
    { preloaderState: false }
  )

export const reportAdditionalStatus = payload =>
  axios.get(
    Vue.config.server +
      "/" +
      Vue.config.api.teacher.additional.status.replace(
        "$task_id",
        payload.task_id
      ),
    { preloaderState: false }
  )

export const reportAdditionalDownload = payload =>
  axios.get(
    Vue.config.server +
      "/" +
      Vue.config.api.teacher.additional.download.replace(
        "$task_id",
        payload.task_id
      ),
    { responseType: "blob", preloaderState: false }
  )

export const getFoodTypes = () =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.teacher.foodTypes.all
  )

export const setFoodType = (orderId, mealTypeId) =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.teacher.orders.changeMealType.replace('$order_id', orderId).replace('$meal_type_id', mealTypeId)
  )

export const removeAdditionalFood = orderId =>
  axios.delete(
    Vue.config.server +
    '/' +
    Vue.config.api.teacher.orders.deleteAdditionalFood.replace('$order_id', orderId)
  )

export const removeClassPrivilege = payload =>
  axios.post(
    Vue.config.server +
        '/' +
        Vue.config.api.teacher.students.removeClassPrivilege,
    payload
  )

export const changeClassExtDatePrivilege = payload =>
  axios.post(
    Vue.config.server +
        '/' +
        Vue.config.api.teacher.students.changeClassExtDatePrivilege,
    payload
  )
