<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 mb-1>
        <h1>Платежи</h1>
      </v-flex>
      <v-flex md3 mt-4>
        <v-card class="rounded mb-4 mr-3"><Receipts /></v-card>
      </v-flex>
      <v-flex md9 mt-4>
        <v-card class="rounded"><OrganizerBalanceHistory /></v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UploadButton from '@/components/common/UploadButton/UploadButton'
import Vue from 'vue'
import store from '@/store'
import OrganizerBalanceHistory from '@/components/organizer/Bill/History'
import Receipts from '@/components/organizer/Bill/Receipts'
import Other_Receipts from '@/components/organizer/Bill/Other_Receipts'
import { DatePickersSmart } from '@/components/common'
import WithTooltip from "@/components/common/Tooltip/Tooltip"
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"

export default {
  name: 'BillIndex',
  components: {
    ContextHelpModal,
    WithTooltip,
    UploadButton,
    OrganizerBalanceHistory,
    Receipts,
    Other_Receipts,
    DatePickersSmart
  },
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
    }
  }
}
</script>

<style scoped>

.rounded {
  border-radius: 10px;
}

</style>
