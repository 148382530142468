<template>
  <div>
    <v-select
        :items="amount_type"
        v-model="filters.amount_type"
        item-value="value"
        item-text="name"
      />

    <v-layout
      class="fs-archive-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          no-data-text="Транзакции отсутсвуют"
          hide-actions
          :disable-initial-sort="true"
          class="elevation-1"
          item-key="data"
        >
          <template v-slot:items="props">
            <tr>
                <td>{{ props.item.created_at }}</td>
                <td>{{ (props.item.amount / 100).toFixed(2) }}</td>
                <td>{{ props.item.amount_type === 1 ? 'Зачисление' : 'Списание' }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-pagination
      v-if="Math.ceil(items_count / paginate.page_size) > 1"
      v-model="paginate.page"
      @input="fetchTransactions"
      total-visible="7"
      :length="Math.ceil(items_count / paginate.page_size)"
    ></v-pagination>
  </div>
</template>

<script>
import { getBuffetTransactions } from '@/router/routes/organizer.js'
import { mapGetters, mapMutations } from 'vuex'
import moment from "moment"
export default {
  name: 'BuffetTransactions',
  data: () => ({
    headers: [
      { text: 'Дата', value: 'created_at' },
      { text: 'Сумма', value: 'amount' },
      { text: 'Тип операции', value: 'amount_type' }
    ],
    items: [],
    items_count: 0,
    amount_type: [
      { name: 'Все', value: null },
      { name: 'Зачисление', value: 1 },
      { name: 'Списание', value: 2 }
    ],
    paginate: {
      page: 1,
      page_size: 10
    },
    filters: {
      amount_type: null
    }
  }),
  mounted() {
    this.fetchTransactions()
  },
  computed: {
    ...mapGetters([ 'currentChild' ])
  },
  watch: {
    'filters.amount_type'() {
      this.paginate.page = 1
      this.fetchTransactions()
    }
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    fetchTransactions() {
      getBuffetTransactions({ params: { ...this.paginate, ...this.filters, pupil: this.currentChild.id } })
        .then(res => {
          this.items = res.data.results
          this.items = res.data.results.map(item => {
            item.created_at = moment(item.created_at).format('DD.MM.yyyy HH:mm:ss')
            return item
          })
          this.items_count = res.data.count
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    }
  }
}
</script>

<style>

</style>
