<template>
  <v-container class="fs-organizer-container">
    <v-card class="rounded">
      <v-layout class="fs-dish" row>
        <v-toolbar class="rounded" color="white" flat>
          <v-spacer></v-spacer>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-layout>
      <v-layout class="fs-dish" row>
        <v-layout ml-5 mr-5 mb-4>
          <v-flex>
            <v-text-field
              :rules="[rules.required]"
              :value="item.name"
              :readonly="isReadonlyView"
              :label="!darkTheme ? 'Наименование типа питания' : ''"
              :placeholder="darkTheme ? 'Наименование типа питания' : ''"
              @input="changeValue('name', $event)">
            </v-text-field>
            <v-text-field
              :rules="[rules.required]"
              :value="item.price"
              :readonly="isReadonlyView"
              :label="!darkTheme ? 'Стоимость типа питания' : ''"
              :placeholder="darkTheme ? 'Стоимость типа питания' : ''"
              @input="changeValue('price', $event)">
            </v-text-field>
            <v-autocomplete
              v-if="item.replaceMealType"
              label="Тип питания, который необходимо заменить"
              item-text="name"
              item-value="id"
              :disabled="!!this.id"
              :readonly="!!this.id"
              :items="mealTypes"
              @change="changeValue('oldMealTypeId', $event)"
            />
            <v-autocomplete
              label="Периоды кормления"
              item-text="name"
              item-value="id"
              multiple
              small-chips
              deletable-chips
              :disabled="!!this.item.replaceMealType"
              :readonly="isReadonlyView"
              v-model="selectedFoodPeriods"
              :items="getFoodPeriods"
            />
            <element-group
              v-if="finSourceFeature && sourcesTemplate !== null"
              class="mt-2"
              v-for="item in selectedFoodPeriods"
              :title="mealTypePeriods[item]">
              <div class="pa-2">
                <v-text-field
                  :disabled="isReadonlyView"
                  class="fs-input"
                  type="number"
                  v-model="sourcesTemplate.find(obj => obj.id === item).price"
                  label="Сумма"
                />
                <v-autocomplete
                  :disabled="isReadonlyView"
                  height="30"
                  label="Финансирование"
                  v-model="sourcesTemplate.find(obj => obj.id === item).source"
                  :items="mealTypeSources"
                  item-value="id"
                  item-text="name"
                  persistent-hint
                />
              </div>
            </element-group>
            <v-autocomplete
              :rules="[rules.required]"
              label="Категория типа питания"
              item-text="name"
              item-value="id"
              :disabled="!!this.id"
              :readonly="!!this.id"
              :value="item.privilegeID"
              :items="sortPrivileges"
              :hint="getPrivilegePrice(item.privilegeID) ? getPrivilegePrice(item.privilegeID) + ' руб.' : null"
              persistent-hint
              @change="changeValue('privilegeID', $event)"
            />
            <v-menu
              v-if="item.replaceMealType"
              v-model="datepickerMenu"
              :close-on-content-click="false"
              min-width="0"
              style="display: none"
              lazy
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :prepend-inner-icon="!darkTheme ? 'event' : ''"
                  :prepend-icon="darkTheme ? 'event' : ''"
                  label="Дата замены типа питания"
                  v-model="item.date"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                class="fs-datepicker"
                prev-icon="keyboard_arrow_left"
                next-icon="keyboard_arrow_right"
                locale="ru"
                first-day-of-week="1"
                no-title
                v-model="item.date"
                @input="selectDate"
              ></v-date-picker>
            </v-menu>
            <v-switch
              :value="item.isExtendedDayGroup"
              :disabled="!!this.item.replaceMealType"
              label="ГПД"
              @change="changeValue('isExtendedDayGroup', $event)"
              :readonly="isReadonlyView"
            />
            <v-switch
              :value="item.isHot"
              :disabled="!!this.item.replaceMealType"
              label="Горячее питание"
              @change="changeValue('isHot', $event)"
              :readonly="isReadonlyView"
            />
            <v-switch
              v-if="setting('department_meal_types_scheduler')"
              :value="item.replaceMealType"
              label="Заменить существующий тип питания"
              @change="changeValue('replaceMealType', $event)"
              :readonly="isReadonlyView"
            />
            <v-switch
              v-if="item.replaceMealType"
              :value="item.archiveMealType"
              label="Отправить текущий тип питания в архив"
              @change="changeValue('archiveMealType', $event)"
              :readonly="isReadonlyView"
            />
            <v-flex>
              <v-card-actions class="d-flex justify-content-end align-items-center flex-wrap mt-3">
                <v-btn
                  color="primary"
                  class="organizer-btn"
                  @click="$router.go(-1)">Назад
                </v-btn>
                <v-btn
                  v-if="!isReadonlyView"
                  color="#69F0AE"
                  class="organizer-btn"
                  @click="updateMealType">{{getBtnSaveUpdateText}}
                </v-btn>
              </v-card-actions>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
  </v-container>
</template>


<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import {
  getMealType,
  getPrivileges,
  postMealType,
  putMealType,
  getAllMealTypesPrivilege
} from '@/router/routes/department'
import converter from '@/helpers/converter'
import MealTypeFactory from '@/factories/municipality/mealType'
import notify from '@/utils/notifications/toastNotification'
import ElementGroup from "@/components/common/ElementGroup/ElementGroup.vue"
import moment from "moment"

export default {
  name: 'FoodTypeItem',
  components: { ElementGroup },
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      id: null,
      item: new MealTypeFactory(),
      privilegesArray: [],
      selectedFoodPeriods: [],
      mealTypes: [],
      date: null,
      day: null,
      month: null,
      year: null,
      datepickerMenu: false,
      finSourceFeature: false,
      rules: {
        required: (v) => !!v || "Поле обязательное для заполнения"
      },
	    mealTypePeriods: {
		    1: 'Завтрак',
        2: 'Второй завтрак',
		    3: 'Обед',
		    4: 'Полдник',
		    5: 'Ужин',
		    6: 'Поздний ужин',
	    },
	    mealTypeSources: [
		    { id: 1, name: 'Федеральное' },
		    { id: 2, name: 'Муниципальное' },
		    { id: 3, name: 'Школьное' },
		    { id: 4, name: 'Род. плата' }
	    ],
	    sourcesTemplate: [
        {
          'id': 1,
          'periodName': 'Завтрак',
          'source': null,
          'price': 0
        },
        {
          'id': 2,
          'periodName': 'Второй завтрак',
          'source': null,
          'price': 0
        },
		    {
          'id': 3,
			    'periodName': 'Обед',
			    'source': null,
			    'price': 0
		    },
		    {
          'id': 4,
			    'periodName': 'Полдник',
			    'source': null,
			    'price': 0
		    },
		    {
          'id': 5,
			    'periodName': 'Ужин',
			    'source': null,
			    'price': 0
		    },
		    {
          'id': 6,
			    'periodName': 'Поздний ужин',
			    'source': null,
			    'price': 0
		    },
	    ],
    }
  },
  computed: {
    ...mapGetters([ 'departmentId', 'foodPeriods', 'setting' ]),
    selectDate() {
      this.datepickerMenu = false
      this.day = moment(this.item.date).date()
      this.month = moment(this.item.date).month() + 1
      this.year = moment(this.item.date).year()
    },
    getBtnSaveUpdateText() {
      if (!this.id) {
        return 'Создать'
      }
      return 'Сохранить'
    },
    sortPrivileges() {
      return this.privilegesArray.sort(function(a, b) {
        const result = a.name - b.name
        return result
      })
    },
    getFoodPeriods() {
      return this.foodPeriods
    },
    getFoodPeriodsIds() {
      return this.selectedFoodPeriods.map(item => {
        return item.id
      })
    },
    isReadonlyView() {
      return this.$route.meta === 'view'
    },
    title() {
      if (!this.id) {
        return 'Создать новый тип питания'
      }
      return `Тип питания: ${this.item.name}`
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getAllPrivileges()
    this.finSourceFeature = this.setting('meal_type_fin_source')
    if (this.id) {
      this.loadItem()
    } else {
      Vue.set(this.item, 'name', '')
    }
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    getPrivilegePrice(id) {
      for (let i = 0, l = this.privilegesArray.length; i < l; i++) {
        if (this.privilegesArray[i].id === id) {
          return converter(this.privilegesArray[i].price, 'pennyToRub')
        }
      }
    },
    async getAllPrivileges(id) {
      try {
        const PAYLOAD = {
          department_id: this.departmentId
        }
        const response = await getPrivileges(PAYLOAD)
        const {
          data: { data: items }
        } = response
        this.privilegesArray = items
      } catch (error) {
        // notify.error('Ошибка не удалось загрузить категории учащихся') // backend handler added (exception_message)
      } finally {
        this.$store.commit('setShowPreloader', false)
      }
    },
    async getMealTypesForPrivilege() {
      if (this.item.privilegeID) {
        try {
          const PAYLOAD = {
            department_id: this.departmentId,
            privilege_id: this.item.privilegeID
          }
          const response = await getAllMealTypesPrivilege(PAYLOAD)
          this.mealTypes = response.data
        } catch (error) {
        } finally {
          this.$store.commit('setShowPreloader', false)
        }
      }
    },
    async loadItem() {
      try {
        const PAYLOAD = {
          id: this.id,
          department_id: this.departmentId
        }
        const response = await getMealType(PAYLOAD)
        const {
          data: { data: item }
        } = response
        this.item = item
        this.item.price = converter(item.price, 'pennyToRub')
        this.item.privilegeID = item.privilege.id
        this.selectedFoodPeriods = [
          ...this.getFoodPeriodsInItem(item.foodPeriods)
        ]
	      if (item.sources.length === 0) {
		      this.sourcesTemplate = null
	      } else {
		      this.sourcesTemplate = item.sources.map(item => {
			      item.price = converter(item.price, 'pennyToRub')
			      return item
		      })
	      }
      } catch (error) {
        // notify.error('Ошибка не удалось загрузить тип питания') // backend handler added (exception_message)
      } finally {
        this.$store.commit('setShowPreloader', false)
      }
    },

    async updateMealType() {
      let request = putMealType
      let resultText = 'Тип питания изменён!'
      if (!this.id) {
        resultText = 'Тип питания успешно создан.'
        request = postMealType
      }

      if (this.item.price === null) {
        notify.error('Укажите сумму')
        return
      }

      if (this.item.replaceMealType && !this.item.oldMealTypeId) {
        notify.error('Необходимо выбрать тип питания для замены')
        return
      }

      if (this.item.replaceMealType && !this.item.date) {
        notify.error('Необходимо указать дату замены')
        return
      }

      let sources = []
      if (this.finSourceFeature) {
        let sourcesPriceSum = 0
        sources = this.sourcesTemplate.filter(item => {
          if (item.source !== null && item.price !== null) {
            sourcesPriceSum += converter(parseFloat(item.price), 'rubToPenny')
            return true
          }
          return false
        })

        if (this.selectedFoodPeriods.length !== sources.length) {
          notify.error('Заполните все поля')
          return
        }

        sources = sources.map(item => {
          item.price = converter(item.price, 'rubToPenny')
          return item
        })

        if (parseInt(this.convertMoney(this.item.price)) !== parseInt(sourcesPriceSum)) {
          notify.error('Суммы не совпадают')
          return
        }
      }
      ////////////////////////

      try {
        const PAYLOAD = {
          id: this.id ? this.id : null,
          department_id: this.departmentId,
          data: {
            name: this.item.name,
            isExtendedDayGroup: !!this.item.isExtendedDayGroup,
            isHot: !!this.item.isHot,
            foodPeriods: this.selectedFoodPeriods,
            price: this.convertMoney(this.item.price),
          }
        }
        if (this.finSourceFeature) {
          PAYLOAD.data.sources = sources
        }
        if (this.item.replaceMealType) {
          PAYLOAD.data.old_meal_type = this.item.oldMealTypeId
          PAYLOAD.data.archiving = this.item.archiveMealType
          PAYLOAD.data.date = this.item.date
        }
        if (!this.id) {
          PAYLOAD.data.privilege = this.item.privilegeID
        }
        await request(PAYLOAD)
        notify.success(resultText)
        this.$router.push({ name: 'MunicipalityFoodTypes' })
      } catch (error) {
        // notify.error('Ошибка, не удалось сохранить тип питания') // backend handler added (exception_message)
        this.sourcesTemplate.map(item => {
          item.price = converter(item.price, 'pennyToRub')
          return item
        })
      } finally {
        this.$store.commit('setShowPreloader', false)
      }
    },
    convertMoney(money) {
      money = money.replaceAll(',', '.').split('.')
      const ruble = money[0]
      let penny = money[1] ? money[1] : '00'
      if (penny.toString().length > 2) {
        notify.error('Ошибка. Стоимость должна содержать две цифры после запятой') //pass
        throw new Error('Ошибка. Стоимость должна содержать две цифры после запятой')
      }
      penny = penny && penny.toString().length === 1 ? penny = penny * 10 : penny
      return `${ruble}${penny}`
    },
    getFoodPeriodsInItem(periodsInItem) {
      return this.foodPeriods.reduce((result, item) => {
        if (periodsInItem.includes(item.alias)) {
          result.push(item.id)
        }
        return result
      }, [])
    },
    changeValue(key, value) {
      Vue.set(this.item, key, value)
      if (key === 'privilegeID' || key === 'replaceMealType') {
        if (this.item.replaceMealType && this.item.privilegeID) {
          this.item.oldMealTypeId = null
          this.getMealTypesForPrivilege()
        }
      }
      if (key === 'oldMealTypeId' && this.item.oldMealTypeId) {
        const selectedMealType = this.mealTypes.find(mealType => mealType.id === this.item.oldMealTypeId)
        if (selectedMealType) {
          this.selectedFoodPeriods = [
            ...this.getFoodPeriodsInItem(selectedMealType.foodPeriods)
          ]
          this.item.isHot = selectedMealType.isHot
          this.item.isExtendedDayGroup = selectedMealType.isExtendedDayGroup
        }
      }
    }
  }
}
</script>

<style scoped>

.rounded {
  border-radius: 10px;
}

.organizer-btn {
  font-size: 14px;
  padding: 8px 16px;
  width: 120px;
  margin: 8px;
  border-radius: 10px;
}

</style>