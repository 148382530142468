<template>
  <div>
    <div class="head-wrap">
      <h3>Продукты на складе</h3>

      <v-btn @click="addNewProduct">
        <v-icon class="fs-active-icon">add</v-icon>
        Добавить продукт
      </v-btn>
    </div>

    <!-- storehouses_products {{storehouses_products}} -->

    <v-layout class="fs-dish-result" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="storehouses_products"
          hide-actions
          no-data-text="Продукты ещё не добавленны"
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ transformDate(props.item.created_at) }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.count }}</td>
              <td>{{ props.item.price }}</td>
              <td>
                <v-flex row>
                  <v-icon
                    class="fs-active-icon"
                    @click="editProduct(props.item.id)"
                    >edit</v-icon
                  >
                  <v-icon
                    class="fs-active-icon"
                    @click="deleteProduct(props.item.id)"
                    >delete</v-icon
                  >
                </v-flex>
              </td>
            </tr>
          </template>


        </v-data-table>
      </v-flex>

    </v-layout>


    <!-- {{pagination.page}} -->
      <div class="text-xs-center" v-if="pagination_count > 1">
        <v-pagination
          v-model="pagination.page"
          :length="pagination_count"
          :total-visible="7"
        ></v-pagination>
      </div>

    <v-dialog v-model="is_edit_product" persistent width="70%">
      <StorehouseProductDetail
        v-if="is_edit_product"
        :product_id="product_id"
        :storehouse_id="storehouse_id"
        :is_create_product="is_create_product"
        @close="closeEditModal"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import moment from 'moment'
import StorehouseProductDetail from '@/components/admin/Storehouse/StorehouseProductDetail'
export default {
  name: 'StorehouseProducts',
  props: [ 'storehouse_id' ],
  components: { StorehouseProductDetail },
  data: () => ({
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Дата создания', value: 'created_at' },
      { text: 'Наименование', value: 'name' },
      { text: 'Кол-во', value: 'count' },
      { text: 'Цена', value: 'cost' },
      { text: 'Операции', value: 'operations' }
    ],
    is_edit_product: false,
    is_create_product: false,
    product_id: null,
    pagination: {
      page: 1,
      page_size: 5
    }
  }),
  watch: {
    'pagination.page'() {
      this.fetchProducts()
    }
  },
  methods: {
    ...mapActions([ 'fetchStorehousesProducts', 'deleteStorehouseProduct' ]),
    ...mapMutations([ 'set_storehouses_one_product' ]),
    fetchProducts() {
      if (this.storehouse_id) {
        this.fetchStorehousesProducts({
          params: { storehouse: this.storehouse_id, ...this.pagination }
        })
      }
    },
    transformDate(date) {
      return moment(date).format(Vue.config.format.date)
    },
    editProduct(productId) {
      this.is_edit_product = true
      this.is_create_product = false
      this.product_id = productId
    },
    closeEditModal() {
      this.is_edit_product = false
      this.product_id = null
      this.set_storehouses_one_product({})
      this.fetchProducts()
    },
    deleteProduct(product_id) {
      this.deleteStorehouseProduct({ product_id: product_id })
        .then(() => this.fetchProducts())
    },
    addNewProduct() {
      this.is_create_product = true
      this.is_edit_product = true
      this.product_id = null
    }
  },
  computed: {
    ...mapGetters([ 'storehouses_products' ]),
    pagination_count() {
      return Math.ceil(this.storehouses_products.count / this.pagination.page_size) || 0
    }
  },
  mounted() {
    if (this.storehouse_id) this.fetchProducts()
  }
}
</script>

<style scoped>
.head-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px 10px;
}
</style>
