import {
  getSyrveys,
  getSyrveyDetail,
  saveSyrveyDetail,
  createQuestion,
  deleteQuestion,
  createSyrvey,
  updateSyrvey,
  updateQuestion,
  getQuestion,
  deleteSyrvey,
  getAllVariants,
  createVariant,
  getPolls,
  deleteSyrveyResult,
  getPollDetail
} from '@/router/routes/admin'

export default {
  actions: {
    async getPolls(ctx) {
      await getPolls({
        params: {
          ...ctx.getters.syrvey_results_pagination,
          ...ctx.getters.syrvey_results_filters
        }
      })
        .then((res) => {
          ctx.commit('set_syrvey_results', res.data)
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async getPollDetail(ctx, id) {
      await getPollDetail({ id: id })
        .then((res) => {
          ctx.commit('set_poll_detail', res.data)
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },

    async deleteSyrveyResult(ctx, id) {
      return await deleteSyrveyResult({ id: id })
        .then(() => {
          return Promise.resolve('success')
        })
        .catch((err) => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async getSyrveys(ctx) {
      await getSyrveys({
        params: {
          ...ctx.getters.syrveys_pagination
        }
      })
        .then((res) => {
          let result = res.data
          result = {
            ...result,
            results: result.results.map((qst) => ({
              ...qst,
              answered_users: qst.questionnaire_poll_set.map((el) => el.user)
            }))
          }
          ctx.commit('set_syrveys', result)
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async createSyrvey(ctx) {
      await createSyrvey(ctx.getters.syrvey_manage_detail)
        .then((res) => {
          return Promise.resolve('success')
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async getAllVariants(ctx) {
      await getAllVariants({ params: { offset: 0, limit: 1000 } })
        .then((res) => {
          ctx.commit('set_all_variants', res.data)
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async createVariant(ctx) {
      await createVariant({ ...ctx.getters.variant_detail })
        .then((res) => {
          return Promise.resolve('success')
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async updateSyrvey(ctx) {
      await updateSyrvey({
        ...ctx.getters.syrvey_manage_detail,
        questionnaire: ctx.getters.syrvey_manage_detail.id
      })
        .then((res) => {
          return Promise.resolve('success')
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async deleteSyrvey(ctx, id) {
      await deleteSyrvey({ syrvey_id: id })
        .then((res) => {
          return Promise.resolve('success')
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async getSyrveyDetail(ctx, id) {
      await getSyrveyDetail({ syrvey_id: id })
        .then((res) => {
          let result = res.data
          result = {
            ...result,
            answered_users: result.questionnaire_poll_set.map((el) => el.user),
            questions: result.questions.map((qst) => ({
              ...qst,
              answer: qst.answer ? qst.answer : []
            }))
          }
          ctx.commit('set_syrvey_detail', result)
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },

    async getSyrveyDetailManage(ctx, id) {
      await getSyrveyDetail({ syrvey_id: id })
        .then((res) => {
          let result = res.data
          result = {
            ...result,
            answered_users: result.questionnaire_poll_set.map((el) => el.user),
            questions: result.questions.map((qst) => ({
              ...qst,
              answer: qst.answer ? qst.answer : []
            }))
          }
          ctx.commit('set_syrvey_manage_detail', result)
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async saveSyrveyResult(ctx, data) {
      return await saveSyrveyDetail(data)
        .then((res) => {
          return Promise.resolve('success')
        })
        .catch((err) => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async createQuestion(ctx) {
      const data = {
        ...ctx.getters.question_detail,
        variants: ctx.getters.question_detail.variants.map((v) => v.id)
      }
      return await createQuestion(data)
        .then((res) => {
          ctx.commit('set_question_detail', res.data)
          return Promise.resolve('success')
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async getQuestionDetail(ctx, id) {
      return await getQuestion({ question_id: id })
        .then((res) => {
          ctx.commit('set_question_detail', res.data)
          return Promise.resolve('success')
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async updateQuestion(ctx) {
      const data = {
        ...ctx.getters.question_detail,
        question_id: ctx.getters.question_detail.id,
        variants: ctx.getters.question_detail.variants.map((v) => v.id)
      }
      return await updateQuestion(data)
        .then((res) => {
          ctx.commit('set_question_detail', res.data)
          return Promise.resolve('success')
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async deleteQuestion(ctx, question_id) {
      return await deleteQuestion({ question_id: question_id })
        .then(() => {
          return Promise.resolve('success')
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    }
  },
  mutations: {
    set_syrveys(state, syrveys) {
      state.syrveys = syrveys
    },
    set_syrveys_pagination(state, pagination) {
      state.syrveys_pagination = {
        ...state.syrveys_pagination,
        ...pagination
      }
    },
    set_syrvey_results_pagination(state, pagination) {
      state.syrvey_results_pagination = {
        ...state.syrvey_results_pagination,
        ...pagination
      }
    },
    set_syrvey_detail(state, syrvey_detail) {
      state.syrvey_detail = syrvey_detail
    },
    clearSyrveyDetail(state) {
      state.syrvey_detail = {}
    },

    set_question_detail(state, data) {
      state.question_detail = data
    },
    set_syrvey_manage_detail(state, data) {
      state.syrvey_manage_detail = data
    },
    set_all_variants(state, value) {
      state.all_variants = value
    },
    clearSyrveyDetailManage(state) {
      state.syrvey_manage_detail = {
        name: '',
        is_published: false,
        schools: null
      }
    },
    clearQuestionDetail(state) {
      state.question_detail = {
        is_several: false,
        is_comment: false,
        questionnaire: null,
        name: '',
        variants: []
      }
    },
    set_variant_detail(state, data) {
      state.variant_detail = data
    },
    clear_variant_detail(state) {
      state.variant_detail = {
        id: null,
        name: '',
        code: '',
        value: 0
      }
    },
    set_syrvey_results(state, data) {
      state.syrvey_results = data
    },
    set_syrvey_results_filters(state, filters) {
      state.syrvey_results_filters = {
        ...state.syrvey_results_filters,
        ...filters
      }
    },
    set_poll_detail(state, data) {
      state.poll_detail = data
    }
  },
  getters: {
    syrveys(state) {
      return state.syrveys
    },
    syrvey_detail(state) {
      return state.syrvey_detail
    },
    syrveys_pagination(state) {
      return state.syrveys_pagination
    },
    question_detail(state) {
      return state.question_detail
    },
    syrvey_manage_detail(state) {
      return state.syrvey_manage_detail
    },
    all_variants(state) {
      return state.all_variants
    },
    variant_detail(state) {
      return state.variant_detail
    },
    syrvey_results_pagination(state) {
      return state.syrvey_results_pagination
    },
    syrvey_results(state) {
      return state.syrvey_results
    },
    syrvey_results_filters(state) {
      return state.syrvey_results_filters
    },
    poll_detail(state) {
      return state.poll_detail
    }
  },
  state: {
    syrveys: {},
    syrvey_detail: {},
    syrveys_pagination: {
      page_size: 10,
      page: 1
    },

    //  manage
    questions: [],
    question_detail: {
      is_several: false,
      is_comment: false,
      questionnaire: null,
      name: '',
      variants: []
    },
    syrvey_manage_detail: {
      name: '',
      is_published: false,
      schools: null
    },
    all_variants: {},
    variant_detail: {
      name: '',
      code: '',
      value: 0
    },
    // results syrvey
    syrvey_results: {},
    syrvey_results_pagination: {
      page_size: 10,
      page: 1
    },
    syrvey_results_filters: {
      school: null,
      department: null
    },
    poll_detail: {}
  }
}
