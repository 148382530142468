<script>
import {
  getBoardingSchoolsDepartment,
  updateBoardingSchoolsDepartment
} from '@/router/routes/superadmin'
import store from '@/store'
import notify from '@/utils/notifications/toastNotification.vue'
import rulesCustom from '@/helpers/rulesCustom'

export default {
  name: 'BoardingSchoolsDepartmentItemView',
  props: ['id'],

  data() {
    const rules = rulesCustom

    return {
      rules,
      isEditMode: false,
      isValid: false,
      isLoading: false,
      fields: {
        id: null,
        name: '',
        short_name: '',
        remote_id: null,
        parent: {
          id: null,
          short_name: '',
          name: ''
        },
        school: {
          id: null,
          short_name: '',
          name: ''
        }
      },
      updatedFields: {
        name: {
          type: 'text',
          key: 'name',
          label: 'Название',
          value: null,
          rules: [rules.required, rules.minLength(3), rules.maxLength(200)],
          counter: 200
        },
        short_name: {
          type: 'text',
          key: 'short_name',
          label: 'Сокращенное название',
          value: null,
          rules: [rules.required, rules.minLength(3), rules.maxLength(128)],
          counter: 100
        }
      }
    }
  },

  methods: {
    async updateDepartment() {
      const requestData = {}
      Object.entries(this.updatedFields).forEach(([k, v]) => {
        if (this.fields[k] !== v.value) {
          requestData[k] = v.value
        }
      })
      // Если нет изменений в полях
      if (!Object.keys(requestData).length) {
        return
      }

      this.isLoading = true
      try {
        const {
          data: { data: card }
        } = await updateBoardingSchoolsDepartment({
          data: requestData,
          id: this.$props.id
        })
        this.fields = { ...this.fields, ...card }
        this.closeEditMode()
        notify.success('Изменения внесены')
      } catch (e) {
        notify.error('Ошибка внесения изменений')
      } finally {
        this.isLoading = false
        store.commit('setShowPreloader', false)
      }
    },

    async getCard() {
      this.isLoading = true

      try {
        const {
          data: { data: card }
        } = await getBoardingSchoolsDepartment({
          id: this.$props.id
        })
        this.fields = card
        this.setUpdatedFields()
      } catch (e) {
        notify.error('Ошибка загрузки карточки')
      } finally {
        this.isLoading = false
        store.commit('setShowPreloader', false)
      }
    },

    closeEditMode() {
      this.isEditMode = false
    },
    setUpdatedFields() {
      Object.keys(this.updatedFields).forEach((k) => {
        this.updatedFields[k].value = this.fields[k]
      })
    }
  },
  computed: {
    isUnequal() {
      return Object.entries(this.updatedFields).some(
        ([k, v]) => this.fields[k] !== v.value
      )
    },
    disabledSubmit() {
      return !this.isUnequal || !this.isValid
    }
  },

  async mounted() {
    await this.getCard()
  },

  watch: {
    isEditMode(newValue) {
      if (!newValue) {
        this.setUpdatedFields()
      }
    }
  }
}
</script>

<template>
  <v-container class="fs-organizer-container">
    <v-toolbar :class="{ 'cyan lighten-5': isEditMode }" extension>
      <v-btn flat color="blue" variant="tonal" @click="$router.go(-1)">
        <v-icon>arrow_back</v-icon>Назад
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title class="text-truncate text-no-wrap px-2"
        >Карточка департамента</v-toolbar-title
      >
      <v-switch
        v-model="isEditMode"
        hide-details
        class="text-no-wrap px-2 blue-grey--text align-center justify-end"
      >
        <template v-slot:prepend>
          Режим редактирования: {{ isEditMode ? 'Вкл' : 'Выкл' }}
        </template>
      </v-switch>
    </v-toolbar>
    <v-layout class="rounded rounded-md" elevation-3>
      <v-flex class="mx-auto">
        <v-form
          ref="form"
          v-model="isValid"
          class="pa-4 pt-6"
          @submit.prevent="updateDepartment"
          row
          justify-space-between
        >
          <!--Изменяемые поля-->
          <v-flex pa-1 v-for="field in updatedFields" :key="field.key">
            <v-text-field
              :label="field.label"
              v-model="field.value"
              variant="filled"
              :readonly="!isEditMode"
              :box="isEditMode"
              :hide-details="!isEditMode"
              :counter="field.counter || false"
              :maxlength="field.counter || false"
              :rules="field.rules.map((rule) => rule(field))"
              :clearable="isEditMode"
              required
            />
          </v-flex>

          <v-flex class="d-flex ga-4">
            <v-flex xs12 md6 pa-1>
              <v-text-field
                label="ID"
                v-model="fields.id"
                variant="filled"
                readonly
                :disabled="isEditMode"
                hide-details
              />
            </v-flex>
            <v-flex xs12 md6 pa-1>
              <v-text-field
                label="ID в ЭШ"
                v-model="fields.remote_id"
                variant="filled"
                readonly
                :disabled="isEditMode"
                hide-details
              />
            </v-flex>
          </v-flex>

          <v-flex pa-1>
            <v-text-field
              label="Школа"
              v-model="
                fields.school?.short_name
                  ? fields.school.short_name
                  : fields.school.name
              "
              variant="filled"
              readonly
              :disabled="isEditMode"
              hide-details
            />
          </v-flex>
          <v-flex pa-1>
            <v-text-field
              label="Департамент"
              v-model="
                fields.parent?.short_name
                  ? fields.parent.short_name
                  : fields.parent.name
              "
              variant="filled"
              readonly
              :disabled="isEditMode"
              hide-details
            />
          </v-flex>

          <v-flex
            class="d-flex justify-end pa-4"
            v-if="isEditMode"
            style="gap: 10px"
          >
            <v-btn @click="closeEditMode" :loading="isLoading"> Отмена </v-btn>
            <v-btn
              color="primary"
              :disabled="disabledSubmit"
              :loading="isLoading"
              type="submit"
            >
              Сохранить
            </v-btn>
          </v-flex>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>
