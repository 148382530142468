<template>
	<div>
		<v-card class="fs-fields-group--gray">
			<context-help-modal style="float: right" :context-key="contextKeys.admin.students.card.food" />
			<v-card-text>
				<v-flex mb-3 v-if="orderErrors.loadingStatus">
					<v-progress-circular size="24" color="primary" indeterminate />
				</v-flex>
				<v-flex mb-3 v-if="orderErrors.errors.length > 0">
	            <span class="error--text font-weight-light">
	              <a @click="handleErrorModal" class="error--text">
	                <v-icon color="error">
	                  warning
	                </v-icon>
	                Некоторые заказы не создались
	              </a>
	            </span>
				</v-flex>
				<v-flex>
					<v-checkbox
						v-if="setting('multiple_meal_types')"
						v-model="student.hasCustomMealTypeSettings"
						class="fs-checkbox"
						:label="`Вариативное питание: ${student.hasCustomMealTypeSettings ? 'вкл' : 'выкл'}`">
					</v-checkbox>
				</v-flex>
				<v-flex mb-3>
					<span class="fs-student-info__key">Тип питания:</span>
					<span v-if="!student.hasCustomMealTypeSettings" class="fs-student-info__value">
						<with-tooltip text="Выбрать тип питания">
	              <span v-if="view || isFake">{{ student.foodType.name || 'Не назначен' }}</span>
	              <a v-else @click="handlerModalTypes">{{ student.foodType.name || 'Назначить' }}</a>
						</with-tooltip>
	        </span>
					<div v-else class="mt-1">
						<v-card class="pa-3">
               <div style="position: absolute; top: 13px; right: 13px">
                <context-help-modal :context-key="contextKeys.admin.students.card.variable" />
		          </div>
							<v-card-text>
								<div v-for="index in 7" class="d-flex align-center">
									<span style="width: 100px" class="mr-4 font-weight-bold">{{ dayNames[index - 1] }}</span>
									<v-select
										class="ttt pa-0"
										clearable
                    :disabled="view"
										placeholder="отсуствует"
										style="width: 70%"
									  v-model="student.mealTypeSettings[index]"
										item-text="name"
										item-value="id"
										:items="student.validFoodTypes">
									</v-select>
								</div>
							</v-card-text>
						</v-card>
					</div>
				</v-flex>
				<v-flex mb-3 row>
					<span class="fs-student-info__key">Льгота:</span>
					<span class="fs-student-info__value">
						<with-tooltip text="Выбрать льготу">
              <span v-if="view || isFake">{{ student.concession.name || 'Без льготы' }}</span>
              <a v-else @click="isShowPupilPrivilegeModal = true">{{ student.concession.name || 'Без льготы' }}</a>
						</with-tooltip>
	        </span>
				</v-flex>
				<v-flex>
					<with-tooltip text="Изменение статуса нахождения ребенка в группе продлённого дня">
	            <span class="fs-student-info__value">
	              <v-checkbox
	                v-model="student.isLongDay"
	                :disabled="view || isFake"
	                class="fs-checkbox"
	                label="Ребёнок в группе продлённого дня"
	              ></v-checkbox>
	            </span>
					</with-tooltip>
				</v-flex>
				<v-flex>
					<with-tooltip text="Изменение статуса питания ребенка в кредит">
	            <span class="fs-student-info__value">
	              <v-checkbox
	                v-model="student.isCredit"
	                :disabled="view || isFake"
	                class="fs-checkbox"
	                label="Ребёнок питается в кредит"
	              ></v-checkbox>
	            </span>
						</with-tooltip>
				</v-flex>
        <with-tooltip
            v-if="setting('sirius')"
            text="Льготное буфетное питание"
        >
          <v-checkbox
              v-model="student.freeBuffet"
              :disabled="view || isFake"
              class="fs-checkbox"
              label="Льготное буфетное питание"
          ></v-checkbox>
        </with-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on: tooltip }">
						<v-flex v-if="!isFake" v-on="{ ...tooltip }">
							<v-menu
								v-model="extSettingDateMenu"
								:close-on-content-click="false"
								:nudge-right="40"
								lazy
								transition="scale-transition"
								offset-y
								full-width
								min-width="290px"
							>
								<template v-slot:activator="{ on: on }">
									<label for="ext_date">
										<b>Дата окончания действия льготы ученика</b>
									</label>
									<v-text-field
										id="ext_date"
										:disabled="view || student.concession.isDefault"
										:value="student.concession.isDefault ? 'Льгота по умолчанию не требует указания срока действия' : settingStudentExtPrivilege.ext_date"
										prepend-icon="event"
										readonly
										v-on="{ ...on }"
									></v-text-field>
								</template>
								<v-date-picker
									locale="ru-ru"
									@input="setExtPrivilegeDate"
								></v-date-picker>
							</v-menu>
						</v-flex>
					</template>
					<span v-if="student.concession.isDefault">Льгота по-умолчанию не требует указания срока действия</span>
					<span v-else>Срок действия текущей установленной льготы</span>
				</v-tooltip>
				<v-flex v-if="!view && !isFake" class="mt-2">
					<with-tooltip text="Снять текущий тип питания у ученика (заявки в статусе черновик будут удалены)">
						<v-btn
							class="fs-btn fs-admin-btn__show v-btn--active"
							flat
							block
							@click="deleteMealTypeStudent"
						>Снять тип питания</v-btn>
					</with-tooltip>
				</v-flex>
			</v-card-text>
		</v-card>
		<ChangePupilPrivilege
			v-if="isShowPupilPrivilegeModal"
			v-model="isShowPupilPrivilegeModal"
			@updated="$emit('getStudent')"
			:currentPrivilege="student.concession"
			:studentId="student.id"
			:minimumDate="minDate"
			@changeExpDate="setExpDate"
      :isTeacher="isTeacher"
			width="50%"
		/>
		<OrderErrors
			:errors="orderErrors.errors"
			:dialog.sync="orderErrors.modal"
			:persistent="true"
		/>
		<ChangeFoodTypeModal
			v-model="changeFoodTypeDialog"
			:foodTypes="student.validFoodTypes"
			:studentFoodTypeIDNow="student.foodType.id"
			:studentFoodTypeId="foodTypeInModal"
			class-name="fs-admin-container"
			@changeType="$emit('updateStudent', $event)"
		/>
	</div>
</template>

<script>
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import AdminClassFactory from "@/factories/admin/class-factory"
import WithTooltip from "@/components/common/Tooltip/Tooltip.vue"
import Vue from "vue"
import { mapGetters, mapMutations } from "vuex"
import {
  deleteMealTypeOneStudent as deleteMealTypeOneStudentTeacher,
} from '@/router/routes/teacher'
import {
  deleteMealTypeOneStudent as deleteMealTypeOneStudentAdmin,
  getStudentExtPrivilege,
  updateStudentExtPrivilege
} from "@/router/routes/admin"
import { getSchoolId } from "@/helpers/utils"
import notify from "@/utils/notifications/toastNotification.vue"
import store from "@/store"
import { objectWithDateProps } from "@/utils/date"
import OrderErrors from "@/components/common/Modals/OrderErrors.vue"
import ChangeFoodTypeModal from "@/components/common/Modals/ChangeFoodType.vue"
import ChangePupilPrivilege from "@/components/common/Modals/ChangePupilPrivilege.vue"
import moment from "moment/moment"
import { getOrderCreationErrors } from "@/router/routes/common"

export default {
  name: 'PupilCardFood',
  components: {
	  ChangePupilPrivilege,
	  ChangeFoodTypeModal,
	  OrderErrors,
    WithTooltip,
	  ContextHelpModal
  },
  props: {
	  view: {
		  type: Boolean,
		  default: false
	  },
    student: {
      type: AdminClassFactory,
      default: null,
	    required: true,
    },
    isFake: {
      type: Boolean,
      default: false,
    },
	  isTeacher: {
		  type: Boolean,
		  default: false,
	  },
  },
  data() {
    return {
	    contextKeys: Vue.config.contextHelp,
      orderErrors: {
        loadingStatus: true,
        modal: false,
        errors: []
      },
	    mealTypeUpdated: false,
	    foodTypeInModal: null,
	    changeFoodTypeDialog: false,
	    isShowPupilPrivilegeModal: false,
	    extSettingDateMenu: false,
	    settingStudentExtPrivilege: objectWithDateProps({}, 'ext_date'),
	    dayNames: [ 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье' ],
    }
  },
  computed: {
    ...mapGetters([ 'currentUser', 'setting' ]),
	  minDate() {
		  const currentDate = new Date()
		  currentDate.setDate(currentDate.getDate() + 1)
		  return currentDate.toISOString().split('T')[0]
	  },
  },
  async created() {
    await this.getStudentExtPrivilege()
    await this.getOrderErrors()
  },
  methods: {
	  ...mapMutations([ 'setShowPreloader' ]),
	  async getOrderErrors() {
		  this.orderErrors.loadingStatus = true
		  this.orderErrors.errors = []
		  const payload = {
			  'orderDateFrom': moment().format('YYYY-MM-DD'),
			  'pupilId': this.student.id
		  }
		  getOrderCreationErrors(payload)
			  .then(res => {
				  this.orderErrors.errors = res.data.map(item => {
					  return {
						  ...item
					  }
				  })
			  })
			  .finally(() => {
				  this.orderErrors.loadingStatus = false
				  this.setShowPreloader(false)
			  })
	  },
	  async getStudentExtPrivilege() {
		  getStudentExtPrivilege({ id: this.student.id })
			  .then(res => {
				  this.settingStudentExtPrivilege.ext_date = res.data.ext_date
				  this.ext_date_id = res.data.id
				  if ((new Date(res.data.ext_date) < new Date()) && (!this.student.concession.isDefault)) {
					  notify.error('У ученика истёк срок действия льготы') //pass
				  }
			  })
			  .finally(() => {
				  this.setShowPreloader(false)
			  })
	  },
	  setExpDate(value) {
		  this.settingStudentExtPrivilege.ext_date = value
	  },
	  setExtPrivilegeDate(date) {
		  this.settingStudentExtPrivilege.ext_date = date
		  this.updateStudentExtPrivilege()

		  this.extSettingDateMenu = false
	  },
    handleErrorModal() {
      this.orderErrors.modal = true
    },
	  handlerModalTypes() {
		  this.foodTypeInModal = this.student.foodType.id
		  this.changeFoodTypeDialog = true
	  },
	  async deleteMealTypeStudent() {
		  const payload = {
			  schoolId: getSchoolId(this.currentUser),
			  id: this.$route.params.id,
		  }
      const method = this.isTeacher ? deleteMealTypeOneStudentTeacher : deleteMealTypeOneStudentAdmin
		  try {
			  await method(payload)
			  notify.success('Информация успешно обновлена')
		  } catch (error) {
			  // notify.error('Ошибка обновления информации') // backend handler added (exception_message)
		  } finally {
        this.$emit('getStudent')
			  await this.getOrderErrors()
			  store.commit('setShowPreloader', false)
		  }
	  },
	  async updateStudentExtPrivilege() {
		  if (!this.settingStudentExtPrivilege.ext_date) return
		  updateStudentExtPrivilege({
			  id: this.student.id,
			  data: {
				  ext_date: this.settingStudentExtPrivilege._ext_date.toISOString().split('T')[0],
				  pupil: this.student.id
			  }
		  })
			  .then(res => {
				  notify.success('Срок действия льготы ученика успешно изменён')
			  })
			  .finally(() => {
				  this.setShowPreloader(false)
			  })
	  },
  }
}
</script>
