<template>
  <v-container fluid>
    <v-layout row>
      <v-flex style="padding-top: 3px" grow>
        <v-text-field
          label="Поиск"
          solo
          prepend-inner-icon="search"
          v-model="searchRow"
          @keyup.enter="search()"
        ></v-text-field>
      </v-flex>
      <v-flex shrink>
        <v-btn
          color="success"
          class="mr-0"
          large
          @keyup.enter="search()"
          @click="search()"
        >
          <v-icon class="mr-2">
            search
          </v-icon>
          Найти
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex>
        <v-card>
          <v-card-title>
            <h2>Реестр отчисленных детей</h2>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :items="content"
              :headers="headers"
              :custom-sort="customSort"
              :rows-per-page-items="[25, 50, {'text': '$vuetify.dataIterator.rowsPerPageAll', 'value' : - 1}]"
            >
              <template v-slot:items="props">
                <td>
                  {{ props.item.expelledDate }}
                </td>
                <td>
                  {{ props.item.pupilId }}
                </td>
                <td>
                  {{ props.item.fullName }}
                </td>
                <td>
                  {{ props.item.schoolName }}
                </td>
                <td>
                  {{ props.item.balance }}
                </td>
                <td>
                  {{ props.item.cashbackStatus }}
                </td>
                <td>
                  {{ props.item.amount }}
                </td>
                <td>
                  {{ props.item.balanceRemain }}
                </td>
                <td>
                  <v-btn
                    small
                    v-if="props.item.cashbackStatus === '-' && props.item.balance !== 0"
                    @click="toggleCashbackModal(props.item.pupilId, props.item.balance, props.item.id)"
                  >
                    Оформить возврат
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <CreateCashbackModal
      :cashbackModal="cashbackModal"
      :pupilId="cashbackParameters.pupilId"
      :amount="cashbackParameters.amount"
      :registerId="cashbackParameters.registerId"
    />
  </v-container>
</template>

<script>
import converter from "@/helpers/converter"
import CreateCashbackModal from "@/components/organizer/Modals/CreateCashbackModal"
import moment from "moment"
import Vue from "vue"
import { getExpelledRegister, searchExpelledRegister } from "@/router/routes/organizer"
import store from "@/store"

export default {
  name: "ExpelledRegister",
  components: {
    CreateCashbackModal
  },
  data() {
    return {
      headers: [
        { text: 'Дата отчисления', value: 'expelledDate' },
        { text: 'Лицевой счет', value: 'pupilId' },
        { text: 'ФИО', value: 'fullName' },
        { text: 'Школа', value: 'schoolName' },
        { text: 'Баланс на момент отчисления, руб', value: 'balance' },
        { text: 'Возврат', value: 'cashbackStatus' },
        { text: 'Сумма возврата, руб', value: 'amount' },
        { text: 'Остаток после возврата, руб', value: 'balanceRemain' },
        { text: 'Действия', value: 'actions' }
      ],
      searchRow: '',
      cashbackModal: false,
      cashbackParameters: {
        pupilId: null,
        amount: null,
        registerId: null,
      },
      content: []
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index === 'expelledDate') {
          return isDesc ?
            moment(a['expelledDate'], 'DD-MM-YYYY') - moment(b['expelledDate'], 'DD-MM-YYYY')
            : moment(b['expelledDate'], 'DD-MM-YYYY') - moment(a['expelledDate'], 'DD-MM-YYYY')
        }
        else if (index === 'fullName' || index === 'schoolName') {
          return isDesc ?
            a[index].localeCompare(b[index])
            : b[index].localeCompare(a[index])
        }
        else {
          return isDesc ?
            a[index] - b[index]
            : b[index] - a[index]
        }
      })
      return items
    },
    toRub(penny) {
      return converter(penny, 'pennyToRub')
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY')
    },
    toggleCashbackModal(pupilId, amount, registerId) {
      this.cashbackParameters.pupilId = this.cashbackModal ? null : pupilId
      this.cashbackParameters.amount = this.cashbackModal ? null : amount
      this.cashbackParameters.registerId = this.cashbackModal ? null : registerId
      this.cashbackModal = !this.cashbackModal
      if (!this.cashbackModal) {
        this.loadData()
      }
    },
    async loadData() {
      const response = await getExpelledRegister()
      this.content = this.parseResponse(response.data)
      store.commit('setShowPreloader', false)
    },
    async search() {
      const response = await searchExpelledRegister({ search: this.searchRow })
      this.content = this.parseResponse(response.data)
      store.commit('setShowPreloader', false)
    },
    parseResponse(content) {
      return content.map(i => {
        return {
          id: i.id,
          expelledDate: this.formatDate(i.expelled_date),
          pupilId: i.pupil_id,
          fullName: i.fullname,
          schoolName: i.school_name,
          balance: this.toRub(i.pupil_balance),
          cashbackStatus: i.cashback_status ? 'Да' : '-',
          amount: i.cashback_amount ? this.toRub(i.cashback_amount) : '-',
          balanceRemain: i.cashback_status ? this.toRub(i.balance_remain) : '-'
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
