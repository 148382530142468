<template>
  <div ref="scrollWrapper">
    <slot name="body">
    </slot>
  </div>
</template>

<script>
// TODO: возможно стоит перенести сюда больше логики СНАРУЖИ
// добавить условия выстреливания  event итд итп
export default {
  name: 'ScrollableContainer',
  data() {
    return {
      clientHeight: null,
      scrollTop: null,
      scrollHeight: null,
      scrollArea: null
    }
  },
  methods: {
    runOnScroll() {
      if (
        this.scrollArea.clientHeight + this.scrollArea.scrollTop >=
        this.scrollArea.scrollHeight
      ) {
        this.$emit('scrollOnBottom')
      }
    }
  },
  mounted() {
    this.scrollArea = this.$refs.scrollWrapper.children[0]
    this.scrollArea.addEventListener('scroll', this.runOnScroll)
  },
  destroyed() {
    if (this.scrollArea) {
      this.scrollArea.removeEventListener('scroll', this.runOnScroll)
    }
  }
}
</script>
