<template>
  <v-container class="fs-admin-container" fluid grid-list-md>
    <v-layout class="fs-dish-result" wrap>
      <v-flex xs12 mb-1>
        <h1>{{ title }}</h1>
        <v-btn color="primary" @click="show = !show">
          изменить организатора питания
        </v-btn>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1 fs-admin-table"
          item-key="name"
          no-data-text="Нет образовательных организаций"
          hide-actions
        >
          <template v-slot:items="props">
            <tr>
              <td class="px-2">{{ props.item.id }}</td>
              <td class="px-2">{{ getDisplayName(props.item) }}</td>
              <td class="px-2" v-if="setting('boarding_school_department')">
                <v-icon class="px-2 d-flex align-center justify-center">{{
                  props.item?.is_boarding_school ? 'check_circle' : 'remove'
                }}</v-icon>
              </td>
              <td class="px-2">{{ getOrganizerName(props.item) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <EditingOrganizations v-model="show" @update="getSchoolList" :width="960" />
  </v-container>
</template>

<script>
import { getLimitForMinScrolling } from '@/utils/scrolling'
import { getSchools } from '@/router/routes/department'
import scrollable from '@/mixins/scrollable'
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import getDisplayName from '@/helpers/getDisplayName'
import EditingOrganizations from '@/components/municipality/EducationalOrganizations/components/EditingOrganizations.vue'
import notify from '@/utils/notifications/toastNotification.vue'

export default {
  name: 'EducationalOrganizations',
  components: { EditingOrganizations },
  mixins: [scrollable],
  created() {
    this.getSchoolList()
    window.addEventListener('scroll', this.scrollHandler)
  },
  destroyed() {
    this.removeScrollHandler()
  },
  computed: {
    ...mapGetters(['currentUser', 'setting']),
    headers() {
      const columns = [
        { text: '№ п/п', value: 'id' },
        { text: 'Наименование', value: 'name' },
        { text: 'Организатор питания', value: 'status' }
      ]

      if (this.setting('boarding_school_department')) {
        columns.splice(2, 0, {
          text: 'Школа-интернат',
          value: 'is_boarding_school',
          align: 'center'
        })
      }

      return columns
    }
  },
  data() {
    return {
      title: 'Образовательные организации',
      items: [],
      LIMIT_CONFIG: getLimitForMinScrolling(),
      show: false
    }
  },
  methods: {
    getDisplayName,
    ...mapMutations(['setShowPreloader']),

    async fetchSchoolList(payload) {
      try {
        const {
          data: { data }
        } = await getSchools(payload)
        return data
      } catch (e) {
        notify.error(e.messages)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async loadMore() {
      const payload = {
        department_id: this.currentUser.department.id,
        params: {
          limit: this.LIMIT_CONFIG,
          offset: this.offset
        }
      }
      this.items = this.items.concat(await this.fetchSchoolList(payload))
    },

    async getSchoolList() {
      this.offset = 0

      const payload = {
        department_id: this.currentUser.department.id,
        params: {
          limit: this.LIMIT_CONFIG,
          offset: this.offset
        }
      }
      this.items = await this.fetchSchoolList(payload)
    },

    getOrganizerName(item) {
      if (item.supplier && item.supplier.short_name)
        return item.supplier.short_name

      return 'Не заполнено'
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>

<style>
.v-icon {
  font-size: 24px;
}
</style>
