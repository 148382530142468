<template>
  <v-container class="fs-organizer-container">
    <v-layout>
      <v-flex>
        <v-flex mb-5>
          <h1>{{ title }}</h1>
        </v-flex>
        <v-layout row wrap>
          <v-form ref="form" v-model="valid" class="op-form">
            <v-text-field
                v-for="item in organizer" :key="item.label"
                :label="item.label"
                :append-icon="isPasswordField(item.key) ? (showPassword ? 'visibility' : 'visibility_off') : undefined"
                :type="!isPasswordField(item.key) || showPassword ? 'text' : 'password'"
                v-model="item.value"
                @click:append="showPassword = !showPassword"
                :rules="[rules.requiredTextfield]"
              >{{item.value}}</v-text-field>
          </v-form>
          <v-flex text-md-right xs12>
            <v-btn flat class="fs-btn v-btn--active" @click="$router.go(-1)">Отмена</v-btn>
            <v-btn flat class="fs-btn fs-btn--primary v-btn--active" @click="store">Добавить</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import Vue from 'vue'
import { storeOrganizer } from '@/router/routes/superadmin'
import store from '@/store'
import rules from '@/helpers/rules'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ModuleAdminOrganizerCreate',
  data() {
    return {
      title: 'Новый организатор питания',
      organizer: [],
      rules: rules,
      valid: true,
      items: [],
      showPassword: false
    }
  },
  created() {
    const org = {}
    this.organizer = [
      {
        key: 'username',
        label: 'Логин',
        value: _.get(org, 'username', '')
      },
      {
        key: 'password',
        label: 'Пароль',
        value: _.get(org, 'password', '')
      },
      {
        key: 'confirmPassword',
        label: 'Подтверждение пароля',
        value: _.get(org, 'confirmPassword', '')
      }
    ]
  },
  methods: {
    ...mapMutations([
      'setShowPreloader'
    ]),
    store() {
      if (!this.$refs.form.validate()) return

      const payload = {}

      _.forEach(this.organizer, item => {
        payload[item.key] = item.value
      })

      storeOrganizer(payload).then(response => {
        if (response.data.success) {
          notify.success('Организатор создан успешно')

          this.$router.push({ name: 'ModuleAdminOPList' })
        }
      }).finally(() => {
        store.commit('setShowPreloader', false)
      })
    },
    isPasswordField(name) {
      return /password/i.test(name)
    }
  }
}
</script>

<style scoped>
.op-form {
  width: 60%;
}
</style>
