<template>
  <v-container class="fs-teacher-container" fluid grid-list-md>
    <Title layout>{{ title }} &mdash; Архив</Title>
    <v-layout row wrap>
      <v-flex>
        <v-btn
          class="fs-btn fs-admin-btn__add ma-0"
          @click="$router.push({ name: 'MunicipalityConcessions' })"
        >Скрыть архив</v-btn>
      </v-flex>
    </v-layout>
    <v-layout class="fs-teacher-order-result" wrap>
      <v-flex xs12 class="mt-2">
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1 fs-admin-table"
          item-key="id"
          hide-actions
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.index + 1 }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.price ? props.item.price.priceToString() : 0 }}</td>
              <td>
                <v-flex xs12 pa-0>
                  <v-btn
                    class="fs-btn fs-teacher-btn__delete"
                    flat
                    @click="$refs.confirmRestore.open(); restorePrivilegeIndex = props.index"
                  >Восстановить</v-btn>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
      <Modal ref="confirmRestore" title="Подтверждение">
        <p>Вы действительно хотите восстановить социальную выплату?</p>
        <p
          class="lime--text text--darken-4"
        >После подтверждения социальная выплата будет перемещена обратно в справочник.</p>
        <template v-slot:actions>
          <v-btn flat color="primary" @click="restorePrivilege">Подтвердить</v-btn>
        </template>
      </Modal>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import Title from '@/ui-components/Title'
import Modal from '@/ui-components/Modal'
import ConfirmModal from '@/components/common/Modals/ConfirmModal'
import { updatePrivilege } from '@/router/routes/department'

import { getConcessions, restorePrivilege } from '@/router/routes/department'
import { mapGetters, mapMutations } from 'vuex'
import { disableSorting } from '@/mixins/helpers'

import scrollable from '@/mixins/scrollable'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ConcessionsArchive',
  mixins: [ scrollable, disableSorting ],
  components: {
    Title,
    ConfirmModal,
    Modal
  },
  data: () => ({
    title: 'Справочник "Социальные выплаты"',
    headers: [
      { text: '#', value: 'number' },
      { text: 'Наименование', value: 'food-category' },
      { text: 'Размер соцвыплаты, руб.', value: 'concession' },
      { text: 'Операции', value: 'operations' }
    ],
    items: [],
    isShowConformModal: false,
    currentItem: null,
    restorePrivilegeIndex: null
  }),
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async handlerConfirmModal(value) {
      if (value) {
        await this.restoreArchive(this.currentItem)
        this.currentItem = null
        this.isShowConformModal = false
      } else {
        this.currentItem = null
        this.isShowConformModal = false
      }
    },
    openConfirmRestoreModal(item) {
      this.currentItem = item
      this.isShowConformModal = true
    },
    async restoreArchive(item) {
      await updatePrivilege(item.id, { is_archived: false })
        .then(response => {
          const findIndex = this.items.findIndex(el => el.id === response.data.id )
          findIndex >= 0 ? this.items.splice(this.indexLastItem, 1) : ''
          notify.success('Социальная выплата успешно восстановлена')
        })
        .finally(() => this.setShowPreloader(false))
    },
    loadMore() {
      const payload = {
        department_id: this.currentUser.department.id,
        params: {
          is_archived: true
        }
      }

      getConcessions(payload)
        .then(response => {
          const items = response.data.data
          this.items = this.items.concat(items)
        })
        .finally(() => this.setShowPreloader(false))
    },
    restorePrivilege() {
      this.$refs.confirmRestore.close()

      restorePrivilege(this.items[this.restorePrivilegeIndex].id).then(res => {
        if (res.data && res.data.success) {
          this.items.splice(this.restorePrivilegeIndex, 1)
          notify.success('Социальная выплата успешна восстановлена')
        }
      }).finally(() => {
        this.restorePrivilegeIndex = null
        this.setShowPreloader(false)
      })
    }
  },
  mounted() {
    this.loadMore()
  }
}
</script>
