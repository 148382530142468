<template>
  <v-container fluid grid-list-xl>
    <v-layout wrap align-center>
      <v-flex xs12 md6 lg3 d-flex >
          <suppliers-autocomplete v-model="supplier" :fetch-suppliers='fetchSuppliers'/>
      </v-flex>
      <v-flex xs12 md6 lg3 d-flex >
        <v-select
            v-model='onlyWithImages'
            :items="[{text: 'Все', value: false}, { text: 'Только с фото', value: true }]"
            :label="!darkTheme ? 'Фильтр по фото' : ''"
            :placeholder="darkTheme ? 'Фильтр по фото' : ''"
            :outline="darkTheme"
        ></v-select>
      </v-flex>

      <v-flex md6 sm12 d-flex >
        <v-layout wrap align-center>
          <v-flex xs6 d-flex>
            <div class="card-detail__item">
              <DateTimePicker v-model='dateStart' label="Дата от" auto-set-time clearable date-only/>
            </div>
          </v-flex>
          <v-flex xs6 d-flex>
            <div class="card-detail__item">
              <DateTimePicker v-model='dateFinish' label="Дата по" auto-set-time clearable date-only/>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>

    </v-layout>
    <v-layout class="fs-header-filter" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="qualityMenuPhotoReports.results"
          hide-actions
          no-data-text="Нет информации"
          class="elevation-1 fs-admin-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr style="cursor: pointer;" @click="openModal(props.item)">
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.date }}</td>
              <td>{{ props.item.supplier }}</td>
              <td class='text-center'>{{ props.item.images_count }}</td>
              <td v-if="!isUserDepartment">{{ props.item.menu_food_control_pk ? 'Редактировать' : 'Создать' }}</td>
              <td v-else>{{ props.item.menu_food_control_pk ? 'Просмотр' : 'Нет отчёта' }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <div class="text-xs-center" v-if="pagination_count > 1">
      <v-pagination
        @input="handlePaginationClick"
        :value="qualityMenuPhotoReportsPagination.page"
        :length="pagination_count"
        :total-visible="7"
      ></v-pagination>
    </div>
    <!-- qualityMenuPhotoReports {{ qualityMenuPhotoReports }} -->
    <v-dialog v-if="modalData.isShowModal" v-model="modalData.isShowModal" persistent>
      <QualityControlModal
        :readonly="isUserDepartment"
        :menu="modalData.menu"
        :controlId="modalData.controlId"
        @close="closeModal"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import QualityControlModal from './modal/QualityControlModal'
import SuppliersAutocomplete from '@/components/common/QualityControl/inputs/SuppliersAutocomplete.vue'
import DateTimePicker from '@/components/common/DatePickersSmart/DateTimePicker.vue'
import Vue from 'vue'

export default {
  name: '',
  components: { DateTimePicker, SuppliersAutocomplete, QualityControlModal },
  props: {
    isUserDepartment: {
      type: Boolean,
      default: false
    },
    fetchSuppliers: { type: Function }
  },
  data: () => ({
    darkTheme: Vue.config.env.darkTheme,
    headers: [
      { text: 'ID', value: 'id', sortable: false },
      { text: 'Дата', value: 'date', sortable: false },
      { text: 'Организатор', value: 'supplier', sortable: false },
      { text: 'Фотографии (кол-во)', value: 'images_count', sortable: false, width: '4' },
      { text: 'Контроль', value: 'menu_food_control_pk', sortable: false }
    ],
    supplier: null,
    dateStart: null,
    dateFinish: null,
    onlyWithImages: false,
    modalData: {
      menuId: null,
      controlId: null,
      isShowModal: false
    }
  }),
  computed: {
    ...mapGetters([ 'qualityMenuPhotoReportsPagination', 'qualityMenuPhotoReportsFilters', 'qualityMenuPhotoReports' ]),
    pagination_count() {
      return Math.ceil(this.qualityMenuPhotoReports.count / this.qualityMenuPhotoReportsPagination.page_size) || 0
    },
  },
  methods: {
    ...mapActions([ 'get_quality_menu_photo_reports' ]),
    ...mapMutations([ 'setQualityMenuPhotoReportsPagination', 'setQualityMenuPhotoReportsFilters' ]),
    handlePaginationClick(page) {
      this.setQualityMenuPhotoReportsPagination({ page: page })
      this.get_quality_menu_photo_reports()
    },
    openModal(menu) {
      this.modalData.menu = menu
      this.modalData.controlId = menu.menu_food_control_pk
      this.modalData.isShowModal = true
    },
    closeModal(needUpdate = false) {
      this.modalData.menu = null
      this.modalData.isShowModal = false
      if (needUpdate) {
        this.get_quality_menu_photo_reports()
      }
    }
  },
  watch: {
    supplier() {
      this.setQualityMenuPhotoReportsFilters({ supplier_id: this.supplier?.id })
    },
    dateStart() {
      this.setQualityMenuPhotoReportsFilters({ date_start: this.dateStart })
    },
    dateFinish() {
      this.setQualityMenuPhotoReportsFilters({ date_finish: this.dateFinish })
    },
    onlyWithImages() {
      this.setQualityMenuPhotoReportsFilters({ only_with_images: this.onlyWithImages })
    },
    qualityMenuPhotoReportsFilters() {
      this.setQualityMenuPhotoReportsPagination({ page: 1 })
      this.get_quality_menu_photo_reports()
    }
  },
  mounted() {
    this.get_quality_menu_photo_reports()
  }
}
</script>

<style>

</style>
