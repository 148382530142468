import {
  getAllDocuments, deleteDocument, createDocument,
  getDocumentDetail, updateDocument
} from '@/router/routes/admin'

export default {
  actions: {
    async getAllDocuments(ctx, payload) {
      await getAllDocuments({
        params: {
          category: 2,
          ...ctx.getters.documents_pagination,
        },
        ...payload
      })
        .then(res => {
          ctx.commit('set_all_documents', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async getAllCertificate(ctx, payload) {
      await getAllDocuments({
        params: {
          category: 1,
          ...ctx.getters.documents_pagination,
        },
        ...payload
      })
        .then(res => {
          ctx.commit('set_all_certificate', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async getDocumentDetail(ctx, id) {
      await getDocumentDetail({ id: id })
        .then(res => {
          ctx.commit('set_attachment_detail', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async deleteDocument(ctx, id) {
      return await deleteDocument({ id: id })
        .then(res => {
          return Promise.resolve("ok")
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async createDocument(ctx, payload) {
      const formData = new FormData()
      for (const key in ctx.getters.attachment_detail) {
        formData.append(key, ctx.getters.attachment_detail[key])
      }
      formData.append("category", 2)
      return await createDocument(formData)
        .then(res => {
          return Promise.resolve("ok")
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async createSertificate(ctx, payload) {
      const formData = new FormData()
      for (const key in ctx.getters.attachment_detail) {
        formData.append(key, ctx.getters.attachment_detail[key])
      }
      formData.append("category", 1)
      return await createDocument(formData)
        .then(res => {
          return Promise.resolve("ok")
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async updateDocument(ctx, id) {
      const formData = new FormData()
      for (const key in ctx.getters.attachment_detail) {
        if (key !== 'qr_code' && key !== 'file' ) formData.append(key, ctx.getters.attachment_detail[key])
        if (key === 'file') {
          if (typeof(ctx.getters.attachment_detail[key]) !== 'string') {
            formData.append(key, ctx.getters.attachment_detail[key])
          }
        }
      }
      return await updateDocument({
        formData: formData,
        id: id
      })
        .then(res => {
          return Promise.resolve("ok")
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
  },
  mutations: {
    set_all_documents(state, data) {
      state.all_documents = data
    },
    set_all_certificate(state, data) {
      state.all_certificate = data
    },
    set_document_pagination(state, data) {
      state.pagination = {
        ...state.pagination,
        ...data
      }
    },
    set_certificate_pagination(state, data) {
      state.pagination_certificate = {
        ...state.pagination_certificate,
        ...data
      }
    },
    set_attachment_detail(state, data) {
      state.attachment_detail = data
    },
    clear_attachment_detail(state, data) {
      state.attachment_detail = {
        title: '',
        description: '',
        is_published: false,
        school: null,
        file: null
      }
    },
  },
  getters: {
    all_documents(state) {
      return state.all_documents
    },
    all_certificate(state) {
      return state.all_certificate
    },
    documents_pagination(state) {
      return state.pagination
    },
    sertificate_pagination(state) {
      return state.pagination_certificate
    },
    attachment_detail(state) {
      return state.attachment_detail
    },
  },
  state: {
    all_documents: [],
    all_certificate: [],
    attachment_detail: {
      title: '',
      description: '',
      is_published: false,
      school: null,
      file: null
    },
    pagination: {
      page: 1,
      page_size: 10
    },
    pagination_certificate: {
      page: 1,
      page_size: 10
    },
  },
}
