<template>
  <v-container fluid>
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <v-flex mb-3>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout pa-0>
      <v-btn
        flat
        @click="$router.go(-1)"
        class="ma-0 mb-3 primary"
      >Назад</v-btn>
    </v-layout>
    <v-layout
      row
      mb-12
      justify-start
      text-md-center
    >
      <v-flex md4>
        <v-autocomplete
          v-model="selectedDepartmentID"
          @change="changeDepartment"
          :items="departments"
          item-text="name"
          item-value="id"
          label="Департамент:"
          clearable
        />
      </v-flex>
    </v-layout>

    <v-layout
      md8
      offset-md2
      class="fs-organizer-school-result"
      column
    >
      <v-flex
        v-show="false"
        class="fs-organizer-school-result__header"
      >
        <h4 mx-auto>Результаты поиска</h4>
      </v-flex>
      <v-layout row>
        <v-flex>
          <v-data-table
            :items="schools"
            hide-actions
            class="elevation-1"
            hide-headers
          >
            <template v-slot:items="props">
              <td>{{ props.item.name }}</td>
              <td>
                <v-checkbox
                  class="fs-checkbox"
                  v-model="props.item.is_connected"
                  @change="toggleSchoolAccess(props.item)"
                  primary
                  hide-details
                ></v-checkbox>
              </td>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import { mapMutations } from 'vuex'
import {
  getAccessSchools,
  getDepartments,
  toggleSchoolAccess
} from '@/router/routes/superadmin'
import scrollable from '@/mixins/scrollable'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'NotificationCreateSupplier',
  data() {
    return {
      title: 'Ограничение доступа',
      schools: [],
      departments: [],
      selectedDepartmentID: null,
      LIMIT: Vue.config.general.limit
    }
  },
  mixins: [ scrollable ],
  created() {
    this.init()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async init() {
      await this.loadMore()
      await this.getDepartments()
      window.addEventListener('scroll', this.scrollHandler)
    },
    async changeDepartment() {
      this.schools = []
      this.resetScrollHandler()
      await this.loadSchools()
    },
    resetScrollHandler(){
      this.removeScrollHandler()
      window.addEventListener('scroll', this.scrollHandler)
    },
    async loadMore() {
      await this.loadSchools()
    },
    async loadSchools() {
      try {
        const PAYLOAD = {
          limit: this.LIMIT,
          offset: this.schools.length,
          department: this.selectedDepartmentID
        }
        const response = await getAccessSchools(PAYLOAD)
        const {
          data: { data: items }
        } = response
        this.schools = [ ...this.schools, ...items ]
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getDepartments() {
      try {
        const {
          data: { data: items }
        } = await getDepartments()
        this.departments = items
      } catch (error) {
        // notify.error('Ошибка загрузки департаментов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async toggleSchoolAccess(item) {
      let response = null
      try {
        store.commit('setShowPreloader', true)
        response = await toggleSchoolAccess( item.id )
      }
      catch (error) {
        // notify.error('Ошибка изменения доступа школы') // backend handler added (exception_message)
      } finally {
        this.schools = this.schools
          .map((school) =>
            school.id === item.id ?
              {
                ...school,
                is_connected: response.data.data.is_connected
              }
              : school
          )
        store.commit('setShowPreloader', false)
      }
    },
  },
  watch: {
    schools(items) {
      this.savePosition(items)
    }
  }
}
</script>
