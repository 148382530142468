<template>
  <v-container
    fluid
    class="fs-moduleadmin-container"
  >
    <v-layout
      class="fs-moduleadmin-order"
      row
      mb-3
    >
      <v-flex mb-3>
        <h1>Заявки на питание</h1>
      </v-flex>
    </v-layout>
    <v-layout
      row
      mb-12
      justify-start
      text-md-center
    >
      <v-flex md3 mr-3>
        <v-autocomplete
          :value="filters.schoolId"
          @change="changeSchool"
          :items="filters.schools"
          item-text="name"
          item-value="id"
          label="Школа:"
        />
      </v-flex>
      <v-flex md3 mr-3>
        <v-autocomplete
          :disabled="!filters.schoolId"
          :value="filters.classId"
          @change="changeClass"
          :items="filters.classes"
          item-text="name"
          item-value="id"
          label="Класс:"
        />
      </v-flex>
      <v-flex md3 mr-3>
        <v-menu
          v-model="filters.datepickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filters.date"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            class="fs-datepicker"
            prev-icon="keyboard_arrow_left"
            next-icon="keyboard_arrow_right"
            locale="ru"
            first-day-of-week="1"
            no-title
            v-model="filters.date"
            @input="selectDate"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex md3 mr-3>
        <v-btn
          flat
          class="fs-btn fs-teacher-btn__show v-btn--active"
          @click="loadOrders"
          :disabled="!filters.schoolId"
        >Показать
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      row
      mb-12
      justify-start
      text-md-center
      class="flex-padding"
    >
      <v-btn
        flat
        class="fs-btn fs-teacher-btn__show v-btn--active"
        @click="modals.issueClassOrders = true"
        :disabled="!actions.canIssueClassOrders || !filters.classId"
      >
        Выдать заказы классу
      </v-btn>
      <v-btn
        flat
        class="fs-btn fs-teacher-btn__show v-btn--active"
        @click="setSubsidy"
        :disabled="!filters.schoolId || !filters.classes.length || !items.length || !actions.canSetSubsidy"
      >
        Поставить на субсидию
      </v-btn>
      <v-btn
        flat
        class="fs-btn fs-teacher-btn__show v-btn--active"
        @click="cancelOrders"
        :disabled="!filters.schoolId || !filters.classes.length || !items.length"
        v-show="showCancelButton"
      >
        Отменить заказы
      </v-btn>
      <v-btn
        flat
        class="fs-btn fs-teacher-btn__show v-btn--active"
        @click="cancelSubsidy"
        :disabled="!filters.schoolId || !actions.canCancelSubsidy || !filters.classes.length || !items.length"
      >
        Отменить субсидию
      </v-btn>
      <v-btn
        flat
        class="fs-btn fs-teacher-btn__show v-btn--active"
        @click="setDryFood"
        :disabled="!filters.schoolId || !filters.classes.length || !items.length || !actions.canSetDryFood"
      >
        Поставить на сух. паек
      </v-btn>
      <v-btn
        flat
        class="fs-btn fs-teacher-btn__show v-btn--active"
        @click="cancelDryFood"
        :disabled="!filters.schoolId || !actions.canCancelDryFood || !filters.classes.length || !items.length"
      >
        Отменить сух. паек
      </v-btn>
    </v-layout>
    <br/>
    <v-layout
      class="fs-teacher-order-result"
      mb-3
      justify-space-around
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :pagination.sync="pagination"
          :items="items"
          hide-actions
          no-data-text="Нет данных"
          class="elevation-1 fs-teacher-table"
          item-key="id"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.schoolClassName }}</td>
              <td>{{ props.item.pupil.fullName }}</td>
              <td>
                {{ props.item.foodType.name }}
                <v-icon v-if="props.item.canChangeFoodType" class="fs-active-icon" @click="changeFoodType(props.item)">edit</v-icon>
              </td>
              <td>{{ props.item.complect.name }}</td>
              <td>{{ props.item.privilege.name }}</td>
              <td>
                {{ props.item.status.name }}
                <span v-if="props.item.status.autoReceived">
                  (без списания)
                </span>
                <v-icon v-if="props.item.canIssueOrder" class="fs-active-icon" @click="issueOrder(props.item)" title="Выдать заказ">edit</v-icon>
                <v-icon v-if="props.item.canCancelOrder" class="fs-active-icon" @click="cancelOrder(props.item)" title="Отменить выдачу заказа">edit</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <ConfirmModal
      :dialog.sync="modals.issueClassOrders"
      @confirm="issueClassOrders"
    >
      <template v-slot:headline>Подтверждение выдачи заказов</template>
      <template v-slot:body>
        Вы подтверждаете выдачу заказов для выбранного класса?
        <v-text-field
          label="Комментарий"
          v-model="comment"
        ></v-text-field>
      </template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="modals.changeFoodType.show"
      :confirmBtnText="'Смена типа питания'"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="confirmChangeFoodType"
    >
      <template v-slot:headline>Смена типа питания {{ modals.changeFoodType.order ? modals.changeFoodType.order.pupil.fullName : null }}</template>
      <template v-slot:body>
        <v-select
          no-data-text="Ничего не выбрано"
          v-model="modals.changeFoodType.foodTypeId"
          item-text="name"
          item-value="id"
          :items="modals.changeFoodType.foodTypes"
          label=""
        />
        <v-text-field
          label="Комментарий"
          v-model="comment"
        ></v-text-field>
      </template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="modals.issueOrder.show"
      :confirmBtnText="'Выдача заказа'"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="confirmIssueOrder"
    >
      <template v-slot:headline>Подтверждение выдачи заказа</template>
      <template v-slot:body>
        Вы подтверждаете <b>выдачу заказа</b> {{ modals.issueOrder.order ? modals.issueOrder.order.pupil.fullName : null }}?
        <v-text-field
          label="Комментарий"
          v-model="comment"
        ></v-text-field>
      </template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="modals.cancelOrder.show"
      :confirmBtnText="'Отмена выдачи заказа'"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="confirmCancelOrder"
    >
      <template v-slot:headline>Подтверждение отмены выдачи заказа</template>
      <template v-slot:body>
        Вы подтверждаете <b>отмену выдачи заказа</b> {{ modals.cancelOrder.order ? modals.cancelOrder.order.pupil.fullName : null }}?
        <v-text-field
          label="Комментарий"
          v-model="comment"
        ></v-text-field>
      </template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="modals.massChangeMealTypes.show"
      :confirmBtnText="modals.massChangeMealTypes.text"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="confirmMassChangeMealType"
    >
      <template v-slot:headline>{{ modals.massChangeMealTypes.text }}</template>
      <template v-slot:body>
        <v-select
          no-data-text="Ничего не выбрано"
          v-model="modals.massChangeMealTypes.classIds"
          item-text="name"
          item-value="id"
          :items="filters.classes"
          label="Классы:"
          deletable-chips
          chips
          multiple
        >
          <template v-slot:prepend-item>
            <v-list-tile
              ripple
              @click="selectAllClasses"
            >
              <v-list-tile-action>
                <v-icon :color="modals.massChangeMealTypes.classIds.length > 0 ? 'primary' : ''">{{
                  modals.massChangeMealTypes.classIds.length === filters.classes.length
                  ? 'check_box'
                  : 'check_box_outline_blank'
                  }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Выбрать всё</v-list-tile-title>
                <v-list-tile-sub-title>Выбрано:
                  {{ modals.massChangeMealTypes.classIds.length }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
        <v-text-field
          label="Комментарий"
          v-model="comment"
        ></v-text-field>
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import moment from 'moment'
import {
  getOrders,
  getSchools,
  getClasses,
  issueClassOrders,
  getMealTypes,
  changeMealType,
  issueOrder,
  cancelOrder,
  setSubsidy,
  cancelSubsidy,
  setDryFood,
  cancelDryFood, cancelClassOrders
} from '@/router/routes/superadmin'
import { mapGetters, mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import _ from 'lodash'
import { ConfirmModal } from '@/components/common'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'SuperadminOrdersIndex',
  components: {
    ConfirmModal
  },
  data() {
    return {
      pagination: {
        rowsPerPage: -1
      },
      headers: [
        { text: 'id', value: 'id' },
        { text: 'Класс', value: 'schoolClassName' },
        { text: 'Ученик', value: 'pupil.fullName' },
        { text: 'Тип питания', value: 'foodType.name' },
        { text: 'Комплекс', value: 'complect.name' },
        { text: 'Льгота', value: 'privilege.name' },
        { text: 'Статус', value: 'status' },
      ],
      items: [],
      showCancelButton: store.getters.setting('super_admin_cancel_order_button'),
      filters: {
        date: moment().format(Vue.config.format.serverDate),
        schoolId: null,
        schools: [],
        classes: [],
        classId: null,
        datepickerMenu: false,
      },
      actions: {
        canIssueClassOrders: false,
        canSetDryFood: false,
        canCancelDryFood: false,
        canCancelSubsidy: false,
        canSetSubsidy: false
      },
      comment: '',
      modals: {
        issueClassOrders: false,
        changeFoodType: {
          show: false,
          order: null,
          foodTypes: [],
          foodTypeId: null
        },
        issueOrder: {
          show: false,
          order: null
        },
        cancelOrder: {
          show: false,
          order: null
        },
        massChangeMealTypes: {
          show: false,
          text: null,
          method: null,
          classIds: []
        }
      }
    }
  },
  computed: {
    dateNormal() {
      const date = this.filters.date.split('-')
      const day = date[2]
      const month = date[1]
      const year = date[0]
      return `${day}-${month}-${year}`
    },
    dateParts() {
      const date = this.filters.date.split('-')
      return {
        year: date[0],
        month: date[1],
        day: date[2]
      }
    }
  },
  created() {
    this.getSchools()
  },
  methods: {
    ...mapGetters([ 'setting' ]),
    ...mapMutations( [ 'setShowPreloader' ] ),
    selectDate(date) {
      this.filters.datepickerMenu = false
      this.filters.date = date
    },
    async cancelOrders() {
      if (this.filters.classId && this.items.length && this.filters.classes.length) {
        const payload = {
          orders: this.items.map(item => item.id)
        }
        const response = await cancelClassOrders(payload)
        if (response.data.success) {
          notify.success('Заказы успешно отменены')
        } else {
          // notify.error('Произошла ошибка при попытке отменить заказы') // backend handler added (exception_message)
        }
        store.commit('setShowPreloader', false)
        await this.loadOrders()
      }
    },
    async loadOrders() {
      try {
        this.comment = ''
        const payload = {
          params: {
            school: this.filters.schoolId,
            schoolClass: this.filters.classId,
            orderDay: this.dateNormal,
          }
        }
        const response = await getOrders(payload)
        let canIssueClassOrders = false
        let canCancelDryFood = false
        let canSetDryFood = false
        let canCancelSubsidy = false
        let canSetSubsidy = false
        this.items = _.map(response.data.data, item => {
          const autoReceived = item.status === Vue.config.general.orderStatuses.complete && item.status_received_is_auto
          if (autoReceived) {
            canIssueClassOrders = true
          }
          if (item.status === Vue.config.general.orderStatuses.complete) {
            canSetDryFood = true
            canSetSubsidy = true
            if (item.order_changed_is_dry_food) {
              canCancelDryFood = true
            }
            if (item.order_changed_is_subsidy) {
              canCancelSubsidy = true
            }
          }
          return {
            id: item.id,
            date: moment(item.orderDay, 'DD-MM-YYYY').format(
              Vue.config.format.date
            ),
            status: {
              id: item.status,
              autoReceived,
              name: Vue.config.general.orderStatusesNames[item.status]
            },
            pupil: item.pupil,
            schoolClassName: item.schoolClass.name,
            foodType: item.foodType,
            complect: item.complect,
            privilege: item.privilege,
            canChangeFoodType: item.status === Vue.config.general.orderStatuses.complete,
            canIssueOrder: item.status === Vue.config.general.orderStatuses.cancel,
            canCancelOrder: item.status === Vue.config.general.orderStatuses.complete,
            supplier: item.supplier
          }
        })
        this.actions.canIssueClassOrders = canIssueClassOrders
        this.actions.canCancelDryFood = canCancelDryFood
        this.actions.canCancelSubsidy = canCancelSubsidy
        this.actions.canSetDryFood = canSetDryFood
        this.actions.canSetSubsidy = canSetSubsidy
      } catch (error) {
        // notify.error('Не удалось загрузить заявки') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getSchools() {
      try {
        const response = await getSchools()
        const {
          data: { data: items }
        } = response
        this.filters.schools = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getClasses() {
      try {
        const payload = {
          school_id: this.filters.schoolId
        }
        const response = await getClasses(payload)
        const {
          data: { data: items }
        } = response
        this.filters.classes = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async changeSchool(value) {
      this.filters.schoolId = value
      this.filters.classId = null
      this.filters.classes = []
      this.actions.canIssueClassOrders = false
      await this.getClasses()
    },
    changeClass(value) {
      this.filters.classId = value
      this.actions.canIssueClassOrders = false
    },
    async issueClassOrders(value) {
      if (value && !this.comment) {
        return
      }
      if (!value) {
        return
      }
      const date = this.dateParts
      try {
        const payload = {
          classId: this.filters.classId,
          year: date.year,
          month: date.month,
          day: date.day,
          data: {
            comment: this.comment
          }
        }
        await issueClassOrders(payload)
        this.modals.issueClassOrders = false
        await this.loadOrders()
      } catch (error) {
        // notify.error('Не удалось выдать заявки') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    changeFoodType(order) {
      this.modals.changeFoodType.show = true
      this.modals.changeFoodType.foodTypes = []
      this.modals.changeFoodType.order = order
      getMealTypes(order.supplier).then(response => {
        this.modals.changeFoodType.foodTypes = response.data.data
      }).finally(() => {
        store.commit('setShowPreloader', false)
      })
    },
    async confirmChangeFoodType(value) {
      if (value && !this.comment) {
        return
      }
      const mealTypeId = this.modals.changeFoodType.foodTypeId
      const orderId = this.modals.changeFoodType.order.id
      this.modals.changeFoodType.show = false
      this.modals.changeFoodType.foodTypes = []
      this.modals.changeFoodType.foodTypeId = null
      if (!value) {
        return
      }
      if (!mealTypeId) {
        return
      }
      try {
        await changeMealType(orderId, mealTypeId, this.comment)
        await this.loadOrders()
      } catch (error) {
        // notify.error(`Не удалось сменить тип питания ${error}`) // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    issueOrder(order) {
      this.modals.issueOrder.show = true
      this.modals.issueOrder.order = order
    },
    cancelOrder(order) {
      this.modals.cancelOrder.show = true
      this.modals.cancelOrder.order = order
    },
    async confirmIssueOrder(value) {
      if (value && !this.comment) {
        return
      }
      const id = this.modals.issueOrder.order.id
      this.modals.issueOrder.show = false
      this.modals.issueOrder.order = null
      if (!value) {
        return
      }
      try {
        await issueOrder(id, this.comment)
        await this.loadOrders()
      } catch (error) {
        // notify.error(`Не удалось выдать заказ`) // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async confirmCancelOrder(value) {
      if (value && !this.comment) {
        return
      }
      const id = this.modals.cancelOrder.order.id
      this.modals.cancelOrder.show = false
      this.modals.cancelOrder.order = null
      if (!value) {
        return
      }
      try {
        await cancelOrder(id, this.comment)
        await this.loadOrders()
      } catch (error) {
        // notify.error(`Не удалось отменить выдачу заказа`) // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    selectAllClasses() {
      if (this.modals.massChangeMealTypes.classIds.length) {
        this.modals.massChangeMealTypes.classIds = []
        return
      }
      this.modals.massChangeMealTypes.classIds = this.filters.classes.map(item => item.id)
    },
    setDryFood() {
      this.modals.massChangeMealTypes.show = true
      this.modals.massChangeMealTypes.text = 'Поставить на сух. паек'
      this.modals.massChangeMealTypes.method = setDryFood
      this.modals.massChangeMealTypes.classIds = []
    },
    setSubsidy() {
      this.modals.massChangeMealTypes.show = true
      this.modals.massChangeMealTypes.text = 'Поставить на субсидию'
      this.modals.massChangeMealTypes.method = setSubsidy
      this.modals.massChangeMealTypes.classIds = []
    },
    cancelDryFood() {
      this.modals.massChangeMealTypes.show = true
      this.modals.massChangeMealTypes.text = 'Отменить на сух. паек'
      this.modals.massChangeMealTypes.method = cancelDryFood
      this.modals.massChangeMealTypes.classIds = []
    },
    cancelSubsidy() {
      this.modals.massChangeMealTypes.show = true
      this.modals.massChangeMealTypes.text = 'Отменить субсидию'
      this.modals.massChangeMealTypes.method = cancelSubsidy
      this.modals.massChangeMealTypes.classIds = []
    },
    async confirmMassChangeMealType(value) {
      if (value && !this.comment) {
        return
      }
      this.modals.massChangeMealTypes.show = false
      const method = this.modals.massChangeMealTypes.method
      const text = this.modals.massChangeMealTypes.text
      this.modals.massChangeMealTypes.method = null
      this.modals.massChangeMealTypes.text = null
      if (!value) {
        return
      }
      if (!this.modals.massChangeMealTypes.classIds.length) {
        return
      }
      const date = this.dateParts
      const payload = {
        year: date.year,
        month: date.month,
        day: date.day,
        schoolId: this.filters.schoolId,
        classIds: this.modals.massChangeMealTypes.classIds,
        comment: this.comment
      }
      try {
        await method(payload)
        await this.loadOrders()
      } catch (error) {
        // notify.error(`Не удалось ${text}: ${error}`) // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>
