export default class NotificationPeriodsFactory {
  constructor() {
    return [
      {
        id: 1,
        name: 'Два раза в день'
      },
      {
        id: 2,
        name: 'Один раз в день'
      },
      {
        id: 3,
        name: 'Раз в два дня'
      },
      {
        id: 4,
        name: 'Раз в неделю'
      }
    ]
  }
}
