<template>
  <v-container class="fs-organizer-container">
    <v-form ref="storehouseForm" v-model="valid">
      <v-layout md8 offset-md2 mb-5 :key="keyUpdate">
        <v-flex>
          <v-flex mb-5>
            <h1>{{!this.is_create ? `Редактировать склад`: `Создать склад`}}</h1>
          </v-flex>
          <div>
            <v-text-field
              v-model="name"
              :rules="[rules.required]"
              label="Наименование склада"
              readonly
            />

            <v-select
              :items="schools"
              item-text="name"
              item-value="id"
              label="Школы"
              return-object
              v-model="card_schools"
              :rules="[rules.required]"
              readonly
            ></v-select>

            <StorehouseProducts v-if="!this.is_create" :storehouse_id="storehouse_card.id"/>
          </div>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex text-md-right>
          <v-btn
            flat
            class="fs-btn v-btn--active"
            @click="handleSaveBtn()"
          >Cохранить</v-btn>
          <v-btn
            flat
            class="fs-btn v-btn--active"
            @click="$router.go(-1)"
          >Назад</v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DateTimePicker from '@/components/common/DatePickersSmart/DateTimePicker'
import StorehouseProducts from '@/components/organizer/Storehouse/StorehouseProducts'
import { getSchools } from '@/router/routes/organizer.js'
import Vue from 'vue'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'StorehouseOrganizerDetail',
  data: () => ({
    datepickerMenu: false,
    is_create: false,
    keyUpdate: 0,
    schools: [],
    valid: true,
    rules: {
      required: (v) => !!v || "Поле обязательное для заполнения"
    },
  }),
  components: { DateTimePicker, StorehouseProducts },
  computed: {
    ...mapGetters([ 'storehouse_card' ]),
    card_schools: {
      get () {
        return this.storehouse_card.school
      },
      set (value) {
        this.set_storehouse_card({
          ...this.storehouse_card,
          school: value
        })
      }
    },
    name: {
      get () {
        return this.storehouse_card.name
      },
      set (value) {
        this.set_storehouse_card({
          ...this.storehouse_card,
          name: value
        })
      }
    },
  },
  async mounted() {
    this.$route.params.id ? this.is_create = false : this.is_create = true
    if (!this.is_create) await this.fetchStorehousesCard({ card_id: this.$route.params.id, params: {} })
    await this.getSchools()
    this.keyUpdate++
  },
  destroyed() {
    this.set_storehouse_card({})
  },
  methods: {
    ...mapMutations([ 'set_storehouse_card' ]),
    ...mapActions([ 'fetchStorehousesCard', 'updateStorehouseCard', 'createStorehouseCard' ]),
    async update() {
      const card = {
        ...this.storehouse_card,
        school: typeof this.card_schools === "number" ? this.card_schools : this.card_schools.id
      }
      await this.updateStorehouseCard({ card_id: this.$route.params.id, data: card })
        .then(() => {
          this.$router.push({ name: 'StorehouseOrganizer' })
        })
    },
    async getSchools() {
      try {
        const PAYLOAD = {
          limit: this.LIMIT,
          offset: this.offset
        }
        const response = await getSchools(PAYLOAD)
        const { data: { data: items } } = response
        const itemsArray = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
        this.schools = [ ...this.schools, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        this.$store.commit('setShowPreloader', false)
      }
    },
    async create() {
      await this.createStorehouseCard({ data: {
        ...this.storehouse_card,
        school: this.storehouse_card.school.id
      } })
        .then(() => {
          this.$router.push({ name: 'Storehouse' })
        })
    },
    handleSaveBtn() {
      this.$refs.storehouseForm.validate()
      if (this.valid) {
        if (this.is_create) this.create()
        else this.update()
      }
    }
  }
}
</script>

<style scoped>

</style>
