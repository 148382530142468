export default {
  showPreloader: state => {
    return state.showPreloader
  },
  asyncLoadFile: state => {
    return state.asyncLoadFile
  },
  asyncLoadFileWithProgressBar: state => {
    return state.asyncLoadFileWithProgressBar
  },
  asyncLoadFileLoaderProgressBarValue: state => {
    return state.asyncLoadFileLoaderProgressBarValue
  },
  asyncRequestWithProgressBar: state => {
    return state.asyncRequestWithProgressBar
  },
  asyncRequestWithProgressBarValue: state => {
    return state.asyncRequestProgressBarValue
  },
  loaderMessage: state => {
    return state.loaderMessage
  },
  currentUser: state => {
    return state.currentUser
  },
  currentChild: state => {
    return state.currentChild
  },
  items: state => {
    return state.items
  },
  notVisitedIds: state => {
    return state.notVisitedIds
  }
}
