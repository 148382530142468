<template>
  <v-form ref="productForm" v-model="valid">
    <v-card>
      <v-toolbar elevation="1" dense light>
        <v-toolbar-title>{{ isCreate ? `Создать` : `Редактировать` }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.prevent="handleClose">
          <v-icon class="fs-active-icon">close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-text-field v-model="form.name" :rules="[rules.required]" label="Наименование продукта:" />
        <v-autocomplete
          v-model="form.category"
          :items="productCategories"
          :item-text="'name'"
          :item-value="'id'"
          clearable
          flat
          dense
          label="Категория продукта"
        />

        <v-text-field v-model="form.description" label="Описание продукта:" />
        <v-text-field v-model="form.count" type="number" label="Кол-во продукта:" />
        <v-text-field v-model="form.price" type="number" label="Цена за ед. продукта:" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="handleClickSaveOrUpdate" color="primary">
          {{ isCreate ? `Создать` : `Обновить` }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import { getOrganizerInfo, createBuffetProductDict, editBuffetProductDict, getBUffetProductDict, fetchCategoryOP } from '@/router/routes/organizer.js'
import scrollable from '@/mixins/scrollable'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ProductDictDetail',
  mixins: [ scrollable ],
  props: {
    isCreate: {
      type: Boolean,
      default: true
    },
    productId: {
      default: null
    }
  },
  data: () => ({
    rules: {
      required: (v) => !!v || "Поле обязательное для заполнения"
    },
    valid: true,
    productCategories: [],
    form: {
      description: "",
      name: "",
      count: 0,
      price: 0,
      deleted: null, // ?
      category: null,
      supplier: null
    },
    LIMIT: Vue.config.general.limit
  }),
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  async mounted() {
    await this.loadProductCategories()
    await this.fetchCurrentOrganizer()
    if (!this.isCreate && this.productId) {
      this.fetchProduct()
    }
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    handleClose() {
      this.form = {
        ...this.form,
        description: "",
        name: "",
        count: 0,
        price: 0,
        deleted: null, // ?
        category: null
      }
      this.$emit('close')
    },
    async fetchCurrentOrganizer() {
      await getOrganizerInfo()
        .then(res => {
          this.form.supplier = res.data.data.id
        }).catch(err => {
          console.error(err)
          // notify.error(`Ошибка создания продукта`) // backend handler added (exception_message)
        }).finally(() => {
          this.setShowPreloader(false)
        })
    },
    handleClickSaveOrUpdate() {
      this.$refs.productForm.validate()
      if (this.valid) {
        if (this.isCreate) {
          createBuffetProductDict(this.form).then(res => {
            notify.success(`Продукт в справочник добавлен успешно`)
            this.handleClose()
            this.$emit('update')
          }).catch(err => {
            console.error(err)
            // notify.error(`Ошибка создания продукта`) // backend handler added (exception_message)
          }).finally(() => {
            this.setShowPreloader(false)
          })
        } else {
          editBuffetProductDict({
            id: this.form.id,
            data: this.form
          }).then(res => {
            notify.success(`Продукт в справочнике изменён успешно`)
            this.handleClose()
            this.$emit('update')
          }).catch(err => {
            console.error(err)
            // notify.error(`Ошибка изменения продукта в справочнике`) // backend handler added (exception_message)
          }).finally(() => {
            this.setShowPreloader(false)
          })
        }
      }
    },
    async fetchProduct() {
      await getBUffetProductDict(this.productId)
        .then(res => {
          this.form = {
            ...res.data,
            category: res.data.category.id
          }
        }).catch(err => {
          console.error(err)
          // notify.error(`Ошибка получения продукта`) // backend handler added (exception_message)
        }).finally(() => {
          this.setShowPreloader(false)
        })
    },
    async loadProductCategories() {
      try {
        const response = await fetchCategoryOP({ params: { limit: this.LIMIT, offset: this.offset } })
        const itemsArray = response.data.results.map(item => ({ id: item.id, name: item.title }))
        this.productCategories = [ ...this.productCategories, ...itemsArray ]
        this.form.category = itemsArray.length ? itemsArray[0].id : null
      } catch (error) {
        console.error(error)
        // notify.error('Ошибка загрузки категорий продуктов') // backend handler added (exception_message)
      } finally {
        this.setShowPreloader(false)
      }
    },
  }
}
</script>

<style>

</style>
