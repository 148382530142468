<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <v-layout
      row
      mb-12
      justify-start
      text-md-center
    >
      <v-flex mb-4>
        <v-autocomplete
          v-model="schoolId"
          :items="schools"
          item-text="short_name"
          item-value="id"
          label="Выберите школу:"
          clearable
        />
      </v-flex>
    </v-layout>

    <AdminBalanceMoving
      v-if="schoolId"
      :key="schoolId"
      :municipality-view="true"
      :municipality-school-id="schoolId"
      :municipality-school-name="schoolName"
    />
  </v-container>
</template>

<script>
import AdminBalanceMoving from '@/components/admin/Balance/Moving/Index'
import {
  getSchools
} from '@/router/routes/department'
import { mapGetters, mapMutations } from "vuex"
import store from "@/store"

export default {
  name: 'MunicipalityOrders',
  components: {
    AdminBalanceMoving
  },
  data() {
    return {
      schools: [],
      schoolId: null
    }
  },
  created() {
    this.loadSchools()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    loadSchools() {
      const payload = {
        department_id: this.currentUser.department.id,
        params: {
          limit: 1000,
          offset: 0
        }
      }
      getSchools(payload)
        .then(response => {
          this.schools = response.data.data
        })
        .finally(() => store.commit('setShowPreloader', false))
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
    ]),
    schoolName() {
      if (!this.schoolId) {
        return null
      }
      const school = this.schools.find(s => s.id === this.schoolId)
      return school ? school.short_name : null
    }
  },
}
</script>
