<template>
  <v-container fluid class="fs-organizer-container">
    <v-layout class="fs-dish" row>
      <div class="mb-3">
        <div class="d-flex">
          <h1 style="width: 30%">{{ !archiveView ? title : 'Архив блюд' }}</h1>
          <context-help-modal :context-key="!archiveView ? contextKeys.supplier.food.list : contextKeys.supplier.food.archive_list" />
        </div>
        <div class="d-flex">
          <v-btn
            flat
            class="fs-btn mt-3"
            @click="$router.push({ name: `Dishes${archiveView ? '' : 'Archive'}` })"
          >{{ archiveView ? 'Скрыть архив' : 'Показать архив' }}</v-btn>
          <v-text-field
              class="ml-3"
              label="Поиск блюда"
              append-icon="search"
              clear-icon="clear"
              clearable
              v-model="searchDishField"
              @keyup.enter="searchDishes"
              @click:append="searchDishes"
              @click:clear="clearSearchDishes"
            ></v-text-field>
        </div>
      </div>
      <v-flex v-if="!archiveView" md9 text-md-right>
        <upload-btn
          :ripple="false"
          :fileChangedCallback="fileChanged"
          title="Загрузить в Excel"
        ></upload-btn>
        <v-btn
          :to="{ name: 'DishesCreate' }"
          class="fs-btn--primary"
          flat
        >Добавить блюдо</v-btn>
        <v-btn
          class="fs-btn--primary"
          flat
          @click="addAdditionalsDishes"
        >Добавить в меню доп. блюда</v-btn>
        <v-btn
            v-if="foodCopy"
            class="fs-btn--primary"
            @click="showFoodCopy"
        >Копирование блюд</v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      class="fs-dish-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items.sync="items"
          class="elevation-1"
          item-key="id"
          :disable-initial-sort="true"
          no-data-text="Нет блюд"
          select-all
          hide-actions
        >
          <template v-slot:items="props">
            <tr>
              <td>
                <v-checkbox
                  class="fs-checkbox"
                  color="#66717f"
                  v-model="props.selected"
                  primary
                  hide-details
                ></v-checkbox>
              </td>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.name }}</td>
              <td v-if = "setting('recipe_food_category')"> {{getFoodCategory(props.item.food_category)}}</td>
              <td v-if = "setting('recipe_food_category')"> {{ props.item.recipe }}</td>
              <td>{{ props.item.weight_primary }}</td>
              <td>{{ props.item.weight_middle }}</td>
              <td>{{ props.item.energy }}</td>
              <td>{{ props.item.protein }}</td>
              <td>{{ props.item.fats }}</td>
              <td>{{ props.item.carbs }}</td>
              <td>{{ (props.item.price / 100) || '-' }}</td>
              <td>
                <v-flex row>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="navigateTo('DishesEdit', props.item.id)"
                      >edit</v-icon>
                    </template>
                    <span>Редактирование</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        v-if="!archiveView"
                        class="fs-active-icon"
                        @click="singleArchive(props.item.id)"
                      >archive</v-icon>
                    </template>
                    <span>Переместить в архив</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        v-if="archiveView"
                        class="fs-active-icon"
                        @click="singleUnarchive(props.item.id)"
                      >unarchive</v-icon>
                    </template>
                    <span>Вернуть из архива</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-flex text-md-right v-if="!archiveView">
      <v-btn
        :disabled="!selected.length"
        class="fs-btn fs-btn--secondary fs-btn__delete"
        flat
        @click="massDelete()"
      ><v-icon dark left>delete</v-icon>Удалить выбранное</v-btn>
    </v-flex>
    <ConfirmModal
        :dialog.sync="dialogs.modalFoodCopy"
        :confirm-btn-text="'Скопировать'"
        :btns-class="'fs-btn fs-btn--primary'"
        @confirm="postFoodCopy"
        :disable-confirm-button="anhoterKey.length === 0"
    >
      <template v-slot:headline>Копирование блюд
        <context-help-modal :context-key="contextKeys.supplier.food.food_copy"/>
      </template>
      <template v-slot:body>
        <v-container class="body-2">
          <div class="flex">
            <div class="mb-3">
              <h3 style="color: #949494">Информация для переноса блюд</h3>
            </div>
            <div>
              <span>Ваш уникальный ключ:</span>
            </div>
            <div>
              <v-text-field
                prepend-icon="vpn_key"
                readonly
                :value="foodCopyKey_str"
              ></v-text-field>
            </div>
            <div>
              <span style="color: #949494; font-size: 12px">
                Поделитесь этим ключом с другим организатором питания, что-бы он скопировал ваши "Блюда"</span>
            </div>
            <div class="my-4">
              <v-divider></v-divider>
            </div>
            <div class="mb-3">
              <h3 style="color: #949494">Выполнить перенос блюд</h3>
            </div>
            <div>
              <input type="checkbox" id="delFood" v-model="deleteFood"
              class="display-1">
              <label for="delFood">
                Удалить/переместить в архив текущие блюда
              </label>
            </div>
            <div>
              <v-text-field
                  prepend-icon="vpn_key"
                  placeholder="Введите ключ"
                  v-model="anhoterKey"
                  clearable
              ></v-text-field>
            </div>
            <div class="mb-2 red--text" v-if="deleteFood">
              Текущие блюда будут удалены или перемещены в архив по возможности
            </div>
            <div class="mb-2 green--text" v-else>
              Текущие блюда останутся и добавятся новые от владельца ключа
            </div>
        </div>
          </v-container>
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import {
  getDishes,
  getDishesSearch,
  getArchivedDishes,
  deleteSelectedDishes,
  deleteDish,
  uploadDishesFile,
  setAdditionalMenu,
  archiveOneDish,
  unarchiveOneDish,
  getFoodCopyKey, createDish, postFoodCopyKey,
} from '@/router/routes/organizer'

import store from '@/store'
import Vue from 'vue'
import _ from 'lodash'
import UploadButton from '@/components/common/UploadButton/UploadButton'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import ConfirmModal from "@/components/common/Modals/ConfirmModal"
import notify from '@/utils/notifications/toastNotification'
import {mapGetters} from "vuex";

export default {
  name: 'DishIndex',
  components: {
    ConfirmModal,
    ContextHelpModal,
    'upload-btn': UploadButton
  },
  props: {
    archiveView: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      title: 'Блюда',
      contextKeys: Vue.config.contextHelp,
      foodCopy: store.getters.setting('supplier_food_copy'),
      foodCopyKey_str: '',
      deleteFood: false,
      anhoterKey: '',
      dialogs: {
        modalFoodCopy: false,
      },
      selected: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Наименование', value: 'name' },
        { text: 'Вес для возрастной категории 7-10 лет, г', value: 'weight_primary' },
        { text: 'Вес для возрастной категории 11-18 лет, г', value: 'weight_middle' },
        { text: 'Энергетическая ценность, кКал/кДж', value: 'energy' },
        { text: 'Белки, г', value: 'protein' },
        { text: 'Жиры, г', value: 'fats' },
        { text: 'Углеводы, г', value: 'carbs' },
        { text: 'Цена, ₽', value: 'price' },
        { text: 'Операции', value: 'actions' },
      ],
      items: [],
      tempItems: [],
      searchDishField: ''
    }
  },
  computed: {
    getSelectedItemIds() {
      return _.map(this.selected, item => {
        return item.id
      })
    },
    ...mapGetters(['foodCategories', 'setting']),
  },
  created() {
    this.load(),
    this.getHeaders()
  },
  methods: {
    async getHeaders() {
      if (this.setting('recipe_food_category')) {
        this.headers.splice(2,0, { text: 'Категория блюда', value: 'food_category' })
        this.headers.splice(3 , 0, { text: 'Номер рецепта', value: 'recipe' })
      }
    },
    getFoodCategory(id) {
        let category = "";
         this.foodCategories.forEach((y) => {
          if (y.id === id) {
            category = y.name;
            return;
          }
        });
        return category;
      },

    async postFoodCopy(value) {
      if (value) {
        const payload = {
          delete: this.deleteFood,
          key: this.anhoterKey
        }
        store.commit('setShowPreloader', true)
        await postFoodCopyKey(payload)
        store.commit('setShowPreloader', false)
        this.load()
      }
      this.dialogs.modalFoodCopy = false
    },
    async foodCopyKey() {
      try{
        store.commit('setShowPreloader', false)
        const response = await getFoodCopyKey()
        this.foodCopyKey_str = response.data.key
      }
      catch (error){
        // notify.error(`Не удалось получить ключ`) // backend handler added (exception_message)
      }
      finally {
        store.commit('setShowPreloader', false)
      }
    },
    async showFoodCopy() {
      this.dialogs.modalFoodCopy = true
      await this.foodCopyKey()
    },
    navigateTo(where, id, item) {
      this.$router.push({ name: where, params: { id }, query: item })
    },
    async searchDishes() {
      if (this.searchDishField.length === 0) {
        return
      }
      const params = {
        archive: this.archiveView,
        search: this.searchDishField
      }
      this.tempItems = this.items
      getDishesSearch(params)
        .then(response => {
          this.items = _.map(response.data, item => {
            return {
              id: item.id,
              name: item.name,
              food_category: item.food_category,
              recipe: item.recipe,
              energy: item.energy_value,
              protein: item.protein,
              fats: item.fat,
              carbs: item.carbohydrates,
              additionally: item.is_additional,
              price: item.price,
              weight_middle: item.weight_middle,
              weight_primary: item.weight_primary
            }
          })
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    clearSearchDishes() {
      this.searchDishField = ''
      if (this.tempItems.length) {
        this.items = this.tempItems
      }
    },
    async load() {
      const method = this.archiveView ? getArchivedDishes : getDishes
      method()
        .then(response => {
          this.items = _.map(response.data.data, item => {
            return {
              id: item.id,
              name: item.name,
              food_category: item.food_category,
              recipe: item.recipe,
              energy: item.energy_value,
              protein: item.protein,
              fats: item.fat,
              carbs: item.carbohydrates,
              additionally: item.is_additional,
              price: item.price,
              weight_middle: item.weight_middle,
              weight_primary: item.weight_primary
            }
          })
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    async addAdditionalsDishes() {
      const payload = {
        ids: this.getSelectedItemIds
      }
      try {
        await setAdditionalMenu(payload)
        notify.success(`Дополнительные блюда добавлены успешно!`)
      } catch (error) {
        // notify.error(`Не удалось добавить дополнительные блюда.`) // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    massDelete() {
      const ids = this.getSelectedItemIds
      const payload = { ids: ids }
      deleteSelectedDishes(payload)
        .then(response => {
          _.forEach(ids, id => {
            this.items = _.remove(this.items, item => {
              return item.id != id
            })
          })
          if (response.data.success) {
            notify.success(Vue.config.notifications.dishes.deleteMassSuccess)
          }
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    singleDelete(id) {
      deleteDish(id)
        .then(response => {
          this.items = _.remove(this.items, item => {
            return item.id != id
          })
          if (response.data.success) {
            notify.success(Vue.config.notifications.dishes.deleteOneSuccess)
          }
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    singleArchive(id) {
      archiveOneDish(id)
        .then(response => {
          this.items = _.remove(this.items, item => {
            return item.id != id
          })
          notify.success('Блюдо помещено в архив')
          store.commit('setShowPreloader', false)
        })
    },
    async singleUnarchive(id) {
      unarchiveOneDish(id)
        .then(response => {
          this.load().then(() => {
            notify.success('Блюдо удалено из архива')
          })
        })
    },
    fileChanged(file) {
      const form = new FormData()
      form.append('file', file)
      uploadDishesFile(form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          if (!this.items.length) {
            this.loadMore()
          }
          if (response.data.success) {
            notify.success(Vue.config.notifications.dishes.uploadSuccess)
          }
        })
        .finally(() => store.commit('setShowPreloader', false))
      // handle file here. File will be an object.
      // If multiple prop is true, it will return an object array of files.
    }
  }
}
</script>
