export const parseSettings = (obj) => {
  const settings = {}
  obj.forEach(obj => {

    let value
    if (obj.value === 'True' || obj.value === 'False') {
      value = obj.value === 'True'
    } else if (/^\d+$/.test(obj.value)) {
      value = parseInt(obj.value)
    } else {
      value = obj.value
    }
    settings[obj.key] = {
      id: obj.id,
      value: value,
      desc: obj.desc
    }
  })
  return settings
}
