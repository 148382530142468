<template>
  <fieldset class="mb-1" :style="{maxWidth: maxWidth}">
    <legend>{{ title }}</legend>
    <div>
      <slot></slot>
    </div>
  </fieldset>
</template>

<script>

export default {
  name: "ElementGroup",
  props: {
    title: {
      default: '',
      type: String
    },
    max: {
      default: 0,
      type: Number
    }
  },
  computed: {
    maxWidth() {
      return this.$props.max ? this.$props.max + 'px' : false
    }
  }
}
</script>

<style>
fieldset {
  border-radius: 5px;
  border: 1px solid silver;
  padding-bottom: 5px;
  padding-left: 5px;
}
legend {
  padding: 0 5px;
  color: #6e6e6e;
  font-size: 12px;
}
ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
li {
  margin: 4px 0;
}
</style>