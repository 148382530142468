<template>
  <div class="sc-message--text" :style="messageColors">
    <template>
      <div class="sc-message--toolbox">
        <slot name="text-message-toolbox" :message="message" :me="me"> </slot>
      </div>
    </template>
    <slot
      :message="message"
      :messageText="messageText"
      :messageColors="messageColors"
      :me="me"
    >
      <p class="sc-message--text-content" v-html="messageText"></p>
      <p
        v-if="message.data.meta"
        class="sc-message--meta"
        :style="{ color: messageColors.color }"
      >
        {{ message.data.meta }}
      </p>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true
    },
    messageColors: {
      type: Object,
      required: true
    },
    messageStyling: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    messageText() {
      return this.message.data.text
    },
    me() {
      return this.message.author === 'me'
    }
  }
}
</script>

<style scoped>
a.chatLink {
  color: inherit !important;
}
</style>
