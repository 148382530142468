<template>
  <v-container fluid grid-list-md>
    <v-layout wrap>
      <v-flex>
        <h1>{{ title }}</h1>
      </v-flex>
      <v-flex align-self-center text-md-right>
        <v-btn
          :to="{ name: 'ModuleAdminMunicipalUsersCreate' }"
          class="ma-0"
          color="primary"
        >Добавить пользователя</v-btn>
      </v-flex>
      <v-flex xs12 mt-5>
        <Table
          ref="municipalUsers"
          :headers="headers"
          :request="getMunicipalUsers"
          requestAfterMount
          showNumbering
        >
          <template v-slot:items="props">
            <td>{{ props.item.username }}</td>
            <td>{{ props.item.municipality }}</td>
            <td :class="{ 'red--text': !props.item.is_active }">{{ props.item.is_active ? 'Да' : 'Нет' }}</td>
            <td>
              <v-flex xs12>
                <v-menu right offset-x transition="slide-x-transition">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" :disabled="deletedItems.includes(props.item.id)">delete</v-icon>
                  </template>
                  <v-btn @click="deleteUser(props.item.id)">Удалить</v-btn>
                </v-menu>
              </v-flex>
            </td>
          </template>
        </Table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { getMunicipalUsers, removeMunicipalUser } from '@/router/routes/superadmin'

import Table from '@/ui-components/Table'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ModuleAdminMunicipalUsers',
  components: {
    Table
  },
  data: () => ({
    title: 'Список муниципальных пользователей',
    headers: [
      { text: 'Имя пользователя', value: 'username' },
      { text: 'Муниципалитет', value: 'municipality' },
      { text: 'Активен', value: 'is_active' },
      { text: 'Действия', value: 'actions' }
    ],
    deletedItems: [],
    getMunicipalUsers
  }),
  methods: {
    deleteUser(id) {
      removeMunicipalUser({ id }).then(r => {
        if (r.status === 204) {
          this.deletedItems.push(id)
          notify.success('Пользователь успешно удален')
        }
      })
    }
  }
}
</script>
