export default class DialyMenuTemplateFactory {
  constructor() {
    const res = []

    for (let i = 1; i <= 21; i++) {
      res.push({
        id: i,
        choise: null,
        dayOfWeek: i,
        complexes: [],
        isWorkDay: false,
        dialog: false,
        dialogNew: false,
        price: 0,
        concession: 0,
        additionalFoods: [],
      })
    }

    return res
  }
}
