<template>
  <v-container
    fluid
    class="fs-moduleadmin-container"
  >
    <v-layout
      class="fs-moduleadmin-order"
      row
      mb-3
    >
      <v-flex mb-3>
        <h1>Лог изменения заявок</h1>
      </v-flex>
    </v-layout>
    <v-layout
      row
      mb-12
      justify-start
      text-md-center
    >
      <v-flex md3 mr-3>
        <v-autocomplete
          :value="filters.schoolId"
          @change="changeSchool"
          :items="filters.schools"
          item-text="name"
          item-value="id"
          label="Школа:"
        />
      </v-flex>
      <v-flex md3 mr-3>
        <v-autocomplete
          :disabled="!filters.schoolId"
          :value="filters.classId"
          @change="changeClass"
          :items="filters.classes"
          item-text="name"
          item-value="id"
          label="Класс:"
        />
      </v-flex>
      <v-flex md3 mr-3 style="position: relative">
        <v-menu
          v-model="filters.datepickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="filters.date"
              prepend-icon="event"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            class="fs-datepicker"
            prev-icon="keyboard_arrow_left"
            next-icon="keyboard_arrow_right"
            locale="ru"
            first-day-of-week="1"
            no-title
            v-model="filters.date"
            @input="selectDate"
          ></v-date-picker>
        </v-menu>
        <v-icon class="fs-active-icon" @click="clearFilters" style="position: absolute; right: -30px; top: 8px">delete</v-icon>
      </v-flex>
      <v-flex md3 mr-3>
        <v-btn
          flat
          class="fs-btn fs-teacher-btn__show v-btn--active"
          @click="loadMore"
        >Показать
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      class="fs-teacher-order-result"
      mb-3
      justify-space-around
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :pagination.sync="pagination"
          :items="items"
          hide-actions
          no-data-text="Нет данных"
          class="elevation-1 fs-teacher-table"
          item-key="id"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.createdAt }}</td>
              <td>{{ props.item.changeType }}</td>
              <td>{{ props.item.comment }}</td>
              <td>{{ props.item.author }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import moment from 'moment'
import {
  getOrdersLogs,
  getSchools,
  getClasses
} from '@/router/routes/organizer'
import { mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import _ from 'lodash'
import scrollable from "@/mixins/scrollable"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'OrganizerOrderLogsIndex',
  mixins: [ scrollable ],
  data() {
    return {
      pagination: {
        rowsPerPage: -1
      },
      headers: [
        { text: 'Дата', value: 'createdAt' },
        { text: 'Событие', value: 'changeType' },
        { text: 'Комментарий', value: 'comment' },
        { text: 'Автор', value: 'author' }
      ],
      items: [],
      filters: {
        date: null,
        schoolId: null,
        schools: [],
        classes: [],
        classId: null,
        datepickerMenu: false,
      },
      limit: Vue.config.general.limit,
    }
  },
  computed: {
    dateNormal() {
      if (!this.filters.date) {
        return null
      }
      const date = this.filters.date.split('-')
      const day = date[2]
      const month = date[1]
      const year = date[0]
      return `${day}-${month}-${year}`
    }
  },
  created() {
    this.getSchools()
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },
  methods: {
    ...mapMutations( [ 'setShowPreloader' ] ),
    selectDate(date) {
      this.filters.datepickerMenu = false
      this.filters.date = date
      this.items = []
      this.offset = 0
    },
    clearFilters() {
      this.filters.datepickerMenu = false
      this.filters.date = ''
      this.filters.schoolId = null
      this.filters.classId = null
      this.items = []
      this.offset = 0
    },
    async loadMore() {
      try {
        const payload = {
          params: {
            school: this.filters.schoolId,
            schoolClass: this.filters.classId,
            orderDay: this.dateNormal || null,
            limit: this.limit,
            offset: this.offset
          }
        }
        const response = await getOrdersLogs(payload)
        const itemsArray = _.map(response.data.data, item => {
          return {
            createdAt: moment(item.created_at, Vue.config.format.serverDateFull).format(Vue.config.format.dateString.time),
            changeType: item.change_type,
            author: item.author,
            comment: item.comment
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        // notify.error('Не удалось загрузить лог') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getSchools() {
      try {
        const response = await getSchools()
        const {
          data: { data: items }
        } = response
        this.filters.schools = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      }
    },
    async getClasses() {
      try {
        const payload = {
          school_id: this.filters.schoolId
        }
        const response = await getClasses(payload)
        const {
          data: { data: items }
        } = response
        this.filters.classes = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async changeSchool(value) {
      this.filters.schoolId = value
      this.filters.classId = null
      this.filters.classes = []
      this.items = []
      this.offset = 0
      await this.getClasses()
    },
    changeClass(value) {
      this.filters.classId = value
      this.items = []
      this.offset = 0
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
