import Vue from 'vue'
import Vuex from 'vuex'
import getters from '@/store/getters'
import mutations from '@/store/mutations'
import actions from '@/store/actions'

import administrators from '@/store/administrators/index'
import parents from '@/store/parents/index'
import teachers from '@/store/teachers/index'
import organizers from '@/store/organizers/index'
import department from '@/store/department/index'
import common from '@/store/common/index'
import cards_store from '@/store/cards/index'
import storehouse_store from '@/store/storehouse/index'
import users_store from '@/store/users/index'
import blacklist_store from '@/store/blacklist/index'
import syrvey_store from '@/store/syrvey/index'
import attachments_store from '@/store/attachments/index'
import foodblock from '@/store/foodblock/index'
import buffet_transactions_store from '@/store/buffetTransactions/index'
import orders_store from '@/store/orders/index'
import complex_store from '@/store/organizers/complex/index'

// const debug = process.env.NODE_ENV !== 'production'
const debug = false

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    parents: parents,
    administrators: administrators,
    teachers: teachers,
    organizers: organizers,
    department: department,
    common: common,
    cards_store: cards_store,
    storehouse_store: storehouse_store,
    users_store: users_store,
    blacklist_store: blacklist_store,
    syrvey_store: syrvey_store,
    attachments_store: attachments_store,
    buffet_transactions_store: buffet_transactions_store,
    foodblock: foodblock,
    orders_store: orders_store,
    complex_store: complex_store,
  },
  strict: debug,
  state: {
    title: '',
    currentUser: null,
    currentChild: null,
    showPreloader: false,
    asyncLoadFile: false,
    
    asyncLoadFileWithProgressBar: false,
    asyncLoadFileLoaderProgressBarValue: 0,

    asyncRequestWithProgressBar: false,
    asyncRequestProgressBarValue: 0,

    loaderMessage: null,

    notVisitedIds: []
  },
  getters: getters,
  mutations: mutations,
  actions: actions
})