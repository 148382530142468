<script>
import {
  getBoardingSchoolsUser,
  updateBoardingSchoolsUser
} from '@/router/routes/superadmin'
import store from '@/store'
import notify from '@/utils/notifications/toastNotification.vue'
import rulesCustom from '@/helpers/rulesCustom'

export default {
  name: 'BoardingSchoolsUserItemView',
  props: ['id'],

  data() {
    const rules = rulesCustom

    return {
      isEditMode: false,
      isValid: false,
      isLoading: false,
      rules,
      fields: {
        is_active: true,
        username: '',
        full_name: '',
        department: {
          id: null,
          name: '',
          short_name: '',
          remote_id: null,
          parent: {
            id: null,
            short_name: '',
            name: ''
          },
          school: {
            id: null,
            short_name: '',
            name: ''
          }
        },
        id: null
      },
      password: {
        new_password: {
          type: 'password',
          key: 'new_password',
          label: 'Пароль',
          value: null,
          rules: [
            rules.required,
            rules.maxLength(20),
            rules.minLength(8),
            (value) => (thisItem) =>
              rules.passwordsMatch(this.password.confirm_password)(value)(
                thisItem
              )
          ],
          counter: 20,
          show: false
        },
        confirm_password: {
          type: 'password',
          key: 'confirm_password',
          label: 'Подтверждение пароля',
          value: null,
          rules: [
            rules.required,
            rules.maxLength(20),
            rules.minLength(8),
            (value) => (thisItem) =>
              rules.passwordsMatch(this.password.new_password)(value)(thisItem)
          ],
          counter: 20,
          show: false
        }
      },
      // редактируемые поля
      updatedFields: {
        full_name: {
          type: 'text',
          key: 'full_name',
          label: 'ФИО',
          value: null,
          rules: [rules.required, rules.minLength(3), rules.maxLength(128)],
          counter: 128
        }
      }
    }
  },

  methods: {
    async updateUser() {
      const requestData = {}
      Object.entries(this.updatedFields).forEach(([k, v]) => {
        if (this.fields[k] !== v.value) {
          requestData[k] = v.value
        }
      })
      Object.entries(this.password).forEach(([k, v]) => {
        if (v.value) {
          requestData[k] = v.value
        }
      })
      // Если нет изменений в полях
      if (!Object.keys(requestData).length) {
        return
      }

      this.isLoading = true
      try {
        const {
          data: { data: card }
        } = await updateBoardingSchoolsUser({
          data: requestData,
          id: this.$props.id
        })
        this.fields = { ...this.fields, ...card }
        this.closeEditMode()
        notify.success('Изменения внесены')
      } catch (e) {
        notify.error('Ошибка внесения изменений')
      } finally {
        this.isLoading = false
        store.commit('setShowPreloader', false)
      }
    },

    async getCard() {
      this.isLoading = true
      try {
        const {
          data: { data: card }
        } = await getBoardingSchoolsUser({
          id: this.$props.id
        })
        this.fields = card
        this.setUpdatedFields()
      } catch (e) {
        notify.error('Ошибка загрузки карточки')
      } finally {
        this.isLoading = false
        store.commit('setShowPreloader', false)
      }
    },

    closeEditMode() {
      this.isEditMode = false
    },
    setUpdatedFields() {
      Object.keys(this.updatedFields).forEach((k) => {
        this.updatedFields[k].value = this.fields[k]
      })
      Object.keys(this.password).forEach((k) => {
        this.password[k].value = null
      })
    },
    showPassword(item) {
      item.show = !item.show
    },

    clearPassword() {
      Object.keys(this.password).forEach((k) => {
        this.password[k].value = ''
      })
      this.$refs.form.resetValidation()
    }
  },
  computed: {
    isUnequal() {
      return Object.entries(this.updatedFields).some(
        ([k, v]) => this.fields[k] !== v.value
      )
    },
    isDirtyPassword() {
      return Object.values(this.password).some(
        (v) => !!v.value || v.value?.length
      )
    },
    disabledSubmit() {
      return !(this.isUnequal || this.isDirtyPassword) || !this.isValid
    }
  },

  async mounted() {
    await this.getCard()
  },

  watch: {
    isEditMode(newValue) {
      if (!newValue) {
        this.setUpdatedFields()
      }
    }
  }
}
</script>

<template>
  <v-container class="fs-organizer-container">
    <v-toolbar :class="{ 'cyan lighten-5': isEditMode }">
      <v-btn flat color="blue" variant="tonal" @click="$router.go(-1)">
        <v-icon>arrow_back</v-icon>Назад
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title class="text-truncate text-no-wrap px-2"
        >Карточка пользователя</v-toolbar-title
      >

      <v-switch
        v-model="isEditMode"
        hide-details
        class="text-no-wrap px-2 blue-grey--text align-center justify-end"
      >
        <template v-slot:prepend>
          Режим редактирования: {{ isEditMode ? 'Вкл' : 'Выкл' }}
        </template>
      </v-switch>
    </v-toolbar>
    <v-layout class="rounded rounded-md" elevation-3>
      <v-flex class="mx-auto">
        <v-form
          ref="form"
          v-model="isValid"
          class="pa-4 pt-6"
          @submit.prevent="updateUser"
          row
          justify-space-between
        >
          <v-flex pa-1>
            <v-text-field
              label="Логин"
              v-model="fields.username"
              variant="filled"
              readonly
              hide-details
              :disabled="isEditMode"
            />
          </v-flex>
          <v-flex class="d-flex ga-3" style="gap: 12px">
            <v-flex xs12 md6 pa-1>
              <v-checkbox
                label="Активен"
                v-model="fields.is_active"
                variant="filled"
                readonly
                :disabled="isEditMode"
                hide-details
              />
            </v-flex>
            <v-flex xs12 md6 pa-1>
              <v-text-field
                label="ID"
                v-model="fields.id"
                variant="filled"
                readonly
                :disabled="isEditMode"
                hide-details
              />
            </v-flex>
          </v-flex>
          <!--Изменяемые поля-->
          <v-flex pa-1 v-for="field in updatedFields" :key="field.key">
            <v-text-field
              :label="field.label"
              v-model="field.value"
              variant="filled"
              :readonly="!isEditMode"
              :box="isEditMode"
              :hide-details="!isEditMode"
              :counter="field.counter || false"
              :maxlength="field.counter || false"
              :rules="field.rules.map((rule) => rule(field))"
              :clearable="isEditMode"
              required
            />
          </v-flex>

          <v-flex pa-1>
            <v-text-field
              label="Школа"
              v-model="
                fields.department.school?.short_name
                  ? fields.department.school.short_name
                  : fields.department.school.name
              "
              variant="filled"
              readonly
              :disabled="isEditMode"
              hide-details
            />
          </v-flex>
          <v-flex pa-1>
            <v-text-field
              label="Управление Школой"
              v-model="
                fields.department?.short_name
                  ? fields.department.short_name
                  : fields.department.name
              "
              variant="filled"
              readonly
              :disabled="isEditMode"
              hide-details
            />
          </v-flex>
          <v-flex pa-1>
            <v-text-field
              label="Департамент"
              v-model="
                fields.department.parent?.short_name
                  ? fields.department.parent.short_name
                  : fields.department.parent.name
              "
              variant="filled"
              readonly
              :disabled="isEditMode"
              hide-details
            />
          </v-flex>

          <!--Поля пароля-->
          <v-flex
            class="d-flex ga-3 pa-1 mt-3"
            style="gap: 4px; flex-direction: column"
            v-if="isEditMode"
          >
            <v-flex class="d-flex ga-3" style="gap: 12px">
              <v-flex v-for="item in password" :key="item.key" xs6 md6>
                <v-text-field
                  v-model="item.value"
                  :label="item.label"
                  :rules="
                    isDirtyPassword ? item.rules.map((rule) => rule(item)) : []
                  "
                  :maxlength="item.counter || false"
                  :counter="item.counter || false"
                  :required="isDirtyPassword"
                  :append-icon="item.show ? 'visibility' : 'visibility_off'"
                  :type="item.show ? 'text' : item.type"
                  browser-autocomplete="off"
                  autocomplete="off"
                  box
                  clearable
                  @click:append="() => showPassword(item)"
                />
              </v-flex>
            </v-flex>

            <v-btn
              v-if="!isValid"
              @click="clearPassword"
              :loading="isLoading"
              md6
            >
              сбросить валидацию и очистить пароль
            </v-btn>
          </v-flex>

          <v-flex
            class="d-flex ga-4 justify-end pa-4"
            v-if="isEditMode"
            style="gap: 10px"
          >
            <v-btn @click="closeEditMode" :loading="isLoading"> Отмена </v-btn>
            <v-btn
              color="primary"
              :disabled="disabledSubmit"
              :loading="isLoading"
              type="submit"
            >
              Сохранить
            </v-btn>
          </v-flex>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style lang="scss" scoped></style>
