<template>
  <v-container fluid>
    <v-layout class="fs-dish" row>
      <div class="title-help">
        <div class="d-flex pt-2">
          <p class="text text-center">Период питания</p>
          <p>
            <context-help-modal :context-key="contextKeys.admin.dashboard.mealtype_period"/>
          </p>
        </div>
      </div>
    </v-layout>
    <v-flex class="mt-4">
      <div style="min-height: 300px">
        <canvas class="chartPeriod" ref="myChart"></canvas>
      </div>
    </v-flex>
  </v-container>
</template>

<script>
import { TextEditorModal } from "@/components/common"
import notify from "@/utils/notifications/toastNotification"
import store from "@/store"
import Chart from 'chart.js/auto'
import { mapGetters } from "vuex"
import { getAdminDashboardMealPeriodCount } from "@/router/routes/admin"
import ChartDataLabels from "chartjs-plugin-datalabels"
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal'
import Vue from "vue"


const labels = [
  'Одноразовое', 'Двухразовое', 'Трехразовое', 'Четырехразовое', 'Пятиразовое', 'Шестиразовое'
]


export default {
  name: 'MealPeriodCount',
  components: {
    TextEditorModal,
    ContextHelpModal
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      one: null,
      two: null,
      three: null,
      four: null,
      five: null,
      six: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        dataLabels: {
          enabled: false
        },
        sparkline: {
          enabled: true
        },
        indexAxis: 'x',
        scales: {
          x: {
            display: true,
            ticks: {
              display: false
            },
            grid: {
              display: false
            }
          },
          y: {
            display: false,
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              generateLabels: function(chart) {
                const data = chart.data
                if (data.labels.length && data.datasets.length) {
                  return data.labels.map(function(label, index) {
                    const dataset = data.datasets[0]
                    const backgroundColor = dataset.backgroundColor[index]
                    return {
                      text: label,
                      fillStyle: backgroundColor
                    }
                  })
                }
                return []
              }
            }
          },
          datalabels: {
            display: true,
            align: function(context) {
              return context.dataset.data[context.dataIndex] > 100 ? 'center' : 'end'
            },
            anchor: function(context) {
              return context.dataset.data[context.dataIndex] > 100 ? 'center' : 'end'
            }
          }
        }
      }
    }
  },
  mounted() {
    this.loadMealPeriod()
      .then(() => {
        const data = {
          labels: labels,
          datasets: [ {
            axis: 'x',
            label: '',
            data: [ this.one, this.two, this.three, this.four, this.five, this.six ],
            fill: false,
            backgroundColor: [
              'rgb(231,171,187)',
              'rgb(208,142,156)',
              'rgb(234,207,146)',
              'rgb(178,241,241)',
              'rgb(228,172,234)',
              'rgb(190,218,238)',
              'rgb(207,192,234)',
              'rgb(236,188,216)'
            ],
            borderWidth: 0,
            barPercentage: 1,
          } ]
        }
        const ctx = this.$refs.myChart.getContext('2d')
        this.chart = new Chart(ctx, {
          type: 'bar',
          data: data,
          options: this.options,
          plugins: [ ChartDataLabels ]
        })
      })
      .catch((error) => {
        console.error(error)
        notify.error('Ошибка загрузки статистики заявок')
      })
      .finally(() => {
        store.commit('setShowPreloader', false)
      })
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    async loadMealPeriod() {
      try {
        const response = await getAdminDashboardMealPeriodCount({ school_id: this.currentUser.school_id })
        this.one = response.data.one
        this.two = response.data.two
        this.three = response.data.three
        this.four = response.data.four
        this.five = response.data.five
        this.six = response.data.six
      } catch (error) {
        // notify.error('Ошибка загрузки статистики заявок') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>

<style scoped>

.chartPeriod {
  width: 100%;
}

</style>

