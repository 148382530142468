<template>
  <v-form ref="applicationForm" v-model="valid">
    <v-card>
      <v-toolbar elevation="1" dense light>
        <v-toolbar-title>Работа с  заявкой</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.prevent="handleClose">
          <v-icon class="fs-active-icon">close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-select
          :items="op_mode ? statuses : statuses_admin"
          item-text="name"
          item-value="id"
          label="Статус"
          v-model="form.status"
          @change="handleChangeFormStatus"
        ></v-select>

        <v-switch v-model="form.is_draft" @change="handleChangeFormDraftStatus" label="Черновик" />

        <div style="text-align: right;">
          <v-btn @click="handleShowProductModal" color="primary" style="margin: 0px" v-show="!op_mode && form.status !== 6">Добавить продукты</v-btn>
        </div>

        <v-flex style="margin-top: 15px;">
          <v-data-table
            :headers="[
              { text: 'Наименование', value: 'product.name' },
              { text: 'Категория', value: 'product.category.title' },
              { text: 'Кол-во', value: 'count' },
              { text: 'Операции', value: '' }
            ]"
            :items.sync="form.items"
            hide-actions
            no-data-text="Нет продуктов в заявке"
            class="elevation-1 fs-admin-table"
            item-key="name"
          >
            <template v-slot:items="props">
              <tr>
                <td>{{ props.item.product.name }}</td>
                <td>{{ props.item.product.category.title  }}</td>
                <td>{{ props.item.count }}</td>
                <td>
                  <v-flex row>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          v-show="form.is_draft && !props.item.is_move"
                          class="fs-active-icon"
                          @click="handleEditProduct(props.item.id)"
                        >edit</v-icon>
                      </template>
                      <span>Редактирование</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          v-show="form.is_draft && !props.item.is_move"
                          @click="handleDeleteProduct(props.item.id)"
                          class="fs-active-icon"
                        >delete</v-icon>
                      </template>
                      <span>Удалить</span>
                    </v-tooltip>
                    <v-btn v-show="form.status === 6 && !props.item.is_move" @click.prevent="handleMoveProductToStorehouse(props.item)" color="primary">
                      Переместить на склад 
                    </v-btn>
                    <span v-show="props.item.is_move">На складе</span>
                  </v-flex>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-card-text>
    </v-card>

     <!-- // ! Переместить продукт на склад -->
    <v-dialog width="80%" v-model="isShowMoveModal" persistent>
      <v-toolbar elevation="1" dense light>
        <v-toolbar-title>Перемещение продукта</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon @click.prevent="handleCloseMoveModal">
          <v-icon class="fs-active-icon">close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-select
            :items="storehouses"
            item-text="name"
            item-value="id"
            label="Выберете склад"
            v-model="move_product_form.storehouse"
          ></v-select>
          <v-select
            :items="storehouse_products"
            item-text="name"
            item-value="id"
            label="Выберете родукт который хотите дополнить на складе"
            v-model="move_product_form.product_storehouse"
          ></v-select>
          <v-text-field type="number" min="0" label="Цена" v-model="move_product_form.price"></v-text-field>
        </v-card-text> 

        <v-btn @click="moveProduct" color="primary">
           Переместить
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- // ! Добавление продукта в заявку -->
    <v-dialog width="80%" v-model="isShowProductModal" persistent>
      <v-toolbar elevation="1" dense light>
        <v-toolbar-title>Добавление продуктов</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.prevent="handleCloseProductModal">
          <v-icon class="fs-active-icon">close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-select
            :items="buffet_product_dict"
            item-text="name"
            item-value="id"
            label="Выберете продукт из справочника"
            v-model="buffet_new_product.product"
          ></v-select>
          <v-text-field type="number" min="0" label="кол-во" v-model="buffet_new_product.count"></v-text-field>
        </v-card-text>

        <v-btn @click="addProductOrUpdate" color="primary">
           {{ productIsEdit ? 'Обновить' : 'Добавить'}}
        </v-btn>
      </v-card>
    </v-dialog>

    <ConfirmModal
      :dialog.sync="confirmUpdateApplication"
      @confirm="handlerConfirmModal"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
    >
      <template v-slot:headline>Вы действительно хотите обновить статус заявки?</template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="confirmUpdateApplicationDraft"
      @confirm="handlerConfirmDraftModal"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
    >
      <template v-slot:headline>Вы действительно хотите обновить статус черновика в заявке?</template>
    </ConfirmModal>
  </v-form>
</template>


<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import ConfirmModal from '@/components/common/Modals/ConfirmModal'
import {
  createBuffetApplication, getBuffetProductDict, createBuffetApplicationsPositions,
  getBuffetApplication, getOneBuffetApplicationsPositions, updateBuffetApplicationsPositions,
  deleteBuffetApplicationsPositions, updateBuffetApplication, getStorehousesProducts,
  moveProductFromApplications
} from '@/router/routes/admin.js'
import { getStorehouses } from '@/router/routes/admin'
import scrollable from '@/mixins/scrollable'
import notify from '@/utils/notifications/toastNotification'
export default {
  name: 'BuffetApplicationModal',
  components: { ConfirmModal },
  mixins: [ scrollable ],
  data: () => ({
    valid: true,
    confirmUpdateApplication: false,
    confirmUpdateApplicationDraft: false,
    rules: {
      required: (v) => !!v || "Выберете товары из списка, если их нет тогда создайте во вкладке - Заказы"
    },
    buffet_new_product: {
      count: 0,
      product: null,
      application: null
    },
    move_product_form: {
      product_storehouse: null,
      position: null,
      price: null,
      storehouse: null
    },
    buffet_product_dict: [],
    productIsEdit: false,
    statuses: [
      { name: 'Новая заявка', id: 1, disabled: true },
      { name: 'В процессе', id: 2 },
      { name: 'Отменена', id: 3, disabled: true },
      { name: 'Отклонена', id: 4 },
      { name: 'Доставлена', id: 5 },
      { name: 'Завершена', id: 6, disabled: true },
    ],
    statuses_admin: [
      { name: 'Новая заявка', id: 1 },
      { name: 'В процессе', id: 2, disabled: true },
      { name: 'Отменена', id: 3 },
      { name: 'Отклонена', id: 4, disabled: true },
      { name: 'Доставлена', id: 5, disabled: true },
      { name: 'Завершена', id: 6 },
    ],
    form_position: {
      count: 0,
      product: null
    },
    form_position_products: [],
    form: {
      is_draft: true,
      status: null,
      school: null,
      items: []
    },
    old_status: null,
    old_draft_status: null,

    storehouses: [],
    storehouse_products: [],
    isShowProductModal: false,
    isShowMoveModal: false
  }),
  props: {
    objId: {
      default: null
    },
    isCreate: {
      type: Boolean,
      default: true
    },
    op_mode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  mounted() {
    if (this.currentUser.school) {
      this.form.school = this.currentUser.school.id
      this.loadBuffetProductDict()
      this.fetchStorehouses()
    }
    if (this.isCreate) {
      this.createApllication()
    } else if (!this.isCreate && this.objId) {
      this.buffet_new_product.application = this.objId
      this.fetchBuffetApplication(this.buffet_new_product.application)
    }
    
  },
  watch: {
    'move_product_form.storehouse'(id) {
      this.fetchStrorehousesProducts(id)
    },
    'form.status'(val, oldVal) {
      this.old_status = oldVal
    },
    'form.is_draft'(val, oldVal) {
      this.old_draft_status = oldVal
    },
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    fetchStrorehousesProducts(storehouse_id) {
      getStorehousesProducts({ params: { storehouse: storehouse_id, page: 1, page_size: 1000 } })
        .then(res => {
          this.storehouse_products = res.data.results 
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    },
    handleChangeFormStatus() {
      this.confirmUpdateApplication = true
    },
    handleChangeFormDraftStatus() {
      this.confirmUpdateApplicationDraft = true
    },
    handlerConfirmModal(value) {
      if (value) {
        this.handleUpdateApplication()
      } else {
        if (this.old_status) {
          this.form.status = this.old_status
          this.old_status = null
        }
      }
    },
    handlerConfirmDraftModal(value) {
      if (value) {
        this.handleUpdateApplication()
      } else {
        this.form.is_draft = this.old_draft_status
        this.old_draft_status = null
      }
    },
    moveProduct() {
      moveProductFromApplications(this.move_product_form)
        .then(response => {
          notify.success('Продукт успешно перемещён на склад')
        })
        .catch(err => {
          console.error(err)
          // notify.error('Не удалось переместить продукт на склад') // backend handler added (exception_message)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    },
    fetchStorehouses() {
      getStorehouses({
        params: {
          page: 1,
          page_size: 1000,
          school: this.currentUser.school.id
        }
      })
        .then(response => {
          const itemsArray = response.data.results.map(item => ({ id: item.id, name: item.name }))
          this.storehouses = [ ...this.storehouses, ...itemsArray ]
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    },
    handleClose() {
      this.$emit('close')
      this.form = {
        is_draft: false,
        status: null,
        school: null,
        items: []
      }
    },
    handleMoveProductToStorehouse(item) {
      this.move_product_form.position = item.id
      this.isShowMoveModal = true
    },
    handleCloseMoveModal() {
      this.isShowMoveModal = false
      this.move_product_form = {
        product_storehouse: null,
        position: null,
        price: null,
        storehouse: null
      }
      this.fetchBuffetApplication(this.buffet_new_product.application)
    },
    handleEditProduct(id) {
      this.isShowProductModal = true
      this.productIsEdit = true
      getOneBuffetApplicationsPositions(id)
        .then(res => {
          this.buffet_new_product = {
            ...this.buffet_new_product,
            id: id,
            count: res.data.count,
            product: res.data.product.id,
          }
        })
        .catch(error => {
          console.error(error)
          // notify.error('Не удалось получить выбранный продукт детально') // backend handler added (exception_message)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    },
    handleDeleteProduct(id) {
      deleteBuffetApplicationsPositions(id)
        .then(res => {
          notify.success('Продукт успешно удалён из заявки')
          this.fetchBuffetApplication(this.buffet_new_product.application)
        })
        .catch(error => {
          console.error(error)
          // notify.error('Не удалось удалить выбранный продукт из заявки') // backend handler added (exception_message)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    },
    fetchBuffetApplication(id) {
      getBuffetApplication(id)
        .then(res => {
          const { is_draft, status, items, school } = res.data
          this.form = { ...this.form, is_draft, status, items, school }
        })
        .catch(error => {
          console.error(error)
          // notify.error('Не удалось получить заявку детально') // backend handler added (exception_message)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    },
    addProductOrUpdate() {
      if (this.productIsEdit) {
        updateBuffetApplicationsPositions({ id: this.buffet_new_product.id, data: this.buffet_new_product })
          .then(res => {
            notify.success('Продукт обновлён в заявке')
            this.handleCloseProductModal()
            this.fetchBuffetApplication(this.buffet_new_product.application)
          })
          .catch(error => {
            console.error(error)
            // notify.error('Не удалось обновить продукт в заявке') // backend handler added (exception_message)
          })
          .finally(() => {
            this.setShowPreloader(false)
          })
      } else {
        createBuffetApplicationsPositions(this.buffet_new_product)
          .then(res => {
            notify.success('Продукт добавлен в заявку')
            this.handleCloseProductModal()
            this.fetchBuffetApplication(this.buffet_new_product.application)
          })
          .catch(error => {
            console.error(error)
            // notify.error('Не удалось добавить продукт в заявку') // backend handler added (exception_message)
          })
          .finally(() => {
            this.setShowPreloader(false)
          })
      }

    },
    handleUpdateApplication() {
      updateBuffetApplication({ id: this.buffet_new_product.application, data: this.form })
        .then(res => {
          this.buffet_new_product.application = res.data.id
          notify.success('Заявка обновлена успешно')
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.setShowPreloader(false)
          this.$emit('updateCreateStatus', true)
        })
    },
    handleShowProductModal() {
      this.isShowProductModal = true
    },
    handleCloseProductModal() {
      this.isShowProductModal = false
      this.productIsEdit = false
      this.buffet_new_product = {
        ...this.buffet_new_product,
        count: 0,
        product: null,
      }
    },
    async loadBuffetProductDict() {
      try {
        const response = await getBuffetProductDict({ limit: this.LIMIT, offset: this.offset, school_id: this.currentUser.school.id })
        const itemsArray = response.data.results.map(item => ({ id: item.id, name: item.name, count: item.count }))
        this.buffet_product_dict = [ ...this.buffet_product_dict, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки подготовленных продуктов для заявки') // backend handler added (exception_message)
        console.error(error)
      } finally {
        this.setShowPreloader(false)
      }
    },
    createApllication() {
      const { is_draft, status, school } = this.form

      createBuffetApplication({ is_draft, status, school })
        .then(res => {
          this.buffet_new_product.application = res.data.id
          notify.success('Заявка создана успешно')
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.setShowPreloader(false)
          this.$emit('updateCreateStatus', true)
        })
    }
  }
}
</script>

<style>

</style>
