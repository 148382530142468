<template>
  <v-container fluid class="fs-parent-container">
    <v-layout mb-5 justify-space-around>
      <v-flex md8>
        <v-card>
          <v-card-text>
            <v-flex mb-5>
              <h1>{{companyName}}</h1>
            </v-flex>
            <v-layout row>
              <v-flex md6>
                <template v-for="(item, index) in organizer">
                  <p :key="item.key" v-if="index % 2 == 0" class="fs-organizer-info">
                    <span class="fs-organizer-info__key">{{item.key}}:</span>
                    <span class="fs-organizer-info__value">{{item.value}}</span>
                  </p>
                </template>
              </v-flex>
              <v-flex md6>
                <template v-for="(item, index) in organizer">
                  <p :key="item.key" v-if="index % 2 != 0" class="fs-organizer-info">
                    <span class="fs-organizer-info__key">{{item.key}}:</span>
                    <span class="fs-organizer-info__value">{{item.value}}</span>
                  </p>
                </template>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout justify-space-around>
      <v-flex text-md-right md8>
        <v-btn
          flat
          class="fs-btn fs-parent-btn fs-btn--secondary fs-form-footer__btn"
          @click="$router.go(-1)"
        >Назад</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getOrganizerInfo } from "@/router/routes/parent"
import { mapGetters, mapMutations } from "vuex"
import store from "@/store"

export default {
  name: "OrganizerCard",
  created() {
    const payload = {
      id: this.currentChild.id
    }
    getOrganizerInfo(payload)
      .then(response => {
        const org = response.data.data
        this.companyName = _.get(org, "short_name", "")
        this.organizer = [
          {
            key: "Полное название",
            value: _.get(org, "full_name", "")
          },
          {
            key: "Краткое название",
            value: _.get(org, "short_name", "")
          },
          /* {
          key: 'Руководитель',
          value: _.get(org, 'manager_name', ''),
        }, */
          {
            key: "Юридический адрес",
            value: _.get(org, "postal_address", "")
          },
          {
            key: "Фактический адрес",
            value: _.get(org, "legal_address", "")
          },
          {
            key: "Телефон",
            value: _.get(org, "phone", "")
          },
          {
            key: "Сайт",
            value: _.get(org, "site", "")
          },
          {
            key: "E-mail",
            value: _.get(org, "mail", "")
          },
          {
            key: "ИНН",
            value: _.get(org, "inn", "")
          },
          {
            key: "КПП",
            value: _.get(org, "kpp", "")
          },
          {
            key: "Лицевой счет",
            value: _.get(org, "personal_account", "")
          },
          {
            key: "Расчетный счет",
            value: _.get(org, "settlement_account", "")
          },
          {
            key: "Банк",
            value: _.get(org, "bank", "")
          },
          {
            key: "БИК",
            value: _.get(org, "bic", "")
          },
          {
            key: "ОГРН",
            value: _.get(org, "ogrn", "")
          },
          {
            key: "Корр. счет",
            value: _.get(org, "correspondent_account", "")
          }
        ]
      })
      .finally(() => store.commit("setShowPreloader", false))
  },
  data() {
    return {
      companyName: "",
      organizer: [],
      orgSchoolHeaders: [
        { text: "Образовательная организация", value: "School" },
        { text: "Корпус", value: "Building" },
        { text: "Заявки", value: "Bills" }
      ],
      orgSchoolItems: [
        {
          name: "Школа 1",
          building: "Строение 1"
        },
        {
          name: "Школа 1",
          building: "Строение 2"
        },
        {
          name: "Школа 1",
          building: "Строение 3"
        },
        {
          name: "Школа 2",
          building: "Строение 1"
        },
        {
          name: "Школа 2",
          building: "Строение 2"
        },
        {
          name: "Школа 3",
          building: "Строение 1"
        }
      ]
    }
  },
  computed: {
    ...mapGetters([ "currentChild" ])
  },
  methods: {
    ...mapMutations([ "setShowPreloader" ])
  }
}
</script>
