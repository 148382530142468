<script>
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import ElementGroup from "@/components/common/ElementGroup/ElementGroup.vue"
import { customFindOrders, getClasses, getSchools } from "@/router/routes/superadmin"
import store from "@/store"
import SuperadminOrdersTableV2 from "@/components/moduleadmin/Orders/Components/OrderTable.vue"
import OrderRestoreDefault from "@/components/moduleadmin/Orders/Components/OrderRestoreDefault.vue"

export default {
  name: 'SuperadminOrdersIndexV2',
  components: { OrderRestoreDefault, SuperadminOrdersTableV2, ElementGroup, ContextHelpModal },
  data() {
    return {
      addModal: false,
      showDatePickerMenu: false,
      schoolsList: [],
      selectedSchool: null,
      schoolClassList: [],
      selectedSchoolClass: [],
      selectedDate: null,
      selectedPupilID: null,
      selectedOrderID: null,
      foundOrders: []
    }
  },
  async mounted() {
    await this.getSchools()
    store.commit('setShowPreloader', false)
  },
  watch: {
    async selectedSchool(val) {
      if (val) {
        await this.getSchoolClasses()
      }
      store.commit('setShowPreloader', false)
    },
    selectedPupilID(val) {
      this.selectedPupilID = val === '' ? null : val
    },
    selectedOrderID(val) {
      this.selectedOrderID = val === '' ? null : val
    }
  },
  methods: {
    async getSchools() {
      try {
        const response = await getSchools()
        this.schoolsList = response.data.data.map(item => {
          item.fullName = `${item.name} (${item.department})`
          return item
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getSchoolClasses() {
      this.schoolClassList = []
      try {
        const response = await getClasses({
          school_id: this.selectedSchool
        })
        this.schoolClassList = response.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async getOrders() {
      this.foundOrders = []
      const payload = {
        selectedSchool: this.selectedSchool,
        selectedSchoolClasses: this.selectedSchoolClass,
        selectedDate: this.selectedDate,
        selectedPupilID: this.selectedPupilID,
        selectedOrderID: this.selectedOrderID
      }
      try {
        const { data } = await customFindOrders(payload)
        this.foundOrders = data
      } catch (e) {
        console.log(e)
      }
      store.commit('setShowPreloader', false)
    },
    updateOrder(order) {
      const idx = this.foundOrders.findIndex(x => x.id === order.id)
      for (const [ key, value ] of Object.entries(order)) {
        this.foundOrders[idx][key] = value
      }
    }
  }
}
</script>

<template>
<v-container fluid class="fs-teacher-container">
  <v-layout row mb-3>
    <div class="d-flex row justify-space-between">
      <h1>Работа с заявками на питание</h1>
    </div>
  </v-layout>
  <div>
    <v-card>
      <v-card-text class="pb-0">
        <div class="d-flex row">
          <element-group :max="500" title="По школе" class="mr-2 px-3 py-2">
            <v-autocomplete
                solo
                @keyup.enter="getOrders"
                clearable
                v-model="selectedSchool"
                :items="schoolsList"
                item-text="fullName"
                item-value="id"
                label="Школа"
            ></v-autocomplete>
            <v-autocomplete
                solo
                @keyup.enter="getOrders"
                multiple
                clearable
                v-model="selectedSchoolClass"
                :items="schoolClassList"
                item-text="name"
                item-value="id"
                label="Класс (если не выбран, ищет по всем)"
            ></v-autocomplete>
          </element-group>
          <element-group title="По дате" class="mr-2 px-3 py-2">
            <v-menu
              ref="menu"
              v-model="showDatePickerMenu"
              :close-on-content-click="true"
              transition="scale-transition"
              min-width="auto"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  solo
                  clearable
                  v-model="selectedDate"
                  label="Дата заявки"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="selectedDate"
                no-title
                locale="ru"
                first-day-of-week="1"
              />
            </v-menu>
            <div class="pt-1">
              <span class="grey--text">Если дата не указана, то будут показаны заявки на текущий день</span>
            </div>
          </element-group>
          <element-group title="По идентификатору" class="mr-2 px-3 py-2">
            <v-text-field
              solo
              @keyup.enter="getOrders"
              clearable
              v-model="selectedPupilID"
              label="Лицевой счет"
            />
            <v-text-field
              solo
              @keyup.enter="getOrders"
              clearable
              v-model="selectedOrderID"
              label="Номер заказа"
            />
          </element-group>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <order-restore-default :schools="schoolsList" class="ma-2" />
        <v-btn
          @click="getOrders"
          small
          color="primary"
          class="mr-3"
        >
          Найти
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
  <div>
    <superadmin-orders-table-v2
        :orders="foundOrders"
        v-on:updateOrder="data => updateOrder(data)"
    />
  </div>
</v-container>
</template>

<style scoped>

</style>
