<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  getSupplierDetail,
  getDepartments,
  assignDepartment,
  setOrganizerPassword,
  getBoardingSchoolsDepartmentList,
  updateSupplierDetail
} from '@/router/routes/superadmin'
import store from '@/store'
import AssignDepartmentsModal from '@/components/moduleadmin/Modals/AssignDepartmentsModal'
import notify from '@/utils/notifications/toastNotification'
import rulesCustom from '@/helpers/rulesCustom'

export default {
  name: 'ModuleAdminOPCard',
  components: { AssignDepartmentsModal },
  data() {
    const rules = rulesCustom

    return {
      rules,
      isValid: false,
      isEditMode: false,
      organizer: {},
      assignDepartmentsDialog: false,
      departments: [],
      department: null,
      selectDepartment: null,
      isBudget: null,
      setPassword: {
        show: false,
        new_password: ''
      },
      isNewPaymentGateway: false,
      changedNewPaymentGateway: false,
      dialogChangePaymentGateway: false
    }
  },
  computed: {
    ...mapGetters(['setting']),

    isViewComponent() {
      return this.$route.params.meta === 'view'
    },
    companyName() {
      return this.isViewComponent
        ? 'Просмотр организатора питания'
        : 'Редактирование организатора питания'
    },
    isUnequal() {
      return this.department !== this.selectDepartment?.id
    },
    disabledSubmit() {
      return !this.isUnequal || !this.isValid
    },
    isSberNewGateway() {
      return this.setting('sber_new_gateway')
    }
  },
  async created() {
    await this.getSupplier()
  },
  methods: {
    ...mapMutations(['setShowPreloader']),
    async getSupplier() {
      try {
        const {
          data: { data: opCard }
        } = await getSupplierDetail(this.$route.params.id)

        this.setData(opCard)

        const {
          data: { data: departments }
        } = await getDepartments()
        this.departments = departments

        const {
          data: { data: boardingDepartments, success }
        } = await getBoardingSchoolsDepartmentList()

        if (success) {
          this.departments.push(
            ...boardingDepartments.map((item) => {
              return { ...item, shortName: item.short_name }
            })
          )
        }

        this.setSelectDepartment()
      } catch (e) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async doSetPassword() {
      try {
        await setOrganizerPassword({
          id: this.$route.params.id,
          data: {
            new_password: this.setPassword.new_password
          }
        })
        this.setPassword = {
          show: false,
          new_password: ''
        }
        notify.success('Пароль обновлен')
      } catch (e) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async assignDepartments() {
      const { id } = this.selectDepartment
      if (!id) {
        return
      }

      try {
        const payload = {
          supplierId: this.$route.params.id,
          departmentId: id
        }
        if (!id) {
          return notify.error('Выберите муниципалитет')
        }
        await assignDepartment(payload)
	      
        this.department = id
        notify.success('Муниципалитет успешно назначен')
        this.closeEditMode()
      } catch (e) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    setSelectDepartment() {
      this.selectDepartment =
        this.departments.find((i) => i.id === this.department) || null
    },

    getDepartmentName() {
      const department = _.find(this.departments, { id: this.department })

      return _.get(department, 'shortName', 'Назначить муниципалитет')
    },

    setBudget(value) {
      this.isBudget = value
    },
    showField(item) {
      switch (item.key) {
	      case 'personalAccount':
        case 'cbc':
        case 'oktmo':
          return this.isBudget
        default:
          return true
      }
    },
    closeEditMode() {
      this.isEditMode = false
      this.setPassword.show = false
      this.setPassword.new_password = ''
    },

    setData(org) {
      this.setOrganizer(org)
      this.isBudget = _.get(org, 'is_budget')
      this.department = _.get(org, 'department', null)
      this.isNewPaymentGateway = _.get(org, 'is_new_payment_gateway', false)
      this.changedNewPaymentGateway = _.get(
        org,
        'is_new_payment_gateway',
        false
      )
    },

    setOrganizer(org) {
      this.organizer = {
        username: {
          key: 'username',
          type: 'text',
          label: 'Логин',
          value: _.get(org, 'username', '')
        },
        fullName: {
          key: 'fullName',
          type: 'text',
          label: 'Полное название',
          value: _.get(org, 'full_name', '')
        },
        shortName: {
          key: 'shortName',
          type: 'text',
          label: 'Краткое название',
          value: _.get(org, 'short_name', '')
        },
        managerName: {
          key: 'managerName',
          type: 'text',
          label: 'Руководитель',
          value: _.get(org, 'manager_name', '')
        },
        postalAddress: {
          key: 'postalAddress',
          type: 'text',
          label: 'Юридический адрес',
          value: _.get(org, 'postal_address', '')
        },
        legalAddress: {
          key: 'legalAddress',
          type: 'text',
          label: 'Фактический адрес',
          value: _.get(org, 'legal_address', '')
        },
        phone: {
          key: 'phone',
          type: 'text',
          label: 'Телефон',
          value: _.get(org, 'phone', '')
        },
        site: {
          key: 'site',
          type: 'text',
          label: 'Сайт',
          value: _.get(org, 'site', '')
        },
        mail: {
          key: 'mail',
          type: 'text',
          label: 'E-mail',
          value: _.get(org, 'mail', '')
        },
        inn: {
          key: 'inn',
          type: 'text',
          label: 'ИНН',
          value: _.get(org, 'inn', '')
        },
        kpp: {
          key: 'kpp',
          type: 'text',
          label: 'КПП',
          value: _.get(org, 'kpp', '')
        },
        personalAccount: {
          key: 'personalAccount',
          type: 'text',
          label: 'Лицевой счет',
          value: _.get(org, 'personal_account', '')
        },
        settlementAccount: {
          key: 'settlementAccount',
          type: 'text',
          label: 'Расчетный счет',
          value: _.get(org, 'settlement_account', '')
        },
        bank: {
          key: 'bank',
          type: 'text',
          label: 'Банк',
          value: _.get(org, 'bank', '')
        },
        bic: {
          key: 'bic',
          type: 'text',
          label: 'БИК',
          value: _.get(org, 'bic', '')
        },
        ogrn: {
          key: 'ogrn',
          type: 'text',
          label: 'ОГРН',
          value: _.get(org, 'ogrn', '')
        },
        correspondentAccount: {
          key: 'correspondentAccount',
          type: 'text',
          label: 'Корр. счет',
          value: _.get(org, 'correspondent_account', '')
        },
        kbk: {
          key: 'cbc',
          label: 'КБК',
          value: _.get(org, 'cbc', '')
        },
        oktmo: {
          key: 'oktmo',
          type: 'text',
          label: 'ОКТМО',
          value: _.get(org, 'oktmo', '')
        },
        isBudget: {
          key: 'isBudget',
          type: 'checkbox',
          label: 'Бюджетная организация',
          value: _.get(org, 'is_budget', false)
        }
      }
    },

    setNewPaymentGateway() {
      this.dialogChangePaymentGateway = true
    },

    canselChangePaymentGateway() {
      this.changedNewPaymentGateway = this.isNewPaymentGateway
      this.dialogChangePaymentGateway = false
    },

    async changeNewPaymentGateway() {
      try {
        const {
          data: { data: org }
        } = await updateSupplierDetail(this.$route.params.id, {
          is_new_payment_gateway: this.changedNewPaymentGateway
        })
        this.setData(org)
      } catch (e) {
        this.changedNewPaymentGateway = this.isNewPaymentGateway
      } finally {
        this.dialogChangePaymentGateway = false
        store.commit('setShowPreloader', false)
      }
    }
  },
  watch: {
    isEditMode(newValue) {
      if (!newValue) {
        this.setSelectDepartment()
      }
    }
  }
}
</script>

<template>
  <v-container class="fs-organizer-container">
    <v-toolbar :class="{ 'cyan lighten-5': isEditMode }" extension>
      <v-btn
        flat
        color="blue"
        variant="tonal"
        @click="$router.push({ name: 'ModuleAdminOPList' })"
      >
        <v-icon>arrow_back</v-icon>Назад
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title class="text-truncate text-no-wrap px-2"
        >Карточка организатора питания</v-toolbar-title
      >
      <v-switch
        v-model="isEditMode"
        hide-details
        class="text-no-wrap px-2 blue-grey--text align-center justify-end"
      >
        <template v-slot:prepend>
          Режим редактирования: {{ isEditMode ? 'Вкл' : 'Выкл' }}
        </template>
      </v-switch>
    </v-toolbar>

    <v-layout class="rounded rounded-md" elevation-3>
      <v-flex>
        <v-layout row wrap pa-3>
          <v-flex
            grow
            pa-1
            md6
            xs12
            v-for="field in organizer"
            :key="field.key"
            v-if="showField(field) && field.key !== 'isBudget'"
          >
            <v-text-field
              :label="field.label"
              v-model="field.value"
              variant="filled"
              readonly
              hide-details
              required
              :disabled="isEditMode"
            />
          </v-flex>
          <v-flex grow pa-1 md12 xs12>
            <v-checkbox
              label="Бюджетная организация"
              @change="setBudget($event)"
              v-model="isBudget"
              readonly
              hide-details
              :disabled="isEditMode"
              >{{ isBudget }}</v-checkbox
            >
          </v-flex>

          <!-- Новый платежный шлюз Настройка - sber_new_gateway -->
          <v-flex grow pa-1 md12 xs12 v-if="isSberNewGateway">
            <v-checkbox
              label="Новый платежный шлюз"
              :readonly="!isEditMode"
              @change="setNewPaymentGateway()"
              v-model="changedNewPaymentGateway"
            ></v-checkbox>

            <!-- Модалка -->
            <v-dialog
              v-model="dialogChangePaymentGateway"
              max-width="550"
              persistent
            >
              <v-card>
                <v-card-title class="headline">
                  <slot name="headline"
                    >{{
                      !isNewPaymentGateway
                        ? 'Включить новый платежный шлюз'
                        : 'Отключить новый платежный шлюз'
                    }}
                  </slot>
                </v-card-title>
                <v-card-actions>
                  <v-btn flat @click="canselChangePaymentGateway()">
                    Отмена
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-btn @click="changeNewPaymentGateway()" color="success">
                    {{ !isNewPaymentGateway ? 'Включить' : 'Отключить' }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>

          <v-form
            class="d-flex grow"
            v-model="isValid"
            @submit.prevent="assignDepartments()"
          >
            <v-flex row wrap md12 xs12>
              <v-flex class="d-flex" grow pa-1 md12 xs12>
                <v-combobox
                  v-model="selectDepartment"
                  :items="departments"
                  chips
                  label="Муниципалитет"
                  hide-details
                  :box="isEditMode"
                  :readonly="!isEditMode"
                  item-text="shortName"
                  item-value="id"
                ></v-combobox>
              </v-flex>
              <v-flex
                class="d-flex"
                grow
                pa-1
                md12
                xs12
                v-if="isEditMode"
                style="gap: 10px"
              >
                <v-btn @click="closeEditMode"> Отмена </v-btn>
                <v-btn color="primary" :disabled="disabledSubmit" type="submit">
                  Сохранить
                </v-btn>
              </v-flex>
            </v-flex>
          </v-form>
        </v-layout>
        <v-divider></v-divider>
        <!--сброс пароля-->
        <v-flex md6 xs12 pa-3>
          <v-btn
            v-if="!setPassword.show"
            class="fs-btn v-btn--active"
            flat
            @click="setPassword.show = true"
            >Установить новый пароль</v-btn
          >
          <v-flex v-else grow pa-2>
            <v-text-field
              v-model="setPassword.new_password"
              label="Введите пароль, минимум 8 символов"
            />
          </v-flex>
          <v-flex v-if="setPassword.show">
            <v-btn
              :disabled="setPassword.new_password.length < 8"
              @click="doSetPassword"
              >Установить пароль</v-btn
            >
          </v-flex>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>
