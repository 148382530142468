import ParentStartPage from '@/components/parent/StartPage/Index'
import PersonalAreaIndex from '@/components/parent/PersonalArea/Index'
import BalanceAddIndex from '@/components/parent/Balance/Add/Index'
import ParentChildHistory from '@/components/parent/History/Index'
import BalanceMovingIndex from '@/components/parent/Balance/Moving/Index'
import ParentOfertaIndex from '@/components/parent/Oferta/Index'
import ParentOrganizerCard from '@/components/parent/OrganizerCard/Index'
import ParentOrderIndex from '@/components/parent/Order/Index'
import ParentTrafficIndex from '@/components/parent/Traffic/Index'
import ParentConcessionsIndex from '@/components/parent/Concessions/Index'
// import ParentReportsIndex from '@/components/parent/Reports/Index'
import ParentCashbackIndex from '@/components/parent/Cashback/Index'
import ParentSyrvey from '@/components/parent/Syrvey/Syrvey'
import ParentSyrveyDetail from '@/components/parent/Syrvey/SyrveyDetail'
import BlackList from '@/components/parent/BlackList/BlackList'
import BlackListDetail from '@/components/parent/BlackList/BlackListDetail'
import ParentQualityAssessments from '@/components/parent/QualityAssessments/Index'
import BuffetMenu from '@/components/parent/BuffetMenu/Index'

import PupilSelectionList from '@/components/lib/PupilSelectionList'
import NotificationIndex from '@/components/common/NotificationsComponent/Index'
import UpdatesNewsParent from '@/components/parent/UpdatesNews/Index'
import UserHelp from "@/components/common/UserHelp/Index.vue"
import HelpDesk from "@/components/common/HelpDesk/HelpDesk.vue"

export default [
  {
    path: '/',
    name: 'Parent',
    component: ParentStartPage,
    props: route => ({
      ...route.params
    })
  },
  {
    path: 'updates',
    name: 'UpdatesNewsParent',
    component: UpdatesNewsParent
  },
  {
    path: 'user-help',
    name: 'ParentUserHelp',
    component: UserHelp
  },
  {
    path: 'helpdesk',
    name: 'HelpDeskPA',
    component: HelpDesk,
    meta: { userRole: 'parent' }
  },
  {
    path: 'pupils',
    name: 'PupilSelectionList',
    component: PupilSelectionList,
    props: route => ({
      ...route.params
    })
  },
  {
    path: 'syrvey',
    name: 'Syrvey',
    component: ParentSyrvey
  },
  {
    path: 'syrvey/syrvey-detail',
    name: 'SyrveyDetail',
    component: ParentSyrveyDetail
  },
  {
    path: 'personal-area',
    name: 'PersonalArea',
    component: PersonalAreaIndex
  },
  {
    path: 'balance/add',
    name: 'BalanceAdd',
    component: BalanceAddIndex
  },
  {
    path: 'balance/moving',
    name: 'BalanceMoving',
    component: BalanceMovingIndex
  },
  {
    path: 'history',
    name: 'ParentChildHistory',
    component: ParentChildHistory
  },
  {
    path: 'oferta',
    name: 'ParentOferta',
    component: ParentOfertaIndex
  },
  {
    path: 'organizer-card',
    name: 'ParentOrganizerCard',
    component: ParentOrganizerCard
  },
  {
    path: 'order',
    name: 'ParentOrderMenu',
    component: ParentOrderIndex
  },
  {
    path: 'traffic',
    name: 'ParentTrafficMenu',
    component: ParentTrafficIndex
  },
  {
    path: 'concessions',
    name: 'ParentConcessions',
    component: ParentConcessionsIndex
  },
  // TODO Удалить весь компонент если. Не используется
  // {
  //   path: 'reports',
  //   name: 'ParentReportsIndex',
  //   component: ParentReportsIndex
  // },
  {
    path: 'cashback',
    name: 'CashbackIndex',
    component: ParentCashbackIndex
  },
  {
    path: 'notifications',
    name: 'ParentNotifications',
    component: NotificationIndex,
    meta: {
      layoutClass: 'fs-parent-container'
    }
  },
  {
    path: 'blacklist',
    name: 'BlackList',
    component: BlackList
  },
  {
    path: 'blacklist/detail/',
    name: 'BlackListDetail',
    component: BlackListDetail
  },
  {
    path: 'quality-assesments',
    name: 'ParentQualityAssessments',
    component: ParentQualityAssessments
  },
  {
    path: 'buffet-menu',
    name: 'BuffetMenu',
    component: BuffetMenu
  },
]
