import {
  getFoodPeriods,
  getFoodTimes,
  getAgeCategories,
  getShifts,
  getNotifications,
  getNotificationsCount,
  getQualityAssesments,
  getQualityAssesmentsSuppliers,
  getQualityMenuPhotoReports,
  getFoodCategories,
} from '@/router/routes/common'
import {getOrganizerFoodTypes} from "@/router/routes/organizer";

export default {
  get_quality_avg_suppliers(ctx) {
    return getQualityAssesmentsSuppliers({
      ...ctx.getters.qualityAvgSuppliersPagination,
      ...ctx.getters.qualityAvgSuppliersFilters
    })
      .then(response => {
        ctx.commit('setQualityAvgSuppliers', response.data)
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        ctx.commit('setShowPreloader', false)
      })
  },
  get_quality_assesments(ctx) {
    return getQualityAssesments({
      ...ctx.getters.qualityAssesmentsPagination,
      ...ctx.getters.qualityAssesmentsFilters
    })
      .then(response => {
        ctx.commit('setQualityAssesments', response.data)
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        ctx.commit('setShowPreloader', false)
      })
  },
  get_quality_menu_photo_reports(ctx) {
    return getQualityMenuPhotoReports({
      ...ctx.getters.qualityMenuPhotoReportsPagination,
      ...ctx.getters.qualityMenuPhotoReportsFilters
    })
      .then(response => {
        ctx.commit('setQualityMenuPhotoReports', response.data)
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        ctx.commit('setShowPreloader', false)
      })
  },
  GET_FOOD_TIMES({ commit, state }, payload) {
    return getFoodTimes().then(response => {
      const foodTimes = response.data.data
      commit('setFoodTimes', foodTimes)
    })
  },

  GET_FOOD_TYPES({ commit, state }, payload) {
    return getOrganizerFoodTypes().then(response => {
      const foodTypes = response.data.data
      commit('setFoodTypes', foodTypes)
    })
    // if (!payload) return
    // return payload.method(payload.params).then(response => {
    //   const foodTypes = response.data.data
    //   commit('setFoodTypes', foodTypes)
    // })
  },

  GET_FOOD_PERIODS({ commit, state }, payload) {
    return getFoodPeriods().then(response => {
      const foodPeriods = response.data.data
      commit('setFoodPeriods', foodPeriods)
    })
  },

  GET_AGE_CATEGORIES({ commit, state }, payload) {
    return getAgeCategories().then(response => {
      const ageCategories = response.data.data
      commit('setAgeCategories', ageCategories)
    })
  },

  GET_FOOD_CATEGORIES({ commit, state }, payload) {
    return getFoodCategories().then(response => {
      const foodCategories = response.data.data
      commit('setFoodCategories', foodCategories)
    })
  },

  GET_SHIFTS({ commit, state }, payload) {
    return getShifts().then(response => {
      const shifts = response.data.data
      commit('setShifts', shifts)
    })
  },

  GET_NOTIFICATIONS({ commit, state }, payload) {
    return getNotifications().then(response => {
      const notifications = response.data.data
      commit('setNotifications', notifications)
    })
  },

  GET_NOTIFICATIONS_COUNT({ commit, state }, payload) {
    return getNotificationsCount().then(response => {
      const count = response.data.data.count
      commit('setNotificationsCount', count)
    })
  }
}
