<template>
  <ParentOrderIndex is-child-view />
</template>

<script>
import ParentOrderIndex from '@/components/parent/Order/Index'

export default {
  name: 'PupilOrderIndex',
  components: {
    ParentOrderIndex
  }
}
</script>
