import Vue from 'vue'
import axios from 'axios'
// при выносе из проекта - добавить другой toastNotification
import notify from '@/utils/notifications/toastNotification.vue'

/**
 * @typedef {Object} ChatNode
 * @property {number} id
 * @property {string} title
 * @property {string} [content]
 * @property {number} [parent]
 */

/**
 * @typedef {Object} Message
 * @property {'text' | 'system'} type
 * @property {number} [id]
 * @property {'me' | 'support'} [author]
 * @property {{ text: string, meta?: string }} data
 * @property {ChatNode[]} [suggestions]
 */

/**
 * @typedef {Object} Chat
 * @property {number} id
 * @property {string} question
 * @property {string} [answer]
 * @property {string} [answer_at]
 * @property {string} created_at
 * @property {{ title: string; content?: string }[]} message_histories
 */

/**
 * @typedef {Object} State
 * @property {boolean} isLoading
 * @property {ChatNode[]} faqData
 * @property {number[]} history
 * @property {boolean} isDisableInput
 * @property {boolean} isShowChatList
 * @property {Chat|null} currentChat
 * @property {Message[]} messageList
 * @property {Chat[]} chatList
 * @property {number} newMessagesCount
 * @property {boolean} isOpen
 * @property {ChatNode[]} suggestions
 * @property {boolean} finish
 * @property {boolean} isMore
 * @property {Map<number, ChatNode>} mapNodes
 */

/**
 * @type {import('axios').AxiosInstance}
 */

// Создание нового экземпляра с использованием базовой настройки
const customAxios = axios.create({
  ...axios,
  baseURL: Vue.config.server,
  preloaderState: false
})

const urls = {
  chat: '/api/support-chat/',
  faq: '/api/chat-script/'
}

/**
 * @type {State}
 */
const state = Vue.observable({
  isLoading: false,
  faqData: [],
  history: [],
  isDisableInput: true,
  isShowChatList: true,
  currentChat: null,
  messageList: [],
  chatList: [],
  newMessagesCount: 0,
  isOpen: false,
  suggestions: [],
  finish: false,
  isMore: false,
  mapNodes: new Map()
})

export const getters = {
  /**
   * @returns {boolean}
   */
  isLoading: () => state.isLoading,

  /**
   * @returns {number[]}
   */
  history: () => state.history,

  /**
   * @returns {boolean}
   */
  isDisableInput: () => state.isDisableInput,

  /**
   * @returns {boolean}
   */
  isShowChatList: () => state.isShowChatList,

  /**
   * @returns {Chat|null}
   */
  currentChat: () => state.currentChat,

  /**
   * @returns {Message[]}
   */
  messageList: () => state.messageList,

  /**
   * @returns {Chat[]}
   */
  chatList: () => state.chatList,

  /**
   * @returns {number}
   */
  newMessagesCount: () => state.newMessagesCount,

  /**
   * @returns {ChatNode[]}
   */
  faqData: () => state.faqData,

  /**
   * @returns {boolean}
   */
  isOpen: () => state.isOpen,

  /**
   * @returns {ChatNode[]}
   */
  suggestions: () => state.suggestions,

  /**
   * @returns {boolean}
   */
  finish: () => state.finish,

  /**
   * @returns {boolean}
   */
  isMore: () => state.isMore,

  /**
   * @returns {Map<number, ChatNode>}
   */
  mapNodes: () => state.mapNodes
}

export const mutations = {
  /**
   * @param {boolean} val
   */
  setLoading: (val) => {
    state.isLoading = val
  },

  /**
   * @param {number[]} val
   */
  setHistory: (val) => {
    state.history = val
  },

  /**
   * @param {number} val
   */
  addHistory: (val) => {
    state.history.push(val)
  },

  /**
   * @param {boolean} val
   */
  setDisableInput: (val) => {
    state.isDisableInput = val
  },

  /**
   * @param {boolean} val
   */
  setShowChatList: (val) => {
    state.isShowChatList = val
  },

  /**
   * @param {Chat} val
   */
  setCurrentChat: (val) => {
    state.currentChat = val
  },

  /**
   * @param {Partial<Chat>} val
   */
  updateCurrentChat: (val) => {
    if (state.currentChat) Object.assign(state.currentChat, val)
  },

  /**
   * @param {Message[]} val
   */
  setMessageList: (val) => {
    state.messageList = val
  },

  /**
   * @param {Message} val
   */
  addMessage: (val) => {
    state.messageList.push(val)
  },

  /**
   * @param {Chat[]} val
   */
  setChatList: (val) => {
    state.chatList = val
  },

  /**
   * @param {Chat[]} val
   */
  addChatList: (val) => {
    state.chatList = [...state.chatList, ...val]
  },

  /**
   * @param {number} val
   */
  setNewMessagesCount: (val) => {
    state.newMessagesCount = val
  },

  /**
   * @param {ChatNode[]} val
   */
  setFaqData: (val) => {
    state.faqData = val
  },

  /**
   * @param {boolean} val
   */
  setOpen: (val) => {
    state.isOpen = val
  },

  clear: () => {
    Object.assign(state, {
      currentChat: null,
      messageList: [],
      history: [],
      isDisableInput: true,
      isShowChatList: true,
      suggestions: []
    })
  },

  /**
   * @param {ChatNode[]} val
   */
  setSuggestions: (val) => {
    state.suggestions = val
  },

  /**
   * @param {ChatNode} val
   */
  addSuggestion: (val) => {
    state.suggestions.push(val)
  },

  /**
   * @param {number} [val=0]
   */
  setCountUnread: (val = 0) => {
    state.newMessagesCount = val
  },

  /**
   * @param {boolean} val
   */
  setFinish: (val) => {
    state.finish = val
  },

  /**
   * @param {boolean} val
   */
  setMore: (val) => {
    state.isMore = val
  },

  resetMapNodes: () => {
    state.mapNodes.clear()
  },

  /**
   * @param {ChatNode[]} val
   */
  setMapNodes: (val) => {
    val.forEach((node) => state.mapNodes.set(node.id, node))
  }
}

export const actions = {
  /**
   * Получает первые Ноды для Faq
   * @param {number|null} [id=null]
   * @returns {Promise<void>}
   */
  async fetchFaq(id = null) {
    if (id === null) {
      mutations.resetMapNodes()
    }
    mutations.setLoading(true)
    try {
      const {
        data: { data }
      } = await customAxios.get(urls.faq, {
        params: {
          chat_node_id: id
        }
      })
      mutations.setFaqData(data)
      mutations.setMapNodes(data)
      mutations.setSuggestions(data)
    } catch (e) {
      console.error(e.message)
    } finally {
      mutations.setLoading(false)
    }
  },

  /**
   * Получает список чатов пользователя
   * @param {number} [offset=0]
   * @param {number} [limit=20]
   * @returns {Promise<void>}
   */
  async fetchChatList(offset = 0, limit = 20) {
    mutations.setLoading(true)
    const payload = { params: { offset, limit } }
    try {
      const { data } = await customAxios.get(urls.chat, payload)
      if (offset === 0) {
        mutations.setChatList(data.data)
      } else {
        mutations.addChatList(data.data)
      }
      mutations.setFinish(data.data.length < limit)
      mutations.setCountUnread(data.unread)
      setTimeout(() => {
        mutations.setMore(false)
      }, 500)
    } catch (e) {
      console.error(e.message)
    } finally {
      mutations.setLoading(false)
    }
  },

  /**
   * Подгружаем Чаты пользователя
   * @returns {Promise<void>}
   */
  async loadMoreChats() {
    if (!getters.finish() && !getters.isLoading()) {
      const offset = getters.chatList().length
      mutations.setMore(true)
      await actions.fetchChatList(offset)
    }
  },

  /**
   * Получаем текущий чат
   * @param {number} chat_id
   * @returns {Promise<void>}
   */
  async fetchChat(chat_id) {
    mutations.setLoading(true)
    try {
      const { data } = await customAxios.get(`${urls.chat}${chat_id}/`)
      mutations.setCurrentChat(data)
      // проверить прочитан ли чат
      await actions.checkReadChat(data)
    } catch (e) {
      console.error(e.message)
    } finally {
      mutations.setLoading(false)
    }
  },

  /**
   * Открываем выбранный чат
   * @param {{ id: number }} Chat - Объект, содержащий идентификатор чата
   * @returns {Promise<void>}
   */
  async openChatCard({ id }) {
    await actions.fetchChat(id)
    mutations.setMessageList([])
    const chat = getters.currentChat()
    actions._convertHistory(chat)
    actions._convertToMessageFormat(chat)
    mutations.setShowChatList(false)
  },

  /**
   * Загружает ноды с Faq при клике на кнопку задать вопрос
   * @returns {Promise<void>}
   */
  async askQuestion() {
    await this.fetchFaq()
    mutations.setShowChatList(false)
    mutations.setMessageList([
      {
        type: 'text',
        id: 0,
        data: {
          text: 'Здравствуйте, <br> Чем мы можем вам помочь?'
        },
        suggestions: this.faqData
      }
    ])
  },

  /**
   * Отправить сообщение
   * @param {Message} message
   * @returns {Promise<void>}
   */
  async sendMessage(message) {
    mutations.setLoading(true)
    try {
      const { data } = await customAxios.post(urls.chat, {
        question: message.data.text,
        message_histories: getters.history()
      })
      actions._convertToMessageFormat(data)
      mutations.setDisableInput(true)
      mutations.setSuggestions([])
      await actions.fetchChatList()
    } catch (e) {
      console.error(e.message)
    } finally {
      mutations.setLoading(false)
    }
  },

  /**
   * Отметить прочитанным
   * @param {number} chat_id
   * @returns {Promise<void>}
   */
  async markAsRead(chat_id) {
    mutations.setLoading(true)
    try {
      const { data } = await customAxios.patch(`${urls.chat}${chat_id}/`, {
        is_read: true
      })
      mutations.updateCurrentChat(data.is_read)
    } catch (e) {
      console.error(e.message)
    } finally {
      mutations.setLoading(false)
    }
  },

  /**
   * @param {{ id: number; is_read: boolean; answer?: string }} Chat - Объект, содержащий информацию о чате
   * @returns {Promise<void>}
   */
  async checkReadChat({ id, is_read, answer }) {
    if (!is_read && answer) {
      await actions.markAsRead(id)
      await actions.fetchChatList()
    }
  },

  /**
   * Конвертирует историю сообщений чата в формат сообщений для отображения.
   * @param {{ message_histories: Array<{ title: string, content?: string }> }} chat - Объект чата, содержащий историю сообщений.
   */
  _convertHistory(chat) {
    chat?.message_histories.forEach((item) => {
      const { title, content } = item
      mutations.addMessage({
        type: 'text',
        author: `me`,
        data: {
          text: title
        }
      })
      if (content) {
        mutations.addMessage({
          type: 'text',
          data: { text: content }
        })
      }
    })
  },

  /**
   * Конвертирует чат в формат сообщений для отображения.
   * @param {{ answer?: string, answer_at?: string, created_at: string, id: number, question: string }} chat - Объект чата.
   */
  _convertToMessageFormat(chat) {
    const { answer, answer_at, created_at, id, question } = chat
    mutations.addMessage({
      type: 'text',
      author: `me`,
      id,
      data: {
        text: question,
        meta: new Date(created_at).toLocaleString('ru-RU')
      }
    })
    mutations.addMessage(
      answer
        ? {
            type: 'text',
            author: `support`,
            id,
            data: {
              text: answer,
              meta: new Date(answer_at).toLocaleString('ru-RU')
            }
          }
        : {
            type: 'system',
            data: {
              text: 'Ожидайте ответа специалиста. \n Обычно ответ занимает около 48 часов'
            }
          }
    )
  },

  /**
   * Обрабатывает предложение пользователя и выполняет соответствующее действие.
   * @param {{ type: string, title: string, id?: number, content?: string, action?: object, parent?: number, component_name?: string }} data - Данные предложения.
   * @returns {Promise<void>}
   */
  async sendSuggestion(data) {
    if (data.type === 'redirect') {
      this.$router.history.current.name !== data.component_name
        ? await this.$router.push({ name: data.component_name })
        : notify.info(`Вы уже перешли к необходимой вкладке`)
    } else {
      mutations.setDisableInput(data.type !== 'question')
      const question = {
        type: 'text',
        author: `me`,
        data: {
          text: data.title
        }
      }
      if (data.type !== 'back') {
        mutations.addMessage(question)
        mutations.addHistory(data.id)
      }

      await actions.fetchFaq(data.id)

      if (data.action) {
        mutations.addSuggestion(data.action)
      }

      if (data.id) {
        // удаляем предыдущие кнопки
        mutations.setSuggestions(
          state.suggestions.filter((suggestion) => suggestion.type !== 'back')
        )
        const backItem = {
          id: data.parent || null,
          type: 'back',
          icon: 'undo',
          title: 'Назад',
          parent: getters.mapNodes().get(data.parent)?.parent || null
        }
        mutations.addSuggestion(backItem)
      }

      if (data.content) {
        mutations.addMessage({
          type: 'text',
          data: { text: data.content }
        })
      }
    }
  },

  /**
   * Открывает чат и сбрасывает его состояние.
   */
  openChat() {
    mutations.setOpen(true)
    mutations.clear()
  },

  /**
   * Закрывает чат и сбрасывает его состояние.
   */
  closeChat() {
    mutations.setOpen(false)
    mutations.clear()
  },

  /**
   * Обрабатывает действие "Назад", сбрасывая состояние чата.
   */
  handleBack() {
    mutations.clear()
  },

  /**
   * Запускает интервал для проверки новых чатов.
   */
  startChatCheckInterval() {
    this.chatCheckInterval = setInterval(async () => {
      await actions.fetchChatList()
    }, 180000) // 180000 мс = 3 минуты
  },

  /**
   * Останавливает интервал для проверки новых чатов.
   */
  stopChatCheckInterval() {
    if (this.chatCheckInterval) {
      clearInterval(this.chatCheckInterval)
    }
  }
}
