import store from '@/store'
import notify from '@/utils/notifications/toastNotification'

export default () => {
  // 2 minutes timeout
  const poolingTimeout = 120000
  let poolingTimer = null
  try {
    poolingTimer = setTimeout(function pooling() {
      store.dispatch('GET_NOTIFICATIONS_COUNT')
      setTimeout(pooling, poolingTimeout)
    }, poolingTimeout)
  } catch (error) {
    notify.error('Ошибка получения нотификаций') //pass
    clearTimeout(poolingTimer)
  }
}
