<template>
  <Pug>
    <h1>403</h1>
    <p>В настоящий момент доступ в данный раздел ограничен для вашей учётной записи.</p>
  </Pug>
</template>

<script>
import Pug from '@/components/lib/Pug'

export default {
  name: 'Forbidden',
  components: {
    Pug
  }
}
</script>
