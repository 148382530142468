<template>
  <Container>
    <Title layout>{{ title }}</Title>
    <v-layout>
      <v-form
        v-model="formValid"
        ref="form"
        lazy-validation
      >
        <v-flex mb-5>
          <div class="headline mb-3">Дата конца и начала учебного года</div>
          <div class="mb-2">
            Смена актуального года в системе может повести за собой невозможность просмотра информации пользователей
            с ролью &laquo;Учитель&raquo; и &laquo;Администратор питания&raquo;, если учебный год текущих классов не
            будет соответствовать выбранному.
          </div>
          <div class="mb-2">
            Укажите дату, с которой должен показываться новый учебный год для &laquo;Учителей&raquo; и
            &laquo;Администраторов питания&raquo;.
          </div>
          <div v-if="midDate" class="mb-3">
            Текущая дата: <b>{{ formattedMidDate }}</b>
          </div>
          <v-date-picker
            v-model="midDate"
            :min="minDate"
            :max="maxDate"
            prev-icon="keyboard_arrow_left"
            next-icon="keyboard_arrow_right"
            first-day-of-week="1"
            locale="ru"
            no-title
            @input="selectDate"
          ></v-date-picker>
        </v-flex>
        <v-flex mb-5>
          <div class="headline mb-3">Учебные дни школ</div>
          <div class="mb-2">
            Данная настройка предназначена исключительно для тестирования. При указании рабочей недели учебные дни
            школ будут создаваться системой, а не браться напрямую из ЭШ.
          </div>
          <v-select
            v-model="workingDays"
            :items="workingDaysItems"
            item-text="text"
            item-value="value"
            label="Рабочая неделя"
            clearable
          ></v-select>
        </v-flex>
        <v-flex v-if="enableQualityControl" mb-5>
          <div class="headline mb-3">Порог оценки для ОП</div>
          <v-text-field
            v-model="evaluation_threshold"
            :label="!darkTheme ? 'Текущий порог' : ''"
            :placeholder="darkTheme ? 'Текущий порог' : ''"
            :outline="darkTheme"
            type="number"
            min="0"
          />
        </v-flex>
      </v-form>
    </v-layout>
    <v-layout column align-end>
      <v-flex>
        <v-btn
          :disabled="!checkFormValid"
          class="mx-0"
          color="primary"
          @click="saveSettings()"
        >Сохранить</v-btn>
      </v-flex>
    </v-layout>
  </Container>
</template>

<script>
import Vue from 'vue'
import {
  getSettings,
  updateSettings
} from '@/router/routes/superadmin'
import { mapMutations } from 'vuex'
import store from '@/store'

import Container from '@/ui-components/Container'
import Title from '@/ui-components/Title'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ModuleAdminSettingsIndex',
  components: {
    Container,
    Title
  },
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      formValid: true,
      evaluation_threshold: 0,
      title: 'Настройки системы',
      midDate: null,
      enableQualityControl: Vue.config.env.modQualityControl,
      workingDays: 0,
      workingDaysItems: [
        { text: '5-дневная', value: 5 },
        { text: '6-дневная', value: 6 },
        { text: '7-дневная', value: 7 }
      ]
    }
  },
  computed: {
    checkFormValid() {
      return (
        this.formValid && !!this.midDate
      )
    },
    minDate() {
      return (new Date()).getFullYear() + '-06-01'
    },
    maxDate() {
      return (new Date()).getFullYear() + '-08-30'
    },
    formattedMidDate() {
      if (!this.midDate) return '-'
      return new Date(this.midDate).toLocaleDateString('ru')
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async init() {
      await this.loadSettings()
    },
    selectDate(date) {
      this.midDate = date
    },
    formatDate(date) {
      const d = new Date(date)
      const month = d.getMonth() + 1
      const day = d.getDate()
      return d.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day)
    },
    async loadSettings() {
      try {
        const response = await getSettings()
        const {
          data: {
            data: {
              mid_date,
              evaluation_threshold,
              working_days
            }
          }
        } = response
        this.midDate = mid_date ? this.formatDate(mid_date) : null
        this.evaluation_threshold = this.enableQualityControl ? evaluation_threshold : 0
        this.workingDays = +working_days
      } catch (error) {
        // notify.error('Ошибка загрузки настроек') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async saveSettings() {
      try {
        const PAYLOAD = {
          data: {
            mid_date: this.midDate,
            evaluation_threshold: this.evaluation_threshold,
            working_days: this.workingDays || 0
          }
        }
        const response = await updateSettings(PAYLOAD)
        const {
          data: {
            data: {
              mid_date,
              evaluation_threshold,
              working_days
            }
          }
        } = response
        this.midDate = mid_date ? this.formatDate(mid_date) : null
        this.evaluation_threshold = evaluation_threshold
        this.workingDays = +working_days || 0
        notify.success('Настройки сохранены')
      } catch (error) {
        // notify.error('Ошибка сохранения настроек') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
  },
}
</script>
