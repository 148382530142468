<template>
  <v-dialog v-model="show" :max-width="width" persistent no-click-animation>
	  <v-card>
		  <v-toolbar elevation="1" dense light>
			  <v-toolbar-title>Смена типа питания для ученика</v-toolbar-title>
			  <v-spacer></v-spacer>
			  <v-btn icon @click="show = false">
				  <v-icon class="fs-active-icon">close</v-icon>
			  </v-btn>
		  </v-toolbar>

		  <v-card-text>
			  <v-layout class="fs-complex" wrap mb-3 v-if="!foodTypes.length">
				  <slot name="empty">
					  <v-flex>
						  <p class="subheading">
							  По категории соцвыплаты ученика не были найдены типы питания, либо специалист МОУО их еще не настроил
						  </p>
					  </v-flex>
				  </slot>
			  </v-layout>
			  <v-layout else style="display: grid">
				  <span class="font-weight-bold">Текущий тип питания:
					  {{ currentFoodType ? `${currentFoodType.name} (${balancable.methods.convertToRubles(currentFoodType.price)} руб.)` : 'не выбран' }}
				  </span>
				  <v-radio-group
					  v-model="type"
					  v-for="(foodType, index) in foodTypes"
					  :key="index"
					  :mandatory="false"
					  md4
				  >
					  <v-layout row>
						  <v-flex>
							  <v-radio
								  :value="foodType.id"
								  :label="`${foodType.name} (${(foodType.price / 100).toFixed(2)} руб.)`"
								  class="fs-radio"
								  color="primary"
							  ></v-radio>
						  </v-flex>
					  </v-layout>
				  </v-radio-group>
				  <v-flex text-md-right mt-3 mr-2>
					  <with-tooltip text="Обновить информацию">
						  <v-btn
							  :disabled="type === null"
							  v-if="foodTypes.length"
							  class="fs-btn fs-btn--primary fs-form-footer__btn"
							  flat
							  @click="save"
						  >Обновить</v-btn>
					  </with-tooltip>
				  </v-flex>
			  </v-layout>
		  </v-card-text>
	  </v-card>
  </v-dialog>
</template>

<script>
import balancable from "@/mixins/balancable"
import WithTooltip from "@/components/common/Tooltip/Tooltip.vue";
export default {
  name: 'ChangeFoodTypeModal',
	components: {WithTooltip},
  props: {
    value: {
      required: true,
      type: Boolean
    },
    width: {
      default: 500
    },
    className: {
      default: 'fs-container'
    },
    studentFoodTypeId: null,
	  studentFoodTypeIDNow: {
      default: null
	  },
    foodTypes: {
      required: true
    }
  },
  data() {
    return {
      title: 'Изменить тип питания',
      type: null,
      noFoodTypes: true
    }
  },
  computed: {
	  balancable() {
		  return balancable
	  },
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
	  currentFoodType() {
		  return this.foodTypes.find(obj => obj.id === this.studentFoodTypeId)
	  }
  },
  methods: {
    save() {
      this.$emit('changeType', this.type)
      this.show = false
    },
  },
  watch: {
    show(show) {
      this.type = this.studentFoodTypeId
    },
  }
}
</script>
