<template>
  <v-dialog v-model="showModal" max-width="600px" persistent>
    <v-card>
      <v-layout class="fs-dish" row>
        <div class="mt-4 mb-2 title-help">
          <div class="d-flex row">
            <h3 class="mt-1">Загрузить заявки</h3>
<!--            <context-help-modal :context-key="contextKeys.admin.dashboard.modals.negative_balance"/>-->
          </div>
        </div>
      </v-layout>
      <v-card-actions class="d-flex justify-content-space-between align-items-center flex-wrap">
        <v-menu
          v-model="datepickerMenu"
          :close-on-content-click="false"
          min-width="0"
          style="display: none"
          lazy
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              class="v-text-field__no_line"
              :prepend-inner-icon="!darkTheme ? 'event' : ''"
              :prepend-icon="darkTheme ? 'event' : ''"
              label="Дата создания заявок"
              v-model="date"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            class="fs-datepicker"
            prev-icon="keyboard_arrow_left"
            next-icon="keyboard_arrow_right"
            locale="ru"
            first-day-of-week="1"
            no-title
            v-model="date"
            @input="selectDate"
          ></v-date-picker>
        </v-menu>
      </v-card-actions>
      <div class="upload-border-div">
        <UploadButton
          :uniqueId="true"
          class="upload-btn-hidden"
          :title="this.fileName ? this.fileName : 'Нажмите на прямоугольную область и выберите файл'"
          block
          noTitleUpdate
          :fileChangedCallback="fileChanged"
          add-class="fs-btn fs-btn--primary"
        />
      </div>
      <v-card-actions class="d-flex justify-content-end align-items-center flex-wrap">
        <v-btn class="meal_admin-btn" color="#FF6347" @click="closeModal">Закрыть</v-btn>
        <v-btn class="meal_admin-btn" color="#69F0AE" @click="upload">Загрузить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import UploadButton from "@/components/common/UploadButton/UploadButton"
import _ from "lodash"
import notify from "@/utils/notifications/toastNotification"
import Vue from "vue"
import store from "@/store"
import { mapGetters } from "vuex"
import {
  getUploadLoadingOrdersFileStatus,
  getUploadLoadingOrdersFileStatusResult,
  uploadLoadingOrdersFile
} from "@/router/routes/admin"
import navigateTo from "@/mixins/navigateTo"
import asyncReqests from "@/helpers/asyncRequestForUpload"

export default {
  name: 'ModalLoadingOrders',
  components: { UploadButton },
  mixins: [ navigateTo ],
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      showModal: true,
      datepickerMenu: false,
      selectDate: null,
      date: null,
      fileName: '',
      file: {},
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    fileChanged(event){
      this.file = event
      this.fileName = event.name
    },
    resetPageFileLoaded() {
      this.closeModal()
      store.commit('setShowPreloader', false)
    },
    async upload() {
      if (_.get(this.file, 'name', '')) {
        const payload = {
          file: this.file,
          school_id: this.currentUser.school_id,
          date: this.date
        }
        const callback = this.resetPageFileLoaded
        try {
          await asyncReqests(
            uploadLoadingOrdersFile,
            getUploadLoadingOrdersFileStatus,
            getUploadLoadingOrdersFileStatusResult,
            payload,
            callback
          )
        } catch (error) {
          notify.error('Ошибка загрузки заявок')
        } finally {
          store.commit('setShowPreloader', false)
        }
      }
    }
  }
}
</script>

<style scoped>

.upload-border-div {
  border-style: dotted;
  border-width: 2px;
  border-color: grey;
  border-radius: 10px;
  min-height: 100px;
  max-width: 100%;
  margin: 0 10px;
}

.upload-btn-hidden {
  width: 100%;
  padding: 0;
}

.meal_admin-btn {
  font-size: 14px;
  padding: 8px 16px;
  width: 120px;
  margin: 8px;
}

.upload-btn >>> label {
  font-size: 11px !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  color: inherit;
  height: 100px;
}

.upload-btn :hover:before {
  background: none;
  box-shadow: none;
  color: inherit;
}

</style>