<template>
  <v-container fluid>
    <v-layout row>
      <v-flex mb-3>
        <h1>{{ title }}</h1>
        <v-btn v-if="content" color="primary" class="mt-4" @click="editorModal = true">
          Изменить
        </v-btn>
        <v-btn v-else color="primary" class="mt-4" @click="editorModal = true">
          Создать
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-card ps3 pe3>
        <v-card-text v-if="content">
          <span v-html="content"/>
        </v-card-text>
      </v-card>
    </v-layout>
    <privacy-modal
      :show.sync="editorModal"
      :content="content"
    />
  </v-container>
</template>

<script>
import PrivacyModal from "@/components/organizer/Modals/PrivacyModal"
import { getPrivacy } from "@/router/routes/organizer"
import store from "@/store"

export default {
  name: 'Privacy',
  components: { PrivacyModal },
  data() {
    return {
      title: 'Политика конфиденциальности',
      editorModal: false,
      content: ''
    }
  },
  created() {
    this.getContent()
  },
  methods: {
    setContent(content) {
      this.content = content
    },
    async getContent() {
      const res = await getPrivacy()
      this.content = res.data.content
      store.commit('setShowPreloader', false)
    }
  }
}
</script>
