<template>
  <v-container fluid class="fs-organizer-container">
    <div class="page-head__common"><h1>{{title}}</h1></div>

    <v-layout class="fs-dish-result" mb-3>
      <div class="user-page__filters">
        <v-text-field
          label="Поиск по имени"
          append-icon="search"
          @keyup.enter="clickSearch"
          @click:clear="clearSearch"
          @click:append="clickSearch"
          clearable
          v-model="search_field"
          style="margin: 0px 10px;"
        />

        <v-select
          :items="all_roles"
          item-text="name"
          item-value="id"
          label="Роли"
          v-model="role_field"
          style="margin: 0px 10px;"
        ></v-select>
      </div>
    </v-layout>

    <v-layout class="fs-dish-result" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="all_users.results"
          hide-actions
          no-data-text="Нет пользователей"
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ showUserRoles(props.item.roles) }}</td>
              <td>{{ props.item.username }}</td>
              <td>{{ props.item.full_name ? props.item.full_name:'-'}}</td>
              <td>{{ props.item.phone ? props.item.phone:'-'}}</td>
              <td>{{ props.item.email ? props.item.email:'-'}}</td>
              <td>{{ props.item.is_active ? 'Да' : 'Нет' }}</td>
              <td>{{ props.item.is_staff ? 'Да' : 'Нет' }}</td>
              <td>
                <v-flex row>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="editUser(props.item.id)"
                      >edit</v-icon>
                    </template>
                    <span>Редактирование</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog v-model="is_edit_user" persistent width="70%">
      <UserDetail
        v-if="is_edit_user"
        :user_id="user_id"
        :is_create="false"
        @close="closeUserDetail"
      />
    </v-dialog>

    <div class="text-xs-center">
      <v-pagination
        :value="users_pagination.page"
        :length="pagination_count"
        :total-visible="7"
        @input="clickPagination"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import UserDetail from '@/components/moduleadmin/UsersList/UserDetail'

export default {
  name: 'UserList',
  components: { UserDetail },
  data: () => ({
    title: 'Все пользователи',
    is_edit_user: false,
    user_id: null,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Роли', value: 'roles' },
      { text: 'Логин', value: 'username' },
      { text: 'ФИО', value: 'full_name' },
      { text: 'Телефон', value: 'phone' },
      { text: 'Почта', value: 'email' },
      { text: 'Активный', value: 'is_active' },
      { text: 'Статус персонала', value: 'is_staff' },
      { text: 'Операции', value: '' },
    ]
  }),
  async mounted() {
    await this.fetchAllUsers()
    await this.fetchRoles()
  },
  methods: {
    ...mapActions([ 'fetchAllUsers', 'fetchRoles' ]),
    ...mapMutations([ 'set_users_filters', 'set_users_pagination' ]),
    editUser(user_id) {
      this.is_edit_user = true
      this.user_id = user_id
    },
    clearSearch() {
      this.search_field = ''
      this.fetchAllUsers()
    },
    clickPagination(num) {
      this.page_paginate = num
      this.fetchAllUsers()
    },
    clickSearch() {
      this.page_paginate = 1
      this.fetchAllUsers()
    },
    closeUserDetail() {
      this.is_edit_user = false
      this.user_id = null
      this.fetchAllUsers()
    },
    showUserRoles(rolesArray) {
      let result = ''
      rolesArray.forEach(role => { result += ` ${role.name} ` })
      return result
    }
  },
  computed: {
    ...mapGetters([ 'all_users', 'users_pagination', 'users_filters', 'all_roles' ]),
    pagination_count() {
      return Math.ceil(this.all_users.count / this.users_pagination.page_size) || 0
    },
    search_field: {
      get () {
        return this.users_filters.search || ''
      },
      set (value) {
        this.set_users_filters({
          ...this.users_filters,
          search: value === "" ? null : value
        })
      }
    },
    page_paginate: {
      get () {
        return this.users_pagination.page
      },
      set (value) {
        this.set_users_pagination({
          ...this.users_pagination,
          page: value
        })
      }
    },
    role_field: {
      get () {
        return this.users_filters.role
      },
      set (value) {
        this.set_users_filters({
          ...this.users_filters,
          role: value
        })
      }
    },
  },
  watch: {
    'users_filters.role'() {
      this.page_paginate = 1
      this.fetchAllUsers()
    },
  },
}
</script>

<style scoped>
.user-page__filters {
  display: flex;
  width: 100%;
  align-items:center;
  justify-content: space-between;
}
</style>
