<template>
  <Nav :navItems="navItems" v-if="navItems.length"></Nav>
  <router-view v-else></router-view>
</template>

<script>
import _ from 'lodash'
import MenuFactory from "@/factories/admin/menu"
import Nav from './Nav'
import store from "@/store";

export default {
  name: "ChildrenStartPage",
  components: {
    Nav
  },
  data() {
    return {
      items: new MenuFactory(store.getters.settings)
    }
  },
  computed: {
    currentItem() {
      return this.items.find(i => i.link === this.$route.name)
    },
    navItems() {
      return _.get(this.currentItem, 'children', [])
    }
  }
}
</script>
