import Vue from 'vue'
import ru from 'vuetify/src/locale/ru.ts'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import 'vuetify/src/stylus/app.styl'

import Vuetify, {
  VApp,
  VNavigationDrawer,
  VFooter,
  VToolbar,
  VFadeTransition,
  VSpacer,
  VToolbarTitle,
  VToolbarItems,
  VBtn,
  VBtnToggle,
  VContainer,
  VLayout,
  VFlex,
  VDivider,
  VIcon,
  VList,
  VListTileTitle,
  VListTileSubTitle,
  VListTileAvatar,
  VListTileAction,
  VListTileContent,
  VListTileActionText,
  VListTile,
  VSubheader,
  VImg,
  VAutocomplete,
  VDataTable,
  VCheckbox,
  VDatePicker,
  VMenu,
  VTextField,
  VForm,
  VTabs,
  VTabItem,
  VTab,
  VCard,
  VCardText,
  VCardTitle,
  VCardActions,
  VCardMedia,
  VTabsSlider,
  VTabsItems,
  VSwitch,
  VRadioGroup,
  VRadio,
  VDialog,
  VAvatar,
  VStepper,
  VStepperHeader,
  VStepperContent,
  VStepperItems,
  VStepperStep,
  VBadge,
  VTextarea,
  VSlideXReverseTransition,
  VSelect,
  VProgressCircular,
  VEditDialog,
  VExpansionPanel,
  VExpansionPanelContent,
  VTimePicker,
  VPagination,
  VCombobox,
  VChip,
  VRating,
  VInput,
  VItem,
  VItemGroup,
  VTooltip,
  VAlert
} from 'vuetify/lib'

const primaryAndAccentColor = (() => Vue.config.env.darkTheme
  ? '#0c77c3'
  : '#52a8b6')()

Vue.use(Vuetify, {
  theme: {
    primary: primaryAndAccentColor,
    secondary: '#dcdee1',
    accent: primaryAndAccentColor,
    error: '#f27171',
    success: '#7ac1d0',
    info: '#5585b5',
    warning: '#f2d30c'
  },
  components: {
    VApp,
    VNavigationDrawer,
    VFooter,
    VToolbar,
    VFadeTransition,
    VSpacer,
    VToolbarTitle,
    VToolbarItems,
    VBtn,
    VBtnToggle,
    VContainer,
    VLayout,
    VFlex,
    VDivider,
    VIcon,
    VList,
    VListTileTitle,
    VListTileSubTitle,
    VListTileAvatar,
    VListTileAction,
    VListTileContent,
    VListTileActionText,
    VListTile,
    VSubheader,
    VImg,
    VAutocomplete,
    VDataTable,
    VCheckbox,
    VDatePicker,
    VMenu,
    VTextField,
    VForm,
    VTabs,
    VTabItem,
    VTab,
    VCard,
    VCardText,
    VCardTitle,
    VCardActions,
    VCardMedia,
    VTabsSlider,
    VTabsItems,
    VSwitch,
    VRadioGroup,
    VRadio,
    VDialog,
    VAvatar,
    VStepper,
    VStepperHeader,
    VStepperContent,
    VStepperItems,
    VStepperStep,
    VBadge,
    VTextarea,
    VSlideXReverseTransition,
    VSelect,
    VProgressCircular,
    VEditDialog,
    VExpansionPanel,
    VExpansionPanelContent,
    VTimePicker,
    VPagination,
    VCombobox,
    VChip,
    VRating,
    VInput,
    VItem,
    VItemGroup,
    VTooltip,
    VAlert
  },
  iconfont: 'md',
  lang: {
    current: 'ru',
    locales: { ru },
    t: undefined
  }
})
