<template>
  <v-container fluid class="fs-moduleadmin-container">
    <div class="d-flex mb-2">
      <div style="display: flex">
        <h1>Наборы доп. блюд</h1>
        <context-help-modal :context-key="contextKeys.supplier.additionalFood" />
      </div>
      <v-spacer style="width: 40%"></v-spacer>
      <v-btn
        flat
        @click="() => showFoodModal()"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Создать набор</v-btn>
    </div>

    <v-layout
      class="fs-teacher-order-result"
      mb-3
      justify-space-around
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :pagination.sync="pagination"
          :items="items"
          hide-actions
          no-data-text="Нет данных"
          class="elevation-1 fs-teacher-table"
          item-key="id"
        >
          <template v-slot:items="props">
            <tr>
              <td class="cursor-pointer" @click="showFoodModal(props.item)">{{ props.item.id }}</td>
              <td class="cursor-pointer" @click="showFoodModal(props.item)">{{ props.item.created_at | transformDateTime }}</td>
              <td class="cursor-pointer" @click="showFoodModal(props.item)">{{ props.item.is_active ? 'Да' : '' }}</td>
              <td class="cursor-pointer" @click="showFoodModal(props.item)">
                <ul>
                  <li v-for="(f, index) in props.item.food_set_maps" :key="index">
                    {{ f.food.name }}: {{ f.schools.length }} шк., {{ f.price / 100 }} руб.
                  </li>
                </ul>
              </td>
              <td>
                <v-flex row>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="OPdeleteAdditionalFoodSet(props.item.is_active, props.item.id)"
                      >delete</v-icon>
                    </template>
                    <span>Удалить</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>


    <ConfirmModal
      cancelBtnText="Закрыть"
      :dialog.sync="modal.show"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="confirmFoodModal"
      :width="1200"
      style="z-index: 9999;"
      :disable-confirm-button="confirmFoodDisabled"
      :closePersistent="true"
      :hideConfirmButton="true"
    >

      <template v-slot:body>
        <ul class="additional-food__list">
          <li v-for="(item, index) in modal.foods" :key="index">
            <v-select
              no-data-text="Ничего не выбрано"
              v-model="item.foodId"
              item-text="name"
              item-value="id"
              :items="foods"
              label="Блюдо:"
              deletable-chips
              chips
            >
            </v-select>
            <v-select
              :disabled="!item.foodId"
              no-data-text="Ничего не выбрано"
              v-model="item.schoolIds"
              item-text="name"
              item-value="id"
              :items="schools"
              label="Школы:"
              deletable-chips
              chips
              multiple
            >
              <template v-slot:prepend-item>
                <v-list-tile
                  ripple
                  @click="selectAllSchools(item)"
                >
                  <v-list-tile-action>
                    <v-icon :color="item.schoolIds.length > 0 ? 'primary' : ''">{{ item.schoolIds.length ===  schools.length ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Выбрать всё</v-list-tile-title>
                    <v-list-tile-sub-title>Выбрано: {{ item.schoolIds.length }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
            <v-layout style="align-items: center">
              <v-flex md-12>
                <v-text-field
                  :class="{ 'fs-input': !darkTheme }"
                  :value="item.price / 100"
                  @input="changePrice(item, $event)"

                  :label="!darkTheme ? 'Стоимость:' : ''"
                  :placeholder="darkTheme ? 'Стоимость:' : ''"
                  :outline="darkTheme"
                />
              </v-flex>

            </v-layout>
              <div style="text-align: right;">
                <v-btn color="error" small @click="deleteFood(index)">
                  Удалить
                </v-btn>
                <v-btn color="primary" small @click="createOrUpdateAdditionalFood(index)">
                  {{ modal.foods[index].id ? 'Редактировать' : 'Создать' }}
                </v-btn>
              </div>
          </li>

        </ul>
        <div style="text-align: center;"><v-btn flat @click="addFood">Добавить блюдо</v-btn></div>
      </template>
    </ConfirmModal>

  </v-container>
</template>

<script>
import {
  getAdditionalDishes, getDishes, getSchools,
  createAdditionalFood,
  deleteAdditionalDishes, updateAdditionalDishes, createAdditionalDishes, deleteAdditionalFoodSet
} from '@/router/routes/organizer'
import { mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import '@/filters/index.js'
import scrollable from "@/mixins/scrollable"
import { ConfirmModal } from '@/components/common'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'OrganizerAdditionalDishes',
  mixins: [ scrollable ],
  components: {
    ContextHelpModal,
    ConfirmModal
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      darkTheme: Vue.config.env.darkTheme,
      pagination: {
        rowsPerPage: -1
      },
      foodId: null,
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Дата', value: 'created_at', sortable: false },
        { text: 'Активный', value: 'is_active', sortable: false },
        { text: 'Блюда', value: 'foods', sortable: false },
        { text: 'Операции', value: 'operations', sortable: false }
      ],
      items: [],
      limit: Vue.config.general.limit,
      foods: [],
      schools: [],
      modal: {
        show: false,
        foods: [],
      }
    }
  },
  created() {
    this.loadMore()
    this.loadDishes()
    this.loadSchools()
    window.addEventListener('scroll', this.scrollHandler)
  },
  methods: {
    ...mapMutations( [ 'setShowPreloader' ] ),
    async OPdeleteAdditionalFoodSet(is_active, id) {
      if (is_active) {
        // notify.error('Невозможно удалить активный набор') //pass
        notify.info('Невозможно удалить активный набор. Создайте новый (пустой) набор, чтобы удалить этот. Наличие набора, даже пустого, обязательно') //pass
        return
      }
      const payload = {
        id: id
      }
      try {
        await deleteAdditionalFoodSet(payload)
        notify.success('Доп. набор успешно удален')
        this.items = []
        this.loadMore()
      } catch (e) {
        // notify.error('Не удалось удалить доп. набор') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    addFood() {
      this.modal.foods.push({
        foodId: null,
        schoolIds: [],
        price: 0,
      })
    },
    selectAllSchools(item) {
      item.schoolIds = this.schools.map(s => s.id)
    },
    changePrice(item, price) {
      const value = parseFloat(price)
      if (isNaN(value)) {
        return
      }
      item.price = parseInt(value * 100)
    },
    showFoodModal(item) {
      this.modal.show = true
      if (item) {
        this.modal.foods = item.food_set_maps.map(f => ({ id: f.id, foodId: f.food, schoolIds: f.schools.map(s => s), price: f.price }))
        this.foodId = item.id
      }

      else {
        createAdditionalFood({ food_set_maps: [] })
          .then(res => {
            this.foodId = res.data.data.id
          })
          .finally(() => {
            store.commit('setShowPreloader', false)
          })
      }
    },
    deleteFood(index) {
      const additionalFood = this.modal.foods[index]

      additionalFood.id
        ? deleteAdditionalDishes(additionalFood.id)
          .then(() => {
            this.modal.foods.splice(index, 1)
          }).finally(() => {
            store.commit('setShowPreloader', false)
            this.items = []
          })
        : this.modal.foods.splice(index, 1)
    },
    createOrUpdateAdditionalFood(index) {

      const additionalFood = this.modal.foods[index]
      additionalFood.id
        ? updateAdditionalDishes({ food_set: this.foodId, schools: additionalFood.schoolIds, price: additionalFood.price, food: additionalFood.foodId, id: additionalFood.id })
          .then(() => {}).finally(() => {
            store.commit('setShowPreloader', false)
          })
        : createAdditionalDishes({ food_set: this.foodId, schools: additionalFood.schoolIds, price: additionalFood.price, food: additionalFood.foodId })
          .then((res) => {
            this.$set(this.modal.foods[index], 'id', res.data.id)
          })
          .finally(() => {
            store.commit('setShowPreloader', false)
          })

    },
    async confirmFoodModal(value) {
      if (!value) {
        this.modal.show = false
        this.modal.foods = []
        this.items = []
        this.offset = 0
        this.foodId = null
        this.loadMore()
        return
      }
    },
    async loadMore() {
      try {
        const response = await getAdditionalDishes({
          params: {
            limit: this.limit,
            offset: this.offset
          }
        })
        this.items = [ ...this.items, ...response.data.data ]
      } catch (error) {
        // notify.error('Не удалось загрузить наборы доп. блюд') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async loadDishes() {
      getDishes()
        .then(response => {
          this.foods = response.data.data
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    async loadSchools() {
      getSchools({
        limit: 1000
      }).then(response => {
        const {
          data: { data: items }
        } = response
        this.schools = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
      })
        .finally(() => store.commit('setShowPreloader', false))
    }
  },
  computed: {
    confirmFoodDisabled() {
      for (const f of this.foodSetMaps) {
        if (!f.food) {
          return true
        }
        if (!f.schools.length) {
          return true
        }
        if (f.price <= 0) {
          return true
        }
      }
      return false
    },
    foodSetMaps() {
      return this.modal.foods.map(f => {
        return {
          food: f.foodId,
          schools: f.schoolIds,
          price: f.price
        }
      })
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>

<style>
.additional-food__list,
.additional-food__list li {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: block;
}

.additional-food__list li {
  padding: 10px;
  border-radius: 3px;
  margin: 30px 0px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}
.additional-food__list li:first-child {
  margin-top: 0px;
}
</style>
