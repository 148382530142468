<template>
  <v-dialog v-model="show" :max-width="width" persistent>
    <v-container :class="className">
      <v-layout class="fs-archive" row mb-3>
        <v-flex mb-3>
          <h1>{{title}}</h1>
        </v-flex>
      </v-layout>
      <v-layout justify-space-around mb-3>
        <v-flex>
          <v-card>
            <v-card-text>
              <v-layout mb-3 mt-3>
                <v-flex>
                  <span class="fs-student-info__key">Льгота:</span>
                </v-flex>
                <v-flex text-md-right>
                  <span class="fs-student-info__key">{{ concession }}</span>
                </v-flex>
              </v-layout>
              <v-layout mb-3>
                <v-flex>
                  <span class="fs-student-info__key">Размер социальной выплаты:</span>
                </v-flex>
                <v-flex text-md-right>
                  <span class="fs-student-info__key">{{ concessionPrice }} руб./день</span>
                </v-flex>
              </v-layout>
              <v-layout v-if="currentChild.schoolClass.eat && currentChild.schoolClass.eat.length" mb-3>
                <v-flex>
                  <span class="fs-student-info__key">Время питания:</span>
                </v-flex>
                <v-flex text-md-right>
                  <span class="fs-student-info__key">{{ foodTime }}</span>
                </v-flex>
              </v-layout>
              <v-layout v-show="false" mb-3>
                <v-flex>
                  <span class="fs-student-info__key">Срок действия:</span>
                </v-flex>
                <v-flex text-md-right>
                  <span class="fs-student-info__key">С 13.01.2019 по 21.01.2020</span>
                </v-flex>
              </v-layout>

              <v-layout v-show="false" justify-space-around>
                <v-flex md6>
                  <v-data-table :headers="feedTimeHeaders" :items="feedTimeItems" hide-actions>
                    <template v-slot:headers="props">
                      <tr v-for="header in props.headers" :key="header.text">
                        <th colspan="2">
                          <strong>{{ header.text }}</strong>
                        </th>
                      </tr>
                    </template>
                    <template v-slot:items="props">
                      <tr>
                        <td>{{props.item.feedType}}</td>
                        <td>{{props.item.feedTime}}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout justify-space-around>
        <v-flex text-md-right>
          <v-btn
            flat
            class="fs-btn fs-parent-btn fs-btn--secondary fs-form-footer__btn"
            @click="show = false"
          >Закрыть</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import numable from "@/mixins/balancable"

export default {
  name: "concessionsIndex",
  mixins: [ numable ],
  data() {
    return {
      title: "Время питания и льготы",
      feedTimeHeaders: [ { text: "Время питания (первая смена)" } ],
      feedTimeItems: [
        {
          id: 1,
          feedType: "Завтрак",
          feedTime: "После первого урока"
        },
        {
          id: 2,
          feedType: "Обед",
          feedTime: "После пятого урока"
        },
        {
          id: 3,
          feedType: "Полдник",
          feedTime: "После седьмого урока"
        }
      ],
      foodTimes: [
        'Первая перемена',
        'Вторая перемена',
        'Третья перемена',
        'Четвертая перемена',
        'Пятая перемена',
        'Шестая перемена',
        'Седьмая перемена',
        'Восьмая перемена'
      ],
    }
  },
  computed: {
    ...mapGetters([ "currentChild" ]),
    concession() {
      return _.get(this.currentChild, "privilege.name", "отсутствует")
    },
    concessionPrice() {
      return this.convertToRubles(
        _.get(this.currentChild, "privilege.price", 0)
      )
    },
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },
    foodTime() {
      let foodTime = ''
      this.currentChild.schoolClass.eat.forEach(v => {
        if (foodTime) foodTime += ', '
        foodTime += this.foodTimes[v.foodTime - 1]
      })
      return foodTime
    }
  },
  props: {
    value: {
      type: Boolean
    },
    width: {
      default: 500
    },
    className: {
      default: "fs-container"
    }
  }
}
</script>
