<script>
import rules from '@/helpers/rules'
import { sendTicketAnswer } from '@/router/routes/superadmin'
import store from '@/store'

export default {
  name: 'AnswerModal',

  data() {
    return {
      answerText: '',
      isValid: false,
      rules: [
        (item) => (value) =>
          (!!value && value.trim()?.length > 0) ||
          `${item.label}Обязательное поле!`,
        (item) => (value) =>
          (value && value.length <= item.length) ||
          `Поле Ввода должно содержать не более ${item.item} символов`
      ],
      fieldItem: {
        label: 'Текст Ответа',
        length: 5000,
        placeholder: 'Введите текст ответа'
      }
    }
  },
  props: {
    showModal: {
      type: Boolean
    },
    item: {
      type: Object
    }
  },
  methods: {
    close() {
      this.$emit('closeModal')
      this.clear()
    },
    async sendAnswer() {
      const payload = {
        id: this.item.id,
        data: { answer: this.answerText }
      }
      try {
        await sendTicketAnswer(payload)
        await this.$emit('send')
        this.close()
      } catch (e) {
        console.error(e)
      } finally {
        store.commit('setShowPreloader', false)
      }
      this.close()
    },
    clear() {
      this.$refs.answerForm.reset()
      this.$refs.answerForm.resetValidation()
    }
  }
}
</script>

<template>
  <v-dialog v-model="showModal" :persistent="true" :width="800">
    <v-card>
      <v-card-title>
        <h3>Ответ на запрос пользователя</h3>
      </v-card-title>
      <v-card-text>
        <div>
          <span><strong>Запрос: </strong></span>
          <p style="word-wrap: break-word; white-space: pre-line">
            {{ item?.question }}
          </p>
        </div>
      </v-card-text>
      <v-card-text>
        <v-form v-model="isValid" ref="answerForm">
          <v-textarea
            v-model="answerText"
            solo
            color="deep-purple"
            :label="fieldItem.label"
            :placeholder="fieldItem.placeholder"
            rows="5"
            auto-grow
            :maxlength="fieldItem.length"
            required
            :counter="fieldItem.length"
            :rules="rules.map((rule) => rule(fieldItem))"
          ></v-textarea>
        </v-form>
      </v-card-text>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn color="error" @click="close"> Отмена </v-btn>
        <v-btn color="success" @click="sendAnswer" :disabled="!isValid">
          Отправить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped></style>
