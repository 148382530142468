<template>
  <v-form ref="buffetBalanceForm" v-model="valid">
    <v-card>
      <v-toolbar elevation="1" dense light>
        <v-toolbar-title>Создать транзакцию</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.prevent="$emit('close')">
          <v-icon class="fs-active-icon">close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-select
          :items="amount_types_m"
          item-text="name"
          item-value="value"
          return-object
          v-model="transaction.amount_type"
          :label="!darkTheme ? 'Сумма' : ''"
          :placeholder="darkTheme ? 'Сумма' : ''"
          :outline="darkTheme"
        />

        <v-text-field
          type="number"
          min="0"
          clearable
          v-model="transaction.amount"
          :rules="[rules.required]"
          :label="!darkTheme ? 'Сумма' : ''"
          :placeholder="darkTheme ? 'Сумма' : ''"
          :outline="darkTheme"
        />

      </v-card-text>
      <v-card-actions>
        <v-btn @click="createTransaction">
          <v-icon class="fs-active-icon">check</v-icon>
          Создать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import Vue from 'vue'
import store from "@/store"
export default {
  name: 'BuffetBalanceModal',
  props: [ 'pupil' ],
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      transaction: {
        amount: null,
        amount_type: { name: 'Зачисление', value: 1 },
        status: 4,
      },
      amount_types_m: [
        { name: 'Зачисление', value: 1 },
        { name: 'Списание', value: 2 },
      ],
      rules: {
        required: (v) => !!v || "Поле обязательное для заполнения"
      },
      valid: true,
    }
  },
  methods: {
    createTransaction() {
      this.$refs.buffetBalanceForm.validate()
      if (this.valid) {
        this.$emit('create', {
          ...this.transaction,
          amount_type: this.transaction.amount_type.value,
          pupil: this.pupil
        })
      } else {
        console.log('reror')
      }
    }
  }
}
</script>
