<template>
  <v-container fluid>
    <v-expansion-panel class="mb-2" :disabled="reportTypeSelected" v-model="selectedPanel">
      <v-expansion-panel-content>
        <template v-slot:header>
          <v-layout column>
            <v-flex>
              <span class="title">Отчёт по балансу</span>
            </v-flex>
            <v-flex>
              <span class="caption">Активные организации</span>
            </v-flex>
          </v-layout>
        </template>
        <v-card>
          <v-card-text>
            <h2>1. Выберите школу</h2>
            <v-select
              :items="schoolListAvailable"
              item-text="name"
              item-value="id"
              label="Список школ"
              @change="getAvailableDates($event, 'assign')"
            >
            </v-select>
            <div v-if="showDatePicker" class="mt-3 mb-2">
              <h2>2. Укажите дату</h2>
              <v-date-picker
                v-model="pickedDate"
                :min="minDate"
                :max="maxDate"
                :show-current="false"
                locale="ru"
              >
              </v-date-picker>
              <div class="mt-2">
                <v-btn class="primary" v-if="pickedDate" @click="getReport">Загрузить</v-btn>
              </div>
            </div>
            <div class="right">
              <v-btn class="primary" @click="resetComponentState">Отмена</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
      <v-expansion-panel-content>
        <template v-slot:header>
          <v-layout column>
            <v-flex>
              <span class="title">Отчёт по балансу (архив)</span>
            </v-flex>
            <v-flex>
              <span class="caption">Отключённые организации</span>
            </v-flex>
          </v-layout>
        </template>
        <v-card>
          <v-card-text>
            <h2>1. Выберите школу</h2>
            <v-select
              :items="schoolList"
              item-text="name"
              item-value="id"
              label="Список школ"
              @change="getAvailableDates"
            >
            </v-select>
            <div v-if="showDatePicker" class="mt-3 mb-2">
              <h2>2. Укажите дату</h2>
              <v-date-picker
                v-model="pickedDate"
                :min="minDate"
                :max="maxDate"
                :show-current="false"
                locale="ru"
              >
              </v-date-picker>
              <v-btn v-if="pickedDate" @click="getReport">Загрузить</v-btn>
            </div>
            <div class="right">
              <v-btn class="primary" @click="resetComponentState">Отмена</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import { getOrganizerInfo } from "@/router/routes/organizer"
import store from "@/store"
import notify from '@/utils/notifications/toastNotification'

export default {
  data() {
    return {
      title: 'Отчёты (Архив)',
      schoolList: [],
      schoolListAvailable: [],
      user: null,
      pickedDate: '',
      pickedSchool: null,
      minDate: null,
      maxDate: null,
      showDatePicker: false,
      reportTypeSelected: false,
      selectedPanel: null
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async init() {
      await this.getUserData()

      await this.getAvailableSchools(this.schoolList)
      await this.getAvailableSchools(this.schoolListAvailable, 'assign')
    },
    async getUserData() {
      const user = await getOrganizerInfo()
      this.user = user.data.data
    },
    async getAvailableSchools(data_item, assign = 'refuse') {
      const res = await axios.get(`${process.env.VUE_APP_ARCHIVE_URL}/school/supplier/?supplier_id=${this.user.id}&${assign}`)
      res.data.forEach(item => {
        if (!data_item.includes(item.school_id)) {
          const obj = {
            id: item.school_id,
            name: assign === 'refuse' ? `${item.school_name} (отключена ${item.created_at}, "Отключение организатора от школы сотрудником департамента")` : item.school_name,
          }
          data_item.push(obj)
        }
      })
      store.commit('setShowPreloader', false)
    },
    async getAvailableDates(item, assign = false) {
      this.reportTypeSelected = true
      this.pickedSchool = item

      try {
        const res = await axios.get(`${process.env.VUE_APP_ARCHIVE_URL}/school/supplier/dates/?supplier_id=${this.user.id}&school_id=${item}&${assign}`)
        this.minDate = res.data.from
        this.maxDate = res.data.to
        this.showDatePicker = true
      } catch (e) {
        // notify.error('Ошибка загрузки доступных дат для указанной школы') //pass!
      }
      store.commit('setShowPreloader', false)
    },
    async getReport() {
      try {
        const res = await axios.get(`${process.env.VUE_APP_ARCHIVE_URL}/school/?date=${this.pickedDate}&school_id=${this.pickedSchool}&xlsx`)
        window.open(res.data.url, '_blank')
      } catch (e) {
      } finally {
	      store.commit('setShowPreloader', false)
      }
    },
    resetComponentState() {
      this.pickedDate = null
      this.minDate = null
      this.maxDate = null
      this.pickedSchool = null
      this.reportTypeSelected = false
      this.test = null
      this.showDatePicker = false
      this.selectedPanel = null
    }
  }
}
</script>

<style scoped>
h3 {
  margin-bottom: -10px;
}
.small-text {
  font-size: 10px;
}
</style>
