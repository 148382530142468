<template>
  <v-container class="fs-organizer-container">
    <v-form ref="storehouseForm" v-model="valid">
      <v-layout md8 offset-md2 mb-5 :key="keyUpdate">
        <v-flex>
          <v-flex mb-5>
            <h1>{{!this.is_create ? `Редактировать склад`: `Создать склад`}}</h1>
          </v-flex>
          <div>

            <v-text-field
              v-model="name"
              :rules="[rules.required]"

              :label="!darkTheme ? 'Наименование склада' : ''"
              :placeholder="darkTheme ? 'Наименование склада' : ''"
              :outline="darkTheme"
            />
            <v-text-field
              v-model="currentUser.school.short_name"
              readonly
              :outline="darkTheme"
            />

            <StorehouseProducts v-if="!this.is_create" :storehouse_id="storehouse_card.id"/>
          </div>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex text-md-right>
          <v-btn
            flat
            class="fs-btn v-btn--active"
            @click="handleSaveBtn()"
          >Cохранить</v-btn>
          <v-btn
            flat
            class="fs-btn v-btn--active"
            @click="$router.go(-1)"
          >Назад</v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DateTimePicker from '@/components/common/DatePickersSmart/DateTimePicker'
import StorehouseProducts from '@/components/admin/Storehouse/StorehouseProducts'
import store from "@/store"

export default {
  name: 'StorehouseDetail',
  data: () => ({
    darkTheme: Vue.config.env.darkTheme,
    datepickerMenu: false,
    is_create: false,
    keyUpdate: 0,
    valid: true,
    rules: {
      required: (v) => !!v || "Поле обязательное для заполнения"
    },
  }),
  components: { DateTimePicker, StorehouseProducts },
  computed: {
    ...mapGetters([ 'storehouse_card', 'currentUser' ]),
    card_schools: {
      get () {
        return this.storehouse_card.school
      },
      set (value) {
        this.set_storehouse_card({ ...this.storehouse_card, school: value })
      }
    },
    name: {
      get () {
        return this.storehouse_card.name
      },
      set (value) {
        this.set_storehouse_card({ ...this.storehouse_card, name: value })
      }
    },
  },
  async mounted() {
    this.$route.params.id ? this.is_create = false : this.is_create = true
    if (!this.is_create) await this.fetchStorehousesCard({ card_id: this.$route.params.id, params: {} })
    this.card_schools = this.currentUser.school.id
    this.keyUpdate++
  },
  destroyed() {
    this.set_storehouse_card({})
  },
  methods: {
    ...mapMutations([ 'set_storehouse_card' ]),
    ...mapActions([ 'fetchStorehousesCard', 'updateStorehouseCard', 'createStorehouseCard' ]),
    async update() {
      const card = {
        ...this.storehouse_card,
        school: this.card_schools
      }
      await this.updateStorehouseCard({ card_id: this.$route.params.id, data: card })
        .then(() => {
          this.$router.push({ name: 'Storehouse' })
        })
    },
    async create() {
      await this.createStorehouseCard({ data: {
        ...this.storehouse_card,
        school: this.card_schools
      } })
        .then(() => {
          this.$router.push({ name: 'Storehouse' })
        })
    },
    handleSaveBtn() {
      this.$refs.storehouseForm.validate()
      if (this.valid) {
        if (this.is_create) this.create()
        else this.update()
      }
    }
  }
}
</script>

<style scoped>

</style>
