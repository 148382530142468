<template>
  <v-container fluid>
    <div class="mb-3 d-flex">
      <div style="display: flex">
        <h1>Шаблоны меню &mdash; {{ weekDayStr }} ({{ week }} неделя)</h1>
        <context-help-modal :context-key="contextKeys.supplier.template.one" />
      </div>
      <v-spacer style="width: 30%"></v-spacer>
      <v-btn
        @click="handleCreateTemplateMenu"
        flat
        class="primary"
      >Создать шаблон меню</v-btn>
    </div>
    <v-layout
      mb-3
      justify-space-around
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="infoTemplateByDay.data"
          hide-actions
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.title === '' ? 'Не указано' : props.item.title }}</td>
              <td>{{ getPrettyDateTime(props.item.created_at) }}</td>
              <td>{{ getPrettyDateTime(props.item.updated_at) }}</td>
              <td>{{ props.item.school.length }}</td>
              <td>
                <span v-if="props.item.active" class="green--text">Активен</span>
                <span v-else class="red--text">Неактивен</span>
              </td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="handleEditMenuTemplate(props.item)">
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Редактировать</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="resetMenuTemplate(props.item)">
                      <v-icon small>restart_alt</v-icon>
                    </v-btn>
                  </template>
                  <span>Сбросить</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
      <v-dialog v-model="isModalActive" v-if="isModalActive" persistent fullscreen>
        <DayTemplateMenuForm
          :entityId="menuTemplateId"
          :weekDay="weekDayNum"
          @close="handleCloseModal"
        />
      </v-dialog>
    </v-layout>
    <div class="d-flex">
      <v-pagination
        v-if="infoTemplateByDay && infoTemplateByDay.pagination"
        :value="paginate.page"
        @input="changePaginatePage"
        :length="infoTemplateByDay.pagination.total"
      ></v-pagination>
      <div style="width:200px;text-align:right;">
        <v-btn
          flat
          class="fs-btn fs-btn--secondary v-btn--active"
          @click="$router.go(-1)"
        >Назад</v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapMutations } from 'vuex'

import {
  getSupplierMenuTemplatesByOneDay,
  clearTemplateComplexes
} from '@/router/routes/organizer'

import DayTemplateMenuForm from '@/components/organizer/Menu/DayTemplateMenuForm'
import moment from "moment/moment"
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'DayMenuTemplates',
  components: { ContextHelpModal, DayTemplateMenuForm },
  data: () => ({
    contextKeys: Vue.config.contextHelp,
    headers: [
      { text: 'Наименование', value: 'title', sortable: false },
      { text: 'Дата создания', value: 'created_at', sortable: false },
      { text: 'Дата обновления', value: 'updated_at', sortable: false },
      { text: 'Кол-во привязанных школ', value: 'school_count', sortable: false },
      { text: 'Статус', value: 'active', sortable: false },
      { text: 'Действия', value: '', sortable: false },
    ],
    infoTemplateByDay: [],
    week: null,
    weekDayNum: 0,
    weekDayStr: '',
    paginate: {
      page: 1,
      page_size: 10
    },
    isModalActive: false,
    menuTemplateId: null
  }),
  mounted() {
    const { week, weekDayNum, weekDayStr } = this.$route.params

    this.week = week
    this.weekDayNum = weekDayNum
    this.weekDayStr = weekDayStr

    this.getSupplierMenuTemplatesByOneDay(this.weekDayNum)
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    getPrettyDateTime(value) {
      return moment(value).format(Vue.config.format.prettyDateWithoutSeconds)
    },
    changePaginatePage(page) {
      this.paginate.page = page
      this.getSupplierMenuTemplatesByOneDay(this.weekDayNum)
    },
    getSupplierMenuTemplatesByOneDay(day) {
      getSupplierMenuTemplatesByOneDay({ params: { ...this.paginate, day: day } })
        .then(res => {
          this.infoTemplateByDay = res.data
        })
        .catch(() => {
          notify.error(
            (() => {
              const text = 'Ошибка загрузки шаблонов'
              if (!this.weekDayStr) return text
              return `${text} организатора за ${this.weekDayStr}`
            })()
          ) //pass
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    },
    handleEditMenuTemplate(item) {
      this.menuTemplateId = item.id
      this.isModalActive = true
    },
    handleCreateTemplateMenu() {
      this.isModalActive = true
    },
    handleCloseModal(willUpdateParent=false) {
      this.isModalActive = false
      this.menuTemplateId = null
      if (willUpdateParent) this.getSupplierMenuTemplatesByOneDay(this.weekDayNum)
    },
    resetMenuTemplate(item) {
      clearTemplateComplexes({
        id: item.id
      })
        .then(res => {
          if (res.data.success) {
            notify.success('Комплексы сброшены')
          }
        })
        .finally(() => this.setShowPreloader(false))
    }
  }
}
</script>

<style>
.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
