<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <v-layout
      class="fs-complex"
      row
      mb-6
    >
      <v-flex
        md12
        mb-3
      >
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout
      class="fs-teacher-order-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1 fs-admin-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.price }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex
        text-md-right
        pt-3
      >
        <v-btn
          flat
          class="fs-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active"
          @click="$router.go(-1)"
        >Назад</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getConcessions } from '@/router/routes/admin'
import { mapGetters } from 'vuex'
import store from '@/store'
import _ from 'lodash'
import numable from '@/mixins/balancable'

export default {
  name: 'StudentIndex',
  mixins: [ numable ],
  created() {
    this.getConcessions()
  },
  data() {
    return {
      title: 'Справочник «Социальные выплаты»',
      headers: [
        { text: '№ п/п', value: 'id' },
        { text: 'Льгота', value: 'name' },
        { text: 'Размер социальной выплаты, руб.', value: 'price' }
      ],
      items: [],
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10)
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    getConcessions() {
      const payload = {
        id: _.get(this.currentUser, 'school_id', null)
      }
      getConcessions(payload)
        .then(response => {
          this.items = _.map(response.data.data, item => {
            return {
              id: item.id,
              name: item.name,
              price: this.convertToRubles(item.price)
            }
          })
        })
        .finally(() => store.commit('setShowPreloader', false))
    }
  }
}
</script>
