<template>
  <v-container fluid class="fs-teacher-container">
    <div class="page-head__common">
      <h1>Управление сертификатами</h1>
      <v-btn
        flat
        @click="handleClickCreateDocument"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Прикрепить сертификат</v-btn>
    </div>

    <v-layout class="fs-teacher-order-result" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="all_certificate.results"
          hide-actions
          no-data-text="Нет сертификатов"
          class="elevation-1 fs-admin-table"
          item-key="name"
          style="cursor: pointer;"
        >
          <template v-slot:items="props">
            <tr>
              <td>
                <img class="qr_code" :src="server + props.item.qr_code" alt="qr_code">
              </td>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.title }}</td>
              <td>{{ props.item.description }}</td>
              <td>{{ props.item.is_published ? 'Да' : 'Нет' }}</td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      class="fs-active-icon"
                      @click="editDocument(props.item.id)"
                    >edit</v-icon>
                  </template>
                  <span>Редактирование</span>
                </v-tooltip>
                <a :href="server + props.item.file">
                  <v-icon
                    class="fs-active-icon"
                  >get_app</v-icon>
                </a>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      class="fs-active-icon"
                      @click="handleDelete(props.item.id)"
                    >delete</v-icon>
                  </template>
                  <span>Удалить</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog v-model="is_show_modal" persistent width="80%">
      <NpaDetail
        v-if="is_show_modal"
        @close="closeModal"
        :is_create="is_create"
        :document_id="document_id"
        :is_document="false"
      />
    </v-dialog>

    <div class="text-xs-center" v-if="pagination_count > 1">
      <v-pagination
        @input="handlePaginationClick"
        :value="documents_pagination.page"
        :length="pagination_count"
        :total-visible="7"
      ></v-pagination>
    </div>

    <ConfirmModal
      :dialog.sync="confirmDeleteModal"
      @confirm="handlerConfirmModal"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
    >
      <template v-slot:headline>Вы действительно хотите удалить сертификат?</template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import NpaDetail from './NpaDetail.vue'
import ConfirmModal from '@/components/common/Modals/ConfirmModal'
import Vue from 'vue'

export default {
  name: "NpaCertificate",
  components: { NpaDetail, ConfirmModal },
  data: () => ({
    confirmDeleteModal: false,
    file_id: null,
    headers: [
      { text: 'QR-код', value: 'qr_code' },
      { text: 'ID', value: 'id' },
      { text: 'Наименование', value: 'title' },
      { text: 'Описание', value: 'description' },
      { text: 'Опубликовано', value: 'is_published' },
      { text: 'Действия', value: 'actions' }
    ],
    is_show_modal: false,
    is_create: false,
    document_id: null,
    server: Vue.config.server
  }),
  methods: {
    ...mapActions([ 'getAllCertificate', 'deleteDocument' ]),
    ...mapMutations([ 'set_document_pagination', 'clear_attachment_detail' ]),
    handlePaginationClick(page) {
      this.set_document_pagination({ page: page })
      this.getAllCertificate()
    },
    handlerConfirmModal(value) {
      if (value) {
        this.deleteDocument(this.file_id).then(() => {
          this.getAllCertificate()
        })
      } else {
        this.confirmDeleteModal = false
      }
      this.file_id = null
    },
    handleDelete(id) {
      this.file_id = id
      this.confirmDeleteModal = true
    },
    handleClickCreateDocument() {
      this.is_create = true
      this.is_show_modal = true
    },
    closeModal() {
      this.document_id = null
      this.is_create = false
      this.is_show_modal = false
      this.getAllCertificate()
    },
    editDocument(id) {
      this.is_create = false
      this.is_show_modal = true
      this.document_id = id
    }
  },
  computed: {
    ...mapGetters([ 'all_certificate', 'documents_pagination' ]),
    pagination_count() {
      return Math.ceil(this.all_certificate.count / this.documents_pagination.page_size) || 0
    },
  },
  mounted() {
    this.getAllCertificate()
  }
}
</script>

<style>
.qr_code {
  width: 150px;
  height: 150px;
}
</style>
