<template>
  <v-container fluid class="fs-organizer-container">
    <v-layout class="fs-complex-choose" row mb-3>
      <v-flex>
        <h1>{{ title }}</h1>
      </v-flex>
    </v-layout>
    <v-layout class="fs-complex-choose fs-complex-choose--description" row mb-3>
      <v-flex mb-3>
        <h3>{{ description }}</h3>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex md8 offset-md2>
        <fs-autocomplete
          outline
          height="30"
          persistent-hint
          placeholder="Начните вводить название школы или выберите из списка"
        >
          <template v-slot:append-outer>
            <v-icon :color="'info'" v-text="'search'"></v-icon>
          </template>
        </fs-autocomplete>
      </v-flex>
    </v-layout>
    <v-layout mb-3>
      <v-flex md8 offset-md2>
        <v-data-table
          :headers="complexHeaders"
          :items="complexItems"
          hide-actions
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.name }}</td>
              <td>
                <v-btn flat class="fs-btn fs-btn--secondary">выбрать</v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex md8 offset-md2 text-md-right>
        <v-btn flat class="fs-btn fs-btn--secondary">отмена</v-btn>
        <v-btn flat class="fs-btn fs-btn--primary fs-form-footer__btn">сохранить</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "ComplexChoose",
  data() {
    return {
      title: "Выбрать комплекс на 1 день периода",
      description: "Для типа питания: трехразовое ГПД",
      complexHeaders: [
        { text: "Наименование комплекса", value: "complex" },
        { text: "Операции", value: "operations" }
      ],
      complexItems: [
        {
          id: 1,
          name: "Комплекс 1"
        },
        {
          id: 2,
          name: "Комплекс 2"
        },
        {
          id: 3,
          name: "Комплекс 3"
        },
        {
          id: 4,
          name: "Комплекс 4"
        },
        {
          id: 5,
          name: "Комплекс 5"
        }
      ]
    }
  }
}
</script>
