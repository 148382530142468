<template>
  <v-container fluid class="fs-parent-container">
    <div style="display: flex; justify-content: space-between;">
      <h2>Список запрещённых продуктов ученика {{blacklist_detail && blacklist_detail.pupil ? blacklist_detail.pupil.full_name : ''}}</h2>
      <div>
        <v-btn
            flat
            @click="$router.go(-1)"
            
          >
          Назад
          </v-btn>
        <v-btn
          flat
          @click="saveBlacklist"
          class="fs-btn fs-btn--primary fs-btn__add-dish"
        >
        {{is_create ? 'Сохранить' : 'Обновить'}}
        </v-btn>
      </div>
    </div>
    


    <div style="margin-top: 30px; display: flex; justify-content: center;">
      <v-card tile style="width: 48%; margin: 0px 15px;">
        <v-list subheader two-line style="padding: 10px; height: 500px; overflow: auto;" >
          
          <div style="display: flex; justify-content: space-between;">
            <v-subheader>Запрещённый список для ученика</v-subheader>
            <v-btn v-show="choices_blacklist_products.length > 0" @click="removeProductFromBlackList">Переместить</v-btn>
          </div>

          <v-list-tile v-for="product in blacklist_detail.products" :key="product.id">
            <v-list-tile-action>
              <v-checkbox :value="product.id" v-model="choices_blacklist_products"></v-checkbox>
            </v-list-tile-action>

            <v-list-tile-content >
              <v-list-tile-title>{{product.name}}</v-list-tile-title>
              <v-list-tile-sub-title>Цена: {{product.price}}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card> 
      
      <v-card tile style="width: 48%; margin: 0px 15px;" >
        <v-list subheader two-line style="padding: 10px; height: 500px; overflow: auto;">
          <div style="display: flex; justify-content: space-between;">
            <v-subheader>Продукты со склада</v-subheader> 
            <v-btn v-show="choices_buffet_products.length > 0" @click="addProductsToBlackList">Переместить</v-btn>
          </div>
          

          <v-list-tile v-for="product in all_buffet_products" :key="product.id" style="cursor: pointer;">
            <v-list-tile-action>
              <v-checkbox :value="product.id" v-model="choices_buffet_products"></v-checkbox>
            </v-list-tile-action>

            <v-list-tile-content >
              <v-list-tile-title>{{product.name}}</v-list-tile-title>
              <v-list-tile-sub-title>Цена: {{product.price}}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card> 
      
    </div>
    
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'BlackListDetail',
  data: () => ({
    is_create: false,
    pupil_pk: null,
    choices_buffet_products: [],
    choices_blacklist_products: []
  }),
  async mounted() {
    if (!this.$route.params.pupil_pk) this.$router.push({ name: 'BlackList' })
    if (this.$route.params.create) this.is_create = true
    else this.is_create = false

    this.pupil_pk = this.$route.params.pupil_pk

    await this.getAllBuffetProducts({ params: { school: this.$route.params.school, page: 1, page_size: 2000 } })

    if (this.is_create) {
      
    } else {
      await this.getBlackLstDetail({ blacklist_id: this.$route.params.blacklist_id })
      this.removeEqualProducts()
    }
  },
  methods: {
    ...mapActions([ 'getBlackLstDetail', 'getAllBuffetProducts', 'createBlackList', 'updateBlackList' ]),
    ...mapMutations([ 'clear_all_blacklist' ]),
    addProductsToBlackList() {
      this.choices_buffet_products.map(idProduct => {
        const findIdx = this.all_buffet_products.findIndex(product => product.id === +idProduct)
        if (findIdx >= 0) {
          const findProduct = this.all_buffet_products.splice(findIdx, 1)[0]
          this.blacklist_detail.products.push(findProduct)
        }
        return 0
      })
      this.choices_buffet_products = []
    },
    removeProductFromBlackList() {
      this.choices_blacklist_products.map(idProduct => {
        const findIdx = this.blacklist_detail.products.findIndex(product => product.id === +idProduct)
        if (findIdx >= 0) {
          const findProduct = this.blacklist_detail.products.splice(findIdx, 1)[0]
          this.all_buffet_products.push(findProduct)
        }
        return 0
      })
      this.choices_blacklist_products = []
      
    },
    removeEqualProducts() {
      this.blacklist_detail.products.forEach(productBlackLIst => {
        const findIdx = this.all_buffet_products.findIndex(product => product.id === productBlackLIst.id)
        if (findIdx >= 0) {
          this.all_buffet_products.splice(findIdx, 1)
        }
        return 0
      })
    },

    saveBlacklist() {
      if (this.is_create) {
        this.createBlackList({
          data: {
            pupil: this.pupil_pk,
            products: this.blacklist_detail.products.map(p => p.id)
          }
        })
      } else {
        this.updateBlackList({
          blacklist_id: this.blacklist_detail.id,
          data: {
            pupil: this.pupil_pk,
            products: this.blacklist_detail.products.map(p => p.id)
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters([ 'blacklist_detail', 'all_buffet_products' ])
  },
  watch: {
    'blacklist_detail.id'(val) {
      if (val) {
        this.is_create = false
      }
    }
  },
  beforeDestroy() {
    this.clear_all_blacklist()
  }
}
</script>

<style>

</style>