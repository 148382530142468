<template>
  <v-container fluid grid-list-md>
    <v-layout row mb-3>
      <v-flex mb-3>
        <h1>Лог действий департамента</h1>
      </v-flex>
    </v-layout>
    <v-layout row mt-3 mb-3 justify-space-around align-center>
      <v-layout wrap row>
        <v-flex md3 mr-3>
          <DatePickersSmart
            :normalStyle="true"
            :startDate.sync="dateStart"
            :endDate.sync="dateEnd"
            @changeDate="changeDate"
          />
        </v-flex>
        <v-flex
          md9
          mr-3
        >
          <v-autocomplete
            v-model="department_id"
            :items="departments"
            item-text="name"
            item-value="id"
            label="МОУО"
          />
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout mb-3 justify-space-around>
      <v-flex>
        <v-data-table
          :headers="headers"
          :pagination.sync="pagination"
          :items="items"
          hide-actions
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.createdAt }}</td>
              <td>{{ props.item.author }}</td>
              <td>{{ props.item.operation_type }}</td>
              <td>{{ props.item.context }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import { getDepartmentLogs, getDepartmentslist } from '@/router/routes/superadmin'
import { mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import moment from 'moment'
import scrollable from "@/mixins/scrollable"
import notify from '@/utils/notifications/toastNotification'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal"
import { DatePickersSmart } from "@/components/common"

export default {
  name: 'DepartmentLogs',
  mixins: [ scrollable ],
  components: {
    ContextHelpModal,
    DatePickersSmart
  },
  data() {
    return {
      pagination: {
        rowsPerPage: -1
      },
      headers: [
        { text: 'Дата', value: 'created_at', sortable: false },
        { text: 'Департамент', value: 'id', sortable: false },
        { text: 'Действие', value: 'operation_type', sortable: false },
        { text: 'Контекст', value: 'context', sortable: false },
      ],
      items: [],
      departments: [],
      dateStart: moment()
        .subtract(1, 'days')
        .format(Vue.config.format.serverDate),
      dateEnd: null,
      department_id: null,
      filters: {
        department_id: null,
      },
      limit: Vue.config.general.limit,
    }
  },
  created() {
    if (this.$route.query.dateStart) {
      this.dateStart = this.$route.query.dateStart
      this.dateEnd = this.$route.query.dateEnd
    }
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    convertToPythonDate(date) {
      if (date) {
        return moment(date).format(Vue.config.format.pythonDate)
      }
      return null
    },
    convertToNormalDate(date) {
      if (date) {
        return moment(date).format(Vue.config.format.date)
      }
      return null
    },
    async getDepartments() {
      try {
        const response = await getDepartmentslist()
        const { data: items } = response
        items.forEach(item => {
          const { id, name } = item
          this.departments.push({
            id: id,
            name: name,
          })
        })
      } catch (err) {
        notify.error('Ошибка загрузки МОУО')
      } finally {
        this.setShowPreloader(false)
      }
    },

    changeDate() {
      this.items = []
      this.loadMore()
    },
    async loadMore(reset) {
      if (reset) {
        this.items = []
        this.offset = 0
      }
      try {
        const payload = {
          params: {
            department_id: this.department_id || null,
            limit: this.limit,
            offset: this.offset
          }
        }
        if (!this.dateEnd) {
          payload.params['date'] = this.convertToPythonDate(this.dateStart)
        } else {
          payload.params['min_date'] = this.convertToPythonDate(this.dateStart)
          payload.params['max_date'] = this.convertToPythonDate(this.dateEnd)
        }
        const response = await getDepartmentLogs(payload)
        const itemsArray = _.map(response.data.data, item => {
          return {
            ...item,
            createdAt: moment(item.created_at).format(Vue.config.format.serverDateFull)
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        // notify.error('Не удалось загрузить лог') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  },
  mounted() {
    this.getDepartments()
  },
  watch: {
    items(items) {
      this.savePosition(items)
    },
    department_id() {
      this.offset = 0
      this.items = []
      this.loadMore()
    }
  }
}
</script>
