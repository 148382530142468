<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-complex"
      row
      mb-3
    >
      <v-flex
        md4
        mb-3
      >
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout
      class="fs-student-info"
      mb-3
      row
    >
      <v-flex md6>
        <v-card class="fs-fields-group--gray">
          <v-card-text>
            <v-flex mb-2>
              <span class="fs-student-info__key">Лицевой счет:</span>
              <span class="fs-student-info__value">{{student.id}}</span>
            </v-flex>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex>
        <v-card class="fs-fields-group--gray">
          <v-card-title>
            <h1>Баланс:</h1>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-layout
              mb-3
              mt-3
            >
              <v-flex>
                <span class="fs-student-info__key">Текущий баланс:</span>
              </v-flex>
              <v-flex text-md-right>
                <span class="fs-student-info__key">{{currentBalance || 0}} р.</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-actions>
            <v-layout>
              <v-flex>
                <v-text-field
                  label="Ручная корректировка"
                  v-model="correctBalance"
                ></v-text-field>
              </v-flex>
              <v-flex>
                <v-text-field
                  label="Описание операции"
                  v-model="comment"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex
        text-md-right
        pt-3
      >
        <v-btn
          flat
          class="fs-btn fs-organizer-btn__show v-btn--active"
          @click="$router.push({name: 'BalanceList'})"
        >Назад</v-btn>
        <v-btn
          :disabled="!comment"
          flat
          class="fs-btn fs-organizer-btn__show v-btn--active fs-form-footer__btn"
          @click="updateStudent()"
        >Сохранить</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getOneStudent, updateStudentBalance } from '@/router/routes/organizer'
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import numable from '@/mixins/balancable'
import Vue from 'vue'
import _ from 'lodash'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'OrganizerBalanceChange',
  mixins: [ numable ],
  data() {
    return {
      title: 'Карточка ученика',
      student: {},
      correctBalance: 0,
      comment: '',
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
    currentBalance() {
      return this.convertToRubles(this.student.balance)
    }
  },
  created() {
    this.getStudent().finally(() => store.commit('setShowPreloader', false))
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    getStudent() {
      const payload = {
        schoolId: this.$route.params.schoolId,
        id: this.$route.params.id
      }
      return getOneStudent(payload).then(response => {
        const student = response.data.data
        this.student = {
          id: student.id,
          remoteId: student.personId,
          schoolClass: {
            id: _.get(student, 'schoolClass.id', null),
            name: _.get(student, 'schoolClass.name', null)
          },
          balance: _.get(student, 'balance', 0)
        }
      })
    },
    updateStudent() {
      if (this.correctBalance !== 0) {
        const payload = {
          personId: this.student.remoteId,
          amount: this.convertToCents(this.correctBalance),
          comment: this.comment,
        }
        updateStudentBalance(payload)
          .then(() => {
            return this.getStudent()
          })
          .then(response => {
            if (response.data.success) {
              notify.success(Vue.config.notifications.administrator.studentUpdated)
            }
          })
          .finally(() => {
            store.commit('setShowPreloader', false)
            notify.success(Vue.config.notifications.administrator.studentUpdated)
          })
      }
    }
  }
}
</script>
