<template>
  <v-container fluid class="fs-teacher-container">
    <div class="page-head__common">
      <h1>{{title}}</h1>
      <v-btn
        flat
        @click="() => $router.push({ name: 'StorehouseDetail' })"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Создать склад для учреждения</v-btn>
    </div>

    <v-layout class="fs-teacher-order-result" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="storehouses.results"
          hide-actions
          no-data-text="Нет складов"
          class="elevation-1 fs-admin-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.uuid }}</td>
              <td>{{ props.item.created_at | transformDate }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.school_detail.name }}</td>
              <td>
                <v-flex row>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="$router.push({name: 'StorehouseDetail', params: {id: props.item.id, meta: 'edit'}})"
                      >edit</v-icon>
                    </template>
                    <span>Редактирование</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="deleteStorehouse(props.item.id)"
                      >delete</v-icon>
                    </template>
                    <span>Удалить</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <ConfirmModal
      :dialog.sync="confirmDeleteModal"
      @confirm="handlerConfirmModal"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
    >
      <template v-slot:headline>Вы действительно хотите удалить склад?</template>
    </ConfirmModal>

    <div class="text-xs-center" v-if="pagination_count > 1">
      <v-pagination
        @input="handlePaginationClick"
        :value="storehouses_pagination.page"
        :length="pagination_count"
        :total-visible="7"
      ></v-pagination>
    </div>

  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ConfirmModal from '@/components/common/Modals/ConfirmModal'
export default {
  name: 'Storehouse',
  components: { ConfirmModal },
  data: () => ({
    confirmDeleteModal: false,
    storehouse_id: null,
    title: 'Склады учреждений',
    limit: Vue.config.general.limit,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'UUID', value: 'uuid' },
      { text: 'Дата создания', value: 'created_at' },
      { text: 'Наименование', value: 'name' },
      { text: 'Школа', value: 'school' },
      { text: 'Операции', value: 'operations' }
    ],
    cards: []
  }),
  methods: {
    ...mapActions([ 'fetchStorehouses', 'deleteStorehouseCard' ]),
    ...mapMutations([ 'set_storehouses_pagination' ]),
    async getStorehouses() {
      this.fetchStorehouses({
        params: {
          page: this.storehouses_pagination.page,
          page_size: this.storehouses_pagination.page_size,
          school: this.currentUser.school.id
        }
      })
    },
    async handlerConfirmModal(value) {
      if (value) {
        this.deleteStorehouseCard({ storehouse_id: this.storehouse_id })
          .then(() => {
            this.getStorehouses()
          })
      } else {
        this.confirmDeleteModal = false
      }
      this.storehouse_id = null
    },
    async deleteStorehouse(storehouse_id) {
      this.storehouse_id = storehouse_id
      this.confirmDeleteModal = true
    },
    handlePaginationClick(page) {
      this.set_storehouses_pagination({ page: page })
      this.getStorehouses()
    }
  },
  computed: {
    ...mapGetters([ 'storehouses', 'storehouses_pagination', 'currentUser' ]),
    pagination_count() {
      return Math.ceil(this.storehouses.count / this.storehouses_pagination.page_size) || 0
    },
  },
  created() {
    this.getStorehouses()
  },
}
</script>