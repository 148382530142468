<template>
  <v-dialog
    v-model="dialog"
    v-bind="$attrs"
    persistent
    no-click-animation
    max-width="600px"
    :hide-overlay="fullscreen"
    :transition="fullscreen ? 'dialog-bottom-transition' : 'dialog-transition'"
  >
    <v-card>
      <v-toolbar v-if="fullscreen" dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <slot name="actions"></slot>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title v-else>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions v-if="!fullscreen">
        <v-spacer></v-spacer>
        <v-btn flat color="primary" @click="close()">Отмена</v-btn>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Modal',
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    fullscreen() {
      return 'fullscreen' in this.$attrs && this.$attrs.fullscreen !== false
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.$emit('close:before')
      this.dialog = false
    }
  }
}
</script>
