import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  state: {},
  getters: getters,
  mutations: mutations,
  actions: actions
}
