<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-dish"
      row
    >
      <v-flex
        md3
        mb-5
      >
        <h1>{{ title }}</h1>
      </v-flex>
    </v-layout>
    <div>
        <v-btn
        flat
        class="primary ma-0 mb-3"
        @click="openEditorModal()"
        >
        <v-icon
            primary
            left
        >edit</v-icon>Создать оповещение
        </v-btn>
        <br>
        <v-btn
        flat
        class="primary ma-0 mb-3"
        @click="showAndHideNotActive()"
        >{{ active ? 'Показать' : 'Скрыть' }} неактивные
        </v-btn>
    </div>
    <v-layout
      class="fs-dish-result"
      column
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="items"
          hide-actions
          no-data-text="Нет оповещений"
          item-key="id"
        >
          <template v-slot:items="props">
            <tr>
              <td class="cursor-pointer" @click="() => {dialogText = props.item.message; dialog = true}">{{ props.item.id }}</td>
              <td class="cursor-pointer" @click="() => {dialogText = props.item.message; dialog = true}">{{ new Date(props.item.start_time).toLocaleString() }}</td>
              <td class="cursor-pointer" @click="() => {dialogText = props.item.message; dialog = true}">{{ new Date(props.item.end_time).toLocaleString() }}</td>
              <td class="text-xs-left">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="openEditorModal(props.item.message); alertStart = props.item.start_time; alertEnd = props.item.end_time; editStatus = true; selectedID = props.item.id"
                      class="fs-active-icon"
                    >edit</v-icon>
                  </template>
                  <span>Редактировать</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="changeActivity(props.item.id, props.item.is_active)"
                      class="pl-2 fs-active-icon"
                    >{{ active ? "toggle_on" : "toggle_off" }}</v-icon>
                  </template>
                  <span>Сделать {{ active ? "неактивным" : "активным" }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="selectedID = props.item.id, confirmAlertDeleting = true"
                      class="pl-2 fs-active-icon"
                    >delete</v-icon>
                  </template>
                  <span>Удалить</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialog" width="60%">
      <v-card-title class="text-h5 grey lighten-2">
        <h3>Оповещение о плановом обновлении системы</h3>
      </v-card-title>
      <v-card class="pa-3">
        <div v-html="dialogText"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mt-4" color="success" @click="() => {this.dialog = false}">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmModal
      :dialog.sync="confirmAlertDeleting"
      @confirm="deleteAlert"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
    >
      <template v-slot:headline>Вы действительно хотите удалить оповещение?</template>
    </ConfirmModal>

    <TextEditorModal
      :dialog.sync="editorModalShow"
      @confirm="handlerConfirmModal"
      :content.sync="content"
    >
      <template v-slot:headline>{{ editStatus ? 'Редактирование' : 'Создание' }} оповещения</template>
      <template v-slot:headright>
        <div class="d-flex" v-if="editorModalShow">
            <div>Начало: <DateTimePicker v-model="alertStart" /></div>
            <div>Конец: <DateTimePicker v-model="alertEnd" /></div>
        </div>
      </template>
    </TextEditorModal>
  </v-container>
</template>

<script>
import store from '@/store'
import Vue from 'vue'
import { TextEditorModal } from '@/components/common'
import DateTimePicker from '@/components/common/DatePickersSmart/DateTimePicker'
import ConfirmModal from '@/components/common/Modals/ConfirmModal'
import {
  getAlertsList,
  createAlert,
  updateAlert,
  deleteAlert,
  changeActivityStatus
} from '@/router/routes/alerts'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'UpdatesNews',
  components: {
    TextEditorModal,
    ConfirmModal,
    DateTimePicker
  },
  data() {
    return {
      title: 'Плановое обновление системы',
      alertStart: null,
      alertEnd: null,
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Начало', value: 'start_time', sortable: false },
        { text: 'Конец', value: 'end_time', sortable: false },
        { text: 'Операции', value: 'operations', sortable: false }
      ],
      items: [],
      dialog: false,
      dialogText: '',
      editorModalShow: false,
      editStatus: false,
      selectedID: null,
      content: '',
      active: true,
      confirmAlertDeleting: false,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      try {
        const payload = {
          params: {
            active: this.active
          }
        }
        const response = await getAlertsList(payload)
        if (this.active) {
          this.items = this.showActiveAlerts(response.data)
          this.findInActive(response.data)
        } else {
          this.items = response.data
        }
      } catch (error) {
        // notify.error('Ошибка загрузки списка оповещений') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    showActiveAlerts(data) {
      return data.filter(item => new Date(item.end_time) > new Date())
    },
    async findInActive(data) {
      const ids = data.filter(item => new Date(item.end_time) < new Date())
      ids.forEach(item => this.changeActivity(item.id, item.is_active))
    },
    async showAndHideNotActive() {
      this.active = !this.active
      await this.load()
    },
    openEditorModal(text) {
      this.content = ''
      if (text) {
        this.content = text
      } else {
        this.alertStart = null
        this.alertEnd = null
      }
      this.editorModalShow = true
    },
    async deleteAlert(value) {
      if (value) {
        try {
          await deleteAlert(this.selectedID)
          notify.success('Оповещение успешно удалено')
        } catch (error) {
          // notify.error('Ошибка удаления оповещения') // backend handler added (exception_message)
        } finally {
          store.commit('setShowPreloader', false)
          this.load()
        }
      }
    },
    async handlerConfirmModal(value) {
      if (value) {
        if (this.alertStart === null || this.alertEnd === null) {
          notify.error('Заполните все поля') //pass
          return
        }
        if (this.editStatus) {
          const payload = {
            id: this.selectedID,
            message: this.content,
            start_time: this.alertStart,
            end_time: this.alertEnd
          }
          try {
            await updateAlert(payload)
            notify.success('Оповещение успешно отредактировано')
            this.load()
          } catch (error) {
            // notify.error('Ошибка редактирования оповещения') // backend handler added (exception_message)
            console.error('LOG: handlerConfirmModal -> error', error)
          } finally {
            store.commit('setShowPreloader', false)
          }
        } else {
          const payload = {
            message: this.content,
            start_time: this.alertStart,
            end_time: this.alertEnd
          }
          try {
            await createAlert(payload)
            notify.success('Оповещение успешно создано')
            this.load()
          } catch (error) {
            // notify.error('Ошибка создания оповещения') // backend handler added (exception_message)
          } finally {
            store.commit('setShowPreloader', false)
          }
        }
      }
      this.editStatus = false
    },
    async changeActivity(id, is_active) {
      try {
        const payload = {
          id: id,
          is_active: !is_active,
        }
        await changeActivityStatus(payload)
        this.load()
      } catch (error) {
        // notify.error('Ошибка изменения активности оповещения') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>
