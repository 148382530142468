import Vue from 'vue'
import { Menu } from '@/lib/menu'

export default class MainMenuFactory {
  constructor(changingSchoolTraffic, isPupil, settings) {
    let menu = [
      new Menu('Новости', 'Новости обновлений', 'UpdatesNewsParent').isOnlyForParent(),
      new Menu(
        'Общая информация',
        'Просмотр информации о ребёнке, типе питания, назначенной категории, текущем балансе и др.',
        isPupil ? 'PupilPersonalArea' : 'PersonalArea'
      ).setParams({ id: 1 }),
      new Menu(
        'Заказ питания',
        'Выбор комплексов питания для ребёнка',
        isPupil ? 'PupilOrderIndex' : 'ParentOrderMenu'
      ),
      new Menu(
        'Явка',
        'Выбор дней явки для ребёнка',
        'ParentTrafficMenu',
        false,
        !changingSchoolTraffic
      ).isOnlyForParent(),
      new Menu(
        'Пополнение счёта',
        'Пополнение баланса лицевого счёта ребёнка',
        'BalanceAdd'
      ).isOnlyForParent(),
      new Menu(
        'Движение денежных средств',
        'Получение выписки по счёту: пополнения и списания денежных средств',
        isPupil ? 'PupilBalanceMoving' : 'BalanceMoving'
      ),
      new Menu(
        'Заявления на возврат',
        'Отправка заявления на возврат денежных средств',
        'CashbackIndex'
      ).isOnlyForParent(),
      new Menu(
        'История питания',
        'Таблица с историей питания ребёнка',
        isPupil ? 'PupilChildHistory' : 'ParentChildHistory'
      ),
      new Menu(
        'Уведомления',
        'Просмотр информационных сообщений от администрации школы',
        'ParentNotifications',
        true
      ).setBadgeClass('menu-notification--parent').isOnlyForParent(),
    ]

    if (settings.mod_buffet?.value) {
      menu = menu.concat([
        new Menu('Опросы', 'Похождение опросов', 'Syrvey', true)
          .setBadgeClass('menu-notification--parent'),
        new Menu('Чёрный список', 'Чёрный список буфетной продукции', 'BlackList'),
        new Menu('Буфетное меню', 'Буфетное меню', 'BuffetMenu')
      ])
    }

    if (settings.mod_quality_control?.value) {
      menu = menu.concat([
        new Menu('Оценки заказов', 'Оценки заказов родителем', 'ParentQualityAssessments', true)
          .setBadgeClass('menu-notification--parent')
      ])
    }

    if (settings.user_help?.value) {
      menu = menu.concat([
        // new Menu('Инструкции', 'Инструкции', 'ParentUserHelp').isOnlyForParent(),
        new Menu('Справка', 'Справка', 'HelpDeskPA')
      ])
    }
    if (settings.parent_menu_cbju?.value) {
      for (const i of menu) {
        if (i.title === 'Заказ питания') {
          i.title = 'Меню'
        }
      }
    }

    return menu
  }
}
