<template>
  <Pug>
    <h1>401</h1>
    <p>Авторизация в системе не пройдена.</p>
  </Pug>
</template>

<script>
import Pug from '@/components/lib/Pug'

export default {
  name: 'Unauthorized',
  components: {
    Pug
  }
}
</script>
