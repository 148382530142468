<script>
import ElementGroup from "@/components/common/ElementGroup/ElementGroup.vue"
import { doErrorRequest } from "@/router/routes/superadmin"

export default {
  name: 'DebugComponent',
  components: { ElementGroup },
  data() {
    return {

    }
  },
  methods: {
    async callError(errorType) {
      await doErrorRequest(errorType)
    }
  }
}
</script>

<template>
  <v-content>
    <v-container fluid>
      <v-card>
        <v-card-title>Проверка системы уведомлений на различных типах ответа сервера</v-card-title>
        <v-card-text>
          <element-group title="Кнопки вызова ошибок">
            <v-btn color="error" @click="callError('drf_dict')">DRF Dict</v-btn>
            <v-btn color="error" @click="callError('drf_dictArray')">DRF Dict Array</v-btn>
            <v-btn color="error" @click="callError('drf_strArray')">DRF Str Array</v-btn>
            <v-btn color="error" @click="callError('drf_validation')">DRF Validation</v-btn>
            <v-btn color="error" @click="callError('exception')">Exception With Message</v-btn>
            <v-btn color="error" @click="callError('500exception')">Exception 500</v-btn>
            <v-btn color="error" @click="callError('500exception_message')">Exception 500 with Message</v-btn>
            <v-btn color="error" @click="callError('500')">Server 500</v-btn>
            <v-btn color="error" @click="callError('500message')">Server 500 with Message</v-btn>
          </element-group>
        </v-card-text>
      </v-card>
    </v-container>
  </v-content>
</template>

<style scoped>

</style>
