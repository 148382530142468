<template>
  <v-container
    v-if="showPayments"
    class="fs-parent-container refill-page"
    grid-list-md
    fluid
  >
    <v-layout>
      <v-flex mb-3>
        <h1>{{ title }}</h1>
      </v-flex>
    </v-layout>
    <v-layout mb-3 row>
      <v-flex md-2>
        <v-alert :value="currentChild.isFake" type="error">
          <h3>
            Ошибка: По данному ученику из учётной системы региона получены
            неполные данные. Работа с ним временно заблокирована. Ожидайте
            повторной синхронизации.
          </h3>
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout v-if="!currentChild.isFake">
      <v-flex mb-3>
        <v-btn
          :href="manual"
          class="fs-btn fs-parent-btn fs-btn--secondary mx-0"
          target="_blank"
          flat
          >Памятка по оплате с моб. устройств</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="!currentChild.isFake">
      <v-flex xs12>
        <h2>Способы оплаты</h2>
        <v-divider class="my-2"></v-divider>
      </v-flex>
      <v-flex xs12 v-if="setting('payment_method_by_receipt')">
        <h3>Оплата по квитанции</h3>
        <p>
          Выберите пункт "Сформировать квитанцию" и нажмите продолжить. Затем
          скачайте или распечатайте квитанцию. После оплаты по квитанции
          обязательно проверьте наличие чека.
        </p>
      </v-flex>
      <v-flex xs12 v-show="sberAvailable === true">
        <h3>Оплата банковской картой</h3>
        <p>
          Для выбора оплаты питания с помощью банковской карты выберите пункт
          "Оплата банковской картой". Оплата происходит через ПАО "Сбербанк".
        </p>
        <p>
          Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на
          платёжный шлюз ПАО "Сбербанк". Соединение с платёжным шлюзом и
          передача информации осуществляется в защищённом режиме с
          использованием протокола шифрования SSL. В случае, если Ваш банк
          поддерживает технологию безопасного проведения интернет-платежей
          Verified By Visa, MasterCard SecureCode, MIRAccept, J-Secure для
          проведения платежа также может потребоваться ввод специального пароля.
          Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность
          сообщаемой персональной информации обеспечивается ПАО "Сбербанк".
          Введённая информация не будет предоставлена третьим лицам за
          исключением случаев, предусмотренных законодательством РФ. Проведение
          платежей по банковским картам осуществляется в строгом соответствии с
          требованиями платежных систем МИР, Visa Int., MasterCard Europe Sprl,
          JCB.
        </p>
      </v-flex>
    </v-layout>
    <v-layout
      v-if="!currentChild.isFake"
      class="fs-parent-payment-form-container"
      justify-space-around
    >
      <v-stepper v-model="paymentSteps" vertical>
        <v-stepper-step :complete="paymentSteps > 1" step="1" color="#cb6026">
          <span class="fs-parent-payment__title">Выберите способ оплаты</span>
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-layout justify-space-around>
            <v-flex md6>
              <v-card>
                <v-card-text>
                  <v-radio-group v-model="paymentMethod">
                    <v-layout class="d-block">
                      <v-flex v-show="sberAvailable === true">
                        <v-radio
                          :value="1"
                          label="Оплата банковской картой"
                          class="fs-radio mb-0"
                          color="#cb6026"
                        ></v-radio>
                        <v-flex v-show="sberAvailable" class="icons">
                          <img alt="payService" :src="mir" />
                          <img alt="payService" :src="visa" />
                          <img alt="payService" :src="mastercard" />
                          <img alt="payService" :src="jcb" />
                        </v-flex>
                        <a href="#" @click="showPrivacyModal"
                          ><small>Политика конфиденциальности</small></a
                        >
                      </v-flex>
                      <v-flex
                        :class="{ 'd-none': !adminAuth }"
                        v-show="sberAvailable === true"
                      >
                        <v-radio
                          :value="3"
                          label="Оплата через SberPay"
                          class="fs-radio"
                          color="#cb6026"
                        ></v-radio>
                      </v-flex>
                      <v-flex v-if="setting('payment_method_by_receipt')">
                        <v-radio
                          :value="2"
                          label="Сформировать квитанцию"
                          class="fs-radio"
                          color="#cb6026"
                        ></v-radio>
                      </v-flex>
                    </v-layout>
                  </v-radio-group>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout justify-space-around mt-3>
            <v-flex>
              <v-btn
                class="fs-btn fs-parent-btn fs-form-footer__btn"
                @click="paymentSteps = 2"
                >Продолжить</v-btn
              >
            </v-flex>
          </v-layout>
        </v-stepper-content>
        <v-stepper-step :complete="paymentSteps > 2" step="2" color="#cb6026">
          <span class="fs-parent-payment__title">Оплатите</span>
          <small
            >Укажите либо количество дней питания, либо сумму. Подсчёт будет
            выполнен автоматически</small
          >
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-layout class="fs-parent-payment-form" wrap>
            <v-flex xs12>
              <v-btn
                class="fs-btn fs-parent-btn fs-form-footer__btn"
                @click="isShowRefillModal = true"
                >Продолжить</v-btn
              >
            </v-flex>
            <v-flex xs12>
              <v-dialog
                v-model="isShowRefillModal"
                persistent
                width="300px"
                fullscreen
              >
                <v-card>
                  <v-toolbar elevation="1" dense light>
                    <v-toolbar-title>Пополнение счёта</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.prevent="isShowRefillModal = false">
                      <v-icon class="fs-active-icon">close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-container grid-list-md pa-0>
                      <v-layout mb-3>
                        <v-flex class="fs-parent-balance">
                          <label>Фактический остаток:</label>
                          <div class="fs-parent-value">{{ balance }} руб.</div>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap mb-3>
                        <v-flex xs12>
                          <v-text-field
                            :value="foodDays"
                            :rules="[formRules.minCount, formRules.maxCount]"
                            label="Кол-во дней питания"
                            color="#cb6026"
                            mask="###"
                            @input="calculateFoodPeriod('days', $event)"
                          />
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            :value="foodSum"
                            :rules="[formRules.minCount]"
                            label="Сумма пополнения"
                            color="#cb6026"
                            mask="######"
                            @input="calculateFoodPeriod('sum', $event)"
                          />
                        </v-flex>
                      </v-layout>
                      <v-layout wrap>
                        <v-flex md6 xs12>
                          <v-btn
                            class="fs-btn fs-parent-btn fs-btn--secondary ma-0"
                            block
                            flat
                            @click="
                              () => {
                                this.isShowRefillModal = false
                                this.paymentSteps = 1
                              }
                            "
                            >Отмена</v-btn
                          >
                        </v-flex>
                        <v-flex md6 xs12>
                          <v-btn
                            v-show="paymentMethod === 1 || paymentMethod === 3"
                            class="fs-btn fs-parent-btn fs-btn--secondary ma-0"
                            block
                            flat
                            @click="goToSberbank()"
                            >Пополнить счёт</v-btn
                          >
                          <v-btn
                            v-show="paymentMethod === 2"
                            class="fs-btn fs-parent-btn fs-btn--primary ma-0"
                            target="_blank"
                            block
                            flat
                            @click="print()"
                            >Распечатать квитанцию</v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-flex>
          </v-layout>
        </v-stepper-content>
        <v-stepper-step :complete="paymentSteps > 3" step="3" color="#cb6026">
          <span class="fs-parent-payment__title">Готово</span>
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-layout justify-space-around>
            <v-flex md6>
              <v-card v-if="paymentMessage">
                <v-card-text>
                  <span>{{ paymentMessage }}</span>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-stepper-content>
      </v-stepper>
    </v-layout>
    <parent-privacy-modal :show.sync="modalShow" />

    <v-dialog v-model="sberPaymentErrorModal" width="60%">
      <v-card-title class="text-h5 grey lighten-2">
        <h3>Внимание!</h3>
      </v-card-title>
      <v-card class="pa-3">
        <p>
          <strong
            >В связи с работами по переходу на новые сертификаты на стороне
            Сбера, возможны проблемы с проведением операций пополнения баланса.
            В данный момент мы работаем для восстановления возможности внесения
            денежных средств на лицевые счета.</strong
          >
        </p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mt-4"
            color="success"
            @click="
              () => {
                this.sberPaymentErrorModal = false
              }
            "
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

  <v-container v-else-if="showPaymentsWarning">
    <v-card-title class="error lighten-2">
      <h2>Внимание!</h2>
    </v-card-title>
    <v-card class="pa-3">
      <h3>
        Возможность внесения оплаты временно приостановлена, т.к. производится
        настройка системы питания
      </h3>
    </v-card>
  </v-container>
</template>

<script>
import {
  checkSberAvailable,
  getOneStudent,
  getOrderLink,
  printInvoice
} from '@/router/routes/parent'
import { mapGetters, mapMutations } from 'vuex'
import numable from '@/mixins/balancable'
import moment from 'moment'
import Vue from 'vue'
import _ from 'lodash'
import store from '@/store'
import rules from '@/helpers/rules'
import mir from '@/assets/img/payments/mir.png'
import visa from '@/assets/img/payments/visa.png'
import jcb from '@/assets/img/payments/jcb.png'
import mastercard from '@/assets/img/payments/mastercard.png'
import { DatePickersSmart } from '@/components/common'
import ParentPrivacyModal from '@/components/parent/Modals/Privacy'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'BalanceAddIndex',
  mixins: [numable],
  components: { ParentPrivacyModal, DatePickersSmart },
  data() {
    return {
      sberPaymentErrorModal: false,
      isShowRefillModal: false,
      title: 'Пополнение счета',
      formRules: rules,
      foodDays: 22,
      foodSum: 0,
      paymentSteps: 1,
      paymentMethod: null,
      paymentMessage: null,
      publicPath: process.env.BASE_URL,
      balance: 0,
      mir: mir,
      visa: visa,
      jcb: jcb,
      mastercard: mastercard,
      manual: Vue.config.publicPath + 'mobile.pdf',
      dateStart: null,
      dateEnd: null,
      sberAvailable: null,
      modalShow: false,
      isShowPayments: false,
      isShowPaymentsWarning: false
    }
  },
  computed: {
    ...mapGetters(['currentChild', 'adminAuth', 'setting']),
    foodPrice() {
      let value = 0
      if (this.currentChild.foodType && this.currentChild.privilege) {
        value =
          _.get(this.currentChild, 'foodType.price', 8000) -
          _.get(this.currentChild, 'privilege.price', 2000)
      }

      return value < 1 ? 6000 : value
    },
    showPayments() {
      return this.isShowPayments
    },
    showPaymentsWarning() {
      return this.isShowPaymentsWarning
    }
  },
  created() {
    if (this.$route.query.status == 'success') {
      this.paymentSteps = 3
      this.paymentMessage = 'Готово'
    }

    if (this.$route.query.status == 'fail') {
      this.paymentSteps = 3
      this.paymentMessage = 'Провал'
    }
    getOneStudent(this.currentChild.id)
      .then((response) => {
        const student = response.data.data
        this.balance = this.convertToRubles(_.get(student, 'balance', 0))
        this.ryazanPaymentsOffCheck(student.supplier.department)
      })
      .finally(() => store.commit('setShowPreloader', false))
  },
  mounted() {
    this.calculateFoodPeriod('days', this.foodDays)
    this.checkSberAvailable()
  },
  methods: {
    ...mapMutations(['setShowPreloader']),
    ryazanPaymentsOffCheck(currentDepartmentId) {
      const paymentOffDepartmentId = 22
      if (
        Vue.config.env.isRyazan &&
        currentDepartmentId === paymentOffDepartmentId
      ) {
        this.isShowPaymentsWarning = true
      } else {
        this.isShowPayments = true
      }
    },
    checkSberAvailable() {
      checkSberAvailable(this.currentChild.personId).then((res) => {
        this.sberAvailable = res.data.sber
        this.sberAvailable ? (this.paymentMethod = 1) : (this.paymentMethod = 2)
      })
    },
    clearDates() {
      this.dateStart = null
      this.dateEnd = null
    },
    showPrivacyModal() {
      this.modalShow = true
    },
    calculateFoodPeriod: _.debounce(function (type, e) {
      if (!e) {
        return
      } else if (type === 'days') {
        this.foodDays = e
        this.foodSum = Math.ceil(e * this.convertToRubles(this.foodPrice))
      } else {
        this.foodDays = parseInt(this.convertToCents(e) / this.foodPrice)
        this.foodSum = e
      }
    }, 300),
    goToSberbank() {
      getOrderLink(
        this.currentChild.personId,
        this.convertToCents(this.foodSum),
        this.paymentMethod === 3
      )
        .then((response) => {
          const link = response.data.data.redirectUrl
          window.open(link, '_blank')
        })
        .finally(() => store.commit('setShowPreloader', false))

      this.calculateFoodPeriod('days', this.foodDays)
    },
    print() {
      const payload = {
        params: {
          student_id: _.get(this.currentChild, 'id'),
          days: this.foodDays,
          sum: this.convertToCents(this.foodSum)
        }
      }
      if (this.dateStart && this.dateEnd) {
        payload.params.start_date = this.dateStart
        payload.params.end_date = this.dateEnd
      }

      printInvoice(payload)
        .then((response) => {
          const file = new Blob([response.data], { type: response.data.type })
          const url = window.URL.createObjectURL(file)
          const a = document.createElement('a')
          a.href = url
          a.download =
            'Квитанция на оплату от ' +
            moment().format(Vue.config.format.date) +
            '.xlsx'
          a.click()
        })
        .catch((error) => {
          notify.error(Vue.config.notifications.parent.noMealTypeOrParentText)
        })
        .finally(() => store.commit('setShowPreloader', false))

      this.paymentSteps = 3
    }
  }
}
</script>

<style scoped>
body .v-dialog__content {
  align-items: flex-start !important;
}
.text-not-available {
  color: #bd5558;
  font-size: 0.8rem;
}
</style>
