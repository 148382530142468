<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-dish"
      row
    >
      <v-flex
        md3
        mb-5
      >
        <h1>{{ title }}</h1>
      </v-flex>

    </v-layout>

    <v-flex>
      <v-data-table
        :headers="headers"
        :items.sync="items"
        hide-actions
        no-data-text="Нет новостей"
        item-key="id"
      >
        <template v-slot:items="props">
          <tr>
            <td class="cursor-pointer" @click="() => {showDialog(props.item.title, props.item.text)}">{{ props.item.id }}</td>
            <td class="cursor-pointer" @click="() => {showDialog(props.item.title, props.item.text)}">{{ props.item.title }}</td>
            <td class="cursor-pointer" @click="() => {showDialog(props.item.title, props.item.text)}">{{ new Date(props.item.date).toLocaleDateString() }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                      v-on="on"
                      @click="showDialog(props.item.title, props.item.text)"
                  >remove_red_eye</v-icon>
                </template>
                <span>Подробнее</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>

    <v-dialog v-model="dialog" width="60%">
      <v-card-title class="text-h5 grey lighten-2">
        <h3>{{ dialogTitle }}</h3>
      </v-card-title>
      <v-card class="pa-3">
        <div v-html="dialogText"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mt-4" color="success" @click="() => {this.dialog = false}">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import store from '@/store'
import Vue from 'vue'
import { TextEditorModal } from '@/components/common'
import {
  getUpdatesNews,
} from '@/router/routes/parent'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'UpdatesNewsParent',
  components: {
    TextEditorModal
  },
  data() {
    return {
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Заголовок', value: 'title', sortable: false },
        { text: 'Дата', value: 'data', sortable: false },
        { text: 'Операции', value: 'data', sortable: false },
      ],
      title: 'Новости',
      items: [],
      dialog: false,
      dialogTitle: '',
      dialogText: '',
    }
  },
  mounted() {
    this.showLatestUpdate()
  },
  methods: {
    async showLatestUpdate() {
      this.dataTableLoaded = false
      this.items = []
      try {
        const response = await getUpdatesNews()
        this.items = response.data
        this.dataTableLoaded = true
      } catch (error) {
        // notify.error('Ошибка загрузки списка обновлений') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    showDialog(title, text) {
      this.dialogTitle = title
      this.dialogText = text
      this.dialog = true
    }
  }
}
</script>
