<script>
import {
  deleteBoardingSchoolsUser,
  getBoardingSchoolsUsers
} from '@/router/routes/superadmin'
import Table from '@/ui-components/Table.vue'
import ModalCreateBoardingSchoolUser from '@/components/moduleadmin/BoardingSchools/Users/conponents/ModalCreateUser.vue'
import getDisplayName from '../../../../helpers/getDisplayName'
import store from '@/store'

export default {
  name: 'BoardingSchoolsUsersView',
  components: { ModalCreateBoardingSchoolUser, Table },

  data() {
    return {
      title: 'Список пользователей Управления Школ-интернат',
      headers: [
        { text: 'Имя пользователя', value: 'username' },
        { text: 'Департамент', value: 'department' },
        { text: 'Управление Школой', value: 'boardingSchoolsDepartment' },
        { text: 'Школа', value: 'school' },
        { text: 'Активен', value: 'is_active' },
        { text: 'Действия', value: 'actions', align: 'right' }
      ],
      searchUserField: '',
      params: {},
      getBoardingSchoolsUsers
    }
  },
  methods: {
    getDisplayName,
    async handleDeleteUser(id) {
      try {
        await deleteBoardingSchoolsUser({ id: id })
        await this.$refs.usersTable.requestData()
      } catch (e) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async searchSUsers() {
      this.params.search = this.searchUserField
      this.$refs.usersTable.resetPagination()
      await this.$refs.usersTable.requestData()
    },
    async clearSearchField() {
      delete this.params?.search
      await this.$refs.usersTable.requestData()
    }
  }
}
</script>

<template>
  <v-container fluid class="fs-organizer-container">
    <v-layout class="fs-dish" row mb-4>
      <v-flex md9>
        <h1>{{ title }}</h1>
      </v-flex>
      <v-flex md3 text-md-right>
        <v-btn
          class="ma-0"
          color="primary"
          @click="$refs.modalCreateUser.openModal()"
          >Добавить пользователя</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout>
      <div class="d-flex align-center">
        <v-text-field
          label="Поиск"
          append-icon="search"
          clear-icon="clear"
          clearable
          v-model="searchUserField"
          @keyup.enter="searchSUsers"
          @click:append="searchSUsers"
          @click:clear="clearSearchField"
          hide-details
        ></v-text-field>
      </div>
    </v-layout>

    <v-layout class="fs-dish-result" mb-3>
      <v-flex>
        <Table
          ref="usersTable"
          :headers="headers"
          :request="getBoardingSchoolsUsers"
          :params="params"
          requestAfterMount
          showNumbering
        >
          <template v-slot:items="props">
            <td class="px-2">{{ props.item.username }}</td>
            <td class="px-2">
              {{ getDisplayName(props.item.department?.parent) }}
            </td>
            <td class="px-2">{{ getDisplayName(props.item.department) }}</td>
            <td class="px-2">
              {{ getDisplayName(props.item.department?.school) }}
            </td>
            <td class="px-2" :class="{ 'red--text': !props.item.is_active }">
              {{ props.item.is_active ? 'Да' : 'Нет' }}
            </td>
            <td class="justify-end align-center layout px-2" style="gap: 10px">
              <v-icon
                class="fs-active-icon px-2"
                @click="
                  $router.push({
                    name: 'BoardingSchoolsUserItemView',
                    params: { id: props.item.id, meta: 'view' }
                  })
                "
              >
                remove_red_eye
              </v-icon>
              <v-menu
                left
                light
                nudge-left="100"
                nudge-top="10"
                transition="slide-x-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">delete</v-icon>
                </template>
                <v-btn color="error" @click="handleDeleteUser(props.item.id)"
                  >Удалить?</v-btn
                >
              </v-menu>
            </td>
          </template>
        </Table>
      </v-flex>
    </v-layout>
    <ModalCreateBoardingSchoolUser
      ref="modalCreateUser"
      @update-table="() => $refs.usersTable.requestData()"
    />
  </v-container>
</template>

<style scoped></style>
