<template>
  <v-app class="parent-app">
    <SuperAdminReturn v-if="isSuperAdmin" />
    <div v-if="accept && !transferred">
      <main-menu
        v-if="$router.currentRoute.name !== 'PupilSelectionList'"
        :className="className"
        :toolbarItems="menuItems"
        :title="menuTitle"
        :is-child-view="isChildView"
      ></main-menu>
      <v-alert :value="!isValidAccount()" type="warning">
        Внимание! Образовательная организация, в которой учится ребёнок, не
        соответствует той, в которой вы сейчас находитесь. Пожалуйста, войдите
        под новой учётной записью
      </v-alert>
      <transition name="slide-fade">
        <router-view></router-view>
      </transition>
      <ConfirmModal
        v-if="notifications"
        :dialog="true"
        :width="1000"
        :hide-confirm-button="true"
        :cancel-btn-text="'Закрыть'"
        :btns-class="'fs-btn fs-parent-btn fs-btn--primary fs-form-footer__btn v-btn v-btn--flat theme--light'"
        @confirm="handlerConfirmModal"
      >
        <template v-slot:headline>Уведомления</template>
        <template v-slot:body>
          <v-list>
            <v-list-tile v-for="n in notifications" :key="n.id">
              <v-list-tile-content>
                <v-list-tile-title>{{ n.message }}</v-list-tile-title>
                <v-list-tile-sub-title>{{ n.date }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </template>
      </ConfirmModal>
    </div>
    <div v-else-if="!accept && !transferred">
      <v-container fluid>
        <v-layout class="fs-archive" row mb-3>
          <v-flex mb-3>
            <h1>{{ title }}</h1>
          </v-flex>
        </v-layout>
        <v-layout row justify-space-around>
          <v-flex md6>
            <v-card v-if="isChildView">
              Оферта не принята родителем, доступ запрещен
            </v-card>
            <v-card v-if="!isChildView">
              <v-card-text>
                <v-layout justify-center column>
                  <v-flex class="text-xs-center">
                    <h3>Оферта</h3>
                  </v-flex>
                  <v-flex v-if="ofertaText" mb-3 v-html="ofertaText"> </v-flex>
                  <v-flex v-else class="text-xs-center">
                    <v-progress-circular
                      indeterminate
                      :size="50"
                      :width="7"
                      color="green"
                    />
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions>
                <v-layout>
                  <v-flex text-md-right mb-3 v-if="showBtns">
                    <div v-if="showBaseBtnAgreement">
                      <v-btn
                        flat
                        class="fs-btn fs-parent-btn fs-btn--secondary"
                        @click="setAgreement(false)"
                        >Отмена</v-btn
                      >
                      <v-btn
                        flat
                        class="fs-btn fs-parent-btn fs-btn--primary"
                        @click="setAgreement(true)"
                        >Принять</v-btn
                      >
                    </div>
                    <v-btn
                      v-else
                      flat
                      class="fs-btn fs-parent-btn fs-btn--primary"
                      @click="returnToBaseState()"
                      >Передумал</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div v-else>
      <v-container fluid>
        <v-layout class="fs-archive" row mb-3>
          <v-flex mb-3>
            <h1>{{ transferTitle }}</h1>
          </v-flex>
        </v-layout>
        <v-layout row justify-space-around>
          <v-flex md6>
            <v-card>
              <v-card-text>
                Ваш организатор питания был изменён. Распечатайте заявление на
                возврат средств для получения их у организатора питания. Баланс
                будет обновлён.
                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions>
                <v-layout>
                  <v-btn
                    flat
                    class="fs-btn fs-parent-btn fs-btn--primary"
                    @click="downloadRefundOrder()"
                    >Скачать</v-btn
                  >
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <AlertUpdateInformation
      @closeDialogEvent="closeDialog()"
      :dialog="showAlertDialog"
      :block="blockDialog"
      :message="alertMessage"
    />

    <template v-if="setting('support_chat_plugin')">
      <v-chat
        v-if="$router.history.current.name !== 'PupilSelectionList'"
        theme="parents"
      />
    </template>
  </v-app>
</template>

<script>
import Vue from 'vue'
import MainMenu from '@/layouts/common/Main'
import MenuFactory from '@/factories/parent/menu'
import notifyPooling from '@/helpers/notifyPooling'
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import {
  signOffer,
  getOffer,
  getRefund,
  getAllStudents,
  closeNotifications
} from '@/router/routes/parent'
import _ from 'lodash'
import { getAllChildStudents } from '@/router/routes/pupil'
import ConfirmModal from '@/components/common/Modals/ConfirmModal'
import moment from 'moment'
import SuperAdminReturn from '@/components/common/SuperAdminReturn'
import AlertUpdateInformation from '@/components/common/Modals/AlertUpdateInformation'
import { getAlertsList } from '@/router/routes/alerts'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ParentIndex',
  components: {
    SuperAdminReturn,
    'main-menu': MainMenu,
    AlertUpdateInformation,
    ConfirmModal
  },
  props: {
    isChildView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menuItems: [],
      menuTitle: 'Главная',
      className: 'fs-toolbar-parent',
      title: 'Договор оферты',
      transferTitle: 'Организатор питания был изменен',
      accept: true,
      showBtns: false,
      showBaseBtnAgreement: true,
      ofertaText: '',
      ofertaTextCopy: '',
      transferred: false,
      notifications: null,
      isSuperAdmin: false,
      showContactModal: false,
      showAlertDialog: false,
      blockDialog: false,
      alertMessage: null
    }
  },
  computed: {
    ...mapGetters(['currentChild', 'currentUser', 'setting'])
  },
  created() {
    this.checkAlertInformation()
    this.fetchData()
    this.superAdminCheck()
  },
  methods: {
    ...mapMutations(['setShowPreloader']),
    superAdminCheck() {
      if (
        sessionStorage.getItem('superadmin_token') &&
        sessionStorage.getItem('superadmin_user_id')
      ) {
        this.isSuperAdmin = true
        return true
      } else return false
    },
    closeDialog() {
      this.showAlertDialog = false
    },
    async checkAlertInformation() {
      try {
        const response = await getAlertsList()
        const data = response.data
        const res = data.find(({ start_time, end_time }) => {
          const curDate = new Date(new Date().toISOString())
          return curDate > new Date(start_time) && curDate < new Date(end_time)
        })
        if (res !== undefined) {
          this.alertMessage = `
            <b>
              Внимание. На данный момент ведутся технические работы.
            <b> <br>
            Время окончания: ${new Date(res.end_time).toLocaleString()}`
          this.blockDialog = res.is_block
          this.showAlertDialog = true
        } else {
          const min = Math.min(
            ...data.map((item) => new Date(item.start_time).valueOf())
          )
          const res_ = data.filter(
            (item) => new Date(item.start_time).valueOf() === min
          )
          this.blockDialog = res_[0].is_block
          this.alertMessage = `${
            res_[0].message
          } <br><br><b>Планируемое время проведения работ: с ${new Date(
            res_[0].start_time
          ).toLocaleString()} по ${new Date(
            res_[0].end_time
          ).toLocaleString()}</b>`
          this.showAlertDialog = true
        }
      } catch (error) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    fetchData() {
      if (this.$route.params.reauth) {
        const method = this.isChildView ? getAllChildStudents : getAllStudents
        method().then((response) => {
          const {
            data: { data }
          } = response
          if (!this.$route.params.childId && data.length > 1) {
            this.$router.push({
              name: 'PupilSelectionList',
              params: { list: data }
            })
            return
          }

          const currentChild =
            data.length === 1
              ? data[0]
              : data?.find((item) => {
                  return item.id == this.$route.params.childId
                })

          store.commit('setCurrentChild', currentChild)
          this.storeInit().then(() => {
            if (
              currentChild.notifications &&
              currentChild.notifications.length
            ) {
              this.notifications = _.map(currentChild.notifications, (n) => {
                return {
                  ...n,
                  date: moment(n.created_at).format(Vue.config.format.date)
                }
              })
            }
          })
        })
      } else {
        const method = this.isChildView ? 'LOGIN_CHILD' : 'LOGIN'
        store
          .dispatch(method, {
            session_id: this.$route.query.session_id,
            child_id: +this.$route.query.selected_child_id
          })
          .then((init) => {
            if (init) {
              this.storeInit()
            }
          })
          .finally(() => store.commit('setShowPreloader', false))
      }
    },
    async handlerConfirmModal() {
      this.notifications = null
    },
    async storeInit() {
      try {
        await store.dispatch('GET_FOOD_PERIODS')
        await store.dispatch('GET_FOOD_TIMES')
        await store.dispatch('GET_AGE_CATEGORIES')
        await store.dispatch('GET_SHIFTS')
        await store.dispatch('GET_YOUR_SCHOOLCHILDREN')
        if (this.isChildView) {
          await store.dispatch('GET_CHILD_DISHES')
        } else {
          await store.dispatch('GET_DISHES', {
            id: this.currentChild.id
          })
        }

        await store.dispatch('GET_NOTIFICATIONS_COUNT')
        if (!this.isChildView) {
          notifyPooling()
        }

        this.accept =
          this.currentChild.isAgreementAccepted ||
          this.currentChild.isOfflineContractAccepted
        if (!this.accept && !this.isChildView) {
          this.getOfferRequest(this.currentChild.id)
        }

        // this.transferred = this.currentChild.isTransferred

        this.menuItems = new MenuFactory(
          this.currentChild.schoolClass.school.is_pupil_traffic_changeable,
          this.isChildView,
          store.getters.settings
        ).filter((i) => !i.disabled)
        if (this.isChildView) {
          this.menuItems = this.menuItems.filter(
            (i) => i.isParentOnly === undefined
          )
        }
      } catch (error) {
        notify.error(`Ошибка инициализации ${error}`) //pass
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    returnToBaseState() {
      this.showBaseBtnAgreement = true
      this.ofertaText = this.ofertaTextCopy
    },
    async getOfferRequest(id) {
      try {
        const response = await getOffer(id)
        const {
          data: {
            data: { text = '' }
          }
        } = response
        this.ofertaText = text
        this.ofertaTextCopy = text
        this.showBtns = true
      } catch (error) {
        // notify.error('Ошибка загрузки оферты') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async signOfferRequest() {
      try {
        const response = await signOffer(this.currentChild.id)

        if (response.data.success) {
          this.accept = true
          notify.success('Оферта успешно принята')
        }
      } catch (error) {
        // notify.error('Ошибка принятия оферты') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    setAgreement(value) {
      if (value) {
        this.signOfferRequest()
      } else {
        this.ofertaText =
          'Пожалуйста, обратитесь в школу для заключения письменного договора'
        this.showBaseBtnAgreement = false
        this.accept = value
      }
    },
    downloadRefundOrder() {
      getRefund(this.currentChild.id)
        .then((response) => {
          const file = new Blob([response.data], { type: response.data.type })

          const _a = document.createElement('a')
          _a.href = window.URL.createObjectURL(file)
          _a.download = 'Заявление на возврат средств.doc'
          _a.click()
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    isValidAccount() {
      let isValid = true
      const parent = store.getters.currentUser
      const child = store.getters.currentChild

      if (
        parent &&
        Vue.config.env.isHmao &&
        child &&
        +parent.school_id !== child.schoolClass.school.id &&
        +parent.school_id !== child.schoolClass.school.remote_id
      ) {
        isValid = false
      }

      return isValid
    }
  },
  watch: {
    $route(v) {
      if (v.params.childId || v.query.selected_child_id) {
        this.fetchData()
      }
    }
  }
}
</script>