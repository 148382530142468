<template>
  <v-container class="fs-teacher-container" fluid>
    <v-layout class="fs-complex" row mb-3>
      <div class="md4 mb-3" style="display: flex">
        <h1>{{ title }}</h1>
	      <context-help-modal :context-key="contextKeys.admin.teachers.list" />
      </div>
    </v-layout>
    <v-layout class="fs-teacher-order-result" mb-3>
      <v-flex>
        <v-data-table
          class="elevation-1 fs-admin-table"
          hide-actions
          item-key="name"
          :headers="headers"
          :items="items"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.classes }}</td>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.fullName }}</td>
              <td>
	              <v-icon :style="props.item.isCredit ? 'color: green' : 'color: red'">
		              {{ props.item.isCredit ? 'done' : 'close' }}
	              </v-icon>
              </td>
              <td>
	              <v-tooltip top>
		              <template v-slot:activator="{ on }">
			              <v-icon
				              v-on="on"
				              @click="$router.push({ name: 'AdminTeachersCard', params: { id: props.item.id }, query: { view: true } })"
			              >remove_red_eye</v-icon>
		              </template>
		              <span>Посмотреть</span>
	              </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
	                    class="ml-2"
                      v-on="on"
                      @click="$router.push({ name: 'AdminTeachersCard', params: { id: props.item.id } })"
                    >edit</v-icon>
                  </template>
                  <span>Изменить</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { getTeachers } from '@/router/routes/admin'
import { disableSorting } from '@/mixins/helpers'
import scrollable from '@/mixins/scrollable'
import store from '@/store'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"

export default {
  name: 'AdminTeachersIndex',
  components: { ContextHelpModal },
  mixins: [ disableSorting, scrollable ],
  created() {
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },
  destroyed() {
    this.removeScrollHandler()
  },
  data() {
    return {
      title: 'Список учителей',
      headers: [
        { text: 'Класс в руководстве', value: 'classes' },
        { text: '№ п/п', value: 'id' },
        { text: 'ФИО учителя', value: 'lastName' },
        { text: 'Может разрешать питаться в кредит', value: 'isCredit' },
        { text: 'Операции', value: 'operations', sortable: false }
      ],
      items: [],
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10),
	    contextKeys: Vue.config.contextHelp,
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    loadMore() {
      const payload = {
        schoolId: this.currentUser.school.id,
        params: {
          limit: Vue.config.general.limit,
          offset: this.offset
        }
      }
      getTeachers(payload)
        .then(response => {
          const {
            data: { data: items }
          } = response
          const itemsArray = items.map(item => ({
            ...item,
            classes: this.getClassesNames(item.schoolClasses)
          }))
          this.items = [ ...this.items, ...itemsArray ]
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    getClassesNames(classes) {
      const names = _.map(classes, item => {
        return item.name
      })
      return names.sort((a, b) =>
        this.getClassLiter(a).localeCompare(this.getClassLiter(b)))
        .sort((a, b) =>
          this.getClassNumber(a) - this.getClassNumber(b)
        ).join(', ')
    },
    getClassLiter(name) {
      return name.split(' ')[1]
    },
    getClassNumber(name) {
      return name.split(' ')[0]
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
