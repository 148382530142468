<template>
	<v-container>
		<v-layout
				class="fs-archive"
				row
				mb-3
		>
			<div class="mb-3 d-flex">
				<h1>{{ title }}</h1>
				<context-help-modal :context-key="contextKeys.admin.workingDays" />
			</div>
		</v-layout>
		<v-card>
			<v-card-title>
				<h2>ТАБЛИЦА РАБОЧИХ ДНЕЙ</h2>
				<v-spacer></v-spacer>
				<div>
					<v-btn
							color="primary"
							@click="showEditModal = true"
					>ИЗМЕНИТЬ</v-btn>
					<v-btn
							color="primary"
							@click="cancelWorkingDays"
					>ОТМЕНИТЬ ВЫБРАННЫЕ</v-btn>
				</div>
			</v-card-title>
			<v-card-text>
				<div class="mb-3" style="display: flex;">
					<div>
						<v-icon color="green">radio_button_checked</v-icon>
						<span>Рабочий день</span>
					</div>
					<div class="ml-3">
						<v-icon color="red">radio_button_unchecked</v-icon>
						<span>Нерабочий день</span>
					</div>
					<div class="ml-3">
						<v-icon color="orange">radio_button_checked</v-icon>
						<span>Рабочий день (изменен)</span>
					</div>
					<div class="ml-3">
						<v-icon color="orange">radio_button_unchecked</v-icon>
						<span>Нерабочий день (изменен)</span>
					</div>
					<v-spacer />
					<div>
						<v-btn icon color="primary">
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-icon
											v-on="on"
											@click="getHeaders('back')"
									>arrow_left</v-icon>
								</template>
								<span>Неделя назад</span>
							</v-tooltip>
						</v-btn>
						{{ startDate | prettyDate }} - {{ endDate | prettyDate }}
						<v-btn icon color="primary">
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-icon
											v-on="on"
											@click="getHeaders('forward')"
									>arrow_right</v-icon>
								</template>
								<span>Неделя вперед</span>
							</v-tooltip>
						</v-btn>
					</div>
				</div>
				<v-data-table
						:headers="headers"
						:items="items"
						hide-actions
						class="elevation-1"
						style="text-align-last: center"
				>

					<template v-slot:headers="props">
						<td v-for="head in props.headers" class="headers">
							<div>{{ head.date }}</div>
							<div>{{ head.text }}</div>
						</td>
					</template>

					<template v-slot:items="props">
						<tr>
							<td style="border-right: 1px solid rgba(0, 0, 0, 0.12)">{{ props.item.name }}</td>
							<td v-for="item in props.item.working_days.slice(start, end)">

								<div v-if="item.is_working_day && !item.is_edited">
									<v-tooltip top>
										<template v-slot:activator="{ on }">
											<v-icon
													:disabled="checkIfDisabled(item.date)"
													color="green"
													v-on="on"
													:class="item.is_selected ? 'outlined' : ''"
													@click="selectItem(item)"
											>radio_button_checked</v-icon>
										</template>
										<span>{{ item.edited_at !== null ? `Дата изменения: ${getPrettyDateTime(item.edited_at)} Автор: ${item.author}` : 'Не изменялся' }}</span>
									</v-tooltip>
								</div>

								<div v-else-if="!item.is_working_day && !item.is_edited">
									<v-tooltip top>
										<template v-slot:activator="{ on }">
											<v-icon
													:disabled="checkIfDisabled(item.date)"
													color="red"
													v-on="on"
													:class="item.is_selected ? 'outlined' : ''"
													@click="selectItem(item)"
											>radio_button_unchecked</v-icon>
										</template>
										<span>{{ item.edited_at !== null ? `Дата изменения: ${getPrettyDateTime(item.edited_at)} Автор: ${item.author}` : 'Не изменялся' }}</span>

									</v-tooltip>
								</div>

								<div v-else-if="item.is_working_day && item.is_edited">
									<v-tooltip top>
										<template v-slot:activator="{ on }">
											<v-icon
													:disabled="checkIfDisabled(item.date)"
													color="orange"
													v-on="on"
													:class="item.is_selected ? 'outlined' : ''"
													@click="selectItem(item)"
											>radio_button_checked</v-icon>
										</template>
										<span>{{ item.edited_at !== null ? `Дата изменения: ${getPrettyDateTime(item.edited_at)} Автор: ${item.author}` : 'Не изменялся' }}</span>
									</v-tooltip>
								</div>

								<div v-else-if="!item.is_working_day && item.is_edited">
									<v-tooltip top>
										<template v-slot:activator="{ on }">
											<v-icon
													:disabled="checkIfDisabled(item.date)"
													color="orange"
													v-on="on"
													:class="item.is_selected ? 'outlined' : ''"
													@click="selectItem(item)"
											>radio_button_unchecked</v-icon>
										</template>
										<span>{{ item.edited_at !== null ? `Дата изменения: ${getPrettyDateTime(item.edited_at)} Автор: ${item.author}` : 'Не изменялся' }}</span>
									</v-tooltip>
								</div>

								<div v-else>-</div>
							</td>
						</tr>
					</template>
				</v-data-table>
				<div class="mt-2" style="text-align-last: right">
					<v-btn icon color="primary">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-icon
										v-on="on"
										@click="getHeaders('back')"
								>arrow_left</v-icon>
							</template>
							<span>Неделя назад</span>
						</v-tooltip>
					</v-btn>
					{{ startDate | prettyDate }} - {{ endDate | prettyDate }}
					<v-btn icon color="primary">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-icon
										v-on="on"
										@click="getHeaders('forward')"
								>arrow_right</v-icon>
							</template>
							<span>Неделя вперед</span>
						</v-tooltip>
					</v-btn>
				</div>
			</v-card-text>
		</v-card>
		<AdminWorkingDaysEdit
				v-if="showEditModal"
				:showEditModal.sync="showEditModal"
				@reload="init()"
		/>
	</v-container>
</template>

<script>
import moment from "moment"
import { getWorkingDays, cancelWorkingDays } from "@/router/routes/admin"
import { getSchoolId } from "@/helpers/utils"
import { mapGetters } from "vuex"
import store from "@/store"
import AdminWorkingDaysEdit from "@/components/admin/WorkingDays/Edit.vue"
import Vue from "vue"
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'

export default {
	name: "AdminWorkingDays",
	components: { ContextHelpModal, AdminWorkingDaysEdit },
	data () {
		return {
			contextKeys: Vue.config.contextHelp,
			title: 'Рабочие дни',

			_headers: [],
			headers: [],

			dates: [],
			items: [],

			startDate: null,
			endDate: null,

			start: 0,
			end: 7,

			showEditModal: false
		}
	},
	computed: {
		...mapGetters([ 'currentUser' ]),
	},
	mounted() {
		this.init()
	},
	filters: {
		prettyDate(value) {
			const res = moment(value).format('DD.MM')
			if (res === 'Invalid date') return ''
			else return res
		}
	},
	methods: {
		getPrettyDateTime(value) {
			return moment(value).format('DD.MM.YYYY hh:mm')
		},
		async selectItem(item) {
			if (!item.is_edited) {
        notify.error('Нельзя выбрать неизмененный день') //pass
				return
			}
			item.is_selected = !item.is_selected
		},
		checkIfDisabled(value) {
			if (moment().isSame(value, 'day')) return false
			return !moment().isBefore(value)
		},
		async getHeaders(value) {
			if (value === 'back') {
				if (this.start <= 0) return
				this.start -= 7
				this.end -= 7
			} else if (value === 'forward') {
				if (this.end >= this._headers.length) return
				this.start += 7
				this.end += 7
			}
			const empty = [ { text: '', sortable: false, width: '10%' } ]
			this.headers = empty.concat(this._headers.slice(this.start, this.end))

			this.startDate = this.dates[this.start]
			this.endDate = this.dates[this.end - 1]
		},
		async init() {
			const payload = {
				id: getSchoolId(this.currentUser)
			}
			try {
				const response = await getWorkingDays(payload)
				this.dates = response.data.days
				this.items = response.data.data.map(i => {
					return {
						name: i.class_name,
						working_days: i.working_days.map(item => {
							return {
								id: item.id,
								date: item.date,
								author: item.edit_author,
								edited_at: item.edited_at,
								is_edited: item.is_edited,
								is_working_day: item.is_working_day,
								is_selected: false
							}
						})
					}
				})
				this._headers = this.dates.map(i => {
					moment.locale('RU')
					return {
						text: i.length ? moment(i).format('DD.MM') : '',
						date: i.length ? moment(i).format('dddd') : '',
						sortable: false
					}
				})
				await this.getHeaders()
			} catch (e) {

			} finally {
				store.commit('setShowPreloader', false)
			}
		},
		async cancelWorkingDays() {
			let temp = []
			this.items.map(i => {
				temp = temp.concat(i.working_days)
				return false
			})
			const id = temp.filter(i => i.is_selected).map(i => i.id)

			if (!id.length) {
        notify.error('Выберите дни') //pass
				return
			}

			const PAYLOAD = {
				schoolID: getSchoolId(this.currentUser),
				payload: {
					id: id
				}
			}

			try {
				await cancelWorkingDays(PAYLOAD)
        notify.success('Изменение успешно отменено')
				await this.init()
			} catch (error) {
        // notify.error('Произошла ошибка при отмене изменений') // backend handler added (exception_message)
			} finally {
				store.commit('setShowPreloader', false)
			}
		}
	}
}
</script>

<style scoped>
input[type="radio"]{
	height: 20px;
	width: 20px;
}
.v-icon.outlined {
	border: 2px solid grey;
	border-radius: 25%;
	height: 30px;
	width: 30px;
}
.headers {
	font-size: 12px;
	height: 56px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
