<template>
  <v-container class="fs-teacher-container" fluid grid-list-md>
    <v-layout class="fs-teacher-order-result" wrap>
      <v-flex xs12 mb-5>
        <h1>{{ title }}</h1>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers="studentsHeaders"
          :items="studentsItems"
          class="elevation-1 fs-admin-table"
          item-key="name"
          hide-actions
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.downAgeHorizont }}</td>
              <td>{{ props.item.upAgeHorizont }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'MunicipalityAgeCategories',
  data() {
    return {
      title: 'Справочник "Возрастные категории"',
      studentsHeaders: [
        { text: '№ п/п', value: 'id' },
        { text: 'Наименование', value: 'name' },
        { text: 'Нижняя возрастная граница', value: 'down-horizont' },
        { text: 'Верхняя возрастная граница', value: 'up-horizont' }
      ],
      studentsItems: [
        {
          id: 1,
          name: 'Начальная школа',
          downAgeHorizont: 6,
          upAgeHorizont: 10
        },
        {
          id: 2,
          name: 'Средняя школа',
          downAgeHorizont: 11,
          upAgeHorizont: 17
        }
      ]
    }
  }
}
</script>
