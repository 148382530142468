<template>
  <v-container
    fluid
    class="fs-admin-container"
  >
    <v-layout
      class="fs-complex"
      row
      mb-3
    >
      <v-flex
        md4
        mb-3
      >
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout
      class="fs-student-info"
      mb-3
      row
    >
      <v-flex
        md4
        offset-md2
        column
      >
        <v-card class="fs-fields-group--gray">
          <v-card-text>
            <v-flex mb-3>
              <span class="fs-student-info__key">ФИО:</span>
              <span class="fs-student-info__value">{{student.fullName}}</span>
            </v-flex>
            <v-flex mb-3>
              <span class="fs-student-info__key">Класс:</span>
              <span class="fs-student-info__value">{{student.schoolClass.name}}</span>
            </v-flex>
            <v-flex mb-3>
              <span class="fs-student-info__key">Лицевой счет:</span>
              <span class="fs-student-info__value">{{student.id}}</span>
            </v-flex>
            <v-flex mb-3>
              <span class="fs-student-info__key">Оферта принята:</span>
              <span class="fs-student-info__value">{{student.isAgreementAccepted? 'Да' :'Нет'}}</span>
            </v-flex>
            <v-flex mb-3>
              <span class="fs-student-info__key">Дата принятия оферты:</span>
              <span class="fs-student-info__value">{{student.agreementAcceptionDate || 'Нет'}}</span>
            </v-flex>
            <v-flex mb-3>
              <span class="fs-student-info__key">Бумажная оферта принята:</span>
              <span class="fs-student-info__value">{{student.isOfflineContractAccepted? 'Да' :'Нет'}}</span>
            </v-flex>
            <v-flex mb-3>
              <span class="fs-student-info__key">Дата принятия бумажной оферты:</span>
              <span class="fs-student-info__value">{{student.offlineContractAcceptionDate || 'Нет'}}</span>
            </v-flex>
            <v-flex mb-3>
              <v-flex md6>
                <v-btn
                  flat
                  block
                  class="fs-btn fs-admin-btn__show v-btn--active"
                  @click="showViewModal"
                >Движение денежных средств</v-btn>
              </v-flex>
            </v-flex>
            <v-card class="fs-fields-group--gray" v-if="!currentUser.roles.includes('DEPARTMENT')">
              <v-card-title>
                <h1>Печать квитанции</h1>
              </v-card-title>
              <v-card-text>
                <v-divider></v-divider>
                <v-layout mb-3 mt-3>
                  <v-text-field
                    v-model="foodDays"
                    mask="###"
                    label="Укажите количество дней пополнения"
                    @input="calculateFoodPeriod('days')"
                  ></v-text-field>
                </v-layout>
                <v-layout mb-3 mt-3>
                  <v-text-field
                    v-model="foodSum"
                    mask="#######"
                    label="Укажите сумму для пополнения"
                    @input="calculateFoodPeriod('sum')"
                  ></v-text-field>
                </v-layout>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions>
                <v-flex text-md-center>
                  <v-btn
                    class="fs-btn fs-teacher-btn__print"
                    flat
                    @click="print()"
                  >Печать</v-btn>
                </v-flex>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex
        column
        md4
      >
        <v-card class="fs-fields-group--gray">
          <v-card-text>
            <v-flex mb-3>
              <span class="fs-student-info__key">Тип питания:</span>
              <span class="fs-student-info__value">
                <p>{{student.foodType.name}}</p>
              </span>
            </v-flex>
            <v-flex mb-3>
              <span class="fs-student-info__key">Льгота:</span>
              <span class="fs-student-info__value">
                <p>{{student.concession.name}}</p>
              </span>
            </v-flex>
            <v-flex>
              <span class="fs-student-info__value">
                <v-checkbox
                  class="fs-checkbox"
                  v-model="student.isLongDay"
                  readonly
                  label="Ребенок в группе продленного дня"
                ></v-checkbox>
              </span>
            </v-flex>
            <v-flex>
              <span class="fs-student-info__value">
                <v-checkbox
                  class="fs-checkbox"
                  v-model="student.isCredit"
                  readonly
                  label="Ребенок питается в кредит"
                ></v-checkbox>
              </span>
            </v-flex>
            <v-flex v-if="municipalityView && ext_date_id > 0">
              <label for="ext_date">
                <b>Дата окончания действия категории</b>
              </label>
              <v-text-field
                id="ext_date"
                :loading="!ext_date_id"
                :value="ext_date"
                prepend-icon="event"
                readonly
              ></v-text-field>
            </v-flex>
          </v-card-text>
        </v-card>
        <v-card class="fs-fields-group--gray">
          <v-card-title>
            <h1>Баланс:</h1>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-layout
              mb-3
              mt-3
            >
              <v-flex>
                <span class="fs-student-info__key">Текущий баланс:</span>
              </v-flex>
              <v-flex text-md-right>
                <span class="fs-student-info__key">{{currentBalance || 0}} р.</span>
              </v-flex>
            </v-layout>
            <v-layout mb-3 style="display: none">
              <v-flex>
                <span class="fs-student-info__key">Зарезервировано на питание:</span>
              </v-flex>
              <v-flex text-md-right>
                <span class="fs-student-info__key">{{reservedBalance || 0}} р.</span>
              </v-flex>
            </v-layout>
            <v-layout mb-3 style="display: none">
              <v-flex>
                <span class="fs-student-info__key">Зарезервировано на доп. питание:</span>
              </v-flex>
              <v-flex text-md-right>
                <span class="fs-student-info__key">{{reservedAdditionalFoodBalance || 0}} р.</span>
              </v-flex>
            </v-layout>
            <v-layout mb-3 style="display: none">
              <v-flex>
                <span class="fs-student-info__key">Доступно для резерва:</span>
              </v-flex>
              <v-flex text-md-right>
                <span class="fs-student-info__key">{{availableBalance || 0}} р.</span>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex
        text-md-right
        pt-3
      >
        <v-btn
          flat
          class="fs-btn fs-admin-btn__show v-btn--active fs-form-footer__btn"
          @click="municipalityView ? $router.push({name: 'MunicipalityStudents',  params: { filters:savedFilters }}) : $router.push({name: 'AdminStudents',  params: { filters:savedFilters }})"
        >Назад</v-btn>
      </v-flex>
    </v-layout>
    <view-modal
      :dialog.sync="showBalanceMovingViewModal"
      :title="balanceMovingViewModalTitle"
      @scrollOnBottom="handlerScrollBottom"
    >
      <template v-slot:content>
        <v-layout class="fs-header-filter" wrap row>
          <v-flex md2>
            <DatePickersSmart
              :startDate.sync="dateStart"
              :endDate.sync="dateEnd"
              @changeDate="changeDate"
            />
          </v-flex>
          <v-flex md4>
            <v-text-field
              :value="accountId"
              label="Лицевой счёт:"
              readonly
            />
          </v-flex>
        </v-layout>
        <v-layout v-if="dataTableLoaded" class="fs-admin-archive-result" mb-3 justify-space-around>
          <v-flex>
            <v-data-table
              :headers="headers"
              :items="payloads"
              class="elevation-1 fs-admin-table"
              no-data-text="Транзакции отсутствуют"
              hide-actions
              item-key="name"
            >
              <template v-slot:items="props">
                <tr>
                  <td>{{ props.item.date }}</td>
                  <td>{{ props.item.sum }}</td>
                  <td>{{ props.item.balance }}</td>
                  <td>{{ props.item.id }}</td>
                  <td>{{ props.item.transactionCode }}</td>
                  <td>{{ props.item.name }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </template>
    </view-modal>
  </v-container>
</template>

<script>
import {
  getOneStudent,
  getPayments,
  printFileTaskId,
  getStudentExtPrivilege,
  getPrintFileStatus,
  getPrintFile, printInvoice
} from '@/router/routes/admin'
import {
  getOneStudent as getOneStudentDepartment,
  getPayments as getPaymentsDepartment
} from '@/router/routes/department'
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import AdminStudentFactory from '@/factories/admin/student-factory'
import numable from '@/mixins/balancable'
import moment from 'moment'
import Vue from 'vue'
import { DatePickersSmart } from '@/components/common'
import converter from '@/helpers/converter'
import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownload'
import ViewModal from "@/components/common/Modals/ViewModal"
import _ from "lodash"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'StudentView',
  mixins: [ numable ],
  components: {
    DatePickersSmart,
    'view-modal': ViewModal
  },
  data() {
    return {
      title: 'Карточка ученика',
      changeFoodTypeDialog: false,
      changeconcessionDialog: false,
      student: new AdminStudentFactory(),
      savedFilters: null,
      showBalanceMovingViewModal: false,
      headers: [
        { text: 'Дата и время операции', value: 'date' },
        { text: 'Сумма операции в рублях', value: 'sum' },
        { text: 'Баланс', value: 'balance' },
        { text: 'Лицевой счет ученика', value: 'id' },
        { text: 'Код транзакции', value: 'transactionCode' },
        { text: 'Имя ученика', value: 'name' }
      ],
      balanceMovingViewModalTitle: 'Движение денежных средств',
      dateStart: moment()
        .add(1, 'days')
        .format(Vue.config.format.serverDate),
      dateEnd: moment()
        .add(1, 'days')
        .format(Vue.config.format.serverDate),
      dataTableLoaded: false,
      payloads: [],
      LIMIT: Vue.config.general.limit,
      foodDays: 22,
      foodSum: 0,
      foodPrice: 0,
      ext_date: null,
      ext_date_id: null
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
    reservedBalance() {
      return this.convertToRubles(this.student.balance.reserved)
    },
    reservedAdditionalFoodBalance() {
      return this.convertToRubles(this.student.balance.reserved_additional_food)
    },
    availableBalance() {
      return this.convertToRubles(this.student.balance.available)
    },
    currentBalance() {
      return this.convertToRubles(this.student.balance.current)
    },
    startDatePython() {
      return moment(this.dateStart).format(Vue.config.format.pythonDate)
    },
    endDatePython() {
      return moment(this.dateEnd).format(Vue.config.format.pythonDate)
    },
    accountId() {
      return this.$route.params.id
    }
  },
  created() {
    getStudentExtPrivilege({ id: this.accountId }).then(res => {
      this.ext_date = res.data.ext_date
      this.ext_date_id = res.data.id
    }).finally(() => store.commit('setShowPreloader', false))

    if (this.$route.params.filters) {
      this.savedFilters = this.$route.params.filters
    }
    const payload = {
      schoolId: this.municipalitySchoolId ? this.municipalitySchoolId : this.currentUser.school.id,
      id: this.$route.params.id
    }
    console.log(payload)
    const method = this.municipalityView ? getOneStudentDepartment : getOneStudent
    method(payload)
      .then(response => {
        const student = response.data.data
        this.student = {
          id: student.id,
          fullName: student.fullName,
          concession: {
            id: _.get(student, 'privilege.id', null),
            name: _.get(student, 'privilege.name', '')
          },
          foodType: {
            id: _.get(student, 'foodType.id', null),
            name: _.get(student, 'foodType.name', '')
          },
          schoolClass: {
            id: _.get(student, 'schoolClass.id', null),
            name: _.get(student, 'schoolClass.name', '')
          },
          balance: {
            available: student.available,
            reserved: student.reserved,
            current: student.balance,
            reserved_additional_food: student.reserved_additional_food
          },
          foodOrganizer: {
            id: _.get(student, 'supplier.id', ''),
            name: _.get(student, 'supplier.name', '')
          },
          isLongDay: _.get(student, 'isLongDay', false),
          isCredit: _.get(student, 'isCredit', false),
          isAgreementAccepted: _.get(student, 'isAgreementAccepted', null),
          agreementAcceptionDate: _.get(student, 'agreementAcceptionDate', ''),
          isOfflineContractAccepted: _.get(
            student,
            'isOfflineContractAccepted',
            null
          ),
          offlineContractAcceptionDate: _.get(
            student,
            'offlineContractAcceptionDate',
            ''
          )
        }
        this.foodPrice = _.get(student, 'foodType.price', 8000) - _.get(student, 'privilege.price', 2000)
        this.calculateFoodPeriod('days')
      })
      .finally(() => store.commit('setShowPreloader', false))
    this.loadPayments()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    showViewModal() {
      this.showBalanceMovingViewModal = true
    },
    async print() {
      const payload = {
        id: _.get(this.currentUser, 'school_id', null),
        params: {
          student_id: this.student.id,
          days: this.foodDays,
          sum: this.convertToCents(this.foodSum)
        }
      }
      try {
        const response = await printInvoice(payload)
        const file = new Blob([ response.data ], { type: response.data.type })
        const url = window.URL.createObjectURL(file)
        const a = document.createElement('a')
        a.href = url
        a.download =
          'Квитанция на оплату от' +
          moment().format(Vue.config.format.date) +
          '.xlsx'
        a.click()
      } catch (e) {

      }

      store.commit('setShowPreloader', false)
    },
    calculateFoodPeriod(type) {
      if (type === 'days') {
        this.foodSum = Math.ceil(this.foodDays * this.convertToRubles(this.foodPrice))
        return
      }
      this.foodDays = parseInt(
        this.convertToCents(this.foodSum) / this.foodPrice
      )
    },
    async printFile() {
      const PAYLOAD = {
        school_id: this.currentUser.school_id,
        id: this.currentUser.user_id,
        data: {
          start_date: this.startDatePython,
          end_date: this.endDatePython,
          accountId: this.accountId,
          schoolClass: this.$route.params.filters.classId
        }
      }
      const callback = this.resetPageFileLoaded
      try {
        await asyncReqestsForDownload(
          printFileTaskId,
          getPrintFileStatus,
          getPrintFile,
          PAYLOAD,
          callback
        )
      } catch (error) {
        // notify.error('Ошибка загрузки файла') // backend handler added (exception_message)
      } finally {
        this.resetPageFileLoaded()
      }
    },
    async loadPayments() {
      try {
        const payload = {
          schoolId: this.municipalitySchoolId ? this.municipalitySchoolId : this.currentUser.school_id,
          params: {
            start_date: this.startDatePython,
            end_date: this.endDatePython,
            accountId: this.accountId,
            schoolClass: this.$route.params.filters.classId,
            limit: this.LIMIT,
            offset: this.offset
          }
        }
        const method = this.municipalityView ? getPaymentsDepartment : getPayments
        const response = await method(payload)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const {
            accountId = null,
            date = null,
            fullName = '',
            sum = null,
            transactionCode = null,
            balance = null
          } = item
          return {
            id: accountId,
            name: fullName,
            date: date,
            sum: converter(sum, 'pennyToRub'),
            transactionCode: transactionCode,
            balance: converter(balance, 'pennyToRub')
          }
        })
        this.payloads = [ ...this.payloads, ...itemsArray ]
        this.dataTableLoaded = true
      } catch (error) {
        // notify.error('Ошибка загрузки счетов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    resetData() {
      this.offset = 0
      this.payloads = []
      this.dataTableLoaded = false
    },
    changeDate() {
      this.resetData()
      this.loadPayments()
    },
    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
    },
    handlerScrollBottom() {
      this.offset += this.LIMIT
      if (this.offset <= this.payloads.length) {
        this.loadPayments()
      }
    }
  },
  props: {
    municipalityView: {
      type: Boolean,
      required: false,
      default: false
    },
    municipalitySchoolId: {
      type: Number,
      required: false,
      default: null
    },
  },
}
</script>
