<script>
import {getEsiaPreConnectKey} from "@/router/routes/organizer";
import store from "@/store";
import {mapGetters} from "vuex";

export default {
  name: 'EsiaConnectButton',
  props: ['isAuth'],
  computed: {
    ...mapGetters([ 'currentUser', 'setting' ]),
  },
  methods: {
    async doAuth() {
      // TODO: переделать на тестовый
      document.location.href = "https://xn--33-6kcadhwnl3cfdx.xn--p1ai/esia/vld_meal.php"
      store.commit('setShowPreloader', false)
    }
  },
}
</script>

<template>
<v-tooltip v-if="setting('esia_auth_supplier')">
  <template v-slot:activator="{ on }">
    <v-btn color="blue darken-2" @click="doAuth">
      <img src="@/assets/img/gosuslugi.png" width="30px"/>
      <span class="color-blue ml-2">Войти через Госуслуги</span>
    </v-btn>
  </template>
</v-tooltip>
</template>

<style scoped>
.color-blue {
  color: #f3f5ff;
}
</style>