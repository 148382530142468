<template>
  <v-dialog v-model="show" persistent>
    <v-container class="fs-parent-container" fluid>
      <v-layout v-if="hasInfoAboutComplects" column>
        <v-flex v-show="getDishes(breakfast).length" mb-4>
          <h2>Завтрак</h2>
          <v-data-table
            :headers="headers"
            :items="getDishes(breakfast)"
            class="fs-archive-result"
            hide-actions
            no-data-text="отсутствует"
            item-key="name"
          >
            <template v-slot:items="props">
              <tr>
                <td :style="props.item.name == 'Итого' ? 'font-weight: bold;' : ''">{{ props.item.name }}</td>
                <td>{{ props.item.weightPrimary }}</td>
                <td>{{ props.item.weightMiddle }}</td>
                <td>{{ props.item.energy }}</td>
                <td>{{ props.item.protein }}</td>
                <td>{{ props.item.fats }}</td>
                <td>{{ props.item.carbs }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
        <v-flex v-show="getDishes(dinner).length" mb-4>
          <h2>Обед</h2>
          <v-data-table
            :headers="headers"
            :items="getDishes(dinner)"
            class="fs-archive-result"
            hide-actions
            no-data-text="отсутствует"
            item-key="name"
          >
            <template v-slot:items="props">
              <tr>
                <td :style="props.item.name == 'Итого' ? 'font-weight: bold;' : ''">{{ props.item.name }}</td>
                <td>{{ props.item.weightPrimary }}</td>
                <td>{{ props.item.weightMiddle }}</td>
                <td>{{ props.item.energy }}</td>
                <td>{{ props.item.protein }}</td>
                <td>{{ props.item.fats }}</td>
                <td>{{ props.item.carbs }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
        <v-flex v-show="getDishes(afternoonSnack).length" mb-4>
          <h2>Полдник</h2>
          <v-data-table
            :headers="headers"
            :items="getDishes(afternoonSnack)"
            class="fs-archive-result"
            hide-actions
            no-data-text="отсутствует"
            item-key="name"
          >
            <template v-slot:items="props">
              <tr>
                <td :style="props.item.name == 'Итого' ? 'font-weight: bold;' : ''">{{ props.item.name }}</td>
                <td>{{ props.item.weightPrimary }}</td>
                <td>{{ props.item.weightMiddle }}</td>
                <td>{{ props.item.energy }}</td>
                <td>{{ props.item.protein }}</td>
                <td>{{ props.item.fats }}</td>
                <td>{{ props.item.carbs }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
        <v-flex v-show="additionalFoods.length" mb-4>
          <h2>Дополнительное питание</h2>
          <v-data-table
            :headers="headers"
            :items="additionalFoods"
            class="fs-archive-result"
            hide-actions
            no-data-text="отсутствует дополнительное питание"
            item-key="name"
          >
            <template v-slot:items="props">
              <tr>
                <td>{{ props.item.food.name }}</td>
                <td>{{ props.item.food.weight_primary }}</td>
                <td>{{ props.item.food.weight_middle }}</td>
                <td>{{ props.item.food.energy_value }}</td>
                <td>{{ props.item.food.protein }}</td>
                <td>{{ props.item.food.fat }}</td>
                <td>{{ props.item.food.carbohydrates }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-layout v-else>
        <h3>Информация о комплексе отсутствует</h3>
      </v-layout>
      <v-flex text-md-right mt-3 mr-2>
        <v-btn
          class="fs-btn fs-parent-btn fs-btn--secondary fs-form-footer__btn"
          flat
          @click="show = false"
        >Закрыть</v-btn>
      </v-flex>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import _ from 'lodash'

export default {
  name: 'OrderModal',
  props: {
    complexes: {
      required: true
    },
    selectedComplex: {
      required: true
    },
    additionalFoods: {
      type: Array,
      default: function() {
        return []
      },
      required: false
    },
    value: {
      type: Boolean
    }
  },
  data() {
    return {
      breakfast: _.get(_.find(this.findComplex(), item => {
        return item.mealPeriod == Vue.config.general.static.breakfast
      }), 'foods', []),
      dinner: _.get(_.find(this.findComplex(), item => {
        return item.mealPeriod == Vue.config.general.static.dinner
      }), 'foods', []),
      afternoonSnack: _.get(_.find(this.findComplex(), item => {
        return item.mealPeriod == Vue.config.general.static.afternoonSnack
      }), 'foods', []),
      headers: [
        { text: 'Наименование', value: 'name', sortable: false },
        { text: 'Выход блюда (7-11 лет), г', value: 'out1', sortable: false },
        { text: 'Выход блюда (12 лет и старше), г', value: 'out2', sortable: false },
        { text: 'Энергетическая ценность, кКал/кДж (на 100 г)', value: 'energy', sortable: false },
        { text: 'Белки, г (на 100 г)', value: 'protein', sortable: false },
        { text: 'Жиры, г (на 100 г)', value: 'fats', sortable: false },
        { text: 'Углеводы, г (на 100 г)', value: 'carbs', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters([ 'dishes' ]),
    hasInfoAboutComplects() {
      return (
        this.getDishes(this.breakfast).length ||
        this.getDishes(this.dinner).length ||
        this.getDishes(this.afternoonSnack).length ||
        (this.additionalFoods && this.additionalFoods.length)
      )
    },
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  created() {
    this.findDishesIds()
  },
  methods: {
    getDishes(dishesIds) {
      const dishes = this.dishes.filter(item => {
        return dishesIds.includes(item.id)
      })
      if (dishes.length) {
        const res = {}
        dishes.forEach(d => {
          [ 'weightPrimary', 'weightMiddle', 'energy', 'protein', 'fats', 'carbs' ].forEach(key => {
            if (!res[key]) res[key] = 0
            res[key] += +d[key]
          })
        })

        // Убираем проблемы с подсчетом float значений
        Object.keys(res).forEach(item => {
          if (res[item] % 1 === 0) res[item] = res[item]
          else res[item] = res[item].toFixed(2)
        })

        dishes.push({ name: "Итого", ...res })
      }
      return dishes
    },
    findComplex() {
      if (!this.selectedComplex) return {}
      const complex = _.find(this.complexes, complex => {
        return complex.id == this.selectedComplex
      })
      if (complex) {
        return _.get(complex, 'fp', null)
      }
    },
    findDishesIds() {
      this.breakfast = _.get(_.find(this.findComplex(), item => {
        return item.mealPeriod == Vue.config.general.static.breakfast
      }), 'foods', [])
      this.dinner = _.get(_.find(this.findComplex(), item => {
        return item.mealPeriod == Vue.config.general.static.dinner
      }), 'foods', [])
      this.afternoonSnack = _.get(
        _.find(
          this.findComplex(),
          item => item.mealPeriod == Vue.config.general.static.afternoonSnack
        ),
        'foods',
        []
      )
    }
  },
  watch: {
    selectedComplex() {
      this.findDishesIds()
    }
  }
}
</script>
