<template>
  <v-dialog v-model="showModal" max-width="700px" persistent>
    <v-card>
      <v-card-title class="fs-dish" row>
        <div class="mt-4 mb-2 title-help">
          <div class="d-flex row">
            <h3 class="ml-3 mr-3 mt-1 mb-1">{{ reportTitle }}</h3>
          </div>
        </div>
      </v-card-title>
      <v-card-text class="px-4 py-2 card__fields">
        <v-form ref="formReports" v-model="isValid">
          <v-flex xs12 md12 v-if="[4, 1, 2].includes(selected_role)">
            <v-select
              v-model="selectedItems.department_id"
              :rules="[rules.required({ label: 'Выберите муниципалитет' })]"
              :items="departments"
              label="Выберите муниципалитет"
              :item-text="getDisplayName"
              item-value="id"
              dense
              outlined
              @change="changeDepartment($event)"
            ></v-select>
          </v-flex>

          <v-flex xs12 md12 v-if="selected_role === 6">
            <v-select
              v-model="selectedItems.supplier_id"
              :rules="[rules.required({ label: 'Выберите ОП' })]"
              :items="suppliers"
              item-text="username"
              item-value="id"
              label="Выберите ОП"
              dense
              outlined
              @change="changeSupplier($event)"
            ></v-select>
          </v-flex>

          <v-flex xs12 md12 v-if="[1, 2].includes(selected_role)">
            <v-select
              v-model="selectedItems.school_id"
              :items="schools"
              :rules="[rules.required({ label: 'Выберите школу' })]"
              :disabled="!selectedItems.department_id"
              label="Выберите школу"
              :item-text="getDisplayName"
              item-value="id"
              dense
              outlined
              @change="changeSchool($event)"
            ></v-select>
          </v-flex>

          <v-flex xs12 md12 v-if="selected_role === 2">
            <v-select
              v-model="selectedItems.school_class_id"
              :rules="[rules.required({ label: 'Выберите класс' })]"
              :items="classes"
              :disabled="!selectedItems.school_id"
              label="Выберите класс"
              :item-text="getDisplayName"
              item-value="id"
              dense
              outlined
              @change="changeSchoolClass($event)"
            ></v-select>
          </v-flex>

          <v-flex v-if="selection.multiple_department_choice">
            <v-select
              no-data-text="Ничего не выбрано"
              v-model="selectedItems.department_ids"
              :rules="[rules.required({ label: 'Выберите муниципалитеты' })]"
              :item-text="getDisplayName"
              item-value="id"
              :items="departments"
              deletable-chips
              chips
              multiple
              placeholder="Выберите муниципалитеты"
              @change="loadSchools"
            >
              <template v-slot:prepend-item>
                <v-list-tile
                  ripple
                  @click="
                    selectAll('department_ids', 'departments', 'loadSchools')
                  "
                >
                  <v-list-tile-action>
                    <v-icon
                      :color="
                        selectedItems.department_ids.length > 0 ? 'primary' : ''
                      "
                    >
                      {{
                        selectedItems.department_ids.length ===
                        departments.length
                          ? 'check_box'
                          : 'check_box_outline_blank'
                      }}
                    </v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Выбрать всё</v-list-tile-title>
                    <v-list-tile-sub-title
                      >Выбрано:
                      {{
                        selectedItems.department_ids.length
                      }}</v-list-tile-sub-title
                    >
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="data">
                <v-chip
                  close
                  @input="remove(data.item, selectedItems.department_ids)"
                  class="v-chip-wrap"
                  small
                >
                  <span class="chip-wrap">
                    {{ getDisplayName(data.item) }}
                  </span>
                </v-chip>
              </template>
            </v-select>
          </v-flex>

          <v-flex v-if="selection.department_choice">
            <v-select
              no-data-text="Ничего не выбрано"
              v-model="selectedItems.department_id"
              :rules="[rules.required({ label: 'Выберите муниципалитет' })]"
              :item-text="getDisplayName"
              item-value="id"
              :items="departments"
              deletable-chips
              chips
              placeholder="Выберите муниципалитет"
              @change="loadSchools"
            >
              <template v-slot:selection="data">
                <v-chip
                  close
                  @input="remove(data.item, selectedItems.department_id)"
                  class="v-chip-wrap"
                  small
                >
                  <span class="chip-wrap">
                    {{ getDisplayName(data.item) }}
                  </span>
                </v-chip>
              </template>
            </v-select>
          </v-flex>

          <v-flex v-if="selection.school_choice">
            <v-select
              v-model="selectedItems.school_id"
              :rules="[rules.required({ label: 'Выберите школу' })]"
              :items="schools"
              :disabled="
                !selectedItems.department_id && !selectedItems.supplier_id
              "
              label="Выберите школу"
              :item-text="getDisplayName"
              item-value="id"
              dense
              outlined
              @change="changeSchool($event)"
            ></v-select>
          </v-flex>

          <v-flex v-if="selection.multiple_supplier_choice">
            <v-select
              no-data-text="Ничего не выбрано"
              v-model="selectedItems.supplier_ids"
              :rules="[rules.required({ label: 'Выберите ОП' })]"
              :item-text="getDisplayName"
              item-value="id"
              :items="suppliers"
              deletable-chips
              chips
              multiple
              placeholder="Выберите ОП"
            >
              <template v-slot:prepend-item>
                <v-list-tile
                  ripple
                  @click="selectAll('supplier_ids', 'suppliers')"
                >
                  <v-list-tile-action>
                    <v-icon
                      :color="
                        selectedItems.supplier_ids.length > 0 ? 'primary' : ''
                      "
                    >
                      {{
                        selectedItems.supplier_ids.length === suppliers.length
                          ? 'check_box'
                          : 'check_box_outline_blank'
                      }}
                    </v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Выбрать всё</v-list-tile-title>
                    <v-list-tile-sub-title
                      >Выбрано:
                      {{
                        selectedItems.supplier_ids.length
                      }}</v-list-tile-sub-title
                    >
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider class="mt-2"></v-divider>
              </template>

              <template v-slot:selection="data">
                <v-chip
                  close
                  @input="remove(data.item, selectedItems.supplier_ids)"
                  class="v-chip-wrap"
                  small
                >
                  <span class="chip-wrap">
                    {{ getDisplayName(data.item) }}
                  </span>
                </v-chip>
              </template>
            </v-select>
          </v-flex>

          <v-flex
            v-if="selection.multiple_school_choice && selected_role !== 1"
          >
            <v-select
              no-data-text="Ничего не выбрано"
              v-model="selectedItems.school_ids"
              :rules="[rules.required({ label: 'Выберите школы' })]"
              :item-text="getDisplayName"
              item-value="id"
              :items="schools"
              deletable-chips
              chips
              multiple
              placeholder="Выберите школы"
              clerable
            >
              <template v-slot:prepend-item>
                <v-list-tile ripple @click="selectAll('school_ids', 'schools')">
                  <v-list-tile-action>
                    <v-icon
                      :color="
                        selectedItems.school_ids.length > 0 ? 'primary' : ''
                      "
                    >
                      {{
                        selectedItems.school_ids.length === schools.length
                          ? 'check_box'
                          : 'check_box_outline_blank'
                      }}
                    </v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Выбрать всё</v-list-tile-title>
                    <v-list-tile-sub-title
                      >Выбрано:
                      {{
                        selectedItems.school_ids.length
                      }}</v-list-tile-sub-title
                    >
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider class="mt-2"></v-divider>
              </template>

              <template v-slot:selection="data">
                <v-chip
                  close
                  @input="remove(data.item, selectedItems.school_ids)"
                  class="v-chip-wrap"
                  small
                >
                  <span class="chip-wrap">
                    {{ getDisplayName(data.item) }}
                  </span>
                </v-chip>
              </template>
            </v-select>
          </v-flex>

          <v-flex
            v-if="selection.multiple_house_choice && housings?.length > 0"
          >
            <v-select
              v-model="selectedItems.house_ids"
              :rules="[rules.required({ label: 'Выберите Корпуса' })]"
              :items="housings"
              :item-text="getDisplayName"
              item-value="id"
              attach
              chips
              deletable-chips
              multiple
              :label="!darkTheme ? 'Выберите Корпуса' : ''"
              :placeholder="darkTheme ? 'Выберите Корпуса' : ''"
              :outline="darkTheme"
              :class="{ 'fs-filter': !darkTheme }"
            >
              <template v-slot:prepend-item>
                <v-list-tile ripple @click="selectAll('house_ids', 'housings')">
                  <v-list-tile-action>
                    <v-icon
                      :color="
                        selectedItems.house_ids.length > 0 ? 'primary' : ''
                      "
                    >
                      {{
                        selectedItems.house_ids.length === housings.length
                          ? 'check_box'
                          : 'check_box_outline_blank'
                      }}
                    </v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Выбрать всё</v-list-tile-title>
                    <v-list-tile-sub-title
                      >Выбрано:
                      {{
                        selectedItems.house_ids.length
                      }}</v-list-tile-sub-title
                    >
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-flex>

          <v-flex v-if="selection.multiple_school_class_choice">
            <v-select
              v-model="selectedItems.school_class_ids"
              :rules="[rules.required({ label: 'Выберите классы' })]"
              :items="classes"
              :item-text="getDisplayName"
              item-value="id"
              attach
              chips
              deletable-chips
              multiple
              :label="!darkTheme ? 'Выберите классы' : ''"
              :placeholder="darkTheme ? 'Выберите классы' : ''"
              :outline="darkTheme"
              :class="{ 'fs-filter': !darkTheme }"
            >
              <template v-slot:prepend-item>
                <v-list-tile
                  ripple
                  @click="selectAll('school_class_ids', 'classes')"
                >
                  <v-list-tile-action>
                    <v-icon
                      :color="
                        selectedItems.school_class_ids.length > 0
                          ? 'primary'
                          : ''
                      "
                    >
                      {{
                        selectedItems.school_class_ids.length === classes.length
                          ? 'check_box'
                          : 'check_box_outline_blank'
                      }}
                    </v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Выбрать всё</v-list-tile-title>
                    <v-list-tile-sub-title
                      >Выбрано:
                      {{
                        selectedItems.school_class_ids.length
                      }}</v-list-tile-sub-title
                    >
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-flex>

          <v-flex>
            <div v-if="date_selection.end_date">
              <DatePickersSmart
                :startDate.sync="selectedItems.start_date"
                :endDate.sync="selectedItems.end_date"
              />
            </div>

            <div
              v-else-if="date_selection.start_date && !date_selection.end_date"
            >
              <DatePickersSmart
                :startDate.sync="
                  date_selection.start_date
                    ? selectedItems.start_date
                    : selectedItems.date
                "
                onePicker
              />
            </div>

            <div v-else-if="date_selection.date">
              <DatePickersSmart
                :startDate.sync="selectedItems.date"
                onePicker
              />
            </div>

            <div v-else-if="date_selection.year">
              <v-select
                v-model="selectedItems.year"
                :items="yearList"
                menu-props="auto"
                label="Год"
                hide-details
                prepend-icon="event"
                single-line
                :rules="[rules.required({ label: 'Выберите Год' })]"
              ></v-select>
            </div>
          </v-flex>
        </v-form>
      </v-card-text>
      <v-card-actions
        class="d-flex justify-content-end align-items-center flex-wrap px-4 py-4"
        :style="{ gap: '16px' }"
      >
        <v-btn class="meal_admin-btn" color="#FF6347" @click="closeModal"
          >Закрыть</v-btn
        >
        <v-btn
          class="meal_admin-btn"
          color="#69F0AE"
          @click="downloadFile"
          :disabled="!isValid || isLoading"
          >Сформировать отчёт</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import { mapGetters } from 'vuex'
import navigateTo from '@/mixins/navigateTo'
import asyncReportDownload from '@/helpers/asyncReportDownload.js'
import notify from '@/utils/notifications/toastNotification.vue'
import { DatePickersSmart } from '@/components/common'
import moment from 'moment/moment'
import {
  getClasses,
  getDepartments,
  getOrganizersPure,
  getReportFileDownload,
  getReportFileStatus,
  getReportFileTaskId,
  getSchools,
  getSchoolsByDepartment,
  getSchoolsBySupplier
} from '@/router/routes/superadmin'
import { getOrganizersByDepartments } from '@/router/routes/department'
import getDisplayName from '@/helpers/getDisplayName'
import rulesCustom from '@/helpers/rulesCustom'

export default {
  name: 'ModalReports',
  components: { DatePickersSmart },
  mixins: [navigateTo],
  props: {
    item: Object
  },
  data() {
    return {
      rules: rulesCustom,
      isValid: false,
      darkTheme: Vue.config.env.darkTheme,
      key: '',
      task_name: '',
      showModal: false,
      reportTitle: '',
      datePickers: null,
      datePickerRange: '',
      datepickerMenu: false,

      yearSelector: null,
      dateStart: null,
      dateEnd: null,

      selected_role: null,

      selectedItems: {
        role_id: null,

        department_id: null,
        supplier_id: null,
        school_id: null,
        school_class_id: null,

        department_ids: [],
        supplier_ids: [],
        school_ids: [],
        school_class_ids: [],
        house_ids: [],

        start_date: null,
        end_date: null,
        date: null,
        year: null
      },

      selection: {
        multiple_department_choice: false,
        department_choice: false,
        multiple_supplier_choice: false,
        multiple_school_choice: false,
        multiple_school_class_choice: false,
        school_choice: false,
        school_class_choice: false,
        multiple_house_choice: false
      },

      date_selection: {
        start_date: false,
        end_date: false,
        date: false,
        year: false
      },

      departments: [],
      suppliers: [],
      all_schools: [],
      schools: [],
      classes: [],
      housings: [],

      params: [],
      isLoadingDepartments: false,
      isLoadingOrganizersUsers: false,
      isLoadingOrganizers: false,
      isLoadingSchools: false,
      isLoadingClasses: false
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    yearList() {
      return Array.from({ length: moment().format('YYYY') - 1999 }, (v, i) =>
        (moment().format('YYYY') - i).toString()
      )
    },

    isLoading() {
      return (
        this.isLoadingDepartments ||
        this.isLoadingOrganizersUsers ||
        this.isLoadingOrganizers ||
        this.isLoadingSchools ||
        this.isLoadingClasses
      )
    }
  },

  async mounted() {
    if (this.item) {
      this.selected_role = this.item.selected_role
      this.selectedItems.role_id = this.item.role_id
      this.selectedItems.report_key = this.item.report_key
      this.showReportModal(this.item)
      await this.init()
    }
  },

  methods: {
    getDisplayName,

    remove(item, arr) {
      const index = arr.indexOf(item.id)
      if (index >= 0) arr.splice(index, 1)
    },
    async init() {
      await this.getDepartments()
      await this.getOrganizersUsers()
      await this.getSchools()
    },

    selectAll(selectArray, parentArray, action = null) {
      if (this.selectedItems[selectArray].length) {
        this.selectedItems[selectArray] = []
      } else {
        const idsArray = this[parentArray].map((item) => item.id)
        this.selectedItems[selectArray] = [...idsArray]
      }
      if (action === 'loadSchools') this.loadSchools()
      else if (action === 'loadOrganizers') this.loadOrganizers()
    },

    closeModal() {
      this.$emit('close')
    },

    resetPageFileLoaded() {
      this.closeModal()
      store.commit('setShowPreloader', false)
    },

    showReportModal(item) {
      // Записанные селекторы
      if (this.selected_role in item.object_selection) {
        Object.entries(item.object_selection[this.selected_role]).forEach(
          ([k, v]) => {
            this.selection[k] = v
          }
        )
      }

      this.params = item.params[this.selected_role] || []

      Object.keys(this.date_selection).forEach((k) => {
        this.date_selection[k] = this.params.some((item) => item === k)
      })

      this.setDatePickersValue()

      this.reportTitle =
        item.name + (item.form_id ? ` (Форма ${item.form_id})` : '')

      this.task_name = item.task_name
      this.key = item.key
      this.showModal = true
    },

    setDatePickersValue() {
      const defaultRange = this.item.range ?? 'month'

      const { end_date } = this.date_selection
      if (end_date) {
        this.selectedItems.start_date = moment()
          .add(defaultRange, -1)
          .format(Vue.config.format.serverDate)
        this.selectedItems.end_date = moment()
          .add('days', 1)
          .format(Vue.config.format.serverDate)
      } else {
        this.selectedItems.start_date = moment().format(
          Vue.config.format.serverDate
        )
        this.selectedItems.date = moment().format(Vue.config.format.serverDate)
      }
    },

    async getDepartments() {
      this.isLoadingDepartments = true
      try {
        const {
          data: { data: departments }
        } = await getDepartments()
        this.departments = departments
      } catch (error) {
      } finally {
        store.commit('setShowPreloader', false)
        this.isLoadingDepartments = false
      }
    },

    async getOrganizersUsers() {
      this.isLoadingOrganizersUsers = true
      try {
        const { data: suppliers } = await getOrganizersPure()
        this.suppliers = suppliers
      } catch (error) {
      } finally {
        store.commit('setShowPreloader', false)
        this.isLoadingOrganizersUsers = false
      }
    },

    async loadOrganizers() {
      this.isLoadingOrganizers = true
      if (!this.selectedItems.department_ids.length) {
        this.selectedOrganizers = []
        return
      }
      try {
        const payload = {
          params: {
            department_ids: this.selectedItems.department_ids.toString()
          }
        }
        const {
          data: { data: items }
        } = await getOrganizersByDepartments(payload)
        const itemsArray = items.map((item) => ({
          id: item.id,
          name: item.shortName
        }))
        this.suppliers = [...itemsArray]
      } catch (error) {
      } finally {
        this.$store.commit('setShowPreloader', false)
        this.isLoadingOrganizers = false
      }
    },

    async getSchools() {
      if (this.selectedItems.department_id) {
        await this.getSchoolsByDepartment()
      } else {
        await this.getAllSchools()
      }
    },

    loadSchools() {
      const departmentsValues = this.departments.filter((item) =>
        this.selectedItems.department_ids.includes(item.id)
      )
      this.schools = this.all_schools.filter((school) => {
        return departmentsValues.find(
          (department) => department.shortName === school.department
        )
      })
    },

    async getAllSchools() {
      this.isLoadingSchools = true
      try {
        const {
          data: { data: schools }
        } = await getSchools()
        this.all_schools = schools
      } catch (e) {
        notify.error(e.response.data.message)
      } finally {
        store.commit('setShowPreloader', false)
        this.isLoadingSchools = false
      }
    },

    async getSchoolsByDepartment() {
      this.isLoadingSchools = true
      const { department_id } = this.selectedItems
      try {
        if (department_id) {
          const {
            data: { data: schools }
          } = await getSchoolsByDepartment(department_id)
          this.schools = schools
        }
      } catch (e) {
        notify.error(e.response.data.message)
      } finally {
        store.commit('setShowPreloader', false)
        this.isLoadingSchools = false
      }
    },

    async getSchoolsBySupplier(id) {
      this.isLoadingSchools = true
      try {
        const { data } = await getSchoolsBySupplier(id)
        data.forEach((item) => {
          this.schools.push({
            id: item.id,
            name: item.short_name
          })
        })
      } catch (e) {
        notify.error(e.response.data.message)
      }
      store.commit('setShowPreloader', false)
      this.isLoadingSchools = false
    },

    async getClasses() {
      this.isLoadingClasses = true
      const { school_id } = this.selectedItems
      try {
        const payload = {
          school_id: school_id
        }
        const response = await getClasses(payload)
        const {
          data: { data: classes }
        } = response
        this.classes = classes
      } catch (e) {
        notify.error(e.response.data.message)
      } finally {
        store.commit('setShowPreloader', false)
        this.isLoadingClasses = false
      }
    },

    async changeDepartment(department_id) {
      this.selectedItems.department_id = department_id
      this.selectedItems.school_id = null
      this.selectedItems.school_class_id = null
      this.schools = []
      this.classes = []
      await this.getSchools()
    },

    async changeSupplier(id) {
      this.selectedItems.school_id = null
      this.selectedItems.school_class_id = null
      this.schools = []
      this.classes = []
      await this.getSchoolsBySupplier(id)
    },

    async changeSchool(school_id) {
      this.selectedItems.school_id = school_id
      this.housings = this.schools.find((i) => i.id === school_id)?.housings
      this.selectedItems.school_class_id = null
      await this.getClasses()
    },

    changeSchoolClass(id) {
      this.selectedItems.school_class_id = id
    },

    changeDate(payload) {},

    async downloadFile() {
      const payload = {
        key: this.key,
        task_name: this.task_name,
        params: {}
      }

      Object.values(this.params).forEach((v) => {
        payload.params[v] = this.selectedItems[v]
      })

      // // временный костыль
      // тест
      console.log('-----------------------------------')
      console.log(payload)
      const callback = this.resetPageFileLoaded
      try {
        await asyncReportDownload(
          getReportFileTaskId,
          getReportFileStatus,
          getReportFileDownload,
          payload,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки файла')
      } finally {
        this.resetPageFileLoaded()
        this.$refs.formReports.resetValidation()
      }
    }
  }
}
</script>

<style lang="scss">
.meal_admin-btn {
  font-size: 14px;
  padding: 8px 16px;
  width: 120px;
  margin: 0 auto;
}

.card__fields {
  max-height: 60vh;
  overflow-y: auto;
}

.v-chip-wrap {
  height: auto !important;

  .v-chip__content {
    max-width: 100% !important;
    height: auto !important;
  }

  .chip-wrap {
    white-space: normal !important;
    word-break: break-word !important;
    max-width: 100% !important;
    height: auto !important;
  }
}
</style>
