export default class DishFactory {
  constructor() {
    return {
      name: '',
      recipe: null,
      foodCategory: null,
      ageCategoires: [
        {
          id: 1,
          alias: 'weightPrimary',
          name: '7-11 лет',
          gramms: 0
        },
        {
          id: 2,
          alias: 'weightMiddle',
          name: '12 лет и старше',
          gramms: 0
        }
      ],
      energy: 0,
      protein: 0,
      fats: 0,
      carbs: 0,
      price: 0,
      isAdditional: false,
      weightPrimary: 0,
      weightMiddle: 0,
      minerals: {
        ca: 0,
        fe: 0,
        mg: 0
      },
      vitamins: {
        a: 0,
        b1: 0,
        c: 0,
        e: 0
      }
    }
  }
}
