<template>
  <v-container
    fluid
    class="fs-moduleadmin-container"
  >
    <v-layout
      class="fs-moduleadmin-order"
      row
      mb-3
    >
      <v-flex mb-3>
        <h1>Лог изменения заявок</h1>
      </v-flex>
    </v-layout>
    <div>
      <v-card>
        <v-card-text class="pb-0">
          <div class="d-flex row">
            <element-group :max="500" title="По школе" class="mr-2 px-3 py-2">
              <v-autocomplete
                  solo
                  dense
                  :value="filters.schoolId"
                  @change="changeSchool"
                  :items="filters.schools"
                  item-text="name"
                  item-value="id"
                  label="Школа"
                  clearable
              />
              <v-autocomplete
                  clearable
                  solo
                  dense
                  :disabled="!filters.schoolId"
                  :value="filters.classId"
                  @change="changeClass"
                  :items="filters.classes"
                  item-text="name"
                  item-value="id"
                  label="Класс"
              />
            </element-group>
            <element-group :max="500" title="По дате" class="mr-2 px-3 py-2">
              <v-menu
                  v-model="filters.datepickerMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      dense
                      solo
                      v-model="filters.date"
                      label="Дата"
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    class="fs-datepicker"
                    prev-icon="keyboard_arrow_left"
                    next-icon="keyboard_arrow_right"
                    locale="ru"
                    first-day-of-week="1"
                    no-title
                    v-model="filters.date"
                    @input="selectDate"
                ></v-date-picker>
              </v-menu>
            </element-group>
            <element-group title="По идентификатору" class="mr-2 px-3 py-2">
              <v-text-field
                  solo
                  label="Лицевой счет ученика"
                  v-model="selectedPupilID"
              />
              <v-text-field
                  solo
                  label="Номер заявки"
                  v-model="selectedOrderID"
              />
            </element-group>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              @click="loadMore"
              :disabled="blockSearchButton"
              small
              color="primary"
              class="mr-3"
          >
            Найти
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <div>
      <order-logs-table :orders="items"/>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment'
import {
  getOrdersLogs,
  getSchools,
  getClasses
} from '@/router/routes/superadmin'
import { mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import _ from 'lodash'
import scrollable from "@/mixins/scrollable"
import notify from '@/utils/notifications/toastNotification'
import ElementGroup from "@/components/common/ElementGroup/ElementGroup.vue";
import OrderLogsTable from "@/components/moduleadmin/OrdersLogs/Components/OrderTable.vue";

export default {
  name: 'SuperadminOrdersLogsIndex',
  components: { OrderLogsTable, ElementGroup },
  data() {
    return {
      pagination: {
        rowsPerPage: -1
      },
      items: [],
      filters: {
        date: moment().format('yyyy-MM-DD'),
        schoolId: null,
        schools: [],
        classes: [],
        classId: null,
        datepickerMenu: false,
      },
      selectedPupilID: null,
      selectedOrderID: null,
      limit: Vue.config.general.limit,
    }
  },
  computed: {
    dateNormal() {
      if (!this.filters.date) {
        return null
      }
      const date = this.filters.date.split('-')
      const day = date[2]
      const month = date[1]
      const year = date[0]
      return `${day}-${month}-${year}`
    },
    blockSearchButton() {
      return (!this.filters.schoolId &&
          !this.filters.classId &&
          !this.selectedPupilID &&
          !this.selectedOrderID)
    }
  },
  created() {
    this.getSchools()
    // this.loadMore()
  },
  methods: {
    ...mapMutations( [ 'setShowPreloader' ] ),
    selectDate(date) {
      this.filters.datepickerMenu = false
      this.filters.date = date
      this.items = []
      this.offset = 0
    },
    clearFilters() {
      this.filters.datepickerMenu = false
      this.filters.date = ''
      this.filters.schoolId = null
      this.filters.classId = null
      this.items = []
      this.offset = 0
    },
    async loadMore() {
      this.items = []
      try {
        const payload = {
          params: {
            school: this.filters.schoolId,
            schoolClass: this.filters.classId,
            orderDay: this.dateNormal || null,
            selectedPupilID: this.selectedPupilID,
            selectedOrderID: this.selectedOrderID
          }
        }
        const response = await getOrdersLogs(payload)
        const itemsArray = _.map(response.data.data, item => {
          return {
            orderId: item.order_id,
            pupilId: item.pupil_id,
            createdAt: moment(item.created_at, Vue.config.format.serverDateFull).format(Vue.config.format.dateString.time),
            changeType: item.change_type,
            author: item.author,
            comment: item.comment
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        // notify.error('Не удалось загрузить лог') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getSchools() {
      try {
        const response = await getSchools()
        const {
          data: { data: items }
        } = response
        this.filters.schools = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      }
      store.commit('setShowPreloader', false)
    },
    async getClasses() {
      try {
        const payload = {
          school_id: this.filters.schoolId
        }
        const response = await getClasses(payload)
        const {
          data: { data: items }
        } = response
        this.filters.classes = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async changeSchool(value) {
      this.filters.schoolId = value
      this.filters.classId = null
      this.filters.classes = []
      this.items = []
      this.offset = 0
      await this.getClasses()
    },
    changeClass(value) {
      this.filters.classId = value
      this.items = []
      this.offset = 0
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    },
    selectedPupilID(val) {
      this.selectedPupilID = val === '' ? null : val
    },
    selectedOrderID(val) {
      this.selectedOrderID = val === '' ? null : val
    }
  }
}
</script>
