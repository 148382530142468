<script>
import {cancelOrdersByFilters, getAdminDashboardOrderStatuses, getClasses} from "@/router/routes/admin";
import {mapGetters, mapMutations} from "vuex";
import {getClasses as getClassesDepartment} from "@/router/routes/department";
import Vue from "vue";
import store from "@/store";
import notify from "@/utils/notifications/toastNotification.vue";

export default {
  name: "OrderCancelModal",
  data: () => {
    return {
      darkTheme: Vue.config.env.darkTheme,
      modalShow: false,
      availableClasses: [], // <- Загружать список классов
      availableStatuses: [
        { name: 'Черновик', id: 1 },
        { name: 'Выполнен (без списания)', id: 11 },
        { name: 'Подтвержден учителем', id: 2 },
        { name: 'Подтвержден школой', id: 3 },
        { name: 'Выдан', id: 4 },
        { name: 'Выполнен', id: 5 },
        { name: 'Сохранен учителем', id: 7 },
        { name: 'Сохранен школой', id: 8 },
        { name: 'Подтвержден автоматически', id: 9 },
        { name: 'Изменен', id: 10 },
      ],
      params: {
        classAll: false,
        statusAll: false,
        selectedClass: [],
        selectedStatus: []
      }
    }
  },
  props: {
    schoolId: Number,
    day: Number,
    month: Number,
    year: Number,
  },
  mounted() {
       this.getClasses()
    },
  watch: {
    modalShow(newValue) {
      if (!newValue) {
        this.params.selectedClass = []
        this.params.selectedStatus = []
        this.params.statusAll =  false
        this.params.classAll =  false

      }
    }
  },
  methods: {
    ...mapMutations(['setShowPreloader']),
    async process() {
      this.params.schoolId = this.schoolId
      this.params.day = this.day
      this.params.month = this.month
      this.params.year = this.year
      const response= await cancelOrdersByFilters(this.params)
      if (response.data.success) {
          notify.success('Заказы успешно отменены')
          this.modalShow = false
        this.$emit('reloadOrders')
        } else {
          // notify.error('Произошла ошибка при попытке отменить заказы') // backend handler added (exception_message)
        }
        store.commit('setShowPreloader', false)
    },

    async getClasses() {
      try {
        const PAYLOAD = {
          schoolId: this.schoolId
        }
        const response = await getClasses(PAYLOAD)
        this.availableClasses = response.data.data.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}

</script>

<template>
<div>
  <v-btn
    @click="modalShow = true"
    class="fs-btn fs-teacher-btn__cancel mr-3">
    <v-icon small class="pr-1" :color="darkTheme ? 'white' : 'error'">no_meals</v-icon>
  Работа с отменой заказов
  </v-btn>
  <v-dialog width="335"
    v-model="modalShow">
    <v-card>
      <v-card-title> <h2>Работа с отменой заказов</h2>  </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="params.selectedClass"
          :items="availableClasses"
          key="id"
          item-text="name"
          item-value="id"
          placeholder="Выберите класс"
          :disabled="params.classAll"
          :multiple="true"
        />
        <v-checkbox
            :label="'Выбрать все классы'"
            v-model="params.classAll"
        >
          Выбрать все
        </v-checkbox>
        <v-autocomplete
          :items="availableStatuses"
          key="name"
          item-text="name"
          item-value="id"
          placeholder="Выберите статус"
          v-model="params.selectedStatus"
          :disabled="params.statusAll"
          :multiple="true"
        />
        <v-checkbox
            :label="'Выбрать все статусы'"
            v-model="params.statusAll"
        >
          Выбрать все
        </v-checkbox>
      </v-card-text>
      <v-card-actions class="d-flex flex-row justify-space-between">
        <v-btn color="error" @click="modalShow = false">
          Закрыть
        </v-btn>
        <v-btn color="success" @click="process()">
          Отменить заказы
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<style scoped>

</style>

