var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sc-message",attrs:{"id":_vm.message.id}},[_c('div',{staticClass:"sc-message--content",class:{
      sent: _vm.message.author === 'me',
      received: _vm.message.author !== 'me' && _vm.message.type !== 'system',
      system: _vm.message.type === 'system'
    }},[_vm._t("user-avatar",function(){return [(_vm.message.type !== 'system' && _vm.authorName && _vm.authorName !== 'me')?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.authorName),expression:"authorName"}],staticClass:"sc-message--avatar",style:({
          backgroundImage: `url(${_vm.chatImageUrl})`
        }),attrs:{"title":_vm.authorName}}):_vm._e()]},{"message":_vm.message,"user":_vm.user}),(_vm.message.type === 'text')?_c('TextMessage',{attrs:{"message":_vm.message,"message-colors":_vm.messageColors,"message-styling":_vm.messageStyling},scopedSlots:_vm._u([{key:"default",fn:function(scopedProps){return [_vm._t("text-message-body",null,{"message":scopedProps.message,"messageText":scopedProps.messageText,"messageColors":scopedProps.messageColors,"me":scopedProps.me})]}},{key:"text-message-toolbox",fn:function(scopedProps){return [_vm._t("text-message-toolbox",null,{"message":scopedProps.message,"me":scopedProps.me})]}}],null,true)}):(_vm.message.type === 'system')?_c('SystemMessage',{attrs:{"data":_vm.message.data,"message-colors":_vm.messageColors}},[_vm._t("system-message-body",null,{"message":_vm.message.data})],2):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }