<template>
  <v-container
    fluid
    class="fs-parent-container"
  >
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <v-flex mb-3>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-layout row>
        <v-flex
          md2
          mr-2
        >
          <DatePickersSmart
            :textFieldClass="'fs-datePickerSmart--parent__textfield'"
            :pickerClass="'fs-datePickerSmart--parent'"
            :normalStyle="true"
            :startDate.sync="dateStart"
            :endDate.sync="dateEnd"
            @changeDate="changeDate"
          />
        </v-flex>
        <v-flex md4>
          <!-- <v-btn
            flat
            class="fs-btn fs-parent-btn fs-btn--secondary"
            @click="filterData()"
          >показать</v-btn> -->
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                v-if="!isChildView"
                @click="printBalance"
              >print</v-icon>
            </template>
            <span>Печать</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </v-layout>

    <v-layout
      class="fs-archive-result mt-2"
      mb-3
    >
      <v-flex>
	      <v-select
		      v-if="setting('mod_buffet')"
		      :items="transactions"
		      item-text="text"
		      item-value="id"
		      v-model="transactionType"
		      @change="changeTransactionType"

		      class="mb-2 ml-3"
		      placeholder="Тип транзакций"
		      style="margin-bottom: 5px; width: 10%;"
	      ></v-select>
        <v-data-table
          :headers="transactionType === 1 ? headers : buffetHeaders"
          :items="items"
          no-data-text="Транзакции отсутствуют"
          hide-actions
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.date }}</td>
              <td>{{ props.item.amount }} руб.</td>
              <td v-if="transactionType === 1">{{ props.item.transaction_code }}</td>
              <td>{{ props.item.balance }} руб.</td>
              <td v-if="transactionType === 1">{{ props.item.transaction.status.text }}</td>
              <td>{{ props.item.transaction.type? "Пополнение" :'Списание' }}</td>
            </tr>
          </template>
          <template
            v-slot:footer
            v-if="items.length"
          >
            <tr>
              <td
                colspan="5"
                class="text-md-right"
              >Итого списаний: {{getTotal(0)}} р.</td>
            </tr>
            <tr>
              <td
                colspan="5"
                class="text-md-right"
              >Итого пополнений: {{getTotal(1)}} р.</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import Vue from 'vue'
import store from '@/store'
import {
  getBalanceHistory,
  // getOrdersTaskId,
  // getOrdersFileStatus,
  // getOrdersFile
  getBalanceTaskId,
  getBalanceFileStatus,
  getBalanceFile, getBuffetBalanceHistory
} from '@/router/routes/parent'
import { getPupilBalanceHistory } from '@/router/routes/pupil'
import scrollable from '@/mixins/scrollable'
import numable from '@/mixins/balancable'
import _ from 'lodash'
import { DatePickersSmart } from '@/components/common'
import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownload'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'BalanceMoving',
  components: {
    DatePickersSmart
  },
  props: {
    isChildView: {
      type: Boolean,
      default: false
    },
  },
  mixins: [ scrollable, numable ],
  data() {
    return {
      title: 'Движение денежных средств',
	    transactions: [
		    { text: 'Питание', id: 1 },
		    { text: 'Буфет', id: 2 },
	    ],
	    transactionType: 1,
      headers: [
        { text: 'Дата и время операции', value: 'dateSort' },
        { text: 'Сумма операции в рублях', value: 'amount' },
        { text: 'Код транзакции', value: 'transaction_code' },
        { text: 'Баланс', value: 'balance' },
        { text: 'Описание операции', value: 'transaction.status.text' },
        { text: 'Тип операции', value: 'transaction.type' }
      ],
	    buffetHeaders: [
		    { text: 'Дата и время операции', value: 'dateSort' },
		    { text: 'Сумма операции в рублях', value: 'amount' },
		    { text: 'Баланс', value: 'balance' },
		    { text: 'Тип операции', value: 'transaction.type' }
	    ],
      items: [],
      dateStart: moment()
        .subtract(1, 'days')
        .format(Vue.config.format.serverDate),
      dateEnd: moment()
        .add(1, 'days')
        .format(Vue.config.format.serverDate)
    }
  },
  computed: {
    ...mapGetters([ 'currentChild', 'setting' ])
  },
  created() {
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },
  destroyed() {
    this.removeScrollHandler()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
	  async changeTransactionType() {
		  this.resetData()
		  await this.loadMore()
	  },
    getTotal(transactionType) {
      let totalSum = 0
      this.items.forEach(item => {
        if (item.transaction.type == transactionType) {
          totalSum += item.amount
        }
      })
      return totalSum
    },
    changeDate(payload) {
      this.resetData()
      this.loadMore()
    },
    resetData() {
      this.items = []
    },
    loadMore() {
      const payload = {
        id: this.currentChild.id,
        params: {
          limit: Vue.config.general.limit,
          offset: this.offset,
          start_date: moment(this.dateStart).format(
            Vue.config.format.pythonDate
          ),
          end_date: moment(this.dateEnd).format(Vue.config.format.pythonDate)
        }
      }

      let method = null
      if (this.setting('mod_buffet') && this.transactionType === 2) {
        method = getBuffetBalanceHistory
      } else {
        method = this.isChildView ? getPupilBalanceHistory : getBalanceHistory
	    }

      method(payload)
        .then(response => {
          const items = _.map(response.data.data, item => {
            return {
              date: item.date,
              dateSort: this.getDateSort(item.date),
              amount: this.convertToRubles(item.amount),
              transaction_code: item.transaction_code,
              balance: this.convertToRubles(item.balance),
              transaction: {
                code: _.get(item, 'transaction.orderId', null),
                status: {
                  id: _.get(item, 'transaction.status', ''),
                  text: _.get(item, 'transaction.statusText', '')
                },
                type: this.checkTransactionType(item.amount)
              }
            }
          })
          this.items = this.items.concat(items)
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    checkTransactionType(amount) {
      const SIGN = Math.sign(amount)
      if (SIGN === 1 || SIGN === 0) {
        return 1
      }
      if (SIGN === -1) {
        return 0
      }
      return null
    },
    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
    },
    // async printOrders() {
    //   const callback = this.resetPageFileLoaded
    //   try {
    //     const PAYLOAD = {
    //       studentId: this.currentChild.id,
    //       data: {
    //         start_date: moment(this.dateStart).format(
    //           Vue.config.format.pythonDate
    //         ),
    //         end_date: moment(this.dateEnd).format(Vue.config.format.pythonDate)
    //       }
    //     }
    //     await asyncReqestsForDownload(
    //       getOrdersTaskId,
    //       getOrdersFileStatus,
    //       getOrdersFile,
    //       PAYLOAD,
    //       callback
    //     )
    //   } catch (error) {
    //     Vue.notify({
    //       type: 'error',
    //       text: 'Ошибка загрузки файла.'
    //     })
    //     callback()
    //   } finally {
    //     store.commit('setAsyncLoadFile', false)
    //   }
    // },
    async printBalance() {
      const callback = this.resetPageFileLoaded
      try {
        const PAYLOAD = {
          studentId: this.currentChild.id,
          data: {
            start_date: moment(this.dateStart).format(
              Vue.config.format.pythonDate
            ),
            end_date: moment(this.dateEnd).format(Vue.config.format.pythonDate)
          }
        }
        await asyncReqestsForDownload(
          getBalanceTaskId,
          getBalanceFileStatus,
          getBalanceFile,
          PAYLOAD,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки файла') //pass
        callback()
      } finally {
        store.commit('setAsyncLoadFile', false)
      }
    },
    getDateSort(date) {
      const dateTimeParts = date.split(' ')
      const dateParts = dateTimeParts[0].split('-').reverse()
      return dateParts.join('-') + ' ' + dateTimeParts[1]
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
