<template>
  <v-card flat>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-actions
      class="elevation-1"
    >
      <template v-slot:items="props">
          <tr>
            <td>{{ props.item.name }}</td>
            <td>
              <v-text-field
                v-model="props.item.square"
                type="number"
                min="0"

                :label="!darkTheme ? 'Площадь кв.м' : ''"
                :placeholder="darkTheme ? 'Площадь кв.м' : ''"
                :outline="darkTheme"
                :hide-details="darkTheme"
              />
            </td>
            <td>
              <v-text-field
                v-model="props.item.tehnicalbox"

                :label="!darkTheme ? 'Единиц/год выпуска' : ''"
                :placeholder="darkTheme ? 'Единиц/год выпуска' : ''"
                :outline="darkTheme"
                :hide-details="darkTheme"
              />
            </td>
          </tr>
        </template>
    </v-data-table>
    <v-card-actions>
      <v-btn
        flat
        @click="update"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import store from "@/store"
export default {
  name: 'Form6',
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      formId: null,
      headers: [
        { text: 'Вид бытовых помещений', sortable: false },
        { text: 'Площадь', sortable: false },
        { text: 'Оборудование', sortable: false }
      ],
      items: [
        { name: 'Санузел для сотрудников пищеблока', square: 0, tehnicalbox: "" },
        { name: 'Гардеробная персонала', square: 0, tehnicalbox: "" },
        { name: 'Душевые для сотрудников пищеблока', square: 0, tehnicalbox: "" },
        { name: 'Стирка спецодежды /где, кем, что для этого имеется', square: 0, tehnicalbox: "" }
      ],
      names: {
        "bathroom_for_catering_staff": "Санузел для сотрудников пищеблока",
        "staff_dressing_room": "Гардеробная персонала",
        "showers_for_catering_staff": "Душевые для сотрудников пищеблока",
        "workwear_washing": "Стирка спецодежды /где, кем, что для этого имеется",
        "meat_and_fish_shop": "Мясорыбный цех"
      },
    }
  },
  mounted() {
    this.getFoodblockHousehold(this.foodBlockPassport.household_sections.id)
      .then(result => this.setForm(result))
  },
  methods: {
    ...mapActions([ 'getFoodblockHousehold', 'updateFoodBlockHousehold' ]),
    update() {
      this.updateFoodBlockHousehold({ id: this.formId, data: { result: this.transform_data_to_back(this.items), passport: this.foodBlockPassport.id } })
        .then(result => this.setForm(result))
    },
    setForm(data) {
      this.formId = data.id
      const items = this.items = data.result ? data.result : this.items
      this.items = this.transform_data_to_front(items)
    },
    transform_data_to_front(backendData) {
      const names = this.names
      const res = Object.keys(backendData).map(function(key) {
        const { square, tehnicalbox } = backendData[key]
        return {
          name: names[key],
          id: key,
          square: square,
          tehnicalbox: tehnicalbox
        }
      })
      return res
    },
    transform_data_to_back(frontendData) {
      const res = {}
      frontendData.forEach(el => {
        res[el.id] = {
          square: el.square,
          tehnicalbox: el.tehnicalbox
        }
      })
      return res
    },
  },
  computed: {
    ...mapGetters([ 'foodBlockPassport' ])
  }
}
</script>
