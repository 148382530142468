<template>
  <BalanceMoving is-child-view />
</template>

<script>
import BalanceMoving from '@/components/parent/Balance/Moving/Index'

export default {
  name: 'PupilBalanceMoving',
  components: {
    BalanceMoving
  }
}
</script>
