import Vue from 'vue'
import moment from 'moment'

Vue.filter('toRUB', function(value) {
  return value / 100
})

Vue.filter('transformDate', function(value) {
  return moment(value).format(Vue.config.format.date)
})

Vue.filter('transformDateTime', function(value) {
  return moment(value).format(Vue.config.format.prettyDateWithoutSeconds)
})
