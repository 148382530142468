<template>
  <v-dialog
    :value="dialog"
    @input="$emit('update:dialog', false)"
    :max-width="width"
    :fullscreen="fullscreenMode"
  >
    <v-card>
      <v-card-title class="headline">
        <slot name="headline"></slot>
      </v-card-title>
      <v-card-text>
        <slot name="body"></slot>
      </v-card-text>
      <v-card-actions>
        <v-layout
          align-center
          justify-end
          row
          fill-height
        >
          <v-btn
            :class="btnsClass"
            flex
            @click="closeDialog()"
            v-if="!hideCancelBtn"
          >{{cancelBtnText}}</v-btn>
          <v-btn
            v-if="!hideConfirmButton"
            :disabled="disableConfirmButton"
            :class="btnsClass"
            flex
            @click="closeDialog('accept')"
          >{{confirmBtnText}}</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    confirm: {
      type: Boolean,
      default: false
    },
    hideConfirmButton: {
      type: Boolean,
      default: false
    },
    disableConfirmButton: {
      type: Boolean,
      default: false
    },
    confirmBtnText: {
      type: String,
      default: 'Подтвердить'
    },
    cancelBtnText: {
      type: String,
      default: 'Отмена'
    },
    hideCancelBtn: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 500
    },
    fullscreenMode: {
      type: Boolean,
      default: false
    },
    btnsClass: {
      type: String,
      default: ''
    },
    closePersistent: {
      type: Boolean,
      default: false
    },
    btnPrintLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeDialog(type) {
      let answer = false
      this.$emit('update:dialog', false)
      if (type === 'accept') {
        answer = true
        this.$emit('btnOnLoad', true)
      }
      this.$emit('confirm', answer)
    }
  }
}
</script>
<style  scoped>
.v-card__title {
    font-size: 18px !important ;
    padding-bottom: 5px;
}
.v-card__text {
    font-size: 14px !important ;
    padding-top: 5px;
}
.v-card__text >>> .fs-filter .v-menu__content {
    max-height: 120px !important;
}
</style>
