import Vue from 'vue'
const LOAD_MORE_COEFFICIENT = 0.2

// TODO: Переписать нах) Во-первых - эвент обработки скролла не должен быть на каждый чих,
// во вторых проверить установку finish.
// LIMIT надо брать из компонента
// requestAnimationFrame
export default {
  data() {
    return {
      size: 0,
      offset: 0,
      finish: false,
      LIMIT_CONFIG: Vue.config.general.limit
    }
  },
  methods: {
    _loadMore() {
      this.loadMore()
    },
    scrollHandler(event) {
      const doc = document.documentElement
      const offset = doc.scrollTop + window.innerHeight
      const height = doc.offsetHeight
      // Когда скроллбар доходит до 80% страницы - выполняем запрос на подгрузку данных.
      const preloaderActivator = LOAD_MORE_COEFFICIENT * height
      if (
        !this.finish &&
        offset > height - preloaderActivator &&
        this.offset < this.size
      ) {
        this.offset += this.LIMIT_CONFIG
        this._loadMore()
      }
    },
    savePosition(collection) {
      this.finish = false
      if (
        (collection.length !== 0 &&
          this.size > collection.length &&
          !this.finish) ||
        this.LIMIT_CONFIG > collection.length
      ) {
        this.finish = true
      }
      this.size = collection.length
    },
    removeScrollHandler() {
      this.finish = true
      this.size = 0
      this.offset = 0
      window.removeEventListener('scroll', this.scrollHandler)
    }
  },
  destroyed() {
    this.removeScrollHandler()
  }
}
