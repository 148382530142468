<template>
  <HistoryIndex is-child-view />
</template>

<script>
import HistoryIndex from '@/components/parent/History/Index'

export default {
  name: 'PupilChildHistory',
  components: {
    HistoryIndex
  },
}
</script>
