<script>
import Modal from '@/ui-components/ModalParent.vue'
import numable from '@/mixins/balancable'
import { mapGetters } from 'vuex'
import store from '@/store'
import notify from '@/utils/notifications/toastNotification.vue'
import _ from "lodash";

export default {
  name: 'TransferModal',
  components: { Modal },
  computed: {
    ...mapGetters(['yourSchoolchildren', 'setting']),
    children() {
      const hasSettingSirius = this.setting('sirius')
      const arrChildren = this.yourSchoolchildren.map((item) => {
        return {
          ...item,
          changedBalance: hasSettingSirius ? item.buffet_balance : item.balance
        }
      })
      if (hasSettingSirius) {
        return arrChildren.filter((child) => child.is_buffet_card)
      } else {
        return arrChildren
      }
    }
  },
  mixins: [numable],
  emits: ['update'],

  data() {
    return {
      title: 'Перенос средств',
      isValid: false,
      fieldsValue: {
        from_pupil_id: null,
        to_pupil_id: null,
        amount: 0
      },
      isLoading: false,
      balance: null,
      toPupilList: [],
      selectFromPupil: null,
      rules: {
        requered: (v) => !!v || `обязательноe поле`,
        decimalNumber: (v) =>
          /^\d*\.?\d+$/.test(v) ||
          'Введите число с плавающей точкой. Допускается не более одной точки.'
      }
    }
  },

  methods: {
    openModal() {
      this.$refs.modal.open()
    },
    clearModalForm() {
      this.$refs.form.reset()
    },

    async changeTransferBalance() {
      const data = _.cloneDeep(this.fieldsValue)
      data.amount = Math.round(data.amount * 100)

      try {
        await store.dispatch('CHANGE_TRANSFER_BALANCE', { data })
        this.$emit('update')
        notify.success('Перенос средств успешно выполнен')
        this.clearModalForm()
	      await store.dispatch('GET_YOUR_SCHOOLCHILDREN')
	      this.$refs.modal.close()
      } catch (e) {
        notify.error(`Ошибка операции ${e}`)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    changeFromPupil(v) {
      this.toPupilList = this.children.filter((item) => item.id !== v)
      this.selectFromPupil = this.children.find((item) => item.id === v)
    },

    displayName(item) {
      return `${item.fullName}: ${this.convertToRubles(item.changedBalance)} руб.`
    }
  }
}
</script>

<template>
  <Modal
    ref="modal"
    :title="title"
    @close:before="clearModalForm"
    color-btn="brown"
  >
    <v-form
      ref="form"
      v-model="isValid"
      @submit.prevent="changeTransferBalance"
    >
      <v-flex>
        <v-autocomplete
          color="brown"
          v-model="fieldsValue.from_pupil_id"
          :items="children"
          label="От кого"
          :loading="isLoading"
          :item-text="displayName"
          item-value="id"
          :rules="[rules.requered]"
          browser-autocomplete="off"
          autocomplete="off"
          clearable
          box
          required
          @change="changeFromPupil($event)"
        />
        <v-autocomplete
          color="brown"
          v-model="fieldsValue.to_pupil_id"
          :items="toPupilList"
          label="Кому"
          :loading="isLoading"
          :item-text="displayName"
          item-value="id"
          :rules="[rules.requered]"
          browser-autocomplete="off"
          autocomplete="off"
          clearable
          box
          required
        />
        <v-text-field
          color="brown"
          v-model="fieldsValue.amount"
          label="Сумма"
          :loading="isLoading"
          browser-autocomplete="off"
          :rules="[
            (v) =>
              v <= convertToRubles(selectFromPupil?.changedBalance) ||
              'сумма должна не должна превышать баланс',
            rules.requered,
            rules.decimalNumber
          ]"
          :maxLength="convertToRubles(selectFromPupil?.changedBalance)?.length"
          autocomplete="off"
          box
          clearable
          required
        />
      </v-flex>
    </v-form>
    <template v-slot:actions>
      <v-btn
        class="fs-btn fs-parent-btn fs-btn--primary fs-form-footer__btn v-btn v-btn--flat theme--light"
        type="submit"
        :disabled="!isValid"
        @click.prevent="changeTransferBalance"
      >
        Подтвердить
      </v-btn>
    </template>
  </Modal>
</template>

<style>
.v-text-field__details {
  display: flex;
}
</style>
