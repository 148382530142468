<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-user-actions"
      row
      mb-3
    >
      <div class="mb-3 d-flex">
        <h1>Действия пользователей</h1>
        <context-help-modal :context-key="contextKeys.supplier.users.logs" />
      </div>
    </v-layout>
    <v-layout>
      <DatePickersSmart
        :startDate.sync="dateStart"
        :endDate.sync="dateEnd"
      />
    </v-layout>
    <v-layout
      class="fs-user-actions-results"
      mb-3
      justify-space-around
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :pagination.sync="pagination"
          :items="items"
          hide-actions
          no-data-text="Нет данных"
          class="elevation-1 fs-organizer-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.date }}</td>
              <td>{{ props.item.author }}</td>
              <td>{{ props.item.event }}</td>
              <td>{{ props.item.data }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment'
import { getEvents } from '@/router/routes/organizer'
import { mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import _ from 'lodash'
import scrollable from '@/mixins/scrollable'
import { DatePickersSmart } from "@/components/common"
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'OrganizerUsersActions',
  mixins: [ scrollable, ],
  components: { ContextHelpModal, DatePickersSmart },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      pagination: {
        rowsPerPage: -1
      },
      headers: [
        { text: 'Дата и время', value: 'date' },
        { text: 'Автор', value: 'author' },
        { text: 'Событие', value: 'event' },
        { text: 'Данные', value: 'data', sortable: false }
      ],
      items: [],
      dateStart: null,
      dateEnd: null
    }
  },
  created() {
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },
  destroyed() {
    this.removeScrollHandler()
  },
  methods: {
    ...mapMutations( [ 'setShowPreloader' ] ),
    async loadMore() {
      try {
        const PAYLOAD = {
          params: {
            limit: Vue.config.general.limit,
            offset: this.offset
          }
        }
        if (this.dateStart) {
          PAYLOAD.params.dateStart = this.dateStart
        }
        if (this.dateEnd) {
          PAYLOAD.params.dateEnd = this.dateEnd
        }
        const response = await getEvents(PAYLOAD)
        this.items = _.map(response.data.data, item => {
          return {
            date: moment(_.get(item, 'created_at', null)).format(
              Vue.config.format.dateString.time
            ),
            author: _.get(item, 'author', null),
            event: _.get(item, 'event', null),
            data: _.get(item, 'data', null)
          }
        })
      } catch (error) {
        // notify.error('Не удалось загрузить события') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
  },
  watch: {
    items(items) {
      this.savePosition(items)
    },
    dateStart() {
      this.offset = 0
      this.loadMore()
    },
    dateEnd() {
      this.offset = 0
      this.loadMore()
    }
  }
}
</script>
