<template>
  <v-container :class="{ 'fs-admin-container': !darkTheme }" fluid>
    <v-layout row class="mb-5">
      <h1>{{ title }}</h1>
      <context-help-modal :context-key="contextKeys.admin.settings" />
    </v-layout>
    <div v-if="showSchoolId" style="font-weight: bold" class="mb-4">
      Идентификатор школы: {{ this.currentUser.school.id }}
    </div>
    <v-form ref="form" v-model="formValid" lazy-validation>
      <v-layout row wrap>
        <v-flex md3>
          <v-flex class="mt-3 mb-3">
            <v-flex class="d-flex order_time" row>
              <v-text-field
                class="fs-input labelFullWidth"
                label="Время подачи заявки на питание"
                :rules="hourRules"
                :disabled="orders_time_change_block"
                v-model="mealOrderSubmissionTime"
                type="number"
                placeholder="Часы"
                hide-details
              />
            </v-flex>
            <v-flex v-if="disableOrderTimePicker" class="mb-4">
              <strong class="accent--text"
                >Изменение времени подачи заявок отключено оператором
                питания</strong
              >
            </v-flex>
          </v-flex>
          <v-text-field
            class="fs-input labelFullWidth"
            :value="complectChoicePeriod"
            :rules="[formRules.settingsPeriodWeek]"
            label="Срок выбора комплекса"
            placeholder="Срок выбора комплекса"
            @input="setValue('complectChoicePeriod', $event)"
          />
          <v-switch
            :value="fillMealCardEj"
            class="fs-switch pt-1"
            label="Заполнять табель питания по ЭЖ"
            hide-details
            @change="setValue('fillMealCardEj', $event)"
          />
          <v-switch
            :value="is_pupil_traffic_changeable"
            class="fs-switch pt-1"
            label="Указание явки родителем"
            hide-details
            @change="setValue('is_pupil_traffic_changeable', $event)"
          />
          <v-switch
            :value="hot_meal_only_for_junior_classes"
            class="fs-switch pt-1"
            label="Формирование заявок на горячее питание только для 1-4 классов"
            hide-details
            @change="setValue('hot_meal_only_for_junior_classes', $event)"
          />
          <v-divider class="mt-3 pb-1" ></v-divider>
          <v-flex grow>
            <v-btn class="me-2" color="error" @click="reset"> Очистить </v-btn>
            <v-btn
              class="me-2"
              :disabled="!checkFormValid && requestSended"
              flat
              @click="saveSettings()"
              >Сохранить</v-btn
            >
          </v-flex>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'

import rules from '@/helpers/rules'
import { getSettings, updateSettings } from '@/router/routes/admin'

import Title from '@/ui-components/Title'
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal.vue'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'SettingsIndex',
  components: {
    ContextHelpModal,
    Title
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      showSchoolId: Vue.config.env.schoolService === 'vladimir',
      darkTheme: Vue.config.env.darkTheme,
      formValid: true,
      requestSended: true,
      timeMenu: false,
      title: 'Настройки системы',
      formRules: rules,
      mealOrderSubmissionTime: '12',
      complectChoicePeriod: null,
      fillMealCardEj: false,
      is_pupil_traffic_changeable: false,
      orders_time_change_block: false,
      hot_meal_only_for_junior_classes: false,
      hourRules: [
        (val) => {
          const valNumber = Number(val)
          if (
            valNumber < 23 &&
            val >= 8 &&
            Number.isInteger(valNumber) &&
            val.length < 3
          ) {
            return true
          }
          return 'Не может быть больше 22, меньше 8 и быть не целым числом'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    checkFormValid() {
      return (
        this.formValid &&
        this.mealOrderSubmissionTime !== null &&
        this.complectChoicePeriod !== null
      )
    },
    disableOrderTimePicker: {
      get() {
        return this.orders_time_change_block
      },
      set(value) {
        this.orders_time_change_block = value
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations(['setShowPreloader']),
    async init() {
      await this.loadSettings()
    },
    async loadSettings() {
      try {
        const PAYLOAD = {
          school_id: this.currentUser.school.id
        }
        const response = await getSettings(PAYLOAD)
        const {
          data: {
            data: {
              mealOrderSubmissionTime,
              complectChoicePeriod,
              fillMealCardEj,
              is_pupil_traffic_changeable,
              orders_time_change_block,
              hot_meal_only_for_junior_classes
            }
          }
        } = response
        this.mealOrderSubmissionTime = mealOrderSubmissionTime.split(':')[0]
        this.complectChoicePeriod = complectChoicePeriod
        this.fillMealCardEj = fillMealCardEj
        this.is_pupil_traffic_changeable = is_pupil_traffic_changeable
        this.orders_time_change_block = orders_time_change_block
        this.hot_meal_only_for_junior_classes = hot_meal_only_for_junior_classes
      } catch (error) {
        // notify.error('Ошибка загрузки настроек') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async saveSettings() {
      this.requestSended = false
      try {
        const PAYLOAD = {
          school_id: this.currentUser.school.id,
          data: {
            mealOrderSubmissionTime: this.getCorrectOrderSubmissionTimeFormat(),
            complectChoicePeriod: this.complectChoicePeriod,
            fillMealCardEj: this.fillMealCardEj,
            is_pupil_traffic_changeable: this.is_pupil_traffic_changeable,
            hot_meal_only_for_junior_classes:
              this.hot_meal_only_for_junior_classes
          }
        }
        await updateSettings(PAYLOAD)
        notify.success('Настройки сохранены')
      } catch (error) {
        // notify.error('Ошибка сохранения настроек') // backend handler added (exception_message)
      } finally {
        this.requestSended = true
        store.commit('setShowPreloader', false)
      }
    },
    getCorrectOrderSubmissionTimeFormat() {
      const time = this.mealOrderSubmissionTime + ':00'
      if (
        Number(this.mealOrderSubmissionTime) < 10 &&
        this.mealOrderSubmissionTime.length === 1
      ) {
        return '0' + time
      }
      return time
    },
    reset() {
      this.$refs.form.reset()
    },
    setValue(valueLabel, value) {
      if (!value) value = valueLabel === 'complectChoicePeriod' ? null : false

      this[valueLabel] = value
    }
  }
}
</script>
<style scoped>
.order_time {
  position: relative;
}
.order_time::after {
  content: ':00';
  position: absolute;
  left: 100%;
}
</style>
