<template>
  <v-container
    fluid
    :class="{ 'fs-admin-container': !darkTheme }"
  >
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <div class="mb-3 d-flex">
        <h1>{{ title }}</h1>
        <context-help-modal :context-key="contextKeys.admin.balanceMoving" />
      </div>
    </v-layout>

    <v-layout
      wrap
      row
      :class="{ 'fs-header-filter': !darkTheme }"
    >
      <v-flex md2>
        <DatePickersSmart
          :startDate.sync="dateStart"
          :endDate.sync="dateEnd"
          @changeDate="changeDate"
        />
      </v-flex>
      <v-flex md4 ml-3>
        <v-text-field
          :value="accountId"
          @input="searchAccountId"
          :label="!darkTheme ? 'Лицевой счёт:' : ''"
          :placeholder="darkTheme ? 'Лицевой счёт:' : ''"
          :outline="darkTheme"
        />
        <v-autocomplete
          :value="selectedClass"
          @change="changeValue('selectedClass', $event)"
          :items="classes"
          :item-text="'name'"
          :item-value="'id'"
          flat
          dense

          :label="!darkTheme ? 'Класс:' : ''"
          :placeholder="darkTheme ? 'Класс:' : ''"
          :outline="darkTheme"
          :class="{ 'fs-admin-complex-autocomplete': !darkTheme }"
        />
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer mb-2">
      <v-flex
        text-md-left
        pt-3
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="printFile"
            >print</v-icon>
          </template>
          <span>Печать</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <v-layout
      v-if="dataTableLoaded"
      class="fs-admin-archive-result"
      mb-3
      justify-space-around
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          no-data-text="Транзакции отсутствуют"
          hide-actions
          class="elevation-1 fs-admin-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.date }}</td>
              <td>{{ props.item.sum }}</td>
              <td>{{ props.item.balance }}</td>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.transactionCode }}</td>
              <td>{{ props.item.name }}</td>
            </tr>
          </template>
          <template v-slot:footer v-if="items.length > 0">
            <tr id="summaryTableRow">
              <td>Количество операций</td>
              <td v-html="items.length"></td>
            </tr>
            <tr id="summaryTableRow">
              <td>Итоговая сумма операций в рублях</td>
              <td v-html="tableFooterSummaryViewer(items)"></td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import Vue from 'vue'
import store from '@/store'
import {
  getPayments,
  printFileTaskId,
  getPrintFileStatus,
  getClasses,
  getPrintFile
} from '@/router/routes/admin'
import scrollable from '@/mixins/scrollable'
import { DatePickersSmart } from '@/components/common'
import converter from '@/helpers/converter'
import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownload'
import {
  getClasses as getClassesDepartment,
  getPayments as getPaymentsDepartment,
  printFileTaskId as printFileTaskIdDepartment,
  getPrintFileStatus as getPrintFileStatusDepartment,
  getPrintFile as getPrintFileDepartment
} from '@/router/routes/department'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'BalanceMoving',
  mixins: [ scrollable ],
  components: {
    ContextHelpModal,
    DatePickersSmart
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      darkTheme: Vue.config.env.darkTheme,
      dateStart: moment()
        .subtract('days', 1)
        .format(Vue.config.format.serverDate),
      dateEnd: moment()
        .add('days', 1)
        .format(Vue.config.format.serverDate),
      title: 'Движение денежных средств',
      headers: [
        { text: 'Дата и время операции', value: 'sortDate' },
        { text: 'Сумма операции в рублях', value: 'sum' },
        { text: 'Баланс', value: 'balance' },
        { text: 'Лицевой счет ученика', value: 'id' },
        { text: 'Код транзакции', value: 'transactionCode' },
        { text: 'Имя ученика', value: 'name' }
      ],
      dataTableLoaded: false,
      accountId: null,
      classes: [],
      selectedClass: null,
      items: [],
      LIMIT: Vue.config.general.limit
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
    startDatePython() {
      return moment(this.dateStart).format(Vue.config.format.pythonDate)
    },
    endDatePython() {
      return moment(this.dateEnd).format(Vue.config.format.pythonDate)
    },
  },
  created() {
    this.init()
    window.addEventListener('scroll', this.scrollHandler)
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async init() {
      await this.getClasses()
    },
    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
    },
    checkTypeObject(value) {
      return typeof value === 'object'
    },
    async changeValue(type, value) {
      this[type] = value
      this.resetData()
      await this.loadMore()
    },
    async getClasses() {
      try {
        const PAYLOAD = {
          schoolId: this.municipalitySchoolId ? this.municipalitySchoolId : this.currentUser.school.id,
          params: {
            limit: Vue.config.general.classLimit,
            offset: 0
          }
        }
        const method = this.municipalityView ? getClassesDepartment : getClasses
        const response = await method(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, name, number } = item
          return {
            id: id,
            name: name,
            number: number
          }
        })
        this.classes = [ ...this.items, ...itemsArray ]
        this.selectedClass = this.classes[0].id
        await this.loadMore()
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async printFile() {
      const PAYLOAD = {
        school_id: this.municipalitySchoolId ? this.municipalitySchoolId : this.currentUser.school.id,
        id: this.currentUser.id,
        data: {
          start_date: this.startDatePython,
          end_date: this.endDatePython,
          class_id: this.selectedClass,
        }
      }
      const callback = this.resetPageFileLoaded
      const method1 = this.municipalityView ? printFileTaskIdDepartment : printFileTaskId
      const method2 = this.municipalityView ? getPrintFileStatusDepartment : getPrintFileStatus
      const method3 = this.municipalityView ? getPrintFileDepartment : getPrintFile
      try {
        await asyncReqestsForDownload(
          method1,
          method2,
          method3,
          PAYLOAD,
          callback
        )
      } catch (error) {
        // notify.error('Ошибка загрузки файла') // backend handler added (exception_message)
      } finally {
        this.resetPageFileLoaded()
      }
    },
    searchAccountId: _.debounce(async function(value) {
      this.accountId = value
      this.resetData()
      await this.loadMore()
    }, 1500),
    async loadMore() {
      const selectedClassValue = this.checkTypeObject(this.selectedClass)
        ? this.selectedClass.id
        : this.selectedClass

      try {
        const payload = {
          schoolId: this.municipalitySchoolId ? this.municipalitySchoolId : this.currentUser.school.id,
          params: {
            start_date: this.startDatePython,
            end_date: this.endDatePython,
            accountId: this.accountId,
            schoolClass: selectedClassValue,
            limit: this.LIMIT,
            offset: this.offset
          }
        }
        const method = this.municipalityView ? getPaymentsDepartment : getPayments
        const response = await method(payload)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const {
            accountId = null,
            date = null,
            fullName = '',
            sum = null,
            transactionCode = null,
            balance = null,
          } = item
          return {
            id: accountId,
            name: fullName,
            date: date,
            sortDate: new Date(this.getISODate(date)),
            sum: converter(sum, 'pennyToRub'),
            balance: converter(balance, 'pennyToRub'),
            transactionCode: transactionCode
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
        this.dataTableLoaded = true
      } catch (error) {
        console.error(error)
        // notify.error('Ошибка загрузки счетов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    resetData() {
      this.offset = 0
      this.items = []
      this.dataTableLoaded = false
    },
    changeDate(payload) {
      this.resetData()
      this.loadMore()
    },
    getISODate(date) {
      return date.split('-').reverse().join('-')
    },
    getClassLiter(name) {
      return name.split(' ')[1]
    },
    tableFooterSummaryViewer(items) {
      return items.reduce(
        (summary, item) => summary + item.sum, 0
      )
    },
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  },
  props: {
    municipalityView: {
      type: Boolean,
      required: false,
      default: false
    },
    municipalitySchoolId: {
      type: Number,
      required: false,
      default: null
    },
    municipalitySchoolName: {
      type: String,
      required: false,
      default: null
    }
  },
}
</script>
<style>
  #summaryTableRow > td {
    font-size: .9em;
  }
</style>
