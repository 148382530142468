import store from '@/store'
import notify from '@/utils/notifications/toastNotification'

const requestWrapper = async (func, ...args) => {
  if (!store.getters.asyncLoadFile && !store.getters.asyncLoadFileWithProgressBar) {
    store.commit('setAsyncRequestLoadProgressBar', true)
  }

  return await func(...args).finally(() => store.commit('setAsyncRequestLoadProgressBar', false))
}

export default async (getTaskId, getStatus, payload, callback) => {
  const reportId = payload && payload.report_id ? payload.report_id : null
  const studentId = payload && payload.studentId ? payload.studentId : null
  const hasPayloadForStatus = payload && payload.school_id ? payload.school_id : null

  requestWrapper(getTaskId, payload ? payload : null).then(resTask => {
    const {
      data: {
        data: { id }
      }
    } = resTask

    const payloadStatus = (() => {
      if (hasPayloadForStatus) {
        return {
          school_id: payload.school_id,
          id: reportId || id,
          task_id: id
        }
      }
      else if (studentId) {
        return {
          studentId,
          task_id: id
        }
      }
      else if (reportId) {
        return {
          id: reportId,
          task_id: id
        }
      }
      else {
        return {
          task_id: id
        }
      }
    })()

    store.commit('setAsyncRequestLoadProgressBar', true)

    const requestGetStatus = () => {
      getStatus(payloadStatus).then(resStatus => {
        let {
          data: {
            data: { progress }
          }
        } = resStatus
        if (progress === null) progress = 0
        store.commit('setAsyncRequestProgressBarValue', parseInt(progress))

        if (+progress === 100) {
          callback(id)
          store.commit('setAsyncRequestLoadProgressBar', false)
        }
        else {
          setTimeout(requestGetStatus, 1000)
        }
      }, () => {
        notify.error('Ошибка получения статуса запроса')
      })
    }

    requestGetStatus()
  }, () => {
    notify.error('Ошибка выполнения запроса')

    callback()
  })
}
