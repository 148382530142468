<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <AdminOrdersItem
      :municipality-view="true"
    />
  </v-container>
</template>

<script>
import AdminOrdersItem from '@/components/admin/Orders/Item'

export default {
  name: 'MunicipalityOrders',
  components: {
    AdminOrdersItem
  }
}
</script>
