import Vue from "vue"

export const getAllChildStudents = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.child.students.all,
    payload
  )


export const getChildDishes = () =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.child.dishes.all
  )

export const getChildStudent = () =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.child.students.one
  )

export const getChildSettings = () =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.child.settings
  )

export const getPupilBalanceHistory = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.child.balance.history,
    { params: payload.params }
  )

export const getChildFoodOrders = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.child.orders.all,
    { params: payload.params }
  )

