<script>
import store from "@/store"
import { getDepartments, getSchools, runSyncRequest } from "@/router/routes/superadmin"

export default {
  name: "SyncFormComponent",
  data() {
    return {
      syncTargetSubject: null,
      syncSubjectList: [],
    }
  },
  async mounted() {
    const { data } = await getSchools()
    this.syncSubjectList = data.data
    store.commit('setShowPreloader', false)
  },
  methods: {
    async runSync() {
      const payload = {
        subject: this.syncTargetSubject
      }
      try {
        await runSyncRequest(payload)
      } catch (e) {
        console.log(e)
      }
      this.resetSyncForm()
      store.commit('setShowPreloader', false)
    },
    resetSyncForm() {
      this.syncTargetSubject = null
    },
  },
}
</script>

<template>
  <v-card>
    <v-card-title>
      <h3>Параметры синхронизации</h3>
    </v-card-title>
    <v-card-text>
      <v-autocomplete
          :items="syncSubjectList"
          item-text="name"
          item-value="id"
          v-model="syncTargetSubject"
          label="Выберите школу"
      />
      <v-btn @click="runSync()">Синхронизировать</v-btn>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>
