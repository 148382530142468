<template>
  <v-container class="fs-admin-container" fluid>
    <v-layout mb-5 justify-space-around>
      <v-flex md8>
        <v-card>
          <v-card-text>
            <v-flex mb-5>
              <h1>{{companyName}}</h1>
            </v-flex>
            <v-layout row>
              <v-flex md6>
                <template v-for="(item, index) in organizer">
                  <p :key="item.key" v-if="index % 2 == 0" class="fs-organizer-info">
                    <span class="fs-organizer-info__key">{{item.key}}:</span>
                    <span class="fs-organizer-info__value">{{item.value}}</span>
                  </p>
                </template>
              </v-flex>
              <v-flex md6>
                <template v-for="(item, index) in organizer">
                  <p :key="item.key" v-if="index % 2 != 0" class="fs-organizer-info">
                    <span class="fs-organizer-info__key">{{item.key}}:</span>
                    <span class="fs-organizer-info__value">{{item.value}}</span>
                  </p>
                </template>
              </v-flex>
            </v-layout>
            <v-dialog max-width="1200" v-model="SchoolOferta">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on"
                  class="fs-btn fs-teacher-btn__details"
                >Публичная оферта</v-btn>
              </template>
              <SchoolOferta>
                <template v-slot:actions>
                  <v-btn
                    color="#1976D2"
                    style="max-width: 60px"
                    class="white--text font-weight-bold ml-auto"
                    @click="SchoolOferta=false"
                  >закрыть
                  </v-btn>
                </template>
              </SchoolOferta>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout justify-space-around>
      <v-flex text-md-right md8>
        <v-btn
          flat
          class="fs-btn fs-admin-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active"
          @click="$router.go(-1)"
        >Назад</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getOrganizers } from "@/router/routes/admin"
import store from "@/store"
import { mapGetters, mapMutations } from "vuex"
import SchoolOferta from "@/components/admin/Oferta/SchoolOferta.vue";

export default {
  name: "OrganizerCard",
  components: {
    SchoolOferta,
  },

  created() {
    const payload = {
      schoolId: this.currentUser.school.id
    }
    getOrganizers(payload)
      .then(response => {
        const org = response.data.data
        this.companyName = _.get(org, "short_name", "")
        this.organizer = [
          {
            key: "Полное название",
            value: _.get(org, "full_name", "")
          },
          {
            key: "Краткое название",
            value: _.get(org, "short_name", "")
          },
          {
            key: "Руководитель",
            value: _.get(org, "manager_name", "")
          },
          {
            key: "Юридический адрес",
            value: _.get(org, "postal_address", "")
          },
          {
            key: "Фактический адрес",
            value: _.get(org, "legal_address", "")
          },
          {
            key: "Телефон",
            value: _.get(org, "phone", "")
          },
          {
            key: "Сайт",
            value: _.get(org, "site", "")
          },
          {
            key: "E-mail",
            value: _.get(org, "mail", "")
          },
          {
            key: "ИНН",
            value: _.get(org, "inn", "")
          },
          {
            key: "КПП",
            value: _.get(org, "kpp", "")
          },
          {
            key: "Лицевой счет",
            value: _.get(org, "personal_account", "")
          },
          {
            key: "Расчетный счет",
            value: _.get(org, "settlement_account", "")
          },
          {
            key: "Банк",
            value: _.get(org, "bank", "")
          },
          {
            key: "БИК",
            value: _.get(org, "bic", "")
          },
          {
            key: "ОГРН",
            value: _.get(org, "ogrn", "")
          },
          {
            key: "Корр. счет",
            value: _.get(org, "correspondent_account", "")
          }
        ]
      })
      .finally(() => store.commit("setShowPreloader", false))
  },
  data() {
    return {
      companyName: "",
      organizer: [],
      SchoolOferta: false
    }
  },
  computed: {
    ...mapGetters([ "currentUser" ])
  },
  methods: {
    ...mapMutations([ "setShowPreloader" ])
  }
}
</script>
