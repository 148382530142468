<script>
import WithTooltip from '@/components/common/Tooltip/Tooltip.vue'
import Table from '@/ui-components/Table.vue'
import store from '@/store'
import moment from 'moment'
import Vue from 'vue'
import notify from '@/utils/notifications/toastNotification.vue'
import AnswerModal from '@/components/moduleadmin/FAQ/components/AnswerModal.vue'
import { fetchTicketList } from '@/router/routes/superadmin'
import { mapGetters } from 'vuex'

export default {
  name: 'SuperAdminFAQView',

  components: {
    AnswerModal,
    WithTooltip,
    Table
  },

  data() {
    return {
      title: 'Центр ответов и FAQ',
      active: 0,
      params: {},
      search: '',
      statusFilter: null,
      date: null,
      menu: false,
      isShowAnswerModal: false,
      currentTicket: null,
      expand: false,
      unanswered: null,

      headers: [
        {
          text: 'Имя',
          value: 'sender',
          class: 'px-2',
          align: 'left',
          sortable: false
        },
        {
          text: 'Вопрос',
          value: 'question',
          class: 'px-2',
          align: 'left',
          sortable: false
        },
        {
          text: 'Дата запроса',
          value: 'created_at',
          class: 'px-2',
          align: 'left',
          sortable: false
        },
        {
          text: 'Ответ',
          value: 'answer',
          class: 'px-2',
          align: 'left',
          sortable: false
        },
        {
          text: 'Дата ответа',
          value: 'answer_at',
          class: 'px-2',
          align: 'left',
          sortable: false
        }
      ],

      pagination: {
        rowsPerPage: 5,
        page: 1,
        totalItems: 1,
        sortBy: null,
        descending: false
      },
      items: []
    }
  },
  computed: {
    ...mapGetters(['setting'])
  },
  methods: {
    async getSupportMessageQuestions() {
      const { rowsPerPage: page_size, page } = this.pagination
      const answer = this.statusFilter === 'all' ? null : this.statusFilter
      const payload = { params: { page_size, page, answer } }
      try {
        const {
          data: { data }
        } = await fetchTicketList(payload)
        this.pagination.totalItems = data.count
        this.items = data.results
        this.unanswered = data.unanswered
      } catch (e) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    formatTime(item) {
      return moment(item).format(Vue.config.format.serverDateFull)
    },
    // TODO можно вынести в helpers
    async copyID(id) {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(id)
        notify.info(`ID: ${id} скопирован в буфер обмена`)
      } else {
        const textarea = document.createElement('textarea')
        textarea.value = id
        textarea.style.position = 'absolute'
        textarea.style.left = '-99999999px'
        document.body.prepend(textarea)
        textarea.select()

        try {
          document.execCommand('copy')
          notify.info(`ID: ${id} скопирован в буфер обмена`)
        } catch (err) {
          console.log(err)
        } finally {
          textarea.remove()
        }
      }
    },

    createAnswer(item) {
      this.currentTicket = item
      this.isShowAnswerModal = true
    },
    closeModal() {
      this.isShowAnswerModal = false
      this.currentTicket = null
    }
  },

  watch: {
    pagination() {
      if (this.pagination.page !== 1) {
        this.params.page = this.pagination.page
      } else {
        delete this.params?.page
      }

      if (this.pagination.rowsPerPage !== 10) {
        this.params.page_size = this.pagination.rowsPerPage
      } else {
        delete this.params?.page_size
      }

      this.getSupportMessageQuestions()
    },
    statusFilter() {
      this.pagination.page = 1
      this.getSupportMessageQuestions()
    }
  },

  async beforeMount() {
    if (!this.setting('support_chat_plugin')) {
      await this.$router.replace('/moduleadmin')
    }
  },

  async mounted() {
    if (this.setting('support_chat_plugin')) {
      await this.getSupportMessageQuestions()
    }
  }
}
</script>

<template>
  <v-container fluid class="fs-organizer-container">
    <v-layout class="fs-dish" row mb-4>
      <v-flex md9>
        <h1>{{ title }}</h1>
      </v-flex>
    </v-layout>

    <v-tabs v-model="active" style="margin-top: 25px" color="transparent">
      <v-tab v-for="n in ['Центр ответов']" :key="n" ripple>
        {{ n }}
      </v-tab>

      <v-tab-item>
        <v-btn-toggle v-model="statusFilter" class="my-2">
          <v-flex class="px-2">
            <v-btn flat value="all">Все</v-btn>
          </v-flex>
          <v-flex class="px-2">
            <v-badge overlap color="error" v-model="unanswered">
              <template v-slot:badge>
                <span>{{ unanswered }}</span>
              </template>
              <v-btn flat :value="false">без ответа</v-btn>
            </v-badge>
          </v-flex>

          <v-flex class="px-2">
            <v-btn flat :value="true">С ответом</v-btn>
          </v-flex>
        </v-btn-toggle>

        <v-data-table
          :headers="headers"
          :items="items"
          :rows-per-page-items="[5, 10, 25]"
          :total-items="pagination.totalItems"
          :pagination.sync="pagination"
          :expand="expand"
          class="elevation-1"
          disable-initial-sort
        >
          <template v-slot:items="props">
            <tr
              @click="props.expanded = !props.expanded"
            >
              <td class="px-2 faq-table-td-text">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-chip
                      v-on="on"
                      outline
                      small
                      @click.stop="copyID(props.item.user.id)"
                      color="grey darken-3"
                    >
                      {{ props.item.user.full_name }}
                    </v-chip>
                  </template>
                  <span>Скопировать ID пользователя</span>
                </v-tooltip>
              </td>
              <td class="px-2 faq-table-td-text text-slice">
                {{ props.item.question }}
              </td>

              <td class="px-2 faq-table-td-text text-slice">
                {{
                  props.item.created_at
                    ? formatTime(props.item.created_at)
                    : null
                }}
              </td>

              <td
                class="px-2 faq-table-td-text text-slice"
                :class="{ 'text-center': !props.item.answer }"
              >
                <template v-if="!props.item.answer">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        class="my-0 mx-auto pa-0 text-right"
                        small
                        v-on="on"
                        @click.stop="createAnswer(props.item)"
                      >
                        <v-icon small color="cyan">question_answer</v-icon>
                      </v-btn>
                    </template>
                    <span>Ответить</span>
                  </v-tooltip>
                </template>
                <template v-else>
                  {{ props.item.answer }}
                </template>
              </td>

              <td class="px-2 faq-table-td-text text-slice">
                {{
                  props.item.answer_at ? formatTime(props.item.answer_at) : null
                }}
              </td>
            </tr>
          </template>

          <template v-slot:expand="props">
            <v-card class="elevation-3 px-3" color="grey lighten-5">
              <v-card-text primary-title class="d-flex flex-column align-start" style="gap: 12px">
                <div>
                  <div class="title py-1">Вопрос:</div>
                  <span style="word-wrap: break-word; white-space: pre-line">
                    {{ props.item.question }}
                  </span>
                </div>
                <div>
                  <div class="title  py-1">Ответ:</div>
                  <template v-if="!props.item.answer">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          class="my-0 mx-auto pa-0"
                          large
                          v-on="on"
                          @click.stop="createAnswer(props.item)"
                        >
                          <v-icon large color="cyan">question_answer</v-icon>
                        </v-btn>
                      </template>
                      <span>Ответить</span>
                    </v-tooltip>
                  </template>
                  <template v-else>
                    <span style="word-wrap: break-word; white-space: pre-line">
                      {{ props.item.answer }}
                    </span>
                  </template>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>

    <answer-modal
      :show-modal="isShowAnswerModal"
      :item="currentTicket"
      v-on:closeModal="closeModal"
      v-on:send="getSupportMessageQuestions"
    ></answer-modal>
  </v-container>
</template>

<style scoped>
.faq-table-td-text {
  max-width: 200px;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.faq-table-td-btns {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}

.text-slice {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  word-wrap: break-word;
}
</style>
