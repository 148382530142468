<template>
  <v-dialog
    :value="dialog"
    :max-width="width"
    :fullscreen="fullscreenMode"
    ref="scrollWrapper"
    @input="$emit('update:dialog', false)"
  >
    <v-card>
      <v-container
        class="fs-admin-container"
        fluid
      >
        <v-layout
          class="fs-archive"
          row
          mb-3
        >
          <v-flex>
            <h1>{{ title }}</h1>
          </v-flex>
        </v-layout>
        <slot name="content"></slot>
      </v-container>
      <v-card-actions class="fs-admin-container">
        <v-layout
          row
          align-center
          justify-end
          fill-height
        >
	        <with-tooltip text="Закрыть окно">
	          <v-btn
	            flex
	            color="primary"
	            @click="closeDialog()"
	          >{{ closeBtnText }}</v-btn>
	        </with-tooltip>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Title from '@/ui-components/Title'
import WithTooltip from "@/components/common/Tooltip/Tooltip.vue"

export default {
  name: 'ViewModal',
  components: {
	  WithTooltip,
    Title
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    closeBtnText: {
      type: String,
      default: 'Закрыть'
    },
    fullscreenMode: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 1200
    },
    title: {
      type: String,
	    default: ''
    }
  },
  data() {
    return {
      clientHeight: null,
      scrollTop: null,
      scrollHeight: null,
      scrollArea: null
    }
  },
  mounted() {
    this.scrollArea = this.$refs.scrollWrapper.$children[0].$el
    this.scrollArea.addEventListener('scroll', this.runOnScroll)
  },
  destroyed() {
    this.scrollArea.removeEventListener('scroll', this.runOnScroll)
  },
  methods: {
    closeDialog() {
      this.$emit('update:dialog', false)
    },
    runOnScroll() {
      const height = this.scrollArea.clientHeight + this.scrollArea.scrollTop
      if (height >= this.scrollArea.scrollHeight) {
        this.$emit('scrollOnBottom')
      }
    }
  }
}
</script>
