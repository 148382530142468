// TODO: можно перегрузить типом пересчёта type === rub/cent)))
// но вообще это метод, потому что миксины не для этого созданы
// src\helpers\converter.js
export default {
  methods: {
    /**
     * переводит копейки в рубли
     * @param num
     * @returns {number}
     */
    convertToRubles(num) {
      return num / 100
    },
    /**
     *
     * @param num
     * @returns {number}
     */
    convertToCents(num) {
      return +(num * 100).toFixed()
    },
    /**
     *
     * @param num
     * @returns {number}
     */
    roundRubles(num) {
      return Math.round(num * 100) / 100
    }
  }
}

