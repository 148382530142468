<template>
  <div class="attach-wrap">
    <div class="attach-actions" v-if="multiple && !readonly">
      {{ value.length === 0 ? 'Добавьте фото' : '' }}
      <v-btn icon :disabled="!(value.length < limit)" @click="onPickFile">
        <v-icon>add</v-icon>
      </v-btn>
      <span v-show="!hideLimitHint" :style="value.length === limit ? 'color: green;' : 'color: #ccc;'">
        <b>{{ value.length }} / {{ limit }}</b>
      </span>
    </div>

    <div class="attach-actions" v-else-if="!multiple && !readonly">
      {{ value.length === 0 ? 'Добавьте фото' : '' }}
      <v-btn icon v-show="value.length < 1" @click="onPickFile">
        <v-icon>add</v-icon>
      </v-btn>
    </div>

    <div class="attach-images">
      <div v-for="(image, idx) in value" :key="idx" class="attach-one-image">
        <div class="overlay" @click.prevent="showImageDetail(idx)">
          <div class="image-remove" @click.prevent.stop="removeImage(idx)" v-if="!readonly">
            <v-icon color="color">close</v-icon>
          </div>
        </div>
        <img :src="getMediaUrl(image.thumb)" alt="attach-images">
      </div>
    </div>

    <image-preview-modal v-model='isShowImageDetail' :src='getMediaUrl(imageDetailSrc)'/>

    <input
      :key="updatedKey"
      @change="onFilePicked"
      accept="image/*"
      type="file"
      style="display: none"
      ref="fileInput"
    >
  </div>
</template>

<script>
import { getMediaUrl } from '@/utils/media'
import ImagePreviewModal from '@/components/common/QualityControl/modal/ImagePreviewModal.vue'

export default {
  name: 'AttachImages',
  components: { ImagePreviewModal },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 5
    },
    value: {
      type: Array,
      default: () => []
    },
    hideLimitHint: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    updatedKey: 0,
    isShowImageDetail: false,
    imageDetailSrc: null
  }),
  methods: {
    onPickFile () {
      this.$refs.fileInput.click()
    },
    removeImage(idx) {
      const removeImage = this.value[idx]
      !removeImage.isNew ? this.$emit('deleteImage', removeImage) : ''
    },
    showImageDetail(idx) {
      this.imageDetailSrc = this.value[idx].image ? this.value[idx].image : this.value[idx].thumb
      this.isShowImageDetail = true
    },
    closeModal() {
      this.isShowImageDetail = false
      this.imageDetailSrc = null
    },
    getMediaUrl,
    async onFilePicked (event) {
      const files = event.target.files

      if (files[0] !== undefined) {
        this.fileName = files[0].name
        if (this.fileName.lastIndexOf('.') <= 0) return

        // If valid, continue
        const fr = new FileReader()
        fr.readAsDataURL(files[0])

        await fr.addEventListener('load', () => {
          this.$emit('uploadImage', files[0])
        })
        this.updatedKey++
      }
    },
  }
}
</script>

<style scoped>
  .attach-wrap {
    display: flex;
    align-items: center;
  }
  .attach-images {
    display: flex;
    margin: 0 10px;
  }
  .attach-one-image {
    width: 100px;
    height: 59px;
    margin: 5px;
    position: relative;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0, 0, 0, .5);
  }
  .attach-one-image:hover .overlay {
    opacity: 1;
    transition: all .3s ease;
  }
  .overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all .3s ease;
    padding: 5px;
  }
  .attach-one-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .image-remove {
    text-align: right;
    display: block;
  }
  .image-remove i {
    color: #fff;
  }
</style>
