<template>
  <v-container fluid class="fs-organizer-container">
		<div class="page-head__common">
      <!-- <h1>Справочник по продуктам буфета</h1> -->
      <v-spacer></v-spacer>
      <v-btn
        flat
        @click="handleClickCreateCategory"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Добавить продукт в справочник</v-btn>
    </div>

    <v-layout class="fs-organizer-school-result" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="items.results"
          hide-actions
          no-data-text="Нет продуктов в справочнике"
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.created_at | transformDate }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.category.title }}</td>
              <td>{{ props.item.price }}</td>
              <td>{{ props.item.count }}</td>
              <td>
                <v-flex row>
                 <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="handleEdit(props.item.id)"
                      >edit</v-icon>
                    </template>
                    <span>Редактирование</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="handleDelete(props.item.id)"
                        class="fs-active-icon"
                      >delete</v-icon>
                    </template>
                    <span>Удалить</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
     <v-pagination
      v-if="pagination_count > 1"
      @input="handlePaginationClick"
      :value="paginate.page"
      :length="pagination_count"
      :total-visible="7"
    ></v-pagination>

    <v-dialog width="90%" v-model="isShowModal" persistent>
      <ProductDictDetail
        v-if="isShowModal"
        :productId="productId"
        :isCreate="isCreate"
        @update="fetchProducts"
        @close="closeModal"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { getBuffetProductDict, deleteBuffetProductDict } from '@/router/routes/organizer.js'
import { mapMutations } from 'vuex'
import ProductDictDetail from '@/components/organizer/BuffetProductDict/ProductDictDetail.vue'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'BuffetProductDict',
  components: { ProductDictDetail },
  data: () => ({
    isShowModal: false,
    isCreate: true,
    headers: [
      { text: 'Дата создания', value: 'created_at' },
      { text: 'Наименование', value: 'name' },
      { text: 'Категория', value: 'category' },
      { text: 'Цена', value: 'price' },
      { text: 'Кол-во', value: 'count' }
    ],
    paginate: {
      page: 1,
      page_size: 10
    },
    productId: null,
    items: {
      count: 0,
      results: []
    }
  }),
  computed: {
    pagination_count() {
      return Math.ceil(this.items.count / this.paginate.page_size) || 0
    },
  },
  mounted() {
    this.fetchProducts()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    handleClickCreateCategory() {
      this.isCreate = true
      this.isShowModal = true
    },
    handleEdit(id) {
      this.isShowModal = true
      this.isCreate = false
      this.productId = id
    },
    handleDelete(id) {
      deleteBuffetProductDict(id)
        .then(res => {
          notify.success(`Продукт из справочника удалён успешно`)
          if (this.items.results.length === 1 && this.paginate.page > 1) {
            this.paginate.page--
          }
          this.fetchProducts()
        })
        .catch(err => {
          console.error(err)
          // notify.error(`Ошибка удаления продукта`) // backend handler added (exception_message)
        }).finally(() => {
          this.setShowPreloader(false)
        })
    },
    handlePaginationClick(page) {
      this.paginate.page = page
      this.fetchProducts()
    },
    closeModal() {
      this.isShowModal = false
      this.productId = null
    },
    fetchProducts() {
      getBuffetProductDict({ params: { ...this.paginate } })
        .then(res => {
          this.items = res.data
        })
        .catch(err => {
          console.error(err)
          // notify.error(`Ошибка загрузки продуктов`) // backend handler added (exception_message)
        }).finally(() => {
          this.setShowPreloader(false)
        })
    }
  }
}
</script>
