<template>
  <div
    class="sc-header"
    :style="{ background: colors.header.bg, color: colors.header.text }"
  >
    <template v-if="isShowChatList">
      <img v-if="imageUrl" class="sc-header--img" :src="imageUrl" alt="" />
      <div class="sc-header--title">{{ title }}</div>
    </template>
    <div class="sc-header--back" v-else>
      <v-btn round dark :color="colors.header.bg" @click="handleBack">
        <v-icon dark left>arrow_back</v-icon>
        назад к чатам
      </v-btn>
    </div>
    <div class="sc-header--close-button">
      <v-btn v-if="showCloseButton" @click="closeChat" icon flat dark fab small>
        <v-icon>close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { actions, getters } from '../store'

export default {
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    showCloseButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    ...actions
  },
  computed: {
    ...getters
  }
}
</script>

<style scoped>
.sc-header {
  min-height: 75px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  padding: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.sc-header--img {
  max-width: 48px;
  max-height: 48px;
  border-radius: 50%;
  align-self: center;
  padding: 2px;
  width: 100%;
  height: 100%;
}

.sc-header--title {
  align-self: center;
  padding: 10px;
  flex: 1;
  user-select: none;
  font-size: 20px;
}
.sc-header--back {
  flex: 1;
  align-self: center;
}

.sc-header--title.enabled {
  cursor: pointer;
  border-radius: 5px;
}

.sc-header--title.enabled:hover {
  box-shadow: 0px 2px 5px rgba(0.2, 0.2, 0.5, 0.1);
}

.sc-header--close-button {
  justify-self: flex-end;
  align-self: center;
  border-radius: 50%;
  margin: 0;
  padding: 0;
}

@media (max-width: 450px) {
  .sc-header {
    border-radius: 0px;
  }
}
</style>
