export default {
  minCount: v => v > 0 || 'Значение должно быть больше 0',
  maxCount: v => v <= 365 || 'Значение должно быть меньше 365',
  settingsMealPeriod: v =>
    (v >= 14 && v % 7 == 0) ||
    'Значение должно быть больше или равно 14 и кратным 7',
  settingsPeriodWeek: v => v % 7 == 0 || 'Значение должно быть кратным 7',
  requiredTextfield: v =>
    (!!v && v !== ' ' && v.length > 0) || 'Обязательное поле!'
}
