<template>
  <v-container fluid>
    <v-layout class="fs-dish" row>
      <div class="title-help">
        <div class="d-flex pt-2">
          <p class="text text-center">Новости</p>
          <p>
            <context-help-modal :context-key="contextKeys.supplier.news"/>
          </p>
        </div>
      </div>
    </v-layout>
    <v-flex>
      <v-data-table
        :headers="headers"
        :items.sync="items"
        hide-actions
        no-data-text="Нет новостей"
        item-key="id"
      >
        <template v-slot:items="props">
          <tr>
            <td class="cursor-pointer" @click="() => {showDialog(props.item.title, props.item.text)}">{{ props.item.title }}</td>
            <td class="cursor-pointer" @click="() => {showDialog(props.item.title, props.item.text)}">{{ new Date(props.item.date).toLocaleDateString() }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    @click="showDialog(props.item.title, props.item.text)"
                  ></v-icon>
                </template>
                <span>Подробнее</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>

    <v-dialog v-model="dialog" width="60%">
      <v-card-title class="rounded-top text-h5 grey lighten-2">
        <h3>{{ dialogTitle }}</h3>
      </v-card-title>
      <v-card class="rounded-zero">
        <div class="pl-3 pt-3" v-html="dialogText"></div>
        <v-card-actions class="d-flex justify-content-end align-items-center flex-wrap">
          <v-btn class="rounded-bottom mt-4"  color="primary" @click="() => {this.dialog = false}">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue"
import { getUpdatesNews } from "@/router/routes/admin"
import store from "@/store"
import { TextEditorModal } from "@/components/common"
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal"

export default {
  name: 'DashboardNews',
  components: {
    TextEditorModal,
    ContextHelpModal
  },
  data() {
    return {
      headers: [
        { text: 'Заголовок', value: 'title', sortable: false },
        { text: 'Дата публикации', value: 'data', sortable: false },
      ],
      contextKeys: Vue.config.contextHelp,
      title: 'Новости',
      items: [],
      dialog: false,
      dialogTitle: '',
      dialogText: '',
    }
  },
  mounted() {
    this.loadNews()
  },
  methods: {
    async loadNews() {
      this.dataTableLoaded = false
      this.items = []
      try {
        const response = await getUpdatesNews()
        this.items = response.data
        this.dataTableLoaded = true
      } catch (error) {
        // notify.error('Ошибка загрузки списка обновлений') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    showDialog(title, text) {
      this.dialogTitle = title
      this.dialogText = text
      this.dialog = true
    }
  }
}
</script>

<style scoped>

.rounded-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.rounded-bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.rounded-zero {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

</style>

