<template>
  <v-card flat>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-actions
      class="elevation-1"
    >
      <template v-slot:items="props">
          <tr>
            <td>{{ props.item.name }}</td>
            <td>
              <v-checkbox v-model="props.item.value1" hide-details :label="`${props.item.value1 ? 'Да': 'Нет'}`" />
            </td>
            <td>
              <v-checkbox v-model="props.item.value2" hide-details :label="`${props.item.value2 ? 'Да': 'Нет'}`" />
            </td>
          </tr>
        </template>
    </v-data-table>
    <v-card-actions>
      <v-btn
        flat
        @click="update"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Form3',
  data() {
    return {
      headers: [
        { text: 'Вид транспорта', sortable: false },
        { text: 'Да/Нет', sortable: false },
        { text: 'Санитарный паспорт (имеется/отсутствует)', sortable: false },
      ],
      formId: null,
      names: {
        "not_specialized_school_transport": "Специализированный транспорт отсутствует",
        "specialized_school_transport": "Специализированный транспорт школы",
        "specialized_school_transport_food_suppliers": "Специализированный транспорт организаций - поставщиков пищевых продуктов",
        "specialized_school_transport_serving_school": "Специализированный транспорт организации, обслуживающей школу",
        "specialized_transport_foodmeals_serving_the_school": "Специализированный транспорт организации общественного питания, обслуживающей школу"
      },
      items: [
        { name: 'Специализированный транспорт школы', value1: false, value2: false, id: "specialized_school_transport" },
        { name: 'Специализированный транспорт организации общественного питания, обслуживающей школу', value1: false, value2: false, id: "specialized_transport_foodmeals_serving_the_school" },
        { name: 'Специализированный транспорт организаций - поставщиков пищевых продуктов', value1: false, value2: false, id: "specialized_school_transport_food_suppliers" },
        { name: 'Специализированный транспорт организации, обслуживающей школу', value1: false, value2: false, id: "specialized_school_transport_serving_school" },
        { name: 'Специализированный транспорт отсутствует', value1: false, value2: false, id: "not_specialized_school_transport" },
      ],
    }
  },
  mounted() {
    this.getFoodblockTransport(this.foodBlockPassport.transport_sections.id)
      .then(result => this.setForm(result))
  },
  methods: {
    ...mapActions([ 'getFoodblockTransport', 'updateFoodBlockTransport' ]),
    update() {
      this.updateFoodBlockTransport({ id: this.formId, data: { result: this.transform_data_to_back(this.items), passport: this.foodBlockPassport.id } })
        .then(result => this.setForm(result))
    },
    transform_data_to_front(backendData) {
      const names = this.names
      const res = Object.keys(backendData).map(function(key) {
        const { is_exist, is_exist_sanitary_transport } = backendData[key]
        return {
          name: names[key],
          id: key,
          value1: is_exist,
          value2: is_exist_sanitary_transport
        }
      })
      return res
    },  
    transform_data_to_back(frontendData) {
      const res = {}
      frontendData.forEach(el => {
        res[el.id] = {
          is_exist: el.value1,
          is_exist_sanitary_transport: el.value2
        }
      })
      return res
    },  
    setForm(data) {
      this.formId = data.id
      const items = data.result && data.result["specialized_school_transport"] ? data.result : this.items
      this.items = this.transform_data_to_front(items)
    },
  },
  computed: {
    ...mapGetters([ 'foodBlockPassport' ])
  },
  
}
</script>