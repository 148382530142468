<template>
  <v-container fluid class="white">
    <v-layout justify-space-around>
      <v-flex>
        <h2>{{title}}</h2>
        <v-layout justify-end>
          <with-tooltip text="Получить список лицевых счетов питающихся учеников">
            <v-btn
                class="primary mr-3"
                :loading="btnUnloadLoaded"
                :disabled="btnUnloadLoaded"
                @click="downloadBudgetIds"
            >выгрузить счета учеников</v-btn>
          </with-tooltip>
          <with-tooltip text="Распечатать информацию по имеющимся в системе платежам">
            <v-btn
                class="primary"
                :loading="btnPrintLoaded"
                :disabled="btnPrintLoaded"
                @click="downloadPrintFile"
            >печать</v-btn>
          </with-tooltip>
        </v-layout>
        <v-layout
          row
          mt-3
          mb-3
          justify-space-around
          align-center
        >
          <v-layout wrap row>
            <v-flex md12 mr-3>
              <DatePickersSmart
                :normalStyle="true"
                :startDate.sync="dateStart"
                :endDate.sync="dateEnd"
                @changeDate="changeDate"
              />
            </v-flex>
            <v-flex md3>
              <v-text-field
                label="Лицевой счёт:"
                :value="accountIdValue"
                @input="searchAccountId"
              />
            </v-flex>
          </v-layout>
        </v-layout>
        <v-layout class="fs-complex-result" mb-3>
          <v-flex>
            <v-data-table
              :headers="headers"
              :items="items"
              hide-actions
              :disable-initial-sort="true"
              no-data-text="Платежи отсутствуют"
              class="elevation-1"
              item-key="name"
            >
              <template v-slot:items="props">
                <tr>
                  <td>{{ props.item.date }}</td>
                  <td>{{ props.item.accountId }}</td>
                  <td>{{ props.item.fullName }}</td>
                  <td>{{ props.item.schoolClass }}</td>
                  <td>{{ props.item.short_name }}</td>
                  <td>{{ props.item.amount }}</td>
                  <td><span>{{ props.item.code }}</span></td>
                  <td>{{ props.item.type }}</td>
                  <td>{{ props.item.payment_order }}</td>
                  <td>{{ props.item.payment_date }}</td>
                  <td>{{ props.item.bic }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment'
import Vue from 'vue'
import { mapMutations } from 'vuex'
import store from '@/store'
import scrollable from '@/mixins/scrollable'
import {
  getBudgetTaskId,
  getBudgetStatus,
  getBudgetFile,
  printFileTaskId,
  getPrintFileStatus,
  getPrintFile,
  getInvoicesHistory
} from '@/router/routes/organizer'
import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownloadWithProgressBar'
import { DatePickersSmart } from '@/components/common'
import numable from '@/mixins/balancable'
import WithTooltip from "@/components/common/Tooltip/Tooltip"
import notify from '@/utils/notifications/toastNotification'

// import downloadFile from "@/helpers/downloadFile"
// import _ from "lodash"

export default {
  name: 'OrganizerBillHistory',
  mixins: [ scrollable, numable ],
  components: {
    WithTooltip,
    DatePickersSmart
  },
  data() {
    return {
      dateStart: moment()
        .subtract('days', 1)
        .format(Vue.config.format.serverDate),
      dateEnd: moment()
        .add('days', 1)
        .format(Vue.config.format.serverDate),
      headers: [
        { text: 'Дата', value: 'date' },
        { text: 'ЛС', value: 'accountId' },
        { text: 'ФИО', value: 'fullName' },
        { text: 'Класс', value: 'schoolClass' },
        { text: 'Школа', value: 'school' },
        { text: 'Сумма', value: 'amount' },
        { text: 'Код транзакции', value: 'code' },
        { text: 'Тип платежа', value: 'type' },
        { text: 'ПП', value: 'payment_order' },
        { text: 'Дата платежа', value: 'payment_date' },
        { text: 'БИК', value: 'bic' }
      ],
      title: 'Архив загруженных платежей',
      accountIdValue: null,
      items: [],
      accountsArray: [],
      btnUnloadLoaded: false,
      btnPrintLoaded: false,
      LIMIT: Vue.config.general.limit
    }
  },
  created() {
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    searchAccountId: _.debounce(async function(value) {
      this.accountIdValue = value
      this.resetData()
      await this.loadMore()
    }, 1500),
    resetData() {
      this.offset = 0
      this.items = []
    },
    changeDate(payload) {
      this.resetData()
      this.loadMore()
    },
    async loadMore() {
      const payload = {
        params: {
          limit: this.LIMIT,
          offset: this.offset,
          date_start: moment(this.dateStart).format(
            Vue.config.format.pythonDate
          ),
          date_end: moment(this.dateEnd).format(Vue.config.format.pythonDate),
          account_id: this.accountIdValue
        }
      }
      try {
        const response = await getInvoicesHistory(payload)
        const {
          data: { data: responseDataArray }
        } = response

        const itemsArray = responseDataArray.map(item => {
          const {
            amount = null,
            transactionCode = null,
            paymentType = '',
            accountId = null,
            schoolClass = null,
            short_name = null,
            fullName = null,
            payment_order = null,
            payment_date = '',
            bic = null
          } = item
          // const studentID = _.get(item, "item.id", null)
          // const studentName = _.get(item, "item.fullName", "")
          const itemDate = moment(
            item.date,
            Vue.config.format.pythonDateFull
          ).format(Vue.config.format.date)
          return {
            accountId,
            schoolClass,
            amount: amount ? this.convertToRubles(amount) : null,
            code: transactionCode,
            type: paymentType,
            date: itemDate,
            short_name,
            fullName,
            payment_order,
            payment_date,
            bic
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        notify.error('Ошибка загрузки счетов')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
      this.btnUnloadLoaded = false
      this.btnPrintLoaded = false
    },
    async downloadPrintFile() {
      this.btnPrintLoaded = true
      const PAYLOAD = {
        start_date: moment(this.dateStart).format(Vue.config.format.pythonDate),
        end_date: moment(this.dateEnd).format(Vue.config.format.pythonDate)
      }
      const callback = this.resetPageFileLoaded
      try {
        await asyncReqestsForDownload(
          printFileTaskId,
          getPrintFileStatus,
          getPrintFile,
          PAYLOAD,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки документа')
      } finally {
        this.resetPageFileLoaded()
      }
    },
    async downloadBudgetIds(data) {
      this.btnUnloadLoaded = true
      const PAYLOAD = null
      const callback = this.resetPageFileLoaded
      try {
        await asyncReqestsForDownload(
          getBudgetTaskId,
          getBudgetStatus,
          getBudgetFile,
          PAYLOAD,
          callback
        )
      } catch (error) {
        notify.error('ERROR asyncReqestsForDownload')
      } finally {
        this.resetPageFileLoaded()
      }
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
