import { getPupilBuffetTransactions, createPupilBuffetTransactions } from '@/router/routes/common'

export default {
  actions: {
    async getPupilBuffetTransactions(ctx, payload) {
      const filter = ctx.getters.buffet_transactions_filters
      await getPupilBuffetTransactions({
        ...ctx.getters.buffet_transactions_pagination,
        status: filter.status.value,
        amount_type: filter.amount_type.value,
        pupil: payload.pupil
      })
        .then(res => {
          ctx.commit('set_buffet_transactions', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },

    async createPupilBuffetTransactions(ctx, transaction) {
      await createPupilBuffetTransactions(transaction)
        .then(() => {
          return Promise.resolve()
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
  },
  mutations: {
    set_buffet_transactions(state, transactions) {
      state.buffet_transactions = transactions
    },
    set_buffet_transactions_pagination(state, paginate) {
      state.buffet_transactions_pagination = {
        ...state.buffet_transactions_pagination,
        ...paginate
      }
    },
    clear_buffet_transactions_filters(state, paginate) {
      state.buffet_transactions_filters = {
        status: { name: 'Все', value: null },
        amount_type: { name: 'Все', value: null }
      }
    },
  },
  getters: {
    buffet_transactions(state) {
      return state.buffet_transactions
    },
    buffet_transactions_pagination(state) {
      return state.buffet_transactions_pagination
    },
    buffet_transactions_filters(state) {
      return state.buffet_transactions_filters
    }
  },
  state: {
    buffet_transactions: {},
    buffet_transactions_pagination: {
      page: 1,
      page_size: 10
    },
    buffet_transactions_filters: {
      status: { name: 'Все', value: null },
      amount_type: { name: 'Все', value: null }
    }
  }
}
