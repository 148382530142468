import Vue from 'vue'

export const getUpdatesNews = id => axios.get(Vue.config.server + '/' + Vue.config.api.parent.updates)


export const getBuffetBalance = id =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.parent.students.buffet.getBalance.replace('$id', id)
  )

export const transferMoney = PAYLOAD =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.parent.students.buffet.transferMoney.replace('$id', PAYLOAD.pupil_id),
    PAYLOAD.payload
  )

export const paymentComplete = order_id =>
  axios.put(
    Vue.config.server
      + '/'
      + Vue.config.api.parent.order.complete.replace('$order_id', order_id)
  )

export const createOrderQualityAssessments = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.parent.quality_assessments.all,
    payload
  )

export const getQualityAssessmentsDetail = id =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.parent.quality_assessments.one.replace('$id', id)
  )

export const createImageOrderQualityAssessments = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.parent.quality_assessments.image,
    payload
  )

export const getPrivacyPolicy = () =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.parent.privacy.get
  )

export const getChildrenOrders = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.parent.students.orders.all,
    { params: payload.params }
  )

export const getQualityAssessmentOrders = params =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.parent.quality_assessments.orders.all,
    { params }
  )


export const getOrderLink = (personId, amount, sberPay = false) =>
  axios.post(Vue.config.server + '/' + Vue.config.api.parent.order.link, {
    personId: personId,
    amount: amount,
    sberPay: sberPay
  })

export const checkSberAvailable = (personId) =>
  axios.post(Vue.config.server + '/' + Vue.config.api.parent.order.check, {
    personId,
    amount: 0
  })

export const getAllStudents = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.parent.students.all,
    payload
  )

export const transferBalance = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.parent.students.family_transfer_balance,
    payload.data
  )

export const getDishes = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.dishes.all.replace('$id', payload.id),
    payload
  )

export const getOneStudent = id =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.students.one.replace('$id', id)
  )

export const getFoodOrders = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.orders.all.replace('$id', payload.id),
    { params: payload.params }
  )

export const saveOrders = payload =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.orders.all.replace('$id', payload.id),
    payload.data
  )
export const saveTraffic = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.parent.traffic.replace('$id', payload.id),
    { 'items': payload.data }
  )
export const getTraffic = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.parent.traffic.replace('$id', payload.id),
    { params: payload.params }
  )

export const getSettings = id =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.settings.save.replace('$studentId', id)
  )

export const saveSettings = payload =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.settings.save.replace('$studentId', payload.id),
    payload.data
  )

export const getOrganizerInfo = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.organizer.replace('$id', payload.id),
    { params: payload.params }
  )

export const printInvoice = payload =>
  axios.get(Vue.config.server + '/' + Vue.config.api.parent.invoice.create, {
    params: payload.params,
    responseType: 'blob'
  })

export const getBuffetBalanceHistory = payload =>
  axios.get(
    Vue.config.server +
        '/' +
        Vue.config.api.parent.balance.buffetHistory.replace('$id', payload.id),
    { params: payload.params }
  )

export const getBalanceHistory = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.balance.history.replace('$id', payload.id),
    { params: payload.params }
  )
// agreement
export const getOffer = id =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.agreement.oferta.replace('$id', id)
  )

export const getRefund = id =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.refund.get.replace('$id', id),
    { responseType: 'blob' }
  )

export const signOffer = id =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.agreement.sign.replace('$id', id)
  )

// ADDITIONAL MENU
export const getAdditional = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.dishes.additional
        .replace('$pupil_pk', payload.pupil_pk)
        .replace('$order_pk', payload.order_pk)
  )
export const patchAdditional = payload =>
  axios.patch(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.dishes.additional
        .replace('$pupil_pk', payload.pupil_pk)
        .replace('$order_pk', payload.order_pk),
    payload.data
  )

export const getBalanceTaskId = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.balance.movingTaskId.replace(
        '$studentId',
        payload.studentId
      ),
    payload.data,
    { preloaderState: false }
  )

export const getBalanceFileStatus = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.balance.movingStatus
        .replace('$studentId', payload.studentId)
        .replace('$task_id', payload.task_id),
    { preloaderState: false }
  )

export const getBalanceFile = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.parent.balance.movingDownload
        .replace('$studentId', payload.studentId)
        .replace('$task_id', payload.task_id),
    { responseType: 'blob', preloaderState: false }
  )


export const getAdditionalFoods = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.parent.dishes.additionalDishes
      .replace('$pupil_pk', payload.pupil_pk)
  )
export const setAdditionalFoods = payload =>
  axios.patch(
    Vue.config.server +
    '/' +
    Vue.config.api.parent.dishes.setAdditionalDishes
      .replace('$pupil_pk', payload.pupil_pk)
      .replace('$order_pk', payload.order_pk),
    payload.data
  )

export const closeNotifications = id =>
  axios.put(
    Vue.config.server +
    '/' +
    Vue.config.api.parent.students.notifications.replace('$id', id),
    {},
    { preloaderState: false }
  )

export const getCashbackRequests = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.parent.students.cashback.replace('$id', payload.id),
    { params: payload.params }
  )


export const uploadCashbackRequest = (payload, id, config) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.parent.students.cashback.replace('$id', id),
    payload,
    config
  )


export const getCashbackPrefill = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.parent.students.cashback_prefill.replace('$id', payload.id),
    { params: payload, responseType: 'blob', preloaderState: false }
  )

export const getCashbackDocument = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.parent.students.cashback_doc.replace('$id', payload.id).replace('$doc_id', payload.docId),
    { responseType: 'blob', preloaderState: false }
  )


export const revokeCashbackDocument = payload =>
  axios.delete(
    Vue.config.server +
    '/' +
    Vue.config.api.parent.students.cashback_doc.replace('$id', payload.id).replace('$doc_id', payload.docId)
  )
