import Vue from 'vue'

const Auth = function() {
  this.user = null
  this.roles = {
    SCHOOL_MEAL_ADMIN: 'Administrator',
    TEACHER: 'Teacher',
    PARENT: 'Parent',
    DEPARTMENT: 'Municipality',
    ADMIN: 'ModuleAdmin',
    SUPPLIER: 'Organizer',
    DEPARTMENT_ADMIN: 'Supermunicipality',
    PUPIL: 'Pupil'
  }

  Object.defineProperty(this, 'isSupplier', {
    get: function() {
      return this.user.roles[0] === 'SUPPLIER'
    }
  })

  Object.defineProperty(this, 'isSubSupplierRole', {
    get: function() {
      const compare = code => Vue.config.general.supplierSubRoles.some(role => role.code === code)
      return this.user.roles.some(compare)
    }
  })

  this.setCurrentUser = function(user) {
    this.user = user
  }

  this.subSupplierIncludesMenu = function(role) {
    return [
      'SUB_SUPPLIER_COMPLECTS',
      'SUB_SUPPLIER_DAILY_MENU',
      'SUB_SUPPLIER_MENU_TEMPLATES'
    ].includes(role)
  }

  this.getLocation = function() {
    const location = {
      name: this.roles.ADMIN,
      params: null
    }

    Object.defineProperty(location, 'params', {
      get: function() {
        const excludeLocations = [ 'ModuleAdmin', 'Organizer' ]
        if (excludeLocations.includes(this.name)) {
          return null
        }
        else {
          return { reauth: true }
        }
      }
    })

    location.name = this.getLocationName()

    if (location.params === null) {
      delete location['params']
    }

    return location
  }

  this.getLocationName = function() {
    if (this.isSubSupplierRole) return this.roles.SUPPLIER

    for (const role in this.roles) {
      if (role === this.user.roles[0]) {
        return this.roles[role]
      }
    }
  }
}

export default Auth
