<template>
  <v-container fluid class="fs-admin-container">
    <v-flex md3 mb-5>
      <h1>{{title}}</h1>
    </v-flex>
    <v-form>
      <v-layout row justify-space-around wrap>
        <v-flex md5>
          <v-text-field label="Наименование" :value="dish.name || ''" readonly></v-text-field>
          <v-text-field
            label="Цена, руб (на 100г)"
            :value="convertToRubles(dish.price || 0)"
            readonly
          ></v-text-field>
          <v-text-field
            label="Энергетическая ценность, кКал/кДж (на 100г)"
            :value="dish.energy || 0"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex md5>
          <v-text-field label="Белки, г (на 100г)" :value="dish.protein || 0" readonly></v-text-field>
          <v-text-field label="Жиры, г (на 100г)" :value="dish.fats || 0" readonly></v-text-field>
          <v-text-field label="Углеводы, г (на 100г)" :value="dish.carbs || 0" readonly></v-text-field>
        </v-flex>
        <v-flex md5>
          <v-text-field label="Витамин A (мкг на 100г)" :value="dish.vitamins.a || 0" readonly></v-text-field>
          <v-text-field label="Витамин В1 (мкг на 100г)" :value="dish.vitamins.b1 || 0" readonly></v-text-field>
          <v-text-field label="Витамин C (мкг на 100г)" :value="dish.vitamins.c || 0" readonly></v-text-field>
          <v-text-field label="Витамин E (мкг на 100г)" :value="dish.vitamins.e || 0" readonly></v-text-field>
        </v-flex>
        <v-flex md5>
          <v-text-field
            label="Минеральные вещества Ca (мкг на 100г)"
            :value="dish.minerals.ca || 0"
            readonly
          ></v-text-field>
          <v-text-field
            label="Минеральные вещества Mg (мкг на 100г)"
            :value="dish.minerals.mg || 0"
            readonly
          ></v-text-field>
          <v-text-field
            label="Минеральные вещества Fe (мкг на 100г)"
            :value="dish.minerals.ca || 0"
            readonly
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row justify-space-around>
        <v-flex md11>
          <v-data-table
            :headers="headers"
            :items="dish.ageCategoires"
            hide-actions
            class="elevation-1 fs-admin-table"
          >
            <template v-slot:items="props">
              <tr>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.gramms }} гр.</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <!--<v-flex text-md-right>
        <v-btn class="fs-btn">отмена</v-btn>
        <v-btn class="fs-btn primary fs-form-footer__btn">сохранить</v-btn>
      </v-flex>-->
      <v-layout class="fs-form-footer">
        <v-flex text-md-right mt-5 pt-3>
          <v-btn
            flat
            class="fs-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active"
            @click="$router.go(-1)"
          >Назад</v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import { getOneDish } from "@/router/routes/admin"
import store from "@/store"
import AdminDishFactory from "@/factories/admin/dish-factory"
import numable from "@/mixins/balancable"

export default {
  name: "DishView",
  mixins: [ numable ],
  created() {
    const payload = {
      id: this.$route.params.id,
      schoolId: this.currentUser.school.id
    }
    getOneDish(payload)
      .then(response => {
        const dish = response.data.data
        this.dish = {
          id: dish.id,
          name: dish.name,
          energy: dish.energyValue || 0,
          protein: dish.protein || 0,
          fats: dish.fat || 0,
          carbs: dish.carbohydrates || 0,
          price: dish.price || 0,
          isAdditional: dish.isAdditional,
          vitamins: {
            a: dish.vitaminA || 0,
            b1: dish.vitaminB1 || 0,
            c: dish.vitaminC || 0,
            e: dish.vitaminE || 0
          },
          minerals: {
            ca: dish.miniralCa || 0,
            fe: dish.miniralFe || 0,
            mg: dish.miniralMg || 0
          },
          ageCategoires: [
            {
              id: 1,
              alias: "weightPrimary",
              name: "7-11 лет",
              gramms: dish.weightPrimary || 0
            },
            {
              id: 2,
              alias: "weightPrimary",
              name: "7-11 лет",
              gramms: dish.weightMiddle || 0
            }
          ]
        }
      })
      .finally(() => store.commit("setShowPreloader", false))
  },
  data() {
    return {
      dish: new AdminDishFactory(),
      headers: [
        { text: "Возрастная категория", value: "age-category" },
        { text: "Выход блюда, г", value: "gramms" }
      ]
    }
  },
  computed: {
    ...mapGetters([ "currentUser" ]),
    title() {
      return `Блюдо ${this.dish.id}`
    }
  }
}
</script>
