<template>
  <v-app :class="{ 'another-theme': darkTheme }">
    <notifications class="notifications" position="top right" />

    <div
      v-if="asyncLoadFile"
      class="fileLoadBlock"
    >
      <v-progress-circular
        color="cyan darken-2"
        indeterminate
      />
      <span class="loadText">Идёт загрузка файла</span>
    </div>


    <div
        v-if="asyncLoadFileWithProgressBar"
        class="fileLoadBlock mt-2"
    >
      <v-card>
        <v-card-text class="py-1 px-2">
          <v-progress-circular
              v-if="asyncLoadFileLoaderProgressBarValue === 0"
              :rotate="-90"
              :size="60"
              :width="7"
              color="primary"
              indeterminate
          >

          </v-progress-circular>
          <v-progress-circular
              v-else
              :rotate="-90"
              :size="60"
              :width="7"
              :value="asyncLoadFileLoaderProgressBarValue"
              color="primary"
          >
            {{ asyncLoadFileLoaderProgressBarValue }}%
          </v-progress-circular>
          <span class="loadText">Идёт загрузка файла</span>
        </v-card-text>
      </v-card>
    </div>

      <div
              v-if="asyncRequestWithProgressBar"
              class="fileLoadBlock mt-2"
      >
          <v-card>
              <v-card-text class="py-1 px-2">
                  <v-progress-circular
                          v-if="asyncRequestWithProgressBarValue === 0"
                          :rotate="-90"
                          :size="60"
                          :width="7"
                          color="primary"
                          indeterminate
                  >

                  </v-progress-circular>
                  <v-progress-circular
                          v-else
                          :rotate="-90"
                          :size="60"
                          :width="7"
                          :value="asyncRequestWithProgressBarValue"
                          color="primary"
                  >
                      {{ asyncRequestWithProgressBarValue }}%
                  </v-progress-circular>
                  <span class="loadText">Идёт загрузка</span>
              </v-card-text>
          </v-card>
      </div>

    <router-view>
    </router-view>
    <loader v-if="!isLogin"></loader>
  </v-app>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
    }
  },
  computed: {
    ...mapGetters([ 'showPreloader', 'asyncLoadFile',
      'asyncLoadFileWithProgressBar', 'asyncLoadFileLoaderProgressBarValue',
      'asyncRequestWithProgressBar', 'asyncRequestWithProgressBarValue'
    ]),
    isLogin() {
      return this.$route.name === 'AuthIndex'
    }
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
  }
}
</script>
