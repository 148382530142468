var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{ref:"modal",attrs:{"title":_vm.title,"color-btn":"brown"},on:{"close:before":_vm.clearModalForm},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"fs-btn fs-parent-btn fs-btn--primary fs-form-footer__btn v-btn v-btn--flat theme--light",attrs:{"type":"submit","disabled":!_vm.isValid},on:{"click":function($event){$event.preventDefault();return _vm.changeTransferBalance.apply(null, arguments)}}},[_vm._v(" Подтвердить ")])]},proxy:true}])},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.changeTransferBalance.apply(null, arguments)}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-flex',[_c('v-autocomplete',{attrs:{"color":"brown","items":_vm.children,"label":"От кого","loading":_vm.isLoading,"item-text":_vm.displayName,"item-value":"id","rules":[_vm.rules.requered],"browser-autocomplete":"off","autocomplete":"off","clearable":"","box":"","required":""},on:{"change":function($event){return _vm.changeFromPupil($event)}},model:{value:(_vm.fieldsValue.from_pupil_id),callback:function ($$v) {_vm.$set(_vm.fieldsValue, "from_pupil_id", $$v)},expression:"fieldsValue.from_pupil_id"}}),_c('v-autocomplete',{attrs:{"color":"brown","items":_vm.toPupilList,"label":"Кому","loading":_vm.isLoading,"item-text":_vm.displayName,"item-value":"id","rules":[_vm.rules.requered],"browser-autocomplete":"off","autocomplete":"off","clearable":"","box":"","required":""},model:{value:(_vm.fieldsValue.to_pupil_id),callback:function ($$v) {_vm.$set(_vm.fieldsValue, "to_pupil_id", $$v)},expression:"fieldsValue.to_pupil_id"}}),_c('v-text-field',{attrs:{"color":"brown","label":"Сумма","loading":_vm.isLoading,"browser-autocomplete":"off","rules":[
          (v) =>
            v <= _vm.convertToRubles(_vm.selectFromPupil?.changedBalance) ||
            'сумма должна не должна превышать баланс',
          _vm.rules.requered,
          _vm.rules.decimalNumber
        ],"maxLength":_vm.convertToRubles(_vm.selectFromPupil?.changedBalance)?.length,"autocomplete":"off","box":"","clearable":"","required":""},model:{value:(_vm.fieldsValue.amount),callback:function ($$v) {_vm.$set(_vm.fieldsValue, "amount", $$v)},expression:"fieldsValue.amount"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }