<template>
  <v-card
   :color="getColor"
   :class="interactive ? 'interactive' : ''"
   class="rounded"
   @click="handleClick(cardKey)"
  >
   <div class="text-center" :class="getFontColor">
     <h3 class="pb-0 pt-3 px-3 custom-title">
      {{ title }}
     </h3>
   </div>
   <v-card-text class="pt-1" :class="getFontColor">
     <div class="text-center">
       <v-progress-circular v-if="value === null" color="primary" :indeterminate="true"/>
       <span class="custom-text" v-else>{{ value }}</span>
     </div>
   </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MiniCard',
  props: {
    cardKey: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    interactive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getColor () {
      if (this.interactive) {
        return this.value > 0 ? 'red' : ''
      }
    },
    getFontColor () {
      if (this.interactive) {
        return this.value > 0 ? 'text-white' : ''
      }
    }
  },
  methods: {
    handleClick(event) {
      if (event === 'privilege') {
        this.$emit('mini-card-privilege')
      }
      if (event === 'uncompleted') {
        this.$emit('mini-card-uncompleted')
      }
    }
  }
}
</script>

<style scoped>

.custom-text {
  font-size: 26px;
  font-weight: 400;
}
.custom-title {
  font-size: 16px;
  font-weight: 300;
}
.interactive {
  cursor: pointer;
}
.text-white {
  color: white;
}
</style>
