<template>
  <v-container fluid class="fs-organizer-container">
    <v-layout justify-space-between text-md-center mb-5>
      <v-flex>
        <v-btn flat class="fs-btn fs-btn--secondary">left</v-btn>
      </v-flex>
      <v-flex>
        <h1>{{title}}</h1>
      </v-flex>
      <v-flex>
        <v-btn flat class="fs-btn fs-btn--secondary">right</v-btn>
      </v-flex>
    </v-layout>

    <v-layout class="fs-complex-assign-result" mb-3>
      <v-flex>
        <v-data-table
          v-model="selected"
          :headers="orgcomplexHeaders"
          :items="orgcomplexItems"
          hide-actions
          class="elevation-1"
          select-all
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>
                <v-checkbox v-model="props.selected" primary hide-details></v-checkbox>
              </td>
              <td>{{ props.item.foodType }}</td>
              <td v-if="props.item.complex" class="fs-org-complex__complex-assigned">
                {{ props.item.complex }}
                <v-icon class="fs-active-icon">edit</v-icon>
                <v-icon class="fs-active-icon">delete</v-icon>
              </td>
              <td v-else>
                <v-btn flat class="fs-btn fs-btn--secondary">Выбрать комплекс</v-btn>
              </td>
              <td>
                <v-btn flat class="fs-btn fs-btn--secondary">Настроить список блюд</v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "ComplexAssign",
  data() {
    return {
      title: "Назначить комплекс на 1 день периода",
      orgcomplexHeaders: [
        { text: "Тип питания", value: "food-type" },
        { text: "Комплекс по-умолчанию", value: "complex" },
        { text: "Операции", value: "operations" }
      ],
      orgcomplexItems: [
        {
          id: 1,
          foodType: "Одноразовый (с родительской доплатой)",
          complex: "Комплекс 1"
        },
        {
          id: 2,
          foodType: "Одноразовый (без родительской доплаты)",
          complex: null
        },
        {
          id: 3,
          foodType: "Двухразовый",
          complex: null
        },
        {
          id: 4,
          foodType: "Двухразовый (многодетные)",
          complex: null
        },
        {
          id: 5,
          foodType: "Трехразовый (ГПД)",
          complex: null
        }
      ]
    }
  }
}
</script>
