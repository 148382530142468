<template>
  <div class="sc-request" :id="card.id">
    <div
      class="sc-request--container"
      :style="{
        backgroundColor: colors.chatCard.bg,
        color: colors.chatCard.text,
        outlineColor: colors.chatCard.basic
      }"
      @click="openChatCard(card)"
    >
      <p class="sc-request--content">
        Запрос: <b class="sc-request--content--bold">{{ card.question }}</b>
      </p>
      <p
        v-if="card.created_at"
        class="sc-request-meta"
        :style="{ color: colors.chatCard.text }"
      >
        {{ formatDate(card.created_at) }}
      </p>

      <p class="sc-request--content">
        Ответ:
        <b v-if="card.answer" class="sc-request--content--bold">{{
          card.answer
        }}</b>
        <code v-else>Ожидание ответа...</code>
      </p>
      <p
        v-if="card.answer_at"
        class="sc-request-meta"
        :style="{ color: colors.chatCard.text }"
      >
        {{ formatDate(card.answer_at) }}
      </p>

      <!-- Флаг -->
      <div class="sc-request--flag">
        <v-icon
          dark
          :color="
            !card.is_read && card.answer ? 'green' : colors.sentMessage.bg
          "
          >{{
            !card.answer
              ? 'pending'
              : card.is_read
              ? 'done_all'
              : 'notification_important'
          }}</v-icon
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getters, mutations, actions } from '../store'

export default {
  components: {},
  props: {
    card: {
      type: Object,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    messageStyling: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    ...actions,
    formatDate(item) {
      return new Date(item).toLocaleString('ru-RU')
    }
  }
}
</script>

<style lang="scss">
.sc-request {
  width: 320px;
  margin: auto;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
}

.sc-request--container {
  width: 100%;
  display: block;
  border-radius: 10px;
  padding: 8px 16px;
  outline-style: solid;
  outline-width: 2px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.01), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;
}
.sc-request--content {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
	white-space: nowrap;
  padding: 2px 0 0;
	word-wrap: break-word;
  &--bold {
    font-weight: 700;
  }
}

.sc-request-meta {
  font-size: xx-small;
  margin: 0;
  padding-bottom: 4px;
  color: white;
  text-align: right;
}

.sc-request--flag {
  position: absolute;
  top: -12px;
  right: -8px;
  padding: 0;
  margin: 0;
}
</style>
