<template>
  <v-container fluid grid-list-md>
    <v-layout>
      <v-flex>
        <h1 class="mb-3">Выберите ребёнка</h1>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex v-for="(item, i) in list" :key="i" lg3 md4 sm6 xs12>
        <v-card hover @click="select(i)">
          <v-card-title primary-title>
            <div class="pupil__card">
              <h3 class="headline">{{ item.fullName }}</h3>
              <div>{{ item.schoolClass.school.name }}</div>
              <div>{{ item.schoolClass.name }}</div>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'PupilSelectionList',
  props: {
    list: {
      type: Array,
      default: () => ([])
    }
  },
  created() {
    if (!this.list.length) {
      this.$router.push({ name: 'Parent' })
    }
  },
  methods: {
    select(index) {
      const options = {
        name: 'Parent',
        params: { reauth: true, childId: this.list[index].id },
        query: { selected_child_id: this.list[index].personId }
      }

      this.$router.push(options)
    }
  }
}
</script>
