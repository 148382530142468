import store from '@/store'
import notify from '@/utils/notifications/toastNotification'

const requestUploadWrapper = async (func, ...args) => {
  if (!store.getters.asyncLoadFile && !store.getters.asyncLoadFileWithProgressBar) {
    store.commit('setAsyncLoadFileWithProgressBar', true)
  }

  return await func(...args).finally(() => store.commit('setAsyncLoadFileWithProgressBar', false))
}

export default async (getTaskId, getStatus, getResult, payload, callback) => {
  const { file, date, school_id } = payload
  const form = new FormData()
  form.append('uploaded_file', file)
  form.append('school_id', school_id)
  form.append('order_day', date)

  requestUploadWrapper(getTaskId, form, { headers: { 'Content-Type': 'multipart/form-data' } }).then(response => {
    const getTaskId = response.data.id

    const payloadStatus = {
      task_id: getTaskId
    }
    store.commit('setAsyncLoadFileWithProgressBar', true)

    const requestGetStatus = () => {
      getStatus(payloadStatus).then(resStatus => {
        let {
          data: {
            data: { progress }
          }
        } = resStatus

        if (progress === null) progress = 0
        store.commit('setAsyncLoadFileLoaderProgressBarValue', parseInt(progress))

        if (+progress === 100) {
          requestUploadWrapper(getResult, payloadStatus).then(result => {
            notify.success('Файл загружен и обработан')
            callback()
          }, () => {
            notify.error('Ошибка загрузки файла') //pass
          })
        }
        else {
          setTimeout(requestGetStatus, 1000)
        }

      }, () => {
        notify.error('Ошибка получения статуса запроса')
      })
    }

    requestGetStatus()
  }, () => {
    notify.error('Ошибка выполнения запроса')

    callback()
  })
}