export default {
  setFoodPeriods(state, foodPeriods) {
    state.foodPeriods = foodPeriods
  },

  setFoodTimes(state, foodTimes) {
    state.foodTimes = foodTimes
  },

  setAgeCategories(state, ageCategories) {
    state.ageCategories = ageCategories
  },

  setFoodCategories(state, foodCategories) {
    state.foodCategories = foodCategories
  },

  setShifts(state, shifts) {
    state.shifts = shifts
  },

  setNotifications(state, notifications) {
    state.notifications = notifications
  },

  setNotificationsCount(state, count) {
    state.notificationsCount = count
  },

  setFoodTypes(state, foodTypes) {
    state.foodTypes = foodTypes
  },

  setSettings(state, obj) {
    state.settings = obj
  },

  setSetting(state, key, value) {
    state.settings[key] = value
  },

  setConcessions(state, concessions) {
    state.concessions = concessions
  },

  setQualityAssesments(state, data) {
    state.qualityAssesments = data
  },
  setQualityAssesmentsFilters(state, data) {
    state.qualityAssesmentsFilters = {
      ...state.qualityAssesmentsFilters,
      ...data
    }
  },
  setQualityAssesmentsPagination(state, data) {
    state.qualityAssesmentsPagination = {
      ...state.qualityAssesmentsPagination,
      ...data
    }
  },
  
  setQualityMenuPhotoReports(state, data) {
    state.qualityMenuPhotoReports = data
  },
  setQualityMenuPhotoReportsFilters(state, data) {
    state.qualityMenuPhotoReportsFilters = {
      ...state.qualityMenuPhotoReportsFilters,
      ...data
    }
  },
  setQualityMenuPhotoReportsPagination(state, data) {
    state.qualityMenuPhotoReportsPagination = {
      ...state.qualityMenuPhotoReportsPagination,
      ...data
    }
  },


  setQualityAvgSuppliers(state, data) {
    state.qualityAvgSuppliers = data
  },
  setQualityAvgSuppliersFilters(state, data) {
    state.qualityAvgSuppliersFilters = {
      ...state.qualityAvgSuppliersFilters,
      ...data
    }
  },
  setQualityAvgSuppliersPagination(state, data) {
    state.qualityAvgSuppliersPagination = {
      ...state.qualityAvgSuppliersPagination,
      ...data
    }
  }
}
