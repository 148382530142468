<template>
  <v-container class="fs-teacher-container" fluid grid-list-md>
    <v-layout class="fs-teacher-order-result" wrap>
      <v-flex xs12 mb-5>
        <h1>{{ title }}</h1>
      </v-flex>
    </v-layout>

    <v-tabs v-model="activeTab" dark>
      <v-tab>Отчёты по МО</v-tab>
      <v-tab>Отчёты по школам</v-tab>

      <v-tab-item>
        <v-layout
          class="fs-teacher-order-result"
          mb-3
          justify-space-around
        >
          <v-flex>
            <v-data-table
              :headers="headers"
              :items="items.filter(el => {
                if (el.id === '2' && el.formId === 2) return false
                if (!el.schoolChoice) return el
                return false
              })"
              hide-actions
              class="elevation-1 fs-teacher-table"
              item-key="name"
            >
              <template v-slot:items="props">
                <tr>
                  <td>{{ props.item.name  }}  {{props.item.formId? `(Форма ${props.item.formId})` : null}}</td>
                  <td>
                    <v-flex class="pa-2">
                      <v-btn
                        flat
                        class="fs-btn fs-teacher-btn__show v-btn--active"
                        @click="showReportModal(props.item)"
                      >Печать</v-btn>
                    </v-flex>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-tab-item>

      <v-tab-item>
        <v-layout
          class="fs-teacher-order-result"
          mb-3
          justify-space-around
        >
          <v-flex>
            <v-data-table
              :headers="headers"
              :items="items.filter(el => {
                if (el.id === '2' && el.formId === 2) return el
                if (el.schoolChoice) return el
                return false
              })"
              hide-actions
              class="elevation-1 fs-teacher-table"
              item-key="name"
            >
              <template v-slot:items="props">
                <tr>
                  <td>{{ props.item.name  }} {{props.item.formId? `(Форма ${props.item.formId})` : null}}</td>
                  <td>
                    <v-flex class="pa-2">
                      <v-btn
                        flat
                        class="fs-btn fs-teacher-btn__show v-btn--active"
                        @click="showReportModal(props.item)"
                      >Печать</v-btn>
                    </v-flex>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-tab-item>
    </v-tabs>

    <ConfirmModal
      :dialog.sync="showModal"
      :startPrint.sync="showPrintLoad"
      :dateStart.sync="dateStart"
      :dateEnd.sync="dateEnd"
      :width="800"
      @confirm="handlerModal"
    >
      <template v-slot:headline>Печать отчёта</template>
      <template v-slot:body>
        Название отчёта:
        <h3>{{reportPrintTitle}}</h3>
        <v-flex
          md4
          class="mt-3"
        >
          <div v-if="datePickers === 1">
            <DatePickersSmart
              :startDate.sync="dateStart"
              :onePicker="true"
              @changeDate="changeDate"
            />
          </div>
          <div v-else-if="datePickers === 2">
            <DatePickersSmart
              :startDate.sync="dateStart"
              :endDate.sync="dateEnd"
              @changeDate="changeDate"
            />
          </div>
        </v-flex>
        <v-flex
          md9
          v-if="schoolsSelector"
        >
          <v-select
            v-model="selectedSchool"
            :items.sync="schoolsArray"
            class="fs-filter"
            item-text="name"
            item-value="id"
            label="Школа"
            attach
          ></v-select>
        </v-flex>
        <v-flex
          md-9
          v-if="multipleSchoolsSelector"
        >
          <v-select
              no-data-text="Ничего не выбрано"
              v-model="selectedSchools"
              item-text="name"
              item-value="id"
              :items="schoolsArray"
              deletable-chips
              chips
              multiple
              placeholder="Выберите школы"
          >
            <template v-slot:prepend-item>
              <v-list-tile
                  ripple
                  @click="selectAll('selectedSchools', 'schoolsArray')"
              >
                <v-list-tile-action>
                  <v-icon :color="selectedSchools.length > 0 ? 'primary' : ''">{{selectedSchools.length ===  schoolsArray.length ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>Выбрать всё</v-list-tile-title>
                  <v-list-tile-sub-title>Выбрано: {{selectedSchools.length}}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-flex>
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import moment from 'moment'
import {
  getAllReports,
  reportFileTaskId,
  reportFormFileTaskId,
  reportFileTaskIdSchool,
  reportFileStatus,
  reportPrintFile,
  getSchools,
} from '@/router/routes/department'
import { mapGetters, mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import { ConfirmModal, DatePickersSmart } from '@/components/common'
import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownloadWithProgressBar'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ReportsIndex',
  components: {
    ConfirmModal,
    DatePickersSmart
  },
  data() {
    return {
      title: 'Отчёты',
      datepickerMenu: false,
      dateStart: '',
      dateEnd: '',
      headers: [
        { text: 'Отчёт', value: 'name', width: '90%' },
        { text: 'Операции', value: 'operations' }
      ],
      items: [],
      activeTab: 0,
      showModal: false,
      showPrintLoad: false,
      reportPrintTitle: '',
      datePickers: null,
      datePickerRange: '',
      currentItem: null,
      schoolsSelector: false,
      multipleSchoolsSelector: false,
      schoolsArray: [],
      selectedSchool: null,
      selectedSchools: [],
      search: {
        schools: null
      },
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
    getDepartmentId() {
      return this.currentUser.department.id
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async init() {
      await this.loadReports()
      await this.loadSchools()
    },
    selectAll(selectArray, parentArray) {
      this.$nextTick(() => {
        if (this[selectArray].length) {
          this[selectArray] = []
        } else {
          const idsArray = this[parentArray].map(item => item.id)
          this[selectArray] = [ ...idsArray ]
        }
      })
    },
    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
    },
    setDatepickersValue(count, range) {
      const defaultRange = 'month'
      if (count === 2) {
        this.dateStart = moment()
          .add(defaultRange, -1)
          .format(Vue.config.format.serverDate)
        this.dateEnd = moment()
          .add('days', 1)
          .format(Vue.config.format.serverDate)
      }
      if (count === 1) {
        this.dateStart = moment().format(Vue.config.format.serverDate)
        this.dateEnd = ''
      }
      if (!count) {
        this.dateStart = ''
        this.dateEnd = ''
      }
    },
    showReportModal(item) {
      this.currentItem = item
      this.showModal = true
      this.datePickers = item.datePickers
      const countDatepickers = item.datePickers
      const rangeType = item.rangeType
      this.setDatepickersValue(countDatepickers, rangeType)
      this.schoolsSelector = item.schoolChoice
      this.multipleSchoolsSelector = !!item.multipleSchoolChoice
      this.selectedSchools = []
      this.reportPrintTitle = item.name + (item.form_id ? ` (Форма ${item.form_id})` : '')
    },
    async handlerModal(value) {
      this.modalResponse = value
      if (this.modalResponse) {
        this.showPrintLoad = true
        await this.printFile()
      }
    },
    async printFile() {
      const {
        name = null,
        id = null,
        formId = null,
        schoolChoice = null,
        is_another_url = null
      } = this.currentItem

      const exceptReportFormId = [
        'Баланс учеников',
        'Отчет по должникам класса',
        'Отчет по категориям питающихся по выбранным классам( в разрезе классов), кол-во человек',
        'Отчет по питающимся (по количеству приемов пищи)',
        'Отчет по питающимся (по количеству приемов пищи) в разрезе классов, кол-во человек',
      ]
      const exceptReportFileTaskId = [
        'Табель питания в разрезе типов питания',
        'Табель учета питания учащихся',
        'Табель учета питания учащихся (старая версия)',
        'Табель учета посещаемости детей'
      ]
      let getTaskId = null

      if (exceptReportFormId.includes(name)) {
        getTaskId = reportFormFileTaskId
      } else if (exceptReportFileTaskId.includes(name)) {
        getTaskId = reportFileTaskId
      } else if (formId && schoolChoice) {
        getTaskId = reportFormFileTaskId
      } else if (formId) {
        getTaskId = reportFormFileTaskId
      } else if (schoolChoice) {
        getTaskId = reportFileTaskIdSchool
      } else {
        getTaskId = reportFileTaskId
      }
      const PAYLOAD = {
        id,
        school_id: this.selectedSchool || this.currentUser.school_id,
        data: {
          start_date: this.dateStart,
          end_date: this.dateEnd,
          schoolClassIds: this.selectedClasses || null,
          departments: [ this.getDepartmentId ],
          schools: this.selectedSchools,
          school: this.selectedSchool
        },
        report_id: id,
        form_id: formId
      }

      if (is_another_url) {
        getTaskId = reportFileTaskId
        PAYLOAD.data.schools = this.selectedSchool
      }

      const callback = this.resetPageFileLoaded
      try {
        await asyncReqestsForDownload(
          getTaskId,
          reportFileStatus,
          reportPrintFile,
          PAYLOAD,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки файла') //pass
      } finally {
        this.resetPageFileLoaded()
      }
    },
    async loadSchools() {
      const PAYLOAD = {
        params: {
          limit: Vue.config.general.schoolLimit,
          offset: 0
        },
        department_id: this.getDepartmentId
      }
      try {
        const response = await getSchools(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, short_name, number } = item
          return {
            id: id,
            name: short_name,
            number: number
          }
        })
        this.schoolsArray = [ ...this.schoolsArray, ...itemsArray ]
        this.selectedSchool = itemsArray[0].id
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async loadReports() {
      try {
        const response = await getAllReports()
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const {
            id = null,
            name = '',
            formId = '',
            rangeType = '',
            rangeAmmount = null,
            datePickers = null,
            classChoice = null,
            schoolChoice = null,
            multipleSchoolChoice = null,
            is_another_url = null
          } = item
          return {
            id,
            name,
            formId,
            datePickers,
            rangeType,
            rangeAmmount,
            classChoice,
            schoolChoice,
            multipleSchoolChoice,
            is_another_url
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки отчётов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    changeDate(payload) {
      return
    }
  }
}
</script>
