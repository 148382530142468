export default {
  'superadmin': {
    'addOrder': 'add_order'
  },
  'admin': {
    'dashboard': {
      'main': 'dashboard_main_info',
      'orders': 'dashboard_block_orders',
      'order_statuses': 'dashboard_order_statuses_today',
      'meal_coverage': 'dashboard_meal_coverage_today',
      'mealtype_period': 'dashboard_mealtype_period_today',
      'news': 'dashboard_news_admin',
      'modals': {
        'negative_balance': 'dashboard_orders_negative_balance',
        'expired_privilege': 'dashboard_pupils_expired_privilege',
        'no_meal_type': 'dashboard_pupils_no_meal_type',
        'unfulfilled': 'dashboard_orders_unfulfilled',
        'overdraft': 'dashboard_orders_overdraft'
      }
    },
    'orders': {
      'statusInfo': 'orders_list_statusInfo'
    },
    'students': {
      'list': 'pupil_list_changeClass',
      'card': {
        'main': 'pupil_card_main',
        'about': 'pupil_card_about',
        'invoices': 'pupil_card_invoices',
        'food': 'pupil_card_foodSettings',
        'balance': 'pupil_card_balance',
        'variable': 'pupil_card_variableMeals'
      }
    },
    'classes': {
      'list': 'food_admin_school_class_list',
      'edit': 'school_class_edit'
    },
    'teachers': {
      'list': 'food_admin_teacher_list',
      'edit': 'food_admin_teacher_edit',
    },
    'changeMealType': 'food_admin_change_meal_type',
    'invoiceInfo': 'pupil_card_report',
    'balanceMoving': 'payments_transaction_list',
    'reports': 'reports_list',
    'notifications': 'notification_list',
    'settings': 'school_settings_edit',
    'workingDays': 'food_admin_working_days'
  },
  'supplier': {
    'users': {
      'info': 'supplier_adm_info',
      'logs': 'supplier_adm_userLogs'
    },
    'food': {
      'create': 'supplier_food_create',
      'list': 'supplier_food_info',
      'archive_list': 'supplier_food_archive',
      'food_copy': 'supplier_food_copy'
    },
    'complex': {
      'info': 'supplier_comp_info',
      'create': 'supplier_comp_create'
    },
    'template': {
      'info': 'supplier_mtemp_info',
      'one': 'supplier_mtemp_one',
      'edit': 'supplier_mtemp_edit'
    },
    'additionalFood': 'supplier_afood_info',
    'dayMenu': 'supplier_dmenu_info',
    'payment': 'supplier_payment_upload',
    'refund': 'supplier_refund_info',
    'card': 'supplier_card_info',
    'credit': 'supplier_schoolSettings_info',
    'ordersInfo': 'supplier_orders_info',
    'news': 'supplier_news'
  }
}