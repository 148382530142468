<template>
  <v-container class="fs-admin-container" fluid grid-list-md>
    <v-layout class="fs-dish-result" wrap>
      <h1>{{ title }}</h1>
      <v-flex v-if="!archiveView" xs12 class="mb-3 mt-3">
        <v-btn
          class="fs-btn fs-admin-btn__add d-inline ma-0 mr-2"
          @click="$router.push({ name: 'MunicipalityFoodTypesArchive' })"
        >Показать архив</v-btn>
        <v-btn
          class="fs-btn fs-admin-btn__add d-inline ma-0 mr-2"
          @click="navigateTo('MunicipalityFoodTypeItemNew')"
        >Добавить</v-btn>
        <v-btn
          v-if="setting('department_meal_types_scheduler')"
          class="fs-btn fs-admin-btn__add d-inline ma-0"
          @click="navigateTo('MunicipalityFoodTypesScheduler')"
        >Запланированная смена типов питания</v-btn>
      </v-flex>
      <v-flex v-else xs12 text-md-right>
        <v-btn
          class="fs-btn fs-admin-btn__add ma-0 mb-3 mt-1"
          @click="$router.push({ name: 'MunicipalityFoodTypes' })"
        >Скрыть архив</v-btn>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1 fs-admin-table"
          item-key="name"
          no-data-text="Нет типов питания"
          hide-actions
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td class="cursor-pointer" @click="navigateTo('MunicipalityFoodTypeView', props.item.id)">{{ props.item.name }}</td>
              <td class="cursor-pointer" @click="navigateTo('MunicipalityFoodTypeView', props.item.id)">{{ props.item.price }}</td>
              <td>
                <v-checkbox
                  v-model="props.item.isExtendedDayGroup"
                  class="fs-checkbox"
                  primary
                  readonly
                  hide-details
                ></v-checkbox>
              </td>
              <td class="cursor-pointer" @click="navigateTo('MunicipalityFoodTypeView', props.item.id)">{{ props.item.studentCategory }}</td>
              <td class="cursor-pointer" @click="navigateTo('MunicipalityFoodTypeView', props.item.id)">{{ getFoodPeriodsNames(props.item.foodPeriods) }}</td>
              <td class="cursor-pointer" @click="navigateTo('MunicipalityFoodTypeView', props.item.id)">{{ props.item.suppliers }}</td>
              <td>
                <v-checkbox
                  v-model="props.item.isHot"
                  class="fs-checkbox"
                  primary
                  readonly
                  hide-details
                ></v-checkbox>
              </td>
              <td class="cursor-pointer" @click="navigateTo('MunicipalityFoodTypeView', props.item.id)">{{ props.item.foodPeriodsCount }}</td>
              <td>
                <v-flex row class="d-flex">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        v-if="!archiveView"
                        @click="showDeleteModal(props.item.id)"
                      >archive</v-icon>
                    </template>
                    <span>Перенести в архив</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        v-if="archiveView"
                        @click="showRestoreModal(props.item.id)"
                      >unarchive</v-icon>
                    </template>
                    <span>Вернуть из архива</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
      <ConfirmModal
        :dialog.sync="showModal"
        :confirmBtnText='confirmBtnText'
        @confirm="handlerConfirmModal"
      >
        <template v-slot:headline>{{ modalTitle }}</template>
        <template v-slot:body>
          <div v-html="modalText"></div>
        </template>
      </ConfirmModal>
      <ConfirmModal
        :dialog.sync="restoreModal"
        :confirmBtnText='confirmBtnText'
        @confirm="handlerRestoreModal"
      >
        <template v-slot:headline>{{ modalTitle }}</template>
        <template v-slot:body>
          <div v-html="modalText"></div>
        </template>
      </ConfirmModal>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import converter from '@/helpers/converter'
import navigateTo from '@/mixins/navigateTo'
import { ConfirmModal } from '@/components/common'
import {
  getAllMealTypes,
  archiveMealType,
  restoreMealType,
  getOrganizers,
  getArchiveMealTypes
} from '@/router/routes/department'
import notify from '@/utils/notifications/toastNotification'
import _ from "lodash"

export default {
  name: 'FoodTypeIndex',
  components: {
    ConfirmModal
  },
  mixins: [ navigateTo ],
  data() {
    return {
      title: this.archiveView ? 'Архив типов питания' : 'Типы питания',
      selected: [],
      showModal: false,
      modalTitle: '',
      modalText: '',
      confirmBtnText: '',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Наименование', value: 'name' },
        { text: 'Стоимость', value: 'price', width: '7%' },
        { text: 'ГПД', value: 'isExtendedDayGroup' },
        { text: 'Категории учащихся, которым доступен', value: 'studentCategory' },
        { text: 'Периоды кормления', value: 'foodPeriods' },
        { text: 'Организаторы питания', value: 'suppliers' },
        { text: 'Горячее питание', value: 'isHot', width: '10%' },
        { text: 'Количество приемов пищи', value: 'foodPeriods.length', width: '12%' },
        { text: 'Операции', value: 'actions', width: '5%', sortable: false }
      ],
      items: [],
      suppliers: [],
      deletedItem: null,
      restoredItem: null,
      restoreModal: false
    }
  },
  props: {
    archiveView: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {
    ...mapGetters([ 'foodPeriods', 'departmentId', 'currentUser', 'setting' ])
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async archiveMealType(id) {
      try {
        const PAYLOAD = {
          department_id: this.departmentId,
          id: id
        }
        await archiveMealType(PAYLOAD)
        notify.success('Тип питания успешно перемещен в архив')
        this.items = this.items.filter(item => item.id !== id)
      } catch (error) {
        // notify.error('Не удалось переместить тип питания в архив') // backend handler added (exception_message)
      } finally {
        this.$store.commit('setShowPreloader', false)
      }
    },
    async init(reset = false) {
      const method = this.archiveView ? getArchiveMealTypes : getAllMealTypes
      try {
        const PAYLOAD = {
          department_id: this.departmentId
        }
        const response = await method(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const {
            id = null,
            name = '',
            price = '',
            isExtendedDayGroup = null,
            privilege: { name: studentCategory },
            foodPeriods = null,
            suppliers = null,
            isHot = null
          } = item
          return {
            id,
            name,
            price: converter(price, 'pennyToRub'),
            isExtendedDayGroup,
            studentCategory,
            foodPeriods,
            suppliers,
            isHot
          }
        })
        if (reset) {
          this.items = [ ...itemsArray ]
        } else {
          this.items = [ ...this.items, ...itemsArray ]
        }
        this.suppliers = await getOrganizers(PAYLOAD)
        _.map(this.items, item => {
          item.suppliers = this.getSuppliers(item.id)
          item.foodPeriodsCount = item.foodPeriods.length
        })
      } catch (error) {
        // notify.error('Ошибка загрузки типов питания') // backend handler added (exception_message)
      } finally {
        this.$store.commit('setShowPreloader', false)
      }
    },
    async handlerConfirmModal(confirm) {
      if (confirm) {
        await this.archiveMealType(this.deletedItem)
        await this.init(true)
      }
      this.deletedItem = null
    },
    async handlerRestoreModal(confirm) {
      if (confirm) {
        const payload = {
          department_id: this.departmentId,
          id: this.restoredItem
        }
        await restoreMealType(payload)
        await this.init(true)
      }
      this.restoredItem = null
    },
    getFoodPeriodsNames(periodsAliases) {
      const names = _.map(periodsAliases, alias => {
        return _.find(this.foodPeriods, item => {
          return alias === item.alias
        })
      })
      return _.map(names, item => {
        if (item === undefined) {
          return null
        }
        return item.name
      })
        .sort()
        .join(', ')
    },
    getSuppliers(mealId) {
      return (
        this.suppliers.data &&
        this.suppliers.data.data
          .filter(item => item.mealTypes.some(type => type.id === mealId))
          .map(item => item.shortName)
          .join(', ')
      )
    },
    showDeleteModal(id) {
      this.modalTitle = 'Внимание!'
      this.modalText = 'Вы уверены, что хотите переместить данный тип питания в архив?'
      this.confirmBtnText = 'В архив'
      this.showModal = true
      this.deletedItem = id
    },
    showRestoreModal(id) {
      this.modalTitle = 'Внимание!'
      this.modalText = 'Вы уверены, что хотите восстановить тип питания?'
      this.confirmBtnText = 'Восстановить'
      this.restoreModal = true
      this.restoredItem = id
    }
  }
}
</script>
