<template>
  <v-container fluid pa-0>
    <v-layout>
      <v-flex>
        <v-card class="rounded">
          <v-card-title primary-title>
            <div>
              <h2 class="headerline pb-3 text-md-center">{{ title }}</h2>
              <div>
                <h3 class="headerline pb-3">Загрузить данные о родительских платежах Сбербанк</h3>
                <context-help-modal :context-key="contextKeys.supplier.payment" />
<!--              <p class="fs-color&#45;&#45;red">Необходимо регулярно (раз в 2 дня) делать загрузку и выгрузку учеников, для корректной синхронизации со сбербанком</p>-->
              </div>
            </div>
          </v-card-title>
          <p class="headerline pb-2 pl-4 pr-2">Данные о платежках: {{ fileName }}</p>
          <v-card-actions class="headerline pb-4">
            <with-tooltip text="Загрузить полученный файл из Сбербанка">
              <UploadButton
                :uniqueId="true"
                title="Выберите файл"
                noTitleUpdate
                :fileChangedCallback="fchangedCallback"
            />
            </with-tooltip>
            <v-btn @click="upload" class="primary">Загрузить</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal"
import WithTooltip from "@/components/common/Tooltip/Tooltip"
import UploadButton from "@/components/common/UploadButton/UploadButton"
import { DatePickersSmart } from "@/components/common"
import Vue from "vue"
import { mapMutations } from "vuex"
import _ from "lodash"
import {
  getBudgetFile,
  getBudgetStatus,
  getBudgetTaskId,
  getPrintFile,
  getPrintFileStatus,
  printFileTaskId,
  uploadInvoicesFile,
  uploadInvoicesFileCasher
} from "@/router/routes/organizer"
import notify from "@/utils/notifications/toastNotification"
import store from "@/store"
import moment from "moment/moment"
import asyncReqestsForDownload from "@/helpers/asyncReqestsForDownloadWithProgressBar"

export default {
  name: 'Receipts',
  components: {
    ContextHelpModal,
    WithTooltip,
    UploadButton,
    DatePickersSmart
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      fileName: '',
      file: {},
      casherFileName: '',
      casherFile: {},
      title: 'Квитанции',
      btnUnloadLoaded: false,
      btnPrintLoaded: false,
      date: new Date().toISOString().substr(0, 10)
    }
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    fchangedCallback(event){
      this.file = event
      this.fileName = event.name
    },
    fchangedCallbackCasher(event){
      this.casherFile = event
      this.casherFileName = event.name
    },

    upload() {
      if (_.get(this.file, 'name', '')) {
        const form = new FormData()
        form.append('uploaded_file', this.file)
        uploadInvoicesFile(form, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            if (response.data.success) {
              notify.success(Vue.config.notifications.uploadSuccess)
            }
          })
          .finally(() => store.commit('setShowPreloader', false))
        return null
      }
      notify.error(Vue.config.notifications.uploadFailed)
    },
    casherUpload() {
      if (_.get(this.casherFile, 'name', '')) {
        const form = new FormData()
        form.append('uploaded_file', this.casherFile)
        uploadInvoicesFileCasher(form, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            if (response.data.success) {
              notify.success(Vue.config.notifications.uploadSuccess)
            }
          })
          .finally(() => store.commit('setShowPreloader', false))
        return null
      }
      notify.error(Vue.config.notifications.uploadFailed)
    },
    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
      this.btnUnloadLoaded = false
      this.btnPrintLoaded = false
    },
    async downloadPrintFile() {
      this.btnPrintLoaded = true
      const PAYLOAD = {
        start_date: moment(this.dateStart).format(Vue.config.format.pythonDate),
        end_date: moment(this.dateEnd).format(Vue.config.format.pythonDate)
      }
      const callback = this.resetPageFileLoaded
      try {
        await asyncReqestsForDownload(
          printFileTaskId,
          getPrintFileStatus,
          getPrintFile,
          PAYLOAD,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки документа')
      } finally {
        this.resetPageFileLoaded()
      }
    },
    async downloadBudgetIds(data) {
      this.btnUnloadLoaded = true
      const PAYLOAD = null
      const callback = this.resetPageFileLoaded
      try {
        await asyncReqestsForDownload(
          getBudgetTaskId,
          getBudgetStatus,
          getBudgetFile,
          PAYLOAD,
          callback
        )
      } catch (error) {
        notify.error('ERROR asyncReqestsForDownload')
      } finally {
        this.resetPageFileLoaded()
      }
    }
  }
}
</script>

<style scoped>

.rounded {
  border-radius: 10px;
}

</style>