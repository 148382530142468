import Vue from 'vue'
import store from '@/store'
import downloadFile from './downloadFile'
import notify from '@/utils/notifications/toastNotification'

const requestFileUploadWrapper = async (func, ...args) => {
  if (!store.getters.asyncLoadFile) {
    store.commit('setAsyncLoadFile', true)
  }

  return await func(...args).finally(() => store.commit('setAsyncLoadFile', false))
}

export default async (getTaskId, getStatus, getFile, payload, callback) => {
  const reportId = payload && payload.report_id ? payload.report_id : null
  const studentId = payload && payload.studentId ? payload.studentId : null
  const hasPayloadForStatus = payload && payload.school_id ? payload.school_id : null

  requestFileUploadWrapper(getTaskId, payload ? payload : null).then(resTask => {
    const {
      data: {
        data: { id }
      }
    } = resTask

    const payloadStatus = (() => {
      if (hasPayloadForStatus) {
        return {
          school_id: payload.school_id,
          id: reportId || id,
          task_id: id
        }
      }
      else if (studentId) {
        return {
          studentId,
          task_id: id
        }
      }
      else if (reportId) {
        return {
          id: reportId,
          task_id: id
        }
      }
      else {
        return {
          task_id: id
        }
      }
    })()

    store.commit('setAsyncLoadFile', true)

    const requestGetStatus = () => {
      getStatus(payloadStatus).then(resStatus => {
        const {
          data: {
            data: { progress }
          }
        } = resStatus

        if (+progress === 100) {
          requestFileUploadWrapper(getFile, payloadStatus).then(resBlob => {
            downloadFile(resBlob)
            callback()
          }, () => {
            notify.error('Ошибка загрузки файла') //pass
          })
        }
        else {
          setTimeout(requestGetStatus, 1000)
        }
      }, () => {
        notify.error('Ошибка получения статуса загрузки') //pass
      })
    }

    requestGetStatus()
  }, (e) => {
    notify.error(e.response.data.message) //pass
    callback()
  })
}
