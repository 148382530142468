<template>
  <v-dialog
    v-model="dialog"
    v-bind="$attrs"
    persistent
    no-click-animation
    max-width="600px"
    :hide-overlay="fullscreen"
    :transition="fullscreen ? 'dialog-bottom-transition' : 'dialog-transition'"
  >
    <v-card fluid class="fs-parent-container fs-parent-container--modal">
      <v-card-title>
        <span class="headline">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-btn icon flat :color="colorBtn" @click="close()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat :color="colorBtn" @click="close()">Отмена</v-btn>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalParent',
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: ''
    },
    colorBtn: {
      type: String,
      default: 'primary'
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.$emit('close:before')
      this.dialog = false
    }
  }
}
</script>
