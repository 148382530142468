export default {
  foodTypes: (state) => {
    return state.foodTypes
  },
  foodTimes: (state) => {
    return state.foodTimes
  },
  foodPeriods: (state) => {
    return state.foodPeriods
  },
  foodCategories: (state) => {
    return state.foodCategories
  },
  notifications: (state) => {
    return state.notifications
  },
  notificationsCount: (state) => {
    return state.notificationsCount
  },

  qualityAssesmentsPagination: (state) => {
    return state.qualityAssesmentsPagination
  },
  qualityAssesmentsFilters: (state) => {
    return state.qualityAssesmentsFilters
  },
  qualityAssesments: (state) => {
    return state.qualityAssesments
  },
  settings: (state) => {
    return state.settings
  },
  setting: (state) => (key) => {
    const { settings } = state
    if (key in settings) {
      return state.settings[key].value
    } else {
      return false
    }
  },
  qualityMenuPhotoReportsPagination: (state) => {
    return state.qualityMenuPhotoReportsPagination
  },
  qualityMenuPhotoReportsFilters: (state) => {
    return state.qualityMenuPhotoReportsFilters
  },
  qualityMenuPhotoReports: (state) => {
    return state.qualityMenuPhotoReports
  },

  qualityAvgSuppliers: (state) => {
    return state.qualityAvgSuppliers
  },
  qualityAvgSuppliersPagination: (state) => {
    return state.qualityAvgSuppliersPagination
  },
  qualityAvgSuppliersFilters: (state) => {
    return state.qualityAvgSuppliersFilters
  }
}
