<template>
  <v-container class="fs-teacher-container" fluid>
    <v-layout class="fs-complex" row mb-3>
      <div class="md4 mb-3 d-flex">
        <h1>{{ title }}</h1>
        <context-help-modal :context-key="contextKeys.admin.classes.list" />
      </div>
    </v-layout>
	  <v-layout v-if="errorClasses !== null">
		  <v-flex>
			  <v-card>
				  <v-card-title class="pb-0 pt-1 justify-space-between">
					  <h3>Внимание</h3>
					  <v-btn fab dark small style="width: 20px; height: 20px"
					         color="primary"
					         @click="closeInfoModal"
					  >
						  <v-icon style="font-size: 14px">
							  close
						  </v-icon>
					  </v-btn>
				  </v-card-title>
				  <v-card-text class="pt-1" style="display: grid">
					  <span>В Электронной Школе в следующих классах отсутствует учитель: {{ errorClasses }}</span>
					  <span>Данные классы не будут отображены в таблице</span>
					  <span style="font-size: 12px" class="pt-2">Информация обновляется 2 раза в день</span>
				  </v-card-text>
			  </v-card>
		  </v-flex>
	  </v-layout>
    <v-layout
      row
      mb-3
      justify-space-around
      text-md-center
      class="fs-header-filter"
    >
    </v-layout>
    <v-layout class="fs-teacher-order-result" mb-3>
      <v-flex>
        <v-data-table
          class="elevation-1 fs-admin-table"
          hide-actions
          item-key="name"
          :headers="headers"
          :items="items"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.shift }}</td>
              <td>{{ props.item.teacher }}</td>
              <td>
	              <v-tooltip top>
		              <template v-slot:activator="{ on }">
			              <v-icon
				              v-on="on"
				              @click="$router.push({ name: 'AdminClassesView', params: { id: props.item.id } })"
			              >remove_red_eye</v-icon>
		              </template>
		              <span>Посмотреть</span>
	              </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
	                    class="ml-2"
                      v-on="on"
                      @click="$router.push({ name: 'AdminClassesEdit', params: { id: props.item.id } })"
                    >edit</v-icon>
                  </template>
                  <span>Изменить</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { getClasses } from '@/router/routes/admin'
import { disableSorting } from '@/mixins/helpers'
import scrollable from '@/mixins/scrollable'
import store from '@/store'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"

export default {
  name: 'StudentIndex',
  components: { ContextHelpModal },
  mixins: [ disableSorting, scrollable ],
  created() {
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },
  destroyed() {
    this.removeScrollHandler()
  },
  data() {
    return {
      title: 'Список классов',
      headers: [
        { text: 'Класс', value: 'class' },
        { text: '№ п/п', value: 'id' },
        { text: 'Смена', value: 'shift' },
        { text: 'Классный руководитель', value: 'teacherLastName' },
        { text: 'Операции', value: 'operations' }
      ],
      items: [],
	    errorClasses: null,
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10),
      contextKeys: Vue.config.contextHelp,
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    closeInfoModal() {
      this.errorClasses = null
    },
    loadMore() {
      const payload = {
        schoolId: this.currentUser.school.id,
        params: {
          limit: Vue.config.general.classLimit,
          offset: this.items.length
        }
      }
      getClasses(payload)
        .then(response => {
          this.errorClasses = response.data.error_classes
          const items = _.map(response.data.data, item => {
            return {
              id: item.id,
              name: item.name,
              shift: item.shift,
              teacher: item.teacher,
            }
          })
          this.items = this.items.concat(items)
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
