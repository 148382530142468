<template>
  <v-container fluid class="fs-parent-container">
    <div class="page-head__common"><h1>Опросы</h1></div>

    <v-layout class="fs-archive-result" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="syrveys.results"
          hide-actions
          no-data-text="Нет опросов"
          class="elevation-1"
          item-key="name"
          style="cursor: pointer;"
        >
          <template v-slot:items="props">
            <tr
              @click="
                is_user_answer_this_questionnaries(props.item.answered_users)
                  ? () => {}
                  : user_id
                  ? syrveyDetail(props.item.id)
                  : () => {}
              "
            >
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ transformDate(props.item.created_at) }}</td>
              <td>{{ props.item.school }}</td>
              <td>{{ props.item.questions.length }}</td>
              <td>
                {{
                  is_user_answer_this_questionnaries(props.item.answered_users)
                    ? 'Уже пройден'
                    : user_id
                    ? 'Пройти'
                    : 'Пользователь не найден'
                }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <div class="text-xs-center" v-if="pagination_count > 1">
      <v-pagination
        @input="handlePaginationClick"
        :value="syrveys_pagination.page"
        :length="pagination_count"
        :total-visible="7"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
export default {
  name: 'Syrvey',
  data: () => ({
    user_id: null,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Наименование', value: 'name' },
      { text: 'Дата создания', value: 'created_at' },
      { text: 'Школа', value: 'school' },
      { text: 'Кол-во вопросов', value: 'questions' },
      { text: 'Информация', value: 'info' }
    ]
  }),
  computed: {
    ...mapGetters([ 'syrveys', 'syrveys_pagination' ]),
    pagination_count() {
      return (
        Math.ceil(this.syrveys.count / this.syrveys_pagination.page_size) || 0
      )
    }
  },
  methods: {
    ...mapActions([ 'getSyrveys' ]),
    ...mapMutations([ 'set_syrveys_pagination' ]),
    transformDate(date) {
      return moment(date).format(Vue.config.format.date)
    },
    handlePaginationClick(page) {
      this.set_syrveys_pagination({ page: page })
      this.getSyrveys()
    },
    syrveyDetail(syrvey_id) {
      this.$router.push({
        name: 'SyrveyDetail',
        params: { syrvey_id: syrvey_id }
      })
    },
    is_user_answer_this_questionnaries(answered_users) {
      if (this.user_id) return answered_users.includes(this.user_id)
      else return false
    }
  },
  mounted() {
    this.user_id = Number(localStorage.getItem('user_id'))
    this.getSyrveys()
  }
}
</script>

<style></style>
