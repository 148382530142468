<template>
  <v-data-table
    :headers="headers"
    :items="items"
    v-model="selected"
    :class="classes"
    :item-key="itemKey"
    :select-all="selectAll"
    :hide-actions="hideActions"
  >
    <template
      slot="headerCell"
      slot-scope="props"
    >
      <slot name="tableHeader">
        <span :style="{ display: 'inline-block'}">
          <span v-if="props.header.value === 'days' || props.header.value === 'sum' ">
            <v-edit-dialog lazy>
              <span style="display:'inline-block'">{{ props.header.text }}<v-icon>edit</v-icon></span>
              <template v-slot:input>
                <v-text-field
                  :label="`Изменить ${props.header.text}`"
                  single-line
                  autofocus
                  :mask="props.header.value === 'days' ? '###' : '#######'"
                  :rules="[formRules.minCount, props.header.value === 'days' ? formRules.maxCount: '']"
                  @input="changeColumn(props.header.value, $event)"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </span>
          <span v-else>{{ props.header.text }}</span>
        </span>
      </slot>
    </template>
    <template v-slot:items="props">
      <tr :active="props.selected">
        <td
          pointer
          @click="props.selected = !props.selected"
          class="text-xs-right"
        >
          <v-checkbox
            class="fs-checkbox"
            :input-value="props.selected"
            primary
            hide-details
          />
        </td>
        <td class="text-xs-left">{{ props.item.id }}</td>
        <td class="text-xs-left">{{ props.item.name }}</td>
        <td class="text-xs-left">
          <v-text-field
            mask="###"
            :value="props.item.days || 0"
            @input="changeValue(props.item, $event, 'days')"
            :rules="[formRules.minCount, formRules.maxCount]"
            placeholder="Укажите количество дней пополнения"
          />
        </td>
        <td class="text-xs-left">
          <v-text-field
            mask="#######"
            :value="(props.item.sum || 0) | toRUB "
            @input="changeValue(props.item, $event, 'sum')"
            :rules="[formRules.minCount]"
            placeholder="Укажите сумму для пополнения"
          />
        </td>
      </tr>
    </template>
    <template v-slot:footer>
      <slot name="tableFooter">
        <tr>
          <td colspan="5">
            <v-layout
              row
              align-center
              justify-space-between
              fill-height
            >
              <v-flex
                xs6
                text-md-left
                align-center
              >
                <div class="checkedChildrenTitle">Выбрано: {{childrenCheckedTitle}}</div>
              </v-flex>
              <v-flex
                xs6
                text-md-right
              >
                <v-btn
                  @click="handlerRefresh"
                  flat
                  class="fs-btn fs-teacher-btn__cancel v-btn--active"
                >Отмена</v-btn>
                <v-btn
                  @click="handlerPrint"
                  :loading="btnPrintLoading"
                  :disabled="btnPrintLoading || !acceptPrint"
                  flat
                  class="fs-btn fs-teacher-btn__print v-btn--active"
                  title="Печать квитанций для выбранных детей"
                >Печать квитанций для выбранных детей</v-btn>
              </v-flex>
            </v-layout>
          </td>
        </tr>
      </slot>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    items: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      default: [],
      required: true
    },
    classes: {
      type: String,
      default: 'elevation-1 fs-teacher-table'
    },
    itemKey: {
      type: String,
      default: 'id',
      required: true
    },
    selectAll: {
      type: Boolean,
      default: true
    },
    hideActions: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    normalHeightCheck() {
      return this.normalStyle ? '100%' : 20
    }
  },
  methods: {
    changeDate(name, value) {
      this.$emit(`update:${name}`, value)
      this.$emit('changeDate', { name, value })
    },
    allowedDateStart(val) {
      return val <= this.endDate
    },
    allowedDateEnd(val) {
      return val >= this.startDate
    }
  }
}
</script>
