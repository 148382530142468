<template>
  <v-container class="fs-teacher-container" fluid grid-list-md>
    <Title layout>Информация о загрузке</Title>
    <v-layout>
      <v-flex>
        <v-btn
          flat
          class="fs-btn fs-admin-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active"
          @click="$router.go(-1)"
        >Назад</v-btn>
      </v-flex>
    </v-layout>
    <v-layout class="fs-teacher-order-result mt-3">
      <v-flex>
        <v-card-actions>
          <v-flex xs12 sm12 md2>
            <v-select
              class="mt-1"
              label="Выберите класс"
              v-model="schoolClass"
              :items="classes"
              @change="findSchoolClass"
            ></v-select>
          </v-flex>
          <v-flex md8>
          </v-flex>
          <v-flex xs12 sm12 md2>
            <v-select
              class="ml-3 mt-1"
              label="Элементов на странице"
              v-model="itemsPerPage"
              :items="[25, 50, 100, 200]"
              @change="updateItemsPerPage"
            ></v-select>
          </v-flex>
        </v-card-actions>
        <v-data-table
          :headers="headers"
          :items="getPaginatedItems()"
          :items-per-page="itemsPerPage"
          :page.sync="page"
          :total-items="selectedItems.length"
          hide-actions
          no-data-text="Отсутствуют загруженные заявки"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.index + 1 }}</td>
              <td>{{ props.item.pupil_id }}</td>
              <td>{{ props.item.full_name }}</td>
              <td>{{ props.item.class_name }}</td>
              <td>{{ props.item.meal_type_name }}</td>
              <td>{{ props.item.meal_type_id }}</td>
              <td>
                <v-icon :style="props.item.status ? 'color: green' : 'color: red' ">
                  {{ props.item.status ? "done" : "close" }}
                </v-icon>
              </td>
              <td>{{ props.item.message }}</td>
            </tr>
          </template>
        </v-data-table>
        <v-pagination
          v-model="currentPage"
          :length="totalVisiblePages"
          @input="updateCurrentPage"
          class="mt-2 mb-2"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Title from "@/ui-components/Title"
import navigateTo from '@/mixins/navigateTo'
import ElementGroup from "@/components/common/ElementGroup/ElementGroup"
import WithTooltip from "@/components/common/Tooltip/Tooltip"
import ModalLoadingOrders from '@/components/admin/LoadingOrders/ModalLoadingOrders'
import { getLoadingOrdersDetail } from "@/router/routes/admin"
import store from "@/store"
import { mapGetters } from "vuex"
import Vue from "vue"

export default {
  name: 'LoadingOrdersDetail',
  components: {
    Title,
    ElementGroup,
    WithTooltip,
    ModalLoadingOrders
  },
  mixins: [ navigateTo ],
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      headers: [
        { text: '№', value: 'number' },
        { text: 'ЛС ученика', value: 'pupil_id' },
        { text: 'ФИО ученика ', value: 'full_name' },
        { text: 'Наименование класса', value: 'class_name' },
        { text: 'Наименование типа питания', value: 'meal_type_name' },
        { text: 'Идентификатор типа питания', value: 'meal_type_id' },
        { text: 'Статус', value: 'status' },
        { text: 'Текст', value: 'message' }
      ],
      id: null,
      items: [],
      selectedItems: [],
      classes: [],
      schoolClass: 'Все',
      page: 0,
      itemsPerPage: 25,
      totalVisiblePages: 1,
      currentPage: 1,
    }
  },
  async mounted() {
    this.id = this.$route.params.id
    if (this.id) {
      await this.getLoadingOrdersInfo()
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    sortClasses() {
      this.classes.sort((a, b) => {
        const getNumber = (item) => {
          if (typeof item === 'number') {
            return item
          } else {
            return parseInt(item.split(' ')[0])
          }
        }

        const getLetter = (item) => {
          if (typeof item === 'number') {
            return ''
          } else {
            return item.split(' ')[1]
          }
        }

        const numA = getNumber(a)
        const numB = getNumber(b)

        if (numA === numB) {
          const letterA = getLetter(a)
          const letterB = getLetter(b)
          return letterA.localeCompare(letterB)
        }

        return numA - numB
      })
    },
    findSchoolClass() {
      if (this.schoolClass !== 'Все') {
        this.selectedItems = this.items.filter(item => item ? item.class_name === this.schoolClass : '')
      } else {
        this.selectedItems = this.items
      }
      this.updateTotalPages()
    },
    getPaginatedItems() {
      const startIndex = this.page * this.itemsPerPage
      const endIndex = startIndex + parseInt(this.itemsPerPage, 10)
      return this.selectedItems.slice(startIndex, endIndex)
    },
    updateItemsPerPage() {
      this.page = 0
      this.updateTotalPages()
    },
    updateTotalPages() {
      this.totalVisiblePages = Math.ceil(this.selectedItems.length / this.itemsPerPage)
    },
    updateCurrentPage(page) {
      this.page = page - 1
    },
    async getLoadingOrdersInfo() {
      try {
        const payload = {
          id: this.id,
          school_id: this.currentUser.school_id
        }
        const response = await getLoadingOrdersDetail(payload)
        this.items = response.data.orders_data
        this.selectedItems = this.items
        this.classes = [ ...new Set(this.items.map(item => item.class_name)) ]
        this.sortClasses()
        this.classes.unshift('Все')
        this.updateTotalPages()
      } catch (error) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
  }
}
</script>

<style scoped>

</style>