<template>
  <v-container class="fs-teacher-container" fluid grid-list-md>
    <Title layout>Загрузка заявок</Title>
    <v-layout :class="{ 'fs-header-filter': !darkTheme, 'justify-space-between': true }">
      <element-group title="Инструменты для загрузки заявок">
        <ul>
          <li>
            <with-tooltip text="Шаблон для загрузки заявок">
              <v-btn
              class="fs-btn fs-teacher-btn__excel mr-3"
              @click="getSample('sample')"
            >Шаблон загрузки заявок</v-btn>
            </with-tooltip>
          </li>
          <li>
            <with-tooltip text="Пример шаблона для загрузки заявок">
              <v-btn
              class="fs-btn fs-teacher-btn__excel mr-3"
              @click="getSample('sample_example')"
            >Пример загрузки заявок</v-btn>
            </with-tooltip>
          </li>
          <li>
            <with-tooltip text="Загрузить шаблон для создания заявок">
              <v-btn
              @click="openModalLoadingOrders"
              class="fs-btn fs-teacher-btn__excel mr-3"
            >Загрузить заявки</v-btn>
            </with-tooltip>
          </li>
        </ul>
      </element-group>
    </v-layout>
    <v-card v-if="showModal && modalLoadingOrders">
      <ModalLoadingOrders @close="handleCloseModal"/>
    </v-card>
    <v-layout class="fs-teacher-order-result mt-3">
      <v-flex>
        <v-card-actions>
          <v-flex xs12 sm12 md3>
            <v-menu
              v-model="datepickerMenu"
              :close-on-content-click="false"
              min-width="0"
              style="display: none"
              lazy
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :prepend-inner-icon="!darkTheme ? 'event' : ''"
                  :prepend-icon="darkTheme ? 'event' : ''"
                  label="Выберите дату заказов"
                  v-model="orderDay"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                class="fs-datepicker"
                prev-icon="keyboard_arrow_left"
                next-icon="keyboard_arrow_right"
                locale="ru"
                first-day-of-week="1"
                no-title
                v-model="orderDay"
                @change="changeOrderDay"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex md6>
          </v-flex>
          <v-flex xs12 sm12 md3>
            <v-select
              class="ml-3 mt-1"
              label="Элементов на странице"
              v-model="pageLimit"
              :items="[10, 25, 50]"
              @change="updatePageLimit"
            ></v-select>
          </v-flex>
        </v-card-actions>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          no-data-text="Отсутствуют загруженные заявки"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.index + 1 }}</td>
              <td>{{ props.item.created_at | transformDate }}</td>
              <td>{{ props.item.order_day | transformDate }}</td>
              <td>{{ props.item.author_name }}</td>
              <td>
                <v-flex row>
                  <with-tooltip text="Скачать загруженный файл">
                    <v-icon
                      @click="getOrdersFile(props.item.file, props.item.order_day)"
                      class="fs-active-icon"
                      >download
                    </v-icon>
                  </with-tooltip>
                </v-flex>
              </td>
              <td>
                <v-flex row>
                  <with-tooltip text="Посмотреть подробную информацию о загрузке">
                    <v-icon
                      @click="navigateTo('LoadingOrdersDetail', props.item.id)"
                      class="fs-active-icon"
                      >remove_red_eye
                    </v-icon>
                  </with-tooltip>
                </v-flex>
              </td>
              <td>
                <v-flex row>
                  <with-tooltip text="Удалить созданные заявки и информацию о загрузке">
                    <v-icon
                      @click="deleteLoadingOrdersInfo(props.item.id)"
                      class="fs-active-icon"
                      >delete
                    </v-icon>
                  </with-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-pagination v-model="currentPage" :length="lastPage" @input="getLoadingList"></v-pagination>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import Title from "@/ui-components/Title"
import moment from "moment/moment"
import navigateTo from '@/mixins/navigateTo'
import ElementGroup from "@/components/common/ElementGroup/ElementGroup"
import WithTooltip from "@/components/common/Tooltip/Tooltip"
import ModalLoadingOrders from '@/components/admin/LoadingOrders/ModalLoadingOrders'
import notify from '@/utils/notifications/toastNotification'
import downloadFile from "@/helpers/downloadFileb64Decode"
import {
  getAdminSampleExampleLoadingOrders,
  getAdminSampleLoadingOrders,
  getLoadingOrdersList,
  getOrdersUploadedFile,
  deleteLoadingOrders,
} from "@/router/routes/admin"
import store from "@/store"
import Vue from "vue"

export default {
  name: 'LoadingOrders',
  components: {
    Title,
    ElementGroup,
    WithTooltip,
    ModalLoadingOrders
  },
  mixins: [ navigateTo ],
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      headers: [
        { text: '№', value: 'id' },
        { text: 'Создано', value: 'created_at' },
        { text: 'Дата заказов', value: 'order_day' },
        { text: 'Автор загрузки', value: 'author_name' },
        { text: 'Файл', value: 'file' },
        { text: 'Подробнее', value: '' },
        { text: 'Операции', value: '' }
      ],
      items: [],
      datepickerMenu: false,
      date: null,
      orderDay: null,
      showModal: false,
      modalLoadingOrders: false,
      currentPage: 1,
      lastPage: 1,
      pageLimit: 25
    }
  },
  mounted() {
    this.getLoadingList()
  },
  computed: {
    selectedDateComputed() {
      if (this.selectedDate) return moment(this.selectedDate).format('DD-MM-YYYY')
      return ''
    },
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    changeOrderDay() {
      if (this.orderDay) {
        this.getLoadingList()
      }
      this.datepickerMenu = false
    },
    updatePageLimit(elements) {
      this.pageLimit = elements
      this.currentPage = 1
      this.getLoadingList()
    },
    openModalLoadingOrders() {
      this.modalLoadingOrders = true
      this.showModal = true
    },
    handleCloseModal() {
      this.modalLoadingOrders = false
      this.showModal = false
      this.getLoadingList()
    },
    async getSample(name) {
      const payload = {
        school_id: this.currentUser.school_id,
      }
      const method = name === 'sample' ? getAdminSampleLoadingOrders : getAdminSampleExampleLoadingOrders
      const response = await method(payload)
      await downloadFile(response)
    },
    async getOrdersFile(file, order_day) {
      const payload = {
        school_id: this.currentUser.school_id,
        order_day: order_day,
        file: file,
      }
      const response = await getOrdersUploadedFile(payload)
      await downloadFile(response)
    },
    async getLoadingList() {
      try {
        const payload = {
          school_id: this.currentUser.school_id,
          order_day: this.orderDay,
          current_page: this.currentPage,
          limit: this.pageLimit
        }
        const response = await getLoadingOrdersList(payload)
        this.items = response.data.items
        this.currentPage = response.data.current_page
        this.lastPage = response.data.last_page
      } catch (error) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async deleteLoadingOrdersInfo(id) {
      try {
        const payload = {
          id: id,
          school_id: this.currentUser.school_id
        }
        const response = await deleteLoadingOrders(payload)
        if (response.status === 204) {
          notify.success('Заявки удалены')
          await this.getLoadingList()
        }
      } catch (error) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>

<style scoped>

</style>