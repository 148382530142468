<script>
import moment from "moment"
import { TextEditorModal } from "@/components/common"
import { getInformation } from "@/router/routes/common"
import store from "@/store"

export default {
  name: "HelpContent",

  computed: {
    moment() {
      return moment
    }
  },
  components: {
    TextEditorModal
  },
  data() {
    return {
      playVideo: false,
      tab_headers: [
        'Инструкции', 'Видео-материалы', 'Часто задаваемые вопросы'
      ],
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Заголовок', value: 'title', sortable: false },
        { text: 'Дата создания', value: 'created_at', sortable: false },
        { text: 'Дата обновления', value: 'created_at', sortable: false }
      ],
      title: 'Инструкции',
      items: [],
      dialog: false,
      dialogText: '',
      dialogTitle: '',
    }
  },
  mounted() {
    this.showInformation()
    console.log(this.$router)
  },
  methods: {
    async showInformation() {
      const payload = {
        role: this.$router.currentRoute.meta.userRole
      }
      try {
        const response = await getInformation(payload)
        this.items = [ response.data.instructions, response.data.videos, response.data.faqs ]
        this.dataTableLoaded = true
      } catch (error) {
        // notify.error('Ошибка загрузки информации') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
  },
}
</script>

<template>
  <v-card>
    <v-card-title>
      <h2>Справочные материалы</h2>
    </v-card-title>
    <v-card-text>
      <v-tabs
          slider-color="blue"
      >

        <v-tabs-slider color="red"></v-tabs-slider>

        <v-tab
            v-for="item in tab_headers"
            :key="item"
        >
          {{ item }}
        </v-tab>
        <v-tabs-items>
          <v-tab-item
              v-for="num in 3"
              :key="num"
          >
            <v-card
                color="basil"
                flat
            >
              <v-flex>
                <v-data-table
                    :headers="headers"
                    :items.sync="items[num-1]"
                    hide-actions
                    no-data-text="Нет данных"
                    item-key="id"
                >
                  <template v-slot:items="props">
                    <tr>
                      <td class="cursor-pointer" @click="() => {dialogText = props.item.content; dialog = true; dialogTitle = props.item.title; props.item.type === 2 ? playVideo = true : playVideo = false}">{{ props.item.id }}</td>
                      <td class="cursor-pointer" @click="() => {dialogText = props.item.content; dialog = true; dialogTitle = props.item.title; props.item.type === 2 ? playVideo = true : playVideo = false}">{{ props.item.title }}</td>
                      <td class="cursor-pointer" @click="() => {dialogText = props.item.content; dialog = true; dialogTitle = props.item.title; props.item.type === 2 ? playVideo = true : playVideo = false}">{{ moment(props.item.created_at).format('DD.MM.YYYY hh:mm') }}</td>
                      <td class="cursor-pointer" @click="() => {dialogText = props.item.content; dialog = true; dialogTitle = props.item.title; props.item.type === 2 ? playVideo = true : playVideo = false}">{{ moment(props.item.updated_at).format('DD.MM.YYYY hh:mm') }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>
