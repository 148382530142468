<script>
import Modal from '@/ui-components/Modal.vue'
import {
  createBoardingSchoolsDepartment,
  getMunicipalities,
  getSchoolsByDepartment
} from '@/router/routes/superadmin'
import store from '@/store'
import notify from '@/utils/notifications/toastNotification.vue'
import { value } from 'lodash/seq'
import getDisplayName from '@/helpers/getDisplayName'
import rulesCustom from '@/helpers/rulesCustom'

export default {
  name: 'ModalCreateDepartment',
  components: { Modal },
  emits: ['update-table'],

  data() {
    const rules = rulesCustom

    return {
      isValid: false,
      fieldsValue: {},
      municipalities: [],
      schoolList: [],
      selectMunicipality: null,
      fields: {
        name: {
          type: 'text',
          key: 'name',
          label: 'Поное наименование',
          value: null,
          rules: [rules.required, rules.minLength(3), rules.maxLength(200)],
          counter: 200
        },
        short_name: {
          type: 'text',
          key: 'short_name',
          label: 'Сокращенное наименование',
          value: null,
          rules: [rules.required, rules.minLength(3), rules.maxLength(100)],
          counter: 100
        },
        parent: {
          type: 'select',
          key: 'parent',
          label: 'Департамент',
          value: undefined,
          rules: [rules.required],
          loader: false
        },
        school: {
          type: 'select',
          key: 'school',
          label: 'Школа',
          value: undefined,
          rules: [rules.required],
          loader: false
        }
      }
    }
  },

  methods: {
    getDisplayName,
    openModal() {
      this.$refs.modal.open()
    },

    async getMunicipalitiesList() {
      this.fields.parent.loader = true
      try {
        const {
          data: { data: list }
        } = await getMunicipalities()
        this.municipalities = list
      } catch (e) {
        notify.error('Ошибка загрузки муниципалитетов')
      } finally {
        store.commit('setShowPreloader', false)
        this.fields.parent.loader = false
      }
    },

    async getSchoolList(id) {
      this.fields.school.loader = true
      try {
        const {
          data: { data }
        } = await getSchoolsByDepartment(id)
        this.schoolList = data
      } catch (e) {
        notify.error('Ошибка загрузки школ')
      } finally {
        store.commit('setShowPreloader', false)
        this.fields.school.loader = false
      }
    },

    async createDepartment() {
      if (this.isValid) {
        Object.entries(this.fields).forEach(
          ([k, v]) => (this.fieldsValue[k] = v.value)
        )
        try {
          const { data } = await createBoardingSchoolsDepartment({
            data: this.fieldsValue
          })
          if (data.success) {
            this.$refs.formAddingDepartment.reset()
            this.$refs.modal.close()
            notify.success('Департамент Создан')
            this.$emit('update-table')
          }
        } catch (err) {
        } finally {
          store.commit('setShowPreloader', false)
        }
      }
    },
    clearSearch() {
      this.schoolList = []
    },
    clearModalForm() {
      Object.keys(this.fields).forEach((k) => (this.fields[k].value = null))
      this.clearSearch()
      this.$refs.formAddingDepartment.resetValidation()
      this.fieldsValue = {}
    }
  },
  async mounted() {
    await this.getMunicipalitiesList()
  },
  watch: {
    'fields.parent.value'(newValue) {
      if (newValue) {
        this.selectMunicipality = newValue
      } else {
        this.selectMunicipality = null
      }
    },
    async selectMunicipality(newValue) {
      if (newValue) {
        await this.getSchoolList(newValue)
      }
    }
  }
}
</script>

<template>
  <Modal
    ref="modal"
    title="Добавление департамента школы-интернат"
    @close:before="clearModalForm"
  >
    <v-form
      ref="formAddingDepartment"
      v-model="isValid"
      @submit.prevent="createDepartment"
    >
      <div v-for="item in fields" :key="item.key">
        <v-text-field
          v-if="item.type !== 'select'"
          v-model="item.value"
          :label="item.label"
          :rules="item.rules.map((rule) => rule(item))"
          :maxlength="item.counter || false"
          clearable
          box
          required
          :counter="item.counter || false"
        />
        <v-autocomplete
          v-else-if="item.key === 'parent'"
          v-model="item.value"
          :items="municipalities"
          :label="item.label"
          :rules="item.rules.map((rule) => rule(item))"
          :loading="item.loader"
          item-text="name"
          item-value="id"
          :item-text="getDisplayName"
          clearable
          box
          @click:clear="clearSearch"
          required
        />
        <v-autocomplete
          v-else-if="item.key === 'school'"
          v-model="item.value"
          :items="schoolList"
          :label="item.label"
          :rules="item.rules.map((rule) => rule(item))"
          :loading="item.loader"
          :disabled="!selectMunicipality"
          :item-text="getDisplayName"
          clearable
          box
          item-value="id"
          required
        />
      </div>
    </v-form>
    <template v-slot:actions>
      <v-btn color="primary" @click="createDepartment" :disabled="!isValid">
        Подтвердить
      </v-btn>
    </template>
  </Modal>
</template>

<style scoped></style>
