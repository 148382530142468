<template>
  <v-card flat>
    <v-card-text>
      <v-checkbox 
        v-for="item in form.questions" :key="item.title"
        v-model="item.value" 
        :label="`${item.title}: ${item.value ? 'Есть': 'Нет'}`" 
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        flat
        @click="update"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Form8',
  data() {
    return {
      form: {
        id: null,
        questions: [
          { title: 'Должностные инструкции сотрудников пищеблока', name: 'document_1', value: false },
          { title: 'Журнал бракеража пищевых продуктов и продовольственного сырья', name: 'document_2', value: false },
          { title: 'Журнал бракеража готовой кулинарной продукции', name: 'document_3', value: false },
          { title: 'Журнал «Здоровье»', name: 'document_4', value: false },
          { title: 'Журнал проведения витаминизации третьих и сладких блюд', name: 'document_5', value: false },
          { title: 'Журнал учета температурного режима холодильного оборудования', name: 'document_6', value: false },
          { title: 'Ведомость контроля за питанием', name: 'document_7', value: false },
          { title: 'Свидетельство о поверке весового оборудования', name: 'document_8', value: false },
          { title: 'Меню-раскладка', name: 'document_9', value: false },
          { title: 'Сопроводительные документы: Товаротранспортная накладная (ТТН) с включением сведений о качестве безопасности продукции. Ветеринарные сопроводительные документы', name: 'document_10', value: false },
          { title: 'Перспективное и ежедневное меню', name: 'document_11', value: false },
          { title: 'Технологические карты', name: 'document_12', value: false },
          { title: 'Инструкции к моющим и дезинфицирующим средствам', name: 'document_13', value: false },
          { title: 'Программа производственного контроля', name: 'document_14', value: false },
          { title: 'Личная медицинская книжка сотрудника пищеблока', name: 'document_15', value: false },
          { title: 'Функциональные обязанности ответственного за организацию питания (администратора питания) образовательной организации', name: 'document_16', value: false },
          { title: 'Акты выполненных работ по вывозу твердых бытовых и пищевых отходов, утилизации', name: 'document_17', value: false },
          { title: 'Акты выполненных работ по вывозу люминесцентных ламп, исправности лифтового оборудования пищеблока', name: 'document_18', value: false },
          { title: 'Копии актов выполненных работ на проведение дезинсекции, дератизации помещений пищеблока с указанием площади обработки', name: 'document_19', value: false },
          { title: 'Копии актов выполненных работ на проведение дезинфекции системы вентиляции', name: 'document_20', value: false },
          { title: 'График приема пищи учащимися с указанием продолжительности перемен и перечня классов, на каждой перемене', name: 'document_21', value: false },
        ]
      }
    }
  },
  computed: {
    ...mapGetters([ 'foodBlockPassport' ])
  },
  mounted() {
    this.getFoodblockDocuments(this.foodBlockPassport.documents_sections.id)
      .then(result => this.setForm(result))
  },
  methods: {
    ...mapMutations([ 'setShowPreloader', 'setShowPreloader', 'updateFoodBlocknames' ]),
    ...mapActions([ 'getFoodblockDocuments', 'updateFoodBlockDocuments' ]),
    prepareObjectForApi() {
      const res = {}
      this.form.questions.forEach(item => {
        res[item.name] = item.value
      })
      res["passport"] = this.foodBlockPassport.id
      return res
    },
    setForm(data) {
      this.form.id = data.id
      this.form.passport = data.passport
      this.form.questions.forEach(item => {
        for (const key in data) {
          if (item.name === key) item.value = data[key]
        }
      })
    },
    update() {
      const requestBody = this.prepareObjectForApi()
      
      this.updateFoodBlockDocuments({ id: this.form.id, data: requestBody })
        .then(result => this.setForm(result))
    }
  },
  
}
</script>
    