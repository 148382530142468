<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <div class="mb-3 d-flex">
        <h1>{{ title }}</h1>
      </div>
    </v-layout>
    <v-layout
      class="fs-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1"
          item-key="student.name"
        >
         <template v-slot:items="props">
            <tr>
              <td>{{ props.item.student.id }}</td>
              <td>{{ props.item.student.name }}</td>
              <td>{{ props.item.foodType.name }}</td>
              <td>{{ props.item.complect.name }}</td>
              <td>{{ props.item.student.balance }}</td>
              <td>{{ props.item.foodType.price }}</td>
              <td>{{ props.item.parentPayment }}</td>
              <td>{{ props.item.privilege }}</td>
              <td>{{ getOrderStatus(props.item.status, props.item.is_auto_status) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex
        text-md-right
        pt-3
      >
        <v-btn
          flat
          class="primary"
          @click="$router.go(-1)"
        >назад</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import store from '@/store'
import {mapMutations} from "vuex";
import moment from "moment/moment";
import Vue from "vue";
import {getClassDetails} from "@/router/routes/organizer";
import _ from "lodash";
import {ConfirmModal} from "@/components/common";
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue";
import WithTooltip from "@/components/common/Tooltip/Tooltip.vue";
import numable from "@/mixins/balancable";


export default {
  name: 'OrganizerOrderDetailsPupils',
  mixins: [ numable ],
  components: {WithTooltip, ContextHelpModal, ConfirmModal},
  created() {
    this.loadOrder().finally(() => store.commit('setShowPreloader', false))
  },
  data() {
    return {
      title: 'Заявка на питание',
      datepickerMenu: false,
      headers: [
        {text: 'Лицевой счет', value: 'student.id'},
        {text: 'ФИО ученика', value: 'student.name'},
        {text: 'Тип питания', value: 'foodType.name'},
        {text: 'Выбранный комплекс', value: 'complect.name'},
        {text: 'Баланс, руб.', value: 'student.balance'},
        {text: 'Полная стоимость, руб.', value: 'foodType.price'},
        {text: 'Доплата родителя, руб.', value: 'parentPayment'},
        {text: 'Льгота, руб.', value: 'privilege'},
        {text: 'Статус', value: 'status'},
      ],
      items: []
    }
  },
   methods: {
     ...mapMutations(['setShowPreloader']),
     loadOrder() {
       const payload = {
         schoolId: this.$route.params.schoolId,
         classId: this.$route.params.classId,
         date: moment(this.$route.params.date).format(
             Vue.config.format.pythonDate
         )
       }
       return getClassDetails(payload).then(response => {
         const items = _.map(response.data.data, item => {
           return {
             student:{
                id: _.get(item, 'pupil.id', null),
               name: _.get(item, 'pupil.fullName', ''),
               balance: this.convertToRubles(_.get(item, 'pupil.balance', 0))
             },
             foodType:{
               name: _.get(item, 'foodType.name', ''),
               price: this.convertToRubles(_.get(item, 'foodType.price', 0))
             },
             complect:{
               name: _.get(item, 'complect.name', ''),
             },
             parentPayment:
              this.roundRubles(this.convertToRubles(_.get(item, 'foodType.price', 0)) -
                this.convertToRubles(
                  _.get(item, 'privilege.price', 0)
                )),
             privilege: this.convertToRubles(_.get(item, 'privilege.price', 0)),
             status: _.get(item, 'status', ''),
             is_auto_status: _.get(item, 'status_received_is_auto', null),
             date: _.get(item, 'orderDay', null)
           }
         })
         this.items = _.filter(items, item => item.status != null)
       })
     },
     getOrderStatus(value, auto) {
      switch (value) {
      case 1:
        return 'Черновик'
      case 2:
        return 'Подтвержден учителем'
      case 3:
        return 'Подтвержден школой'
      case 4:
        return 'Выдан'
      case 5:
        if (auto)
          return 'Выполнен (без списания)'
        return 'Выполнен'
      case 6:
        return 'Отменен'
      case 7:
        return 'Сохранен учителем'
      case 8:
        return 'Сохранен школой'
      case 9:
        return 'Подтвержден автоматически'
      case 10:
        return 'Изменен'
      }
    },
   }
}
 </script>