<template>
  <v-container fluid class="fs-organizer-container">
    <v-flex md4 mb-5>
      <h1>{{title}}</h1>
    </v-flex>
    <v-form>
      <v-layout row justify-space-around>
        <v-flex md5>
          <v-text-field label="Наименование" :value="item.name || ''"></v-text-field>
          <v-flex text-md-right>
            <v-menu
              v-model="datepickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field v-model="date" prepend-icon="event" readonly v-on="on"></v-text-field>
              </template>
              <v-date-picker
                class="fs-datepicker"
                prev-icon="keyboard_arrow_left"
                next-icon="keyboard_arrow_right"
                locale="ru"
                first-day-of-week="1"
                no-title
                v-model="date"
                @input="datepickerMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-flex>
        <v-flex md5>
          <fs-autocomplete outline height="30" label="Образовательная организация"></fs-autocomplete>
          <fs-autocomplete outline height="30" label="Тип питания"></fs-autocomplete>
        </v-flex>
      </v-layout>
      <v-flex md3 mb-2>
        <h3>Спецификация комплекса</h3>
      </v-flex>
      <v-flex mb-3>
        <v-divider></v-divider>
      </v-flex>
      <v-layout column mb-3>
        <v-tabs grow>
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab>Завтрак</v-tab>

          <v-tab>Обед</v-tab>

          <v-tab>Полдник</v-tab>

          <v-tab>Ужин</v-tab>

          <v-tab-item>
            <v-layout row>
              <v-flex>
                <v-data-table
                  :headers="complexHeaders"
                  :items="complexBreakfastItems"
                  hide-actions
                  class="elevation-1 fs-admin-table"
                >
                  <template v-slot:items="props">
                    <tr>
                      <td>
                        <fs-autocomplete
                          outline
                          height="30"
                          :items="preparedDishes"
                          :value="props.item.dishName"
                          clearable
                        ></fs-autocomplete>
                      </td>
                      <td>
                        <v-flex row>
                          <v-icon class="fs-active-icon">edit</v-icon>
                          <v-icon class="fs-active-icon">delete</v-icon>
                        </v-flex>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-tab-item>
          <v-tab-item>
            <v-layout row>
              <v-flex>
                <v-data-table
                  :headers="complexHeaders"
                  :items="complexLunchItems"
                  hide-actions
                  class="elevation-1"
                >
                  <template v-slot:items="props">
                    <tr>
                      <td>
                        <fs-autocomplete
                          outline
                          height="30"
                          :items="preparedDishes"
                          :value="props.item.dishName"
                          clearable
                        ></fs-autocomplete>
                      </td>
                      <td>
                        <v-flex row>
                          <v-icon class="fs-active-icon">edit</v-icon>
                          <v-icon class="fs-active-icon">delete</v-icon>
                        </v-flex>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-tab-item>
          <v-tab-item>
            <v-layout row>
              <v-flex>
                <v-data-table
                  :headers="complexHeaders"
                  :items="complexAfternoonTeaItems"
                  hide-actions
                  class="elevation-1"
                >
                  <template v-slot:items="props">
                    <tr>
                      <td>
                        <fs-autocomplete
                          outline
                          height="30"
                          :items="preparedDishes"
                          :value="props.item.dishName"
                          clearable
                        ></fs-autocomplete>
                      </td>
                      <td>
                        <v-flex row>
                          <v-icon class="fs-active-icon">edit</v-icon>
                          <v-icon class="fs-active-icon">delete</v-icon>
                        </v-flex>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-tab-item>
          <v-tab-item>
            <v-layout row>
              <v-flex>
                <v-data-table
                  :headers="complexHeaders"
                  :items="complexDinnerItems"
                  hide-actions
                  class="elevation-1"
                >
                  <template v-slot:items="props">
                    <tr>
                      <td>
                        <fs-autocomplete
                          outline
                          height="30"
                          :items="preparedDishes"
                          :value="props.item.dishName"
                          clearable
                        ></fs-autocomplete>
                      </td>
                      <td>
                        <v-flex row>
                          <v-icon class="fs-active-icon">edit</v-icon>
                          <v-icon class="fs-active-icon">delete</v-icon>
                        </v-flex>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-tab-item>
        </v-tabs>
      </v-layout>
      <v-flex text-md-right>
        <v-btn flat class="fs-btn fs-btn--secondary">отмена</v-btn>
        <v-btn flat class="fs-btn fs-btn--primary fs-teacher-btn__cancel v-btn--active">сохранить</v-btn>
      </v-flex>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "ComplexCreate",
  data() {
    return {
      title: "Добавить комплекс",
      tab: null,
      item: this.$route.query,
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10),
      complexHeaders: [
        { text: "Блюдо", value: "dish-name" },
        { text: "Операции", value: "operations" }
      ],
      complexBreakfastItems: [
        {
          id: 1,
          dishName: "Сосиски"
        },
        {
          id: 2,
          dishName: "Макароны"
        },
        {
          id: 3,
          dishName: "Суп гороховый"
        },
        {
          id: 4,
          dishName: "Суп лапша"
        },
        {
          id: 5,
          dishName: "Суп борщ"
        }
      ],
      complexLunchItems: [
        {
          id: 1,
          dishName: "Сосиски"
        },
        {
          id: 2,
          dishName: "Макароны"
        },
        {
          id: 3,
          dishName: "Суп гороховый"
        },
        {
          id: 4,
          dishName: "Суп лапша"
        },
        {
          id: 5,
          dishName: "Суп борщ"
        }
      ],
      complexAfternoonTeaItems: [
        {
          id: 1,
          dishName: "Сосиски"
        },
        {
          id: 2,
          dishName: "Макароны"
        },
        {
          id: 3,
          dishName: "Суп гороховый"
        },
        {
          id: 4,
          dishName: "Суп лапша"
        },
        {
          id: 5,
          dishName: "Суп борщ"
        }
      ],
      complexDinnerItems: [
        {
          id: 1,
          dishName: "Сосиски"
        },
        {
          id: 2,
          dishName: "Макароны"
        },
        {
          id: 3,
          dishName: "Суп гороховый"
        },
        {
          id: 4,
          dishName: "Суп лапша"
        },
        {
          id: 5,
          dishName: "Суп борщ"
        }
      ],
      preparedDishes: [
        {
          text: "Суп гороховый",
          value: 1
        },
        {
          text: "Макароны",
          value: 2
        },
        {
          text: "Сосиски",
          value: 3
        },
        {
          text: "Суп борщ",
          value: 4
        },
        {
          text: "Суп лапша",
          value: 5
        }
      ]
    }
  }
}
</script>
