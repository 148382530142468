<template>
  <v-card flat>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-actions
      class="elevation-1"
    >
      <template v-slot:items="props">
          <tr>
            <td>{{ props.item.name }}</td>
            <td>
              <v-text-field
                v-model="props.item.square"
                type="number"
                min="0"

                :label="!darkTheme ? 'Площадь кв.м' : ''"
                :placeholder="darkTheme ? 'Площадь кв.м' : ''"
                :outline="darkTheme"
                :hide-details="darkTheme"
              />
            </td>
            <td>
              <v-text-field
                v-model="props.item.tehnicalbox"
                type="number"
                min="0"

                :label="!darkTheme ? 'Едениц' : ''"
                :placeholder="darkTheme ? 'Едениц' : ''"
                :outline="darkTheme"
                :hide-details="darkTheme"
              />
            </td>
            <td>
              <v-text-field
                v-model="props.item.coldbox"

                :label="!darkTheme ? 'Кол-во/год выпуска' : ''"
                :placeholder="darkTheme ? 'Кол-во/год выпуска' : ''"
                :outline="darkTheme"
                :hide-details="darkTheme"
              />
            </td>
            <td>
              <v-text-field
                v-model="props.item.washbox"
                type="number"
                min="0"

                :label="!darkTheme ? 'Едениц' : ''"
                :placeholder="darkTheme ? 'Едениц' : ''"
                :outline="darkTheme"
                :hide-details="darkTheme"
              />
            </td>
          </tr>
        </template>
    </v-data-table>
    <v-card-actions>
      <v-btn
        flat
        @click="update"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import store from "@/store"
export default {
  name: 'Form4',
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      formId: null,
      headers: [
        { text: 'Набор помещений', sortable: false },
        { text: 'Площадь', sortable: false },
        { text: 'Технологическое (плиты, моечные ванны, столы и пр.), ед.', sortable: false },
        { text: 'Холодильное (наименование кол-во единиц/год выпуска)', sortable: false },
        { text: 'Наличие раковин для мытья рук, ед.', sortable: false },
      ],
      names: {
        "dinner_room": "Обеденный зал",
        "dispensing_area": "Раздаточная зона",
        "vegetable_workshop": "Овощной цех",
        "cold_shop": "Холодный цех",
        "meat_and_fish_shop": "Мясорыбный цех",
        "egg_processing_room": "Помещение для обработки яиц",
        "flour_shop": "Мучной цех",
        "finishing_shop": "Доготовочный цех",
        "bread_slicer": "Помещение для нарезки хлеба",
        "hot_shop": "Горячий цех",
        "dishwasher": "Моечная для мытья столовой посуды",
        "washing_kitchen_utensils": "Моечная кухонной посуды",
        "washing_containers": "Моечная тары",
        "premises__processing_storing_cleaning_equipment": "Помещение для обработки и хранения уборочного инвентаря",
        "storehouses": "Склады",
        "buffet": "Буфет",
        "dispensing_canteen_production_room": "Производственное помещение буфета раздаточной",
        "dishwasher_sideboard": "Посудомоечная буфета-раздаточной",
        "eating_room": "Комната для приема пищи"
      },
      items: [
        { name: 'Обеденный зал', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "dinner_room" },
        { name: 'Раздаточная зона', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0 , id: "dispensing_area" },
        { name: 'Овощной цех', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "vegetable_workshop" },
        { name: 'Холодный цех', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "cold_shop" },
        { name: 'Мясорыбный цех', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "meat_and_fish_shop" },
        { name: 'Помещение для обработки яиц', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "egg_processing_room" },
        { name: 'Мучной цех', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "flour_shop" },
        { name: 'Доготовочный цех', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "finishing_shop" },
        { name: 'Помещение для нарезки хлеба', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "bread_slicer" },
        { name: 'Горячий цех', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "hot_shop" },
        { name: 'Моечная для мытья столовой посуды', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "dishwasher" },
        { name: 'Моечная кухонной посуды', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "washing_kitchen_utensils" },
        { name: 'Моечная тары', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "washing_containers" },
        { name: 'Помещение для обработки и хранения уборочного инвентаря', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "premises__processing_storing_cleaning_equipment" },
        { name: 'Склады', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "storehouses" },
        { name: 'Буфет', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "buffet" },
        { name: 'Производственное помещение буфета раздаточной', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "dispensing_canteen_production_room" },
        { name: 'Посудомоечная буфета-раздаточной', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "dishwasher_sideboard" },
        { name: 'Комната для приема пищи', square: 0, tehnicalbox: 0, coldbox: "", washbox: 0, id: "eating_room" },
      ]
    }
  },
  mounted() {
    this.getFoodblockRooms(this.foodBlockPassport.room_sections.id)
      .then(result => this.setForm(result))
  },
  methods: {
    ...mapActions([ 'getFoodblockRooms', 'updateFoodBlockRooms' ]),
    update() {
      this.updateFoodBlockRooms({ id: this.formId, data: { result: this.transform_data_to_back(this.items), passport: this.foodBlockPassport.id } })
        .then(result => this.setForm(result))
    },
    setForm(data) {
      this.formId = data.id
      const items = data.result ? data.result : this.items
      this.items = this.transform_data_to_front(items)
    },
    transform_data_to_front(backendData) {
      const names = this.names
      const res = Object.keys(backendData).map(function(key) {
        const { square, tehnicalbox, coldbox, washbox } = backendData[key]
        return {
          name: names[key],
          id: key,
          square: square,
          tehnicalbox: tehnicalbox,
          coldbox: coldbox,
          washbox: washbox
        }
      })
      return res
    },
    transform_data_to_back(frontendData) {
      const res = {}
      frontendData.forEach(el => {
        res[el.id] = {
          square: el.square,
          tehnicalbox: el.tehnicalbox,
          coldbox: el.coldbox,
          washbox: el.washbox
        }
      })
      return res
    },
  },
  computed: {
    ...mapGetters([ 'foodBlockPassport' ])
  }
}
</script>
