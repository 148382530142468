<template>
  <v-container
    fluid
  >
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <div class="d-flex mb3">
        <h1>{{ title }}</h1>
        <context-help-modal :context-key="contextKeys.supplier.refund" />
      </div>
    </v-layout>

    <v-layout
      row
      justify-start
    >
      <v-flex
        md2
        mr-3
      >
        <v-select
          :value="selectedSchool"
          @change="changeSchoolValue('selectedSchool', $event)"
          :items="schools"
          item-text="name"
          item-value="id"
          label="Образовательная организация"
        ></v-select>
      </v-flex>
      <v-flex
        md2
        mr-3
      >
        <v-select
          :value="selectedClass"
          @change="changeValue('selectedClass', $event)"
          :items="classes"
          item-text="name"
          item-value="id"
          label="Класс"
        ></v-select>
      </v-flex>
      <v-flex
        md2
        mr-3
      >
        <v-text-field
          label="ФИО"
          :value="pupilName"
          @change="changeValue('pupilName', $event)"
        ></v-text-field>
      </v-flex>
      <v-flex
        md2
        mr-3
      >
        <v-select
          :value="status"
          @change="changeValue('status', $event)"
          :items="filterStatuses"
          item-text="name"
          item-value="id"
          label="Статус"
        ></v-select>
      </v-flex>
      <v-flex
        md2
        mr-3
      >
        <v-btn
          flat
          @click="search"
          class="fs-btn fs-btn--primary"
        >Показать</v-btn>
      </v-flex>
      <v-flex>
        <!--          TODO Заменить SUPPLIER_REFUND на параметры из бд -->
        <v-btn
          flat
          class="fs-btn fs-btn--primary"
          @click="toggleCashbackModal()"
          v-if="showCreateRefundButton"
        >Добавить заявление</v-btn>
      </v-flex>
    </v-layout>

    <v-layout
      class="fs-complex-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          no-data-text="Заявления на возврат отсутствуют"
          hide-actions
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.created_at }}</td>
              <td>{{ props.item.refund_at }}</td>
              <td>{{ props.item.sender }}</td>
              <td>{{ props.item.status_display }}</td>
              <td>{{ props.item.pupil.school.name }}</td>
              <td>{{ props.item.pupil.school_class.name }}</td>
              <td>{{ props.item.pupil.full_name }}</td>
              <td>{{ convert(props.item.balance_on_submit) }} </td>
              <td>{{ convert(props.item.amount) }}</td>
              <td>{{ convert(props.item.balance_on_approve) }} </td>
              <td>{{ props.item.comment }}</td>
              <td>
                <v-flex row v-if="props.item.status === statuses.STATUS_NEW">
                  <v-btn
                    flat
                    @click="setStatus(props.item.id, statuses.STATUS_WORK)"
                  >В работе</v-btn>
                </v-flex>
                <v-flex row v-if="props.item.status === statuses.STATUS_WORK">
                  <v-btn
                    flat
                    @click="setStatus(props.item.id, statuses.STATUS_COMPLETED)"
                  >Выполнено</v-btn>
                </v-flex>
                <v-flex row v-if="props.item.status === statuses.STATUS_WORK">
                  <v-btn
                    flat
                    @click="setStatus(props.item.id, statuses.STATUS_DENIED)"
                  >Отказано</v-btn>
                </v-flex>
                <v-btn
                  flat
                  @click="download(props.item.id)"
                >Скачать</v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <CreateCashbackModal
      :cashbackModal="cashbackModal"
    />

    <ConfirmModal
      :dialog.sync="confirm.show"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="handlerConfirmModal"
    >
      <template v-slot:headline>Подтвердите действие</template>
      <template v-slot:body>
        <div v-if="confirm.newStatus === statuses.STATUS_WORK">
          Вы действительно хотите перевести заявление в статус "В работе"?
        </div>
        <div v-if="confirm.newStatus === statuses.STATUS_COMPLETED">
          Вы действительно хотите перевести заявление в статус "Выполнено"?
          <v-text-field type="number" v-model="confirm.amount" label="Укажите сумму возврата"></v-text-field>
        </div>
      </template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="decline.show"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="handlerConfirmModalDecline"
    >
      <template v-slot:headline>Подтвердите действие</template>
      <template v-slot:body>
          Вы действительно хотите перевести заявление в статус "Отказано"?
          <v-text-field v-model="decline.comment" label="Укажите причину"></v-text-field>
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import store from '@/store'
import converter from "@/helpers/converter"
import {
  getCashbackDocument,
  getCashbackRequests,
  setCashbackDocumentStatus,
  getSchools,
  getClasses
} from '@/router/routes/organizer'
import scrollable from '@/mixins/scrollable'
import _ from 'lodash'
import moment from "moment"
import downloadFile from "@/helpers/downloadFile"
import { ConfirmModal } from '@/components/common'
import CreateCashbackModal from '@/components/organizer/Modals/CreateCashbackModal'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'

const STATUS_NEW = 1
const STATUS_WORK = 2
const STATUS_COMPLETED = 3
const STATUS_DENIED = 4
const STATUS_REVOKED = 5

export default {
  name: 'OrganizerCashbackIndex',
  mixins: [ scrollable ],
  components: { ContextHelpModal, ConfirmModal, CreateCashbackModal },
  data() {
    return {
      title: 'Заявления на возврат',
      contextKeys: Vue.config.contextHelp,
      cashbackModal: false,
      showCreateRefundButton: store.getters.setting('supplier_refund'),
      headers: [
        { text: 'Дата подачи', value: 'created_at' },
        { text: 'Дата возврата', value: 'refund_at' },
        { text: 'Отправитель', value: 'sender' },
        { text: 'Статус', value: 'status_display' },
        { text: 'Школа', value: 'school' },
        { text: 'Класс', value: 'school_class' },
        { text: 'Ученик', value: 'pupil.full_name' },
        { text: 'Баланс на дату подачи', value: 'balance_on_submit' },
        { text: 'Сумма возврата', value: 'amount' },
        { text: 'Баланс на дату возврата', value: 'balance_on_approve' },
        { text: 'Комментарий', value: 'comment' },
        { text: 'Операции', value: 'actions' }
      ],
      items: [],
      confirm: {
        id: null,
        newStatus: null,
        show: false,
        amount: null,
      },
      decline: {
        id: null,
        show: false,
        comment: ''
      },
      showModal: false,
      statuses: {
        STATUS_NEW,
        STATUS_WORK,
        STATUS_COMPLETED,
        STATUS_DENIED
      },
      filterStatuses: [
        { id: 0, name: 'Все' },
        { id: STATUS_NEW, name: 'Новая' },
        { id: STATUS_WORK, name: 'В работе' },
        { id: STATUS_COMPLETED, name: 'Исполнено' },
        { id: STATUS_DENIED, name: 'Отказано' },
        { id: STATUS_REVOKED, name: 'Отозвано' },
      ],
      schools: [],
      classes: [],
      selectedSchool: null,
      selectedClass: null,
      features: this.$store.state.common.features,
      pupilName: '',
      status: 0,
    }
  },
  created() {
    this.loadMore()
    this.getSchools()
    window.addEventListener('scroll', this.scrollHandler)
  },
  destroyed() {
    this.removeScrollHandler()
  },
  methods: {
    ...mapGetters([ 'setting' ]),
    ...mapMutations([ 'setShowPreloader' ]),
    getClassNumber(name) {
      return name.split(' ')[0]
    },
    getClassLiter(name) {
      return name.split(' ')[1]
    },
    toggleCashbackModal() {
      this.cashbackModal = !this.cashbackModal
    },
    async search() {
      this.resetData()
      this.loadMore()
    },
    async changeSchoolValue(type, value) {
      this[type] = value
      this.classes = []
      if (value) {
        await this.getClasses()
      }
    },
    changeValue(type, value) {
      this[type] = value
    },
    async getSchools() {
      try {
        const PAYLOAD = {
          limit: 200,
        }
        const response = await getSchools(PAYLOAD)
        const {
          data: { data: items }
        } = response
        this.schools = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    convert(amount) {
      return converter(amount, 'pennyToRub')
    },
    async getClasses() {
      const PAYLOAD = {
        limit: Vue.config.general.classLimit,
        school_id: this.selectedSchool
      }
      try {
        const response = await getClasses(PAYLOAD)
        const {
          data: { data: items }
        } = response
        this.classes = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    resetData() {
      this.items = []
    },
    async handlerConfirmModal(value) {
      this.confirm.show = false

      const amount = parseInt(this.confirm.amount * 100)
      if (amount < 0) {
        notify.error('Укажите сумму больше нуля') //pass
        return
      }

      const { id, newStatus } = this.confirm
      this.confirm.id = null
      this.confirm.newStatus = null
      this.confirm.amount = null
      if (!value) {
        return
      }
      const payload = {
        id,
        status: newStatus,
        amount
      }
      await setCashbackDocumentStatus(payload)
      this.resetData()
      this.loadMore()
    },
    async handlerConfirmModalDecline(value) {
      const { id, comment } = this.decline
      if (!value) {
        this.decline.id = null
        this.decline.comment = ''
        this.decline.show = false
        return
      }
      if (!comment) {
        return
      }
      this.decline.id = null
      this.decline.comment = ''
      this.decline.show = false
      const payload = {
        id,
        status: STATUS_DENIED,
        comment,
      }
      await setCashbackDocumentStatus(payload)
      this.resetData()
      this.loadMore()
    },
    setStatus(id, status) {
      if (status === STATUS_WORK || status === STATUS_COMPLETED) {
        this.confirm.id = id
        this.confirm.newStatus = status
        this.confirm.show = true
        return
      }
      if (status === STATUS_DENIED) {
        this.decline.id = id
        this.decline.comment = ''
        this.decline.show = true
      }
    },
    async download(id) {
      const response = await getCashbackDocument(id)
      await downloadFile(response)
    },
    loadMore() {
      const payload = {
        params: {
          limit: Vue.config.general.limit,
          offset: this.offset,
          school: this.selectedSchool,
          school_class: this.selectedClass,
          name: this.pupilName,
          status: this.status || null
        }
      }

      getCashbackRequests(payload)
        .then(response => {
          const items = _.map(response.data.data, item => {
            return {
              ...item,
              created_at: moment(item.created_at).format(Vue.config.format.dateString.time)
            }
          })
          this.items = this.items.concat(items)
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
