<template>
  <v-container fluid class="fs-parent-container">
      <div class="page-head__common">
        <h1>Оценки заказов</h1>
      </div>
      <v-layout wrap align-center class='mb-3'>
        <v-flex xs12 md12 lg4 d-flex class='pr-2'>
          <v-select
              v-if="children && children.length > 0"
              :items="children"
              item-text="fullName"
              item-value="id"
              clearable
              return-object
              v-model="choice_children"

              placeholder="Все дети"
              :outline="darkTheme"
              :hide-details="darkTheme"
          ></v-select>
        </v-flex>

        <v-flex xs12 md6 lg4 d-flex class='mb-2 pr-2'>
            <v-flex xs6 d-flex>
              <div class="card-detail__item">
                <DateTimePicker v-model="dateStart" label="Дата от" date-only auto-set-time clearable/>
              </div>
            </v-flex>

            <v-flex xs6 d-flex>
              <div class="card-detail__item">
                <DateTimePicker v-model="dateEnd" label="Дата по" date-only auto-set-time clearable/>
              </div>
            </v-flex>
        </v-flex>
        <v-flex xs12 md6 lg4 d-flex class='pr-2'>
          <v-select
              v-model='onlyWithGrade'
              :items="gradeFilters"
              :outline="darkTheme"
              :hide-details="darkTheme"
          ></v-select>
        </v-flex>
      </v-layout>

      <v-layout class="fs-archive-result" mb-3 >
        <v-flex>
          <v-data-table
            :headers="headers"
            :items.sync="children_orders.results"
            hide-actions
            no-data-text="У выбранного ребёнка нет заказов"
            class="elevation-1"
            item-key="name"
          >
            <template v-slot:items="props">
              <tr
                style="cursor: pointer;"
                @click="showModal(props.item)"
              >
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.order_day }}</td>
                <td>{{ props.item.pupil.full_name }}</td>
                <td>{{ props.item.order_grade?.comment.slice(0, 80) || '---' }}</td>
                <td>
                  <div v-if="is_grade_already_exist(props.item.order_grade)">
                    <v-rating
                      style="margin: 0 auto;"
                      v-model="props.item.order_grade.evaluation"
                      length="10"
                      readonly
                    />
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <div class="text-xs-center" v-if="pagination_count > 1">
        <v-pagination
          @input="handlePaginationClick"
          :value="children_orders_pagination.page"
          :length="pagination_count"
          :total-visible="7"
        ></v-pagination>
      </div>

      <v-dialog v-model="isShowModal" persistent width="70%" style="z-index: 9999;">
        <QualityAssessmentsDetail
          v-if="isShowModal"
          :orderId="orderId"
          :orderGradeId="orderGradeId"
          :readonly="readonly"
          @save="handleSaveGrade"
          @close="handleCloseModal"
        />
      </v-dialog>

  </v-container>
</template>

<script>
import Vue from 'vue'
import QualityAssessmentsDetail from './QualityAssessmentsDetail.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getAllStudents } from '@/router/routes/parent.js'
import DateTimePicker from '@/components/common/DatePickersSmart/DateTimePicker.vue'
import SuppliersAutocomplete from '@/components/common/QualityControl/inputs/SuppliersAutocomplete.vue'

export default {
  name: 'ParentQualityAssessments',
  components: { SuppliersAutocomplete, DateTimePicker, QualityAssessmentsDetail },
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      isShowModal: false,
      readonly: false,
      orderGradeId: null,
      orderId: null,
      dateStart: null,
      dateEnd: null,
      onlyWithGrade: false,
      gradeFilters: [
        { value: false, text: 'Все заказы' }, { value: true, text: 'Только с оценкой' }
      ],

      isShowFullImage: false,

      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Дата', value: 'order_day', sortable: false },
        { text: 'Ученик', value: 'pupil', sortable: false },
        { text: 'Комментарий', value: 'comment', sortable: false },
        { text: 'Оценка', value: '', sortable: false },
      ],
      children: [],
      choice_children: null,
      order_status: {
        COMPLETE: 5
      }
    }
  },
  mounted() {
    this.fetchParentChildren()
    this.fetchChildrenOrders()
  },
  computed: {
    ...mapGetters([ 'children_orders', 'children_orders_pagination' ]),
    pagination_count() {
      return Math.ceil(this.children_orders.count / this.children_orders_pagination.page_size) || 0
    },
  },
  methods: {
    ...mapActions([ 'getQualityAssessmentOrders' ]),
    ...mapMutations([ 'setShowPreloader', 'set_children_orders_pagination' ]),
    fetchParentChildren() {
      getAllStudents()
        .then(res => { this.children = res.data.data })
        .finally(() => this.setShowPreloader(false))
    },
    fetchChildrenOrders() {
      this.getQualityAssessmentOrders({
        page: this.children_orders_pagination.page ? this.children_orders_pagination.page : null,
        status: this.order_status.COMPLETE,
        pupil_pk: this.choice_children ? this.choice_children.id : null,
        date_start: this.dateStart,
        date_end: this.dateEnd,
        only_with_grade: this.onlyWithGrade
      })
    },
    showModal(order) {
      if (this.is_grade_already_exist(order.order_grade)) {
        this.readonly = true
        this.orderGradeId = order.order_grade.id
      }
      this.orderId = order.id
      this.isShowModal = true

    },
    handleCloseModal() {
      this.readonly = false
      this.isShowModal = false
      this.orderGradeId = null
      this.orderId = null
    },
    handleSaveGrade() {
      this.handleCloseModal()
      this.fetchChildrenOrders()
    },
    handlePaginationClick(page) {
      this.set_children_orders_pagination({ page: page })
      this.fetchChildrenOrders()
    },
    is_grade_already_exist(grade) {
      return grade
    },
    onFiltersChange() {
      this.set_children_orders_pagination({ page: 1 })
      this.fetchChildrenOrders()
    }
  },
  watch: {
    choice_children() {
      this.onFiltersChange()
    },
    dateStart() {
      this.onFiltersChange()
    },
    dateEnd() {
      this.onFiltersChange()
    },
    onlyWithGrade() {
      this.onFiltersChange()
    }
  }
}
</script>

<style>
  .textarea-wrap .v-textarea.v-text-field--box .v-text-field__prefix, .v-textarea.v-text-field--enclosed .v-text-field__prefix, .v-textarea.v-text-field--box textarea, .v-textarea.v-text-field--enclosed textarea {
    margin-top: 7px !important;
  }

  .rating-wrap .error--text {
    text-align: center;
  }

  .rating-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  .order-grade-form .image-wrap {
    margin: 10px 0;
  }

  .order-grade-form .image-wrap .image {
    cursor: pointer;
    margin: 10px 0;
    width: 320px;
    height: 200px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .5);
  }
  .order-grade-form .image-wrap .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .image-wrap__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
