<template>
  <v-dialog v-model="show" :max-width="width" persistent>
    <v-container :class="className" md6>
      <v-layout class="fs-complex" row mb-3>
        <v-flex mb-3>
          <div style="display: flex; align-items: center; justify-content: space-between;">
            <h1 style="width: 80%;">{{ title }}</h1>
          </div>
        </v-flex>
      </v-layout>
      <v-layout class="fs-dish-result" mb-3>
        <v-flex>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="schools"
            class="elevation-1 fs-admin-table"
            item-key="id"
            select-all
            hide-actions
          >
            <template v-slot:items="props">
              <tr>
                <td>
                  <v-checkbox
                    v-model="props.selected"
                    class="fs-checkbox"
                    color="#66717f"
                    primary
                    hide-details
                  ></v-checkbox>
                </td>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.short_name }}</td>
                <td>{{ props.item.supplier ? props.item.supplier.short_name : 'Не назначен' }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-checkbox
          style="width: 100%; justify-content: flex-end;"
          class="fs-checkbox"
          color="#66717f"
          v-model="reset_balance"
          primary
          hide-details
          label="обнулить баланс учеников"
        ></v-checkbox>
      <v-flex
        text-md-right
        mt-3
        mr-2
      >
        <v-btn
          flat
          class="fs-btn fs-btn--secondary"
          @click="show = false"
        >Закрыть</v-btn>
        <v-btn
          class="fs-btn fs-btn--primary fs-form-footer__btn"
          flat
          @click="showConfirmModal = true"
        >Сохранить</v-btn>
      </v-flex>
    </v-container>
    <ConfirmModal
      :dialog.sync="showConfirmModal"
      @confirm="handlerConfirmModal"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
    >
      <template v-slot:headline>Внимание</template>
      <template v-slot:body>
        <p>Вы действительно хотите {{ reset_balance ? 'обнулить' : 'сохранить' }} балансы?</p>
      </template>
    </ConfirmModal>
  </v-dialog>
</template>

<script>
import { ConfirmModal } from '@/components/common'
export default {
  name: 'AssignDepartmentsModal',
  components: { ConfirmModal },
  props: {
    value: {
      required: true,
      type: Boolean
    },
    width: {
      default: 800
    },
    schools: {
      type: Array
    },
    className: {
      default: 'fs-container'
    }
  },
  data() {
    return {
      title: 'Назначить школы',
      reset_balance: true,
      showConfirmModal: false,
      selected: [],
      headers: [
        { text: '№ п/п', value: 'id' },
        { text: 'Школы', value: 'short_name' },
        { text: 'Орг. питания', value: 'supplier.short_name' }
      ],
      ids: []
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    handlerConfirmModal(value) {
      if (value) {
        this.save()
        this.show = false
        this.reset_balance = true
        this.showConfirmModal = false
      }
    },
    save() {
      this.$emit('assignSchools', { schools: this.getSchools(), reset_balance: this.reset_balance })
      this.show = false
    },
    getSchools() {
      const schools = this.schools
      for (let i = 0, l = schools.length; i < l; i++) {
        schools[i].selected = this.ids.includes(schools[i].id)
      }

      return schools
    }
  },
  watch: {
    schools: {
      immediate: true,
      deep: true,
      handler(v) {
        for (let i = 0, l = v.length; i < l; i++) {
          if (v[i].selected) {
            this.selected.push(v[i])
          }
        }
      }
    },
    selected: {
      deep: true,
      handler(v) {
        this.ids = []
        for (let i = 0, l = v.length; i < l; i++) {
          this.ids.push(v[i].id)
        }
      }
    }
  }
}
</script>
