export default class MealTypeFactory {
  constructor() {
    return {
      id: null,
      name: '',
      department: null,
      foodPeriods: [],
      isExtendedDayGroup: false,
      isHot: false,
      price: null,
      privilegeID: null,
      source: [],
      oldMealTypeId: null,
      replaceMealType: false,
      archiveMealType: false,
      date: null
    }
  }
}
