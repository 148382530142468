import MunicipalityStartPage from '@/components/municipality/StartPage/Index'
import MunicipalityFoodTypeIndex from '@/components/municipality/FoodType/Index'
import MunicipalityFoodTypeIndexArchive from '@/components/municipality/FoodType/IndexArchive'
import MunicipalityFoodTypeItem from '@/components/municipality/FoodType/Item'
import MunicipalityFoodTypesItemScheduler from '@/components/municipality/FoodType/ItemScheduler'
import MunicipalityOrganizersIndex from '@/components/municipality/Organizers/Index'
import MunicipalityOrganizerItem from '@/components/municipality/Organizers/Item'
import MunicipalityConcessionsIndex from '@/components/municipality/Directories/Concessions'
import MunicipalityConcessionsArchive from '@/components/municipality/Directories/ConcessionsArchive'
import MunicipalityFoodPeriodsIndex from '@/components/municipality/Directories/FoodPeriods'
import MunicipalityFoodTimesIndex from '@/components/municipality/Directories/FoodTimes'
import MunicipalityAgeCategoriesIndex from '@/components/municipality/Directories/AgeCategories'
import MunicipalityReportsFood from '@/components/municipality/Reports/FoodReport'
import MunicipalityReportsHotFood from '@/components/municipality/Reports/HotFoodReport'
import MunicipalityReportsStudentCategories from '@/components/municipality/Reports/StudentCategoriesReport'
import MunicipalityReportsFoodCount from '@/components/municipality/Reports/FoodCountReport'
import MunicipalityReportsStudents from '@/components/municipality/Reports/StudentReport'
import MunicipalityReportsFoodPay from '@/components/municipality/Reports/FoodPayReport'
import MainMunicipalitySummaryReport from '@/components/municipality/Reports/SummaryReport'
import MunicipalityReportsIndex from '@/components/municipality/Reports/Index'
import MunicipalityNotificationsCreate from '@/components/municipality/Notifications/Create'
import MunicipalityOrders from '@/components/municipality/Orders/Index'
import MunicipalityOrdersTable from '@/components/municipality/Orders/Item'
import MunicipalityStudentsIndex from '@/components/municipality/Students/Index'
import MunicipalityStudentsView from '@/components/municipality/Students/View'
import MunicipalityBalanceMoving from '@/components/municipality/Balance/Index'
import MunicipalityQualityControlWrap from '@/components/common/QualityControl/Index'
import NotificationIndex from '@/components/common/NotificationsComponent/Index.vue'
import MunicipalityFoodMealPassports from '@/components/municipality/FoodMealPassports/Index.vue'
import DepartmentSyrveyResult from '@/components/admin/Syrvey/SyrveyResult'
import DepartmentSyrveyDetail from '@/components/admin/Syrvey/AdminSyrveyDetail'
import UpdatesNewsDepartment from '@/components/municipality/UpdatesNews/Index'
import ConcessionsScheduler from '@/components/municipality/Directories/ConcessionsScheduler'
import UserHelp from '@/components/common/UserHelp/Index'
import HelpDesk from "@/components/common/HelpDesk/HelpDesk.vue"
import EducationalOrganizationsView
  from "@/components/municipality/EducationalOrganizations/EducationalOrganizationsView.vue";

const routes = [
  {
    path: '/',
    name: 'Municipality',
    component: MunicipalityStartPage
  },
  {
    path: 'updates',
    name: 'UpdatesNewsDepartment',
    component: UpdatesNewsDepartment
  },
  // {
  //   path: 'user-help',
  //   name: 'MunicipalityUserHelp',
  //   component: UserHelp
  // },
  {
    path: 'helpdesk',
    name: 'HelpDeskMU',
    component: HelpDesk,
    meta: { userRole: 'department' }
  },
  {
    path: 'food-types',
    name: 'MunicipalityFoodTypes',
    component: MunicipalityFoodTypeIndex
  },
  {
    path: 'food-types/archive',
    name: 'MunicipalityFoodTypesArchive',
    component: MunicipalityFoodTypeIndexArchive
  },
  {
    path: 'food-types/:id/view',
    name: 'MunicipalityFoodTypeView',
    component: MunicipalityFoodTypeItem,
    meta: 'view'
  },
  {
    path: 'food-types/:id/edit',
    name: 'MunicipalityFoodTypeEdit',
    component: MunicipalityFoodTypeItem,
    meta: 'edit'
  },
  {
    path: 'food-types/new',
    name: 'MunicipalityFoodTypeItemNew',
    component: MunicipalityFoodTypeItem
  },
  {
    path: 'food-types/scheduler',
    name: 'MunicipalityFoodTypesScheduler',
    component: MunicipalityFoodTypesItemScheduler
  },
  {
    path: 'organizers',
    name: 'MunicipalityOrganizersIndex',
    component: MunicipalityOrganizersIndex
  },
  {
    path: 'organizers/:id/view',
    name: 'MunicipalityOrganizerView',
    component: MunicipalityOrganizerItem,
    meta: 'view'
  },
  {
    path: 'organizers/:id/edit',
    name: 'MunicipalityOrganizerEdit',
    component: MunicipalityOrganizerItem,
    meta: 'edit'
  },
  {
    path: 'educational-organizations',
    name: 'EducationalOrganizations',
    component: EducationalOrganizationsView
  },
  {
    path: 'concessions',
    name: 'MunicipalityConcessions',
    component: MunicipalityConcessionsIndex
  },
  {
    path: 'concessions/archive',
    name: 'MunicipalityConcessionsArchive',
    component: MunicipalityConcessionsArchive
  },
  {
    path: 'concessions/scheduler',
    name: 'ConcessionsScheduler',
    component: ConcessionsScheduler,
    props: { user_role: 'is_department' }
  },
  {
    path: 'food-periods',
    name: 'MunicipalityFoodPeriods',
    component: MunicipalityFoodPeriodsIndex
  },
  {
    path: 'food-times',
    name: 'MunicipalityFoodTimes',
    component: MunicipalityFoodTimesIndex
  },
  {
    path: 'age-categories',
    name: 'MunicipalityAgeCategories',
    component: MunicipalityAgeCategoriesIndex
  },
  {
    path: 'reports/food',
    name: 'MunicipalityReportsFood',
    component: MunicipalityReportsFood
  },
  {
    path: 'reports/hot-food',
    name: 'MunicipalityReportsHotFood',
    component: MunicipalityReportsHotFood
  },
  {
    path: 'reports/student-categories',
    name: 'MunicipalityReportsStudentCategories',
    component: MunicipalityReportsStudentCategories
  },
  {
    path: 'reports/food-count',
    name: 'MunicipalityReportsFoodCount',
    component: MunicipalityReportsFoodCount
  },
  {
    path: 'reports/students',
    name: 'MunicipalityReportsStudents',
    component: MunicipalityReportsStudents
  },
  {
    path: 'reports/food-pay',
    name: 'MunicipalityReportsFoodPay',
    component: MunicipalityReportsFoodPay
  },
  {
    path: 'reports/summary-report',
    name: 'MainMunicipalitySummaryReport',
    component: MainMunicipalitySummaryReport
  },
  {
    path: 'reports',
    name: 'MunicipalityReportsIndex',
    component: MunicipalityReportsIndex
  },
  {
    path: 'notifications',
    name: 'MunicipalityNotifications',
    component: NotificationIndex,
    meta: {
      layoutClass: 'fs-teacher-container'
    }
  },
  {
    path: 'notifications/create',
    name: 'NotificationsCreateDepartment',
    component: MunicipalityNotificationsCreate
  },
  {
    path: 'orders',
    name: 'MunicipalityOrders',
    component: MunicipalityOrders,
    meta: { isMunicipality: true }
  },
  {
    path: 'orders/:id/view',
    name: 'MunicipalityOrdersTableView',
    component: MunicipalityOrdersTable,
    meta: { isMunicipality: true }
  },
  {
    path: 'students',
    name: 'MunicipalityStudents',
    component: MunicipalityStudentsIndex
  },
  {
    path: 'students/:id/view',
    name: 'MunicipalityStudentsView',
    component: MunicipalityStudentsView
  },
  {
    path: 'balance/moving',
    name: 'MunicipalityBalanceMoving',
    component: MunicipalityBalanceMoving
  },
  {
    path: 'quality-control',
    name: 'MunicipalityQualityControlWrap',
    component: MunicipalityQualityControlWrap,
    props: true
  },
  {
    path: 'foodmeal-passports',
    name: 'MunicipalityFoodMealPassports',
    component: MunicipalityFoodMealPassports,
    props: true
  },
  {
    path: 'syrvey-result',
    name: 'DepartmentSyrveyResult',
    component: DepartmentSyrveyResult,
    props: { user_role: 'is_department' }
  },
  {
    path: 'syrvey-detail',
    name: 'DepartmentSyrveyDetail',
    component: DepartmentSyrveyDetail,
    props: { user_role: 'is_department' }
  },
]

export default routes
