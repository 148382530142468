<template>
  <v-layout justify-space-around>
    <v-flex>
      <h2>{{title}}</h2>
      <v-layout
        class="fs-complex-result"
        mb-3
      >
        <v-flex>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
            :disable-initial-sort="true"
            no-data-text="Платежи отсутствуют"
            class="elevation-1"
            item-key="name"
          >
            <template v-slot:items="props">
              <tr>
                <td>{{ props.item.date }}</td>
                <td>{{ props.item.supplier }}</td>
                <td>
                  <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click="download(props.item.content)"
                          class="fs-active-icon"
                        >download</v-icon>
                      </template>
                      <span>Скачать</span>
                    </v-tooltip>
                </td>
                <td>{{ props.item.author }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from 'moment'
import Vue from 'vue'
import { mapMutations } from 'vuex'
import store from '@/store'
import scrollable from '@/mixins/scrollable'
import {
  getPayments
} from '@/router/routes/superadmin'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'SuperadminPaymentsHistory',
  mixins: [ scrollable ],
  data() {
    return {
      headers: [
        { text: 'Дата', value: 'date' },
        { text: 'ОП', value: 'supplier' },
        { text: 'Файл', value: 'content' },
        { text: 'Автор', value: 'author' }
      ],
      title: 'Архив загруженных платежей',
      items: [],
      LIMIT: Vue.config.general.limit,
    }
  },
  created() {
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    download(content) {
      const element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content))
      element.setAttribute('download', 'payments.csv')

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    },
    async loadMore() {
      const payload = {
        params: {
          limit: this.LIMIT,
          offset: this.offset,
        }
      }
      try {
        const response = await getPayments(payload)
        const {
          data: { data: responseDataArray }
        } = response

        const itemsArray = responseDataArray.map(item => {
          const {
            created_at = null,
            supplier = null,
            content = '',
            author = null,
          } = item
          const itemDate = moment(created_at).format(Vue.config.format.dateString.time)
          return {
            date: itemDate, supplier, content, author,
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
