export default {
  computed: {
    childrenCheckedTitle() {
      const COUNT = this.selected.length
      const COUNT_ARRAY = COUNT.toString().split('')
      const LAST_DIGIT_SYMB = COUNT_ARRAY.pop()

      let PREV_DIGIT_NUM = null
      let title = 'детей'

      const checkPrevNum = num => {
        if (num === 1) {
          title = 'детей'
        }
      }

      if (COUNT > 10) {
        // eslint-disable-next-line no-unused-vars
        const PREV_DIGIT_SYMB = COUNT_ARRAY.splice(-1, 1).pop()
        PREV_DIGIT_NUM = +PREV_DIGIT_SYMB
      }
      const LAST_DIGIT_NUM = +LAST_DIGIT_SYMB

      if (LAST_DIGIT_NUM === 1) {
        title = 'ребёнок'
        checkPrevNum(PREV_DIGIT_NUM)
      }
      if (
        LAST_DIGIT_NUM === 2 ||
        LAST_DIGIT_NUM === 3 ||
        LAST_DIGIT_NUM === 4
      ) {
        title = 'ребёнка'
        checkPrevNum(PREV_DIGIT_NUM)
      }
      return `${COUNT} ${title}`
    }
  }
}
