<template>
  <v-container fluid class="fs-parent-container">
    <v-layout class="fs-archive" row mb-3>
      <v-flex mb-3>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout justify-space-around mb-3>
      <v-flex md8>
        <v-card>
          <v-card-text>
            <v-layout mb-3 mt-3>
              <v-flex>
                <span class="fs-student-info__key">Льгота:</span>
              </v-flex>
              <v-flex text-md-right>
                <span class="fs-student-info__key">Многодетные</span>
              </v-flex>
            </v-layout>
            <v-layout mb-3>
              <v-flex>
                <span class="fs-student-info__key">Размер социальной выплаты:</span>
              </v-flex>
              <v-flex text-md-right>
                <span class="fs-student-info__key">40.00 руб./день</span>
              </v-flex>
            </v-layout>
            <v-layout mb-3>
              <v-flex>
                <span class="fs-student-info__key">Срок действия:</span>
              </v-flex>
              <v-flex text-md-right>
                <span class="fs-student-info__key">С 13.01.2019 по 21.01.2020</span>
              </v-flex>
            </v-layout>

            <v-layout justify-space-around>
              <v-flex md6>
                <v-data-table :headers="feedTimeHeaders" :items="feedTimeItems" hide-actions>
                  <template v-slot:headers="props">
                    <tr v-for="header in props.headers" :key="header.text">
                      <th colspan="2">
                        <strong>{{ header.text }}</strong>
                      </th>
                    </tr>
                  </template>
                  <template v-slot:items="props">
                    <tr>
                      <td>{{props.item.feedType}}</td>
                      <td>{{props.item.feedTime}}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout justify-space-around>
      <v-flex md8 text-md-right>
        <v-btn
          flat
          class="fs-btn fs-parent-btn fs-btn--secondary fs-form-footer__btn"
          @click="$router.go(-1)"
        >Назад</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "concessionsIndex",
  data() {
    return {
      title: "Время питания и категория социальной выплаты",
      feedTimeHeaders: [ { text: "Время питания (первая смена)" } ],
      feedTimeItems: [
        {
          id: 1,
          feedType: "Завтрак",
          feedTime: "После первого урока"
        },
        {
          id: 2,
          feedType: "Обед",
          feedTime: "После пятого урока"
        },
        {
          id: 3,
          feedType: "Полдник",
          feedTime: "После седьмого урока"
        }
      ]
    }
  }
}
</script>
