<template>
  <v-container fluid class="fs-organizer-container">
    <v-layout class="fs-dish" row>
      <v-flex md6 mb-5>
        <h1>{{ showArchive ? 'Архив' : 'Список' }} организаторов питания</h1>
      </v-flex>
      <v-flex md6 text-md-right>
        <with-tooltip text="Создать нового организатора питания">
          <v-btn
            :to="{ name: 'ModuleAdminOPListCreate' }"
            flat
            class="fs-btn fs-btn--primary fs-btn__add-dish"
            >Создать организатора питания</v-btn
          >
        </with-tooltip>
      </v-flex>
    </v-layout>
    <v-layout>
      <div mb-2 class="d-flex align-center">
        <v-btn class="mx-0" color="primary" @click="changeShowArchive"
          >{{ showArchive ? 'Скрыть' : 'Показать' }} архив</v-btn
        >
        <v-text-field
          class="ml-5 mb-10"
          label="Поиск ОП"
          append-icon="search"
          clear-icon="clear"
          clearable
          v-model="searchSupplierField"
          @keyup.enter="searchSupplier"
          @click:append="searchSupplier"
          @click:clear="clearSearchField"
        ></v-text-field>
      </div>
    </v-layout>
    <v-layout class="fs-dish-result" mb-3>
      <v-flex>
        <Table
          ref="suppliers"
          :headers="headers"
          :request="getSuppliers"
          :params="params"
          requestAfterMount
          showNumbering
        >
          <template v-slot:items="props">
            <td class="px-2">
              {{ props.item.name || '-' }}
            </td>
            <td class="px-2">
              {{ props.item.username }}
            </td>
            <td class="px-2">
              {{ props.item.department || '-' }}
            </td>
            <td>
              <v-icon
                color="green"
                v-if="props.item.sber_identify_exists"
              >
                verified
              </v-icon>
              <v-icon
                color="grey"
                v-else
              >
                block
              </v-icon>
            </td>
            <td>
              <v-icon
                color="green"
                v-if="props.item.sber_merchant_exists"
              >
                verified
              </v-icon>
              <v-icon
                color="grey"
                v-else
              >
                block
              </v-icon>
            </td>
            <td
              class="justify-center align-center layout px-2"
              style="gap: 5px"
            >
              <v-icon
                class="fs-active-icon"
                @click="$router.push({name: 'ModuleAdminOPListView', params: {id: props.item.id, meta: 'edit'}})"
              >edit</v-icon>
              <v-icon
                class="fs-active-icon"
                @click="$router.push({name: 'ModuleAdminOPBankEdit', params: {id: props.item.id, meta: 'banking'}})"
              >payment</v-icon>
              <v-icon
                class="fs-active-icon px-1"
                @click="
                  $router.push({
                    name: 'ModuleAdminOPCard',
                    params: { id: props.item.id }
                  })
                "
                >account_circle</v-icon
              >
              <v-icon
                v-if="!props.item.locked"
                class="fs-active-icon px-1"
                @click="
                  $refs.confirmLock.open()
                  currentOPId = props.item.id
                "
                >lock</v-icon
              >
              <v-icon
                v-else
                class="fs-active-icon px-1"
                @click="organizerEnable(props.item.id)"
                >lock_open</v-icon
              >
            </td>
          </template>
        </Table>
        <Modal ref="confirmLock" title="Подтверждение">
          <p>Вы действительно хотите заблокировать организатора питания?</p>
          <p class="lime--text text--darken-4">
            После подтверждения организатор будет заблокирован и перемещен в
            архив.
          </p>
          <template v-slot:actions>
            <v-btn flat color="primary" @click="handlerModal"
              >Подтвердить</v-btn
            >
          </template>
        </Modal>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import store from '@/store'
import { mapMutations } from 'vuex'
import {
  getSuppliers,
  disableOrganizer,
  enableOrganizer
} from '@/router/routes/superadmin'
import Vue from 'vue'

import Table from '@/ui-components/Table'
import Modal from '@/ui-components/Modal'
import WithTooltip from '@/components/common/Tooltip/Tooltip'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ModuleAdminOPList',
  components: {
    WithTooltip,
    Modal,
    Table
  },
  data() {
    return {
      modalResponse: false,
      currentOPId: null,
      headers: [
        { text: 'Наименование', value: 'name' },
        { text: 'Имя пользователя', value: 'username' },
        { text: 'Муниципалитет', value: 'department' },
        { text: 'Сбер реквизиты', value: 'operations' },
        { text: 'Сбер ID мерчанта', value: 'operations' },
        { text: 'Операции', value: 'operations' }
      ],
      getSuppliers,
      showArchive: false,
      params: {
        archive: false,
        search: null
      },
      searchSupplierField: ''
    }
  },
  methods: {
    ...mapMutations(['setShowPreloader']),
    changeShowArchive() {
      this.showArchive = this.params.archive = !this.showArchive

      this.$refs.suppliers.resetPagination()
      this.$refs.suppliers.requestData()
    },
    searchSupplier() {
      this.params.search = this.searchSupplierField
      this.$refs.suppliers.requestData()
    },
    clearSearchField() {
      this.params.search = null
      this.$refs.suppliers.requestData()
    },
    organizerDisable(id) {
      return disableOrganizer({ supplierId: id })
        .then((response) => {
          if (response.status == 204) {
            notify.success('Организатор заблокирован успешно')

            this.$refs.suppliers.requestData()
          }
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    organizerEnable(id) {
      return enableOrganizer({ supplierId: id })
        .then((response) => {
          if (response.status == 204) {
            notify.success('Организатор разблокирован успешно')

            this.$refs.suppliers.requestData()
          }
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    handlerModal(value) {
      this.$refs.confirmLock.close()

      this.modalResponse = value
      if (this.modalResponse && this.currentOPId) {
        this.organizerDisable(this.currentOPId)
        this.currentOPId = null
      }
    }
  }
}
</script>
