var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fs-parent-container",attrs:{"fluid":""}},[_c('div',{staticClass:"page-head__common"},[_c('h1',[_vm._v("Запрещенный список продуктов ученика")])]),_c('v-select',{staticStyle:{"margin-bottom":"15px"},attrs:{"items":_vm.blacklist_school_user,"item-text":"fullname","item-value":"id","clearable":"","return-object":"","label":!_vm.darkTheme ? 'Ученики' : '',"placeholder":_vm.darkTheme ? 'Ученики' : '',"outline":_vm.darkTheme,"hide-details":_vm.darkTheme},model:{value:(_vm.pupil_school_pk),callback:function ($$v) {_vm.pupil_school_pk=$$v},expression:"pupil_school_pk"}}),(_vm.blacklist_pupil_school_pk && _vm.pupil_blacklist.length > 0)?_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-list',{attrs:{"subheader":"","two-line":""}},[_c('v-subheader',[_vm._v("Список запрещённых продуктов")]),_c('v-list-tile',[_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v("№"+_vm._s(_vm.pupil_blacklist[0].id))]),_c('v-list-tile-sub-title',[_vm._v("Дата создания: "+_vm._s(_vm.transformDate(_vm.pupil_blacklist[0].created_at))+" ")])],1)],1),_c('v-btn',{staticClass:"fs-btn fs-btn--primary fs-btn__add-dish",attrs:{"flat":""},on:{"click":() =>
            _vm.$router.push({
              name: 'BlackListDetail',
              params: {
                create: false,
                pupil_pk: _vm.blacklist_pupil_school_pk.id,
                blacklist_id: _vm.pupil_blacklist[0].id,
                school: _vm.blacklist_pupil_school_pk.school
              }
            })}},[_vm._v("Изменить список запрещённых продуктов")])],1),_vm._v(" "+_vm._s(_vm.blacklistId)+" ")],1):(_vm.blacklist_pupil_school_pk && _vm.pupil_blacklist.length == 0)?_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-list',{attrs:{"subheader":"","two-line":""}},[_c('v-subheader',[_vm._v("У ученика нет черного списка продуктов")]),_c('v-btn',{staticClass:"fs-btn fs-btn--primary fs-btn__add-dish ml-2",attrs:{"flat":""},on:{"click":() =>
            _vm.$router.push({
              name: 'BlackListDetail',
              params: {
                create: true,
                pupil_pk: _vm.blacklist_pupil_school_pk.id,
                school: _vm.blacklist_pupil_school_pk.school
              }
            })}},[_vm._v("Создать список запрещённых продуктов для ученика")])],1)],1):_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-list',{attrs:{"subheader":"","two-line":""}},[_c('v-subheader',[_vm._v("Ученик не выбран")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }