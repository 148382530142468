<template>
  <v-container fluid class="fs-parent-container">
    <v-layout class="fs-archive" row mb-3>
      <v-flex mb-3>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout row justify-space-around>
      <v-flex md6>
        <v-card>
          <v-card-text>
            <v-layout>
              <v-flex  v-html="oferta.text">
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-actions>
            <v-layout>
              <v-flex text-md-right mb-3>
                <v-btn
                  flat
                  class="fs-btn fs-parent-btn fs-btn--primary"
                  @click="$router.go(-1)"
                >Назад</v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import { getOffer } from '@/router/routes/parent'

export default {
  name: "OfertaIndex",
  created(){
    getOffer(this.currentChild.id)
      .then(response => { this.oferta = response.data.data })
      .finally(() => store.commit('setShowPreloader', false))
  },
  data() {
    return {
      title: "Договор оферты",
      oferta: {
        text: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentChild',
    ])
  },
  methods: {
    ...mapMutations([
      'setShowPreloader',
    ])
  }
}
</script>
