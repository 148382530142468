<template>
  <div class="white align-content-space-between px-2">
    <div class="d-inline-block">
      <span>Выполнен вход под суперадминистратором</span>
    </div>
    <div @click="superAdminLogout" class="d-inline-block" style="float: right; cursor: pointer;">
      <v-icon style="vertical-align: middle !important" color="black">logout</v-icon>
      Вернуться
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuperAdminReturn',
  methods: {
    superAdminLogout() {
      const superAdmin_token = sessionStorage.getItem('superadmin_token')
      const superAdmin_id = sessionStorage.getItem('superadmin_user_id')
      sessionStorage.setItem('token', superAdmin_token)
      sessionStorage.setItem('user_id', superAdmin_id)
      window.location.href = '/moduleadmin'
    }
  }
}
</script>
