<template>
  <v-card>
    <v-card-title>
      <h1>{{title}}</h1>
    </v-card-title>
    <v-card-text>
      <v-layout>
        <v-flex  ref="ofertaText" v-html="oferta.text">
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-actions>
      <v-layout>
      <v-flex class="pa-2">
        <v-tooltip top>
          <template v-slot:activator="{on}">
            <v-icon
                v-on="on"
                class="ma-0"
                @click="PrintOferta"
            >print</v-icon>
          </template>
          <span>Печать</span>
        </v-tooltip>
      </v-flex>
        <v-flex text-md-right mb-3>
          <slot name="actions">
          </slot>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import store from '@/store'
import {mapGetters, mapMutations} from 'vuex'
import { getOfert } from '@/router/routes/admin'
import { getSchoolId } from '@/helpers/utils'

export default {
  name: "SchoolOferta",
  created(){
    getOfert(getSchoolId(this.currentUser))
      .then(response => { this.oferta = response.data.data })
      .finally(() => store.commit('setShowPreloader', false))
  },
  data() {
    return {
      title: "Оферта",
      oferta: {
        text: null,
      },
    }
  },
  computed: {
    ...mapGetters([ "currentUser" ])
  },
  methods: {
    ...mapMutations([
      'setShowPreloader',
    ]),
    PrintOferta () {
      var printWindow = window.open('', '_blank', 'height=400,width=800');
            printWindow.document.write('<html><body >');
            printWindow.document.write(this.$refs.ofertaText.innerHTML);
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
    }
  }

}
</script>