<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <v-layout
      class="fs-teacher-order"
      row
      mb-3
    >
      <v-flex
        md4
        mb-3
      >
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      class="fs-header-filter"
    >

    </v-layout>
    <v-layout
      class="fs-teacher-order-result"
      mb-3
      justify-space-around
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1 fs-teacher-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.name  }} {{props.item.formId? `(Форма ${props.item.formId})` : null}}</td>
              <td>
                <v-flex class="pa-2">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="showReportModal(props.item)"
                      >print</v-icon>
                    </template>
                    <span>Печать</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <ConfirmModal
      :dialog.sync="showModal"
      :startPrint.sync="showPrintLoad"
      :dateStart.sync="dateStart"
      :dateEnd.sync="dateEnd"
      :departments.sync="departments"
      :schools.sync="schools"
      :organizers.sync="organizers"
      :search.sync="search"
      :width="800"
      @confirm="handlerModal"
    >
      <template v-slot:headline>Печать отчёта</template>
      <template v-slot:body>
        Название отчёта:
        <h3>{{reportPrintTitle}}</h3>
        <v-flex
          md4
          class="mt-3"
        >
          <div v-if="datePickers === 1">
            <DatePickersSmart
              :startDate.sync="dateStart"
              :onePicker="true"
              @changeDate="changeDate"
            />
          </div>
          <div v-else-if="datePickers === 2">
            <DatePickersSmart
              :startDate.sync="dateStart"
              :endDate.sync="dateEnd"
              @changeDate="changeDate"
            />
          </div>
          <div v-if="report.school">
	          <v-select
		          no-data-text="Ничего не выбрано"
		          v-model="selectedDepartments"
		          item-text="name"
		          item-value="id"
		          :items.sync="departments"
		          deletable-chips
		          chips
		          multiple
		          label="Выберите департаменты"
		          @change="loadSchools"
	          >
		          <template v-slot:prepend-item>
			          <v-list-tile
				          ripple
				          @click="selectAll('selectedDepartments', 'departments', 'loadSchools')"
			          >
				          <v-list-tile-action>
					          <v-icon :color="selectedDepartments.length > 0 ? 'primary' : ''">{{ selectedDepartments.length ===  departments.length ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
				          </v-list-tile-action>
				          <v-list-tile-content>
					          <v-list-tile-title>Выбрать всё</v-list-tile-title>
					          <v-list-tile-sub-title>Выбрано: {{ selectedDepartments.length }}</v-list-tile-sub-title>
				          </v-list-tile-content>
			          </v-list-tile>
			          <v-divider class="mt-2"></v-divider>
		          </template>
	          </v-select>
            <v-select
                    no-data-text="Ничего не выбрано"
                    v-model="selectedSchools"
                    item-text="name"
                    item-value="id"
                    :items.sync="schools"
                    deletable-chips
                    chips
                    multiple

                    label="Выберите школы"
                    @change="search.schools = null"
            >
                <template v-slot:prepend-item>
                    <v-list-tile
                            ripple
                            @click="selectAll('selectedSchools', 'schools')"
                    >
                        <v-list-tile-action>
                            <v-icon :color="selectedSchools.length > 0 ? 'primary' : ''">{{ selectedSchools.length ===  schools.length ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                            <v-list-tile-title>Выбрать всё</v-list-tile-title>
                            <v-list-tile-sub-title>Выбрано: {{ selectedSchools.length }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-divider class="mt-2"></v-divider>
                </template>
            </v-select>
          </div>
          <div v-if="report.organizer">
	          <v-select
		          no-data-text="Ничего не выбрано"
		          v-model="selectedDepartments"
		          item-text="name"
		          item-value="id"
		          :items.sync="departments"
		          deletable-chips
		          chips
		          multiple
		          label="Выберите департаменты"
		          @change="loadOrganizers"
	          >
		          <template v-slot:prepend-item>
			          <v-list-tile
				          ripple
				          @click="selectAll('selectedDepartments', 'departments', 'loadOrganizers')"
			          >
				          <v-list-tile-action>
					          <v-icon :color="selectedDepartments.length > 0 ? 'primary' : ''">{{ selectedDepartments.length ===  departments.length ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
				          </v-list-tile-action>
				          <v-list-tile-content>
					          <v-list-tile-title>Выбрать всё</v-list-tile-title>
					          <v-list-tile-sub-title>Выбрано: {{ selectedDepartments.length }}</v-list-tile-sub-title>
				          </v-list-tile-content>
			          </v-list-tile>
			          <v-divider class="mt-2"></v-divider>
		          </template>
	          </v-select>

	          <v-select
		          no-data-text="Ничего не выбрано"
		          v-model="selectedOrganizers"
		          item-text="name"
		          item-value="id"
		          :items.sync="organizers"
		          deletable-chips
		          chips
		          multiple
		          label="Выберите ОП"
	          >
		          <template v-slot:prepend-item>
			          <v-list-tile
				          ripple
				          @click="selectAll('selectedOrganizers', 'organizers')"
			          >
				          <v-list-tile-action>
					          <v-icon :color="selectedDepartments.length > 0 ? 'primary' : ''">{{ selectedOrganizers.length ===  organizers.length ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
				          </v-list-tile-action>
				          <v-list-tile-content>
					          <v-list-tile-title>Выбрать всё</v-list-tile-title>
					          <v-list-tile-sub-title>Выбрано: {{ selectedOrganizers.length }}</v-list-tile-sub-title>
				          </v-list-tile-content>
			          </v-list-tile>
			          <v-divider class="mt-2"></v-divider>
		          </template>
	          </v-select>
          </div>
          <div v-if="report.department">
            <v-select
              no-data-text="Ничего не выбрано"
              v-model="selectedDepartments"
              item-text="name"
              item-value="id"
              :items.sync="departments"
              deletable-chips
              chips
              multiple
              label="Выберите департаменты"
              @change="search.departments = null"
            >
              <template v-slot:prepend-item>
                <v-list-tile
                  ripple
                  @click="selectAll('selectedDepartments', 'departments')"
                >
                  <v-list-tile-action>
                    <v-icon :color="selectedDepartments.length > 0 ? 'primary' : ''">{{ selectedDepartments.length ===  departments.length ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>Выбрать всё</v-list-tile-title>
                    <v-list-tile-sub-title>Выбрано: {{ selectedDepartments.length }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </div>
        </v-flex>
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import moment from 'moment'
import {
  getAdminReportTaskId,
  reportFileStatus,
  reportPrintFile,
  getAllAdminReports,
  getDepartments,
	getOrganizersByDepartments,
  adminReportPrintFile,
  adminReportFileStatus,
  getAdminReportBalanceTaskId,
  adminReportBalancePrintFile,
  adminReportBalanceFileStatus, reportFileTaskId
} from '@/router/routes/department'
import { mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import { ConfirmModal, DatePickersSmart } from '@/components/common'
import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownloadWithProgressBar'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ReportsIndex',
  components: {
    ConfirmModal,
    DatePickersSmart
  },
  data() {
    return {
      isRyazan: Vue.config.env.isRyazan,
      title: 'Отчёты',
      datepickerMenu: false,
      dateStart: '',
      dateEnd: '',
      headers: [
        { text: 'Отчёт', value: 'name', width: '90%' },
        { text: 'Операции', value: 'operations' }
      ],
      showModal: false,
      showPrintLoad: false,
      reportPrintTitle: '',
      datePickers: null,
      datePickerRange: '',
      currentItem: null,
      report: {
        school: null,
        organizer: null,
        department: null
      },
      schools: [],
      departments: [],
      departmentsValues: null,
      organizers: [],
      selectedDepartments: [],
      selectedDepartment: null,
      selectedSchools: [],
      selectedOrganizers: [],
      search: {
        departments: null,
        organizers: null,
        schools: null
      },
      items: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async init() {
      await this.loadReports()
      await this.loadDepartments()
    },
    selectAll(selectArray, parentArray, action = null) {
      if (this[selectArray].length) {
        this[selectArray] = []
      } else {
        const idsArray = this[parentArray].map(item => item.id)
        this[selectArray] = [ ...idsArray ]
      }
	    if (action === 'loadSchools') this.loadSchools()
	    else if (action === 'loadOrganizers') this.loadOrganizers()
    },
    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
    },
    setDatepickersValue(count) {
      const defaultRange = 'month'
      if (count === 2) {
        this.dateStart = moment()
          .add(defaultRange, -1)
          .format(Vue.config.format.serverDate)
        this.dateEnd = moment()
          .add('days', 1)
          .format(Vue.config.format.serverDate)
      }
      if (count === 1) {
        this.dateStart = moment().format(Vue.config.format.serverDate)
        this.dateEnd = ''
      }
      if (!count) {
        this.dateStart = ''
        this.dateEnd = ''
      }
    },
    showReportModal(item) {
      this.report.school = null
      this.report.organizer = null
      this.report.department = null
      switch (item.id) {
      case 'school-transfer-money':
        this.report.school = true
        break
      case '15': // Отчёт в разрезе муниципалитета об общих показателях за выбранный период
        if (this.isRyazan) {
          this.report.school = true
        }
        break
      case 'supplier-transfer-money':
        this.report.organizer = true
        break
      case '22': // Отчёт о ведении системы
        this.report.department = true
        break
      case 'report-balance':
        this.report.school = true
        break
      case 'number-of-eaters':
        this.report.school = true
        break
      case 'of-rate':
        this.report.department = true
        break
      }
      this.currentItem = item
      this.showModal = true
      this.datePickers = item.datePickers
      this.setDatepickersValue(item.datePickers)
      this.reportPrintTitle =
        item.name + (item.form_id ? ` (Форма ${item.form_id})` : '')
    },
    async handlerModal(value) {
      this.modalResponse = value
      if (this.modalResponse) {
        this.showPrintLoad = true
        await this.printFile()
      }
    },
    async printFile() {
      let getTaskId, fileStatus, printFile, PAYLOAD, callback = null
      callback = this.resetPageFileLoaded

      switch (this.currentItem.id) {
      case 'school-transfer-money': {
        PAYLOAD = {
          id: this.currentItem.id,
          report_id: 'transfer-money',
          data: {
            start_date: this.dateStart,
            end_date: this.dateEnd,
            departments: this.selectedDepartments,
            schools: this.selectedSchools
          }
        }
        getTaskId = getAdminReportTaskId
        fileStatus = adminReportFileStatus
        printFile = adminReportPrintFile
        break
      }
      case 'of-rate': {
        PAYLOAD = {
          id: this.currentItem.id,
          report_id: 'of-rate',
          data: {
            start_date: this.dateStart,
            end_date: this.dateEnd,
            departments: this.selectedDepartments,
            schools: this.selectedSchools
          }
        }
        getTaskId = getAdminReportTaskId
        fileStatus = adminReportFileStatus
        printFile = adminReportPrintFile
        break
      }
      case 'supplier-transfer-money': {
        PAYLOAD = {
          id: this.currentItem.id,
          report_id: 'transfer-money',
          data: {
            start_date: this.dateStart,
            end_date: this.dateEnd,
            ids: this.selectedOrganizers,
            department_ids: this.selectedDepartments
          },
        }
        getTaskId = getAdminReportTaskId
        fileStatus = adminReportFileStatus
        printFile = adminReportPrintFile
        break
      }
      case '22': {
        getTaskId = reportFileTaskId
        fileStatus = reportFileStatus
        printFile = reportPrintFile
        const { id } = this.currentItem
        PAYLOAD = {
          id,
          data: {
            start_date: this.dateStart,
            end_date: this.dateEnd,
            departments: this.selectedDepartments
          },
          report_id: id,
        }
        break
      }
      case 'report-balance': {
        PAYLOAD = {
          id: this.currentItem.id,
          report_id: 'transfer-money',
          data: {
            date: this.dateStart,
            schools: this.selectedSchools,
            departments: this.selectedDepartments
          }
        }
        getTaskId = getAdminReportBalanceTaskId
        fileStatus = adminReportBalanceFileStatus
        printFile = adminReportBalancePrintFile
        break
      }
      case 'number-of-eaters':
        getTaskId = getAdminReportTaskId
        fileStatus = reportFileStatus
        printFile = reportPrintFile
        const { id } = this.currentItem
        PAYLOAD = {
          id,
          data: {
            start_date: this.dateStart,
            end_date: this.dateEnd,
            schools: this.selectedSchools,
            departments: this.selectedDepartments
          },
          report_id: id,
        }
        break
      default: {
        const { id = null, formId = null } = this.currentItem
        getTaskId = getAdminReportTaskId
        fileStatus = reportFileStatus
        printFile = reportPrintFile
        PAYLOAD = {
          id,
          data: {
            start_date: this.dateStart,
            end_date: this.dateEnd,
            schools: this.selectedSchools,
            departments: this.currentItem.id === '15' && this.isRyazan ? this.selectedDepartments : null
          },
          report_id: id,
          form_id: formId
        }
      }
      }
      await this.printReport(getTaskId, fileStatus, printFile, PAYLOAD, callback)
    },
    async printReport(getTaskId, reportFileStatus, reportPrintFile, PAYLOAD, callback) {
      try {
        await asyncReqestsForDownload(
          getTaskId,
          reportFileStatus,
          reportPrintFile,
          PAYLOAD,
          callback
        )
      } catch (error) {
        console.error(error)
        notify.error('Ошибка загрузки файла') //pass
      } finally {
        this.resetPageFileLoaded()
      }
    },
    changeDate(payload) {},
    async loadReports() {
      try {
        const response = await getAllAdminReports()
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const {
            id = null,
            name = '',
            formId = '',
            rangeType = '',
            rangeAmmount = null,
            datePickers = null,
            classChoice = null,
            schoolChoice = null
          } = item
          return {
            id,
            name,
            formId,
            datePickers,
            rangeType,
            rangeAmmount,
            classChoice,
            schoolChoice
          }
        })
        this.items = [ ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки отчётов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async loadDepartments() {
      try {
        const response = await getDepartments()
        const {
          data: items
        } = response
        this.departmentsValues = items
        const departmentsArray = items
          .map((item) => ({
            id: item.id,
            name: item.name
          }))
        this.departments = [ ...departmentsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки департаментов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
        this.department = true
      }
    },
    async loadSchools() {
      this.schools = this.departmentsValues
        .filter((item) => this.selectedDepartments.includes(item.id))
        .map((item) => item.schools)
        .reduce((result, items) => [ ...result, ...items ], [])
        .map((item) => ({
          id: item.id,
          name: item.name
        }))
    },
    async loadOrganizers() {
      if (this.selectedDepartments.length === 0) {
				this.selectedOrganizers = []
	      return
      }
	    let department_ids = ''
	    this.selectedDepartments.forEach(id => {
		    department_ids += `${id},`
	    })
      try {
        const PAYLOAD = {
	        params: {
            department_ids: department_ids
	        }
        }
        const {
          data: { data: items }
        } = await getOrganizersByDepartments(PAYLOAD)
        const itemsArray = items
          .map((item) => ({
            id: item.id,
            name: item.shortName
          }))
        this.organizers = [ ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки организаторов питания') // backend handler added (exception_message)
      } finally {
        this.$store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>
