import TeacherStartPage from '@/components/teacher/StartPage/Index'
import TeacherOrdersPrintIndex from '@/components/teacher/Orders/Print/Index'
import TeacherReportsIndex from '@/components/teacher/Reports/Index'
import TeacherStudentsIndex from '@/components/teacher/Students/Index'
import TeacherStudentsEdit from '@/components/common/PupilCard/Main.vue'
import TeacherOrdersTableIndex from '@/components/teacher/Orders/Index'
import TeacherOrdersItem from '@/components/teacher/Orders/Item'
import TeacherNotificationsCreate from '@/components/teacher/Notifications/Create'

import NotificationIndex from '@/components/common/NotificationsComponent/Index.vue'
import UpdatesNewsTeacher from '@/components/teacher/UpdatesNews/Index'
import UserHelp from "@/components/common/UserHelp/Index.vue"
import HelpDesk from "@/components/common/HelpDesk/HelpDesk.vue"

export default [
  {
    path: '/',
    name: 'Teacher',
    component: TeacherStartPage
  },
  {
    path: 'updates',
    name: 'UpdatesNewsTeacher',
    component: UpdatesNewsTeacher
  },
  {
    path: 'user-help',
    name: 'TeacherUserHelp',
    component: UserHelp
  },
  {
    path: 'helpdesk',
    name: 'HelpDeskTE',
    component: HelpDesk,
    meta: { userRole: 'teacher' }
  },
  {
    path: 'students',
    name: 'Students',
    component: TeacherStudentsIndex
  },
  {
    path: 'students/:id/view',
    name: 'StudentsView',
    component: TeacherStudentsEdit,
    props: {
      view: true,
      isTeacher: true
    }
  },
  {
    path: 'students/:id/edit',
    name: 'StudentsEdit',
    component: TeacherStudentsEdit,
    props: {
      isTeacher: true
    }
  },
  {
    path: 'reports',
    name: 'Reports',
    component: TeacherReportsIndex
  },
  {
    path: 'orders',
    name: 'TeacherOrders',
    component: TeacherOrdersTableIndex
  },
  {
    path: 'orders/:id/view',
    name: 'TeacherOrdersView',
    component: TeacherOrdersItem,
    meta: 'view'
  },
  {
    path: 'orders/:id/edit',
    name: 'TeacherOrdersEdit',
    component: TeacherOrdersItem,
    meta: 'edit'
  },
  {
    path: 'print',
    name: 'OrdersPrint',
    component: TeacherOrdersPrintIndex
  },
  {
    path: 'notifications',
    name: 'TeacherNotifications',
    component: NotificationIndex,
    meta: {
      layoutClass: 'fs-teacher-container'
    }
  },
  {
    path: 'notifications/create',
    name: 'NotificationsCreateTeacher',
    component: TeacherNotificationsCreate
  }
]
