<template>
  <v-container class="fs-parent-container" fluid>
    <v-layout row justify-space-around>
      <v-flex md12 pt-5 text-md-center>
        <h1>Что-то пошло не так. Пожалуйста, закройте это окно и начните процесс сначала.</h1>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Fail"
}
</script>
