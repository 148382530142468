import getters from './getters'
import mutators from './mutators'
import actions from './actions'

export default {
  state: {},
  getters: getters,
  mutators: mutators,
  actions: actions
}
