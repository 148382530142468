<template>
  <v-container fluid class="fs-parent-container">
    <div class="page-head__common">
      <h1>Буфетное меню</h1>
    </div>

    <v-tabs v-model="activeTab">
      <v-tab>Продукты в буфете</v-tab>
      <v-tab>Транзакции</v-tab>
      
      <v-tab-item>
        <BuffetProducts />
      </v-tab-item>

      <v-tab-item>
        <BuffetTransactions />
      </v-tab-item>
    </v-tabs>


  </v-container>
</template>

<script>
import BuffetProducts from '@/components/parent/BuffetMenu/BuffetProducts'
import BuffetTransactions from '@/components/parent/BuffetMenu/BuffetTransactions'
export default {
  name: 'BuffetMenu',
  components: { BuffetProducts, BuffetTransactions },
  data: () => ({
    activeTab: 0
  })
  
}
</script>

<style>

</style>