<template>
  <v-container
    fluid
    class="fs-admin-container"
  >
    <v-layout
      class="fs-complex"
      row
      mb-3
    >
      <v-flex
        md3
        mb-3
      >
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <!-- <v-layout row mb-3 justify-space-around text-md-center class="fs-header-filter">
      <v-flex md3>
        <fs-autocomplete
          outline
          height="30"
          label="Тип питания:"
          class="fs-admin-complex-autocomplete"
        ></fs-autocomplete>
      </v-flex>
      <v-flex md3>
        <fs-autocomplete outline height="30" label="ОП:" class="fs-admin-complex-autocomplete"></fs-autocomplete>
      </v-flex>
      <v-flex md3>
        <fs-autocomplete outline height="30" label="Корпус:" class="fs-admin-complex-autocomplete"></fs-autocomplete>
      </v-flex>
      <v-flex md3>
        <v-btn flat class="fs-btn fs-btn--secondary fs-admin-btn__cancel v-btn--active">очистить</v-btn>
      </v-flex>
    </v-layout> -->
    <v-layout
      class="fs-complex-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1 fs-admin-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.index + 1 }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.organizer }}</td>
              <td>{{ props.item.foodType }}</td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="$router.push({ name: 'AdminComplexesView', params: { id: props.item.id}})"
                      >remove_red_eye
                    </v-icon>
                  </template>
                  <span>Подробнее</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex
        text-md-right
        pt-3
      >
        <v-btn
          flat
          class="fs-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active"
          @click="$router.go(-1)"
        >Назад</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getComplexes } from '@/router/routes/admin'
import store from '@/store'
import Vue from 'vue'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import scrollable from '@/mixins/scrollable'

export default {
  name: 'complexIndex',
  mixins: [ scrollable ],
  created() {
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },
  destroyed() {
    this.removeScrollHandler()
  },
  data() {
    return {
      title: 'Комплексы',
      selected: [],
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10),
      headers: [
        { text: "№ п/п", value: "number" },
        { text: 'Комплекс', value: 'complex' },
        { text: 'Организатор питания', value: 'organizer' },
        { text: 'Тип питания', value: 'food-type' },
        { text: 'Операции', value: 'actions' }
      ],
      items: []
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    loadMore() {
      const payload = {
        schoolId: this.currentUser.school.id,
        params: {
          limit: Vue.config.general.limit,
          offset: this.offset
        }
      }
      getComplexes(payload)
        .then(response => {
          const items = _.map(response.data.data, item => {
            return {
              id: item.id,
              name: item.name,
              foodType: _.first(item.mealType).name,
              organizer: item.supplier
            }
          })
          this.items = this.items.concat(items)
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    getOrganizerName() {
      return _.get(this.currentUser, 'school.supplier.short_name', null)
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>