// @TODO fix this shit. get filetype and name optional
export default async responseBlob => {
  const today = new Date()
  const todayString = `${today.getDate()}_${today.getMonth() +
    1}_${today.getFullYear()}`
  const dataBlob = responseBlob.data
  // TODO: С бэка приходит text/csv - эт хня, поменяйте
  const typeBlob = responseBlob.data.type
  const blobHeaders = responseBlob.headers
  const fileName = decodeURIComponent(blobHeaders.filename) || `Документ ${todayString}`
  const file = new Blob([ dataBlob ], { type: typeBlob })
  const url = window.URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  link.click()
}
