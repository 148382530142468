<template>
  <div class="datePikerSmart">
    <div v-if="!onePicker">
      <v-flex>
        <v-menu
          ref="menuStartDate"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :prepend-inner-icon="darkTheme ? 'event' : ''"
              :prepend-icon="!darkTheme ? 'event' : ''"
              :label="!darkTheme ? startDateTitle : ''"
              :outline="!!darkTheme"

              :class="'textFieldClass'"
              :height="normalHeightCheck"
              :value="startDate"

              readonly
              tile
              v-on="on"
            >
            </v-text-field>
          </template>
          <v-date-picker
            :class="pickerClass"
            locale="ru"
            first-day-of-week="1"
            no-title
            :value="startDate"
            @input="changeDate('startDate', $event)"
            :allowed-dates="allowedDateStart"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex>
        <v-menu
          ref="menuEndDate"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :prepend-inner-icon="darkTheme ? 'event' : ''"
              :prepend-icon="!darkTheme ? 'event' : ''"
              :label="!darkTheme ? endDateTitle : ''"
              :outline="!!darkTheme"

              :class="textFieldClass"
              :height="normalHeightCheck"
              :value="endDate"
              readonly
              v-on="on"
              outfield
            ></v-text-field>
          </template>
          <v-date-picker
            :class="pickerClass"
            locale="ru"
            first-day-of-week="1"
            no-title
            :value="endDate"
            @input="changeDate('endDate', $event)"
            :allowed-dates="allowedDateEnd"
          ></v-date-picker>
        </v-menu>
      </v-flex>
    </div>
    <div v-else-if="onePicker">
      <v-flex>
        <v-menu
          ref="menuOnePicker"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :prepend-inner-icon="darkTheme ? 'event' : ''"
              :prepend-icon="!darkTheme ? 'event' : ''"
              :label="!darkTheme ? onePickerTitle : ''"
              :outline="!!darkTheme"

              :class="textFieldClass"
              :height="normalHeightCheck"
              :value="startDate"
              readonly
              outfield
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :class="pickerClass"
            locale="ru"
            no-title
            :value="startDate"
            @input="changeDate('startDate', $event)"
            :allowed-dates="allowedDateEndForOne"
          ></v-date-picker>
        </v-menu>
      </v-flex>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import store from "@/store"
export default {
  name: 'DatePickersSmart',
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
    }
  },
  props: {
    startDate: {
      type: String,
      default: ''
    },
    startDateTitle: {
      type: String,
      default: 'Дата c:'
    },
    endDate: {
      type: String,
      default: ''
    },
    endDateTitle: {
      type: String,
      default: 'Дата по:'
    },
    normalStyle: {
      type: Boolean,
      default: false
    },
    onePicker: {
      type: Boolean,
      default: false
    },
    onePickerTitle: {
      type: String,
      default: 'Дата:'
    },
    pickerClass: {
      type: String,
      default: 'fs-datePickerSmart'
    },
    textFieldClass: {
      type: String,
      default: ''
    },
    allowDatesPeriod: {
      type: Number,
      default: 0
    },
    allowLastPickDate: {
      type: String,
      default: ''
    }
  },
  computed: {
    normalHeightCheck() {
      if (this.darkTheme) return '100%'
      return this.normalStyle ? '100%' : 20
    }
  },
  methods: {
    capitalizeString(word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    },
    changeDate(name, value) {
      this.$emit(`update:${name}`, value)
      this.$emit('changeDate', { name, value })
    },
    allowedDateStart(val) {
      if (this.endDate !== null) {
        return val <= this.endDate
      } else return val
    },
    allowedDateEnd(val) {
      if (this.startDate !== null) {
        if (this.allowLastPickDate) {
          return val >= this.startDate && val <= this.allowLastPickDate
        }
        return val >= this.startDate
      } else return val
    },
    allowedDateEndForOne(val) {
      return val <= this.startDate
    }
  }
}
</script>
<style >
.v-input__prepend-outer {
    margin-top: 0px;
}
</style>
