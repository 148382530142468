<template>
  <v-container class="fs-organizer-container">
    <v-layout
      md8
      offset-md2
      mb-5
    >
      <v-flex>
        <v-flex mb-5>
          <h1>{{organizer.id ? `${organizer.short_name} (@${organizer.username})` : null}}</h1>
        </v-flex>
        <v-switch
            :disabled="merchantReadOnly"
            v-model="useMechantId"
            color="green"
            hide-details
            :label="useMechantId ? 'Использовать МерчантЛогин' : 'Использовать стандартный эквайринг'"
        />
        <v-switch
            :disabled="!merchantEnabled"
            v-model="useNewGateway"
            color="green"
            hide-details
            class="mb-3"
            :label="useNewGateway ? 'Использовать новый шлюз' : 'Использовать старый шлюз'"
        />
        <v-card class="mb-2" v-if="useMechantId">
          <v-card-title class="justify-space-between">
            <div>
              <h3 class="d-inline-block">Настройка по ID мерчанта</h3>
            </div>
            <v-btn small color="primary" @click="reqAuthCheck(0)">
              Проверить
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="d-flex row">
              <v-text-field
                  :value="organizer.sber_merchant_id"
                  label="ID мерчанта текущий"
                  class="mx-1"
                  disabled
              ></v-text-field>
              <v-text-field
                  v-model="merchantId"
                  label="ID мерчанта новый"
                  class="mx-1"
                  solo
              ></v-text-field>
            </div>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-title class="justify-space-between">
            <h3>Настройка по реквизитам</h3>
            <v-btn small color="primary" @click="reqAuthCheck(1)">
              Проверить
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div>
              <div class="d-flex row">
                <v-text-field
                    :value="organizer.sber_identify_token"
                    label="Логин (Токен) текущий"
                    class="mx-1"
                    disabled
                ></v-text-field>
                <v-text-field
                    v-model="token"
                    label="Логин (Токен) новый"
                    class="mx-1"
                    solo
                ></v-text-field>
              </div>
              <div class="d-flex row">
                <v-text-field
                    :value="organizer.sber_identify_key"
                    label="Пароль (Ключ) текущий"
                    class="mx-1"
                    disabled
                ></v-text-field>
                <v-text-field
                    v-model="secret"
                    label="Пароль (Ключ) новый"
                    class="mx-1"
                    solo
                ></v-text-field>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="d-flex row align-content-end">
      <v-btn
          flat
          class="fs-btn v-btn--active"
          :disabled="!(token && secret) && !merchantId"
          @click="update()"
      >сохранить</v-btn>
      <v-btn
          flat
          class="fs-btn v-btn--active"
          @click="$router.push({ name: 'ModuleAdminOPBank' })"
      >назад</v-btn>
    </div>
  </v-container>
</template>

<script>
import {
  checkSberAuth,
  getOrganizer,
  updateOrganizer,
} from '@/router/routes/superadmin'
import store from '@/store'
import Vue from 'vue'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ModuleAdminOPBankEdit',
  data() {
    return {
      organizer: {},
      token: null,
      secret: null,
      merchantId: null,
      useNewGateway: false,
      gatewayReadOnly: false,
      useMechantId: false,
      merchantEnabled: false,
    }
  },
  created() {
    let org = {}
    getOrganizer(this.$route.params.id)
      .then(response => {
        org = response.data.data
        this.organizer = org
        this.gatewayReadOnly = org.gateway.read_only
        this.useNewGateway = org.gateway.supplier_flag ? org.gateway.supplier_flag : org.gateway.read_only
        this.merchantEnabled = org.sber_merchant_enabled
        this.useMechantId = !!org.sber_merchant_id
      })
      .finally(() => store.commit('setShowPreloader', false))
  },
  methods: {
    async reqAuthCheck(type) {
      if (this.useMechantId && !this.merchantId) {
        notify.error('Укажите ID мерчанта для проверки связи')
        return
      }
      const payload = {
        'sber_merchant_id': this.merchantId ? this.merchantId : this.organizer.sber_merchant_id,
        'sber_identify_token': this.token ? this.token : this.organizer.sber_identify_token,
        'sber_identify_key': this.secret ? this.secret : this.organizer.sber_identify_key,
        'supplier_id': this.organizer.id,
        'use_merchant_id': this.useMechantId
      }
      try {
        const response = await checkSberAuth(payload)
        console.log(response)
        if (response.data.ok) {
          notify.success("Проверка прошла успешно")
        } else {
          notify.error(`${response.data.message}, код ошибки: ${response.data.code}`)
        }
      } catch (e) {
        notify.error(e)
      }
      store.commit('setShowPreloader', false)
    },
    update() {
      updateOrganizer(this.$route.params.id, {
        sberbank_token: this.token,
        sberbank_secret: this.secret,
        sber_merchant_id: this.merchantId,
        is_new_payment_gateway: this.useNewGateway
      })
        .then(response => {
          this.token = null
          this.secret = null
          this.organizer.sber_identify_token = response.data.sber_identify_token
          this.organizer.sber_identify_key = response.data.sber_identify_key
          this.organizer.sber_merchant_id = response.data.sber_merchant_id
          return notify.success('Настройки сохранены')
        })
        .finally(() => store.commit('setShowPreloader', false))
    }
  }
}
</script>
