<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <v-flex mb-3>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout
      row
      mb-3
      justify-space-between
      text-md-center
      class="fs-header-filter"
    >
      <v-flex
        md12
        text-md-right
      >
        <v-btn
          flat
          :disabled="!items.length"
          @click="printOrdersHandler('withChanges')"
          class="primary"
        >Фактическая заявка</v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      class="fs-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.date.formatted }}</td>
              <td>{{ props.item.school.name }}</td>
              <td>{{ props.item.class.name }}</td>
              <td>{{ getOrderStatus(props.item.status, props.item.is_auto_status) }}</td>
              <td>{{ props.item.teacher.name }}</td>
              <td>
                <v-flex row>
                  <v-btn
                    flat
                    class="fs-btn fs-btn--secondary"
                    @click="$router.push({name: 'OrganizerArchiveOrderDetails', params: {
                         schoolId: props.item.school.id,
                         classId: props.item.class.id,
                         date: props.item.date.default }})"
                  >подробнее</v-btn>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex
        text-md-right
        pt-3
      >
        <v-btn
          flat
          class="primary"
          @click="$router.push({name: 'OrganizerArchiveOrderSchoolView'})"
        >К списку заявок</v-btn>
      </v-flex>
    </v-layout>
    <ConfirmModal
      :dialog.sync="houseModalShow"
      :confirmBtnText="'Печатать'"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="handlerHouseModal"
    >
      <template v-slot:headline>Печать заявки по корпусу</template>
      <template v-slot:body>
        <v-flex md6>
          <v-select
            no-data-text="Ничего не выбрано"
            v-model="selectedHouseIDs"
            item-text="name"
            item-value="id"
            :items="houses"
            label="Корпуса:"
            deletable-chips
            chips
            multiple
          >
            <template v-slot:prepend-item>
              <v-list-tile
                ripple
                @click="selectAll('selectedHouseIDs', 'houses')"
              >
                <v-list-tile-action>
                  <v-icon :color="selectedHouseIDs.length > 0 ? 'primary' : ''">{{selectedHouseIDs.length ===  houses.length ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>Выбрать всё</v-list-tile-title>
                  <v-list-tile-sub-title>Выбрано: {{selectedHouseIDs.length}}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-flex>
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import _ from 'lodash'
import {getCurrentSchoolArchiveOrders,
  getOrderReport, getOrderReportDownload,
  getOrderReportStatus,
} from '@/router/routes/organizer'
import store from '@/store'
import { mapMutations } from 'vuex'
import { ConfirmModal } from '@/components/common'
import notify from '@/utils/notifications/toastNotification'
import asyncReqestsForDownload from "@/helpers/asyncReqestsForDownloadWithProgressBar";

export default {
  name: 'OrganizerArchiveOrderIndex',
  components: {
    ConfirmModal
  },
  created() {
    this.loadOrders().finally(() => store.commit('setShowPreloader', false))
  },
  data() {
    return {
      title: 'Табель архивных заявок ' + moment(this.$route.params.date).format(
        Vue.config.format.date
      ),
      headers: [
        { text: 'Дата', value: 'date' },
        { text: 'ОО', value: 'organization' },
        { text: 'Класс', value: 'class' },
        { text: 'Статус заявки', value: 'status' },
        { text: 'Классный руководитель', value: 'director' },
        { text: 'Операции', value: 'operations' }
      ],
      items: [],
      houses: [],
      selectedHouseIDs: [],
      houseModalShow: false,
      reportType: 'withChanges'
    }
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    loadOrders() {
      const payload = {
        schoolId: this.$route.params.schoolId,
        date: moment(this.$route.params.date).format(
          Vue.config.format.pythonDate
        )
      }
      return getCurrentSchoolArchiveOrders(payload).then(response => {
        this.houses = response.data.data.houses
        const items = _.map(response.data.data.orders, item => {
          return {
            date: {
              default: item.date,
              formatted: moment(item.date).format(Vue.config.format.date)
            },
            school: {
              id: _.get(item, 'school.id', null),
              name: _.get(item, 'school.short_name', '')
            },
            class: {
              id: _.get(item, 'school_class.id', null),
              name: _.get(item, 'school_class.name', '')
            },
            status: _.get(item, 'status', ''),
            is_auto_status: _.get(item, 'status_received_is_auto', null),
            teacher: {
              id: _.get(item, 'teacher.id', null),
              name: _.get(item, 'teacher.fullName', '')
            }
          }
        })
        this.items = _.filter(items, item => item.status != null)
      })
    },
    async print(reportType) {
      const payload = {
        order_day: moment(this.$route.params.date).format(
          Vue.config.format.serverDate
        ),
        reportType: reportType,
        schoolId: this.$route.params.schoolId
      }
      const callback = () => false
      try {
        await asyncReqestsForDownload(
          getOrderReport,
          getOrderReportStatus,
          getOrderReportDownload,
          payload,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки документа')
      } finally {
        callback()
        store.commit('setShowPreloader', false)
      }
    },

    printOrdersHandler(reportType) {
      if (this.houses.length) {
        this.reportType = reportType
        this.houseModalShow = true
      } else {
        this.print(reportType)
      }
    },
    getOrderStatus(value, auto) {
      if (value === 'Заказ выполнен') {
        if (auto)
          return 'Заказ выполнен (без списания)'
        return 'Заказ выполнен'
      } else {
        return value
      }
    },
    handlerHouseModal(value) {
      if (value) {
        if (this.selectedHouseIDs.length) {
          this.print(this.reportType)
        } else {
          notify.error('Ошибка печати. Не выбран корпус') //pass
        }
      }
    },
    selectAll(selectArray, parentArray) {
      this.$nextTick(() => {
        if (this[selectArray].length) {
          this[selectArray] = []
        } else {
          const idsArray = this[parentArray].map(item => item.id)
          this[selectArray] = [ ...idsArray ]
        }
      })
    }
  }
}
</script>
