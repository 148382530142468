<template>
  <div class="sc-user-input" :style="{ background: colors.messageList.bg }">
    <template v-if="isShowChatList">
      <div style="text-align: center; background: transparent; padding: 8px">
        <v-btn
          :color="colors.header.bg"
          outline
          dark
          round
          large
          @click.native.prevent="askQuestion"
        >
          Есть вопрос
        </v-btn>
      </div>
    </template>
    <template v-else>
      <Suggestions v-if="!!suggestions.length" :colors="colors" />
      <v-form
        ref="form"
        v-model="isValid"
        v-if="!isDisableInput"
        class="sc-user-input--form"
        :style="{ background: colors.userInput.bg }"
        @submit.prevent="submitText"
      >
        <v-textarea
          v-model="textField"
          :rules="rules.map((rule) => rule({ label: 'поле ввода', length }))"
          required
          auto-grow
          row-height="5"
          solo
          :maxlength="length"
          :counter="length"
          contentEditable="true"
          :placeholder="placeholder"
          class="sc-user-input--text"
          :color="colors.userInput.text"
          autofocus
          hide-details
        />
        <div class="sc-user-input--buttons">
          <v-btn class="ma-0" icon type="submit" :disabled="!isValid">
            <v-icon :color="colors.userInput.text">send</v-icon>
          </v-btn>
        </div>
      </v-form>
    </template>
  </div>
</template>

<script>
import Suggestions from './Suggestions.vue'
import { actions, getters } from '../store'

export default {
  components: { Suggestions },
  props: {
    placeholder: {
      type: String,
      default: 'Write something...'
    },
    colors: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      length: 5000,
      isValid: false,
      textField: '',
      rules: [
        (item) => (value) =>
          (!!value && value.trim()?.length > 0) ||
          `${item.label}Обязательное поле!`,
        (item) => (value) =>
          (value && value.length <= item.length) ||
          `Поле Ввода должно содержать не более ${item.item} символов`
      ]
    }
  },
  computed: {
    ...getters
  },

  mounted() {
    this.$root.$on('focusUserInput', () => {
      if (this.$refs.userInput) {
        this.focusUserInput()
      }
    })
  },
  methods: {
    ...actions,

    focusUserInput() {
      this.$nextTick(() => {
        this.$refs.userInput.focus()
      })
    },

    submitText() {
      this.sendMessage({
        author: 'me',
        type: 'text',
        data: { text: this.textField }
      })
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    }
  }
}
</script>

<style lang="scss">
.sc-user-input {
  background-color: #f4f7f9;
  border-radius: 0 0 10px 10px;
}
.sc-user-input--form {
  height: fit-content;
  min-height: 55px;
  margin: 0;
  display: flex;
  background-color: #f4f7f9;
  border-radius: 0 0 10px 10px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  padding: 18px;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  &:focus {
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
    background-color: white;
    box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.2);
  }
}

.sc-user-input--text {
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #565867;
  -webkit-font-smoothing: antialiased;
  max-height: 200px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 0 256px;
}

.sc-user-input--buttons {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 2px;
  align-self: center;
  flex: 0 1 auto;
}
</style>
