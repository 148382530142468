<template>
  <v-dialog
    v-model="dialog"
    persistent
    no-click-animation
    max-width="768px"
  >
    <v-card>
      <v-form ref="formPrivilege">
        <v-container grid-list-md>
          <v-layout>
            <v-flex>
              <span class="headline">{{ title }}</span>
            </v-flex>
            <v-flex class="text-xs-right">
              <v-btn class="ma-0" icon @click="closeDialog">
                <v-icon>close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="formData.name"
                :rules="rules.name"
                :disabled="act === 'delete'"
                label="Наименование"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="formData.price"
                :rules="rules.price"
                :disabled="act === 'delete'"
                suffix="руб."
                label="Цена"
                required
              ></v-text-field>
            </v-flex>
            <v-flex class="text-md-right" xs12>
              <v-btn class="ma-0" color="primary" @click="onClick">{{ button }}</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormPrivilegeModal',
  props: {
    value: {
      type: Boolean
    },
    action: {
      type: String,
      default: 'add',
      validator(v) {
        return [ 'add', 'edit', 'delete' ].indexOf(v) !== -1
      }
    },
    privilege: {
      type: Object,
      default: undefined,
      validator(v) {
        const keys = [ 'id', 'name', 'price' ]

        for (let i = 0; i < 3; i++) {
          if (keys[i] in v) {
            if ((i % 2 === 0 && typeof v[keys[i]] === 'number') ||
                (i % 2 !== 0 && typeof v[keys[i]] === 'string')) {
              continue
            }
          }

          return false
        }

        return true
      }
    }
  },
  data: () => ({
    title: 'Добавить',
    button: 'Создать',
    rules: {
      name: [
        v => !!v || 'Наименование обязательно для заполнения',
        v => (v && v.length <= 255) || 'Наименование должно содержать не более 255 символов'
      ],
      price: [
        v => v.length > 0 || 'Цена обязательна для заполнения',
        v => /^(\d+|\d+\,\d{1,2})$/.test(v) || 'Неверный формат ввода цены'
      ]
    },
    formData: {
      id: 0,
      name: '',
      price: ''
    }
  }),
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    act() {
      return this.action ? this.action : 'add'
    }
  },
  methods: {
    onClick() {
      if (this.$refs.formPrivilege.validate()) {
        const privilege = {
          id: this.formData.id,
          name: this.formData.name,
          price: this.formData.price.priceToNumber()
        }

        if (privilege.price === 0) {
          privilege.price = null
        }

        this.$emit('confirm', privilege)
      }
    },
    closeDialog() {
      this.dialog = false
      this.formData = {
        id: 0,
        name: '',
        price: ''
      }

      this.resetValidation()
    },
    resetValidation() {
      this.$refs.formPrivilege.resetValidation()
    }
  },
  watch: {
    action(v) {
      if (v === 'delete') {
        this.title = 'Переместить в архив'
        this.button = 'Подтвердить'
      }
      else {
        this.title = 'Добавить'
        this.button = 'Создать'
      }
    },
    privilege: {
      deep: true,
      immediate: true,
      handler(v) {
        if (v) {
          this.formData = {
            id: v.id,
            name: v.name,
            price: v.price.priceToString()
          }
        }
        else {
          this.formData = {
            id: 0,
            name: '',
            price: ''
          }
        }
      }
    }
  }
}
</script>
