<script>
import {getStudent, updateOrder} from "@/router/routes/superadmin";
import store from "@/store";
import converter from '@/mixins/balancable'
import numable from "@/mixins/balancable";

export default {
  name: 'SuperadminOrdersTableV2EditModal',
  props: {
    showModal: {
      type: Boolean
    },
    orderData: {
      type: Object
    }
  },
  async mounted() {
    await this.getStudentData()
    this.privilegeAmount = this.convertToRubles(this.orderData.privilege_amount)
    this.isAutoReceived = this.orderData.status_received_is_auto
    this.selectedStatus = this.orderData.status
    store.commit('setShowPreloader', false)
  },
  data() {
    return {
      availableMealTypes: [],
      availableStatuses: [
        { name: 'Черновик', id: 1 },
        { name: 'Подтвержден учителем', id: 2 },
        { name: 'Подтвержден школой', id: 3 },
        { name: 'Выдан', id: 4 },
        { name: 'Выполнен', id: 5 },
        { name: 'Отменен', id: 6 },
        { name: 'Сохранен учителем', id: 7 },
        { name: 'Сохранен школой', id: 8 },
        { name: 'Подтвержден автоматически', id: 9 },
        { name: 'Изменен', id: 10 },
      ],
      selectedMealType: null,
      selectedStatus: null,
      isAutoReceived: null,
      privilegeAmount: null,
      pupilData: null
    }
  },
  watch: {
    selectedMealType(val) {
      this.pupilData.validFoodTypes.forEach(item => {
        if (item.id === val) {
          this.privilegeAmount = this.convertToRubles(item.privilege.price)
        }
      })
    }
  },
  mixins: [ numable ],
  methods: {
    close() {
      this.$emit('closeModal')
    },
    async getStudentData() {
      try {
        const response = await getStudent(this.orderData.pupil)
        this.pupilData = response.data
        this.availableMealTypes = this.pupilData.validFoodTypes.map(item => {
          if (this.orderData.meal_type === item.id) {
            this.selectedMealType = item.id
          }
          return {
            id: item.id,
            name: item.name,
            price: this.convertToRubles(item.price),
            privilege_amount: this.convertToRubles(item.privilege.price),
            selected: this.orderData.meal_type === item.id
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async save() {
      const payload = {
        order: this.orderData.id,
        meal_type: this.selectedMealType,
        privilege_amount: this.convertToCents(this.privilegeAmount),
        status: this.selectedStatus,
        status_received_is_auto: this.isAutoReceived
      }
      try {
        const { data } = await updateOrder(payload)
        this.$emit('updateOrder', data)
        await this.getStudentData()
      } catch (e) {
        console.log(e)
      }
      store.commit('setShowPreloader', false)
    }
  }
}
</script>

<template>
  <v-dialog
    v-model="showModal"
    :persistent="true"
    :width="500"
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <h2>Заявка #{{ orderData.id }}</h2>
        <small class="grey--text text-right">от {{ orderData.order_day }}</small>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <b>Ученик</b>
          {{ orderData.pupil_full_name }}
        </div>
        <div class="d-flex justify-space-between">
          <b>Школа</b>
          {{ orderData.school_name }}
        </div>
        <div class="d-flex justify-space-between">
          <b>Класс</b>
          {{ orderData.school_class_name }}
        </div>
        <div class="d-flex justify-space-between">
          <b>Текущий баланс, руб</b>
          {{ pupilData ? convertToRubles(pupilData.balance) : '...' }}
        </div>
        <v-divider class="my-1" />
        <small>Тип питания</small>
        <v-autocomplete
          solo
          v-model="selectedMealType"
          :items="availableMealTypes"
          key="id"
          item-text="name"
          item-value="id"
          label="Тип питания"
        />
        <small>Размер льготы, руб</small>
        <v-text-field
          solo
          v-model="privilegeAmount"
        />
        <small>Статус заявки</small>
        <v-autocomplete
            solo
            v-model="selectedStatus"
            :items="availableStatuses"
            key="name"
            item-text="name"
            item-value="id"
            label="Статус"
        />
        <v-checkbox
          v-model="isAutoReceived"
          label="Без списания"
        />
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-btn color="error" @click="close">
          Закрыть
        </v-btn>
        <v-btn color="success" @click="save">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.text-right {
  text-align: right;
}
</style>
