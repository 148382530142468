<template>
  <div class="date-time__wrap">
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      :nudge-right="35"
      transition="scale-transition"
      offset-y
      :disabled="disabled || readonly"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          style="margin-right: 5px"
          v-model="date"
          readonly
          v-bind="attrs"
          v-on="on"
          hide-details
          :label="!darkTheme ? label || 'Дата' : ''"
          :placeholder="darkTheme ? label || 'Дата' : ''"
          :outline="darkTheme"
          :prepend-inner-icon="darkTheme ? 'event' : ''"
          :prepend-icon="!darkTheme ? 'event' : ''"
          :disabled="disabled"
        >
          <template v-slot:append-outer>
            <v-tooltip bottom v-if="clearable && dateOnly">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="reset" v-show="date">close</v-icon>
              </template>
              <span>Очистить поле "{{ label || 'Дата' }}"</span>
            </v-tooltip>
          </template>
        </v-text-field>
      </template>

      <v-date-picker
        v-model="date"
        @input="setDate"
        locale="ru-ru"
        :readonly="readonlyPicker"
        :disabled="disabled"
      ></v-date-picker>
    </v-menu>

    <v-menu
      v-if="!dateOnly"
      v-model="timeMenu"
      ref="timeMenu"
      :close-on-content-click="false"
      :nudge-right="35"
      transition="scale-transition"
      offset-y
      :disabled="disabled || readonly"
    >
      <template v-slot:activator="{ on, attrs }">
        <div>
          <v-text-field
            v-model="time"
            :disabled="dateIsEmpty || disabled"
            readonly
            v-bind="attrs"
            v-on="on"
            hide-details
            :label="!darkTheme ? 'Время' : ''"
            :placeholder="darkTheme ? 'Время' : ''"
            :outline="darkTheme"
            :prepend-inner-icon="darkTheme ? 'alarm' : ''"
            :prepend-icon="!darkTheme ? 'alarm' : ''"
          >
            <template v-slot:append-outer>
              <v-tooltip bottom v-if="clearable">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" @click="reset" v-show="date">close</v-icon>
                </template>
                <span>Очистить поле "{{ label || 'Дата' }}"</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </div>
      </template>

      <v-time-picker
        v-if="timeMenu"
        format="24hr"
        v-model="time"
        @click:minute="setTime"
        :readonly="readonlyPicker"
        :disabled="disabled"
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'

export default {
  name: 'TDateTimeField',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      required: false
    },
    dateOnly: {
      type: Boolean,
      default: false
    },
    format: {
      type: String
    },
    clearable: {
      type: Boolean,
      default: false
    },
    /*
     * При выборе даты автоматически выставлять время на 00:00, если время не было выбрано до этого.
     * */
    autoSetTime: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    readonlyPicker: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      date: '',
      time: '',
      dateMenu: false,
      timeMenu: false
    }
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        // если приходят пустые данные сбросить
        this.reset()
      }
    }
  },

  methods: {
    reset() {
      !!this.date ? (this.date = '') : ''
      !!this.time ? (this.time = '') : ''
      this.$emit('input', this.datetime)
    },

    clear() {
      this.date = this.time = ''
    },

    transformServerDate(dateFromApi) {
      // 2021-02-19T05:56:45.517707Z
      const date = new Date(dateFromApi).toLocaleString().split(',')
      const [day, month, year] = date[0].split('.')
      const [hours, min] = date[1].split(':')
      return {
        date: moment(new Date(year, month - 1, day)).format('YYYY-MM-DD'),
        time: `${hours}:${min}`
      }
    },

    initDatePicker() {
      const { date, time } = this.transformServerDate(this.value)
      this.date = date
      this.time = time
    },

    setTime() {
      this.$emit('input', this.datetime)
      this.$refs.timeMenu.save(this.time)
    },

    setDate() {
      if (this.timeIsEmpty && this.autoSetTime) this.time = '00:00'
      !this.timeIsEmpty ? this.$emit('input', this.datetime) : ''
      this.dateMenu = false
    }
  },
  mounted() {
    this.value !== '' && this.value !== undefined && this.value !== null
      ? this.initDatePicker()
      : ''
  },
  computed: {
    dateIsEmpty() {
      return this.date.length === 0
    },
    timeIsEmpty() {
      return this.time.length === 0
    },
    datetime() {
      if (this.dateOnly) return this.date
      return !!this.date && !!this.time
        ? new Date(this.date + 'T' + this.time.trim()).toISOString()
        : null
    }
  }
}
</script>

<style scoped>
.date-time__wrap {
  display: flex;
}
</style>
