<template>
  <v-layout v-if="layout">
    <v-flex>
      <h1 class="mb-5">
        <slot></slot>
      </h1>
    </v-flex>
  </v-layout>
  <h1 v-else class="mb-5">
    <slot></slot>
  </h1>
</template>

<script>
export default {
  name: 'Title',
  props: {
    layout: {
      type: Boolean,
      default: false
    }
  }
}
</script>
