<!-- eslint-disable array-bracket-spacing -->
<!-- eslint-disable indent -->
<script>
import {
  deleteComplex,
  deleteSelectedComplexes,
  stashSelectedComplexes,
  getStashedComplexes,
  restoreStashedComplexes,
  getComplexes,
  getOrganizerFoodTypes
} from '@/router/routes/organizer'

import ConfirmModal from '@/components/common/Modals/ConfirmModal'
import _ from 'lodash'
import store from '@/store'
import Vue from 'vue'
import { mapMutations } from 'vuex'
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal.vue'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ComplexesView',
  components: {
    ContextHelpModal,
    ConfirmModal
  },
  computed: {
    isStashedComponent() {
      return this.$route.meta.flag === 'stashed'
    },
    title() {
      return this.$route.meta.flag === 'stashed'
        ? 'Архив комплексов'
        : 'Комплексы'
    },
    modalText() {
      return this.$route.meta.flag === 'stashed'
        ? 'Вы действительно хотите восстановить выбранные комплексы?'
        : 'Вы действительно хотите перенести выбранные комплексы в архив?'
    },
    headers() {
      return this.$route.meta.flag === 'stashed'
        ? [
            { text: 'ID', value: 'id', sortable: false },
            { text: 'Комплекс', value: 'name', sortable: false },
            { text: 'Тип питания', value: 'foodTypes', sortable: false },
            { text: 'Время отправки в архив', value: 'time', sortable: false },
            { text: 'Операции', value: 'actions', sortable: false }
          ]
        : [
            { text: 'ID', value: 'id', sortable: false },
            { text: 'Комплекс', value: 'name', sortable: false },
            { text: 'Тип питания', value: 'foodTypes', sortable: false },
            { text: 'Операции', value: 'actions', sortable: false }
          ]
    }
  },
  data: () => ({
    contextKeys: Vue.config.contextHelp,
    showModal: false,
    selected: [],
    items: [],
    selectedComplex: null,
    dialog: false,
    loading: false
  }),
  methods: {
    create() {
      store.dispatch('GET_FOOD_TYPES', { method: getOrganizerFoodTypes })
    },
    ...mapMutations(['setShowPreloader']),
    navigateTo(where, id, item) {
      this.$router.push({ name: where, params: { id }, query: item })
    },
    removeConfirm(id) {
      this.selectedComplex = id
      this.dialog = true
    },
    massDelete() {
      const ids = _.map(this.selected, (item) => {
        return item.id
      })

      const payload = { ids: ids }
      deleteSelectedComplexes(payload).then((response) => {
        _.forEach(ids, (id) => {
          this.items = _.remove(this.items, (item) => {
            return item.id != id
          })
        })
        if (response.data.success) {
          notify.success(Vue.config.notifications.complexes.deleteMassSuccess)
        }
      })
    },
    singleDelete(id) {
      deleteComplex(id)
        .then((response) => {
          this.items = _.remove(this.items, (item) => {
            return item.id != id
          })
          if (response.data.success) {
            notify.success(Vue.config.notifications.complexes.deleteOneSuccess)
          }
        })
        .finally(() => store.commit('setShowPreloader', false))
      this.selectedComplex = null
      this.dialog = false
      this.handler()
    },
    stashComplexes() {
      const ids = _.map(this.selected, (item) => item.id)
      const payload = { ids: ids }
      stashSelectedComplexes(payload)
        .then((response) => {
          _.forEach(ids, (id) => {
            this.items = _.remove(this.items, (item) => item.id != id)
          })
          if (response.data.success) {
            notify.success(Vue.config.notifications.complexes.stashMassSuccess)
          }
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    restoreComplexes() {
      const ids = _.map(this.selected, (item) => item.id)
      const payload = { ids: ids }
      restoreStashedComplexes(payload)
        .then((response) => {
          _.forEach(ids, (id) => {
            this.items = _.remove(this.items, (item) => item.id != id)
          })
          if (response.data.success) {
            notify.success(
              Vue.config.notifications.complexes.restoreMassSuccess
            )
          }
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    handlerModal(confirm) {
      if (confirm) {
        if (this.$route.meta.flag === 'stashed') {
          this.restoreComplexes()
          return
        }
        this.stashComplexes()
      }
    }
  },
  watch: {
    '$route.meta.flag': {
      immediate: true,
      handler() {
        this.items = []
        this.loading = true
        if (this.$route.meta.flag === 'stashed') {
          getStashedComplexes()
            .then((response) => {
              this.items = _.map(response.data.data, (item) => {
                return {
                  id: item.id,
                  name: item.name,
                  foodTypes: item.meal_type.map((m) => m.name).join(', '),
                  time: '',
                  isArchived: item.meal_type.map((m) => m.isArchived).join(', ')
                }
              })
            })
            .finally(() => {
              store.commit('setShowPreloader', false)
              this.loading = false
            })
          return
        }
        getComplexes()
          .then((response) => {
            this.items = _.map(response.data.data, (item) => {
              return {
                id: item.id,
                name: item.name,
                foodTypes: item.meal_type.map((m) => m.name).join(', '),
                isArchived: item.meal_type.map((m) => m.isArchived).join(', ')
              }
            })
          })
          .finally(() => {
            store.commit('setShowPreloader', false)
            this.loading = false
          })
      }
    }
  }
}
</script>

<template>
  <v-container fluid class="fs-organizer-container">
    <v-layout class="fs-complex" row mb-3>
      <div class="md3 mb-3 d-flex">
        <h1>{{ title }}</h1>
        <context-help-modal :context-key="contextKeys.supplier.complex.info" />
      </div>
      <v-flex text-md-right>
        <v-btn
          v-if="!isStashedComponent"
          flat
          @click="$router.push({ name: 'ComplexesCreate' })"
          class="primary"
          >Создать комплекс</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex v-if="!isStashedComponent" md9 mb-3 text-md-left>
        <v-btn
          :class="!selected.length ? '' : 'primary'"
          flat
          :disabled="!selected.length"
          @click="showModal = true"
          >Добавить в архив</v-btn
        >

        <v-btn
          class="ml-2"
          flat
          @click="$router.push({ name: 'StashedComplexes' })"
          >Показать архив</v-btn
        >
      </v-flex>
      <v-flex v-else md9 mb-3 text-md-left>
        <v-btn
          :class="!selected.length ? '' : 'primary'"
          flat
          :disabled="!selected.length"
          @click="showModal = true"
          >Восстановить</v-btn
        >

        <v-btn class="ml-2" flat @click="$router.push({ name: 'Complexes' })"
          >Скрыть архив</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout class="fs-complex-result" mb-3>
      <v-flex>
        <v-data-table
          :loading="loading"
          v-model="selected"
          :headers="headers"
          :items="items"
          hide-actions
          no-data-text="Комплексов нет"
          class="elevation-1"
          select-all
          item-key="id"
        >
          <template v-slot:items="props">
            <tr>
              <td>
                <v-checkbox
                  class="fs-checkbox"
                  v-model="props.selected"
                  primary
                  hide-details
                ></v-checkbox>
              </td>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.name }}</td>
              <td v-if="props.item.isArchived === 'true'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon style="color: red" v-on="on" class="fs-active-icon"
                      >report_problem</v-icon
                    >
                  </template>
                  <span
                    >Архивный тип питания: не может использоваться в
                    шаблоне</span
                  >
                </v-tooltip>
                {{ props.item.foodTypes }}
              </td>
              <td v-else>{{ props.item.foodTypes }}</td>
              <td v-if="isStashedComponent">
                {{ props.item.time }}
              </td>
              <td>
                <v-flex row d-flex style="gap: 5px">
                  <v-icon
                    v-if="!isStashedComponent"
                    @click="navigateTo('ComplexItem', props.item.id)"
                    class="fs-active-icon"
                    >remove_red_eye</v-icon
                  >
                  <v-icon
                    v-else
                    @click="navigateTo('StashedComplexesView', props.item.id)"
                    class="fs-active-icon"
                    >remove_red_eye</v-icon
                  >
                  <v-icon
                    class="fs-active-icon"
                    @click="removeConfirm(props.item.id)"
                    >delete</v-icon
                  >
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
        <ConfirmModal
          :dialog.sync="showModal"
          :width="700"
          @confirm="handlerModal"
        >
          <template v-slot:headline>Подтверждение</template>
          <template v-slot:body>
            {{ modalText }}
          </template>
        </ConfirmModal>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog">
      <v-card class="text-center pa-4">
        <h4 class="mb-3">Вы точно хотите удалить комплекс?</h4>
        <v-btn
          color="success"
          class="mr-3"
          @click="singleDelete(selectedComplex)"
          >Да</v-btn
        >
        <v-btn
          color="error"
          @click="
            () => {
              this.selectedComplex = null
              this.dialog = false
            }
          "
          >Нет</v-btn
        >
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style>
.text-center {
  text-align: center;
}
</style>
