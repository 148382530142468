<template>
  <v-container class="fs-organizer-container">
    <v-flex md3 mb-5>
      <h1>{{title}}</h1>
    </v-flex>
    <v-form>
      <v-layout row justify-space-around>
        <v-flex md5>
          <v-text-field label="Наименование"></v-text-field>
          <v-text-field label="Цена, руб (на 100г)"></v-text-field>
          <v-text-field label="Энергетическая ценность, кКал/кДж (на 100г)"></v-text-field>
        </v-flex>
        <v-flex md5>
          <v-text-field label="Белки, г (на 100г)"></v-text-field>
          <v-text-field label="Жиры, г (на 100г)"></v-text-field>
          <v-text-field label="Углеводы, г (на 100г)"></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex>
          <v-data-table
            :headers="dishAgeCategoryHeaders"
            :items="dishAgeCategoryItems"
            hide-actions
            class="elevation-1 fs-admin-table"
          >
            <template v-slot:items="props">
              <tr>
                <td>{{ props.item.ageCategory }}</td>
                <td>{{ props.item.gramms }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-flex text-md-right>
        <v-btn flat class="fs-btn fs-teacher-btn__cancel v-btn--active">отмена</v-btn>
        <v-btn flat class="fs-btn fs-btn--primary fs-teacher-btn__cancel v-btn--active">сохранить</v-btn>
      </v-flex>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "DishCreate",
  data() {
    return {
      dishAgeCategoryHeaders: [
        { text: "Возрастная категория", value: "age-category" },
        { text: "Выход блюда, г", value: "gramms" }
      ],
      dishAgeCategoryItems: [
        {
          id: 1,
          ageCategory: "7-11 лет",
          gramms: "50",
          price: "50"
        },
        {
          id: 2,
          ageCategory: "12 лет и старше",
          gramms: "100",
          price: "100"
        }
      ]
    }
  },
  computed: {
    title() {
      return "Создать блюдо"
    }
  }
}
</script>
