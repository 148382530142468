<template>
  <v-container fluid class="fs-parent-container">
    <v-card flat v-if="is_success_result === null">
      <v-card-title style="display: flex; justify-content: space-between;">
        <h1>{{syrvey_name}}</h1>
        <span style="color: gray;">{{transformDate(syrvey_detail.created_at)}}</span>
      </v-card-title>

      <v-list subheader two-line v-for="question in syrvey_detail.questions" :key="question.id" >

        <v-subheader v-if="!question.is_comment">{{question.name}}</v-subheader>

        <v-textarea
          v-if="question.is_comment"
          name="input-7-1"
          :label="question.name"
          class="px-3"
          v-model="question.answer"
        />

        <div style="display: flex; flex-wrap: wrap;" v-else>
          <v-list-tile v-for="answer in question.variants" :key="answer.id">
            <v-checkbox
              v-if="question.is_several"
              :label="answer.name"
              :input-value="question.answer.includes(answer.id)"
              @change="(e) => handleChangeInput(question, answer.id, e)"
            />

            <v-radio-group v-else v-model="question.answer">
              <v-radio :label="answer.name" :value="answer.id"></v-radio>
            </v-radio-group>
          </v-list-tile>
        </div>
      </v-list>

      <v-btn flat @click="sendResult" class="fs-btn fs-btn--primary fs-btn__add-dish">Отправить</v-btn>
    </v-card>

    <v-card v-else-if="is_success_result === true" flat>
      <v-card-title style="display: flex; justify-content: center;">
        Спасибо за прохождение
      </v-card-title>
    </v-card>

    <v-card v-else-if="is_success_result === false" flat>
      <v-card-title style="display: flex; justify-content: center;">
        При отправке запроса возникла ошибка, повторите позднее
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'SyrveyDetail',
  data: () => ({
    syrvey_id: null,
    is_success_result: null,
    user_id: null,
    syrvey_statuses: {
      NEW: 0,
      DRAFT: 1,
      COMPLETE: 2 
    }
  }),
  computed: {
    ...mapGetters([ 'syrvey_detail' ]),
    syrvey_name() {
      return this.syrvey_detail.name
    }
  },
  methods: {
    ...mapActions([ 'getSyrveyDetail', 'saveSyrveyResult' ]),
    ...mapMutations([ 'clearSyrveyDetail' ]),
    transformDate(date) {
      return moment(date).format(Vue.config.format.date)
    },
    async sendResult() {
      const redirect = () => {
        const timeoutId = setTimeout(() => {
          this.$router.push({ name: 'Syrvey' })
          clearTimeout(timeoutId)
        }, 3000)
      }
      this.saveSyrveyResult({
        status: this.syrvey_statuses.COMPLETE,
        result: this.syrvey_detail,
        user: this.user_id,
        questionnaire: this.syrvey_detail.id
      }).then(res => {
        this.is_success_result = true
        redirect()
      }).catch(err => {
        this.is_success_result = false
        redirect()
      })
    },
    handleChangeInput(question, answerId, e) {
      const findIdx = question.answer.findIndex(val => val === answerId)
      findIdx >= 0 
        ? question.answer.splice(findIdx, 1)
        : question.answer.push(answerId)
    }
  },
  mounted() {
    this.user_id = localStorage.getItem('user_id')
    this.syrvey_id = this.$route.params.syrvey_id
    if (!this.syrvey_id || !this.user_id) this.$router.push({ name: 'Syrvey' })
    else this.getSyrveyDetail(this.syrvey_id)
  },
  beforeDestroy() {
    this.clearSyrveyDetail()
  }
}
</script>

<style>

</style>