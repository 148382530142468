<template>
  <v-container class="fs-teacher-container" fluid grid-list-md>
    <v-layout>
      <v-flex>
        <h1 class="mb-5">{{ title }}</h1>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex>
        <v-btn
          class="fs-btn fs-admin-btn__add d-inline ma-0 mr-3"
          @click="$router.push({ name: 'MunicipalityConcessionsArchive' })"
        >Показать архивные</v-btn>
        <v-btn
          class="fs-btn fs-admin-btn__add d-inline ma-0 mr-3"
          @click="showFormPrivilegeModal('add')"
        >Добавить</v-btn>
        <v-btn
          class="fs-btn fs-admin-btn__add d-inline ma-0 mr-3"
          v-if="setting('department_privilege_scheduler')"
          @click="$router.push({ name: 'ConcessionsScheduler' })"
        >Запланированная смена льгот</v-btn>
      </v-flex>
    </v-layout>
    <v-layout class="fs-teacher-order-result" wrap>
      <v-flex xs12 class="mt-2">
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1 fs-admin-table"
          item-key="id"
          :disable-initial-sort="true"
          hide-actions
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.idx}}</td>
              <td>
                {{ props.item.name }}
              </td>
              <td>{{ props.item.price.priceToString() }}</td>
              <td>
                <div class="d-inline-block mx-2">
                  <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      v-if="props.item.is_default"
                    >bookmark_added</v-icon>
                  </template>
                  <span>Льгота по умолчанию</span>
                </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        v-if="!props.item.is_default"
                        @click="showFormPrivilegeModal('delete', props.index)"
                      >archive</v-icon>
                    </template>
                    <span>Перенести в архив</span>
                  </v-tooltip>
                </div>
                <div class="d-inline-block mx-1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        v-if="!props.item.is_default"
                        @click="setDefault(props.item.id)"
                      >bookmark_add</v-icon>
                    </template>
                    <span>Назначить льготой по умолчанию</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
      <FormPrivilegeModal
        v-model="formPrivilegeModal"
        :action="privilegeAction"
        :privilege="privilegeData"
        ref="formPrivilegeModal"
        @confirm="formPrivilegeConfirm"
      />
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'

import { mapGetters, mapMutations } from 'vuex'
import { getConcessions, setDefaultConcession } from '@/router/routes/department'
import { createPrivilege, archivePrivilege } from '@/router/routes/department'
import { disableSorting } from '@/mixins/helpers'

import store from '@/store'
import scrollable from '@/mixins/scrollable'

import Title from '@/ui-components/Title'
import FormPrivilegeModal from '@/components/municipality/Modals/FormPrivilegeModal'
import notify_ from '@/utils/notifications/toastNotification'
import _ from "lodash";
import numable from "@/mixins/balancable";

export default {
  name: 'ConcessionsIndex',
  mixins: [ numable,  scrollable],
  created () {
    this.loadMore()
  },
  components: {
    Title,
    FormPrivilegeModal
  },
  computed: {
    ...mapGetters([ 'currentUser', 'setting' ])
  },
  data() {
    return {
      title: 'Справочник "Социальные выплаты"',
      headers: [
        { text: '#п/н', value: 'idx' },
        { text: 'Наименование', value: 'name' },
        { text: 'Размер соцвыплаты, руб.', value: 'price' },
        { text: 'Операции', value: 'operations' }
      ],
      items: [],
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10),
      formPrivilegeModal: false,
      privilegeAction: 'add',
      privilegeData: null,
      indexLastItem: null
    }
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async loadMore() {
      const payload = {
        department_id: this.currentUser.department.id
      }
      getConcessions(payload)
        .then(response => {
          //const items = response.data.data
          //this.items = this.items.concat(items)
          this.items = _.map(response.data.data, (item, idx) => {
            return {
              idx: idx+1,
              id: item.id,
              name: item.name,
              price: item.price,
              is_default: item.is_default
            }
          })
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    async setDefault(id) {
      const payload = {
        department_id: this.currentUser.department.id,
        concession: id
      }
      setDefaultConcession(payload)
        .then(res => {
          if (res.data.success === true) {
            notify_.success('Льгота по-умолчанию успешно изменена')
            this.items = []
            this.loadMore()
          }
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    showFormPrivilegeModal(action, index = null) {
      if (index !== null && this.items[index]) {
        this.indexLastItem = index
        this.privilegeData = {
          id: this.items[index].id,
          name: this.items[index].name,
          price: this.items[index].price || 0
        }
      }
      else {
        this.indexLastItem = null
        this.privilegeData = null
        this.$refs.formPrivilegeModal.resetValidation()
      }

      this.privilegeAction = action
      this.formPrivilegeModal = true
    },
    formPrivilegeConfirm(v) {
      const { payload, request, notify } = (() => {
        if (this.privilegeAction === 'add') {
          return {
            payload: {
              name: v.name,
              price: v.price
            },
            request: createPrivilege,
            notify: 'Соцвыплата успешно добавлена'
          }
        }
        else {
          return {
            payload: {
              id: v.id
            },
            request: archivePrivilege,
            notify: 'Соцвыплата успешно перемещена в архив'
          }
        }
      })()

      request(payload).then(response => {
        const { data } = response

        if (this.indexLastItem !== null && this.privilegeAction === 'delete') {
          this.items.splice(this.indexLastItem, 1)
          this.items = this.items.map((item, idx) => {
            item.idx = idx + 1
            return item
          })
        }
        else {
          this.items.unshift(data.data)
          this.items = this.items.map((item, idx) => {
            item.idx = idx + 1
            return item
          })
        }
        notify_.success(notify)

        this.$refs.formPrivilegeModal.closeDialog()
      }).finally(() => store.commit('setShowPreloader', false))
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
