<template>
  <v-container
    fluid
    :class="getLayoutClass"
  >
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <h1>{{ title }}</h1>
      <context-help-modal :context-key="contextKeys.admin.notifications" />
    </v-layout>
    <v-layout pa-0>
      <v-btn
        v-if="btnCreateView"
        flat
        @click="navigateToCreate"
        :class="getBackBtnClass"
      >Создать уведомление</v-btn>
    </v-layout>
    <v-layout>
      <v-flex>
        <v-card v-show="!!items.length" :flat="darkTheme" >
          <v-layout>
            <v-flex
              md3
              class="notifylistRightBlock"
            >
              <v-flex class="text-md-center justify-center pa-3">
                <h3>От кого</h3>
              </v-flex>
              <v-divider />
              <ScrollableContainer
                v-if="!!items.length"
                @scrollOnBottom="handlerScrollBottom"
              >
                <template v-slot:body>
                  <v-list class="notifylistRightBlock__list">
                    <template v-for="(item, idx) in items">
                      <v-list-tile
                        :key="item.createdAt+idx"
                        :class="{active:item.id===currentNotification.id}"
                        @click="showCurrentNotification(item.id)"
                      >
                        <v-list-tile-content>
                          <v-list-tile-title :class="{textBold:!item.readAt}"> {{ item.author }}</v-list-tile-title>
                          <v-list-tile-sub-title :class="{textBold:!item.readAt}"> {{ item.createdAt }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>
                      <v-divider :key="idx"></v-divider>
                    </template>
                  </v-list>
                </template>
              </ScrollableContainer>

            </v-flex>
            <v-flex
              md8
              class="pa-0"
            >
              <v-flex class="text-md-center  pa-3">
                <h3>Сообщение</h3>
              </v-flex>
              <v-divider />
              <v-flex class="pa-2">
                {{currentNotification? currentNotification.message : ''}}
              </v-flex>
            </v-flex>
          </v-layout>
        </v-card>
        <v-flex v-show="items.length == 0">
          <h3>Нет уведомлений</h3>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import moment from 'moment'
import _ from 'lodash'
import { mapMutations, mapGetters } from 'vuex'
// import scrollable from '@/mixins/scrollable'
import ScrollableContainer from './ScrollableContainer'
import { getNotifications, readNotification } from '@/router/routes/common'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'NotificationIndex',
  components: {
    ContextHelpModal,
    ScrollableContainer
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      darkTheme: Vue.config.env.darkTheme,
      title: 'Уведомления',
      currentNotification: null,
      items: [],
      LIMIT: Vue.config.general.notifyLimit,
      offset: 0,
      supplierSubRoles: Vue.config.general.supplierSubRoles
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
    getLayoutClass() {
      return this.$route.meta.layoutClass
    },
    isSubSupplierRole() {
      return this.currentUser.roles
        .some((code) =>
          this.supplierSubRoles
            .some((role) => role.code === code)
        )
    },
    getBackBtnClass() {
      const defaultClasses = 'ma-0 mb-3'
      if(this.isSubSupplierRole) {
        return `${defaultClasses} primary`
      }
      switch (this.currentUser.roles[0]) {
      case 'SUPPLIER':
        return `${defaultClasses} primary`
        break
      case 'PARENT':
        return `${defaultClasses} fs-btn fs-parent-btn fs-btn--secondary`
        break
      default:
        return `${defaultClasses} fs-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active`
        break
      }
    },
    btnCreateView() {
      if (this.currentUser.roles[0] === 'PARENT' || this.currentUser.roles[0].id === '3') {
        return false
      }
      return true
    }
  },
  created() {
    this.loadMore()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    handlerScrollBottom(value) {
      this.offset += this.LIMIT
      // проверка если offset НЕ зашёл за items.length
      if (!(this.offset > this.items.length)) {
        this.loadMore()
      }
    },
    async loadMore() {
      store.commit('setShowPreloader', true)
      try {
        const PAYLOAD = {
          params: {
            limit: this.LIMIT,
            offset: this.offset
          }
        }
        const response = await getNotifications(PAYLOAD)
        const {
          data: { data: itemsArray }
        } = response
        const itemsResultArray = itemsArray.map(item => {
          const {
            author = '',
            createdAt = '',
            readAt = null,
            id = null,
            message = '',
            messageType = ''
          } = item
          return {
            author,
            createdAt: createdAt ? this.convertDate(createdAt) : '',
            readAt: readAt ? this.convertDate(readAt) : null,
            id,
            message,
            messageType
          }
        })
        this.items = [ ...this.items, ...itemsResultArray ]
        if (this.items.length) {
          this.showCurrentNotification(this.items[0].id)
        }
      } catch (error) {
        // notify.error('Ошибка загрузки уведомлений') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    convertDate(date) {
      return moment(date, Vue.config.format.pythonDateFull).format(
        Vue.config.format.prettyDateFull
      )
    },
    navigateToCreate() {
      this.$router.push({ path: 'notifications/create' })
    },
    async showCurrentNotification(id) {
      const currentNotification = _.find(this.items, item => id == item.id)
      if (!currentNotification.readAt) {
        await this.readNotification(id)
        this.items.map(item => {
          if (item.id === id) {
            Vue.set(item, 'readAt', moment())
          }
          return item
        })
        store.dispatch('GET_NOTIFICATIONS_COUNT')
      }
      this.currentNotification = currentNotification
    },

    async readNotification(id) {
      try {
        const PAYLOAD = {
          id: id
        }
        await readNotification(PAYLOAD)
      } catch (error) {
        // notify.error('Ошибка обновления уведомления') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>
