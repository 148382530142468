<template>
  <v-card flat>
    <v-card-text>
      <v-checkbox 
        v-for="item in form.questions"
        :key="item.title"
        v-model="item.value" 
        :label="`${item.title}: ${item.value ? 'Да': 'Нет'}`" 
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        flat
        @click="update"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Form1',
  data() {
    return {
      form: {
        id: null,
        questions: [
          { title: 'Столовая, работающая на сырье', value: false, name: 'raw_material' },
          { title: 'Столовая-доготовочная (работающая на полуфабрикатах)', value: false, name: 'semifinished' },
          { title: 'Буфет-раздаточная', value: false, name: 'buffet_handout' },
          { title: 'Буфет', value: false, name: 'buffet' },
          { title: 'Помещение для приема пищи', value: false, name: 'room_for_eating' },
          { title: 'Отсутствует все вышеперечисленное', value: false, name: 'absent' },
        ]
      }
    }
  },
  computed: {
    ...mapGetters([ 'foodBlockPassport' ])
  },
  mounted() {
    this.getFoodBlockTypes(this.foodBlockPassport.types_sections.id)
      .then(result => this.setForm(result))
  },
  methods: {
    ...mapMutations([ 'setShowPreloader', 'setShowPreloader', 'updateFoodBlockTypes' ]),
    ...mapActions([ 'getFoodBlockTypes', 'updateFoodBlockTypes' ]),
    prepareObjectForApi() {
      const res = {}
      this.form.questions.forEach(item => {
        res[item.name] = item.value
      })
      res["passport"] = this.foodBlockPassport.id
      return res
    },
    setForm(data) {
      this.form.id = data.id
      this.form.passport = data.passport
      this.form.questions.forEach(item => {
        for (const key in data) {
          if (item.name === key) item.value = data[key]
        }
      })
    },
    update() {
      const requestBody = this.prepareObjectForApi()
      this.updateFoodBlockTypes({ id: this.form.id, data: requestBody })
        .then(result => this.setForm(result))
    }
  },
  
}
</script>