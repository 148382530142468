<template>
  <div class="align-self-center">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-icon
            v-if="setting('context_user_help')"
            v-on="on"
            @click="getContextHelp"
            class="pl-2 fs-active-icon"
            style="font-size: 22px; color: #a4a4a4"
        >help</v-icon>
      </template>
      <span>Интерактивная справка</span>
    </v-tooltip>
    <v-dialog v-model="dialog" width="60%">
      <v-card class="pa-3">
        <div v-html="content"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mt-4" color="success" @click="() => {this.content = ''; this.dialog = false}">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getContextHelp } from '@/router/routes/common'
import Vue from 'vue'
import store from '@/store'
import {mapGetters} from "vuex";
export default {
  name: "ContextHelpModal",
  props: {
    contextKey: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      dialog: false,
      content: ''
    }
  },
  computed: {
    ...mapGetters([ 'setting' ])
  },
  methods: {
    async getContextHelp() {
      const response = await getContextHelp(this.contextKey)
      this.content = response.data.content
      this.dialog = true
      this.$emit('showContextHelp', false)
      store.commit('setShowPreloader', false)

    }
  }
}
</script>
