import Vue from 'vue'

export const getManualUrl = (url) =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.manual.download, {
    params: {
      url
    }
  })

export const getManualStatus = (url) =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.manual.status, {
    params: {
      url
    }
  })

export const verifyToken = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.verify,
    payload
  )

export const getUserService = () => axios.get(Vue.config.server + '/' + Vue.config.api.userService)

export const getPupilCardOrders = payload =>
  axios.get(Vue.config.server + '/' + Vue.config.api.administrator.orders.pupilCardOrders, {
    params: payload
  })

export const recreatePupilOrders = payload =>
  axios.post(Vue.config.server + '/' + Vue.config.api.common.recreatePupilOrders, payload)

export const getContextHelp = key => axios.get(
  Vue.config.server + '/' + Vue.config.api.contextHelp.replace('$key', key)
)

export const getInformation = payload => axios.get(Vue.config.server + '/' + Vue.config.api.information, {
  params: payload
})
export const getUserProfile = () => axios.post(Vue.config.api.main.userProfile)
export const logout = () => axios.post(Vue.config.api.main.logout)

export const getQualityAssesmentsSuppliers = (params, config) =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.qualityAssesments.suppliers, { params, ...config })

export const loadSettings = () =>
  axios.get(`${Vue.config.server}/${Vue.config.api.setting}`, {
    headers: {
      'Authorization': ''
    }
  })

export const getQualityMenuPhotoReports = payload =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.qualityAssesments.controls.menu.all, {
    params: payload
  })

export const getOrderCreationErrors = payload =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.orderErrors.all, {
    params: payload
  })

export const save_quality_food_image = payload =>
  axios.post(Vue.config.server + '/' + Vue.config.api.common.qualityAssesments.controls.images.all, payload)

export const delete_quality_food_image = id =>
  axios.delete(Vue.config.server + '/' + Vue.config.api.common.qualityAssesments.controls.images.one.replace('$id', id))

export const update_quality_complect_control = payload =>
  axios.put(Vue.config.server + '/' + Vue.config.api.common.qualityAssesments.controls.complect.one.replace('$id', payload.id), payload.data)

export const createQualityMenuControl = payload =>
  axios.post(Vue.config.server + '/' + Vue.config.api.common.qualityAssesments.controls.all, payload)

export const getQualityMenuControl = id =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.qualityAssesments.controls.one.replace('$id', id))


export const getQualityAssesments = payload =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.qualityAssesments.all, {
    params: payload
  })



export const getPupilBuffetTransactions = payload =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.buffetTransactions.all, {
    params: payload
  })

export const createPupilBuffetTransactions = payload =>
  axios.post(Vue.config.server + '/' + Vue.config.api.common.buffetTransactions.all, payload)

export const createPupilTransaction = payload =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.buffetTransactions.all, {
    params: payload
  })

export const getFoodPeriods = payload =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.foodPeriods, {
    params: payload
  })

export const createProductCategory = async payload =>
  await axios.post(Vue.config.server + '/api/buffet/products/categories/', payload)

export const updateProductCategory = async payload =>
  await axios.put(Vue.config.server + `/api/buffet/products/categories/${payload.id}/`, payload)

export const deleteProductCategory = async id =>
  await axios.delete(Vue.config.server + `/api/buffet/products/categories/${id}/`)

export const getProductCategoryDetail = id =>
  axios.get(
    Vue.config.server + `/api/buffet/products/categories/${id}`
  )

export const getProductCategories = payload =>
  axios.get(
    Vue.config.server + '/api/buffet/products/categories/', {
      params: payload
    }
  )

export const getAgeCategories = () =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.ageCategories)

export const getFoodCategories = () =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.foodCategories)

export const getShifts = () =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.shifts)

export const getFoodTimes = payload =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.foodTimes, {
    params: payload
  })

export const login = payload =>
  axios.post(Vue.config.server + '/' + Vue.config.api.login, payload)

export const loginRoles = payload => axios.get(Vue.config.server + '/' + Vue.config.api.authRoles, {
    params: payload.params,
})

export const signin = payload =>
  axios.post(Vue.config.server + '/' + Vue.config.api.credentials, payload)

export const getNotifications = payload =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.notifications.all, {
    params: payload.params,
    preloaderState: false
  })

export const getNotificationsCount = payload =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.common.notifications.count,
    {
      preloaderState: false
    }
  )
// FOR POST, PUT ... config change - (URL, data, config) - set data=null
export const readNotification = payload =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.common.notifications.one.replace('$id', payload.id),
    null,
    {
      preloaderState: false
    }
  )
