<template>
  <v-container class="fs-teacher-container" fluid>
    <div class="storehouse-head">
      <h1>Паспорта по пищеблокам</h1>
    </div>
    <v-layout style="margin-top: 20px;">
        <v-select
          v-model="selectedSchool"
          :items="schoolsArray"
          clearable
          item-text="name"
          item-value="id"
          label="Школа"
          attach
        ></v-select>
    </v-layout>
    <v-btn color="primary" :disabled="!selectedSchool" @click="downloadReport">Получить отчёт</v-btn>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import {
  getSchools,
  reportFileTaskId,
  reportFileStatus,
  reportPrintFile,
} from '@/router/routes/department'
import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownload'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'FoodMealPassports',
  data: () => ({
    schoolsArray: [],
    selectedSchool: null,
    showPrintLoad: false,
  }),
  computed: {
    ...mapGetters([ 'currentUser' ]),
    getDepartmentId() {
      return this.currentUser.department.id
    }
  },
  async mounted() {
    await this.loadSchools()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async loadSchools() {
      const PAYLOAD = {
        params: {
          limit: Vue.config.general.schoolLimit,
          offset: 0
        },
        department_id: this.getDepartmentId
      }
      try {
        const response = await getSchools(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, short_name, number, passport_exist } = item
          return {
            id: id,
            name: !passport_exist ? short_name + ' (Паспорт не заполнен)': short_name,
            number: number,
            passport_exist: passport_exist,
            disabled: !passport_exist
          }
        })
        this.schoolsArray = [ ...this.schoolsArray, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        this.setShowPreloader(false)
      }
    },
    resetPageFileLoaded() {
      this.setShowPreloader(false)
    },
    async downloadReport() {
      this.showPrintLoad = true
      await this.printFile()
    },
    async printFile() {
      const PAYLOAD = {
        id: 'passport',
        report_id: 'passport',
        data: {
          school_pk: this.selectedSchool,
        },
      }
      const getTaskId = reportFileTaskId

      const callback = this.resetPageFileLoaded
      try {
        await asyncReqestsForDownload(
          getTaskId,
          reportFileStatus,
          reportPrintFile,
          PAYLOAD,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки файла') //pass
        console.error(error)
      } finally {
        this.resetPageFileLoaded()
      }
    },
  },

}
</script>
