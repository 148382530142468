<template>
  <v-container fluid class="fs-teacher-container">
    <v-layout class="fs-complex" row mb-3>
      <v-flex md4 mb-3>
        <h1>{{ title }}</h1>
      </v-flex>
    </v-layout>
    <v-layout row mb-3 wrap>
      <v-flex md3>
        <v-autocomplete
          v-model="classID"
          :outline="darkTheme"
          :label="!darkTheme ? 'Класс' : ''"
          :placeholder="darkTheme ? 'Класс' : ''"
          :items="classesArray"
          @change="changeFiltersHandler"
          item-text="name"
          item-value="id"
          flat
          dense
        />
        <v-dialog max-width="1200" v-model="SchoolOferta">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="fs-btn fs-teacher-btn__details"
              >Публичная оферта</v-btn
            >
          </template>
          <SchoolOferta>
            <template v-slot:actions>
              <v-btn
                color="#1976D2"
                style="max-width: 60px"
                class="white--text font-weight-bold ml-auto"
                @click="SchoolOferta = false"
                >закрыть
              </v-btn>
            </template>
          </SchoolOferta>
        </v-dialog>
      </v-flex>
      <v-flex
        v-if="isShowChangeClassPrivilege"
        class="d-flex ml-3 flex-shrink-0 flex-grow-0"
        row
        xs4
        xs2
      >
        <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          style="display: none"
          lazy
          transition="scale-transition"
          min-width="290px"
          max-width="290px"
        >
          <template v-slot:activator="{ on: on }">
            <v-text-field
              v-model="changeExtPrivilegeDate"
              prepend-icon="event"
              readonly
              label="Дата окончания льготы"
              v-on="on"
              width="80px"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="ru"
            v-model="changeExtPrivilegeDate"
          ></v-date-picker>
        </v-menu>
        <v-btn
          class="white--text ml-5"
          @click="changePrivilege"
          color="primary"
          style="max-width: 200px; min-width: 150px"
        >
          Продлить льготу
        </v-btn>
      </v-flex>
      <v-flex
        class="d-flex ml-3 flex-shrink-0 flex-grow-0"
        v-if="isShowRemoveClassPrivilege"
        row
        xs4
        xs2
      >
        <v-btn
          @click="
            () => {
              this.showConfirmRemovePrivilegeModal = true
            }
          "
          color="primary"
          style="max-width: 200px; min-width: 150px"
        >
          Снять льготу
        </v-btn>
        <ConfirmModal
          :dialog.sync="showConfirmRemovePrivilegeModal"
          @confirm="handlerConfirmRemovePrivilegeModal"
          :btnsClass="'fs-btn fs-teacher-btn__cancel'"
        >
          <template v-slot:headline>Подтверждение</template>
          <template v-slot:body>
            <div>
              Подтвердите снятие льготы у всех учеников выбранного класса
            </div>
          </template>
        </ConfirmModal>
      </v-flex>
    </v-layout>
    <v-layout mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          no-data-text="Нет данных"
          class="elevation-1 fs-teacher-table local-table-style"
          item-key="name"
          :pagination.sync="pagination"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>
                <v-tooltip bottom v-if="props.item.isFake">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on"> warning </v-icon>
                  </template>
                  По ученику получены неполные данные, работа с ним временно
                  заблокирована.
                </v-tooltip>
                {{ props.item.name }}
              </td>
              <td>
                <v-btn
                  @click="
                    $router.push({
                      name: 'StudentsView',
                      params: { id: props.item.id }
                    })
                  "
                  class="fs-btn fs-teacher-btn__details"
                  flat
                  >Смотреть</v-btn
                >
                <v-btn
                  @click="
                    $router.push({
                      name: 'StudentsEdit',
                      params: { id: props.item.id }
                    })
                  "
                  class="fs-btn fs-teacher-btn__edit"
                  flat
                  >Изменить</v-btn
                >
              </td>
              <td>
                {{
                  props.item.concession.name +
                  ' (' +
                  convertToRub(props.item.concession.price) +
                  ' руб)'
                }}
              </td>
              <td>
                {{ props.item.foodType.name || 'отсутствует' }}
                {{
                  props.item.foodType.name
                    ? '(' + convertToRub(props.item.foodType.price) + ' руб)'
                    : ''
                }}
              </td>
              <td>
                {{
                  props.item.agreementAcceptionDate
                    ? new Date(
                        props.item.agreementAcceptionDate
                      ).toLocaleDateString('ru-ru')
                    : ''
                }}
              </td>
              <td>
                {{
                  props.item.offlineContractAcceptionDate
                    ? new Date(
                        props.item.offlineContractAcceptionDate
                      ).toLocaleDateString('ru-ru')
                    : ''
                }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {
  changeClassExtDatePrivilege,
  getStudents,
  removeClassPrivilege
} from '@/router/routes/teacher'
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
import Vue from 'vue'
import scrollable from '@/mixins/scrollable'
import converter from '@/helpers/converter'
import notify from '@/utils/notifications/toastNotification'
import { ConfirmModal } from '@/components/common'
import SchoolOferta from '@/components/admin/Oferta/SchoolOferta.vue'

export default {
  name: 'TeacherStudentIndex',
  components: {
    SchoolOferta,
    ConfirmModal
  },
  mixins: [scrollable],
  data() {
    return {
      isShowChangeClassPrivilege: store.getters.setting(
        'teacher_change_class_privilege_date'
      ),
      isShowRemoveClassPrivilege: store.getters.setting(
        'teacher_remove_class_privilege'
      ),
      darkTheme: Vue.config.env.darkTheme,
      title: 'Карточки учеников',
      classesArray: [],
      classID: 0,
      pagination: {
        sortBy: 'lastName',
        descending: false,
        rowsPerPage: -1
      },
      SchoolOferta: false,
      headers: [
        { text: '№ п/п', value: 'id' },
        { text: 'ФИО ученика', value: 'lastName' },
        { text: 'Операции', value: 'operations' },
        { text: 'Категория социальных выплат', value: 'concession' },
        { text: 'Тип питания', value: 'food-type' },
        { text: 'Оферта принята', value: 'agreementAcceptionDate' },
        {
          text: 'Бумажная оферта принята',
          value: 'offlineContractAcceptionDate'
        }
      ],
      items: [],
      datepickerMenu: false,
      limit: 100,
      offset: 0,
      changeExtPrivilegeDate: null,
      showConfirmRemovePrivilegeModal: false
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  created() {
    this.initParams()
    this.loadOrders()
  },
  methods: {
    ...mapMutations(['setShowPreloader']),
    handlerConfirmRemovePrivilegeModal(confirm) {
      if (confirm) {
        this.removeClassPrivilege()
      }
    },
    changePrivilege() {
      if (this.changeExtPrivilegeDate !== null) {
        const resp = this.processChangePrivilege()
          .then((response) => {
            if (response.status === 200) {
              notify.success('Льгота была успешно продлена у всего класса')
            }
            this.resetData()
            this.loadOrders()
          })
          .catch((error) => {
            console.log(error)
            notify.error('Ошибка продления льготы')
          })
          .finally(() => {
            store.commit('setShowPreloader', false)
          })
      } else {
        notify.error('Поле даты окончания льготы должно быть заполнено')
      }
    },
    removeClassPrivilege() {
      const resp = this.processRemoveClassPrivilege()
        .then((response) => {
          if (response.status === 200) {
            notify.success('Льгота успешно снята у всего класса')
          }
          this.resetData()
          this.loadOrders()
        })
        .catch((error) => {
          notify.error(error)
        })
        .finally(() => {
          store.commit('setShowPreloader', false)
        })
    },
    async processRemoveClassPrivilege() {
      const PAYLOAD = {
        pupils: _.map(this.items, (item) => {
          return item.id
        })
      }
      return await removeClassPrivilege(PAYLOAD)
    },
    async processChangePrivilege() {
      const PAYLOAD = {
        extDate: this.changeExtPrivilegeDate,
        pupils: _.map(this.items, (item) => {
          return item.id
        })
      }
      return await changeClassExtDatePrivilege(PAYLOAD)
    },
    initParams() {
      try {
        this.classesArray = this.currentUser.school_class_list
        this.classID = this.classesArray[0].id
      } catch (error) {}
    },
    async changeFiltersHandler() {
      this.resetData()
      await this.loadOrders()
    },
    convertToRub(value) {
      return converter(value, 'pennyToRub')
    },
    resetData() {
      this.items = []
    },
    async loadOrders() {
      try {
        const PAYLOAD = {
          params: {
            limit: this.limit,
            offset: this.offset,
            class_id: this.classID
          }
        }
        const response = await getStudents(PAYLOAD)
        const {
          data: { data: studentsData }
        } = response

        this.items = studentsData.map((item) => ({
          name: item.fullName,
          isFake: item.isFake,
          id: item.id,
          concession: {
            id: _.get(item, 'privilege.id', null),
            name: _.get(item, 'privilege.name', ''),
            price: _.get(item, 'privilege.price', '')
          },
          foodType: {
            id: _.get(item, 'foodType.id', null),
            name: _.get(item, 'foodType.name', ''),
            price: _.get(item, 'foodType.price', '')
          },
          agreementAcceptionDate: _.get(item, 'agreementAcceptionDate', ''),
          offlineContractAcceptionDate: _.get(
            item,
            'offlineContractAcceptionDate',
            ''
          )
        }))
      } catch (error) {
        // notify.error('Ошибка загрузки учеников') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
