<script>
import store from '@/store'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
import Vue from 'vue'
import rulesCustom from '@/helpers/rulesCustom'

export default {
  name: 'ComplexItem',

  data() {
    const rules = rulesCustom

    return {
      rules,
      isValid: false,
      headers: [
        { text: 'Блюдо', value: 'dish-name' },
        { text: 'Энергетическая ценность', value: 'energy_value' }
      ],
      updateFields: Object.keys(Vue.config.general.meals).reduce(
        (acc, meal) => {
          acc[meal] = []
          return acc
        },
        {}
      ),
      isEqual: true,
      updateData: [],
      meals: Vue.config.general.meals
    }
  },

  computed: {
    ...mapGetters([
      'foodTypes',
      'setting',
      'dishList',
      'getEditMode',
      'getCreateMode',
      'complex',
      'specification'
    ]),
    isStashedComponent() {
      return this.$route.meta.flag === 'stashed'
    },
    title() {
      return this.isCreateMode
        ? 'Добавление нового комплекса'
        : this.isEditMode
        ? 'Редактирование комплекса'
        : 'Комплекс'
    },

    filtredFoodTypes() {
      if (!this.mealTypes?.length) {
        Object.keys(this.meals).forEach((k) => {
          this.updateFields[k] = []
        })

        return this.foodTypes
      } else {
        return this.foodTypes.filter(
          (item) =>
            this.mealTypes[0].mealPeriods.length === item.mealPeriods.length &&
            this.mealTypes[0].mealPeriods.every((i) =>
              item.mealPeriods.includes(i)
            )
        )
      }
    },

    isEditMode: {
      get() {
        return this.getEditMode
      },
      set(value) {
        this.change_edit_mode(value)
      }
    },
    isCreateMode: {
      get() {
        return this.getCreateMode
      },
      set(value) {
        this.change_create_mode(value)
      }
    },
    mealTypes: {
      get() {
        return this.complex.meal_type
      },
      set(value) {
        this.change_complex({ meal_type: value })
      }
    },
    complexName: {
      get() {
        return this.complex.name
      },
      set(value) {
        this.change_complex({ name: value })
      }
    },
    disabledSubmit() {
      if (this.isCreateMode) {
        return !this.isValid
      } else {
        return this.isEqual || !this.isValid
      }
    }
  },

  methods: {
    ...mapActions([
      'fetchDishes',
      'fetchComplex',
      'updateComplex',
      'createComplex'
    ]),
    ...mapMutations([
      'change_edit_mode',
      'change_create_mode',
      'change_complex',
      'resetState'
    ]),

    getHeaders() {
      if (this.setting('supplier_complex_view_price_and_weight')) {
        this.headers.push(
          { text: 'Цена, руб.', value: 'price' },
          { text: 'Выход блюда, гр. (7-11 / 12+)', value: 'weight_primary' }
        )
      }
    },

    checkingForEquality() {
      this.isEqual = _.isEqual(this.updateFields, this.specification)
    },

    cloneFields(fields) {
      this.updateFields = _.cloneDeep(fields)
    },

    changeFoods(key) {
      if (!this.isCreateMode) {
        const id = this.complex.meals[key]
        const foods = this.updateFields[key]
        const index = this.updateData.findIndex((item) => item.id === id)
        const newValue = { id, foods: foods.map((item) => item.id) }
        if (index !== -1) {
          this.updateData.splice(index, 1, newValue)
        } else {
          this.updateData.push(newValue)
        }
        this.checkingForEquality()
      } else {
        Vue.set(this.updateFields, key, this.updateFields[key])
      }
    },

    async updateFoodForComplex() {
      const payload = { id: this.$route.params.id }
      payload.data = this.updateData
      await this.updateComplex(payload)
      this.cloneFields(this.specification)
      this.updateData = []
    },

    async fetchItem() {
      const payload = { id: this.$route.params.id }
      if (this.$route.meta.flag === 'stashed') {
        payload.params = { stashed: true }
      }
      await this.fetchComplex(payload)
      this.cloneFields(this.specification)
    },

    async createNewComplex() {
      const payload = {
        name: this.complexName,
        is_reserve: false,
        meal_types_ids: this.mealTypes.map((item) => item.id)
      }
      Object.entries(this.meals).forEach(([k, v]) => {
        payload[v.key.toLowerCase()] =
          this.updateFields[k]?.map((food) => food.id) ?? []
      })
      await this.createComplex(payload)
      this.$router.push({
        name: 'Complexes'
      })
    },

    submit() {
      if (this.isCreateMode) {
        this.createNewComplex()
      } else {
        this.updateFoodForComplex()
      }
    },
    hsndleCopyComplex() {
      this.$router.replace({
        name: 'ComplexesCreate'
      })
      this.isCreateMode = true
    }
  },

  async beforeMount() {
    if (this.$route.meta.create) {
      this.isCreateMode = true
    }

    this.getHeaders()
    await this.fetchDishes()

    if (!this.isCreateMode) {
      await this.fetchItem()
    }
  },

  destroyed() {
    this.resetState()
  },

  watch: {
    updateFields: {
      handler: 'checkingForEquality',
      deep: true
    },
    isEditMode: {
      handler() {
        this.cloneFields(this.specification)
      },
      deep: true
    }
  }
}
</script>

<template>
  <v-container fluid class="fs-organizer-container">
    <v-toolbar :class="{ 'cyan lighten-5': isEditMode }" extension>
      <v-btn flat round color="primary" @click="$router.go(-1)">
        <v-icon>arrow_back</v-icon>
        Назад
      </v-btn>

      <v-toolbar-title class="text-truncate text-no-wrap px-2">
        <h1>{{ title }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-toolbar-items style="gap: 5px" v-if="!isCreateMode">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              flat
              round
              dark
              color="info"
              icon
              v-on="on"
              @click="hsndleCopyComplex()"
            >
              <v-icon dark>content_copy</v-icon>
            </v-btn>
          </template>
          <code class="text-xs-center">
            Копировать и <br />
            Создать новый Комплекс
          </code>
        </v-tooltip>

        <v-switch
          v-if="!isStashedComponent"
          v-model="isEditMode"
          hide-details
          class="text-no-wrap px-2 blue-grey--text align-center justify-end"
        >
          <template v-slot:prepend>
            Режим редактирования: {{ isEditMode ? 'Вкл' : 'Выкл' }}
          </template>
        </v-switch>
      </v-toolbar-items>
    </v-toolbar>
    <v-layout class="rounded rounded-md" elevation-3>
      <v-flex class="mx-auto">
        <v-form
          ref="form"
          v-model="isValid"
          class="pa-4 pt-6"
          row
          justify-space-between
        >
          <v-layout row>
            <v-flex md6>
              <v-text-field
                label="Наименование"
                v-model="complexName"
                requered
                :readonly="!isCreateMode"
                :clearable="isCreateMode"
                :rules="
                  [
                    rules.required,
                    rules.minLength(3),
                    rules.maxLength(200)
                  ].map((rule) =>
                    rule({ label: 'Наименование', value: complexName })
                  )
                "
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <v-autocomplete
                v-model="mealTypes"
                :items="filtredFoodTypes"
                label="Тип питания"
                class="fs-autocomplete"
                item-value="name"
                item-text="name"
                return-object
                chips
                multiple
                persistent-hint
                :readonly="!isCreateMode"
                :clearable="isCreateMode"
                requered
                :rules="
                  [rules.required, rules.minLengthArr(1)].map((rule) =>
                    rule({ label: 'Тип питания', value: mealTypes })
                  )
                "
              >
                <template v-slot:selection="data">
                  <div :key="JSON.stringify(data.item.id)" class="custom-chip">
                    {{ data.item.name }}
                  </div>
                </template>
              </v-autocomplete>
            </v-flex>
          </v-layout>

          <v-layout column mb-3>
            <v-flex md3 mb-2>
              <h3>Спецификация комплекса</h3>
            </v-flex>

            <v-tabs
              grow
              color="grey lighten-3"
              class="elevation-3"
              v-if="!!mealTypes?.length"
            >
              <v-tabs-slider color="primary"></v-tabs-slider>

              <template v-for="(value, key) in meals">
                <template v-if="mealTypes[0].mealPeriods.includes(value.id)">
                  <v-tab :key="value.id">{{ value.name }}</v-tab>
                  <v-tab-item :key="value.id">
                    <v-autocomplete
                      v-if="isEditMode || isCreateMode"
                      v-model="updateFields[key]"
                      :items="dishList"
                      :label="`Выберите блюдо на ${meals[key].name}`"
                      item-value="id"
                      :item-text="
                        (item) => `${item.name} ${item.weight_middle} г.`
                      "
                      color="blue-grey lighten-1"
                      background-color="blue-grey lighten-4"
                      return-object
                      dense
                      chips
                      small-chips
                      multiple
                      box
                      hide-details
                      deletable-chips
                      @change="() => changeFoods(key)"
                      requered
                      clearable
                      :rules="
                        [rules.required, rules.minLengthArr(1)].map((rule) =>
                          rule({
                            label: `Выберите блюдо на ${meals[key].name}`,
                            value: mealTypes
                          })
                        )
                      "
                    ></v-autocomplete>

                    <v-flex>
                      <v-data-table
                        :headers="headers"
                        :items="updateFields[key]"
                        hide-actions
                        no-data-text="Отсутствует"
                      >
                        <template v-slot:items="props">
                          <tr>
                            <td>{{ props.item.name }}</td>
                            <td>{{ props.item.energy_value }}</td>
                            <template
                              v-if="
                                setting(
                                  'supplier_complex_view_price_and_weight'
                                )
                              "
                            >
                              <td>
                                {{ (props.item.price / 100).toFixed(2) }}
                              </td>
                              <td>
                                {{ props.item.weight_primary }} /
                                {{ props.item.weight_middle }}
                              </td>
                            </template>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-tab-item>
                </template>
              </template>
            </v-tabs>
          </v-layout>
          <v-flex text-md-center>
            <v-btn
              v-if="isEditMode || isCreateMode"
              color="primary"
              @click="submit"
              :disabled="disabledSubmit"
              >Сохранить
            </v-btn>
          </v-flex>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style scoped>
.custom-chip {
  align-items: center;
  border-radius: 28px;
  background: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  font-size: 13px;
  margin: 4px;
  outline: none;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  padding: 4px 12px;
}
</style>
