import { Menu } from '@/lib/menu'

export default class MainMenuFactory {
  constructor(settings) {
    let menu = [
      new Menu('Отчёты', 'Отчёты', 'SuperMunicipalityReportsIndex')
    ]

    if (settings.mod_buffet?.value) {
      menu = menu.concat([
        new Menu('Паспорт пищеблока', 'Паспорт пищеблока', 'SuperMunicipalityFoodMealPassports'),
        new Menu('Результаты опросов', 'Результаты опросов', 'SuperMunicipalSyrveyResult')
      ])
    }

    return menu
  }
}
