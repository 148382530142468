<template>
  <v-container fluid class="fs-teacher-container start-page">
    <v-flex md6 offset-md3>
      <v-list two-line class="fs-list">
        <template v-for="(item, index) in navItems">
          <v-list-tile :key="item.title" avatar :disabled="item.disabled" :to="{name: item.link}">
            <v-list-tile-avatar>
              <v-icon :class="[item.iconClass]">{{ item.icon }}</v-icon>
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ item.subtitle }}</v-list-tile-sub-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <v-btn icon ripple>
                <v-icon color="grey lighten-1">info</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider v-if="index + 1 < navItems.length" :key="index"></v-divider>
        </template>
      </v-list>
    </v-flex>
  </v-container>
</template>

<script>
import MenuFactory from "@/factories/teacher/menu"
import store from "@/store"

export default {
  name: "StartPage",
  data() {
    return {
      navItems: new MenuFactory(store.getters.settings)
    }
  }
}
</script>
