<template>
  <v-app class="eschool">
    <SuperAdminReturn v-if="isSuperAdmin" />
    <main-menu
      :className="className"
      :toolbarItems="menuItems"
      :title="menuTitle"
    ></main-menu>
    <transition name="slide-fade">
      <router-view></router-view>
    </transition>
    <AlertUpdateInformation
      @closeDialogEvent="closeDialog()"
      :dialog="showAlertDialog"
      :block="blockDialog"
      :message="alertMessage"/>
    <SetContactInformationModal
      v-if="setting('user_contact_request')"
      :dialog="showContactModal"
      :is-super-admin="isSuperAdmin"
      @close="closeContactsModal"
    />
  </v-app>
</template>

<script>
import MainMenu from '@/layouts/common/Main'
import MenuFactory from '@/factories/admin/menu'
import { getAdminUserContacts, getFoodTypes as getAdminFoodTypes } from '@/router/routes/admin'
import { mapGetters, mapMutations } from 'vuex'
import store from '@/store'
import notifyPooling from '@/helpers/notifyPooling'
import SetContactInformationModal from "@/components/common/Modals/SetContactInformationModal"
import SuperAdminReturn from "@/components/common/SuperAdminReturn"
import AlertUpdateInformation from "@/components/common/Modals/AlertUpdateInformation"
import { getAlertsList } from '@/router/routes/alerts'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'AdminIndex',
  components: {
    SuperAdminReturn,
    SetContactInformationModal,
    AlertUpdateInformation,
    'main-menu': MainMenu
  },
  async mounted() {
    await this.checkAlertInformation()
    if(this.$route.params.reauth){
      await this.storeInit()
    } else {
      await store
        .dispatch('ADMIN_LOGIN', {
          session_id: this.$route.query.session_id,
          child_id: this.$route.query.selected_child_id,
          role: this.$route.query.role,
        })
        .then(() => this.storeInit())
        .then(() => {
          if (this.setting('user_contact_request')) {
            this.contactsCheck()
          }
        })
        .finally(() => store.commit('setShowPreloader', false))
    }
  },
  data() {
    return {
      menuItems: new MenuFactory(store.getters.settings),
      menuTitle: 'Главная',
      className: 'fs-toolbar--grey',
      showContactModal: false,
      isSuperAdmin: false,
      showAlertDialog: false,
      blockDialog: false,
      alertMessage: null
    }
  },
  computed: {
    ...mapGetters([ 'showPreloader', 'currentUser', 'setting' ])
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async storeInit(){
      try {
        await store.dispatch('GET_FOOD_PERIODS')
        await store.dispatch('GET_FOOD_TIMES')
        await store.dispatch('GET_AGE_CATEGORIES')
        await store.dispatch('GET_SHIFTS')
        await store.dispatch('GET_NOTIFICATIONS_COUNT')
        notifyPooling()
        // // @TODO подумать как бы получше их получить
        // await store.dispatch('GET_FOOD_TYPES', {
        //   method: getAdminFoodTypes,
        //   params: { schoolId: this.currentUser.school.id }
        // })
      } catch (error) {
        notify.error(`Ошибка авторизации ${error}`) //pass
      } finally {
        this.superAdminCheck()
        store.commit('setShowPreloader', false)
      }
    },
    async contactsCheck(){
      await getAdminUserContacts()
        .then(res => {
          store.commit('setShowPreloader', false)
          if (!res.data.email || !res.data.phone || !res.data.full_name) this.showContactModal = true
        })
    },
    superAdminCheck() {
      if (sessionStorage.getItem('superadmin_token') && sessionStorage.getItem('superadmin_user_id')) {
        this.isSuperAdmin = true
        return true
      } else return false
    },
    closeContactsModal() {
      this.showContactModal = false
    },
    async checkAlertInformation() {
      try {
        const response = await getAlertsList()
        const data = response.data
        const res = data.find(({ start_time, end_time }) => {
          const curDate = new Date(new Date().toISOString())
          return curDate > new Date(start_time) && curDate < new Date(end_time)
        })
        if (res !== undefined) {
          this.alertMessage = `
            <b>
              Внимание. На данный момент ведутся технические работы.
            <b> <br>
            Время окончания: ${new Date(res.end_time).toLocaleString()}`
          this.blockDialog = res.is_block
          this.showAlertDialog = true
        } else {
          const min = Math.min(...data.map(item => new Date(item.start_time).valueOf()))
          const res_ = data.filter(item => new Date(item.start_time).valueOf() === min)

          this.blockDialog = res_[0].is_block
          this.alertMessage = `
            ${res_[0].message} <br /><br />
            <b>Планируемое время проведения работ: с
            ${new Date(res_[0].start_time).toLocaleString()} по
            ${new Date(res_[0].end_time).toLocaleString()}</b>
          `
          this.showAlertDialog = true
        }
      } catch (error) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    closeDialog() {
      this.showAlertDialog = false
    }
  }
}
</script>
