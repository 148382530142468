<template>
  <PersonalArea is-child-view/>
</template>

<script>
import PersonalArea from '@/components/parent/PersonalArea/Index'

export default {
  name: 'PupilPersonalAreaIndex',
  components: {
    PersonalArea
  },
}
</script>
