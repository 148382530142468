<template>
  <v-form ref="formCreateUser">
    <v-container grid-list-md>
      <v-layout wrap>
        <v-flex xs12 mb-5>
            <h1>{{ title }}</h1>
        </v-flex>
        <v-flex v-for="item in fields" :key="item.label" md6 xs12>
          <v-text-field
            v-if="item.key !== 'municipality_id'"
            v-model="item.value"
            :label="item.label"
            :append-icon="isPasswordField(item.key) ? (showPassword ? 'visibility' : 'visibility_off') : null"
            :type="!isPasswordField(item.key) || showPassword ? 'text' : 'password'"
            :rules="item.rules"
            required
            @click:append="showPassword = !showPassword"
          >{{ item.value }}</v-text-field>
          <v-select
            v-else-if="municipality"
            v-model="item.value"
            :items="municipalities"
            :label="item.label"
            :rules="item.rules"
            :loading="loadingMunicipalities"
            :item-text="getDisplayName"
            item-value="id"
            required
          ></v-select>
        </v-flex>
        <v-flex text-md-right xs12>
          <v-btn class="ma-0" @click="$router.go(-1)">Отмена</v-btn>
          <v-btn class="ma-0" color="primary" @click="create">Добавить</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import Vue from 'vue'
import { mapMutations } from 'vuex'
import { getMunicipalities } from '@/router/routes/superadmin'
import store from '@/store'
import notify from '@/utils/notifications/toastNotification'
import getDisplayName from '@/helpers/getDisplayName'

export default {
  name: 'CreateUser',
  props: {
    title: {
      default: 'Новый пользователь',
      type: String
    },
    request: {
      required: true,
      type: Function
    },
    convertKeys: {
      default: () => ({}),
      type: Object
    },
    municipality: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'username',
          label: 'Логин',
          value: '',
          rules: [
            v => !!v || 'Логин обязателен'
          ]
        },
        {
          key: 'municipality_id',
          label: 'Муниципалитет',
          value: '',
          rules: [
            v => !!v || 'Муниципалитет обязателен'
          ]
        },
        {
          key: 'password',
          label: 'Пароль',
          value: '',
          rules: [
            v => !!v || 'Пароль обязателен'
          ]
        },
        {
          key: 'confirmPassword',
          label: 'Подтверждение пароля',
          value: '',
          rules: [
            v => !!v || 'Подтверждение пароля обязательно'
          ]
        }
      ],
      municipalities: [],
      loadingMunicipalities: false,
      showPassword: false
    }
  },
  created() {
    this.loadingMunicipalities = this.municipality
  },
  mounted() {
    if (this.municipality) {
      getMunicipalities().then(r => {
        if (r.data.success) {
          this.municipalities = r.data.data
        }
        else {
          notify.error('Ошибка загрузки муниципалитетов') //pass
        }
      }).finally(() => {
        store.commit('setShowPreloader', false)
        this.loadingMunicipalities = false
      })
    }
  },
  methods: {
    getDisplayName,
    ...mapMutations([ 'setShowPreloader' ]),
    create() {
      if (!this.$refs.formCreateUser.validate()) {
        return
      }

      const payload = {}

      this.fields.forEach(item => {
        if (item.key === 'municipality_id' && !this.municipality) {
          return
        }

        const key = item.key in this.convertKeys ? this.convertKeys[item.key] : item.key

        payload[key] = item.value
      })

      this.request(payload).then(response => {
        if (response.data.success) {
          notify.success('Пользователь успешно добавлен')

          this.$router.go(-1)
        }
      }).finally(() => {
        store.commit('setShowPreloader', false)
      })
    },
    isPasswordField(name) {
      return /password/i.test(name)
    }
  }
}
</script>
