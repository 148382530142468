export default {
  methods: {
    navigateTo(component, id, payload) {
      this.$router.push({
        name: component,
        params: { id },
        query: payload
      })
    }
  }
}
