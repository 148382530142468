<template>
  <v-form ref="questionSyrveyForm" v-model="valid">

    <v-card>

      <v-toolbar elevation="1" dense light>
        <v-toolbar-title v-if="is_document">
          {{ is_create ? 'Добавить НПА' : 'Редактировать НПА' }}
        </v-toolbar-title>
         <v-toolbar-title v-else>
          {{ is_create ? 'Создать новый сертификат' : 'Редактировать сертификат' }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.prevent="closeEditModal">
          <v-icon class="fs-active-icon">close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-text-field
          v-model="name"
          :rules="[rules.required]"
          :label="!darkTheme ? 'Наименование' : ''"
          :placeholder="darkTheme ? 'Наименование' : ''"
          :outline="darkTheme"
        />

        <v-text-field
          v-model="description"
          :label="!darkTheme ? 'Описание' : ''"
          :placeholder="darkTheme ? 'Описание' : ''"
          :outline="darkTheme"
        />

        <v-switch
        v-model="is_published"
        inset
        :label="'Опубликовать'"
      ></v-switch>

        <v-text-field
          v-model="currentUser.school.short_name"
          readonly
          :outline="darkTheme"
        />

        <v-text-field
            label="Select..."
            @click='onPickFile'
            v-model='fileName'
            :outline="darkTheme"
            :prepend-inner-icon="darkTheme ? 'attach_file' : ''"
            :prepend-icon="!darkTheme ? 'attach_file' : ''"
        ></v-text-field>
        <input
            type="file"
            style="display: none"
            ref="fileInput"
            accept="*/*"
            @change="onFilePicked">

      </v-card-text>

      <v-card-actions>
        <v-btn @click.prevent="closeEditModal">
          <v-icon class="fs-active-icon">close</v-icon>
          Отменить
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn @click="saveDocument">
          <v-icon class="fs-active-icon">check</v-icon>
          {{ is_create ? 'Создать' : 'Обновить' }}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-form>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import store from "@/store"

export default {
  name: 'NpaDetail',
  props: {
    is_create: {
      type: Boolean,
      default: true
    },
    document_id: {
      type: Number,
      default: -1
    },
    is_document: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    darkTheme: Vue.config.env.darkTheme,
    rules: {
      required: (v) => !!v || "Поле обязательно для заполнения"
    },
    valid: true,

    url: '',
    fileName: '',

    fileObject: null,
  }),
  async mounted() {
    if (!this.is_create) {
      this.getDocumentDetail(this.document_id)
    }
    this.school = this.currentUser.school.id
  },
  methods: {
    ...mapActions([ 'createDocument', 'getDocumentDetail', 'updateDocument', 'createSertificate' ]),
    ...mapMutations([ 'set_attachment_detail', 'clear_attachment_detail' ]),
    onPickFile () {
      this.$refs.fileInput.click()
    },
    onFilePicked (event) {
      const files = event.target.files

      if (files[0] !== undefined) {

        this.fileName = files[0].name
        // Check validity of file
        if (this.fileName.lastIndexOf('.') <= 0) {
          return
        }

        // If valid, continue
        const fr = new FileReader()
        fr.readAsDataURL(files[0])

        fr.addEventListener('load', () => {
          this.url = fr.result
          this.fileObject = files[0] // this is an file that can be sent to server...
          this.set_attachment_detail({
            ...this.attachment_detail,
            file: this.fileObject
          })
        })
      } else {
        this.fileName = ''
        this.fileObject = null
        this.url = ''
        this.set_attachment_detail({
          ...this.attachment_detail,
          file: this.fileObject
        })
      }
    },
    closeEditModal() {
      this.clear_attachment_detail()
      this.$emit('close')
    },

    saveDocument() {
      this.$refs.questionSyrveyForm.validate()
      if (this.valid) {
        if (this.is_create) {
          if (this.is_document) {
            this.createDocument().then(() => {
              this.closeEditModal()
            })
          } else {
            this.createSertificate().then(() => {
              this.closeEditModal()
            })
          }

        }
        else {
          this.updateDocument(this.document_id).then(() => {
            this.closeEditModal()
          })
        }
      }
    },
  },
  computed: {
    ...mapGetters([ 'attachment_detail', 'currentUser' ]),
    name: {
      get () {
        return this.attachment_detail.title
      },
      set (value) {
        this.set_attachment_detail({
          ...this.attachment_detail,
          title: value
        })
      }
    },
    description: {
      get () {
        return this.attachment_detail.description
      },
      set (value) {
        this.set_attachment_detail({
          ...this.attachment_detail,
          description: value
        })
      }
    },
    is_published: {
      get () {
        return this.attachment_detail.is_published
      },
      set (value) {
        this.set_attachment_detail({
          ...this.attachment_detail,
          is_published: value
        })
      }
    },
    school: {
      get () {
        return this.currentUser.school.id
      },
      set (value) {
        this.set_attachment_detail({
          ...this.attachment_detail,
          school: value
        })
      }
    },
    file: {
      get () {
        return this.attachment_detail.file
      },
      set (value) {
        this.set_attachment_detail({
          ...this.attachment_detail,
          file: value
        })
      }
    },
  }
}
</script>
