<script>
import support_icon from './assets/icons/support_icon.svg'
import contact_support from './assets/icons/contact_support_24dp.svg'
import availableColors from './colors'
import { mutations, actions } from './store/index'
import Launcher from './components/Launcher.vue'

export default {
  name: 'Chat',
  components: { Launcher },

  props: {
    theme: {
      type: String,
      default: 'blue'
    }
  },

  data() {
    return {
      participants: [
        {
          id: 'support',
          name: 'Поддержка',
          imageUrl: support_icon
        }
      ],
      titleImageUrl: contact_support,
      alwaysScrollToBottom: true
    }
  },
  computed: {
    colors() {
      return availableColors[this.theme]
    }
  },
  methods: {
    ...mutations,
    ...actions
  },

  async beforeMount() {
    await this.fetchChatList()
    await this.startChatCheckInterval()
  },
  beforeDestroy() {
    this.stopChatCheckInterval()
  }
}
</script>

<template>
  <Launcher
    :participants="participants"
    :title-image-url="titleImageUrl"
    :colors="colors"
    :always-scroll-to-bottom="alwaysScrollToBottom"
  >
  </Launcher>
</template>

<style></style>
