<template>
  <v-container fluid class="fs-organizer-container">
		<div class="page-head__common">
      <!-- <h1>Заявки от школ по буфетной продукции</h1> -->
    </div>

    <v-layout class="fs-teacher-order-result" mb-3>
      <v-flex>
        <v-data-table
          :headers="requestHeaders"
          :items.sync="items.results"
          hide-actions
          no-data-text="Нет заявок"
          class="elevation-1 fs-admin-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.created_at | transformDateTime }}</td>
              <td>{{ statuses[props.item.status] }}</td>
              <td>{{ props.item.is_draft ? 'Да' : 'Нет' }}</td>
              <td>{{ props.item.items.length }}</td>
              <td>
                <v-flex row>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="handleEdit(props.item.id)"
                      >edit</v-icon>
                    </template>
                    <span>Редактирование</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog width="90%" v-model="isShowModal" persistent>
      <BuffetApplicationModal
        v-if="isShowModal"
        :objId="objId"
        :isCreate="false"
        :op_mode="true"
        @close="closeModal"
      />
    </v-dialog>

    <div class="text-xs-center" v-if="pagination_count > 1">
      <v-pagination
        @input="handlePaginationClick"
        :value="paginate.page"
        :length="pagination_count"
        :total-visible="7"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import BuffetApplicationModal from '@/components/admin/BuffetProductsApplications/BuffetApplicationModal.vue'
import {
  getAllBuffetApplication
} from '@/router/routes/organizer.js'
import { mapMutations } from 'vuex'
import notify from '@/utils/notifications/toastNotification'
export default {
  name: 'BuffetSchoolApplications',
  components: { BuffetApplicationModal },
  data: () => ({
    requestHeaders: [
      { text: 'id', value: 'id' },
      { text: 'Дата создания', value: 'created_at' },
      { text: 'Статус', value: 'status' },
      { text: 'Черновик', value: 'is_draft' },
      { text: 'Выбранны продуктов', value: 'items' },
      { text: 'Операции', value: '' },
    ],
    statuses: {
      1: 'Новая заявка',
      2: 'В процессе',
      3: 'Отменена',
      4: 'Отклонена',
      5: 'Доставлена',
      6: 'Завершена'
    },
    items: {
      results: [],
      count: 0
    },
    paginate: {
      page: 1,
      page_size: 10
    },
    isShowModal: false,
    objId: null
  }),
  computed: {
    pagination_count() {
      return Math.ceil(this.items.count / this.paginate.page_size) || 0
    },
  },
  mounted() {
    this.fetchAllAPplications()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    fetchAllAPplications() {
      getAllBuffetApplication({ params: { ...this.paginate, is_draft: 'False' } })
        .then(res => {
          this.items = res.data
        })
        .catch(error => {
          console.error(error)
          // notify.error('Не удалось получить заявки') // backend handler added (exception_message)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    },
    handleEdit(id) {
      this.isShowModal = true
      this.objId = id
    },
    closeModal() {
      this.isShowModal = false
      this.objId = null
      this.fetchAllAPplications()
    },
    handlePaginationClick(page) {
      this.paginate.page = page
      this.fetchAllAPplications()
    }
  }
}
</script>

<style>

</style>
