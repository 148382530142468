<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-dish"
      row
    >
      <v-flex
        md3
        mb-3
      >
        <h1>{{ title }}</h1>
        <v-btn
          flat
          class="mt-4"
          @click="$router.push( {name: 'OfertaStudents'} )"
        >Посмотреть статус
        </v-btn>
      </v-flex>

    </v-layout>
    <v-btn
      flat
      class="primary ma-0 mb-3"
      @click="openEditorModal()"
    >
      <v-icon
        primary
        left
      >edit</v-icon>Создать оферту
    </v-btn>
    <v-layout
      v-if="hasItems"
      class="fs-dish-result"
      column
      mb-3
    >
      <v-flex>
        <v-btn
          flat
          class="primary ma-0 mb-3"
          @click="showLatestOffer()"
        >
          <v-icon
            primary
            left
          >remove_red_eye</v-icon>последняя версия
        </v-btn>
      </v-flex>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="items"
          hide-actions
          no-data-text="Нет оферт"
          item-key="id"
        >
          <template v-slot:items="props">
            <tr>
              <td class="text-xs-left ">{{ props.item.id }}</td>
              <td class="text-xs-left">{{ props.item.createdAt }}</td>

              <td class="text-xs-left">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      class="fs-active-icon"
                      @click="openEditorModal(props.item.text)"
                    >edit</v-icon>
                  </template>
                  <span>Редактирование</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <TextEditorModal
      :dialog.sync="editorModalShow"
      @confirm="handlerConfirmModal"
      :content.sync="content"
    >
      <template v-slot:headline>Создать оферту</template>
    </TextEditorModal>
  </v-container>
</template>

<script>
import {
  getOfferList,
  /* eslint-disable no-unused-vars */
  createOffer,
  getOfferLatest,
  /* eslint-enable no-unused-vars */
} from '@/router/routes/organizer'
import store from '@/store'
import Vue from 'vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import { TextEditorModal } from '@/components/common'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'Oferta',
  components: {
    TextEditorModal
  },
  data() {
    return {
      title: 'Оферта',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Создана', value: 'createdAt' },
        { text: 'Операции', value: 'operations' }
      ],
      items: [],
      LIMIT: Vue.config.general.limit,
      dataTableLoaded: false,
      editorModalShow: false,
      content: ''
    }
  },
  computed: {
    hasItems() {
      return this.items.length
    }
  },
  created() {
    this.loadMore()
  },
  methods: {
    openEditorModal(text) {
      this.content = ''
      if (text) {
        this.content = text
      }
      this.editorModalShow = true
    },
    async showLatestOffer() {
      try {
        const response = await getOfferLatest()
        const {
          data: {
            data: { text = '' }
          }
        } = response
        this.openEditorModal(text)
      } catch (error) {
        console.error('LOG: handlerConfirmModal -> error', error)
        // notify.error('Ошибка загрузки последней версии') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async handlerConfirmModal(value) {
      if (value) {
        const payload = {
          params: {
            text: this.content
          }
        }
        try {
          await createOffer(payload)
          await this.resetData()
          await this.loadMore()
          notify.success('Оферта успешно создана')
        } catch (error) {
          console.error('LOG: handlerConfirmModal -> error', error)
        } finally {
          store.commit('setShowPreloader', false)
        }
      }
      this.btnPrintLoading = false
    },
    async resetData() {
      this.offset = 0
      this.selected = []
      this.items = []
    },
    async loadMore() {
      const payload = {
        params: {
          limit: this.LIMIT,
          offset: this.offset
        }
      }
      try {
        const response = await getOfferList(payload)
        const {
          data: { data: items }
        } = response

        const itemsArray = items.map(item => {
          const { id = null, text = null, createdAt = '' } = item
          return {
            id,
            text,
            createdAt
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
        this.dataTableLoaded = true
      } catch (error) {
        // notify.error('Ошибка загрузки оферт') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>
