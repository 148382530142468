<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <v-flex mb-3>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-layout
        row
        justify-space-around
      >
        <v-flex md4>
          <fs-autocomplete
            outline
            height="30"
            label="Образовательная организация"
          ></fs-autocomplete>
        </v-flex>
        <v-flex md4>
          <DatePickersSmart
            :startDate.sync="dateStart"
            :endDate.sync="dateEnd"
            @changeDate="changeDate"
          />
        </v-flex>
        <v-flex
          text-md-right
          pt-4
          md2
        >
          <v-btn
            flat
            class="fs-btn fs-btn--secondary"
          >показать</v-btn>
          <v-btn
            flat
            class="fs-btn fs-btn--primary fs-form-footer__btn"
          >печать</v-btn>
        </v-flex>
      </v-layout>
    </v-layout>

    <v-layout
      class="fs-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="archiveHeaders"
          :items="archiveItems"
          hide-actions
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.date }}</td>
              <td>{{ props.item.organization }}</td>
              <td>{{ props.item.class }}</td>
              <td>{{ props.item.status }}</td>
              <td>{{ props.item.director }}</td>
              <td>
                <v-flex row>
                  <v-btn
                    flat
                    class="fs-btn fs-btn--secondary"
                    @click="navigateTo('ArchiveView', props.item.id)"
                  >подробнее</v-btn>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { DatePickersSmart } from '@/components/common'
export default {
  name: 'ArchiveIndex',
  components: {
    DatePickersSmart
  },
  data() {
    return {
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10),
      title: 'Архив заявок',
      archiveHeaders: [
        { text: 'Дата', value: 'date' },
        { text: 'ОО', value: 'organization' },
        { text: 'Класс', value: 'class' },
        { text: 'Статус заявки', value: 'status' },
        { text: 'Классный руководитель', value: 'director' },
        { text: 'Операции', value: 'operations' }
      ],
      archiveItems: [
        {
          id: 1,
          date: new Date().toISOString().substr(0, 10),
          organization: 'ОО 1',
          class: '1 А класс',
          status: 'Статус заявки',
          director: 'Петрова Анна Ивановна'
        },
        {
          id: 2,
          date: new Date().toISOString().substr(0, 10),
          organization: 'ОО 1',
          class: '1 А класс',
          status: 'Статус заявки',
          director: 'Петрова Анна Ивановна'
        },
        {
          id: 3,
          date: new Date().toISOString().substr(0, 10),
          organization: 'ОО 1',
          class: '1 А класс',
          status: 'Статус заявки',
          director: 'Петрова Анна Ивановна'
        },
        {
          id: 4,
          date: new Date().toISOString().substr(0, 10),
          organization: 'ОО 1',
          class: '1 А класс',
          status: 'Статус заявки',
          director: 'Петрова Анна Ивановна'
        },
        {
          id: 5,
          date: new Date().toISOString().substr(0, 10),
          organization: 'ОО 1',
          class: '1 А класс',
          status: 'Статус заявки',
          director: 'Петрова Анна Ивановна'
        }
      ],
      dateStart: moment()
        .subtract('days', 1)
        .format(Vue.config.format.serverDate),
      dateEnd: moment()
        .add('days', 1)
        .format(Vue.config.format.serverDate)
    }
  },
  methods: {
    navigateTo(where, id, item) {
      this.$router.push({ name: where, params: { id }, query: item })
    },
    changeDate(payload) {}
  }
}

// @TODO поменять цвет кнопок
</script>
