<template>
  <v-container fluid class="fs-organizer-container">
    <div class="page-head__common">
      <h1>Буфет</h1>
    </div>

    <v-tabs v-model="activeTab">
      <v-tab>Заявки</v-tab>
      <v-tab>Продукты</v-tab>
      <v-tab>Категории</v-tab>
      
      
      <v-tab-item>
        <BuffetSchoolApplications />
      </v-tab-item>

      <v-tab-item>
        <BuffetProductDict />
      </v-tab-item>

      <v-tab-item>
        <Categories />
      </v-tab-item>
    </v-tabs>


  </v-container>
</template>

<script>
import BuffetSchoolApplications from '@/components/organizer/BuffetSchoolApplications/Index'
import BuffetProductDict from '@/components/organizer/BuffetProductDict/Index'
import Categories from '@/components/organizer/Buffet/Categories'
export default {
  name: 'Buffet',
  components: { BuffetSchoolApplications, BuffetProductDict, Categories },
  data: () => ({
    activeTab: 0
  })
  
}
</script>