<template>
  <v-container fluid>
    <v-layout class="fs-dish" row>
      <div class="title-help">
        <div class="d-flex pt-2">
          <p class="text text-center">Охват питанием за день</p>
          <p>
            <context-help-modal :context-key="contextKeys.supplier.news"/>
          </p>
        </div>
      </div>
    </v-layout>
    <v-flex mb-1>
      <apexchart type="donut" :options="chartOptions" :series="series" height="350" width="100%"/>
    </v-flex>
  </v-container>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import { getAdminDashboardFoodCoverage } from "@/router/routes/admin"
import store from "@/store"
import { mapGetters } from "vuex"
import notify from "@/utils/notifications/toastNotification"
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal'
import Vue from "vue"

export default {
  name: 'FoodCoverage',
  components: {
    ContextHelpModal,
    apexchart: VueApexCharts
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      pupil_order_today: 0,
      pupil_no_order: 0,
      series: [],
      chartOptions: {
        colors: [ '#A9FF9B', '#FF4A4A' ],
        chart: {
          type: 'donut',
        },
        labels: [ 'Созданных заявок', 'Не созданных заявок' ],
        legend: {
          position: 'bottom',
          horizontalAlign: 'left'
        },
        responsive: [ {
          breakpoint: 480,
          options: {
            chart: {
              width: 100
            },
            legend: {
              position: 'bottom'
            }
          }
        } ]
      }
    }
  },
  mounted() {
    this.loadStatistics()
      .then(() => {
        this.series = [ this.pupil_order_today, this.pupil_no_order ]
      })
      .catch((error) => {
        console.error(error)
        notify.error('Ошибка загрузки статистики охвата питанием')
      })
      .finally(() => {
        store.commit('setShowPreloader', false)
      })
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
  },
  methods: {
    async loadStatistics() {
      try {
        const response = await getAdminDashboardFoodCoverage({ school_id: this.currentUser.school_id })
        this.pupil_order_today = response.data.pupil_order_today
        this.pupil_no_order = response.data.pupil_no_order
      } catch (error) {
        // notify.error('Ошибка загрузки статистики охвата питанием') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>
