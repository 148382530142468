<template>
  <v-container fluid class="fs-teacher-container">
    <div class="page-head__common">
      <h1>Управление опросами</h1>
      <v-btn
          flat
          @click="createSyrvey"
          class="fs-btn fs-btn--primary fs-btn__add-dish"
        >Создать опрос</v-btn>
    </div>

    <v-layout class="fs-teacher-order-result" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="syrveys.results"
          hide-actions
          no-data-text="Нет опросов"
          class="elevation-1 fs-admin-table"
          item-key="name"
          style="cursor: pointer;"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ transformDate(props.item.created_at) }}</td>
              <td>{{ props.item.school }}</td>
              <td>{{ props.item.questions.length }}</td>
              <td>{{ props.item.is_published }}</td>
              <td>
                <v-flex row>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="editSyrvey(props.item.id)"
                      >edit</v-icon>
                    </template>
                    <span>Редактирование</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="syrveyDetail(props.item.id)"
                      >help</v-icon>
                    </template>
                    <span>Вопросы</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="handleDeleteSyrvey(props.item.id)"
                      >delete</v-icon>
                    </template>
                    <span>Удалить</span>
                  </v-tooltip>
              </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <div class="text-xs-center" v-if="pagination_count > 1">
      <v-pagination
        @input="handlePaginationClick"
        :value="syrveys_pagination.page"
        :length="pagination_count"
        :total-visible="7"
      ></v-pagination>
    </div>


    <v-dialog v-model="show_create_or_edit_syrvey" persistent width="100%">
      <CreateOrUpdateSyrvey
        v-if="show_create_or_edit_syrvey"
        :is_create="is_create"
        :syrvey_id="syrvey_id"
        @close="closeModal"
      />
    </v-dialog>

    <ConfirmModal
      :dialog.sync="confirmDeleteModal"
      @confirm="handlerConfirmModal"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
    >
      <template v-slot:headline>Вы действительно хотите удалить опрос?</template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CreateOrUpdateSyrvey from '@/components/admin/Syrvey/CreateOrUpdateSyrvey'
import ConfirmModal from '@/components/common/Modals/ConfirmModal'
import moment from 'moment'
export default {
  name: 'SyrveyManage',
  components: { CreateOrUpdateSyrvey, ConfirmModal },
  data: () => ({
    confirmDeleteModal: false,
    user_id: null,
    show_create_or_edit_syrvey: false,
    is_create: false,
    syrvey_id: null,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Наименование', value: 'name' },
      { text: 'Дата создания', value: 'created_at' },
      { text: 'Школа', value: 'school' },
      { text: 'Кол-во вопросов', value: 'questions' },
      { text: 'Опубликован', value: 'is_published' },
      { text: 'Управление', value: 'manage' }
    ],
  }),
  computed: {
    ...mapGetters([ 'syrveys', 'syrveys_pagination' ]),
    pagination_count() {
      return Math.ceil(this.syrveys.count / this.syrveys_pagination.page_size) || 0
    },
  },
  methods: {
    ...mapActions([ 'getSyrveys', 'deleteSyrvey' ]),
    ...mapMutations([ 'set_syrveys_pagination' ]),
    transformDate(date) {
      return moment(date).format(Vue.config.format.date)
    },
    handlePaginationClick(page) {
      this.set_syrveys_pagination({ page: page })
      this.getSyrveys()
    },
    createSyrvey() {
      this.show_create_or_edit_syrvey = true
      this.is_create = true
    },
    handlerConfirmModal(value) {
      if (value) {
        this.deleteSyrvey(this.syrvey_id).then(() => {
          this.getSyrveys()
        })
      } else {
        this.confirmDeleteModal = false
      }
      this.syrvey_id = null
    },
    handleDeleteSyrvey(id) {
      this.syrvey_id = id
      this.confirmDeleteModal = true
      
    },
    closeModal() {
      this.show_create_or_edit_syrvey = false
      this.is_create = false
      this.syrvey_id = null
      this.getSyrveys()
    },
    syrveyDetail(syrveyId) {
      this.$router.push({ name: 'SyrveyManageQuestions', params: { syrvey_id: syrveyId } })
    },
    editSyrvey(id) {
      this.show_create_or_edit_syrvey = true
      this.is_create = false
      this.syrvey_id = id
    }

  },
  mounted() {
    this.getSyrveys()
  }
}
</script>