<template>
  <v-container
    fluid
    class="fs-container"
  >
    <v-layout
      class="fs-order"
      row
      mb-3
    >
      <v-flex
        md4
        mb-3
      >
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout
      class="fs-order-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1 fs-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.ageCategory }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.qty }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex
        text-md-right
        pt-3
      >
        <v-btn
          flat
          class="primary"
          @click="$router.push({name: 'OrganizerArchiveOrderSchoolView'})"
        >К списку заявок</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment'
import {
  getCurrentArchiveOrder,
} from '@/router/routes/organizer'
import { mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import store from '@/store'
import _ from 'lodash'

export default {
  name: 'OrganizerArchiveOrderView',
  created() {
    this.loadOrder().finally(() => store.commit('setShowPreloader', false))
  },
  data() {
    return {
      title: 'Архивная заявка на питание ' + moment(this.$route.params.date).format(
        Vue.config.format.date
      ),
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10),
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Возрастная категория', value: 'ageCategory' },
        { text: 'Название блюда', value: 'name' },
        { text: 'Количество', value: 'qty' }
      ],
      items: []
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    loadOrder() {
      const payload = {
        schoolId: this.$route.params.schoolId,
        classId: this.$route.params.classId,
        date: moment(this.$route.params.date).format(
          Vue.config.format.pythonDate
        )
      }
      return getCurrentArchiveOrder(payload).then(response => {
        if (!response.data.data.length) {
          return
        }
        const middleOrders = _.map(response.data.data[0].weightMiddle, item => {
          return {
            id: item.id,
            ageCategory: '11-18лет',
            name: item.name,
            qty: item.quantity
          }
        })
        const primaryOrders = _.map(
          response.data.data[0].weightPrimary,
          item => {
            return {
              id: item.id,
              ageCategory: '7-10лет',
              name: item.name,
              qty: item.quantity
            }
          }
        )
        this.items = this.items.concat(middleOrders)
        this.items = this.items.concat(primaryOrders)
      })
    },
    navigateTo(where, params) {
      const date = {
        day: moment(params.date, Vue.config.format.date).format('DD'),
        month: moment(params.date, Vue.config.format.date).format('MM'),
        year: moment(params.date, Vue.config.format.date).format('YYYY'),
        id: params.class.id
      }

      this.$router.push({ name: where, params: date })
    }
  }
}
</script>
