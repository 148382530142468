<script>
import { getBuffetUsers } from "@/router/routes/superadmin";
import {mapMutations} from "vuex";
import store from "@/store";
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'BuffetUserList',
  data() {
    return {
      usersList: [],
      headers: [
        { text: '#', value: 'id' },
        { text: 'Логин', value: 'username' },
        { text: 'Комбинат питания', value: 'supplier' },
        { text: 'Школы', value: 'schools' },
        { text: 'Действия', value: 'id' },
      ]
    }
  },
  async mounted() {
    await this.getBuffetUsers()
    store.commit('setShowPreloader', false)
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async getBuffetUsers() {
      const { data } = await getBuffetUsers()
      console.log(data)
      data.forEach(item => {
        this.usersList.push({
          id: item.id,
          username: item.user.username,
          supplier: item.supplier.short_name,
          schools: item.schools
        })
      })
    }
  }
}

</script>

<template>
<div>
<v-container fluid>
  <v-layout
      class="fs-archive"
      row
      mb-3
  >
    <v-flex mb-3>
      <h1>Пользователи буфета</h1>
    </v-flex>
  </v-layout>
  <v-layout class="mt-4" row wrap>
    <v-flex sm12>
      <v-card>
        <v-card-text>
          <v-btn @click="$router.push({name: 'BuffetUserCreate'})" small>
            Добавить пользователя
          </v-btn>
          <v-data-table
            :items="usersList"
            :headers="headers"
          >
            <template v-slot:items="props">
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.username }}</td>
              <td>{{ props.item.supplier }}</td>
              <td>{{ props.item.schools.map(item => item.short_name).toString() }}</td>
              <td></td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</v-container>
</div>
</template>

<style scoped>

</style>
