<script>
import { mapGetters, mapMutations } from 'vuex'
import store from '@/store'
import {parseSettings} from "@/utils/parseSettings";
import {roleExtraData, getRole} from '@/utils/roles'

export default {
  name: 'SelectCrossAuthRoles',
  components: {
  },
  async mounted() {
    this.roles = store.getters.available_roles.map(roleEngName => (
        {value: roleEngName, name: roleExtraData[roleEngName].humanName}
    ))
  },
  data() {
    return {
      roles: store.getters.available_roles,
      selectedRole: null,

    }
  },
  computed: {
    ...mapGetters([ 'showPreloader', 'currentUser', 'setting' ]),
    fullRoleData() {
      if (!this.selectedRole) return null
      return getRole(this.selectedRole)
    }
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    continueButtonOnClick() {
      const nextRoute = this.fullRoleData.routePath
      this.$router.push({
        path: nextRoute,
        query: {session_id: this.$route.query.session_id, role: this.selectedRole}
      })
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <div class="card">
      <div class="card-header">
        <p>Выберите роль</p>
      </div>
      <div class="card-body">
        <v-autocomplete
          v-model="selectedRole"
          :items="roles"
          item-text="name"
          item-value="value"
          label="Роль"
          outlined
        />
        <v-btn class="button" color="primary" @click="continueButtonOnClick">Продолжить</v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.card {
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  overflow: hidden;
}

.card-header {
  background-color: #2d9cf3;
  color: #fff;
  padding: 15px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.card-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.button {
  align-self: flex-end; /* Кнопка справа */
}
</style>

