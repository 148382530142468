<template>
  <div>
    <v-dialog
        :value="showEditModal"
        :max-width="600"
        persistent
    >
      <v-card>
        <v-card-title>
          <h3>Изменение рабочих дней</h3>
        </v-card-title>
        <v-container class="pt-2">
          <v-tabs
              v-model="step"
              show-arrows
              bg-color="deep-purple-darken-4"
              center-active
              class="mt-2"
          >
            <v-tab :disabled="tab0">Выберите класс(ы)</v-tab>
            <v-tab :disabled="tab1">Укажите дни</v-tab>
            <v-tab :disabled="tab2">Выберите действие</v-tab>

            <v-tabs-items>


              <v-tab-item>
                <v-select
                    no-data-text="Ничего не выбрано"
                    v-model="selectedClasses"
                    item-text="name"
                    item-value="id"
                    :items="classes"
                    label="Классы:"
                    deletable-chips
                    chips
                    multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-tile
                        ripple
                        @click="selectAll('selectedClasses', 'classes')"
                    >
                      <v-list-tile-action>
                        <v-icon :color="selectedClasses.length > 0 ? 'primary' : ''">{{
                            selectedClasses.length === classes.length
                                ? 'check_box'
                                : 'check_box_outline_blank'
                          }}</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title>Выбрать всё</v-list-tile-title>
                        <v-list-tile-sub-title>Выбрано: {{ selectedClasses.length }}</v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-tab-item>


              <v-tab-item>
                <div class="mt-3 mb-2">Внимание! Если вы хотите изменить <b>один</b> день, то укажите только "Дата с:"</div>
                <DatePickersSmart
                    :startDate.sync="dateStart"
                    :endDate.sync="dateEnd"
                />
              </v-tab-item>


              <v-tab-item>
                <div class="d-flex mt-4 justify-center">
                  <v-btn
                      style="max-width: 110px"
                      :color="actionIsWorking ? 'grey' : 'error'"
                      class="white--text font-weight-bold"
                      @click="chooseWorkingButton(0)"
                  >НЕ РАБОЧИЙ</v-btn>
                  <v-btn
                      style="max-width: 50px"
                      :color="actionIsWorking ? 'green' : 'grey'"
                      class="white--text font-weight-bold"
                      @click="chooseWorkingButton(1)"
                  >РАБОЧИЙ</v-btn>
                </div>
              </v-tab-item>


            </v-tabs-items>

          </v-tabs>
        </v-container>
          <div class="d-flex" style="background-color: #5B5B5B">
            <v-btn
                color="error"
                style="max-width: 50px"
                class="font-weight-bold"
                @click="closeOrBackAction"
            >{{ closeOrBack }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="#1976D2"
                style="max-width: 50px"
                class="white--text font-weight-bold"
                @click="forward"
            >{{ forwardOrDone }}</v-btn>
          </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getClasses, updateWorkingDays } from "@/router/routes/admin"
import Vue from "vue"
import store from "@/store"
import { mapGetters } from "vuex"
import { getSchoolId } from "@/helpers/utils"
import DatePickersSmart from "@/components/common/DatePickersSmart/DatePickersSmart.vue"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: "AdminWorkingDaysEdit",
  components: { DatePickersSmart },
  props: {
    showEditModal: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  data() {
    return {
      selectedClasses: [],
      classes: [],
      step: 0,
      tab0: false,
      tab1: true,
      tab2: true,
      actionIsWorking: true,

      dateStart: null,
      dateEnd: null,
    }
  },
  created() {
    this.getClasses()
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
    closeOrBack() {
      return this.step === 0 ? 'Отмена' : 'Назад'
    },
    forwardOrDone() {
      return this.step === 2 ? 'Готово' : 'Далее'
    }
  },
  methods: {
    closeOrBackAction() {
      if (this.step === 0) {
        this.$emit('update:showEditModal', false)
        return
      }
      this.step -= 1
      this.updateTabsState()
    },
    forward() {
      if (this.step === 0) {
        if (!this.selectedClasses.length) {
          notify.error('Укажите классы') //pass
        } else {
          this.step += 1
          this.updateTabsState()
        }
      } else if (this.step === 1) {
        if (this.dateStart === null) {
          notify.error('Укажите дату(ы)') //pass
        } else {
          this.step += 1
          this.updateTabsState()
        }
      } else if (this.step !== 2) {
        this.step += 1
        this.updateTabsState()
      } else {
        this.updateWorkingDays()
      }
    },
    updateTabsState() {
      if (this.step === 0) {
        this.tab0 = false
        this.tab1 = true
        this.tab2 = true
      } else if (this.step === 1) {
        this.tab0 = true
        this.tab1 = false
        this.tab2 = true
      } else if (this.step === 2) {
        this.tab0 = true
        this.tab1 = true
        this.tab2 = false
      }
    },
    chooseWorkingButton(value) {
      if (!value && this.actionIsWorking) {
        this.actionIsWorking = false
      } else if (value && !this.actionIsWorking) {
        this.actionIsWorking = true
      }
    },
    async getClasses() {
      const payload = {
        schoolId: getSchoolId(this.currentUser)
      }
      try {
        const response = await getClasses(payload)
        this.classes = response.data.data.map(i => {
          return {
            id: i.id,
            name: i.name,
          }
        })
      } catch (e) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    selectAll(selectArray, parentArray) {
      this.$nextTick(() => {
        if (this[selectArray].length) {
          this[selectArray] = []
        } else {
          const idsArray = this[parentArray].map(item => item.id)
          this[selectArray] = [ ...idsArray ]
        }
      })
    },
    async updateWorkingDays() {
      const PAYLOAD = {
        id: getSchoolId(this.currentUser),
        payload: {
          start_date: this.dateStart,
          end_date: this.dateEnd,
          classes: this.selectedClasses,
          is_working_day: this.actionIsWorking
        }
      }
      try {
        await updateWorkingDays(PAYLOAD)
        notify.success('Рабочие дни успешно обновлены')
        this.$emit('reload')
        this.$emit('update:showEditModal', false)
      } catch (e) {
        // notify.error('Ошибка обновления рабочих дней') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  },
}
</script>
