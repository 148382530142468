<template>
  <v-dialog v-model="show" :max-width="width" persistent>
    <v-container :class="className" md6>
      <v-layout class="fs-complex" row mb-3>
        <v-flex mb-3>
          <h1>{{ title }}</h1>
        </v-flex>
      </v-layout>
        <v-layout row>
          <v-flex>
            <v-autocomplete
              v-model="departmentId"
              :items="departments"
              item-text="name"
              item-value="id"
              persistent-hint
            ></v-autocomplete>
          </v-flex>
        </v-layout>
      <v-flex text-md-right mt-3 mr-2>
        <v-btn class="fs-btn fs-btn--secondary" flat @click="show = false">Закрыть</v-btn>
        <v-btn class="fs-btn fs-btn--primary fs-form-footer__btn" flat @click="save">Сохранить</v-btn>
      </v-flex>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: 'AssignDepartmentsModal',
  data() {
    return {
      title: 'Назначить муниципалитет',
      departmentId: null
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    save() {
      this.$emit('assignDepartments', this.departmentId)
      this.show = false
    }
  },
  props: {
    value: {
      required: true,
      type: Boolean
    },
    width: {
      default: 500
    },
    departments: {
      type: Array
    },
    currentDepartment: {
      type: Number,
      default: null
    },
    className: {
      default: 'fs-container'
    },
  },
  watch: {
    currentDepartment: {
      immediate: true,
      handler(v) {
        this.departmentId = v
      }
    }
  }
}
</script>
