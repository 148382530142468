<template>
  <v-container fluid :class="{ 'fs-teacher-container': !is_supplier }">
    <div class="page-head__common"><h1>Результаты опросов</h1></div>
    <v-autocomplete
      v-if="is_department_admin"
      :items="departments"
      item-text="name"
      item-value="id"
      label="Выберете департамент"
      v-model="department"
      clearable
    ></v-autocomplete>
    <v-autocomplete
      :items="schools"
      item-text="name"
      item-value="id"
      label="Выберете школу"
      v-model="school"
      clearable
    ></v-autocomplete>

    <v-layout class="fs-teacher-order-result" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="syrvey_results.results"
          hide-actions
          no-data-text="Нет результатов"
          class="elevation-1 fs-admin-table"
          item-key="name"
          style="cursor: pointer;"
        >
        <template v-slot:items="props">
          <tr>
            <td class="cursor-pointer" @click="handleClickSyrveyResult(props.item.id)">{{ props.item.id }}</td>
            <td class="cursor-pointer" @click="handleClickSyrveyResult(props.item.id)">{{ props.item.created_at | transformDateTime }}</td>
            <td class="cursor-pointer" @click="handleClickSyrveyResult(props.item.id)">{{ props.item.user }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    v-show="is_school_admin"
                    class="fs-active-icon"
                    @click="handleDeleteSyrveyResult(props.item.id)"
                  >delete</v-icon>
                </template>
                <span>Удалить</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <div class="text-xs-center" v-if="pagination_count > 1">
      <v-pagination
        @input="handlePaginationClick"
        :value="syrvey_results_pagination.page"
        :length="pagination_count"
        :total-visible="7"
      ></v-pagination>
    </div>

     <ConfirmModal
        :dialog.sync="confirmDeleteModal"
        @confirm="handlerConfirmModal"
        :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      >
        <template v-slot:headline>Вы действительно хотите удалить результат опроса?</template>
      </ConfirmModal>

  </v-container>
</template>

<script>
import Vue from 'vue'
import { getSchools as getSchoolsAdmin } from "@/router/routes/admin.js"
import { getSchools as getSchoolsOP } from "@/router/routes/organizer.js"
import { getDepartments, getSchools as getSchoolsSuperDepartment } from "@/router/routes/department.js"
import ConfirmModal from '@/components/common/Modals/ConfirmModal'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'SyrveyResult',
  components: { ConfirmModal },
  props: {
    user_role: {
      type: String,
      default: 'is_school_admin'
    }
  },
  data: () => ({
    confirmDeleteModal: false,
    syrvey_result_id: null,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Дата создания', value: 'created_at' },
      { text: 'Пользователь', value: 'user' },
      { text: 'Действия', value: 'actions' }
    ],
    schools: [],
    departments: []
  }),
  methods: {
    ...mapActions([ 'getPolls', 'deleteSyrveyResult' ]),
    ...mapMutations([ 'set_syrvey_results_filters', 'set_syrvey_results_pagination' ]),
    handleDeleteSyrveyResult(id) {
      this.syrvey_result_id = id
      this.confirmDeleteModal = true
    },
    handlerConfirmModal(value) {
      if (value) {
        this.deleteSyrveyResult(this.syrvey_result_id).then(() => {
          this.getPolls()
        })
      } else {
        this.confirmDeleteModal = false
      }
      this.syrvey_result_id = null
    },
    handleClickSyrveyResult(id) {
      let routerName = ''
      if (this.is_school_admin) {
        routerName = 'AdminSyrveyDetail'
      }
      else if (this.is_department_admin) {
        routerName = 'SuperDepartmentSyrveyDetail'
      }
      else if (this.is_department) {
        routerName = 'DepartmentSyrveyDetail'
      }
      else if (this.is_supplier) {
        routerName = 'OrganizerSyrveyDetail'
      }
      if (routerName !== '') this.$router.push({ name: routerName, params: { poll_id: id } })
    },
    async getSchools() {
      let getSchools = null
      let payload = {}
      if (this.is_department_admin) {
        if (!this.department) return
        getSchools = getSchoolsSuperDepartment
        payload = {
          params: { limit: this.LIMIT, offset: this.offset },
          department_id: this.department
        }
      }
      else if (this.is_department) {
        getSchools = getSchoolsSuperDepartment
        payload = {
          params: { limit: this.LIMIT, offset: this.offset },
          department_id: this.currentUser.department.id
        }
      }
      else if (this.is_school_admin) {
        getSchools = getSchoolsAdmin
        payload = { limit: this.LIMIT, offset: this.offset } // !
      }
      else if (this.is_supplier) {
        getSchools = getSchoolsOP
        payload = { limit: this.LIMIT, offset: this.offset } // !
      }
      try {
        const response = await getSchools(payload)
        const { data: { data: items } } = response
        const itemsArray = items.map((item) => ({ id: item.id, name: item.name }))
        this.schools = [
          { id: null, name: 'Все' },
          ...this.schools,
          ...itemsArray
        ]
      } catch (error) {
        // notify.error("Ошибка загрузки школ") // backend handler added (exception_message)
      } finally {
        this.$store.commit("setShowPreloader", false)
      }
    },
    async getDepartments() {
      try {
        const response = await getDepartments()
        const itemsArray = response.data.map((item) => ({ id: item.id, name: item.name }))
        this.departments = [
          { id: null, name: 'Все' },
          ...this.departments,
          ...itemsArray
        ]
        this.department = this.departments[0].id
      } catch (error) {
        // notify.error("Ошибка загрузки департаментов") // backend handler added (exception_message)
      } finally {
        this.$store.commit("setShowPreloader", false)
      }
    },
    handlePaginationClick(page) {
      this.set_syrvey_results_pagination({ page: page })
      this.getPolls()
    }
  },
  computed: {
    ...mapGetters([ 'syrvey_results', 'syrvey_results_filters', 'syrvey_results_pagination', 'currentUser' ]),
    school: {
      get: function () {
        return this.syrvey_results_filters.school
      },
      set: function (val) {
        this.set_syrvey_results_filters({
          school: val
        })
      }
    },
    department: {
      get: function () {
        return this.syrvey_results_filters.department
      },
      set: function (val) {
        this.set_syrvey_results_filters({
          department: val
        })
      }
    },
    pagination_count() {
      return Math.ceil(this.syrvey_results.count / this.syrvey_results_pagination.page_size) || 0
    },
    is_school_admin() {
      return this.user_role === 'is_school_admin'
    },
    is_supplier() {
      return this.user_role === 'is_supplier'
    },
    is_department() {
      return this.user_role === 'is_department'
    },
    is_department_admin() {
      return this.user_role === 'is_department_admin'
    },
  },
  async mounted() {
    await this.getPolls()
    if (this.is_department_admin) {
      await this.getDepartments()
    }
    this.getSchools()
  },
  watch: {
    department(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.school = null
        this.schools = []
        this.getSchools()
        this.getPolls()
      }
    },
    school(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getPolls()
      }
    }
  }
}
</script>
