<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <v-layout
      class="fs-teacher-order"
      row
      mb-3
    >
      <v-flex
        md4
        mb-3
      >
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      class="fs-header-filter"
    >

    </v-layout>
    <v-layout
      class="fs-teacher-order-result"
      mb-3
      justify-space-around
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1 fs-teacher-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.name  }} {{props.item.formId? `(Форма ${props.item.formId})` : null}}</td>
              <td>
                <v-flex class="pa-2">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="ma-0"
                        @click="!props.item.is_another_url ? showReportModal(props.item) : showAnotherModal(props.item)"
                      >print</v-icon>
                    </template>
                    <span>Печать</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <ConfirmModal
      :dialog.sync="showModal"
      :startPrint.sync="showPrintLoad"
      :dateStart.sync="dateStart"
      :dateEnd.sync="dateEnd"
      :width="800"
      :hideConfirmButton="storehouseChoice && (storehouses.length <= 1 || !storehouse)"
      @confirm="handlerModal"
    >
      <template v-slot:headline>Печать отчёта</template>
      <template v-slot:body>
        Название отчёта:
        <h3>{{reportPrintTitle}}</h3>
        <v-flex
          md4
          class="mt-3"
        >
          <div v-if="datePickers === 1">
            <DatePickersSmart
              :startDate.sync="dateStart"
              :onePicker="true"
              @changeDate="changeDate"
            />
          </div>
          <div v-else-if="datePickers === 2">
            <DatePickersSmart
              :startDate.sync="dateStart"
              :endDate.sync="dateEnd"
              @changeDate="changeDate"
            />
          </div>
          <div v-if="yearSelector">
            <v-text-field
              v-model="selectedYear"
            />
          </div>
          <v-menu
            v-if="yearPicker.active"
            ref="yearPickerMenu"
            :close-on-content-click="false"
            v-model="yearPicker.menu"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              v-model="yearPicker.year"
              label="Год"
              clearable
              prepend-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker
              ref="yearPicker"
              v-model="yearPicker.date"
              @input="save"
              :max="now"
              reactive
              no-title
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex v-if="buffetProductTypes">
          <v-select
            :items="productCategories"
            item-text="name"
            item-value="id"
            label="Категория питания"
            return-object
            v-model="productType"
          ></v-select>
        </v-flex>
        <v-flex
          md9
          v-if="schoolsSelector"
        >
          <v-select
            v-model="selectedSchoolID"
            :items="schoolsArray"
            class="fs-filter"
            item-text="name"
            item-value="id"
            label="Школа:"
            attach
          ></v-select>
        </v-flex>
        <v-flex
          md9
          v-if="storehouseChoice"
        >
          <v-select
            v-model="storehouse"
            :items="storehouses"
            class="fs-filter"
            item-text="name"
            item-value="id"
            label="Склад:"
            attach
          ></v-select>
        </v-flex>
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import { getProductCategories } from '@/router/routes/common'
import moment from 'moment'
import {
  getAllReports,
  reportFileTaskId,
  reportFormFileTaskId,
  reportFileTaskIdSchool,
  reportFileStatus,
  reportPrintFile,
  reportForm2,
  getSchools,
  getStorehouses,
  getArchiveSchools,
  getReportBalanceTaskId,
  reportBalanceFileStatus,
  reportBalancePrintFile
} from '@/router/routes/organizer'

import { mapGetters, mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import { ConfirmModal, DatePickersSmart } from '@/components/common'
import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownloadWithProgressBar'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ReportsIndex',
  components: {
    ConfirmModal,
    DatePickersSmart
  },
  data() {
    return {
      server: Vue.config.server,
      title: 'Отчёты',
      datepickerMenu: false,
      dateStart: '',
      dateEnd: '',
      selectedYear: null,
      headers: [
        { text: 'Отчёт', value: 'name', width: '90%' },
        { text: 'Операции', value: 'operations' }
      ],
      items: [],
      showModal: false,
      showPrintLoad: false,
      buffetProductTypes: false,
      productCategories: [],
      productType: '' ,
      reportPrintTitle: '',
      datePickers: null,
      datePickerRange: '',
      currentItem: null,
      schoolsSelector: false,
      archiveSchoolsSelector: false,
      schoolsArray: [],
      archiveSchools: [],
      selectedSchoolID: null,
      yearSelector: null,
      yearPicker: {
        year: null,
        date: null,
        active: null,
        menu: false
      },
      storehouseChoice: false,
      schoolId: null,
      school: '',
      storehouses: [
        { id: null, name: 'Склад не выбран' }
      ],
      storehouse: ''
    }
  },
  computed: {
    ...mapGetters([ 'currentUser', 'setting' ]),
    sortSchools() {
      return this.schoolsArray.sort(function(a, b) {
        const result = a.number - b.number
        return result
      })
    },
    sortArchiveSchools() {
      return this.archiveSchools.sort(function(a, b) {
        return a.number - b.number
      })
    },
    now() {
      return moment().format('YYYY-MM-DD')
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader', 'setAsyncLoadFile' ]),
    async init() {
      await this.loadReports()
      await this.loadSchools()

      if (this.setting('mod_buffet')) {
        await this.loadStorehouses()
        await this.loadProductCategories()
      }
    },
    async loadProductCategories() {
      try {
        const response = await getProductCategories({ limit: this.LIMIT, offset: this.offset })
        const itemsArray = response.data.results.map(item => ({ id: item.id, name: item.title }))
        this.productCategories = [ ...this.productCategories, ...itemsArray ]
        this.productType = itemsArray.length ? itemsArray[0].id : null
      } catch (error) {
        // notify.error('Ошибка загрузки типов питания') // backend handler added (exception_message)
      } finally {
        this.$store.commit('setShowPreloader', false)
      }
    },
    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
    },
    setDatepickersValue(count, range, yearSelector) {
      const defaultRange = 'month'
      if (count === 2) {
        this.dateStart = moment()
          .add(defaultRange, -1)
          .format(Vue.config.format.serverDate)
        this.dateEnd = moment()
          .add('days', 1)
          .format(Vue.config.format.serverDate)
      }
      if (count === 1) {
        this.dateStart = moment().format(Vue.config.format.serverDate)
        this.dateEnd = ''
      }
      if (!count) {
        this.dateStart = ''
        this.dateEnd = ''
      }
      if (yearSelector) {
        this.selectedYear = moment().format('YYYY')
      }
    },
    showReportModal(item) {
      this.currentItem = item
      this.showModal = true
      this.datePickers = item.datePickers
      const countDatepickers = item.datePickers
      const rangeType = item.rangeType
      this.yearPicker.active = item.id === 11
      this.yearSelector = item.yearSelector
      this.setDatepickersValue(countDatepickers, rangeType, item.yearSelector)
      this.schoolsSelector = item.schoolChoice
      this.buffetProductTypes = item.buffetProductTypes
      this.storehouseChoice = item.storehouseChoice
      this.archiveSchoolsSelector = item.archiveSchoolChoice
      this.reportPrintTitle =
        item.name + (item.form_id ? ` (Форма ${item.form_id})` : '')
      if (this.archiveSchoolsSelector) {
        const [ year, month, day ] = this.dateStart.split('-')
        this.loadArchiveSchools(year, month, day)
      }
    },
    async handlerModal(value) {
      this.modalResponse = value
      if (this.modalResponse) {
        this.showPrintLoad = true
        await this.printFile()
      }
    },
    async printFile() {
      const { id = null, formId = null, schoolChoice = null, form2 = null, usual_request = null, storehouseChoice = null, supplier_pk_f = null } = this.currentItem
      let getTaskId = reportFileTaskId
      if (usual_request) {
        getTaskId = reportFileTaskId
      } else if (formId && !form2) {
        getTaskId = reportFormFileTaskId
      } else if (schoolChoice && !form2) {
        getTaskId = reportFileTaskIdSchool
      } else if (form2) {
        getTaskId = reportForm2
      }
      let PAYLOAD = {
        id,
        school_id: this.selectedSchoolID,
        data: {
          school_id: this.selectedSchoolID,
          start_date: this.dateStart,
          year: this.yearPicker.active && this.yearPicker.year || this.selectedYear || null,
          end_date: this.dateEnd,
          schoolClassIds: this.selectedClasses || null,
          school_pk: schoolChoice ? this.selectedSchoolID : null,
          storehouse_id: storehouseChoice ? this.storehouse : null,
          supplier_pk_f: supplier_pk_f ? this.currentUser.user_id : null,
          production_type: this.buffetProductTypes && this.productType && this.productType.id ? this.productType.id: null
        },
        report_id: id,
        form_id: formId
      }
      let cbStatus = reportFileStatus
      let cbPrintFIle = reportPrintFile

      if (id === 'report-balance') {
        PAYLOAD = {
          id: this.currentItem.id,
          report_id: 'report-balance',
          // data: {
          //   date: this.dateStart
          // }
        }
        getTaskId = getReportBalanceTaskId
        cbStatus = reportBalanceFileStatus
        cbPrintFIle = reportBalancePrintFile
      }

      const callback = this.resetPageFileLoaded
      try {
        await asyncReqestsForDownload(
          getTaskId,
          cbStatus,
          cbPrintFIle,
          PAYLOAD,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки файла') //pass!
      } finally {
        this.resetPageFileLoaded()
      }
    },
    changeDate(payload) {
      if (this.archiveSchoolsSelector) {
        const [ year, month, day ] = payload.value.split('-')
        this.loadArchiveSchools(year, month, day)
      }
    },
    async loadArchiveSchools(year, month, day) {
      try {
        const response = await getArchiveSchools(year, month, day)
        const {
          data: { data: items }
        } = response

        const itemsArray = items.map(item => {
          const { id, shortName, number } = item
          return {
            id: id,
            name: shortName,
            number: number
          }
        })
        this.archiveSchools = itemsArray
        this.selectedSchoolID = itemsArray.length ? itemsArray[0].id : null
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async loadStorehouses() {
      const payload = {
        params: {
          limit: Vue.config.general.schoolLimit,
          offset: 0
        }
      }
      try {
        const response = await getStorehouses(payload)
        const items = response.data.results

        const itemsArray = items.map(item => {
          const { id, name, uuid } = item
          return {
            id: id,
            name: name,
            uuid: uuid
          }
        })
        this.storehouses = [ ...this.storehouses, ...itemsArray ]
        this.storehouse = this.storehouses.length ? this.storehouses[0].id : null
      } catch (error) {
        console.error(error)
        // notify.error('Ошибка загрузки складов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async loadSchools() {
      const payload = {
        params: {
          limit: Vue.config.general.schoolLimit,
          offset: 0
        }
      }
      try {
        const response = await getSchools(payload)
        const {
          data: { data: items }
        } = response

        const itemsArray = items.map(item => {
          const { id, name, number } = item
          return {
            id: id,
            name: name,
            number: number
          }
        })
        this.schoolsArray = [ ...this.schoolsArray, ...itemsArray ]
        this.selectedSchoolID = itemsArray.length ? itemsArray[0].id : null
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async loadReports() {
      try {
        const response = await getAllReports()
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          let {
            id = null,
            name = '',
            formId = '',
            rangeType = '',
            rangeAmmount = null,
            datePickers = null,
            classChoice = null,
            schoolChoice = null,
            form2 = null,
            yearSelector = null,
            is_another_url = null,
            buffetProductTypes = null,
            storehouseChoice = null,
            usual_request = null,
            supplier_pk_f = null
          } = item
          let form2Archive = false
          let archiveSchoolChoice = false
          if (id == 2 && formId == 2) {
            schoolChoice = true
            form2 = true
          }
          if (formId == 999) {
            form2Archive = true
            formId = 2
            schoolChoice = true
            archiveSchoolChoice = true
          }
          return {
            id,
            name,
            formId,
            datePickers,
            rangeType,
            rangeAmmount,
            classChoice,
            schoolChoice,
            form2,
            yearSelector,
            is_another_url,
            buffetProductTypes,
            storehouseChoice,
            usual_request,
            supplier_pk_f,
            form2Archive,
            archiveSchoolChoice
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки отчётов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    save (date) {
      this.$refs.yearPickerMenu.save()
      this.yearPicker.year = new Date(date).getFullYear()
      this.$refs.yearPicker.activePicker = 'YEAR'
      this.yearPicker.menu = false
    }
  },
  watch: {
    'yearPicker.menu' (val) {
      val && this.$nextTick(() => (this.$refs.yearPicker.activePicker = 'YEAR'))
    }
  }
}
</script>
