<script>
import Vue from 'vue'
import successNotification from "@/utils/notifications/successNotification"
import infoNotification from "@/utils/notifications/infoNotification"
import errorNotification from "@/utils/notifications/errorNotification"
import errorWithUuidNotification
  from "@/utils/notifications/errorWithUuidNotification"

export default {
  commonOptions: {
          position: "top-right",
          timeout: 5000,
          closeOnClick: false,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        },
    error(message) {
      const localOptions = {
            ...this.commonOptions,
      }
      const content = {
        component: errorNotification,
        props: {
          message: message
        }
      }
      Vue.$toast.error(content, localOptions)
    },
    success(message) {
      const localOptions = {
            ...this.commonOptions,
      }
      const content = {
        component: successNotification,
        props: {
          message: message
        }
      }
      Vue.$toast.success(content, localOptions)
    },
    info(message) {
      const localOptions = {
            ...this.commonOptions,
      }
      const content = {
        component: infoNotification,
        props: {
          message: message
        }
      }
      Vue.$toast.info(content, localOptions)
    },
    errorWithUuid(message, uuid) {
      const localOptions = {
            ...this.commonOptions,
            toastClassName: "my-custom-toast-class",
            // bodyClassName: ["custom-class-1", "custom-class-2"],
            icon: false,
            closeButton: false,
            timeout: 10000,
      }
      const content = {
        component: errorWithUuidNotification,
        props: {
          message: message,
          uuid: uuid
        }
      }
      Vue.$toast.error(content, localOptions)
    },
}
</script>

<style>
/* When setting CSS, remember that priority increases with specificity, so don't forget to select the exisiting classes as well */

    .Vue-Toastification__toast.my-custom-toast-class {
      position: relative;
      padding: 0px !important;
      overflow: visible;
      transition: .5s;
      transition-delay: .2s;
    }

    /* Applied to the toast body when using regular strings as content */
    .Vue-Toastification__toast-body.custom-class-1 {
        /*font-size: 1em;*/
    }

    /* Applied to a wrapper div when using a custom component as content */
    .Vue-Toastification__toast-component-body.custom-class-2 {
        /*width: 100%;*/
    }
</style>