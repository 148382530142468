import Vue from 'vue'
import axios from 'axios'

export const cancelOrdersByFilters = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.cancelOrdersByFilters,
    payload
  )

export const getOfert = (id) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.schoolOferta.replace('$schoolID', id)
  )

export const getMealTypesBySchool = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodTypes.all.replace(
        '$schoolId',
        payload.schoolID
      )
  )

export const changeMealType = (PAYLOAD) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.changeMealType.replace(
        '$schoolID',
        PAYLOAD.id
      ),
    PAYLOAD.payload
  )

export const getWorkingDays = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.workingDays.replace('$schoolID', payload.id)
  )

export const updateWorkingDays = (PAYLOAD) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.workingDays.replace('$schoolID', PAYLOAD.id),
    PAYLOAD.payload
  )

export const cancelWorkingDays = (PAYLOAD) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.workingDays.replace(
        '$schoolID',
        PAYLOAD.schoolID
      ),
    PAYLOAD.payload
  )

export const getUpdatesNews = (id) =>
  axios.get(Vue.config.server + '/' + Vue.config.api.administrator.updates)

export const getFoodblockGeneral = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.general.one.replace(
        '$form_id',
        payload.id
      )
  )

export const getOrderFoodTypes = (payload) => {
  let url =
    Vue.config.server +
    '/' +
    Vue.config.api.administrator.students.order_meal_types
      .replace('$schoolId', payload.schoolId)
      .replace('$id', payload.id)
  if (payload.orderId) {
    url += '?order_id=' + payload.orderId
  }
  return axios.get(url)
}

export const getAdminDashboardOrderHistogram = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.order.replace(
        '$school_pk',
        payload.school_id
      )
  )

export const getAdminDashboardFoodCoverage = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.coverage.replace(
        '$school_pk',
        payload.school_id
      )
  )

export const getAdminDashboardOrderStatuses = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.orderStatus.replace(
        '$school_pk',
        payload.school_id
      )
  )

export const getAdminDashboardPupilNegativeBalance = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.pupilNegativeBalance.replace(
        '$school_pk',
        payload.school_id
      ),
    { params: { search: payload.search, filter_class: payload.filter_class } }
  )

export const patchAdminDashboardPupilNegativeBalance = (payload) =>
  axios.patch(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.pupilNegativeBalance.replace(
        '$school_pk',
        payload.school_id
      ),
    payload.data
  )

export const getAdminDashboardPupilExpiredPrivilege = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.pupilExpiredPrivilege.replace(
        '$school_pk',
        payload.school_id
      ),
    { params: { search: payload.search, filter_class: payload.filter_class } }
  )

export const patchAdminDashboardPupilExpiredPrivilege = (payload) =>
  axios.patch(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.pupilExpiredPrivilege.replace(
        '$school_pk',
        payload.school_id
      ),
    payload
  )

export const getAdminDashboardPupilWithoutFoodtypes = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.pupilWithoutFoodtypes.replace(
        '$school_pk',
        payload.school_id
      ),
    { params: { search: payload.search, filter_class: payload.filter_class } }
  )

export const patchAdminDashboardPupilWithoutFoodtypes = (payload) =>
  axios.patch(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.pupilWithoutFoodtypes.replace(
        '$school_pk',
        payload.school_id
      ),
    payload
  )

export const getAdminDashboardPupilAllPrivilegesAndFoodtypes = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.pupilAllPrivilegesAndFoodtypes.replace(
        '$school_pk',
        payload.school_id
      )
  )

export const getAdminDashboardOrdersYesterdayUncompleted = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.ordersYesterdayUncompleted.replace(
        '$school_pk',
        payload.school_id
      ),
    { params: { search: payload.search, filter_class: payload.filter_class } }
  )

export const patchAdminDashboardOrdersYesterdayUncompleted = (payload) =>
  axios.patch(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.ordersYesterdayUncompleted.replace(
        '$school_pk',
        payload.school_id
      ),
    payload
  )

export const getAdminDashboardOrdersOverCredit = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.ordersOverCredit.replace(
        '$school_pk',
        payload.school_id
      ),
    { params: { search: payload.search, filter_class: payload.filter_class } }
  )

export const patchAdminDashboardPupilOverCredit = (payload) =>
  axios.patch(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.ordersOverCredit.replace(
        '$school_pk',
        payload.school_id
      ),
    payload.data
  )

export const getAdminDashboardMealPeriodCount = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.mealPeriodCount.replace(
        '$school_pk',
        payload.school_id
      )
  )

export const getAdminDashboardModalAllClasses = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dashboard.allClasses.replace(
        '$school_pk',
        payload.school_id
      )
  )

export const getAdminSampleLoadingOrders = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.loading_orders.sample.replace(
        '$school_pk',
        payload.school_id
      ),
    { responseType: 'blob', preloaderState: false }
  )

export const getAdminSampleExampleLoadingOrders = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.loading_orders.sample_example.replace(
        '$school_pk',
        payload.school_id
      ),
    { responseType: 'blob', preloaderState: false }
  )

export const getOrdersUploadedFile = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.loading_orders.uploaded_file.replace(
        '$school_pk',
        payload.school_id
      ),
    { params: payload, responseType: 'blob', preloaderState: false }
  )

export const getLoadingOrdersList = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.loading_orders.list.replace(
        '$school_pk',
        payload.school_id
      ),
    { params: payload }
  )

export const getLoadingOrdersDetail = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.loading_orders.detail
        .replace('$school_pk', payload.school_id)
        .replace('$pk', payload.id),
    payload
  )

export const deleteLoadingOrders = (payload) =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.loading_orders.delete
        .replace('$school_pk', payload.school_id)
        .replace('$pk', payload.id),
    payload
  )

export const uploadLoadingOrdersFile = (payload, config) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.loading_orders.upload.replace(
        '$school_pk',
        payload.get('school_id')
      ),
    payload,
    config
  )

export const getUploadLoadingOrdersFileStatus = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.loading_orders.uploadStatus.replace(
        '$id',
        payload.task_id
      ),
    { preloaderState: false }
  )

export const getUploadLoadingOrdersFileStatusResult = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.loading_orders.uploadResult.replace(
        '$id',
        payload.task_id
      ),
    { preloaderState: false }
  )

export const getAdminUserContacts = () =>
  axios.get(Vue.config.server + '/' + Vue.config.api.administrator.contacts)

export const setAdminUserContacts = (payload) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.administrator.contacts,
    payload
  )

export const getBuffetProductDict = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.productDict.all,
    payload
  )

export const getAllBuffetApplication = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.applications.all,
    payload
  )

export const deleteBuffetApplication = (id) =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.applications.one.replace('$id', id)
  )

export const createBuffetApplication = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.applications.all,
    payload
  )

export const getBuffetApplication = (id) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.applications.one.replace('$id', id)
  )

export const updateBuffetApplication = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.applications.one.replace(
        '$id',
        payload.id
      ),
    payload.data
  )

export const createBuffetApplicationsPositions = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.applications_position.all,
    payload
  )

export const getOneBuffetApplicationsPositions = (id) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.applications_position.one.replace(
        '$id',
        id
      )
  )

export const deleteBuffetApplicationsPositions = (id) =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.applications_position.one.replace(
        '$id',
        id
      )
  )

export const updateBuffetApplicationsPositions = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.applications_position.one.replace(
        '$id',
        payload.id
      ),
    payload.data
  )

export const updateFoodBlockGeneral = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.general.one.replace(
        '$form_id',
        payload.id
      ),
    payload.data
  )

export const getStudentExtPrivilege = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.students_privileges.one.replace(
        '$student_id',
        payload.id
      ),
    payload.data
  )

export const updateStudentExtPrivilege = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.students_privileges.one.replace(
        '$student_id',
        payload.id
      ),
    payload.data
  )

// Organizations
export const getFoodblockOrganizations = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.organizations.one.replace(
        '$form_id',
        payload.id
      )
  )

export const updateFoodBlockOrganizations = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.organizations.one.replace(
        '$form_id',
        payload.id
      ),
    payload.data
  )

// Documents
export const getFoodblockDocuments = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.documents.one.replace(
        '$form_id',
        payload.id
      )
  )

export const updateFoodBlockDocuments = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.documents.one.replace(
        '$form_id',
        payload.id
      ),
    payload.data
  )

// Staff
export const getFoodblockSchedule = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.schedule.one.replace(
        '$form_id',
        payload.id
      )
  )

export const updateFoodBlockSchedule = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.schedule.one.replace(
        '$form_id',
        payload.id
      ),
    payload.data
  )

// Staff
export const getFoodblockStaff = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.staff.one.replace(
        '$form_id',
        payload.id
      )
  )

export const updateFoodBlockStaff = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.staff.one.replace(
        '$form_id',
        payload.id
      ),
    payload.data
  )

// Householdrooms
export const getFoodblockHousehold = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.household.one.replace(
        '$form_id',
        payload.id
      )
  )

export const updateFoodBlockHousehold = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.household.one.replace(
        '$form_id',
        payload.id
      ),
    payload.data
  )

// warehouses
export const getFoodblockWarehouses = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.warehouses.one.replace(
        '$form_id',
        payload.id
      )
  )

export const updateFoodBlockWarehouses = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.warehouses.one.replace(
        '$form_id',
        payload.id
      ),
    payload.data
  )

// rooms
export const getFoodBlockRooms = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.rooms.one.replace(
        '$form_id',
        payload.id
      )
  )

export const updateFoodBlockRooms = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.rooms.one.replace(
        '$form_id',
        payload.id
      ),
    payload.data
  )

// transport
export const getFoodBlockTransport = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.transport.one.replace(
        '$form_id',
        payload.form_id
      )
  )

export const getAllExceptionInfo = (payload) =>
  axios.get(Vue.config.server + '/' + Vue.config.api.exceptionInfo.all)

export const updateFoodBlockTransport = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.transport.one.replace(
        '$form_id',
        payload.id
      ),
    payload.data
  )

// engineer
export const getFoodBlockEngineer = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.engineer.one.replace(
        '$form_id',
        payload.form_id
      )
  )

export const updateFoodBlockEngineer = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.engineer.one.replace(
        '$form_id',
        payload.id
      ),
    payload.data
  )

// types
export const getFoodBlockTypes = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.types.one.replace(
        '$form_id',
        payload.form_id
      )
  )

export const updateFoodBlockTypes = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.types.one.replace(
        '$form_id',
        payload.id
      ),
    payload.data
  )

export const getFoodBlockPassports = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodblock.passport.all.replace(
        '$school_pk',
        payload.school_pk
      )
  )

export const getRoles = (payload) =>
  axios.get(Vue.config.server + '/' + Vue.config.api.administrator.roles.all)
export const updateUserRole = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.users_role.all.replace(
        '$user_id',
        payload.user_id
      ),
    payload.data
  )
export const getAllUsers = (payload) =>
  axios.get(Vue.config.server + '/' + Vue.config.api.administrator.users.all, {
    params: payload.params
  })
export const getOneUser = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.users.one.replace(
        '$user_id',
        payload.user_id
      ),
    { params: payload.params }
  )
export const getStorehousesProducts = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.storehouse_products.all,
    { params: payload.params }
  )

export const moveProductFromApplications = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.move_product.all,
    payload
  )

export const getSyrveys = (payload) =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.administrator.syrvey.all,
    payload
  )

export const getSyrveyDetail = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.syrvey.detail.replace(
        '$syrvey_id',
        payload.syrvey_id
      ),
    payload
  )

export const getAllDocuments = (payload) =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.administrator.documents.all,
    payload
  )

export const deleteDocument = (payload) =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.documents.one.replace('$id', payload.id),
    payload
  )

export const createDocument = (payload) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.administrator.documents.all,
    payload
  )

export const updateDocument = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.documents.one.replace('$id', payload.id),
    payload.formData
  )

export const getDocumentDetail = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.documents.one.replace('$id', payload.id),
    payload
  )

export const saveSyrveyDetail = (payload) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.administrator.syrvey.result_save,
    payload
  )

export const createQuestion = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.syrvey.question.create,
    payload
  )

export const getQuestion = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.syrvey.question.detail.replace(
        '$question_id',
        payload.question_id
      ),
    payload
  )

export const deleteSyrvey = (payload) =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.syrvey.detail.replace(
        '$syrvey_id',
        payload.syrvey_id
      ),
    payload
  )

export const getAllVariants = (payload) =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.administrator.syrvey.variants.all,
    payload
  )

export const createVariant = (payload) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.administrator.syrvey.variants.all,
    payload
  )

export const getPolls = (payload) =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.administrator.syrvey.results.all,
    payload
  )

export const deleteSyrveyResult = (payload) =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.syrvey.results.one.replace(
        '$id',
        payload.id
      ),
    payload
  )

export const getPollDetail = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.syrvey.results.one.replace(
        '$id',
        payload.id
      ),
    payload
  )

export const getSchools = (payload) =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.administrator.schools.all,
    payload
  )

export const updateQuestion = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.syrvey.question.update.replace(
        '$question_id',
        payload.question_id
      ),
    payload
  )

export const deleteQuestion = (payload) =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.syrvey.question.delete.replace(
        '$question_id',
        payload.question_id
      ),
    payload
  )

export const createSyrvey = (payload) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.administrator.syrvey.all,
    payload
  )

export const updateSyrvey = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.syrvey.detail.replace(
        '$syrvey_id',
        payload.id
      ),
    payload
  )

export const getStorehousesOneProduct = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.storehouse_products.one.replace(
        '$product_id',
        payload.product_id
      ),
    { params: payload.params }
  )
export const createStorehouseProduct = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.storehouse_products.one.replace(
        '$product_id/',
        ''
      ),
    payload.data
  )
export const updateStorehouseProduct = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.storehouse_products.one.replace(
        '$product_id',
        payload.product_id
      ),
    payload.data
  )
export const deleteStorehouseProduct = (payload) =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.storehouse_products.one.replace(
        '$product_id',
        payload.product_id
      )
  )

export const getStorehouses = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.storehouses.all,
    { params: payload.params }
  )

export const getStorehouseCard = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.storehouses.one.replace(
        '$storehouse_id',
        payload.card_id
      ),
    { params: payload.params }
  )

export const updateStorehouseCard = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.storehouses.one.replace(
        '$storehouse_id',
        payload.card_id
      ),
    payload.data
  )

export const createStorehouseCard = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.storehouses.one.replace(
        '$storehouse_id/',
        ''
      ),

    payload.data
  )
export const deleteStorehouseCard = (payload) =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.storehouses.one.replace(
        '$storehouse_id',
        payload.storehouse_id
      )
  )

export const getCards = (payload) =>
  axios.get(Vue.config.server + '/' + Vue.config.api.common.cards.all, {
    params: payload.params
  })

export const getCard = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.common.cards.one.replace('$card_id', payload.card_id),
    { params: payload.params }
  )

export const updateCard = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.common.cards.one.replace('$card_id', payload.card_id),
    payload.data
  )

export const createCard = (payload) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.common.cards.index,
    payload.data
  )

/**
 * Получение истории баланса карты буфета ученика
 * @param {Object} payload - Объект с параметрами запроса
 * @param {number | string} payload.pupil_id - Идентификатор ученика
 * @param {number | string} payload.school_id - Идентификатор школы
 * @param {Object} [payload.params] - Дополнительные параметры запроса
 * @param {string} [payload.params.min_date] - Фильтрация с указанной даты (формат: ДД-ММ-ГГГГ)
 * @param {string} [payload.params.max_date] - Фильтрация по указанную дату (формат: ДД-ММ-ГГГГ)
 * @param {boolean} [payload.params.is_bank_transactions] - Фильтрация по наличию банковской транзакции (true/false)
 * @param {string} [payload.params.ordering] - Сортировка записей. `create_date` для сортировки от старой записи к новой, `-create_date` для сортировки от новой записи к старой
 * @returns {Promise} Результат запроса к API
 */
export const getCardBalanceHistories = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.balance_histories.for_pupil
        .replace('$pupil_id', payload.pupil_id)
        .replace('$school_id', payload.school_id),
    { params: payload.params }
  )

/**
 * Создание транзакции в истории баланса карты буфета ученика
 *
 * @param {Object} payload - Объект с параметрами запроса
 * @param {number|string} payload.pupil_id - Идентификатор ученика
 * @param {number|string} payload.school_id - Идентификатор школы
 * @param {Object} payload.data - Данные для отправки в запросе
 * @param {number} payload.data.amount - Сумма транзакции
 * @returns {Promise} Результат запроса к API
 */
export const postTransactionBalanceHistories = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.balance_histories.for_pupil
        .replace('$pupil_id', payload.pupil_id)
        .replace('$school_id', payload.school_id),
    payload.data
  )

export const getStudentBlackList = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.blacklist.pupil.replace(
        '$pupil_pk',
        `?pupil_pk=${payload.pupil_pk}`
      ),
    payload.data
  )

export const createBlackList = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.blacklist.create,
    payload.data
  )

export const updateBlackList = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.blacklist.update.replace(
        '$blacklist_id',
        `${payload.blacklist_id}`
      ),
    payload.data
  )

export const getBlackListDetail = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.buffet.blacklist.detail.replace(
        '$blacklist_id',
        `${payload.blacklist_id}`
      ),
    payload.data
  )

export const getTeachers = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.teachers.all.replace(
        '$schoolId',
        payload.schoolId
      ),
    { params: payload.params }
  )

export const getOneTeacher = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.teachers.one
        .replace('$schoolId', payload.schoolId)
        .replace('$id', payload.id)
  )

export const assignCreditToTeacher = (payload) =>
  axios.patch(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.teachers.one
        .replace('$schoolId', payload.schoolId)
        .replace('$id', payload.id),
    payload.params
  )

/**
 * Функция для получения списка учеников по школе
 * @param {Object} payload - Объект с параметрами для запроса
 * @param {number} payload.school_id - ID школы
 * @param {Object} [payload.params] - Дополнительные параметры запроса (например, фамилия или ID класса)
 * @param {string} [payload.params.last_name] - Фамилия ученика для фильтрации
 * @param {number} [payload.params.school_class_id] - ID класса для фильтрации
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const getPupilsBySchool = (payload) =>
  axios.get(
    `${Vue.config.server}/${Vue.config.api.school.pupil_list(
      payload.school_id
    )}`,
    { params: payload.params }
  )

/**
 * Функция для получения списка родителей по школе
 * @param {Object} payload - Объект с параметрами для запроса
 * @param {number} payload.school_id - ID школы
 * @param {Object} [payload.params] - Дополнительные параметры запроса (например, фамилия или ID класса)
 * @param {string} [payload.params.last_name] - Фамилия родителя для фильтрации
 * @param {number} [payload.params.school_class_id] - ID класса для фильтрации
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const getParentsBySchool = (payload) =>
  axios.get(
    `${Vue.config.server}/${Vue.config.api.school.parent_list(
      payload.school_id
    )}`,
    { params: payload.params }
  )

/**
 * Функция для получения списка школьных домов
 * @param {Object} [payload] - Объект с параметрами для запроса (необязательный)
 * @param {number} [payload.school_id] - ID школы (необязательный)
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const getSchoolHouse = (payload = {}) =>
  axios.get(
    `${Vue.config.server}/${Vue.config.api.school.school_house}`,
    { params: payload } // Передаем объект payload напрямую, чтобы учесть отсутствие параметров
  )

export const getFilteredStudents = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.students.filtered
        .replace('$schoolId', payload.schoolId)
        .replace('$classId', payload.classId),
    { params: payload.params }
  )

export const getOneStudent = (payload) => {
  let url =
    Vue.config.server +
    '/' +
    Vue.config.api.administrator.students.one
      .replace('$schoolId', payload.schoolId)
      .replace('$id', payload.id)
  if (payload.orderId) {
    url += '?order_id=' + payload.orderId
  }
  return axios.get(url)
}

export const deleteMealTypeOneStudent = (payload) =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.students.one
        .replace('$schoolId', payload.schoolId)
        .replace('$id', payload.id)
  )

export const updateOneStudent = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.students.one
        .replace('$schoolId', payload.schoolId)
        .replace('$id', payload.id),
    payload.data
  )

export const getPrivileges = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.students.privileges.replace(
        '$schoolId',
        payload.schoolId
      ),
    payload.data
  )

export const updateStudentPrivilege = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.students.privilege
        .replace('$schoolId', payload.schoolId)
        .replace('$id', payload.id),
    payload.data
  )

export const updateStudentBalance = (payload) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.administrator.balance.start,
    payload
  )

export const getClasses = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.classes.all.replace(
        '$schoolId',
        payload.schoolId
      ),
    { params: payload.params }
  )

export const getOneClass = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.classes.one
        .replace('$schoolId', payload.schoolId)
        .replace('$id', payload.id)
  )

export const setFoodTime = (payload) =>
  axios.patch(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.classes.one
        .replace('$schoolId', payload.schoolId)
        .replace('$id', payload.id),
    {
      shift: payload.shift,
      eat: payload.eat,
      delete_old_eat: payload.delete_old_eat
    }
  )

export const getDishes = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dishes.all.replace(
        '$schoolId',
        payload.schoolId
      ),
    { params: payload.params }
  )

export const getOneDish = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.dishes.one
        .replace('$schoolId', payload.schoolId)
        .replace('$id', payload.id)
  )

export const getComplexes = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.complexes.all.replace(
        '$schoolId',
        payload.schoolId
      ),
    { params: payload.params }
  )

export const getOneComplex = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.complexes.one
        .replace('$schoolId', payload.schoolId)
        .replace('$id', payload.id)
  )

export const getOrganizers = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.organizers.all.replace(
        '$schoolId',
        payload.schoolId
      )
  )

export const getOneOrganizer = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.organizers.one
        .replace('$schoolId', payload.schoolId)
        .replace('$id', payload.id)
  )

export const getFoodTypes = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodTypes.all.replace(
        '$schoolId',
        payload.schoolId
      ),
    { params: payload.params }
  )

export const getOneFoodType = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.foodTypes.one
        .replace('$schoolId', payload.schoolId)
        .replace('$id', payload.id)
  )

/**
 * Функция для получения всех классов для таблицы заказов для конкретной школы и даты
 * @param {Object} payload - Объект с параметрами для запроса заказов
 * @param {number} payload.id - ID школы
 * @param {string} payload.date - Дата в формате 'YYYY-MM-DD' для получения заказов
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const getOrders = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.all(payload.id, payload.date)
  )

/**
 * Функция для получения всех классов для таблицы заказов для конкретного корпуса школы и даты
 * @param {Object} payload - Объект с параметрами для запроса заказов
 * @param {number} payload.id - ID Школы
 * @param {number} payload.houseId - ID школы
 * @param {string} payload.date - Дата в формате 'YYYY-MM-DD' для получения заказов
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const getOrdersByHouse = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.byHouse(
        payload.id,
        payload.houseId,
        payload.date
      )
  )

/**
 * Функция для отмены заказов на сервере
 * @param {Object} payload - Объект с данными для отмены заказов
 * @param {number} payload.schoolId - ID школы
 * @param {string} payload.date - Дата, на которую необходимо отменить заказы
 * @param {Array<number>} payload.classIds - Массив ID классов, для которых нужно отменить заказы
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const cancelOrders = (payload) =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.cancel(
        payload.schoolId,
        payload.date
      ),
    { data: { classIds: payload.classIds } }
  )

export const getOneOrder = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.one(payload.classId, payload.date)
  )

export const setDefaultMealType = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.dotation
        .replace('$schoolId', payload.schoolId)
        .replace('$classId', payload.classId)
        .replace('$date', payload.date)
  )

export const cancelDefaultMealType = (payload) =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.dotation
        .replace('$schoolId', payload.schoolId)
        .replace('$classId', payload.classId)
        .replace('$date', payload.date)
  )

/**
 * Функция для отмены статуса заказа
 * @param {Object} payload - Объект с параметрами для запроса
 * @param {number} payload.schoolId - ID класса
 * @param {number} payload.classId - ID класса
 * @param {string} payload.date - Дата в формате 'YYYY-MM-DD'
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const cancelStatus = (payload) =>
  axios.put(
    `${Vue.config.server}/${Vue.config.api.administrator.orders.cancelStatus(
      payload.schoolId,
      payload.classId,
      payload.date
    )}`
  )

/**
 * Функция для изменения статуса заказов 'Выдано' для подтвержденных заявок,
 * либо в 'Выполнены' для заявок 'Выполнено (без списания)'
 * @param {Object} payload - Объект с данными для изменения статуса заказов
 * @param {number} payload.schoolId - ID школы
 * @param {string} payload.date - Дата, для которой нужно изменить статус заказов
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const getChangedOrders = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.getChangedOrders(
        payload.schoolId,
        payload.date
      )
  )

export const changeOrdersStatus = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.changeOrdersStatus,
    payload
  )

/**
 * Функция для установки статуса подтверждения заказов на сервере
 * @param {Object} payload - Объект с данными для установки статуса подтверждения
 * @param {number} payload.schoolId - ID школы
 * @param {string} payload.date - Дата, на которую нужно установить статус подтверждения заказов
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const setConfirmedStatus = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.setConfirmedStatus(
        payload.schoolId,
        payload.date
      )
  )

/**
 * Функция для установки статуса отмены заказов на сервере
 * @param {Object} payload - Объект с данными для установки статуса отмены
 * @param {number} payload.schoolId - ID школы
 * @param {string} payload.date - Дата, на которую нужно установить статус отмены заказов
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const setCancelOrderStatus = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.cancelOrderStatusBySchool(
        payload.schoolId,
        payload.date
      )
  )

/**
 * Функция для обновления одного заказа (если дети поели)
 * @param {Object} payload - Объект с параметрами для обновления заказа
 * @param {number} payload.id - ID класса
 * @param {string} payload.date - Дата в формате 'YYYY-MM-DD'
 * @param {Object} payload.data - Данные для обновления заказа
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const updateOneOrder = (payload) =>
  axios.put(
    `${Vue.config.server}/${Vue.config.api.administrator.orders.one(
      payload.id,
      payload.date
    )}`,
    payload.data
  )
/**
 * Функция для предварительного обновления одного заказа (если дети будут есть)
 * @param {Object} payload - Объект с параметрами для предварительного обновления заказа
 * @param {number} payload.id - ID класса
 * @param {string} payload.date - Дата в формате 'YYYY-MM-DD'
 * @param {Object} payload.data - Данные для обновления заказа
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const preUpdateOneOrder = (payload) =>
  axios.patch(
    `${Vue.config.server}/${Vue.config.api.administrator.orders.one(
      payload.id,
      payload.date
    )}`,
    payload.data
  )

/**
 * Функция для подтверждения заказа
 * @param {Object} payload - Объект с параметрами для подтверждения заказа
 * @param {number} payload.id - ID класса
 * @param {string} payload.date - Дата в формате 'YYYY-MM-DD'
 * @param {Object} payload.data - Данные для подтверждения заказа
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const confirmOrder = (payload) =>
  axios.patch(
    `${Vue.config.server}/${Vue.config.api.administrator.orders.confirm(
      payload.id,
      payload.date
    )}`,
    payload.data
  )

export const printOrder = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.download.replace(
        '$schoolId',
        payload.schoolId
      ),
    payload,
    { responseType: 'blob' }
  )

export const getPrintOrderTaskId = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.application_preliminary_actual.all.replace(
        '$schoolId',
        payload.school_id
      ),
    payload,
    { preloaderState: false }
  )

export const getPrintOrderStatus = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.application_preliminary_actual.status
        .replace('$schoolId', payload.school_id)
        .replace('$id', payload.id),
    { preloaderState: false }
  )

export const getPrintOrderFile = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.application_preliminary_actual.download
        .replace('$schoolId', payload.school_id)
        .replace('$id', payload.id),
    { responseType: 'blob', preloaderState: false }
  )

export const getConcessions = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.concessions.all.replace(
        '$schoolId',
        payload.id
      ),
    payload.data
  )

export const printInvoice = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.invoice.create.replace(
        '$schoolId',
        payload.id
      ),
    { params: payload.params, responseType: 'blob' }
  )

export const printInvoiceFromDateToDate = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.invoice.period.replace(
        '$schoolId',
        payload.school_id
      ),
    payload.data,
    { responseType: 'arraybuffer' }
  )

export const getInvoicesTaskId = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.invoices.all.replace(
        '$schoolId',
        payload.school_id
      ),
    payload.data,
    { preloaderState: false }
  )

export const getInvoicesFileStatus = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.invoices.status
        .replace('$schoolId', payload.school_id)
        .replace('$id', payload.id),
    { preloaderState: false }
  )

export const getInvoicesFile = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.invoices.download
        .replace('$schoolId', payload.school_id)
        .replace('$id', payload.id),
    { responseType: 'blob', preloaderState: false }
  )

export const getBuffetPayments = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.balance.buffet.replace(
        '$schoolId',
        payload.schoolId
      ),
    payload
  )

export const getPayments = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.balance.get.replace(
        '$schoolId',
        payload.schoolId
      ),
    payload
  )
// PRINT file
export const printFileTaskId = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.balance.printMoving
        .replace('$schoolId', payload.school_id)
        .replace('$id', payload.id),
    payload.data,
    { preloaderState: false }
  )

export const getPrintFileStatus = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.balance.printMovingProgress
        .replace('$schoolId', payload.school_id)
        .replace('$id', payload.id),
    { preloaderState: false }
  )

export const getPrintFile = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.balance.printMovingFile
        .replace('$schoolId', payload.school_id)
        .replace('$id', payload.id),
    { responseType: 'blob', preloaderState: false }
  )
// agreement
export const studentOffer = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.agreement.one
        .replace('$schoolId', payload.school_id)
        .replace('$id', payload.id)
  )

// reports
// PRINT REPORT file
export const getAllReports = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.reports.all.replace(
        '$school_id',
        payload.school_id
      )
  )
export const reportFileTaskId = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.reports.one
        .replace('$school_id', payload.school_id)
        .replace('$id', payload.id),
    payload.data,
    { preloaderState: false }
  )

export const reportFormFileTaskId = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.reports.form
        .replace('$school_id', payload.school_id)
        .replace('$id', payload.id)
        .replace('$form_id', payload.form_id),
    payload.data,
    { preloaderState: false }
  )

export const reportFileStatus = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.reports.status
        .replace('$school_id', payload.school_id)
        .replace('$id', payload.id)
        .replace('$task_id', payload.task_id),
    { preloaderState: false }
  )

export const reportPrintFile = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.reports.download
        .replace('$school_id', payload.school_id)
        .replace('$id', payload.id)
        .replace('$task_id', payload.task_id),
    { responseType: 'blob', preloaderState: false }
  )
// settings
export const getSettings = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.settings.all.replace(
        '$school_id',
        payload.school_id
      )
  )
export const updateSettings = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.settings.all.replace(
        '$school_id',
        payload.school_id
      ),
    payload.data
  )
export const pathSettings = (payload) =>
  axios.patch(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.settings.all.replace(
        '$school_id',
        payload.school_id
      ),
    payload.data
  )
//
// Notifications
export const getNotifications = () =>
  axios.get(
    Vue.config.server + '/' + Vue.config.api.administrator.notifications.all
  )

export const setNotification = (payload) =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.administrator.notifications.all,
    payload.data
  )

export const setNotificationEveryone = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.notifications.everyone,
    payload.data
  )

export const updateNotification = (payload) =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.notifications.update.replace(
        '$id',
        payload.id
      ),
    payload.data
  )

/**
 * Функция для получения таблицы явка по ЭЖ в классе
 * @param {Object} payload - Объект с данными для запроса ID задачи
 * @param {number} payload.schoolId - ID школы
 * @param {number} payload.classId - ID класса
 * @param {string} payload.date - Дата, на которую необходимо получить явки
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const checkTable = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.table.check(
        payload.schoolId,
        payload.classId,
        payload.date
      )
  )

/**
 * Функция для получения ID задачи по управлению трафиком для указанной школы и даты
 * @param {Object} payload - Объект с данными для запроса ID задачи
 * @param {number} payload.school_id - ID школы
 * @param {string} payload.date - Дата, на которую необходимо получить ID задачи
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const getTaskId = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.traffic.task(
        payload.school_id,
        payload.date
      ),
    { preloaderState: false }
  )

export const getTaskStatus = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.traffic.taskStatus.replace(
        '$id',
        payload.task_id
      ),
    { preloaderState: false }
  )

export const getTaskResults = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.traffic.taskResult.replace(
        '$id',
        payload.task_id
      ),
    { preloaderState: false }
  )
//
//

// PRINT ADDITIONAL REPORT file
export const reportAdditional = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.additional.one.replace(
        '$school_id',
        payload.school_id
      ),
    payload.data,
    { preloaderState: false }
  )

export const reportAdditionalStatus = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.additional.status
        .replace('$school_id', payload.school_id)
        .replace('$task_id', payload.task_id),
    { preloaderState: false }
  )

export const reportAdditionalDownload = (payload) =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.additional.download
        .replace('$school_id', payload.school_id)
        .replace('$task_id', payload.task_id),
    { responseType: 'blob', preloaderState: false }
  )

/**
 * Функция для переноса трафика учеников для определённого класса
 * @param {Object} payload - Объект с параметрами для запроса
 * @param {number} payload.schoolId - ID школы
 * @param {number} payload.classId - ID класса
 * @param {Object} payload.data - Данные, которые нужно отправить с запросом
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const transferTrafficClass = (payload) =>
  axios.post(
    `${
      Vue.config.server
    }/${Vue.config.api.administrator.orders.transferTrafficClass(
      payload.schoolId,
      payload.classId
    )}`,
    payload.data
  )

/**
 * Функция для переноса трафика всей школы
 * @param {Object} payload - Объект с данными для переноса трафика
 * @param {number} payload.schoolId - ID школы
 * @param {string} payload.date - Дата, на которую нужно перенести трафик
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const transferTrafficSchool = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.transferTrafficSchool(
        payload.schoolId,
        payload.date
      )
  )

/**
 * * Функция для начисления денег за посещенные и не посещенные заказы
 * @param {Object} payload - Объект с данными для начисления денег
 * @param {Array<number>} payload.visitedIds - Массив ID заказов, которые были посещены
 * @param {Array<number>} payload.notVisitedIds - Массив ID заказов, которые не были посещены
 * @param {number} payload.classId - ID класса, для которого начисляются деньги
 * @param {number} payload.schoolId - ID школы
 * @param {string} payload.date - Дата, за которую производится начисление
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const chargeMoney = (payload) => {
  const data = {
    ordersVisited: payload.visitedIds,
    ordersNotVisited: payload.notVisitedIds,
    classId: payload.classId
  }

  return axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.chargeMoney(
        payload.schoolId,
        payload.date
      ),
    data
  )
}

export const setFoodType = (orderId, mealTypeId) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.changeMealType
        .replace('$order_id', orderId)
        .replace('$meal_type_id', mealTypeId)
  )

/**
 * Функция для установки типа сухого пайка для определённых классов
 * @param {number} schoolId - ID школы
 * @param {string} date - Дата в формате 'YYYY-MM-DD', на которую нужно установить тип сухого пайка
 * @param {Array<number>} classIds - Массив ID классов, для которых устанавливается тип сухого пайка
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const setDryFoodType = (schoolId, date, classIds) =>
  axios.post(
    `${Vue.config.server}/${Vue.config.api.administrator.orders.setDryFood(
      schoolId,
      date
    )}`,
    { classIds }
  )

/**
 * Функция для отмены типа сухого пайка для определённых классов
 * @param {number} schoolId - ID школы
 * @param {string} date - Дата в формате 'YYYY-MM-DD', на которую нужно отменить тип сухого пайка
 * @param {Array<number>} classIds - Массив ID классов, для которых отменяется тип сухого пайка
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const cancelDryFoodType = (schoolId, date, classIds) =>
  axios.delete(
    `${Vue.config.server}/${Vue.config.api.administrator.orders.setDryFood(
      schoolId,
      date
    )}`,
    {
      data: { classIds }
    }
  )

/**
 * Функция для установки субсидируемого типа питания для определённых классов
 * @param {number} schoolId - ID школы
 * @param {string} date - Дата в формате 'YYYY-MM-DD', на которую нужно установить субсидируемое питание
 * @param {Array<number>} classIds - Массив ID классов, для которых устанавливается субсидируемое питание
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const setSubsidyFoodType = (schoolId, date, classIds) =>
  axios.post(
    `${Vue.config.server}/${Vue.config.api.administrator.orders.setSubsidy(
      schoolId,
      date
    )}`,
    { classIds }
  )

/**
 * Функция для отмены субсидируемого типа питания для определённых классов
 * @param {number} schoolId - ID школы
 * @param {string} date - Дата в формате 'YYYY-MM-DD', на которую нужно отменить субсидируемое питание
 * @param {Array<number>} classIds - Массив ID классов, для которых отменяется субсидируемое питание
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const cancelSubsidyFoodType = (schoolId, date, classIds) =>
  axios.delete(
    `${Vue.config.server}/${Vue.config.api.administrator.orders.setSubsidy(
      schoolId,
      date
    )}`,
    {
      data: { classIds }
    }
  )

/**
 * Функция для возврата заказа учителю
 * @param {Object} payload - Объект с параметрами для запроса
 * @param {number} payload.schoolId - ID Школы
 * @param {number} payload.classId - ID класса
 * @param {string} payload.date - Дата в формате 'YYYY-MM-DD'
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const returnToTeacher = (payload) =>
  axios.post(
    `${Vue.config.server}/${Vue.config.api.administrator.orders.returnToTeacher(
      payload.schoolId,
      payload.classId,
      payload.date
    )}`
  )

export const removeAdditionalFood = (orderId) =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.deleteAdditionalFood.replace(
        '$order_id',
        orderId
      )
  )

/**
 * Функция для пересоздания черновиков заказов для указанных классов на сервере
 * @param {Object} payload - Объект с данными для пересоздания черновиков
 * @param {number} payload.schoolId - ID школы
 * @param {string} payload.date - Дата пересоздания черновиков
 * @param {Object} payload.data - Дополнительные данные
 * @param {Array<number>} payload.data.classIds - Массив ID классов, для которых нужно пересоздать черновики
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const recreateDraftsSchool = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.recreateDrafts(
        payload.schoolId,
        payload.date
      ),
    payload.data
  )

/**
 * Функция для отправки запроса на печать меню для определённой школы и даты
 * @param {Object} payload - Объект с данными для печати меню
 * @param {number} payload.schoolId - ID школы
 * @param {string} payload.date - Дата в формате 'YYYY-MM-DD', на которую нужно распечатать меню
 * @returns {Promise} - Возвращает промис с результатом запроса, который содержит файл (blob) с меню
 */
export const printMenu = (payload) =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.administrator.orders.printMenu(
        payload.schoolId,
        payload.date
      ),
    {},
    { responseType: 'blob', preloaderState: false }
  )
