<template>
  <v-container fluid class="fs-organizer-container start-page">
    <v-flex md6 offset-md3>
      <v-card v-if="!privacyPolicyStatus && setting('supplier_privacy_policy')" color="yellow">
        <v-card-text>
          Внимание! Заполните политику конфиденциальности для возобновления работы услуги
          пополнения счёта через сервис Сбербанк Онлайн
        </v-card-text>
      </v-card>
      <v-card v-if="hasReserveAlert" class="my-1" color="yellow">
        <v-card-text>
          Внимание, необходимо перейти на работу с резервного шаблона меню.
          Имеются заказы на резервное меню с {{reserveStartDate}} {{reserveLastDate ? `по ${reserveLastDate}` : ''}}!
        </v-card-text>
      </v-card>
      <v-list two-line class="fs-list">
        <template v-for="(item, index) in navItems">
          <v-list-tile
            v-if="!item.children"
            :key="item.title"
            :to="{ name: item.link }"
            :disabled="item.disabled"
            avatar
          >
            <v-list-tile-avatar>
              <v-icon :class="[item.iconClass]">{{ item.icon }}</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ item.subtitle }}</v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn icon ripple>
                <v-icon color="grey lighten-1">info</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile
            v-if="item.children"
            v-for="item_ in item.children"
            :key="item_.title"
            :to="{ name: item_.link }"
            :disabled="item_.disabled"
            avatar
          >
            <v-list-tile-avatar>
              <v-icon :class="[item_.iconClass]">{{ item_.icon }}</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ item_.title }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ item_.subtitle }}</v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn icon ripple>
                <v-icon color="grey lighten-1">info</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider v-if="index + 1 < navItems.length && !item.childs" :key="index"></v-divider>
        </template>
      </v-list>
    </v-flex>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { getBanking, getPrivacy, hasReserve } from "@/router/routes/organizer"
import { Menu } from '@/lib/menu'
import store from "@/store"
import MenuFactory from "@/factories/organizer/menu"

export default {
  name: "StartPage",
  mounted() {
    this.requestPrivacyPolicy()
    if (this.setting('use_reserve')) {
      this.checkReserve()
    }
  },
  data() {
    // TODO Сменить параметры из БД SUPPLIER_PRIVACY_POLICY
    return {
      privacyPolicyStatus: true,
      hasReserveAlert: false,
      reserveStartDate: null,
      reserveLastDate: null
    }
  },
  methods: {
    async requestPrivacyPolicy() {
      const res = await getBanking()
      if (res.data.sber && res.data.sber === 'connected') {
        const privacy = await getPrivacy()
        if (privacy.data.content.length === 0) {
          this.privacyPolicyStatus = false
        }
      }
      store.commit('setShowPreloader', false)
    },
    async checkReserve() {
      const response = await hasReserve()
      if (response.data.has_reserve === true) {
        this.hasReserveAlert = true
        this.reserveStartDate = response.data.start_date
        this.reserveLastDate = response.data.last_date
      }
    }
  },
  computed: {
    ...mapGetters([ 'currentUser', 'setting' ]),
    isSupplier() {
      return this.currentUser.roles[0] === 'SUPPLIER'
    },
    navItems() {
      return this.isSupplier
        ? new MenuFactory(null, store.getters.settings, this.currentUser)
        : new MenuFactory(this.currentUser.roles, store.getters.settings)
    }
  }
}
</script>
