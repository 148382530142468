<script>
import { getUserService } from "@/router/routes/common"

export default {
  name: "SupportContacts",
  data: () => {
    return {
      supportContacts: [
        { name: 'E-Mail', type: 'email', value: null },
        { name: 'Телефон', type: 'phone', value: null }
      ]
    }
  },
  async mounted() {
    const { data } = await getUserService()
    this.supportContacts[0].value = data.email
    this.supportContacts[1].value = data.phone
  }
}
</script>

<template>
  <v-card>
    <v-card-title>
      <h2>Поддержка</h2>
    </v-card-title>
    <v-card-text>
      <div :key="idx" v-for="(item, idx) in supportContacts" class="d-flex row justify-space-between">
        <div class="support-title mb-2">{{ item.name }}</div>
        <div class="support-value" v-if="supportContacts[0].value">
          <a :href="`mailto:${item.value}`" v-if="item.type === 'email'">
            {{ item.value }}
          </a>
          <a :href="`tel:${item.value}`" v-if="item.type === 'phone'">
            {{ item.value }}
          </a>
        </div>
        <div class="support-value" v-else>
          <v-progress-circular size="16" :indeterminate="true" color="primary" />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.support-title {
  font-weight: 600;
}
.support-value {
  text-align: right;
}
</style>
