<template>
  <v-container fluid grid-list-md>
    <v-layout row mb-3>
      <v-flex mb-3>
        <h1>Лог действий в карточке ученика</h1>
      </v-flex>
    </v-layout>
    <v-layout wrap mb-12 align-center>
      <v-flex xs12 sm6 md4 lg3>
        <v-text-field v-model="pupil_id" label="ID ученика" @keyup.enter="loadMore(true)" />
      </v-flex>
      <v-flex xs12 sm6 md4 lg3>
        <v-btn
            class="v-btn--active"
            flat
            @click="loadMore(true)"
        >Показать</v-btn>
      </v-flex>
    </v-layout>
    <v-layout mb-3 justify-space-around>
      <v-flex>
        <v-data-table
            :headers="headers"
            :pagination.sync="pagination"
            :items="items"
            hide-actions
            class="elevation-1"
            item-key="id"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.createdAt }}</td>
              <td>{{ props.item.author }}</td>
              <td>{{ props.item.pupil }}</td>
              <td>{{ props.item.operation_type }}</td>
              <td>{{ props.item.context }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import { getAdminTeacherPupilCardLogs } from '@/router/routes/superadmin'
import { mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import moment from 'moment'
import scrollable from "@/mixins/scrollable"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'AdminTeacherPupilCardLogs',
  mixins: [ scrollable ],
  data() {
    return {
      pagination: {
        rowsPerPage: -1
      },
      headers: [
        { text: 'Дата', value: 'created_at', sortable: false },
        { text: 'Автор', value: 'author', sortable: false },
        { text: 'Ученик', value: 'pupil', sortable: false },
        { text: 'Действие', value: 'operation_type', sortable: false },
        { text: 'Контекст', value: 'context', sortable: false },
      ],
      items: [],
      pupil_id: null,
      filters: {
        pupil_pk: null,
      },
      limit: Vue.config.general.limit,
    }
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler)
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async loadMore(reset) {
      if (reset) {
        this.items = []
        this.offset = 0
      }
      try {
        const payload = {
          params: {
            pupil_pk: this.pupil_id || null,
            limit: this.limit,
            offset: this.offset
          }
        }
        const response = await getAdminTeacherPupilCardLogs(payload)
        const itemsArray = _.map(response.data.data, item => {
          return {
            ...item,
            createdAt: moment(item.created_at).format(Vue.config.format.serverDateFull)
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        // notify.error('Не удалось загрузить лог') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
