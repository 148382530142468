<script>
import numable from '@/mixins/balancable'
import { getOrdersLogForPastDay } from '@/router/routes/superadmin'
import store from '@/store'
import moment from 'moment'
import Vue from 'vue'
import notify from '@/utils/notifications/toastNotification.vue'
import { debounce } from 'lodash'
import { disableLoader } from '@/mixins/helpers'

export default {
  name: 'SuperadminOrderLogsTable',

  mixins: [numable, disableLoader],

  components: {},

  data() {
    return {
      expand: false,
      pagination: {
        rowsPerPage: 10,
        page: 1,
        totalItems: 0,
        sortBy: null,
        descending: false
      },
      isLoading: true,
      logList: [],
      showCreateDatePickerMenu: false,
      showCreateDateOrdersPickerMenu: false,
      minDate: moment()
        .subtract('month', 1)
        .format(Vue.config.format.serverDate),
      dateYesterday: moment()
        .subtract('days', 1)
        .format(Vue.config.format.serverDate),
      filters: {
        date_create: {
          name: 'date_create',
          label: 'По дате создания',
          placeholder: 'Дата создания',
          value: null
        },
        date_create_orders: {
          name: 'date_create_orders',
          label: 'По дате Заявки',
          placeholder: 'Дата заявки',
          value: null
        },
        school_id: {
          name: 'school_id',
          label: 'По ID Школы',
          placeholder: 'ID Школы',
          value: null,
          rules: [(v) => typeof v === 'number']
        },
        school_class: {
          name: 'school_class',
          label: 'По ID Класса',
          placeholder: 'ID Класса',
          value: null,
          rules: [(v) => typeof v === 'number']
        },
        pupils_ids: {
          name: 'pupils_ids',
          label: 'По ID Ученика',
          placeholder: 'ID Ученика',
          value: null,
          rules: [(v) => typeof v === 'number']
        }
      },
      headers: [
        {
          text: 'ID',
          align: 'left',
          value: 'id'
        },
        {
          text: 'Пользователь',
          align: 'left',
          value: 'user_name'
        },
        {
          text: 'Дата запроса',
          align: 'center',
          value: 'date_request'
        },
        {
          text: 'Дата в заявке',
          align: 'center',
          value: 'date_order'
        },
        {
          text: 'Группа запроса',
          align: 'center',
          value: 'request_object'
        },
        {
          text: 'Запрос',
          align: 'center',
          value: 'params'
        }
      ]
    }
  },

  methods: {
    getLogList: debounce(async function (payload) {
      this.isLoading = true

      try {
        const { data } = await getOrdersLogForPastDay({ params: payload })
        this.logList = data.results
        this.pagination.totalItems = data.count
        this.sortedTable() // сортируем согласно установленным значениям
        store.commit('setShowPreloader', false)
      } catch (e) {
        notify.error(
          'Ошибка загрузки истории создания заявок на прошедшую дату'
        )
      } finally {
        this.isLoading = false
        store.commit('setShowPreloader', false)
      }
    }, 1000),

    clearFilters() {
      this.filters.date_create.value = null
      this.filters.date_create_orders.value = null
      this.filters.school_id.value = null
      this.filters.school_class.value = null
      this.filters.pupils_ids.value = null
    },

    copyText(text) {
      navigator.clipboard.writeText(text)
      notify.info(`ID: ${text} скопирован в буфер обмена`)
    },

    createdAt(date) {
      return moment(date).format(Vue.config.format.serverDateFull)
    },

    sortedTable() {
      const { sortBy, descending } = this.pagination
      if (sortBy) {
        this.logList.sort((a, b) => {
          const sortA = a[sortBy]
          const sortB = b[sortBy]

          if (descending) {
            if (sortA < sortB) return 1
            if (sortA > sortB) return -1
            return 0
          } else {
            if (sortA < sortB) return -1
            if (sortA > sortB) return 1
            return 0
          }
        })
      }
    }
  },
  mounted() {
    setTimeout(this.getLogList, 1000)
  },

  computed: {
    params() {
      const params = {}
      if (this.filters.date_create.value) {
        params.date_create = this.filters.date_create.value
      } else {
        delete params?.date_create
      }
      if (this.filters.date_create_orders.value) {
        params.date_create_orders = this.filters.date_create_orders.value
      } else {
        delete params?.date_create_orders
      }
      if (this.filters.school_id.value) {
        params.school_id = this.filters.school_id.value
      } else {
        delete params?.school_id
      }
      if (this.filters.school_class.value) {
        params.school_class = this.filters.school_class.value
      } else {
        delete params?.school_class
      }
      if (this.filters.pupils_ids.value) {
        params.pupils_ids = this.filters.pupils_ids.value
      } else {
        delete params?.pupils_ids
      }

      return { ...params }
    }
  },
  watch: {
    pagination() {
      if (this.pagination.page !== 1) {
        this.params.page = this.pagination.page
      } else {
        delete this.params?.page
      }

      if (this.pagination.rowsPerPage !== 10) {
        this.params.page_size = this.pagination.rowsPerPage
      } else {
        delete this.params?.page_size
      }

      this.getLogList(this.params)
    },

    'pagination.sortBy': {
      handler() {
        this.sortedTable()
      },
      deep: true
    },
    'pagination.descending': {
      handler() {
        this.sortedTable()
      },
      deep: true
    },

    params: {
      handler() {
        this.getLogList(this.params)
      },
      deep: true
    }
  }
}
</script>

<template>
  <v-card class="pa-3 mt-3">
    <v-card-title primary-title>
      <h3 class="headline font-weight-medium">
        История создания заявок на прошедшую дату
      </h3>
    </v-card-title>

    <v-card-text class="pa-0 elevation-1">
      <v-form>
        <v-container class="pa-0 ma-3">
          <v-subheader class="pa-0">Фильтры</v-subheader>
          <v-layout>
            <v-flex xs12 md4>
              <v-menu
                v-model="showCreateDatePickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filters.date_create.value"
                    :label="filters.date_create.label"
                    :placeholder="filters.date_create.placeholder"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  :max="dateYesterday"
                  no-title
                  locale="ru"
                  first-day-of-week="1"
                  v-model="filters.date_create.value"
                  @input="showCreateDatePickerMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 md4>
              <v-menu
                v-model="showCreateDateOrdersPickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filters.date_create_orders.value"
                    :label="filters.date_create_orders.label"
                    :placeholder="filters.date_create_orders.placeholder"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  :max="dateYesterday"
                  no-title
                  locale="ru"
                  first-day-of-week="1"
                  v-model="filters.date_create_orders.value"
                  @input="showCreateDateOrdersPickerMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                v-model="filters.school_id.value"
                :rules="filters.school_id.rules"
                :label="filters.school_id.label"
                :placeholder="filters.school_id.placeholder"
                prepend-icon="school"
                clearable
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md4>
              <v-text-field
                v-model="filters.school_class.value"
                :rules="filters.school_class.rules"
                :label="filters.school_class.label"
                :placeholder="filters.school_class.placeholder"
                prepend-icon="desk"
                clearable
              ></v-text-field>
            </v-flex>

            <v-flex xs12 md4>
              <v-text-field
                v-model="filters.pupils_ids.value"
                :rules="filters.pupils_ids.rules"
                :label="filters.pupils_ids.label"
                :placeholder="filters.pupils_ids.placeholder"
                prepend-icon="person"
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-btn @click="clearFilters">Очистить</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-text class="pa-0 pa-0">
      <v-data-table
        :headers="headers"
        :items="logList"
        :rows-per-page-items="[5, 10, 25, 50, 100]"
        :total-items="pagination.totalItems"
        :pagination.sync="pagination"
        :expand="expand"
        :loading="isLoading"
        class="elevation-1"
        disable-initial-sort
      >
        <v-progress-linear
          v-slot:progress
          color="blue-grey"
          indeterminate
        ></v-progress-linear>

        <template v-slot:items="props">
          <tr
            @click="props.expanded = !props.expanded"
            :class="props.expanded ? 'active' : ''"
          >
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.user_name }}</td>
            <td class="text-center">
              {{ createdAt(props.item.date_request) }}
            </td>
            <td class="text-center">
              {{ props.item.date_order }}
            </td>
            <td class="text-center">
              {{ props.item.request_object }}
            </td>
            <td class="text-right">
              {{ props.item.params.school.name }}
            </td>
          </tr>
        </template>
        <template v-slot:expand="props">
          <v-card class="elevation-3" color="grey lighten-5">
            <v-flex>
              <v-flex>
                <strong>Школа: </strong>
                <span class="text-truncate text-none">
                  &nbsp; {{ props.item.params.school.name }}
                </span>
              </v-flex>
              <v-chip
                outline
                small
                @click="copyText(props.item.params.school.id)"
                >ID: {{ props.item.params.school.id }}
              </v-chip>
            </v-flex>

            <v-flex v-if="props.item.params?.school_classes?.length > 0" wrap>
              <v-flex xs5 sm3>
                <strong
                  >Классы
                  <span
                    v-if="props.item.params.school_classes.length"
                    class="grey--text"
                  >
                    &nbsp;({{ props.item.params.school_classes.length }})
                  </span>
                  :</strong
                >
              </v-flex>

              <v-chip
                outline
                v-for="(item, idx) in props.item.params.school_classes"
                :key="item.school_class_id"
                small
                @click="copyText(item.school_class_id)"
              >
                {{ idx + 1 }}. {{ item.school_class_name }} ({{
                  item.school_class_id
                }})
              </v-chip>
            </v-flex>

            <v-flex dense v-if="props.item.params?.pupils?.length > 0">
              <v-flex xs5 sm3>
                <strong
                  >Ученики
                  <span
                    v-if="props.item.params.pupils.length"
                    class="grey--text"
                  >
                    &nbsp;({{ props.item.params.pupils.length }})
                  </span>
                  :</strong
                >
              </v-flex>

              <v-chip
                outline
                v-for="(pupil, idx) in props.item.params.pupils"
                :key="pupil.id"
                small
                @click="copyText(pupil.pupil_id)"
              >
                {{ idx + 1 }}. {{ pupil.full_name }} ({{ pupil.pupil_id }})
              </v-chip>
            </v-flex>
          </v-card>
        </template>

        <template v-slot:no-data>
          <v-alert :value="!isLoading" icon="warning">
            Данные отсутствуют
          </v-alert>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style>
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.active {
  background-color: #eceff1;
}
</style>
