import _ from 'lodash'
import {
  getAllStudents,
  getDishes,
  transferBalance
} from '@/router/routes/parent'
import { getChildDishes } from '@/router/routes/pupil'

function setDishes(response, commit) {
  const dishes = _.map(response.data.data, (item) => {
    return {
      id: item.id,
      name: item.name,
      energy: item.energy_value,
      protein: item.protein,
      fats: item.fat,
      carbs: item.carbohydrates,
      additionally: item.is_additional,
      weightMiddle: item.weight_middle || 0,
      weightPrimary: item.weight_primary || 0,
      minerals: {
        ca: item.miniral_ca,
        fe: item.miniral_fe,
        mg: item.miniral_mg
      },
      vitamins: {
        a: item.vitamin_a,
        b1: item.vitamin_b1,
        c: item.vitamin_c,
        e: item.vitamin_e
      }
    }
  })
  commit('setDishes', dishes)
}

async function getYourSchoolchildren(payload, commit) {
  const {
    data: { data }
  } = await getAllStudents(payload)
  commit('setYourSchoolchildren', data)
}

async function changeTransferBalance(payload, commit) {
  const { data } = await transferBalance(payload)
  commit('updateYourSchoolchildrenBalance', data)
}

export default {
  GET_DISHES({ commit, state }, payload) {
    return getDishes(payload).then((response) => {
      setDishes(response, commit)
    })
  },
  GET_CHILD_DISHES({ commit, state }, payload) {
    return getChildDishes(payload).then((response) => {
      setDishes(response, commit)
    })
  },
  GET_YOUR_SCHOOLCHILDREN({ commit, state }, payload) {
    return getYourSchoolchildren(payload, commit)
  },
  async CHANGE_TRANSFER_BALANCE({ commit, state }, payload) {
    await changeTransferBalance(payload, commit)
  }
}
