<template>
  <v-container class="fs-parent-container" fluid>
    <v-layout class="fs-daily-menu" row mb-3>
      <v-flex v-if="setting('parent_menu_cbju')" md3 mb-3>
        <h1>Меню</h1>
      </v-flex>
      <v-flex v-else md3 mb-3>
        <h1>{{ title }}</h1>
      </v-flex>
    </v-layout>
    <v-layout mb-3 row>
      <v-flex md-2>
        <v-alert
            :value="currentChild.isFake"
            type="error"
        >
          <h3>Ошибка: По данному ученику из учётной системы региона получены неполные данные. Работа с ним временно
            заблокирована. Ожидайте повторной синхронизации.</h3>
        </v-alert>
      </v-flex>
    </v-layout>
    <template  v-if="!currentChild.isFake">
      <v-layout v-if="setting('parent_menu_cbju')" column>
        <v-flex class="mb-3 mt-3" style="display: flex;">
          <h1>Информация об ученике</h1>
        </v-flex>
        <v-flex>
          <v-divider></v-divider>
          <p>
            <span class="fs-parent-info__key" style="font-weight: bold;">Тип питания:</span>
            {{ currentChild.foodType && currentChild.foodType.name }}
          </p>
        </v-flex>
        <v-flex>
          <span class="fs-parent-info__key" style="font-weight: bold;">Возрастная категория:</span>
          <span v-if='currentChild.schoolClass.number < 5'> 7-11</span>
          <span v-else> 12-18</span>
        </v-flex>
      </v-layout>
      <v-card
          v-for="(weekArray, weekIndex) in allWeeks"
          :key="weekIndex"
          v-show="menuLoaded"
          class="mb-3"
      >
        <v-card-text class="parent_orders_panels pb-0">
          <v-layout
              row
              wrap
          >
            <v-flex class="dateRangeTitle" xs12>
              <h3>{{ getWeekLabelText(weekIndex) }}</h3>
            </v-flex>
            <v-flex xs12>
              <table class="expansionTable mb-1" width="100%">
                <tr>
                  <th class="th_status" colspan="2">
                    <div class="text-xs-center">Статус</div>
                  </th>
                  <th class="th_date">
                    <div class="text-xs-left">День</div>
                  </th>
                  <th class="th_price">
                    <div class="text-xs-center">Сумма, р.</div>
                  </th>
                  <th class="th_concession">
                    <div class="text-xs-center">Сумма соцвыплат, р.</div>
                  </th>
                  <th class="th_total">
                    <div class="text-xs-center">Итого, р.</div>
                  </th>
                </tr>
                <tr
                    v-for="(item, index) in weekArray"
                    :key="index"
                >
                  <td colspan="6" v-show="!item.choise && item.isWorkDay" class="px-2 rounded text-xs-center">
                    <v-alert v-show="true" color="66717f" class="text-black">
                      Меню на {{ calculateDay(item.dayOfWeek) }} не сформировано комбинатом питания
                    </v-alert>
                  </td>
                  <td colspan="6" v-show="![6,7].includes(item.dayOfWeek) && !item.isWorkDay"
                      class="px-2 rounded text-xs-center">
                    <v-alert v-show="true" color="66717f" class="text-black">
                      <span class="text-capitalize">{{ calculateDay(item.dayOfWeek) }}</span> нерабочий день
                    </v-alert>
                  </td>
                  <td colspan="6" v-show="item.choise">
                    <v-expansion-panel focusable>
                      <v-expansion-panel-content hide-actions>
                        <template v-slot:header>
                          <div class="v-expansion-panel__header__icon col_arrow">
                            <i aria-hidden="true" class="v-icon material-icons theme--light">keyboard_arrow_down</i>
                          </div>
                          <div class="col_icon">
                            <v-icon :class="item.editableDay ? 'icon_active' : ''">
                              {{ item.editableDay ? 'check_circle' : 'lock' }}
                            </v-icon>
                          </div>
                          <div class="col_date">{{ calculateDate(item.dayOfWeek) }}, <span
                              class="text-capitalize">{{ calculateDay(item.dayOfWeek) }}</span></div>
                          <div class="col_price text-xs-center">
                            {{
                              item.selectedComplect && item.selectedComplect.foodType ? convertToRubles(item.selectedComplect.foodType.price) + getAdditionalFoodPrice(item.selectedComplect) : 0
                            }}
                            <span v-if="item.selectedComplect && item.selectedComplect.additionalFoodInfo"> (из них доп. блюда: {{
                                getAdditionalFoodPrice(item.selectedComplect)
                              }})</span>
                          </div>
                          <div class="col_concession text-xs-center">{{ item.concession }}</div>
                          <div class="col_total text-xs-center">{{ convertToRubles(calculateTotalSum(item)) }}</div>
                        </template>
                        <v-layout v-if="!hasReserveOrders" row wrap>
                          <v-flex
                              v-for="(complex, index) in item.complexes"
                              :key="index"
                              class="complexCard"
                              xs12
                              sm6
                              md4
                              pa-2
                          >
                            <v-card>
                              <v-card-title>
                                <div>
                                  <h3>Комплекс: {{ complex.name }}</h3>
                                </div>

                              </v-card-title>
                              <v-card-actions>
                                <v-layout row justify-space-around>
                                  <v-flex>
                                  <v-btn
                                      class="fs-btn fs-parent-btn fs-btn--primary fs-form-footer__btn"
                                      @click="handlerComplexInfoModal(item, complex.id)"
                                  >Блюда(КБЖУ)
                                  </v-btn>
                                </v-flex>
                                  <v-flex class="complex__price text-xs-left">{{
                                      complex.mealType && complex.mealType.length && complex.mealType[0].price ? convertToRubles(complex.mealType[0].price) : '0'
                                    }} р.
                                  </v-flex>
                                  <v-flex class="complex__check text-xs-right">
                                    <v-icon
                                        :color="item.editableDay ? '#cb6026' : '#00000042'"
                                        :class="[ 'selectCheckbox', item.editableDay ? '' : 'disabled' ]"
                                        size="24"
                                        @click="item.choise = complex.id"
                                    >{{ item.choise === complex.id ? 'check_box' : 'check_box_outline_blank' }}
                                    </v-icon>
                                  </v-flex>
                                </v-layout>
                              </v-card-actions>
                            </v-card>
                          </v-flex>
                          <v-flex class="complexCard" xs12 sm6 md4 pa-2>
                            <v-card>
                              <v-card-title>
                                <v-flex xs12>
                                  <h3>Дополнительное меню</h3>
                                </v-flex>
                              </v-card-title>
                              <v-card-text>
                                <v-flex xs12>Выбрано: {{
                                    item.selectedComplect && item.selectedComplect.additionalFoodInfo ? item.selectedComplect.additionalFoodInfo.length - 1 : 0
                                  }}
                                </v-flex>
                                <v-flex v-if="item.additionalFoods.length" xs12>
                                  <ul>
                                    <li v-for="food in getAdditionalFoods(item)" :key="food.food.id">{{
                                        food.food.name
                                      }}, {{ convertToRubles(food.price) }} р., {{ food.amount }} шт.
                                    </li>
                                  </ul>
                                </v-flex>
                                <v-flex xs12>
                                  <v-btn
                                      v-if="!isChildView"
                                      class="fs-btn ma-0"
                                      @click="handlerAdditional(item)"
                                  >
                                    <v-icon size="24" color="#cb6026">edit</v-icon>
                                  </v-btn>
                                </v-flex>
                              </v-card-text>
                            </v-card>
                          </v-flex>
                        </v-layout>
                        <v-layout v-else row wrap>
                          <v-flex>
                            <h3>Меню находится в процессе заполнения. Это займет некоторое время.</h3>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </td>
                </tr>
              </table>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions class="d-flex row justify-space-between pt-0 px-3">
          <v-card
            v-for="item in totalHeaders"
            :key="item.name"
            v-if="!item.settingsKey || (item.settingsKey && setting(item.settingsKey))"
            class="pa-0 text-center"
          >
            <v-card-title class="py-0 body-2 justify-space-around">
              {{ item.name }}
            </v-card-title>
            <v-card-text class="pt-0 pb-1 title" style="font-size: 1.2rem !important">
              {{ item.methodKey ?
                `${getTotalValue(item.methodKey, weekIndex)} ₽`:
                `${energyTotal.weeks[weekIndex][item.key]} / 100гр`
              }}
            </v-card-text>
          </v-card>
        </v-card-actions>
        <v-flex class="totalRow text-xs-right">
          <v-btn
              v-if="weekIndex === 2 && !isChildView"
              class="fs-btn fs-parent-btn fs-btn--primary fs-form-footer__btn"
              flat
              @click="save()"
              >Сохранить</v-btn>
        </v-flex>
      </v-card>
    </template>
    <OrderModal
        v-model="selectedComplexesDialog"
        :complexes="selectedComplexes"
        :selectedComplex.sync="selectedComplexId"
    />
    <ConfirmModal
        :dialog.sync="showModal"
        :confirmBtnText="'Назначить'"
        :width="1000"
        :disable-confirm-button="!selectedItem || !selectedItem.editableDay || noAdditionalFoodsSelected"
        @confirm="handlerConfirmModal"
    >
      <template v-slot:headline>Дополнительные блюда</template>
      <template v-slot:body>
        <v-flex>
          <v-data-table
              :headers="headers"
              :items="additionalFoods"
              class="elevation-1"
              hide-actions
              no-data-text="Нет дополнительных блюд"
              select-all
              item-key="name"
          >
            <template v-slot:items="props">
              <tr>
                <td>
                  <v-checkbox
                      v-model="props.item.is_selected"
                      :disabled="!selectedItem || !selectedItem.editableDay"
                      class="fs-checkbox"
                      color="#66717f"
                      primary
                      hide-details
                  ></v-checkbox>
                </td>
                <td>{{ props.item.food.id }}</td>
                <td>{{ props.item.food.name }}</td>
                <td>{{ (props.item.price / 100) || '-' }}</td>
                <td>
                  <strong>{{ props.item.amount }}</strong>
                  <v-text-field
                      v-model="props.item.amount"
                      type="number"
                      label="Кол-во"
                      append-outer-icon="add"
                      @click:append-outer="increment(props)"
                      @click:prepend="decrement(props)"
                      prepend-icon="remove"
                  ></v-text-field>
                </td>
                <td>{{ props.item.food.energy_value }}</td>
                <td>{{ props.item.food.protein }}</td>
                <td>{{ props.item.food.fat }}</td>
                <td>{{ props.item.food.carbohydrates }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </template>
    </ConfirmModal>
    <ConfirmModal :dialog.sync="showBalanceMessage">
      <template v-slot:headline>Ошибка</template>
      <template v-slot:body>
        <div v-html="'У вас недостаточно денежных средств для подтверждения выбора. Пожалуйста, пополните счет.'"></div>
      </template>
    </ConfirmModal>
  </v-container>
</template>

<style>
.text-black {
  color: black !important;
}
</style>

<script>
import Vue from 'vue'
import moment from 'moment'
import OrderModal from '@/components/parent/Modals/OrderModal'
import {getAdditionalFoods, getFoodOrders, saveOrders, setAdditionalFoods} from '@/router/routes/parent'
import store from '@/store'
import numable from '@/mixins/balancable'
import {mapGetters, mapMutations} from 'vuex'
import DailyMenuTemplateFactory from '@/factories/parent/dialy-menu-template-factory'
import _ from 'lodash'
import {ConfirmModal} from '@/components/common'
import {getChildFoodOrders} from '@/router/routes/pupil'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ParentOrderIndex',
  mixins: [numable],
  components: {
    OrderModal,
    ConfirmModal
  },
  props: {
    isChildView: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      title: 'Заказ питания',
      dailyMenuTemplate: new DailyMenuTemplateFactory(),
      additonalMenuItems: [],
      additonalMenuSelected: [],
      showModal: false,
      showBalanceMessage: false,
      hasReserveOrders: false,
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'Наименование', value: 'name'},
        {text: 'Цена, р.', value: 'price'},
        {text: 'Кол-во', value: 'amount'},
        {text: 'Энергетическая ценность, кКал/кДж (на 100 г)', value: 'energy_value'},
        {text: 'Белки, г (на 100 г)', value: 'protein'},
        {text: 'Жиры, г (на 100 г)', value: 'fat'},
        {text: 'Углеводы, г (на 100 г)', value: 'carbohydrates'}
      ],
      selectedItem: null,
      selectedComplexes: [],
      selectedComplexId: null,
      selectedComplexesDialog: false,
      additionalFoods: [],
      menuLoaded: false,
      energyTotal: {},
      totalHeaders: [
        {name: "Белки", key: "protein", settingsKey: 'parent_menu_cbju'},
        {name: "Жиры", key: "fats", settingsKey: 'parent_menu_cbju'},
        {name: "Углеводы", key: "carbs", settingsKey: 'parent_menu_cbju'},
        {name: "Сумма заказов", methodKey: 'totalPerWeek'},
        {name: "Сумма соцвыплат", methodKey: "totalSocial"},
        {name: "Итого к оплате", methodKey: "totalWeek"},
      ]
    }
  },
  computed: {
    ...mapGetters(['currentChild', 'dishes', 'setting']),
    allWeeks() {
      return [
        this.dailyMenuTemplate.slice(0, 7),
        this.dailyMenuTemplate.slice(7, 14),
        this.dailyMenuTemplate.slice(14)
      ]
    },
    noAdditionalFoodsSelected() {
      return this.additionalFoods.filter(f => f.is_selected).length === 0
    }
  },
  created() {
    this.init()
    this.loadFoods()
  },
  methods: {
    ...mapMutations(['setShowPreloader']),
    getTotalValue(key, weekIndex) {
      switch (key) {
        case 'totalPerWeek':
          return this.roundRubles(this.getTotalPerWeek(weekIndex))
        case 'totalSocial':
          return this.getTotalSocialPerWeek(weekIndex)
        case 'totalWeek':
          return this.getTotalWeek(weekIndex)
        default:
          return null
      }
    },
    getAdditionalFoodPrice(item) {
      return item.additionalFoodInfo ? this.convertToRubles(item.additionalFoodInfo.slice(-1)[0].totalPrice) : 0
    },
    increment(props) {
      if (props.item.amount >= 3) {
        return
      }
      props.item.amount += 1
    },
    decrement(props) {
      if (props.item.amount <= 1) {
        return
      }
      props.item.amount -= 1
    },
    getWeekLabelText(index) {
      const arrayLength = this.allWeeks[index].length
      const startDate = this.allWeeks[index] && this.allWeeks[index][0] && this.allWeeks[index][0].dayOfWeek
          ? this.calculateDate(this.allWeeks[index][0].dayOfWeek)
          : ''
      const endDate = this.calculateDate(
          this.allWeeks[index][arrayLength - 1].dayOfWeek
      )
      return `c "${startDate}" по "${endDate}" `
    },
    handlerComplexInfoModal(item, id) {
      this.selectedComplexes = item.complexes
      this.selectedComplexId = id
      this.selectedComplexesDialog = true
    },
    async handlerConfirmModal(value) {
      if (!value) {
        return
      }
      const amounts = []
      const selected = []
      this.additionalFoods.filter(f => !!f.is_selected).forEach(f => {
        selected.push(f.id)
        amounts.push(f.amount)
      })
      try {
        const PAYLOAD = {
          order_pk: this.selectedItem.selectedComplect.id,
          pupil_pk: this.currentChild.id,
          data: {
            foods: selected,
            amounts,
          }
        }
        await setAdditionalFoods(PAYLOAD)
        notify.success('Дополнительные блюда успешно назначены')
        await this.init()
      } catch (error) {
        // notify.error('Ошибка установки дополнительного меню') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async handlerAdditional(item) {
      this.selectedItem = item
      this.additionalFoods = this.additionalFoods.map(a => {
        const selected = !!item.additionalFoods.find(food => food.id === a.id)
        let amount = 1
        if (item.selectedComplect && item.selectedComplect.additionalFoodInfo) {
          const add_food = item.selectedComplect.additionalFoodInfo.find(f => f.food_id === a.food.id)
          if (add_food) {
            amount = add_food.amount
          }
        }
        return {
          ...a,
          is_selected: selected,
          amount,
        }
      })
      this.showModal = true
    },
    getAdditionalFoods(item) {
      return this.additionalFoods.map(a => {
        const selected = !!item.additionalFoods.find(food => food.id === a.id)
        let amount = 1
        if (item.selectedComplect && item.selectedComplect.additionalFoodInfo) {
          const add_food = item.selectedComplect.additionalFoodInfo.find(f => f.food_id === a.food.id)
          if (add_food) {
            amount = add_food.amount
          }
        }
        return {
          ...a,
          is_selected: selected,
          amount,
        }
      }).filter(f => f.is_selected)
    },
    async loadFoods() {
      if (this.isChildView) {
        return
      }
      const payload = {
        pupil_pk: this.currentChild.id
      }
      const response = await getAdditionalFoods(payload)
      const {
        data: { data: item }
      } = response
      if (item && item.food_set_maps) {
        this.additionalFoods = item.food_set_maps
      }
    },
    async init() {
      try {
        const payload = {
          id: this.currentChild.id,
          params: {
            dateStart: moment()
              .locale('ru')
              .startOf('isoweek')
              .format(Vue.config.format.pythonDate),
            dateEnd: moment()
              .locale('ru')
              .startOf('isoweek')
              .add(20, 'days')
              .format(Vue.config.format.pythonDate)
          }
        }
        const method = this.isChildView ? getChildFoodOrders : getFoodOrders
        const response = await method(payload)

        const {
          data: {data: templates}
        } = response

        // filling template by date in period (day starts from monday)
        _.forEach(this.dailyMenuTemplate, day => {
          const formatedDate = moment()
            .locale('ru')
            .startOf('isoweek')
            .add(day.dayOfWeek - 1, 'days')
            .format(Vue.config.format.datepickerDate)
          _.forEach(templates, item => {
            if (formatedDate == item.date) {
              day.complexes = item.complects.filter((complect) => !complect.stashed && !item.isblocked)
              day.choise = _.get(item, 'selectedComplect.complect.id', null)
              day.isReserve = _.get(item, 'selectedComplect.is_reserve', null)
              if (!this.hasReserveOrders) this.hasReserveOrders = _.get(item, 'selectedComplect.is_reserve', false)
              day.traffic = _.get(item, 'selectedComplect.traffic', null)
              day.isWorkDay = _.get(item, 'isWorkingDay', false)
              day.isBlocked = item.isBlocked
              day.isDraft = item.selectedComplect && item.selectedComplect.status === Vue.config.general.orderStatuses.draft
              // Проверка дня на редактируемость
              day.editableDay =
                !!_.get(item, 'isEditable', null) &&
                !!_.get(item, 'isWorkingDay', null) &&
                !item.isBlocked
              day.selectedComplect = { ...item.selectedComplect }
              day.additionalFood = [ ...item.additionalFood ]
              day.additionalFoods = []
              if (item.additionalFoods) {
                day.additionalFoods = item.additionalFoods
              }
              day.additionalDisabled =
                item.isBlocked ||
                item.selectedComplect === null ||
                item.additionalFood === null
              day.totalPrice = day.selectedComplect ? day.selectedComplect.totalPrice : 0
              day.privilegePrice = 0
              if (day.selectedComplect) {
                day.privilegePrice = day.selectedComplect.privilegePrice
              }
            }
          })
        })
      } catch (error) {
        // notify.error(`Не удалось загрузить шаблоны меню`) // backend handler added (exception_message)
      } finally {
        this.getTotalEnergy()
        this.menuLoaded = true
        store.commit('setShowPreloader', false)
      }
    },

    calculateDate(dayOfWeek) {
      return moment()
        .locale('ru')
        .startOf('isoweek')
        .add(dayOfWeek - 1, 'days')
        .format(Vue.config.format.date)
    },
    calculateDateForPython(dayOfWeek) {
      return moment()
        .locale('ru')
        .startOf('isoweek')
        .add(dayOfWeek - 1, 'days')
        .format(Vue.config.format.pythonDate)
    },
    calculateDay(dayOfWeek) {
      return moment()
        .locale('ru')
        .startOf('isoweek')
        .add(dayOfWeek - 1, 'days')
        .format('dddd')
    },
    calculatePricePerDay(templateDay) {
      const complex = templateDay.selectedComplect
      if (!complex.id) {
        return 0
      }
      return this.convertToRubles(complex.foodType.price + this.calculateAdditionalPrice(templateDay))
    },
    getTotalPerWeek(index) {
      let res = 0
      _.forEach(this.allWeeks[index], day => {
        if (day.isWorkDay) {
          const price = this.calculatePricePerDay(day)
          res += price
        }
      })
      return res
    },
    getTotalEnergy() {
      const _chem = {
        weightPrimary: 0,
        weightMiddle: 0,
        energy: 0,
        fats: 0,
        protein: 0,
        carbs: 0
      }
      this.energyTotal = {
        allWeeks: Object.assign({}, _chem),
        weeks: [
          Object.assign({}, _chem),
          Object.assign({}, _chem),
          Object.assign({}, _chem),
        ]
      }
      for (const [idx, week] of this.allWeeks.entries()) {
        for (const day of week) {
          if (day.selectedComplect) {
            if (Object.keys(day.selectedComplect).length !== 0) {
              for (const complex of day.selectedComplect.complect.fp) {
                const food = this.dishes.filter(item => {
                  return complex.foods.includes(item.id)
                })
                food.forEach(d => {
                  ['weightPrimary', 'weightMiddle', 'energy', 'protein', 'fats', 'carbs'].forEach(key => {
                    this.energyTotal.allWeeks[key] += +d[key]
                    this.energyTotal.weeks[idx][key] += +d[key]
                  })
                })
              }
            }
          }
        }
      }

    },
    calculateAdditionalPrice(templateDay) {
      const complex = templateDay.selectedComplect
      const additionalFoodInfo = complex.additionalFoodInfo
      if (!additionalFoodInfo) return 0
      return additionalFoodInfo.slice(-1)[0].totalPrice
    },
    calculateSocialPerDay(templateDay) {
      const complect = templateDay.selectedComplect
      if (!complect) {
        return 0
      }
      if (complect.privilegePrice === undefined) {
        return 0
      }
      return complect.privilegePrice
    },
    getTotalSocialPerWeek(index) {
      let res = 0
      _.forEach(this.allWeeks[index], day => {
        if (day.isWorkDay) {
          day.concession = this.convertToRubles(this.calculateSocialPerDay(day))
          res += this.calculateSocialPerDay(day)
        }
      })
      return this.convertToRubles(res)
    },
    getTotalWeek(weekIndex) {
      const price = this.getTotalPerWeek(weekIndex) - this.getTotalSocialPerWeek(weekIndex)
      return this.roundRubles(price)
    },
    calculateTotalSum(item) {
      return item.totalPrice
    },
    save() {
      const ordersDays = []
      _.forEach(this.dailyMenuTemplate, item => {
        if (item.choise && item.editableDay) {
          ordersDays.push({
            orderDay: this.calculateDateForPython(item.dayOfWeek),
            complect: item.choise,
            price: this.calculateTotalSum(item)
          })
        }
      })
      const totalSum = ordersDays.reduce((sum, order) => sum + order.price, 0)
      const creditAmount = this.currentChild.isCredit && this.currentChild.isAbleToCredit ? this.currentChild.maxCreditSumm : 0
      if (totalSum > this.currentChild.balance + creditAmount) {
        this.showBalanceMessage = true
        return
      }
      const payload = {
        id: this.currentChild.id,
        data: {
          orders: ordersDays
        }
      }

      const updateOrders = () => {
        return saveOrders(payload)
          .then(response => {
            if (response.data.success) {
              notify.success(Vue.config.notifications.parent.orderSuccess)
            }
          })
      }

      updateOrders().finally(() => store.commit('setShowPreloader', false))
    }
  },
  watch: {
    dailyMenuTemplate: {
      handler(val, oldVal) {
        // TODO: костыль от старой реализации
        this.getTotalPerWeek(1)
        this.getTotalPerWeek(2)
      },
      deep: true
    }
  }
}
</script>
