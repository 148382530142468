<template>
  <v-container fluid class="fs-organizer-container">
    <div class="page-head__common">
      <h1>{{title}}</h1>
    </div>

    <v-select
      :items="schools"
      item-text="name"
      item-value="id"
      label="Фильтр по школам"
      return-object
      v-model="school"
      clearable
    ></v-select>

    <v-layout class="fs-complex-result" mb-3>
      
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="storehouses.results"
          hide-actions
          no-data-text="Нет складов"
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.uuid }}</td>
              <td>{{ props.item.created_at | transformDate }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.school_detail.name }}</td>
              <td>
                <v-flex row>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="$router.push({name: 'StorehouseOrganizerDetail', params: {id: props.item.id, meta: 'edit'}})"
                      >edit</v-icon>
                    </template>
                    <span>Редактирование</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <div class="text-xs-center" v-if="pagination_count > 1">
      <v-pagination
        @input="handlePaginationClick"
        :value="storehouses_pagination.page"
        :length="pagination_count"
        :total-visible="7"
      ></v-pagination>
    </div>

  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getSchools } from '@/router/routes/organizer.js'
import scrollable from '@/mixins/scrollable'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'StorehouseOrganizer',
  mixins: [ scrollable ],
  data: () => ({
    title: 'Доступные cклады',
    limit: Vue.config.general.limit,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'UUID', value: 'uuid' },
      { text: 'Дата создания', value: 'created_at' },
      { text: 'Наименование', value: 'name' },
      { text: 'Школа', value: 'school' },
      { text: 'Операции', value: 'operations' }
    ],
    cards: [],
    schools: [],
    school: ''
  }),
  methods: {
    ...mapActions([ 'fetchStorehouses', 'deleteStorehouseCard' ]),
    ...mapMutations([ 'set_storehouses_pagination' ]),
    async getStorehouses() {
      this.fetchStorehouses({
        params: {
          page: this.storehouses_pagination.page,
          page_size: this.storehouses_pagination.page_size,
          school: this.school && this.school.id ? this.school.id : null
        }
      })
    },
    async getSchools() {
      try {
        const response = await getSchools({ limit: this.LIMIT, offset: this.offset })
        const { data: { data: items } } = response
        const itemsArray = items.map((item) => ({ id: item.id, name: item.name }))
        this.schools = [ ...this.schools, ...itemsArray ]
      } catch (error) {
        // notify.error("Ошибка загрузки школ") // backend handler added (exception_message)
      } finally {
        this.$store.commit("setShowPreloader", false)
      }
    },
    async deleteStorehouse(storehouse_id) {
      this.deleteStorehouseCard({ storehouse_id: storehouse_id })
        .then(() => {
          this.getStorehouses()
        })
    },
    handlePaginationClick(page) {
      this.set_storehouses_pagination({ page: page })
      this.getStorehouses()
    }
  },
  computed: {
    ...mapGetters([ 'storehouses', 'storehouses_pagination' ]),
    pagination_count() {
      return Math.ceil(this.storehouses.count / this.storehouses_pagination.page_size) || 0
    },
  },
  watch: {
    school() {
      this.set_storehouses_pagination({ page: 1 })
      this.getStorehouses()
    }
  },
  mounted() {
    this.getSchools()
    this.getStorehouses()
  },
}
</script>