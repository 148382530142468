<template>
  <v-container fluid grid-list-md>
    <v-layout row mb-3>
      <v-flex mb-3>
        <h1>Поиск ошибок по UUID</h1>
        <span>Всего ошибок в базе: {{ count }}</span>
      </v-flex>
    </v-layout>
    <v-layout wrap mb-12 align-center>
      <v-flex xs12 sm6 md4 lg3>
        <v-text-field
            v-model="uuid"
            label="UUID ошибки"
            @input="checkIfEmpty"
        />
      </v-flex>
      <v-flex xs12 sm6 md4 lg3>
        <v-btn
            class="primary"
            flat
            @click="loadMore(true)"
        >Показать</v-btn>
      </v-flex>
    </v-layout>
    <v-layout mb-3 justify-space-around v-if="showTable">
      <v-flex>
        <v-data-table
            :headers="headers"
            :pagination.sync="pagination"
            :items="items"
            hide-actions
            class="elevation-1"
            item-key="id"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.uuid }}</td>
              <td v-html="props.item.trace"></td>
              <td v-html="props.item.context"></td>
              <td>{{ props.item.priorityLevel }}</td>
              <td>{{ props.item.appModel }}</td>
              <td>{{ props.item.user }}</td>
              <td>{{ Date(props.item.created_at) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

  </v-container>
</template>

<script>
import {
  getAdminTeacherPupilCardLogs,
  getAllExceptionInfo,
  getExceptionInfo,
  getExceptionsCount
} from '@/router/routes/superadmin'
import { mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import moment from 'moment'
import scrollable from "@/mixins/scrollable"
import notify from '@/utils/notifications/toastNotification'



export default {
  name: 'ExceptionSearch',
  mixins: [ scrollable ],
  data() {
    return {
      pagination: {
        rowsPerPage: -1
      },
      count: 0,
      headers: [
        { text: 'UUID', value: 'uuid', sortable: false },
        { text: 'Traceback', value: 'trace', sortable: false },
        { text: 'Контекст', value: 'context', sortable: false },
        { text: 'Приоритет', value: 'priority_level', sortable: false },
        { text: 'Файл', value: 'app_model', sortable: false },
        { text: 'ID Пользователя', value: 'app_model', sortable: false },
        { text: 'Время создания', value: 'created_at ', sortable: false },
      ],
      items: [],
      uuid: null,
      limit: Vue.config.general.limit,
      showTable: true,
    }
  },
  created() {
    window.addEventListener('scroll', this.scrollHandler)
    window.addEventListener('keydown', (event) => {
      if (event.code === 'Enter') {
        this.loadMore(true)
      }
    })
    this.getCount()
    this.loadMore()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    checkIfEmpty(value) {
      if (value.length === 0) {
        this.uuid = null;
        this.loadMore(true);
      }
      if (value.length === 36) {
        this.loadMore(true);
      }
    },
    async getCount() {
      try {
        const response = await getExceptionsCount()
        this.count = response.data.exceptions_count
      } catch (error) {

      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async loadMore(reset) {
      if (reset) {
        this.items = []
        this.offset = 0
      }
      try {
        const payload = {
          params: {
            uuid: this.uuid,
            limit: this.limit,
            offset: this.offset
          }
        }
        const response = await getExceptionInfo(payload)
        const itemsArray = response.data.data.map(item => {
          const obj = JSON.parse(item.context)
          return {
            id: item.id,
            uuid: item.uuid,
            trace: "<br>" + item.trace.replaceAll("File", "<br><br>File") + "<br><br>",
            context: `
                URL: ${obj.URL}<br><br>
                GET: ${JSON.stringify(obj.GET)}<br><br>
                POST: ${JSON.stringify(obj.POST)} <br><br>
                STATUS CODE: ${obj.STATUS_CODE} <br><br>
            `,
            priorityLevel: item.priority_level,
            appModel: item.app_model,
            user: item.user,
            created_at: item.created_at,
          }
        })
        this.items = [ ...this.items, ...itemsArray ]

        this.showTable = true
      } catch (error) {
        console.log(error)
        // notify.error('Не удалось загрузить лог') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>