import {
  getCards,
  getCard,
  updateCard,
  createCard,
  getPupilsBySchool,
  getParentsBySchool,
  getCardBalanceHistories,
  postTransactionBalanceHistories
} from '@/router/routes/admin'

/**
 * Форматирование данных карты, включая определение роли пользователя
 * @param {Object} card - Объект с данными карты
 * @param {Object} card.pupil - Объект с данными ученика
 * @param {string} card.pupil.first_name - Имя ученика
 * @param {string} card.pupil.middle_name - Отчество ученика
 * @param {string} card.pupil.last_name - Фамилия ученика
 * @param {string} card.pupil.full_name - Полное имя ученика
 * @returns {Object} Форматированный объект карты с дополнительными полями
 */
const formatFetchCard = (card) => {
  const role =
    ['is_admin', 'is_guest', 'is_parent'].find((i) => card[i]) ?? 'is_pupil'
  return {
    ...card,
    role,
    first_name: card.pupil.first_name,
    middle_name: card.pupil.middle_name,
    last_name: card.pupil.last_name,
    full_name: card.pupil.full_name
  }
}

export default {
  actions: {
    /**
     * Получение списка карт
     * @param {Object} ctx - Контекст Vuex
     * @param {Object} payload - Объект с параметрами запроса
     * @returns {Promise<void>}
     */
    async fetchCards(ctx, payload) {
      try {
        const { data } = await getCards(payload)
        ctx.commit('set_cards', data)
      } catch (err) {
        console.error(err)
      } finally {
        ctx.commit('setShowPreloader', false)
      }
    },

    /**
     * Получение истории баланса карты буфета ученика
     * @param {Object} ctx - Контекст Vuex (используется для доступа к состоянию и мутациям)
     * @param {Object} payload - Объект с параметрами запроса
     * @param {number | string} payload.pupil_id - Идентификатор ученика
     * @param {number | string} payload.school_id - Идентификатор школы
     * @param {Object} [payload.params] - Дополнительные параметры запроса
     * @param {string} [payload.params.page] - Фильтр страницы
     * @param {string} [payload.params.page_size] - Количество на странице
     * @param {string} [payload.params.min_date] - Фильтрация с указанной даты (формат: ДД-ММ-ГГГГ)
     * @param {string} [payload.params.max_date] - Фильтрация по указанную дату (формат: ДД-ММ-ГГГГ)
     * @param {boolean} [payload.params.is_bank_transactions] - Фильтрация по наличию банковской транзакции (true/false)
     * @param {string} [payload.params.ordering] - Сортировка записей. `create_date` для сортировки от старой записи к новой, `-create_date` для сортировки от новой записи к старой
     * @returns {Promise<void>} Результат запроса к API
     */
    async fetchBalanceHistories(ctx, payload) {
      try {
        const {
          data: { data: transactions, pagination }
        } = await getCardBalanceHistories(payload)
        ctx.commit('set_balance_histories', transactions)
        ctx.commit('set_transaction_pagination', pagination)
      } catch (err) {
        console.error(err)
      } finally {
        ctx.commit('setShowPreloader', false)
      }
    },

    /**
     * Создание записи о транзакции в истории баланса карты буфета ученика
     * @param {Object} ctx - Контекст Vuex (используется для доступа к состоянию и мутациям)
     * @param {Object} payload - Объект с параметрами запроса
     * @param {number|string} payload.pupil_id - Идентификатор ученика
     * @param {number|string} payload.school_id - Идентификатор школы
     * @param {Object} payload.data - Данные для отправки в запросе
     * @param {number} payload.data.amount - Сумма транзакции
     * @returns {Promise<void>} Результат запроса к API
     */
    async createBuffetCardTransaction(ctx, payload) {
      try {
        const { data } = await postTransactionBalanceHistories(payload)
        console.log(data)
      } catch (err) {
        console.error(err)
      } finally {
        ctx.commit('setShowPreloader', false)
      }
    },

    /**
     * Получение данных одной карты
     * @param {Object} ctx - Контекст Vuex
     * @param {Object} payload - Объект с параметрами запроса
     * @returns {Promise<void>}
     */
    async fetchCard(ctx, payload) {
      try {
        const { data } = await getCard(payload)
        ctx.commit('set_card', formatFetchCard(data))
        const {
          pupil: { id, school }
        } = data

        ctx.dispatch('fetchBalanceHistories', {
          pupil_id: id,
          school_id: school
        })
      } catch (err) {
        console.error(err)
      } finally {
        ctx.commit('setShowPreloader', false)
      }
    },

    /**
     * Обновление данных карты
     * @param {Object} ctx - Контекст Vuex
     * @param {Object} payload - Объект с параметрами запроса
     * @returns {Promise<void>}
     */
    async updateCard(ctx, payload) {
      try {
        const { data } = await updateCard(payload)
        ctx.commit('set_card', formatFetchCard(data))
      } catch (err) {
        console.error(err)
      } finally {
        ctx.commit('setShowPreloader', false)
      }
    },

    /**
     * Создание новой карты
     * @param {Object} ctx - Контекст Vuex
     * @param {Object} payload - Объект с параметрами запроса
     * @returns {Promise<void>}
     */
    async createCard(ctx, payload) {
      try {
        const { data } = await createCard(payload)
        ctx.commit('set_card', formatFetchCard(data))
      } catch (err) {
        console.error(err)
      } finally {
        ctx.commit('setShowPreloader', false)
      }
    },

    /**
     * Получение списка учеников для школы
     * @param {Object} ctx - Контекст Vuex
     * @param {Object} payload - Объект с параметрами запроса
     * @returns {Promise<void>}
     */
    async getPupils(ctx, payload) {
      try {
        const {
          data: { data }
        } = await getPupilsBySchool(payload)
        console.log(data)
        ctx.commit('set_pupil_list', data)
      } catch (e) {
        console.error(e)
      } finally {
        ctx.commit('setShowPreloader', false)
      }
    },

    /**
     * Получение списка родителей для школы
     * @param {Object} ctx - Контекст Vuex
     * @param {Object} payload - Объект с параметрами запроса
     * @returns {Promise<void>}
     */
    async getParents(ctx, payload) {
      try {
        const {
          data: { data }
        } = await getParentsBySchool(payload)
        ctx.commit('set_parent_list', data)
      } catch (e) {
        console.error(e)
      } finally {
        ctx.commit('setShowPreloader', false)
      }
    }
  },

  mutations: {
    /**
     * Установка данных карты в состояние
     * @param {Object} state - Состояние Vuex
     * @param {Object} card - Объект с данными карты
     */
    set_card(state, card) {
      state.card = { ...card }
    },

    /**
     * Установка списка карт в состояние
     * @param {Object} state - Состояние Vuex
     * @param {Array} cards - Массив объектов с данными карт
     */
    set_cards(state, cards) {
      state.cards = cards
    },

    /**
     * Установка списка учеников в состояние
     * @param {Object} state - Состояние Vuex
     * @param {Array} list - Массив объектов с данными учеников
     */
    set_pupil_list(state, list) {
      state.pupil_list = list
    },

    /**
     * Установка списка родителей в состояние
     * @param {Object} state - Состояние Vuex
     * @param {Array} list - Массив объектов с данными родителей
     */
    set_parent_list(state, list) {
      state.parent_list = list
    },

    /**
     * Установка данных пагинации для карт
     * @param {Object} state - Состояние Vuex
     * @param {Object} data - Объект с данными пагинации
     */
    set_cards_pagination(
      state,

      data
    ) {
      state.pagination = {
        ...state.pagination,
        ...data
      }
    },

    /**
     * Установка фильтров для карт
     * @param {Object} state - Состояние Vuex
     * @param {Object} data - Объект с фильтрами
     */
    set_cards_filters(state, data) {
      state.cards_filters = {
        ...state.cards_filters,
        ...data
      }
    },

    /**
     * Установка фильтров для транзакций
     * @param {Object} state - Состояние Vuex
     * @param {Object} data - Объект с фильтрами
     */
    set_transaction_filters(state, data) {
      state.transaction_filters = {
        ...state.transaction_filters,
        ...data
      }
    },

    /**
     * Сброс фильтров транзакций к значениям по умолчанию
     * @param {Object} state - Состояние Vuex
     */
    reset_transaction_filters(state) {
      state.transaction_filters = {
        max_date: null,
        min_date: null,
        is_bank_transactions: { value: null, name: 'Все' },
        ordering: { value: null, name: 'По умолчанию' }
      }
    },

    /**
     * Установка данных пагинации для транзакций
     * @param {Object} state - Состояние Vuex
     * @param {Object} data - Объект с данными пагинации
     */
    set_transaction_pagination(state, data) {
      state.transactions_pagination = {
        ...state.transactions_pagination,
        ...data
      }
    },

    /**
     * Установка истории баланса в состояние
     * @param {Object} state - Состояние Vuex
     * @param {Array} data - Массив объектов с историей баланса
     */
    set_balance_histories(state, data) {
      state.balance_histories = data
    },

    /**
     * Сброс фильтров карт к значениям по умолчанию
     * @param {Object} state - Состояние Vuex
     */
    reset_cards_filters(state) {
      state.cards_filters = {
        is_blocked: { value: null, name: 'Все' },
        is_deleted: { value: null, name: 'Все' },
        is_active: { value: null, name: 'Все' },
        role: { value: null, name: 'Все' }
      }
    },

    /**
     * Сброс данных карты к значениям по умолчанию
     * @param {Object} state - Состояние Vuex
     */
    reset_card(state) {
      state.card = {
        is_blocked: false,
        is_active: false,
        is_guest: false,
        is_admin: false,
        is_parent: false,
        expires: '',
        issued: '',
        pupil: {},
        last_name: '',
        first_name: '',
        middle_name: '',
        role: '',
        balance_histories: []
      }
    }
  },

  getters: {
    /**
     * Получение списка карт из состояния
     * @param {Object} state - Состояние Vuex
     * @returns {Array} Список карт
     */
    cards(state) {
      return state.cards
    },

    /**
     * Получение текущей карты из состояния
     * @param {Object} state - Состояние Vuex
     * @returns {Object} Текущая карта
     */
    card(state) {
      return state.card
    },

    /**
     * Получение списка учеников из состояния
     * @param {Object} state - Состояние Vuex
     * @returns {Array} Список учеников
     */
    pupil_list(state) {
      return state.pupil_list
    },

    /**
     * Получение списка родителей из состояния
     * @param {Object} state - Состояние Vuex
     * @returns {Array} Список родителей
     */
    parent_list(state) {
      return state.parent_list
    },

    /**
     * Получение данных пагинации для карт из состояния
     * @param {Object} state - Состояние Vuex
     * @returns {Object} Данные пагинации для карт
     */
    cards_pagination(state) {
      return state.pagination
    },

    /**
     * Получение данных пагинации для транзакций из состояния
     * @param {Object} state - Состояние Vuex
     * @returns {Object} Данные пагинации для транзакций
     */
    transactions_pagination(state) {
      return state.transactions_pagination
    },

    /**
     * Получение фильтров для карт из состояния
     * @param {Object} state - Состояние Vuex
     * @returns {Object} Фильтры для карт
     */
    cards_filters(state) {
      return state.cards_filters
    },

    /**
     * Получение истории баланса из состояния
     * @param {Object} state - Состояние Vuex
     * @returns {Array} История баланса
     */
    balance_histories(state) {
      return state.balance_histories
    },

    /**
     * Получение фильтров для транзакций из состояния
     * @param {Object} state - Состояние Vuex
     * @returns {Object} Фильтры для транзакций
     */
    transaction_filters(state) {
      return state.transaction_filters
    }
  },

  state: {
    cards: {},
    guest_cards: {},
    isProgress: false,
    pagination: {
      page: 1,
      page_size: 10
    },
    transactions_pagination: {
      page: 1,
      page_size: 10, // по умолчанию
      count: 0,
      total: 0
    },
    card: {
      is_blocked: false,
      is_active: false,
      is_guest: false,
      is_admin: false,
      is_parent: false,
      expires: '',
      issued: '',
      pupil: {},
      last_name: '',
      first_name: '',
      middle_name: '',
      role: ''
    },
    cards_filters: {
      is_blocked: { value: null, name: 'Все' },
      is_deleted: { value: null, name: 'Все' },
      is_active: { value: null, name: 'Все' },
      role: { value: null, name: 'Все' }
    },
    transaction_filters: {
      max_date: null,
      min_date: null,
      is_bank_transactions: { value: null, name: 'Все' },
      ordering: { value: null, name: 'По умолчанию' }
    },
    cards_pupil: [],
    pupil_list: [],
    parent_list: [],
    balance_histories: []
  }
}
