<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <div class="mb-3 d-flex">
        <h1>{{ title }}</h1>
        <context-help-modal :context-key="contextKeys.supplier.ordersInfo" />
      </div>
    </v-layout>
    <v-btn
        flat
        class="mb-2"
        @click="$router.push( {name: 'OrganizerArchiveOrderSchoolView'} )"
    >Показать архив заявок</v-btn>
    <v-layout
      row
      mb-3
      justify-space-between
    >
      <v-flex md2>
        <DatePickersSmart
          :startDate.sync="dateStart"
          :endDate.sync="dateEnd"
          @changeDate="changeDate"
        />
      </v-flex>
      <v-flex
        md4
        text-md-right
      >
        <v-btn
          flat
          @click="loadData()"
          class="primary"
        >показать</v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      class="fs-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.date.formatted }}</td>
              <td>{{ props.item.name }}</td>
              <td>
                <v-flex row>
                  <with-tooltip text="Сформировать файл с меню по указанному дню">
                    <v-btn
                        flat
                        @click="menuPrint(props.item.date.default)"
                        class="primary"
                    >печать меню на день</v-btn>
                  </with-tooltip>
                  <with-tooltip text="Сформировать файл с меню по указанному дню (в разрезе школ)">
                    <v-btn
                        flat
                        @click="print('newForm', props.item.date.default, props.item.id)"
                        class="primary"
                    >печать в разрезе школ новая форма</v-btn>
                  </with-tooltip>
                  <with-tooltip text="Сформировать файл  сменю по указанному дню (в разрезе корпусов)">
                    <v-btn
                        flat
                        @click="print('newFormWithHouses', props.item.date.default, props.item.id)"
                        class="primary"
                    >печать в разрезе школ по корпусам</v-btn>
                  </with-tooltip>
                  <with-tooltip text="Сформировать файл с меню по указанному дню (старая форма)">
                    <v-btn
                        flat
                        @click="print('oldForm', props.item.date.default, props.item.id)"
                        class="primary"
                    >печать в разрезе школ старая форма</v-btn>
                  </with-tooltip>
                  <v-btn
                    flat
                    class="fs-btn fs-btn--secondary"
                    @click="$router.push({name: 'OrganizerOrderClassView', params: {
                         schoolId: props.item.id,
                         date: props.item.date.default,
                          }})"
                  >подробнее</v-btn>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import _ from 'lodash'
import {
  getSchools,
  printMenu,
  getOrderReport,
  getOrderReportStatus,
  getOrderReportDownload
} from '@/router/routes/organizer'
import { mapMutations } from 'vuex'
import store from '@/store'
import { DatePickersSmart } from '@/components/common'
import WithTooltip from "@/components/common/Tooltip/Tooltip";
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import asyncReqestsForDownload from "@/helpers/asyncReqestsForDownloadWithProgressBar";
import notify from "@/utils/notifications/toastNotification.vue";

export default {
  name: 'OrganizerOrderIndex',
  components: {
    ContextHelpModal,
    WithTooltip,
    DatePickersSmart
  },
  data() {
    return {
      title: 'Заявки на питание',
      contextKeys: Vue.config.contextHelp,
      datepickerMenu: false,
      datepickerMenu1: false,
      dateRanges: [],
      dateStart: moment()
        .subtract('days', 1)
        .format(Vue.config.format.serverDate),
      dateEnd: moment()
        .add('days', 1)
        .format(Vue.config.format.serverDate),
      headers: [
        { text: 'Дата', value: 'date.formatted' },
        { text: 'ОО', value: 'name' },
        { text: 'Операции', value: 'operations' }
      ],
      items: []
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    changeDate(payload) {},
    loadData() {
      this.formDatesForSchoolOrders()
      this.items = []
      getSchools()
        .then(response => {
          _.forEach(this.dateRanges, date => {
            _.forEach(response.data.data, school => {
              this.items.push({
                id: school.id,
                name: school.name,
                date: {
                  default: moment(date).format(Vue.config.format.serverDate),
                  formatted: moment(date).format(Vue.config.format.date)
                }
              })
            })
          })
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    async print(reportType, date, schoolId) {
      const payload = {
        order_day: moment(date).format(Vue.config.format.serverDate),
        reportType: reportType,
        schoolId
      }
      const callback = () => false
      try {
        await asyncReqestsForDownload(
          getOrderReport,
          getOrderReportStatus,
          getOrderReportDownload,
          payload,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки документа')
      } finally {
        callback()
        store.commit('setShowPreloader', false)
      }
    },
    menuPrint(date) {
      const name =
        'Меню на' + moment(date).format(Vue.config.format.date) + '.xlsx'
      const payload = { date: date }
      printMenu(payload)
        .then(response => {
          const file = new Blob([ response.data ], { type: response.data.type })
          const url = window.URL.createObjectURL(file)
          const a = document.createElement('a')
          a.href = url
          a.download = name
          a.click()
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    formDatesForSchoolOrders() {
      const daysQuantity = moment(this.dateEnd).diff(
        moment(this.dateStart),
        'days'
      )
      this.dateRanges = []
      for (let i = 0; i <= daysQuantity; i++) {
        this.dateRanges.push(
          moment(this.dateStart)
            .add(i, 'days')
            .format(Vue.config.format.serverDate)
        )
      }
    }
  }
}
</script>
