<template>
  <div>
    <v-toolbar
      :class="'fs-toolbar scrollx ' + classNameComputed"
      :dark="classNameComputed !== 'fs-toolbar--grey'"
      style="overflow-y: hidden;"
    >
      <template v-for="(item, index) in toolbarItems">
        <v-toolbar-items
          v-if="item.isSubmenu"
          :key="index"
          class="fs-toolbar-items"
        >
          <v-menu class="pa-0" transition="slide-y-transition" bottom offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="item.isDisabled"
                class="fs-toolbar__item"
                tag="a"
                flat
              >
                <span>{{ item[itemTitle] }}</span>
                <v-icon v-text="'arrow_drop_down'"></v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile
                v-for="(children, _index) in item.children"
                :key="_index"
                :to="{ name: children.link, params: children.params }"
                class="fs-toolbar__item"
              >
                <v-list-tile-title>{{ children[itemTitle] }}</v-list-tile-title>
                <v-badge
                  v-if="children.hasBadge && getNotificationsNoReadCount"
                  :class="children.badgeClass"
                  style="top: -10px;"
                >
                  <template v-slot:badge>{{ getNotificationsNoReadCount }}</template>
                </v-badge>
              </v-list-tile>
            </v-list>
          </v-menu>
          <v-badge
            v-if="item.hasBadge && getNotificationsNoReadCount"
            :class="item.badgeClass || 'menu-notification'"
          >
            <template v-slot:badge>{{ getNotificationsNoReadCount }}</template>
          </v-badge>
        </v-toolbar-items>
        <div
          v-else
          :key="index"
          class="fs-toolbar-items v-toolbar__items"
        >
          <v-btn
            :disabled="item.isDisabled"
            :to="{ name: item.link, params: item.params }"
            class="fs-toolbar__item"
            flat
          >{{ item[itemTitle] }}</v-btn>
          <v-badge
              v-if="item.hasBadge && getNotificationsNoReadCount"
              :class="item.badgeClass || 'menu-notification'"
          >
            <template v-slot:badge>{{ getNotificationsNoReadCount }}</template>
          </v-badge>
        </div>
      </template>
	    <slot name="newMenuItem"></slot>
    </v-toolbar>
    <span
      v-if="classNameComputed === 'fs-toolbar-parent'"
      class="fs-toolbar-parent__divider"
    ></span>
    <div>
      <loader
        :value="showPreloader"
        :message="loaderMessage"
      ></loader>
    </div>
    <ConfirmModal :dialog.sync="showNotifyChildBalanceModal" hideCancelBtn>
      <template v-slot:headline>Внимание</template>
      <template v-slot:body>
        <div v-html="'Пожалуйста, не забудьте пополнить счёт'"></div>
      </template>
    </ConfirmModal>
  </div>
</template>

<script>
import Vue from 'vue'
import ChildrenFactory from '@/factories/parent/children'
import { mapGetters } from 'vuex'
import { getSettings } from '@/router/routes/parent'
import { ConfirmModal } from '@/components/common'
import store from "@/store"

export default {
  name: 'MainMenu',
  props: {
    itemTitle: {
      type: String,
      default: 'title'
    },
    toolbarItems: {
      required: true,
      type: Array,
      default: () => ([])
    },
    className: {
      required: false,
      type: String,
      default: ''
    },
    isChildView: {
      type: Boolean,
      default: false
    },
  },
  components: { ConfirmModal },
  data() {
    return {
      currentChild: new ChildrenFactory()[0],
      children: new ChildrenFactory(),
      darkTheme: Vue.config.env.darkTheme,
      showNotifyChildBalanceModal: false,
      limitBalance: false
    }
  },
  computed: {
    ...mapGetters({
      'showPreloader': 'showPreloader',
      'loaderMessage': 'loaderMessage',
      'currentUser': 'currentUser',
      'curChild': 'currentChild',
      'notificationsCount': 'notificationsCount'
    }),
    classNameComputed() {
      return this.darkTheme ? 'menu-another-theme' : this.className
    },
    getNotificationsNoReadCount() {
      if (this.notificationsCount > 50) {
        return '50+'
      } else {
        return this.notificationsCount
      }
    }
  },
  methods: {
    getMenuChilds(menuItem) {
      if (!menuItem.childs) return []
      return this.toolbarItems.filter(v => menuItem.childs.includes(v.link))
    },
    itemHasParent(menuItem) {
      let res = false
      this.toolbarItems.forEach(v => {
        if (v.childs && v.childs.includes(menuItem.link)) {
          res = true
        }
      })
      return res
    }
  },
  watch: {
    currentChild() {
      this.toolbarItems.forEach(item => {
        if (item.link === 'PersonalArea') {
          item.params = { id: localStorage.getItem('childId') }
        }
      })
    },
    'curChild.id'(id, oldId) {
      if (id !== oldId) {
        this.limitBalance = null
        if (!this.isChildView) {
          getSettings(id)
            .then(res => {
              const { isNotify, notificationSum } = res.data.data
              if (isNotify && this.curChild.balance <= notificationSum) {
                this.showNotifyChildBalanceModal = true
                this.limitBalance = notificationSum
                this.showNotifyChildBalanceModal = true
              }
            })
        }
      }
    }
  }
}
</script>
