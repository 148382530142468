<template>
  <v-card class='d-flex flex-column'>
    <v-toolbar dense light flat>
      <v-toolbar-title>Оценить меню {{ menu.date }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click.prevent="closeModal">
        <v-icon class="fs-active-icon">close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-spacer style='margin-top: 50px'/>

    <v-card-text v-if="controlForm.complects">
      <v-card v-for="complect in controlForm.complects" :key="complect.id" style="margin: 25px 0; padding: 10px;">
        <v-card-actions style="justify-content: space-between; margin-top: 5px;">
          <h2>Комплекс: {{complect.name}}</h2>
          <v-btn v-if="isComplectStatusDraft(complect) && !readonly" @click.prevent="updateComplectStatus(complect)">Отправить на проверку</v-btn>
          <span v-else-if="!readonly" style="margin: 10px 0 0; color: #ccc; font-size: 18px;">
            Статус: Отправлено
          </span>
        </v-card-actions>

        <v-list class="food-wrap" v-for="food in complect.foods" :key="food.id" style="display: flex; align-items: center;">
          <v-subheader class="food-wrap__title">{{food.name}}</v-subheader>

          <v-list-tile-content class="food-wrap__content">
            <AttachImages
              :readonly="!isComplectStatusDraft(complect) || readonly"
              v-model="food.images"
              multiple
              @deleteImage="handleDeleteImage"
              @uploadImage="(image) => handleUploadImage(image, food.id, complect.complect_control.id)"
            />
          </v-list-tile-content>

        </v-list>
      </v-card>
    </v-card-text>
    <v-card-text v-if='!this.controlId && !this.controlForm.id' class='d-flex flex-column align-center'>
      <v-card-title>Подтвердите создание фото-отчёта для меню от {{ menu.date }}</v-card-title>
      <v-btn @click='createControl'>Создать</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import { createQualityMenuControl, getQualityMenuControl, update_quality_complect_control } from '@/router/routes/common'
import AttachImages from '@/components/common/AttachFiles/AttachImages'
import { save_quality_food_image, delete_quality_food_image } from '@/router/routes/common'

export default {
  name: 'QualityControlModal',
  components: { AttachImages },
  props: {
    menu: {
      type: Object,
      default: null
    },
    controlId: {
      type: Number,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    darkTheme: Vue.config.env.darkTheme,
    CONTROL_STATUS: { DRAFT: 0, SENT: 1 },
    max_photo: 5,
    controlForm: {},
    editablePhoto: {
      complectId: null,
      foodId: null
    }
  }),
  computed: {
    ...mapGetters([ 'currentUser' ]),
    foodControlCreated() {
      return !this.controlId && this.controlForm.id
    }
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    closeModal() {
      const needUpdate = this.foodControlCreated
      this.$emit('close', needUpdate)
    },
    isComplectStatusDraft(complect) {
      return complect.complect_control.state === this.CONTROL_STATUS.DRAFT
    },
    handleDeleteImage(image) {
      delete_quality_food_image(image.id)
        .then(() => {
          this.getControl()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    },
    updateComplectStatus(complect) {
      update_quality_complect_control({
        id: complect.complect_control.id,
        data: {
          state: this.CONTROL_STATUS.SENT,
          complect: complect.id,
          food_control: this.controlForm.id
        }
      })
        .then(() => {
          this.getControl()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    },
    handleUploadImage(image, foodId, controlComplectId) {
      const formData = new FormData()
      formData.append("complect_control", controlComplectId)
      formData.append("food", foodId)
      formData.append("image", image)

      save_quality_food_image(formData)
        .then(() => {
          this.getControl()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    },
    async createControl() {
      await createQualityMenuControl({
        user: this.currentUser.user_id,
        menu: this.menu.id,
        comment: ""
      }).then(res => {
        this.controlForm = res.data
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.setShowPreloader(false)
      })
    },
    getControl() {
      getQualityMenuControl(this.controlForm.id)
        .then(res => {
          this.controlForm = res.data
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    }
  },
  async mounted() {
    this.controlForm.id = this.controlId
    if (this.controlForm.id) this.getControl()
  }
}
</script>

<style scoped>
.v-toolbar {
  position: fixed;
  z-index: 2;
  width:auto;
  left: 24px;
  right: 24px;
}

.food-wrap{
  padding: 5px 0;
  /* border: 1px solid #ddd; */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin: 10px 0 0;
}
.food-wrap__content {
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.food-wrap__photos {
  display: flex;
  margin-right: 5px;
}

.food-wrap__photos div {
  width: 120px;
  height: 100px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.food-wrap__title {
  font-size: 16px;
  font-weight: 600;
  color: #969696;
}
</style>
