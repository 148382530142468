<template>
  <v-container fluid class="fs-teacher-container">

      <v-card-title style="display: flex; justify-content: space-between;">
        <h1>{{syrvey_name}} | Вопросы</h1>
        <v-btn
          flat
          @click="createQuestion"
          class="fs-btn fs-btn--primary fs-btn__add-dish"
        >Создать вопрос</v-btn>
      </v-card-title>

      <!-- {{syrvey_detail}} -->

      <v-layout class="fs-teacher-order-result" mb-3>
        <v-flex>
          <v-data-table
              :headers="headers"
              :items.sync="syrvey_detail.questions"
              hide-actions
              no-data-text="Нет вопросов"
              class="elevation-1 fs-admin-table"
              item-key="name"
              style="cursor: pointer;"
          >
          <template v-slot:items="props">
              <tr @click="() => {}">
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ transformDate(props.item.created_at) }}</td>
              <td>{{ props.item.is_several ? 'Да' : 'Нет' }}</td>
              <td>{{ props.item.is_comment ? 'Да' : 'Нет' }}</td>
              <td>
                <v-flex row>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="editQuestion(props.item.id)"
                      >edit</v-icon>
                    </template>
                    <span>Редактирование</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="handleDeleteQuestion(props.item.id)"
                      >delete</v-icon>
                    </template>
                    <span>Удалить</span>
                  </v-tooltip>
                </v-flex>
              </td>
              </tr>
          </template>
          </v-data-table>
        </v-flex>
        </v-layout>

    <v-dialog v-model="show_question" persistent width="100%">
      <SyrveyManageQuestionDetail
        v-if="show_question"
        :is_create="is_create"
        :syrvey_id="syrvey_detail.id"
        :question_id="question_id"
        @close="closeModal"
      />
    </v-dialog>

    <ConfirmModal
      :dialog.sync="confirmDeleteModal"
      @confirm="handlerConfirmModal"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
    >
      <template v-slot:headline>Вы действительно хотите удалить вопрос?</template>
    </ConfirmModal>

  </v-container>
</template>

<script>
import Vue from 'vue'

import moment from 'moment'
import SyrveyManageQuestionDetail from '@/components/admin/Syrvey/SyrveyManageQuestionDetail'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ConfirmModal from '@/components/common/Modals/ConfirmModal'
export default {
  name: 'SyrveyDetail',
  components: { SyrveyManageQuestionDetail, ConfirmModal },
  data: () => ({
    confirmDeleteModal: false,

    syrvey_id: null,
    show_question: false,
    is_create: false,
    question_id: null,
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Наименование вопроса', value: 'name' },
      { text: 'Дата создания', value: 'created_at' },
      { text: 'Несколько вариантов ответов', value: 'answers' },
      { text: 'Ответ комментарий', value: 'is_published' },
      { text: 'Управление', value: 'manage' }
    ],
  }),
  computed: {
    ...mapGetters([ 'syrvey_detail' ]),
    syrvey_name() {
      return this.syrvey_detail.name
    }
  },
  methods: {
    ...mapActions([ 'getSyrveyDetail', 'deleteQuestion' ]),
    ...mapMutations([ 'clearSyrveyDetail' ]),
    transformDate(date) {
      return moment(date).format(Vue.config.format.date)
    },
    createQuestion() {
      this.is_create = true
      this.show_question = true
    },
    editQuestion(id) {
      this.is_create = false
      this.show_question = true
      this.question_id = id
    },
    handleDeleteQuestion(id) {
      this.confirmDeleteModal = true
      this.question_id = id
    },
    closeModal() {
      this.is_create = false
      this.show_question = false
      this.question_id = null
      this.getSyrveyDetail(this.syrvey_id)
    },
    handlerConfirmModal(value) {
      if (value) {
        this.deleteQuestion(this.question_id).then(() => {
          this.getSyrveyDetail(this.syrvey_id)
        })
      } else {
        this.confirmDeleteModal = false
      }
      this.question_id = null
    },
  },
  mounted() {
    this.syrvey_id = this.$route.params.syrvey_id
    if (!this.syrvey_id) this.$router.push({ name: 'SyrveyManage' })
    else { 
      this.getSyrveyDetail(this.syrvey_id)
    }
  },
  beforeDestroy() {
    this.clearSyrveyDetail()
  }
}
</script>

<style>

</style>