import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  state: {
    settings: {},
    foodTimes: [],
    foodPeriods: [],
    foodTypes: [],
    foodCategories: [],
    ageCategories: [],
    shifts: [],
    concessions: [],
    notifications: [],
    notificationsCount: null,

    qualityMenuPhotoReportsFilters: {
      date_start: null,
      date_finish: null,
      supplier_id: null,
      only_with_images: false
    },
    qualityMenuPhotoReportsPagination: {
      page: 1,
      page_size: 10
    },
    qualityMenuPhotoReports: {},

    qualityAssesmentsPagination: {
      page: 1,
      page_size: 10
    },
    qualityAssesmentsFilters: {
      school: null,
      date_start: null,
      date_finish: null,
      lower_evaluation: null,
      upper_evaluation: null,
      supplier_id: null
    },
    qualityAssesments: {},

    qualityAvgSuppliers: {},
    qualityAvgSuppliersPagination: {
      page: 1,
      page_size: 10
    },
    qualityAvgSuppliersFilters: {
      fallen: null,
      avg_evaluation: null,
      search: null,
      ordering: null
    },

  },
  getters: getters,
  mutations: mutations,
  actions: actions
}
