<template>
  <v-app>
    <main-menu
      :toolbarItems="menuItems"
      :title="menuTitle"
    ></main-menu>
    <transition name="slide-fade">
      <router-view></router-view>
    </transition>
  </v-app>
</template>

<script>
import MainMenu from '@/layouts/common/Main'
import MenuFactory from '@/factories/moduleadmin/menu'
import store from "@/store"

export default {
  name: 'ModuleAdminIndex',
  components: {
    'main-menu': MainMenu
  },
  data() {
    return {
      menuItems: new MenuFactory(store.getters.settings),
      menuTitle: 'Главная'
    }
  },
  mounted() {
    this.saveSuperAdminAuth()
  },
  methods: {
    saveSuperAdminAuth() {
      sessionStorage.setItem('superadmin_token', sessionStorage.getItem('token'))
      sessionStorage.setItem('superadmin_user_id', sessionStorage.getItem('user_id'))
    }
  }
}
</script>
