<script>
import {doUserAuthEsia} from "@/router/routes/organizer";
import store from "@/store";
import setToken from "@/helpers/localStorage"
import localStorageHelpers from "@/helpers/localStorage";

export default {
  name: 'EsiaSuccessConnect',
  data() {
    return {
      isError: false,
      counter: 5
    }
  },
  async mounted() {
    await this.getUserAuth()
  },
  methods: {
    async getUserAuth() {
      if (this.$route.query.authCode) {
        const code = this.$route.query.authCode
        try {
          const { data } = await doUserAuthEsia(code)
          localStorage.setItem('user_id', data.user_id)
          localStorageHelpers.__setToken(data.token)
          console.log(data.token)
          setInterval(() => {
            this.counter--
          }, 1000)
        } catch (e) {
          this.isError = true
          console.error(e)
        }
      } else {
        this.isError = true
      }
      store.commit('setShowPreloader', false)
    }
  },
  watch: {
    counter(cnt) {
      if (cnt === 0) {
        document.location.href = '/organizers/dashboard/'
      }
    }
  }
}
</script>

<template>
<v-container>
  <div v-if="!isError">Вы успешно авторизовались! Перенаправление в кабинет через {{ counter }}...</div>
  <div v-else>
    <div>Не удалось авторизоваться</div>
    <div><a href="/">Вернуться</a></div>
  </div>
</v-container>
</template>

<style scoped>

</style>