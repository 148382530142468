import { Menu } from '@/lib/menu'
import store from '@/store'

export default class MainMenuFactory {
  constructor(settings) {
    const currentUser = store.getters.currentUser
    const isBoardingSchool = currentUser.department?.level === 2

    let menu = [
      new Menu('Новости', 'Новости обновлений', 'UpdatesNewsDepartment'),
      new Menu(
        'Организаторы питания',
        'Просмотр и редактирование организаторов питания',
        'MunicipalityOrganizersIndex'
      ),
      new Menu(
        'Образовательные организации',
        'Перечень образовательных организаций в рамках муниципалитета, привязка организаторов питания',
        'EducationalOrganizations'
      ),
      new Menu(
        'Социальные выплаты',
        'Просмотр и редактирование социальных выплат',
        'MunicipalityConcessions'
      ),
      new Menu(
        'Типы питания',
        'Просмотр и редактирование типов питания',
        'MunicipalityFoodTypes'
      ),

      new Menu('Отчёты', 'Отчёты', 'MunicipalityReportsIndex')
    ]

    if (!isBoardingSchool) {
      menu.push(
        new Menu(
          'Возрастные категории',
          'Просмотр и редактирование возрастных категорий',
          'MunicipalityAgeCategories'
        ),
        new Menu(
          'Периоды кормления',
          'Просмотр и редактирование периодов кормления',
          'MunicipalityFoodPeriods'
        ),
        new Menu(
          'Время кормления',
          'Просмотр и редактирование времени кормления',
          'MunicipalityFoodTimes'
        ),
        new Menu(
          'Уведомления',
          'Просмотр уведомлений',
          'MunicipalityNotifications',
          true
        ),
        new Menu('Заявки', 'Просмотр заявок', 'MunicipalityOrders'),
        new Menu(
          'Список учеников',
          'Просмотр информации об учениках',
          'MunicipalityStudents'
        ),
        new Menu(
          'Движение денежных средств',
          'Просмотр информации о пополнении и списании денежных средств',
          'MunicipalityBalanceMoving'
        )
      )

      if (settings.mod_buffet?.value) {
        menu.push(
          new Menu(
            'Результаты опросов',
            'Результаты опросов',
            'DepartmentSyrveyResult'
          ),
          new Menu(
            'Паспорт пищеблока',
            'Паспорт пищеблока',
            'MunicipalityFoodMealPassports'
          )
        )
      }

      if (settings.mod_quality_control?.value) {
        menu.push(
          new Menu(
            'Контроль качества',
            'Контроль качества',
            'MunicipalityQualityControlWrap'
          )
        )
      }
    }

    if (settings.user_help?.value) {
      menu = menu.concat([
        // new Menu('Инструкции', 'Инструкции', 'MunicipalityUserHelp'),
        new Menu('Справка', 'Справка', 'HelpDeskMU')
      ])
    }

    return menu
  }
}
