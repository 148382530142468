<template>
  <v-container class="fs-organizer-container">
    <v-flex md3 mb-5>
      <h1>{{title}}</h1>
    </v-flex>
    <v-form>
      <v-layout row justify-space-around>
        <v-flex md5>
          <v-text-field label="Наименование" :value="item.name || ''"></v-text-field>
          <v-text-field label="Цена, руб (на все блюда)" :value="convertToRubles(item.price || '')"></v-text-field>
          <v-text-field
            label="Энергетическая ценность, кКал/кДж (на 100г)"
            :value="item.energy || ''"
          ></v-text-field>
        </v-flex>
        <v-flex md5>
          <v-text-field label="Белки, г (на 100г)" :value="item.protein || ''"></v-text-field>
          <v-text-field label="Жиры, г (на 100г)" :value="item.fats || ''"></v-text-field>
          <v-text-field label="Углеводы, г (на 100г)" :value="item.carbs || ''"></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex>
          <v-data-table
            :headers="dishAgeCategoryHeaders"
            :items="dishAgeCategoryItems"
            hide-actions
            class="elevation-1 fs-admin-table"
          >
            <template v-slot:items="props">
              <tr>
                <td>{{ props.item.ageCategory }}</td>
                <td>{{ props.item.weight }} гр.</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <!--<v-flex text-md-right>
        <v-btn class="fs-btn">отмена</v-btn>
        <v-btn class="fs-btn primary fs-form-footer__btn">сохранить</v-btn>
      </v-flex>-->
    </v-form>
  </v-container>
</template>

<script>
import numable from "@/mixins/balancable"

export default {
  name: "DishView",
  mixins: [ numable ],
  data() {
    return {
      item: this.$route.query,
      dishAgeCategoryHeaders: [
        { text: "Возрастная категория", value: "age-category" },
        { text: "Выход блюда, г", value: "weight" }
      ],
      dishAgeCategoryItems: [
        {
          id: 1,
          ageCategory: "7-11 лет",
          weight: 50,
          price: "50"
        },
        {
          id: 2,
          ageCategory: "12 лет и старше",
          weight: 100,
          price: "100"
        }
      ]
    }
  },
  computed: {
    title() {
      return `Блюдо ${this.item.id}`
    }
  }
}
</script>
