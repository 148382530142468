<template>
  <v-dialog max-width="700px" v-model="dialog">
    <v-card>
      <v-card-text>
        <v-layout row wrap>
          <v-flex style="margin: 5px 0" v-for="(item, index) in errors" key xs12>
            <span class="font-weight-bold error--text">{{item.order_date}}</span>
            <span style="padding-left: 10px">{{item.error}}</span>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="closeDialog">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "OrderErrors",
  props: {
    errors: {
      type: Array
    },
    dialog: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:dialog', false)
    }
  }
}
</script>

<style scoped>

</style>