import ChildrenStartPage from '@/components/admin/StartPage/Children'

import AdminStartPage from '@/components/admin/StartPage/Index'
import AdminOrdersTableIndex from '@/components/admin/Orders/Index'
import AdminOrdersItem from '@/components/admin/Orders/Item'
import AdminStudentsIndex from '@/components/admin/Students/Index'
import AdminStudentsEdit from '@/components/common/PupilCard/Main'
import AdminClassesIndex from '@/components/admin/Classes/Index'
import AdminClassesItem from '@/components/admin/Classes/Item'
import AdminOrganizerCard from '@/components/admin/OrganizerCard/Index'
import AdminDishIndex from '@/components/admin/Dish/Index'
import AdminDishView from '@/components/admin/Dish/View'
import AdminDishEdit from '@/components/admin/Dish/Edit'
import AdminDishCreate from '@/components/admin/Dish/Create'
import AdminComplexIndex from '@/components/admin/Complex/Index'
import AdminComplexEdit from '@/components/admin/Complex/Edit'
import AdminComplexView from '@/components/admin/Complex/View'
import AdminComplexCreate from '@/components/admin/Complex/Create'
import AdminOrganizersIndex from '@/components/admin/Organizers/Index'
import AdminTeachersIndex from '@/components/admin/Teachers/Index'
import AdminTeachersCard from '@/components/admin/Teachers/Item'
import AdminOrdersPrintIndex from '@/components/admin/Orders/Print/Index'
import AdminConcessionsIndex from '@/components/admin/Directories/Concessions'
import AdminFoodTypesIndex from '@/components/admin/Directories/FoodTypes'
import AdminBalanceMovingIndex from '@/components/admin/Balance/Moving/Index'
import AdminSettingsIndex from '@/components/admin/Settings/Index'
import AdminFoodTimesIndex from '@/components/admin/Directories/FoodTimes'
import AdminReportsIndex from '@/components/admin/Reports/Index'
import AdminNotificationsCreate from '@/components/admin/Notifications/Create'

import SyrveyManage from '@/components/admin/Syrvey/SyrveyManage'
import SyrveyManageQuestions from '@/components/admin/Syrvey/SyrveyManageQuestions'
import AdminSyrveyResult from '@/components/admin/Syrvey/SyrveyResult'
import AdminSyrveyDetail from '@/components/admin/Syrvey/AdminSyrveyDetail'
import AdminQualityControlWrap from '@/components/common/QualityControl/Index'

import AdminFoodBlock from '@/components/admin/AdminFoodBlock/Index'
import AdminCards from '@/components/admin/Cards/CardsView.vue'
import AdminCardDetail from '@/components/admin/Cards/CardDetail'
import AdminStorehouse from '@/components/admin/Storehouse/Storehouse'
import AdminStorehouseDetail from '@/components/admin/Storehouse/StorehouseDetail'
import AdminNpaDocuments from '@/components/admin/NpaDocuments/NpaDocuments'
import AdminNpaCertificate from '@/components/admin/NpaDocuments/NpaCertificate'
import AdminProductCategories from '@/components/admin/ProductCategories/Index'
import AdminBuffetBalanceAdd from '@/components/admin/BuffetBalance/Index'
import AdminBuffetProductsApplications from '@/components/admin/BuffetProductsApplications/Index'
import UpdatesNewsAdmin from '@/components/admin/UpdatesNews/Index'
import DashboardAdmin from '@/components/admin/Dashboard/Index'
import LoadingOrders from '@/components/admin/LoadingOrders/Index'
import LoadingOrdersDetail from '@/components/admin/LoadingOrders/LoadingOrdersDetail'
import UserHelp from '@/components/common/UserHelp/Index'
import WorkingDays from '@/components/admin/WorkingDays/Index'
import AdminChangeMealType from '@/components/admin/ChangeMealType/Index'

import NotificationIndex from '@/components/common/NotificationsComponent/Index.vue'
import HelpDesk from "@/components/common/HelpDesk/HelpDesk.vue"

export default [
  {
    path: '/',
    name: 'Administrator',
    component: AdminStartPage
  },
  {
    path: 'dashboard-admin',
    name: 'DashboardAdmin',
    component: DashboardAdmin
  },
  {
    path: 'loading-orders',
    name: 'LoadingOrders',
    component: LoadingOrders
  },
  {
    path: 'loading-orders-detail',
    name: 'LoadingOrdersDetail',
    component: LoadingOrdersDetail
  },
  {
    path: 'working-days',
    name: 'AdminWorkingDays',
    component: WorkingDays
  },
  {
    path: 'change-meal-type',
    name: 'AdminChangeMealType',
    component: AdminChangeMealType
  },
  {
    path: 'updates',
    name: 'UpdatesNewsAdmin',
    component: UpdatesNewsAdmin
  },
  {
    path: 'user-help',
    name: 'AdminUserHelp',
    component: UserHelp
  },
  {
    path: 'helpdesk',
    name: 'HelpDeskMA',
    component: HelpDesk,
    meta: { userRole: 'school_admin' }
  },
  {
    path: 'orders',
    name: 'AdminOrders',
    component: AdminOrdersTableIndex
  },
  {
    path: 'orders/:id/view',
    name: 'AdminOrdersTableView',
    component: AdminOrdersItem,
    meta: 'view'
  },
  {
    path: 'orders/:id/edit',
    name: 'AdminOrdersTableEdit',
    component: AdminOrdersItem,
    meta: 'edit'
  },
  {
    path: 'organizers/organizer-card',
    name: 'AdminOrganizerCard',
    component: AdminOrganizerCard
  },
  {
    path: 'organizers',
    name: 'AdminOrganizers',
    component: AdminOrganizersIndex
  },
  {
    path: 'complexes/create',
    name: 'AdminComplexesCreate',
    component: AdminComplexCreate
  },
  {
    path: 'print',
    name: 'AdminOrdersPrint',
    component: AdminOrdersPrintIndex
  },
  {
    path: 'balance/moving',
    name: 'AdminBalanceMoving',
    component: AdminBalanceMovingIndex
  },
  {
    path: 'settings',
    name: 'AdminSettings',
    component: AdminSettingsIndex
  },
  {
    path: 'reports',
    name: 'AdminReportsIndex',
    component: AdminReportsIndex
  },
  {
    path: 'notifications',
    name: 'AdminNotifications',
    component: NotificationIndex,
    meta: {
      layoutClass: 'fs-teacher-container'
    }
  },
  {
    path: 'notifications/create',
    name: 'NotificationsCreateAdmin',
    component: AdminNotificationsCreate
  },
  {
    path: 'cards',
    name: 'Cards',
    component: AdminCards
  },
  {
    path: 'food-block',
    name: 'AdminFoodBlock',
    component: AdminFoodBlock
  },
  {
    path: 'cards/card-detail',
    name: 'CardDetail',
    component: AdminCardDetail
  },
  // {
  //   path: 'storehouse',
  //   name: 'Storehouse',
  //   component: AdminStorehouse
  // },
  // {
  //   path: 'storehouse/storehouse-detail',
  //   name: 'StorehouseDetail',
  //   component: AdminStorehouseDetail
  // },
  {
    path: 'syrvey-manage',
    name: 'SyrveyManage',
    component: SyrveyManage
  },
  {
    path: 'syrvey-manage/syrvey-manage-detail',
    name: 'SyrveyManageQuestions',
    component: SyrveyManageQuestions
  },
  // {
  //   path: 'npa-documents',
  //   name: 'NpaDocuments',
  //   component: AdminNpaDocuments,
  //   props: true
  // },
  {
    path: 'npa-certificate',
    name: 'NpaCertificate',
    component: AdminNpaCertificate,
    props: true
  },
  // {
  //   path: 'product-categories',
  //   name: 'AdminProductCategories',
  //   component: AdminProductCategories,
  //   props: true
  // },
  {
    path: 'syrvey-result',
    name: 'SyrveyResult',
    component: AdminSyrveyResult,
    props: { user_role: 'is_school_admin' }
  },
  {
    path: 'quality-control',
    name: 'AdminQualityControlWrap',
    component: AdminQualityControlWrap,
    props: true
  },
  {
    path: 'syrvey-result/syrvey-result-detail',
    name: 'AdminSyrveyDetail',
    component: AdminSyrveyDetail,
    props: true
  },
  {
    path: 'buffet-balance-add',
    name: 'AdminBuffetBalanceAdd',
    component: AdminBuffetBalanceAdd,
    props: true
  },
  // {
  //   path: 'buffet-products-request',
  //   name: 'BuffetProductsApplications',
  //   component: AdminBuffetProductsApplications,
  //   props: true
  // },
  {
    path: 'concessions',
    name: 'AdminConcessions',
    component: ChildrenStartPage,
    props: true,
    children: [
      {
        path: 'social',
        name: 'AdminSocial',
        component: AdminConcessionsIndex
      },
      {
        path: 'dishes',
        name: 'AdminDishes',
        component: AdminDishIndex
      },
      {
        path: 'dishes/:id/view',
        name: 'AdminDishesView',
        component: AdminDishView
      },
      {
        path: 'dishes/:id/create',
        name: 'AdminDishesCreate',
        component: AdminDishCreate
      },
      {
        path: 'dishes/:id/edit',
        name: 'AdminDishesEdit',
        component: AdminDishEdit
      },
      {
        path: 'complexes',
        name: 'AdminComplexes',
        component: AdminComplexIndex
      },
      {
        path: 'complexes/:id/view',
        name: 'AdminComplexesView',
        component: AdminComplexView
      },
      {
        path: 'complexes/:id/edit',
        name: 'AdminComplexesEdit',
        component: AdminComplexEdit
      },
      {
        path: 'food-types',
        name: 'AdminFoodTypes',
        component: AdminFoodTypesIndex
      },
      {
        path: 'food-times',
        name: 'AdminFoodTimes',
        component: AdminFoodTimesIndex
      },
    ]
  },
  {
    path: 'lists',
    name: 'AdminLists',
    component: ChildrenStartPage,
    props: true,
    children: [
      {
        path: 'classes',
        name: 'AdminClasses',
        component: AdminClassesIndex
      },
      {
        path: 'classes/:id/view',
        name: 'AdminClassesView',
        component: AdminClassesItem,
        meta: 'view'
      },
      {
        path: 'classes/:id/edit',
        name: 'AdminClassesEdit',
        component: AdminClassesItem,
        meta: 'edit'
      },
      {
        path: 'students',
        name: 'AdminStudents',
        component: AdminStudentsIndex
      },
      {
        path: 'students/:id/view',
        name: 'AdminStudentsView',
        component: AdminStudentsEdit,
        props: {
          view: true
        }
      },
      {
        path: 'students/:id/edit',
        name: 'AdminStudentsEdit',
        component: AdminStudentsEdit
      },
      {
        path: 'teachers',
        name: 'AdminTeachers',
        component: AdminTeachersIndex
      },
      {
        path: 'teachers/:id/',
        name: 'AdminTeachersCard',
        component: AdminTeachersCard,
      },
    ]
  }
]
