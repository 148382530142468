<template>
  <v-container
      fluid
      class="fs-teacher-container"
  >
    <v-layout
        class="fs-dish"
        row
    >
      <v-flex
          md3
          mb-5
      >
        <h1>{{ title }}</h1>
      </v-flex>

    </v-layout>


    <div>
      <v-tabs
          dark
          slider-color="yellow"
      >

        <v-tabs-slider color="red"></v-tabs-slider>

        <v-tab
            v-for="item in tab_headers"
            :key="item"
        >
          {{ item }}
        </v-tab>

        <v-tabs-items>
          <v-tab-item
              v-for="num in 3"
              :key="num"
          >
            <v-card
                color="basil"
                flat
            >
              <v-flex>
                <v-data-table
                    :headers="headers"
                    :items.sync="items[num-1]"
                    hide-actions
                    no-data-text="Нет данных"
                    item-key="id"
                >
                  <template v-slot:items="props">
                    <tr>
                      <td class="cursor-pointer" @click="() => {dialogText = props.item.content; dialog = true; dialogTitle = props.item.title; props.item.type === 2 ? playVideo = true : playVideo = false}">{{ props.item.id }}</td>
                      <td class="cursor-pointer" @click="() => {dialogText = props.item.content; dialog = true; dialogTitle = props.item.title; props.item.type === 2 ? playVideo = true : playVideo = false}">{{ props.item.title }}</td>
                      <td class="cursor-pointer" @click="() => {dialogText = props.item.content; dialog = true; dialogTitle = props.item.title; props.item.type === 2 ? playVideo = true : playVideo = false}">{{ moment(props.item.created_at).format('DD.MM.YYYY hh:mm') }}</td>
                      <td class="cursor-pointer" @click="() => {dialogText = props.item.content; dialog = true; dialogTitle = props.item.title; props.item.type === 2 ? playVideo = true : playVideo = false}">{{ moment(props.item.updated_at).format('DD.MM.YYYY hh:mm') }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>

    <v-dialog v-model="dialog" width="60%">
      <v-card-title class="text-h5 grey lighten-2">
        <h3>{{ this.dialogTitle }}</h3>
      </v-card-title>
      <v-card class="pa-3">
        <div v-if="playVideo">
          <video width="100%" controls :src="dialogText"></video>
        </div>
        <div v-else v-html="dialogText"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mt-4" color="success" @click="() => {this.dialogText = ''; this.dialogTitle = ''; this.dialog = false}">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import store from '@/store'
import Vue from 'vue'
import { TextEditorModal } from '@/components/common'
import {
  getInformation,
} from '@/router/routes/common'
import moment from "moment"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'UserHelp',
  computed: {
    moment() {
      return moment
    }
  },
  components: {
    TextEditorModal
  },
  data() {
    return {
      playVideo: false,
      tab_headers: [
        'Инструкции', 'Видео-материалы', 'Часто задаваемые вопросы'
      ],
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Заголовок', value: 'title', sortable: false },
        { text: 'Дата создания', value: 'created_at', sortable: false },
        { text: 'Дата обновления', value: 'created_at', sortable: false }
      ],
      roles: {
        'AdminUserHelp': 'school_admin',
        'TeacherUserHelp': 'teacher',
        'ParentUserHelp': 'parent',
        'MunicipalityUserHelp': 'department',
        'SuperMunicipalityUserHelp': 'department_admin',
        'OrganizerUserHelp': 'supplier',
      },
      title: 'Инструкции',
      items: [],
      dialog: false,
      dialogText: '',
      dialogTitle: '',
    }
  },
  mounted() {
    this.showInformation()
  },
  methods: {
    async showInformation() {
      const payload = {
        role: this.roles[this.$router.currentRoute.name]
      }
      try {
        const response = await getInformation(payload)
        this.items = [ response.data.instructions, response.data.videos, response.data.faqs ]
        this.dataTableLoaded = true
      } catch (error) {
        // notify.error('Ошибка загрузки информации') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
  },
}
</script>
