<template>
  <v-container fluid class="fs-teacher-container">
    <v-layout row mb-3>
      <div class="d-flex row justify-space-between">
        <h1>Работа с учениками</h1>
      </div>
    </v-layout>
    <div>
      <v-card>
        <v-card-text class="pb-0">
          <div class="d-flex row">
            <element-group title="По школе" class="mr-2 px-3 py-2">
              <v-autocomplete
                solo
                @keyup.enter="getPupilList"
                clearable
                v-model="selectedSchool"
                :items="schoolsList"
                item-text="fullName"
                item-value="id"
                label="Школа"
                @click:clear="clearSearch"
              ></v-autocomplete>
              <v-autocomplete
                solo
                @keyup.enter="getPupilList"
                clearable
                v-model="selectedSchoolClass"
                :items="schoolClassList"
                item-text="name"
                item-value="id"
                label="Класс"
              ></v-autocomplete>
            </element-group>
            <element-group title="По ученику" class="mr-2 px-3 py-2">
              <v-text-field
                solo
                @keyup.enter="getPupilList"
                clearable
                v-model="pupilID"
                item-value="id"
                label="По идентификатору"
              />

              <div>
                <span class="grey--text">
                  При заполнении ID игнорируются поля "Школа" и "Класс"
                </span>
              </div>
            </element-group>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            @click="getPupilList"
            small
            color="primary"
            class="mr-3"
            :disabled="disabled"
          >
            Найти
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <div>
      <superadmin-pupil-table
        :pupils="foundPupils"
        v-on:updatePupil="updatePupil"
      />
    </div>
  </v-container>
</template>

<script>
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal.vue'
import ElementGroup from '@/components/common/ElementGroup/ElementGroup.vue'
import {
  getClasses,
  getSchools,
  getStudent,
  getStudents
} from '@/router/routes/superadmin'
import store from '@/store'
import SuperadminPupilTable from '@/components/moduleadmin/PupilList/Components/PupilTable.vue'

export default {
  name: 'SuperadminPupilsList',

  components: {
    SuperadminPupilTable,
    ElementGroup,
    ContextHelpModal
  },

  data() {
    return {
      schoolsList: [],
      selectedSchool: null,
      schoolClassList: [],
      selectedSchoolClass: null,
      pupilID: null,
      foundPupils: [],
      disabled: true
    }
  },

  async mounted() {
    await this.getSchools()
    store.commit('setShowPreloader', false)
  },

  watch: {
    async selectedSchool(val) {
      if (val) {
        await this.getSchoolClasses()
      }
      store.commit('setShowPreloader', false)
      this.disableButton()
    },

    selectedPupilID(val) {
      this.selectedPupilID = val === '' ? null : val
    },

    selectedPupilSurname(val) {
      this.selectedPupilSurname = val === '' ? null : val
    },

    selectedSchoolClass() {
      this.disableButton()
    },

    pupilID() {
      this.disableButton()
    }
  },

  methods: {
    async getSchools() {
      try {
        const response = await getSchools()
        this.schoolsList = response.data.data.map((item) => {
          item.fullName = `${item.name} (${item.department})`
          return item
        })
      } catch (e) {
        console.log(e)
      }
    },

    async getSchoolClasses() {
      this.schoolClassList = []
      try {
        const response = await getClasses({
          school_id: this.selectedSchool
        })
        this.schoolClassList = response.data.data
      } catch (e) {
        console.log(e)
      }
    },

    async getPupilList() {
      this.foundPupils = []
      try {
        if (this.pupilID) {
          const { data } = await getStudent(this.pupilID)
          this.foundPupils = [data]
        } else {
          const payload = {
            schoolId: this.selectedSchool,
            classId: this.selectedSchoolClass
          }
          const { data } = await getStudents(payload)

          this.foundPupils = data.data
        }
      } catch (e) {
        console.log(e)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    updatePupil(data) {
      this.foundPupils = this.foundPupils.map((item) => {
        const updateData = data.find((it) => it.id === item.id)
        if (updateData) {
          item.foodType = updateData.foodType
        }
        return item
      })
    },

    clearSearch() {
      this.schoolClassList = []
    },

    disableButton() {
      this.disabled = !(
        (this.selectedSchool && this.selectedSchoolClass) ||
        (this.pupilID && this.pupilID !== '')
      )
    }
  }
}
</script>

<style scoped></style>
