<template>
  <v-dialog v-model="showModal" max-width="1200px" persistent>
    <v-card>
      <v-layout class="fs-dish" row>
        <div class="mt-4 mb-2 title-help">
          <div class="d-flex row">
            <h3 class="mt-1">Заявки сверх кредита</h3>
            <context-help-modal :context-key="contextKeys.admin.dashboard.modals.overdraft"/>
          </div>
        </div>
      </v-layout>
      <v-card-actions>
        <v-flex xs12 sm12 md3>
          <v-select
            class="ml-3 mt-1"
            label="Элементов на странице"
            v-model="itemsPerPage"
            :items="[10, 25, 50]"
            dense outlined
            @change="updateItemsPerPage"
          ></v-select>
        </v-flex>
        <v-flex md1.5>
        </v-flex>
        <v-flex xs12 sm12 md3>
          <v-select
            class="mt-1"
            label="Выберите класс"
            v-model="searchClassField"
            :items="filterClasses"
            item-text="name"
            item-value="id"
            dense outlined
            @change="searchClass"
          ></v-select>
        </v-flex>
        <v-flex md1.5>
        </v-flex>
        <v-flex xs12 sm12 md3>
          <v-text-field
            class="mt-1 mr-3"
            label="Поиск ученика"
            append-icon="search"
            clear-icon="clear"
            clearable
            v-model="searchPupilField"
            @click:append="searchPupil"
            @click:clear="clearSearchPupil"
          ></v-text-field>
        </v-flex>
      </v-card-actions>
      <v-layout class="fs-complex-result" mb-3 mt-3 ml-3 mr-3>
        <v-flex>
          <v-data-table
            :headers="headers"
            :items="getPaginatedItems()"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            :total-items="items.length"
            hide-actions
            disable-initial-sort
            class="elevation-1"
          >
            <template v-slot:no-data>
              <tr>
                <td colspan="10" class="text-center">Ученики отсутствуют</td>
              </tr>
            </template>
            <template slot="headers" slot-scope="{ props }">
              <tr>
                <th>
                  <v-checkbox
                    v-model="selectAll"
                    hide-details
                    @click.native.stop
                  ></v-checkbox>
                </th>
                <th v-for="header in headers" :key="header.text" class="text-left">
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template v-slot:items="{ item, index }">
              <tr :key="item.pupil_id" :class="{ 'selected-row': item.isSelected }">
                <td>
                  <v-checkbox
                    v-model="item.isSelected"
                    :value="item.isSelected"
                    :class="{ 'selected-row': item.isSelected }"
                    hide-details
                    @click.native.stop
                  ></v-checkbox>
                </td>
                <td>{{ index + 1 }}</td>
                <td>{{ item.pupil_id }}</td>
                <td>{{ item.full_name }}</td>
                <td>{{ item.school_class }}</td>
                <td>{{ item.order_day }}</td>
                <td :class="{ 'text-red': item.price > 0 }">{{ item.price !== undefined ? convertToRubles(item.price) : null }}</td>
                <td>{{ item.balance !== undefined ? convertToRubles(item.balance) : null }}</td>
                <td>{{ item.max_credit !== undefined ? convertToRubles(item.max_credit) : null }}</td>
                <td>{{ item.status === 'Заказ выполнен' && item.status_received_is_auto ? 'Заказ выполнен (без списания)' : item.status }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-pagination
        v-model="currentPage"
        :length="totalVisiblePages"
        @input="updateCurrentPage"
        class="mt-2 mb-2"
      ></v-pagination>
      <v-card-actions class="d-flex justify-content-end align-items-center flex-wrap">
        <v-btn color="primary" class="dashboard-btn" @click="closeModal">Закрыть</v-btn>
        <v-btn color="#FF5252" class="dashboard-btn" v-if="hasSelectedItems" @click="updateOrdersOverCredit">Отменить заказы</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from "vuex"
import {
  getAdminDashboardModalAllClasses,
  getAdminDashboardOrdersOverCredit,
  patchAdminDashboardPupilOverCredit
} from "@/router/routes/admin"
import notify from "@/utils/notifications/toastNotification"
import store from "@/store"
import numable from '@/mixins/balancable'
import Vue from "vue"
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal'

export default {
  name: 'ModalOrdersOverCredit',
  mixins: [ numable ],
  components: {
    ContextHelpModal,
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      items: [],
      filterClasses: [],
      selectAll: false,
      showModal: true,
      hasSelectedItems: false,
      page: 0,
      itemsPerPage: 10,
      totalVisiblePages: 1,
      currentPage: 1,
      searchPupilField: '',
      searchClassField: null,
      headers: [
        { text: '№', value: 'index' },
        { text: 'ЛС', value: 'pupil_id' },
        { text: 'ФИО', value: 'full_name' },
        { text: 'Класс', value: 'school_class' },
        { text: 'Дата заказа', value: 'order_day' },
        { text: 'Цена заказа', value: 'price' },
        { text: 'Баланс', value: 'balance' },
        { text: 'Макс. сумма кредита', value: 'max_credit' },
        { text: 'Статус заказа', value: 'status' },
      ],
    }
  },
  async mounted() {
    await this.loadPupilOverCredit()
    await this.loadAllClasses()
  },
  watch: {
    selected(newVal) {
      this.selectAll = newVal.length === this.items.length
      console.log(this.items, this.selectAll)
    },
    selectAll(newVal) {
      if (newVal) {
        const paginatedItems = this.getPaginatedItems()
        paginatedItems.forEach(item => {
          const index = this.items.findIndex(i => i.id === item.id)
          if (index !== -1) {
            this.items[index].isSelected = true
          }
        })
      } else {
        this.items.forEach((item) => {
          item.isSelected = false
        })
      }
    },
    items: {
      handler() {
        if (this.items.every(item => item.isSelected)) {
          this.selectAll = this.getPaginatedItems
        } else if (this.items.every(item => !item.isSelected)) {
          this.selectAll = false
        }
        this.hasSelectedItems = this.items.filter((item) => item.isSelected).length > 0
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    getPaginatedItems() {
      const startIndex = this.page * this.itemsPerPage
      const endIndex = startIndex + parseInt(this.itemsPerPage, 10)
      return this.items.slice(startIndex, endIndex)
    },
    updateItemsPerPage() {
      this.page = 0
      this.updateTotalPages()
    },
    updateTotalPages() {
      this.totalVisiblePages = Math.ceil(this.items.length / this.itemsPerPage)
    },
    updateCurrentPage(page) {
      this.page = page - 1
    },
    async searchPupil() {
      if (this.searchPupilField.length === 0) {
        return
      }
      try {
        const response = await getAdminDashboardOrdersOverCredit({
          school_id: this.currentUser.school_id,
          search: this.searchPupilField
        })
        this.items = response.data.map(item => ({ ...item, isSelected: false }))
        this.page = 0
        this.searchClassField = null
        await this.updateTotalPages()
      } catch (error) {
        notify.error('Ошибка загрузки учеников') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async searchClass() {
      if (this.searchClassField === null ) {
        await this.loadPupilOverCredit()
        return
      }
      try {
        const response = await getAdminDashboardOrdersOverCredit({
          school_id: this.currentUser.school_id,
          filter_class: this.searchClassField
        })
        this.items = response.data.map(item => ({ ...item, isSelected: false }))
        this.page = 0
        this.searchPupilField = ''
        await this.updateTotalPages()
      } catch (error) {
        notify.error('Ошибка фильтрации классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async clearSearchPupil() {
      this.searchPupilField = ''
      await this.loadPupilOverCredit()
    },
    async loadPupilOverCredit() {
      try {
        const response = await getAdminDashboardOrdersOverCredit({ school_id: this.currentUser.school_id })
        this.items = response.data.map(item => ({ ...item, isSelected: false }))
        this.page = 0
        await this.updateTotalPages()
      } catch (error) {
        notify.error('Ошибка загрузки заявок') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    closeModal() {
      this.$emit('close')
    },
    async loadAllClasses() {
      try {
        const response = await getAdminDashboardModalAllClasses({
          school_id: this.currentUser.school_id
        })
        this.filterClasses = response.data
        this.filterClasses.unshift({ name: 'Все', id: null })
      } catch (error) {
        notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async updateOrdersOverCredit() {
      try {
        const selectedItems = this.items.filter((item) => item.isSelected).map(({
          pupil_id, full_name, balance, price, max_credit, school_class, status_received_is_auto, ...rest }) => rest)
        await patchAdminDashboardPupilOverCredit({ school_id: this.currentUser.school_id, data: selectedItems })
        this.loadPupilOverCredit()
        this.searchPupilField = ''
        notify.success('Заявки успешно отменены')
      } catch (error) {
        notify.error('Ошибка загрузки учеников') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>

<style scoped>

.text-red {
  color: red;
}
.selected-row {
    background-color: #FCD7D7;
  }
.text-left {
    text-align: left;
}

.v-dialog.v-dialog--active .v-card {
  border-radius: 10px;
}

</style>
