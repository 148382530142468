<template>
  <v-container
      fluid
      class="fs-organizer-container"
  >
    <v-layout
        class="fs-balance"
        row
        mb-3
    >
      <v-flex mb-3>
        <h1>{{ title }}</h1>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-layout
          row
          justify-start
      >
        <v-flex
            col-6
            mr-3
        >
          <v-select
              :value="selectedSchool"
              @change="changeSchoolValue('selectedSchool', $event)"
              :items="schools"
              item-text="name"
              item-value="id"
              label="Образовательная организация"
          ></v-select>
        </v-flex>
        <v-flex
            md2
            mr-3
            align-self-end
        >
          <v-select
              :value="selectedClass"
              @change="changeValue('selectedClass', $event)"
              :items="classes"
              item-text="name"
              item-value="id"
              label="Класс"
          ></v-select>
        </v-flex>
        <v-flex
            md2
            mr-3
            align-self-end
        >
          <v-text-field
              label="ФИО"
              :value="pupilName"
              @change="changeValue('pupilName', $event)"
          ></v-text-field>
        </v-flex>
        <v-flex
            md2
            mr-3
            align-self-center
        >
          <v-btn
              flat
              @click="load"
              class="fs-btn fs-btn--primary"
          >Показать</v-btn>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout
        class="fs-balance-result"
        mb-3
    >
      <v-flex
          v-show="dataExist"
      >
        <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
            class="elevation-1"
            item-key="name"
            no-data-text="Транзакции отсутствуют"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.fullName }}</td>
              <td>{{ props.item.reserved }}</td>
              <td>{{ props.item.available }}</td>
              <td>{{ props.item.balance }}</td>
            </tr>

          </template>
        </v-data-table>
        <v-card>
          <v-card-text text-md-right>
            <b>ИТОГО</b>
            <div>Зарезервированных средств: <b>{{ total("reserved") }}</b></div>
            <div>Доступных средств: <b>{{ total("available") }}</b></div>
            <div>Баланс: <b>{{ total("balance") }}</b></div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import store from '@/store'
import scrollable from '@/mixins/scrollable'
import { mapMutations, mapGetters } from 'vuex'
import converter from '@/helpers/converter'
import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownload'
import {
  getStudentsBalance,
  getSchools,
  getClasses
} from '@/router/routes/organizer'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'BalanceIndex',
  mixins: [ scrollable ],
  data() {
    return {
      title: 'Просмотр баланса',
      headers: [
        { text: 'Лицевой счет', value: 'id' },
        { text: 'ФИО', value: 'fullName' },
        { text: 'Зарезервировано', value: 'reserved' },
        { text: 'Доступно', value: 'available' },
        { text: 'Баланс', value: 'balance' }
      ],
      items: [],
      classes: [],
      selectedClass: null,
      schools: [],
      selectedSchool: null,
      accountIdValue: null,
      pupilName: '',
      LIMIT: Vue.config.general.limit,
      dataExist: false
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  created() {
    this.init()
    window.addEventListener('scroll', this.scrollHandler)
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async changeSchoolValue(type, value) {
      this[type] = value
      this.resetData()
      this.classes = []
      await this.getClasses()
    },
    async changeValue(type, value) {
      this[type] = value
      this.resetData()

      if (type === 'selectedClass') {
        this.loadMore()
      }
    },
    resetData() {
      this.offset = 0
      this.selected = []
      this.items = []
      this.dataTableLoaded = false
    },
    searchAccountId: _.debounce(async function(value) {
      this.accountIdValue = value
      this.resetData()
      await this.loadMore()
    }, 1500),
    async init() {
      try {
        await this.getSchools()
        // await this.loadMore()
      } catch (error) {
        // notify.error('Ошибка инициализации страницы') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getSchools() {
      try {
        const PAYLOAD = {
          limit: this.LIMIT,
          offset: this.offset
        }
        const response = await getSchools(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
        this.schools = [ ...this.schools, ...itemsArray ]
        if (!this.schools[0]) {
          notify.error('Вам не назначена ни одна школа') //pass
        }
        else {
          this.selectedSchool = this.schools[0]
          await this.getClasses()
        }
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getClasses() {
      const PAYLOAD = {
        limit: Vue.config.general.classLimit,
        offset: this.offset,
        school_id: this.checkTypeObject(this.selectedSchool)
          ? this.selectedSchool.id
          : this.selectedSchool
      }
      try {
        const response = await getClasses(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
        this.classes = [ ...this.classes, ...itemsArray ]
        this.selectedClass = this.classes[0]
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async load() {
      this.items = []
      await this.loadMore()
    },
    async loadMore() {
      const schoolIdValue = this.checkTypeObject(this.selectedSchool)
        ? this.selectedSchool.id
        : this.selectedSchool
      const selectedClassValue = this.checkTypeObject(this.selectedClass)
        ? this.selectedClass.id
        : this.selectedClass
      try {
        const payload = {
          params: {
            limit: this.LIMIT,
            offset: this.offset,
            schoolId: schoolIdValue,
            classId: selectedClassValue,
            accountId: this.accountIdValue,
            fullName: this.pupilName
          }
        }
        const response = await getStudentsBalance(payload)
        const {
          data: { data: balanseItems }
        } = response

        this.dataExist = true

        const itemsArray = balanseItems.map(item => {
          const {
            id = null,
            reserved = null,
            available = null,
            balance = null,
            fullName = null
          } = item
          return {
            id: id,
            reserved: reserved ? converter(reserved, 'pennyToRub') : 0,
            available: available ? converter(available, 'pennyToRub') : 0,
            balance: balance ? converter(balance, 'pennyToRub') : 0,
            fullName: fullName
          }
        })

        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки счетов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    checkTypeObject(value) {
      return typeof value === 'object'
    },
    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
      this.btnUnloadLoaded = false
      this.btnPrintLoaded = false
    },
    async downloadPrintFile() {
      this.btnPrintLoaded = true
      const PAYLOAD = null
      const callback = this.resetPageFileLoaded
      try {
        await asyncReqestsForDownload(
          printFileTaskId,
          getPrintFileStatus,
          getPrintFile,
          PAYLOAD,
          callback
        )
      } catch (error) {
        // notify.error('Ошибка загрузки документа') // backend handler added (exception_message)
      } finally {
        this.resetPageFileLoaded()
      }
    },
    async downloadBudgetIds(data) {
      this.btnUnloadLoaded = true
      const PAYLOAD = null
      const callback = this.resetPageFileLoaded
      try {
        await asyncReqestsForDownload(
          getBudgetTaskId,
          getBudgetStatus,
          getBudgetFile,
          PAYLOAD,
          callback
        )
      } catch (error) {
        // notify.error('ERROR asyncReqestsForDownload') // backend handler added (exception_message)
      } finally {
        this.resetPageFileLoaded()
      }
    },
    total(type) {
      let total = 0
      _.forEach(this.items, item => { total += parseFloat(item[type]) })

      return total
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
