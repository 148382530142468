<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <v-layout class="fs-teacher-order" row mb-3>
      <h1>{{ title }}</h1>
      <context-help-modal :context-key="contextKeys.admin.invoiceInfo" />
    </v-layout>
    <v-layout
      row
      mb-3
      justify-start
    >
      <v-flex md2 mr-3>
        <v-autocomplete
          :value="classID"
          :items="classesArray"
          @change="changeClass"
          :item-text="'name'"
          :item-value="'id'"
          flat
          dense
          :outline="darkTheme"
          :placeholder="darkTheme ? 'Класс' : ''"
          :label="!darkTheme ? 'Класс:' : ''"
          :class="{ 'fs-admin-complex-autocomplete': !darkTheme }"
        />
      </v-flex>
      <v-flex md2 mr-3>
      </v-flex>
      <v-flex md2>
        <v-btn
          v-show="dateStart || dateEnd"
          class="primary"
          @click="clearDates"
        >Очистить даты</v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      class="fs-teacher-order-result"
      mb-3
      v-if="dataTableLoaded"
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          v-model="selected"
          :pagination.sync="pagination"
          hide-actions
          class="elevation-1 fs-teacher-table"
          item-key="id"
          select-all
        >
          <template
            slot="headerCell"
            slot-scope="props"
          >
            <span :style="{ display: 'inline-block'}">
              <span v-if="props.header.value === 'days' || props.header.value === 'sum' ">
                <v-edit-dialog
                  lazy
                  :style="{ display: 'inline-block'}"
                >
                  <with-tooltip
                      :text="
                      props.header.value === 'days' ?
                      'Установить количество дней питания ученика' : 'Вычисленная сумма квитанции'"
                  >
                    <span style="display:'inline-block'">{{ props.header.text }}</span>
                  </with-tooltip>
                  <template v-slot:input>
                    <v-text-field
                      :hint="`Изменить: ${props.header.text}`"
                      persistent-hint
                      clearable
                      single-line
                      :mask="props.header.value === 'days' ? '###' : '#######'"
                      :rules="[formRules.minCount]"
                      @input="changeColumns(props.header.value, subtype=props.header.value === 'days' ? 'sum' : 'days', $event)"
                    ></v-text-field>
                  </template>
                  <v-icon :style="{ transition: 'none'}">edit</v-icon>
                </v-edit-dialog>
              </span>
              <span v-else>{{ props.header.text }}</span>
            </span>
          </template>
          <template v-slot:items="props">
            <tr :active="props.selected" @click="props.selected = !props.selected">
              <td
                pointer
                class="text-xs-right"
              >
                <v-checkbox
                  class="fs-checkbox"
                  :disabled="props.item.isFake"
                  :input-value="props.selected"
                  primary
                  hide-details
                />
              </td>
              <td class="text-xs-left">{{ props.item.id }}</td>
              <td class="text-xs-left">
                <v-tooltip bottom v-if="props.item.isFake">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">
                      warning
                    </v-icon>
                  </template>
                  По ученику получены неполные данные, работа с ним временно заблокирована.
                </v-tooltip>
                {{ props.item.name }}
              </td>
              <td class="text-xs-left">
                <v-text-field
                  :outline="darkTheme"
                  :hide-details="darkTheme"
                  mask="###"
                  :disabled="props.item.isFake"
                  :value="props.item.days || 0"
                  @input="changeValue(props.item, $event, 'days')"
                  :rules="[formRules.minCount, formRules.maxCount]"
                  placeholder="Укажите количество дней пополнения"
                />
              </td>
              <td class="text-xs-left">
                <v-text-field
                  :outline="darkTheme"
                  :hide-details="darkTheme"
                  :disabled="props.item.isFake"
                  mask="#######"
                  :value="(props.item.sum || 0)"
                  @input="changeValue(props.item, $event, 'sum')"
                  :rules="[formRules.minCount]"
                  placeholder="Укажите сумму для пополнения"
                />
              </td>
            </tr>
          </template>
          <template v-slot:footer>
            <tr>
              <td colspan="5">
                <v-layout
                  row
                  align-center
                  justify-space-between
                  fill-height
                >
                  <v-flex
                    xs6
                    text-md-left
                    align-center
                  >
                    <div class="checkedChildrenTitle">Выбрано: {{childrenCheckedTitle}}</div>
                  </v-flex>
                  <v-flex
                    xs6
                    text-md-right
                  >
                    <with-tooltip text="Выбрать промежуток времени для формирования квитанции на оплату">
                      <v-btn
                      @click="handlerPrintFromDateToDate"
                      :loading="btnPrintLoading"
                      :disabled="btnPrintLoading || !acceptPrint"
                      flat
                      class="fs-btn fs-teacher-btn__cancel v-btn--active"
                    >Выбрать период для печати</v-btn>
                    </with-tooltip>
                    <with-tooltip text="Печать квитанций для выбранных детей">
                      <v-btn
                      @click="handlerPrint"
                      :loading="btnPrintLoading"
                      :disabled="btnPrintLoading || !acceptPrint"
                      flat
                      class="fs-btn fs-teacher-btn__print v-btn--active"
                    >Печать квитанций для выбранных детей</v-btn>
                    </with-tooltip>
                  </v-flex>
                </v-layout>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <ConfirmModal
      :dialog.sync="showSelectDateModal"
      @confirm="handlerConfirmSelectDateModal"
      @btnOnLoad="btnLoadStart"
    >
      <template v-slot:headline>Выбор периода для печати квитанций</template>
      <template v-slot:body>
        <DatePickersSmart
          startDateTitle="От"
          endDateTitle="До"
          :startDate.sync="dateStart"
          :endDate.sync="dateEnd"
        />
      </template>
    </ConfirmModal>
    <ConfirmModal
      :dialog.sync="showModal"
      @confirm="handlerConfirmModal"
    >
      <template v-slot:headline>Подтверждение печати квитанций</template>
      <template v-slot:body>
        Вы подтверждаете печать
        <span class="highlighted">{{selected.length}}</span> квитанций для выбранных детей?
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import {
  getClasses,
  getFilteredStudents,
  getInvoicesTaskId,
  getInvoicesFileStatus,
  getInvoicesFile,
  printInvoiceFromDateToDate
} from '@/router/routes/admin'

import _ from 'lodash'
import store from '@/store'
import { mapGetters } from 'vuex'
import scrollable from '@/mixins/scrollable'
import childrenCheckedTitle from '@/mixins/childrenCheckedTitle'
import Vue from 'vue'
import rules from '@/helpers/rules'
import { ConfirmModal, DatePickersSmart } from '@/components/common'
import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownload'
import converter from '@/helpers/converter'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'
import WithTooltip from "@/components/common/Tooltip/Tooltip";

export default {
  name: 'AdminOrdersPrintIndex',
  mixins: [ scrollable, childrenCheckedTitle ],
  components: {
    ContextHelpModal,
    ConfirmModal,
    DatePickersSmart,
    WithTooltip
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      darkTheme: Vue.config.env.darkTheme,
      title: 'Формирование квитанций на оплату',
      dataTableLoaded: false,
      pagination: {
        sortBy: 'lastName',
        descending: false,
        rowsPerPage: -1
      },
      datepickerMenu: false,
      selected: [],
      classesArray: [],
      classID: 0,
      formRules: rules,
      dateStart: null,
      dateEnd: null,
      headers: [
        { text: '№ п/п', value: 'id' },
        { text: 'ФИО ученика', value: 'name' },
        { text: 'Количество дней', value: 'days' },
        { text: 'Сумма квитанции', value: 'sum' }
      ],
      items: [],
      btnPrintLoading: false,
      showModal: false,
      showSelectDateModal: false,
      modalResponse: false
    }
  },
  computed: {
    ...mapGetters([ 'currentUser', 'foodPeriods' ]),
    acceptPrint() {
      return this.selected.length ? true : false
    }
  },
  created() {
    this.initData()
    window.addEventListener('scroll', this.scrollHandler)
  },
  methods: {
    btnLoadStart() {
      this.btnPrintLoading = true;
    },
    getClassLiter(name) {
      return name.split(' ')[1]
    },
    changeColumns: _.debounce(function(type, subtype, value) {
      let SUB_VALUE = null
      if (type === 'sum') {
        value = converter(value, 'rubToPenny')
      }
      this.items.map(item => {
        if (subtype === 'sum') {
          SUB_VALUE = this.getAmountOfInvoice(
            item.foodType.price,
            item.privilege.price,
            value
          )
        }
        if (subtype === 'days') {
          SUB_VALUE = this.getDays(
            item.foodType.price,
            item.privilege.price,
            value
          )
        }
        Vue.set(item, type, value)
        Vue.set(item, subtype, SUB_VALUE)
        return item
      })
    }, 200),
    async initData() {
      const payload = {
        schoolId: this.currentUser.school.id,
        params: {
          limit: Vue.config.general.classLimit,
          offset: 0
        }
      }
      try {
        const response = await getClasses(payload)
        const {
          data: { data: items }
        } = response

        const itemsArray = items.map(item => {
          const { id, name, number } = item
          return {
            id: id,
            name: name,
            number: number
          }
        })
        this.classesArray = [ ...this.items, ...itemsArray ]
        this.classID = this.classesArray[0].id
        await this.loadMore()
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async loadMore() {
      const payload = {
        schoolId: this.currentUser.school.id,
        classId: this.classID,
        params: {
          limit: Vue.config.general.limit,
          offset: this.offset
        }
      }
      try {
        const response = await getFilteredStudents(payload)
        const DEFAULT_DAYS = 22
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const foodTypePrice = this.toRub(_.get(item, 'foodType.price', 8000))
          const concessionPrice = this.toRub(
            _.get(item, 'privilege.price', 2000)
          )
          const { fullName = '', id = null, lastName = '' } = item
          return {
            name: fullName,
            id: id,
            isFake: _.get(item, 'isFake'),
            lastName: lastName,
            days: DEFAULT_DAYS,
            sum: this.getAmountOfInvoice(
              foodTypePrice,
              concessionPrice,
              DEFAULT_DAYS
            ),
            privilege: {
              price: concessionPrice
            },
            foodType: {
              price: foodTypePrice
            }
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
        this.dataTableLoaded = true
      } catch (error) {
        // notify.error('Ошибка загрузки учеников') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async changeClass(value) {
      this.classID = value
      this.resetData()
      await this.loadMore()
    },
    clearDates() {
      this.dateStart = null
      this.dateEnd = null
    },
    resetData() {
      this.offset = 0
      this.selected = []
      this.items = []
      this.dataTableLoaded = false
    },
    getAmountOfInvoice(price, privilege, days) {
      // return Math.ceil((price - privilege) * days)
      return Math.ceil(this.toRub(this.toCent(price) - this.toCent(privilege)) * days)
    },
    getDays(price, privilege, sum) {
      const divider = price - privilege

      return Math.floor(sum / divider)
    },
    toCent(value) {
      const CENTS_IN_RUB = 100
      return value * CENTS_IN_RUB
    },
    toRub(value) {
      const CENTS_IN_RUB = 100
      return value / CENTS_IN_RUB
    },
    changeValue(student, value, type) {
      const items = this.items.map(item => {
        let keyChanged = null
        let newValue = null
        if (item.id === student.id) {
          if (type === 'days') {
            keyChanged = 'sum'
            newValue = this.getAmountOfInvoice(
              item.foodType.price,
              item.privilege.price,
              value
            )
          }
          if (type === 'sum') {
            keyChanged = 'days'
            newValue = this.getDays(
              item.foodType.price,
              item.privilege.price,
              value
            )
          }
          Vue.set(item, type, value)
          Vue.set(item, keyChanged, newValue)
          return item
        }
        return item
      })
      this.items = items
    },
    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
      this.btnPrintLoading = false
    },
    async requestInvoice(data) {
      const PAYLOAD = { school_id: this.currentUser.school.id, data: data }

      await asyncReqestsForDownload(
          getInvoicesTaskId,
          getInvoicesFileStatus,
          getInvoicesFile,
          PAYLOAD,
          this.resetPageFileLoaded
      )
      this.resetPageFileLoaded()
    },
    getFilteredData() {
      const DATA = JSON.parse(JSON.stringify(this.selected))
      DATA.map(item => {
        item.student_id = item.id

        delete item.id
        delete item.foodType
        delete item.privilege
        delete item.name
        item.sum = this.toCent(item.sum)
        if (this.dateStart && this.dateEnd) {
          item.start_date = this.dateStart
          item.end_date = this.dateEnd
        }
        return item
      })
      return DATA
    },
    getFilteredDataForPeriod() {
      const DATA = JSON.parse(JSON.stringify(this.selected))
      const payload = {
        date_from: this.dateStart,
        date_to: this.dateEnd,
        pupils: DATA.map(item => item.id)
      }
      return payload
    },
    handlerPrint() {
      this.showModal = true
    },
    handlerPrintFromDateToDate() {
      this.dateStart = null
      this.dateEnd = null
      this.showSelectDateModal = true
    },
    async handlerConfirmModal(value) {
      this.modalResponse = value
      if (this.modalResponse) {
        const DATA_FILTERED = this.getFilteredData()
        try {
          await this.requestInvoice(DATA_FILTERED)
        } catch (error) {
          notify.error('Ошибка отправки списка учеников') //pass!
        }
      }
    },
    async handlerConfirmSelectDateModal(value) {
      this.modalResponse = value
      if (this.modalResponse) {
        if (!this.dateStart || !this.dateEnd) {
          notify.error('Ошибка. Выберите период формирования квитанции') //pass
          this.showSelectDateModal = false
          return
        }
        const payload = {
          school_id: this.currentUser.school.id,
          data: this.getFilteredDataForPeriod()
        }
        try {
          await printInvoiceFromDateToDate(payload)
            .then(response => {
              const file = new Blob([ response.data ], { type: "octet/stream" })
              const url = window.URL.createObjectURL(file)
              const a = document.createElement('a')
              a.href = url
              a.download =
                'Квитанции на оплату за период от ' + this.dateStart + ' до ' + this.dateEnd + '.zip'
              a.click()
            })
        } catch (error) {
          // notify.error('Ошибка формирования квитанции') // backend handler added (exception_message)
        }
      }
      this.btnPrintLoading = false
      this.showSelectDateModal = false
      store.commit('setShowPreloader', false)
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
