import store from '@/store'
import downloadFile from './downloadFile'
import notify from '@/utils/notifications/toastNotification'

const requestFileUploadWrapper = async (func, ...args) => {
  if (!store.getters.asyncLoadFile && !store.getters.asyncLoadFileWithProgressBar) {
    store.commit('setAsyncLoadFileWithProgressBar', true)
  }
  return await func(...args).finally(() => store.commit('setAsyncLoadFileWithProgressBar', false))
}

export default async (getTaskId, getStatus, getFile, payload, callback) => {
  requestFileUploadWrapper(getTaskId, payload ? payload : null).then(response => {
    const getTaskId = response.data.id
    const payloadStatus = {
      task_id: getTaskId
    }
    store.commit('setAsyncLoadFileWithProgressBar', true)

    const requestGetStatus = () => {
      getStatus(payloadStatus).then(resStatus => {
        let {
          data: {
            data: { progress }
          }
        } = resStatus

        if (progress === null) progress = 0
        store.commit('setAsyncLoadFileLoaderProgressBarValue', parseInt(progress))

        if (+progress === 100) {
          requestFileUploadWrapper(getFile, payloadStatus).then(resBlob => {
            downloadFile(resBlob)
            notify.success('Файл загружен')
            callback()
          }, () => {
            notify.error('Ошибка загрузки файла')
          })
        }
        else {
          setTimeout(requestGetStatus, 1000)
        }
      }, () => {
        notify.error('Ошибка получения статуса загрузки')
      })
    }

    requestGetStatus()
  }, () => {
    notify.error('Ошибка загрузки файла')

    callback()
  })
}