<template>
  <v-data-table
    v-bind="$attrs"
    ref="dataTable"
    :headers="headers"
    :pagination.sync="pagination"
    :total-items="pagination.totalItems"
    :items="items"
    :loading="loading"
    class="elevation-1"
    item-key="id"
    @update:pagination="paginator($event, requestData)"
  >
    <template v-for="(key, slot, index) of $scopedSlots" v-slot:[slot]="scope">
      <td v-if="showNumbering && slot === 'items'" :key="'items__item-' + index">{{ getNumbering(scope.index) }}</td>
      <slot v-bind="scope" :name="slot"></slot>
    </template>
  </v-data-table>
</template>

<script>
import { disableLoader, disableSorting, paginationTable } from '@/mixins/helpers'

export default {
  name: 'Table',
  inheritAttrs: false,
  mixins: [ disableLoader, disableSorting, paginationTable ],
  data: () => ({
    loading: false,
    items: [],
    refTable: 'dataTable'
  }),
  updated() {
    if (this.dataArray) {
      this.loadData()
    }
  },
  props: {
    request: {
      type: Function,
      default: null
    },
    params: {
      type: Object,
      default: null
    },
    resource: {
      type: Object,
      default: () => ({})
    },
    requestAfterMount: {
      type: Boolean,
      default: false
    },
    showNumbering: {
      type: Boolean,
      default: false
    },
    dataArray: {
      type: Array,
      default: null
    }
  },
  mounted() {
    if (this.requestAfterMount) {
      this.requestData()
    }
  },
  created() {
    if ('headers' in this.$attrs) {
      this.headers = this.showNumbering
        ? [ { text: '#', value: 'num', sortable: false } ].concat(this.$attrs.headers)
        : this.$attrs.headers
    }
  },
  methods: {
    async requestData() {
      if (this.request === null) {
        return
      }
      this.loading = true
      const tId = await setTimeout(() => {clearTimeout(tId)}, 500)
      const params = { ...this.params, page: this.requestPagination.page, page_size: this.requestPagination.page_size }
      const { data: r } = await this.request.call(this.resource, params)
      if (r.success) {
        this.setResponsePagination(r.pagination)
        this.items = r.data
      }
      else {
        this.clearData()
      }

      this.loading = false
    },
    clearData() {
      this.setResponsePagination(null)
      this.items = []
    },
    loadData() {
      this.items = this.dataArray
    }
  },
  watch: {
    resource: {
      handler(obj) {
        for (const key in obj) {
          if (obj[key] === null) {
            this.clearData()
            return
          }
        }
        this.requestData()
      },
      deep: true
    }
  }
}
</script>
