import Vue from 'vue'
import axios from "axios"

const anotherPrivilegesUrl = Vue.config.server + '/' + Vue.config.api.department.another.privileges

export const getUpdatesNews = id => axios.get(Vue.config.server + '/' + Vue.config.api.department.updates)

export const createPrivilege = payload => axios.post(anotherPrivilegesUrl, payload)
export const archivePrivilege = payload => axios.delete(anotherPrivilegesUrl, {
  data: payload
})

export const updatePrivilege = (id, payload) => axios.patch(`${anotherPrivilegesUrl}${id}/`, payload)

export const restorePrivilege = id => axios.patch(
  `${Vue.config.server}/${Vue.config.api.department.concessions.update}`.replace('$id', id),
  { is_archived: false }
)

export const getSchools = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.schools.all.replace(
        '$department_id',
        payload.department_id
      ),
    { params: payload.params, preloaderState: payload.preloaderState }
  )

export const getClasses = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.classes.all.replace(
        '$school_id',
        payload.schoolId
      ),
    { params: payload.params }
  )

export const getStudents = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.students.filtered
        .replace('$schoolId', payload.school_id)
        .replace('$classId', payload.class_id),
    { params: payload.params }
  )

export const getConcessions = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.concessions.all.replace(
        '$department_id',
        payload.department_id
      ),
    { params: payload.params }
  )

export const getSuppliers = params =>
  axios.get(
    `${Vue.config.server}/${Vue.config.api.department.suppliers}`,
    { params }
  )

export const disableOrganizer = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.department.suppliers_disable,
    payload
  )

export const enableOrganizer = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.department.suppliers_unlock,
    payload
  )

export const getOrganizers = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.organizers.all.replace(
        '$department_id',
        payload.department_id
      ),
    { params: payload.params }
  )

export const getOrganizersByDepartments = payload =>
  axios.get(
    Vue.config.server +
        '/' +
        Vue.config.api.department.organizers.byDepartment,
    { params: payload.params }
  )

export const getOrganizer = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.organizers.one
        .replace('$department_id', payload.department_id)
        .replace('$id', payload.supplier_id)
  )

export const assignSchools = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.department.organizers.schoolsAssign.replace(
        '$department_id',
        payload.department_id
      ),
    payload.data
  )

export const assignFoodTypes = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.department.organizers.foodTypesAssign
        .replace('$department_id', payload.department_id)
        .replace('$id', payload.id),
    payload.data
  )

export const getDepartmentUserContacts = () =>
  axios.get(Vue.config.server + '/' + Vue.config.api.department.contacts)

export const setDepartmentUserContacts = payload =>
  axios.post(Vue.config.server + '/' + Vue.config.api.department.contacts, payload)

export const setDefaultConcession = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.department.concessions.all
      .replace('$department_id', payload.department_id),
    { concession: payload.concession }
  )

// REPORTS
export const getAllReports = () =>
  axios.get(Vue.config.server + '/' + Vue.config.api.department.reports.all)

export const reportFileTaskId = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.department.reports.one.replace('$id', payload.id),
    payload.data,
    { preloaderState: false }
  )

export const reportFileTaskIdSchool = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.department.reports.schoolOne
        .replace('$id', payload.id)
        .replace('$school_id', payload.school_id),
    payload.data,
    { preloaderState: false }
  )

export const reportFormFileTaskId = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.department.reports.form
        .replace('$id', payload.id)
        .replace('$form_id', payload.form_id),
    payload.data,
    { preloaderState: false }
  )
export const reportFormFileTaskIdSchool = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.department.reports.formSchoolOne
        .replace('$id', payload.id)
        .replace('$form_id', payload.form_id)
        .replace('$school_id',payload.school_id),
    payload.data,
    { preloaderState: false }
  )

export const reportFileStatus = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.reports.status
        .replace('$id', payload.id)
        .replace('$task_id', payload.task_id),
    { preloaderState: false }
  )

export const reportPrintFile = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.reports.download
        .replace('$id', payload.id)
        .replace('$task_id', payload.task_id),
    { responseType: 'blob', preloaderState: false }
  )
// /
// Meal types
export const getAllMealTypes = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.mealTypes.all.replace(
        '$department_id',
        payload.department_id
      )
  )

export const getArchiveMealTypes = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.department.mealTypes.archiveList.replace(
      '$department_id',
      payload.department_id
    )
  )

export const postMealTypes = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.department.mealTypes.all.replace(
        '$department_id',
        payload.department_id
      ),
    payload.data
  )
export const getMealType = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.mealTypes.one
        .replace('$department_id', payload.department_id)
        .replace('$id', payload.id)
  )

export const getAllMealTypesPrivilege = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.priveleges.meal_types
        .replace('$department_id', payload.department_id)
        .replace('$privilege_id', payload.privilege_id)
  )

export const postMealType = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.department.mealTypes.create.replace(
        '$department_id',
        payload.department_id
      ),
    payload.data
  )
export const putMealType = payload =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.department.mealTypes.one
        .replace('$department_id', payload.department_id)
        .replace('$id', payload.id),
    payload.data
  )
export const deleteMealType = payload =>
  axios.delete(
    Vue.config.server +
      '/' +
      Vue.config.api.department.mealTypes.one
        .replace('$department_id', payload.department_id)
        .replace('$id', payload.id),
    payload.data
  )

export const archiveMealType = payload =>
  axios.delete(
    Vue.config.server +
    '/' +
    Vue.config.api.department.mealTypes.archive
      .replace('$department_id', payload.department_id)
      .replace('$id', payload.id)
  )

export const getScheduledMealTypesChanges = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.department.mealTypes.scheduler
      .replace('$department_id', payload.department_id))

export const removeScheduledMealTypesChange = payload =>
  axios.delete(Vue.config.server + '/' + Vue.config.api.department.mealTypes.scheduler
    .replace('$department_id', payload.department_id) + payload.schedule_id + '/')


export const restoreMealType = payload =>
  axios.put(
    Vue.config.server +
    '/' +
    Vue.config.api.department.mealTypes.archive
      .replace('$department_id', payload.department_id)
      .replace('$id', payload.id)
  )

// privilege
export const getPrivileges = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.priveleges.all.replace(
        '$department_id',
        payload.department_id
      )
  )

export const getScheduledPrivilegeChanges = payload =>
  axios.get(Vue.config.server + '/' + Vue.config.api.department.priveleges.scheduler
    .replace('$department_id', payload.department_id))

export const removeScheduledPrivilegeChange = payload =>
  axios.delete(Vue.config.server + '/' + Vue.config.api.department.priveleges.scheduler
    .replace('$department_id', payload.department_id) + payload.schedule_id + '/')

export const createScheduledPrivilegeChange = payload =>
  axios.post(Vue.config.server + '/' + Vue.config.api.department.priveleges.scheduler
    .replace('$department_id', payload.department), payload)

export const getOnePrivilege = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.priveleges.one
        .replace('$department_id', payload.department_id)
        .replace('$id', payload.id)
  )

export const updateOrganizer = payload =>
  axios.put(
    Vue.config.server +
      '/' +
      Vue.config.api.department.organizers.one
        .replace('$department_id', payload.department_id)
        .replace('$id', payload.supplier_id),
    payload.params
  )

export const updateOrganizerStatusLock = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.department.organizers.status
        .replace('$supplier_id', payload.supplier_id),
    payload.formData
  )

export const getOrganizerStatusLock = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.organizers.status
        .replace('$supplier_id', payload.supplier_id)
  )
/** ****Notification  */

export const setNotification = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.department.notifications.all,
    payload.data
  )

export const setNotificationEveryone = payload =>
  axios.post(
    Vue.config.server + '/' + Vue.config.api.department.notifications.everyone,
    payload.data
  )

export const getAllAdminReports = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.admin.reports.all
  )

export const getAdminReportTaskId = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.department.admin.reports.one
        .replace('$id', payload.id),
    payload.data,
    { preloaderState: false }
  )

export const getAdminReportBalanceTaskId = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.department.admin.reports.balance,
    payload.data,
    { preloaderState: false }
  )

export const adminReportBalanceFileStatus = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.department.admin.reports.balanceStatus
      .replace('$task_id', payload.task_id),
    payload.data,
    { preloaderState: false }
  )

export const adminReportBalancePrintFile = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.department.admin.reports.balanceDownload
      .replace('$task_id', payload.task_id),
    { responseType: 'blob', preloaderState: false }
  )


export const adminReportFileStatus = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.admin.reports.status
        .replace('$id', payload.id)
        .replace('$task_id', payload.task_id),
    payload.data,
    { preloaderState: false }
  )

export const adminReportPrintFile = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.admin.reports.download
        .replace('$id', payload.id)
        .replace('$task_id', payload.task_id),
    { responseType: 'blob', preloaderState: false }
  )

export const getDepartments = () =>
  axios.get(
    Vue.config.server +
        '/' +
        Vue.config.api.department.admin.departments
  )

/**
 * Функция для получения всех заказов для конкретной школы и даты
 * @param {Object} payload - Объект с параметрами для запроса заказов
 * @param {number} payload.id - ID школы
 * @param {string} payload.date - Дата в формате 'YYYY-MM-DD' для получения заказов
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const getOrders = (payload) =>
  axios.get(
    `${Vue.config.server}/${Vue.config.api.department.orders.all(
      payload.id,
      payload.date
    )}`
  )

/**
 * Функция для получения заказов для конкретного дома в рамках школы
 * @param {Object} payload - Объект с параметрами для запроса заказов по дому
 * @param {number} payload.id - ID Школы
 * @param {number} payload.houseId - ID дома
 * @param {string} payload.date - Дата, на которую нужно установить статус отмены заказов
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const getOrdersByHouse = (payload) =>
  axios.get(
    `${Vue.config.server}/${Vue.config.api.department.orders.byHouse(
      payload.id,
      payload.houseId,
      payload.date
    )}`
  )

/**
 * Функция для загрузки заказа в виде файла для конкретной школы
 * @param {Object} payload - Объект с параметрами для загрузки заказа
 * @param {number} payload.schoolId - ID школы
 * @returns {Promise} - Возвращает промис с бинарными данными (файлом)
 */
export const printOrder = (payload) =>
  axios.post(
    `${Vue.config.server}/${Vue.config.api.department.orders.download(
      payload.schoolId
    )}`,
    payload,
    { responseType: 'blob' } // Указывает, что ответ будет в формате бинарных данных (файл)
  )

/**
 * Функция для печати меню для конкретной школы и даты
 * @param {Object} payload - Объект с параметрами для печати меню
 * @param {number} payload.schoolId - ID школы
 * @param {string} payload.date - Дата в формате 'YYYY-MM-DD', на которую нужно распечатать меню
 * @returns {Promise} - Возвращает промис с бинарными данными (файлом)
 */
export const departmentPrintMenu = (payload) =>
  axios.post(
    `${Vue.config.server}/${Vue.config.api.department.orders.menu(
      payload.schoolId,
      payload.date
    )}`,
    {},
    { responseType: 'blob', preloaderState: false } // Отключение прелоадера и указание формата ответа
  )

export const getPrintOrderTaskIdDepartment = payload =>
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.department.application_preliminary_actual.all.replace(
        '$schoolId',
        payload.school_id
      ),
    payload,
    { preloaderState: false }
  )

export const getPrintOrderStatusDepartment = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.application_preliminary_actual.status
        .replace('$schoolId', payload.school_id)
        .replace('$id', payload.id),
    { preloaderState: false }
  )

export const getPrintOrderFileDepartment = payload =>
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.application_preliminary_actual.download
        .replace('$schoolId', payload.school_id)
        .replace('$id', payload.id),
    { responseType: 'blob', preloaderState: false }
  )

/**
 * Функция для получения информации о заказе для определенного класса на указанную дату
 * @param {Object} payload - Объект с параметрами запроса
 * @param {number} payload.classId - ID класса
 * @param {string} payload.date - Дата в формате 'YYYY-MM-DD', для которой нужно получить информацию о заказе
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const getOneOrder = (payload) =>
  axios.get(
    `${Vue.config.server}/${Vue.config.api.department.orders.one(
      payload.classId,
      payload.date
    )}`
  )


export const reportAdditional = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.department.additional.one.replace(
      '$school_id',
      payload.school_id
    ),
    payload.data,
    { preloaderState: false }
  )

export const reportAdditionalStatus = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.department.additional.status
      .replace('$school_id', payload.school_id)
      .replace('$task_id', payload.task_id),
    { preloaderState: false }
  )

export const reportAdditionalDownload = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.department.additional.download
      .replace('$school_id', payload.school_id)
      .replace('$task_id', payload.task_id),
    { responseType: 'blob', preloaderState: false }
  )

export const getFilteredStudents = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.department.students.filtered
      .replace('$schoolId', payload.schoolId)
      .replace('$classId', payload.classId),
    { params: payload.params }
  )


export const getOneStudent = payload => {
  let url = Vue.config.server +
    '/' +
    Vue.config.api.department.students.one
      .replace('$schoolId', payload.schoolId)
      .replace('$id', payload.id)
  if (payload.orderId) {
    url += '?order_id=' + payload.orderId
  }
  return axios.get(url)
}

export const getPayments = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.department.balance.get.replace(
      '$schoolId',
      payload.schoolId
    ),
    payload
  )


export const printFileTaskId = payload =>
  axios.post(
    Vue.config.server +
    '/' +
    Vue.config.api.department.balance.printMoving
      .replace('$schoolId', payload.school_id)
      .replace('$id', payload.id),
    payload.data,
    { preloaderState: false }
  )

export const getPrintFileStatus = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.department.balance.printMovingProgress
      .replace('$schoolId', payload.school_id)
      .replace('$id', payload.id),
    { preloaderState: false }
  )

export const getPrintFile = payload =>
  axios.get(
    Vue.config.server +
    '/' +
    Vue.config.api.department.balance.printMovingFile
      .replace('$schoolId', payload.school_id)
      .replace('$id', payload.id),
    { responseType: 'blob', preloaderState: false }
  )

/**
 * Функция для получения списка школьных домов
 * @param {Object} [payload] - Объект с параметрами для запроса (необязательный)
 * @param {number} [payload.school_id] - ID школы (необязательный)
 * @param {number} [payload.department_id] - ID департамента (необязательный)
 * @returns {Promise} - Возвращает промис с результатом запроса
 */
export const getSchoolHouseByDepartment = (payload = {}) =>
  axios.get(
    `${Vue.config.server}/${Vue.config.api.department.school_house.base}`,
    { params: payload } // Передаем объект payload напрямую, чтобы учесть отсутствие параметров
  )

export const fetchPupilBalances = (payload) =>
  // payload: { params: { school_ids: Array } }
  axios.get(
    Vue.config.server +
      '/' +
      Vue.config.api.department.balance.pupilBalanceBySchool,
    {
      ...payload,
      paramsSerializer: (params) =>
        Object.entries(params)
          .map(([key, value]) =>
            Array.isArray(value)
              ? value.map((item) => `${key}=${item}`).join('&')
              : `${key}=${value}`
          )
          .join('&')
    }
  )

export const appointMealOrganizationForSchools = (payload) =>
  // payload = {
  //   data: [
  //     {
  //       school_id: item.id,
  //       supplier_id: item.supplier.id,
  //       saved_balance: item.savedBalance
  //     }
  //   ]
  // }
  axios.post(
    Vue.config.server +
      '/' +
      Vue.config.api.department.schools.appointMealOrganizationForSchools,
    payload
  )