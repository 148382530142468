<template>
  <div class="component">
    {{ message }}
  </div>
</template>

<script>
export default {
  props: ['message']
};
</script>
<style>
.component {
  line-height: 24px;
}
</style>