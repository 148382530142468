import PupilStartPage from '@/components/pupil/StartPage/Index'
import PupilPersonalAreaIndex from '@/components/pupil/PersonalArea/Index'
import PupilBalanceMovingIndex from '@/components/pupil/Balance/Moving/Index'
import PupilChildHistory from '@/components/pupil/History/Index'
import PupilOrderIndex from '@/components/pupil/Order/Index'

export default [
  {
    path: '/',
    name: 'Pupil',
    component: PupilStartPage
  },
  {
    path: 'personal-area',
    name: 'PupilPersonalArea',
    component: PupilPersonalAreaIndex
  },
  {
    path: 'balance/moving',
    name: 'PupilBalanceMoving',
    component: PupilBalanceMovingIndex
  },
  {
    path: 'history',
    name: 'PupilChildHistory',
    component: PupilChildHistory
  },
  {
    path: 'order',
    name: 'PupilOrderIndex',
    component: PupilOrderIndex
  },
]
