<script>
import { getMealTypesList, updateMealTypesMulti } from "@/router/routes/superadmin"
import store from "@/store"

export default {
  name: 'SuperadminMealTypesTableV2EditMultipleModal',
  props: {
    orders: {
      type: Array
    },
    showModal: {
      type: Boolean
    },
  },
  methods: {
    close() {
      this.$emit('closeModal')
    },
    async onShowModalChange(value) {
      if (value) {
        await this.getMealTypes()
      }
    },
    async getMealTypes() {
      try {
        const firstOrder = Object.values(this.orders)[0]
        const school_id = firstOrder.school_id
        if (school_id) {
          const response = await getMealTypesList(school_id)
          this.availableMealTypes = response.data
        }
      } catch (err) {
      }
      store.commit('setShowPreloader', false)
    },
    async save() {
      const payload = {
        orders: this.orders.map(item => item.id),
        meal_type: this.selectedMealType
      }
      try {
        const { data } = await updateMealTypesMulti(payload)
        this.$emit('updateOrder', data)
      } catch (err) {
      }
      store.commit('setShowPreloader', false)
    },
  },
  watch: {
    showModal(value) {
      this.onShowModalChange(value)
    },
  },
  data() {
    return {
      availableMealTypes: [],
      selectedMealType: null
    }
  }
}
</script>

<template>
  <v-dialog
      v-model="showModal"
      :persistent="true"
      :width="1000"
  >
    <v-card>
      <v-card-title>
        <h3>Выбрано заявок: {{ orders.length }}</h3>
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          solo
          v-model="selectedMealType"
          :items="availableMealTypes"
          key="id"
          item-text="name"
          item-value="id"
          label="Тип питания"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn color="error" @click="close">
          Отмена
        </v-btn>
        <v-btn color="success" @click="save">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>