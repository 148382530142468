export class Menu {
  title
  subtitle
  link
  icon = 'assignment'
  iconClass = 'blue white--text'
  hasBadge = false
  badgeClass = 'menu-notification'
  params = {}
  disabled = false

  constructor(title, subtitle, link = '', hasBadge = false, disabled = false) {
    this.title = title
    this.subtitle = subtitle
    this.link = link
    this.hasBadge = hasBadge
    this.disabled = disabled
  }

  setBadgeClass(cls) {
    this.badgeClass = cls
    return this
  }

  setParams(params) {
    this.params = params
    return this
  }

  isOnlyForParent() {
    this.isParentOnly = true
    return this
  }

  static toOneLevel(menu) {
    const _menu = []

    for (const key in menu) {
      if (menu[key].isSubmenu) {
        _menu.push(...menu[key].children)
      } else {
        _menu.push(menu[key])
      }
    }

    return _menu
  }

  get isDisabled() {
    return this.disabled
  }

  get isSubmenu() {
    return !this.subtitle && !this.link
  }
}

export class Submenu extends Menu {
  children = []

  constructor(title) {
    super(title, '')
  }

  add(...menu) {
    for (let i = 0, k = menu.length; i < k; i++) {
      this.children.push(menu[i])
    }
    return this
  }

  includes(menu) {
    let res = false
    this.children.map(i => {
      if (i.link === menu.link) res = true
      return 0
    })
    return res
  }

  remove(menu) {
    this.children = this.children.filter(item => item.link !== menu)
  }

  get link() {
    return super.link
  }
}
