import Vue from 'vue'

export const objectWithDateProps = (obj, ...props) => {
  props.forEach(prop => {
    const _prop = '_' + prop

    Object.defineProperty(obj, prop, {
      get() {
        return this[_prop] && typeof this[_prop] === 'object'
          ? this[_prop].toLocaleDateString('ru-ru')
          : this[_prop]
      },
      set(v) {
        this[_prop] = v ? new Date(v) : ''
      }
    })

    Vue.set(obj, prop, '')
  })

  return obj
}
