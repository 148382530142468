<template>
  <v-container fluid class="fs-admin-container">
    <v-layout class="fs-dish" row>
      <v-flex md3 mb-5>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout class="fs-dish-result" mb-3>
      <v-flex>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1 fs-admin-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.index + 1 }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.weightPrimary }}</td>
              <td>{{ props.item.weightMiddle }}</td>
              <td>{{ props.item.energy }}</td>
              <td>{{ props.item.protein }}</td>
              <td>{{ props.item.fats }}</td>
              <td>{{ props.item.carbs }}</td>
              <td>{{ props.item.vitamins.vitaminA }}</td>
              <td>{{ props.item.vitamins.vitaminB1 }}</td>
              <td>{{ props.item.vitamins.vitaminC }}</td>
              <td>{{ props.item.vitamins.vitaminE }}</td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="$router.push({ name: 'AdminDishesView', params: { id: props.item.id}})"
                      >remove_red_eye
                    </v-icon>
                  </template>
                  <span>Подробнее</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex text-md-right pt-3>
        <v-btn
          flat
          class="fs-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active"
          @click="$router.go(-1)"
        >Назад</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getDishes } from "@/router/routes/admin"
import store from "@/store"
import Vue from "vue"
import _ from "lodash"
import { mapGetters } from "vuex"
import scrollable from "@/mixins/scrollable"

export default {
  name: "DishIndex",
  mixins: [ scrollable ],
  created() {
    this.loadMore()
    window.addEventListener("scroll", this.scrollHandler)
  },
  destroyed() {
    this.removeScrollHandler()
  },
  data() {
    return {
      title: "Блюда",
      selected: [],
      headers: [
        { text: "№ п/п", value: "number" },
        { text: "Наименование", value: "name" },
        { text: "Вес для возрастной категории 7-10 лет", value: "weightPrimary" },
        { text: "Вес для возрастной категории 11-18 лет", value: "weightMiddle" },
        { text: "Энергетическая ценность кКал/кДж", value: "energy" },
        { text: "Белки г", value: "protein" },
        { text: "Жиры г", value: "fats" },
        { text: "Углеводы г", value: "carbs" },
        { text: "Витамин А", value: "vitaminA" },
        { text: "Витамин B1", value: "vitaminB1" },
        { text: "Витамин C", value: "vitaminC" },
        { text: "Витамин E", value: "vitaminE" },
        { text: "Операции", value: "actions" }
      ],
      items: []
    }
  },
  computed: {
    ...mapGetters([ "currentUser" ])
  },
  methods: {
    loadMore() {
      const payload = {
        schoolId: this.currentUser.school.id,
        params: {
          limit: Vue.config.general.limit,
          offset: this.offset
        }
      }
      getDishes(payload)
        .then(response => {
          const items = _.map(response.data.data, item => {
            return {
              id: item.id,
              name: item.name,
              energy: item.energyValue,
              protein: item.protein,
              fats: item.fat,
              carbs: item.carbohydrates,
              price: item.price || 0,
              vitamins: {
                vitaminA: _.get(item, "vitaminA", null),
                vitaminB1: _.get(item, "vitaminB1", null),
                vitaminC: _.get(item, "vitaminC", null),
                vitaminE: _.get(item, "vitaminE", null)
              },
              weightPrimary: item.weightPrimary,
              weightMiddle: item.weightMiddle,
              additionally: false
            }
          })

          this.items = this.items.concat(items)
        })
        .finally(() => store.commit("setShowPreloader", false))
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
