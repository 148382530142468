export const roleExtraData = {
        'teacher': {humanName: "Учитель", routePath: '/teachers/'},
        'administrator': {humanName: "Администратор", routePath: '/administrators/'},
        'municipalities': {humanName: "Муниципалитет", routePath: '/municipalities/'},
        'parent': {humanName: "Родитель", routePath: '/parents/'},
        'pupil': {humanName: "Ученик", routePath: '/pupils/'},
        'supermunicipality': {humanName: "СуперМуниципалитет", routePath: '/supermunicipality/'},
}

export const getRole = (roleEngName) => {
        return {name: roleEngName, ...roleExtraData[roleEngName]}
}
