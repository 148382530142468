import store from '@/store'
import _ from 'lodash'

import OrganizerStartPage from '@/components/organizer/StartPage/Index'
import OrganizerOrganizerCard from '@/components/organizer/OrganizerCard/Index'
import OrganizerSchoolIndex from '@/components/organizer/Schools/Index'
import OrganizerDishIndex from '@/components/organizer/Dish/Index'
import OrganizerDishArchiveIndex from '@/components/organizer/Dish/IndexArchive'
import OrganizerDishView from '@/components/organizer/Dish/View'
import OrganizerDishEdit from '@/components/organizer/Dish/Edit'
import OrganizerDishCreate from '@/components/organizer/Dish/Create'
import ComplexesView from '@/components/organizer/Complex/ComplexesView'
import ComplexItem from '@/components/organizer/Complex/ComplexItem.vue'
import OrganizerComplexChoose from '@/components/organizer/Complex/Choose'
import OrganizerComplexAssign from '@/components/organizer/Complex/Assign'
import OrganizerArhciveIndex from '@/components/organizer/Archive/Index'
import OrganizerArhciveView from '@/components/organizer/Archive/View'
import OrganizerBalanceIndex from '@/components/organizer/Balance/Index'
import OrganizerBalanceList from '@/components/organizer/Balance/List'
import OrganizerBalanceChange from '@/components/organizer/Balance/Change'
import AllDays from '@/components/organizer/Menu/AllDays'
import DayMenuTemplates from '@/components/organizer/Menu/DayMenuTemplates'
import OrganizerMenuTemplates from '@/components/organizer/Templates/Index'
import OrganizerMenuTemplatesItem from '@/components/organizer/Templates/Item'
import OrganizerOrderDetails from '@/components/organizer/Order/Details'
import OrganizerArchiveOrderDetails from '@/components/organizer/Order/DetailsArchive'
import OrganizerOrderClass from '@/components/organizer/Order/ClassView'
import OrganizerOrderArchiveClass from '@/components/organizer/Order/ArchiveClassView'
import OrganizerOrderSchool from '@/components/organizer/Order/SchoolView'
import OrganizerArchiveOrderSchool from '@/components/organizer/Order/ArchiveSchoolView'
import OrganizerBillIndex from '@/components/organizer/Bill/Index'
import OrganizerOfertaIndex from '@/components/organizer/Oferta/Index'
import OrganizerOfertaStudentsIndex from '@/components/organizer/OfertaStudents/Index'
import OrganizerReportsIndex from '@/components/organizer/Reports/Index'
import OrganizerNotificationsCreate from '@/components/organizer/Notifications/Create'
import OrganizerUsers from '@/components/organizer/Users/Index'
import OrganizerUsersActions from '@/components/organizer/UsersActions/Index'
import OrganizerOrderLogs from '@/components/organizer/OrderLogs/Index'
import OrganizerAdditionalDishes from '@/components/organizer/OrganizerAdditionalDishes/Index'
import OrganizerCashbackIndex from '@/components/organizer/Cashback/Index'
import OrganizerReportsArchiveIndex from '@/components/organizer/ReportsArchive/Index'
import OrganizerOrderDetailsPupils from '@/components/organizer/Order/PupilDetails.vue'

import OrganizerProductCategories from '@/components/organizer/ProductCategories/Index'
import OrganizerBuffetProductDict from '@/components/organizer/BuffetProductDict/Index'
import OrganizerBuffet from '@/components/organizer/Buffet/Index'

import OrganizerStorehouse from '@/components/organizer/Storehouse/StorehouseOrganizer'
import OrganizerStorehouseDetail from '@/components/organizer/Storehouse/StorehouseOrganizerDetail'
import OrganizerQualityControlWrap from '@/components/common/QualityControl/Index'
import OrganizerBuffetSchoolApplications from '@/components/organizer/BuffetSchoolApplications/Index'

import NotificationIndex from '@/components/common/NotificationsComponent/Index.vue'
import UpdatesNewsOrganizer from '@/components/organizer/UpdatesNews/Index'

import CashbackCreate from '@/components/organizer/Modals/CreateCashbackModal'

import OrganizerSyrveyResult from '@/components/admin/Syrvey/SyrveyResult'
import OrganizerSyrveyDetail from '@/components/admin/Syrvey/AdminSyrveyDetail'

import Privacy from '@/components/organizer/Privacy/Index'
import ExpelledRegister from '@/components/organizer/Cashback/ExpelledRegister'
import UserHelp from '@/components/common/UserHelp/Index.vue'
import HelpDesk from '@/components/common/HelpDesk/HelpDesk.vue'
import FormerSchoolsView
  from "@/components/organizer/FormerSchools/FormerSchoolsView.vue";
import EsiaSettings from "@/components/organizer/Esia/EsiaSettings.vue";

const isUserHasSubRoles = (meta) => {
  const currentUser = _.get(store, 'getters.currentUser')
  const userRoles = currentUser && currentUser.roles
  const isSupplier = (userRoles && userRoles[0] === 'SUPPLIER') || false
  return !isSupplier && meta && meta.subrole && userRoles.includes(meta.subrole)
}

const supplierGuardProvider = (to, from, next) => {
  if (to.params && to.params?.reauth) {
    next()
    return
  }

  if (isUserHasSubRoles(to.meta)) {
    next()
    return
  }

  next()
}

export default [
  {
    path: '/',
    name: 'Organizer',
    component: OrganizerStartPage,
    beforeEnter: supplierGuardProvider
  },
  {
    path: 'updates',
    name: 'UpdatesNewsOrganizer',
    component: UpdatesNewsOrganizer
  },
  {
    path: 'Privacy',
    name: 'Privacy',
    component: Privacy
  },
  // {
  //   path: 'user-help',
  //   name: 'OrganizerUserHelp',
  //   component: UserHelp
  // },
  {
    path: 'organizer-card',
    name: 'OrganizerCard',
    component: OrganizerOrganizerCard,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_INFO' }
  },
  {
    path: 'schools/:id',
    name: 'OrganizerSchoolIndex',
    component: OrganizerSchoolIndex,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_INFO' }
  },
  {
    path: 'reports-archive',
    name: 'OrganizerReportsArchiveIndex',
    component: OrganizerReportsArchiveIndex,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_REPORTS' }
  },
  {
    path: 'dishes',
    name: 'Dishes',
    component: OrganizerDishIndex,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_MEALS' }
  },
  {
    path: 'dishes/archive',
    name: 'DishesArchive',
    component: OrganizerDishArchiveIndex,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_MEALS' }
  },
  {
    path: 'dishes/:id/view',
    name: 'DishesView',
    component: OrganizerDishView,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_MEALS' }
  },
  {
    path: 'dishes/create',
    name: 'DishesCreate',
    component: OrganizerDishCreate,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_MEALS' }
  },
  {
    path: 'dishes/:id/edit',
    name: 'DishesEdit',
    component: OrganizerDishEdit,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_MEALS' }
  },
  {
    path: 'complexes',
    name: 'Complexes',
    component: ComplexesView,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_COMPLECTS' }
  },
  {
    path: 'complexes/stashed',
    name: 'StashedComplexes',
    component: ComplexesView,
    beforeEnter: supplierGuardProvider,
    meta: { flag: 'stashed', subrole: 'SUB_SUPPLIER_COMPLECTS_ARCHIVE' }
  },
  {
    path: 'complexes/:id/view',
    name: 'ComplexItem',
    component: ComplexItem, // Заменил OrganizerComplexView
    beforeEnter: supplierGuardProvider,
    meta: { create: false, subrole: 'SUB_SUPPLIER_COMPLECTS' }
  },
  {
    path: 'complexes/stashed/:id/view',
    name: 'StashedComplexesView',
    component: ComplexItem, // Заменил OrganizerComplexView
    beforeEnter: supplierGuardProvider,
    meta: { flag: 'stashed', subrole: 'SUB_SUPPLIER_COMPLECTS_ARCHIVE' }
  },
  {
    path: 'complexes/create',
    name: 'ComplexesCreate',
    component: ComplexItem, // OrganizerComplexCreate,
    beforeEnter: supplierGuardProvider,
    meta: { create: true, subrole: 'SUB_SUPPLIER_COMPLECTS' }
  },
  {
    path: 'complexes/choose',
    name: 'ComplexesChoose',
    component: OrganizerComplexChoose,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_COMPLECTS' }
  },
  {
    path: 'complexes/:id/assign',
    name: 'ComplexesAssign',
    component: OrganizerComplexAssign,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_COMPLECTS' }
  },
  {
    path: 'archive',
    name: 'Archive',
    component: OrganizerArhciveIndex,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_COMPLECTS_ARCHIVE' }
  },
  {
    path: 'archive/:id/view',
    name: 'ArchiveView',
    component: OrganizerArhciveView,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_COMPLECTS_ARCHIVE' }
  },
  {
    path: 'balance',
    name: 'Balance',
    component: OrganizerBalanceIndex,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_BALANCE_VIEW' }
  },
  {
    path: 'balancelist',
    name: 'BalanceList',
    component: OrganizerBalanceList,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_BALANCE_VIEW' }
  },
  {
    path: 'balancelist/:id/edit',
    name: 'BalanceChange',
    component: OrganizerBalanceChange,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_BALANCE_EDIT' }
  },
  {
    path: 'menu-templates-days',
    name: 'AllDaysMenu',
    component: AllDays,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_COMPLECTS' }
  },
  {
    path: 'menu-templates-day',
    name: 'DayMenuTemplates',
    component: DayMenuTemplates,
    beforeEnter: supplierGuardProvider,
    props: true,
    meta: { subrole: 'SUB_SUPPLIER_COMPLECTS' }
  },
  {
    path: 'daily-menu',
    name: 'MenuTemplates',
    component: OrganizerMenuTemplates,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_MENU_TEMPLATES' }
  },
  {
    path: 'daily-menu/day/:id',
    name: 'MenuTemplatesDayView',
    component: OrganizerMenuTemplatesItem,
    beforeEnter: supplierGuardProvider,
    meta: { flag: 'view', subrole: 'SUB_SUPPLIER_MENU_TEMPLATES' }
  },
  {
    path: 'daily-menu/day/:id',
    name: 'MenuTemplatesDayEdit',
    component: OrganizerMenuTemplatesItem,
    beforeEnter: supplierGuardProvider,
    meta: { flag: 'edit', subrole: 'SUB_SUPPLIER_MENU_TEMPLATES' }
  },
  {
    path: 'orders',
    name: 'OrganizerOrderSchoolView',
    component: OrganizerOrderSchool,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_ORDERS' }
  },
  {
    path: 'orders/archive',
    name: 'OrganizerArchiveOrderSchoolView',
    component: OrganizerArchiveOrderSchool,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_ORDERS' }
  },
  {
    path: 'orders/:schoolId/:date',
    name: 'OrganizerOrderClassView',
    component: OrganizerOrderClass,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_ORDERS' }
  },
  {
    path: 'orders/archive/:schoolId/:date',
    name: 'OrganizerArchiveOrderClassView',
    component: OrganizerOrderArchiveClass,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_ORDERS' }
  },
  {
    path: 'orders/:schoolId/:classId/:date',
    name: 'OrganizerOrderDetails',
    component: OrganizerOrderDetails,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_ORDERS' }
  },
  {
    path: 'orders/details/:schoolId/:classId/:date',
    name: 'OrganizerOrderDetailsPupils',
    component: OrganizerOrderDetailsPupils,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_ORDERS' }
  },
  {
    path: 'orders/archive/:schoolId/:classId/:date',
    name: 'OrganizerArchiveOrderDetails',
    component: OrganizerArchiveOrderDetails,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_ORDERS' }
  },
  {
    path: 'bills',
    name: 'Bills',
    component: OrganizerBillIndex,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_RECEIPTS' }
  },
  {
    path: 'oferta',
    name: 'Oferta',
    component: OrganizerOfertaIndex,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_OFERTA' }
  },
  {
    path: 'oferta/students',
    name: 'OfertaStudents',
    component: OrganizerOfertaStudentsIndex,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_OFERTA_STATUS' }
  },
  {
    path: 'reports',
    name: 'OrganizerReportsIndex',
    component: OrganizerReportsIndex,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_REPORTS' }
  },
  {
    path: 'notifications',
    name: 'OrganizerNotifications',
    component: NotificationIndex,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_NOTIFICATIONS' }
  },
  {
    path: 'notifications/create',
    name: 'NotificationsCreateSupplier',
    component: OrganizerNotificationsCreate,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_NOTIFICATIONS' }
  },
  {
    path: 'users',
    name: 'OrganizerUsers',
    component: OrganizerUsers,
    beforeEnter: supplierGuardProvider
  },
  {
    path: 'quality-control',
    name: 'OrganizerQualityControlWrap',
    component: OrganizerQualityControlWrap,
    props: true
  },
  {
    path: 'users-actions',
    name: 'OrganizerUsersActions',
    component: OrganizerUsersActions,
    beforeEnter: supplierGuardProvider
  },
  {
    path: 'order-logs',
    name: 'OrganizerOrderLogs',
    component: OrganizerOrderLogs,
    beforeEnter: supplierGuardProvider
  },
  {
    path: 'additional-dishes',
    name: 'OrganizerAdditionalDishes',
    component: OrganizerAdditionalDishes,
    beforeEnter: supplierGuardProvider
  },
  {
    path: 'cashback-requests',
    name: 'OrganizerCashbackIndex',
    component: OrganizerCashbackIndex,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_CASHBACK_INDEX' }
  },
  {
    path: 'former-schools',
    name: 'FormerSchoolsView',
    component: FormerSchoolsView,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_FORMER_SCHOOLS' }
  },
  {
    path: 'expelled-register',
    name: 'ExpelledRegister',
    component: ExpelledRegister,
    beforeEnter: supplierGuardProvider,
    meta: { subrole: 'SUB_SUPPLIER_EXPELLED_REGISTER' }
  },
  {
    path: 'storehouse/storehouse-detail',
    name: 'StorehouseOrganizerDetail',
    component: OrganizerStorehouseDetail
  },
  {
    path: 'storehouse',
    name: 'StorehouseOrganizer',
    component: OrganizerStorehouse
  },
  {
    path: 'product-categories',
    name: 'OrganizerProductCategories',
    component: OrganizerProductCategories,
    props: true
  },
  {
    path: 'buffet-product-dict',
    name: 'BuffetProductDict',
    component: OrganizerBuffetProductDict
  },
  {
    path: 'buffet-schools-applications',
    name: 'BuffetSchoolApplications',
    component: OrganizerBuffetSchoolApplications
  },
  {
    path: 'buffet',
    name: 'OrganizerBuffet',
    component: OrganizerBuffet
  },
  {
    path: 'syrvey-result',
    name: 'OrganizerSyrveyResult',
    component: OrganizerSyrveyResult,
    props: { user_role: 'is_supplier' }
  },
  {
    path: 'syrvey-detail',
    name: 'OrganizerSyrveyDetail',
    component: OrganizerSyrveyDetail,
    props: { user_role: 'is_supplier' }
  },
  {
    path: 'helpdesk',
    name: 'HelpDeskSU',
    component: HelpDesk,
    meta: { userRole: 'supplier' }
  },
  {
    path: 'esia',
    name: 'EsiaSupplierSettings',
    component: EsiaSettings,
    meta: { userRole: 'supplier' }
  },
]
