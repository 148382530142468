<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-order"
      row
      mb-3
    >
      <v-flex mb-3>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout
      mb-4
      column
    >
      <v-flex>
        <h2>Загрузить данные о платежах</h2>
        <p class="fs-color--red">
          Внимание - входной файл в формате .csv, разделенного точкой с запятой, строго в кодировке UTF-8.
        </p>
        <p>
          <a :href="paymentsLink" target="_blank">Пример файла</a>
        </p>
        <v-card
          justify-space-between
          class="mb-4 mt-4 pa-2"
        >
          <v-form>
            <v-layout align-center>
              <v-flex md6>
                Данные о платежках:
                {{ fileName }}
                <UploadButton
                  :uniqueId="true"
                  title="Выберите файл"
                  noTitleUpdate
                  :fileChangedCallback="fchangedCallback"
                />
              </v-flex>
              <v-flex md3>
                <v-select
                  no-data-text="Выберите ОП"
                  v-model="supplierId"
                  item-text="username"
                  item-value="id"
                  :items="organizers"
                  label="ОП"
                />
              </v-flex>
              <v-flex
                md3
                class="text-md-right"
              >
                <v-btn
                  @click="upload"
                  class="primary"
                  :disabled="!supplierId"
                >Загрузить</v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
    <History :key="componentKey"/>
    <ConfirmModal
      :dialog.sync="showModal"
      :hideConfirmButton="true"
      :cancelBtnText="'Закрыть'"
      :width="1000"
    >
      <template v-slot:headline>Платежи не были загружены</template>
      <template v-slot:body>
        <p v-for="error in errors" :key="error">
          {{ error }}
        </p>
      </template>
    </ConfirmModal>
  </v-container>
</template>
<script>
import UploadButton from '@/components/common/UploadButton/UploadButton'
import History from '@/components/moduleadmin/Payments/History'
import { ConfirmModal } from '@/components/common'
import {
  uploadPayments, getOrganizers
} from '@/router/routes/superadmin'
import _ from 'lodash'
import Vue from 'vue'
import store from '@/store'
import { mapMutations } from 'vuex'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'SuperadminPayments',
  components: {
    UploadButton, History, ConfirmModal
  },
  data() {
    return {
      fileName: '',
      file: {},
      title: 'Зачисление остатков',
      organizers: [],
      supplierId: null,
      errors: [],
      showModal: false,
      componentKey: 0
    }
  },
  computed: {
    paymentsLink() {
      return Vue.config.publicPath + 'payments.csv'
    },
  },
  created() {
    const payload = {
      limit: 500,
    }
    getOrganizers(payload)
      .then(response => {
        this.organizers = _.map(response.data.data, item => {
          return {
            id: item.id,
            username: item.username,
          }
        })
      })
      .finally(() => store.commit('setShowPreloader', false))
  },
  methods: {
    handlerConfirmModal() {
      this.showModal = false
      this.errors = []
    },
    ...mapMutations([ 'setShowPreloader' ]),
    fchangedCallback(event){
      this.file = event
      this.fileName = event.name
    },

    upload() {
      if (_.get(this.file, 'name', '')) {
        const form = new FormData()
        form.append('uploaded_file', this.file)
        uploadPayments(form, this.supplierId, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            if (response.data.data.errors.length) {
              this.errors = response.data.data.errors
              this.showModal = true
            } else if (response.data.data.handled_payments) {
              this.supplierId = null
              const x = response.data.data.handled_payments
              notify.success(`Обработано успешно ${x} платежей`)
              this.componentKey += 1
            } else {
              notify.error(`Неизвестная ошибка`) //pass (but exception backend handler added)
            }
          })
          .finally(() => store.commit('setShowPreloader', false))
        return null
      }
      notify.error(Vue.config.notifications.uploadFailed) //pass
    }
  }
}
</script>
