<script>
import Vue from 'vue'

export default {
  name: 'SuppliersAutocomplete',
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      suppliers: [],
      isSuppliersLoading: false,
      suppliersSearchQuery: null,
    }
  },
  props: {
    modelValue: { type: Object },
    fetchSuppliers: { type: Function },
  },
  mounted() {
    if (this.suppliers.length === 0) this.handleFetchSuppliers()
  },
  methods: {
    onInput(newValue) {
      this.$emit('input', newValue)
    },
    handleFetchSuppliers() {
      this.isSuppliersLoading = true
      this.fetchSuppliers({ search: this.suppliersSearchQuery })
        .then(response => {
          this.suppliers = response.data.results
        }).finally(() => {
          this.isSuppliersLoading = false
        })
    },
  },
  watch: {
    suppliersSearchQuery: _.debounce(function () {
      this.handleFetchSuppliers()
    }, 500),
  },
}
</script>

<template>
  <v-autocomplete
      :value='modelValue'
      @input='onInput'

      :items="suppliers"
      :loading='isSuppliersLoading'
      :search-input.sync='suppliersSearchQuery'
      no-filter
      item-value="id"
      item-text="username"
      return-object
      clearable
      :label="!darkTheme ? 'ОП' : ''"
      :placeholder="darkTheme ? 'ОП' : ''"
      :outline="darkTheme"
  >
    <template v-slot:item='{ item }'>
      <span>{{ item.username }}</span>
      <span v-if='item.short_name'>&nbsp;- {{ item.short_name }}</span>
    </template>
  </v-autocomplete>
</template>

<style scoped>

</style>
