import { Menu } from '@/lib/menu'

export default class MainMenuFactory {
  constructor(settings) {
    let menu = [
      new Menu('Новости', 'Новости обновлений', 'UpdatesNewsTeacher'),
      new Menu('Табель заявок на питание', 'Просмотр и создание заявок на питание', 'TeacherOrders'),
      new Menu(
        'Карточки учеников',
        'Просмотр и управление информацией об учениках: баланс, тип питания, питание в кредит',
        'Students'
      ),
      new Menu('Отчёты', 'Формирование отчётности по своим классам', 'Reports'),
      new Menu('Уведомления', 'Просмотр уведомлений', 'TeacherNotifications', true)
    ]

    if(settings.payment_method_by_receipt?.value) {
      menu.splice( 3,0,     new Menu('Печать квитанций', 'Печать квитанций на оплату питания детей', 'OrdersPrint'),
      )
    }

    if (settings.user_help?.value) {
      menu = menu.concat([
        // new Menu('Инструкции', 'Инструкции', 'TeacherUserHelp'),
        new Menu('Справка', 'Справка', 'HelpDeskTE')
      ])
    }

    return menu
  }
}
