<template>
  <Pug>
    <h1>404</h1>
    <p>Запрашиваемая Вами страница не найдена.</p>
    <a href="/">Вернуться на главную</a>
  </Pug>
</template>

<script>
import Pug from '@/components/lib/Pug'

export default {
  name: 'NotFound',
  components: {
    Pug
  }
}
</script>
