<script >
import {disableSorting} from "@/mixins/helpers";

export default {
  name: 'OrderLogsTable',
  mixins: [ disableSorting ],
  props: {
    orders: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      headers: [
        { text: 'Номер заявки', value: 'orderId' },
        { text: 'Лицевой счет', value: 'pupilId' },
        { text: 'Дата', value: 'createdAt' },
        { text: 'Событие', value: 'changeType' },
        { text: 'Комментарий', value: 'comment' },
        { text: 'Автор', value: 'author' }
      ],
      search: ""
    }
  }
}
</script>

<template>
<div class="py-2">
  <v-card>
    <v-card-text class="pt-1 pb-0">
      <div class="d-flex justify-space-between">
        <v-text-field
            solo
            v-model="search"
            label="Поиск по таблице"
            style="max-width: 400px"
            hide-details
        />
      </div>
      <v-divider />
      <v-data-table
        :items="orders"
        :headers="headers"
        :search="search"
        :rows-per-page-items="[25, 50, -1]"
      >
        <template v-slot:items="props">
          <tr>
            <td>{{ props.item.orderId }}</td>
            <td>{{ props.item.pupilId }}</td>
            <td>{{ props.item.createdAt }}</td>
            <td>{{ props.item.changeType }}</td>
            <td>{{ props.item.comment }}</td>
            <td>{{ props.item.author }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</div>
</template>

<style scoped>

</style>
