<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <v-layout
      class="fs-complex"
      row
      mb-6
    >
      <v-flex
        md12
        mb-12
      >
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout
      row
      mb-6
      justify-space-around
      text-md-center
      class="fs-header-filter"
    >
    </v-layout>
    <v-layout
      class="fs-teacher-order-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1 fs-admin-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.short_name }}</td>
              <td>
                <v-icon
                    v-on="on"
                    class="fs-active-icon mr-1"
                    @click="$router.push({ name: 'AdminOrganizerCard'})"
                  >remove_red_eye</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex
        text-md-right
        pt-3
      >
        <v-btn
          flat
          class="fs-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active"
          @click="$router.go(-1)"
        >Назад</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getOrganizers } from '@/router/routes/admin'
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AdminOrganizersIndex',
  created() {
    this.loadMore()
  },
  data() {
    return {
      title: 'Список организаторов питания',
      headers: [
        { text: '№ п/п', value: 'id' },
        { text: 'Наименование ОП', value: 'name' },
        { text: 'Операции', value: 'operations' },
      ],
      items: [],
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10)
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    loadMore() {
      const payload = {
        schoolId: this.currentUser.school.id
      }
      getOrganizers(payload)
        .then(response => {
          if (response.data.data) {
            this.items = Array.of(response.data.data)
          }
          else {
            this.items = []
          }
        })
        .finally(() => store.commit('setShowPreloader', false))
    }
  }
}
</script>