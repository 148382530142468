<template>
  <v-container :class="{ 'fs-teacher-container': !isSupplier && !isAdmin }" fluid>
    <div class="storehouse-head">
      <h1>Контроль качества</h1>
    </div>

    <v-tabs v-model="active" style="margin-top: 25px;">
      <v-tab>Оценки заказов</v-tab>
      <v-tab v-if="isSchoolMealAdmin || isDepartment || isSupplier">Отчёты по ежедневному меню</v-tab>
      <v-tab v-if="isDepartment || isAdmin">Средняя оценка по ОП</v-tab>

      <v-tab-item>
        <QualityControl
          v-if="userRoles && userRoles.length > 0"
          :fetchQualityAssesments="fetchQualityAssesments"
          :fetchSchools="fetchSchools"
          :fetchSuppliers="fetchOrganizers"
          :filters="filters[currentDomainRole] || []"
        />
      </v-tab-item>

      <v-tab-item v-if="isSchoolMealAdmin || isDepartment || isSupplier">
        <QualityControlPhotoReports
          :fetch-suppliers='fetchOrganizers'
          :isUserDepartment="isDepartment"
        />
      </v-tab-item>

      <v-tab-item v-if="isDepartment || isAdmin">
        <QualityAvgSuppliers />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import QualityControl from '@/components/common/QualityControl/QualityControl'
import QualityAvgSuppliers from '@/components/common/QualityControl/QualityAvgSuppliers'
import QualityControlPhotoReports from '@/components/common/QualityControl/QualityControlPhotoReports'

import { getSchools as getSchoolsSuperadmin } from '@/router/routes/superadmin'
import { getSchools as getSchoolsMealAdmin } from '@/router/routes/admin'
import { getSchools as getSchoolsOrganizer } from '@/router/routes/organizer'

import { getSchools as getSchoolsDepartment } from '@/router/routes/department'
import { getQualityAssesmentsSuppliers } from '@/router/routes/common'


export default {
  name: 'QualityControlWrap',

  components: { QualityControl, QualityAvgSuppliers, QualityControlPhotoReports },

  data: () => ({
    ROLES: {
      SCHOOL_MEAL_ADMIN: 1,
      DEPARTMENT_ADMIN: 7,
      DEPARTMENT: 4,
      SUPPLIER: 6,

      ADMIN: 5,
      TEACHER: 2,
      PARENT: 3,
      SUB_SUPPLIER_INFO: 8,
      SUB_SUPPLIER_BALANCE_VIEW: 9,
      SUB_SUPPLIER_BALANCE_EDIT: 10,
      SUB_SUPPLIER_RECEIPTS: 11,
      SUB_SUPPLIER_OFERTA: 12,
      SUB_SUPPLIER_OFERTA_STATUS: 13,
      SUB_SUPPLIER_NOTIFICATIONS: 14,
      SUB_SUPPLIER_ORDERS: 15,
      SUB_SUPPLIER_MEALS: 16,
      SUB_SUPPLIER_COMPLECTS: 17,
      SUB_SUPPLIER_COMPLECTS_ARCHIVE: 18,
      SUB_SUPPLIER_MENU_TEMPLATES: 19,
      SUB_SUPPLIER_DAILY_MENU: 20,
      SUB_SUPPLIER_REPORTS: 21,
      PUPIL: 22,
      SUB_SUPPLIER_EXPELLED_REGISTER: 23,
      SUB_SUPPLIER_CASHBACK_INDEX: 24

    },
    active: 0,
    filters: {
      ADMIN: [ 'school', 'datepicker', 'evaluation', 'supplier' ],
      SCHOOL_MEAL_ADMIN: [ 'school', 'datepicker', 'evaluation', 'supplier' ],
      DEPARTMENT_ADMIN: [ 'school', 'datepicker', 'evaluation', 'supplier' ],
      DEPARTMENT: [ 'school', 'datepicker', 'evaluation', 'supplier' ],
      SUPPLIER: [ 'school', 'datepicker', 'evaluation' ],
    },
    userRoles: [], // Array<{ id: number }>
  }),

  computed: {
    ...mapGetters([ 'currentUser' ]),
    userFilters() {
      return this.filters[key]
    },
    isAdmin() {
      return this.userRoles.some(roleObj => +roleObj.id === this.ROLES.ADMIN)
    },
    isSchoolMealAdmin() {
      return this.userRoles.some(roleObj => +roleObj.id === this.ROLES.SCHOOL_MEAL_ADMIN)
    },
    isDepartmentAdmin() {
      return this.userRoles.some(roleObj => +roleObj.id === this.ROLES.DEPARTMENT_ADMIN)
    },
    isDepartment() {
      return this.userRoles.some(roleObj => +roleObj.id === this.ROLES.DEPARTMENT)
    },
    isSupplier() {
      return this.userRoles.some(roleObj => +roleObj.id === this.ROLES.SUPPLIER)
    },
    currentDomainRole() {
      if (this.isAdmin) return "ADMIN"
      if (this.isSchoolMealAdmin) return "SCHOOL_MEAL_ADMIN"
      if (this.isDepartmentAdmin) return "DEPARTMENT_ADMIN"
      if (this.isDepartment) return "DEPARTMENT"
      if (this.isSupplier) return "SUPPLIER"
    },
  },

  methods: {
    ...mapActions([ 'get_quality_assesments', 'fetchOrganizers' ]),
    ...mapMutations([ 'setShowPreloader' ]),
    fetchQualityAssesments() {
      this.get_quality_assesments()
    },
    async fetchSchools(params) {
      let getSchools = () => {}
      if (this.isAdmin) getSchools = getSchoolsSuperadmin
      else if (this.isSchoolMealAdmin) getSchools = getSchoolsMealAdmin
      else if (this.isDepartmentAdmin || this.isDepartment ) getSchools = getSchoolsDepartment
      else if (this.isSupplier) getSchools = getSchoolsOrganizer
      try {
        const payload = { params, preloaderState: false }
        if (this.isDepartmentAdmin || this.isDepartment) payload.department_id = this.currentUser.department.id
        const response = await getSchools(payload)
        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.setShowPreloader(false)
      }
    },
    async fetchOrganizers(params) {
      try {
        const payload = { page: 1, page_size: 50, ...params }
        const response = await getQualityAssesmentsSuppliers(payload, { preloaderState: false })
        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      } finally {
        this.setShowPreloader(false)
      }
    },
    getRoleNameById(id) {
      return Object.entries(this.ROLES).find(role => role[1] === Number(id))[0]
    },
    transformUserRole() { // Это сделано т.к при авторизации объект ролей может придти разный [ "SUPPLIER" ] или [ { id: 6 } ]; return Array<{ id: number }>
      const isRoleExist = this.currentUser && this.currentUser.roles && this.currentUser.roles.length > 0
      if (isRoleExist) {
        typeof(this.currentUser.roles[0]) === 'string'
          ? this.userRoles = this.currentUser.roles.map(role => ({ id: this.ROLES[role], role: role }))
          : this.userRoles = this.currentUser.roles.map(role => ({ id: +role.id, role: this.getRoleNameById(role.id) }))
      }
    }
  },

  mounted() {
    this.transformUserRole()
  },

  watch: {
    'currentUser'() {
      this.transformUserRole()
    }
  },
}
</script>

<style>
.helpers li span {
  font-weight: bold;
}
</style>
