<template>
  <Container fluid>
    <Title layout>{{ title }}</Title>
    <v-layout
      row
      mb-12
      justify-start
      text-md-center
    >
      <v-flex md3 mr-3>
        <v-autocomplete
          :value="selectedSchoolID"
          @change="changeSchoolValue('selectedSchoolID', $event)"
          :items="schools"
          item-text="name"
          item-value="id"
          label="Школа:"
        />
      </v-flex>
      <v-flex md3 mr-3>
        <v-autocomplete
          :value="selectedClassID"
          @change="changeValue('selectedClassID', $event)"
          :items="classes"
          item-text="name"
          item-value="id"
          label="Класс:"
        />
      </v-flex>
      <v-flex md3 mr-3>
        <v-text-field
          label="ФИО"
          :value="pupilName"
          @change="changeValue('pupilName', $event)"
        ></v-text-field>
      </v-flex>
      <v-flex md3 mr-3>
        <v-btn
          flat
          @click="loadMore"
          class="fs-btn fs-btn--primary"
        >Показать</v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      row
      mb-12
      justify-start
      text-md-center
    >
      <v-flex md3 mr-3>
        <v-text-field
          label="Лицевой счет или ФИО"
          :value="account_id_or_lastname"
          @input="changePupil($event)"
          @keyup.enter="findPupil"
          type="text"
        ></v-text-field>
      </v-flex>
      <v-flex md3 mr-3>
        <v-btn
          :disabled="!account_id_or_lastname"
          flat
          @click="findPupil"
          class="fs-btn fs-btn--primary"
        >Поиск</v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      v-if="viewBalanceStudent"
      wrap
      class="fs-organizer-order-result"
      mb-12
    >
      <v-data-table
        :headers="headersBalance"
        :items="viewBalanceStudent"
        hide-actions
        class="elevation-1"
        item-key="full_name"
        dense
      >
        <template v-slot:items="props">
          <tr>
            <td>{{ props.item.pk }}</td>
            <td>{{ props.item.full_name }}</td>
            <td>{{ props.item.school }}</td>
            <td>{{ props.item.school_class }}</td>
            <td>{{ getBalanceOfPupil(props.item.balance) }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-layout>
    <v-layout
      wrap
      class="fs-organizer-order-result"
      style="margin-top: 15px;"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1"
          item-key="name"
          dense
        >
          <template v-slot:items="props">
            <tr>
              <td @click="showPupilTransactions(props.item.id)" class="cursor-pointer">{{ props.item.id }}</td>
              <td @click="showPupilTransactions(props.item.id)" class="cursor-pointer">
                <v-tooltip bottom v-if="props.item.isFake">
                  <template v-slot:activator="{ on }">
                    <v-icon color="error" v-on="on">
                      warning
                    </v-icon>
                  </template>
                  По ученику получены неполные данные, работа с ним временно заблокирована.
                </v-tooltip>
                {{ props.item.name }}
              </td>
              <td @click="showPupilTransactions(props.item.id)" class="cursor-pointer">{{ props.item.schoolClass.name }}</td>
              <td>
                <v-tooltip top v-if="!props.item.isFake">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="handlerChange(props.item)"
                    >edit</v-icon>
                  </template>
                  <span>Изменить</span>
                </v-tooltip>
                <v-tooltip top v-if="!props.item.isFake">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="ml-3"
                      v-on="on"
                      @click="showPupilTransactions(props.item.id)"
                    >remove_red_eye</v-icon>
                  </template>
                  <span>Транзакции</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <ConfirmModal
      v-if="currentStudent"
      :dialog.sync="showModal"
      @confirm="handlerConfirmModal"
      confirmBtnText="Сохранить"
      :disable-confirm-button="!comment || saveButtonClicked"
    >
      <template v-slot:headline>{{`Карточка ученика: ${currentStudent.name}`}}
      </template>
      <template v-slot:body>
        <v-layout
          class="fs-student-info"
          column
        >
          <v-flex md6>
            <v-card class="fs-fields-group--gray">
              <v-card-text>
                <v-flex mb-2>
                  <span class="mr-2"><b>Лицевой счет:</b></span>
                  <span>{{ currentStudent.id }}</span>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex>
            <v-card class="fs-fields-group--gray">
              <v-card-title>
                <h2>Баланс</h2>
              </v-card-title>
              <v-card-text>
                <v-divider></v-divider>
                <v-layout
                  mb-3
                  mt-3
                >
                  <v-flex>
                    <span class="fs-student-info__key">Текущий баланс:</span>
                  </v-flex>
                  <v-flex text-md-right>
                    <span class="fs-student-info__key">{{ currentStudentBalance || 0 }} р.</span>
                  </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <v-layout
                  mb-3
                  mt-3
                >
                  <v-flex>
                    <v-text-field
                      label="Ручная корректировка"
                      v-model="correctBalance"
                    />
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      label="Описание операции"
                      v-model="comment"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </template>
    </ConfirmModal>
    <Modal
      ref="pupilTransactions"
      title="Транзакции"
      fullscreen
      @close:before="transactions.resource.pupilId = null"
    >
      <Table
        :headers="transactions.headers"
        :request="transactions.getTransactions"
        :resource="transactions.resource"
        showNumbering
      >
        <template v-slot:items="props">
          <td>{{ new Date(props.item.time).toLocaleString('ru-RU') }}</td>
          <td
            :class="{
              'green--text': props.item.type_id === 1,
              'red--text': props.item.type_id === 2,
              'font-weight-bold': true
            }"
          >{{ transactions.getSum(props.item) }}</td>
          <td>{{ props.item.balance.priceToString() }}</td>
          <td>{{ props.item.code }}</td>
          <td>{{ props.item.type }}</td>
        </template>
      </Table>
    </Modal>
  </Container>
</template>

<script>
import Container from '@/ui-components/Container'
import Modal from '@/ui-components/Modal'
import Title from '@/ui-components/Title'
import Table from '@/ui-components/Table'

import {
  getSchools,
  getClasses,
  getFilteredStudents,
  getOneStudent,
  updateStudentBalance,
  getStudentsBalanceBySupplier,
  getTransactions
} from '@/router/routes/organizer'
import numable from '@/mixins/balancable'
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import scrollable from '@/mixins/scrollable'
import { ConfirmModal } from '@/components/common'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'StudentIndex',
  mixins: [ scrollable, numable ],
  data() {
    return {
      title: 'Изменение баланса',
      headers: [
        { text: '№ п/п', value: 'id', width: '50%' },
        { text: 'Имя', value: 'name' },
        { text: 'Класс', value: 'class' },
        { text: 'Операции', value: 'operations' }
      ],
      headersBalance: [
        { text: '№ п/п', value: 'pk' },
        { text: 'ФИО', value: 'full_name' },
        { text: 'Школа', value: 'school' },
        { text: 'Класс', value: 'school_class' },
        { text: 'Баланс', value: 'balance' }
      ],
      items: [],
      classes: [],
      selectedClassID: null,
      schools: [],
      selectedSchoolID: null,
      accountIdValue: null,
      LIMIT: Vue.config.general.limit,
      showModal: false,
      modalResponse: false,
      currentStudent: null,
      correctBalance: 0,
      comment: '',
      pupilName: '',
      account_id_or_lastname: null,
      viewBalanceStudent: null,
      saveButtonClicked: false,
      transactions: {
        headers: [
          { text: 'Время операции', value: 'time' },
          { text: 'Сумма, руб.', value: 'sum' },
          { text: 'Баланс, руб.', value: 'balance' },
          { text: 'Код транзакции', value: 'code' },
          { text: 'Тип операции', value: 'type' }
        ],
        getTransactions,
        resource: {
          pupilId: null
        },
        getSum: (item) => {
          return (item.type_id === 1 ? '+ ' : '') + item.sum.priceToString()
        }
      }
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
    currentStudentBalance() {
      return (
        +this.convertToRubles(this.currentStudent.balance) +
        +this.correctBalance
      ).toFixed(2)
    }
  },
  created() {
    this.init()
    window.addEventListener('scroll', this.scrollHandler)
  },
  destroyed() {
    this.removeScrollHandler()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    getBalanceOfPupil(balance) {
      return typeof balance === 'number' && balance !== null ? balance.priceToString() : balance
    },
    getClassLiter(name) {
      return name.split(' ')[1]
    },
    getClassNumber(name) {
      return name.split(' ')[0]
    },
    async handlerChange(item) {
      this.currentStudent = item
      this.correctBalance = 0
      this.comment = ''
      await this.getStudent(item.id)
      this.showModal = true
    },
    async findPupil() {
      if (!this.account_id_or_lastname) {
        return false
      }
      try {
        const response = await getStudentsBalanceBySupplier({
          params: {
            account_id_or_lastname: this.account_id_or_lastname
          }
        })
        this.viewBalanceStudent = response.data
      } catch (e) {
        //notify.error('Ошибка загрузки ученика')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getStudent(id) {
      try {
        const PAYLOAD = {
          schoolId: this.selectedSchoolID,
          id: id
        }
        const response = await getOneStudent(PAYLOAD)
        const student = response.data.data
        this.currentStudent = { ...this.currentStudent, ...student }
      } catch (error) {
        //notify.error('Ошибка загрузки ученика')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async updateStudent() {
      try {
        const PAYLOAD = {
          personId: this.currentStudent.personId,
          amount: this.convertToCents(this.correctBalance),
          comment: this.comment,
        }
        const response = await updateStudentBalance(PAYLOAD)
        if (response.data.success) {
          notify.success(Vue.config.notifications.administrator.studentUpdated)
        }
      } catch (error) {
        notify.error('Не удалось зачислить транзакцию, вероятно она уже была зачислена ранее')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async handlerConfirmModal(value) {
      this.saveButtonClicked = value
      this.modalResponse = value
      if (this.modalResponse) {
        if (this.correctBalance !== 0) {
          this.updateStudent().finally(() => {
            this.saveButtonClicked = false
          })
        }
      }
    },
    async init() {
      try {
        await this.getSchools()
        await this.loadMore()
      } catch (error) {
        // notify.error('Ошибка инициализации страницы') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getSchools() {
      try {
        const PAYLOAD = {
          limit: this.LIMIT,
          offset: this.offset
        }
        const response = await getSchools(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
        this.schools = [ ...this.schools, ...itemsArray ]
        if (!this.schools[0]) {
          notify.error('Вам не назначена ни одна школа')
        }
        else {
          this.selectedSchoolID = this.schools[0].id
          await this.getClasses()
        }
      } catch (error) {
        notify.error('Ошибка загрузки школ')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getClasses() {
      const PAYLOAD = {
        limit: Vue.config.general.classLimit,
        offset: this.offset,
        school_id: this.selectedSchoolID
      }
      try {
        const response = await getClasses(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
        this.classes = [ ...this.classes, ...itemsArray ]
        this.selectedClassID = this.classes[0] ? this.classes[0].id : null
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async loadMore() {
      if (!this.selectedClassID) {
        return
      }
      const schoolIdValue = this.selectedSchoolID
      const selectedClassValue = this.selectedClassID
      try {
        const payload = {
          schoolId: schoolIdValue,
          classId: selectedClassValue,
          params: {
            limit: this.LIMIT,
            offset: this.offset,
            schoolId: schoolIdValue,
            accountId: this.accountIdValue,
            fullName: this.pupilName
          }
        }
        if (selectedClassValue) {
          payload.params.classId = selectedClassValue
        }
        const response = await getFilteredStudents(payload)
        const {
          data: { data: students }
        } = response

        const itemsArray = students.map(item => {
          const {
            id = null,
            schoolClass = {
              name: null
            },
            isFake = null,
            fullName = null
          } = item
          return {
            id: id,
            isFake: isFake,
            schoolClass: {
              name: schoolClass.name
            },
            name: fullName
          }
        })

        this.items = itemsArray
      } catch (error) {
        // notify.error('Ошибка загрузки учеников') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async changeSchoolValue(type, value) {
      this[type] = value
      this.resetData()
      this.classes = []
      await this.getClasses()
    },
    changeValue(type, value) {
      this[type] = value
      this.resetData()

      if (type === 'selectedClassID') {
        this.loadMore()
      }
    },
    changePupil(value) {
      this.account_id_or_lastname = value
      this.viewBalanceStudent = false
    },
    resetData() {
      this.offset = 0
      this.selected = []
      this.items = []
      this.dataTableLoaded = false
    },
    checkTypeObject(value) {
      return typeof value === 'object'
    },
    showPupilTransactions(pupilId) {
      this.$refs.pupilTransactions.open()
      this.transactions.resource.pupilId = pupilId
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  },
  components: {
    ConfirmModal,
    Container,
    Modal,
    Title,
    Table
  }
}
</script>
