export default class AdminStudentFactory {
  constructor() {
    return {
      id: null,
      firstName: '',
      lastName: '',
      middleName: '',
      concession: {
        name: ''
      },
      foodType: {
        id: null,
        name: ''
      },
      schoolClass: {
        id: null,
        name: ''
      },
      balance: {
        avaliable: 0,
        reserved: 0,
        current: 0
      },
      foodOrganizer: {
        id: null,
        name: ''
      },
      validFoodTypes: [],
      isLongDay: false,
      isCredit: false
    }
  }
}
