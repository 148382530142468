<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <AdminStudentsView
      :municipality-view="true"
      :municipality-school-id="$route.params.schoolId"
    />
  </v-container>
</template>

<script>
import AdminStudentsView from '@/components/admin/Students/Veiw'

export default {
  name: 'MunicipalityOrders',
  components: {
    AdminStudentsView
  }
}
</script>
