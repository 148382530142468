import { getStudentBlackList, getBlackListDetail, getStorehousesProducts, createBlackList, updateBlackList } from '@/router/routes/admin'

export default {
  actions: {
    async getStudentBlackList(ctx, payload) {
      await getStudentBlackList(payload)
        .then(res => {
          ctx.commit('set_pupil_blacklist', res.data.results)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async getBlackLstDetail(ctx, payload) {
      await getBlackListDetail(payload)
        .then(res => {
          ctx.commit('set_blacklist_detail', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async getAllBuffetProducts(ctx, payload) {
      await getStorehousesProducts(payload)
        .then(res => {
          ctx.commit('set_all_buffet_products', res.data.results)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async createBlackList(ctx, payload) {
      await createBlackList(payload)
        .then(res => {
          ctx.commit('set_blacklist_detail', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async updateBlackList(ctx, payload) {
      await updateBlackList(payload)
        .then(res => {
          ctx.commit('set_blacklist_detail', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },

    
  },
  mutations: {
    set_blacklist_detail(state, blacklist_detail) {
      state.blacklist_detail = blacklist_detail
    },
    set_all_buffet_products(state, all_buffet_products) {
      state.all_buffet_products = all_buffet_products
    },
    set_pupil_blacklist(state, pupil_blacklist) {
      state.pupil_blacklist = pupil_blacklist
    },
    clear_all_blacklist(state) {
      state.all_buffet_products = []
      state.blacklist_detail = {
        products: []
      }
      state.pupil_blacklist = []
      state.pupil_school_pk = null
    },
    set_blacklist_all_schools(state, data) {
      state.schools = data
    },
    set_blacklist_school_user(state, data) {
      state.school_user = data
    },
    set_blacklist_school_pk(state, value) {
      state.school_pk = value
    },
    set_blacklist_pupil_school_pk(state, value) {
      state.pupil_school_pk = value
    },
  },
  getters: {
    blacklist_detail(state) {
      return state.blacklist_detail
    },
    all_buffet_products(state) {
      return state.all_buffet_products
    },
    pupil_blacklist(state) {
      return state.pupil_blacklist
    },
    blacklist_all_schools(state) {
      return state.schools
    },
    blacklist_school_pk(state) {
      return state.school_pk
    },
    blacklist_pupil_school_pk(state) {
      return state.pupil_school_pk
    },
    blacklist_school_user(state) {
      return state.school_user
    },
  },
  state: {
    all_buffet_products: [],
    blacklist_detail: {
      products: []
    },
    pupil_blacklist: [],
    pupil_school_pk: null,
    school_pk: null,

    schools: [],
    school_user: []
  },
}
