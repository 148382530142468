<script>
import Modal from '@/ui-components/Modal.vue'
import {
  createBoardingSchoolsUser,
  getBoardingSchoolsDepartmentList
} from '@/router/routes/superadmin'
import store from '@/store'
import notify from '@/utils/notifications/toastNotification.vue'
import getDisplayName from '@/helpers/getDisplayName'
import rulesCustom from '@/helpers/rulesCustom'

export default {
  name: 'ModalCreateBoardingSchoolUser',
  components: { Modal },
  emits: ['update-table'],
  data() {
    const rules = rulesCustom

    return {
      isValid: false,
      municipalities: [],
      fieldsValue: {},
      title: 'Добавление пользователя Управления Школы-интернат',
      fields: {
        username: {
          type: 'text',
          key: 'username',
          label: 'Логин',
          value: null,
          rules: [
            rules.required,
            rules.maxLength(50),
            rules.minLength(3),
            rules.containsLatinAndDigit
          ],
          counter: 50,
          required: true
        },
        // full_name: {
        //   type: 'text',
        //   key: 'full_name',
        //   label: 'Имя пользователя',
        //   value: null,
        //   rules: [rules.maxLength(128), rules.minLength(3)],
        //   counter: 128,
        //   required: false
        // },
        department: {
          type: 'select',
          key: 'department',
          label: 'Департамент',
          value: undefined,
          rules: [rules.required],
          loader: false,
          required: true
        },
        new_password: {
          type: 'password',
          key: 'new_password',
          label: 'Пароль',
          value: null,
          rules: [
            rules.required,
            rules.maxLength(20),
            rules.minLength(8),
            (value) => (thisItem) =>
              rules.passwordsMatch(this.fields.confirm_password)(value)(
                thisItem
              )
          ],
          counter: 20,
          show: false,
          required: true
        },
        confirm_password: {
          type: 'password',
          key: 'confirm_password',
          label: 'Подтверждение пароля',
          value: null,
          rules: [
            rules.required,
            rules.maxLength(20),
            rules.minLength(8),
            (value) => (thisItem) =>
              rules.passwordsMatch(this.fields.new_password)(value)(thisItem)
          ],
          counter: 20,
          show: false,
          required: true
        }
      }
    }
  },
  methods: {
    getDisplayName,
    openModal() {
      this.$refs.modal.open()
    },

    async getMunicipalitiesList() {
      this.fields.department.loader = true
      try {
        const {
          data: { data: list }
        } = await getBoardingSchoolsDepartmentList()
        this.municipalities = list
      } catch (e) {
        notify.error('Ошибка загрузки департаментов')
      } finally {
        store.commit('setShowPreloader', false)
        this.fields.department.loader = false
      }
    },

    async createUserDepartment() {
      if (this.isValid) {
        Object.entries(this.fields).forEach(
          ([k, v]) => (this.fieldsValue[k] = v.value)
        )

        try {
          const { data } = await createBoardingSchoolsUser({
            data: this.fieldsValue
          })
          if (data.success) {
            this.$refs.formAddingUser.reset()
            this.$refs.modal.close()
            notify.success('Пользователь Создан')
            await this.$emit('update-table')
          }
        } catch (err) {
        } finally {
          store.commit('setShowPreloader', false)
        }
      }
    },
    clearModalForm() {
      Object.keys(this.fields).forEach((k) => (this.fields[k].value = null))
      this.$refs.formAddingUser.resetValidation()
    },

    showPassword(item) {
      item.show = !item.show
    }
  },
  async mounted() {
    await this.getMunicipalitiesList()
  }
}
</script>

<template>
  <Modal ref="modal" :title="title" @close:before="clearModalForm">
    <v-form
      ref="formAddingUser"
      v-model="isValid"
      @submit="createUserDepartment"
    >
      <v-flex v-for="item in fields" :key="item.key">
        <v-text-field
          v-if="item.type !== 'select'"
          v-model="item.value"
          :label="item.label"
          :rules="item.rules.map((rule) => rule(item))"
          :maxlength="item.counter || false"
          :counter="item.counter || false"
          required
          :append-icon="
            item.type === 'password'
              ? item.show
                ? 'visibility'
                : 'visibility_off'
              : null
          "
          :type="item.show ? 'text' : item.type"
          browser-autocomplete="off"
          autocomplete="off"
          box
          clearable
          @click:append="() => showPassword(item)"
        />
        <v-autocomplete
          v-else
          v-model="item.value"
          :items="municipalities"
          :label="item.label"
          :rules="item.rules.map((rule) => rule(item))"
          :loading="item.loader"
          :item-text="getDisplayName"
          item-value="id"
          browser-autocomplete="off"
          autocomplete="off"
          clearable
          box
          required
        />
      </v-flex>
    </v-form>
    <template v-slot:actions>
      <v-btn color="primary" @click="createUserDepartment" :disabled="!isValid">
        Подтвердить
      </v-btn>
    </template>
  </Modal>
</template>

<style scoped></style>
