<template>
  <v-container grid-list-md v-bind="$attrs">
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: 'Container',
  inheritAttrs: false
}
</script>
