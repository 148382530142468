<script>
import { getDepartments, getSchools } from "@/router/routes/superadmin"
import store from "@/store"
import SyncFormComponent from "@/components/moduleadmin/SyncData/components/SyncFormComponent.vue"

export default {
  name: "SyncData",
  components: { SyncFormComponent },
  data () {
    return {

    }
  },
  computed: {
  }
}
</script>

<template>
<v-container fluid>
  <SyncFormComponent />
</v-container>
</template>

<style scoped>

</style>
