<template>
  <v-container fluid>
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <v-flex mb-3>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout
      row
      mb-12
      justify-start
      text-md-center
    >
      <v-flex md4>
        <v-autocomplete
          :value="selectedSchoolID"
          @change="changeValue('selectedSchoolID', $event)"
          :items="schools"
          item-text="name"
          item-value="id"
          label="Школа:"
        />
        <v-autocomplete
          :disabled="!selectedSchoolID"
          :value="selectedClassID"
          @change="changeValue('selectedClassID', $event)"
          :items="classes"
          item-text="name"
          item-value="id"
          label="Класс:"
        />
        <v-select
          :disabled="!selectedClassID"
          no-data-text="Ничего не выбрано"
          v-model="selectedStudentsIds"
          :item-text="(item) => `${item.name} (${item.id})`"
          item-value="id"
          :items="students"
          label="Ученики:"
          deletable-chips
          chips
          multiple
        >
          <template v-slot:prepend-item>
            <v-list-tile
              ripple
              @click="selectAll('selectedStudentsIds', 'students')"
            >
              <v-list-tile-action>
                <v-icon :color="selectedStudentsIds.length > 0 ? 'primary' : ''">{{selectedStudentsIds.length ===  students.length ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Выбрать всё</v-list-tile-title>
                <v-list-tile-sub-title>Выбрано: {{selectedStudentsIds.length}}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
      </v-flex>
      <v-flex md6></v-flex>
    </v-layout>
    <v-layout>
      <v-flex md4>
        <v-form
          ref="form"
        >
          <v-btn
            flat
            class="primary ma-0"
            @click="deleteSelectedUsers"
          >Удалить</v-btn>
        </v-form>
      </v-flex>
    </v-layout>

    <v-flex class="deleteUserByID">
        <v-text-field
            outline
            v-model="selectedUserID"
            type="number" min="0"
            placeholder="Введите ID пользователя"
        ></v-text-field>
    </v-flex>
    <v-layout>
      <v-flex md4>
        <v-form
          ref="form"
        >
          <v-btn
              flat
              class="primary ma-0"
              @click="getInfoByUserByID"
            >Получить информацию</v-btn>
        </v-form>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" width="50%">
      <v-card class="text-center pa-4">
        <h3 class="mb-3">Информация о выбранном пользователе</h3>
        <p><h4>Имя пользователя:</h4> {{ responseUserName }}
        <p><h4>Образовательное учреждение:</h4> {{ responseUserSchool }}
        <p><div align="center">
          <v-btn color="success" class="mr-3" @click="deleteUserByID">Удалить</v-btn>
          <v-btn color="error" @click="() => {this.selectedUserID = ''; this.dialog = false}">Отменить</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import { mapMutations } from 'vuex'
import {
  getSchools,
  getClasses,
  getStudents,
  getUserByID,
  deleteUsers
} from '@/router/routes/superadmin'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'DeleteUsers',
  data() {
    return {
      title: 'Удаление пользователей',
      /* */
      schools: [],
      selectedSchoolID: null,
      classes: [],
      selectedClassID: null,
      students: [],
      /* */
      selectedStudentsIds: [],
      selectedUserID: '',
      responseUserName: '',
      responseUserUUID: '',
      responseUserSchool: '',
      dialog: false
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    getClassLiter(name) {
      return name.split(' ')[1]
    },
    getClassNumber(name) {
      return name.split(' ')[0]
    },
    async init() {
      await this.getSchools()
    },
    selectAll(selectArray, parentArray) {
      this.$nextTick(() => {
        if (this[selectArray].length) {
          this[selectArray] = []
        } else {
          const idsArray = this[parentArray].map(item => item.id)
          this[selectArray] = [ ...idsArray ]
        }
      })
    },
    async changeValue(type, value) {
      this[type] = value
      if (type === 'selectedSchoolID') {
        this.classes = []
        this.selectedClassID = null
        await this.getClasses()
      }
      if (type === 'selectedClassID') {
        this.students = []
        await this.getStudents()
      }
    },
    async getSchools() {
      try {
        const PAYLOAD = {
          limit: this.LIMIT,
          offset: this.offset
        }
        const response = await getSchools(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
        this.schools = [ ...this.schools, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getClasses() {
      try {
        const PAYLOAD = {
          limit: Vue.config.general.classLimit,
          offset: this.offset,
          school_id: this.selectedSchoolID
        }
        const response = await getClasses(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
        this.classes = [ ...this.classes, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getStudents() {
      try {
        const PAYLOAD = {
          schoolId: this.selectedSchoolID,
          classId: this.selectedClassID,
          params: {
            limit: Vue.config.general.limit,
            offset: this.offset
          }
        }
        const response = await getStudents(PAYLOAD)

        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, fullName } = item
          return {
            id: id,
            name: fullName
          }
        })
        this.students = [ ...this.students, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки учеников') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    setValue(valueLabel, value) {
      this[valueLabel] = value
    },
    async getInfoByUserByID() {
      if (this.selectedUserID < 0 || this.selectedUserID === '') {
        notify.error('Введите валидный ID пользователя') //pass
        return
      }
      try {
        const response = await getUserByID(this.selectedUserID)
        this.dialog = true
        this.responseUserName = response.data.full_name + " (" + this.selectedUserID + ")"
        this.responseUserSchool = response.data.school_class
      } catch (error) {
        // notify.error('Ошибка получения информации о пользователе') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async deleteUserByID() {
      this.dialog = false
      if (this.selectedUserID < 0 || this.selectedUserID === '') {
        notify.error('Введите валидный ID пользователя') //pass
        return
      }
      try {
        const PAYLOAD = {
          pupils: [ this.selectedUserID ]
        }
        await deleteUsers(PAYLOAD)
        notify.success('Пользователь успешно удален')

        this.students = this.students.filter(s => !this.selectedUserID.includes(s.id))
        this.selectedUserID = ''
      } catch (error) {
        // notify.error('Ошибка удаления пользователя') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async deleteSelectedUsers() {
      if (!this.selectedStudentsIds[0]) {
        notify.error('Выберите пользователей') //pass
        return
      }
      try {
        const PAYLOAD = {
          pupils: [ ...this.selectedStudentsIds ]
        }
        await deleteUsers(PAYLOAD)
        notify.success('Пользователь успешно удален')
        this.students = this.students.filter(s => !this.selectedStudentsIds.includes(s.id))
        this.selectedStudentsIds = []
      } catch (error) {
        notify.error('Ошибка удаления пользователя') //pass
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>


<style scoped>
.deleteUserByID {
  margin-top: 5%;
  width: 33%
}
</style>
