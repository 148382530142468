export default class ChildrenFactory {
  constructor() {
    return [
      {

        id: 1,
        src: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
        name: 'Алексеева Виктория Сергеевна',
        billNumber: '421-1123-23-35-1110005',
        foodOrganizer: 'ООО Нептун',
        concession: 'Многодетность',
        foodType: 'Двухразовое (многодетные)',
        currentBalance: 658.0,
        reservedBalance: 437.0,
        availableBalance: 221.0,
        creditBalance: 1000.0,
        debt: 0.0,
        debtPeriond: null,
        notifications: {
          isEnabled: true,
          balanceTrigger: 300.0,
          period: 2
        }
      },
      {
        id: 2,
        src: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
        name: 'Алексеева Изольда Дмитриевна',
        billNumber: '421-1123-23-35-1110006',
        foodOrganizer: 'ООО Нептун',
        concession: 'Многодетность',
        foodType: 'Двухразовое (многодетные)',
        currentBalance: 650.0,
        reservedBalance: 430.0,
        availableBalance: 220.0,
        creditBalance: 1700.0,
        debt: 0.0,
        debtPeriond: null,
        notifications: {
          isEnabled: true,
          balanceTrigger: 300.0,
          period: 2
        }
      }
    ]
  }
}
