<script>
import moment from 'moment'
import { getStorehouses } from '@/router/routes/admin'
import { mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import { fetchBuffetMenuSchedule } from '@/router/routes/superadmin'
import balancable from '@/mixins/balancable'

export default {
  name: 'BuffetMenuScheduleView',
  props: {},
  mixins: [balancable],

  data() {
    return {
      title: 'Расписание меню',
      headers: [
        {
          text: 'Id из системы 1С',
          value: 'external_id',
          class: 'px-2',
          align: 'left',
          sortable: false
        },
        {
          text: 'Наименование товара',
          value: 'name',
          class: 'px-2',
          align: 'left',
          sortable: false
        },
        {
          text: 'Категория',
          value: 'category',
          class: 'px-2',
          align: 'left',
          sortable: false
        },
        {
          text: 'Количество товара',
          value: 'count',
          class: 'px-2',
          align: 'left',
          sortable: false
        },
        {
          text: 'Цена за ед. товара',
          value: 'price',
          class: 'px-2',
          align: 'left',
          sortable: false
        },
        {
          text: 'Поставщик',
          value: 'supplier',
          class: 'px-2',
          align: 'left',
          sortable: false
        }
      ],
      menuSchedule: [],

      storehouseList: [],
      pagination: {
        page: 1,
        page_size: 10,
        count: 0,
        total: 0
      },
      filters: {
        storehouse: null,
        date: moment().format(Vue.config.format.serverDate)
      },

      isLoading: false,
      menu_date: false
    }
  },

  computed: {
    ...mapGetters(['setting'])
  },

  methods: {
    ...mapMutations(['setShowPreloader']),

    convertPrice(item) {
      return `${this.convertToRubles(item)} p.`
    },
    async fetchStorehouses() {
      const payload = {
        params: {
          limit: Vue.config.general.schoolLimit,
          offset: 0
        }
      }

      try {
        const {
          data: { results }
        } = await getStorehouses(payload)
        this.storehouseList = results
      } catch (e) {
        console.error(e.message)
      } finally {
        this.setShowPreloader(false)
      }
    },

    async fetchMenuSchedule() {
      const params = {
        ...this.filters,
        page: this.pagination.page,
        page_size: this.pagination.page_size
      }

      if (this.filters.storehouse) {
        this.isLoading = true
        try {
          const {
            data: { data, pagination }
          } = await fetchBuffetMenuSchedule({ params })
          // const { data } = await fetchBuffetMenuSchedule({ params })
          this.menuSchedule = data
          this.set_pagination(pagination)
        } catch (e) {
          console.error(e.message)
        } finally {
          this.setShowPreloader(false)
          this.isLoading = false
        }
      }
    },

    formatDate(value) {
      return value
        ? moment(value).format(Vue.config.format.serverDate)
        : moment().format(Vue.config.format.serverDate)
    },

    set_pagination(data) {
      this.pagination = {
        ...this.pagination,
        ...data
      }
    },

    handlePaginationClick(page) {
      this.set_pagination({ page: page })
      this.fetchMenuSchedule()
    },

    handleSearch() {
      this.set_pagination({ page: 1 })
      this.fetchMenuSchedule()
    }
    //
    // reset_pagination() {
    //   this.pagination = {
    //     page: 1,
    //     page_size: 10,
    //     count: 0,
    //     total: 0
    //   }
    // },
    // reset_filters() {
    //   this.filters = {
    //     storehouse: null,
    //     date: moment().format(Vue.config.format.serverDate)
    //   }
    // },
    //
    // reset_data() {
    //   this.menuSchedule = []
    //   this.reset_pagination()
    //   this.reset_filters()
    // }
  },

  watch: {
    selectStorehouse: {
      deep: true,
      handler: _.debounce(function () {
        this.fetchMenuSchedule({ page: 1 })
      }, 1000)
    },

    filters: {
      deep: true,
      handler: _.debounce(function () {
        this.fetchMenuSchedule({ page: 1 })
      }, 1000)
    }
  },

  async mounted() {
    await this.fetchStorehouses()
  }
}
</script>

<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 mb-1 pa-2>
        <h1>{{ title }}</h1>
      </v-flex>
      <v-flex xs12 pa-2>
        <v-card class="rounded">
          <v-card-title>
            <v-flex xs5 md5>
              <v-select
                label="Склад"
                v-model="filters.storehouse"
                :items="storehouseList"
                item-text="name"
                item-value="uuid"
                hide-details
              ></v-select>
            </v-flex>
            <v-flex xs3 sm3 class="ml-3">
              <v-menu
                ref="menu_date"
                v-model="menu_date"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
                hide-details
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="formatDate(filters.date)"
                    label="Дата"
                    prepend-icon="event"
                    v-on="on"
                    hide-details
                    readonly
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker v-model="filters.date"></v-date-picker>
              </v-menu>
            </v-flex>
          </v-card-title>

          <v-card-text v-if="filters.storehouse">
            <v-layout class="fs-complex-result" mb-3>
              <v-flex>
                <v-data-table
                  :headers="headers"
                  :items.sync="menuSchedule"
                  hide-actions
                  class="elevation-1"
                  disable-initial-sort
                  no-data-text="Меню на выбранную дату отсутствует"
                  item-key="name"
                  :loading="isLoading"
                >
                  <template v-slot:items="props">
                    <tr>
                      <td class="px-2 faq-table-td-text">
                        {{ props.item.external_id }}
                      </td>
                      <td class="px-2 faq-table-td-text text-slice">
                        {{ props.item.name }}
                      </td>

                      <td class="px-2 faq-table-td-text text-slice">
                        {{ props.item.category }}
                      </td>

                      <td class="px-2 faq-table-td-text text-slice">
                        {{ props.item.count }}
                      </td>
                      <td class="px-2 faq-table-td-text text-slice">
                        {{ convertPrice(props.item.price) }}
                      </td>
                      <td class="px-2 faq-table-td-text text-slice">
                        {{ props.item.supplier }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
            <div class="text-xs-center" v-if="pagination.total > 1">
              <v-pagination
                @input="handlePaginationClick"
                :value="pagination.page"
                :length="pagination.total"
                :total-visible="7"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style scoped></style>