<template>
  <v-form ref="questionSyrveyForm" v-model="valid">
    <v-card>
      <v-toolbar elevation="1" dense light>
        <v-toolbar-title>
          {{ is_create ? `Создать новый опрос` : `Редактировать опрос` }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.prevent="closeEditModal">
          <v-icon class="fs-active-icon">close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
      <v-text-field
        v-model="name"
        :rules="[rules.required]"
        :label="!darkTheme ? 'Название анкеты:' : ''"
        :placeholder="darkTheme ? 'Название анкеты:' : ''"
        :outline="darkTheme"
      />

      <v-switch
        v-model="is_published"
        inset
        :label="`Опубликовать`"
      ></v-switch>


    <v-text-field
      v-model="currentUser.school.short_name"
      readonly
      :outline="darkTheme"
    />


      </v-card-text>

      <v-card-actions>
        <v-btn @click.prevent="closeEditModal">
          <v-icon class="fs-active-icon">close</v-icon>
          Отменить
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn @click="saveSyrvey">
          <v-icon class="fs-active-icon">check</v-icon>
          {{ is_create ? `Создать` : `Обновить` }}
        </v-btn>
      </v-card-actions>
    </v-card>


  </v-form>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from "@/store"
export default {
  name: 'CreateOrUpdateSyrvey',
  props: [ 'is_create', 'syrvey_id' ],
  data: () => ({
    darkTheme: Vue.config.env.darkTheme,
    rules: {
      required: (v) => !!v || "Поле обязательное для заполнения"
    },
    valid: true
  }),
  async mounted() {
    if (!this.is_create) {
      this.getSyrveyDetailManage(this.syrvey_id)
    }
    if (this.storehouse_id) {

    }
    this.syrvey_manage_detail.schools = [ this.currentUser.school_id ]
  },
  methods: {
    ...mapActions([ 'getSyrveyDetailManage', 'createSyrvey', 'updateSyrvey' ]),
    ...mapMutations([ 'set_syrvey_manage_detail', 'clearSyrveyDetailManage' ]),
    closeEditModal() {
      this.clearSyrveyDetailManage()
      this.$emit('close')
    },

    saveSyrvey() {
      this.$refs.questionSyrveyForm.validate()
      if (this.valid) {
        if (this.is_create) {
          this.createSyrvey().then(() => {
            this.closeEditModal()
          })
        }
        else {
          this.updateSyrvey().then(() => {
            this.closeEditModal()
          })
        }
      }
    },
  },
  computed: {
    ...mapGetters([ 'syrvey_manage_detail', 'currentUser' ]),
    name: {
      get () {
        return this.syrvey_manage_detail.name
      },
      set (value) {
        this.set_syrvey_manage_detail({
          ...this.syrvey_manage_detail,
          name: value
        })
      }
    },
    is_published: {
      get () {
        return this.syrvey_manage_detail.is_published
      },
      set (value) {
        this.set_syrvey_manage_detail({
          ...this.syrvey_manage_detail,
          is_published: value
        })
      }
    }
  }
}
</script>
