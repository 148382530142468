<template>
  <div id="notify-container">
    <div :id="`messageWrapper-${uuid}`" class="messageWrapper">
    <span :id="`icon-${uuid}`" class="material-icons icon">warning</span>
      <span :id="`message-${uuid}`" class="message">{{ message }}</span>
    <button :id="`close-icon-${uuid}`" class="material-icons close-icon" @click="$emit('close-toast')">close</button>
    </div>
    <div :id="`void-container-${uuid}`" class="void-container"></div>

    <div :id="`uuid-wrapper-${uuid}`" class="uuid-wrapper"> Скопируйте код ошибки для отправки в техническую поддержку
      <br>Код ошибки: <span id="uuid" class="uuid">{{ uuid }}</span>
      <div :id="`copied-${uuid}`" class="copied">Скопировано!</div>
    </div>
    <button :id="`show-more-button-${uuid}`" class="show-more-button" @click.stop="clicked">Подробнее</button>
    <button :id="`copy-button-${uuid}`" class="copy-button" @click.stop="copy">Скопировать код ошибки</button>
    <button :id="`another-button-${uuid}`" class="another-button" @click.stop="copyOneMore">Скопировать еще раз</button>
    <button :id="`close-button-${uuid}`" class="close-button" @click="$emit('close-toast')">Закрыть</button>
  </div>
</template>

<script>
export default {
  props: ['message', 'uuid'],
  methods: {
    clicked() {
      const showMoreButton = document.getElementById('show-more-button-'+this.uuid)
      showMoreButton.style.opacity = '0'

      const uuidWrapper = document.getElementById('uuid-wrapper-'+this.uuid)
      if (window.innerWidth > 447) {uuidWrapper.style.bottom = '33px'}
      else {uuidWrapper.style.bottom = '15px'}
      uuidWrapper.style.opacity = '1'

      const copyBtn = document.getElementById('copy-button-'+this.uuid)
      if (window.innerWidth > 447) {copyBtn.style.bottom = '0px'}
      else {copyBtn.style.bottom = '-20px'}
      copyBtn.style.opacity = '1'
      copyBtn.style.pointerEvents = 'auto'

      const voidContainer = document.getElementById('void-container-'+this.uuid)
      voidContainer.style.height = '80px'
    },
    copy() {
      const copyBtn = document.getElementById('copy-button-'+this.uuid)
      copyBtn.style.bottom = '-100px'
      copyBtn.style.opacity = '0'

      const anotherBtn = document.getElementById('another-button-'+this.uuid)
      anotherBtn.style.bottom = '0px'
      anotherBtn.style.opacity = '1'
      anotherBtn.style.pointerEvents = 'auto'

      const closeBtn = document.getElementById('close-button-'+this.uuid)
      closeBtn.style.bottom = '0px'
      closeBtn.style.opacity = '1'
      closeBtn.style.pointerEvents = 'auto'

      const uuidWrapper = document.getElementById('uuid-wrapper-'+this.uuid)
      uuidWrapper.style.color = '#ff5252'

      const copied = document.getElementById('copied-'+this.uuid)
      if (window.innerWidth > 447) {copied.style.bottom = '33%'}
      else {copied.style.top = '23%'}
      copied.style.opacity = 1
      copied.style.color = '#ffffff'

      navigator.clipboard.writeText(this.uuid)

      // Возможность отдавать чуть более подробную информацию при копировании
      // navigator.clipboard.writeText(
      //     `Сообщение: ${this.message}\n`
      //     +`Код ошибки: ${this.uuid}\n`
      //     +`URL: ${window.location.pathname}`
      // )
    },
    copyOneMore() {

      navigator.clipboard.writeText(this.uuid)

      // Возможность отдавать чуть более подробную информацию при копировании
      // navigator.clipboard.writeText(
      //           `Сообщение: ${this.message}\n`
      //           +`Код ошибки: ${this.uuid}\n`
      //           +`URL: ${window.location.pathname}`)

      const copied = document.getElementById('copied-'+this.uuid)
      copied.style.opacity = 0
      setTimeout(() => {copied.style.opacity = 1}, 300)
    }
  },
};
</script>
<style>
.messageWrapper {
  padding: 15px 39px 30px 15px;
  line-height: 19px;
  min-width: 450px;
  max-width: 500px;
  transition: .2s;
}
.void-container {
  height: 0px;
  transition: .2s;
}
.icon {
  float: left;
  margin: -2px 15px 0 0;
}
.close-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 20px;
  opacity: .4;
}
.uuid-wrapper {
  position: absolute;
  bottom: -58px;
  background: #ff5252;
  width: 100%;
  padding: 10px 15px 10px 15px;
  line-height: 19px;
  opacity: 0;
  transition: .2s;
  border-top: 1px solid #ff2727;
}
/*BUTTONS*/
.show-more-button {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 33%;
  height: 26px;
  font-size: 12px;
  text-align: center;
  background: #ff2828;
  padding: 0;
  border-radius: 8px 0px 8px 0px;
  transition: .5s;
}
.show-more-button:hover {
  background: #d22828;
    transition: .2s;
}
.copy-button {
  position: absolute;
  bottom: -93px;
  width: 100%;
  background: #ff2828;
  padding: 10px 0 12px 0;
  border-radius: 0 0 8px 8px;
  height: 35px;
  opacity: 0;
  transition: .2s;
  z-index:10;
  pointer-events: none;
}
.copy-button:hover {
  background: #d22828;
    transition: .1s;
}
.another-button {
  position: absolute;
  bottom: -96px;
  left: 0%;
  border-right: 1px solid #ff5d5d;
  width: 50%;
  background: #ff2828;
  opacity: 0;
  padding: 10px 0 12px 0;
  border-radius: 0 0 0 8px;
  transition: .2s;
  z-index: 5;
  pointer-events: none;
}
.another-button:hover {
  background: #d22828;
}
.close-button {
  position: absolute;
  bottom: -96px;
  left: 50%;
  border-left: 1px solid #ff2727;
  width: 50%;
  opacity: 0;
  background: #ff2828;
  border-radius: 0 0 8px 0;
  padding: 10px 0 12px 0;
  transition: .2s;
  z-index: 5;
  pointer-events: none;
}
.close-button:hover {
  background: #d22828;
  transition: .1s;
}
.copied {
  position: absolute;
  left: 39%;
  top: 33%;
  opacity: 0;
  transition: .2s;
  transition-delay: .1s;
}
</style>