<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <v-layout
      class="fs-teacher-order"
      row
      mb-3
    >
      <v-flex
        md4
        mb-3
      >
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
      class="fs-header-filter"
    >

    </v-layout>
    <v-layout
      class="fs-teacher-order-result"
      mb-3
      justify-space-around
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1 fs-teacher-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.name  }} {{props.item.formId? `(Форма ${props.item.formId})` : null}}</td>
              <td>
                <v-flex class="pa-2">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="showReportModal(props.item)"
                      >print</v-icon>
                    </template>
                    <span>Печать</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <ConfirmModal
      :dialog.sync="showModal"
      :startPrint.sync="showPrintLoad"
      :dateStart.sync="dateStart"
      :dateEnd.sync="dateEnd"
      :width="700"
      @confirm="handlerModal"
    >
      <template v-slot:headline>Печать отчёта:  "{{reportPrintTitle}}"</template>
      <template v-slot:body>

        <v-autocomplete
          v-model="classID"
          :items="classesArray"
          :item-text="'name'"
          :item-value="'id'"
          flat
          dense
          :outline="darkTheme"
          :label="!darkTheme ? 'Класс:' : ''"
          placeholder="Класс"
        />
        <v-flex
          md4
          class="mt-3"
        >
          <div v-if="datePickers === 1">
            <DatePickersSmart
              :startDate.sync="dateStart"
              :onePicker="true"
              @changeDate="changeDate"
            />
          </div>
          <div v-else-if="datePickers === 2">
            <DatePickersSmart
              :startDate.sync="dateStart"
              :endDate.sync="dateEnd"
              @changeDate="changeDate"
            />
          </div>
        </v-flex>
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import moment from 'moment'
import {
  getAllReports,
  reportFileTaskId,
  reportFormFileTaskId,
  reportFileStatus,
  reportPrintFile
} from '@/router/routes/teacher'
import { mapGetters, mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
// import _ from 'lodash'
import { ConfirmModal, DatePickersSmart } from '@/components/common'
import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownloadWithProgressBar'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ReportsIndex',
  components: {
    ConfirmModal,
    DatePickersSmart
  },
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      title: 'Отчёты',
      datepickerMenu: false,
      dateStart: '',
      dateEnd: '',
      headers: [
        { text: 'Отчёт', value: 'name', width: '90%' },
        { text: 'Операции', value: 'operations' }
      ],
      items: [],
      showModal: false,
      showPrintLoad: false,

      reportPrintTitle: '',
      datePickers: null,
      datePickerRange: '',
      currentItem: null,
      classesArray: [],
      classID: null,
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  created() {
    this.init()
    this.classesArray = this.currentUser.school_class_list
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async init() {
      await this.loadReports()
    },
    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
    },
    setDatepickersValue(count, range) {
      const defaultRange = 'month'
      if (count === 2) {
        this.dateStart = moment()
          .add(defaultRange, -1)
          .format(Vue.config.format.serverDate)
        this.dateEnd = moment()
          .add('days', 1)
          .format(Vue.config.format.serverDate)
      }
      if (count === 1) {
        this.dateStart = moment().format(Vue.config.format.serverDate)
        this.dateEnd = ''
      }
      if (!count) {
        this.dateStart = ''
        this.dateEnd = ''
      }
    },
    showReportModal(item) {
      this.currentItem = item
      this.showModal = true
      this.datePickers = item.datePickers
      const countDatepickers = item.datePickers
      const rangeType = item.rangeType
      this.setDatepickersValue(countDatepickers, rangeType)
      this.reportPrintTitle =
        item.name + (item.form_id ? ` (Форма ${item.form_id})` : '')
    },
    async handlerModal(value) {
      this.modalResponse = value
      if (this.modalResponse) {
        this.showPrintLoad = true
        await this.printFile()
      }
    },
    async printFile() {
      const { id = null, formId = null } = this.currentItem
      const getTaskId = formId ? reportFormFileTaskId : reportFileTaskId
      const PAYLOAD = {
        id,
        data: {
          start_date: this.dateStart,
          end_date: this.dateEnd,
          class_id: this.classID
        },
        report_id: id,
        form_id: formId
      }
      const callback = this.resetPageFileLoaded
      try {
        await asyncReqestsForDownload(
          getTaskId,
          reportFileStatus,
          reportPrintFile,
          PAYLOAD,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки файла') //pass
      } finally {
        this.resetPageFileLoaded()
      }
    },
    changeDate(payload) {},
    async loadReports() {
      try {
        const response = await getAllReports()
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const {
            id = null,
            name = '',
            formId = '',
            rangeType = '',
            rangeAmmount = null,
            datePickers = null,
            classChoice = null,
            schoolChoice = null
          } = item
          return {
            id,
            name,
            formId,
            datePickers,
            rangeType,
            rangeAmmount,
            classChoice,
            schoolChoice
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки отчётов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>
