<template>
  <v-card style="padding: 20px">
    <v-tabs
      v-model="step"
      show-arrows
      bg-color="deep-purple-darken-4"
      center-active
      class="mt-2"
    >
      <v-tab :disabled="tab1">Выберите департамент</v-tab>
      <v-tab :disabled="tab2">Укажите типы питания</v-tab>
      <v-tabs-items>
        <v-tab-item>
          <v-autocomplete
            no-data-text="Ничего не выбрано"
            label="Департаменты:"
            class="mt-3"
            clearable
            :items="departments"
            item-text="name"
            item-value="id"
            v-model="selectedDepartment"
          ></v-autocomplete>
          <v-select
            no-data-text="Ничего не выбрано"
            v-model="selectedSchool"
            item-text="short_name"
            item-value="id"
            :items="schools"
            label="Школы:"
          >
          </v-select>
          <v-select
            no-data-text="Ничего не выбрано"
            v-model="selectedClasses"
            item-text="name"
            item-value="id"
            :items="classes"
            label="Классы:"
            deletable-chips
            chips
            multiple
          >
          </v-select>
        </v-tab-item>
        <v-tab-item>
          <div
            class="mt-2"
            style="align-items: center; display: flex" >
            <v-autocomplete
              label="Старый тип питания"
              class="mr-3"
              clearable
              solo
              :items="mealTypes"
              :item-text="item => `${item.name} - (${item.privilege})`"
              item-value="id"
              v-model="oldMealType"
            ></v-autocomplete>
            <v-icon style="text-align: center" class="mb-4">sync</v-icon>
            <v-autocomplete
              label="Новый тип питания"
              class="ml-3"
              clearable
              solo
              :items="mealTypes"
              :item-text="item => `${item.name} - (${item.privilege})`"
              item-value="id"
              v-model="newMealType"
            ></v-autocomplete>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <div class="d-flex mt-3">
    <v-btn
      v-show="step !== 0"
      color="error"
      style="max-width: 50px"
      class="font-weight-bold"
      @click="back"
    >Назад</v-btn>
    <v-btn
      color="#1976D2"
      style="max-width: 60px"
      class="white--text font-weight-bold"
      @click="forward"
    >{{ forwardOrDone }}</v-btn>
       <v-btn
      color="#1976D2"
      style="max-width: 60px"
      class="white--text font-weight-bold ml-auto"
      @click="closeModal"
    >Закрыть</v-btn>
    <slot name="actions">
    </slot>
  </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
import Vue from "vue"
import { getSchoolId } from "@/helpers/utils"
import {
  getConcessions
} from "@/router/routes/admin"
import store from "@/store"
import balancable from "@/mixins/balancable"
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'
import {
  getDepartments,
  getSchoolsByDepartment,
  getClasses,
  getDepartmentMealTypesList, getMealTypesList, massiveChangeMealType
} from "@/router/routes/superadmin"

export default {
  name: "SuperModalChangeMealType",
  components: { ContextHelpModal },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      title: 'Смена типа питания',

      step: 0,
      showModal: true,

      tab1: false,
      tab2: true,

      oldMealType: undefined,
      newMealType: undefined,
      selectedPrivilege: undefined,

      departments: [],
      selectedDepartment: undefined,

      schools: [],
      selectedSchool: undefined,

      classes: [],
      selectedClasses: [],

      privileges: [],
      mealTypes: []
    }
  },
  created() {
    this.getDepartments()
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
    forwardOrDone() {
      return this.step === 2 ? 'Изменить' : 'Далее'
    }
  },
  watch: {
    async selectedDepartment(val) {
      if (!val) {
        this.schools = []
        this.selectedSchool = undefined
      }
      await this.getSchools()
    },
    async selectedSchool(val) {
      if (val) {
        await this.getClasses()
      } else {
        this.classes = []
        this.selectedClasses = []
      }
    }
  },
  methods: {
    back() {
      if (this.step === 0) return
      this.step -= 1
      this.updateTabsState()
    },
    forward() {
      if (this.step === 0) {
        if (!this.selectedDepartment) {
          notify.error('Выберите департамент')
          return
        }
        this.step += 1
        this.updateTabsState()
        this.getMealTypes()
      } else {
        if (this.oldMealType === undefined || this.newMealType === undefined) {
          notify.error('Укажите типы питания') //pass
          return
        } else if (this.oldMealType === this.newMealType) {
          notify.error('Новый тип питания не может быть таким же, как старый') //pass
          return
        }
        this.changeMealType()
      }
    },
    updateTabsState() {
        if (this.step === 0) {
        this.tab1 = false
        this.tab2 = true
      } else if (this.step === 1) {
        this.tab1 = true
        this.tab2 = false
      }
    },
    reset() {
      this.tab1 = false
      this.tab2 = true
      this.step = 0
      this.selectedDepartment = undefined
      this.selectedSchool = undefined
      this.oldMealType = undefined
      this.newMealType = undefined
      this.selectedClasses = []
    },
    async changeMealType() {
      const payload = {
        old_meal_type: this.oldMealType,
        new_meal_type: this.newMealType
      }
      if (this.selectedClasses.length > 0) {
        payload.school_class_ids = this.selectedClasses
      } else if (this.selectedSchool) {
        payload.school_id = this.selectedSchool
      } else {
        payload.department_id = this.selectedDepartment
      }

      try {
        await massiveChangeMealType(payload)
        notify.success('Тип питания успешно изменен')
        this.reset()
      } catch (e) {
        // notify.error('Ошибка изменения типа питания') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    closeModal() {
      this.reset()
      this.$emit('close')
    },
    async getDepartments() {
      store.commit('setShowPreloader', true)
      try {
        const {
          data: { data: items }
        } = await getDepartments()
        this.departments = items
      } catch (error) {
        console.log(error)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getSchools() {
      try {
        const response = await getSchoolsByDepartment(this.selectedDepartment)
        this.schools = response.data.data
      } catch (e) {
        console.log(e)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getClasses() {
      const payload = {
        school_id: this.selectedSchool
      }
      try {
        const response = await getClasses(payload)
        this.classes = response.data.data.map(i => {
          return {
            id: i.id,
            name: i.name,
          }
        })
      } catch (e) {
        console.log(e)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getPrivileges() {
      const payload = {
        id: getSchoolId(this.currentUser)
      }
      await getConcessions(payload)
        .then(response => {
          this.privileges = _.map(response.data.data, item => {
            return {
              id: item.id,
              name: `${item.name} (${balancable.methods.convertToRubles(item.price)} руб.)`,
            }
          })
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    async getMealTypes() {
      let payload = {
        department_id: this.selectedDepartment
      }
      let method = getDepartmentMealTypesList
      if (this.selectedSchool) {
        payload = this.selectedSchool
        method = getMealTypesList
      }
      try {
        const response = await method(payload)
        this.mealTypes = response.data
      } catch (e) {
        console.log(e)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    selectAll(selectArray, parentArray) {
      this.$nextTick(() => {
        if (this[selectArray].length) {
          this[selectArray] = []
        } else {
          const idsArray = this[parentArray].map(item => item.id)
          this[selectArray] = [ ...idsArray ]
        }
      })
    },
  }
}
</script>
