export default class AdminTeacherFactory {
  constructor() {
    return {
      id: null,
      firstName: '',
      middleName: '',
      lastName: '',
      fullName: '',
      schoolClasses: []
    }
  }
}
