<template>
  <v-container class="fs-organizer-container" fluid>
    <v-layout class="fs-dialy-menu" row mb-3>
      <v-flex md6 mb-3>
        <h1>{{ title }}</h1>
      </v-flex>
    </v-layout>
    <v-layout class="fs-dialy-menu" row mb-3 wrap>
      <v-flex class="mr-3" md3>
        <v-select
          v-if="!isViewComponent"
          v-model="selectedSchools"
          :items="schools"
          item-text="name"
          item-value="id"
          label="Школа"
          attach
          multiple
        />
      </v-flex>
      <v-flex md3>
        <v-select
          v-if="!isViewComponent"
          v-model="selectedAdditionals"
          :items="additionalsMenuItems"
          item-text="name"
          item-value="id"
          label="Доп. блюда"
          attach
          multiple
        />
      </v-flex>
    </v-layout>
    <v-data-table
      :headers="headers"
      :items="foodTypes"
      class="elevation-1"
      no-data-text="Нет"
      hide-actions
      item-key="name"
    >
      <template v-slot:items="props">
        <tr>
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.name }}</td>
          <td>
            <v-autocomplete
              v-model="assignedDefaultComplects[props.item.id]"
              :items="matchComplects(props.item.id)"
              :value="returnDefaultComplectsValue(props.item.id)"
              :disabled="isViewComponent"
              label="Выберите комплекс по умолчанию"
              clearable
              item-text="name"
              item-value="id"
              @change="checkEmptyComplects()"
            />
          </td>
          <td>
            <v-autocomplete
              :items="matchComplects(props.item.id)"
              :value="getAssignedComplexes(props.item.id)"
              :disabled="isViewComponent"
              label="Выберите комплекс"
              clearable
              multiple
              item-text="name"
              item-value="id"
              @change="onChangeSelect(props.item.id, $event)"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-spacer></v-spacer>
    <v-layout mt-3>
      <v-flex text-md-right>
        <v-btn :to="{ name: 'MenuTemplates', query: { dateStart: this.$route.query.dateStart, dateEnd: this.$route.query.dateEnd } }" class="fs-btn" flat>Назад</v-btn>
        <v-btn
          v-if="!isViewComponent"
          :disabled="isEmptyComplexes"
          :loading="btnSaveLoading"
          class="fs-btn primary"
          color="white"
          flat
          dark
          @click="setDayMenu()"
        >Сохранить</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import store from '@/store'
import moment from 'moment'
import Vue from 'vue'
import {
  getComplexes,
  getAdditionalMenu,
  getSchools,
  getDayMenu,
  setDayMenu
} from '@/router/routes/organizer'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'MenuTemplatesDayEdit',
  data() {
    return {
      btnSaveLoading: false,
      dialog: false,
      assignedComplexesByMealType: {},
      assignedComplects: {},
      assignedDefaultComplects: {},
      isEmptyComplexes: true,
      complexes: [],
      item: {},
      itemComplects: [],
      itemDefaultComplects: [],
      complects_mapping: [],
      date: '',
      dayName: '',
      serverDate: '',
      headers: [
        { text: '', sortable: false },
        { text: 'Тип питания', sortable: false },
        { text: 'Комплекс по умолчанию', sortable: false },
        { text: 'Комплексы', sortable: false }
      ],
      complects: null,
      additionalsMenuItems: [],
      schools: [],
      selectedSchools: [],
      selectedAdditionals: []
    }
  },
  computed: {
    ...mapGetters([ 'foodTypes' ]),
    title() {
      return `Просмотр комплексов меню на день: ${this.dayName} ${this.date}`
    },
    isViewComponent() {
      return this.$route.meta.flag === 'view'
    },
    getAssignedComplects() {
      const complexes = []
      for (const mealTypeId of Object.keys(this.assignedComplexesByMealType)) {
        for (const complectId of this.assignedComplexesByMealType[mealTypeId]) {
          complexes.push({
            mealTypeId,
            complectId
          })
        }
      }
      return complexes
    },
    menuId() {
      return this.$route.params.id
    }
  },
  created() {
    this.init()
  },
  methods: {

    onChangeSelect(id, payload) {
      this.assignedComplects[id] = payload
      this.assignedComplexesByMealType[id] = payload
    },
    isDCEmpty() {
      let dcIsEmpty = false
      Object.keys(this.assignedDefaultComplects).forEach(x => {
        if (this.assignedDefaultComplects[x] === undefined) {
          dcIsEmpty = true
        }
      })
      return dcIsEmpty
    },
    isCEmpty() {
      let cIsEmpty = false
      Object.keys(this.assignedComplects).forEach(x => {
        if (this.assignedComplects[x].length === 0) {
          cIsEmpty = true
        }
      })
      return cIsEmpty
    },

    setAssignedComplexesValues(foodTypeId) {
      // transform complexes from response to my type
      const assignedComplexes = _.map(this.itemComplects, item => {
        return {
          id: item.id,
          foodTypesIds: _.map(item.meal_type, item => item.id)
        }
      })
      _.forEach(assignedComplexes, item => {
        if (_.includes(item.foodTypesIds, foodTypeId)) {
          this.assignedComplects[foodTypeId].push(item.id)
          this.assignedComplexesByMealType[foodTypeId].push(item.id)
        }
      })

      for (const mapping of this.complects_mapping) {
        if (mapping.meal_type_id === foodTypeId) {
          this.assignedComplexesByMealType[foodTypeId] = []
        }
      }

      for (const mapping of this.complects_mapping) {
        if (mapping.meal_type_id === foodTypeId) {
          this.assignedComplexesByMealType[foodTypeId].push(mapping.complect_id)
        }
      }

      // fix for unique
      _.forEach(this.assignedComplexes, (item, key) => {
        this.assignedComplects[key] = _.uniq(item)
      })
    },

    async init() {
      _.forEach(this.foodTypes, item => {
        this.assignedComplects[item.id] = []
        this.assignedComplexesByMealType[item.id] = []
        this.assignedDefaultComplects[item.id] = []
      })
      await this.getAllComplexes()
      await this.getDayMenu()
      _.forEach(this.foodTypes, item => {
        this.setAssignedComplexesValues(item.id)
      })
      if (!this.isViewComponent) {
        await this.getSchools()
        await this.getAdditionalMenu()
      }
      this.checkEmptyComplects()
    },
    setSelectedAdditionalsIds(additionals) {
      return additionals.reduce((result, item) => {
        result.push(item.id)
        return result
      }, [])
    },
    async getAllComplexes() {
      try {
        const response = await getComplexes()
        const {
          data: { data: complexes }
        } = response

        this.complects = _.map(complexes, item => {
          return {
            id: item.id,
            name: item.name,
            foodTypes: item.meal_type
          }
        })
      } catch (error) {
        // notify.error(`Ошибка загрузки комплексов`) // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getSchools() {
      const PAYLOAD = {
        params: {
          limit: Vue.config.general.schoolLimit,
          offset: 0
        },
        department_id: this.getDepartmentId
      }
      try {
        const response = await getSchools(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
        this.schools = [ ...this.schools, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getAdditionalMenu() {
      try {
        const response = await getAdditionalMenu()
        const {
          data: { data: additionals }
        } = response
        this.additionalsMenuItems = _.map(additionals, item => {
          return {
            id: item.id,
            name: item.name,
            energy: item.energy_value,
            protein: item.protein,
            fats: item.fat,
            carbs: item.carbohydrates,
            additionally: item.is_additional,
            price: item.price
          }
        })
      } catch (error) {
        // notify.error('Ошибка загрузки доп. меню') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getDayMenu() {
      try {
        const PAYLOAD = {
          id: this.menuId
        }
        const response = await getDayMenu(PAYLOAD)
        const {
          data: {
            data: {
              additional_food = null,
              school = null,
              complects = [],
              default_complects = [],
              complects_mapping = [],
              date: dateDayMeny
            }
          }
        } = response
        this.date = this.convertDateDayMenu(dateDayMeny, Vue.config.format.date)
        this.serverDate = this.convertDateDayMenu(
          dateDayMeny,
          Vue.config.format.pythonDate
        )
        this.dayName = this.convertDateDayMenu(dateDayMeny, 'dddd', true)
        this.itemComplects = [ ...this.itemComplects, ...complects ]
        this.itemDefaultComplects = [
          ...this.itemDefaultComplects,
          ...default_complects
        ]
        this.complects_mapping = complects_mapping
        this.selectedAdditionals = [
          ...this.setSelectedAdditionalsIds(additional_food)
        ]
        if (school) {
          this.selectedSchools = [ school.id ]
        }
      } catch (error) {
        // notify.error('Ошибка загрузки меню на день') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async setDayMenu() {
      if (!this.selectedSchools.length) {
        notify.error('Укажите школу!') //pass
        return
      }
      if (this.isDCEmpty()) {
        notify.error('Заполнены не все комплексы по умолчанию') //pass
        return
      }
      if (this.isCEmpty()) {
        notify.error('Заполнены не все комплексы') //pass
        return
      }
      try {
        this.btnSaveLoading = true
        const PAYLOAD = {
          data: {
            date: this.serverDate,
            complects: this.getAssignedComplects,
            default_complects: this.assignedDefaultComplects,
            additional_food: this.selectedAdditionals,
            school_ids: this.selectedSchools
          }
        }

        await setDayMenu(PAYLOAD)
        store.commit('setShowPreloader', false)
        notify.success('Новое меню успешно сохранено!')

      } catch (e) {

        // notify.error('Ошибка сохранения меню') // backend handler added (exception_message)

      } finally {
        store.commit('setShowPreloader', false)
        this.btnSaveLoading = false
      }
    },
    convertDateDayMenu(date, format, name) {
      if (!name) {
        return moment(date).format(format)
      }
      return moment(date)
        .locale('ru')
        .format(format)
    },
    matchComplects(foodTypeId) {
      let matchComplects = null
      matchComplects = _.filter(this.complects, item => {
        const foodTypesIds = _.map(item.foodTypes, type => type.id)
        if (_.includes(foodTypesIds, foodTypeId)) return item
      })
      return matchComplects
    },
    returnDefaultComplectsValue(foodTypeId) {
      // transform complexes from response to my type
      const defaultComplexes = _.map(this.itemDefaultComplects, item => {
        return {
          id: item.complect,
          foodTypeId: item.meal_type
        }
      })
      // match complexes with response by typeId
      _.forEach(defaultComplexes, item => {
        this.assignedDefaultComplects[item.foodTypeId] = item.id
      })

      this.checkEmptyComplects()
      return this.assignedDefaultComplects[foodTypeId]
    },
    getAssignedComplexes(id) {
      if (typeof this.assignedComplexesByMealType[id] !== 'undefined') {
        return this.assignedComplexesByMealType[id]
      }
      return this.assignedComplects[id] || []
    },
    checkEmptyComplects() {
      const everyItem = _.map(this.assignedComplects, item => {
        return _.isEmpty(item)
      })
      this.isEmptyComplexes = _.some(everyItem, item => item == true)
      return this.isEmptyComplexes
    }
  }
}
</script>
