import Vue from 'vue'
import contextHelp from '@/helpers/contextHelp'

Vue.config.contextHelp = contextHelp

Vue.config.env = (() => {
  const variables = {}

  for (const key in process.env) {
    const _key = key
      .toLowerCase()
      .replace(/^vue_app_/, '')
      .replace(/_.{1}/g, v => v[1].toUpperCase())

    if ([ '1', 'true', 'on' ].includes(process.env[key])) {
      variables[_key] = true
    } else if ([ '0', 'false', 'off' ].includes(process.env[key])) {
      variables[_key] = false
    } else if (process.env[key].match(/^\d+$/)) {
      variables[_key] = +process.env[key]
    } else {
      variables[_key] = process.env[key]
    }
  }

  variables['isRyazan'] = variables['schoolService'] === 'ryazan'
  variables['isHmao'] = variables['schoolService'] === 'hmao'

  return variables
})()

Vue.config.server = (() => {
  if (
    process.env.VUE_APP_DEBUG === 'true' &&
    process.env.VUE_APP_BACK_HOST &&
    process.env.VUE_APP_BACK_PORT
  ) {
    return `${window.location.protocol}//${process.env.VUE_APP_BACK_HOST}:${process.env.VUE_APP_BACK_PORT}`
  } else {
    return `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
  }
})()

Vue.config.publicPath = '/static/'

const urlGetter = {
  get: function(t, p) {
    if (typeof t[p] === 'object' && t[p] !== null) {
      return new Proxy(t[p], urlGetter)
    }
    else {
      return `${Vue.config.server}/${t[p]}`
    }
  }
}

;(Vue.config.general = {
  smallLimit: 10,
  limit: 40,
  // TODO: увеличиваем лимит классов, чтоб подгружалось 200,
  // костыли, надо переделывать списки, делать там подгрузку по скроллу
  classLimit: 200,
  schoolLimit: 200,
  notifyLimit: 20,
  orderStatuses: {
    draft: 1,
    teacherConfirm: 2,
    schoolConfirm: 3,
    recieved: 4,
    complete: 5,
    cancel: 6,
    teacherSaved: 7,
    schoolSaved: 8,
    confirmAuto: 9,
    updated: 10,
  },
  orderStatusesNames: {
    1: 'Черновик',
    2: 'Подтвержден учителем',
    3: 'Подтвержден школой',
    4: 'Выдан',
    5: 'Выполнен',
    6: 'Отменен',
    7: 'Сохранен учителем',
    8: 'Сохранен школой',
    9: 'Подтвержден автоматически',
    10: 'Изменен',
  },
  static: {
    breakfast: 1,
    lunch: 6,
    dinner: 2,
    afternoonSnack: 3,
    eveningMeal: 4,
    lateDinner: 5,
  },
  meals: {
    breakfast: { id: 1, name: "Завтрак", key: 'BREAKFAST' },
    lunch: { id: 6, name: "Второй завтрак", key: 'LUNCH' },
    dinner: { id: 2, name: "Обед", key: 'DINNER' },
    afternoonSnack: { id: 3, name: "Полдник", key: 'AFTERNOON_SNACK' },
    eveningMeal: { id: 4, name: "Ужин", key: 'EVENING_MEAL' },
    lateDinner: { id: 5, name: "Поздний ужин", key: 'LATE_DINNER' },
  },
  supplierSubRoles: [
    { id: 8, code: 'SUB_SUPPLIER_INFO', name: 'Карточка организатора питания' },
    { id: 16, code: 'SUB_SUPPLIER_MEALS', name: 'Блюда' },
    { id: 17, code: 'SUB_SUPPLIER_COMPLECTS', name: 'Комплексы' },
    { id: 18, code: 'SUB_SUPPLIER_COMPLECTS_ARCHIVE', name: 'Архив комплексов' },
    { id: 15, code: 'SUB_SUPPLIER_ORDERS', name: 'Заявки на питание' },
    { id: 9, code: 'SUB_SUPPLIER_BALANCE_VIEW', name: 'Просмотр баланса' },
    { id: 10, code: 'SUB_SUPPLIER_BALANCE_EDIT', name: 'Изменение баланса' },
    { id: 11, code: 'SUB_SUPPLIER_RECEIPTS', name: 'Платежи по квитанциям' },
    { id: 19, code: 'SUB_SUPPLIER_MENU_TEMPLATES', name: 'Шаблоны меню' },
    { id: 20, code: 'SUB_SUPPLIER_DAILY_MENU', name: 'Ежедневное меню' },
    { id: 12, code: 'SUB_SUPPLIER_OFERTA', name: 'Оферта' },
    { id: 13, code: 'SUB_SUPPLIER_OFERTA_STATUS', name: 'Оферта статус' },
    { id: 21, code: 'SUB_SUPPLIER_REPORTS', name: 'Отчеты' },
    { id: 14, code: 'SUB_SUPPLIER_NOTIFICATIONS', name: 'Уведомления' },
    { id: 23, code: 'SUB_SUPPLIER_EXPELLED_REGISTER', name: 'Реестр отчисленных детей' },
    { id: 24, code: 'SUB_SUPPLIER_CASHBACK_INDEX', name: 'Заявление на возврат' }
  ],
}),
(Vue.config.api = {
  login: 'api/auth/school/',
  authRoles: 'api/auth/roles/',
  verify: 'api/auth/verify/',
  credentials: 'api/auth/credentials/',
  refresh: 'api/auth/refresh/',
  main: new Proxy({
    userProfile: 'api/user/profile/',
    logout: 'api/auth/logout/'
  }, urlGetter),
  alerts: {
    all: 'api/update_alerts/',
    one: 'api/update_alerts/$id/',
  },
  information: 'api/user-help/',
  userService: 'api/user-service/',
  contextHelp: 'api/context-help/?key=$key',
  setting: 'api/setting/',
  common: {
    recreatePupilOrders: 'api/recreate-orders/',
    orderErrors: {
      all: 'api/common/orders/errors/'

    },
    manual: {
      download: 'api/user-help/manual/download/',
      status: 'api/user-help/manual/'
    },
    notifications: {
      all: 'api/notifications/',
      one: 'api/notifications/$id/',
      count: 'api/notifications/count/'
    },
    qualityAssesments: {
      all: 'api/quality_assessments/',
      one: 'api/quality_assessments/$id/',
      suppliers: 'api/quality_assessments/suppliers/',
      controls: {
        menu: {
          all: 'api/quality_assessments/controls/menus/'
        },
        complect: {
          all: 'api/quality_assessments/controls/complects/',
          one: 'api/quality_assessments/controls/complects/$id/',
        },
        all: 'api/quality_assessments/controls/',
        one: 'api/quality_assessments/controls/$id/',
        images: {
          all: 'api/quality_assessments/controls/images/',
          one: 'api/quality_assessments/controls/images/$id/',
        }
      }
    },
    foodPeriods: 'api/common/meal-periods/',
    buffetTransactions: {
      all: 'api/buffet/transactions/'
    },
    foodTypesCrutch: 'api/supplier/supplier-meal-type/',
    foodTimes: 'api/common/meal-times/',
    ageCategories: 'api/common/age-categories/',
    foodCategories: 'api/common/food-categories/',
    shifts: 'api/common/shifts/',
    concessions: {
      all: 'api/departments/$id/concessions/',
      one: 'api/departments/$departmentId/concessions/$id/'
    },
    foodTypes: {
      all: 'api/department/1/meal-types/',
      one: 'api/department/1/meal-types/$id/'
    },
    cards: {
      index: 'api/cards/',
      all: 'api/cards_list/',
      one: 'api/cards/$card_id/'
    },
    syrvey: {
      all: 'api/questionnaires/',
      detail: 'api/questionnaires/$syrvey_id/',
      result_save: 'api/polls/',
      question: {
        create: 'api/questions/',
        delete: 'api/questions/$question_id/',
        update: 'api/questions/$question_id/',
        detail: 'api/questions/$question_id/',
      },
      results: {
        all: 'api/polls/',
        one: 'api/polls/$id/'
      },
      variants: {
        all: 'api/variants/'
      }
    },
  },
  child: {
    students: {
      all: 'api/child/students/',
      one: 'api/child/student/'
    },
    dishes: {
      all: 'api/child/food/'
    },
    settings: 'api/child/settings/',
    balance: {
      history: 'api/child/balance/history/'
    },
    orders: {
      all: 'api/child/orders/'
    },
  },
  parent: {
    updates: 'api/parent/updates/',
    refund: {
      get: 'api/parent/cashback/$id/'
    },
    privacy: {
      get: 'api/parent/privacy'
    },
    invoice: {
      create: 'api/parent/invoice/create/'
    },
    organizer: 'api/parent/students/$id/supplier/',
    dishes: {
      all: 'api/parent/students/$id/food/',
      additional: 'api/parent/students/$pupil_pk/additional-menu/$order_pk/',
      additionalDishes: 'api/parent/students/$pupil_pk/additional_foods/',
      setAdditionalDishes: 'api/parent/students/$pupil_pk/additional_foods/$order_pk/'
    },
    order: {
      link: 'api/parent/payment/create/',
      check: 'api/parent/payment/check/',
      complete: 'api/payment/$order_id/complete/'
    },
    students: {
      all: 'api/parent/students/',
      one: 'api/parent/students/$id/',
      cashback: 'api/parent/students/$id/cashback/',
      cashback_prefill: 'api/parent/students/$id/cashback/prefill/',
      cashback_doc: 'api/parent/students/$id/cashback/doc/$doc_id/',
      notifications: 'api/parent/students/$id/notifications/',
      orders: {
        all: 'api/parent/students/orders/'
      },
      buffet: {
        getBalance: 'api/parent/students/$id/buffet-balance-get/',
        transferMoney: 'api/parent/students/$id/transfer-balance/',
      },
      family_transfer_balance: 'api/parent/students/family-transfer-balance/',
    },
    quality_assessments: {
      all: 'api/quality_assessments/',
      one: 'api/quality_assessments/$id/',
      image: 'api/quality_assessments/images/',
      orders: {
        all: 'api/quality_assessments/students/orders/'
      }
    },
    buffet: {
      transactions: {
        all: 'api/buffet/transactions/'
      },
      products: {
        all: 'api/buffet/products/'
      }
    },
    balance: {
      history: 'api/parent/students/$id/balance/',
      buffetHistory: 'api/parent/students/$id/buffet-balance/',
      movingTaskId: 'api/parent/students/$studentId/balance/moving/',
      movingStatus: 'api/parent/students/$studentId/balance/moving/$task_id/',
      movingDownload:
          'api/parent/students/$studentId/balance/moving/$task_id/download/'
    },
    orders: {
      all: 'api/parent/students/$id/orders/',
      one: 'api/parent/students/$studentId/orders/$id/'
    },
    traffic: 'api/parent/students/$id/traffic/',
    settings: {
      save: 'api/parent/students/$studentId/settings/'
    },
    agreement: {
      sign: 'api/parent/students/$id/sign/',
      oferta: 'api/parent/students/$id/oferta/'
    }
  },

  exceptionInfo: 'api/exceptions/',
  exceptionsCount: 'api/exceptions/count',

  administrator: {
    schoolOferta: 'api/school/administrator/oferta/$schoolID/',
    changeMealType: 'api/school/administrator/change-meal-type/$schoolID/',
    workingDays: 'api/school/administrator/working-days/$schoolID/',
    table: {
      check: (schoolId, classId, date) =>
        `api/school/administrator/traffic/${schoolId}/${classId}/${date}/`
    },
    contacts: 'api/school/administrator/contacts/',
    dashboard: {
      order: 'api/dashboard_order/$school_pk/',
      coverage: 'api/dashboard_coverage/$school_pk/',
      orderStatus: 'api/dashboard_status/$school_pk/',
      pupilNegativeBalance: 'api/dashboard_pupil_negative_balance/$school_pk/',
      pupilExpiredPrivilege: 'api/dashboard_pupil_expired_privilege/$school_pk/',
      pupilWithoutFoodtypes: 'api/dashboard_pupil_without_foodtypes/$school_pk/',
      pupilAllPrivilegesAndFoodtypes: 'api/dashboard_all_privileges_and_foodtypes/$school_pk/',
      ordersYesterdayUncompleted: 'api/dashboard_orders_yesterday_uncompleted/$school_pk/',
      ordersOverCredit: 'api/dashboard_orders_over_credit/$school_pk/',
      mealPeriodCount: 'api/dashboard_meal_period/$school_pk/',
      allClasses: 'api/dashboard_modal_all_classes/$school_pk/',
    },
    loading_orders: {
      sample: 'api/school/administrator/loading-orders/$school_pk/sample/',
      sample_example: 'api/school/administrator/loading-orders/$school_pk/sample_example/',
      uploaded_file: 'api/school/administrator/loading-orders/$school_pk/uploaded_file/',
      list: 'api/school/administrator/loading-orders/$school_pk/list/',
      detail: 'api/school/administrator/loading-orders/$school_pk/detail/$pk/',
      delete: 'api/school/administrator/loading-orders/$school_pk/delete/$pk/',
      upload: 'api/school/administrator/loading-orders/$school_pk/upload/',
      uploadStatus: 'api/school/administrator/loading-orders/upload/status/$id/',
      uploadResult: 'api/school/administrator/loading-orders/upload/result/$id/',
    },
    foodblock: {
      passport: {
        all: 'api/buffet/foodblocks/passports/$school_pk/'
      },
      types: {
        all: 'api/buffet/foodblocks/types/',
        one: 'api/buffet/foodblocks/types/$form_id/'
      },
      engineer: {
        all: 'api/buffet/foodblocks/supports/',
        one: 'api/buffet/foodblocks/supports/$form_id/'
      },
      transport: {
        all: 'api/buffet/foodblocks/transports/',
        one: 'api/buffet/foodblocks/transports/$form_id/'
      },
      rooms: {
        all: 'api/buffet/foodblocks/rooms/',
        one: 'api/buffet/foodblocks/rooms/$form_id/'
      },
      warehouses: {
        all: 'api/buffet/foodblocks/warehouses/',
        one: 'api/buffet/foodblocks/warehouses/$form_id/'
      },
      household: {
        all: 'api/buffet/foodblocks/households/',
        one: 'api/buffet/foodblocks/households/$form_id/'
      },
      staff: {
        all: 'api/buffet/foodblocks/staff/',
        one: 'api/buffet/foodblocks/staff/$form_id/'
      },
      schedule: {
        all: 'api/buffet/foodblocks/schedules/',
        one: 'api/buffet/foodblocks/schedules/$form_id/'
      },
      documents: {
        all: 'api/buffet/foodblocks/documents/',
        one: 'api/buffet/foodblocks/documents/$form_id/'
      },
      organizations: {
        all: 'api/buffet/foodblocks/organizations/',
        one: 'api/buffet/foodblocks/organizations/$form_id/'
      },
      general: {
        all: 'api/buffet/foodblocks/general/',
        one: 'api/buffet/foodblocks/general/$form_id/'
      }
    },
    traffic: {
      task: (schoolId, date) =>
        `api/school/administrator/traffic/${schoolId}/${date}/`,
      taskStatus: 'api/school/administrator/traffic/task/status/$id/',
      taskResult: 'api/school/administrator/traffic/task/result/$id/',
    },
    invoice: {
      create: 'api/school/administrator/$schoolId/invoice/create/',
      period: 'api/school/administrator/$schoolId/invoices/range/'
    },
    invoices: {
      all: 'api/school/administrator/$schoolId/invoices/',
      status: 'api/school/administrator/$schoolId/invoices/$id/',
      download: 'api/school/administrator/$schoolId/invoices/$id/download/'
    },
    application_preliminary_actual: {
      all: 'api/school/administrator/$schoolId/orders/download/',
      status: 'api/school/administrator/$schoolId/orders/download/$id/',
      download: 'api/school/administrator/$schoolId/orders/download/$id/download/'
    },
    all: 'api/schools/administrators/',
    balance: {
      start: 'api/school/administrator/payment/add/',
      get: 'api/school/administrator/$schoolId/payments/',
      buffet: 'api/school/administrator/$schoolId/buffet-payments/',
      printMoving: 'api/school/administrator/$schoolId/balance/moving/',
      printMovingProgress:
          'api/school/administrator/$schoolId/balance/moving/$id/',
      printMovingFile:
          'api/school/administrator/$schoolId/balance/moving/$id/download/'
    },
    students_privileges: {
      one: 'api/parent/students/$student_id/extprivilege/'
    },
    classes: {
      all: 'api/school/administrator/$schoolId/classes/',
      one: 'api/school/administrator/$schoolId/classes/$id/',
      changeClassExtDatePrivilege: 'api/school/administrator/class/change/ext_date/',
      removeClassPrivilege: 'api/school/administrator/remove/class/privilege/',
    },
    complexes: {
      all: 'api/school/administrator/$schoolId/complects/',
      one: 'api/school/administrator/$schoolId/complects/$id/'
    },
    dishes: {
      all: 'api/school/administrator/$schoolId/foods/',
      one: 'api/school/administrator/$schoolId/foods/$id/'
    },
    schools: {
      all: 'api/school/administrator/schools/'
    },
    updates: 'api/school/administrator/updates/',
    orders: {
      all: (schoolId, date) =>
        `api/school/administrator/order-table/${schoolId}/${date}/`,
      pupilCardOrders: 'api/school/pupil-card-orders/',
      byHouse: (schoolId, houseId, date) =>
        `api/school/administrator/order-table/school-house/${schoolId}/${houseId}/${date}/`,
      one: (classId, date) =>
        `api/school/administrator/order-table/school-class/${classId}/${date}/`,
      confirm: (classId, date) =>
        `api/school/administrator/order-table/school-class/${classId}/${date}/confirm/`,
      dotation: 'api/school/administrator/orders/set-default-meal-type/$schoolId/$classId/$date/',
      download: 'api/school/administrator/$schoolId/orders/download/',
      invoices: 'api/school/administrator/$schoolId/invoices/',
      invoicesDownload: 'api/school/teacher/invoices/$taskID/downloads/',
      transferTrafficClass: (schoolId, classId) =>
        `api/school/administrator/order-table/${schoolId}/${classId}/transfer-traffic/`, // TODO уточнение
      transferTrafficSchool: (schoolId, date) =>
        `api/school/administrator/order-table/${schoolId}/transfer-traffic/${date}/`,
      cancelStatus: (schoolId, classId, date) =>
        `api/school/administrator/orders/cancel-received-status/${schoolId}/${classId}/${date}/`,
      cancelOrderStatusBySchool: (schoolId, date) =>
        `api/school/administrator/orders/cancel-received-status/${schoolId}/${date}/`,
      changeOrdersStatus: 'api/school/administrator/orders/change-status/',
      cancelOrdersByFilters: 'api/school/administrator/orders/cancel/',
      getChangedOrders: (schoolId, date) =>
        `api/school/administrator/orders/set-received-status/${schoolId}/${date}/`,
      setConfirmedStatus: (schoolId, date) =>
        `api/school/administrator/orders/set-confirmed-status/${schoolId}/${date}/`,
      chargeMoney: (schoolId, date) =>
        `api/school/administrator/orders/charge_money/${schoolId}/${date}/`,
      changeMealType: 'api/school/administrator/orders/change-meal-type/$order_id/$meal_type_id/',
      cancel:  (schoolId, date) =>
        `api/school/administrator/orders/${schoolId}/cancel/${date}/`,
      setDryFood: (schoolId, date) =>
        `api/school/administrator/orders/${schoolId}/dry-food/${date}/`,
      setSubsidy: (schoolId, date) =>
        `api/school/administrator/orders/${schoolId}/subsidy/${date}/`,
      returnToTeacher: (schoolId, classId, date)=>
        `api/school/administrator/orders/return-to-teacher/${schoolId}/${classId}/${date}/`,
      deleteAdditionalFood: 'api/school/administrator/orders/delete-additional-food/$order_id/',
      recreateDrafts: (schoolId, date) =>
        `api/school/administrator/recreate-drafts/${schoolId}/${date}/`,
      printMenu: (schoolId, date) =>
        `api/school/administrator/${schoolId}/${date}/menu/download/`,
    },
    concessions: {
      all: 'api/school/administrator/$schoolId/priveleges/',
      one: 'api/school/administrators/concessions/$id/',
      mealTypesByPrivilege: 'api/school/administrator/privilege-meal-types/$schoolID/$privilegeID/'
    },
    students: {
      all: 'api/school/administrator/$schoolId/students/',
      filtered: 'api/school/administrator/$schoolId/students/?classId=$classId',
      one: 'api/school/administrator/$schoolId/students/$id/',
      privilege: 'api/school/administrator/$schoolId/students/$id/update-privilege/',
      privileges: 'api/school/administrator/$schoolId/school-privilegies/',
      order_meal_types: 'api/school/administrator/$schoolId/students/$id/order_meal_types/'
    },
    settings: {
      all: 'api/school/administrator/$school_id/settings/'
    },
    documents: {
      all: 'api/attachments/',
      one: 'api/attachments/$id/',
    },
    syrvey: {
      all: 'api/questionnaires/',
      detail: 'api/questionnaires/$syrvey_id/',
      result_save: 'api/polls/',
      question: {
        create: 'api/questions/',
        delete: 'api/questions/$question_id/',
        update: 'api/questions/$question_id/',
        detail: 'api/questions/$question_id/',
      },
      results: {
        all: 'api/polls/',
        one: 'api/polls/$id/'
      },
      variants: {
        all: 'api/variants/'
      }
    },
    buffet: {
      storehouses: {
        all: 'api/buffet/storehouses/',
        one: 'api/buffet/storehouses/$storehouse_id/'
      },
      move_product: {
        all: 'api/buffet/suppliers/products/move/'
      },
      storehouse_products: {
        all: 'api/buffet/products/',
        one: 'api/buffet/products/$product_id/'
      },
      blacklist: {
        create: 'api/buffet/blacklists/',
        update: 'api/buffet/blacklists/$blacklist_id/',
        pupil: 'api/buffet/blacklists/$pupil_pk/',
        detail: 'api/buffet/blacklists/$blacklist_id/'
      },
      applications_position: {
        all: 'api/buffet/applications/positions/',
        one: 'api/buffet/applications/positions/$id/',
      },
      applications: {
        all: 'api/buffet/applications/',
        one: 'api/buffet/applications/$id/',
      },
      productDict: {
        one: 'api/buffet/suppliers/products/$product_id/',
        all: 'api/buffet/suppliers/products/'
      },
      balance_histories: {
        for_pupil: "api/school/administrator/$school_id/students/$pupil_id/buffet-balance-histories/",
      }
    },
    users: {
      all: 'api/users/',
      one: 'api/users/$user_id/',
    },
    users_role: {
      all: 'api/role/$user_id/'
    },
    roles: {
      all: 'api/roles/'
    },
    organizers: {
      all: 'api/school/administrator/$schoolId/supplier/',
      one: 'api/school/administrator/$schoolId/supplier/$id/',
    },
    teachers: {
      all: 'api/school/administrator/$schoolId/teachers/',
      one: 'api/school/administrator/$schoolId/teachers/$id/'
    },
    foodTypes: {
      all: 'api/school/administrator/$schoolId/meal-types/',
      one: 'api/school/administrator/$schoolId/meal-types/$id/'
    },
    agreement: {
      one: 'api/school/administrator/$schoolId/students/$id/',
      all: 'api/school/administrator/$schoolId/students/'
    },
    reports: {
      one: 'api/school/administrator/$school_id/reports/report-$id/',
      all: 'api/school/administrator/$school_id/reports/',
      status: 'api/school/administrator/$school_id/reports/report-$id/$task_id/',
      download: 'api/school/administrator/$school_id/reports/report-$id/$task_id/download/',
      form: 'api/school/administrator/$school_id/reports/report-$id/forms/$form_id/'
    },
    additional: {
      one: 'api/school/administrator/$school_id/reports/additional-food/',
      status: 'api/school/administrator/$school_id/reports/additional-food/$task_id/',
      download: 'api/school/administrator/$school_id/reports/additional-food/$task_id/download/'
    },
    notifications: {
      all: 'api/school/administrator/notifications/',
      everyone: 'api/school/administrator/notifications/everyone/',
      update: 'api/school/administrator/notifications/$id/'
    },
  },
  teacher: {
    updates: 'api/school/teacher/updates/',
    invoice: {
      create: 'api/school/teacher/invoice/create/'
    },
    invoices: {
      all: 'api/school/teacher/invoices/',
      status: 'api/school/teacher/invoices/$task_id/',
      download: 'api/school/teacher/invoices/$task_id/download/'
    },
    classes: {
      all: 'api/school/teachers/classes/',
      one: 'api/school/teachers/classes/$id/'
    },
    students: {
      all: 'api/school/teacher/students/',
      one: 'api/school/teacher/$schoolId/students/$id/',
      privilege: 'api/school/teacher/$schoolId/students/$id/update-privilege/',
      privileges: 'api/school/teacher/$schoolId/school-privilegies/',
      changeClassExtDatePrivilege: 'api/school/teacher/class/change/ext_date/',
      removeClassPrivilege: 'api/school/teacher/remove/class/privilege/',
      order_meal_types: 'api/school/teacher/$schoolId/students/$id/order_meal_types/'
    },
    orders: {
      all: 'api/school/teacher/order-table/$classId/',
      one: 'api/school/teacher/order-table/$classId/$year/$month/$day/',
      confirm: 'api/school/teacher/order-table/$classId/$year/$month/$day/confirm/',
      download: 'api/school/teacher/orders/download/',
      changeMealType: 'api/school/teacher/orders/change-meal-type/$order_id/$meal_type_id/',
      deleteAdditionalFood: 'api/school/teacher/orders/delete-additional-food/$order_id/',
      transferTrafficClass: 'api/school/teacher/orders/$schoolClassId/transfer-traffic/'
    },
    agreement: {
      one: 'api/school/teacher/students/$id/',
      all: 'api/school/teacher/students/'
    },
    reports: {
      all: 'api/school/teacher/reports/',
      one: 'api/school/teacher/reports/report-$id/',
      status: 'api/school/teacher/reports/report-$id/$task_id/',
      download: 'api/school/teacher/reports/report-$id/$task_id/download/',
      form: 'api/school/teacher/reports/report-$id/forms/$form_id/'
    },
    additional: {
      one: 'api/school/teacher/reports/additional-food/',
      status: 'api/school/teacher/reports/additional-food/$task_id/',
      download: 'api/school/teacher/reports/additional-food/$task_id/download/'
    },
    notifications: {
      all: 'api/school/teacher/notifications/',
      everyone: 'api/school/teacher/notifications/everyone/',
      update: 'api/school/teacher/notifications/$id/'
    },
    foodTypes: {
      all: 'api/school/teacher/meal_types/',
    },
    balance: {
      get: 'api/school/teacher/payments/',
    },
  },
  organizer: {
    updates: 'api/supplier/updates/',
    esia: {
      status: 'api/esia/status/',
      preConnect: 'api/esia/pre-connect/',
      success: 'api/esia/success/',
      failed: 'api/esia/failed/'
    },
    menuTemplateByDays: {
      all: 'api/v1/supplier/menu-templates/',
      byDay: 'api/v1/supplier/menu-templates/by-day/',
    },
    defaultComplex: {
      all: 'api/v1/supplier/default-complects/'
    },
    balance: {
      start: 'api/supplier/payment/add/',
      transactions: 'api/v1/supplier/balance/$pupil_id/transactions/'
    },
    buffet: {
      productDict: {
        one: 'api/buffet/suppliers/products/$product_id/',
        all: 'api/buffet/suppliers/products/'
      },
      category: {
        all: 'api/buffet/suppliers/products/categories/',
        one: 'api/buffet/suppliers/products/categories/$id/'
      }
    },
    budget: {
      all: 'api/supplier/statement/',
      one: 'api/supplier/statement/$task_id/',
      download: 'api/supplier/statement/$task_id/download/'
    },
    students: {
      balanceHistory: 'api/supplier/students/balance/',
      balanceHistoryBySupplier: 'api/supplier/students/balance/bysupplier/',
      filtered: 'api/supplier/schools/$schoolId/students/?classId=$classId',
      one: 'api/supplier/schools/$schoolId/students/$id/',
      balance: 'api/supplier/students/$id/balance/',
      receipts: 'api/supplier/students/$id/receipts/',
      receiptsAdd: 'api/supplier/students/receipts/'
    },
    expelled: {
      getRegister: 'api/supplier/expelled-pupil-cashback/'
    },
    cashback: {
      index: 'api/supplier/cashback/requests/',
      one: 'api/supplier/cashback/requests/$id/',
      status: 'api/supplier/cashback/requests/status/$id/$status/',
      create: 'api/supplier/cashback/requests/',
      upload: 'api/supplier/cashback/requests/upload'
    },
    info: 'api/supplier/info/',
    dialyMenu: {
      all: 'api/supplier/menu_template/',
      one: 'api/supplier/menu_template/$id/',
      activate: 'api/supplier/menu_template/complete/',
      additionalMenu: 'api/supplier/additional-menu/',
      clear: 'api/supplier/menu_template/$id/',
      clearFuture: 'api/supplier/day-menu/delete_future/'
    },
    complexes: {
      item: (id) => `api/supplier/complects/${id}/`, //
      all: 'api/supplier/complects/',
      one: 'api/supplier/complects/$id/',
      destroy: 'api/supplier/complects/delete/',
      stash: 'api/supplier/complects/stashed/',
      reserve: 'api/supplier/complects/reserve/'
    },
    stashedComplexes: {
      all: 'api/supplier/complects/?stashed=true/',
      one: 'api/supplier/complects/$id/?stashed=true/',
      restore: 'api/supplier/complects/restored/'
    },
    dishes: {
      all: 'api/supplier/food/',
      search: 'api/supplier/food/search/',
      archive: 'api/supplier/food/archive/',
      destroy: 'api/supplier/food/delete/',
      one: 'api/supplier/food/$id/',
      oneArchive: 'api/supplier/food/$id/archive/',
      uploadFile: 'api/supplier/xls_upload/'
    },
    dishWeights: {
      all: 'api/suppliers/foods/$dishId/food-info/',
      one: 'api/suppliers/foods/$dishId/food-info/$id/'
    },
    orders: {
      all: 'api/supplier/orders/',
      school: 'api/supplier/orders/$schoolId/$date/',
      schoolArchive: 'api/supplier/archive/orders/$schoolId/$date/',
      details: 'api/supplier/orders/$schoolId/$classId/$date/',
      detailsArchive: 'api/supplier/archive/orders/$schoolId/$classId/$date/',
      print: 'api/supplier/menu/download/',
      report: 'api/supplier/orders/report/',
      reportStatus: 'api/supplier/orders/report/$task_id/',
      reportDownload: 'api/supplier/orders/report/$task_id/download/',
      downloadArchive: 'api/supplier/archive/orders/download/',
      logs: 'api/supplier/orders/logs/',
      hasReserve: 'api/supplier/has-reserve/',
      classDetails: 'api/supplier/orders/detail/$schoolId/$classId/$date/'
    },
    additionalDishes: {
      index: 'api/supplier/additional_foods/',
      dishes_create: 'api/supplier/additional_foods/add/',
      deleteFoodSet: 'api/supplier/additional_foods/$foodSetID/delete/'
    },
    schools: {
      all: 'api/supplier/schools/',
      archive: 'api/supplier/archive/schools/$year/$month/$day/',
      one: 'api/supplier/schools/$id/',
      classes: 'api/supplier/schools/$school_id/classes/'
    },
    invoices: {
      all: 'api/supplier/payments/',
      upload: 'api/supplier/payment_upload/',
      uploadCasher: 'api/supplier/payment_upload_casher/',
      status: 'api/supplier/payments/$id/',
      download: 'api/supplier/payments/$id/download/',
      print: 'api/supplier/payments/history/',
      printProgress: 'api/supplier/payments/history/$task_id/',
      printDownload: 'api/supplier/payments/history/$task_id/download/'
    },
    foodTypes: 'api/supplier/supplier-meal-type/',
    agreement: {
      all: 'api/supplier/agreement/',
      one: 'api/supplier/agreement/$id/',
      latest: 'api/supplier/agreement/latest/',
      students: 'api/supplier/agreement/students/'
    },
    privacy: {
      all: 'api/supplier/privacy/'
    },
    reports: {
      all: 'api/supplier/reports/',
      one: 'api/supplier/reports/report-$id/',
      schoolOne: 'api/supplier/reports/report-$id/schools/$school_id/',
      status: 'api/supplier/reports/report-$id/$task_id/',
      download: 'api/supplier/reports/report-$id/$task_id/download/',
      form: 'api/supplier/reports/report-$id/forms/$form_id/',
      report2: 'api/supplier/reports/report-2/forms/2/',
      archiveBalance: 'api/supplier/archive/reports/report-2/',
      balance: 'api/supplier/reports/report-balance/',
      balanceStatus: 'api/supplier/reports/report-balance/$task_id/',
      balanceDownload: 'api/supplier/reports/report-balance/$task_id/download/'
    },
    dayMenu: {
      all: 'api/supplier/day-menu/',
      one: 'api/supplier/day-menu/$id/'
    },
    notifications: {
      all: 'api/supplier/notifications/',
      everyone: 'api/supplier/notifications/everyone/',
      update: 'api/supplier/notifications/$id/'
    },
    notificationsAdmin: {
      all: 'api/admin/notifications/',
      everyone: 'api/admin/notifications/everyone/',
      update: 'api/admin/notifications/$id/'
    },
    users: {
      all: 'api/supplier/users/',
      one: 'api/supplier/users/$userId/',
    },
    events: 'api/supplier/events/',
    banking: 'api/supplier/online-banking/',
    foodCopy: 'api/supplier/food-copy/',
    formerSchools: {
      schools: 'api/supplier/former_schools/',
      payments: 'api/supplier/former_schools/payments/',
    }
  },
  department: {
    updates: 'api/department/updates/',
    contacts: 'api/department/contacts/',
    admin: {
      reports: {
        all: 'api/department/admin/reports/',
        one: 'api/department/admin/reports/report-$id/',
        balance: 'api/department/reports/report-balance/',
        balanceStatus: 'api/department/reports/report-balance/$task_id/',
        balanceDownload: 'api/department/reports/report-balance/$task_id/download/',
        status: 'api/department/admin/reports/report-$id/$task_id/',
        download: 'api/department/admin/reports/report-$id/$task_id/download/',
      },
      departments: 'api/department/admin/departments/'
    },
    concessions: {
      all: 'api/department/$department_id/privileges/',
      update: 'api/v1/department/privileges/$id/'
    },
    reports: {
      all: 'api/department/reports/',
      one: 'api/department/reports/report-$id/',
      schoolOne: 'api/department/reports/report-$id/schools/$school_id/',
      status: 'api/department/reports/report-$id/$task_id/',
      download: 'api/department/reports/report-$id/$task_id/download/',
      form: 'api/department/reports/report-$id/forms/$form_id/',
      formSchoolOne: 'api/department/reports/report-$id/forms/$form_id/schools/$school_id/',
    },
    mealTypes: {
      all: 'api/department/$department_id/meal-types/',
      one: 'api/department/$department_id/meal-types/$id/',
      create: 'api/department/$department_id/meal-types/',
      archive: 'api/department/$department_id/meal-types/$id/archive/',
      archiveList: 'api/department/$department_id/meal-types/archive/',
      scheduler: 'api/department/$department_id/meal-types/schedule/'
    },
    priveleges: {
      all: 'api/department/$department_id/privileges/',
      one: 'api/department/$department_id/privileges/$id/',
      meal_types: 'api/department/$department_id/privileges/$privilege_id/meal_types/',
      scheduler: 'api/department/$department_id/privileges/schedule/'
    },
    another: {
      privileges: 'api/department/another/privileges/'
    },
    schools: {
      all: 'api/department/$department_id/schools/',
      appointMealOrganizationForSchools: 'api/department/appoint-meal-organization-for-schools/'
    },
    school_house: {
      base: 'api/department/school_house/'
    },
    classes: {
      all: 'api/department/schools/$school_id/classes/'
    },
    students: {
      filtered: 'api/department/schools/$schoolId/students/?classId=$classId',
      one: 'api/department/schools/$schoolId/students/$id/'
    },
    suppliers: 'api/v1/department/suppliers/',
    suppliers_disable: 'api/v1/department/suppliers/disable/',
    suppliers_unlock: 'api/v1/department/suppliers/unlock/',
    // TODO: end
    organizers: {
      all: 'api/department/$department_id/suppliers/',
      byDepartment: 'api/department/suppliers/',
      one: 'api/department/$department_id/suppliers/$id/',
      schools: 'api/department/$department_id/suppliers/$id/schools/',
      schoolsAssign: 'api/department/$department_id/schools/assign/',
      foodTypesAssign: 'api/department/$department_id/meal-types/$id/assign/',
      status: 'api/department/suppliers/blocking/$supplier_id/'
    },
    notifications: {
      all: 'api/department/notifications/',
      everyone: 'api/department/notifications/everyone/',
      update: 'api/department/notifications/$id/'
    },
    orders: {
      all: (schoolId, date) =>
        `api/department/order-table/${schoolId}/${date}/`,
      byHouse: (schoolId, houseId, date) => `api/department/order-table/school-house/${schoolId}/${houseId}/${date}/`,
      download: (schoolId) =>
        `api/department/${schoolId}/orders/download/`,
      one:  (classId, date) =>
        `api/department/order-table/school-class/${classId}/${date}/`,
      menu:  (schoolId, date) =>
        `api/department/${schoolId}/${date}/menu/download/`,
    },
    application_preliminary_actual: {
      all: 'api/department/$schoolId/orders/download/',
      status: 'api/department/$schoolId/orders/download/$id/',
      download: 'api/department/$schoolId/orders/download/$id/download/'
    },
    additional: {
      one: 'api/department/$school_id/reports/additional-food/',
      status:
        'api/department/$school_id/reports/additional-food/$task_id/',
      download:
        'api/department/$school_id/reports/additional-food/$task_id/download/'
    },
    balance: {
      get: 'api/department/$schoolId/payments/',
      printMoving: 'api/department/$schoolId/balance/moving/',
      printMovingProgress:
        'api/department/$schoolId/balance/moving/$id/',
      printMovingFile:
        'api/department/$schoolId/balance/moving/$id/download/',
      pupilBalanceBySchool:
        `api/department/pupil-reset-balance/`
    },
  },
  superadmin: {
    debug: {
      error: 'api/admin/debug/error'
    },
    buffet: {
      users: 'api/buffet/users/',
      menuSchedule:'api/1c/buffet_menu/'
    },
    sync: {
      new: 'api/sync/'
    },
    updates: {
      all: 'api/admin/updates/',
      new: 'api/admin/updates/',
      latest: 'api/admin/updates/latest/',
      edit: 'api/admin/updates/edit/',
      delete: 'api/admin/updates/delete'
    },
    pupil: 'api/admin/orders/pupil?id=:id',
    userHelp: {
      all: 'api/admin/user-help/',
      new: 'api/admin/user-help/create/',
      edit: 'api/admin/user-help/:id/',
    },
    users: {
      getUserByID: 'api/admin/getuserbyid/$userId/',
      delete: 'api/admin/deleteusers/'
    },
    log: {
      all: 'api/admin/reversion/history/',
      departmentLogs: 'api/admin/department-logs/',
      adminTeacherPupilCardLogs: 'api/admin/pupil-card-logs/',
    },
    notifications: {
      all: 'api/admin/notifications/',
      everyone: 'api/admin/notifications/everyone/',
    },
    departments: {
      all: 'api/admin/organizers/departments/',
      one: 'api/admin/organizers/department/assign/',
      list: 'api/admin/departments_list',
      mealTypes: 'api/admin/department/meal-types-list/'
    },
    municipalities: 'api/admin/municipalities/',
    municipalUsers: 'api/admin/municipal-users/',
    settings: 'api/admin/settings/',
    orders: {
      all: 'api/admin/orders/',
      logs: 'api/admin/orders/logs/',
      defaultLog: 'api/admin/orders/logs/default/',
      issueClass: 'api/admin/orders/issue/$classId/$year/$month/$day/',
      changeMealType: 'api/admin/orders/change-meal-type/$orderId/$mealTypeId/',
      issueOne: 'api/admin/orders/$orderId/issue/',
      cancelOne: 'api/admin/orders/$orderId/cancel/',
      cancelMultiple: 'api/admin/orders/cancel/',
      setDryFood: 'api/admin/orders/$schoolId/dry-food/$year/$month/$day/',
      setSubsidy: 'api/admin/orders/$schoolId/subsidy/$year/$month/$day/',
      customSearch: 'api/admin/orders/find/',
      customEdit: 'api/admin/orders/edit/',
      customEditMulti: 'api/admin/orders/edit/multi/',
      customEditMealTypesMulti: 'api/admin/orders/edit/meal_types/',
      restore: 'api/admin/orders/restore/',
      createOrdersForPastDay: 'api/admin/orders/create-orders-for-past-day/',
      getOrdersLogForPastDay: 'api/admin/orders/get-application-logs/'
    },
    payments: {
      upload: 'api/admin/payments/$supplier_id/',
      list: 'api/admin/payments/',
    },
    supplierList: 'api/v1/admin/suppliers/',
    organizers: {
      all: 'api/admin/organizers/',
      one: 'api/admin/organizers/$id/',
      set_password: 'api/admin/organizers/$id/set_password/',
      disable: 'api/admin/organizers/disable/',
      enable: 'api/admin/organizers/unlock/',
      checkSber: 'api/admin/organizers/sber/check/',
      schools:{
        all:'api/admin/organizers/schools/',
        byDepartment: 'api/admin/schools_by_department/$department_id/',
        byId: 'api/admin/organizers/schools/byId/',
        classes:'api/admin/organizers/schools/$school_id/classes/',
        admins:'api/admin/organizers/schools/$school_id/admins/',
        teacher:'api/admin/organizers/schools/$school_id/$class_id/teacher/',
        parents:'api/admin/organizers/schools/$school_id/$class_id/parents/',
      },
      suppliers: 'api/admin/organizers/auth/users/',
      department_users: 'api/admin/organizers/auth/department/users/',
      super_department_users: 'api/admin/organizers/auth/department/super/users/',
      auth: {
        admin: 'api/admin/organizers/auth/food_admin/$school_id/$user_id/',
        teacher: 'api/admin/organizers/auth/teacher/$school_id/$class_id/$user_id/',
        parent: 'api/admin/organizers/auth/parent/$user_id/$pupil_id/',
        pupil: 'api/admin/organizers/auth/pupil/$pupil_id/',
        department: 'api/admin/organizers/auth/department/$department_id/',
        super_department: 'api/admin/organizers/auth/department/super/$userId/',
        supplier: 'api/admin/organizers/auth/supplier/$supplier_id/',
      },
      mealTypes: 'api/admin/organizers/$supplierId/meal-types/'
    },
    schools: {
      all: 'api/admin/schools/',
      one: 'api/admin/schools/$school_id/',
      students: 'api/admin/schools/$school_id/students/$classId/',
      students_school: 'api/admin/schools/students/school_id/',
      meal_types_list: 'api/admin/schools/meal_types_list_school?school_id=:school_id'
    },
    suppliers: {
      detail: 'api/admin/suppliers/$id/detail/'
    },
    reports: {
      all: 'api/admin/all_reports/',
      task_id: 'api/create_report/',
      status: 'api/create_report/$task_id/',
      download: 'api/create_report/$task_id/download/'
    },
    pupils: {
      restoreMealType: 'api/admin/restore-meal-type/',
    },
    boardingSchools: {
      departments: 'api/admin/boarding-schools/departments/',
      users: 'api/admin/boarding-schools/users/'
    },
    changeMealType: 'api/admin/change-meal-type/'
  },

  school: {
    parent_list: (school_id) => `api/school/parent_list/${school_id}/`, // $school_id, params: { last_name, school_class_id }
    pupil_list: (school_id) => `api/school/pupil_list/${school_id}/`, // $school_id, params: { last_name, school_class_id }
    school_house:  `api/school/school_house/`, //  params: school_id, department_id
  },

  supportChat: {
    ticketList: '/api/ticket/',
    ticket: '/api/support-chat/' // Patch ${chat_id}, data: { answer: text }
  }
})

Vue.config.notifications = {
  uploadFailed: 'Выберите файл',
  uploadSuccess: 'Файл был успешно обработан',
  dishes: {
    createSuccess: 'Блюдо успешно создано',
    deleteMassSuccess: 'Блюда успешно удалены',
    deleteOneSuccess: 'Блюдо успешно удалено',
    uploadSuccess: 'Блюда успешно загружены'
  },
  complexes: {
    createSuccess: 'Комплекс успешно создан',
    deleteMassSuccess: 'Комплексы успешно удалены',
    stashMassSuccess: 'Комплексы успешно добавлены в архив',
    restoreMassSuccess: 'Комплексы успешно восстановлены из архива',
    deleteOneSuccess: 'Комплекс успешно удален'
  },
  teachers: {
    updateSuccess: 'Данные учителя обновлены',
    orderPreUpdated: 'Заявка успешно отправлена организатору питания',
    orderSaved: 'Заявка успешно сохранена',
    orderConfirmed: 'Заявка успешно закрыта'
  },
  administrator: {
    studentUpdated: 'Данные ученика успешно обновлены',
    noBalanceDataText: 'Отсутствуют транзакции для печати',
    noBalanceShowText: 'Отсутствуют транзакции для просмотра'
  },
  organizer: {
    complexAssignSuccess: 'Шаблон меню успешно создан',
    activateSuccess: 'Меню успешно активировано',
    schoolUpdatedSuccess: 'Данные о кредитовании школы успешно обновлены',
    noBalanceDataText: 'Отсутствуют транзакции для печати',
    noBalanceShowText: 'Отсутствуют транзакции для просмотра',
    dishUpdated: 'Блюдо успешно обновлено',
    dailyMenuClearSuccess: 'Данные о ежедневном меню успешно очищены'
  },
  parent: {
    orderSuccess: 'Комплексы успешно обновлены',
    noBalanceDataText: 'Отсутствуют транзакции для печати',
    noBalanceShowText: 'Отсутствуют транзакции для просмотра',
    noMealTypeOrParentText: 'У ученика нет типа питания или не задан родитель.'
  }
}

Vue.config.format = {
  date: 'DD.MM.YYYY',
  pythonDate: 'DD-MM-YYYY',
  pythonDateFull: 'DD-MM-YYYY HH:mm:ss',
  prettyDateFull: 'DD.MM.YYYY HH:mm:ss',
  prettyDateWithoutSeconds: 'DD.MM.YYYY HH:mm',
  serverDate: 'YYYY-MM-DD',
  serverDateMonth: 'YYYY-MM',
  serverDateFull: 'YYYY-MM-DD HH:mm:ss',
  datepickerDate: 'YYYY-MM-DD',
  time: 'HH:mm:ss',
  shortTime: 'HH:mm',
  dateString: {
    time: 'DD.MM.YYYY HH:mm',
    day: 'D MMMM YYYY',
    week: 'MMMM YYYY, Wo',
    month: 'MMMM YYYY',
    year: 'YYYY'
  },
  dateNumber: {
    time: 'YYYYMMDDHHmm',
    day: 'YYYYMMDD',
    week: 'YYYYWW',
    month: 'YYYYMM',
    year: 'YYYY'
  },
  numberLocale: 'ru-RU',
  integerOptions: { style: 'decimal' },
  floatOptions: { style: 'decimal', minimumFractionDigits: 2 },
  currencyOptions: { style: 'currency', currency: 'RUB' },
  percentOptions: { style: 'percent', maximumFractionDigits: 2 }
}

export default Vue
