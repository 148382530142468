<script>
import { mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import Receipts from '@/components/organizer/Bill/Receipts.vue'
import _ from 'lodash'
import moment from 'moment'
import {
  getFormerSchools,
  getPaymentsHistoryFormerSchools
} from '@/router/routes/organizer'

export default {
  name: 'FormerSchoolsView',
  components: { Receipts },

  data() {
    return {
      formerSchools: [],
      selectSchool: null,
      headers: [
        { text: 'Дата', value: 'date' },
        { text: 'ЛС', value: 'accountId' },
        { text: 'ФИО', value: 'fullName' },
        { text: 'Класс', value: 'schoolClass.name' },
        { text: 'Школа', value: 'schoolClass.school.name' },
        { text: 'Сумма', value: 'sum' },
        { text: 'Код транзакции', value: 'transactionCode' },
        { text: 'Тип платежа', value: 'type' }
      ],
      payments: [],
      pagination: {
        page: 1,
        page_size: 10,
        count: 0,
        total: 0
      },
      filters: {
        last_name: null,
        pupil_scope: null,
        start_date: null,
        end_date: null
      },
      isLoading: false,
      menu_start_date: false,
      menu_send_data: false
    }
  },
  props: {},
  computed: {
    ...mapGetters(['setting'])
  },
  methods: {
    ...mapMutations(['setShowPreloader']),

    async fetchFormerSchools() {
      try {
        const { data } = await getFormerSchools()
        this.formerSchools = data
      } catch (e) {
        console.error(e.message)
      } finally {
        this.setShowPreloader(false)
      }
    },

    async fetchPaymentsHistoryFormerSchools() {
      const params = {
        ...this.filters,
        start_date: this.formatDate(this.filters.start_date),
        end_date: this.formatDate(this.filters.end_date),
        school: this.selectSchool,
        page: this.pagination.page,
        page_size: this.pagination.page_size
      }

      if (this.selectSchool) {
        this.isLoading = true
        try {
          const {
            data: { data, pagination }
          } = await getPaymentsHistoryFormerSchools({ params })
          this.payments = data
          this.set_pagination(pagination)
        } catch (e) {
          console.error(e.message)
        } finally {
          this.setShowPreloader(false)
          this.isLoading = false
        }
      }
    },

    set_pagination(data) {
      this.pagination = {
        ...this.pagination,
        ...data
      }
    },

    handlePaginationClick(page) {
      this.set_pagination({ page: page })
      this.fetchPaymentsHistoryFormerSchools()
    },

    handleSearch() {
      this.set_pagination({ page: 1 })
      this.fetchPaymentsHistoryFormerSchools()
    },

    reset_pagination() {
      this.pagination = {
        page: 1,
        page_size: 10,
        count: 0,
        total: 0
      }
    },
    reset_filters() {
      this.filters = {
        last_name: null,
        pupil_scope: null,
        start_date: null,
        end_data: null
      }
    },

    reset_data() {
      this.selectSchool = null
      this.payments = []
      this.reset_pagination()
      this.reset_filters()
    },

    formatDate(value) {
      return value ? moment(value).format(Vue.config.format.pythonDate) : null
    }
  },

  watch: {
    selectSchool: {
      deep: true,
      handler: _.debounce(function () {
        this.fetchPaymentsHistoryFormerSchools({ page: 1 })
      }, 1000)
    },
    filters: {
      deep: true,
      handler: _.debounce(function () {
        this.fetchPaymentsHistoryFormerSchools({ page: 1 })
      }, 1500)
    }
  },

  /**
   * Убираем под настройку страницу,
   * если нет настройки перенаправляем на главную страницу
   */
  beforeMount() {
    if (!this.setting('former_schools_for_supplier')) {
      console.log('beforeMount')
      this.$router.push({ name: 'Organizer' })
    }
  },

  mounted() {
    this.fetchFormerSchools()
  }
}
</script>

<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 mb-1 pa-2>
        <h1>История платежей по бывшим школам</h1>
      </v-flex>
      <v-flex xs12 pa-2>
        <v-card class="rounded">
          <v-card-title>
            <v-flex xs5 md5>
              <v-select
                label="Школа"
                v-model="selectSchool"
                :items="formerSchools"
                item-text="name"
                item-value="id"
                hide-details
              ></v-select>
            </v-flex>
          </v-card-title>
          <v-card-text v-if="selectSchool">
            <v-flex xs12 sm12 d-flex :style="{ gap: '12px' }">
              <v-flex xs2 sm2 d-flex mb-4>
                <v-text-field
                  v-model="filters.last_name"
                  label="По фамилии ученика"
                  placeholder="По фамилии ученика"
                  hide-details
                  solo
                  clerable
                ></v-text-field>
              </v-flex>
              <v-flex xs2 sm2 d-flex mb-4>
                <v-text-field
                  v-model="filters.pupil_scope"
                  label="По лицевому счету ученика"
                  placeholder="По лицевому счету ученика"
                  hide-details
                  solo
                  clerable
                ></v-text-field>
              </v-flex>
              <v-flex xs3 sm3 d-flex row>
                <v-menu
                  ref="menu_start_date"
                  v-model="menu_start_date"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                  hide-details
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate(filters.start_date)"
                      label="От..."
                      prepend-icon="event"
                      v-on="on"
                      hide-details
                      readonly
                      solo
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="filters.start_date"></v-date-picker>
                </v-menu>
                <v-menu
                  ref="menu_send_data"
                  v-model="menu_send_data"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                  hide-details
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate(filters.end_data)"
                      label="До..."
                      prepend-icon="event"
                      v-on="on"
                      hide-details
                      readonly
                      solo
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="filters.end_data"></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs2 sm2 d-flex pr-2>
                <v-btn @click.prevent="reset_filters"> Сбросить фильтры </v-btn>
              </v-flex>
            </v-flex>

            <v-layout class="fs-complex-result" mb-3>
              <v-flex>
                <v-data-table
                  :headers="headers"
                  :items="payments"
                  hide-actions
                  :disable-initial-sort="true"
                  no-data-text="Платежи отсутствуют"
                  class="elevation-1"
                  item-key="name"
                  :loading="isLoading"
                >
                  <template v-slot:items="props">
                    <tr>
                      <td>{{ props.item.date }}</td>
                      <td>{{ props.item.accountId }}</td>
                      <td>{{ props.item.fullName }}</td>
                      <td>{{ props.item.schoolClass.name }}</td>
                      <td>{{ props.item.schoolClass.school.name }}</td>
                      <td>{{ props.item.sum }}</td>
                      <td>
                        <span>{{ props.item.transactionCode }}</span>
                      </td>
                      <td>{{ props.item.type }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
            <div class="text-xs-center" v-if="pagination.total > 1">
              <v-pagination
                @input="handlePaginationClick"
                :value="pagination.page"
                :length="pagination.total"
                :total-visible="7"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style scoped></style>
