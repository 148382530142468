<script>
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal.vue'
import ElementGroup from '@/components/common/ElementGroup/ElementGroup.vue'
import {
  getClasses,
  getSchools,
  getStudents,
  createOrdersForPastDay
} from '@/router/routes/superadmin'
import store from '@/store'
import SuperadminOrderLogsTable from '@/components/moduleadmin/Orders/OrderGeneration/components/OrderLogsTable.vue'
import Vue from 'vue'
import notify from '@/utils/notifications/toastNotification.vue'
import moment from 'moment/moment'

export default {
  name: 'SuperadminOrderGeneration',
  components: {
    SuperadminOrderLogsTable,
    ElementGroup,
    ContextHelpModal
  },
  data() {
    return {
      showDatePickerMenu: false,
      schoolsList: [],
      schoolClassList: [],
      schoolPupilList: [],
      selectedSchool: null,
      selectedSchoolClass: [],
      selectedSchoolPupil: [],
      selectedDate: null,
      disabledSubmit: false,
      minDate: moment()
        .subtract('month', 1)
        .format(Vue.config.format.serverDate),
      dateYesterday: moment()
        .subtract('days', 1)
        .format(Vue.config.format.serverDate),
      isValid: true,
      rules: {
        required: (v) => !!v || 'Это поле обязательно для заполнения'
      },
      isLoading: false
    }
  },
  async mounted() {
    await this.getSchools()
    store.commit('setShowPreloader', false)
  },
  watch: {
    async selectedSchool(val) {
      if (val) {
        await this.getSchoolClasses()
      }
    },
    async selectedSchoolClass(val) {
      if (val?.length === 1) {
        await this.getPupilList()
      } else {
        this.schoolPupilList = []
      }
    }
  },
  methods: {
    async getSchools() {
      try {
        const response = await getSchools()
        this.schoolsList = response.data.data.map((item) => {
          item.fullName = `${item.name} (${item.department})`
          return item
        })
      } catch (e) {
        notify.error('Ошибка загрузки школ')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async getSchoolClasses() {
      this.schoolClassList = []
      try {
        const response = await getClasses({
          school_id: this.selectedSchool
        })
        this.schoolClassList = response.data.data
      } catch (e) {
        notify.error('Ошибка загрузки классов')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getPupilList() {
      this.schoolPupilList = []
      try {
        const payload = {
          schoolId: this.selectedSchool,
          classId: this.selectedSchoolClass[0]
        }
        const { data } = await getStudents(payload)
        this.schoolPupilList = data.data
      } catch (e) {
        notify.error('Ошибка загрузки учеников')
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    updateOrder(order) {
      const idx = this.foundOrders.findIndex((x) => x.id === order.id)
      for (const [key, value] of Object.entries(order)) {
        this.foundOrders[idx][key] = value
      }
    },

    clearSchoolSearch() {
      this.selectedSchool = null
    },
    async submit() {
      this.isLoading = true
      const payload = {}
      if (this.selectedSchoolPupil?.length > 0) {
        payload.pupils_ids = [...this.selectedSchoolPupil]
      } else if (this.selectedSchoolClass?.length > 0) {
        payload.classes_ids = [...this.selectedSchoolClass]
      } else {
        payload.school_id = this.selectedSchool
      }

      payload.date = this.selectedDate

      try {
        const res = await createOrdersForPastDay(payload)
        console.log(res)
        console.log(res.data)
        if (res.data.success) {
          notify.success(`Заказ(ы) добавлен(ы) в статусе Отменен`)
        }
        // Обновляем логи через 2 секунды
        // Реф дочернего компонента, вызов функции getLogList
        setTimeout(await this.$refs.orderLogsTable.getLogList, 2000)
      } catch (err) {
      } finally {
        store.commit('setShowPreloader', false)
        this.isLoading = false
      }
    },

    reset() {
      this.$refs.formCreateOrders.reset()
      this.$refs.formCreateOrders.resetValidation()
      this.schoolClassList = []
      this.schoolPupilList = []
    }
  },
  computed: {}
}
</script>

<template>
  <v-container fluid class="fs-teacher-container">
    <v-layout row mb-3>
      <div class="d-flex row justify-space-between">
        <h1>Формирование заказа на прошедшую дату</h1>
      </div>
    </v-layout>
    <div>
      <v-card class="pa-3">
        <v-form
          ref="formCreateOrders"
          class="pb-0"
          @submit.prevent="submit"
          v-model="isValid"
        >
          <v-container fluid grid-list-xl>
            <v-layout row wrap justify-center>
              <v-flex xs6>
                <v-autocomplete
                  solo
                  clearable
                  v-model="selectedSchool"
                  :items="schoolsList"
                  item-text="fullName"
                  item-value="id"
                  label="Школа"
                  @click:clear="clearSchoolSearch"
                  prepend-icon="school"
                  required
                  :rules="[rules.required]"
                >
                  <template v-slot:append-outer>
                    <v-slide-x-reverse-transition mode="out-in">
                      <v-icon color="error">*</v-icon>
                    </v-slide-x-reverse-transition>
                  </template>
                </v-autocomplete>

                <v-menu
                  ref="menu"
                  v-model="showDatePickerMenu"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  lazy
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      solo
                      clearable
                      v-model="selectedDate"
                      label="Дата заявки"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      prepend-icon="event"
                      required
                      :rules="[rules.required]"
                    >
                      <template v-slot:append-outer>
                        <v-slide-x-reverse-transition mode="out-in">
                          <v-icon color="error">*</v-icon>
                        </v-slide-x-reverse-transition>
                      </template></v-text-field
                    >
                  </template>
                  <v-date-picker
                    v-model="selectedDate"
                    :min="minDate"
                    :max="dateYesterday"
                    no-title
                    locale="ru"
                    first-day-of-week="1"
                  />
                </v-menu>
              </v-flex>
              <v-flex xs6>
                <v-autocomplete
                  solo
                  multiple
                  clearable
                  v-model="selectedSchoolClass"
                  :items="schoolClassList"
                  item-text="name"
                  item-value="id"
                  label="Класс"
                  prepend-icon="desk"
                >
                  <template v-slot:append-outer>
                    <v-slide-x-reverse-transition mode="out-in">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon color="blue-grey" v-on="on">help</v-icon>
                        </template>
                        <div class="pa-2">
                          <span>
                            Если Класс не выбран, то создает по всей школе
                          </span>
                        </div>
                      </v-tooltip>
                    </v-slide-x-reverse-transition>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  solo
                  multiple
                  clearable
                  v-model="selectedSchoolPupil"
                  :items="schoolPupilList"
                  item-text="fullName"
                  item-value="id"
                  :onlyread="!(selectedSchoolClass.length === 1)"
                  label="Ученики"
                  prepend-icon="person"
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon color="blue-grey" v-on="on">help</v-icon>
                      </template>
                      <div class="pa-2">
                        <span>
                          Если ученик не выбран, то создает на весь класс
                          <br />
                          Выбор учеников доступен при выборе только одного
                          класса.
                        </span>
                      </div>
                    </v-tooltip>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex xs4 d-flex>
                <v-btn @click="reset"> Очистить </v-btn>
                <v-btn
                  type="submit"
                  color="primary"
                  :disabled="!isValid"
                  :loading="isLoading"
                >
                  Создать заказ(ы)
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card>
    </div>
    <div>
      <superadmin-order-logs-table
        ref="orderLogsTable"
      ></superadmin-order-logs-table>
    </div>
  </v-container>
</template>

<style scoped></style>
