<template>
  <v-container
    fluid
    class="fs-parent-container"
  >
    <v-layout
      class="fs-daily-menu"
      row
      mb-3
    >
      <v-flex
        md3
        mb-3
      >
        <h1>Явка</h1>
      </v-flex>
    </v-layout>

    <template v-if="hasChanges">
      <v-btn flat class="fs-btn fs-btn--primary fs-form-footer__btn" @click="save">Сохранить</v-btn>
    </template>

    <v-layout
      class="parent_orders_panels"
      row
      wrap
      v-for="(weekArray, weekIndex) in allWeeks"
      :key="weekIndex"

    >
      <v-flex
        xs12
        class="dateRangeTitle"
        style="margin: 10px 0px;"
      >
        <h3>{{getWeekLabelText(weekIndex)}}</h3>
      </v-flex>
      <v-flex xs12>
        <div
          v-for="(item, index) in weekArray"
          :key="index"

        >
          <table>
            <tr :style="styleTable">
              <td style="width: 280px; padding: 10px">
                {{calculateDate(item)}}, {{calculateDay(item)}}
              </td>
              <td style="width: 280px; padding: 10px">
                <span v-if="hasCheckbox(item)">
                  <span v-if="getIsWorking(item)">
                    Явка <v-btn @click="setNoTraffic(item)">Поставить неявку</v-btn>
                  </span>
                  <span v-else>
                    Неявка <v-btn @click="setTraffic(item)">Поставить явку</v-btn>
                  </span>
                </span>
                <span v-else>
                  &nbsp;
                </span>
              </td>
            </tr>
          </table>
        </div>
      </v-flex>
    </v-layout>

    <template v-if="hasChanges">
      <v-btn flat class="fs-btn fs-btn--primary fs-form-footer__btn" @click="save">Сохранить</v-btn>
    </template>

  </v-container>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import {
  saveTraffic,
  getTraffic,
} from '@/router/routes/parent'
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import notify from '@/utils/notifications/toastNotification'

const week1 = [ 1, 2, 3, 4, 5, 6, 7 ]
const week2 = [ 8, 9, 10, 11, 12, 13, 14 ]
const week3 = [ 15, 16, 17, 18, 19, 20, 21 ]
const allWeeks = [ week1, week2, week3 ]

export default {
  name: 'ParentTrafficIndex',
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      items: [],
      valid_dates: [],
      allWeeks: allWeeks,
      traffic: {}
    }
  },
  computed: {
    ...mapGetters([ 'currentChild' ]),
    hasChanges() {
      return Object.keys(this.traffic).length > 0
    },
    styleTable() {
      return !this.darkTheme
        ? 'height: 40px; background: #ffefc6; border: 1px solid #caa17a'
        : 'height: 40px; border: 1px solid #caa17a;'
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    getWeekLabelText(index) {
      const arrayLength = allWeeks[index].length
      const startDate = this.calculateDate(allWeeks[index][0])
      const endDate = this.calculateDate(
        allWeeks[index][arrayLength - 1]
      )
      return `c "${startDate}" по "${endDate}"`
    },
    async init() {
      try {
        const payload = {
          id: this.currentChild.id,
          params: {
            start_date: moment()
              .locale('ru')
              .startOf('isoweek')
              .format(Vue.config.format.pythonDate),
            end_date: moment()
              .locale('ru')
              .startOf('isoweek')
              .add('days', 20)
              .format(Vue.config.format.pythonDate)
          }
        }
        const response = await getTraffic(payload)

        const {
          data: { data: {
            items, valid_dates
          } }
        } = response
        this.items = items
        this.valid_dates = valid_dates
      } catch (error) {
        // notify.error(`Не удалось загрузить явку`) // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    setNoTraffic(dayOfWeek) {
      const date = this.getSaveableDate(dayOfWeek)
      const index = this.items.map(d => d.date).indexOf(date)
      this.$set(this.traffic, date, false)
      if (index === -1) {
        this.items.push({ date, is_working: false })
        return
      }
      this.$set(this.items, index, { date, is_working: false })
    },
    setTraffic(dayOfWeek) {
      const date = this.getSaveableDate(dayOfWeek)
      const index = this.items.map(d => d.date).indexOf(date)
      this.$set(this.traffic, date, true)
      if (index === -1) {
        this.items.push({ date, is_working: true })
        return
      }
      this.$set(this.items, index, { date, is_working: true })
    },
    calculateDate(dayOfWeek) {
      return moment()
        .locale('ru')
        .startOf('isoweek')
        .add(dayOfWeek - 1, 'days')
        .format(Vue.config.format.date)
    },
    getSaveableDate(dayOfWeek) {
      return moment()
        .locale('ru')
        .startOf('isoweek')
        .add(dayOfWeek - 1, 'days')
        .format(Vue.config.format.pythonDate)
    },
    getServerDate(dayOfWeek) {
      return moment()
        .locale('ru')
        .startOf('isoweek')
        .add(dayOfWeek - 1, 'days')
        .format(Vue.config.format.serverDate)
    },
    hasCheckbox(dayOfWeek) {
      const date = this.getServerDate(dayOfWeek)
      return !!this.valid_dates.find(d => d === date)
    },
    getIsWorking(dayOfWeek) {
      const date = moment()
        .locale('ru')
        .startOf('isoweek')
        .add(dayOfWeek - 1, 'days')
        .format(Vue.config.format.pythonDate)
      const item = this.items.find(d => d.date === date)
      if (!item) {
        return true
      }
      return item.is_working
    },
    calculateDateForPython(dayOfWeek) {
      return moment()
        .locale('ru')
        .startOf('isoweek')
        .add(dayOfWeek - 1, 'days')
        .format(Vue.config.format.pythonDate)
    },
    calculateDay(dayOfWeek) {
      return moment()
        .locale('ru')
        .startOf('isoweek')
        .add(dayOfWeek - 1, 'days')
        .format('dddd')
    },
    async save() {
      const data = []
      for (const date of Object.keys(this.traffic)) {
        data.push({
          date,
          is_working: this.traffic[date]
        })
      }

      const payload = {
        id: this.currentChild.id,
        data
      }
      saveTraffic(payload).then(() => {
        this.traffic = {}
      }).finally(() => store.commit('setShowPreloader', false))
    }
  }
}
</script>
