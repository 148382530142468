import Vue from 'vue'

import Loader from '@/components/Loader'
import '@/config'
import '@/bootstrap'

// TODO Изменить на параметры из бд DARK_THEME
if (Vue.config.env.darkTheme) {
  require('@/assets/css/index_dark_theme.scss')
} else {
  require('@/assets/css/index.scss')
}

import '@/plugins/vuetify'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import App from './App'
import router from './router'
import FsAutocomplete from '@/components/common/FsAutocomplete/FsAutocomplete'
import store from './store'
import Vuex from 'vuex'
import Notifications from 'vue-notification'
import Toast from 'vue-toastification'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vue-toastification/dist/index.css'
import VueChat from '@/plugins/VueChat'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(CKEditor)
Vue.use(Notifications)
Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  maxToasts: 20,
  newestOnTop: false
})
Vue.use(VueChat)

Vue.component('fs-autocomplete', FsAutocomplete)
Vue.component('loader', Loader)

new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})
