<template>
  <v-container fluid grid-list-xl>

    <v-layout wrap align-center>
      <v-flex xs12 d-flex v-if="filters.includes('school')">
        <v-autocomplete
          v-model="school"
          :items="schools"
          :loading='isSchoolsLoading'
          :search-input.sync='schoolsSearchQuery'
          no-filter
          item-text="name"
          item-value="id"
          return-object
          clearable
          :label="!darkTheme ? 'Школы' : ''"
          :placeholder="darkTheme ? 'Школы' : ''"
          :outline="darkTheme"
        ></v-autocomplete>
      </v-flex>

      <v-flex xs6 d-flex v-if="filters.includes('evaluation')">
        <v-select
          clearable
          :items="evaluations"
          item-text="id"
          item-value="id"
          v-model="qualityAssesmentsFilters.lower_evaluation"
          @change='(value) => setQualityAssesmentsFilters({ lower_evaluation: value })'
          :label="!darkTheme ? 'Оценка (больше чем)' : ''"
          :placeholder="darkTheme ? 'Оценка (больше чем)' : ''"
          :outline="darkTheme"
        ></v-select>
        <v-select
          class='ml-3'
          clearable
          :items="evaluations"
          item-text="id"
          item-value="id"
          v-model="qualityAssesmentsFilters.upper_evaluation"
          @change='(value) => setQualityAssesmentsFilters({ upper_evaluation: value })'
          :label="!darkTheme ? 'Оценка (меньше чем)' : ''"
          :placeholder="darkTheme ? 'Оценка (меньше чем)' : ''"
          :outline="darkTheme"
        ></v-select>
      </v-flex>

      <v-flex xs6 d-flex v-if="filters.includes('supplier')">
        <suppliers-autocomplete v-model="supplier" :fetch-suppliers='fetchSuppliers'/>
      </v-flex>

      <v-flex md6 sm12 d-flex v-if="filters.includes('datepicker')">
        <v-layout wrap align-center>
            <v-flex xs6 d-flex>
              <div class="card-detail__item">
                <DateTimePicker v-model="date_start" label="Дата от" auto-set-time clearable/>
              </div>
            </v-flex>

            <v-flex xs6 d-flex>
              <div class="card-detail__item">
                <DateTimePicker v-model="date_finish" label="Дата по" auto-set-time clearable/>
              </div>
            </v-flex>
        </v-layout>
      </v-flex>

    </v-layout>

    <v-layout class="fs-header-filter" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="qualityAssesments.results"
          :total-items='qualityAssesments.count'
          hide-actions
          no-data-text="Нет информации"
          class="elevation-1 fs-admin-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr style="cursor: pointer;" @click="showOrderGradeDetail(props.item)">
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.created_at | transformDate }}</td>
              <!-- <td>{{ props.item.order.orderDay }}</td> -->
              <td>{{ props.item.supplier?.username || 'Имя не указано' }}</td>
              <td>
                <v-rating v-model="props.item.evaluation" length="10" readonly />
              </td>
              <td>
                <v-icon small class="pr-1" color="green" v-if="props.item.images.length > 0">image</v-icon>
              </td>
              <td>{{ props.item.order.orderDay }}</td>
              <td>{{ props.item.order.complect.name }}</td>
              <td>{{ props.item.order.pupil.fullName }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <div class="text-xs-center" v-if="pagination_count > 1">
      <v-pagination
        @input="handlePaginationClick"
        :value="qualityAssesmentsPagination.page"
        :length="pagination_count"
        :total-visible="7"
      ></v-pagination>
    </div>

    <v-dialog v-model="isShowModal" width="70%" style="z-index: 9999;">
      <QualityAssessmentsDetail
        v-if="isShowModal"
        :orderGradeId="orderGradeId"
        :readonly="true"
        @close="handleCloseModal"
      />
    </v-dialog>

  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import DateTimePicker from '@/components/common/DatePickersSmart/DateTimePicker'
import QualityAssessmentsDetail from '@/components/parent/QualityAssessments/QualityAssessmentsDetail.vue'
import SuppliersAutocomplete from '@/components/common/QualityControl/inputs/SuppliersAutocomplete.vue'

export default {
  name: 'QualityControl',
  components: { SuppliersAutocomplete, DateTimePicker, QualityAssessmentsDetail },
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      isShowModal: false,
      orderGradeId: null,
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Дата оценки', value: 'created_at', sortable: false },
        // { text: 'Дата заказа', value: 'orderDay', sortable: false },
        { text: 'Организатор', value: 'supplier', sortable: false },
        { text: 'Оценка', value: 'evaluation', sortable: false },
        { text: 'Фото', sortable: false },
        { text: 'Дата заказа', value: 'order_date', sortable: false },
        { text: 'Комплекс', value: 'complect', sortable: false },
        { text: 'Ученик', value: 'pupil', sortable: false }
      ],
      schools: [],
      isSchoolsLoading: false,
      schoolsSearchQuery: null,
      evaluations: [
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 },
        { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }
      ]
    }
  },
  props: {
    fetchQualityAssesments: { type: Function },
    fetchSchools: { type: Function },
    fetchSuppliers: { type: Function },
    filters: { type: Array, default: [] }
  },
  computed: {
    ...mapGetters([ 'qualityAssesments', 'qualityAssesmentsFilters', 'qualityAssesmentsPagination' ]),
    pagination_count() {
      return Math.ceil(this.qualityAssesments.count / this.qualityAssesmentsPagination.page_size) || 0
    },
    school: {
      get() {
        return this.qualityAssesmentsFilters.school
      },
      set(school) {
        this.setQualityAssesmentsFilters({ school: school ? school.id : null })
      }
    },
    supplier: {
      get() {
        return this.qualityAssesmentsFilters.supplier_id
      },
      set(supplier) {
        this.setQualityAssesmentsFilters({ supplier_id: supplier ? supplier.id : null })
      }
    },
    date_start: {
      get() {
        return this.qualityAssesmentsFilters.date_start
      },
      set(date_start) {
        this.setQualityAssesmentsFilters({ date_start: date_start ? date_start : null })
      }
    },
    date_finish: {
      get() {
        return this.qualityAssesmentsFilters.date_finish
      },
      set(date_finish) {
        this.setQualityAssesmentsFilters({ date_finish: date_finish ? date_finish : null })
      }
    }
  },
  mounted() {
    if (!this.qualityAssesments.results) this.fetchQualityAssesments()
    if (this.filters.includes('school') && this.schools.length === 0) this.handleFetchSchools()
  },
  methods: {
    ...mapMutations([ 'setQualityAssesmentsPagination', 'setQualityAssesmentsFilters' ]),
    showOrderGradeDetail(orderGrade) {
      this.isShowModal = true
      this.orderGradeId = orderGrade.id
    },
    handleCloseModal() {
      this.isShowModal = false
      this.orderGradeId = null
    },
    handlePaginationClick(page) {
      this.setQualityAssesmentsPagination({ page: page })
      this.fetchQualityAssesments()
    },
    handleFetchSchools() {
      this.isSchoolsLoading = true
      this.fetchSchools({ search: this.schoolsSearchQuery })
        .then(response => {
          this.schools = response.data.data
        }).finally(() => {
          this.isSchoolsLoading = false
        })
    }
  },
  watch: {
    qualityAssesmentsFilters() {
      this.setQualityAssesmentsPagination({ page: 1 })
      this.fetchQualityAssesments()
    },
    schoolsSearchQuery: _.debounce(function () {
      this.handleFetchSchools()
    }, 500),
  }
}
</script>
