<script>
import SuperadminOrdersTableV2EditModal from "@/components/moduleadmin/Orders/Components/OrderEdit.vue"
import ElementGroup from "@/components/common/ElementGroup/ElementGroup.vue"
import SuperadminOrdersTableV2EditMultipleModal from "@/components/moduleadmin/Orders/Components/OrderMultipleEdit.vue"
import numable from "@/mixins/balancable"
import SuperadminMealTypesTableV2EditMultipleModal
  from "@/components/moduleadmin/Orders/Components/MealTypeMultipleEdit"

export default {
  name: 'SuperadminOrdersTableV2',
  components: {
    SuperadminMealTypesTableV2EditMultipleModal,
    SuperadminOrdersTableV2EditMultipleModal, ElementGroup, SuperadminOrdersTableV2EditModal },
  props: {
    orders: {
      type: Array
    }
  },
  mixins: [ numable ],
  data() {
    return {
      showModal: false,
      showMultiModal: false,
      showMealTypeModal: false,
      selectedOrder: null,
      selectedMultipleOrders: [],
      search: ""
    }
  },
  mounted() {
    this.selectedMultipleOrders = []
  },
  methods: {
    showOrder(data) {
      this.selectedOrder = data
      this.showModal = true
    },
    showMulti() {
      this.showMultiModal = true
    },
    showMultiMealType() {
      this.showMealTypeModal = true
    },
    closeModal() {
      this.selectedOrder = null
      this.showModal = false
    },
    closeMultiModal() {
      this.showMultiModal = false
    },
    closeMultiMealTypeModal() {
      this.showMealTypeModal = false
    },
    updateOrderData(orders) {
      orders.forEach(order => {
        this.$emit('updateOrder', order)
      })
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Номер заказа',
          align: 'left',
          value: 'id'
        },
        {
          text: 'Дата заказа',
          align: 'left',
          value: 'order_day'
        },
        {
          text: 'Класс',
          align: 'center',
          value: 'school_class_name'
        },
        {
          text: 'Ученик',
          align: 'left',
          value: 'pupil_full_name'
        },
        {
          text: 'Тип питания (стоимость, руб)',
          align: 'left',
          value: 'meal_type'
        },
        {
          text: 'Сумма льготы, руб',
          align: 'left',
          value: 'privilege_amount'
        },
        {
          text: 'Итого, руб',
          align: 'left',
          value: 'order_price'
        },
        {
          text: 'Статус',
          value: 'status_text'
        },
        {
          text: 'Действия',
          align: 'right'
        }
      ]
    }
  }
}
</script>

<template>
<div class="py-2">
  <v-card>
    <v-card-text class="px-0 pb-0 pt-1">
      <div class="d-flex justify-space-between">
        <v-text-field
            solo
            v-model="search"
            label="Поиск по таблице"
            class="mb-1 ml-3"
            style="max-width: 400px"
            hide-details
        />
        <div class="d-flex justify-end">
          <v-btn
             :disabled="selectedMultipleOrders.length === 0"
             class="mb-2"
             color="primary"
             @click="showMultiMealType"
             style="min-width: 200px; max-width: 200px"
          >
            Изменить тип питания
          </v-btn>
          <v-btn
              :disabled="selectedMultipleOrders.length === 0"
              class="mb-2"
              color="primary"
              @click="showMulti"
              style="min-width: 150px; max-width: 150px"
          >
            Изменить статус
          </v-btn>
        </div>
      </div>
      <v-data-table
          :headers="headers"
          :items="orders"
          v-model="selectedMultipleOrders"
          :rows-per-page-items="[25, 50]"
          item-key="id"
          :search="search"
          select-all
          class="elevation-1"
      >
        <template v-slot:items="props">
          <td>
            <v-checkbox
                v-model="props.selected"
                hide-details
            />
          </td>
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.order_day }}</td>
          <td class="text-center">{{ props.item.school_class_name }}</td>
          <td>{{ props.item.pupil_full_name }}</td>
          <td>{{ props.item.meal_type_name }} ({{ convertToRubles(props.item.meal_type_price) }} руб)</td>
          <td>{{ convertToRubles(props.item.privilege_amount) }} руб</td>
          <td>{{ convertToRubles(props.item.order_price) }} руб</td>
          <td class="text-right">{{ props.item.status_text }}</td>
          <td class="text-center">
            <v-btn
                small
                icon
                @click="showOrder(props.item)"
            >
              <v-icon small>
                edit
              </v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
  <superadmin-orders-table-v2-edit-modal
    v-if="selectedOrder"
    :show-modal="showModal"
    :order-data="selectedOrder"
    v-on:closeModal="closeModal"
    v-on:updateOrder="x => updateOrderData([x])"
  />
  <superadmin-orders-table-v2-edit-multiple-modal
    v-if="selectedMultipleOrders.length > 0"
    :show-modal="showMultiModal"
    :orders="selectedMultipleOrders"
    v-on:closeModal="closeMultiModal"
    v-on:updateOrder="x => updateOrderData(x)"
  />
  <superadmin-meal-types-table-v2-edit-multiple-modal
    v-if="selectedMultipleOrders.length > 0"
    :show-modal="showMealTypeModal"
    :orders="selectedMultipleOrders"
    v-on:closeModal="closeMultiMealTypeModal"
    v-on:updateOrder="x => updateOrderData(x)"
  />
</div>
</template>

<style>
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
</style>
