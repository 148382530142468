import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import notify from '@/utils/notifications/toastNotification'

window.axios = require('axios')

axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

export const preloaderInterceptor = axios.interceptors.request.use(config => {
  if (config.preloaderState != false) {
    store.commit('setShowPreloader', true)
  }
  return config
})

const handleResponseError = (req, res) => {

  // Обработка серверных ошибок
  if (typeof res.data === 'object') {
    const code = 'code' in res.data ? res.data.code : null
    const message = 'message' in res.data ? res.data.message : null
    // Обработка массива объектов message: [{key: value}, "str"]
    if (Array.isArray(message)) {
      message.forEach(item => {
        if (typeof item === 'string') {
          notify.error(item)
        } else {
          Object.entries(item).forEach(i => {
            const msg = `${i[0]}: ${i[1]}`
            notify.error(msg)
          })
        }
      })
    }
    // Обработка единичного словаря message: {key: value}
    if (typeof message === 'object' && !Array.isArray(message)) {
      Object.entries(message).forEach(item => {
        const msg = `${item[0]}: ${item[1]}`
        notify.error(msg)
      })
    }
    if (typeof message === 'string') {
      if (code?.length > 4) {
        notify.errorWithUuid(message, code)
      } else {
        notify.error(message)
      }
    }
  }

  if (typeof res.data === 'string') {
    if (res.status === 500) {
      notify.error(res.data ? res.data : 'Сервер сообщил об ошибке обработки данных. Обратитесь в техническую поддержку.')
    }
  }

  if (req.responseType === 'blob') {
    const blob = new Blob([ res.data ], {
      type: res.type
    })
    const fr = new FileReader()
    fr.onload = event => {
      const error_message = JSON.parse(event.target.result).message
      if (Array.isArray(error_message)) {
        notify.error(error_message[0])
      }
      else {
        notify.error(error_message)
      }
    }
    fr.readAsText(blob)
  }
}

axios.interceptors.response.use(
  response => response,
  error => {
    if (![ 401 ].includes(error.request.status)) {
      if (Object.keys(error).includes('custom_error')) {
        if (error.response.data.message.custom_error === 'True') {
          const authParams = JSON.parse(error.config.data)
          const getParams = `?session_id=${authParams.session_id}`
          router.push({ path: `/teachers${getParams}` })
          store.commit('setShowPreloader', false)
          return Promise.reject()
        } else {
          handleResponseError(error.request, error.response)
          if (error.request.status === 417) {
            router.push({ name: 'Forbidden' })
          }
        }
      } else {
        handleResponseError(error.request, error.response)
      }
    }

    if (router.currentRoute.name === 'Parent') router.push({ name: 'Forbidden' })
    store.commit('setShowPreloader', false)
    return Promise.reject(error)
  }
)
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Accept'] = 'application/json'

Number.prototype.priceToString = function() {
  return (this / 100).toFixed(2).replace('.', ',').replace(/,00$/, '')
}

String.prototype.priceToNumber = function() {
  return +(this.replace(',', '.') * 100).toFixed()
}
