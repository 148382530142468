import {
  getStorehouses, getStorehouseCard, updateStorehouseCard,
  createStorehouseCard, getStorehousesProducts, getStorehousesOneProduct,
  createStorehouseProduct, updateStorehouseProduct, deleteStorehouseProduct,
  deleteStorehouseCard
} from '@/router/routes/admin'

import { getProductCategories, createProductCategory } from '@/router/routes/common'

export default {
  actions: {
    async fetchStorehousesProducts(ctx, payload) {
      await getStorehousesProducts(payload)
        .then(res => {
          ctx.commit('set_storehouses_products', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async createProductCategory(ctx, payload) {
      return await createProductCategory(payload)
        .then(res => {
          return Promise.resolve("success")
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async getProductCategories(ctx, payload) {
      await getProductCategories(payload)
        .then(res => {
          ctx.commit('set_product_categories', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async fetchStorehousesOneProduct(ctx, payload) {
      await getStorehousesOneProduct(payload)
        .then(res => {
          ctx.commit('set_storehouses_one_product', {
            ...res.data,
            category: res.data.category.id
          })
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async createStorehouseProduct(ctx, payload) {
      await createStorehouseProduct(payload)
        .then(res => {
          return Promise.resolve()
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async updateStorehouseProduct(ctx, payload) {
      await updateStorehouseProduct(payload)
        .then(res => {
          return Promise.resolve()
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async deleteStorehouseProduct(ctx, payload) {
      await deleteStorehouseProduct(payload)
        .then(res => {
          return Promise.resolve()
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async fetchStorehouses(ctx, payload) {
      await getStorehouses(payload)
        .then(res => {
          ctx.commit('set_storehouses', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async fetchStorehousesCard(ctx, payload) {
      await getStorehouseCard(payload)
        .then(res => {
          ctx.commit('set_storehouse_card', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async updateStorehouseCard(ctx, payload) {
      return await updateStorehouseCard(payload)
        .then(res => {
          ctx.commit('set_storehouse_card', res.data)
          return Promise.resolve()
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async deleteStorehouseCard(ctx, payload) {
      return await deleteStorehouseCard(payload)
        .then(res => {
          return Promise.resolve()
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async createStorehouseCard(ctx, payload) {
      return await createStorehouseCard(payload)
        .then(res => {
          ctx.commit('set_card', res.data)
          return Promise.resolve()
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
  },
  mutations: {
    set_storehouse_card(state, storehouse_card) {
      state.storehouse_card = storehouse_card
    },
    set_storehouses(state, storehouses) {
      state.storehouses = storehouses
    },
    set_storehouses_products(state, storehouses_products) {
      state.storehouses_products = storehouses_products
    },
    set_errors_storehouse(state, errors_storehouse) {
      state.errors_storehouse = errors_storehouse
    },
    set_storehouses_one_product(state, storehouses_one_product) {
      state.storehouses_one_product = storehouses_one_product
    },
    set_product_category(state, storehouses_one_product) {
      state.storehouses_one_product = storehouses_one_product
    },
    set_product_categories(state, categories) {
      state.product_categories = categories
    },
    set_storehouses_pagination(state, data) {
      state.storehouses_pagination = {
        ...state.storehouses_pagination,
        ...data
      }
    },
    set_product_categories_pagination(state, data) {
      state.product_categories_pagination = {
        ...state.product_categories_pagination,
        ...data
      }
    },

  },
  getters: {
    storehouses(state) {
      return state.storehouses
    },
    storehouse_card(state) {
      return state.storehouse_card
    },
    storehouses_products(state) {
      return state.storehouses_products
    },
    storehouses_one_product(state) {
      return state.storehouses_one_product
    },
    product_categories(state) {
      return state.product_categories
    },
    storehouses_pagination(state) {
      return state.storehouses_pagination
    },
    product_categories_pagination(state) {
      return state.product_categories_pagination
    },
  },
  state: {
    storehouses: [],
    storehouses_pagination: {
      page: 1,
      page_size: 10
    },
    product_categories_pagination: {
      page: 1,
      page_size: 10
    },
    product_categories: [],
    storehouses_products: [],
    storehouses_one_product: {
      name: "",
      count: 0,
      price: 0,
      storehouse: null,
      category: null
    },
    storehouse_card: {
      id: null,
      created_at: "",
      updated_at: "",
      name: ""
    },
    errors_storehouse: false,
  },
}
