<template>
  <v-container fluid class="fs-teacher-container">
    <div class="page-head__common">
      <h1>Пополнение счёта буфета</h1>
      <v-btn
        v-if="selectedStudent"
        flat
        @click="showTransactionModal = true"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
        >Создать транзакцию для "{{ selectedStudent.name }}"</v-btn
      >
    </div>
    <div class="choice-select">
      <v-autocomplete
        v-model="selectedClass"
        :items="classes"
        :item-text="'name'"
        :item-value="'id'"
        return-object
        clearable
        flat
        dense
        :label="!darkTheme ? 'Выбрать класс:' : ''"
        :placeholder="darkTheme ? 'Выбрать класс:' : ''"
        :outline="darkTheme"
      />

      <v-autocomplete
        v-show="selectedClass"
        v-model="selectedStudent"
        :items="students"
        :item-text="'name'"
        :item-value="'id'"
        return-object
        clearable
        flat
        dense
        :label="!darkTheme ? 'Выбрать ученика:' : ''"
        :placeholder="darkTheme ? 'Выбрать ученика:' : ''"
        :outline="darkTheme"
      />
    </div>

    <v-container fluid grid-list-xl v-if="selectedStudent">
      <v-layout wrap align-center>
        <v-flex xs12 sm6 d-flex>
          <v-select
            :items="filter.amount_type"
            item-text="name"
            item-value="value"
            return-object
            v-model="buffet_transactions_filters.amount_type"
            :label="!darkTheme ? 'Тип операции:' : ''"
            :placeholder="darkTheme ? 'Тип операции:' : ''"
            :outline="darkTheme"
          />
        </v-flex>
        <v-flex xs12 sm6 d-flex>
          <v-select
            :items="filter.status"
            item-text="name"
            item-value="value"
            return-object
            v-model="buffet_transactions_filters.status"
            :label="!darkTheme ? 'Тип статуса:' : ''"
            :placeholder="darkTheme ? 'Тип статуса:' : ''"
            :outline="darkTheme"
          />
        </v-flex>
      </v-layout>
    </v-container>

    <v-layout class="fs-teacher-order-result" mb-3 v-if="selectedStudent">
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="buffet_transactions.results"
          hide-actions
          no-data-text="Нет транзакций по данному ученику"
          class="elevation-1 fs-admin-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.created_at | transformDate }}</td>
              <td>{{ amount_types[props.item.amount_type] }}</td>
              <td>{{ statuses[props.item.status] }}</td>
              <td>{{ convertToRubles(props.item.amount) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <div class="text-xs-center" v-if="pagination_count > 1">
      <v-pagination
        @input="handlePaginationClick"
        :value="buffet_transactions_pagination.page"
        :length="pagination_count"
        :total-visible="7"
      ></v-pagination>
    </div>

    <v-dialog v-model="showTransactionModal" persistent width="70%">
      <BuffetBalanceModal
        v-if="showTransactionModal"
        @close="showTransactionModal = false"
        @create="createTransaction"
        :pupil="selectedStudent.id"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { getClasses, getFilteredStudents } from '@/router/routes/admin.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BuffetBalanceModal from '@/components/admin/BuffetBalance/BuffetBalanceModal'
import notify from '@/utils/notifications/toastNotification'
import store from '@/store'
import numable from '@/mixins/balancable'
export default {
  name: 'AdminBuffetBalanceAdd',
  components: { BuffetBalanceModal },
  mixins: [numable],
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      classes: [],
      selectedClass: null,

      students: [],
      selectedStudent: null,

      statuses: {
        1: 'Создан',
        2: 'В обработке',
        3: 'Ошибка',
        4: 'Завершен'
      },

      amount_types: {
        1: 'Зачисление',
        2: 'Списание'
      },

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Дата создания', value: 'created_at' },
        { text: 'Тип операции', value: 'amount_type' },
        { text: 'Статус', value: 'status' },
        { text: 'Сумма', value: 'amount' }
      ],

      filter: {
        amount_type: [
          { value: null, name: 'Все' },
          { value: 1, name: 'Зачисление' },
          { value: 2, name: 'Списание' }
        ],
        status: [
          { value: null, name: 'Все' },
          { value: 1, name: 'Создан' },
          { value: 2, name: 'В обработке' },
          { value: 3, name: 'Ошибка' },
          { value: 4, name: 'Завершен' }
        ]
      },

      showTransactionModal: false
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'buffet_transactions_pagination',
      'buffet_transactions_filters',
      'buffet_transactions'
    ]),
    pagination_count() {
      return (
        Math.ceil(
          this.buffet_transactions.count /
            this.buffet_transactions_pagination.page_size
        ) || 0
      )
    }
  },
  async mounted() {
    await this.getClasses()
  },
  methods: {
    ...mapActions([
      'getPupilBuffetTransactions',
      'createPupilBuffetTransactions'
    ]),
    ...mapMutations([
      'setShowPreloader',
      'set_buffet_transactions_pagination',
      'set_buffet_transactions',
      'clear_buffet_transactions_filters'
    ]),
    clearStudents() {
      ;(this.students = []), (this.selectedStudent = null)
    },
    async getClasses() {
      console.log('getClasses=>', this.currentUser.school.id)
      try {
        const response = await getClasses({
          schoolId: this.currentUser.school.id,
          params: { limit: 1000, offset: 0 }
        })
	      console.log(response)
        this.classes = response.data.data.map((item) => ({
          id: item.id,
          name: item.name,
          number: item.number
        }))
        this.selectedClass = this.classes[0]
      } catch (error) {
        console.error(error)
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        this.setShowPreloader(false)
      }
    },
    async getStudents() {
      try {
        const response = await getFilteredStudents({
          schoolId: this.currentUser.school.id,
          classId: this.selectedClass.id,
          params: { limit: 1000, offset: 0 }
        })
        this.students = response.data.data.map((item) => ({
          id: item.id,
          name: item.fullName
        }))
      } catch (error) {
        console.error(error)
        // notify.error('Ошибка загрузки учеников') // backend handler added (exception_message)
      } finally {
        this.setShowPreloader(false)
      }
    },
    handlePaginationClick(page) {
      this.set_buffet_transactions_pagination({ page: page })
    },
    fetchPupilBuffetTransactions() {
      if (this.selectedStudent && this.selectedStudent.id)
        this.getPupilBuffetTransactions({ pupil: this.selectedStudent.id })
    },
    createTransaction(transaction) {
      transaction.amount = numable.methods.convertToCents(transaction.amount)
      this.createPupilBuffetTransactions(transaction).then(() => {
        this.showTransactionModal = false
        this.fetchPupilBuffetTransactions()
      })
    }
  },
  watch: {
    selectedClass(val) {
      if (val) this.getStudents()
      else this.clearStudents()
      this.selectedStudent = null
      this.set_buffet_transactions_pagination({ page: 1 })
      this.clear_buffet_transactions_filters()
    },
    selectedStudent(val) {
      if (val) this.fetchPupilBuffetTransactions()
      else this.set_buffet_transactions({})
      this.set_buffet_transactions_pagination({ page: 1 })
      this.clear_buffet_transactions_filters()
    },
    'buffet_transactions_pagination.page'() {
      this.fetchPupilBuffetTransactions()
    },
    buffet_transactions_filters: {
      deep: true,
      handler() {
        this.set_buffet_transactions_pagination({ page: 1 })
        this.fetchPupilBuffetTransactions()
      }
    }
  }
}
</script>

<style scoped>
.choice-select {
  margin: 10px 0px;
}
</style>
