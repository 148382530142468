<script>
import Table from '@/ui-components/Table.vue'
import Modal from '@/ui-components/Modal.vue'
import WithTooltip from '@/components/common/Tooltip/Tooltip.vue'
import { getBoardingSchoolsDepartments } from '@/router/routes/superadmin'
import ModalCreateDepartment from '@/components/moduleadmin/BoardingSchools/Departments/components/ModalCreateDepartment.vue'

export default {
  name: 'BoardingSchoolsDepartmentsView',
  components: { ModalCreateDepartment, WithTooltip, Modal, Table },

  data() {
    return {
      title: 'Список Департаментов Школ-интернат',
      selectedID: null,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Наименование', value: 'short_name' },
        { text: 'Муниципалитет', value: 'parent' },
        { text: 'Школа', value: 'school' },
        { text: 'Операции', value: 'actions', align: 'right' }
      ],
      params: {},
      searchBoardingSchoolField: '',
      getBoardingSchoolsDepartments
    }
  },
  methods: {
    async searchBoardingSchool() {
      this.params.search = this.searchBoardingSchoolField
      this.$refs.departments.resetPagination()
      await this.$refs.departments.requestData()
    },
    async clearSearchField() {
      delete this.params?.search
      await this.$refs.departments.requestData()
    }
  }
}
</script>

<template>
  <v-container fluid class="fs-organizer-container">
    <v-layout class="fs-dish" row mb-4>
      <v-flex md9>
        <h1>{{ title }}</h1>
      </v-flex>
      <v-flex md3 text-md-right>
        <with-tooltip text="Создать новый департамент школы-интернат">
          <v-btn
            flat
            class="fs-btn fs-btn--primary fs-btn__add-dish"
            @click="$refs.addingDepartment.openModal()"
            >Добавить департамент</v-btn
          >
        </with-tooltip>
      </v-flex>
    </v-layout>
    <v-layout>
      <div class="d-flex align-center">
        <v-text-field
          label="Поиск"
          append-icon="search"
          clear-icon="clear"
          clearable
          v-model="searchBoardingSchoolField"
          @keyup.enter="searchBoardingSchool"
          @click:append="searchBoardingSchool"
          @click:clear="clearSearchField"
          hide-details
        ></v-text-field>
      </div>
    </v-layout>
    <v-layout class="fs-dish-result" mb-3>
      <v-flex>
        <Table
          ref="departments"
          :headers="headers"
          :request="getBoardingSchoolsDepartments"
          :params="params"
          requestAfterMount
          showNumbering
        >
          <template v-slot:items="props">
            <td class="px-2">{{ props.item.id }}</td>
            <td class="px-2">
              {{ props.item.short_name || props.item.name || '-' }}
            </td>
            <td class="px-2">
              {{
                props.item.parent.short_name || props.item.parent.name || '-'
              }}
            </td>
            <td class="px-2">
              {{
                props.item.school.short_name || props.item.school.name || '-'
              }}
            </td>
            <td class="justify-end align-center layout px-2" style="gap: 5px">
              <v-icon
                class="fs-active-icon px-2"
                @click="
                  $router.push({
                    name: 'BoardingSchoolsDepartmentItemView',
                    params: { id: props.item.id, meta: 'view' }
                  })
                "
              >
                remove_red_eye
              </v-icon>
            </td>
          </template>
        </Table>
      </v-flex>
    </v-layout>
    <ModalCreateDepartment
      ref="addingDepartment"
      @update-table="() => $refs.departments.requestData()"
    />
  </v-container>
</template>

<style scoped></style>
