<template>
  <v-container>
    <v-layout column mb-3>
      <div class="d-flex mb-3 align-center" style="width: fit-content">
        <h1 class="mb-2">{{ title }}</h1>
        <context-help-modal :context-key="contextKeys.admin.changeMealType"  />
      </div>
       <ChangeMealType/>
    </v-layout>
    </v-container>
</template>

<script>
import Vue from "vue"
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import ChangeMealType from "@/components/admin/ChangeMealType/ChangeMealType.vue";



export default {
  name: "AdminChangeMealType",
  components: {
    ContextHelpModal,
    ChangeMealType,
  },

  data() {
    return {
      title: 'Смена типов питания',
      contextKeys: Vue.config.contextHelp,
      showModal: true,
    }
  }
}

</script>
