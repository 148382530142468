import localStorageHelpers from '@/helpers/localStorage'
import _ from 'lodash'
import { login, signin, getUserProfile } from '@/router/routes/common'
import { getAllStudents } from '@/router/routes/parent'
import { getChildStudent } from "@/router/routes/pupil"
import router from '@/router'
import { getRole } from '@/utils/roles'

export default {
  GET_USER_PROFILE({ commit }) {
    return getUserProfile().then(r => {
      commit('setCurrentUser', r.data.data)
    })
  },

  LOGIN({ commit, state }, payload) {
    return login({
        ...payload,
        role: payload.role || state.users_store.active_role?.name,
        sm_roles: state.users_store.available_roles
    })
      .then(response => {
        const user = response.data
        commit('setCurrentUser', user)
        localStorageHelpers.__setToken(user.token)
        return getAllStudents()
      })
      .then(response => {
        if (!payload.child_id && response.data.data.length > 1) {
          router.push({ name: 'PupilSelectionList', params: { list: response.data.data } })
          return false
        }

        const currentChild = (() => {
          const { data } = response.data

          if (data && data.length === 1) {
            return data.pop()
          }
          else {
            return _.find(data, child => {
              return child.personId === payload.child_id
            })
          }
        })()

        commit('setCurrentChild', currentChild)
        return true
      })
  },

  LOGIN_CHILD({ commit, state }, payload) {
    return login({
        ...payload,
        role: payload.role || state.users_store.active_role?.name,
        sm_roles: state.users_store.available_roles
    })
      .then(response => {
        const user = response.data
        commit('setCurrentUser', user)
        localStorageHelpers.__setToken(user.token)
        return getChildStudent()
      }).then(response => {
        commit('setCurrentChild', response.data.data)
      })
  },


  ADMIN_LOGIN({ commit, state }, payload) {
    return login({
        ...payload,
        role: payload.role || state.users_store.active_role?.name,
        sm_roles: state.users_store.available_roles
    }).then(response => {
      const user = response.data
      commit('setCurrentUser', user)
      localStorageHelpers.__setToken(user.token)
    })
  },

  SIGNIN({ commit, state }, payload) {
    return signin(payload)
      .then(response => {
        const user = response.data
        commit('setCurrentUser', user)
        localStorageHelpers.__setToken(user.token)

        if (user.hasOwnProperty('children')) {
          return getAllStudents()
        }
      })
      .then(response => {
        if (response) {
          const currentChild = _.find(response.data.data, item => {
            return item.personId == payload.child_id
          })
          commit('setCurrentChild', currentChild)
        }
      })
  }
}
