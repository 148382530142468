export default {
  setDishes(state, dishes) {
    state.dishes = dishes
  },

  setYourSchoolchildren(state, data) {
    state.yourSchoolchildren = data
  },

  updateYourSchoolchildrenBalance(state, data) {
    Object.entries(data).forEach(([k, v]) => {
      const findIndex = state.yourSchoolchildren.findIndex(
        (item) => item.id === v?.id
      )
      if (findIndex !== -1) {
        state.yourSchoolchildren[findIndex].balance = v.balance
      }
    })
  }
}
