<template>
  <v-dialog v-model="show" :max-width="width" persistent>
    <v-container :class="className" md6>
      <v-layout class="fs-complex" row mb-3>
        <v-flex mb-3>
          <h1>{{ title }}</h1>
        </v-flex>
      </v-layout>
      <v-layout class="fs-dish-result" mb-3>
        <v-flex>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="foodTypes"
            class="elevation-1 fs-admin-table"
            item-key="id"
            select-all
            hide-actions
          >
            <template v-slot:items="props">
              <tr>
                <td>
                  <v-checkbox
                    v-model="props.selected"
                    class="fs-checkbox"
                    color="#66717f"
                    primary
                    hide-details
                  ></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                    v-model="props.item.selectedDry"
                    class="fs-checkbox"
                    color="#66717f"
                    primary
                    hide-details
                  ></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                    v-model="props.item.selectedSubsidy"
                    class="fs-checkbox"
                    color="#66717f"
                    primary
                    hide-details
                  ></v-checkbox>
                </td>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.privilege ? props.item.privilege.name : null }}</td>
                <td>{{ props.item.price.priceToString() }}</td>
                <td>{{ getTranslationFeedingPeriods(props.item.foodPeriods) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-flex text-md-right mt-3 mr-2>
        <v-btn class="fs-btn fs-btn--secondary" flat @click="show = false">Закрыть</v-btn>
        <v-btn class="fs-btn fs-btn--primary fs-form-footer__btn" flat @click="save">Сохранить</v-btn>
      </v-flex>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AssignDepartmentsModal',
  data() {
    return {
      title: 'Назначить типы питания',
      selected: [],
      headers: [
        { text: 'Сух. паек', value: "selectedDry", sortable: false },
        { text: 'Субсидия', value: "selectedSubsidy", sortable: false },
        { text: "№ п/п", value: "id" },
        { text: "Наименование", value: "name" },
        { text: "Льгота", value: "privilegeName" },
        { text: 'Стоимость', value: 'price' },
        { text: 'Периоды кормления', value: 'feeding-periods', sortable: false }
      ],
      ids: []
    }
  },
  computed: {
    ...mapGetters([ 'foodPeriods' ]),
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    save() {
      this.$emit('assignFoodTypes', this.getFoodTypes(), this.ids)
      this.show = false
    },
    getFoodTypes() {
      const foodTypes = this.foodTypes
      for (let i = 0, l = foodTypes.length; i < l; i++) {
        foodTypes[i].selected = this.ids.includes(foodTypes[i].id)
      }

      return foodTypes
    },
    getTranslationFeedingPeriods(arr) {
      let translated = ''
      arr.forEach((v, i) => {
        const feedingPeriod = this.foodPeriods.find(item => item.alias === v)
        if (feedingPeriod) {
          translated += (i ? ', ' : '') + feedingPeriod.name
        }
      })

      return translated
    }
  },
  props: {
    value: {
      required: true,
      type: Boolean
    },
    width: {
      default: 800
    },
    foodTypes: {
      type: Array
    },
    className: {
      default: 'fs-container'
    }
  },
  watch: {
    foodTypes: {
      immediate: true,
      deep: true,
      handler(v) {
        for (let i = 0, l = v.length; i < l; i++) {
          if (v[i].selected) {
            this.selected.push(v[i])
          }
        }
      }
    },
    selected: {
      deep: true,
      handler(v) {
        this.ids = []
        for (let i = 0, l = v.length; i < l; i++) {
          this.ids.push(v[i].id)
        }
      }
    }
  }
}
</script>
