<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <v-layout
      class="fs-teacher-order"
      row
      mb-3
    >
      <div class="md4 mb-3 d-flex">
        <h1>{{ title }}</h1>
        <context-help-modal :context-key="contextKeys.admin.reports" />
      </div>
    </v-layout>
    <v-layout
      row
      wrap
      class="fs-header-filter"
    >

    </v-layout>
    <v-layout
      class="fs-teacher-order-result"
      mb-3
      justify-space-around
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1"
          item-key="name"

          :outline="darkTheme"
          :class="{ 'fs-teacher-table': !darkTheme }"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.name  }} {{props.item.formId? `(Форма ${props.item.formId})` : null}}</td>
              <td>
                <v-flex class="pa-2">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="showReportModal(props.item)"
                      >print</v-icon>
                    </template>
                    <span>Печать</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <ConfirmModal
      :dialog.sync="showModal"
      :startPrint.sync="showPrintLoad"
      :dateStart.sync="dateStart"
      :dateEnd.sync="dateEnd"
      :width="700"
      @confirm="handlerModal"
    >
      <template v-slot:headline>Печать отчёта</template>
      <template v-slot:body>
        Название отчёта:
        <h3>{{reportPrintTitle}}</h3>
        <v-flex
          md4
          class="mt-3"
        >
          <div v-if="datePickers === 1">
            <DatePickersSmart
              :startDate.sync="dateStart"
              :onePicker="true"
              @changeDate="changeDate"
            />
          </div>
          <div v-else-if="datePickers === 2">
            <DatePickersSmart
              :startDate.sync="dateStart"
              :endDate.sync="dateEnd"
              @changeDate="changeDate"
            />
          </div>
          <div v-if="yearSelector">
            <v-text-field
              :outline="darkTheme"
              v-model="selectedYear"
            />
          </div>
        </v-flex>
        <v-flex>
          <div v-if="schoolChoice">
            <v-select
              clearable
              :items="schools"
              item-text="name"
              item-value="id"
              return-object
              v-model="school"

              :label="!darkTheme ? 'Школы' : ''"
              :placeholder="darkTheme ? 'Школы' : ''"
              :outline="darkTheme"
            ></v-select>
          </div>
        </v-flex>
        <v-flex
          md6
          v-if="classesSelector"
        >
          <v-select
            v-model="selectedClasses"
            :items="classesArray"
            class="fs-filter"
            item-text="name"
            item-value="id"
            attach
            chips
            deletable-chips
            multiple

            :label="!darkTheme ? 'Класс:' : ''"
            :placeholder="darkTheme ? 'Класс' : ''"
            :outline="darkTheme"
            :class="{ 'fs-filter': !darkTheme }"
          >
            <template v-slot:prepend-item>
              <v-list-tile
                ripple
                @click="selectAllClasses()"
              >
                <v-list-tile-action>
                  <v-icon :color="selectedClasses.length > 0 ? 'primary' : ''">{{selectedClasses.length === classesArray.length ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>Выбрать всё</v-list-tile-title>
                  <v-list-tile-sub-title>Выбрано: {{selectedClasses.length}}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-select>
        </v-flex>
      </template>
    </ConfirmModal>


  </v-container>
</template>

<script>
import moment from 'moment'
import {
  getAllReports,
  reportFileTaskId,
  reportFormFileTaskId,
  reportFileStatus,
  reportPrintFile,
  getClasses,
  getSchools
} from '@/router/routes/admin'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
// import _ from 'lodash'
import { ConfirmModal, DatePickersSmart } from '@/components/common'
import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownloadWithProgressBar'
import DishView from "@/components/admin/Dish/Edit.vue"
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ReportsIndex',
  components: {
    ContextHelpModal,
    DishView,
    ConfirmModal,
    DatePickersSmart,
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      darkTheme: Vue.config.env.darkTheme,
      server: Vue.config.server,
      title: 'Отчёты',
      datepickerMenu: false,
      dateStart: '',
      dateEnd: '',
      selectedYear: null,
      headers: [
        { text: 'Отчёт', value: 'name', width: '90%' },
        { text: 'Операции', value: 'operations' }
      ],
      items: [],
      showModal: false,
      showPrintLoad: false,

      reportPrintTitle: '',
      datePickers: null,
      datePickerRange: '',
      currentItem: null,
      yearSelector: null,

      classesSelector: false,
      classesArray: [],
      selectedClasses: [],
      schoolChoice: false,

      schoolId: null,

      schools: [],
      school: ''
    }
  },
  computed: {
    ...mapGetters([ 'currentUser', 'foodBlockPassport' ]),
  },
  created() {
    this.init()
    this.getSchools()
  },
  methods: {
    ...mapActions([ 'getFoodBlockPassports' ]),
    ...mapMutations([ 'setShowPreloader' ]),
    async getSchools() {
      try {
        const PAYLOAD = {
          limit: this.LIMIT,
          offset: this.offset
        }
        let response = null
        response = await getSchools(PAYLOAD)

        const { data: { data: items } } = response
        const itemsArray = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
        this.schools = [ ...this.schools, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        this.$store.commit('setShowPreloader', false)
      }
    },
    getClassLiter(name) {
      return name.split(' ')[1]
    },
    getClassNumber(name) {
      return name.split(' ')[0]
    },

    async init() {
      await this.loadReports()
      await this.loadClasses()
    },

    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
    },
    setDatepickersValue(count, range, yearSelector) {
      const defaultRange = 'month'
      if (count === 2) {
        this.dateStart = moment()
          .add(defaultRange, -1)
          .format(Vue.config.format.serverDate)
        this.dateEnd = moment()
          .add('days', 1)
          .format(Vue.config.format.serverDate)
      }
      if (count === 1) {
        this.dateStart = moment().format(Vue.config.format.serverDate)
        this.dateEnd = ''
      }
      if (!count) {
        this.dateStart = ''
        this.dateEnd = ''
      }
      if (yearSelector) {
        this.selectedYear = moment().format('YYYY')
      }
    },

    showReportModal(item) {
      this.currentItem = item
      this.showModal = true
      this.datePickers = item.datePickers
      this.yearSelector = item.yearSelector
      this.schoolChoice = item.schoolChoice
      const countDatepickers = item.datePickers
      const rangeType = item.rangeType
      this.setDatepickersValue(countDatepickers, rangeType, item.yearSelector)
      this.classesSelector = item.classChoice
      this.selectedClasses = []
      this.reportPrintTitle =
        item.name + (item.form_id ? ` (Форма ${item.form_id})` : '')
    },
    selectAllClasses() {
      this.$nextTick(() => {
        if (this.selectedClasses.length) {
          this.selectedClasses = []
          return
        }
        const idsArray = this.classesArray.map(item => item.id)
        this.selectedClasses = [ ...idsArray ]
      })
    },
    async handlerModal(value) {
      this.modalResponse = value
      if (this.modalResponse) {
        this.showPrintLoad = true
        await this.printFile()
      }
    },

    async printFile() {
      const { id = null, formId = null } = this.currentItem
      if (id === "passport") await this.getFoodBlockPassports(this.school.id)
      const getTaskId = formId ? reportFormFileTaskId : reportFileTaskId
      const PAYLOAD = {
        school_id: this.currentUser.school.id,
        id,
        data: {
          start_date: this.dateStart,
          end_date: this.dateEnd,
          schoolClassIds: this.selectedClasses || null,
          year: this.selectedYear || null,
          school_pk: this.school && this.school.id ? this.school.id : null
        },
        report_id: id,
        form_id: formId
      }
      const callback = this.resetPageFileLoaded
      try {
        await asyncReqestsForDownload(
          getTaskId,
          reportFileStatus,
          reportPrintFile,
          PAYLOAD,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки файла') //pass!
      } finally {
        this.resetPageFileLoaded()
      }
    },
    changeDate(payload) {},
    changeDateFromModal(payload) {
      if (payload.name === 'startDate') {
        this.dateStart = payload.value
      } else if (payload.name === 'endDate') {
        this.dateEnd = payload.value
      }
    },
    changeSchoolFilter(schoolId) {
      this.schoolId = schoolId
    },
    async loadClasses() {
      const payload = {
        schoolId: this.currentUser.school.id,
        params: {
          limit: Vue.config.general.classLimit,
          offset: 0
        }
      }
      try {
        const response = await getClasses(payload)
        const {
          data: { data: items }
        } = response

        const itemsArray = items.map(item => {
          const { id, name, number } = item
          return {
            id: id,
            name: name,
            number: number
          }
        })
        this.classesArray = [ ...this.classesArray, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async loadReports() {
      const PAYLOAD = {
        school_id: this.currentUser.school.id
      }
      try {
        const response = await getAllReports(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const {
            id = null,
            name = '',
            formId = '',
            rangeType = '',
            rangeAmmount = null,
            datePickers = null,
            classChoice = null,
            schoolChoice = null,
            yearSelector = null,
            is_another_url = false
          } = item
          return {
            id,
            name,
            formId,
            datePickers,
            rangeType,
            rangeAmmount,
            classChoice,
            schoolChoice,
            yearSelector,
            is_another_url
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        console.error('LOG: loadReports -> error', error)
        notify.error('Ошибка загруки отчётов') //pass!
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>
