<template>
  <div>
    <v-layout
      class="fs-archive-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          no-data-text="Транзакции отсутсвуют"
          hide-actions
          :disable-initial-sort="true"
          class="elevation-1"
          item-key="data"
        >
          <template v-slot:items="props">
            <tr>
                <td>{{ props.item.name }}</td>
                <td>{{ (props.item.price / 100).toFixed(2) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-pagination
      v-if="Math.ceil(items_count / paginate.page_size) > 1"
      v-model="paginate.page"
      @input="fetchProducts"
      total-visible="7"
      :length="Math.ceil(items_count / paginate.page_size)"
    ></v-pagination>
  </div>
</template>

<script>
import { getBuffetProducts } from '@/router/routes/organizer.js'
import { mapMutations } from 'vuex'
export default {
  name: 'BuffetProducts',
  data: () => ({
    headers: [
      { text: 'Наименование', value: 'name' },
      { text: 'Стоимость', value: 'price' },
    ],
    items: [],
    items_count: 0,
    paginate: {
      page: 1,
      page_size: 10
    }
  }),
  mounted() {
    this.fetchProducts()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    fetchProducts() {
      getBuffetProducts({ params: { ...this.paginate, ...this.filters } })
        .then(res => {
          this.items = res.data.results
          this.items_count = res.data.count
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    }
  }
}
</script>

<style>

</style>
