<template>
  <v-dialog v-model="showModal" :persistent="true" :width="1000">
    <v-card>
      <v-card-title>
        <h3>Выбрано учеников: {{ pupils.length }}</h3>
      </v-card-title>
      <v-card-text> Подтвердить восстановление типа питания? </v-card-text>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn color="error" @click="close"> Отмена </v-btn>
        <v-btn color="success" @click="save"> Восстановить </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { resetMealTypes } from '@/router/routes/superadmin'
import store from '@/store'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'SuperadminDialogResetMealType',

  props: {
    pupils: {
      type: Array
    },
    showModal: {
      type: Boolean
    }
  },

  data() {
    return {
      availableMealTypes: []
    }
  },
  
  methods: {
    close() {
      this.$emit('closeModal')
    },

    async save() {
      const payload = {
        pupil_ids: this.pupils.map((item) => item.id)
      }
      try {
        const { data } = await resetMealTypes(payload)
        this.$emit('updatePupil', data)
        notify.success('Типы питания восстановлены')
      } catch (err) {
      } finally {
        store.commit('setShowPreloader', false)
        this.close()
      }
    }
  }
}
</script>

<style scoped></style>
