<template>
  <v-container fluid class="fs-teacher-container">
    <v-layout class="fs-complex" row mb-3>
      <v-flex md4 mb-3>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout class="fs-teacher-order-result" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1 fs-admin-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.name }}</td>
              <td>{{ convertToRubles(props.item.price) }}</td>
              <td>
                <v-icon :style="props.item.isLongDay ? 'color: green' : 'color: red' ">
                  {{ props.item.isLongDay ? "done" : "close" }}
                </v-icon>
              </td>
              <td>{{ props.item.concession.name }}</td>
              <td>{{ getFoodPeriodsNames(props.item.foodPeriods) }}</td>
              <td>
                <v-icon :style="props.item.isHotFood ? 'color: green' : 'color: red' ">
                  {{ props.item.isHotFood ? "done" : "close" }}
                </v-icon>
              </td>
              <td>
                <v-icon :style="props.item.isDryFood ? 'color: green' : 'color: red' ">
                  {{ props.item.isDryFood ? "done" : "close" }}
                </v-icon>
              </td>
              <td>
                <v-icon :style="props.item.isSubsidy ? 'color: green' : 'color: red' ">
                  {{ props.item.isSubsidy ? "done" : "close" }}
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex text-md-right pt-3>
        <v-btn
          flat
          class="fs-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active"
          @click="$router.go(-1)"
        >Назад</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from "vue"
import store from "@/store"
import { mapGetters, mapMutations } from "vuex"
import _ from "lodash"
import numable from "@/mixins/balancable"
import scrollable from "@/mixins/scrollable"
import { getFoodTypes } from "@/router/routes/admin"

export default {
  name: "AdminFoodTypes",
  mixins: [ numable, scrollable ],
  created() {
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },
  data() {
    return {
      limit: Vue.config.general.limit,
      title: "Справочник «Типы питания»",
      headers: [
        { text: '№ п/п', value: 'id' },
        { text: 'Наименование', value: 'name' },
        { text: 'Общая стоимость', value: 'price' },
        { text: 'ГПД', value: 'isLongDay' },
        { text: 'Социальная выплата', value: 'concession.name' },
        { text: 'Периоды кормления', value: 'foodPeriods' },
        { text: 'Признак "Горячее питание"', value: 'isHot' },
        { text: 'Сух. паек', value: 'isDryFood' },
        { text: 'Субсидия', value: 'isSubsidy' },
      ],
      items: [],
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10)
    }
  },
  computed: {
    ...mapGetters([ "currentUser", "foodPeriods" ])
  },
  methods: {
    ...mapMutations([
      "setShowPreloader",
    ]),
    async loadMore() {
      const payload = {
        schoolId: this.currentUser.school.id,
        params: {
          limit: this.limit,
          offset: this.offset
        }
      }

      getFoodTypes(payload).then(response => {
        const items = _.map(response.data.data, item => {
          return {
            id: item.id,
            name: item.name,
            price: item.price,
            isLongDay: item.isExtendedDayGroup,
            concession: item.privilege,
            foodPeriods: item.foodPeriods,
            isHotFood: item.isHot,
            isDryFood: item.isDryFood,
            isSubsidy: item.isSubsidy
          }
        })
        this.items = this.items.concat(items)
      }).finally(() => store.commit('setShowPreloader', false))
    },
    getConcessionsNames(concessions) {
      // @TODO метод оставлю, но я так понимаю их там дохуищща может быть
      const names = _.map(concessions, item => {
        return _.get(item, "name", "")
      })
      return names.sort().join(", ")
    },
    getFoodPeriodsNames(foodPeriodsAliases) {
      const currentFoodPeriods = _.filter(this.foodPeriods, item =>
        _.includes(foodPeriodsAliases, item.alias)
      )
      const names = _.map(currentFoodPeriods, item => {
        return _.get(item, "name", "")
      })
      return names.sort().join(", ")
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
