<template>
  <v-container
    fluid
    class="fs-admin-container"
  >
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <div class="mb-3 d-flex">
        <h1>{{ title }}</h1>
        <context-help-modal :context-key="contextKeys.admin.classes.edit" />
      </div>
    </v-layout>
    <v-layout justify-space-around>
      <v-flex md10>
        <v-layout>
          <v-layout
            row
            justify-start
            mb-4
            class="fs-header-filter"
          >
            <v-flex
              md6
              class="fs-admin__classname"
            >
              <v-flex
                text-md-left
                class="fs-admin__classname"
              >
                <span class="fs-student-info__key v-label">Класс:</span>
                <span class="fs-student-info__value">{{item.name}}</span>
              </v-flex>
              <v-flex
                text-md-left
                class="fs-admin__classname"
              >
                <span class="fs-student-info__key v-label">Корпус:</span>
                <span class="fs-student-info__value">{{item.house || 'отсутствует'}}</span>
              </v-flex>
            </v-flex>
            <v-flex md6>
              <v-flex
                text-md-left
                class="fs-admin__classname"
              >
                <span class="fs-student-info__key v-label">Смена:</span>
                <span class="fs-student-info__value">{{item.shift || 'отсутствует'}}</span>
              </v-flex>
              <v-flex
                text-md-left
                class="fs-admin__classname"
              >
                <span class="fs-student-info__key v-label">Классный руководитель:</span>
                <span class="fs-student-info__value">{{ item.teacher }}</span>
              </v-flex>
            </v-flex>
            <!--v-flex md3>
          <v-flex text-md-left pt-4>
            <v-btn flat class="fs-btn fs-btn--secondary fs-teacher-btn__show v-btn--active">показать</v-btn>
          </v-flex>
            </v-flex-->
            <v-flex
              text-md-right
              md6
            >
	            <with-tooltip text="Вернуться на предыдущую страницу">
	              <v-btn
	                flat
	                class="fs-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active"
	                @click="$router.go(-1)"
	              >Назад</v-btn>
	            </with-tooltip>
	            <with-tooltip text="Сохранить изменения">
	              <v-btn
	                v-if="!isViewComponent"
	                flat
	                class="fs-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active"
	                @click="save"
	              >Сохранить</v-btn>
	            </with-tooltip>
            </v-flex>
          </v-layout>
          <!-- <v-layout class="fs-form-header">
            <v-flex
              text-md-right
              pt-3
            >
              <v-btn
                flat
                class="fs-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active"
                @click="$router.go(-1)"
              >Назад</v-btn>
              <v-btn
                v-if="!isViewComponent"
                flat
                class="fs-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active"
                @click="save"
              >Сохранить</v-btn>
            </v-flex>
          </v-layout> -->
        </v-layout>
        <v-layout class="fs-admin-operation-panel">
          <v-flex
            md12
            text-md-left
          >
            <span>Настройка времени кормления</span>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!isViewComponent"
          class="fs-admin-archive-result"
          mb-3
          justify-space-around
        >
          <v-flex
            md2
            text-md-left
          >
            <v-select
              class='fs-student-info__select'
              :value="currentShift"
              @change="changeShift"
              :items="shifts"
              item-text="name"
              item-value="id"
              :aria-hidden="true"
              :readonly="isViewComponent"
            ></v-select>
            <v-select
              class='fs-student-info__select'
              :value="currentPeriod"
              @change="changePeriod"
              :items="foodPeriods"
              item-text="name"
              item-value="id"
              :aria-hidden="true"
              :readonly="isViewComponent"
            ></v-select>
            <v-select
              class='fs-student-info__select'
              :value="currentTime"
              @change="changeTime"
              :items="foodTimes"
              item-text="name"
              item-value="id"
              :aria-hidden="true"
              :readonly="isViewComponent"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout
          class="fs-teacher-order-result"
          mb-3
        >
          <v-flex>
            <v-data-table
              :headers="headers"
              :items="items"
              hide-actions
              class="elevation-1 fs-admin-table"
              item-key="name"
            >
              <template v-slot:items="props">
                <tr :style="{ textDecoration: toDelete.includes(props.item.pk) ? 'line-through' : '' }">
                  <td>{{ props.item.foodPeriod }}</td>
                  <td>{{ props.item.foodTime }}</td>
                  <td>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-if="!toDelete.includes(props.item.pk)" v-on="on">
                          <v-icon class="fs-active-icon" @click="deleteItem(props.item.pk)">delete</v-icon>
                        </span>
                      </template>
                      <span>Удалить</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getOneClass, setFoodTime } from '@/router/routes/admin'
import store from '@/store'
import _ from 'lodash'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import AdminClassFactory from '@/factories/admin/class-factory'
import {
  getShifts,
  getFoodTimes,
  getFoodPeriods
} from '../../../router/routes/common'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'
import WithTooltip from "@/components/common/Tooltip/Tooltip.vue";

export default {
  name: 'AdminClassesItem',
  components: {WithTooltip, ContextHelpModal },
  created() {
    getShifts().then(response => {
      this.shifts = response.data.data
    })
    getFoodTimes().then(response => {
      this.foodTimes = response.data.data
    })
    getFoodPeriods().then(response => {
      this.foodPeriods = response.data.data
    })
    this.getOneClass()
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      item: {},
      datepickerMenu: false,
      date: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      shifts: [],
      foodTimes: [],
      foodPeriods: [],
      currentTime: 1,
      currentPeriod: 1,
      currentShift: 1,
      shift: null,
      headers: [
        { text: 'Период кормления', value: 'foodPeriod' },
        { text: 'Время кормления', value: 'foodTime' },
        { text: 'Действия', values: 'actions' }
      ],
      items: [],
      toDelete: [],
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
    title() {
      const titleLastPart =
        this.$route.meta === 'view'
          ? 'просмотр информации'
          : 'редактирование информации'
      return 'Класс ' + this.item.name + `: ${titleLastPart}`
    },
    isViewComponent() {
      return this.$route.meta === 'view'
    }
  },
  methods: {
    deleteItem(pk) {
      this.toDelete.push(pk)
    },
    getOneClass() {
      const payload = {
        schoolId: this.currentUser.school.id,
        id: this.$route.params.id
      }
      getOneClass(payload)
        .then(response => {
          const currentClass = response.data.data
          this.item = {
            id: currentClass.id,
            name: currentClass.name,
            teacher: _.get(currentClass, 'teacher', null),
            shift: currentClass.shift,
	          house: _.get(currentClass, 'house', null),
          }
          this.currentShift = currentClass.shift || this.currentShift
          this.items = currentClass.eat.map(item => ({
            foodTime: this.foodTimes.filter(
              time => time.id === item.foodTime
            )[0].name,
            foodPeriod: this.foodPeriods.filter(
              period => period.id === item.foodPeriod
            )[0].name,
            pk: item.id
          }))
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    save() {
      const PAYLOAD = {
        schoolId: this.currentUser.school.id,
        id: this.$route.params.id,
        shift: this.currentShift,
        eat: [ { foodTime: this.currentTime, foodPeriod: this.currentPeriod } ],
        delete_old_eat: this.toDelete
      }
      setFoodTime(PAYLOAD)
        .then(result => {
          if (!result.data.success) {
            // notify.error('Ошибка при сохранении') // backend handler added (exception_message)
            return
          }
          notify.success('Смена и период кормления успешно сохранены')
          this.toDelete = []
          this.getOneClass()
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    changeTime(value) {
      this.currentTime = value
    },
    changeShift(value) {
      this.currentShift = value
    },
    changePeriod(value) {
      this.currentPeriod = value
    }
  }
}
</script>
<style scoped>
.fs-student-info__select >>> .v-select__slot .v-select__selections input {
    display: none !important;
}
</style>
