<template>
  <v-card flat>
    <v-card-text v-for="(item, idx) in form.questions" :key="idx">
      <v-checkbox
        v-if="item.type !== 'text'"
        v-model="item.value"
        :label="`${item.title}: ${item.value ? 'Есть': 'Нет'}`"
      />
      <v-text-field
        v-else
        v-model="item.value"
        :label="!darkTheme ? item.title : ''"
        :placeholder="darkTheme ? item.title : ''"
        :outline="darkTheme"
        :hide-details="darkTheme"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        flat
        @click="update"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from "@/store"
export default {
  name: 'Form8',
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      form: {
        id: null,
        questions: [
          { title: 'Столовая, работающая на сырье', name: 'availability', value: false },
          { title: 'Название организации', name: 'name', value: "", type: "text" },
          { title: 'Адрес организации', name: 'address', value: "", type: "text" },
          { title: 'Санитарно-эпидемиологическое заключение', name: 'conclusion', value: false },
        ]
      }
    }
  },
  computed: {
    ...mapGetters([ 'foodBlockPassport' ])
  },
  mounted() {
    this.getFoodblockOrganizations(this.foodBlockPassport.external_organization_sections.id)
      .then(result => this.setForm(result))
  },
  methods: {
    ...mapMutations([ 'setShowPreloader', 'setShowPreloader', 'updateFoodBlocknames' ]),
    ...mapActions([ 'getFoodblockOrganizations', 'updateFoodBlockOrganizations' ]),
    prepareObjectForApi() {
      const res = {}
      this.form.questions.forEach(item => {
        res[item.name] = item.value
      })
      res["passport"] = this.foodBlockPassport.id
      return res
    },
    setForm(data) {
      this.form.id = data.id
      this.form.passport = data.passport
      this.form.questions.forEach(item => {
        for (const key in data) {
          if (item.name === key) item.value = data[key]
        }
      })
    },
    update() {
      const requestBody = this.prepareObjectForApi()
      this.updateFoodBlockOrganizations({ id: this.form.id, data: requestBody })
        .then(result => this.setForm(result))
    }
  },

}
</script>
