<template>
  <v-container class="fs-teacher-container" fluid>
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <v-flex mb-3>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout pa-0>
      <v-btn
        flat
        @click="$router.go(-1)"
        class="ma-0 mb-3 fs-btn fs-teacher-btn__show v-btn--active"
      >Назад</v-btn>
    </v-layout>
    <v-layout
      row
      mb-12
      justify-start
      text-md-center
    >
      <v-flex md3>
        <v-autocomplete
          :value="selectedSchoolID"
          :items="schools"
          item-text="name"
          item-value="id"
          @change="changeValue('selectedSchoolID', $event)"
          :class="{ 'fs-admin-complex-autocomplete': !darkTheme }"
          :label="!darkTheme ? 'Школа:' : ''"
          :placeholder="darkTheme ? 'Школа:' : ''"
          :outline="darkTheme"
          :disabled="everyone"
        />
        <v-autocomplete
          :disabled="!selectedSchoolID || everyone"
          :value="selectedClassID"
          @change="changeValue('selectedClassID', $event)"
          :items="classes"
          item-text="name"
          item-value="id"

          :class="{ 'fs-admin-complex-autocomplete': !darkTheme }"
          :label="!darkTheme ? 'Класс:' : ''"
          :placeholder="darkTheme ? 'Класс:' : ''"
          :outline="darkTheme"
        />
        <v-select
          :disabled="!selectedClassID || everyone"
          no-data-text="Ничего не выбрано"
          v-model="selectedStudentsIds"
          item-text="name"
          item-value="id"
          :items="students"
          deletable-chips
          chips
          multiple
          class=""

          :label="!darkTheme ? 'Ученики:' : ''"
          :placeholder="darkTheme ? 'Ученики:' : ''"
          :outline="darkTheme"
        >
          <template v-slot:prepend-item>
            <v-list-tile
              ripple
              @click="selectAll('selectedStudentsIds', 'students')"
            >
              <v-list-tile-action>
                <v-icon :color="selectedStudentsIds.length > 0 ? 'primary' : ''">{{selectedStudentsIds.length ===  students.length ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Выбрать всё</v-list-tile-title>
                <v-list-tile-sub-title>Выбрано: {{selectedStudentsIds.length}}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
      </v-flex>
      <v-flex md6></v-flex>
    </v-layout>
    <v-layout>
      <v-flex md4>
        <v-form
          v-model="formValid"
          ref="form"
          lazy-validation
        >

          <v-text-field
            class="fs-textbox"
            multi-line
            label="Сообщение"
            box
            :value="message"
            @input="setValue('message', $event)"
            :rules="[formRules.requiredTextfield]"
          />


          <v-checkbox
            v-if="false"
            class="fs-checkbox"
            :input-value="everyone"
            :label="`Отправить всем`"
            @change="setValue('everyone',$event)"
          />
          <v-btn
            color="error"
            class="fs-btn fs-teacher-btn__show v-btn--active ma-0"
            @click="reset"
          >
            Очистить
          </v-btn>
          <v-btn
            :disabled="!checkFormValid"
            flat
            class="fs-btn fs-teacher-btn__show v-btn--active ma-0"
            @click="createNotification"
          >Создать</v-btn>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import rules from '@/helpers/rules'
import { mapMutations, mapGetters } from 'vuex'
import { setNotification, setNotificationEveryone } from '@/router/routes/admin'
import { getClasses, getFilteredStudents } from '@/router/routes/admin'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'NotificationCreateAdmin',
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      title: 'Создать уведомление',
      formValid: true,
      formRules: rules,
      message: '',
      isDraft: false,
      everyone: false,
      /* */
      schools: [],
      selectedSchoolID: null,
      classes: [],
      selectedClassID: null,
      students: [],
      /* */
      selectedStudentsIds: []
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
    checkFormValid() {
      return this.formValid && this.message.length != 0
    },
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    getClassLiter(name) {
      return name.split(' ')[1]
    },
    getClassNumber(name) {
      return name.split(' ')[0]
    },
    async init() {
      this.schools = [ this.currentUser.school ]
      this.selectedSchoolID = this.currentUser.school.id
      await this.getClasses()
    },
    selectAll(selectArray, parentArray) {
      this.$nextTick(() => {
        if (this[selectArray].length) {
          this[selectArray] = []
        } else {
          const idsArray = this[parentArray].map(item => item.id)
          this[selectArray] = [ ...idsArray ]
        }
      })
    },
    async changeValue(type, value) {
      this[type] = value
      if (type === 'selectedSchoolID') {
        this.classes = []
        this.selectedClassID = null
        await this.getClasses()
      }
      if (type === 'selectedClassID') {
        this.students = []
        await this.getStudents()
      }
    },
    async getClasses() {
      try {
        const PAYLOAD = {
          limit: Vue.config.general.classLimit,
          offset: this.offset,
          schoolId: this.selectedSchoolID
        }
        const response = await getClasses(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
        this.classes = [ ...this.classes, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getStudents() {
      try {
        const PAYLOAD = {
          schoolId: this.selectedSchoolID,
          classId: this.selectedClassID,
          params: {
            limit: Vue.config.general.limit,
            offset: this.offset
          }
        }
        const response = await getFilteredStudents(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, fullName } = item
          return {
            id: id,
            name: fullName
          }
        })
        this.students = [ ...this.students, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки учеников') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    setValue(valueLabel, value) {
      if (value && valueLabel == 'everyone') {
        this.selectedSchoolID = null
        this.selectedClassID = null
        this.selectedStudentsIds = []
      }
      this[valueLabel] = value
    },
    reset() {
      this.message = ''
      this.isDraft = false
      this.selectedSchoolID = null
      this.selectedClassID = null
      this.students = []
      this.setValue('everyone', false)
    },
    fuckXSS(value) {
      return value
        .trim()
        .replace(/\&/g, '&amp;')
        .replace(/\</g, '&lt;')
        .replace(/\>/g, '&gt;')
        .replace(/\"/g, '&quot;')
        .replace(/\'/g, '&#x27')
        .replace(/\//g, '&#x2F')
    },
    async createNotification() {
      const messageSave = this.fuckXSS(this.message)
      const reuestFunction = this.everyone
        ? setNotificationEveryone
        : setNotification
      try {
        const PAYLOAD = {
          data: {
            message: messageSave,
            isDraft: this.isDraft
          }
        }
        if (this.selectedSchoolID && !this.selectedClassID) {
          PAYLOAD.data.schools = [ this.selectedSchoolID ]
        }
        if (this.selectedClassID && !this.selectedStudentsIds.length) {
          PAYLOAD.data.classes = [ this.selectedClassID ]
        }
        if (this.selectedStudentsIds.length) {
          PAYLOAD.data.pupils = [ ...this.selectedStudentsIds ]
        }
        await reuestFunction(PAYLOAD)
        notify.success('Уведомление успешно создано')
      } catch (error) {
        // notify.error('Ошибка создания уведомления') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>
