<template>
  <v-container fluid class="fs-teacher-container">
    <v-layout row wrap>
      <v-layout class="fs-dish" row>
        <div class="mb-2 pl-2">
          <div class="d-flex row">
            <h1>{{ title }}</h1>
            <context-help-modal :context-key="contextKeys.admin.dashboard.main"/>
          </div>
        </div>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex xs6 md3 class="px-2 pb-2">
              <mini-card :value="miniCardData.pupil_order" title="Заказов сегодня"/>
            </v-flex>
            <v-flex xs6 md3 class="px-2 pb-2">
              <mini-card :value="miniCardData.pupil_count" title="Всего учеников"/>
            </v-flex>
            <v-flex xs6 md3 class="px-2 pb-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <mini-card
                        :class="miniCardData.pupil_expired_privilege > 0 ? 'hover-red' : 'hover'"
                        :card-key="'privilege'"
                        :value="miniCardData.pupil_expired_privilege"
                        :interactive="true"
                        title="Истек срок льготы"
                        @mini-card-privilege="callExpiredPrivilege"
                    />
                  </div>
                </template>
                <span>Список учеников, у которых истек срок действия льготы</span>
              </v-tooltip>
              <v-card v-if="showModal && modalExpiredPrivilege">
                <ModalPupilExpiredPrivilege @close="handleCloseModal"/>
              </v-card>
            </v-flex>
            <v-flex xs6 md3 class="px-2 pb-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <mini-card
                        :class="miniCardData.orders_yesterday_uncompleted > 0 ? 'hover-red' : 'hover'"
                        :card-key="'uncompleted'"
                        :value="miniCardData.orders_yesterday_uncompleted"
                        :interactive="true"
                        title="Невыполненные заявки"
                        @mini-card-uncompleted="callYesterdayUncompleted"
                    />
                  </div>
                </template>
                <span>Заявки за вчерашний день, которые не перешли в статус "Выполнена" или "Отменена".</span>
              </v-tooltip>
              <v-card v-if="showModal && modalYesterdayUncompleted">
                <ModalOrdersYesterdayUncompleted @close="handleCloseModal"/>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 md6 class="pa-2">
          <v-card class="rounded">
            <OrderHistogram @getData="getData" />
          </v-card>
        </v-flex>
        <v-flex xs12 md3 class="pa-2">
        <v-card  v-if="showChangeMealType" class="rounded pa-4" height="100%">
         <v-layout class="fs-dish" row>
            <div class="title-help">
              <div class="d-flex pt-2">
                <p class="text text-center">Меню быстрого доступа</p>
                <p>
                  <context-help-modal :context-key="contextKeys.admin.dashboard.orders"/>
                </p>
              </div>
            </div>
          </v-layout>
          <mini-card
              class="hover"
              value=""
              :card-key="'privilege'"
              :interactive="true"
              title="Смена типа питания"
              @mini-card-privilege="openModalChangeMealType"
          />
          <v-dialog v-model="modalChangeMealType" max-width="1200px" persistent>
            <ChangeMealType>
              <template v-slot:actions>
                <v-btn
                  color="#1976D2"
                  style="max-width: 60px"
                  class="white--text font-weight-bold ml-auto"
                  @click="modalChangeMealType=false"
                >закрыть
                </v-btn>
                </template>
            </ChangeMealType>
          </v-dialog>
        </v-card>
      </v-flex>
        <v-flex xs12 md6 class="pa-2">
          <v-card class="rounded">
            <OrderStatusesHistogram/>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 md4 class="pa-2">
        <v-card class="chart rounded">
          <FoodCoverage/>
        </v-card>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        <v-card class="block rounded">
          <MealPeriodCount/>
        </v-card>
      </v-flex>
      <v-flex xs12 md4 class="pa-2">
        <v-card class="block rounded">
          <DashboardNews/>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal"
import { TextEditorModal } from '@/components/common'
import OrderHistogram from '@/components/admin/Dashboard/OrderHistogram'
import OrderStatusesHistogram from '@/components/admin/Dashboard/OrderStatusesHistogram'
import FoodCoverage from '@/components/admin/Dashboard/FoodCoverage'
import MealPeriodCount from '@/components/admin/Dashboard/MealPeriodCount'
import DashboardNews from '@/components/admin/Dashboard/DashboardNews'
import MiniCard from '@/components/admin/Dashboard/MiniCard'
import ModalPupilExpiredPrivilege from '@/components/admin/Dashboard/ModalPupilExpiredPrivilege'
import ModalOrdersYesterdayUncompleted from '@/components/admin/Dashboard/ModalOrdersYesterdayUncompleted'
import ChangeMealType from "@/components/admin/ChangeMealType/ChangeMealType.vue";
import store from "@/store";



export default {
  name: 'DashboardAdmin',
  components: {
    ChangeMealType,
    MiniCard,
    OrderHistogram,
    OrderStatusesHistogram,
    FoodCoverage,
    DashboardNews,
    TextEditorModal,
    ContextHelpModal,
    MealPeriodCount,
    ModalPupilExpiredPrivilege,
    ModalOrdersYesterdayUncompleted,
  },
  data() {
    return {
      showModal: false,
      showChangeMealType: store.getters.setting('change_meal_type_dashboard'),
      modalChangeMealType: false,
      modalYesterdayUncompleted: false,
      modalExpiredPrivilege: false,
      title: 'Панель управления',
      contextKeys: Vue.config.contextHelp,
      miniCardData: {
        'pupil_count': null,
        'pupil_order': null,
        'pupil_expired_privilege': null,
        'orders_yesterday_uncompleted': null
      }
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      const chart = document.querySelector('.chart')
      const chartHeight = chart.getBoundingClientRect().height
      const blocks = document.querySelectorAll('.block')
      blocks.forEach(block => {
        block.style.height = chartHeight + 'px'
      })
    })
  },
  methods: {
    getData(data) {
      if (data.length > 0) {
        this.miniCardData.pupil_order = data[0]
        this.miniCardData.pupil_count = data[1]
        this.miniCardData.pupil_expired_privilege = data[2]
        this.miniCardData.orders_yesterday_uncompleted = data[3]
      }
    },
    callExpiredPrivilege() {
      this.modalExpiredPrivilege = true
      this.showModal = true
    },
    callYesterdayUncompleted() {
      this.modalYesterdayUncompleted = true
      this.showModal = true
    },
    handleCloseModal() {
      this.modalExpiredPrivilege = false
      this.modalYesterdayUncompleted = false
      this.showModal = false
    },
    openModalChangeMealType() {
      this.modalChangeMealType = true
    },
  }
}
</script>

<style scoped>

.rounded {
  border-radius: 10px;
}

.block {
  flex: 1;
  height: 100%;
}

.hover:hover {
  background-color: #eeeeee !important;
}

.hover-red:hover {
  background-color: #c70c0c !important;
}
</style>

