<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DateTimePicker from '@/components/common/DatePickersSmart/DateTimePicker'
import balancable from '@/mixins/balancable'
import moment from 'moment/moment'
import rulesCustom from '@/helpers/rulesCustom'
import _ from 'lodash'

export default {
  name: 'CardDetail',
  data() {
    const rules = rulesCustom
    return {
      darkTheme: Vue.config.env.darkTheme,
      datepickerMenu: false,
      is_create: false,
      isEditMode: false,
      keyUpdate: 0,
      valid: true,
      rules,
      transactionForm: false,
      amountTransaction: 0,
      menu_min: false,
      menu_max: false,

      filters: {
        is_bank_transactions: [
          { value: null, name: 'Все' },
          { value: 'True', name: 'С транзакциями' },
          { value: 'False', name: 'Без транзакций' }
        ],
        min_date: null,
        max_date: null,
        ordering: [
          { value: null, name: 'По умолчанию' },
          { value: 'create_date', name: 'От старой к новой' },
          { value: '-create_date', name: 'От новой к старой' }
        ]
      },
      buffetTransactionsTableHeaders: [
        {
          text: 'Номер транзакции',
          value: 'id',
          class: 'px-2',
          sortable: false
        },
        {
          text: 'Дата транзакции',
          value: 'create_date',
          class: 'px-2',
          sortable: false
        },
        {
          text: 'Банковская транзакция',
          value: 'bank_transactions',
          class: 'px-2',
          sortable: false
        },
        { text: 'Сумма', value: 'amount', class: 'px-2', sortable: false },
        { text: 'Баланс', value: 'balance', class: 'px-2', sortable: false },
        {
          text: 'Участник перевода',
          value: 'transfer_participant.full_name',
          class: 'px-2',
          align: 'center',
          sortable: false
        }
      ],
      role: [
        { value: 'is_admin', name: 'Администратор' },
        { value: 'is_guest', name: 'Гость' },
        { value: 'is_pupil', name: 'Ученик' }
      ],
      activeTab: 0
    }
  },
  mixins: [balancable],
  components: { DateTimePicker },
  computed: {
    ...mapGetters([
      'card',
      'currentUser',
      'setting',
      'pupil_list',
      'parent_list',
      'balance_histories',
      'transaction_filters',
      'transactions_pagination'
    ]),

    min_date: {
      get() {
        return this.transaction_filters.min_date
      },
      set(value) {
        this.set_transaction_filters({ min_date: value })
      }
    },

    max_date: {
      get() {
        return this.transaction_filters.max_date
      },
      set(value) {
        this.set_transaction_filters({ max_date: value })
      }
    },
    filter_bank_transactions: {
      get() {
        return this.transaction_filters.is_bank_transactions.value
      },
      set(value) {
        this.set_transaction_filters({ is_bank_transactions: value })
      }
    },
    filter_ordering: {
      get() {
        return this.transaction_filters.ordering.value
      },
      set(value) {
        this.set_transaction_filters({ ordering: value })
      }
    },

    selected_user: {
      get() {
        return this.card.pupil
      },
      set(value) {
        this.set_card({ ...this.card, pupil: value })
      }
    },
    issued: {
      get() {
        return this.card.issued
      },
      set(value) {
        this.set_card({ ...this.card, issued: value })
      }
    },
    expires: {
      get() {
        return this.card.expires
      },
      set(value) {
        this.set_card({ ...this.card, expires: value })
      }
    },
    is_active: {
      get() {
        return this.card.is_active
      },
      set(value) {
        this.set_card({ ...this.card, is_active: value })
      }
    },
    is_blocked: {
      get() {
        return this.card.is_blocked
      },
      set(value) {
        this.set_card({ ...this.card, is_blocked: value })
      }
    },
    is_guest: {
      get() {
        return this.card.is_guest
      },
      set(value) {
        this.set_card({ ...this.card, is_guest: value })
      }
    },
    is_admin: {
      get() {
        return this.card.is_admin
      },
      set(value) {
        this.set_card({ ...this.card, is_admin: value })
      }
    },
    card_role: {
      get() {
        return this.card.role
      },
      set(value) {
        this.set_card({ ...this.card, role: value })
      }
    },
    number_dec: {
      get() {
        return this.card.number_dec
      },
      set(value) {
        this.set_card({ ...this.card, number_dec: value })
      }
    },
    number_hex: {
      get() {
        return this.card.number_hex
      },
      set(value) {
        this.set_card({ ...this.card, number_hex: value })
      }
    },

    first_name: {
      get() {
        return this.card.first_name
      },
      set(value) {
        this.set_card({ ...this.card, first_name: value })
      }
    },
    middle_name: {
      get() {
        return this.card.middle_name
      },
      set(value) {
        this.set_card({ ...this.card, middle_name: value })
      }
    },
    last_name: {
      get() {
        return this.card.last_name
      },
      set(value) {
        this.set_card({ ...this.card, last_name: value })
      }
    },

    card_number_field() {
      const field = {
        label: 'Десятичный номер карты',
        placeholder: 'Десятичный номер карты',
        rules: [
          this.rules.required({ label: 'Десятичный номер карты' }),
          this.rules.numberDecBig({ label: 'Десятичный номер карты' })
        ]
      }

      if (this.setting('buffet_card_type_hex')) {
        field.label = 'Шестнадцатеричный номер карты'
        field.placeholder = 'Шестнадцатеричный номер карты'
        field.rules = [
          this.rules.required({ label: 'Шестнадцатеричный номер карты' }),
          this.rules.numberHexBig({ label: 'Шестнадцатеричный номер карты' })
        ]
      }

      return field
    }
  },
  async mounted() {
    this.$route.params.id ? (this.is_create = false) : (this.is_create = true)
    if (!this.is_create) {
      await this.fetchCard({ card_id: this.$route.params.id, params: {} })

      if (this.card.role === 'is_pupil') {
        this.set_pupil_list([this.card.pupil])
      }
      if (this.card.role === 'is_parent') {
        this.set_parent_list([this.card.pupil])
      }
    } else {
      this.initDatePeriods()
    }
    this.keyUpdate++

    if (this.setting('buffet_parent_card')) {
      this.role.push({ value: 'is_parent', name: 'Родитель' })
    }
  },

  methods: {
    ...mapMutations([
      'set_card',
      'set_pupil_list',
      'set_parent_list',
      'reset_card',
      'set_transaction_pagination',
      'set_transaction_filters',
      'reset_transaction_filters'
    ]),
    ...mapActions([
      'fetchCard',
      'updateCard',
      'createCard',
      'getPupils',
      'getParents',
      'createBuffetCardTransaction',
      'fetchBalanceHistories'
    ]),

    async changeRole(value) {
      if (value === 'is_pupil') {
        await this.getPupils({
          school_id: this.currentUser.school_id,
          params: { offset: 0, limit: 5000 }
        })
      }
      if (value === 'is_parent') {
        await this.getParents({
          school_id: this.currentUser.school_id,
          params: { offset: 0, limit: 5000 }
        })
      }
    },

    async update() {
      const { is_blocked, is_active, expires, issued } = this.card

      const cardData = {
        is_blocked,
        is_active,
        expires,
        issued
      }
      await this.updateCard({ card_id: this.$route.params.id, data: cardData })
      this.isEditMode = false
    },

    initDatePeriods() {
      const featurePeriodMonth = 3
      const curDate = new Date()
      const featureDate = new Date(
        curDate.getFullYear(),
        curDate.getMonth() + featurePeriodMonth,
        curDate.getDate(),
        curDate.getHours(),
        curDate.getMinutes(),
        curDate.getSeconds()
      )
      this.issued = curDate.toISOString()
      this.expires = featureDate.toISOString()
    },

    async create() {
      const {
        is_blocked,
        is_active,
        is_guest,
        is_admin,
        expires,
        issued,
        number_dec
      } = this.card

      const cardData = {
        is_blocked,
        is_active,
        is_guest,
        is_admin,
        expires,
        issued,
        number_dec: this.setting('buffet_card_type_hex')
          ? BigInt(parseInt(number_dec, 16)).toString()
          : BigInt(number_dec).toString(),
        school_id: this.currentUser.school_id
      }

      if (this.card.role === 'is_pupil') {
        cardData.pupil = this.card.pupil.id
      } else if (this.card.role === 'is_parent') {
        cardData.parent_id = this.card.pupil.id
        cardData.is_parent = true
      } else {
        Object.assign(cardData, {
          first_name: this.first_name,
          middle_name: this.middle_name,
          last_name: this.last_name
        })
        cardData[this.card.role] = true
      }
      this.createCard({ data: cardData }).then(() => {
        this.$router.push({ name: 'Cards' })
      })
    },

    handleSaveBtn() {
      this.$refs.cardDetailForm.validate()
      if (this.valid) {
        if (this.is_create) this.create()
        else this.update()
      }
    },
    transformDateTime(date) {
      return moment(date).format(Vue.config.format.prettyDateWithoutSeconds)
    },

    async sendTransaction() {
      try {
        const payload = {
          pupil_id: this.card.pupil.id,
          school_id: this.card.pupil.school,
          data: { amount: this.amountTransaction * 100 }
        }
        await this.createBuffetCardTransaction(payload)
        await this.fetchCard({ card_id: this.$route.params.id, params: {} })

        this.$refs.transactionForm.validate()
        this.$refs.transactionForm.reset()
      } catch (e) {}
    },

    async fetchBalanceHistory() {
      const payload = {
        pupil_id: this.card.pupil.id,
        school_id: this.card.pupil.school,
        params: {
          page: this.transactions_pagination.page,
          page_size: this.transactions_pagination.page_size,
          is_bank_transactions: this.filter_bank_transactions,
          min_date: this.formatDate(this.min_date),
          max_date: this.formatDate(this.max_date),
          ordering: this.filter_ordering
        }
      }
      await this.fetchBalanceHistories(payload)
    },
    formatDate(value) {
      return value ? moment(value).format(Vue.config.format.pythonDate) : null
    },

    handlePaginationClick(page) {
      this.set_transaction_pagination({ page: page })
      this.fetchBalanceHistory()
    },
    handleSearch() {
      this.set_transaction_pagination({ page: 1 })
      this.fetchBalanceHistory()
    }
  },

  destroyed() {
    this.reset_card()
  },
  watch: {
    transaction_filters: {
      deep: true,
      handler: _.debounce(function () {
        this.handleSearch()
      }, 500)
    }
  }
}
</script>

<template>
  <v-container fluid class="fs-teacher-container">
    <v-card color="grey lighten-5">
      <v-toolbar :class="{ 'cyan lighten-5': isEditMode }" extension>
        <v-btn
          flat
          class="fs-btn v-btn--active"
          variant="tonal"
          @click="$router.push({ name: 'Cards' })"
        >
          <v-icon>arrow_back</v-icon>Назад
        </v-btn>
        <v-toolbar-title class="text-truncate text-no-wrap px-2">
          {{ !is_create ? `Карта №${card?.number_dec}` : `Новая карта` }}
        </v-toolbar-title>
        <v-switch
          v-if="!is_create"
          v-model="isEditMode"
          hide-details
          class="text-no-wrap px-2 blue-grey--text align-center justify-end"
        >
          <template v-slot:prepend>
            Режим редактирования: {{ isEditMode ? 'Вкл' : 'Выкл' }}
          </template>
        </v-switch>
      </v-toolbar>
      <v-tabs
        v-if="!is_create"
        v-model="activeTab"
        color="grey darken-1"
        slider-color="green"
        dark
        centered
        fixed-tabs
        align-with-title
      >
        <v-tab :value="0"> Карта </v-tab>

        <v-tab :value="1" class="text-none">
          Баланс и транзакции пользователя
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-form ref="cardDetailForm" v-model="valid" class="pa-4">
            <v-layout md8 offset-md2 mb-2 :key="keyUpdate">
              <v-flex>
                <div>
                  <v-text-field
                    v-if="this.is_create"
                    v-model="number_dec"
                    :rules="card_number_field.rules"
                    :label="!darkTheme ? card_number_field.label : ''"
                    :placeholder="
                      darkTheme ? card_number_field.placeholder : ''
                    "
                    :outline="darkTheme"
                  />

                  <div class="card-detail__item">
                    <span>Выдана</span>
                    <DateTimePicker
                      v-model="issued"
                      :disabled="isEditMode"
                      :readonly="!(is_create || isEditMode)"
                    />
                  </div>

                  <div class="card-detail__item">
                    <span>Истекает</span>
                    <DateTimePicker
                      v-model="expires"
                      :readonly="!(is_create || isEditMode)"
                    />
                  </div>

                  <v-text-field
                    v-model="currentUser.school.short_name"
                    readonly
                    :disabled="isEditMode"
                    :outline="darkTheme"
                  />

                  <v-flex xs12 sm6 align-center>
                    <v-select
                      :items="role"
                      v-model="card_role"
                      item-name="name"
                      item-text="name"
                      item-value="value"
                      label="Роль пользователя"
                      :rules="[rules.required({ label: 'Роль пользователя' })]"
                      @change="changeRole"
                      box
                      solo
                      :readonly="!is_create"
                      :disabled="isEditMode"
                    ></v-select>
                  </v-flex>

                  <v-layout
                    column
                    v-if="card.role === 'is_admin' || card.role === 'is_guest'"
                  >
                    <v-flex tag="h4">Введите ФИО держателя карты</v-flex>

                    <v-text-field
                      small
                      v-model="last_name"
                      :rules="[rules.required({ label: 'Фамилия' })]"
                      :label="!darkTheme ? 'Фамилия' : ''"
                      :placeholder="darkTheme ? 'Фамилия' : ''"
                      :outline="darkTheme"
                      :readonly="!is_create"
                      :disabled="isEditMode"
                    />

                    <v-text-field
                      v-model="first_name"
                      :rules="[rules.required({ label: 'Имя' })]"
                      :label="!darkTheme ? 'Имя' : ''"
                      :placeholder="darkTheme ? 'Имя' : ''"
                      :outline="darkTheme"
                      :readonly="!is_create"
                      :disabled="isEditMode"
                    />

                    <v-text-field
                      v-model="middle_name"
                      :rules="[rules.required({ label: 'Отчество' })]"
                      :label="!darkTheme ? 'Отчество' : ''"
                      :placeholder="darkTheme ? 'Отчество' : ''"
                      :outline="darkTheme"
                      :readonly="!is_create"
                      :disabled="isEditMode"
                    ></v-text-field>
                  </v-layout>

                  <v-autocomplete
                    v-if="card.role === 'is_pupil' || card.role === 'is_parent'"
                    :items="card.role === 'is_pupil' ? pupil_list : parent_list"
                    v-model="selected_user"
                    return-object
                    item-text="full_name"
                    item-value="id"
                    :clearable="is_create"
                    :label="!darkTheme ? 'Выберите держателя карты' : ''"
                    :placeholder="!darkTheme ? 'Выберите держателя карты' : ''"
                    :outline="darkTheme"
                    :rules="[
                      rules.required({ label: 'Выберите держателя карты' })
                    ]"
                    box
                    solo
                    :disabled="isEditMode"
                    :readonly="!is_create"
                  />

                  <v-switch
                    v-model="is_active"
                    label="Активна"
                    class="my-2 pa-0"
                    hide-details
                    :readonly="!(is_create || isEditMode)"
                    :bax="isEditMode"
                  />
                  <v-switch
                    v-model="is_blocked"
                    label="Заблокированa"
                    class="my-2 pa-0"
                    hide-details
                    :readonly="!(is_create || isEditMode)"
                    :bax="isEditMode"
                  />
                </div>
              </v-flex>
            </v-layout>

            <v-layout v-if="isEditMode || is_create">
              <v-flex text-md-center>
                <v-btn
                  flat
                  class="fs-btn v-btn--active"
                  @click="handleSaveBtn()"
                  >сохранить</v-btn
                >
              </v-flex>
            </v-layout>
          </v-form>
        </v-tab-item>

        <v-tab-item>
          <v-card>
            <v-card>
              <v-card-title class="headline pb-0">
                Баланс пользователя &ensp;<i class="font-weight-medium">
                  {{ card.pupil?.full_name }} </i
                >:&ensp;
                <i class="font-weight-medium">
                  {{
                    convertToRubles(card.pupil?.buffet_balance || 0).toLocaleString(
                      'ru-RU'
                    )
                  }}
                  руб. </i
                >:&ensp;
              </v-card-title>
              <v-card-text>
                <v-form
                  class="d-flex"
                  ref="transactionForm"
                  v-model="transactionForm"
                >
                  <v-flex md-6 xs4>
                    <v-text-field
                      label="Сумма транзакции"
                      v-model="amountTransaction"
                      solo
                      :rules="
                        [rules.required, rules.isFloat].map((rule) =>
                          rule({ label: 'Сумма транзакции' })
                        )
                      "
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex md-6 xs4 class="pa-2">
                    <v-btn
                      :disabled="!transactionForm"
                      type="submit"
                      @click.prevent="sendTransaction"
                    >
                      Провести
                    </v-btn>
                  </v-flex>
                </v-form>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-title class="headline pb-0">
                Транзакции пользователя
              </v-card-title>
              <v-card-text>
                <v-flex xs12 sm12 d-flex>
                  <v-flex xs12 sm2 d-flex pк-2>
                    <v-select
                      :items="filters.is_bank_transactions"
                      item-name="name"
                      item-text="name"
                      item-value="value"
                      return-object
                      v-model="filter_bank_transactions"
                      label="По Банковской транзакции"
                      placeholder="По Банковской транзакции"
                      hide-details
                      solo
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm3 d-flex row px-2>
                    <v-menu
                      ref="menu_min"
                      v-model="menu_min"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                      hide-details
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="formatDate(min_date)"
                          label="От..."
                          prepend-icon="event"
                          v-on="on"
                          hide-details
                          readonly
                          solo
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="min_date"></v-date-picker>
                    </v-menu>
                    <v-menu
                      ref="menu_max"
                      v-model="menu_max"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px"
                      hide-details
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="formatDate(max_date)"
                          label="До..."
                          prepend-icon="event"
                          v-on="on"
                          hide-details
                          readonly
                          solo
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="max_date"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <!--                  <v-flex xs12 sm2 d-flex px-2>-->
                  <!--                    <v-select-->
                  <!--                      :items="filters.ordering"-->
                  <!--                      item-name="name"-->
                  <!--                      item-text="name"-->
                  <!--                      item-value="value"-->
                  <!--                      return-object-->
                  <!--                      v-model="filter_ordering"-->
                  <!--                      label="Сортировка по дате"-->
                  <!--                      placeholder="Сортировка по дате"-->
                  <!--                      :outline="darkTheme"-->
                  <!--                      hide-details-->
                  <!--                      box-->
                  <!--                    ></v-select>-->
                  <!--                  </v-flex>-->
                  <v-flex xs12 sm2 d-flex pl-2>
                    <v-btn @click.prevent="reset_transaction_filters">
                      Сбросить фильтры
                    </v-btn>
                  </v-flex>
                </v-flex>

                <v-layout class="fs-teacher-order-result fs-header-filter" my-3>
                  <v-flex>
                    <v-data-table
                      :items="balance_histories"
                      :headers="buffetTransactionsTableHeaders"
                      hide-actions
                      no-data-text="Нет пользователей с картами"
                      class="elevation-1 fs-admin-table"
                      item-key="name"
                    >
                      <template v-slot:items="props">
                        <tr>
                          <td class="px-2">{{ props.item?.id }}</td>
                          <td class="px-2">
                            {{ transformDateTime(props.item.create_date) }}
                          </td>
                          <td class="px-2">
                            {{ props.item?.bank_transactions || 'НЕТ' }}
                          </td>
                          <td class="px-2">{{ props.item.amount / 100 }}</td>
                          <td class="px-2">{{ props.item.balance / 100 }}</td>
                          <td class="px-2">
                            <div
                              v-if="props.item.transfer_participant"
                              class="d-flex align-content-start justify-self-start justify-start"
                            >
                              <v-icon
                                :color="props.item.amount < 0 ? 'red' : 'green'"
                                :class="{
                                  'icon-rotate': props.item.amount > 0
                                }"
                              >
                                forward
                              </v-icon>
                              <span
                                class="font-weight-medium align-self-center"
                              >
                                {{
                                  props.item.transfer_participant?.full_name
                                }}</span
                              >
                            </div>
                            <div
                              v-else
                              class="d-flex align-content-start justify-self-start justify-start"
                            >
                              <span class="font-weight-medium text-xs-center">
                                НЕТ
                              </span>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-flex>
                </v-layout>
                <div
                  class="text-xs-center"
                  v-if="transactions_pagination.total > 1"
                >
                  <v-pagination
                    @input="handlePaginationClick"
                    :value="transactions_pagination.page"
                    :length="transactions_pagination.total"
                    :total-visible="7"
                  ></v-pagination>
                </div>
              </v-card-text>
            </v-card>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<style scoped>
.card-detail__item {
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
}
.card-detail__item span {
  font-weight: 900;
  margin-right: 15px;
}
.v-tabs__item--active-custom {
  outline-width: 5px;
}

.icon-rotate {
  transform: rotate(180deg);
}
</style>
