import { Menu, Submenu } from '@/lib/menu'

export default class MainMenuFactory {
  constructor(settings) {
    const school = new Submenu('Школа').add(
      new Menu(
        'Список учеников',
        'Просмотр информации об учениках, настройка типов питания, разрешение питаться в кредит',
        'AdminStudents'
      ),
      new Menu(
        'Список классов',
        'Просмотр классов образовательной организации, настройка времени кормления',
        'AdminClasses'
      ),
      new Menu(
        'Список учителей',
        'Просмотр информации об учителях образовательной организации, настройка функций',
        'AdminTeachers'
      )
    )

    const finances = new Submenu('Финансы').add(
      new Menu(
        'Движение денежных средств',
        'Просмотр информации о пополнении и списании денежных средств',
        'AdminBalanceMoving'
      )
    )

    if (settings.payment_method_by_receipt?.value) {
      finances.add(
        new Menu(
          'Формирование квитанций',
          'Формирование квитанций для оплаты питания детей',
          'AdminOrdersPrint'
        )
      )
    }

    const referenceBooks = new Submenu('Справочники').add(
      new Menu(
        'Организаторы питания',
        'Просмотр информации по организаторам питания',
        'AdminOrganizers'
      ),
      new Menu(
        'Блюда',
        'Просмотр информации о блюдах, предоставляемых организатором питания',
        'AdminDishes'
      ),
      new Menu(
        'Комплексы',
        'Просмотр информации о комплексах блюд, предоставляемых организатором питания',
        'AdminComplexes'
      ),
      new Menu(
        'Типы питания',
        'Просмотр информации о возможных типах питания',
        'AdminFoodTypes'
      ),
      new Menu(
        'Социальные выплаты',
        'Просмотр информации о возможных категориях учеников',
        'AdminSocial'
      ),
      new Menu(
        'Время кормления',
        'Просмотр информации о возможном времени кормления',
        'AdminFoodTimes'
      )
    )

    let menu = []
    if (settings.dashboard_admin?.value) {
      menu = menu.concat([
        new Menu('Панель управления', null, 'DashboardAdmin')
      ])
    }
    menu = menu.concat([
      new Menu('Новости', 'Новости обновлений', 'UpdatesNewsAdmin'),
      new Menu(
        'Заявки на питание',
        'Просмотр заявок на питание по школе, корпусу и классу',
        'AdminOrders'
      ),
      school,
      finances,
      new Menu('Отчёты', 'Отчёты', 'AdminReportsIndex'),
      new Menu('Уведомления', 'Просмотр уведомлений', 'AdminNotifications'),
      new Menu(
        'Настройки',
        'Настройки параметров заказа питания',
        'AdminSettings'
      )
    ])

    if (settings.loading_orders?.value) {
      menu = menu.concat([new Menu('Загрузка заявок', null, 'LoadingOrders')])
    }

    if (settings.school_admin_working_days_edit?.value) {
      menu = menu.concat([
        new Menu(
          'Рабочие дни',
          'Просмотр и редактирование рабочих дней',
          'AdminWorkingDays'
        )
      ])
    }

    menu = menu.concat([referenceBooks])

    if (settings.mod_buffet?.value) {
      menu = menu.concat([
        new Submenu('Буфет').add(
          new Menu('Карты', 'Карты пользователей', 'Cards'),
          new Menu(
            'Пополнение счёта буфета',
            'Пополнение счёта буфета',
            'AdminBuffetBalanceAdd'
          )
          // new Menu('Категории продуктов', 'Категории продуктов', 'AdminProductCategories'),
          // new Menu('Склады', 'Склады', 'Storehouse'),
        ),
        new Menu('Опросы', 'Опросы', 'SyrveyManage'),
        // new Menu('НПА', 'НПА', 'NpaDocuments'),
        new Menu('Сертификаты', 'Сертификаты', 'NpaCertificate'),
        new Menu('Результаты опросов', 'Результаты опросов', 'SyrveyResult'),

        new Menu('Паспорт пищеблока', 'Паспорт пищеблока', 'AdminFoodBlock')
        // new Menu('Заявки на буфетную продукцию', 'Заявки на буфетную продукцию', 'BuffetProductsApplications')
      ])
    }

    if (settings.mod_quality_control?.value) {
      menu = menu.concat([
        new Menu(
          'Контроль качества',
          'Контроль качества',
          'AdminQualityControlWrap'
        )
      ])
    }

    if (settings.user_help?.value) {
      // menu = menu.concat([
      //   new Menu('Инструкции', 'Инструкции', 'AdminUserHelp')
      // ])
      menu = menu.concat([new Menu('Справка', 'Справка', 'HelpDeskMA')])
      if (!referenceBooks.includes(menu)) referenceBooks.add(menu)
    }

    if (settings.school_admin_change_meal_type?.value) {
      menu = menu.concat([
        new Menu(
          'Смена типа питания',
          'Смена типа питания',
          'AdminChangeMealType'
        )
      ])
    }

    return menu
  }
}
