<template>
  <v-layout row justify-center>
    <v-dialog v-model="$props.show" fullscreen>
      <v-card>
        <v-card-title ps3 pe3>
          Политика конфиденциальности
        </v-card-title>
        <v-card-text>
          <p v-html="content" />
        </v-card-text>
        <v-card-actions>
          <v-layout align-center justify-space-between row fill-height>
            <v-btn flex ms3 @click="closeDialog">Закрыть</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import { getPrivacyPolicy } from "@/router/routes/parent"
import store from "@/store"
export default {
  name: 'ParentPrivacyModal',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: ""
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:show', false)
    },
    async getPrivacyPolicy() {
      const res = await getPrivacyPolicy()
      if (res.data.content && res.data.content !== "") {
        this.content = res.data.content
      }
      store.commit('setShowPreloader', false)
    }
  },
  watch: {
    show() {
      this.getPrivacyPolicy()
    }
  }
}
</script>