<template>
  <v-container fluid>
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <v-flex mb-3>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout pa-0>
      <v-btn
        flat
        @click="$router.go(-1)"
        class="ma-0 mb-3 primary"
      >Назад</v-btn>
    </v-layout>
    <v-checkbox
            :input-value="everyone"
            :label="`Отправить всем`"
            @change="setValue('everyone',$event)"
    />
    <v-layout
      :disabled="everyoneValue"
      row
      mb-12
      justify-start
      text-md-center
    >
      <v-flex md4>
        <v-autocomplete
          :value="selectedSchoolID"
          @change="changeValue('selectedSchoolID', $event)"
          :items="schools"
          item-text="name"
          item-value="id"
          label="Школа:"
          :disabled="everyone"
        />
        <v-autocomplete
          :disabled="!selectedSchoolID || everyone"
          :value="selectedClassID"
          @change="changeValue('selectedClassID', $event)"
          :items="classes"
          item-text="name"
          item-value="id"
          label="Класс:"
        />
        <v-select
          :disabled="!selectedClassID || everyone"
          no-data-text="Ничего не выбрано"
          v-model="selectedStudentsIds"
          item-text="name"
          item-value="id"
          :items="students"
          label="Ученики:"
          deletable-chips
          chips
          multiple
        >
          <template v-slot:prepend-item>
            <v-list-tile
              ripple
              @click="selectAll('selectedStudentsIds', 'students')"
            >
              <v-list-tile-action>
                <v-icon :color="selectedStudentsIds.length > 0 ? 'primary' : ''">{{selectedStudentsIds.length ===  students.length ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Выбрать всё</v-list-tile-title>
                <v-list-tile-sub-title>Выбрано: {{selectedStudentsIds.length}}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
      </v-flex>
      <v-flex md6></v-flex>
    </v-layout>
    <v-layout>
      <v-flex
          md4
      >
        <v-form
          v-model="formValid"
          ref="form"
          lazy-validation
        >

          <v-text-field
            multi-line
            placeholder="Сообщение"
            box
            :value="message"
            @input="setValue('message', $event)"
            :rules="[formRules.requiredTextfield]"
          />

          <v-checkbox
            :input-value="everyone"
            :label="`Отправить учителям`"
            :disabled="everyoneValue"
            v-model="teachersValue"
          />
          <v-checkbox
            :label="`Отправить администратору питания`"
            :disabled="everyoneValue"
            v-model="adminValue"
          />
          <v-checkbox
            :label="`Отправить организатору питания`"
            :disabled="everyoneValue"
            v-model="supplierValue"
          />
          <v-checkbox
            :label="`Отправить родителям`"
            :disabled="everyoneValue"
            v-model="parentsValue"
          />
          <v-btn
            color="error"
            class="primary ma-0"
            @click="reset"
          >
            Очистить
          </v-btn>
          <v-btn
            :disabled="!checkFormValid"
            flat
            class="primary ma-0"
            @click="createNotification"
          >Создать</v-btn>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import store from '@/store'
import rules from '@/helpers/rules'
import { mapMutations } from 'vuex'
import {
  setNotificationAdmin,
  setNotificationEveryoneAdmin
} from '@/router/routes/organizer'
import {
  getSchools,
  getClasses,
  getStudents
} from '@/router/routes/superadmin'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'NotificationCreateSupplier',
  data() {
    return {
      title: 'Создать уведомление',
      formValid: true,
      formRules: rules,
      message: '',
      isDraft: false,
      everyone: false,
      parents: false,
      teachers: false,
      admin: false,
      supplier: false,
      /* */
      schools: [],
      selectedSchoolID: null,
      classes: [],
      selectedClassID: null,
      students: [],
      /* */
      selectedStudentsIds: []
    }
  },
  computed: {
    checkFormValid() {
      return this.formValid && this.message.length != 0
    },
    everyoneValue: {
      get() {
        return this.everyone
      },
      set(value) {
        this.everyone = value
      }
    },
    parentsValue: {
      get() {
        return this.parents
      },
      set(value) {
        this.parents = value
      }
    },
    teachersValue: {
      get() {
        return this.teachers
      },
      set(value) {
        this.teachers = value
      }
    },
    adminValue: {
      get() {
        return this.admin
      },
      set(value) {
        this.admin = value
      }
    },
    supplierValue: {
      get() {
        return this.supplier
      },
      set(value) {
        this.supplier = value
      }
      },
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    getClassLiter(name) {
      return name.split(' ')[1]
    },
    getClassNumber(name) {
      return name.split(' ')[0]
    },
    async init() {
      await this.getSchools()
    },
    selectAll(selectArray, parentArray) {
      this.$nextTick(() => {
        if (this[selectArray].length) {
          this[selectArray] = []
        } else {
          const idsArray = this[parentArray].map(item => item.id)
          this[selectArray] = [ ...idsArray ]
        }
      })
    },
    async changeValue(type, value) {
      this[type] = value
      if (type === 'selectedSchoolID') {
        this.classes = []
        this.selectedClassID = null
        await this.getClasses()
      }
      if (type === 'selectedClassID') {
        this.students = []
        await this.getStudents()
      }
    },
    async getSchools() {
      try {
        const PAYLOAD = {
          limit: this.LIMIT,
          offset: this.offset
        }
        const response = await getSchools(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
        this.schools = [ ...this.schools, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getClasses() {
      try {
        const PAYLOAD = {
          limit: Vue.config.general.classLimit,
          offset: this.offset,
          school_id: this.selectedSchoolID
        }
        const response = await getClasses(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
        this.classes = [ ...this.classes, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getStudents() {
      try {
        const PAYLOAD = {
          schoolId: this.selectedSchoolID,
          classId: this.selectedClassID,
          params: {
            limit: Vue.config.general.limit,
            offset: this.offset
          }
        }
        const response = await getStudents(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, fullName } = item
          return {
            id: id,
            name: fullName
          }
        })
        this.students = [ ...this.students, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки учеников') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    setValue(valueLabel, value) {
      if (value && valueLabel == 'everyone') {
        this.selectedSchoolID = null
        this.selectedClassID = null
        this.selectedStudentsIds = []
      }
      this[valueLabel] = value
    },
    reset() {
      this.message = ''
      this.isDraft = false
      this.setValue('everyone', false)
    },
    fuckXSS(value) {
      return value
        .trim()
        .replace(/\&/g, '&amp;')
        .replace(/\</g, '&lt;')
        .replace(/\>/g, '&gt;')
        .replace(/\"/g, '&quot;')
        .replace(/\'/g, '&#x27')
        .replace(/\//g, '&#x2F')
    },
    async createNotification() {
      if (!(this.teachers || this.admin || this.parents || this.supplier || this.everyone)) {
        notify.error('Для создание уведомленя выберите хотя бы одну опцию')
        return
      }
      const messageSave = this.fuckXSS(this.message)
      const reuestFunction = this.everyone
        ? setNotificationEveryoneAdmin
        : setNotificationAdmin
      try {
        const PAYLOAD = {
          data: {
            message: messageSave,
            isDraft: this.isDraft,
            schools: [ this.selectedSchoolID ],
            classes: this.selectedClassID === null ? [] : [ this.selectedClassID ],
            pupils: [ ...this.selectedStudentsIds ],
            isParents: this.parents,
            isTeachers: this.teachers,
            isAdmins: this.admin,
            isSupplier: this.supplier
          }
        }
        await reuestFunction(PAYLOAD)
        notify.success('Уведомление успешно создано')
      } catch (error) {
        // notify.error('Ошибка создания уведомления') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>
