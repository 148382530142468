<script>
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import localStorageHelpers from '@/helpers/localStorage'
import {
  authAdmin,
  authDepartment,
  authParent,
  authPupil,
  authSuperDepartment,
  authSupplier,
  authTeacher,
  getAdmins,
  getBoardingSchoolsDepartmentList,
  getClasses,
  getMunicipalities, // заменил getDepartmentUsers,
  getOrganizersPure,
  getParents,
  getSchools,
  getSchoolsByDepartment,
  getSuperDepartmentUsers,
  getTeacher
} from '@/router/routes/superadmin'
import _ from 'lodash'
import getDisplayName from '@/helpers/getDisplayName'

export default {
  name: 'ModuleAdminReauthorization',
  data() {
    return {
      title: 'Авторизация',
      departments: [],
      schools: [],
      classes: [],
      admins: [],
      teacher: null,
      selectedDepartmentId: -1,
      selectedSchoolId: null,
      selectedClassId: null,
      selectedAdmin: null,
      selectedTeacher: null,
      selectedDepartmentUser: null,
      selectedBoardingSchoolDepartmentUser: null,
      selectedSuperDepartmentUser: null,
      selectedSupplier: null,
      departmentsList: [],
      boardingSchoolDepartments: [],
      filteredBoardingSchoolsDepartments: [],
      superDepartmentUsers: [],
      suppliers: [],
      administratorStartPage: 'Administrator',
      teacherStartPage: 'Teacher',
      departmentStartPage: 'Municipality',
      superDepartmentStartPage: 'Supermunicipality',
      supplierStartPage: 'Organizer',
      selectedParent: null,
      selectedPupil: null,
      parentStartPage: 'Parent',
      pupilStartPage: 'Pupil',
      parents: [],
      pupils: [],
      pupilAuthEnabled: store.getters.setting('pupil_admin_auth')
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    ...mapGetters(['setting'])
  },
  methods: {
    getDisplayName,
    ...mapMutations(['setShowPreloader', 'setCurrentUser']),
    getClassLiter(name) {
      return name.split(' ')[1]
    },
    getClassNumber(name) {
      return name.split(' ')[0]
    },
    async init() {
      await Promise.all([
        this.getDepartments(),
        this.getSuperDepartmentUsers(),
        this.getOrganizersUsers(),
        this.getSchools(),
        this.getBoardingSchoolsDepartments()
      ])
      this.filterBoardingSchoolsDepartments()
    },
    async getSchools() {
      if (this.selectedDepartmentId === -1) {
        await this.getAllSchools()
      } else {
        await this.getSchoolsByDepartment()
      }
    },
    async getAllSchools() {
      try {
        const response = await getSchools()
        this.schools = response.data.data
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getSchoolsByDepartment() {
      try {
        const {
          data: { data: schools }
        } = await getSchoolsByDepartment(this.selectedDepartmentId)

        this.schools = schools
      } catch (error) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getClasses() {
      try {
        const PAYLOAD = {
          school_id: this.selectedSchoolId
        }
        const {
          data: { data: classes }
        } = await getClasses(PAYLOAD)
        this.classes = classes
      } catch (error) {
        // notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getAdmins() {
      try {
        const PAYLOAD = {
          school_id: this.selectedSchoolId
        }

        const {
          data: { data: items }
        } = await getAdmins(PAYLOAD)

        this.admins = items.map((item) => {
          const { id, fullName } = item
          return {
            id,
            name: fullName
          }
        })
      } catch (error) {
        // notify.error('Ошибка загрузки администраторов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getOrganizersUsers() {
      try {
        const { data } = await getOrganizersPure()
        this.suppliers = data
      } catch (error) {
        // notify.error('Ошибка загрузки ОП') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getSuperDepartmentUsers() {
      try {
        const {
          data: { data: items }
        } = await getSuperDepartmentUsers()

        this.superDepartmentUsers = _.map(items, (item) => {
          return {
            id: item.id,
            name: item.username
          }
        })
      } catch (error) {
        // notify.error('Ошибка загрузки пользователей') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getDepartments() {
      try {
        const {
          data: { data: departmentsList }
        } = await getMunicipalities()
        this.setDepartments(departmentsList)
        this.departmentsList = departmentsList
      } catch (error) {
        // notify.error('Ошибка загрузки департаментов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    setDepartments(response) {
      const itemsArray = []
      itemsArray.push({
        id: -1,
        name: 'Все МО'
      })
      for (const item of response) itemsArray.push(item)
      this.departments = itemsArray
    },
    async getParents() {
      try {
        const PAYLOAD = {
          school_id: this.selectedSchoolId,
          class_id: this.selectedClassId
        }
        const response = await getParents(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const parents = []
        const pupils = []
        let index = 0
        for (const item of items) {
          for (const pupil of item.pupils) {
            parents.push({
              index,
              id: item.user_id,
              name: `${item.user_id} (ребёнок ${pupil.full_name})`,
              pupil: {
                id: pupil.id,
                name: pupil.full_name
              }
            })
            index++
            if (!pupils.find((p) => p.id === pupil.id)) {
              pupils.push({
                id: pupil.id,
                name: `${pupil.full_name} (${pupil.id})`
              })
            }
          }
        }
        this.parents = parents
        this.pupils = pupils
      } catch (error) {
        // notify.error('Ошибка получения родителей') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getTeacher() {
      try {
        const PAYLOAD = {
          school_id: this.selectedSchoolId,
          class_id: this.selectedClassId
        }
        const response = await getTeacher(PAYLOAD)
        const {
          data: { data: item }
        } = response
        this.selectedTeacher = item
      } catch (error) {
        // notify.error('Ошибка получения учителя') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async getBoardingSchoolsDepartments() {
      try {
        const {
          data: { data: list }
        } = await getBoardingSchoolsDepartmentList()

        this.boardingSchoolDepartments = list
      } catch (e) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async authAsNutritionAdmin() {
      try {
        const PAYLOAD = {
          school_id: this.selectedSchoolId,
          user_id: this.selectedAdmin.id
        }
        const response = await authAdmin(PAYLOAD)
        const user = response.data
        store.commit('setCurrentUser', user)
        localStorageHelpers.__setToken(user.token)
        if (store.getters.setting('dashboard_admin')) {
          this.administratorStartPage = 'DashboardAdmin'
        }
        await this.$router.push({
          name: this.administratorStartPage,
          params: { reauth: true }
        })
      } catch (error) {
        // notify.error('Ошибка при авторизации') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async authAsTeacher() {
      try {
        const PAYLOAD = {
          school_id: this.selectedSchoolId,
          class_id: this.selectedClassId,
          user_id: this.selectedTeacher.id
        }
        const response = await authTeacher(PAYLOAD)
        const user = response.data
        store.commit('setCurrentUser', user)
        localStorageHelpers.__setToken(user.token)
        await this.$router.push({
          name: this.teacherStartPage,
          params: { reauth: true }
        })
      } catch (error) {
        // notify.error('Ошибка при авторизации') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async authAsParent() {
      try {
        const PAYLOAD = {
          user_id: this.selectedParent.id,
          pupil_id: this.selectedParent.pupil.id
        }
        const response = await authParent(PAYLOAD)
        const user = response.data
        store.commit('setCurrentUser', user)
        localStorageHelpers.__setToken(user.token)
        await this.$router.push({
          name: this.parentStartPage,
          params: { reauth: true, childId: PAYLOAD.pupil_id }
        })
      } catch (error) {
        // notify.error('Ошибка при авторизации') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async authAsPupil() {
      try {
        const PAYLOAD = {
          pupil_id: this.selectedPupil.id
        }
        const response = await authPupil(PAYLOAD)
        const user = response.data
        store.commit('setCurrentUser', user)
        localStorageHelpers.__setToken(user.token)
        await this.$router.push({
          name: this.pupilStartPage,
          params: { reauth: true, childId: PAYLOAD.pupil_id }
        })
      } catch (error) {
        // notify.error('Ошибка при авторизации') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async authAsDepartment(id) {
      try {
        const PAYLOAD = {
          department_id: id
        }
        const response = await authDepartment(PAYLOAD)
        const user = response.data
        store.commit('setCurrentUser', user)
        localStorageHelpers.__setToken(user.token)
        await this.$router.push({
          name: this.departmentStartPage,
          params: { reauth: true }
        })
      } catch (error) {
        // notify.error('Ошибка при авторизации') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async authAsSuperDepartment() {
      try {
        const response = await authSuperDepartment(
          this.selectedSuperDepartmentUser.id
        )
        const user = response.data
        store.commit('setCurrentUser', user)
        localStorageHelpers.__setToken(user.token)
        await this.$router.push({
          name: this.superDepartmentStartPage,
          params: { reauth: true }
        })
      } catch (error) {
        console.error(error)
        // notify.error('Ошибка при авторизации') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async authAsSupplier() {
      try {
        const PAYLOAD = {
          supplier_id: this.selectedSupplier.id
        }
        const response = await authSupplier(PAYLOAD)
        const user = response.data
        store.commit('setCurrentUser', user)
        localStorageHelpers.__setToken(user.token)
        await this.$router.push({
          name: this.supplierStartPage,
          params: { reauth: true }
        })
      } catch (error) {
        // notify.error('Ошибка при авторизации') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async changeDepartment(department_id) {
      this.selectedDepartmentId =
        department_id !== undefined ? department_id : -1
      this.selectedSchoolId = null
      this.selectedClassId = null
      this.selectedTeacher = null
      this.selectedParent = null
      this.selectedAdmin = null
      this.schools = []
      this.classes = []
      this.admins = []
      this.parents = []
      this.pupils = []
      await this.getSchools()
    },
    async changeSchool(school_id) {
      this.selectedSchoolId = school_id
      this.selectedClassId = null
      this.selectedTeacher = null
      this.selectedParent = null
      this.selectedAdmin = null
      this.classes = []
      this.admins = []
      this.pupils = []
      await this.getClasses()
      await this.getAdmins()
    },
    async changeClass(class_id) {
      this.selectedClassId = class_id
      this.selectedTeacher = null
      this.selectedParent = null
      this.selectedAdmin = null
      this.parents = []
      this.pupils = []
      await this.getTeacher()
      await this.getParents()
    },
    changeParent(index) {
      this.selectedParent = this.parents.find((p) => p.index === index)
    },
    changePupil(id) {
      this.selectedPupil = this.pupils.find((p) => p.id === id)
    },
    changeDepartmentUser(id) {
      this.selectedDepartmentUser = this.departmentsList.find(
        (d) => d.id === id
      )
      this.filterBoardingSchoolsDepartments()
      this.selectedBoardingSchoolDepartmentUser = null
    },
    changeBoardingSchoolDepartmentUser(id) {
      this.selectedBoardingSchoolDepartmentUser =
        this.boardingSchoolDepartments.find((d) => d.id === id)
    },
    changeSuperDepartmentUser(id) {
      this.selectedSuperDepartmentUser = this.superDepartmentUsers.find(
        (u) => u.id === id
      )
    },
    changeSupplier(id) {
      this.selectedSupplier = this.suppliers.find((s) => s.id === id)
    },

    filterBoardingSchoolsDepartments() {
      this.filteredBoardingSchoolsDepartments = this.selectedDepartmentUser
        ? this.boardingSchoolDepartments.filter(
            (item) => item?.parent === this.selectedDepartmentUser.id
          )
        : this.boardingSchoolDepartments
    }
  }
}
</script>

<template>
  <v-container class="fs-organizer-container" fluid grid-list-md>
    <v-layout class="fs-dish" row>
      <v-flex md6 mb-5>
        <h1>{{ title }}</h1>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <v-autocomplete
          :value="selectedDepartmentId"
          :items="departments"
          :item-text="getDisplayName"
          item-value="id"
          :label="'Выберите МО'"
          @change="changeDepartment($event)"
          :clearable="selectedDepartmentId !== -1"
        />
        <v-autocomplete
          :value="selectedSchoolId"
          :items="schools"
          :item-text="getDisplayName"
          item-value="id"
          :label="'Выберите школу'"
          :disabled="selectedDepartmentId === null"
          @change="changeSchool($event)"
          clearable
        />
        <v-autocomplete
          :value="selectedClassId"
          :items="classes"
          item-text="name"
          item-value="id"
          :label="'Выберите класс'"
          :disabled="selectedSchoolId === null"
          @change="changeClass($event)"
          clearable
        />
        <v-autocomplete
          :value="selectedParent && selectedParent.index"
          :items="parents"
          item-text="name"
          item-value="index"
          :label="'Выберите родителя'"
          :disabled="selectedClassId === null"
          @change="changeParent($event)"
          clearable
        />
        <v-autocomplete
          v-if="pupilAuthEnabled"
          :value="selectedPupil && selectedPupil.id"
          :items="pupils"
          item-text="name"
          item-value="id"
          :label="'Выберите ученика'"
          :disabled="selectedSchoolId === null && selectedClassId === null"
          @change="changePupil($event)"
          clearable
        />
        <v-autocomplete
          v-model="selectedAdmin"
          :items="admins"
          item-text="name"
          return-object
          :label="'Выберите администратора'"
          :disabled="selectedSchoolId === null"
        />
      </v-flex>
    </v-layout>
    <v-layout v-if="!!selectedAdmin">
      <v-flex>
        <span
          >Администратор питания:
          <strong>{{ selectedAdmin.name }}</strong></span
        >
        <br />
        <v-btn class="primary mx-0" @click="authAsNutritionAdmin"
          >Авторизоваться</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout v-if="!!selectedTeacher">
      <v-flex>
        <span
          >Учитель: <strong>{{ selectedTeacher.fullName }}</strong></span
        >
        <br />
        <v-btn class="primary mx-0" @click="authAsTeacher"
          >Авторизоваться</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout v-if="!!selectedParent">
      <v-flex>
        <span
          >Родитель: <strong>{{ selectedParent.name }}</strong></span
        >
        <br />
        <v-btn class="primary mx-0" @click="authAsParent">Авторизоваться</v-btn>
      </v-flex>
    </v-layout>
    <v-layout v-if="pupilAuthEnabled">
      <v-flex v-if="!!selectedPupil">
        <h3>Выбранный ученик: {{ selectedPupil.name }}</h3>
        <v-btn class="primary" @click="authAsPupil"
          >Авторизоваться как {{ selectedPupil.name }}</v-btn
        >
      </v-flex>
    </v-layout>

    <!--Выбор муниципалитета для авторизации-->
    <v-layout mt-4>
      <v-flex>
        <v-autocomplete
          :value="selectedDepartmentUser ? selectedDepartmentUser.id : null"
          :items="departmentsList"
          :item-text="getDisplayName"
          item-value="id"
          :label="'Выберите муниципалитет'"
          @change="changeDepartmentUser($event)"
          clearable
        />
      </v-flex>
    </v-layout>
    <v-layout v-if="!!selectedDepartmentUser">
      <v-flex>
        <span
          >Сотрудник муниципалитета:
          <strong>{{ selectedDepartmentUser?.short_name }}</strong></span
        >
        <br />
        <v-btn
          class="primary mx-0"
          @click="() => authAsDepartment(selectedDepartmentUser.id)"
          >Авторизоваться</v-btn
        >
      </v-flex>
    </v-layout>

    <!--Выбрать Школу интернат-->
    <v-layout mt-4 v-if="setting('boarding_school_department')">
      <v-flex>
        <v-autocomplete
          :value="
            selectedBoardingSchoolDepartmentUser
              ? selectedBoardingSchoolDepartmentUser.id
              : null
          "
          :items="filteredBoardingSchoolsDepartments"
          :item-text="getDisplayName"
          item-value="id"
          :label="'Выберите департамент школы-интернат'"
          @change="changeBoardingSchoolDepartmentUser($event)"
          clearable
        />
      </v-flex>
    </v-layout>
    <v-layout v-if="!!selectedBoardingSchoolDepartmentUser">
      <v-flex>
        <span
          >Сотрудник департамента школы-интернат:
          <strong>{{
            getDisplayName(selectedBoardingSchoolDepartmentUser)
          }}</strong></span
        >
        <br />
        <v-btn
          class="primary mx-0"
          @click="
            () => authAsDepartment(selectedBoardingSchoolDepartmentUser.id)
          "
          >Авторизоваться</v-btn
        >
      </v-flex>
    </v-layout>

    <!--Выбор Супер-муниципалитет для авторизации-->
    <v-layout mt-4>
      <v-flex>
        <v-autocomplete
          :value="
            selectedSuperDepartmentUser ? selectedSuperDepartmentUser.id : null
          "
          :items="superDepartmentUsers"
          :item-text="getDisplayName"
          item-value="id"
          :label="'Выберите супер-муниципалитет'"
          @change="changeSuperDepartmentUser($event)"
          clearable
        />
      </v-flex>
    </v-layout>
    <v-layout v-if="!!selectedSuperDepartmentUser">
      <v-flex>
        <span
          >Супер-муниципалитет:
          <strong>{{ selectedSuperDepartmentUser.name }}</strong></span
        >
        <br />
        <v-btn class="primary mx-0" @click="authAsSuperDepartment"
          >Авторизоваться</v-btn
        >
      </v-flex>
    </v-layout>

    <!--Выбор Организатора питания для авторизации-->
    <v-layout mt-4>
      <v-flex>
        <v-autocomplete
          :value="selectedSupplier ? selectedSupplier.id : null"
          :items="suppliers"
          item-text="username"
          item-value="id"
          :label="'Выберите ОП'"
          @change="changeSupplier($event)"
          clearable
        />
      </v-flex>
    </v-layout>
    <v-layout v-if="!!selectedSupplier">
      <v-flex>
        <span
          >Организатор питания:
          <strong>{{ selectedSupplier.username }}</strong></span
        >
        <br />
        <v-btn class="primary mx-0" @click="authAsSupplier"
          >Авторизоваться</v-btn
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>
