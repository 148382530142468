<template>
  <v-dialog v-model="show" persistent :max-width="width">
    <v-card>
      <v-toolbar elevation="1" dense light>
        <v-toolbar-title>Смена льготы для ученика</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.prevent="close">
          <v-icon class="fs-active-icon">close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <div style="font-size: 18px;">
          <b class="mr-3">Текущая льгота:</b> {{ currentPrivilege.name }}
        </div>
        <div style="font-size: 18px; display: flex; align-items: center;">
	        <span class="mr-5 mt-1 font-weight-bold">Новая льгота:</span>
	        <with-tooltip text="Поле выбора новой льготы" custom-style="width: 70%">
	          <v-select
	            @input="showDebug"
	            :items="privilegesArray"
	            item-text="name"
	            item-value="id"
	            label="Выберите льготу"
	            return-object
	            v-model="privilege"
	            clearable
	          ></v-select>
		      </with-tooltip>
        </div>
        <div style="font-size: 18px; display: flex; align-items: center;">
          <v-menu
            v-if="!isDefault"
            :nudge-right="150"
            lazy
            transition="scale-transition"
            :close-on-content-click="false"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
	            <span class="font-weight-bold mt-1">Дата окончания действия льготы:</span>
	            <with-tooltip text="Формат: дд.мм.гггг" custom-style="width: 50%; text-align: right">
	              <v-text-field
	                class="pl-3"
	                v-model="expDateFormat"
	                v-on="on"
	              ></v-text-field>
	            </with-tooltip>
            </template>
            <v-date-picker
              locale="ru-ru"
              :first-day-of-week="1"
              :min="minimumDate"
              v-model="expDate"
            ></v-date-picker>
          </v-menu>
        </div>
      </v-card-text>
      <v-card-actions>
	      <v-spacer />
	      <with-tooltip text="Обновить информацию">
          <v-btn style="margin-left: auto;" :disabled="!privilege" @click="updateStudent">Обновить</v-btn>
	      </with-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { updateStudentPrivilege, getPrivileges } from '@/router/routes/admin'
import {
  updateStudentPrivilege as updateStudentPrivilegeTeacher,
  getPrivileges as getPrivilegesTeacher
} from '@/router/routes/teacher'
import { mapGetters, mapMutations } from 'vuex'
import { getSchoolId } from '@/helpers/utils'
import moment from 'moment'
import notify from '@/utils/notifications/toastNotification'
import WithTooltip from "@/components/common/Tooltip/Tooltip.vue"

export default {
  name: 'ChangePupilPrivilege',
  components: { WithTooltip },
  data: () => ({
    privilege: '',
    privilegesArray: [],
    expDate: null,
    expDateFormat: null
  }),
  props: {
    minimumDate: String,
    value: {
      required: true,
      type: Boolean
    },
    studentId: {
    },
    width: {
      default: 500
    },
    currentPrivilege: {
      type: Object,
      default: () => ({
        id: null,
        name: 'Без льгот'
      })
    },
    isTeacher: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    isDefault() {
      return !!(this.privilege && this.privilege.is_default)
    }
  },
  mounted() {
    this.getAllPrivileges()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    close() {
      this.$emit('close')
      this.show = false
    },
    formatDate(date) {
      if (!date) return null
      const [ year, month, day ] = date.split('-')
      return `${day}.${month}.${year}`
    },
    showDebug() {},
    updateStudent() {
      try {
        const selectedDate = moment(this.expDateFormat, 'DD.MM.YYYY')
        const minDate = moment(this.minimumDate)

        if (selectedDate <= minDate) {
          notify.error(`Укажите дату не ранее ${minDate.format('DD.MM.YYYY')}`) //pass
          return
        }

        this.expDate = selectedDate.format('YYYY-MM-DD')
      } catch (e) {
        notify.error(`Ошибка указания даты. Проверьте внимательно написание (Прим.: 20.01.2022)`) //pass
        return
      }
      const updateMethod = this.isTeacher ? updateStudentPrivilegeTeacher : updateStudentPrivilege
      if (!this.isDefault && !this.expDate) {
        return
      }
      if (this.isDefault) {
        this.expDate = null
      }
      if (this.expDate === 'Invalid date') {
        this.expDate = null
        notify.error(`Ошибка указания даты. Проверьте внимательно написание (Прим.: 20.01.2022)`) //pass
        return
      }
      const payload = {
        schoolId: getSchoolId(this.currentUser),
        id: this.studentId,
        data: {
          privilege: this.privilege.id,
          expireDate: this.expDate
        }
      }
      updateMethod(payload)
        .then(res => {
          this.show = false
          this.$emit('updated')
          this.$emit('changeExpDate', this.expDate)
        })
        .finally(() => this.setShowPreloader(false))
    },
    async getAllPrivileges() {
      const getPrivilegesMethod = this.isTeacher ? getPrivilegesTeacher : getPrivileges

      try {
        const { data } = await getPrivilegesMethod({
          schoolId: getSchoolId(this.currentUser)
        })

        this.privilegesArray = data
      } catch (error) {
        // notify.error('Ошибка загрузки категорий льгот') // backend handler added (exception_message)
      } finally {
        this.$store.commit('setShowPreloader', false)
      }
    },
  },
  watch: {
    expDate(val) {
      this.expDateFormat = moment(val).format('DD.MM.YYYY')
    }
  }
}
</script>
