<template>
  <v-dialog v-model="showModal" max-width="1200px" persistent>
    <v-card>
      <v-layout class="fs-dish" row>
        <div class="mt-4 mb-2 title-help">
          <div class="d-flex row">
            <h3 class="mt-1">Ученики без типа питания</h3>
            <context-help-modal :context-key="contextKeys.admin.dashboard.modals.no_meal_type"/>
          </div>
        </div>
      </v-layout>
      <v-card-actions>
        <v-flex xs12 sm12 md3>
          <v-select
            class="ml-3 mt-1"
            label="Элементов на странице"
            v-model="itemsPerPage"
            :items="[10, 25, 50]"
            dense outlined
            @change="updateItemsPerPage"
          ></v-select>
        </v-flex>
        <v-flex md1.5>
        </v-flex>
        <v-flex xs12 sm12 md3>
          <v-select
            class="mt-1"
            label="Выберите класс"
            v-model="searchClassField"
            :items="filterClasses"
            item-text="name"
            item-value="id"
            dense outlined
            @change="searchClass"
          ></v-select>
        </v-flex>
        <v-flex md1.5>
        </v-flex>
        <v-flex xs12 sm12 md3>
          <v-text-field
            class="mt-1 mr-3"
            label="Поиск ученика"
            append-icon="search"
            clear-icon="clear"
            clearable
            v-model="searchPupilField"
            @click:append="searchPupil"
            @click:clear="clearSearchPupil"
          ></v-text-field>
        </v-flex>
      </v-card-actions>
      <v-card-actions class="justify-start">
        <v-layout wrap align-center>
          <v-flex v-if="hasSelectedItems" ml-3 mr-3 xs12 sm12 d-flex>
            <v-select
              :items="privileges"
              label="Выберите льготу"
              item-text="name"
              item-value="id"
              solo-filled
              v-model="selectedPrivilege"
              @change="filterMealTypes"
            >
              <template #item="{ item }">
                {{ item.name }}
              </template>
            </v-select>
          </v-flex>
          <v-flex v-if="hasSelectedItems" ml-3 mr-3 xs12 sm12 d-flex>
            <v-select
              :items="filteredMealTypes"
              label="Выберите тип питания"
              item-text="name"
              item-value="id"
              solo-filled
              v-model="selectedMealType"
            >
              <template #item="{ item }">
                {{ item.name }}
              </template>
            </v-select>
          </v-flex>
          <v-flex v-if="hasSelectedItems && selectedPrivilegeNotDefault" ml-3 mr-3 xs12 sm12 d-flex>
            <v-menu
              v-model="datepickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="0"
              style="display: none"
              lazy
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :prepend-inner-icon="darkTheme ? 'event' : ''"
                  :prepend-icon="!darkTheme ? 'event' : ''"
                  :outline="false"
                  placeholder="Выберите дату"
                  v-model="date"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                class="fs-datepicker"
                prev-icon="keyboard_arrow_left"
                next-icon="keyboard_arrow_right"
                locale="ru"
                first-day-of-week="1"
                no-title
                v-model="date"
                @input="selectDate"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-card-actions>
      <v-layout class="fs-complex-result" mb-3 mt-3 ml-3 mr-3>
        <v-flex>
          <v-data-table
            :headers="headers"
            :items="getPaginatedItems()"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            :total-items="items.length"
            hide-actions
            disable-initial-sort
            class="elevation-1"
          >
            <template v-slot:no-data>
              <tr>
                <td colspan="10" class="text-center">Ученики отсутствуют</td>
              </tr>
            </template>
            <template slot="headers" slot-scope="{ props }">
              <tr>
                <th>
                  <v-checkbox
                    v-model="selectAll"
                    hide-details
                    @click.native.stop
                  ></v-checkbox>
                </th>
                <th v-for="header in headers" :key="header.text" class="text-left">
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template v-slot:items="{ item, index }">
              <tr :key="item.id" :class="{ 'selected-row': item.isSelected }">
                <td>
                  <v-checkbox
                    v-model="item.isSelected"
                    :value="item.isSelected"
                    :class="{ 'selected-row': item.isSelected }"
                    hide-details
                    @click.native.stop
                  ></v-checkbox>
                </td>
                <td>{{ index + 1 }}</td>
                <td>{{ item.id }}</td>
                <td>{{ item.full_name }}</td>
                <td>{{ item.school_class }}</td>
                <td>{{ item.privilege }}</td>
                <td>{{ item.ext_date }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-pagination
        v-model="currentPage"
        :length="totalVisiblePages"
        @input="updateCurrentPage"
        class="mt-2 mb-2"
      ></v-pagination>
      <v-card-actions class="d-flex justify-content-end align-items-center flex-wrap">
        <v-btn color="primary" class="dashboard-btn" @click="closeModal">Закрыть</v-btn>
        <v-btn color="#69F0AE" class="dashboard-btn" v-if="hasSelectedItems" @click="checkingValues">Изменить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from "vuex"
import {
  getAdminDashboardModalAllClasses,
  getAdminDashboardPupilAllPrivilegesAndFoodtypes,
  getAdminDashboardPupilWithoutFoodtypes,
  patchAdminDashboardPupilWithoutFoodtypes,
} from "@/router/routes/admin"
import notify from "@/utils/notifications/toastNotification"
import store from "@/store"
import moment from "moment/moment"
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal'
import Vue from "vue"


export default {
  name: 'ModalPupilWithoutFoodtypes',
  components: {
    ContextHelpModal,
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      items: [],
      filterClasses: [],
      privileges: [],
      mealTypes: [],
      filteredMealTypes: [],
      selectedPrivilege: null,
      selectedMealType: null,
      selectAll: false,
      showModal: true,
      date: null,
      day: null,
      month: null,
      year: null,
      datepickerMenu: false,
      hasSelectedItems: false,
      selectedPrivilegeNotDefault: false,
      page: 0,
      itemsPerPage: 10,
      totalVisiblePages: 1,
      currentPage: 1,
      searchPupilField: '',
      searchClassField: null,
      headers: [
        { text: '№', value: 'index' },
        { text: 'ЛС', value: 'id' },
        { text: 'ФИО', value: 'full_name' },
        { text: 'Класс', value: 'school_class' },
        { text: 'Льгота', value: 'privilege' },
        { text: 'Дата окончания', value: 'ext_date' },
      ],
    }
  },
  async mounted() {
    await this.loadPupilWithoutFoodtypes()
    await this.loadAllClasses()
  },
  watch: {
    selected(newVal) {
      this.selectAll = newVal.length === this.items.length
    },
    selectAll(newVal) {
      if (newVal) {
        const paginatedItems = this.getPaginatedItems()
        paginatedItems.forEach(item => {
          const index = this.items.findIndex(i => i.id === item.id)
          if (index !== -1) {
            this.items[index].isSelected = true
          }
        })
      } else {
        this.items.forEach((item) => {
          item.isSelected = false
        })
      }
    },
    items: {
      handler() {
        if (this.items.every(item => item.isSelected)) {
          this.selectAll = this.getPaginatedItems
        } else if (this.items.every(item => !item.isSelected)) {
          this.selectAll = false
        }
        this.hasSelectedItems = this.items.filter((item) => item.isSelected).length > 0
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    selectDate() {
      this.datepickerMenu = false
      this.day = moment(this.date).date()
      this.month = moment(this.date).month() + 1
      this.year = moment(this.date).year()
    },
    checkingValues() {
      if (!this.selectedPrivilege) {
        notify.error('Выберите льготу')
        return
      }
      if (!this.selectedMealType) {
        notify.error('Выберите тип питания')
        return
      }
      if (!this.date && this.selectedPrivilegeNotDefault) {
        notify.error('Выберите дату окончания льготы')
        return
      }
      this.updatePupilMealTypes()
    },
    getPaginatedItems() {
      const startIndex = this.page * this.itemsPerPage
      const endIndex = startIndex + parseInt(this.itemsPerPage, 10)
      return this.items.slice(startIndex, endIndex)
    },
    updateItemsPerPage() {
      this.page = 0
      this.updateTotalPages()
    },
    updateTotalPages() {
      this.totalVisiblePages = Math.ceil(this.items.length / this.itemsPerPage)
    },
    updateCurrentPage(page) {
      this.page = page - 1
    },
    filterMealTypes(selectedPrivilege) {
      if (!selectedPrivilege) {
        this.filteredMealTypes = [ ...this.mealTypes ]
        return
      }
      const privilege = this.privileges.find(privilege => privilege.id === selectedPrivilege)
      const isDefault = privilege?.is_default
      this.selectedPrivilegeNotDefault = !isDefault ? true : false
      this.filteredMealTypes = this.mealTypes.filter(
        mealType => mealType.privilege.id === selectedPrivilege
      )
    },
    async searchPupil() {
      if (this.searchPupilField.length === 0) {
        return
      }
      try {
        const response = await getAdminDashboardPupilWithoutFoodtypes({
          school_id: this.currentUser.school_id,
          search: this.searchPupilField
        })
        this.items = response.data.map(item => ({ ...item, isSelected: false }))
        this.page = 0
        this.searchClassField = null
        await this.updateTotalPages()
      } catch (error) {
        notify.error('Ошибка загрузки учеников') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async searchClass() {
      if (this.searchClassField === null ) {
        await this.loadPupilWithoutFoodtypes()
        return
      }
      try {
        const response = await getAdminDashboardPupilWithoutFoodtypes({
          school_id: this.currentUser.school_id,
          filter_class: this.searchClassField
        })
        this.items = response.data.map(item => ({ ...item, isSelected: false }))
        this.page = 0
        this.searchPupilField = ''
        await this.updateTotalPages()
      } catch (error) {
        notify.error('Ошибка фильтрации классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async clearSearchPupil() {
      this.searchPupilField = ''
      await this.loadPupilWithoutFoodtypes()
    },
    async loadPupilWithoutFoodtypes() {
      try {
        const response = await getAdminDashboardPupilWithoutFoodtypes({
          school_id: this.currentUser.school_id
        })
        this.items = response.data.map(item => ({ ...item, isSelected: false }))
        this.page = 0
        await this.updateTotalPages()
        this.getAllPrivilegesAndFoodtypes()
      } catch (error) {
        notify.error('Ошибка загрузки учеников') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async loadAllClasses() {
      try {
        const response = await getAdminDashboardModalAllClasses({
          school_id: this.currentUser.school_id
        })
        this.filterClasses = response.data
        this.filterClasses.unshift({ name: 'Все', id: null })
      } catch (error) {
        notify.error('Ошибка загрузки классов') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    closeModal() {
      this.$emit('close')
    },
    async getAllPrivilegesAndFoodtypes() {
      try {
        const response = await getAdminDashboardPupilAllPrivilegesAndFoodtypes({
          school_id: this.currentUser.school_id
        })
        console.log(response.data)
        this.mealTypes = [ ...response.data.meal_type]
        this.privileges = [ ...new Set(this.mealTypes.map(mealType => mealType.privilege)) ]
      } catch (error) {
        notify.error('Ошибка загрузки типов питания') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async updatePupilMealTypes() {
      try {
        const selectedItems = this.items.filter((item) => item.isSelected).
          map(({ isSelected, full_name, school_class, ext_date, ...rest }) => {
            if (this.selectedPrivilegeNotDefault) {
              return {
                ...rest,
                privilege: this.selectedPrivilege,
                meal_type: this.selectedMealType,
                ext_date: this.date
              }
            } else {
              return {
                ...rest,
                privilege: this.selectedPrivilege,
                meal_type: this.selectedMealType,
              }
            }
          })
        await patchAdminDashboardPupilWithoutFoodtypes({
          school_id: this.currentUser.school_id, data: selectedItems
        })
        this.loadPupilWithoutFoodtypes()
        this.searchPupilField = ''
        notify.success('Данные успешно обновлены')
      } catch (error) {
        notify.error('Ошибка загрузки учеников') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>

<style >

.selected-row {
  background-color: #bef7be;
}

.text-left {
  text-align: left;
}

.v-dialog.v-dialog--active .v-card {
  border-radius: 10px;
}

.dashboard-btn {
  padding: 8px 16px;
  width: 120px;
  margin: 8px;
}

</style>