<template>
  <v-form ref="gradeForm" v-model="valid" class="order-grade-form">
    <v-card>
      <v-toolbar elevation="1" dense light>
        <v-toolbar-title v-if='readonly'>Просмотр оценки заказа от {{ orderGrade?.order?.orderDay }}</v-toolbar-title>
        <v-toolbar-title v-else>Оценить заказ</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn icon @click.prevent="closeModal">
          <v-icon class="fs-active-icon">close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <div class='rating-wrap'>
          <span>
             Оценка: {{gradeForm.evaluation}}
          </span>
          <v-input
              :value="gradeForm.evaluation"
              :rules="[rules.required]"
              mb-5
          >
            <v-rating
                :readonly="readonly"
                v-model="gradeForm.evaluation"
                length="10"
            />
          </v-input>
        </div>

        <p style="color: red; margin-bottom: 5px;" v-if="!readonly">
          Название вложения должно содержать латинские буквы цифры
        </p>
        <v-text-field
          v-if="!gradeForm.image.thumb && !readonly"
          label="Прикрепите изображение"
          @click='onPickFile'
          v-model='gradeForm.image.fileName'
          :outline="darkTheme"
          :prepend-inner-icon="darkTheme ? 'attach_file' : ''"
          :prepend-icon="!darkTheme ? 'attach_file' : ''"
        ></v-text-field>
        <input
          type="file"
          style="display: none"
          ref="fileInput"
          accept="*/*"
          @change="onFilePicked">

          <div v-if="gradeForm.image.thumb" class="image-wrap">
            <div class="image-wrap__head">
              <h3>Прикреплённое фото</h3>
              <v-btn v-if="!readonly" @click="handleClearImage" icon>
                <v-icon class="fs-active-icon">delete</v-icon>
              </v-btn>
            </div>
            <div class="image" v-if="gradeForm.image.thumb" @click="showFullImage">
              <img :src="getMediaUrl(gradeForm.image.thumb)" alt="image-grade" height="220" width="320" />
            </div>

            <image-preview-modal
                v-model='isShowFullImage'
                :src='getMediaUrl(fullSizeImage)'
            />
          </div>


        <v-textarea
          style="margin-top: 20px;"
          :readonly="readonly"
          class="textarea-wrap"
          :rules="[rules.required]"
          v-model="gradeForm.comment"
          :label="!darkTheme ? 'Комментарий:' : ''"
          :placeholder="darkTheme ? 'Комментарий:' : ''"
          :outline="darkTheme"
        />
      </v-card-text>

      <v-card-actions v-if="!readonly">
        <v-btn @click="save" style="margin-left: auto;">
          <v-icon class="fs-active-icon">check</v-icon>
          Оценить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { getMediaUrl } from '@/utils/media'
import ImagePreviewModal from '@/components/common/QualityControl/modal/ImagePreviewModal.vue'

export default {
  name: 'QualityAssessmentsDetail',
  components: { ImagePreviewModal },
  props: {
    readonly: { type: Boolean, default: true },
    orderGradeId: { type: Number },
    orderId: { type: Number }
  },
  data: () => ({
    darkTheme: Vue.config.env.darkTheme,
    server: Vue.config.server,
    isShowFullImage: false,
    valid: true,
    orderGrade: null,
    gradeForm: {
      user: null,
      order: null,
      evaluation: 0,
      comment: "",
      image: {
        thumb: '',
        fileName: '',
        fileObject: null,
      },
    },
    rules: {
      min: (v) => Number(v) <= 0 || "Поле обязательное для заполнения",
      required: (v) => !!v || "Поле обязательное для заполнения"
    },
  }),
  computed: {
    ...mapGetters([ 'currentUser' ]),
    fullSizeImage() {
      return this.gradeForm.image.image ? this.gradeForm.image.image : this.gradeForm.image.thumb
    }
  },
  mounted() {
    if (this.orderGradeId) this.fetchQualityGrade()
    else {
      this.gradeForm.user = this.currentUser.user_id
      this.gradeForm.order = this.orderId
    }
  },
  methods: {
    ...mapActions([ 'createOrderQualityAssessments', 'createImageOrderQualityAssessments', 'getQualityAssessmentsDetail' ]),
    fetchQualityGrade() {
      this.getQualityAssessmentsDetail(this.orderGradeId)
        .then(res => {
          this.orderGrade = res
          this.gradeForm.user = res.user
          this.gradeForm.order = res.order.id
          this.gradeForm = {
            ...this.gradeForm,
            evaluation: res.evaluation,
            comment: res.comment,
            image: {
              fileObject: null,
              thumb: res.images && res.images.length > 0 ? res.images[0].thumb : '',
              image: res.images && res.images.length ? res.images[0].image : ''
            }
          }
        })
    },
    async save() {
      this.$refs.gradeForm.validate()
      if (this.valid) {
        const isImageExist = this.gradeForm.image.fileObject
        await this.createOrderQualityAssessments(this.gradeForm)
          .then(async res => {
            if (isImageExist) {
              await this.createImageOrderQualityAssessments({
                description: "",
                quality_assessment: res.id,
                image: this.gradeForm.image.fileObject
              })
            }
          })
        this.$emit('save')
        this.closeModal()
      }
    },
    closeModal() {
      this.clearGradeForm()
      this.handleClearImage()
      this.$emit('close')
    },
    clearGradeForm() {
      this.gradeForm = {
        user: null,
        order: null,
        evaluation: 0,
        comment: "",
      }
    },
    onPickFile () {
      this.$refs.fileInput.click()
    },
    showFullImage() {
      this.isShowFullImage = true
    },
    handleClearImage() {
      this.gradeForm.image = {
        thumb: '',
        fileName: '',
        fileObject: null,
      }
    },
    getMediaUrl,
    onFilePicked (event) {
      const files = event.target.files
      const isFileExist = files[0] !== undefined

      if (isFileExist) {
        this.gradeForm.image.fileName = files[0].name

        const fr = new FileReader()
        fr.readAsDataURL(files[0])

        fr.addEventListener('load', () => {
          this.gradeForm.image = { thumb: fr.result, fileObject: files[0] }
          this.gradeForm.image.thumb = fr.result
          this.gradeForm.image.fileObject = files[0]
        })
      }

      else {
        this.gradeForm.image.thumb = ''
        this.gradeForm.image.fileName = ''
        this.gradeForm.image.fileObject = null
      }
    },
  }
}
</script>

<style>

</style>
