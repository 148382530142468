<template>
	<v-container fluid class="fs-teacher-container">
		<div class="page-head__common">
      <h1>Категории продуктов</h1>
      <v-btn
        flat
        @click="handleClickCreateCategory"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Добавить категорию</v-btn>
    </div>

    <v-select
      :items="storehouses"
      item-text="name"
      item-value="id"
      return-object
      v-model="storehouse_f"

      :label="!darkTheme ? 'Выбор склада' : ''"
      :placeholder="darkTheme ? 'Выбор склада' : ''"
      :outline="darkTheme"
    ></v-select>

  <v-layout class="fs-teacher-order-result" mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="product_categories"
          hide-actions
          no-data-text="Нет категорий для выбранного склада"
          class="elevation-1 fs-admin-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.created_at | transformDate }}</td>
              <td>{{ props.item.title }}</td>
              <td>
                <v-flex row>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="handleClickEditCategory(props.item.id)"
                      >edit</v-icon>
                    </template>
                    <span>Редактирование</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="handleDeleteCategory(props.item.id)"
                        class="fs-active-icon"
                      >delete</v-icon>
                    </template>
                    <span>Удалить</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog width="30%" v-model="isShowModal">

      <v-form ref="categoryForm" v-model="valid">
        <v-card>
          <v-toolbar elevation="1" dense light>
            <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="closeCategoryModal">
              <v-icon class="fs-active-icon">close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-text-field
              v-model="category.title"
              :rules="[rules.required]"
              @keydown.enter="handleClickSaveOrUpdate"

              :label="!darkTheme ? 'Наименование категории' : ''"
              :placeholder="darkTheme ? 'Наименование категории' : ''"
              :outline="darkTheme"
            />
          </v-card-text>

          <v-card-actions>
            <v-btn @click="handleClickSaveOrUpdate">
              {{ isCreate ? `Создать` : `Обновить` }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <div class="text-xs-center" v-if="pagination_count > 1">
      <v-pagination
        @input="handlePaginationClick"
        :value="product_categories_pagination.page"
        :length="pagination_count"
        :total-visible="7"
      ></v-pagination>
    </div>

    <ConfirmModal
      :dialog.sync="confirmDeleteModal"
      @confirm="handlerConfirmModal"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
    >
      <template v-slot:headline>Вы действительно хотите удалить категорию продукта?</template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ConfirmModal from '@/components/common/Modals/ConfirmModal'
import { getProductCategoryDetail, createProductCategory, updateProductCategory, deleteProductCategory } from '@/router/routes/common'
import { getStorehouses } from '@/router/routes/admin'
import store from "@/store"
export default {
  name: 'AdminProductCategories',
  components: { ConfirmModal },
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      confirmDeleteModal: false,
      product_id: null,

      headers: [
        { text: 'Дата создания', value: 'created_at' },
        { text: 'Наименование', value: 'title' },
        { text: 'Операции', value: 'actions' }
      ],

      rules: {
        required: (v) => !!v || "Поле обязательное для заполнения"
      },

      valid: true,

      category: {
        title: '',
        deleted: null,
        storehouse: null
      },

      storehouses: [],
      storehouse_f: '',

      isShowModal: false,
      isCreate: false
    }
  },
  computed: {
    ...mapGetters([ 'product_categories', 'product_categories_pagination', 'currentUser' ]),
    pagination_count() {
      return Math.ceil(this.product_categories.count / this.product_categories_pagination.page_size) || 0
    },
    modalTitle() {
      return this.isCreate ? 'Создать категорию' : 'Редактировать категорию'
    }
  },
  methods: {
    ...mapActions([ 'getProductCategories' ]),
    ...mapMutations([ 'set_product_categories_pagination', 'setShowPreloader' ]),
    async handleClickEditCategory(id) {
      await getProductCategoryDetail(id)
        .then(res => {
          this.category = res.data
          this.isCreate = false
          this.isShowModal = true
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    },
    handleClickSaveOrUpdate() {
      this.$refs.categoryForm.validate()
      if (this.valid) {
        if (this.isCreate) {
          createProductCategory({
            ...this.category,
            storehouse: this.storehouse_f.id
          })
            .then(() => {
              this.closeCategoryModal()
              this.fetchProductCategories()
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              this.setShowPreloader(false)
            })
        } else {
          updateProductCategory(this.category)
            .then(() => {
              this.closeCategoryModal()
              this.fetchProductCategories()
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              this.setShowPreloader(false)
            })
        }
      }

    },
    handleClickCreateCategory() {
      this.isCreate = true
      this.category = {}
      this.isShowModal = true
    },
    handleDeleteCategory(id) {
      this.product_id = id
      this.confirmDeleteModal = true
    },
    handlerConfirmModal(value) {
      if (value) {
        deleteProductCategory(this.product_id)
          .then(() => {
            if (this.product_categories.results && this.product_categories.results.length === 1) {
              this.set_product_categories_pagination({ page: 1 })
            }
            this.fetchProductCategories()
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.setShowPreloader(false)
          })
      } else {
        this.confirmDeleteModal = false
      }
      this.product_id = null
    },
    handlePaginationClick(page) {
      this.set_product_categories_pagination({ page })
      this.fetchProductCategories()
    },
    closeCategoryModal() {
      this.category = {}
      this.isShowModal = false
    },
    fetchProductCategories() {
      this.getProductCategories({
        page: this.product_categories_pagination.page,
        page_size: this.product_categories_pagination.page_size,
        school: this.currentUser.school_id,
        storehouse: this.storehouse_f.id
      })
    },
    fetchStorehouses() {
      getStorehouses({
        params: {
          page: 1,
          page_size: 1000,
          school: this.currentUser.school_id
        }
      })
        .then(response => {
          const itemsArray = response.data.results.map(item => ({ id: item.id, name: item.name }))
          this.storehouses = [ ...this.storehouses, ...itemsArray ]
          if (this.storehouses && this.storehouses.length > 0) {
            this.storehouse_f = this.storehouses[0]
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.setShowPreloader(false)
        })
    }
  },
  watch: {
    storehouse_f() {
      this.set_product_categories_pagination({ page: 1 })
      this.fetchProductCategories()
    }
  },
  async mounted() {
    this.fetchStorehouses()
  }
}
</script>
