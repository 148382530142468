<template>
  <v-card flat>
    <v-card-text v-for="item in form.questions" :key="item.title">
      <v-checkbox
        v-if="item.type !== 'text'"
        v-model="item.value"
        :label="`${item.title}: ${item.value ? 'Да': 'Нет'}`"
      />
      <v-text-field
        v-else
        v-model="item.value"
        :label="!darkTheme ? item.title : ''"
        :placeholder="darkTheme ? item.title : ''"
        :outline="darkTheme"
        :hide-details="darkTheme"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        flat
        @click="update"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from "@/store"
export default {
  name: 'Form8',
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      form: {
        id: null,
        questions: [
          { title: 'Школы', value: false, name: 'schools' },
          { title: 'Комбината школьного питания', value: false, name: 'food_combine' },
          { title: 'Организации общественного питания, обслуживающего школу', value: false, name: 'catering_organization' },
          { title: 'ООО, ИП, обслуживающего школу/ наименование,№ договора', value: false, name: 'llc', type: 'text' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([ 'foodBlockPassport' ])
  },
  mounted() {
    this.getFoodblockSchedule(this.foodBlockPassport.schedule_sections.id)
      .then(result => this.setForm(result))
  },
  methods: {
    ...mapMutations([ 'setShowPreloader', 'setShowPreloader', 'updateFoodBlockTypes' ]),
    ...mapActions([ 'getFoodblockSchedule', 'updateFoodBlockSchedule' ]),
    prepareObjectForApi() {
      const res = {}
      this.form.questions.forEach(item => {
        res[item.name] = item.value
      })
      res["passport"] = this.foodBlockPassport.id
      return res
    },
    setForm(data) {
      this.form.id = data.id
      this.form.passport = data.passport
      this.form.questions.forEach(item => {
        for (const key in data) {
          if (item.name === key) item.value = data[key]
        }
      })
    },
    update() {
      const requestBody = this.prepareObjectForApi()
      this.updateFoodBlockSchedule({ id: this.form.id, data: requestBody })
        .then(result => this.setForm(result))
    }
  },

}
</script>
