<template>
  <v-container class="fs-organizer-container">
    <div class="d-inline-flex">
      <h1>{{ title }}</h1>
      <context-help-modal :context-key="contextKeys.supplier.food.create" />
    </div>
    <v-form>
      <v-layout row justify-space-around wrap>
        <v-flex class="pr-2" md6>
          <v-text-field v-model="item.name" label="Наименование"></v-text-field>
          <v-text-field v-model="item.price" label="Цена, руб (на 100 г)"></v-text-field>
           <v-autocomplete
             v-if = "setting('recipe_food_category')"
             label="Категория еды"
             v-model="currentFoodCategory"
             :items="foodCategories"
             clearable
             item-text="name"
             item-value="id"
          ></v-autocomplete>
          <v-text-field v-model="item.energy" label="Энергетическая ценность, кКал/кДж (на 100 г)"></v-text-field>
          <v-text-field v-model="item.protein" label="Белки, г (на 100 г)"></v-text-field>
          <v-text-field v-model="item.fats" label="Жиры, г (на 100 г)"></v-text-field>
          <v-text-field v-model="item.carbs" label="Углеводы, г (на 100 г)"></v-text-field>
        </v-flex>
        <v-flex class="pr-2" md6>
          <v-text-field label="Витамины A (мкг на 100 г)" v-model="item.vitamins.a"></v-text-field>
          <v-text-field label="Витамины В1 (мкг на 100 г)" v-model="item.vitamins.b1"></v-text-field>
          <v-text-field label="Витамины C (мкг на 100 г)" v-model="item.vitamins.c"></v-text-field>
          <v-text-field label="Витамины E (мкг на 100 г)" v-model="item.vitamins.e"></v-text-field>
          <v-text-field label="Минеральные вещества Ca (мкг на 100 г)" v-model="item.minerals.ca"></v-text-field>
          <v-text-field label="Минеральные вещества Mg (мкг на 100 г)" v-model="item.minerals.mg"></v-text-field>
          <v-text-field label="Минеральные вещества Fe (мкг на 100 г)" v-model="item.minerals.fe"></v-text-field>
        </v-flex>
        <v-flex md12>
          <v-text-field v-if = "setting('recipe_food_category')"  v-model="item.recipe"  label="Номер рецепта"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12>
          <v-checkbox
            v-model="item.additionally"
            label="Дополнительное"
          ></v-checkbox>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex>
          <v-data-table
            :headers="dishAgeCategoryHeaders"
            :items="item.ageCategoires"
            hide-actions
            class="elevation-1"
          >
            <template v-slot:items="props">
              <tr>
                <td>{{ props.item.name }}</td>
                <td>
                  <v-text-field v-model="props.item.gramms" suffix="гр."></v-text-field>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <v-flex text-md-right mt-3>
        <v-btn class="mx-0" @click="$router.push({ name: 'Dishes' })">Отмена</v-btn>
        <v-btn class="primary mx-0 ml-2" @click="save()">Сохранить</v-btn>
      </v-flex>
    </v-form>
  </v-container>
</template>

<script>
import Title from '@/ui-components/Title'

import { createDish } from '@/router/routes/organizer'
import store from '@/store'
import DishFactory from '@/factories/organizer/dish-factory'
import Vue from 'vue'
import _ from 'lodash'
import numable from '@/mixins/balancable'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'
import {mapGetters} from "vuex";

export default {
  name: 'DishCreate',
  mixins: [ numable ],
  components: {
    ContextHelpModal,
    Title
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      title: 'Создать блюдо',
      item: new DishFactory(),
      dishAgeCategoryHeaders: [
        { text: 'Возрастная категория', value: 'age-category' },
        { text: 'Выход блюда, г', value: 'gramms' }
      ],
      currentFoodCategory: null,
    }
  },
  computed: {
    ...mapGetters(['foodCategories', 'setting']),
  },
  methods: {
    save() {
      const weightPrimary = _.find(this.item.ageCategoires, item => item.alias === 'weightPrimary')
      const weightMiddle = _.find(this.item.ageCategoires, item => item.alias === 'weightMiddle')


      const payload = {
        name: this.item.name,
        foodCategory: this.currentFoodCategory || null,
        recipe: this.item.recipe,
        weight_primary: weightPrimary.gramms,
        weight_middle: weightMiddle.gramms,
        energy_value: this.item.energy,
        vitamin_b1: this.item.vitamins.b1,
        vitamin_c: this.item.vitamins.c,
        vitamin_a: this.item.vitamins.a,
        vitamin_e: this.item.vitamins.e,
        miniral_ca: this.item.minerals.ca,
        miniral_fe: this.item.minerals.fe,
        miniral_mg: this.item.minerals.mg,
        protein: this.item.protein,
        fat: this.item.fats,
        carbohydrates: this.item.carbs,
        price: this.convertToCents(this.item.price),
        is_additional: this.item.additionally
      }

      createDish(payload).then(response => {
        const dish = response.data.data
        if (response.data.success) {
          notify.success(Vue.config.notifications.dishes.createSuccess)
        }
        store.commit('setShowPreloader', false)
        this.$router.push({ name: 'DishesView', params: { id: dish.id } })
      })
    }
  }
}
</script>
