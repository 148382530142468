<template>
  <ParentIndex :is-child-view="true" />
</template>

<script>
import ParentIndex from '@/components/parent/Index'

export default {
  name: 'PupilIndex',
  components: {
    ParentIndex
  }
}
</script>
