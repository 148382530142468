<template>
  <v-layout row justify-center>
    <v-dialog v-model="$props.show" fullscreen>
      <v-card ms3 me3>
        <v-card-title class="headline">
          <h2>Политика конфиденциальности</h2>
        </v-card-title>
        <v-card-text>
          <ckeditor
            v-model="modalText"
            :editor="editor"
            :config="editorConfig"
          ></ckeditor>
        </v-card-text>
        <v-card-actions>
          <v-layout align-center justify-space-between row fill-height>
            <v-btn ms3 flex left @click="closeDialog">Отмена</v-btn>
            <v-btn me3 flex right @click="save">Сохранить</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import store from '@/store'
import notify from '@/utils/notifications/toastNotification'
import ckeditorMixin from '@/mixins/ckeditorMixin'
import { createPrivacyPolitics } from '@/router/routes/organizer'

export default {
  name: 'PrivacyModal',
  mixins: [ ckeditorMixin ],

  props: {
    show: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      modalText: null
    }
  },
  methods: {
    load() {
      this.modalText = this.content
    },
    closeDialog() {
      this.$emit('update:show', false)
    },
    async save() {
      const content = this.modalText
      if (content.length <= 0) {
        notify.error('Нельзя сохранить пустой документ') //pass
        return
      }
      const res = await createPrivacyPolitics({ content })
      if (res.data.content) {
        notify.success('Успешно')
        this.$emit('update:show', false)
        this.$parent.setContent(res.data.content)
      }
      store.commit('setShowPreloader', false)
    }
  },
  watch: {
    show() {
      this.load()
    }
  }
}
</script>
