<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <div :style="customStyle" v-on="on">
        <slot></slot>
      </div>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "with-tooltip",
  props: {
    text: {
      default: '',
      type: String
    },
	  customStyle: {
		  default: 'display: inline-block;',
		  type: String
	  },
  }
}
</script>

<style scoped>
.v-tooltip {
  display: none;
}
</style>