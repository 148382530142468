<template>
	<v-card v-if="!isFake" class="fs-fields-group--gray">
		<context-help-modal style="float: right" :context-key="contextKeys.admin.students.card.balance" />
		<v-card-title>
			<h1>Баланс</h1>
		</v-card-title>
		<v-card-text>
			<v-divider></v-divider>
			<v-layout mb-3 mt-3>
				<v-flex>
					<span class="fs-student-info__key">Текущий баланс:</span>
				</v-flex>
				<v-flex text-md-right>
					<span class="fs-student-info__key">{{ balance || 0 }} р.</span>
				</v-flex>
			</v-layout>
		</v-card-text>
	</v-card>
</template>

<script>
import Vue from "vue"
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"

export default {
  name: 'PupilCardBalance',
  components: { ContextHelpModal },
  props: {
    isFake: {
      type: Boolean,
      default: false
    },
    balance: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
	    contextKeys: Vue.config.contextHelp,
    }
  }
}
</script>