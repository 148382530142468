<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-dish"
      row
    >
      <v-flex
        md6
        mb-5
      >
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex md12 mb-5>
        Фильтры
        <v-text-field
          append-icon="search"
          label="Поиск по ID или по имени пользователя"
          @keyup.enter="clickSearch"
          @click:clear="clearSearch"
          clearable
          v-model="search_field"
          style="margin: 0px 10px;"
        />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex md12 mb-5>
        <div style="display: flex; align-items: center;">
          <div style="display: flex; align-items: center; margin-right: 15px;">
            <span style="font-weight: bold;">C</span>
            <DateTimePicker onlyDate v-model="start_date" />
          </div>
          <div style="display: flex; align-items: center;">
            <span style="font-weight: bold;">По</span>
            <DateTimePicker onlyDate v-model="end_date" />
          </div>
          <v-btn
            v-if="start_date || end_date"
            flat
            @click="clearDateFilter"
            class="fs-btn fs-btn--primary fs-btn__add-dish"
          >очистить</v-btn>
        </div>
      </v-flex>
    </v-layout>
    <v-layout mb-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          no-data-text="Журнал пуст"
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{props.item.user }}</td>
              <td>{{props.item.name}}</td>
              <td>{{props.item.data}}</td>
              <td>{{ props.item.date }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getLog } from '@/router/routes/superadmin'
import scrollable from '@/mixins/scrollable'
import DateTimePicker from '@/components/common/DatePickersSmart/DateTimePicker'
import store from '@/store'
import { mapMutations } from 'vuex'
import moment from 'moment'
import Vue from 'vue'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ModuleAdminOPList',
  mixins: [ scrollable ],
  components: { DateTimePicker },
  data() {
    return {
      search_field: '',
      start_date: '',
      end_date: '',
      limit: Vue.config.general.limit,
      title: 'Журнал',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Пользователь', value: 'user' },
        { text: 'Название модели', value: 'name' },
        { text: 'Данные', value: 'data' },
        { text: 'Дата', value: 'date' }
      ],
      items: []
    }
  },
  created() {
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },

  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    clickSearch() {
      this.clearItemsAndPagination()
      this.loadMore()
    },
    clearSearch() {
      this.search_field = ''
      this.clearItemsAndPagination()
      this.loadMore()
    },

    clearItemsAndPagination() {
      this.size = 0
      this.offset = 0
      this.items = []
    },

    clearDateFilter() {
      this.start_date = ''
      this.end_date = ''
      this.clearItemsAndPagination()
      this.loadMore()
    },

    transformDateFromDatePicker(dateString) { // 2021-05-05T12:14:00.000Z => 05-05-2021
      const [ year, month, date ] = dateString.split('T')[0].split('-')
      return `${date}-${month}-${year}`
    },

    async loadMore() {
      try {
        const PAYLOAD = {
          limit: this.limit,
          offset: this.offset,
          search: this.search_field === '' ? null : this.search_field,
          start_date: this.start_date && this.end_date ? this.transformDateFromDatePicker(this.start_date) : null,
          end_date: this.start_date && this.end_date ? this.transformDateFromDatePicker(this.end_date) : null,
        }
        const response = await getLog(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, dateCreated, contentType, serializedData, user } = item
          return {
            id: id,
            user: this.getFullUserName(user),
            name: contentType,
            data: serializedData,
            date: this.formatDate(dateCreated)
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки журнала') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    getFullUserName(user) {
      if (user == null) return 'Система'
      return user
    },
    formatDate(datetime) {
      // console.info(datetime)
      return moment(datetime, 'DD-MM-YYYY hh:mm:ss').format(
        'DD.MM.YYYY hh:mm:ss'
      )
    },
    formatData(data) {
      return JSON.parse(data)[0].fields
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    },
    start_date(){
      if (!this.start_date || !this.end_date) {
        return
      }
      this.items = []
      this.loadMore()
    },
    end_date() {
      if (!this.start_date || !this.end_date) {
        return
      }
      this.items = []
      this.loadMore()
    }
  }
}
</script>
