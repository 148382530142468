import Vue from 'vue'
import Router from 'vue-router'

import Page from '@/layouts/common/Page'

import Authorization from '@/components/Auth'
import NotFound from '@/components/NotFound'
import Unauthorized from '@/components/Unauthorized'
import Forbidden from '@/components/Forbidden'
import Success from '@/components/Success'
import Fail from '@/components/Fail'
import SelectCrossAuthRole from '@/components/common/SelectCrossAuthRole/Index.vue'
import OrganizerIndex from '@/components/organizer/Index'
import TeacherIndex from '@/components/teacher/Index'
import ParentIndex from '@/components/parent/Index'
import AdminIndex from '@/components/admin/Index'
import MunicipalityIndex from '@/components/municipality/Index'
import SuperMunicipalityIndex from '@/components/supermunicipality/Index'
import ModuleAdminIndex from '@/components/moduleadmin/Index'
import PupilIndex from '@/components/pupil/Index'

import Instructions from '@/components/common/Help/Instructions'
import Videos from '@/components/common/Help/Videos'
import Questions from '@/components/common/Help/Questions'

import organizers from './childroutes/organizers'
import teachers from './childroutes/teachers'
import parents from './childroutes/parents'
import administrators from './childroutes/administrators'
import municipalities from './childroutes/municipalities'
import supermunicipality from './childroutes/supermunicipality'
import moduleadmin from './childroutes/moduleadmin'

import store from '@/store'
import {loadSettings, loginRoles, logout} from '@/router/routes/common'
import { parseSettings } from "@/utils/parseSettings"
import pupils from './childroutes/pupils'
import EsiaSettings from "@/components/organizer/Esia/EsiaSettings.vue";
import AuthSuccess from "@/components/esia/AuthSuccess.vue";
import {getRole} from "@/utils/roles";

Vue.use(Router)

const baseRouter = {
  mode: 'history',
  routes: [
    {
      path: '',
      name: 'AuthIndex',
      component: Authorization
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound
    },
    {
      path: '/401',
      name: 'Unauthorized',
      component: Unauthorized
    },
    {
      path: '/403',
      name: 'Forbidden',
      component: Forbidden
    },
    {
      path: '/select-cross-auth-role',
      name: 'SelectCrossAuthRole',
      component: SelectCrossAuthRole
    },
    {
      path: '/help',
      name: 'Help',
      component: Page,
      children: [
        {
          path: 'instructions',
          name: 'HelpInstructions',
          component: Instructions,
          meta: {
            title: 'Инструкции'
          }
        },
        {
          path: 'videos',
          name: 'HelpVideos',
          component: Videos,
          meta: {
            title: 'Видеоматериалы'
          }
        },
        {
          path: 'questions',
          name: 'HelpQuestions',
          component: Questions,
          meta: {
            title: 'Часто задаваемые вопросы'
          }
        }
      ]
    },
    {
      path: '/success',
      name: 'SberbankSuccess',
      component: Success
    },
    {
      path: '/fail',
      name: 'SberbankFail',
      component: Fail
    },
    {
      path: '/organizers/dashboard',
      component: OrganizerIndex,
      props: route => ({ query: route.query.q }),
      children: organizers
    },
    {
      path: '/teachers',
      component: TeacherIndex,
      props: route => ({ query: route.query.q }),
      children: teachers
    },
    {
      path: '/parents',
      component: ParentIndex,
      props: route => ({ query: route.query.q }),
      children: parents
    },
    {
      path: '/administrators',
      component: AdminIndex,
      props: route => ({ query: route.query.q }),
      children: administrators
    },
    {
      path: '/municipalities',
      component: MunicipalityIndex,
      props: route => ({ query: route.query.q }),
      children: municipalities
    },
    {
      path: '/supermunicipality',
      component: SuperMunicipalityIndex,
      props: route => ({ query: route.query.q }),
      children: supermunicipality
    },
    {
      path: '/moduleadmin',
      component: ModuleAdminIndex,
      props: route => ({ query: route.query.q }),
      children: moduleadmin
    },
    {
      path: '/esia-success',
      name: 'EsiaSuccessConnect',
      component: AuthSuccess,
      meta: { userRole: 'supplier' }
    },
  ]
}

baseRouter.routes.push({
  path: '/pupils',
  component: PupilIndex,
  props: route => ({ query: route.query.q }),
  children: pupils
})

const router = new Router(baseRouter)

const getBearerLocal = () => sessionStorage.getItem('token')

router.beforeEach(async function(to, from, next) {

  const bearer = getBearerLocal()
  console.log(`to: ${to.name}`)
  if (from.name === null) {
    if ([
      'AuthIndex',
      'Supermunicipality',
      'Municipality',
      'Administrator',
      'Teacher',
      'Parent',
      'Pupil'
    ].includes(to.name)) {
      console.log('Access auth page from nowhere')
      // If access to auth page from nowhere

      if (to.query.session_id) {
        // 1. Check session_id token
        console.log('Session ID query found, skip check')
      } else {
        // 2. Check bearer token
        if (bearer === null && to.name !== 'AuthIndex') {
          console.log('Session not found')
          location.href = '/'
        } else {
          console.log('Bearer exists')
        }
      }

    } else {
      // If access to non auth page from nowhere by direct link or Parent
      // 1. Check Bearer token first
      // 2. If not -> redirect to Auth
      if ([ 'SberbankSuccess', 'SberbankFail' ].includes(to.name)) {
        console.log('SberPage')
      } else if (
          bearer === null &&
          to.name !== 'AuthIndex' &&
          to.name !== 'EsiaSuccessConnect' &&
          to.name !== 'SelectCrossAuthRole'
      ) {
        console.log('Session bearer token not found')
        location.href = '/'
      } else {
        console.log('Bearer check')
      }

    }
  } else {
    // Move from known to known
    console.log('K2K')
  }

  if (!store.getters.setting('user_help')) {
    const res = await loadSettings()
    if (res.data) {
      store.commit('setSettings', parseSettings(res.data))
    }
  }

  const options = {}

  if ('logout' in to.query && to.query.logout === 'true') {
    console.log('router logout')
    await logout().then(() => {
      options.path = '/'
    }, () => {
      return
    }).finally(() => { store.commit('setShowPreloader', false) })
  }
  else if (
      to.path.match(/^\/parents\/.+/)
      && !store.getters.currentChild
      && to.name !== 'PupilSelectionList'
  ) {
    options.path = '/parents/'
  }
  else if (
      to.name !== 'Forbidden'
      && to.name !== 'Unauthorized'
      && to.name !== 'AuthIndex'
      && !store.getters.currentUser
  ) {
    console.log('to name: forbidden, unauth, authindex')
    if (to.name === 'SelectCrossAuthRole') {
      console.log('next to crossauth')
      return next(options)
    }

    // DO AUTH IF IT CROSSAUTH REQUEST
    if (
      [ 'Administrator', 'Municipality', 'Parent', 'Pupil', 'Supermunicipality', 'Teacher' ].includes(to.name)
    ) {
      if (to.query.session_id) {
        console.log('router get user role')
        let roleData = getRole(to.query.role || store.getters.active_role)
        if (!to.query.role) {
          await store.dispatch('fetchAvailableRoles', {params:  {session_id: to.query.session_id}})
          const roles = store.getters.available_roles
          if (roles.length <= 1) {
            to.query.role = roles[0]
            roleData = getRole(roles[0])
            options.path = roleData.routePath
          } else {
            console.log('next to crossauth-2')
            return next({...options, path: 'select-cross-auth-role', query: { session_id: to.query.session_id}})
          }
        }
        store.commit('set_active_role', roleData)
        await store.dispatch('ADMIN_LOGIN', { session_id: to.query.session_id, role: to.query.role})
      }
    }

    if (to.name !== 'EsiaSuccessConnect') {
      await store.dispatch('GET_USER_PROFILE').then(() => {
        if (to.name !== 'AuthIndex' && !to.query.session_id) {
          console.log('reauth set true')
          to.params.reauth = true
        }
      }, () => {
        return
      }).finally(() => { store.commit('setShowPreloader', false) })
    }
  }
  else if (from.path === '/') {
    to.params.reauth = true
  }

  next(options)
})

export default router
