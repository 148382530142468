<template>
  <v-dialog v-model="dialog" :max-width="width" scrollable persistent>
    <v-card>
      <v-card-title class="headline">
        <slot name="headline"></slot>
        <v-spacer></v-spacer>
        <slot name="headright"></slot>
      </v-card-title>
      <v-card-text>
        <ckeditor
          :editor="editor"
          v-model="contentLocal"
          :config="editorConfig"
        ></ckeditor>
      </v-card-text>
      <v-card-actions>
        <v-layout align-center justify-space-between row fill-height>
          <v-btn flex left @click="closeDialog()">Отмена</v-btn>
          <v-btn flex right @click="closeDialog('accept')">Создать</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ckeditorMixin from '@/mixins/ckeditorMixin'

export default {
  name: 'TextEditorModal',
  mixins: [ ckeditorMixin ],
  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    content: {
      type: String,
      default: '',
      required: true
    },
    confirm: {
      type: Boolean,
      default: false
    },
    width: {
      default: 1000
    }
  },
  data: () => ({
    contentLocal: ''
  }),
  methods: {
    async closeDialog(type) {
      let answer = false
      if (type === 'accept') {
        this.$emit('update:content', this.contentLocal)
        answer = true
      }
      this.$emit('update:dialog', false)
      this.$emit('confirm', answer)
    }
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.contentLocal = this.content
      }
    }
  }
}
</script>

<style></style>
