<template>
  <v-container fluid class="fs-admin-container">
    <v-form ref="form">
      <v-layout md8 offset-md2 mb-5>
        <v-flex>
          <v-flex mb-5>
            <h1>{{ companyName }}</h1>
          </v-flex>
          <v-layout row justify-space-between>
            <v-flex md5>
              <template v-for="(item, index) in organizer">
                <p
                  :key="item.label"
                  v-if="index % 2 == 0"
                  class="fs-admin-info fs-admin-info__edit"
                >
                  <v-text-field
                    :readonly="isViewMode"
                    v-if="showField(item.key)"
                    :label="item.label"
                    v-model="item.value"
                    :rules="item.rule"
                    :placeholder="item.placeholder"
                    @blur="() => (item.value = item.value.trim())"
                    >{{ item.value }}</v-text-field
                  >
                </p>
              </template>
            </v-flex>
            <v-flex md5>
              <template v-for="(item, index) in organizer">
                <p
                  v-if="index % 2 != 0"
                  :key="item.key"
                  class="fs-admin-info fs-admin-info__edit"
                >
                  <v-text-field
                    v-if="showField(item.key)"
                    v-model="item.value"
                    :label="item.label"
                    :readonly="isViewMode"
                    :rules="item.rule"
                    :placeholder="item.placeholder"
                    @blur="() => (item.value = item.value.trim())"
                    >{{ item.value }}</v-text-field
                  >
                </p>
                <v-checkbox
                  v-if="item.key === 'isBudget'"
                  v-model.trim="item.value"
                  :key="item.key"
                  :label="item.label"
                  :readonly="isViewMode"
                  :placeholder="item.placeholder"
                  hide-details
                >
                  {{ item.value }}
                </v-checkbox>
              </template>
              <div class="fs-student-info__value pt-2" v-if="!isViewMode">
                <h3>Школы на обслуживании ({{ countSchools() }})</h3>
	              <a @click="assignSchoolsDialog = true">назначить</a>
              </div>

	            <AssignSchoolsModal
			            v-model="assignSchoolsDialog"
			            v-if="!isViewMode"
			            :schools="schools"
			            :width="960"
			            @assignSchools="assignSchools"
			            className="fs-moduleadmin-contanier"
	            ></AssignSchoolsModal>

              <div class="fs-student-info__value pt-2" v-if="!isViewMode">
                <h3>Типы питания ({{ countFoodTypes() }})</h3>
                <a @click="assignFoodTypesDialog = true">назначить</a>
              </div>
              <AssignFoodTypessModal
                v-if="!isViewMode"
                v-model="assignFoodTypesDialog"
                :foodTypes="foodTypes"
                :width="960"
                @assignFoodTypes="assignFoodTypes"
                className="fs-moduleadmin-contanier"
              ></AssignFoodTypessModal>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex text-md-right>
          <v-btn
            flat
            class="fs-btn fs-btn--secondary fs-admin-btn__cancel v-btn--active"
            @click="$router.push({ name: 'MunicipalityOrganizersIndex' })"
            >назад</v-btn
          >
          <v-btn
            v-if="!isViewMode"
            flat
            class="fs-btn fs-btn--secondary fs-admin-btn__cancel v-btn--active"
            @click="update"
            >сохранить</v-btn
          >
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  getOrganizer,
  getSchools,
  getAllMealTypes,
  assignSchools,
  assignFoodTypes,
  updateOrganizer
} from '@/router/routes/department'
import store from '@/store'
import AssignSchoolsModal from '@/components/municipality/Modals/AssignSchoolsModal'
import AssignFoodTypessModal from '@/components/municipality/Modals/AssignFoodTypesModal'
import _ from 'lodash'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'MunicipalityOrganizerItem',
  components: { AssignSchoolsModal, AssignFoodTypessModal },
  async created() {
    await this.getOrganizer()
  },
  computed: {
    ...mapGetters(['currentUser', 'setting']),
    isViewMode() {
      return this.$route.meta === 'view'
    }
  },
  data() {
    return {
      companyName: '',
      organizer: [],
      assignSchoolsDialog: false,
      assignFoodTypesDialog: false,
      headers: [
        { text: 'Образовательная организация', value: 'School' },
        { text: 'Операции', value: 'operations' }
      ],
      items: [],
      org: null,
      schools: [],
      foodTypes: [],
      department: null,
      selectedFoodTypes: [],
      selectedDryFoodTypes: [],
      selectedSubsidyFoodTypes: [],
      selectedSchools: [],
      rules: {
        required: (value) => !!value || 'Обязательное поле',
        strict10or12: (value) => {
          if (value.length === 10 || value.length === 12) return true
          return '10 или 12 символов'
        },
        strict8: (value) => value.length === 8 || '8 символов',
        strict9: (value) => value.length === 9 || '9 символов',
        strict12: (value) => value.length === 12 || '12 символов',
        strict15: (value) => value.length === 15 || '15 символов',
        strict20: (value) => value.length === 20 || '20 символов',
        min13: (value) => value.length >= 13 || 'Не менее 13 символов',
        max11: (value) => value.length <= 11 || 'Не более 11 символов',
        max15: (value) => value.length <= 15 || 'Не более 15 символов',
        max20: (value) => value.length <= 20 || 'Не более 20 символов',
        max100: (value) => value.length <= 100 || 'Не более 100 символов',
        max150: (value) => value.length <= 150 || 'Не более 150 символов',
        max200: (value) => value.length <= 200 || 'Не более 200 символов',
        max254: (value) => value.length <= 254 || 'Не более 254 символов',
        max255: (value) => value.length <= 255 || 'Не более 255 символов',
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Укажите корректный email'
        },
        number: (value) => {
          const pattern = /^[0-9]*$/
          return pattern.test(value) || 'В этом поле должны быть только цифры'
        },
        phone: (value) => {
          const pattern = /^[78]\d{10}$/
          return pattern.test(value) || 'Укажите корректный номер телефона'
        }
      },
      newSchools: [],
      oldSchools: []
    }
  },
  methods: {
    ...mapMutations(['setShowPreloader']),

	  async assignSchools({ schools, reset_balance }) {

		  const selectedSchools = _.map(schools, item => {
			  if (item.selected) return item.id
		  }).filter(x => !!x)

		  const oldSchools = this.oldSchools.filter(item => !selectedSchools.includes(item))
		  const newSchools = selectedSchools.filter(item => !this.oldSchools.includes(item))

		  const payload_old = []
		  const payload_new = []

		  oldSchools.forEach(item => {
			  payload_old.push({
				  supplier_id: this.$route.params.id,
				  school_id: item,
				  action: 'REFUSE'
			  })
		  })

		  newSchools.forEach(item => {
			  payload_new.push({
				  supplier_id: this.$route.params.id,
				  school_id: item,
				  action: 'ASSIGN'
			  })
		  })

		  if (this.setting('archive_report_service')) {
			  await axios.post(`${process.env.VUE_APP_ARCHIVE_URL}/school/supplier/`, payload_old)
			  await axios.post(`${process.env.VUE_APP_ARCHIVE_URL}/school/supplier/`, payload_new)
		  }

		  this.schools = schools

		  const payload = {
			  department_id: this.currentUser.department.id,
			  data: {
				  supplierId: this.$route.params.id,
				  schoolIds: selectedSchools,
				  reset_balance: reset_balance
			  }
		  }

		  assignSchools(payload).then(() => {
			  this.selectedSchools = selectedSchools
		  })

		  assignSchools(payload).then(response => {
			  return notify.success('Процесс переноса школ запущен, операция может занять до 1 часа')
		  }).finally(() => store.commit('setShowPreloader', false))
	  },

    countSchools() {
      return this.selectedSchools.length
    },
    assignFoodTypes(foodTypes) {
      const selectedFoodTypes = _.map(foodTypes, (item) => {
        if (item.selected) return item.id
      }).filter((x) => !!x)

      const dryFoodTypesIds = _.map(foodTypes, (item) => {
        if (item.selectedDry) return item.id
      }).filter((x) => !!x)

      const subsidyFoodTypesIds = _.map(foodTypes, (item) => {
        if (item.selectedSubsidy) return item.id
      }).filter((x) => !!x)

      this.foodTypes = foodTypes

      const payload = {
        department_id: this.currentUser.department.id,
        id: this.$route.params.id,
        data: {
          mealTypeIds: selectedFoodTypes,
          dryFoodMealTypeIds: dryFoodTypesIds,
          subsidyMealTypeIds: subsidyFoodTypesIds
        }
      }

      assignFoodTypes(payload)
        .then(() => {
          this.selectedFoodTypes = selectedFoodTypes

          return notify.success('Типы питания назначены успешно')
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    countFoodTypes() {
      return this.selectedFoodTypes.length
    },
    async update() {
      const valid = this.$refs.form.validate()
      if (!valid) return
      const payload = {
        department_id: this.currentUser.department.id,
        supplier_id: this.$route.params.id,
        params: {
          totalDaysInPeriod: 14
        }
      }

      _.forEach(this.organizer, (item) => {
        if (this.showField(item.key) && item.key !== 'isBudget') {
          payload.params[item.key] = item.value
        } else if (item.key === 'isBudget') {
          payload.params[item.key] = item.value
        }
      })

      updateOrganizer(payload)
        .then((response) => {
          if (response.data.success) {
            notify.success('Данные успешно изменены')
            this.$router.push({ name: 'MunicipalityOrganizersIndex' })
          }
        })
        .finally(() => {
          store.commit('setShowPreloader', false)
        })
    },
    async getOrganizer() {
      try {
        const payload = {
          department_id: this.currentUser.department.id,
          supplier_id: this.$route.params.id
        }
        const response = await getOrganizer(payload)
        const org = response.data.data

        this.organizer = [
          {
            key: 'fullName',
            label: 'Полное название',
            value: _.get(org, 'fullName', '') || '',
            rule: [this.rules.required, this.rules.max255],
            placeholder: 'Полное название организации'
          },
          {
            key: 'shortName',
            label: 'Краткое название',
            value: _.get(org, 'shortName', '') || '',
            rule: [this.rules.required, this.rules.max150],
            placeholder: 'Краткое название организации'
          },
          {
            key: 'managerName',
            label: 'Руководитель',
            value: _.get(org, 'managerName', '') || '',
            rule: [this.rules.required, this.rules.max100],
            placeholder: 'Имя Фамилия Отчество'
          },
          {
            key: 'postalAddress',
            label: 'Юридический адрес',
            value: _.get(org, 'postalAddress', '') || '',
            rule: [this.rules.required, this.rules.max255],
            placeholder: 'Юридический адрес организации'
          },
          {
            key: 'legalAddress',
            label: 'Фактический адрес',
            value: _.get(org, 'legalAddress', '') || '',
            rule: [this.rules.required, this.rules.max255],
            placeholder: 'Фактический адрес организации'
          },
          {
            key: 'phone',
            label: 'Телефон',
            value: _.get(org, 'phone', '') || '',
            rule: [this.rules.required, this.rules.phone, this.rules.max11],
            placeholder: '89001112233'
          },
          {
            key: 'site',
            label: 'Сайт',
            value: _.get(org, 'site', '') || '',
            rule: [this.rules.max200],
            placeholder: 'http://example.ru'
          },
          {
            key: 'mail',
            label: 'E-mail',
            value: _.get(org, 'mail', '') || '',
            rule: [this.rules.required, this.rules.email, this.rules.max254],
            placeholder: 'example@mail.ru'
          },
          {
            key: 'inn',
            label: 'ИНН',
            value: _.get(org, 'inn', '') || '',
            rule: [
              this.rules.required,
              this.rules.number,
              this.rules.strict10or12
            ],
            placeholder: '6002002277'
          },
          {
            key: 'kpp',
            label: 'КПП',
            value: _.get(org, 'kpp', '') || '',
            rule: [this.rules.required, this.rules.number, this.rules.strict9],
            placeholder: '600900100'
          },
          {
            key: 'personalAccount',
            label: 'Лицевой счет',
            value: _.get(org, 'personalAccount', '') || '',
            rule: [this.rules.required, this.rules.strict20],
            placeholder: '20090060010'
          },
          {
            key: 'settlementAccount',
            label: 'Расчетный счет',
            value: _.get(org, 'settlementAccount', '') || '',
            rule: [this.rules.required, this.rules.number, this.rules.strict20],
            placeholder: '03230040016200001200'
          },
          {
            key: 'bank',
            label: 'Банк',
            value: _.get(org, 'bank', '') || '',
            rule: [this.rules.required, this.rules.max255],
            placeholder: 'Банк'
          },
          {
            key: 'bic',
            label: 'БИК',
            value: _.get(org, 'bic', '') || '',
            rule: [this.rules.required, this.rules.number, this.rules.strict9],
            placeholder: '006100031'
          },
          {
            key: 'ogrn',
            label: 'ОГРН',
            value: _.get(org, 'ogrn', '') || '',
            rule: [
              this.rules.required,
              this.rules.number,
              this.rules.min13,
              this.rules.max15
            ],
            placeholder: '1030020001110'
          },
          {
            key: 'correspondentAccount',
            label: 'Корр. счет',
            value: _.get(org, 'correspondentAccount', '') || '',
            rule: [this.rules.required, this.rules.number, this.rules.strict20],
            placeholder: '40000810040070001001'
          },
          {
            key: 'oktmo',
            label: 'ОКТМО',
            value: _.get(org, 'oktmo', false) || '',
            rule: [this.rules.required, this.rules.number, this.rules.strict8],
            placeholder: '60020050'
          },
          {
            key: 'cbc',
            label: 'КБК',
            value: _.get(org, 'cbc', false) || '',
            rule: [this.rules.required, this.rules.number, this.rules.strict20],
            placeholder: '00000000000000000100'
          },
          {
            key: 'isBudget',
            label: 'Бюджетная организация',
            value: _.get(org, 'isBudget', false),
            rule: [this.rules.required]
          }
        ]

        if (this.$route.meta === 'view') {
          return
        }
        this.selectedFoodTypes = response.data.data.mealTypeIds
        this.selectedDryFoodTypes = response.data.data.dryFoodMealTypeIds
        this.selectedSubsidyFoodTypes = response.data.data.subsidyMealTypeIds
        this.selectedSchools = response.data.data.schoolIds

	      await this.getSchools()
	      await this.getFoodTypes()
      } catch (error) {
        // notify.error('Не удалось загрузить организацию') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

	  async getSchools() {
		  try {
			  const payload = {
				  department_id: this.currentUser.department.id,
				  params: {
					  limit: 500,
					  offset: 0
				  }
			  }
			  const response = await getSchools(payload)
			  this.schools = _.map(response.data.data, item => {
				  item.selected = this.selectedSchools.includes(item.id)
				  if (this.selectedSchools.includes(item.id)) this.oldSchools.push(item.id)
				  return item
			  })
		  }
		  catch(error) {
			  // notify.error('Не удалось загрузить школы') // backend handler added (exception_message)
		  }
		  finally {
			  store.commit('setShowPreloader', false)
		  }
	  },

    async getFoodTypes() {
      try {
        const payload = {
          department_id: this.currentUser.department.id,
          params: {
            limit: 500,
            offset: 0
          }
        }
        const response = await getAllMealTypes(payload)
        this.foodTypes = _.map(response.data.data, (item) => {
          item.selected = true
          item.selectedDry = false
          item.selectedSubsidy = false
          item.privilegeName = item.privilege ? item.privilege.name : null
          if (!this.selectedFoodTypes.includes(item.id)) item.selected = false
          if (this.selectedDryFoodTypes.includes(item.id)) {
            item.selectedDry = true
          }
          if (this.selectedSubsidyFoodTypes.includes(item.id)) {
            item.selectedSubsidy = true
          }
          return item
        })
      } catch (error) {
        // notify.error('Не удалось загрузить типы питания') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    showField(key) {
      const isBudjetValue = this.organizer.find(
        (item) => item.key === 'isBudget'
      ).value
      switch (key) {
        case 'oktmo':
        case 'cbc':
        case 'personalAccount':
          return isBudjetValue
        default:
          return key !== 'isBudget'
      }
    }
  }
}
</script>
<style scoped>
.v-input >>> .v-text-field__details {
  display: block !important;
}
</style>
