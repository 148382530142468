<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <v-layout mb-3>
      <h1>{{ title }}</h1>
      <context-help-modal :context-key="contextKeys.admin.orders.statusInfo" />
    </v-layout>
    <v-layout class="mb-3" v-if="infoModal">
      <v-flex>
        <v-card>
          <v-card-title class="pb-0 pt-1 justify-space-between">
            <h3>Внимание</h3>
            <v-btn fab dark small style="width: 20px; height: 20px"
                   color="primary"
                   @click="closeInfoModal"
            >
              <v-icon style="font-size: 14px">
                close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-0">
            <p>Рекомендуем не сохранять\подтверждать заявки более чем на день вперед,
              т.к. в случае каких-либо изменений сохраненные и подтвержденные заявки
              уже не переформируются в соответствии с новыми обстоятельствами.
            </p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout
      row
      mb-3
      wrpa
    >
      <v-flex md3 mr-3>
        <DatePickersSmart
          :startDate.sync="dateStart"
          :endDate.sync="dateEnd"
          @changeDate="changeFiltersHandler"
        />
      </v-flex>
      <v-flex md3>
        <v-autocomplete
          v-model="classID"
          :outline="darkTheme"
          :label="!darkTheme ? 'Класс:' : ''"
          :items="classesArray"
          :item-text="'name'"
          :item-value="'id'"
          flat
          dense
          @change="changeFiltersHandler"
        />
      </v-flex>
    </v-layout>

    <v-card>
        <v-card-text>
          <div v-if="isShowFeedingCount" class="mb-2">
            Всего питающихся:
            <span class="font-weight-bold">
              {{ feedingPupilsCount }}
            </span>
          </div>
          <v-layout
            class="fs-teacher-order-result"
            mb-3
          >
            <v-flex>
                  <v-data-table
                    :headers="headers"
                    :items="items"
                    class="elevation-1 fs-teacher-table"
                    item-key="name"
                    no-data-text="Нет данных"
                    hide-actions
                  >
                    <template v-slot:items="props">
                      <tr>
                        <td @click="navigateTo('TeacherOrdersView', props.item)" class="cursor-pointer">{{ props.item.date }}</td>
                        <td @click="navigateTo('TeacherOrdersView', props.item)" class="cursor-pointer">{{ props.item.class }}</td>
                        <td @click="navigateTo('TeacherOrdersView', props.item)" class="cursor-pointer">{{ props.item.statusName || 'не подтверждена' }}</td>
                        <td
                            v-if="showWorkingDays"
                            @click="navigateTo('AdminOrdersTableView', props.item, currentHouseId)"
                            class="cursor-pointer"
                        >
                          <v-icon :style="props.item.is_working_day ? 'color: green' : 'color: red' ">
                            {{ props.item.is_working_day ? "done" : "close" }}
                          </v-icon>
                        </td>
                        <td class="grid-list-md text-xs-center">
                          <v-flex v-if="props.item.ordersErrors.length > 0">
                            <v-tooltip class="rounded-xl" top color="yellow">
                              <template v-slot:activator="{on}">
                                <span v-on="on" class="subheading error--text font-weight-light">
                                  <v-icon color="warning" icon="mdi-information" size="medium">
                                    warning
                                  </v-icon>
                                </span>
                              </template>
                              <ul v-for="(item, index) in props.item.ordersErrors" :key="index">
                                <li class="black--text">
                                  <b>{{item.pupil__saved_last_name}} {{item.pupil__saved_first_name}}.{{item.pupil__saved_middle_name}}</b> - {{item.error}}
                                </li>
                              </ul>
                            </v-tooltip>
                          </v-flex>
                        </td>
                        <td>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-on="on"
                                @click="navigateTo('TeacherOrdersEdit', props.item)"
                              >
                                edit
                              </v-icon>
                            </template>
                            <span>Изменить</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                </v-data-table>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>

    <v-layout class="fs-form-footer">
      <v-flex
        text-md-right
        pt-3
      >
        <v-btn
          class="fs-btn fs-btn--secondary fs-teacher-btn__cancel v-btn--active"
          flat
          @click="$router.go(-1)"
        >Назад</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import moment from 'moment'
import { getOrders } from '@/router/routes/teacher'
import { mapGetters, mapMutations } from 'vuex'
import store from '@/store'
import Vue from 'vue'
import _ from 'lodash'
import { DatePickersSmart } from '@/components/common'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'complexIndex',
  components: {
    ContextHelpModal,
    DatePickersSmart
  },
  data() {
    return {
      showWorkingDays: store.getters.setting('show_working_days_in_orders'),
      contextKeys: Vue.config.contextHelp,
      darkTheme: Vue.config.env.darkTheme,
      title: 'Табель заявок на питание',
      classesArray: [],
      classID: 0,
      headers: [
        { text: 'Дата и время', value: 'date', sortable: false },
        { text: 'Класс', value: 'class', sortable: false },
        { text: 'Статус', value: 'status', sortable: false },
        { text: 'Инфо', value: 'ordersErrors', width: '1', sortable: false },
        { text: 'Операции', value: 'operations', sortable: false }
      ],
      items: [],
      dateStart: moment()
        .subtract(1, 'days')
        .format(Vue.config.format.serverDate),
      dateEnd: moment()
        .add(1, 'days')
        .format(Vue.config.format.serverDate),
      infoModal: true,
      feedingPupilsCount: 0,
      isShowFeedingCount: false,
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
    startDatePython() {
      return moment(this.dateStart).format(Vue.config.format.pythonDate)
    },
    endDatePython() {
      return moment(this.dateEnd).format(Vue.config.format.pythonDate)
    },
    getFeedingPupilsCount: {
      get() {
        return this.feedingPupilsCount
      },
      set(value) {
        this.feedingPupilsCount = value
      }
    },
  },
  mounted() {
    this.getHeaders()
    const value = localStorage.getItem("infoModal")
    if (value === null) {
      localStorage.setItem("infoModal", 'true')
    } else {
      value === 'true' ? this.infoModal = true : this.infoModal = false
    }
  },
  created() {
    this.initParams()
    this.loadOrders()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    getHeaders() {
      if (this.showWorkingDays) {
        this.headers.splice(3, 0, { text: 'Рабочий день', value: 'is_working_day' })
      }
    },
    closeInfoModal() {
      this.infoModal = false
      localStorage.setItem('infoModal', 'false')
    },
    initParams() {
      try {
        this.classesArray = this.currentUser.school_class_list
        this.classID = this.currentUser.school_class_list[0].id
        if (this.$route.params.dateStart && this.$route.params.dateEnd && this.$route.params.classId){
          this.dateStart = this.$route.params.dateStart
          this.dateEnd = this.$route.params.dateEnd
          this.classID = this.$route.params.classId
        }
      } catch (error) {}
    },
    getClassLiter(name) {
      return name.split(' ')[1]
    },
    getClassNumber(name) {
      return name.split(' ')[0]
    },
    async changeFiltersHandler() {
      this.resetData()
      await this.loadOrders()
    },
    resetData() {
      this.items = []
    },
    async loadOrders() {
      try {
        const PAYLOAD = {
          id: this.classID,
          params: {
            startDate: this.dateStart,
            endDate: this.dateEnd
          }
        }
        const response = await getOrders(PAYLOAD)

        if (Object.hasOwn(response.data.data, 'orders_count')) {
          this.feedingPupilsCount = response.data.data.orders_count
          this.isShowFeedingCount = true
        }

        const {
          data: { data: { orders: orders } }
        } = response

        const itemsArray = _.map(orders, item => {
          return {
            date: moment(_.get(item, 'date', null), 'DD-MM-YYYY').format(
              Vue.config.format.date
            ),
            class: _.get(item, 'schoolClass.name', null),
            status: _.get(item, 'tableStatus', null),
            statusName: this.getStatusName(_.get(item, 'tableStatus', null), _.get(item, 'tableStatusName', null), _.get(item, 'status_received_is_auto', null)),
            is_working_day: item.is_working_day,
            ordersErrors: item.order_error
          }
        }).sort((a, b) => {
          return moment(a.date, 'DD.MM.YYYY') - moment(b.date, 'DD.MM.YYYY')
        })
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки заявок') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    getStatusName(status, name, status_received){
      if (status === 4 && status_received) {
        return `${name} (без списания)`
      }
      return name
    },
    navigateTo(where, params) {
      const date = {
        day: moment(params.date, Vue.config.format.date).format('DD'),
        month: moment(params.date, Vue.config.format.date).format('MM'),
        year: moment(params.date, Vue.config.format.date).format('YYYY'),
        class: this.classesArray.find(item => item.id === this.classID),
        returnDateStart: this.dateStart,
        returnDateEnd: this.dateEnd,
      }

      date.id = date.class.id

      this.$router.push({ name: where, params: date })
    }
  }
}
</script>
