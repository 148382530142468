<script>
import EsiaButton from "@/components/esia/components/EsiaButton.vue";
import {getEsiaStatus} from "@/router/routes/organizer";

export default {
  name: 'EsiaSettings',
  components: {EsiaButton},
  data() {
    return {
      snilsInput: null,
      snilsId: null
    }
  },
  methods: {
    async getEsiaData() {
      const { data } = await getEsiaStatus()
      this.snilsInput = data.esia_snils
      this.snilsId = data.esia_id
      store.commit('setShowPreloader', false)
    }
  },
  async mounted() {
    await this.getEsiaData()
  }
}
</script>

<template>
<v-container>
  <v-card>
    <v-card-title>
      <h3>Статус связи профиля с ГосУслугами</h3>
    </v-card-title>
    <v-card-text>
      <div>
        Статус:
        <span v-if="snilsId"><v-icon color="success" size="14">check_circle</v-icon> подключено</span>
        <span v-else><v-icon>cancel</v-icon> не подключено</span>
      </div>
      <div class="d-flex row justify-space-between mt-3">
        <div>
          <v-text-field
              v-model="snilsInput"
              type="text"
              name="snils"
              label="СНИЛС (Формат: ХХХХХХХХХХХ, 11 цифр)"
              prepend-icon="badge"
          ></v-text-field>
        </div>
        <div></div>
        <div class="d-flex row align-center">
          <EsiaButton :is-auth="false" />
        </div>
      </div>
    </v-card-text>
  </v-card>
</v-container>
</template>

<style scoped>

</style>