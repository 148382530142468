<template>
  <v-container
    fluid
    class="fs-parent-container"
  >
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <v-flex mb-2>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout mb-3 row>
      <v-flex md-2>
        <v-alert
            :value="currentChild.isFake"
            type="error"
        >
          <h3>Ошибка: По данному ученику из учётной системы региона получены неполные данные. Работа с ним временно заблокирована. Ожидайте повторной синхронизации.</h3>
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout class="mb-4" v-if="!currentChild.isFake">
      <v-flex>
        Чтобы подать заявление на возврат, необходимо загрузить шаблон заявления - нажать кнопку “Скачать заявление“. Заполнить и распечатать заявление, после чего - сфотографировать или отсканировать заявление и отправить его организатору питания используя кнопку “выберите файл“, после чего нажать кнопку “загрузить заполненное заявление“ для отправки его в систему.
      </v-flex>
    </v-layout>

    <v-layout v-if="!currentChild.isFake">
      <v-flex>
        <v-form>
          <v-layout align-center>
            <v-flex md8>
              Загрузка заявления:
              {{ fileName }}
              <UploadButton
                :uniqueId="true"
                title="Выберите файл"
                noTitleUpdate
                :fileChangedCallback="fileChanged"
                add-class="fs-btn fs-parent-btn fs-btn--secondary"
              />
              <v-btn
                :disabled="!file"
                @click="uploadRequest"
                class="fs-btn fs-parent-btn fs-btn--secondary"
              >Загрузить заполненное заявление</v-btn>
            </v-flex>
            <v-flex
              md4
              class="text-md-left"
            >
              <v-btn
                @click="getPrefillAction"
                class="fs-btn fs-parent-btn fs-btn--secondary"
              >Скачать заявление</v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>

    <v-layout
      class="fs-archive-result"
      mb-3
      v-if="!currentChild.isFake"
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          no-data-text="Заявления на возврат отсутствуют"
          hide-actions
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.created_at }}</td>
              <td>{{ props.item.status_display }}</td>
              <td>{{ props.item.supplier.full_name }}</td>
              <td>
                <v-flex row>
                  <v-btn
                    flat
                    @click="download(props.item.id)"
                  >Скачать</v-btn>
                </v-flex>
                <v-flex row v-if="props.item.status == 1">
                  <v-btn
                    flat
                    @click="revoke(props.item.id)"
                  >Отозвать</v-btn>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <ConfirmModal
      :dialog.sync="showModal"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="handlerConfirmModal"
    >
      <template v-slot:headline>Подтвердите действие</template>
      <template v-slot:body>
        <div>
          Вы действительно хотите отозвать заявление на возврат?
        </div>
      </template>
    </ConfirmModal>

    <ConfirmModal
        :dialog.sync="cashback.show"
        :btnsClass="'fs-btn fs-teacher-btn__cancel'"
        @confirm="handleCashbackModal"
    >
      <template v-slot:headline>Заполните заявление</template>
      <template v-slot:body>
        <div>
          <v-text-field v-model="cashback.parentFullName" label="Укажите Ваше ФИО (полностью, без сокращений)"></v-text-field>
          <v-text-field v-model="cashback.pupilFullName" label="Укажите ФИО ребенка (полностью, без сокращений)"></v-text-field>
        </div>
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import store from '@/store'
import {
  getCashbackRequests,
  uploadCashbackRequest,
  getCashbackPrefill,
  getCashbackDocument,
  revokeCashbackDocument
} from '@/router/routes/parent'
import scrollable from '@/mixins/scrollable'
import _ from 'lodash'
import moment from "moment"
import UploadButton from '@/components/common/UploadButton/UploadButton'
import downloadFile from "@/helpers/downloadFile"
import { ConfirmModal } from '@/components/common'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'CashbackIndex',
  mixins: [ scrollable ],
  components: { UploadButton, ConfirmModal },
  data() {
    return {
      title: 'Заявления на возврат',
      headers: [
        { text: 'Дата подачи', value: 'created_at' },
        { text: 'Статус', value: 'status_display' },
        { text: 'Организатор питания', value: 'supplier.full_name' },
        { text: 'Операции', value: 'actions' }
      ],
      showModalFullName: store.getters.setting('show_modal_in_cashback_parent'),
      items: [],
      fileName: '',
      file: null,
      revokeId: null,
      showModal: false,
      cashback: {
        show: false,
        parentFullName: null,
        pupilFullName: null
      }
    }
  },
  computed: {
    ...mapGetters([ 'currentChild' ])
  },
  created() {
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },
  destroyed() {
    this.removeScrollHandler()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    resetData() {
      this.items = []
    },
    fileChanged(event){
      this.file = event
      this.fileName = event.name
    },
    revoke(id) {
      this.revokeId = id
      this.showModal = true
    },
    async getPrefillAction() {
      if (!this.showModalFullName) {
        await this.getPrefill({
          id: this.currentChild.id
        })
      } else {
        this.cashback.show = true
      }
    },
    async handleCashbackModal(value) {
      if (!value) return
      await this.getPrefill({
        id: this.currentChild.id,
        parentFullName: this.cashback.parentFullName,
        pupilFullName: this.cashback.pupilFullName,
      })
    },
    async handlerConfirmModal(value) {
      this.showModal = false
      if (!value) {
        return
      }
      const payload = {
        id: this.currentChild.id,
        docId: this.revokeId
      }
      this.revokeId = null
      await revokeCashbackDocument(payload)
      this.resetData()
      this.loadMore()
    },
    async download(id) {
      const payload = {
        id: this.currentChild.id,
        docId: id
      }
      const response = await getCashbackDocument(payload)
      await downloadFile(response)
    },
    loadMore() {
      const payload = {
        id: this.currentChild.id,
        params: {
          limit: Vue.config.general.limit,
          offset: this.offset,
        }
      }

      getCashbackRequests(payload)
        .then(response => {
          const items = _.map(response.data.data, item => {
            return {
              ...item,
              created_at: moment(item.created_at).format(Vue.config.format.dateString.time)
            }
          })
          this.items = this.items.concat(items)
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    async getPrefill(payload) {
      const response = await getCashbackPrefill(payload)
      await downloadFile(response)
    },
    uploadRequest() {
      const form = new FormData()
      form.append('file', this.file)
      uploadCashbackRequest(form, this.currentChild.id, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        notify.success(Vue.config.notifications.uploadSuccess)
        this.file = null
        this.fileName = ''
        this.resetData()
        this.loadMore()
      })
        .finally(() => store.commit('setShowPreloader', false))
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
