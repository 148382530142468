<script>
import SupportContacts from "@/components/common/HelpDesk/Components/SupportContacts.vue"
import UserManual from "@/components/common/HelpDesk/Components/UserManual.vue"
import HelpContent from "@/components/common/HelpDesk/Components/HelpContent.vue"

export default {
  name: "HelpDesk",
  components: { HelpContent, UserManual, SupportContacts }
}
</script>

<template>
<v-container fluid>
  <h1>Справочный раздел</h1>
  <v-layout row wrap class="mt-3">
    <v-flex id="TechSupport" sm12 md3 xl2 class="px-2 pt-2">
      <support-contacts class="mb-2" />
      <user-manual />
    </v-flex>
    <v-flex id="SupportBlock" sm12 md9 xl10>
      <div id="UserHelp" class="px-2 pt-2">
        <help-content />
      </div>
    </v-flex>
  </v-layout>
</v-container>
</template>

<style scoped>

</style>
