<template>
  <v-container fluid class="fs-teacher-container" v-if="this.foodBlockPassport && this.foodBlockPassport.id">
    <div class="storehouse-head"><h1>Пищеблок | {{tabMenu[active].fullTitle}}</h1></div>
    <v-tabs v-model="active" style="margin-top: 25px;">
      <v-tab v-for="(item, idx) in tabMenu" :key="idx">{{item.title}}</v-tab>
      
      <v-tab-item><Form1 v-if="active == 0" /></v-tab-item>
      <v-tab-item><Form2 v-if="active == 1" /></v-tab-item>
      <v-tab-item><Form3 v-if="active == 2"/></v-tab-item>
      <v-tab-item><Form4 v-if="active == 3"/></v-tab-item>
      <v-tab-item><Form5 v-if="active == 4"/></v-tab-item>
      <v-tab-item><Form6 v-if="active == 5"/></v-tab-item>
      <v-tab-item><Form7 v-if="active == 6"/></v-tab-item>
      <v-tab-item><Form8 v-if="active == 7"/></v-tab-item>
      <v-tab-item><Form9 v-if="active == 8"/></v-tab-item>
      <v-tab-item><Form10 v-if="active == 9"/></v-tab-item>
      <v-tab-item><Form11 v-if="active == 10"/></v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Form1 from './InputForm/Form1.vue' // !В общеобразовательном учреждении имеется
import Form2 from './InputForm/Form2.vue' // !Инженерное обеспечение пищеблока (указать наличие)
import Form3 from './InputForm/Form3.vue' // !Для перевозки продуктов питания используется
import Form4 from './InputForm/Form4.vue' // !Характеристика пищеблока общеобразовательного учреждения:
import Form5 from './InputForm/Form5.vue' // !Характеристика складских помещений пищеблока:
import Form6 from './InputForm/Form6.vue' // !Характеристика бытовых помещений для сотрудников пищеблока:
import Form7 from './InputForm/Form7.vue' // !Работники пищеблока:
import Form8 from './InputForm/Form8.vue' // !Персонал пищеблока входит в штатное расписание:
import Form9 from './InputForm/Form9.vue' // !Наличие комплекта документов пищеблока:
import Form10 from './InputForm/Form10.vue' // !Где организованно питание:
import Form11 from './InputForm/Form11.vue' // !Информация (объединённые оставшиеся текстовые блоки)
export default {
  name: 'AdminFoodBlock',
  components: { Form1, Form2, Form3, Form4, Form5, Form6, Form7, Form8, Form9, Form10, Form11 },
  async mounted() {
    await this.getFoodBlockPassports(this.currentUser.school_id)
  },
  methods: {
    ...mapActions([ 'getFoodBlockPassports' ]),
  },
  computed: {
    ...mapGetters([ 'currentUser', 'foodBlockPassport' ])
  },
  data() {
    return {
      active: 0,
      checkbox: '',
      tabMenu: [
        { title: "Помещения", fullTitle: "В общеобразовательном учреждении имеется:" },
        { title: "Инженерное обеспечение пищеблока", fullTitle: 'Инженерное обеспечение пищеблока (указать наличие):' },
        { title: "Перевозка продуктов", fullTitle: "Для перевозки продуктов питания используется:" },
        { title: "Характеристика пищеблока", fullTitle: "Характеристика пищеблока общеобразовательного учреждения:" },
        { title: "Характеристика складских помещений", fullTitle: "Характеристика складских помещений пищеблока:" },
        { title: "Характеристика бытовых помещений", fullTitle: "Характеристика бытовых помещений для сотрудников пищеблока:" },
        { title: "Работники пищеблока", fullTitle: "Работники пищеблока" },
        { title: "Персонал пищеблока входит в штатное расписание:", fullTitle: "Персонал пищеблока входит в штатное расписание" },
        { title: "Наличие комплекта документов пищеблока", fullTitle: "Наличие комплекта документов пищеблока" },
        { title: "Где организованно питание", fullTitle: "Где организованно питание" },
        { title: "Информация", fullTitle: "Информация" }, // ! [2, 5, 11-20]
      ]
    }
  }
}
</script>