<template>
  <DishIndex :archive-view="true"/>
</template>

<script>
import DishIndex from '@/components/organizer/Dish/Index'

export default {
  name: 'DishIndexArchive',
  components: {
    DishIndex
  }
}
</script>
