<script>
import {updateOrdersMulti} from "@/router/routes/superadmin";
import store from "@/store";

export default {
  name: 'SuperadminOrdersTableV2EditMultipleModal',
  props: {
    orders: {
      type: Array
    },
    showModal: {
      type: Boolean
    },
  },
  methods: {
    close() {
      this.$emit('closeModal')
    },
    async save() {
      const payload = {
        orders: this.orders.map(item => item.id),
        status: this.selectedStatus
      }
      try {
        const { data } = await updateOrdersMulti(payload)
        this.$emit('updateOrder', data)
      } catch (e) {
        console.log(e)
      }
      store.commit('setShowPreloader', false)
    }
  },
  data() {
    return {
      availableStatuses: [
        { name: 'Черновик', id: 1 },
        { name: 'Подтвержден учителем', id: 2 },
        { name: 'Подтвержден школой', id: 3 },
        { name: 'Выдан', id: 4 },
        { name: 'Выполнен', id: 5 },
        { name: 'Отменен', id: 6 },
        { name: 'Сохранен учителем', id: 7 },
        { name: 'Сохранен школой', id: 8 },
        { name: 'Подтвержден автоматически', id: 9 },
        { name: 'Изменен', id: 10 },
        { name: 'Заказ выполнен (без списания)', id: 11 },
      ],
      selectedStatus: null
    }
  }
}
</script>

<template>
  <v-dialog
      v-model="showModal"
      :persistent="true"
      :width="500"
  >
    <v-card>
      <v-card-title>
        <h3>Выбрано заявок: {{ orders.length }}</h3>
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          solo
          v-model="selectedStatus"
          :items="availableStatuses"
          key="id"
          item-text="name"
          item-value="id"
          label="Статус заявок"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn color="error" @click="close">
          Отмена
        </v-btn>
        <v-btn color="success" @click="save">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
