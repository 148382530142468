<template>
  <v-container class="fs-teacher-container" fluid grid-list-md>
    <Title layout>Планировщик смены льгот</Title>
    <v-layout row wrap>
      <v-flex>
        <v-btn
          class="fs-btn fs-admin-btn__add d-inline ma-0 mr-3"
          @click="$router.push({ name: 'MunicipalityConcessions' })"
        >
          Скрыть планировщик
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex>
        <v-card>
          <v-card-title>
            <h3>Добавить запланированную смену льготы</h3>
          </v-card-title>
          <v-card-text>
            <v-flex d-flex>
              <v-select
                label="Старая льгота"
                class="mr-2"
                solo
                :items="privileges"
                item-text="name"
                item-value="id"
                v-model="selectedPrivilegeOld"
              ></v-select>
              <v-icon
                style="text-align: center; padding-top: 12px"
              >
                sync
              </v-icon>
              <v-select
                label="Новая льгота"
                class="mr-2"
                solo
                :items="privileges"
                item-text="name"
                item-value="id"
                v-model="selectedPrivilegeNew"
              ></v-select>
              <v-icon
                style="text-align: center; padding-top: 12px"
              >
                more_time
              </v-icon>
              <v-menu
                :close-on-content-click="false"
                lazy
                transition="scale-transition"
                offset-y
                style="display: none"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Дата"
                    persistent-hint
                    v-model="selectedDateComputed"
                    v-on="on"
                    readonly
                    class="mr-2"
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="ru-ru"
                  type="date"
                  v-model="selectedDate"
                  first-day-of-week="1"
                  :min="currentDate"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
              <v-icon
                style="text-align: center; padding-top: 12px"
              >
                keyboard_double_arrow_right
              </v-icon>
              <v-btn
                class="fs-btn"
                @click="createSchedule"
              >
                Создать
              </v-btn>
            </v-flex>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout class="fs-teacher-order-result mt-3">
      <v-flex>
        <v-card>
          <v-card-title>
            <h3>Запланированные смены льгот</h3>
          </v-card-title>
          <v-card-text>
            <v-data-table
              class="fs-admin-table"
              hide-actions
              disable-initial-sort
              :headers="headers"
              :items="scheduledTasks"
            >
              <template v-slot:items="props">
                <tr>
                  <td>{{ props.index + 1 }}</td>
                  <td>{{ props.item.change_date }}</td>
                  <td>{{ props.item.privilege_old }}</td>
                  <td>{{ props.item.privilege_new }}</td>
                  <td>{{ props.item.status === 0 ? 'Запланировано' : 'Выполнено' }}</td>
                  <td>{{ props.item.created_at }}</td>
                  <td style="text-align: center">
                    <div class="d-inline-block mx-2">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            @click="removeScheduledTask(props.item)"
                          >delete_outline</v-icon>
                        </template>
                        <span>Удалить</span>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Title from '@/ui-components/Title'
import {
  createScheduledPrivilegeChange,
  getPrivileges,
  getScheduledPrivilegeChanges,
  removeScheduledPrivilegeChange
} from "@/router/routes/department"
import { mapGetters } from "vuex"
import store from "@/store"
import Vue from "vue"
import moment from "moment"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: "ConcessionsScheduler",
  components: {
    Title
  },
  data() {
    return {
      headers: [
        { text: '#', value: 'id' },
        { text: 'Запланировано на', value: 'change_date' },
        { text: 'Старая льгота', value: 'privilege_old' },
        { text: 'Новая льгота', value: 'privilege_new' },
        { text: 'Статус', value: 'status' },
        { text: 'Создано', value: 'created_at' },
        { text: 'Операции', value: 'actions' }
      ],
      scheduledTasks: [],
      privileges: [],
      selectedPrivilegeOld: null,
      selectedPrivilegeNew: null,
      selectedDate: null,
      currentDate: moment().add(1, 'day').format('YYYY-MM-DD')
    }
  },
  mounted() {
    this.getPrivilegeTypes()
    this.getScheduledTasks()
  },
  computed: {
    selectedDateComputed() {
      if (this.selectedDate) return moment(this.selectedDate).format('DD-MM-YYYY')
      return ''
    },
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    async getPrivilegeTypes() {
      const response = await getPrivileges({
        department_id: this.currentUser.department.id
      })
      this.privileges = response.data.data.filter(item => {
        if (!item.is_archived) return item
        return false
      })
      store.commit('setShowPreloader', false)
    },
    async createSchedule() {
      const payload = {
        change_date: this.selectedDate,
        department: this.currentUser.department.id,
        privilege_old: this.selectedPrivilegeOld,
        privilege_new: this.selectedPrivilegeNew
      }
      const response = await createScheduledPrivilegeChange(payload)
      if (response.data.success === false) {
        notify.error(`При сохранении данных произошла ошибка. Подробности: ${response.data}`) //pass!
      } else {
        this.clearInputs()
        notify.success('Успешно сохранено!')
      }
      await this.getScheduledTasks()
    },
    clearInputs() {
      this.selectedDate = null
      this.selectedPrivilegeNew = null
      this.selectedPrivilegeOld = null
    },
    async getScheduledTasks() {
      const response = await getScheduledPrivilegeChanges({
        department_id: this.currentUser.department.id
      })
      this.scheduledTasks = response.data
      store.commit('setShowPreloader', false)
    },
    async removeScheduledTask(item) {
      const payload = {
        department_id: this.currentUser.department.id,
        schedule_id: item.id
      }
      await removeScheduledPrivilegeChange(payload)
      notify.success('Запланированная задача удалена')
      await this.getScheduledTasks()
    }
  }
}
</script>

<style scoped>
.v-select {
  max-width: 300px;
  text-overflow: ellipsis;
}
.v-select .v-select__selections input {
  display: none;
}
</style>
