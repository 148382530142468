<template>
  <div>
    <div
      v-if="showLauncher"
      class="sc-launcher"
      :class="{ opened: isOpen }"
      :style="{ backgroundColor: colors.launcher.bg }"
      @click.prevent="isOpen ? closeChat() : openAndFocus()"
    >
      <div v-if="newMessagesCount > 0" class="sc-new-messages-count">
        <!--	    <div v-if="newMessagesCount > 0 && !isOpen" class="sc-new-messages-count">-->
        {{ newMessagesCount }}
      </div>
      <v-icon
        color="white"
        large
        :class="isOpen ? 'sc-closed-icon' : 'sc-open-icon'"
        >{{ isOpen ? 'close' : 'contact_support' }}</v-icon
      >
    </div>
    <ChatWindow
      :show-launcher="showLauncher"
      :show-close-button="showCloseButton"
      :send-suggestion="sendSuggestion"
      :participants="participants"
      :title="title"
      :title-image-url="titleImageUrl"
      :is-open="isOpen"
      :on-close="closeChat"
      :show-header="showHeader"
      :placeholder="placeholder"
      :colors="colors"
      :always-scroll-to-bottom="alwaysScrollToBottom"
      :message-styling="messageStyling"
      @scrollToTop="$emit('scrollToTop')"
    >
      <template v-slot:header>
        <slot name="header"> </slot>
      </template>
      <template v-slot:user-avatar="scopedProps">
        <slot
          name="user-avatar"
          :user="scopedProps.user"
          :message="scopedProps.message"
        >
        </slot>
      </template>
      <template v-slot:text-message-body="scopedProps">
        <slot
          name="text-message-body"
          :message="scopedProps.message"
          :messageText="scopedProps.messageText"
          :messageColors="scopedProps.messageColors"
          :me="scopedProps.me"
        >
        </slot>
      </template>
      <template v-slot:system-message-body="scopedProps">
        <slot name="system-message-body" :message="scopedProps.message"> </slot>
      </template>
      <template v-slot:text-message-toolbox="scopedProps">
        <slot
          name="text-message-toolbox"
          :message="scopedProps.message"
          :me="scopedProps.me"
        >
        </slot>
      </template>
    </ChatWindow>
  </div>
</template>

<script>
import ChatWindow from './ChatWindow.vue'
import { getters, mutations, actions } from '../store'

export default {
  components: {
    ChatWindow
  },

  props: {
    showLauncher: {
      type: Boolean,
      default: true
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    participants: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: () => 'FAQ'
    },
    titleImageUrl: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: 'Введите текст...'
    },
    colors: {
      type: Object,
      validator: (c) =>
        'header' in c &&
        'bg' in c.header &&
        'text' in c.header &&
        'launcher' in c &&
        'bg' in c.launcher &&
        'messageList' in c &&
        'bg' in c.messageList &&
        'sentMessage' in c &&
        'bg' in c.sentMessage &&
        'text' in c.sentMessage &&
        'receivedMessage' in c &&
        'bg' in c.receivedMessage &&
        'text' in c.receivedMessage &&
        'userInput' in c &&
        'bg' in c.userInput &&
        'text' in c.userInput &&
        'chatCard' in c &&
        'bg' in c.chatCard &&
        'text' in c.chatCard &&
        'basic' in c.chatCard,
      default: function () {
        return {
          header: {
            bg: '#4e8cff',
            text: '#ffffff'
          },
          launcher: {
            bg: '#4e8cff'
          },
          messageList: {
            bg: '#ffffff'
          },
          sentMessage: {
            bg: '#4e8cff',
            text: '#ffffff'
          },
          receivedMessage: {
            bg: '#f4f7f9',
            text: '#ffffff'
          },
          userInput: {
            bg: '#f4f7f9',
            text: '#565867'
          },
          chatCard: {
            bg: '#4e8cff',
            text: '#ffffff',
            basic: '#eaeaea'
          }
        }
      }
    },
    alwaysScrollToBottom: {
      type: Boolean,
      default: () => true
    },
    messageStyling: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...getters
  },
  methods: {
    ...mutations,
    ...actions,
    openAndFocus() {
      this.openChat()
      this.$root.$emit('focusUserInput')
    }
  }
}
</script>

<style scoped>
.sc-launcher {
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  right: 25px;
  bottom: 25px;
  border-radius: 50%;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;
}

.sc-launcher:before {
  content: '';
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: box-shadow 0.2s ease-in-out;
}

.sc-launcher .sc-open-icon,
.sc-launcher .sc-closed-icon {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 25px;
  bottom: 25px;
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
}

.sc-launcher .sc-closed-icon {
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
  width: 60px;
  height: 60px;
}

.sc-launcher .sc-open-icon {
  padding: 20px;
  box-sizing: border-box;
  opacity: 1;
}

.sc-launcher.opened .sc-open-icon {
  transform: rotate(-90deg);
  opacity: 1;
}

.sc-launcher.opened .sc-closed-icon {
  transform: rotate(-90deg);
  opacity: 1;
}

.sc-launcher.opened:before {
  box-shadow: 0px 0px 400px 250px rgba(148, 149, 150, 0.2);
}

.sc-launcher:hover {
  box-shadow: 0 0px 27px 1.5px rgba(0, 0, 0, 0.2);
}

.sc-new-messages-count {
  position: absolute;
  top: -3px;
  left: 41px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background: radial-gradient(#3bb08f, #1cac78, #addfad);
  box-shadow: inset 0 -1em 1em rgba(0, 0, 0, 0.01), 0 0 0 1px rgb(208, 240, 192),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  color: white;
  text-align: center;
  margin: auto;
  font-size: 12px;
  font-weight: 600;
}
</style>
