<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-order"
      row
      mb-3
    >
      <v-flex mb-3>
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout mb-3>
      <v-layout
        row
        justify-space-around
      >
        <v-flex md5>
          <v-text-field
            height="30"
            label="Образовательная организация"
            :value="item.organization || ''"
          ></v-text-field>
          <!--<fs-autocomplete outline height="30"
            label="Возрастная категория"
          ></fs-autocomplete>-->
          <v-text-field
            height="30"
            label="Статус заявки"
            :value="item.status || ''"
          ></v-text-field>
        </v-flex>
        <v-flex md5>
          <v-text-field
            height="30"
            label="Класс"
            :value="item.class || ''"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex
          text-md-center
          pt-3
        >
          <!--<v-btn flat class="fs-btn fs-btn&#45;&#45;secondary">показать</v-btn>-->
          <v-btn
            flat
            class="fs-btn fs-btn--primary"
          >печать</v-btn>
          <v-btn
            flat
            class="primary"
            :href="menuprint"
          >печать меню</v-btn>
        </v-flex>
      </v-layout>
    </v-layout>

    <v-layout>
      <v-flex>
        <div>Галочки сделаны для удобства ведения счета и никаких других функций не реализуют</div>
      </v-flex>
    </v-layout>

    <v-layout
      class="fs-order-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="orderHeaders"
          :items="orderItems"
          hide-actions
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>
                <v-checkbox
                  class="fs-checkbox"
                  :model="props.item.selected"
                  primary
                  hide-details
                ></v-checkbox>
              </td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.weight }}</td>
              <td>{{ props.item.qty }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ArchiveView',
  data() {
    return {
      menuprint: Vue.config.publicPath + 'menuprint.xlsx',
      item: this.$route.query,
      title: 'Заявка на питание',
      orderHeaders: [
        { text: '', value: 'checkbox' },
        { text: 'Блюдо', value: 'name' },
        { text: 'Вес', value: 'weight' },
        { text: 'Количество', value: 'qty' }
      ],
      orderItems: [
        {
          id: 1,
          name: 'Суп гороховый',
          weight: '75г',
          qty: 12,
          selected: false
        },
        {
          id: 2,
          name: 'Суп гороховый',
          weight: '125г',
          qty: 251,
          selected: false
        },
        {
          id: 3,
          name: 'Суп гороховый',
          weight: '300г',
          qty: 75,
          selected: false
        },
        {
          id: 4,
          name: 'Суп гороховый',
          weight: '450г',
          qty: 25,
          selected: false
        },
        {
          id: 5,
          name: 'Суп гороховый',
          weight: '315г',
          qty: 60,
          selected: false
        },
        {
          id: 6,
          name: 'Макароны по флотски',
          weight: '75г',
          qty: 12,
          selected: false
        },
        {
          id: 7,
          name: 'Макароны по флотски',
          weight: '125г',
          qty: 251,
          selected: false
        },
        {
          id: 8,
          name: 'Макароны по флотски',
          weight: '300г',
          qty: 75,
          selected: false
        },
        {
          id: 9,
          name: 'Макароны по флотски',
          weight: '450г',
          qty: 25,
          selected: false
        },
        {
          id: 10,
          name: 'Макароны по флотски',
          weight: '315г',
          qty: 60,
          selected: false
        }
      ]
    }
  }
}
</script>
