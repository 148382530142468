<template>
  <v-layout row justify-center>
    <v-dialog v-model="value" persistent content content-class="centered-dialog">
      <v-container fill-height>
        <v-layout column justify-center align-center>
          <v-progress-circular indeterminate :size="70" :width="7" :color="progressColor"></v-progress-circular>
          <h1 v-if="message != null">{{message}}</h1>
        </v-layout>
      </v-container>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  name: "Loader",
  data: () => {
    return {}
  },
  props: {
    value: { type: Boolean, default: false },
    message: { type: String, default: null },
    progressColor: { type: String, default: "primary" }
  }
}
</script>

<style>
.dialog.centered-dialog,
.v-dialog.centered-dialog {
  box-shadow: none;
  width: auto;
}
.v-progress-circular {
  color: whitesmoke !important;
}
</style>
