<template>
  <v-container fluid>
    <v-layout class="fs-dish" row>
      <div class="title-help">
        <div class="d-flex pt-2">
          <p class="text text-center">Cтатус заказов на сегодня</p>
          <p><context-help-modal :context-key="contextKeys.admin.dashboard.order_statuses"/></p>
        </div>
      </div>
    </v-layout>
    <v-flex>
      <canvas ref="myChart"></canvas>
    </v-flex>
  </v-container>
</template>

<script>
import store from '@/store'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { TextEditorModal } from '@/components/common'
import { getAdminDashboardOrderStatuses } from '@/router/routes/admin'
import notify from '@/utils/notifications/toastNotification'
import ContextHelpModal from '@/components/common/Modals/ContextHelpModal'
import { mapGetters } from "vuex"
import Vue from "vue"

const labels = [
  'Черновик', 'Сохранен', 'Подтвержден', 'Выдан', 'Выполнен', 'Отменен',
  'Подтвержден автоматически', 'Изменен',
  'Выполнен (без списания)'
]

export default {
  name: 'OrderStatusesHistogram',
  components: {
    TextEditorModal,
    ContextHelpModal
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      items: [],
      draft: 0,
      teacher_saved: 0,
      teacher_confirm: 0,
      school_saved: 0,
      school_confirm: 0,
      received: 0,
      complete: 0,
      cancel: 0,
      confirm_auto: 0,
      updated: 0,
      complete_auto: 0,
      options: {
        indexAxis: 'y',
        scales: {
          x: {
            display: false,
            grid: {
              display: false
            }
          },
          y: {
            display: true,
            grid: {
              display: false
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            align: function(context) {
              return context.dataset.data[context.dataIndex] > 100 ? 'center' : 'end'
            },
            anchor: function(context) {
              return context.dataset.data[context.dataIndex] > 100 ? 'center' : 'end'
            }
          }
        }
      }
    }
  },
  mounted() {
    this.loadStatistics()
      .then(() => {
        const data = {
          labels: labels,
          legend: {
            show: false
          },
          datasets: [ {
            axis: 'y',
            label: 'Статусы заказов',
            data: [
              this.draft, this.saved, this.confirmed, this.received, this.complete, this.cancel,
              this.confirm_auto, this.updated, this.complete_auto
            ],
            fill: false,
            backgroundColor: [
              'rgb(203,203,203)',
              'rgb(228,255,175)',
              'rgb(157,206,137)',
              'rgb(170,219,255)',
              'rgb(101,192,255)',
              'rgb(251,160,152)',
              'rgb(255,212,149)',
              'rgb(166,255,187)',
              'rgba(255, 205, 86)',
              'rgb(255, 159, 64)',
            ],
            borderWidth: 0,
            barPercentage: 1,
          } ]
        }
        const ctx = this.$refs.myChart.getContext('2d')
        new Chart(ctx, {
          type: 'bar',
          data: data,
          options: this.options,
          plugins: [ ChartDataLabels ]
        })
      })
      .catch((error) => {
        console.error(error)
        notify.error('Ошибка загрузки статистики заявок')
      })
      .finally(() => {
        store.commit('setShowPreloader', false)
      })
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    async loadStatistics() {
      try {
        const response = await getAdminDashboardOrderStatuses({ school_id: this.currentUser.school_id })
        this.items = response.data.map(obj => obj.order_count)
        this.draft = this.items[0]
        this.saved = this.items[6] + this.items[7]
        this.confirmed = this.items[1] + this.items[2]
        this.received = this.items[3]
        this.complete = this.items[4]
        this.cancel = this.items[5]
        this.confirm_auto = this.items[8]
        this.updated = this.items[9]
        this.complete_auto = this.items[10]
      } catch (error) {
        // notify.error('Ошибка загрузки статистики заявок') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>

<style>

.title-help {
  display: flex;
  width: 100%;
  justify-content: center;
}

canvas {
  width: available !important;
}

</style>

