<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <v-layout
      class="fs-complex"
      row
      mb-3
    >
      <v-flex
        md6
        mb-3
      >
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout
      row
      mb-3
    >
      <v-flex md4>
        <v-btn
          @click="print"
          class="fs-btn fs-teacher-btn__print mr-3"
        >печать</v-btn>
        <v-btn
          @click="printAdditional"
          class="fs-btn fs-teacher-btn__print mr-3"
        >печать заявки по доп. блюдам </v-btn>
        <with-tooltip
            text="Переносит явку аналогично текущему дню на заявки следующего дня"
            v-if="teacherTransferSetting"
        >
          <v-btn
            @click="() => {this.showTransferModal = true}"
            class="fs-btn fs-teacher-btn__cancel"
          >
            <v-icon small class="pr-1" :color="darkTheme ? 'white' : 'primary'">copy_all</v-icon>
            Перенести явку на следующий день
          </v-btn>
        </with-tooltip>
      </v-flex>
      <v-flex pt-3 v-if="ordersErrors.length > 0">
        <v-tooltip bottom color="error">
          <template v-slot:activator="{on}">
            <span v-on="on" class="subheading error--text font-weight-light">
              <v-icon color="error">
                warning
              </v-icon>
              Некоторые заказы не создались, наведите для подробностей
            </span>
          </template>
            <ul v-for="item in ordersErrors">
                <li><b>{{ item.pupil.fullName }}</b> - {{ item.error }}</li>
            </ul>
        </v-tooltip>
      </v-flex>
    </v-layout>

    <v-card>
        <v-card-text>
          <div v-if="isShowFeedingCount" class="mb-2">
            Всего питающихся:
            <span class="font-weight-bold">
              {{ feedingPupilsCount }}
            </span>
          </div>
          <v-layout
            class="fs-teacher-order-result"
            mb-3
          >
            <v-flex>
              <v-data-table
                :headers="headers"
                :pagination.sync="pagination"
                :items="items"
                hide-actions
                class="elevation-1 fs-teacher-table nowrapTable"
                item-key="name"
                sort-icon="arrow_drop_up"
                no-data-text="Отсутствуют заявки"
              >
                <template
                  slot="headerCell"
                  slot-scope="props"
                >
                  <span
                    slot="activator"
                    v-if="props.header.value !=='visit'"
                  >
                    {{ props.header.text }}
                  </span>
                  <span
                    v-else
                    @click="selectAll"
                    slot="
                    activator"
                  >
                    <v-checkbox
                      class="fs-checkbox selectedTh"
                      :value="selectAllChecbox"
                      :label="props.header.text"
                    />
                  </span>

                </template>
                <template v-slot:items="props">
                  <tr :style="{backgroundColor: props.item.trafficDiff ? 'lightcoral' : null}" :title="props.item.trafficDiff">
                    <td v-if="showNumbering">{{ props.item.idx + 1 }}</td>
                    <td>{{ props.item.id }}</td>
                    <td>{{ props.item.student.id }}</td>
                    <td>{{ props.item.student.name }}</td>
                    <td>
                      <v-checkbox
                        class="fs-checkbox colTableCheckbox"
                        :readonly="isViewComponent"
                        v-model="props.item.visit"
                      ></v-checkbox>
                    </td>
                    <td>
                      {{ props.item.foodType.name }}
                      <v-icon v-if="props.item.canChangeFoodType" class="fs-active-icon" @click="changeFoodType(props.item)">edit</v-icon>
                    </td>
                    <td>{{ props.item.complex.name }}</td>
                    <td>{{ props.item.student.balance.toFixed(2) }}</td>
                    <td>
                      {{ props.item.allPrice }}
                      <span v-if="props.item.additionalPrice">
                        (доп. блюда {{ props.item.additionalPrice }})
                      </span>
                    </td>
                    <td>{{ props.item.parentPayment }}</td>
                    <td>{{ props.item.privilege }}</td>

                    <td>
                      <v-icon v-if="props.item.additional_foods.length" class="fs-active-icon" @click="showAdditionalFood(props.item)">edit</v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-card-text>
    </v-card>

    <v-layout class="fs-form-footer">
      <v-flex
        text-md-right
        pt-3
      >
        <v-btn
          flat
          class="fs-btn fs-btn--secondary fs-admin-btn__cancel v-btn--active"
          @click="returnPage"
        >назад</v-btn>
        <v-btn
          v-if="!isViewComponent && canUpdate"
          flat
          class="fs-btn fs-btn--secondary fs-admin-btn__cancel v-btn--active"
          @click="updateOrder()"
        >сохранить</v-btn>
        <v-btn
          v-if="!isViewComponent && canConfirm"
          flat
          class="fs-btn fs-btn--secondary fs-admin-btn__cancel v-btn--active"
          @click="confirmOrder()"
        >подтвердить</v-btn>
      </v-flex>
    </v-layout>

    <ConfirmModal
      :dialog.sync="showTransferModal"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="makeTransferTrafficClass"
    >
      <template v-slot:headline>Подтверждение переноса явки по классу</template>
      <template v-slot:body>Вы подтверждаете <b>перенос явки</b> класса на следующий рабочий день?</template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="changeFoodTypeModal"
      :confirmBtnText="'Смена типа питания'"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="confirmChangeFoodType"
    >
      <template v-slot:headline>Смена типа питания {{ changeFoodTypeOrder ? changeFoodTypeOrder.student.name : null }}</template>
      <template v-slot:body>
        <v-select
          no-data-text="Ничего не выбрано"
          v-model="changeFoodTypeId"
          item-text="name"
          item-value="id"
          :items="studentFoodTypes"
          label=""
        />
      </template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="updateOrderModal"
      :confirmBtnText="'Сохранить'"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="confirmationUpdateOrder"
    >
      <template v-slot:headline>Сохранить заказы</template>
      <template v-slot:body>
        <ul>
          <li v-for="order in ordersTrafficDiff" :key="order.id">
            {{ order.id }}, {{ order.student.name }}: {{ order.trafficDiff }}
          </li>
        </ul>
      </template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="confirmOrderModal"
      :confirmBtnText="'Подтвердить'"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="confirmationConfirmOrder"
    >
      <template v-slot:headline>Подтвердить заказы</template>
      <template v-slot:body>
        <ul>
          <li v-for="order in ordersTrafficDiff" :key="order.id">
            {{ order.id }}, {{ order.student.name }}: {{ order.trafficDiff }}
          </li>
        </ul>
      </template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="additionalFoodModal"
      :hide-confirm-button="true"
      :cancel-btn-text="'Закрыть'"
      @confirm="additionalFoodModal = false"
    >
      <template v-slot:headline>Дополнительные блюда</template>
      <template v-slot:body>
        <ul v-if="additionalFoodOrder">
          <li v-for="food in additionalFoodOrder.additional_foods" :key="food.name">
            {{ food.name }}, {{ convertToRubles(food.price) }} руб., {{ food.amount }} шт.
          </li>
        </ul>
        <br/>
        <v-btn
          v-if="additionalFoodOrder && additionalFoodOrder.can_delete_additional_food && !isViewComponent"
          @click="deleteAdditionalFood"
        >Удалить указанные блюда из заявки</v-btn>
      </template>
    </ConfirmModal>

    <ConfirmModal
      :dialog.sync="removeAdditionalFoodOrderModal"
      :confirmBtnText="'Да, удалить блюда из заявки'"
      :btnsClass="'fs-btn fs-teacher-btn__cancel'"
      @confirm="confirmationRemoveAdditionalFood"
    >
      <template v-slot:headline>Подтвердить удаление</template>
      <template v-slot:body>
        <ul v-if="additionalFoodOrder">
          <li v-for="food in additionalFoodOrder.additional_foods" :key="food.name">
            {{ food.name }}
          </li>
        </ul>
      </template>
    </ConfirmModal>

  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import store from '@/store'
import numable from '@/mixins/balancable'

import {
  confirmOneOrder,
  getOneOrder,
  getOrderFoodTypes,
  printOrder,
  removeAdditionalFood,
  reportAdditional,
  reportAdditionalDownload,
  reportAdditionalStatus,
  setFoodType, teacherTransferTrafficClass,
  updateOneOrder
} from '@/router/routes/teacher'

import asyncReqestsForDownload from '@/helpers/asyncReqestsForDownload'
import { ConfirmModal } from "@/components/common"
import notify from '@/utils/notifications/toastNotification'
import {getOrderCreationErrors} from "@/router/routes/common";
import WithTooltip from "@/components/common/Tooltip/Tooltip"

// TODO: надо рефакторить как админа
export default {
  name: 'TeacherOrdersItem',
  mixins: [ numable ],
  components: { ConfirmModal, WithTooltip },
  data() {
    return {
      order: {},
      showNumbering: store.getters.setting('show_numbering_in_orders_school_class'),
      teacherTransferSetting: store.getters.setting('teacher_transfer_traffic'),
      pagination: {
        sortBy: 'student.lastName',
        descending: false,
        rowsPerPage: -1
      },
      headers: [],
      items: [],
      darkTheme: Vue.config.env.darkTheme,
      datepickerMenu: false,
      selectAllChecbox: false,
      className: null,
      classId: null,
      day: null,
      month: null,
      year: null,
      foodTypes: [],
      changeFoodTypeOrder: null,
      changeFoodTypeId: null,
      changeFoodTypeModal: false,
      studentFoodTypes: [],
      tableStatus: null,
      ordersErrors: [],
      updateOrderModal: false,
      confirmOrderModal: false,
      additionalFoodOrder: null,
      additionalFoodModal: false,
      removeAdditionalFoodOrderModal: false,
      returnDateStart: null,
      returnDateEnd: null,
      feedingPupilsCount: 0,
      showTransferModal: false,
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
    canUpdate() {
      return this.tableStatus === Vue.config.general.orderStatuses.draft || this.tableStatus === Vue.config.general.orderStatuses.teacherSaved
    },
    canConfirm() {
      return this.tableStatus === Vue.config.general.orderStatuses.teacherSaved || this.tableStatus === Vue.config.general.orderStatuses.draft
    },
    isViewComponent() {
      return this.$route.meta === 'view'
    },
    dateNormal() {
      return (
        this.$route.params.day +
        '-' +
        this.$route.params.month +
        '-' +
        this.$route.params.year
      )
    },
    dateServer() {
      return (
        this.$route.params.year +
        '-' +
        this.$route.params.month +
        '-' +
        this.$route.params.day
      )
    },
    title() {
      const titleView = 'Просмотр заявки'
      const titleEdit = 'Редактирование заявки'
      let titleFirstPart = ''
      if (this.isViewComponent) {
        titleFirstPart = titleView
      } else {
        titleFirstPart = titleEdit
      }
      if (
        this.$route.params.day &&
        this.$route.params.month &&
        this.$route.params.year &&
        this.$route.params.class.name
      ) {
        return (
          `${titleFirstPart} - Дата: ` +
          this.dateNormal +
          ' Класс: ' +
          this.$route.params.class.name
        )
      }
      return titleFirstPart
    },
    ordersTrafficDiff() {
      return this.items.filter(item => !!item.trafficDiff)
    },
    getShowTransferModal: {
      get() {
        return this.showTransferModal
      },
      set(value) {
        this.showTransferModal = value
      }
    },
    getFeedingPupilsCount: {
      get() {
        return this.feedingPupilsCount
      },
      set(value) {
        this.feedingPupilsCount = value
      }
    },
    getIsShowFeedingCount: {
      get() {
        return this.isShowFeedingCount
      },
      set(value) {
        this.isShowFeedingCount = value
      }
    }
  },
  watch: {
    changeFoodTypeModal() {
      this.changeFoodTypeId = null
    },
  },
  created() {
    this.createHeaders()
    this.initParams()
    this.loadOrder()
    this.getErrors()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),

    makeTransferTrafficClass() {
      const PAYLOAD = {
        classId: this.classId,
        data:   {
          statusList: _.map(this.items, item => {
            return {
              order_id: item.id,
              to_next_status: item.visit
            }
          })
        }
      }

      const resp = teacherTransferTrafficClass(PAYLOAD).then(response => {
        if (response.status === 200) {
          notify.success('Явка успешно перенесена')
        }
        return response
      }).catch(response => {
        console.log(error)
        notify.error('Ошибка переноса явки класса:')
      }).finally(response => {
        this.showTransferModal = false
        store.commit('setShowPreloader', false)
      })
    },
    createHeaders() {
      const headers = [
        { text: 'Лицевой счет', value: 'student.id' },
        { text: 'ФИО ученика', value: 'student.lastName' },
        { text: 'Явка', value: 'visit', sortable: false },
        { text: 'Тип питания', value: 'foodType.name' },
        { text: 'Выбранный комплекс', value: 'complex.name' },
        { text: 'Баланс, руб.', value: 'student.balance' },
        { text: 'Полная стоимость, руб.', value: 'price' },
        { text: 'Доплата родителя, руб.', value: 'parentPayment' },
        { text: 'Льгота, руб.', value: 'privilege' },
        { text: 'Доп. блюда', value: 'additional_foods', sortable: false }
      ]
      if (this.showNumbering) {
        this.headers = [
          { text: '№ п/п', value: 'idx' },
          { text: 'Номер заказа', value: 'id' },
          ...headers
        ]
      } else {
        this.headers = [
          { text: '№ п/п', value: 'id' },
          ...headers
        ]
      }
    },
    initParams() {
      this.className = this.$route.params.class.name
      this.classId = this.$route.params.class.id
      this.returnDateStart = this.$route.params.returnDateStart
      this.returnDateEnd = this.$route.params.returnDateEnd
      this.day = this.$route.params.day
      this.month = this.$route.params.month
      this.year = this.$route.params.year
    },
    returnPage() {
      const date = {
        dateStart: this.returnDateStart,
        dateEnd: this.returnDateEnd,
        classId: this.classId
      }
      this.$router.push({ name: 'TeacherOrders', params: date })
    },
    deleteAdditionalFood() {
      this.removeAdditionalFoodOrderModal = true
    },
    getErrors() {
      const payload = {
        orderDate: `${this.year}-${this.month}-${this.day}`,
        schoolClass: this.classId
      }
      getOrderCreationErrors(payload)
        .then(res => {
          this.ordersErrors = res.data
        })
    },
    selectAll() {
      this.selectAllChecbox = !this.selectAllChecbox
      this.items.map(item => {
        item.visit = this.selectAllChecbox
        return item
      })
    },
    showAdditionalFood(order) {
      this.additionalFoodOrder = order
      this.additionalFoodModal = true
    },
    changeFoodType(order) {
      this.changeFoodTypeOrder = order
      this.changeFoodTypeModal = true
      this.changeFoodTypeId = null
      this.studentFoodTypes = []
      getOrderFoodTypes({ schoolId: this.currentUser.school_id, id: order.student.id, orderId: order.id }).then(response => {
        const student = response.data
        this.studentFoodTypes = student.orderFoodTypes
      }).finally(() => {
        store.commit('setShowPreloader', false)
      })
    },
    confirmChangeFoodType(value) {
      if (!value) {
        return
      }
      if (!this.changeFoodTypeId) {
        return
      }
      setFoodType(this.changeFoodTypeOrder.id, this.changeFoodTypeId).then(() => {
        this.loadOrder()
      })
    },
    loadOrder() {
      const payload = {
        id: this.classId,
        day: this.$route.params.day,
        month: this.$route.params.month,
        year: this.$route.params.year
      }
      return getOneOrder(payload)
        .then(response => {
          if (Object.hasOwn(response.data.data, 'orders_count')) {
            this.isShowFeedingCount = true
            this.feedingPupilsCount = response.data.data.orders.length
          }
          this.items = _.map(response.data.data.orders, (item, idx) => {
            let addPrice = 0
            if (item.additional_foods) {
              for (const food of item.additional_foods) {
                addPrice += food.price * food.amount
              }
            }
            return {
              idx: idx,
              id: item.id,
              student: {
                id: _.get(item, 'pupil.id', null),
                name: _.get(item, 'pupil.fullName', null),
                lastName: _.get(item, 'pupil.lastName', null),
                balance: this.convertToRubles(
                  _.get(item, 'pupil.balance', 0)
                ),
                isCredit: item.pupil.isCredit,
                creditAmount: this.convertToRubles(_.get(item, 'pupil.creditAmount', 0))
              },
              canChangeFoodType: item.canChangeFoodType,
              trafficDiff: item.traffic && item.traffic.diff_text,
              foodType: {
                name: _.get(item, 'foodType.name', null)
              },
              complex: {
                name: _.get(item, 'complect.name', null)
              },
              price: this.convertToRubles(
                _.get(item, 'foodType.price', 0)
              ),
              totalPrice: this.convertToRubles(item.totalPrice),
              additionalPrice: this.convertToRubles(addPrice),
              allPrice: this.convertToRubles(_.get(item, 'privilege.price', 0) + item.totalPrice),
              parentPayment:
                this.roundRubles(this.convertToRubles(
                  _.get(item, 'foodType.price', 0)
                ) -
                this.convertToRubles(
                  _.get(item, 'privilege.price', 0)
                )),
              privilege: this.convertToRubles(
                _.get(item, 'privilege.price', 0)
              ),
              visit:
                _.get(item, 'status', null) !=
                Vue.config.general.orderStatuses.cancel
                  ? true
                  : false,
              can_delete_additional_food: item.can_delete_additional_food,
              additional_foods: item.additional_foods ? item.additional_foods : []
            }
          })
          this.tableStatus = response.data.data.tableStatus
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    async confirmationUpdateOrder(value) {
      this.updateOrderModal = false
      if (value) {
        await this.updateOrder(true)
      }
    },
    async confirmationRemoveAdditionalFood(value) {
      if (!value) {
        this.removeAdditionalFoodOrderModal = false
        return
      }
      await removeAdditionalFood(this.additionalFoodOrder.id)
      this.additionalFoodOrder = null
      this.additionalFoodModal = false
      await this.loadOrder()
    },
    async confirmationConfirmOrder(value) {
      this.confirmOrderModal = false
      if (value) {
        await this.confirmOrder(true)
      }
    },
    async updateOrder(force) {
      if (!force && this.ordersTrafficDiff.length) {
        this.updateOrderModal = true
        return
      }
      try {
        const PAYLOAD = {
          id: this.classId,
          day: this.$route.params.day,
          month: this.$route.params.month,
          year: this.$route.params.year,
          data: {
            statusList: _.map(this.items, item => {
              return {
                order_id: item.id,
                to_next_status: item.visit
              }
            })
          }
        }
        const response = await updateOneOrder(PAYLOAD)

        if (response.data.success) {
          notify.success(Vue.config.notifications.teachers.orderSaved)
        }
      } catch (error) {
        // notify.error('Ошибка сохранения заявки') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async confirmOrder(force) {
      if (!force && this.ordersTrafficDiff.length) {
        this.confirmOrderModal = true
        return
      }
      try {
        const PAYLOAD = {
          id: this.classId,
          day: this.$route.params.day,
          month: this.$route.params.month,
          year: this.$route.params.year,
          data: {
            statusList: _.map(this.items, item => {
              return {
                order_id: item.id,
                to_next_status: item.visit
              }
            })
          }
        }
        const response = await confirmOneOrder(PAYLOAD)

        if (response.data.success) {
          notify.success(Vue.config.notifications.teachers.orderConfirmed)
        }
      } catch (error) {
        // notify.error('Ошибка подтверждения заявки') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    print() {
      const payload = {
        order_day: this.dateServer
      }
      printOrder(payload)
        .then(response => {
          const file = new Blob([ response.data ], { type: response.data.type })
          const url = window.URL.createObjectURL(file)
          const a = document.createElement('a')
          a.href = url
          a.download = 'Табель питания на' + this.dateNormal + '.xlsx'
          a.click()
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    async printAdditional() {
      try {
        const PAYLOAD = {
          data: {
            order_day: this.dateServer
          }
        }
        const callback = this.resetPageFileLoaded
        await asyncReqestsForDownload(
          reportAdditional,
          reportAdditionalStatus,
          reportAdditionalDownload,
          PAYLOAD,
          callback
        )
      } catch (error) {
        notify.error('Ошибка загрузки файла') //pass
      } finally {
        this.resetPageFileLoaded()
      }
    },
    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
    }
  }
}
</script>
