<script>
import { getManualStatus, getManualUrl } from "@/router/routes/common"
import Vue from "vue"
import store from "@/store"

export default {
  name: "UserManual",
  data: () => {
    return {
      manual: {
        status: undefined,
        lastUpdate: null
      }
    }
  },
  mounted() {
    this.getManualStatus()
  },
  methods: {
    async getManual() {
      const { data } = await getManualUrl(location.href)
      if (data.manual_url) {
        window.open(`${Vue.config.server}/${data.manual_url}`, "_blanc").focus()
      }
      store.commit('setShowPreloader', false)
    },
    async getManualStatus() {
      const { data } = await getManualStatus(location.href)
      this.manual.status = data.manual_exists
      if (this.manual.status) {
        this.manual.lastUpdate = data.update_date
      }
      store.commit('setShowPreloader', false)
    }
  }
}
</script>

<template>
  <v-card>
    <v-card-text>
      <div class="d-flex row justify-space-between" v-if="manual.status === undefined">
        <v-progress-circular :indeterminate="true" color="primary" />
      </div>
      <a href="#" @click="getManual" v-else-if="manual.status">
        <div class="d-flex row align-center">
          <v-icon color="red" size="32" class="iconBlock mr-1">
            picture_as_pdf
          </v-icon>
          <div id="manualInfo">
            <div id="manualLink">
              Загрузить инструкцию по работе с системой
            </div>
            <div id="manualMeta" class="manual-subtitle">
              обновлено {{ manual.lastUpdate }}
            </div>
          </div>
        </div>
      </a>
      <div v-else>
        Руководство пользователя недоступно для загрузки
      </div>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.iconBlock {
  max-width: 42px;
}
a {
  text-decoration: none;
}
.manual-subtitle {
  color: #969696
}
</style>
