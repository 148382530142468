<template>
  <v-container class="fs-parent-container start-page" fluid>
    <v-flex md6 offset-md3>
      <v-list class="fs-list" two-line>
        <template v-for="(item, index) in navItems">
          <v-list-tile
            :key="item.title"
            avatar
            :disabled="item.disabled"
            :to="{ name: item.link, params: item.params }"
          >
            <v-list-tile-avatar>
              <v-icon :class="[item.iconClass]">{{ item.icon }}</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ item.subtitle }}</v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn icon ripple>
                <v-icon color="grey lighten-1">info</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider
            v-if="index + 1 < navItems.length"
            :key="index"
          ></v-divider>
        </template>
      </v-list>
    </v-flex>
  </v-container>
</template>

<script>
import MenuFactory from '@/factories/parent/menu'
import { mapGetters } from 'vuex'
import store from "@/store"

export default {
  name: 'StartPage',
  data() {
    return {
      navItems: []
    }
  },
  computed: {
    ...mapGetters([ 'currentChild' ])
  },
  created() {
    if (this.currentChild) {
      this.init()
    }
  },
  methods: {
    init() {
      this.navItems = new MenuFactory(
        this.currentChild.schoolClass.school.is_pupil_traffic_changeable,
        this.isChildView,
        store.getters.settings
      ).filter(i => !i.disabled)

      if (this.isChildView) {
        this.navItems = this.navItems.filter(i => i.isParentOnly === undefined)
      }
    }
  },
  watch: {
    currentChild() {
      this.init()
    }
  },
  props: {
    isChildView: {
      type: Boolean,
      default: false
    }
  }
}
</script>
