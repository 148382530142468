<template>
  <v-container class="fs-teacher-container" fluid grid-list-md>
    <v-layout class="fs-teacher-order-result" wrap>
      <v-flex xs12 mb-5>
        <h1>{{ title }}</h1>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items.sync="items"
          class="elevation-1 fs-admin-table"
          item-key="name"
          no-data-text="Нет организаторов питания"
          hide-actions
        >
          <template v-slot:items="props">
            <tr :class="props.item.isActive ? '' : 'fs-blocked'">
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.username }}</td>
              <td>{{ props.item.shortName }}</td>
              <td>{{ props.item.isActive ? 'Нет' : 'Да' }}</td>
              <td>
                <v-flex row>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon mr-1"
                        @click="$router.push({ name: 'MunicipalityOrganizerView', params: { id: props.item.id, meta: 'view' }})"
                      >remove_red_eye</v-icon>
                    </template>
                    <span>Подробнее</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon mr-1"
                        @click="$router.push({ name: 'MunicipalityOrganizerEdit', params: { id: props.item.id, meta: 'edit' }})"
                      >edit</v-icon>
                    </template>
                    <span>Редактирование</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon mr-1"
                        @click="showModalBlockUser(props.item)"
                        :color="props.item.isActive ? 'red' : 'green'"
                      >{{ props.item.isActive ? 'lock' : 'lock_open' }}</v-icon>
                    </template>
                    <span>{{props.item.isActive ? 'Заблокировать' : 'Разблокировать'}}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        v-if="!props.item.isActive"
                        class="fs-active-icon"
                        @click="showDetailBlockUser(props.item)"
                        color="info"
                      >info</v-icon>
                    </template>
                    <span>Информация</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog v-model="lockUser.isShowModal" persistent width="60%">
      <v-card v-if="isShowDetailBlockUser">
        <v-toolbar elevation="1" dense light>
          <v-toolbar-title>Причина блокировки</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.prevent="closeModalBlockUser">
            <v-icon class="fs-active-icon">close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-textarea
            v-model="unlockUserForm.comment"
            :label="!darkTheme ? 'Комментарий' : ''"
            :placeholder="darkTheme ? 'Комментарий' : ''"
            :outline="darkTheme"
            readonly
          />
          <a target="_blank" :href="unlockUserForm.attachment">Скачать документ</a>
        </v-card-text>
      </v-card>
      <v-form v-else ref="lockModalForm" v-model="lockUser.valid">
        <v-card>
          <v-toolbar elevation="1" dense light>
            <v-toolbar-title>Заблокировать организатора</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="closeModalBlockUser">
              <v-icon class="fs-active-icon">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-textarea
              v-model="lockUser.form.comment"
              :rules="[rules.required]"
              :label="!darkTheme ? 'Комментарий' : ''"
              :placeholder="darkTheme ? 'Комментарий' : ''"
              :outline="darkTheme"
            />
            <v-text-field
              @click='onPickFile'
              :rules="[rules.required]"
              v-model='lockUser.form.attachment.fileName'
              :outline="darkTheme"
              :prepend-inner-icon="darkTheme ? 'attach_file' : ''"
              :prepend-icon="!darkTheme ? 'attach_file' : ''"
              clearable
              @click:clear="clearAttachment"
            ></v-text-field>
            <input
              type="file"
              style="display: none"
              ref="fileInput"
              accept="*/*"
              @change="onFilePicked">
          </v-card-text>
          <v-card-actions>
            <v-btn @click="saveLockUser" style="margin-left: auto;">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <ConfirmModal
      confirmBtnText="Да"
      :dialog.sync="isShowUnlockModal"
      @confirm="handlerConfirmUnlock"
      btnsClass="fsisShowDetailBlockUser-btn fs-teacher-btn__cancel"
    >
      <template v-slot:headline>
        <div>Вы действительно хотите <b>разблокировать</b> организатора?</div>
      </template>
    </ConfirmModal>
  </v-container>
</template>

<script>
import scrollable from '@/mixins/scrollable'
import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import Modal from '@/ui-components/Modal'
import Table from '@/ui-components/Table'

import { getSuppliers, disableOrganizer, enableOrganizer } from '@/router/routes/department'
import { getOrganizers, updateOrganizerStatusLock, getOrganizerStatusLock } from '@/router/routes/department'

import ConfirmModal from '@/components/common/Modals/ConfirmModal'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'MunicipalityOPList',
  mixins: [ scrollable ],
  components: { Modal, Table, ConfirmModal },
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      isShowDetailBlockUser: false,
      unlockUserForm: {
        comment: '',
        attachment: ''
      },
      isShowUnlockModal: false,
      lockUser: {
        id: null,
        valid: false,
        isShowModal: false,
        isUserLock: false,
        form: {
          comment: '',
          attachment: {
            url: '',
            fileName: '',
            fileObject: null
          }
        }
      },
      rules: {
        required: (v) => !!v || "Поле обязательно для заполнения"
      },
      limit: Vue.config.general.limit,
      title: 'Список организаторов питания',
      modalResponse: false,
      showArchive: false,
      getSuppliers,
      currentOPId: null,
      params: {
        archive: false,
        department: null
      },
      headers: [
        { text: '№', value: 'id' },
        { text: 'Логин', value: 'username' },
        { text: 'Название', value: 'shortName' },
        { text: 'Заблокирован', value: 'isActive' },
        { text: 'Операции', value: 'operations', sortable: false }
      ],
      items: [],
    }
  },
  created() {
    this.params.department = this.departmentId || null
    window.addEventListener('scroll', this.scrollHandler)
    this.loadMore()
  },
  computed: {
    ...mapGetters([ 'currentUser', 'departmentId' ])
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    onPickFile () {
      this.$refs.fileInput.click()
    },
    async showDetailBlockUser(user) {
      await getOrganizerStatusLock({ supplier_id: user.id })
        .then(res => {
          this.unlockUserForm = { comment: '', attachment: '' }
          this.unlockUserForm = { comment: res.data.comment, attachment: res.data.attachment }
        })
        .finally(() => {
          store.commit('setShowPreloader', false)
          this.isShowDetailBlockUser = true
          this.lockUser.isShowModal = true
        })
    },
    clearAttachment() {
      this.lockUser.form.attachment.fileName = ''
      this.lockUser.form.attachment.fileObject = null
      this.lockUser.form.attachment.url = ''
    },
    async saveLockUser() {
      this.$refs.lockModalForm.validate()
      if (this.lockUser.valid) {
        const formData = new FormData()
        formData.append('is_active', false)
        formData.append('attachment', this.lockUser.form.attachment.fileObject)
        formData.append('comment', this.lockUser.form.comment)
        await updateOrganizerStatusLock({ formData: formData, supplier_id: this.lockUser.id })
          .finally(() => {
            store.commit('setShowPreloader', false)
            this.closeModalBlockUser()
            this.offset = 0
            this.items = []
            this.loadMore()
          })
      }
    },
    onFilePicked (event) {
      const files = event.target.files

      if (files[0] !== undefined) {
        const fileName = files[0].name
        if (fileName.lastIndexOf('.') <= 0) return

        // If valid, continue
        const fr = new FileReader()
        fr.readAsDataURL(files[0])

        fr.addEventListener('load', () => {
          this.lockUser.form.attachment.url = fr.result
          this.lockUser.form.attachment.fileName = fileName || 'Имя не указано'
          this.lockUser.form.attachment.fileObject = files[0] // this is an file that can be sent to server...
        })
      }
      else this.clearAttachment()
    },
    async handlerConfirmUnlock(value) {
      if (value) {
        const formData = new FormData()
        formData.append('is_active', true)
        await updateOrganizerStatusLock({ formData: formData, supplier_id: this.lockUser.id })
          .finally(() => {
            store.commit('setShowPreloader', false)
            this.isShowUnlockModal = false
            this.offset = 0
            this.items = []
            this.loadMore()
          })
      }
      else this.isShowUnlockModal = false
    },
    async showModalBlockUser(user) {
      this.lockUser.id = user.id
      if (!user.isActive) {
        this.isShowUnlockModal = true
        return
      }
      this.lockUser.isShowModal = true
    },
    closeModalBlockUser() {
      this.isShowDetailBlockUser = false
      this.lockUser.form.comment = ''
      this.lockUser.isShowModal = false
      this.lockUser.id = null
      this.clearAttachment()
    },
    async loadMore() {
      const payload = {
        department_id: this.currentUser.department.id,
        params: {
          limit: this.limit,
          offset: this.offset
        }
      }
      getOrganizers(payload)
        .then(response => {
          const items = response.data.data
          this.items = this.items.concat(items)
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    organizerEnable(id) {
      return enableOrganizer({ supplierId: id })
        .then(response => {
          if (response.status === 204) {
            notify.success('Организатор разблокирован успешно')

            this.$refs.suppliers.requestData()
          }
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    organizerDisable(id) {
      return disableOrganizer({ supplierId: id })
        .then(response => {
          if (response.status == 204) {
            notify.success('Организатор заблокирован успешно')

            this.$refs.suppliers.requestData()
          }
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    handlerModal(value) {
      this.$refs.confirmLock.close()

      this.modalResponse = value
      if (this.modalResponse && this.currentOPId) {
        this.organizerDisable(this.currentOPId)
        this.currentOPId = null
      }
    },
    changeShowArchive() {
      this.showArchive = this.params.archive = !this.showArchive

      this.$refs.suppliers.resetPagination()
      this.$refs.suppliers.requestData()
    },
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
