<template>
  <v-card flat>
    <v-card-text>
      <h4>Водоснабжение</h4>
      <v-select
        :items="waterSupply.items"
        v-model="form.water_supply"
        item-value="value"
        item-text="title"

        :outline="darkTheme"
      />
      <v-text-field
        v-if="form.water_supply === 5"
        v-model="form.water_supply_other"
        clearable

        :label="!darkTheme ? 'Уточнение:' : ''"
        :placeholder="darkTheme ? 'Уточнение:' : ''"
        :outline="darkTheme"
      />
    </v-card-text>

    <v-card-text>
      <h4>Горячее водоснабжение</h4>
      <v-text-field
        v-model="form.hot_water_supply"
        clearable

        :label="!darkTheme ? 'Указать источник:' : ''"
        :placeholder="darkTheme ? 'Указать источник:' : ''"
        :outline="darkTheme"
      />
    </v-card-text>

    <v-card-text>
      <h4>Наличие резервного горячего водоснабжения</h4>
      <v-checkbox
        v-model="form.Back_up_hot_water_supply"
        :label="form.Back_up_hot_water_supply ? 'есть' : 'нет'"
        clearable
      />
    </v-card-text>

    <v-card-text>
      <h4>Отопление</h4>
      <v-select
        :items="hotWaterSupply.items"
        v-model="form.heating"
        item-value="value"
        item-text="title"
        :outline="darkTheme"
      />
    </v-card-text>

    <v-card-text>
      <h4>Водоотведение</h4>
      <v-select
        :items="wastewaterDisposal.items"
        v-model="form.wastewater_disposal"
        item-value="value"
        item-text="title"
        :outline="darkTheme"
      />
      <v-text-field
        v-if="form.wastewater_disposal === 5"
        v-model="form.wastewater_disposal_other"
        clearable
        :label="!darkTheme ? 'Уточнение:' : ''"
        :placeholder="darkTheme ? 'Уточнение:' : ''"
        :outline="darkTheme"
      />
    </v-card-text>

    <v-card-text>
      <h4>Вентиляция (механическая)</h4>
      <v-checkbox
        v-model="form.ventilation"
        :label="form.ventilation ? 'есть' : 'нет'"
        clearable
      />
    </v-card-text>

    <v-btn
        flat
        @click="update"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Сохранить</v-btn>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import store from "@/store"
export default {
  name: 'Form2',
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      id: null,
      form: {
        water_supply: 5,
        water_supply_other: '',
        hot_water_supply: '',
        Back_up_hot_water_supply: false,
        heating: 1,
        wastewater_disposal: 5,
        wastewater_disposal_other: '',
        ventilation: '',
      },
      waterSupply: {
        items: [
          { title: 'Централизованное', value: 1 },
          { title: 'От сетей населенного пункта', value: 2 },
          { title: 'Собственная скважина учреждения', value: 3 },
          { title: 'Вода привозная', value: 4 },
          { title: 'Прочее (уточнить)', value: 5 }
        ]
      },
      hotWaterSupply: {
        items: [
          { title: 'Централизованное', value: 1 },
          { title: 'От сетей населенного пункта', value: 2 },
          { title: 'Собственная котельная', value: 6 }
        ]
      },
      wastewaterDisposal: {
        items: [
          { title: 'Централизованное', value: 1 },
          { title: 'От сетей населенного пункта', value: 2 },
          { title: 'Выгребная яма', value: 7 },
          { title: 'Выгребная яма', value: 7 },
          { title: 'Локальные очистные сооружени', value: 8 },
          { title: 'Прочее', value: 5 },
        ]
      }
    }
  },
  computed: {
    ...mapGetters([ 'foodBlockPassport' ])
  },
  mounted() {
    this.getFoodblockEngineer(this.foodBlockPassport.engineering_sections.id)
      .then(result => this.setForm(result))
  },
  methods: {
    ...mapActions([ 'getFoodblockEngineer', 'updateFoodBlockEngineer' ]),
    setForm(formFromApi) {
      this.id = formFromApi.id
      this.form = formFromApi
    },
    update() {
      this.updateFoodBlockEngineer({ id: this.id, data: this.form })
        .then(result => this.setForm(result))
    },
  },
  watch: {
    'form.water_supply'(val) {
      if (val !== 5) this.form.water_supply_other = ''
    },
    'form.wastewater_disposal'(val) {
      if (val !== 5) this.form.wastewater_disposal_other = ''
    },
  }
}
</script>
