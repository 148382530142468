<template>
  <v-form id="auth-form">
    <v-container grid-list-md>
      <v-layout justify-space-around>
        <v-flex lg5 md6 sm8>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Пожалуйста, авторизуйтесь</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="login"
                type="text"
                name="login"
                label="Логин"
                prepend-icon="person"
              ></v-text-field>
              <v-text-field
                v-model="password"
                type="password"
                id="password"
                name="password"
                label="Пароль"
                prepend-icon="lock"
                @keydown.enter="signin"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <EsiaConnectButton :is-auth="true" />
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="signin">Войти</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import Auth from '@/utils/auth'
import store from '@/store'
import { getOrganizerFoodTypes } from '@/router/routes/organizer'
import { mapGetters, mapMutations } from 'vuex'
import notifyPooling from '@/helpers/notifyPooling'
import EsiaConnectButton from "@/components/esia/components/EsiaButton.vue";

export default {
  name: 'AuthIndex',
  components: {EsiaConnectButton},
  data() {
    return {
      login: null,
      password: null,
      paths: {
        SCHOOL_MEAL_ADMIN: '/administrators/dashboard-admin',
        TEACHER: '/teachers',
        PARENT: '/parents',
        DEPARTMENT: '/municipalities',
        ADMIN: '/moduleadmin',
        SUPPLIER: '/organizers/dashboard',
        DEPARTMENT_ADMIN: '/supermunicipality',
        PUPIL: '/pupils',
        BUFFET: '/buffet/'
      },
      pushed: false,
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ]),
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    signin() {
      const auth = new Auth()

      store
        .dispatch('SIGNIN', { username: this.login, password: this.password })
        .then(() => {
          if (this.paths[this.currentUser.roles[0]]) {
            if (this.currentUser.roles[0] === 'BUFFET') {
              window.location = '/buffet/'
            } else {
              this.$router.push({
                path: this.paths[this.currentUser.roles[0]],
                params: { reauth: true },
              })
              this.pushed = true
            }
          }

          auth.setCurrentUser(this.currentUser)

          if (auth.isSupplier) {
            store.dispatch('GET_FOOD_PERIODS')
            store.dispatch('GET_FOOD_TIMES')
            store.dispatch('GET_FOOD_TYPES')
            store.dispatch('GET_AGE_CATEGORIES')
            store.dispatch('GET_SHIFTS')
          }
          // if (auth.isSubSupplierRole) {
          //   this.currentUser.roles.forEach((role) => {
          //     if (auth.subSupplierIncludesMenu(role)) {
          //       store.dispatch('GET_FOOD_TYPES', {
          //         method: getOrganizerFoodTypes,
          //       })
          //     } else {
          //       store.dispatch('GET_FOOD_PERIODS')
          //       store.dispatch('GET_FOOD_TIMES')
          //       store.dispatch('GET_AGE_CATEGORIES')
          //       store.dispatch('GET_SHIFTS')
          //     }
          //   })
          // }

          store.dispatch('GET_NOTIFICATIONS_COUNT')
          notifyPooling()
        })
        .then(() => {
          if (this.pushed) return
          this.$router.push(auth.getLocation())
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
  },
}
</script>

<style scoped>
form#auth-form {
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: -4%;
}
</style>
