import {
  getChildrenOrders, createOrderQualityAssessments,
  createImageOrderQualityAssessments, getQualityAssessmentsDetail, getQualityAssessmentOrders,
} from '@/router/routes/parent'

export default {
  actions: {
    async getQualityAssessmentsDetail(ctx, id) {
      return await getQualityAssessmentsDetail(id)
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          return Promise.reject(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async getChildrenOrders(ctx, payload) {
      return await getChildrenOrders(payload)
        .then(res => {
          ctx.commit('set_children_orders', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async getQualityAssessmentOrders(ctx, params) {
      return await getQualityAssessmentOrders(params)
        .then(res => {
          ctx.commit('set_children_orders', res.data)
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async createOrderQualityAssessments(ctx, payload) {
      return await createOrderQualityAssessments(payload)
        .then(res => {
          return Promise.resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          return Promise.reject()
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
    async createImageOrderQualityAssessments(ctx, payload) {
      const formData = new FormData()
      formData.append("description", payload.description)
      formData.append("quality_assessment", payload.quality_assessment)
      formData.append("image", payload.image)

      return await createImageOrderQualityAssessments(formData)
        .then(() => {
          return Promise.resolve()
        })
        .catch(err => {
          console.error(err)
          return Promise.reject()
        })
        .finally(() => {
          ctx.commit('setShowPreloader', false)
        })
    },
  },
  mutations: {
    set_children_orders(state, transactions) {
      state.children_orders = transactions
    },
    set_children_orders_pagination(state, paginate) {
      state.children_orders_pagination = {
        ...state.children_orders_pagination,
        ...paginate
      }
    }
  },
  getters: {
    children_orders(state) {
      return state.children_orders
    },
    children_orders_pagination(state) {
      return state.children_orders_pagination
    }
  },
  state: {
    children_orders: {},
    children_orders_pagination: {
      page: 1,
      page_size: 10
    }
  }
}
