import {
  createComplex,
  getComplex,
  getDishes,
  updateComplex
} from '@/router/routes/organizer'
import _ from 'lodash'
import Vue from 'vue'
import notify from '@/utils/notifications/toastNotification.vue'

const initialisationState = () => ({
  complex: {},
  complexList: {},
  dishList: [],
  isEditMode: false,
  isCreateMode: false,
  isProgress: false,
  specification: {}
})

export default {
  state: initialisationState(),

  actions: {
    async fetchDishes(ctx) {
      try {
        const {
          data: { data: dishes }
        } = await getDishes()
        ctx.commit('set_dish_list', dishes)
      } catch (e) {
        console.error(e)
      } finally {
        ctx.commit('setShowPreloader', false)
      }
    },

    async fetchComplex(ctx, payload) {
      try {
        const {
          data: { data: complex }
        } = await getComplex(payload)
        ctx.commit('set_complex', complex)
      } catch (e) {
        console.error(e)
      } finally {
        ctx.commit('setShowPreloader', false)
      }
    },

    async updateComplex(ctx, payload) {
      try {
        const {
          data: { data: complex }
        } = await updateComplex(payload)
        ctx.commit('set_complex', complex)
        ctx.commit('change_edit_mode', false)
        notify.success(
          `Спецификация комплекса "${complex.name}" успешно обновлена`
        )
      } catch (err) {
        console.error(err)
      } finally {
        ctx.commit('setShowPreloader', false)
      }
    },

    async createComplex(ctx, payload) {
      try {
        const {
          data: { data: complex }
        } = await createComplex(payload)
        ctx.commit('set_complex', complex)
        notify.success(`Комплекс "${complex.name}" успешно создан`)
      } catch (err) {
        console.error(err)
      } finally {
        ctx.commit('setShowPreloader', false)
      }
    }
  },

  mutations: {
    set_complex(state, complex) {
      const meals = {}
      Object.entries(Vue.config.general.meals).forEach(([k, v]) => {
        const meal =
          _.find(complex.fp, (item) => item.meal_period === v.id) ?? null

        meals[k] = meal?.id ?? null
        state.specification[k] = meal?.foods ?? null
      })
      state.complex = { ...complex, meals }
    },
    change_complex(state, item) {
      state.complex = { ...state.complex, ...item }
    },
    // set_complex_list(state, list) {
    //   state.complexList = list
    // },
    set_dish_list(state, list) {
      state.dishList = list
    },
    change_edit_mode(state, value) {
      state.isEditMode = value
    },
    change_create_mode(state, value) {
      state.isCreateMode = value
      state.isEditMode = false
    },
    // set_specification(state, mealTypes) {},
    resetState(state) {
      Object.assign(state, initialisationState())
    }
  },

  getters: {
    complex: (state) => state.complex,
    // complexList: (state) => state.complexList,
    dishList: (state) => state.dishList,
    getEditMode: (state) => state.isEditMode,
    getCreateMode: (state) => state.isCreateMode,
    specification: (state) => state.specification
  }
}
