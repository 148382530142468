<template>
  <FoodTypeIndex :archive-view="true" />
</template>

<script>
import FoodTypeIndex from '@/components/municipality/FoodType/Index'

export default {
  name: 'FoodTypeIndexArchive',
  components: {
    FoodTypeIndex
  },
}
</script>
