export default {
  setShowPreloader(state, showPreloader) {
    state.showPreloader = showPreloader
  },
  setAsyncLoadFile(state, value) {
    state.asyncLoadFile = value
  },
  setAsyncLoadFileWithProgressBar(state, value) {
    state.asyncLoadFileWithProgressBar = value
  },
  setAsyncLoadFileLoaderProgressBarValue(state, value) {
    state.asyncLoadFileLoaderProgressBarValue = value
  },
  setAsyncRequestLoadProgressBar(state, value) {
    state.asyncRequestWithProgressBar = value
  },
  setAsyncRequestProgressBarValue(state, value) {
    state.asyncRequestProgressBarValue = value
  },
  setLoaderMessage(state, loaderMessage) {
    state.loaderMessage = loaderMessage
  },
  setCurrentUser(state, user) {
    state.currentUser = user
    localStorage.setItem('user_id', user.user_id)
  },
  setCurrentChild(state, child) {
    state.currentChild = child
  },
  setNotVisitedIds(state, id) {
    if (!state.notVisitedIds.includes(id)) {
      state.notVisitedIds.push(id)
    }
  },
}
