<template>
  <v-app>
    <Main
      :toolbarItems="menuItems"
      :title="menuTitle"
    ></Main>
    <Container>
      <Title layout>{{ menuTitle }}</Title>
      <transition name="slide-fade">
        <router-view></router-view>
      </transition>
    </Container>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

import Main from '@/layouts/common/Main'

import Container from '@/ui-components/Container'
import Title from '@/ui-components/Title'

import AdminMenu from '@/factories/moduleadmin/menu'

export default {
  name: 'ModuleAdminIndex',
  components: {
    Main,
    Container,
    Title
  },
  computed: {
    ...mapGetters({
      'currentUser': 'currentUser'
    })
  },
  methods: {
    initTitle() {
      if (this.$route.meta && this.$route.meta.title) {
        this.menuTitle = this.$route.meta.title
      }
    }
  },
  data: () => ({
    menuTitle: '',
    menuItems: []
  }),
  created() {
    this.initTitle()

    if (this.currentUser.roles.length) {
      switch (this.currentUser.roles[0]) {
      case 'ADMIN':
        this.menuItems = new AdminMenu()
        break

      default:
        break
      }
    }
  },
  beforeUpdate() {
    this.initTitle()
  }
}
</script>
