<template>
  <ParentStartPage is-child-view />
</template>

<script>
import ParentStartPage from '@/components/parent/StartPage/Index'

export default {
  name: 'PupilStartPage',
  components: {
    ParentStartPage
  }
}
</script>
