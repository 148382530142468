<script>
import { getters, actions } from '../store'

export default {
  props: {
    colors: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...actions
  },
  computed: {
    ...getters
  }
}
</script>

<template>
  <div
    class="sc-suggestions-row"
    :class="{ 'sc-suggestions-end': isDisableInput }"
    :style="{ background: colors.messageList.bg }"
  >
    <button
      v-for="(suggestion, idx) in suggestions"
      :key="suggestion?.id ?? idx"
      class="sc-suggestions-element"
      :style="{
        borderColor: colors.sentMessage.text,
        backgroundColor: colors.sentMessage.bg,
        color: colors.sentMessage.text
      }"
      :disabled="isLoading"
      @click="sendSuggestion(suggestion)"
    >
      <v-icon :color="colors.sentMessage.text" v-if="suggestion.icon" small>{{
        suggestion.icon
      }}</v-icon>
      <v-icon
        :color="colors.sentMessage.text"
        v-if="suggestion.type === 'redirect'"
        small
        >launch</v-icon
      >
      {{ suggestion.title ?? suggestion.label }}
    </button>
  </div>
</template>

<style>
.sc-suggestions-row {
  text-align: center;
  background: inherit;
  padding: 8px;
}

.sc-suggestions-element {
  margin: 3px;
  padding: 5px 10px 5px 10px;
  border: 1px solid;
  border-radius: 15px;
  font-size: 14px;
  background: inherit;
  cursor: pointer;
}
.sc-suggestions-end {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
