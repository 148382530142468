<template>
  <v-app>
    <SuperAdminReturn v-if="isSuperAdmin" />
    <main-menu :toolbarItems="menuItems" :title="menuTitle">
      <template v-slot:newMenuItem>
        <v-spacer class="px-2" />
        <v-tooltip top class="mr-4">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="logout">
              <v-icon>logout</v-icon>
            </v-btn>
          </template>
          <span>Выйти</span>
        </v-tooltip>
      </template>
    </main-menu>
    <transition name="slide-fade">
      <router-view></router-view>
    </transition>
    <AlertUpdateInformation
      @closeDialogEvent="closeDialog()"
      :dialog="showAlertDialog"
      :block="blockDialog"
      :message="alertMessage"
    />
  </v-app>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import MainMenu from '@/layouts/common/Main'
import MenuFactory from '@/factories/organizer/menu'
import store from '@/store'
import { getOrganizerFoodTypes } from '@/router/routes/organizer'
import notifyPooling from '@/helpers/notifyPooling'
import SuperAdminReturn from '@/components/common/SuperAdminReturn'
import AlertUpdateInformation from '@/components/common/Modals/AlertUpdateInformation'
import { getAlertsList } from '@/router/routes/alerts'
import notify from '@/utils/notifications/toastNotification'
import { logout } from '@/router/routes/common'

export default {
  name: 'OrganizerIndex',
  components: {
    SuperAdminReturn,
    AlertUpdateInformation,
    'main-menu': MainMenu
  },
  mounted() {
    this.checkAlertInformation()
    if (this.$route.params.reauth) {
      this.storeInit()
    }
    this.superAdminCheck()
  },
  computed: {
    ...mapGetters(['currentUser']),
    isSupplier() {
      return this.currentUser.roles[0] === 'SUPPLIER'
    },
    menuItems() {
      return this.isSupplier
        ? new MenuFactory(null, store.getters.settings, this.currentUser)
        : new MenuFactory(this.currentUser.roles, store.getters.settings)
    }
  },
  data() {
    return {
      menuTitle: 'Главная',
      isSuperAdmin: false,
      showContactModal: false,
      showAlertDialog: false,
      blockDialog: false,
      alertMessage: null
    }
  },
  methods: {
    async logout() {
      try {
        await logout()
        await this.$router.push({ name: 'AuthIndex' })
      } catch (e) {
        console.log(e)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    superAdminCheck() {
      if (
        sessionStorage.getItem('superadmin_token') &&
        sessionStorage.getItem('superadmin_user_id')
      ) {
        this.isSuperAdmin = true
        return true
      } else return false
    },
    async storeInit() {
      try {
        await store.dispatch('GET_FOOD_PERIODS')
        await store.dispatch('GET_FOOD_TIMES')
        await store.dispatch('GET_FOOD_CATEGORIES')
        await store.dispatch('GET_FOOD_TYPES', {
          method: getOrganizerFoodTypes
        })
        await store.dispatch('GET_AGE_CATEGORIES')
        await store.dispatch('GET_SHIFTS')
        await store.dispatch('GET_NOTIFICATIONS_COUNT')
        notifyPooling()
      } catch (error) {
        notify.error(`Ошибка авторизации ${error}`) //pass
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async checkAlertInformation() {
      try {
        const response = await getAlertsList()
        const data = response.data
        const res = data.find(({ start_time, end_time }) => {
          const curDate = new Date(new Date().toISOString())
          return curDate > new Date(start_time) && curDate < new Date(end_time)
        })
        if (res !== undefined) {
          this.alertMessage = `
            <b>
              Внимание. На данный момент ведутся технические работы.
            <b> <br>
            Время окончания: ${new Date(res.end_time).toLocaleString()}`
          this.blockDialog = res.is_block
          this.showAlertDialog = true
        } else {
          const min = Math.min(
            ...data.map((item) => new Date(item.start_time).valueOf())
          )
          const res_ = data.filter(
            (item) => new Date(item.start_time).valueOf() === min
          )
          this.blockDialog = res_[0].is_block
          this.alertMessage = `${
            res_[0].message
          } <br><br><b>Планируемое время проведения работ: с ${new Date(
            res_[0].start_time
          ).toLocaleString()} по ${new Date(
            res_[0].end_time
          ).toLocaleString()}</b>`
          this.showAlertDialog = true
        }
      } catch (error) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    closeDialog() {
      this.showAlertDialog = false
    }
  }
}
</script>
