var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":_vm.width,"persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-btn',{staticClass:"fs-btn fs-btn--secondary",attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',{staticClass:"py-0"},[_c('v-stepper',{model:{value:(_vm.stepper),callback:function ($$v) {_vm.stepper=$$v},expression:"stepper"}},[_c('v-stepper-header',{staticClass:"elevation-3 mb-1"},[_c('v-stepper-step',{attrs:{"complete":_vm.stepper > 1,"step":"1"}},[_vm._v(" Изменение ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.stepper > 2,"step":"2"}},[_vm._v(" Просмотр ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3"}},[_vm._v(" Сохранение ")])],1),_c('v-stepper-content',{staticClass:"pa-0 mt-1",attrs:{"step":"1"}},[_c('v-flex',{staticClass:"table-container pa-0"},[_c('v-data-table',{staticClass:"custom-data-table",attrs:{"headers":_vm.schoolHeaders,"items":_vm.changedSchools,"item-key":"id","hide-actions":""},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',[_c('td',{staticClass:"px-2 body-2"},[_vm._v(_vm._s(props.item.id))]),_c('td',{staticClass:"px-2 body-2"},[_vm._v(_vm._s(props.item.short_name))]),_c('td',{staticClass:"px-2",staticStyle:{"min-width":"300px"}},[_c('v-autocomplete',{staticClass:"ma-1 pa-0 body-2",class:{
                        'cyan lighten-5': !props.item.supplier?.id
                      },staticStyle:{"width":"300px"},attrs:{"items":_vm.mealsOrganizations,"item-text":"shortName","item-value":"id","label":"Организатор питания","box":"","solo":"","dense":"","hide-details":"","return-object":"","disabled":props.item?.is_changes,"loading":_vm.mealsOrganizationsLoading},on:{"change":($value) => _vm.changeSchool(props.item, $value)},model:{value:(props.item.supplier),callback:function ($$v) {_vm.$set(props.item, "supplier", $$v)},expression:"props.item.supplier"}})],1),_c('td',{staticClass:"px-2",staticStyle:{"min-width":"150px"}},[(props.item?.is_changes)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v("help_outline")])]}}],null,true)},[_vm._v(" Находится в обработке. "),_c('br'),_vm._v(" Не доступно для изменения ")])]:_vm._e(),(
                        props.item?.changed && !_vm.hasFirstAppoint(props.item)
                      )?_c('v-switch',{staticClass:"pa-0 ma-0 body-2",attrs:{"disabled":props.item?.balanceSavingSwitchDisabled,"hide-details":"","color":"primary"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"body-2"},[_c('strong',{class:props.item.savedBalance
                                ? 'success--text'
                                : 'error--text'},[_vm._v(" "+_vm._s(props.item.savedBalance ? 'Сохранить' : 'Обнулить')+" балансы ")])])]},proxy:true}],null,true),model:{value:(props.item.savedBalance),callback:function ($$v) {_vm.$set(props.item, "savedBalance", $$v)},expression:"props.item.savedBalance"}}):_vm._e()],2)])]}}])})],1)],1),_c('v-stepper-content',{staticClass:"pa-0",attrs:{"step":"2"}},[_c('v-flex',{staticClass:"table-container pa-0"},[_c('v-data-table',{staticClass:"custom-data-table",attrs:{"headers":_vm.schoolChangedHeaders,"items":_vm.onlyChangesSchools,"hide-actions":"","loading":_vm.balanceIsLoading,"no-data-text":"Нет данных изменения","expand":_vm.expand,"item-key":"id"},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{class:{ 'th-active': props.expanded },on:{"click":function($event){props.expanded = !props.expanded}}},[_c('td',{staticClass:"text-center px-2"},[_vm._v(_vm._s(props.item.id))]),_c('td',{staticClass:"px-2"},[_vm._v(_vm._s(props.item.short_name))]),_c('td',{staticClass:"px-2",class:{
                      'info--text font-weight-bold': !_vm.schoolsMap.get(
                        props.item.id
                      ).supplier?.id
                    }},[_vm._v(" "+_vm._s(_vm.schoolsMap.get(props.item.id).supplier?.short_name || _vm.schoolsMap.get(props.item.id).supplier?.shortName || 'Не назначен')+" ")]),_c('td',{staticClass:"px-1"},[_c('v-icon',{attrs:{"color":"blue-grey"}},[_vm._v("keyboard_double_arrow_right")])],1),_c('td',{staticClass:"px-2",class:{
                      'info--text font-weight-bold': !props.item.supplier?.id
                    }},[_vm._v(" "+_vm._s(props.item.supplier?.short_name || props.item.supplier?.shortName || 'Не назначен')+" ")]),_c('td',{staticClass:"text-center px-2"},[_c('strong',{staticClass:"font-weight-bold",class:props.item.savedBalance
                          ? 'success--text'
                          : 'error--text'},[_vm._v(" "+_vm._s(props.item.savedBalance ? 'Да' : 'Нет')+" ")])]),_c('td',{staticClass:"text-center px-2"},[_c('v-icon',[_vm._v(_vm._s(props.expanded ? 'expand_less' : 'expand_more'))])],1)])]}},{key:"expand",fn:function(props){return [_c('v-flex',{staticClass:"gray ma-1 pa-1 elevation-3"},[_c('v-alert',{attrs:{"value":_vm.hasFirstAppoint(props.item) &&
                      _vm.balanceListMap
                        .get(props.item.id)
                        ?.pupil_balance.filter((i) => i.balance !== 0)
                        ?.length > 0,"type":"error"}},[_vm._v(" ИЗМЕНЕНИЕ ДАННОЙ ШКОЛЫ НЕВОЗМОЖНО, ПОКА НЕ ОБНУЛЕНЫ ВСЕ БАЛАНСЫ УЧЕНИКОВ ")]),_c('v-divider'),_c('v-data-table',{staticClass:"custom-data-table",attrs:{"headers":_vm.balanceHeaders,"items":_vm.balanceListMap
                        .get(props.item.id)
                        ?.pupil_balance.filter((i) => i.balance !== 0),"hide-actions":"","loading":_vm.balanceIsLoading,"no-data-text":"Нет данных изменения балансов"},scopedSlots:_vm._u([{key:"items",fn:function(propsExpand){return [_c('tr',[_c('td',{staticClass:"px-2"},[_vm._v(_vm._s(propsExpand.item.id))]),_c('td',{staticClass:"px-2"},[_vm._v(" "+_vm._s(propsExpand.item.full_name)+" ")]),_c('td',{staticClass:"px-2"},[_vm._v(" "+_vm._s(propsExpand.item.school_class)+" ")]),_c('td',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.convertToRubles( propsExpand.item.balance ).toLocaleString('ru-RU'))+" руб. ")]),_c('td',{staticClass:"px-2",class:_vm.paintGraph(propsExpand.item, props.item)},[_vm._v(" "+_vm._s(_vm.convertToRubles( props.item.savedBalance ? propsExpand.item.balance : 0 ).toLocaleString('ru-RU'))+" руб. ")])])]}}],null,true)}),_c('v-divider')],1)]}}])})],1)],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-flex',{attrs:{"xs12":"","text-xs-center":""}},[_c('v-btn',{attrs:{"color":"primary","large":""},on:{"click":_vm.save}},[_vm._v("Сохранить")])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pa-4"},[(_vm.stepper > 1)?_c('v-btn',{on:{"click":function($event){_vm.stepper--}}},[_vm._v("Назад")]):_vm._e(),_c('v-spacer'),(_vm.stepper < 3)?_c('v-btn',{attrs:{"color":"primary","disabled":_vm.balanceIsLoading || !_vm.hasChanges},on:{"click":function($event){_vm.stepper++}}},[_vm._v(" Далее ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }