<template>
  <v-container fluid grid-list-xl>
    <v-layout wrap align-center>
      <v-flex xs12 sm6>
        <v-text-field
          ref='searchField'
          v-model='searchQuery'
          clearable
          :label="!darkTheme ? 'Поиск' : ''"
          :placeholder="darkTheme ? 'Поиск' : ''"
          :outline="darkTheme"
        />
        <v-select
          v-model="fallen"
          :items="fallenFilter"
          item-text="name"
          item-value="value"
          return-object

          :label="!darkTheme ? 'Фильтр по рейтингу' : ''"
          :placeholder="darkTheme ? 'Фильтр по рейтингу' : ''"
          :outline="darkTheme"
        />
      </v-flex>

      <v-flex xs12 sm6>
        <v-select
          v-model="avg_evaluation"
          :items="evaluations"
          item-text="id"
          item-value="id"
          return-object
          clearable
          :label="!darkTheme ? 'Фильтрация по средней оценке (меньше чем)' : ''"
          :placeholder="darkTheme ? 'Фильтрация по средней оценке (меньше чем)' : ''"
          :outline="darkTheme"
        />
        <v-select
          v-model='ordering'
          :items="sorting"
          item-text="text"
          item-value="value"
          :label="!darkTheme ? 'Сортировка' : ''"
          :placeholder="darkTheme ? 'Сортировка' : ''"
          :outline="darkTheme"
        />
      </v-flex>
    </v-layout>


    <v-flex class='mt-3'>
      <v-data-table
        :headers="headers"
        :items.sync="qualityAvgSuppliers.results"
        hide-actions
        no-data-text="Нет информации"
        class="elevation-1 fs-admin-table"
        item-key="name"
      >
        <template v-slot:items="props">
          <tr :class="{ 'danger-zone': isSupplierGradeDangerZone(props.item.summ_evaluation) }">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.username }}</td>
            <td>{{ props.item.short_name }}</td>
            <td>
              <div style="display: flex; align-items: center;">
                  <v-rating
                  v-model="props.item.summ_evaluation"
                  length="10"
                  readonly
                />
                ( {{ fixedRating(props.item.summ_evaluation) === '0.00' ? 'Нет оценок' : fixedRating(props.item.summ_evaluation) }} )
              </div>

            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>

    <div class="text-xs-center" v-if="pagination_count > 1">
      <v-pagination
        @input="handlePaginationClick"
        :value="qualityAvgSuppliersPagination.page"
        :length="pagination_count"
        :total-visible="7"
      ></v-pagination>
    </div>

  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'QualityAvgSuppliers',
  data: () => ({
    darkTheme: Vue.config.env.darkTheme,
    gradeDanger: 3,
    headers: [
      { text: 'ID', value: 'id', sortable: false },
      { text: 'ОП', value: 'username', sortable: false },
      { text: 'Название', value: 'short_name', sortable: false },
      { text: 'Средняя оценка', value: 'sum_evaluation', sortable: false }
    ],
    searchQuery: '',
    fallenFilter: [
      { name: 'Все', value: null },
      { name: 'ОП с низким рейтингом', value: true },
    ],
    evaluations: [
      { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 },
      { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }
    ],
    sorting: [
      { text: 'Без сортировки', value: null },
      { text: 'По убыванию', value: '-summ_evaluation' },
      { text: 'По возрастанию', value: 'summ_evaluation' }
    ],
    ordering: null
  }),
  computed: {
    ...mapGetters([ 'qualityAvgSuppliers', 'qualityAvgSuppliersPagination', 'qualityAvgSuppliersFilters' ]),

    fallen: {
      get() {
        return this.qualityAvgSuppliersFilters.fallen
      },
      set(object) {
        if (object) this.setQualityAvgSuppliersFilters({ fallen: object.value })
        else this.setQualityAvgSuppliersFilters({ fallen: null })
      }
    },
    avg_evaluation: {
      get() {
        return this.qualityAvgSuppliersFilters.avg_evaluation
      },
      set(object) {
        if (object) this.setQualityAvgSuppliersFilters({ avg_evaluation: object.id })
        else this.setQualityAvgSuppliersFilters({ avg_evaluation: null })
      }
    },
    pagination_count() {
      return Math.ceil(this.qualityAvgSuppliers.count / this.qualityAvgSuppliersPagination.page_size) || 0
    },
  },
  mounted() {
    if (!this.qualityAvgSuppliers.results) this.get_quality_avg_suppliers()
  },
  methods: {
    ...mapActions([ 'get_quality_avg_suppliers' ]),
    ...mapMutations([ 'setQualityAvgSuppliersFilters', 'setQualityAvgSuppliersPagination' ]),
    isSupplierGradeDangerZone(grade) {
      return grade && grade > 0 && grade <= this.gradeDanger
    },
    fixedRating(sum_evaluation) {
      const num = Number(sum_evaluation)
      return num.toFixed(2)
    },
    handlePaginationClick(page) {
      this.setQualityAvgSuppliersPagination({ page: page })
      this.get_quality_avg_suppliers()
    }
  },
  watch: {
    searchQuery: _.debounce(function () {
      this.setQualityAvgSuppliersFilters({ search: this.searchQuery })
    }, 500),
    ordering() {
      this.setQualityAvgSuppliersFilters({ ordering: this.ordering })
    },
    async qualityAvgSuppliersFilters() {
      this.setQualityAvgSuppliersPagination({ page: 1 })
      await this.get_quality_avg_suppliers()
      this.$refs.searchField.focus()
    }
  }
}
</script>

<style>
.danger-zone {
  background-color: rgba(235, 0, 0, .2);
}
</style>
