import ModuleAdminMunicipalUsers from '@/components/moduleadmin/MunicipalUsers/Index'

import ModuleAdminOPList from '@/components/moduleadmin/OPList/Index'
import ModuleAdminOPListCreate from '@/components/moduleadmin/OPList/Create'
import ModuleAdminOPCard from '@/components/moduleadmin/OPList/OPCard'
import ModuleAdminOPBankList from '@/components/moduleadmin/OPBankList/Index'
import ModuleAdminOPBankEdit from '@/components/moduleadmin/OPBankList/Edit'
import ModuleAdminLogs from '@/components/moduleadmin/Logs/Index'
import ModuleAdminStartPage from '@/components/moduleadmin/StartPage/Index'
import ModuleAdminNotificationsCreate from '@/components/moduleadmin/Notifications/Create'
import ModuleAdminReauthorization from '@/components/moduleadmin/Reauthorization/ReauthorizationView'
import ModuleAdminAccessLimitation from '@/components/moduleadmin/Access/Index'
import ModuleAdminOrders from '@/components/moduleadmin/Orders/Index'
import SuperadminOrdersIndexV2 from '@/components/moduleadmin/Orders/IndexV2'
import ModuleAdminOrdersLogs from '@/components/moduleadmin/OrdersLogs/Index'
import SuperadminOrderGeneration from '@/components/moduleadmin/Orders/OrderGeneration/OrderGeneration.vue'
import ModuleAdminOrderDefaultLogs from '@/components/moduleadmin/OrderDefaultLog/Index'
import ModuleAdminPayments from '@/components/moduleadmin/Payments/Index'
import ModuleAdminSettings from '@/components/moduleadmin/Settings/Index'

import SuperadminReports from '@/components/moduleadmin/Reports/Reports.vue'
import ModuleAdminQualityControlWrap from '@/components/common/QualityControl/Index'
import SuperadminPupilsList from '@/components/moduleadmin/PupilList/Index'

import DeleteUsers from '@/components/moduleadmin/DeleteUsers/Index'
import UpdatesNews from '@/components/moduleadmin/UpdatesNews/Index'
import Alerts from '@/components/moduleadmin/Alerts/Index'
import DepartmentLogs from '@/components/moduleadmin/DepartmentLogs/Index'
import AdminTeacherPupilCardLogs from '@/components/moduleadmin/AdminTeacherPupilCardLogs/Index'

import CreateUser from '@/components/lib/CreateUser'

import UsersList from '@/components/moduleadmin/UsersList/UsersList'

import NotificationIndex from '@/components/common/NotificationsComponent/Index.vue'

import { storeOrganizer, createMunicipalUser } from '../routes/superadmin'
import ModuleAdminUserHelp from '@/components/moduleadmin/UserHelp/UserHelp'
import UserHelpEdit from '@/components/moduleadmin/UserHelp/UserHelpEdit'
import UserHelpCreate from '@/components/moduleadmin/UserHelp/UserHelpCreate'
import ExceptionSearch from '@/components/moduleadmin/ExceptionSearch/Index'
import BuffetUserList from '@/components/moduleadmin/BuffetUsers/BuffetUserList.vue'
import BuffetUserItem from '@/components/moduleadmin/BuffetUsers/BuffetUserItem.vue'
import DebugComponent from '@/components/moduleadmin/DebugPage/DebugComponent.vue'
import SyncData from '@/components/moduleadmin/SyncData/SyncData.vue'

import BoardingSchoolsDepartmentsView from '@/components/moduleadmin/BoardingSchools/Departments/DepartmentsView.vue'
import BoardingSchoolsDepartmentItemView from '@/components/moduleadmin/BoardingSchools/Departments/DepartmentItemView.vue'
import BoardingSchoolsUsersView from '@/components/moduleadmin/BoardingSchools/Users/UsersView.vue'
import BoardingSchoolsUserItemView from '@/components/moduleadmin/BoardingSchools/Users/UserItemView.vue'

import FAQView from '@/components/moduleadmin/FAQ/FAQView.vue'
import BuffetMenuScheduleView
  from "@/components/moduleadmin/BuffetMenuScheduleView/BuffetMenuScheduleView.vue";

export default [
  {
    path: '/',
    name: 'ModuleAdmin',
    component: ModuleAdminStartPage
  },
  {
    path: '/sync',
    name: 'SyncData',
    component: SyncData
  },
  {
    path: '/debug',
    name: 'DebugComponent',
    component: DebugComponent
  },
  {
    path: '/exception-search',
    name: 'ExceptionSearch',
    component: ExceptionSearch
  },
  {
    path: 'updates',
    name: 'UpdatesNews',
    component: UpdatesNews
  },
  {
    path: 'alerts',
    name: 'Alerts',
    component: Alerts
  },
  {
    path: 'delete-users',
    name: 'DeleteUsers',
    component: DeleteUsers
  },
  {
    path: 'buffet-users',
    name: 'BuffetUserList',
    component: BuffetUserList
  },
  {
    path: 'buffet-users/create',
    name: 'BuffetUserCreate',
    component: BuffetUserItem
  },
  {
    path: 'buffet-users/:id/',
    name: 'BuffetUserList',
    component: BuffetUserItem
  },
  {
    path: 'op-list',
    name: 'ModuleAdminOPList',
    component: ModuleAdminOPList
  },
  {
    path: 'municipal-users',
    name: 'ModuleAdminMunicipalUsers',
    component: ModuleAdminMunicipalUsers
  },
  {
    path: 'municipal-users/create',
    name: 'ModuleAdminMunicipalUsersCreate',
    component: CreateUser,
    props: {
      title: 'Новый муниципальный пользователь',
      request: createMunicipalUser,
      convertKeys: {
        password: 'new_password',
        confirmPassword: 'confirm_password'
      },
      municipality: true
    }
  },
  {
    path: 'boarding-schools/departments',
    name: 'BoardingSchoolsDepartmentsView',
    component: BoardingSchoolsDepartmentsView
  },
  {
    path: 'boarding-schools/departments/:id/',
    name: 'BoardingSchoolsDepartmentItemView',
    component: BoardingSchoolsDepartmentItemView,
    props: true
  },
  {
    path: 'boarding-schools/users',
    name: 'BoardingSchoolsUsersView',
    component: BoardingSchoolsUsersView
  },
  {
    path: 'boarding-schools/users/:id/',
    name: 'BoardingSchoolsUserItemView',
    component: BoardingSchoolsUserItemView,
    props: true
  },
  {
    path: 'op-bank-list',
    name: 'ModuleAdminOPBank',
    component: ModuleAdminOPBankList
  },
  {
    path: 'op-bank-list/:id/edit',
    name: 'ModuleAdminOPBankEdit',
    component: ModuleAdminOPBankEdit
  },
  {
    path: 'user-help/',
    name: 'ModuleAdminUserHelp',
    component: ModuleAdminUserHelp
  },
  {
    path: 'user-help/create/',
    name: 'UserHelpCreate',
    component: UserHelpCreate
  },
  {
    path: 'user-help/:id/',
    name: 'UserHelpEdit',
    component: UserHelpEdit
  },
  {
    path: 'op-list/create',
    name: 'ModuleAdminOPListCreate',
    component: CreateUser,
    props: {
      title: 'Новый организатор питания',
      request: storeOrganizer
    }
  },
  // {
  //   path: 'op-list/:id/view', // TODO Удалить
  //   name: 'ModuleAdminOPListView',
  //   component: ModuleAdminOPListView
  // },
  // {
  //   path: 'op-list/:id/edit', // TODO Удалить
  //   name: 'ModuleAdminOPListEdit',
  //   component: ModuleAdminOPListEdit
  // },
  {
    path: 'op-list/:id',
    name: 'ModuleAdminOPCard',
    component: ModuleAdminOPCard,
    props: true
  },
  {
    path: 'logs',
    name: 'ModuleAdminLogs',
    component: ModuleAdminLogs
  },
  {
    path: 'department-logs',
    name: 'DepartmentLogs',
    component: DepartmentLogs
  },
  {
    path: 'pupil-card-logs',
    name: 'AdminTeacherPupilCardLogs',
    component: AdminTeacherPupilCardLogs
  },
  {
    path: 'reauth',
    name: 'ModuleAdminReauthorization',
    component: ModuleAdminReauthorization
  },
  {
    path: 'notifications',
    name: 'ModuleAdminNotifications',
    component: NotificationIndex
  },
  {
    path: 'notifications/create',
    name: 'NotificationsCreateModuleAdmin',
    component: ModuleAdminNotificationsCreate
  },
  {
    path: 'access',
    name: 'ModuleAdminAccessLimitation',
    component: ModuleAdminAccessLimitation
  },
  {
    path: 'orders',
    name: 'SuperadminOrdersIndex',
    component: ModuleAdminOrders
  },
  {
    path: 'orders_v2',
    name: 'SuperadminOrdersIndexV2',
    component: SuperadminOrdersIndexV2
  },
  {
    path: 'order_generation',
    name: 'SuperadminOrderGeneration',
    component: SuperadminOrderGeneration
  },
  {
    path: 'pupils',
    name: 'SuperadminPupilsList',
    component: SuperadminPupilsList
  },
  {
    path: 'order_logs',
    name: 'SuperadminOrdersLogsIndex',
    component: ModuleAdminOrdersLogs
  },
  {
    path: 'order_logs_default',
    name: 'SuperadminOrderLogDefaultIndex',
    component: ModuleAdminOrderDefaultLogs
  },
  {
    path: 'payments',
    name: 'SuperadminPayments',
    component: ModuleAdminPayments
  },
  {
    path: 'users-list',
    name: 'UsersList',
    component: UsersList
  },
  {
    path: 'settings',
    name: 'SuperadminSettings',
    component: ModuleAdminSettings
  },
  {
    path: 'reports',
    name: 'SuperadminReports',
    component: SuperadminReports
  },
  {
    path: 'quality-control',
    name: 'ModuleAdminQualityControlWrap',
    component: ModuleAdminQualityControlWrap,
    props: true
  },
  {
    path: 'question-management',
    name: 'SuperAdminFAQView',
    component: FAQView
  },
  {
    path: 'buffet-menu-schedule',
    name: 'BuffetMenuSchedule',
    component: BuffetMenuScheduleView,
    props: true
  },
]
