<template>
  <v-dialog v-model="showModal" :persistent="true" :width="1000">
    <v-card>
      <v-card-title>
        <h3>Изменение типа питания</h3>
      </v-card-title>
      <v-card-text>
        <div>
          <span class="grey--text">
            <strong>Выбрано учеников: {{ pupils.length }}</strong>
          </span>
        </div>
      </v-card-text>
      <v-card-text>
        <v-autocomplete
          solo
          v-model="selectedMealType"
          :items="availableMealTypes"
          key="id"
          item-text="name"
          item-value="id"
          label="Тип питания"
        />
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :close-on-click="false"
          :nudge-right="40"
          :return-value.sync="dates"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-combobox
              v-model="dates"
              multiple
              chips
              small-chips
              label="Список дат"
              prepend-icon="event"
              :append-icon="dates.length > 0 ? 'close' : ''"
              v-on="on"
              readonly
              @click:append="dates = []"
            >
            </v-combobox>
          </template>
          <v-date-picker
            v-model="dates"
            multiple
            no-title
            scrollable
            reactive
            first-day-of-week="1"
            locale="ru-RU"
          >
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="menu = false">Отмена</v-btn>
            <v-btn flat color="primary" @click="$refs.menu.save(dates)">
              Выбрать
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-card-text>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn color="error" @click="close"> Отмена </v-btn>
        <v-btn color="success" @click="save" :disabled="disabledButton">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { editMealTypes, getMealTypesList } from '@/router/routes/superadmin'
import store from '@/store'
import notify from '@/utils/notifications/toastNotification'
import moment from 'moment'
import Vue from 'vue'

export default {
  name: 'SuperadminModalEditingMealType',

  props: {
    pupils: {
      type: Array
    },
    showModal: {
      type: Boolean
    }
  },

  data() {
    return {
      availableMealTypes: [],
      selectedMealType: null,
      dates: [],
      menu: false,
      disabledButton: true
    }
  },

  methods: {
    close() {
      this.$emit('closeModal')
    },

    async onShowModalChange(value) {
      if (value) {
        await this.getMealTypes()
      }
    },

    async getMealTypes() {
      try {
        const school_id = Object.values(this.pupils)[0].schoolClass.school.id
        if (school_id) {
          const { data } = await getMealTypesList(school_id)
          this.availableMealTypes = data
        }
      } catch (e) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    async save() {
      const datesFormat = this.dates.map((item) =>
        moment(item).format(Vue.config.format.serverDate)
      )
      const pupilIdList = this.pupils.map((item) => item.id)
      const payload = {
        pupils: pupilIdList,
        meal_type: this.selectedMealType,
        dates: datesFormat
      }

      try {
        // От Backend нет объекта с успешным завершение процесса.
        // const { data } = await editMealTypes(payload)
        // if (data.success) {
        //   notify.success('Изменения внесены')
        // }
        await editMealTypes(payload)
        notify.success('Изменения внесены')
        this.close()
      } catch (e) {
      } finally {
        store.commit('setShowPreloader', false)
      }
    },

    disableButton() {
      this.disabledButton = !(this.selectedMealType && this.dates.length > 0)
    }
  },

  watch: {
    showModal(value) {
      this.onShowModalChange(value)
    },

    selectedMealType() {
      this.disableButton()
    },

    dates() {
      this.disableButton()
    }
  }
}
</script>

<style scoped></style>
