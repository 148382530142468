<script setup>
defineProps([ 'value', 'src' ])
const emit = defineEmits([ 'input' ])

const closeModal = () => {
  emit('input', false)
}
</script>

<template>
  <v-dialog :value='value' >
    <v-card @click='closeModal' class='pa-3 d-flex justify-center'>
      <v-img :src="src" max-height='80vh' max-width='80vw' contain/>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>
