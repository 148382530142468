<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h3>Создать новый справочный материал</h3>
      </v-card-title>
      <v-card-text>
        <v-layout column>
          <v-flex>
            <v-text-field v-model="title" label="Заголовок" solo>
            </v-text-field>
          </v-flex>
          <v-flex>
            <v-select
              label="Тип материала"
              v-model="type"
              :items="type_choices"
              disabled=""
              item-text="name"
              item-value="id"
              solo
            ></v-select>
          </v-flex>
          <v-flex>
            <v-select
              label="Для роли"
              v-model="role"
              :items="role_choices"
              disabled
              item-text="name"
              item-value="id"
              solo
            ></v-select>
          </v-flex>
          <v-flex v-if="type === 2">
            <h4>Ссылка</h4>
            <v-text-field v-model="url"></v-text-field>
          </v-flex>
          <v-flex v-else>
            <h4>Содержание</h4>
            <ckeditor
              v-model="content"
              :editor="editor"
              :config="editorConfig"
            ></ckeditor>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-layout row>
          <v-flex>
            <v-btn @click="$router.push('/moduleadmin/user-help/')">
              Назад
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn @click="save()" class="right success"> Сохранить </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { editUserHelpItem, getUserHelpItem } from '@/router/routes/superadmin'
import store from '@/store'
import notify from '@/utils/notifications/toastNotification'
import ckeditorMixin from '@/mixins/ckeditorMixin'

export default {
  name: 'UserHelpEdit',
  mixins: [ ckeditorMixin ],
  data() {
    return {
      id: this.$route.params.id,
      title: null,
      type: null,
      role: null,
      url: null,
      content: null,
      type_choices: [
        { name: 'Инструкция', id: 1 },
        { name: 'Видео-материал', id: 2 },
        { name: 'Часто задаваемые вопросы', id: 3 }
      ],
      role_choices: [
        { name: 'Администратор питания', id: 1 },
        { name: 'Учитель', id: 2 },
        { name: 'Родитель', id: 3 },
        { name: 'МОУО', id: 4 },
        { name: 'Департамент', id: 7 },
        { name: 'Организатор питания', id: 6 }
      ]
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const payload = { id: this.id }
      const response = await getUserHelpItem(payload)
      this.title = response.data.title
      this.type = response.data.type
      this.role = response.data.role
      if (this.type === 2) this.url = response.data.content
      else this.content = response.data.content
      store.commit('setShowPreloader', false)
    },
    async save() {
      const payload = {
        id: this.id,
        title: this.title,
        content: this.content,
        type: this.type,
        role: this.role
      }
      await editUserHelpItem(payload)
      notify.success('Сохранено')
      await this.loadData()
    }
  }
}
</script>

<style scoped></style>
