export default async response => {
  const dataBlob = response.data
  const headers = response.headers
  const typeBlob = response.data.type
  const filename = headers ? b64DecodeUnicode(headers.filename) : 'Файл.xlsx'
  const file = new Blob([ dataBlob ], { type: typeBlob })
  const url = window.URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = url
  link.download = filename
  link.click()
}

function b64DecodeUnicode(str) {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  )
}