<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'CardsListTable',
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      search_field: null,
      title: 'Карты гостевые и администраторов',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Дата активации', value: 'issued' },
        { text: 'Номер', value: 'number_dec' },
        { text: 'Пользователь', value: 'pupil' },
        { text: 'Операции', value: 'operations' }
      ],
      filter: {
        search: {
          value: null,
          name: 'Поиск'
        },
        blocked: [
          { value: null, name: 'Все' },
          { value: 'True', name: 'Только заблокированные' },
          { value: 'False', name: 'Только не заблокированные' }
        ],
        deleted: [
          { value: null, name: 'Все' },
          { value: 'True', name: 'Только удаленные' },
          { value: 'False', name: 'Только не удаленные' }
        ],
        is_active: [
          { value: null, name: 'Все' },
          { value: 'True', name: 'Активные' },
          { value: 'False', name: 'Не активные' }
        ],
        role: [
          { value: null, name: 'Все' },
          { value: 'is_admin', name: 'Администратор' },
          { value: 'is_guest', name: 'Гость' },
          { value: 'is_pupil', name: 'Ученик' }
        ]
      }
    }
  },

  computed: {
    ...mapGetters([
      'cards',
      'currentUser',
      'cards_pagination',
      'cards_filters',
      'setting'
    ]),
    pagination_count() {
      return Math.ceil(this.cards.count / this.cards_pagination.page_size) || 0
    }
  },

  methods: {
    ...mapActions(['fetchCards']),
    ...mapMutations([
      'setShowPreloader',
      'set_cards_pagination',
      'set_cards_is_progress',
      'reset_cards_filters',
      'set_cards_filters'
    ]),

    clearFilters() {
      this.search_field = null
      this.reset_cards_filters()
    },

    getCards() {
      const params = {
        page: this.cards_pagination.page,
        page_size: this.cards_pagination.page_size,
        school: this.currentUser.school_id,
        is_blocked: this.cards_filters.is_blocked.value,
        is_deleted: this.cards_filters.is_deleted.value,
        is_active: this.cards_filters.is_active.value
      }
      // Если выбрана роль,
      // то указываем нужный параметр со значением True
      if (this.cards_filters.role.value) {
        params[this.cards_filters.role.value] = 'True'
      }
      if (this.search_field && this.search_field.length > 0) {
        params.search = this.search_field
      }
      this.fetchCards({
        params: params
      })
    },
    handlePaginationClick(page) {
      this.set_cards_pagination({ page: page })
      this.getCards()
    },
    handleSearch() {
      this.set_cards_pagination({ page: 1 })
      this.getCards()
    },
    transformDateTime(date) {
      return moment(date).format(Vue.config.format.prettyDateWithoutSeconds)
    }
  },

  watch: {
    search_field: _.debounce(function () {
      this.reset_cards_filters()
    }, 500),

    cards_filters: {
      deep: true,
      handler: _.debounce(function () {
        this.handleSearch()
      }, 500)
    }
  },
  mounted() {
    this.getCards()

    if (this.setting('buffet_parent_card')) {
      this.filter.role.push({ value: 'is_parent', name: 'Родитель' })
    }
  }
}
</script>

<template>
  <v-container fluid pa-0>
    <v-container fluid grid-list-xl pa-0>
      <v-layout wrap align-center>
        <v-flex xs12 sm9 d-flex>
          <v-text-field
            v-model="search_field"
            clearable
            @keyup.enter="handleSearch"
            :label="!darkTheme ? 'Поиск' : ''"
            :placeholder="darkTheme ? 'Поиск' : ''"
            :outline="darkTheme"
            :hide-details="true"
            box
          />
        </v-flex>
        <v-flex xs12 sm3 d-flex>
          <v-btn @click="clearFilters">сбросить фильтры</v-btn>
        </v-flex>
        <v-flex xs12 sm3 d-flex>
          <v-select
            :items="filter.blocked"
            item-name="name"
            item-text="name"
            item-value="value"
            return-object
            v-model="cards_filters.is_blocked"
            :label="!darkTheme ? 'Статус блокировки' : ''"
            :placeholder="darkTheme ? 'Статус блокировки' : ''"
            :outline="darkTheme"
            :hide-details="true"
            box
          ></v-select>
        </v-flex>

        <v-flex xs12 sm3 d-flex>
          <v-select
            :items="filter.deleted"
            item-name="name"
            item-text="name"
            item-value="value"
            return-object
            v-model="cards_filters.is_deleted"
            :label="!darkTheme ? 'По статусу удалена' : ''"
            :placeholder="darkTheme ? 'По статусу удалена' : ''"
            :outline="darkTheme"
            :hide-details="true"
            box
          ></v-select>
        </v-flex>

        <v-flex xs12 sm3 d-flex>
          <v-select
            :items="filter.is_active"
            item-name="name"
            item-text="name"
            item-value="value"
            return-object
            v-model="cards_filters.is_active"
            :label="!darkTheme ? 'По статусу активности' : ''"
            :placeholder="darkTheme ? 'По статусу активности' : ''"
            :outline="darkTheme"
            :hide-details="true"
            box
          ></v-select>
        </v-flex>

        <v-flex xs12 sm3 d-flex>
          <v-select
            :items="filter.role"
            item-name="name"
            item-text="name"
            item-value="value"
            return-object
            v-model="cards_filters.role"
            :label="!darkTheme ? 'По роли держателя карты' : ''"
            :placeholder="darkTheme ? 'По роли держателя карты' : ''"
            :outline="darkTheme"
            :hide-details="true"
            box
          ></v-select>
        </v-flex>
      </v-layout>
    </v-container>
    <v-layout class="fs-teacher-order-result fs-header-filter" my-3>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="cards.results"
          hide-actions
          no-data-text="Нет пользователей с картами"
          class="elevation-1 fs-admin-table"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td>{{ transformDateTime(props.item.issued) }}</td>
              <td>
                {{
                  setting('buffet_card_type_hex')
                    ? props.item.number_hex
                    : props.item.number_dec
                }}
                <span v-if="!props.item.is_active">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        style="color: grey"
                        v-on="on"
                        class="fs-active-icon"
                        >report_problem</v-icon
                      >
                    </template>
                    <span>Карта неактивна</span>
                  </v-tooltip>
                </span>
                <span v-if="props.item.is_blocked">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        style="color: red"
                        v-on="on"
                        class="fs-active-icon"
                        >cancel</v-icon
                      >
                    </template>
                    <span>Карта заблокирована</span>
                  </v-tooltip>
                </span>
                <span v-if="props.item.is_admin">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon color="green" v-on="on"
                        >admin_panel_settings</v-icon
                      >
                    </template>
                    <span>Администратор</span>
                  </v-tooltip>
                </span>
                <span v-if="props.item.is_guest">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon color="green" v-on="on">group</v-icon>
                    </template>
                    <span>Гостевая</span>
                  </v-tooltip>
                </span>
                <span v-if="props.item.is_parent">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon color="green" v-on="on">escalator_warning</v-icon>
                    </template>
                    <span>Родитель</span>
                  </v-tooltip>
                </span>
              </td>
              <td>{{ props.item.pupil?.full_name }}</td>
              <td>
                <v-flex row>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="
                          $router.push({
                            name: 'CardDetail',
                            params: { id: props.item.id, meta: 'read' }
                          })
                        "
                      >
                        description
                      </v-icon>
                    </template>
                    <span>Открыть</span>
                  </v-tooltip>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <div class="text-xs-center" v-if="pagination_count > 1">
      <v-pagination
        @input="handlePaginationClick"
        :value="cards_pagination.page"
        :length="pagination_count"
        :total-visible="7"
      ></v-pagination>
    </div>
  </v-container>
</template>
