<template>
  <v-container
    fluid
    class="fs-admin-container"
  >
    <v-layout
      class="fs-complex"
      row
      mb-3
    >
      <div class="md4 mb-3" style="display: flex">
        <h1>{{ title }}</h1>
	      <context-help-modal style="float: right" :context-key="contextKeys.admin.teachers.edit" />
      </div>
    </v-layout>
    <v-layout
      class="fs-student-info"
      mb-3
      row
    >
      <v-flex
        md4
        offset-md2
        column
      >
        <div class="fs-fields-group--gray">
          <v-flex mb-3>
            <span class="fs-student-info__key">Фамилия:</span>
            <span class="fs-student-info__value">{{teacher.lastName}}</span>
          </v-flex>
          <v-flex mb-3>
            <span class="fs-student-info__key">Имя:</span>
            <span class="fs-student-info__value">{{teacher.firstName}}</span>
          </v-flex>
          <v-flex mb-3>
            <span class="fs-student-info__key">Отчество:</span>
            <span class="fs-student-info__value">{{teacher.middleName}}</span>
          </v-flex>
        </div>
      </v-flex>
      <v-flex
        column
        md4
      >
        <v-flex class="fs-fields-group--gray">
          <v-flex mb-3>
            <span class="fs-student-info__key">Класс в руководстве:</span>
            <span class="fs-student-info__value">{{ getClassesNames(teacher.schoolClasses) }}</span>
          </v-flex>
          <v-flex>
	          <with-tooltip text="Настройка возможности питания в кредит">
	            <span class="fs-student-info__value">
	              <v-checkbox
		              :disabled="view"
	                class="fs-checkbox"
	                v-model="teacher.isCredit"
	                label="Может разрешать питаться в кредит"
	              ></v-checkbox>
	            </span>
	          </with-tooltip>
          </v-flex>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex
        text-md-right
        pt-3
      >
	      <with-tooltip text="Вернуться на предыдущую страницу">
	        <v-btn
	          flat
	          class="fs-btn fs-admin-btn__show v-btn--active mr-1"
	          @click="$router.go(-1)"
	        >Назад</v-btn>
	      </with-tooltip>
	      <with-tooltip text="Сохранить изменения">
	        <v-btn
		        v-if="!view"
	          flat
	          class="fs-btn fs-admin-btn__show v-btn--active fs-form-footer__btn"
	          @click="updateTeacher()"
	        >Сохранить</v-btn>
	      </with-tooltip>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getOneTeacher, assignCreditToTeacher } from "@/router/routes/admin"
import store from "@/store"
import { mapGetters } from "vuex"
import Vue from "vue"
import AdminTeacherFactory from "@/factories/admin/teacher-factory"
import notify from '@/utils/notifications/toastNotification'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import WithTooltip from "@/components/common/Tooltip/Tooltip.vue"

export default {
  name: "AdminTeachersCard",
  components: { WithTooltip, ContextHelpModal },
  // props: {
  //   view: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  created() {
    const payload = {
      schoolId: this.currentUser.school.id,
      id: this.$route.params.id
    }
    getOneTeacher(payload)
      .then(response => {
        const teacher = response.data.data
        this.teacher = {
          id: teacher.id,
          firstName: teacher.firstName,
          middleName: teacher.middleName,
          lastName: teacher.lastName,
          schoolClasses: _.get(teacher, "schoolClasses", []),
          isCredit: _.get(teacher, "isCredit", false)
        }
      })
      .finally(() => store.commit("setShowPreloader", false))
  },

  data() {
    return {
      title: "Карточка учителя",
      teacher: new AdminTeacherFactory(),
	    contextKeys: Vue.config.contextHelp,
    }
  },
  computed: {
    ...mapGetters([ "currentUser" ]),
	  view() {
			return this.$route.query.view
	  }
  },
  methods: {
    async updateTeacher() {
      const payload = {
        schoolId: this.currentUser.school.id,
        id: this.$route.params.id,
        params: {
          isCredit: this.teacher.isCredit
        }
      }

      try {
        const response = await assignCreditToTeacher(payload)
        if (response.data.success) {
          notify.success(Vue.config.notifications.teachers.updateSuccess)
        }
      } catch (e) {
        if (this.teacher.isCredit) {
          notify.error(e.response.data.message)
          this.teacher.isCredit = false
        } else {
          notify.success(Vue.config.notifications.teachers.updateSuccess)
        }
      }
      store.commit("setShowPreloader", false)
    },
    getClassesNames(classes) {
      const names = _.map(classes, item => {
        return _.get(item, "name", "")
      })
      return names.sort((a, b) =>
        this.getClassLiter(a).localeCompare(this.getClassLiter(b)))
        .sort((a, b) =>
          this.getClassNumber(a) - this.getClassNumber(b)
        ).join(', ')
    },
    getClassLiter(name) {
      return name.split(' ')[1]
    },
    getClassNumber(name) {
      return name.split(' ')[0]
    }
  }
}
</script>
