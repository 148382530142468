import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/ru'

export default {
  data() {
    return {
      editor: ClassicEditor,

      editorConfig: {
        language: 'ru',
        toolbar: {
          shouldNotGroupWhenFull: true
        }
      }
    }
  }
}
