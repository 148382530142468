<template>
  <v-autocomplete
    :clearable="clearable"
    :items="items"
    :label="label"
    :multiple="multiple"
    :no-data-text="noData"
    :placeholder="placeholder"
    :readonly="readonly"
    :value="value"
    :item-text="itemText"
    :item-value="itemValue"
    @change="handler"
    height="30"
  />
</template>

<script>
import VAutocomplete from 'vuetify/es5/components/VAutocomplete/VAutocomplete'

export default {
  name: 'FsAutocomplete',
  extends: VAutocomplete,
  data() {
    return {
      noData: 'Нет данных'
    }
  },
  methods: {
    checkValue(itemName) {
      if (itemName == this.value) return true
      return false
    },
    changeValue() {}
  },
  props: {
    items: {},
    value: {},
    label: {},
    placeholder: {},
    readonly: {},
    multiple: {
      default: false
    },
    clearable: {
      default: false
    },
    itemText: {
      required: false
    },
    itemValue: {
      required: false
    },
    handler: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
