<template>
  <v-container
    fluid
    class="fs-teacher-container"
  >
    <v-layout
      class="fs-complex"
      row
      mb-6
    >
      <v-flex
        md12
        mb-12
      >
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <div style="width: 30%">
      <v-text-field
        class="mb-10"
        label="Поиск ОП"
        append-icon="search"
        clear-icon="clear"
        clearable
        v-model="searchSupplierField"
        @click:append="searchSupplier"
        @keyup.enter="searchSupplier"
        @click:clear="clearSearchField"
      ></v-text-field>
    </div>
    <v-layout
      row
      mb-6
      justify-space-around
      text-md-center
      class="fs-header-filter"
    >
    </v-layout>
    <v-layout
      class="fs-teacher-order-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :pagination.sync="pagination"
          :items="items"
          hide-actions
          no-data-text="Нет организаторов питания"
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr :class="props.item.isActive ? '' : 'fs-blocked'">
              <td>{{ props.item.id }}</td>
              <td :style="{ color: props.item.is_ready_for_payment ? 'green' : 'red' }">{{ props.item.username }}</td>
              <td>{{ props.item.department }}</td>
              <td>{{ props.item.is_ready_for_payment ? 'Заполнен' : 'Нет' }}</td>
              <td>{{ props.item.isActive ? 'Нет' : 'Да' }}</td>
              <td>{{ props.item.has_assigned_meal_types ? 'Назначены' : '-' }}</td>
              <td>{{ props.item.has_assigned_schools ? 'Назначены' : '-' }}</td>
              <td>{{ props.item.has_active_daily_menu ? 'Назначено' : '-' }}</td>
              <td style="text-align: center">
                <v-icon
                    color="green"
                    v-if="props.item.sber_identify_exists"
                >
                  verified
                </v-icon>
                <v-icon
                    color="grey"
                    v-else
                >
                  block
                </v-icon>
              </td>
              <td style="text-align: center">
                <v-icon
                    color="green"
                    v-if="props.item.sber_merchant_exists"
                >
                  verified
                </v-icon>
                <v-icon
                    color="grey"
                    v-else
                >
                  block
                </v-icon>
              </td>
              <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        class="fs-active-icon"
                        @click="$router.push({
                          name: 'ModuleAdminOPBankEdit',
                          params: { id: props.item.id, meta: 'edit' }
                        })"
                      >edit</v-icon>
                    </template>
                    <span>Редактирование</span>
                  </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import store from '@/store'
import { getOrganizers } from '@/router/routes/superadmin'
import Vue from 'vue'
import { disableSorting } from '@/mixins/helpers'
import scrollable from "@/mixins/scrollable"
import Veiw from '../../admin/Teachers/Veiw.vue'
import notify from '@/utils/notifications/toastNotification'


export default {
  components: { Veiw },
  name: 'ModuleAdminOPBankList',
  mixins: [ disableSorting, scrollable ],
  data() {
    return {
      title: 'Список организаторов питания',
      items: [],
      pagination: {
        rowsPerPage: -1
      },
      limit: Vue.config.general.limit,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Название', value: 'username' },
        { text: 'Назначенный муниципалитет', value: 'department' },
        { text: 'Заполненность профиля', value: 'isReady' },
        { text: 'Заблокирован', value: 'isActive' },
        { text: 'Типы питания', value: 'hasMealTypes' },
        { text: 'Школы', value: 'hasSchools' },
        { text: 'Меню', value: 'hasMenu' },
        { text: 'Сбер реквизиты', value: 'paymentSettings' },
        { text: 'Сбер ID мерчанта', value: 'operations' },
        { text: 'Операции', value: 'operations' }
      ],
      searchSupplierField: null
    }
  },
  created() {
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },
  methods: {
    async searchSupplier() {
      if (this.searchSupplierField === null) return
      try {
        const params = {
          search: this.searchSupplierField
        }
        const response = await getOrganizers(params)
        this.items = response.data.data
      } catch (e) {
        // notify.error('Ошибка загрузки ОП') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async clearSearchField() {
      this.items = []
      this.searchSupplierField = null
      this.loadMore()
    },
    async loadMore() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset
        }
        const response = await getOrganizers(params)
        const itemsArray = response.data.data
        this.items = [ ...this.items, ...itemsArray ]
      } catch (e) {
        // notify.error('Ошибка загрузки ОП') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
