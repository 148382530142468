<script>
import { createBuffetUser, getOrganizersPure, getSchoolsBySupplier, getSuppliers } from "@/router/routes/superadmin"
import { mapMutations } from "vuex"
import store from "@/store"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'BuffetUserItem',
  data() {
    return {
      isCreate: this.$router.currentRoute.name === 'BuffetUserCreate',
      title: null,
      user: {
        supplierId: null,
        schoolList: [],
        username: null,
        password: null
      },
      supplierList: [],
      schoolList: []
    }
  },
  async mounted() {
    this.title = this.isCreate ? 'Создание пользователя' : 'Просмотр пользователя'
    await this.getSuppliers()
    store.commit('setShowPreloader', false)
  },
  watch: {
    'user.supplierId' (val) {
      this.getSchools()
    }
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async getSuppliers() {
      try {
        const { data } = await getOrganizersPure({})
        data.forEach(item => {
          this.supplierList.push({
            id: item.id,
            name: item.name + ` (${item.username})`
          })
        })
      } catch (e) {
        notify.error(e.response.data.message)
      }
    },
    async getSchools() {
      try {
        const { data } = await getSchoolsBySupplier(this.user.supplierId)
        data.forEach(item => {
          this.schoolList.push({
            id: item.id,
            name: item.short_name
          })
        })
      } catch (e) {
        notify.error(e.response.data.message)
      }
      store.commit('setShowPreloader', false)
    },
    async createUser() {
      const payload = {
        supplier_id: this.user.supplierId,
        username: this.user.username,
        password: this.user.password,
        schools_id: this.user.schoolList
      }
      if (!payload.supplier_id || !payload.schools_id || !payload.username || !payload.password) {
        notify.error('Заполнены не все поля')
        return
      }
      try {
        await createBuffetUser(payload)
        notify.success('Пользователь создан')
      } catch (e) {
        notify.error(e.response.data.message)
      }
      store.commit('setShowPreloader', false)
    }
  }
}
</script>

<template>
<v-container fluid>
  <v-layout
      class="fs-archive"
      row
      mb-3
  >
    <v-flex mb-3>
      <h1>{{ title }}</h1>
    </v-flex>
  </v-layout>
  <v-layout>
    <v-flex sm12>
      <v-card>
        <v-card-text>
          <v-autocomplete
              :items="supplierList"
              item-text="name"
              item-value="id"
              v-model="user.supplierId"
              label="Комбинат питания"
              solo
          />
          <v-autocomplete
            :items="schoolList"
            item-text="name"
            item-value="id"
            v-model="user.schoolList"
            v-if="user.supplierId"
            label="Школы"
            multiple
            solo
          />
          <v-text-field
            v-model="user.username"
            v-if="user.schoolList.length > 0"
            label="Логин"
            solo
          />
          <v-text-field
            v-model="user.password"
            v-if="user.schoolList.length > 0"
            label="Пароль"
            solo
          />
          <v-layout row justify-space-between>
            <v-btn
                @click="$router.push({name: 'BuffetUserList'})"
            >
              Назад
            </v-btn>
            <v-btn
                @click="createUser"
            >
              Создать
            </v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</v-container>
</template>

<style scoped>

</style>
