<template>
  <v-form ref="storehouseProductForm" v-model="valid">
    <v-card>
      <v-toolbar elevation="1" dense light>
        <v-toolbar-title>
          {{ is_create_product ? `Добавить новый продукт` : `Обновить продукт` }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.prevent="closeEditModal">
          <v-icon class="fs-active-icon">close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-text-field label="Наименование продукта"
          v-model="name_product"
          :rules="[rules.required]"
        />
        <v-text-field
          type="number"
          min="0"
          label="Кол-во продукта"
          v-model="count_product"
          :rules="[rules.required]"
        />
        <v-text-field
          type="number"
          min="0"
          label="Стоимость товара"
          v-model="price_product"
          :rules="[rules.required]"
        />

        <div style="display: flex; align-items: center; ">
          <v-select
            :items="product_categories.results"
            item-text="title"
            item-value="id"
            label="Категория продукта"
            return-object
            v-model="category_product"
            :rules="[rules.required]"
          ></v-select>
        </div>      
      </v-card-text>

      <v-card-actions>
        <v-btn @click.prevent="closeEditModal">
          <v-icon class="fs-active-icon">close</v-icon>
          Отменить
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn @click="saveProduct">
          <v-icon class="fs-active-icon">check</v-icon>
          {{ is_create_product ? `Создать` : `Обновить` }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'StorehouseProductDetail',
  props: [ 'is_create_product', 'product_id', 'storehouse_id' ],
  data: () => ({
    rules: {
      required: (v) => !!v || "Поле обязательное для заполнения"
    },
    valid: true,
    name_category: '',
  }),
  async mounted() {
    await this.getProductCategories({
      offset: 0,
      limit: 1000,
      storehouse: this.storehouse_id
    })
    if (!this.is_create_product) {
      await this.fetchStorehousesOneProduct({ product_id: this.product_id })
    }
    if (this.storehouse_id) {
      this.set_storehouses_one_product({
        ...this.storehouses_one_product,
        storehouse: this.storehouse_id
      })
    }

  },
  methods: {
    ...mapActions([ 'createStorehouseProduct', 'fetchStorehousesOneProduct', 'updateStorehouseProduct', 'getProductCategories' ]),
    ...mapMutations([ 'set_storehouses_one_product' ]),
    closeEditModal() {
      this.set_storehouses_one_product({})
      this.$emit('close')
    },
    saveProduct() {
      this.$refs.storehouseProductForm.validate()
      if (this.valid) {
        if (this.is_create_product) {
          this.createStorehouseProduct({ data: this.storehouses_one_product })
            .then(() => this.closeEditModal())
        }
        else {
          this.updateStorehouseProduct({ data: this.storehouses_one_product, product_id: this.product_id })
            .then(() => this.closeEditModal())
        }
      }
    },
  },
  computed: {
    ...mapGetters([ 'storehouses_one_product', 'product_categories' ]),
    name_product: {
      get () {
        return this.storehouses_one_product.name
      },
      set (value) {
        this.set_storehouses_one_product({
          ...this.storehouses_one_product,
          name: value
        })
      }
    },
    count_product: {
      get () {
        return this.storehouses_one_product.count
      },
      set (value) {
        this.set_storehouses_one_product({
          ...this.storehouses_one_product,
          count: value
        })
      }
    },
    price_product: {
      get () {
        return this.storehouses_one_product.price
      },
      set (value) {
        this.set_storehouses_one_product({
          ...this.storehouses_one_product,
          price: value
        })
      }
    },
    category_product: {
      get () {
        return this.storehouses_one_product.category
      },
      set (value) {
        this.set_storehouses_one_product({
          ...this.storehouses_one_product,
          category: value.id
        })
      }
    },
  }
}
</script>

<style>
</style>
