<template>
  <v-container
    fluid
    class="fs-parent-container"
  >
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <v-flex mb-2>
        <h1>{{ title }}</h1>
      </v-flex>
    </v-layout>
    <v-layout mb-3 row>
      <v-flex md-2>
        <v-alert
          :value="currentChild.isFake"
          type="error"
        >
          <h3>
            Ошибка: по данному ученику из учётной системы региона получены неполные данные.
            Работа с ним временно заблокирована. Ожидайте повторной синхронизации.
          </h3>
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout v-if="!currentChild.isFake">
      <v-flex class="font-weight-bold">
        <p>
          Данная страница носит сугубо информационный характер. Таким образом, могут возникать ситуации,
          при которых статус заказа на конкретный день &laquo;Выполнен&raquo;, но при этом списание в рамках данного дня могло
          не произойти из-за того, что заказ не был подтвержден школой для Вашего ребёнка.
          Чтобы понять, было ли списание в конкретном дне или диапазоне дней,
          воспользуйтесь информацией на странице &laquo;Движение денежных средств&raquo;.
        </p>
      </v-flex>
    </v-layout>
    <v-layout v-if="!currentChild.isFake">
      <v-layout row>
        <v-flex
          md2
          mr-2
        >
          <DatePickersSmart
            :textFieldClass="'fs-datePickerSmart--parent__textfield'"
            :pickerClass="'fs-datePickerSmart--parent'"
            :normalStyle="true"
            :startDate.sync="dateStart"
            :endDate.sync="dateEnd"
            @changeDate="changeDate"
            :allowLastPickDate="dateEnd"
          />
        </v-flex>
      </v-layout>
    </v-layout>

    <v-layout
      class="fs-archive-result"
      mb-3
      v-if="!currentChild.isFake"
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          no-data-text="История питания отсутствует"
          :disable-initial-sort="true"
          class="elevation-1"
          item-key="data"
        >
          <template v-slot:items="props">
            <!-- If has selected complect -->
            <tr>
              <td>{{ props.item.date }}</td>
              <td>{{ props.item.completeAmount }}</td>
              <td>{{ props.item.parentPrice }}</td>
              <td>{{ props.item.dotation }}</td>
              <td>{{ props.item.name }} </td>
              <td>{{ props.item.status }} </td>
              <td>
                <v-icon
                  size="24"
                  color="#cb6026"
                  @click="handlerInfoModal(props.item)"
                >info</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <OrderModal
      :complexes="itemComplects"
      :additionalFoods="additionalFoods"
      :selectedComplex.sync="itemSelectedComplectId"
      v-model="showModal"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import Vue from 'vue'
import store from '@/store'
import { getFoodOrders } from '@/router/routes/parent'
import { getChildFoodOrders } from '@/router/routes/pupil'
import dateFormatter from '@/helpers/dateFormatter'
import numable from '@/mixins/balancable'
import _ from 'lodash'
import { DatePickersSmart } from '@/components/common'
import OrderModal from '@/components/parent/Modals/OrderModal'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ParentChildHistory',
  components: {
    DatePickersSmart,
    OrderModal
  },
  props: {
    isChildView: {
      type: Boolean,
      default: false
    },
  },
  mixins: [ numable ],
  data() {
    return {
      title: 'История питания ребёнка',
      headers: [
        { text: 'Дата', value: 'date' },
        { text: 'Полная стоимость комплекса, руб.', value: 'completeAmount' },
        { text: 'Родительская доплата, руб.', value: 'parentPrice' },
        { text: 'Дотация, руб.', value: 'dotation' },
        { text: 'Комплекс', value: 'name' },
        { text: 'Статус заказа', value: 'status' },
        { text: 'Информация', value: 'info' }
      ],
      items: [],
      dateStart: dateFormatter(moment().subtract(3, 'days'), 'serverDate'),
      dateEnd: dateFormatter(moment(), 'serverDate'),
      showModal: false,
      itemComplects: null,
      itemSelectedComplectId: null,
      additionalFoods: []
    }
  },
  computed: {
    ...mapGetters([ 'currentChild' ]),
    dateStartServer() {
      return dateFormatter(this.dateStart, 'pythonDate')
    },
    dateEndServer() {
      return dateFormatter(this.dateEnd, 'pythonDate')
    }
  },
  created() {
    this.loadMore()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    handlerInfoModal(item) {
      try {
        this.itemComplects = [ item.selectedComplect ]
        this.additionalFoods = item.additionalFoods
        this.itemSelectedComplectId = item.selectedComplect.id
      } catch (error) {
        notify.error(`Ошибка получения параметров комплекса`) //pass
      }
      this.showModal = true
    },
    changeDate() {
      this.resetData()
      this.loadMore()
    },
    resetData() {
      this.items = []
    },
    async loadMore() {
      try {
        const payload = {
          id: this.currentChild.id,
          params: {
            dateStart: this.dateStartServer,
            dateEnd: this.dateEndServer
          }
        }
        const method = this.isChildView ? getChildFoodOrders : getFoodOrders
        const response = await method(payload)

        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          return {
            date: dateFormatter(_.get(item, 'date', null), 'date'),
            selectedComplect: _.get(item, 'selectedComplect.complect', null),
            completeAmount: this.convertToRubles(
              _.get(item, 'selectedComplect.foodType.price', null)
            ),
            parentPrice: this.convertToRubles(
              _.get(item, 'selectedComplect.totalPrice', null)
            ),
            dotation: this.convertToRubles(
              _.get(item, 'selectedComplect.privilegePrice', null)
            ),
            name: _.get(item, 'selectedComplect.complect.name', ''),
            status: this.getTextStatus(
              _.get(item, 'selectedComplect.status', null),
              _.get(item, 'selectedComplect.status_received_is_auto', null)
            ),
            additionalFoods: item.additionalFoods && item.additionalFoods.length ? item.additionalFoods : [],
          }
        })
        this.items = [ ...itemsArray ]
        this.items = this.items.filter(item => item.selectedComplect)
      } catch (error) {
        // notify.error(`Не удалось загрузить заказы`) // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    getTextStatus(value, status_received) {
      if (status_received) {
        value = 7
      }
      switch (value) {
      case 1:
      case 2:
      case 3:
      case 4:
        return 'Явка утверждена'
      case 5:
        return 'Выполнен'
      case 6:
        return 'Отменен'
      case 7:
        return 'Заказ не был выдан'
      default:
        return 'Неизвестный статус'
      }
    },
    resetPageFileLoaded() {
      store.commit('setShowPreloader', false)
    }
  }
}
</script>
