export default class PersonalInfoFactory {
  constructor() {
    return {
      first_name: '',
      middle_name: '',
      last_name: '',
      billNumber: '',
      foodOrganizer: '',
      concession: '',
      foodType: '',
      currentBalance: 0,
      reservedBalance: 0,
      availableBalance: 0,
      creditBalance: 0,
      debt: 0,
      debtPeriond: null,
      notifications: {
        isEnabled: false,
        balanceTrigger: 0,
        period: 1
      }
    }
  }
}
