<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="true"
    persistent
    no-click-animation
    >
    <v-card>
      <v-container fluid>
        <h2>Укажите контактные данные</h2>
        <p>
          Для продолжения пользования необходимо указать контактную информацию
        </p>
        <v-text-field
            outline
            placeholder="Электронная почта"
          v-model="email">
        </v-text-field>
        <v-text-field
            outline
            placeholder="Номер телефона, прим: +79008005040"
            v-model="phone">
        </v-text-field>
        <v-text-field
            outline
            placeholder="ФИО"
            v-model="fullName">
        </v-text-field>
        <div class="text-right">
          <v-btn @click="save">
            Сохранить
          </v-btn>
          <v-btn color="error" v-if="$props.isSuperAdmin" @click="close">
            Закрыть
          </v-btn>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { setDepartmentUserContacts } from "@/router/routes/department"
import Vue from "vue"
import store from '@/store'
import { getAdminUserContacts, setAdminUserContacts } from "@/router/routes/admin"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'SetContactInformationModal',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    isSuperAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: "",
      phone: "",
      fullName: ""
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      getAdminUserContacts()
        .then(res => {
          this.email = res.data.email
          this.phone = res.data.phone
          this.fullName = res.data.full_name
        })
        .finally(() => store.commit('setShowPreloader', false))
    },
    close() {
      this.$emit('close')
    },
    save() {
      if (!this.email && !this.phone && !this.fullName) {
        notify.info('Заполните все поля')
        return
      }
      setAdminUserContacts({
        email: this.email,
        phone: this.phone,
        full_name: this.fullName
      })
        .then(res => {
          if (res.data.success) {
            window.location.reload()
          } else {
            notify.error(Object.keys(res.data).map((item) => `${item}: ${res.data[item]}`).toString()) //pass
          }
        })
        .finally(() => store.commit('setShowPreloader', false))
    }
  }
}
</script>
<style>
.text-right {
  text-align: right;
}
</style>