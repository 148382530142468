<template>
  <v-container class="fs-organizer-container start-page" fluid>
    <v-flex md6 offset-md3>
      <v-list class="fs-list" two-line>
        <template v-for="(item, index) in navItems">
          <v-list-tile
            v-if="!item.isSubmenu"
            :key="item.title"
            :to="{ name: item.link }"
            :disabled="item.isDisabled"
            avatar
          >
            <v-list-tile-avatar>
              <v-icon :class="[ item.iconClass ]">{{ item.icon }}</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ item.subtitle }}</v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn icon ripple>
                <v-icon color="grey lighten-1">info</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
          <v-divider v-if="index + 1 < navItems.length - 1 && !item.isSubmenu" :key="index"></v-divider>
        </template>
      </v-list>
    </v-flex>
  </v-container>
</template>

<script>
import { Menu } from '@/lib/menu'
import MenuFactory from '@/factories/moduleadmin/menu'
import store from "@/store"

export default {
  name: 'StartPage',
  data() {
    return {
      navItems: Menu.toOneLevel(new MenuFactory(store.getters.settings))
    }
  }
}
</script>
