<template>
  <div
    ref="scrollList"
    class="sc-message-list"
    :style="{ backgroundColor: colors.messageList.bg }"
    @scroll="handleScroll"
  >
    <template v-if="isShowChatList">
      <Card
        v-for="chatCard in chatList.slice().reverse()"
        :message-styling="messageStyling"
        :colors="colors"
        :key="chatCard.id"
        :card="chatCard"
      />
    </template>
    <template v-else>
      <Message
        v-for="(message, idx) in messageList"
        :key="idx"
        :message="message"
        :user="profile(message.author)"
        :colors="colors"
        :message-styling="messageStyling"
      >
        <template v-slot:user-avatar="scopedProps">
          <slot
            name="user-avatar"
            :user="scopedProps.user"
            :message="scopedProps.message"
          >
          </slot>
        </template>
        <template v-slot:text-message-body="scopedProps">
          <slot
            name="text-message-body"
            :message="scopedProps.message"
            :messageText="scopedProps.messageText"
            :messageColors="scopedProps.messageColors"
            :me="scopedProps.me"
          >
          </slot>
        </template>
        <template v-slot:system-message-body="scopedProps">
          <slot name="system-message-body" :message="scopedProps.message">
          </slot>
        </template>
        <template v-slot:text-message-toolbox="scopedProps">
          <slot
            name="text-message-toolbox"
            :message="scopedProps.message"
            :me="scopedProps.me"
          >
          </slot>
        </template>
      </Message>
    </template>
  </div>
</template>

<script>
import Message from './Message.vue'
import Card from './Card.vue'
import { actions, getters } from '../store'

export default {
  components: {
    Card,
    Message
  },
  props: {
    participants: {
      type: Array,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    alwaysScrollToBottom: {
      type: Boolean,
      required: true
    },
    messageStyling: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...getters
  },
  mounted() {
    setTimeout(() => this.$nextTick(this._scrollDown), 100)
  },

  updated() {
    setTimeout(
      () =>
        this.$nextTick(() => {
          if (this.shouldScrollToBottom()) {
            this._scrollDown()
          }
        }),
      100
    )
  },

  watch: {},
  methods: {
    ...actions,
    _scrollDown() {
      const scrollList = this.$refs.scrollList
      scrollList?.scrollTo(0, scrollList.scrollHeight)
    },

    handleScroll(e) {
      if (e.target.scrollTop === 0) {
        this.$emit('scrollToTop')
      } else if (e.target.scrollTop < 100) {
        this.loadMoreChats()
      }
    },

    shouldScrollToBottom() {
      const scrollList = this.$refs.scrollList
      const threshold = 100 // пороговое значение для определения, близки ли мы к нижней части списка
      return (
        !this.isMore &&
        (this.alwaysScrollToBottom ||
          scrollList.scrollTop + scrollList.clientHeight >=
            scrollList.scrollHeight - threshold)
      )
    },

    profile(author) {
      const profile = this.participants.find((profile) => profile.id === author)
      return profile || { imageUrl: '', name: '' }
    }
  }
}
</script>

<style scoped>
.sc-message-list {
  height: 100%;
  overflow-y: auto;
  background-size: 100%;
  padding: 20px 0;
  border-radius: 10px;
}
</style>