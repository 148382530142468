<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-archive"
      row
      mb-3
    >
      <v-flex mb-3>
        <h1>{{ title }}</h1>
        <v-btn
          flat
          class="mt-3"
          @click="$router.push( {name: 'OrganizerOrderSchoolView'} )"
        >Скрыть архив заявок</v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      row
      mb-3
      justify-space-between
    >
      <v-flex md2>
        <DatePickersSmart
          :startDate.sync="dateStart"
          :onePicker="true"
        />
      </v-flex>
      <v-flex
        md4
        text-md-right
      >
        <v-btn
          flat
          @click="loadData()"
          class="primary"
        >показать</v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      class="fs-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.date.formatted }}</td>
              <td>{{ props.item.name }}</td>
              <td>
                <v-flex row>
                  <v-btn
                    flat
                    class="fs-btn fs-btn--secondary"
                    @click="$router.push({name: 'OrganizerArchiveOrderClassView', params: {
                         schoolId: props.item.id,
                         date: props.item.date.default,
                    }})"
                  >подробнее</v-btn>
                </v-flex>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import _ from 'lodash'
import { getArchiveSchools } from '@/router/routes/organizer'
import { mapMutations } from 'vuex'
import store from '@/store'
import { DatePickersSmart } from '@/components/common'

export default {
  name: 'OrganizerArchiveOrderIndex',
  components: {
    DatePickersSmart
  },
  data() {
    return {
      title: 'Архив заявок на питание',
      datepickerMenu: false,
      datepickerMenu1: false,
      dateStart: moment()
        .subtract('days', 1)
        .format(Vue.config.format.serverDate),
      headers: [
        { text: 'Дата', value: 'date.formatted' },
        { text: 'ОО', value: 'name' },
        { text: 'Операции', value: 'operations' }
      ],
      items: []
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    loadData() {
      this.items = []
      const [ year, month, day ] = this.dateStart.split('-')
      getArchiveSchools(year, month, day)
        .then(response => {
          _.forEach(response.data.data, school => {
            this.items.push({
              id: school.id,
              name: school.shortName,
              date: {
                default: moment(this.dateStart).format(Vue.config.format.serverDate),
                formatted: moment(this.dateStart).format(Vue.config.format.date)
              }
            })
          })
        })
        .finally(() => store.commit('setShowPreloader', false))
    }
  }
}
</script>
