<template>
  <v-container pa-0>
    <v-layout>
      <v-flex>
        <v-card class="rounded">
          <v-card-title primary-title>
            <div>
              <h2 class="headerline pb-3 text-md-center">{{ title }}</h2>
              <div>
                <h3 class="headerline pb-3">Внесение данных о родительских платежах из других банков</h3>
                <context-help-modal :context-key="contextKeys.supplier.payment" />
              </div>
            </div>
          </v-card-title>
          <v-flex ml-3 mr-5>
            <v-layout v-if="pupilInfo" mb-2>
              <v-flex mb-2 mr-0>
                <span class="fs-student-info__key">Ученик: </span>
                <span class="fs-student-info__key">Текущий баланс:</span>
              </v-flex>
              <v-flex mb-2>
                <span class="fs-student-info__key green--text">{{ pupil.full_name }}</span>
                <span class="fs-student-info__key green--text">{{ currentStudentBalance || 0 }} р.</span>
              </v-flex>
            </v-layout>
            <v-text-field
              label="Лицевой счет ученика"
              v-model="valueId"
              @focusout="handleFocusoutId"
            />
          </v-flex>
          <v-flex ml-3 mr-5>
            <v-text-field
              label="Cумма операции"
              v-model="valueAmount"
              @focusout="handleFocusoutBalance"
            />
          </v-flex>
          <v-flex ml-3 mr-5>
            <v-text-field
              label="Платежное поручение №"
              v-model="valuePaymentOrder"
              @focusout="handleFocusoutPaymentOrder"
            />
          </v-flex>
          <v-flex ml-3 mr-5>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="valuePaymentDate"
                  label="Дата платежа"
                  append-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="valuePaymentDate" locale="ru-ru" no-title scrollable>
                <div class="flex-grow-1"></div>
                <v-btn text color="primary" @click="menu = false">Отмена</v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">Сохранить</v-btn>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex ml-3 mr-5 mb-2>
            <v-text-field
              label="БИК"
              v-model="valueBic"
              @focusout="handleFocusoutBIC"
            ></v-text-field>
          </v-flex>
          <v-card-actions class="headerline pb-4 pl-4">
            <v-btn
              :disabled="isDisable"
              @click="saveReceipts"
              class="primary">Cохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal"
import WithTooltip from "@/components/common/Tooltip/Tooltip"
import UploadButton from "@/components/common/UploadButton/UploadButton"
import { DatePickersSmart } from "@/components/common"
import Vue from "vue"
import { mapMutations } from "vuex"
import { getOneStudentReceipts, postOneStudentReceipts } from "@/router/routes/organizer"
import notify from "@/utils/notifications/toastNotification"
import store from "@/store"
import numable from '@/mixins/balancable'

export default {
  mixins: [ numable ],
  name: "Other_Receipts",
  components: {
    ContextHelpModal,
    WithTooltip,
    UploadButton,
    DatePickersSmart
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      title: 'Квитанции из других банков',
      valueId: '',
      valueAmount: '',
      valuePaymentOrder: '',
      valuePaymentDate: '',
      valueBic: '',
      valueIdError: true,
      valueAmountError: true,
      valuePaymentOrderError: true,
      valueBicError: true,
      pupil: [],
      menu: null,
      date: '',
    }
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    checkingValues(min_len, max_len, value, field) {
      if (Number.isInteger(Number(value)) && (Number(value) > 0)) {
        if ((value.length >= min_len) && (value.length <= max_len)) {
          return false
        } else {
          notify.error(`${field} должен состоять от ${min_len} до ${max_len} символов`)
          return true
        }
      } else {
        notify.error(`${field} должен быть положительный и в числовом формате`)
        return true
      }
    },
    async handleFocusoutId() {
      if (this.valueId.length > 0) {
        const id = this.valueId
        this.valueIdError = false
        // Проверка является ли id целым положительным числом
        if (Number.isInteger(Number(id)) && (Number(id) > 0)) {
          return await getOneStudentReceipts(id)
            .then(response => {
              this.pupil = response.data.data
            })
            .catch(err => {
              this.pupil = []
              this.valueIdError = true
            })
            .finally(() => store.commit('setShowPreloader', false))
        } else {
          this.pupil = []
          this.valueId = ''
          this.valueIdError = true
          notify.error('Лицевой счет некорректно указан')
        }
      }
    },
    async handleFocusoutBalance() {
      if (this.valueAmount.length > 0) {
        if (this.valueAmount > 0) {
          this.valueAmountError = false
        } else {
          this.valueAmountError = true
          notify.error('Сумма операции должна быть положительной и в числовом формате')
        }
      }
    },
    async handleFocusoutPaymentOrder() {
      if (this.valuePaymentOrder.length > 0) {
        this.valuePaymentOrderError = this.checkingValues(1, 6, this.valuePaymentOrder,'Номер платежного поручения')
      }
    },
    async handleFocusoutBIC() {
      if (this.valueBic.length > 0) {
        this.valueBicError = this.checkingValues(8, 11, this.valueBic, 'Идентификационный код банка')
      }
    },
    async saveReceipts() {
      if (!this.valuesError) {
        const payload = {
          id: this.valueId,
          amount: this.convertToCents(this.valueAmount),
          payment_order: this.valuePaymentOrder,
          payment_date: this.valuePaymentDate,
          bic: this.valueBic
        }
        return await postOneStudentReceipts(payload)
          .then(response => {
            this.pupil = []
            this.valueId = ''
            this.valueAmount = ''
            this.valuePaymentOrder = ''
            this.valuePaymentDate = ''
            this.valueBic = ''
            notify.success('Зачисление выполнено')
          })
          .catch(err => {
            this.pupil = []
          })
          .finally(() => store.commit('setShowPreloader', false))
      }
    },
  },
  computed: {
    pupilInfo() {
      return this.pupil.length !== 0
    },
    currentStudentBalance() {
      console.log(this.pupil)
      console.log(this.valueAmount)
      return (
        +this.convertToRubles(this.pupil.balance) +
        +this.valueAmount
      ).toFixed(2)
    },
    isDisable() {
      return (
        this.valueId.length === 0 ||
        this.valueAmount.length === 0 ||
        this.valuePaymentOrder.length === 0 ||
        this.valuePaymentDate.length === 0 ||
        this.valueBic.length === 0
      )
    },
    valuesError() {
      return (
        this.valueIdError ||
        this.valueAmountError ||
        this.valuePaymentOrderError ||
        this.valueBicError
      )
    }
  }
}
</script>

<style scoped>

.rounded {
  border-radius: 10px;
}

</style>