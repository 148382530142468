<template>
  <v-container
    fluid
    class="fs-admin-container"
  >
    <v-layout
      class="fs-complex"
      row
      mb-3
    >
      <v-flex
        md4
        mb-3
      >
        <h1>{{title}}</h1>
      </v-flex>
    </v-layout>
    <v-layout
      class="fs-student-info"
      mb-3
      row
    >
      <v-flex
        md4
        offset-md2
        column
      >
        <div class="fs-fields-group--gray">
          <v-flex mb-3>
            <span class="fs-student-info__key">Фамилия:</span>
            <span class="fs-student-info__value">{{teacher.lastName}}</span>
          </v-flex>
          <v-flex mb-3>
            <span class="fs-student-info__key">Имя:</span>
            <span class="fs-student-info__value">{{teacher.firstName}}</span>
          </v-flex>
          <v-flex mb-3>
            <span class="fs-student-info__key">Отчество:</span>
            <span class="fs-student-info__value">{{teacher.middleName}}</span>
          </v-flex>
        </div>
      </v-flex>
      <v-flex
        column
        md4
      >
        <v-flex class="fs-fields-group--gray">
          <v-flex mb-3>
            <span class="fs-student-info__key">Класс в руководстве:</span>
            <span class="fs-student-info__value">{{getClassesNames(teacher.schoolClasses)}}</span>
          </v-flex>
          <v-flex>
            <span class="fs-student-info__value">
              <v-checkbox
                readonly
                class="fs-checkbox"
                v-model="teacher.isCredit"
                label="Может разрешать питаться в кредит"
              ></v-checkbox>
            </span>
          </v-flex>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-layout class="fs-form-footer">
      <v-flex
        text-md-right
        pt-3
      >
        <v-btn
          flat
          class="fs-btn fs-admin-btn__show v-btn--active fs-form-footer__btn"
          @click="$router.go(-1)"
        >Назад</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getOneTeacher } from "@/router/routes/admin"
import store from "@/store"
import { mapGetters } from "vuex"
import AdminTeacherFactory from "@/factories/admin/teacher-factory"

export default {
  name: "TeacherView",
  created() {
    const payload = {
      schoolId: this.currentUser.school.id,
      id: this.$route.params.id
    }
    getOneTeacher(payload)
      .then(response => {
        const teacher = response.data.data
        this.teacher = {
          id: teacher.id,
          firstName: teacher.firstName,
          middleName: teacher.middleName,
          lastName: teacher.lastName,
          schoolClasses: _.get(teacher, "schoolClasses", []),
          isCredit: _.get(teacher, "isCredit", false)
        }
      })
      .finally(() => store.commit("setShowPreloader", false))
  },

  data() {
    return {
      title: "Карточка учителя",
      teacher: new AdminTeacherFactory()
    }
  },
  computed: {
    ...mapGetters([ "currentUser" ])
  },
  methods: {
    getClassesNames(classes) {
      const names = _.map(classes, item => {
        return _.get(item, "name", "")
      })
      return names.sort((a, b) =>
        this.getClassLiter(a).localeCompare(this.getClassLiter(b)))
        .sort((a, b) =>
          this.getClassNumber(a) - this.getClassNumber(b)
        ).join(', ')
    },
    getClassLiter(name) {
      return name.split(' ')[1]
    },
    getClassNumber(name) {
      return name.split(' ')[0]
    }
  }
}
</script>
