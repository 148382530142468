import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  state: {
    departmenId: null,
    foodPeriods: []
  },
  getters: getters,
  mutations: mutations,
  actions: actions
}
