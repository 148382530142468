<template>
	<div v-if="foodType">
		<v-card class="fs-fields-group--gray" v-if="!isFake">
			<context-help-modal style="float: right" :context-key="contextKeys.admin.students.card.invoices" />
			<v-card-title>
				<h1>Печать квитанции</h1>
			</v-card-title>
			<v-card-text>
				<v-divider></v-divider>
				<v-layout mb-3 mt-3>
					<with-tooltip text="Расчет суммы по количеству дней" custom-style="width: 100%">
						<v-text-field
							v-model="foodDays"
							mask="###"
							label="Укажите количество дней пополнения"
							@input="calculateFoodPeriod('days')"
						></v-text-field>
					</with-tooltip>
				</v-layout>
				<v-layout mb-3 mt-3>
					<with-tooltip text="Расчет количества дней по указанной сумме" custom-style="width: 100%">
						<v-text-field
							v-model="foodSum"
							mask="#######"
							label="Укажите сумму для пополнения"
							@input="calculateFoodPeriod('sum')"
						></v-text-field>
					</with-tooltip>
				</v-layout>
				<v-divider></v-divider>
			</v-card-text>
			<v-card-actions>
				<v-flex text-md-right>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-icon
								v-on="on"
								@click="isTeacher ? printTeacher() : printAdmin()"
							>print</v-icon>
						</template>
						<span>Печать</span>
					</v-tooltip>
					<with-tooltip v-if="!isTeacher" text="Указать произвольный срок, по которому будет сформирована итоговая сумма в квитанции">
						<v-btn
							@click="handlerPrintFromDateToDate"
							flat
							class="fs-btn fs-teacher-btn__cancel v-btn--active mb-2 ml-2"
						>Выбрать период для печати</v-btn>
					</with-tooltip>
				</v-flex>
			</v-card-actions>
		</v-card>
		<ConfirmModal
			:dialog.sync="showSelectDateModal"
			@confirm="handlerConfirmSelectDateModal"
		>
			<template v-slot:headline>Выбор периода для печати квитанций</template>
			<template v-slot:body>
				<DatePickersSmart
					startDateTitle="От"
					endDateTitle="До"
					:startDate.sync="dateStart"
					:endDate.sync="dateEnd"
				/>
			</template>
		</ConfirmModal>
	</div>
</template>

<script>
import { ConfirmModal } from "@/components/common"
import DatePickersSmart from "@/components/common/DatePickersSmart/DatePickersSmart.vue"
import notify from "@/utils/notifications/toastNotification.vue"
import {
  printInvoice as printInvoiceAdmin,
  printInvoiceFromDateToDate
} from "@/router/routes/admin"
import { printInvoice as printInvoiceTeacher } from "@/router/routes/teacher"
import store from "@/store"
import moment from "moment/moment"
import Vue from "vue"
import WithTooltip from "@/components/common/Tooltip/Tooltip.vue"
import { mapGetters, mapMutations } from "vuex"
import _ from "lodash"
import numable from '@/mixins/balancable'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue";

export default {
  name: 'PupilCardInvoice',
  mixins: [ numable ],
  components: {
	  ContextHelpModal,
	  WithTooltip,
    DatePickersSmart,
	  ConfirmModal
  },
  props: {
	  accountId: {
      type: Number,
		  required: true
	  },
	  foodPrice: {
      type: Number,
		  required: true
	  },
    isFake: {
      type: Boolean,
      default: false,
    },
	  isTeacher: {
		  type: Boolean,
		  default: false,
	  },
    foodType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
	    contextKeys: Vue.config.contextHelp,
      showSelectDateModal: false,
	    dateStart: moment().add(1, 'days').format(Vue.config.format.serverDate),
	    dateEnd: moment().add(1, 'days').format(Vue.config.format.serverDate),
	    foodDays: 22,
	    foodSum: 0,
    }
  },
  computed: {
    ...mapGetters([ 'currentUser' ])
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
	  getFilteredDataForPeriod() {
		  return {
			  date_from: this.dateStart,
			  date_to: this.dateEnd,
			  pupils: [ this.accountId ]
		  }
	  },
	  async printTeacher() {
		  const payload = {
			  params: {
				  student_id: parseInt(this.accountId),
				  days: this.foodDays,
				  sum: this.convertToCents(this.foodSum)
			  }
		  }

		  await printInvoiceTeacher(payload)
			  .then(response => {
				  const file = new Blob([ response.data ], { type: response.data.type })
				  const url = window.URL.createObjectURL(file)
				  const a = document.createElement('a')
				  a.href = url
				  a.download = 'Квитанция на оплату от ' + moment().format(Vue.config.format.date) + '.xlsx'
				  a.click()
			  })
			  .finally(() => this.setShowPreloader(false))
	  },
	  async printAdmin() {
		  const payload = {
			  id: _.get(this.currentUser, 'school_id', null),
			  params: {
				  student_id: parseInt(this.accountId),
				  days: this.foodDays,
				  sum: this.convertToCents(this.foodSum)
			  }
		  }

		  await printInvoiceAdmin(payload)
			  .then(response => {
				  const file = new Blob([ response.data ], { type: response.data.type })
				  const url = window.URL.createObjectURL(file)
				  const a = document.createElement('a')
				  a.href = url
				  a.download = 'Квитанция на оплату от ' + moment().format(Vue.config.format.date) + '.xlsx'
				  a.click()
			  })
			  .finally(() => this.setShowPreloader(false))
	  },
    async handlerConfirmSelectDateModal(value) {
      this.modalResponse = value
      if (this.modalResponse) {
        if (!this.dateStart || !this.dateEnd) {
          notify.error('Ошибка. Выберите период формирования квитанции') //pass
          this.showSelectDateModal = false
          return
        }
        const payload = {
          school_id: this.currentUser.school.id,
          data: this.getFilteredDataForPeriod()
        }
        try {
          await printInvoiceFromDateToDate(payload)
            .then(response => {
              const file = new Blob([ response.data ], { type: "octet/stream" })
              const url = window.URL.createObjectURL(file)
              const a = document.createElement('a')
              a.href = url
              a.download =
								'Квитанции на оплату за период от ' + this.dateStart + ' до ' + this.dateEnd + '.zip'
              a.click()
            })
        } catch (error) {
          // notify.error('Ошибка формирования квитанции') // backend handler added (exception_message)
        }
      }
      this.showSelectDateModal = false
      store.commit('setShowPreloader', false)
    },
	  handlerPrintFromDateToDate() {
		  this.dateStart = null
		  this.dateEnd = null
		  this.showSelectDateModal = true
	  },
	  calculateFoodPeriod(type) {
		  if (type === 'days') {
			  this.foodSum = Math.ceil(this.foodDays * this.convertToRubles(this.foodPrice))
			  return
		  }
		  this.foodDays = parseInt(
			  this.convertToCents(this.foodSum) / this.foodPrice
		  )
	  },
  }
}
</script>
