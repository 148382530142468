<template>
  <v-card>
    <v-toolbar color="primary" dark dense>
      <v-toolbar-title>{{ isEdit ? 'Редактировать шаблон' : 'Создать шаблон' }}</v-toolbar-title>
      <context-help-modal :context-key="contextKeys.supplier.template.edit" />
      <v-spacer></v-spacer>
      <v-btn icon @click.prevent="$emit('close')">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-alert
      :value="dataLoaded && isEdit && isDefaultComplexEmpty"
      type="warning"
      color="red"
    >Внимание! Некоторые типы питания не имеют выбранного комплекса по умолчанию</v-alert>
    <v-card-text>
      <v-text-field
        v-model="form.title"
        label="Наименование шаблона"
      />
      <v-autocomplete
        :items="schools"
        :value="selectedSchools"
        v-model="selectedSchools"
        item-text="name"
        item-value="id"
        label="Образовательные организации"
        clearable
        multiple
        @change="changeSchools($event)"
      >
        <template v-slot:prepend-item>
          <v-list-tile
            :class="{ 'primary--text': selectedAllSchools }"
            ripple
            @click="selectAllSchools"
          >
            <v-list-tile-action>
              <v-checkbox v-model="selectedAllSchools" color="primary"></v-checkbox>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Все</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-autocomplete>
      <v-data-table
        :headers="headers"
        :items="complexesByMealType"
        class="elevation-1 mt-3"
        hide-actions
        item-key="name"
      >
        <template v-slot:items="props">
          <tr>
            <td>{{ props.item.name }}</td>
            <td>
              <v-autocomplete
                :loading="isEdit && !dataLoaded && !default_complect_map[props.item.id]"
                :value="default_complect_map[props.item.id]"
                :items="props.item.complexes"
                :aria-hidden="true"
                item-text="name"
                item-value="id"
                return-object
                @change="changeDefaultComplex($event, props.item.id)"
              />
            </td>
            <td>
              <v-autocomplete
                :loading="isEdit && !dataLoaded && !complect_map[props.item.id]"
                :value="complect_map[props.item.id]"
                :items="props.item.complexes"
                :aria-hidden="true"
                item-text="name"
                item-value="id"
                return-object
                clearable
                multiple
                @change="changeComplexes($event, props.item.id)"
              />
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-switch
        readonly
        v-model="form.active"
        label="Статус активности"
        :disabled="isDefaultComplexEmpty"
      />
      <v-card-actions class="pa-0">
        <v-btn
          :disabled="isDefaultComplexEmpty"
          class="primary mx-0"
          flat
          @click="handleCreateOrUpdateTemplate"
        >{{ isEdit ? 'Обновить' : 'Создать' }}</v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import {mapGetters, mapMutations} from 'vuex'

import {
  getOneComplexTemplate,
  getSchools,
  getComplexes,
  getOrganizerFoodTypes,
  assignComplexesToTemplate,
  createTemplateMenu
} from '@/router/routes/organizer'

import scrollable from '@/mixins/scrollable'
import { disableSorting } from '@/mixins/helpers'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import store from "@/store"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'DayTemplateMenuForm',
  components: { ContextHelpModal },
  mixins: [ scrollable, disableSorting ],
  props: {
    entityId: {
      default: null
    },
    weekDay: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      LIMIT: Vue.config.general.limit,
      schools: [],
      complexes: [],
      meal_types: [],
      complect_map: {},
      default_complect_map: {},
      selectedSchools: [],
      selectedAllSchools: false,
      headers: [
        { text: 'Тип питания', value: 'meal_type' },
        { text: 'Комплекс по умолчанию', value: 'default_complex' },
        { text: 'Комплексы', value: 'updated_at' },
      ],
      form: {
        title: '',
        day_in_period: this.weekDay,
        school: [],
        active: true,
        supplier: null,
        complects: [],
        complects_mapping: [],
        default_complects: {}
      },
      complexesByMealType: [],
      dataLoaded: false
    }
  },
  computed: {
    isEdit() {
      return !!this.entityId
    },
    isDefaultComplexEmpty() {
      return Object.keys(this.default_complect_map).length !== this.meal_types.length
    },
  },
  async mounted() {
    await this.getOrganizerFoodTypes()
    await this.getSchools()
    await this.getComplexes()

    if (this.isEdit && this.entityId) {
      await this.getOneComplexTemplate(this.entityId)
    } else {
      this.selectedSchools = [ ...this.schools ]
      this.changeSchools(this.selectedSchools)
    }

    this.prepareComplexesByMealType()

    store.commit('setShowPreloader', false)

  },
  methods: {
    ...mapGetters(['showPreloader']),
    ...mapMutations([ 'setShowPreloader' ]),
    selectAllSchools() {
      this.selectedAllSchools = !this.selectedAllSchools
      this.selectedSchools = this.selectedAllSchools ? [ ...this.schools.map((e) => e.id ) ] : []
    },
    prepareComplexesByMealType() {
      this.complexesByMealType = this.meal_types.map(mt => {
        return {
          id: mt.id,
          name: mt.name,
          selected: [],
          complexes: this.complexes
            .filter(complex => {
              const complexMelTypeIds = complex.meal_type.map(cmt => cmt.id)
              return complexMelTypeIds.includes(mt.id)
            })
            .map(complex => ({ id: complex.id, name: complex.name }))
        }
      })
    },
    changeSchools(e) {
      this.selectedAllSchools = e.length === this.schools.length
    },
    changeDefaultComplex(e, id) {
      this.$set(this.default_complect_map, id, e.id)
    },
    changeComplexes(e, id) {
      this.$set(this.complect_map, id, e.map(c => typeof c === 'object' ? c.id : c))
    },
    async getOneComplexTemplate(templateId) {
      try {
        const res = await getOneComplexTemplate(templateId)
        const data = res.data.data
        this.form = data

        this.schools.forEach(el => {
          if (data.school.includes(el.id)) {
            this.selectedSchools.push(el)
          }
        })
        this.changeSchools(this.selectedSchools)

        let _complects = [], _default_complects = []
        data.complects_mapping.forEach(el => {
          _complects = this.complect_map[el.meal_type_id] || []
          _complects.push(el.complect_id)
          _default_complects = data.default_complects.filter(item => item.meal_type === el.meal_type_id)

          this.$set(this.complect_map, el.meal_type_id, _complects)
          if (_default_complects.length === 1) {
            this.$set(this.default_complect_map, el.meal_type_id, _default_complects[0].complect)
          }
        })

        this.dataLoaded = true
      } catch (e)
      {
        // notify.error('Ошибка загрузки шаблона') // backend handler added (exception_message)
        console.error(e)
      }
    },
    async getSchools() {
      try {
        const PAYLOAD = {
          limit: this.LIMIT,
          offset: this.offset
        }
        const response = await getSchools(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })

        this.schools = [ ...this.schools, ...itemsArray ]
      } catch (err) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
        console.error(err)
      }
    },
    async getComplexes() {
      try {
        const res = await getComplexes()
        this.complexes = res.data.data
      } catch (e)
      {
        // notify.error('Ошибка получения комплексов ОП') // backend handler added (exception_message)
        console.error(e)
      }
    },
    async getOrganizerFoodTypes() {
      try {
        const res = await getOrganizerFoodTypes()
        this.meal_types = res.data.data
      } catch (e) {
        // notify.error('Ошибка получения типов питания ОП') // backend handler added (exception_message)
        console.error(e)
      }
    },
    handleCreateOrUpdateTemplate() {
      const { title, active, supplier } = this.form
      const school = (() => {
        if (this.selectedSchools.length) {
          return this.selectedSchools.map(e => {
            if (e.id) return e.id
            else return e
          })
        } else {
          return this.schools.map(v => v.id)
        }
      })()
      const params = {
        title,
        active,
        school,
        day_in_period: this.weekDay,
        complects: []
      }

      Object.keys(this.complect_map).forEach(mt => {
        this.complect_map[mt].forEach(id => params.complects.push({
          mealTypeId: +mt,
          complectId: id
        }))
      })

      if (this.isEdit) {
        params['defaultComplects'] = this.default_complect_map

        assignComplexesToTemplate({
          id: this.entityId,
          params
        })
          .then(() => {
            notify.success('Шаблон меню обновлен успешно')
            const willUpdateParent = true
            this.$emit('close', willUpdateParent)
          })
          .catch(err => {
            // notify.error('Ошибка обновления шаблона меню') // backend handler added (exception_message)
            console.error(err)
          })
          .finally(() => {
            this.setShowPreloader(false)
          })
      } else {
        params['supplier'] = supplier
        params['default_complects'] = this.default_complect_map

        createTemplateMenu({ params })
          .then(() => {
            notify.success('Шаблон меню создан успешно')
            const willUpdateParent = true
            this.$emit('close', willUpdateParent)
          })
          .catch(err => {
            // notify.error(`Ошибка создания шаблона меню: ${err}`) // backend handler added (exception_message)
            console.error(err)
          })
          .finally(() => {
            this.setShowPreloader(false)
          })
      }
    }
  }
}
</script>
