<template>
  <v-form ref="questionSyrveyForm" v-model="valid">
    <v-card>
      <v-toolbar elevation="1" dense light>
        <v-toolbar-title>
          {{ is_create ? `Добавить новый вопрос` : `Изменить вопрос` }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.prevent="closeEditModal">
          <v-icon class="fs-active-icon">close</v-icon>
        </v-btn>
      </v-toolbar>


      <v-card-text>
        <v-text-field
          v-model="name_product"
          :rules="[rules.required]"
          :label="!darkTheme ? 'Наименование вопроса:' : ''"
          :placeholder="darkTheme ? 'Наименование вопроса:' : ''"
          :outline="darkTheme"
        />

        <v-switch
          v-model="is_several"
          inset
          :label="`Разрешить несколько вариантов ответов`"
        ></v-switch>

      <v-switch
        v-model="is_comment"
        inset
        :label="`Ответом на вопрос будет комментарий пользователя`"
      ></v-switch>

      <v-combobox
        clearable
        multiple
        small-chips
        solo
        v-model="question_detail.variants"
        :items="all_variants.results"
        item-text="name"
        item-value="id"

        :label="!darkTheme ? 'Список ответов:' : ''"
        :placeholder="darkTheme ? 'Список ответов:' : ''"

      ></v-combobox>

      <v-btn
          flat
          @click="is_show_create_question = true"
          class="fs-btn fs-btn--primary fs-btn__add-dish"
        >Добавить ответ</v-btn>

      </v-card-text>

      <v-card-actions>
        <v-btn @click.prevent="closeEditModal">
          <v-icon class="fs-active-icon">close</v-icon>
          Отменить
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn @click="saveProduct">
          <v-icon class="fs-active-icon">check</v-icon>
          {{ is_create ? `Создать` : `Обновить` }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="is_show_create_question" persistent width="80%">
      <v-card>
        <v-toolbar elevation="1" dense light>
          <v-toolbar-title>
            Создать вариант ответа
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.prevent="closeVariantModal">
            <v-icon class="fs-active-icon">close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-text-field label="Название ответа:" v-model="variant_name" />
          <v-text-field label="Мнемокод:" v-model="variant_code" />
          <v-text-field type="number" label="Целочисленнное значение ответа:" v-model="variant_value" />
        </v-card-text>

        <v-card-actions>
          <v-btn @click.prevent="closeVariantModal">
            <v-icon class="fs-active-icon">close</v-icon>
            Отменить
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn @click="handleCreateVariant">
            <v-icon class="fs-active-icon">check</v-icon>
            Создать
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>


  </v-form>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from "@/store"
export default {
  name: 'SyrveyManageQuestionDetail',
  props: [ 'is_create', 'syrvey_id', 'question_id' ],
  data: () => ({
    darkTheme: Vue.config.env.darkTheme,
    rules: {
      required: (v) => !!v || "Поле обязательное для заполнения"
    },
    valid: true,
    is_show_create_question: false
  }),
  async mounted() {
    await this.getAllVariants()
    this.set_question_detail({
      ...this.question_detail,
      questionnaire: this.syrvey_id
    })
    if (!this.is_create) {
      this.getQuestionDetail(this.question_id)
    }
  },
  methods: {
    ...mapActions([ 'createQuestion', 'updateQuestion', 'getQuestionDetail', 'getAllVariants', 'createVariant' ]),
    ...mapMutations([ 'set_question_detail', 'clearQuestionDetail', 'set_variant_detail', 'clear_variant_detail' ]),
    closeEditModal() {
      this.clearQuestionDetail()
      this.$emit('close')
    },
    handleCreateVariant() {
      this.createVariant().then(() => {
        this.closeVariantModal()
        this.getAllVariants()
      })

    },
    closeVariantModal() {
      this.is_show_create_question = false
      this.clear_variant_detail()
    },

    saveProduct() {
      this.$refs.questionSyrveyForm.validate()
      if (this.valid) {
        if (this.is_create) {
          this.createQuestion().then(() => {
            this.closeEditModal()
          })
        }
        else {
          this.updateQuestion().then(() => {
            this.closeEditModal()
          })
        }
      }
    },
  },
  watch: {
    'question_detail.variants'(list) {
      if (this.question_detail.is_comment && list.length) {
        this.set_question_detail({
          ...this.question_detail,
          is_comment: false
        })
      }
    }

  },
  computed: {
    ...mapGetters([ 'question_detail', 'all_variants', 'variant_detail' ]),
    variant_name: {
      get () {
        return this.variant_detail.name
      },
      set (value) {
        this.set_variant_detail({
          ...this.variant_detail,
          name: value
        })
      }
    },
    variant_code: {
      get () {
        return this.variant_detail.code
      },
      set (value) {
        this.set_variant_detail({
          ...this.variant_detail,
          code: value
        })
      }
    },
    variant_value: {
      get () {
        return this.variant_detail.value
      },
      set (value) {
        this.set_variant_detail({
          ...this.variant_detail,
          value: value
        })
      }
    },
    name_product: {
      get () {
        return this.question_detail.name
      },
      set (value) {
        this.set_question_detail({
          ...this.question_detail,
          name: value
        })
      }
    },
    is_several: {
      get () {
        return this.question_detail.is_several
      },
      set (value) {
        if (value) {
          this.set_question_detail({
            ...this.question_detail,
            is_comment: false
          })
        }
        this.set_question_detail({
          ...this.question_detail,
          is_several: value
        })
      }
    },
    is_comment: {
      get () {
        return this.question_detail.is_comment
      },
      set (value) {
        if (value) {
          this.set_question_detail({
            ...this.question_detail,
            is_several: false
          })
          this.question_detail.variants = []
        }
        this.set_question_detail({
          ...this.question_detail,
          is_comment: value
        })
      }
    },
  }
}
</script>

<style>
</style>
