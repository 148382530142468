<template>
	<v-container class="fs-admin-container" fluid>
		<div class="mb-3" style="display: flex">
			<h1>{{ title }}</h1>
			<context-help-modal style="float: right" :context-key="contextKeys.admin.students.card.main" />
		</div>
		<v-layout mb-3 row>
			<v-flex md-2>
				<v-alert
					:value="isFake"
					type="error"
				>
					<h3>
						Ошибка: по данному ученику из учётной системы региона получены неполные данные.
						Работа с ним временно заблокирована. Ожидайте повторной синхронизации.
					</h3>
				</v-alert>
			</v-flex>
			<with-tooltip text="Вернуться на предыдущую страницу">
				<v-btn
					class="fs-btn fs-admin-btn__show v-btn--active mr-1 text-md-right"
					flat
					@click="$router.push({ name: isTeacher ? 'Students' : 'AdminStudents', params: { filters: savedFilters } })"
				>Назад</v-btn>
			</with-tooltip>
		</v-layout>
		<v-layout class="fs-student-info" mb-3 row>
			<v-flex md4 offset-md2 column>
				<v-card class="fs-fields-group--gray">
					<context-help-modal style="float: right" :context-key="contextKeys.admin.students.card.about" />
					<v-card-text>
						<v-flex mb-3>
							<span class="fs-student-info__key">ФИО:</span>
							<span class="fs-student-info__value">{{ student.fullName }}</span>
						</v-flex>
						<v-flex mb-3>
							<span class="fs-student-info__key">Класс:</span>
							<span class="fs-student-info__value">{{ student.schoolClass.name }}</span>
						</v-flex>
						<v-flex mb-3>
							<span class="fs-student-info__key">Лицевой счет:</span>
							<span class="fs-student-info__value">{{ student.id }}</span>
						</v-flex>
						<v-flex v-if="extId" mb-3>
							<span class="fs-student-info__key">Внешний лицевой счет:</span>
							<span class="fs-student-info__value">{{ extId }}</span>
						</v-flex>
						<v-flex mb-3>
							<span class="fs-student-info__key">Оферта принята:</span>
							<span class="fs-student-info__value">{{ student.isAgreementAccepted ? 'Да' : 'Нет' }}</span>
						</v-flex>
						<v-flex mb-3>
							<span class="fs-student-info__key">Дата принятия оферты:</span>
							<span class="fs-student-info__value">{{ student.agreementAcceptionDate || 'Нет' }}</span>
						</v-flex>
						<v-flex mb-3 v-if="!setting('sirius')">
							<span class="fs-student-info__key">Бумажная оферта принята:</span>
							<span class="fs-student-info__value">{{ student.isOfflineContractAccepted ? 'Да' : 'Нет' }}</span>
						</v-flex>
						<v-flex mb-3 v-if="!setting('sirius')">
							<span class="fs-student-info__key">Дата принятия бумажной оферты:</span>
							<span class="fs-student-info__value">{{ student.offlineContractAcceptionDate || 'Нет' }}</span>
						</v-flex>
						<v-flex v-if="!view && !isFake && !setting('sirius')">
							<with-tooltip
                text="Принять бумажную оферту"
              >
								<v-checkbox
									v-model="student.isOfflineContractAccepted"
									class="fs-checkbox"
									label="Принять бумажную оферту в отправку"
								></v-checkbox>
							</with-tooltip>
						</v-flex>
						<v-flex style="display: flex">
							<with-tooltip text="Отображает движение денежных средств по данному ученику" v-if="!isFake">
								<v-btn
									class="fs-btn fs-admin-btn__show v-btn--active"
									flat
									block
									@click="showViewModal"
								>Движение денежных средств</v-btn>
							</with-tooltip>
							<with-tooltip text="Показывает предыдущие заявки ученика" v-if="!view && !isTeacher && ordersViewFeature">
								<v-btn
									class="fs-btn fs-admin-btn__show v-btn--active ml-2"
									flat
									block
									@click="showOrdersModal"
								>Заявки на питание</v-btn>
							</with-tooltip>
						</v-flex>
					</v-card-text>
				</v-card>
				<PupilCardInvoice v-if="studentInfoLoaded && setting('payment_method_by_receipt')"
				                    :isFake="isFake"
				                    :isTeacher="isTeacher"
				                    :accountId="parseInt(accountId)"
				                    :foodPrice="foodPrice"
                            :foodType="student.foodType.id"
				/>
			</v-flex>
			<v-flex column md4>
				<PupilCardFood v-if="studentInfoLoaded"
				                 :student.sync="student"
				                 :isFake="isFake"
				                 :isTeacher="isTeacher"
				                 :view="view"
				                 @getStudent="getStudent"
				                 @updateStudent="updateStudent($event, true)"
				/>

				<PupilCardBalance
					:balance="currentBalance"
					:isFake="isFake"
				/>
			</v-flex>
		</v-layout>
		<ViewModal
			:title="balanceMovingViewModalTitle"
			:dialog.sync="showBalanceMovingViewModal"
			@scrollOnBottom="handlerScrollBottom"
		>
			<template v-slot:content>
				<v-layout class="fs-header-filter" wrap row>
					<v-flex md2>
						<with-tooltip text="Выбор начальной и конечной даты">
							<DatePickersSmart
								:startDate.sync="dateStart"
								:endDate.sync="dateEnd"
								@changeDate="changeDate"
							/>
						</with-tooltip>
					</v-flex>
					<v-flex md4>
						<span style="font-weight: bold">Лицевой счёт: {{ accountId }}</span>
					</v-flex>
				</v-layout>
				<v-layout v-if="dataTableLoaded" class="fs-admin-archive-result" mb-3 justify-space-around>
					<v-flex>
						<v-data-table
							:headers="headers"
							:items="payloads"
							class="elevation-1 fs-admin-table"
							no-data-text="Транзакции отсутствуют"
							hide-actions
							item-key="name"
						>
							<template v-slot:items="props">
								<tr>
									<td>{{ props.item.date }}</td>
									<td>{{ props.item.sum }}</td>
									<td>{{ props.item.balance }}</td>
									<td>{{ props.item.id }}</td>
									<td>{{ props.item.transactionCode }}</td>
									<td>{{ props.item.name }}</td>
								</tr>
							</template>
						</v-data-table>
					</v-flex>
				</v-layout>
			</template>
		</ViewModal>

		<OrdersViewModal
			:dialog.sync="showOrdersViewModal"
			:studentName="student.fullName"
			:studentID="student.id"
			:schoolID="currentUser.school_id"
		/>

	<v-layout class="fs-form-footer">
		<v-flex text-md-right pt-3>
			<with-tooltip text="Сохранить изменения">
				<v-btn
					v-if="!view && !isFake"
					class="fs-btn fs-admin-btn__show v-btn--active fs-form-footer__btn"
					flat
					@click="updateStudent(student.foodType.id)"
				>Сохранить</v-btn>
			</with-tooltip>
		</v-flex>
	</v-layout>
	</v-container>
</template>

<script>
import Title from '@/ui-components/Title'
import ViewModal from '@/components/common/Modals/ViewModal'
import OrdersViewModal from '@/components/common/Modals/OrdersViewModal'

import {
  getOneStudent as getOneStudentAdmin,
  getPayments as getPaymentsAdmin,
  updateOneStudent as updateOneStudentAdmin,
} from '@/router/routes/admin'

import {
  getOneStudent as getOneStudentTeacher,
  getPayments as getPaymentsTeacher,
  updateOneStudent as updateOneStudentTeacher,
} from '@/router/routes/teacher'

import store from '@/store'
import { mapGetters, mapMutations } from 'vuex'
import AdminStudentFactory from '@/factories/admin/student-factory'
import numable from '@/mixins/balancable'
import Vue from 'vue'
import DatePickersSmart from '@/components/common/DatePickersSmart/DatePickersSmart'
import converter from '@/helpers/converter'
import _ from 'lodash'
import moment from 'moment'
import { getSchoolId } from "@/helpers/utils"
import { ConfirmModal } from '@/components/common'
import { objectWithDateProps } from '@/utils/date'
import { recreatePupilOrders } from "@/router/routes/common"
import WithTooltip from "@/components/common/Tooltip/Tooltip"
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'
import PupilCardBalance from "@/components/common/PupilCard/Balance.vue"
import PupilCardFood from "@/components/common/PupilCard/Food.vue"
import PupilCardInvoice from "@/components/common/PupilCard/Invoice.vue"

export default {
  name: 'PupilCard',
  mixins: [ numable ],
  components: {
	  PupilCardInvoice,
	  PupilCardFood,
	  PupilCardBalance,
    ContextHelpModal,
    WithTooltip,
    Title,
    ViewModal,
    DatePickersSmart,
    ConfirmModal,
    OrdersViewModal,
  },
  props: {
    view: {
      type: Boolean,
      default: false
    },
	  isTeacher: {
		  type: Boolean,
		  default: false
	  },
  },
  data() {
    return {
	    contextKeys: Vue.config.contextHelp,
	    studentInfoLoaded: false,
      title: 'Карточка ученика',
      student: new AdminStudentFactory(),
      foodSum: 0,
      foodPrice: 0,
      savedFilters: null,
      showBalanceMovingViewModal: false,
      isShowPupilPrivilegeModal: false,
      extSettingDateMenu: false,
      isFake: null,
      extId: false,
      settingStudentExtPrivilege: objectWithDateProps({}, 'ext_date'),
      headers: [
        { text: 'Дата и время операции', value: 'sortDate' },
        { text: 'Сумма операции в рублях', value: 'sum' },
        { text: 'Баланс', value: 'balance' },
        { text: 'Лицевой счет ученика', value: 'id' },
        { text: 'Код транзакции', value: 'transactionCode' },
        { text: 'Имя ученика', value: 'name' }
      ],
      balanceMovingViewModalTitle: 'Движение денежных средств',
      dateStart: moment().add(1, 'days').format(Vue.config.format.serverDate),
      dateEnd: moment().add(1, 'days').format(Vue.config.format.serverDate),
      dataTableLoaded: false,
      payloads: [],
      LIMIT: Vue.config.general.limit,
      ext_date_id: null,
      showModal: false,
      modalResponse: false,
      showOrdersViewModal: false,
      ordersViewFeature: store.getters.setting('pupil_card_orders_view'),
    }
  },
  computed: {
    ...mapGetters([ 'currentUser', 'setting' ]),
    currentBalance() {
      return this.convertToRubles(this.student.balance.current)
    },
    startDatePython() {
      return moment(this.dateStart).format(Vue.config.format.pythonDate)
    },
    endDatePython() {
      return moment(this.dateEnd).format(Vue.config.format.pythonDate)
    },
    accountId() {
      return this.$route.params.id
    },
  },
  async mounted() {
    if (this.$route.params.filters) {
      this.savedFilters = this.$route.params.filters
    }
    await this.getStudent().finally(() => this.setShowPreloader(false))
    await this.loadPayments()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    showViewModal() {
      this.showBalanceMovingViewModal = true
    },
    showOrdersModal() {
      this.showOrdersViewModal = true
    },
    async loadPayments() {
      try {
        const payload = {
          schoolId: this.currentUser.school_id,
          params: {
            start_date: this.startDatePython,
            end_date: this.endDatePython,
            accountId: this.accountId,
            schoolClass: this.student.schoolClass.id,
            limit: this.LIMIT,
            offset: this.offset
          }
        }
        const method = this.isTeacher ? getPaymentsTeacher : getPaymentsAdmin
        const response = await method(payload)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const {
            accountId = null,
            date = null,
            fullName = '',
            sum = null,
            transactionCode = null,
            balance = null
          } = item
          return {
            id: accountId,
            name: fullName,
            date: date,
            sortDate: new Date(this.getISODate(date)),
            sum: converter(sum, 'pennyToRub'),
            transactionCode: transactionCode,
            balance: converter(balance, 'pennyToRub')
          }
        })
        this.payloads = [ ...this.payloads, ...itemsArray ]
        this.dataTableLoaded = true
      } catch (error) {
        console.log(error)
        // notify.error('Ошибка загрузки счетов') // backend handler added (exception_message)
      } finally {
	      store.commit('setShowPreloader', false)
      }
    },
    async getStudent() {
      const payload = {
        schoolId: getSchoolId(this.currentUser),
        id: this.$route.params.id
      }
      const method = this.isTeacher ? getOneStudentTeacher(payload) : getOneStudentAdmin(payload)
      return await method.then(response => {
        const student = response.data.data
        this.isFake = student.isFake
        this.extId = student.extId
        this.ext_date_id = !student.privilege.is_default

        this.student = {
          id: student.id,
          remoteId: student.personId,
          fullName: student.fullName,
          mt:student.meal_type,
          concession: {
            id: _.get(student, 'privilege.id', null),
            name: _.get(student, 'privilege.name', null),
            isDefault: student.privilege.is_default
          },
          foodType: {
            id: _.get(student, 'foodType.id', null),
            name: _.get(student, 'foodType.name', null)
          },
          schoolClass: {
            id: _.get(student, 'schoolClass.id', null),
            name: _.get(student, 'schoolClass.name', null)
          },
          balance: {
            available: student.available,
            reserved: student.reserved,
            current: student.balance,
            reserved_additional_food: student.reserved_additional_food
          },
          isLongDay: _.get(student, 'isLongDay', false),
          isCredit: _.get(student, 'isCredit', false),
          freeBuffet: _.get(student, 'freeBuffet', false),
          validFoodTypes: _.get(student, 'validFoodTypes', false),
          isAgreementAccepted: _.get(student, 'isAgreementAccepted', null),
          isOfflineContractAccepted: _.get(student, 'isOfflineContractAccepted', null),
          hasCustomMealTypeSettings: _.get(student, 'hasCustomMealTypeSettings', false),
	        mealTypeSettings: student.mealTypeSettings
        }

        if (this.student.foodType.id === null && !store.getters.setting('sirius')) {
          // notify.error("Для печати квитанции необходимо выбрать тип питания")
        }

        if (this.student.validFoodTypes) {
          this.student.validFoodTypes = student.validFoodTypes.filter(
            item => item.privilege.id === student.privilege.id
          )
        }

	      if (this.student.mealTypeSettings === null) {
		      this.student.mealTypeSettings = {
			      1: null,
			      2: null,
			      3: null,
			      4: null,
			      5: null,
			      6: null,
			      7: null,
		      }
	      }

        objectWithDateProps(this.student, 'agreementAcceptionDate', 'offlineContractAcceptionDate')

        this.student.agreementAcceptionDate = _.get(student, 'agreementAcceptionDate', '')
        this.student.offlineContractAcceptionDate = _.get(student, 'offlineContractAcceptionDate', '')

        if (student.foodType) {
          let foodPrice = _.get(student, 'foodType.price', 8000) - _.get(student, 'privilege.price', 2000)
          if (foodPrice < 0) {
            foodPrice = 0
          }
          this.foodPrice = foodPrice
        }
        else {
          this.foodPrice = 6000
        }

        this.calculateFoodPeriod('days')
        this.setShowPreloader(false)

	      this.studentInfoLoaded = true

        return response
      })
    },
    async updateStudent(event, mealTypeUpdated=false) {
      const payload = {
        schoolId: this.currentUser.school.id,
        id: this.$route.params.id,
        data: {
          foodType: event,
          isLongDay: this.student.isLongDay,
          isCredit: this.student.isCredit,
          freeBuffet: this.student.freeBuffet,
          isOfflineContractAccepted: this.student.isOfflineContractAccepted,
	        hasCustomMealTypeSettings: this.student.hasCustomMealTypeSettings,
	        mealTypeSettings: this.student.mealTypeSettings
        }
      }
      const method = this.isTeacher ? updateOneStudentTeacher : updateOneStudentAdmin
      try {
        await method(payload)
        notify.success('Информация успешно обновлена')
      } catch (error) {
        // notify.error('Ошибка обновления информации') // backend handler added (exception_message)
      } finally {
        await this.getStudent()
        if (mealTypeUpdated) {
          await recreatePupilOrders({ 'pupil_id': payload.id })
        }
        store.commit('setShowPreloader', false)
      }
    },
    resetData() {
      this.offset = 0
      this.payloads = []
      this.dataTableLoaded = false
    },
    changeDate() {
      this.resetData()
      this.loadPayments()
    },
    resetPageFileLoaded() {
      this.setShowPreloader(false)
    },
	  calculateFoodPeriod(type) {
		  if (type === 'days') {
			  this.foodSum = Math.ceil(this.foodDays * this.convertToRubles(this.foodPrice))
			  return
		  }
		  this.foodDays = parseInt(
			  this.convertToCents(this.foodSum) / this.foodPrice
		  )
	  },
    handlerScrollBottom() {
      this.offset += this.LIMIT
      if (this.offset <= this.payloads.length) {
        this.loadPayments()
      }
    },
    getISODate(date) {
      return date.split('-').reverse().join('-')
    }
  }
}
</script>
