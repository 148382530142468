<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h3>Создать новый справочный материал</h3>
      </v-card-title>
      <v-card-text>
        <v-layout column>
          <v-flex>
            <v-text-field v-model="title" label="Заголовок" solo>
            </v-text-field>
          </v-flex>
          <v-flex>
            <v-select
              label="Тип материала"
              v-model="type"
              :items="type_choices"
              item-text="name"
              item-value="id"
              solo
            ></v-select>
          </v-flex>
          <v-flex>
            <v-select
              label="Для роли"
              v-model="role"
              :items="role_choices"
              item-text="name"
              item-value="id"
              solo
            ></v-select>
          </v-flex>
          <v-flex v-if="type === 2">
            <h4>Ссылка</h4>
            <v-text-field v-model="url"></v-text-field>
          </v-flex>
          <v-flex v-else>
            <h4>Содержание</h4>
            <ckeditor
              v-model="content"
              :config="editorConfig"
              :editor="editor"
            ></ckeditor>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-layout row>
          <v-flex>
            <v-btn @click="$router.push('/moduleadmin/user-help/')">
              Назад
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn @click="create()" class="right success"> Сохранить </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { createUserHelp } from '@/router/routes/superadmin'
import store from '@/store'
import notify from '@/utils/notifications/toastNotification'
import ckeditorMixin from '@/mixins/ckeditorMixin'

export default {
  name: 'UserHelpCreate',
  mixins: [ ckeditorMixin ],

  data() {
    return {
      title: null,
      type: null,
      role: null,
      content: null,
      url: null,
      type_choices: [
        { name: 'Инструкция', id: 1 },
        { name: 'Видео-материал', id: 2 },
        { name: 'Часто задаваемые вопросы', id: 3 }
      ],
      role_choices: [
        { name: 'Администратор питания', id: 1 },
        { name: 'Учитель', id: 2 },
        { name: 'Родитель', id: 3 },
        { name: 'МОУО', id: 4 },
        { name: 'Департамент', id: 7 },
        { name: 'Организатор питания', id: 6 }
      ]
    }
  },
  methods: {
    async create() {
      const payload = {
        type: this.type,
        role: this.role,
        title: this.title,
        content: this.type === 2 ? this.url : this.content
      }
      await createUserHelp(payload)
      notify.success('Запись создана')
      this.title = null
      this.type = null
      this.role = null
      this.content = null
      this.url = null
      store.commit('setShowPreloader', false)
    }
  }
}
</script>

<style scoped></style>
