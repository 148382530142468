<template>
  <v-container
      fluid
      class="fs-organizer-container"
  >
    <v-layout
      row
      mb3
    >
      <div class="md12 mb-3 d-flex">
        <h1>{{ title }}</h1>
        <context-help-modal :context-key="contextKeys.supplier.credit"/>
      </div>
    </v-layout>
    <v-layout
        row
        wrap
        mb-3
    >
      <v-flex xs12 md6>
        <element-group
            title="Питание в кредит"
            class="pa-2"
        >
          <v-layout wrap column>
            <v-flex xs6 md6>
              <v-checkbox
                  label="Разрешить школе питание в кредит"
                  placeholder="Разрешить школе питание в кредит"
                  v-model="school.isAbleToCredit"
              ></v-checkbox>
            </v-flex>
            <v-flex xs6 md6>
              <v-layout column>
                <v-flex pl-1 pr-5>
                  <v-text-field
                      placeholder="Введите сумму кредитования на ученика школы"
                      label="Введите сумму кредитования на ученика школы"
                      v-model="school.maxCreditAmountForOneStudent"
                  ></v-text-field>
                </v-flex>
                <v-flex pl-1 pr-5>
                  <v-text-field
                      placeholder="Введите сумму кредитования на ученика школы с ГПД"
                      label="Введите сумму кредитования на ученика школы с ГПД"
                      v-model="school.is_long_day_credit_amount"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </element-group>
      </v-flex>
      <v-flex xs12 md6 class="px-2">
        <element-group
            class="pa-3"
            title="Подача заявки"
            v-if="$store.getters.setting('set_time_orders_school_block')"
        >
          <v-checkbox
              label="Блокировка изменения времени подачи заявки школой"
              placeholder="Разрешить школе питание в кредит"
              v-model="school.ordersTimeChangeBlock"
          />
          <v-checkbox
            label="Буфетная школа"
            v-model="school.meal_preparation_type"
            v-if="setting('meal_preparation_type')"
          />
          <v-layout
              v-if="school.ordersTimeChangeBlock && $store.getters.setting('set_time_orders_school_block')"
          >
            <v-flex class="d-flex" row xs8>
              <v-text-field
                  class="ma-2"
                  type="number"
                  label="Часы"
                  v-model="orderHours"
                  :rules="hourRules"
                  hide-details
              />
              <v-layout row align-center>
                <v-flex  xs6>
                  : 00
                </v-flex>
              </v-layout>
<!--              <v-text-field-->
<!--                  type="number"-->
<!--                  class="ma-2"-->
<!--                  label="Минуты"-->
<!--                  v-model="orderMinutes"-->
<!--                  :rules="minuteRules"-->
<!--                  maxlength="2"-->
<!--              />-->
            </v-flex>
          </v-layout>
          <v-flex class="ma-2">
              <p class="error--text" v-if="isShowOrderTimeError">
                <strong>Время подачи заявок не должно превышать 22:00 и быть отрицательным</strong>
              </p>
          </v-flex>
        </element-group>
      </v-flex>
    </v-layout>

    <v-layout class="fs-form-footer">
      <v-flex
          text-md-right
          pt-3
      >
        <v-btn
            flat
            class="secondary"
            @click="$router.push({name: 'OrganizerCard'})"
        >Назад
        </v-btn>
        <v-btn
            flat
            class="primary"
            @click="save()"
        >Сохранить
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { getSchool, updateSchool } from '@/router/routes/organizer'
import Vue from 'vue'
import _ from 'lodash'
import store from '@/store'
import numable from '@/mixins/balancable'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'
import ElementGroup from "@/components/common/ElementGroup/ElementGroup"
import { mapGetters } from "vuex"

export default {
  name: 'OrganizerSchoolIndex',
  components: { ContextHelpModal, ElementGroup },
  mixins: [ numable ],
  created() {
    this.loadSchool(this.$route.params.id)
  },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      school: {
        isAbleToCredit: false,
        maxCreditAmountForOneStudent: 0,
        is_long_day_credit_amount: 0,
        ordersTimeChangeBlock: false,
        meal_preparation_type: false,
      },
      orderHoursValue: 22,
      orderMinutesValue: 0,
      orderGiveotTime: '',
      hourRules: [
        val => {
          if (val < 23 && val >= 0) {
            return true
          }
          return 'Не может быть больше 22'
        },
      ],
      minuteRules: [
        val => {
          if (val < 60 && val >= 0) {
            return true
          }
          return 'Не может быть больше 22'
        },
      ],
      showOrderTimeError: false
    }
  },
  watch: {
    orderHoursValue(newVal, oldVal) {
      this.isShowOrderTimeError = this.isOrderTimeCorrect()
    },
    orderMinutesValue(newVal, oldVal) {
      this.isShowOrderTimeError = this.isOrderTimeCorrect()
    }
  },
  computed: {
    ...mapGetters([ 'setting' ]),
    title() {
      return 'Настройки школы ' + this.$route.params.name
    },
    orderHours: {
      get() {
        return this.orderHoursValue
      },
      set(value) {
        this.orderHoursValue = value
      }
    },
    orderMinutes: {
      get() {
        return this.orderMinutesValue
      },
      set(value) {
        this.orderMinutesValue = value
      }
    },
    isShowOrderTimeError: {
      get() {
        return this.showOrderTimeError
      },
      set(value) {
        this.showOrderTimeError = value
      }
    }
  },
  methods: {
    isOrderTimeCorrect() {
      const hours = Number(this.orderHoursValue)
      const minutes = Number(this.orderMinutesValue)

      if (hours < 0 || hours > 22 || minutes < 0 || minutes > 59) {
        return true
      }
      else if (hours === 22) {
        if (minutes > 0) {
          return true
        }
      }
      return false
    },
    loadSchool(id) {
      getSchool(id)
        .then(response => {
          const school = response.data.data
          this.school = {
            isAbleToCredit: _.get(school, 'isAbleToCredit', false),
            ordersTimeChangeBlock: _.get(school, 'orders_time_change_block'),
            maxCreditAmountForOneStudent: this.convertToRubles(
              _.get(school, 'maxCreditAmountForOneStudent', 0)
            ),
            is_long_day_credit_amount: this.convertToRubles(
              _.get(school, 'is_long_day_credit_amount', 0),
            ),
            orders_time_change_block: this.school.ordersTimeChangeBlock,
            meal_preparation_type: _.get(school, 'meal_preparation_type', false)
          }
          const orderGiveoutTime = response.data.data['meal_order_submission_time']
          const orderGiveoutSplit = orderGiveoutTime.split(':')
          this.orderHoursValue = orderGiveoutSplit[0]
          this.orderMinutesValue = '00'
        }
        ).finally(() => store.commit('setShowPreloader', false))
    },
    save() {
      if (!this.isShowOrderTimeError) {
        const hoursValue = this.orderHoursValue.length === 1 ? '0' + this.orderHoursValue : this.orderHoursValue
        const minutesValue = this.orderMinutesValue.length === 1 ? '0' + this.orderMinutesValue : this.orderMinutesValue
        this.orderGiveotTime = hoursValue + ':' + minutesValue
      }

      const payload = {
        id: this.$route.params.id,
        params: {
          isAbleToCredit: this.school.isAbleToCredit,
          maxCreditAmountForOneStudent: this.convertToCents(
            this.school.maxCreditAmountForOneStudent
          ),
          is_long_day_credit_amount: this.convertToCents(
            this.school.is_long_day_credit_amount
          ),
          orders_time_change_block: this.school.ordersTimeChangeBlock,
          meal_order_submission_time: this.orderGiveotTime,
          meal_preparation_type: this.school.meal_preparation_type
        }
      }
      if (
        !this.isShowOrderTimeError &&
          !(this.orderHoursValue === '' || this.orderMinutesValue === '')&&
          this.orderHoursValue.length < 3 &&
          this.orderMinutesValue.length < 3
      ) {
        updateSchool(payload)
          .then(response => {
            if (response.data.success) {
              notify.success(Vue.config.notifications.organizer.schoolUpdatedSuccess)
            }
            this.loadSchool(payload.id)
          })
          .finally(() => {
            store.commit('setShowPreloader', false)
            this.orderGiveotTime = ''
          })
      }
      else {
        notify.error('Время подачи заявок указно неверно')
      }
    }
  }
}
</script>
