<template>
  <v-container>
    <v-layout fill-height>
      <v-flex md12>
        <div class="justify-center align-center" :style="flex">
          <slot></slot>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Pug',
  data: () => ({
    flex: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }
  })
}
</script>
