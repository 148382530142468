<template>
  <v-container class="fs-organizer-container" fluid>
    <v-layout justify-space-around>
      <v-flex>
        <div class="d-inline-flex">
          <h1>{{ title }}</h1>
          <context-help-modal :context-key="contextKeys.supplier.dayMenu" />
        </div>
        <v-layout row mt-3 mb-3 justify-space-around align-center>
          <v-layout wrap row>
            <v-flex md3 mr-3>
              <DatePickersSmart
                :normalStyle="true"
                :startDate.sync="dateStart"
                :endDate.sync="dateEnd"
                @changeDate="changeDate"
              />
            </v-flex>

            <v-flex
              md9
              mr-3
            >
              <v-autocomplete
                v-model="school"
                :items="schools"
                item-text="name"
                multiple
                item-value="id"
                label="Образовательные организации"
              />
            </v-flex>
          </v-layout>
        </v-layout>
        <v-layout class="fs-complex-result" mb-3>
          <v-flex>
            <v-data-table
              :headers="headers"
              :items="items"
              class="elevation-1"
              hide-actions
              item-key="name"
            >
              <template v-slot:items="props">
                <tr>
                  <td class="cursor-pointer" @click="$router.push({ name: 'MenuTemplatesDayView', params: { id: props.item.id }, query: { dateStart: dateStart, dateEnd: dateEnd } })">{{ props.item.date }}</td>
                  <td class="cursor-pointer" @click="$router.push({ name: 'MenuTemplatesDayView', params: { id: props.item.id }, query: { dateStart: dateStart, dateEnd: dateEnd } })">{{ props.item.school ? props.item.school.short_name : '-' }}</td>
                  <td class="cursor-pointer" @click="$router.push({ name: 'MenuTemplatesDayView', params: { id: props.item.id }, query: { dateStart: dateStart, dateEnd: dateEnd } })">{{ props.item.complects_mapping.length }}</td>
                  <td class="cursor-pointer" @click="$router.push({ name: 'MenuTemplatesDayView', params: { id: props.item.id }, query: { dateStart: dateStart, dateEnd: dateEnd } })">{{ props.item.created_at }}</td>
                  <td class="cursor-pointer" @click="$router.push({ name: 'MenuTemplatesDayView', params: { id: props.item.id }, query: { dateStart: dateStart, dateEnd: dateEnd } })">{{ props.item.updated_at }}</td>
                  <td class="cursor-pointer" @click="$router.push({ name: 'MenuTemplatesDayView', params: { id: props.item.id }, query: { dateStart: dateStart, dateEnd: dateEnd } })">{{ props.item.block_date || 'нет' }}</td>
                  <td>
                    <v-flex row>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            @click="$router.push({ name: 'MenuTemplatesDayEdit', params: { id: props.item.id } })"
                          >edit</v-icon>
                        </template>
                        <span>Редактирование</span>
                      </v-tooltip>
                    </v-flex>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment'
import Vue from 'vue'
import { mapMutations } from 'vuex'
import store from '@/store'
import scrollable from '@/mixins/scrollable'
import { getAllDayMenu, getSchools } from '@/router/routes/organizer'
import { DatePickersSmart } from '@/components/common'
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'MenuTemplates',
  mixins: [ scrollable ],
  components: {
    ContextHelpModal,
    DatePickersSmart
  },
  data() {
    return {
      title: 'Ежедневное меню',
      contextKeys: Vue.config.contextHelp,
      dateStart: moment()
        .subtract(1, 'days')
        .format(Vue.config.format.serverDate),
      schools: [],
      school: null,
      dateEnd: null,
      headers: [
        { text: 'Дата', value: 'date' },
        { text: 'Школа', value: 'school.short_name' },
        { text: 'Назначено комплексов', value: 'complects' },
        { text: 'Дата создания', value: 'created_at' },
        { text: 'Дата обновления', value: 'updated_at' },
        { text: 'Дата блокировки', value: 'block_date' },
        { text: 'Операции', value: 'operations' }
      ],
      items: [],
      accountsArray: [],
      btnUnloadLoaded: false,
      btnPrintLoaded: false,
      LIMIT: Vue.config.general.limit
    }
  },
  created() {
    if (this.$route.query.dateStart) {
      this.dateStart = this.$route.query.dateStart
      this.dateEnd = this.$route.query.dateEnd
    }
    this.loadMore()
    this.getSchools()
    window.addEventListener('scroll', this.scrollHandler)
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    async getSchools() {
      try {
        const PAYLOAD = {
          limit: 10000,
          offset: 0
        }
        const response = await getSchools(PAYLOAD)
        const {
          data: { data: items }
        } = response
        const itemsArray = items.map(item => {
          const { id, name } = item
          return {
            id: id,
            name: name
          }
        })
        this.schools = [ ...this.schools, ...itemsArray ]
      } catch (err) {
        // notify.error('Ошибка загрузки школ') // backend handler added (exception_message)
        console.error(err)
      } finally {
        this.setShowPreloader(false)
      }
    },
    changeDate(payload) {
      this.items = []
      this.loadMore()
    },
    convertToPythonDate(date) {
      if (date) {
        return moment(date).format(Vue.config.format.pythonDate)
      }
      return null
    },
    convertToNormalDate(date) {
      if (date) {
        return moment(date).format(Vue.config.format.date)
      }
      return null
    },
    async loadMore() {
      let PAYLOAD = null
      try {
        PAYLOAD = {
          params: {
            limit: this.LIMIT,
            offset: this.offset,
            schools: this.school && this.school.length > 0 ? this.school.join(',') : null
          }
        }
        if (!this.dateEnd) {
          PAYLOAD.params['date'] = this.convertToPythonDate(this.dateStart)
        } else {
          PAYLOAD.params['min_date'] = this.convertToPythonDate(this.dateStart)
          PAYLOAD.params['max_date'] = this.convertToPythonDate(this.dateEnd)
        }
        const response = await getAllDayMenu(PAYLOAD)
        const {
          data: { data: responseDataArray }
        } = response
        const itemsArray = responseDataArray.map(item => {
          const {
            id = null,
            date = '',
            additional_food = [],
            block_date = null,
            complects = [],
            complects_mapping = [],
            default_complects = [],
            is_blocked = null,
            school = null,
            supplier = null,
            created_at = '',
            updated_at = ''
          } = item
          // const studentID = _.get(item, "item.id", null)
          // const studentName = _.get(item, "item.fullName", "")
          return {
            id,
            date: this.convertToNormalDate(date),
            additional_food,
            block_date: this.convertToNormalDate(block_date),
            complects,
            complects_mapping,
            default_complects,
            is_blocked,
            school,
            supplier,
            created_at: this.convertToNormalDate(created_at),
            updated_at: this.convertToNormalDate(updated_at)
          }
        })
        // const itemsArraySorted = this.sortArrayBySchools(itemsArray)
        this.items = [ ...this.items, ...itemsArray ]
      } catch (error) {
        // notify.error('Ошибка загрузки ежедневного меню') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    },
    school(newVal, oldVal) {
      this.offset = 0
      this.items = []
      this.loadMore()
    }
  }
}
</script>
