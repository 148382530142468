<template>
  <v-container fluid>
    <div class="mb-3 d-flex">
      <div style="display: flex;">
        <h1>Шаблоны меню</h1>
        <context-help-modal :context-key="contextKeys.supplier.template.info" />
      </div>
      <v-spacer style="width: 50%"></v-spacer>
      <v-btn
          class="error"
          flat
          @click="openClearDialog"
      >
        Очистка меню
      </v-btn>
      <v-btn
        class="primary"
        flat
        @click="activateMenu"
      >Активировать меню</v-btn>
    </div>

    <v-layout
      mb-3
    >
      <v-flex>
        <v-expansion-panel>
          <v-expansion-panel-content>
            <template v-slot:header>
              <div class="subheading">Очередность недель в текущем месяце</div>
            </template>
            <v-data-table
                :items="weeks"
                :headers="weekHeaders"
                hide-actions
                class="elevation-1"
            >
              <template v-slot:items="props">
                <tr>
                  <td>{{ props.item.weekFrom }}</td>
                  <td>{{ props.item.weekTo }}</td>
                  <td>{{ props.item.weekOrder }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </v-layout>

    <v-layout
      mb-3
      justify-space-around
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="infoTemplateByDays"
          hide-actions
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:items="props">
            <tr v-if="props.index % 7 === 0">
              <th colspan="3">{{ props.index / 7 + 1 }} неделя</th>
            </tr>
            <tr :style="{ backgroundColor: props.item.assigned > 0 ? '#e3ffe4':'',
            cursor: 'pointer',
            }" @click="handleClickWeekDay(props.item)">
              <td>{{ week[props.item.day % 7] }}</td>
              <td>{{ props.item.total }}</td>
              <td>{{ props.item.assigned }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-container>
      <v-dialog
          v-model="isOpenClearDialog"
          max-width="500"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Подтвердите очистку меню</span>
          </v-card-title>
          <v-card-text>
            <h4>Внимание!</h4>
            При подтверждении будут удалены все записи о ежедневном меню начиная с текущего дня при условии,
            что на этот день не будет подтвержденных заявок на питание от школ. Если заявки есть - меню на день
            не будет удалено.
            <br/>
            <b>Вам необходимо будет заново провести активацию меню, что бы заявки смогли формироваться</b>
          </v-card-text>
          <v-card-actions>
            <v-btn
                @click="closeClearDialog"
                color="grey-darken-1"
            >
              Отмена
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                @click="confirmMenuReset"
                color="error"
            >
              Подтвердить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapMutations } from 'vuex'

import {
  getSupplierMenuTemplatesByDays,
  activateTemplates,
  getReserveComplex,
  setReserveComplex, clearFutureDailyMenu
} from '@/router/routes/organizer'
import moment from "moment";
import ContextHelpModal from "@/components/common/Modals/ContextHelpModal.vue"
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'AllDays',
  components: { ContextHelpModal },
  data() {
    return {
      contextKeys: Vue.config.contextHelp,
      features: this.$store.state.common.features,
      reserveComplexes: [],
      selectedReserveComplex: null,
      infoTemplateByDays: [],
      activeTab: 0,
      isOpenClearDialog: false,
      weeks: [],
      weekHeaders: [
        { text: 'Начало недели', value: 'weekFrom', sortable: false },
        { text: 'Конец недели', value: 'weekTo', sortable: false },
        { text: 'Номер недели', value: 'weekOrder', sortable: false }
      ],
      headers: [
        { text: 'День недели', value: 'day', sortable: false },
        { text: 'Кол-во шаблонов', value: 'total', sortable: false, width: '20%' },
        { text: 'Кол-во активных шаблонов', value: 'assigned', sortable: false, width: '20%' },
      ],
      week: {
        0: 'Воскресенье',
        1: 'Понедельник',
        2: 'Вторник',
        3: 'Среда',
        4: 'Четверг',
        5: 'Пятница',
        6: 'Суббота'
      },
      start_date: null
    }
  },
  mounted() {
    this.getSupplierMenuTemplatesByDays()
  },
  methods: {
    ...mapMutations([ 'setShowPreloader' ]),
    handleClickWeekDay(item) {
      this.$router.push({
        name: 'DayMenuTemplates',
        params: {
          week: Math.ceil(item.day / 7),
          weekDayNum: item.day,
          weekDayStr: this.week[item.day % 7]
        }
      })
    },
    openClearDialog() {
      this.isOpenClearDialog = true
    },
    closeClearDialog() {
      this.isOpenClearDialog = false
    },
    async confirmMenuReset() {
      try {
        const response = await clearFutureDailyMenu()
        notify.success(Vue.config.notifications.organizer.dailyMenuClearSuccess)
      } catch (error) {
        notify.error('Не удалось очистить ежедненвое меню, обратитесь в поддержку')
      } finally {
        this.setShowPreloader(false)
        this.closeClearDialog()
      }
    },
    async activateMenu() {
      try {
        const response = await activateTemplates()

        const {
          data: { success }
        } = response
        if (success) {
          notify.success(Vue.config.notifications.organizer.activateSuccess)
        }
      } catch (error) {

      } finally {
        this.setShowPreloader(false)
      }
    },
    getSupplierMenuTemplatesByDays() {
      getSupplierMenuTemplatesByDays()
        .then(res => {
          this.infoTemplateByDays = res.data.data
          this.start_date = res.data.start_date
        })
        .catch(err => {
          // notify.error('Ошибка загрузки информации шаблонов организатора по дням')
        })
        .finally(() => {
          this.getWeekOrder()
          this.setShowPreloader(false)
        })
    },
    getWeekOrder() {
      const firstDayOfMonth = moment().date(1)
      const weekToCheck = moment(firstDayOfMonth)
      let weekCount = null
      if (this.start_date < 8) {   //
        weekCount = 1
      } else {
        weekCount = 2
      }
      this.weeks.push({
        weekOrder: weekCount,
        weekFrom: firstDayOfMonth.format('DD.MM.YYYY'),
        weekTo: moment(firstDayOfMonth).add(1, 'week').day('sunday').format('DD.MM.YYYY')
      })
      while (weekToCheck.month() === firstDayOfMonth.month()) {
        weekCount === 1 ? weekCount = 2 : weekCount = 1
        weekToCheck.add(1, 'week')
        const weekStart = moment(weekToCheck).day('monday')
        let weekEnd = moment(weekStart).add(1, 'week').day('sunday')
        if (weekEnd.month() !== firstDayOfMonth.month()) {
          weekEnd = moment(firstDayOfMonth).add(1, 'month').subtract(1, 'day')
        }
        if (weekStart > weekEnd) break
        this.weeks.push({
          weekOrder: weekCount,
          weekFrom: weekStart.format('DD.MM.YYYY'),
          weekTo: weekEnd.format('DD.MM.YYYY')
        })
      }
    }
  }
}
</script>
