<template>
  <v-container class="fs-parent-container" fluid>
    <v-layout row justify-space-around>
      <v-flex md12 pt-5 text-md-center>
        <h1>{{ message }}</h1>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import store from '@/store'
import { paymentComplete } from '@/router/routes/parent'

export default {
  name: 'Success',
  data() {
    return {
      message: 'Баланс успешно пополнен. Пожалуйста, закройте это окно.'
    }
  },
  async mounted() {
    if (this.$route.query.orderId) {
      try {
        await paymentComplete(this.$route.query.orderId)
      } catch (e) {
        this.message = e.response.data.message
      }
      store.commit('setShowPreloader', false)
    } else {
      this.message = 'Идентификатор платежа не найден'
    }
  }
}
</script>
