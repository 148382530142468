import axios from 'axios'
import { preloaderInterceptor } from '@/bootstrap'

export const disableSorting = {
  data: () => ({
    headersKey: 'headers',
  }),
  created() {
    const headers = (this.headersKey in this.$attrs
      ? this.$attrs[this.headersKey]
      : this[this.headersKey]) || []

    if (Array.isArray(headers)) {
      headers.forEach(header => {
        header.sortable = false
      })
    }
  }
}

export const paginationTable = {
  data: () => ({
    pagination: {},
    requestPagination: {},
    responsePagination: null
  }),
  computed: {
    paginateLocalStorageKey() {
      return this.params && this.params.archive ? `${this.$parent.$options.name}_archive` : this.$parent.$options.name
    }
  },
  created() {
    if (localStorage.getItem(this.paginateLocalStorageKey)) {
      this.initPaginateFromLs()
    } else {
      this.resetPagination()
    }
  },
  methods: {
    setPaginationInfoLocalStorage(value) {
      localStorage.setItem(this.paginateLocalStorageKey, value)
    },
    initPaginateFromLs() {
      const lsPaginate = JSON.parse(localStorage.getItem(this.paginateLocalStorageKey))
      if (!lsPaginate) return
      this.pagination = lsPaginate

      if (!this.requestPagination) this.requestPagination = {}
      this.requestPagination.page = lsPaginate.page
      this.requestPagination.page_size = lsPaginate.rowsPerPage

    },
    resetPagination() {
      const pagination = {
        page: 1,
        rowsPerPage: 10,
        totalItems: null
      }

      this.requestPagination = {
        page: 1,
        page_size: 10
      }

      for (const key in pagination) {
        this.pagination[key] = pagination[key]
      }
    },
    setResponsePagination(obj) {
      this.pagination.totalItems = obj && 'count' in obj ? obj.count : null
      this.setPaginationInfoLocalStorage(JSON.stringify(this.pagination))
    },
    paginator(obj, request) {
      this.requestPagination = { page: this.pagination.page, page_size: this.pagination.rowsPerPage }
      request()
    },
    getNumbering(index) {
      let num = index + 1
      if (this.pagination.page > 1) {
        num += (this.pagination.page - 1) * this.pagination.rowsPerPage
      }

      return num
    }
  },
  watch: {
    params: {
      deep: true,
      handler(newVal, oldVal) {
        this.initPaginateFromLs()
      }
    }
  }
}

export const disableLoader = {
  created() {
    axios.interceptors.request.eject(preloaderInterceptor)
  }
}
