<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-dish"
      row
    >
      <v-flex
        md8
        mb-3
      >
        <h1>{{ title }}</h1>
        <v-btn
          flat
          class="mt-4"
          @click="$router.push( {name: 'Oferta'} )"
        >Назад
        </v-btn>
      </v-flex>

    </v-layout>
    <v-layout
      v-if="hasItems"
      class="fs-dish-result"
      mb-3
    >
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="items"
          hide-actions
          no-data-text="Нет оферт"
          item-key="id"
        >
          <template v-slot:items="props">
            <tr>
              <td class="text-xs-left ">{{ props.item.id }}</td>
              <td class="text-xs-left ">{{ props.item.full_name }}</td>
              <td class="text-xs-left">{{ props.item.agreementAcceptionDate || 'нет' }}</td>
              <td class="text-xs-left">{{ props.item.offlineContractAcceptionDate || 'нет'}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <!-- <v-layout v-else>
      <v-flex class="text-xs-center">
        <h1>У вас пока нет оферт</h1>
      </v-flex>
    </v-layout> -->
    <!-- <TextEditorModal
      :dialog.sync="editorModalShow"
      @confirm="handlerConfirmModal"
      :content.sync="content"
    >
      <template v-slot:headline>Просмотр оферты</template>
    </TextEditorModal> -->
  </v-container>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  getOfferLatest,
  // eslint-disable-next-line no-unused-vars
  deleteOffer,
  studentsOffer
  /* eslint-enable no-unused-vars */
} from '@/router/routes/organizer'
import store from '@/store'
import Vue from 'vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import { TextEditorModal } from '@/components/common'
import scrollable from '@/mixins/scrollable'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'Oferta',
  mixins: [ scrollable ],
  components: {
    TextEditorModal
  },
  data() {
    return {
      title: 'Оферта статус',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Имя ученика', value: 'full_name' },
        { text: 'Оферта принята', value: 'agreementAcceptionDate' },
        { text: 'Бумажная оферта принята', value: 'offlineContractAcceptionDate' },
      ],
      items: [],
      LIMIT: Vue.config.general.limit,
      dataTableLoaded: false,
      editorModalShow: false,
      content: ''
    }
  },
  computed: {
    hasItems() {
      return this.items.length
    }
  },
  created() {
    this.loadMore()
    window.addEventListener('scroll', this.scrollHandler)
  },
  methods: {
    openEditorModal(text) {
      const defaultContent = ''
      this.content = defaultContent
      if (text) {
        this.content = text
      }
      this.editorModalShow = true
    },
    async handlerConfirmModal(value) {
      this.modalResponse = value
      if (this.modalResponse) {
        const payload = {
          params: {
            text: this.content
          }
        }
        try {
          await createOffer(payload)
          await this.resetData()
          await this.loadMore()
        } catch (error) {
          console.error('LOG: handlerConfirmModal -> error', error)
          // notify.error('Ошибка создания оферты') // backend handler added (exception_message)
        } finally {
          store.commit('setShowPreloader', false)
        }
      }
      this.btnPrintLoading = false
    },
    async resetData() {
      this.offset = 0
      this.selected = []
      this.items = []
    },
    async loadMore() {
      const payload = {
        params: {
          limit: this.LIMIT,
          offset: this.offset
        }
      }
      try {
        const response = await studentsOffer(payload)
        const {
          data: { data: items }
        } = response

        const itemsArray = items.map(item => {
          const {
            pupilId = null,
            full_name = '',
            agreementAcceptionDate = '',
            offlineContractAcceptionDate = ''
          } = item
          return {
            id: pupilId,
            full_name,
            agreementAcceptionDate,
            offlineContractAcceptionDate
          }
        })
        this.items = [ ...this.items, ...itemsArray ]
        this.dataTableLoaded = true
      } catch (error) {
        // notify.error('Ошибка загрузки оферт') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  },
  watch: {
    items(items) {
      this.savePosition(items)
    }
  }
}
</script>
