<template>
  <v-card flat>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-actions
      class="elevation-1"
    >
    items {items}}
      <template v-slot:items="props">
          <tr>
            <td>{{ props.item.name }}</td>
            <td>
              <v-text-field
                v-model="props.item.count"
                type="number"
                min="0"

                :label="!darkTheme ? 'Кол-во ставок' : ''"
                :placeholder="darkTheme ? 'Кол-во ставок' : ''"
                :outline="darkTheme"
                :hide-details="darkTheme"
              />
            </td>
            <td>
              <v-text-field
                v-model="props.item.completeness"

                :label="!darkTheme ? 'Укомплектован-ность' : ''"
                :placeholder="darkTheme ? 'Укомплектован-ность' : ''"
                :outline="darkTheme"
                :hide-details="darkTheme"
              />
            </td>
            <td>
              <v-text-field
                v-model="props.item.qualifying"
                type="number"
                min="0"

                :label="!darkTheme ? 'Квалификационный разряд' : ''"
                :placeholder="darkTheme ? 'Квалификационный разряд' : ''"
                :outline="darkTheme"
                :hide-details="darkTheme"
              />
            </td>
            <td>
              <v-text-field
                v-model="props.item.workExperience"
                type="number"
                min="0"

                :label="!darkTheme ? 'Стаж работы по специальности' : ''"
                :placeholder="darkTheme ? 'Стаж работы по специальности' : ''"
                :outline="darkTheme"
                :hide-details="darkTheme"
              />
            </td>
            <td>
              <v-checkbox
                v-model="props.item.medicalRecord"

              />
            </td>
          </tr>
        </template>
    </v-data-table>
    <v-card-actions>
      <v-btn
        flat
        @click="update"
        class="fs-btn fs-btn--primary fs-btn__add-dish"
      >Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import store from "@/store"
export default {
  name: 'Form7',
  data() {
    return {
      darkTheme: Vue.config.env.darkTheme,
      formId: null,
      headers: [
        { text: 'Категории работников', sortable: false },
        { text: 'Кол-во ставок', sortable: false },
        { text: 'Укомплектован-ность', sortable: false },
        { text: 'Квалификационный разряд', sortable: false },
        { text: 'Стаж работы по специальности', sortable: false },
        { text: 'Наличие медицинской книжки', sortable: false },
      ],
      items: [
        { name: 'Поваров', count: 0, completeness: "", qualifying: "", workExperience: "", medicalRecord: "" },
        { name: 'Рабочих кухни/помощники повара', count: 0, completeness: "", qualifying: "", workExperience: "", medicalRecord: "" },
        { name: 'Официантов', count: 0, completeness: "", qualifying: "", workExperience: "", medicalRecord: "" },
        { name: 'Других работников пищеблока/ посудомойщицы', count: 0, completeness: "", qualifying: "", workExperience: "", medicalRecord: "" },
        { name: 'Технических работников/ уборщицы', count: 0, completeness: "", qualifying: "", workExperience: "", medicalRecord: "" },
      ],
      names: {
        "chefs": "Поваров",
        "kitchen_workers_and_cook_assistants": "Рабочих кухни/помощники повара",
        "waiters": "Официантов",
        "other_food_service_workers_dishwashers": "Других работников пищеблока/ посудомойщицы",
        "technical_workers_cleaners": "Технических работников/ уборщицы"
      },
    }
  },
  mounted() {
    this.getFoodblockStaff(this.foodBlockPassport.household_sections.id)
      .then(result => this.setForm(result))
  },
  methods: {
    ...mapActions([ 'getFoodblockStaff', 'updateFoodBlockStaff' ]),
    update() {
      this.updateFoodBlockStaff({ id: this.formId, data: { result: this.transform_data_to_back(this.items), passport: this.foodBlockPassport.id } })
        .then(result => this.setForm(result))
    },
    setForm(data) {
      this.formId = data.id
      const items = this.items = data.result ? data.result : this.items
      this.items = this.transform_data_to_front(items)
    },
    transform_data_to_front(backendData) {
      const names = this.names
      const res = Object.keys(backendData).map(function(key) {
        const { count, completeness, qualifying, workExperience, medicalRecord } = backendData[key]
        return {
          name: names[key],
          id: key,
          count: count,
          completeness: completeness,
          qualifying: qualifying,
          workExperience: workExperience,
          medicalRecord: medicalRecord,
        }
      })
      return res
    },
    transform_data_to_back(frontendData) {
      const res = {}

      frontendData.forEach(el => {
        res[el.id] = {
          count: el.count,
          completeness: el.completeness,
          qualifying: el.qualifying,
          workExperience: el.workExperience,
          medicalRecord: el.medicalRecord,
        }
      })
      return res
    },
  },
  computed: {
    ...mapGetters([ 'foodBlockPassport' ])
  }
}
</script>
