<template>
  <v-container fluid class="fs-parent-container">
    <div class="page-head__common">
      <h1>Запрещенный список продуктов ученика</h1>
    </div>
    <v-select
      :items="blacklist_school_user"
      item-text="fullname"
      item-value="id"
      clearable
      return-object
      v-model="pupil_school_pk"

      :label="!darkTheme ? 'Ученики' : ''"
      :placeholder="darkTheme ? 'Ученики' : ''"
      :outline="darkTheme"
      :hide-details="darkTheme"
      style="margin-bottom: 15px;"
    ></v-select>

    <v-card
      tile
      flat
      v-if="blacklist_pupil_school_pk && pupil_blacklist.length > 0"
    >
      <v-list subheader two-line>
        <v-subheader>Список запрещённых продуктов</v-subheader>

        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>№{{ pupil_blacklist[0].id }}</v-list-tile-title>
            <v-list-tile-sub-title
              >Дата создания: {{ transformDate(pupil_blacklist[0].created_at) }}
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-btn
          flat
          @click="
            () =>
              $router.push({
                name: 'BlackListDetail',
                params: {
                  create: false,
                  pupil_pk: blacklist_pupil_school_pk.id,
                  blacklist_id: pupil_blacklist[0].id,
                  school: blacklist_pupil_school_pk.school
                }
              })
          "
          class="fs-btn fs-btn--primary fs-btn__add-dish"
          >Изменить список запрещённых продуктов</v-btn
        >
      </v-list>

      {{ blacklistId }}
    </v-card>

    <v-card
      tile
      flat
      v-else-if="blacklist_pupil_school_pk && pupil_blacklist.length == 0"
    >
      <v-list subheader two-line>
        <v-subheader>У ученика нет черного списка продуктов</v-subheader>
        <v-btn
          flat
          @click="
            () =>
              $router.push({
                name: 'BlackListDetail',
                params: {
                  create: true,
                  pupil_pk: blacklist_pupil_school_pk.id,
                  school: blacklist_pupil_school_pk.school
                }
              })
          "
          class="fs-btn fs-btn--primary fs-btn__add-dish ml-2"
          >Создать список запрещённых продуктов для ученика</v-btn
        >
      </v-list>
    </v-card>

    <v-card tile flat v-else>
      <v-list subheader two-line>
        <v-subheader>Ученик не выбран</v-subheader>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { getAllStudents } from '@/router/routes/parent.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import store from "@/store"
import notify from '@/utils/notifications/toastNotification'
export default {
  name: 'BlackList',
  data: () => ({
    darkTheme: Vue.config.env.darkTheme,
    blacklistId: null,
    headers: [
      { text: 'ID', value: 'id_blacklist' },
      { text: 'Дата создания', value: 'created_at' },
      { text: 'ID Ученика', value: 'pupil.id' }
    ]
  }),
  mounted() {
    this.getStudents()
  },
  computed: {
    ...mapGetters([
      'pupil_blacklist',
      'blacklist_school_user',
      'blacklist_pupil_school_pk'
    ]),
    pupil_school_pk: {
      get() {
        return this.blacklist_pupil_school_pk
      },
      set(val) {
        this.set_blacklist_pupil_school_pk(val)
      }
    }
  },
  methods: {
    ...mapActions([ 'getStudentBlackList' ]),
    ...mapMutations([
      'set_blacklist_school_user',
      'set_blacklist_pupil_school_pk'
    ]),
    async getStudents() {
      try {
        const response = await getAllStudents()
        const itemsArray = response.data.data.map(item => ({
          id: item.id,
          fullname: item.fullName,
          school: item.schoolClass.school.id
        }))
        this.set_blacklist_school_user([ ...itemsArray ])
      } catch (error) {
        console.error(error)
        notify.error('Ошибка загрузки учеников') //pass
      } finally {
        this.$store.commit('setShowPreloader', false)
      }
    },
    clearPupil() {
      this.pupil_school_pk = null
    },
    transformDate(date) {
      return moment(date).format(Vue.config.format.date)
    }
  },
  watch: {
    blacklist_pupil_school_pk(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.getStudentBlackList({ pupil_pk: newVal.id })
      }
    }
  }
}
</script>

<style></style>
