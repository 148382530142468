<template>
  <v-container fluid>
    <v-layout row>
      <v-flex>
        <v-btn @click="$router.push('/moduleadmin/user-help/create/')">
          Создать материал
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex
        my-2
        px-1
        class="sm12 xl4"
        v-for="(category, idx) in [instructions, videos, faqs]"
      >
        <v-expansion-panel v-model="panels[idx]">
          <v-expansion-panel-content>
            <template v-slot:header>
              <h3>{{ category.name }}</h3>
            </template>
            <v-card>
              <v-card-text>
                <v-data-table
                  :items="category.items"
                  :headers="headers"
                  hide-actions
                >
                  <template v-slot:items="props">
                    <tr>
                      <td>{{ roleBeautify(props.item.role_name) }}</td>
                      <td>{{ props.item.title }}</td>
                      <td>{{ props.item.created_at }}</td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              @click="editItem(props.item.id)"
                              v-on="on"
                              class="mx-2 cursor-pointer"
                            >
                              edit
                            </v-icon>
                          </template>
                          <span>Редактировать</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              @click="deleteItem(props.item.id)"
                              v-on="on"
                              class="mx-2 cursor-pointer"
                              color="red"
                            >
                              delete
                            </v-icon>
                          </template>
                          <span>Удалить</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getUserHelp, removeUserHelp } from '@/router/routes/superadmin'
import store from '@/store'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'ModuleAdminUserHelp',
  mounted() {
    this.getData()
  },
  data() {
    return {
      instructions: {
        name: 'Инструкции',
        items: []
      },
      videos: {
        name: 'Видео',
        items: []
      },
      faqs: {
        name: 'Часто задаваемые вопросы',
        items: []
      },
      panels: [0, 0, 0],
      headers: [
        { text: 'Роль', value: 'role' },
        { text: 'Название', value: 'title' },
        { text: 'Создано', value: 'created_at' },
        { text: 'Операции', value: 'ops' }
      ]
    }
  },
  methods: {
    async getData() {
      const response = await getUserHelp()
      this.instructions.items = response.data.instructions
      this.videos.items = response.data.videos
      this.faqs.items = response.data.faqs
      store.commit('setShowPreloader', false)
    },
    async deleteItem(id) {
      const payload = { id }
      await removeUserHelp(payload)
      notify.success('Запись удалена')
      await this.getData()
    },
    editItem(id) {
      this.$router.push('/moduleadmin/user-help/:id/'.replace(':id', id))
    },
    roleBeautify(item) {
      switch (item) {
        case 'SCHOOL_MEAL_ADMIN':
          return 'Администратор питания'
        case 'TEACHER':
          return 'Учитель'
        case 'PARENT':
          return 'Родитель'
        case 'DEPARTMENT':
          return 'МОУО'
        case 'SUPPLIER':
          return 'Организатор питания'
        case 'DEPARTMENT_ADMIN':
          return 'Департамент'
        default:
          return 'Неизвестно'
      }
    }
  }
}
</script>

<style scoped></style>
