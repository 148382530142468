<template>
  <v-container
    fluid
    class="fs-organizer-container"
  >
    <v-layout
      class="fs-dish"
      row
    >
      <v-flex
        md3
        mb-5
      >
        <h1>{{ title }}</h1>
      </v-flex>
    </v-layout>
    <v-select
      style="width: 22%"
      v-on:change='this.loadUpdatesList'
      v-model="selectedRole"
      :items="dropListItems"
      item-text="name"
      item-value="value"
      label="Выберите роль"
      solo
    ></v-select>
    <v-btn
      flat
      class="primary ma-0 mb-3"
      @click="openEditorModal()"
    >
      <v-icon
        primary
        left
      >edit</v-icon>Создать новость
    </v-btn>
    <v-layout
      class="fs-dish-result"
      column
      mb-3
    >
      <v-flex>
        <v-btn
          flat
          class="primary ma-0 mb-3"
          @click="showLatestUpdate()"
        >
          <v-icon
            primary
            left
          >remove_red_eye</v-icon>последняя
        </v-btn>
      </v-flex>
      <v-flex>
        <v-data-table
          :headers="headers"
          :items.sync="items"
          hide-actions
          no-data-text="Нет новостей"
          item-key="id"
        >
          <template v-slot:items="props">
            <tr>
              <td class="cursor-pointer" @click="() => {showDialog(props.item.title, props.item.text, props.item.date)}">{{ props.item.id }}</td>
              <td class="cursor-pointer" @click="() => {showDialog(props.item.title, props.item.text, props.item.date)}">{{ props.item.title }}</td>
              <td class="cursor-pointer" @click="() => {showDialog(props.item.title, props.item.text, props.item.date)}">{{ new Date(props.item.date).toLocaleDateString() }}</td>
              <td class="text-xs-left">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="dialogTitle = props.item.title; editStatus = true; editID = props.item.id; TextModalDate = props.item.date; openEditorModal(props.item.text)"
                      class="fs-active-icon"
                    >edit</v-icon>
                  </template>
                  <span>Редактирование</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      @click="deleteNews(props.item.id)"
                      class="pl-2 fs-active-icon"
                    >delete</v-icon>
                  </template>
                  <span>Удалить</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialog" width="60%">
      <v-card-title class="text-h5 grey lighten-2">
        <h3>{{ dialogTitle }}</h3>
      </v-card-title>
      <v-card class="pa-3">
        <div v-html="dialogText"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mt-4" color="success" @click="() => {this.dialogText = ''; this.dialogDate = ''; this.dialog = false}">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <TextEditorModal
      :dialog.sync="editorModalShow"
      @confirm="handlerConfirmModal"
      :content.sync="content"
    >
      <template v-slot:headline>Создать новость для [{{ dropListItems.find(obj => selectedRole === obj.value).name }}]</template>
      <template v-slot:headright>
        <div class="d-flex" style="width: 100%">
          <v-text-field
                  v-model="dialogTitle"
                  clearable
                  label="Заголовок"
          />
          <v-spacer />
          <v-menu
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="new Date(TextModalDate).toLocaleDateString()"
                clearable
                label="Выберите дату"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="ru-RU"
              v-model="TextModalDate"
            ></v-date-picker>
          </v-menu>
        </div>
      </template>
    </TextEditorModal>
  </v-container>
</template>

<script>
import store from '@/store'
import Vue from 'vue'
import { TextEditorModal } from '@/components/common'
import {
  createUpdatesNews,
  updateLastNews,
  getUpdatesNewsLatest,
  getUpdatesNewsList,
  deleteNewsByID
} from '@/router/routes/superadmin'
import notify from '@/utils/notifications/toastNotification'

export default {
  name: 'UpdatesNews',
  components: {
    TextEditorModal
  },
  data() {
    return {
      title: 'Новости',
      TextModalDate: null,
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Заголовок', value: 'title', sortable: false },
        { text: 'Создана', value: 'data', sortable: false },
        { text: 'Операции', value: 'operations', sortable: false }
      ],
      selectedRole: 'admin',
      dropListItems: [
        { name: 'Департамент', value: 'department' },
        { name: 'Администратор питания', value: 'admin' },
        { name: 'Организатор питания', value: 'supplier' },
        { name: 'Учитель', value: 'teacher' },
        { name: 'Родитель', value: 'parent' },
      ],
      items: [],
      dialog: false,
      editStatus: false,
      editID: 0,
      dialogTitle: '',
      dialogText: '',
      dialogDate: '',
      dataTableLoaded: false,
      editorModalShow: false,
      content: ''
    }
  },
  mounted() {
    this.loadUpdatesList()
  },
  methods: {
    async loadUpdatesList() {
      this.dataTableLoaded = false
      this.items = []
      try {
        const payload = {
          params: {
            role: this.selectedRole
          }
        }
        const response = await getUpdatesNewsList(payload)
        this.items = response.data
        this.dataTableLoaded = true
      } catch (error) {
        // notify.error('Ошибка загрузки списка обновлений') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    openEditorModal(text) {
      this.content = ''
      if (text) {
        this.content = text
      }
      this.editorModalShow = true
    },
    async showLatestUpdate() {
      try {
        const payload = {
          params: {
            role: this.selectedRole
          }
        }
        const response = await getUpdatesNewsLatest(payload)
        this.dialogText = response.data.text
        this.dialogDate = response.data.date
        this.dialogTitle = response.data.title
        this.dialog = true
      } catch (error) {
        // notify.error('Ошибка загрузки последнего обновления') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async deleteNews(id) {
      const payload = {
        params: {
          id: id,
        }
      }
      try {
        await deleteNewsByID(payload)
        notify.success('Новость успешно удалена')
        await this.loadUpdatesList()
      } catch (error) {
        console.error('LOG: handlerConfirmModal -> error', error)
      } finally {
        store.commit('setShowPreloader', false)
      }
    },
    async handlerConfirmModal(value) {
      if (value) {
        if (this.editStatus) {
          const payload = {
            params: {
              title: this.dialogTitle,
              text: this.content,
              role: this.selectedRole,
              id: this.editID,
              date: this.TextModalDate
            }
          }
          this.TextModalDate = null
          this.editID = 0
          this.editStatus = false
          try {
            await updateLastNews(payload)
            notify.success('Новость успешно отредактирована')
            await this.loadUpdatesList()
          } catch (error) {
            console.error('LOG: handlerConfirmModal -> error', error)
          } finally {
            store.commit('setShowPreloader', false)
          }
        } else {
          const payload = {
            params: {
              title: this.dialogTitle,
              text: this.content,
              role: this.selectedRole,
              date: this.TextModalDate
            }
          }
          try {
            await createUpdatesNews(payload)
            notify.success('Новость успешно создана')
            await this.loadUpdatesList()
          } catch (error) {
            console.error('LOG: handlerConfirmModal -> error', error)
          } finally {
            store.commit('setShowPreloader', false)
          }
        }
      }
    },
    showDialog(title, text, date) {
      this.dialogTitle = title
      this.dialogText = text
      this.dialogDate = date
      this.dialog = true
    }
  }
}
</script>
