<template>
  <v-container fluid class="fs-parent-container">
    <v-layout class="fs-archive" mb-3>
      <v-flex mb-3>
        <h1>{{ title }}</h1>
      </v-flex>
    </v-layout>
    <v-layout mb-3 row>
      <v-flex md-2>
        <v-alert :value="student.isFake" type="error">
          <h3>
            Ошибка: по данному ученику из учётной системы региона получены
            неполные данные. Работа с ним временно заблокирована. Ожидайте
            повторной синхронизации.
          </h3>
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout v-if="!isChildView">
      <v-flex>
        <v-btn
          flat
          class="fs-btn fs-parent-btn fs-btn--secondary"
          @click="$router.push({ name: 'HelpDeskPA' })"
          target="_blank"
        >
          Руководство пользователя
        </v-btn>
        <v-btn
          flat
          class="fs-btn fs-parent-btn fs-btn--secondary"
          :href="reminder"
          target="_blank"
        >
          Памятка для родителей
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      wrap
      align-space-around
      fill-height
      style="justify-content: space-between"
    >
      <v-flex md5>
        <v-card>
          <v-card-title>
            <div>
              <h3 class="headline">Информация:</h3>
            </div>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-layout column>
              <v-flex class="mb-3 mt-3" style="display: flex">
                <h1>{{ student.fullName }}</h1>
                <h1 class="ml-4">{{ student.className }}</h1>
              </v-flex>
              <v-flex>
                <p>
                  <span class="fs-parent-info__key" style="font-weight: bold"
                    >Образовательное учреждение:</span
                  >
                  {{ student.schoolName }}
                </p>
              </v-flex>
              <v-flex>
                <p>
                  <span class="fs-parent-info__key" style="font-weight: bold"
                    >Лицевой счет:</span
                  >
                  {{ student.billNumber }}
                </p>
              </v-flex>
              <v-flex>
                <p>
                  <span class="fs-parent-info__key" style="font-weight: bold"
                    >Организатор питания:</span
                  >
                  <router-link :to="{ name: 'ParentOrganizerCard' }">
                    {{
                      student.foodOrganizer && student.foodOrganizer.short_name
                    }}
                  </router-link>
                </p>
              </v-flex>
              <v-flex>
                <p>
                  <span class="fs-parent-info__key" style="font-weight: bold"
                    >Категория социальной выплаты:</span
                  >
                  {{ student.concession && student.concession.name }}
                </p>
              </v-flex>
              <v-flex>
                <p>
                  <span class="fs-parent-info__key" style="font-weight: bold"
                    >Тип питания:</span
                  >
                  {{ student.foodType && student.foodType.name }}
                </p>
              </v-flex>
              <v-flex>
                <p>
                  <span class="fs-parent-info__key" style="font-weight: bold"
                    >Оферта принята:</span
                  >
                  {{ student.isAgreementAccepted ? 'Да' : 'Нет' }}
                </p>
              </v-flex>
              <v-flex>
                <p>
                  <span class="fs-parent-info__key" style="font-weight: bold"
                    >Дата принятия оферты:</span
                  >
                  {{ student.agreementAcceptionDate || 'Нет даты' }}
                </p>
              </v-flex>
              <v-flex v-if="!student.isAgreementAccepted">
                <p>
                  <span class="fs-parent-info__key" style="font-weight: bold"
                    >Бумажная оферта принята:</span
                  >
                  {{ student.isOfflineContractAccepted ? 'Да' : 'Нет' }}
                </p>
              </v-flex>
              <v-flex v-if="!student.isAgreementAccepted">
                <p>
                  <span class="fs-parent-info__key" style="font-weight: bold"
                    >Дата принятия бумажной оферты:</span
                  >
                  {{ student.offlineContractAcceptionDate || 'Нет даты' }}
                </p>
              </v-flex>
              <v-layout column>
                <v-btn
                  disabled
                  flat
                  class="fs-btn fs-parent-btn fs-btn--secondary fs-parent-btn--personal-info-first"
                  v-show="false"
                  >Получить выписку по счету</v-btn
                >
                <v-btn
                  v-if="!isChildView"
                  :disabled="student.isFake"
                  flat
                  class="fs-btn fs-parent-btn fs-btn--secondary"
                  :to="{ name: 'ParentOferta' }"
                  >Публичная оферта</v-btn
                >
                <v-btn
                  flat
                  :disabled="student.isFake"
                  class="fs-btn fs-parent-btn fs-btn--secondary"
                  @click="concessionsDialog = true"
                  >Время питания и льготы</v-btn
                >
                <v-btn
                  v-if="!isChildView"
                  flat
                  :disabled="student.isFake"
                  class="fs-btn fs-parent-btn fs-btn--secondary"
                  @click="$router.push({ name: 'CashbackIndex' })"
                  target="_blank"
                  >Возврат средств</v-btn
                >
              </v-layout>
            </v-layout>
          </v-card-text>
        </v-card>
        <v-card class="my-5" v-if="!isChildView">
          <v-card-title primary-title>
            <div>
              <h3 class="headline">Настройка уведомлений</h3>
            </div>
          </v-card-title>
          <v-card-text>
            <v-switch
              color="primary"
              class=""
              :disabled="student.isFake"
              :label="`Уведомлять о снижении баланса`"
              v-model="isNotify"
            ></v-switch>
            <v-divider></v-divider>
            <v-expansion-panel :style="{ background: '#fffbf1 !important' }">
              <v-expansion-panel-content hide-actions v-model="isNotify" lazy>
                <v-layout pa-3 column>
                  <v-flex>
                    <v-text-field
                      :disabled="!isNotify"
                      type="number"
                      color="primary"
                      label="Отправлять уведомления, когда баланс ниже"
                      v-model="notificationSum"
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <span style="color: red">
                      При снижении баланса ниже, чем
                      {{ notificationSum }} рублей, Вам будет отправлено
                      уведомление с просьбой пополнить баланс на счёте.
                    </span>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-flex style="margin-top: 10px">
              <v-btn
                flat
                :disabled="student.isFake"
                @click="saveSettings"
                class="fs-btn fs-parent-btn fs-btn--secondary"
                >Сохранить</v-btn
              >
            </v-flex>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex md6>
        <v-card>
          <v-card-title>
            <div>
              <h3 class="headline">Баланс:</h3>
            </div>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-layout mb-3 mt-3 v-if="!is_sirius">
              <v-flex md8>
                <span class="fs-student-info__key">Текущий баланс:</span>
              </v-flex>
              <v-flex text-md-right>
                <span class="fs-student-info__key"
                  >{{ convertToRubles(student.currentBalance) }} р.</span
                >
              </v-flex>
            </v-layout>
            <v-layout v-if="setting('mod_buffet')" mb-3 mt-3>
              <v-flex md8>
                <span class="fs-student-info__key">Баланс в буфете:</span>
              </v-flex>
              <v-flex text-md-right>
                <span class="fs-student-info__key"
                  >{{ convertToRubles(student.buffetBalance) }} р.</span
                >
              </v-flex>
            </v-layout>
            <v-layout mb-3>
              <v-flex md8>
                <span class="fs-student-info__key">Кредит разрешен:</span>
              </v-flex>
              <v-flex text-md-right>
                <span class="fs-student-info__key">{{
                  student.isCredit ? 'Да' : 'Нет'
                }}</span>
              </v-flex>
            </v-layout>
            <v-layout mb-3>
              <v-flex md8>
                <span class="fs-student-info__key">Кредитный лимит:</span>
              </v-flex>
              <v-flex text-md-right>
                <span class="fs-student-info__key">{{
                  student.creditLimit
                }}</span>
              </v-flex>
            </v-layout>

            <v-layout mb-3 v-if="is_buffet_parent_card">
              <v-flex md8>
                <span class="fs-student-info__key">Номер Вашей карты:</span>
              </v-flex>
              <v-flex text-md-right>
                <span class="fs-student-info__key">{{
                  student.parent_card
                }}</span>
              </v-flex>
            </v-layout>

            <v-btn
              class="fs-btn fs-parent-btn fs-btn--secondary align-self-end"
              v-if="setting('mod_buffet') && student.isBuffetCard && !is_sirius"
              @click="isOpenTransfer = !isOpenTransfer"
            >
              Перевести на счёт буфета
            </v-btn>
          </v-card-text>
        </v-card>

        <v-card
          class="mt-3"
          v-if="is_family_transfer_balance && yourSchoolchildren?.length > 1"
        >
          <v-card-title>
            <h3 v-if="is_sirius" class="headline">
              Балансы детей на буфетных картах:
            </h3>
            <h3 v-else class="headline">Балансы детей:</h3>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-layout
              mb-3
              mt-3
              v-for="(item, idx) in yourSchoolchildren"
              :key="idx"
            >
              <v-flex md8>
                <span class="fs-student-info__key">{{ item.fullName }}</span>
              </v-flex>
              <v-flex text-md-right>
                <span class="fs-student-info__key"
                  >{{ getDisplayBalanceChildren(item) }}
                </span>
              </v-flex>
            </v-layout>

            <v-btn
              v-if="!disabledTransferButton()"
              flat
              :disabled="disabledTransferButton()"
              class="fs-btn fs-parent-btn fs-btn--secondary align-self-end"
              @click="handleBalanceTransfer"
              target="_blank"
            >
              Перенос средств со счета на счет
            </v-btn>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <concessions-modal
      class="fs-parent-container"
      class-name="fs-parent-container fs-parent-container--modal"
      width="1200"
      v-model="concessionsDialog"
    ></concessions-modal>
    <ConfirmModal
      v-if="isOpenTransfer"
      :dialog="true"
      :width="450"
      confirmBtnText="Перевести"
      cancel-btn-text="Закрыть"
      :disableConfirmButton="transferButtonBlock"
      :closePersistent="true"
      :btns-class="'fs-btn fs-parent-btn fs-btn--primary fs-form-footer__btn v-btn v-btn--flat theme--light'"
      @confirm="confirmTransferMoney"
    >
      <template v-slot:headline
        >Перевод денежных средств на счёт буфета</template
      >
      <template v-slot:body>
        <v-text-field
          v-model="transferMoneyAmount"
          type="number"
          label="Укажите сумму"
        ></v-text-field>
      </template>
    </ConfirmModal>
    <TransferModal ref="transferModal" @update="getStudent" />
  </v-container>
</template>

<script>
import PersonalInfoFactory from '@/factories/parent/personal-info'
import NotificationPeriodsFactory from '@/factories/notification-periods'
import { mapGetters, mapMutations } from 'vuex'
import ConcessionsModal from '@/components/parent/Modals/Concessions'
import numable from '@/mixins/balancable'
import _ from 'lodash'
import {
  getOneStudent,
  getSettings,
  saveSettings,
  transferMoney,
  getBuffetBalance
} from '@/router/routes/parent'
import {
  getChildStudent,
  getChildSettings,
  saveChildSettings
} from '@/router/routes/pupil'
import store from '@/store'
import converter from '@/helpers/converter'
import Vue from 'vue'
import notify from '@/utils/notifications/toastNotification'
import ConfirmModal from '@/components/common/Modals/ConfirmModal.vue'
import TransferModal from '@/components/parent/PersonalArea/components/TransferModal.vue'

export default {
  name: 'PersonalAreaIndex',
  components: {
    TransferModal,
    ConfirmModal,
    'concessions-modal': ConcessionsModal
  },
  mixins: [numable],
  data() {
    return {
      transferButtonBlock: false,
      title: 'Общая информация об ученике',
      isOpenTransfer: false,
      transferMoneyAmount: 0,
      concessionsDialog: false,
      notifyMinBalance: null,
      student: new PersonalInfoFactory(this.$route.params.id),
      notificationPeriods: new NotificationPeriodsFactory(),
      isNotify: null,
      notificationSum: null,
      notificationPeriod: null,
      manual: Vue.config.publicPath + 'Manual.pdf',
      reminder: Vue.config.publicPath + 'reminder.pdf'
    }
  },
  props: {
    isChildView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'currentChild',
      'setting',
      'yourSchoolchildren'
    ]),

    is_family_transfer_balance() {
      return this.setting('family-transfer-balance')
    },
    is_buffet_parent_card() {
      return this.setting('buffet_parent_card')
    },
    is_sirius() {
      return this.setting('sirius')
    }
  },
  created() {
    this.getStudent()
  },
  methods: {
    ...mapMutations(['setShowPreloader']),
    handleBalanceTransfer() {
      this.$refs.transferModal.openModal()
    },

    getDisplayBalanceChildren(child) {
      if (this.is_sirius) {
        return child.is_buffet_card
          ? `${this.convertToRubles(child?.buffet_balance || 0)} р.`
          : 'Нет карты'
      } else {
        return `${this.convertToRubles(child.balance)} р.`
      }
    },

    disabledTransferButton() {
      const childrenWithBuffetCard = this.yourSchoolchildren.filter(
        (child) => child.is_buffet_card
      )
      return this.is_sirius ? childrenWithBuffetCard?.length < 2 : false
    },

    async confirmTransferMoney(confirm) {
      this.transferButtonBlock = true
      if (confirm) {
        const PAYLOAD = {
          pupil_id: this.currentChild.id,
          payload: {
            amount: numable.methods.convertToCents(this.transferMoneyAmount)
          }
        }
        try {
          const response = await transferMoney(PAYLOAD)
          this.student.currentBalance = response.data.balance
          this.student.buffetBalance = !response.data.buffet_balance
            ? 0
            : response.data.buffet_balance
          this.isOpenTransfer = false
          Vue.notify({
            type: 'success',
            text: 'Деньги успешно переведены на счёт буфета'
          })
        } catch (e) {
          try {
            const error = JSON.parse(e.request.response).message
            if (error.includes('success')) {
              Vue.notify({
                type: 'error',
                text: JSON.parse(error).detail
              })
            } else {
              Vue.notify({
                type: 'error',
                text: error
              })
            }
          } catch {
            Vue.notify({
              type: 'error',
              text: 'Произошла неизвестная ошибка'
            })
          }
        } finally {
          store.commit('setShowPreloader', false)
        }
        this.transferMoneyAmount = 0
      } else {
        this.isOpenTransfer = false
      }
      this.transferButtonBlock = false
    },
    changeSum: function (value) {
      this.notificationSum = converter(value, 'rubToPenny')
    },
    async getStudent() {
      try {
        const ID = this.currentChild.id
        const response = this.isChildView
          ? await getChildStudent()
          : await getOneStudent(ID)
        const student = response.data.data
        this.student = {
          fullName: _.get(student, 'fullName', ''),
          billNumber: _.get(student, 'id', null),
          foodOrganizer: _.get(student, 'supplier', {}),
          concession: _.get(student, 'foodType.privilege', ''),
          foodType: _.get(student, 'foodType', ''),
          currentBalance: _.get(student, 'balance', ''),
          reservedBalance: _.get(student, 'reserved', ''),
          availableBalance: _.get(student, 'available', ''),
          isFake: _.get(student, 'isFake', ''),
          isCredit: _.get(student, 'isAbleToCredit', ''),
          creditLimit: this.convertToRubles(
            _.get(student, 'maxCreditSumm', '')
          ),
          isAgreementAccepted: _.get(student, 'isAgreementAccepted', null),
          agreementAcceptionDate: _.get(student, 'agreementAcceptionDate', ''),
          isOfflineContractAccepted: _.get(
            student,
            'isOfflineContractAccepted',
            null
          ),
          offlineContractAcceptionDate: _.get(
            student,
            'offlineContractAcceptionDate',
            ''
          ),
          className: _.get(student, 'schoolClass.name', ''),
          schoolName: _.get(student, 'schoolClass.school.name', ''),
          buffetBalance: _.get(student, 'buffet_balance', ''),
          isBuffetCard: _.get(student, 'is_buffet_card', false)
        }
        const responseSettings = this.isChildView
          ? await getChildSettings()
          : await getSettings(ID)
        const {
          data: {
            data: {
              isNotify = false,
              notificationSum = 0,
              notificationPeriod = 1
            }
          }
        } = responseSettings
        this.isNotify = isNotify
        this.notificationSum = converter(notificationSum, 'pennyToRub')
        this.notificationPeriod = notificationPeriod
      } catch (error) {
        // notify.error('Не удалось загрузить информацию об ученике') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
      if (this.setting('mod_buffet')) {
        try {
          const { data } = await getBuffetBalance(this.currentChild.id)
          this.student.buffetBalance = !data.buffet_balance
            ? 0
            : data.buffet_balance
          if (this.is_buffet_parent_card) {
            this.student.parent_card = data.parent_card
          }
        } catch {
          notify.error(
            'Не удалось загрузить информацию об балансе ученика в буфете'
          )
        } finally {
          store.commit('setShowPreloader', false)
        }
      }
    },
    async saveSettings() {
      const payload = {
        id: this.currentChild.id,
        data: {
          isNotify: this.isNotify,
          notificationSum: converter(this.notificationSum, 'rubToPenny'),
          notificationPeriod: this.notificationPeriod
        }
      }
      try {
        this.isChildView
          ? await saveChildSettings(payload)
          : await saveSettings(payload)
        notify.success('Настройки сохранены успешно')
      } catch (error) {
        // notify.error('Не удалось сохранить настройки') // backend handler added (exception_message)
      } finally {
        store.commit('setShowPreloader', false)
      }
    }
  }
}
</script>
